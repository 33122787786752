import React from "react";

export const columns = [
  {
    title: "Business Entity",
    field: "party_name",
  },
  {
    title: "Agency or Court",
    field: "law_enforcement_bd_busines_entity.business_name",
  },
  {
    field: "docket_number",
  },
  {
    field: "case_name",
  },
  {
    title: "Date Filed",
    field: "charge_date",
  },
];

export const tableQuestions = (
  <>
    In the last ten (10) years, has the applicant, or any of it’s affiliates,
    intermediaries, subsidiaries or holding companies had:
    <ul>
      <li>
        Any petition under any provision of the Federal Bankruptcy Code or under
        any state insolvency law filed by or against it?
      </li>
      <li>
        Sought relief under any provision of the Federal Bankruptcy Code or
        under any state insolvency law?
      </li>
      <li>
        A court appointed any receiver, fiscal agent trustee, reorganization
        trustee, or similar officer?
      </li>
    </ul>
    If yes to any of the above provide details below.
  </>
);

export const defaultInitialValues = {
  type: "bankruptcy",
  bd_party_id: null,
  law_enforcement_bd_business_entity_id: null,
  agent_bd_party_id: null,
  is_bankruptcy_petition: null,
  is_bankruptcy_relief_sought: null,
  is_bankruptcy_court_appointed_officer: null,
  case_name: "",
  docket_number: "",
  charge_date: null,
  disposition_date: null,
  appointed_agent_date: null,
  nature_of_judgement: "",
  bankruptcy_disposition: "",
};

export const endpoint = "bdLegalHistory?type=bankruptcy";
