import React from "react";
import { formats } from "components/Common/Tables/Common/helpers";

export const columns = [
  { title: "Creditor Name", field: "creditor_pd_party_name" },
  { title: "Relative Name", field: "relative_pd_person.full_name" },
  { title: "Incurred Date", field: "incurred_date" },
  {
    title: "Original Amount",
    field: "original_amount",
    format: formats.currency,
  },
  {
    title: "Outstanding Amount",
    field: "outstanding_amount",
    format: formats.currency,
  },
  {
    title: "Current",
    field: "is_current",
    format: "boolean",
    sortType: "boolean",
  },
];

export const tableQuestions = (
  <>
    List below the information requested with regard to all loans against life
    insurance policies, pension plans, etc., taken by you, your spouse or your
    dependent children.
  </>
);

export const nonRequired = ["relative_pd_person_id"];

export const endpoint = "insurance_pension_loans_insurancePayable";

export const defaultInitialValues = {
  type: "insurance",
  relative_pd_person_id: null,
  creditor_pd_party_id: null,
  nature_of_payable: "",
  nature_of_security: "",
  original_amount: null,
  interest_rate: null,
  incurred_date: null,
  periodic_payment_details: "",
  outstanding_amount: null,
  due_date: null,
  is_current: true,
};

export const modalTitle = "Loans Against Insurance/Pension Plans";

export const dataHookProps = {
  defaultInitialValues,
  nonRequired,
  endpoint,
};
