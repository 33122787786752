import React from "react";
import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import MultiFormWrapper from "components/Layout/SectionsLayout/MultiFormWrapper.jsx";
import ApplicationInformationForm from "./ApplicationInformationForm.jsx";
import OrganizationalChartForm from "./OrganizationalChart.jsx";

const IowaClassDApplicationInformation = ({ name }) => {
  return (
    <FormSectionWrapper title={name}>
      <MultiFormWrapper>
        <ApplicationInformationForm />
        <OrganizationalChartForm />
      </MultiFormWrapper>
    </FormSectionWrapper>
  );
};

export default IowaClassDApplicationInformation;
