import React from "react";

export const columns = [
  {
    title: "Business Entity",
    field: "party_name",
  },
  {
    title: "Agency or Court",
    field: "law_enforcement_bd_busines_entity.business_name",
  },
  {
    title: "Disposition",
    field: "criminal_disposition",
  },
  {
    field: "docket_number",
  },
  {
    field: "case_name",
  },
  {
    field: "type",
    format: "startCase",
  },
];

export const tableQuestions = (
  <>
    Has the Applicant or its related companies ever had a judgment order,
    consent decree or consent order pertaining to a violation or alleged
    violation of the federal antitrust, trade, regulation or securities laws, or
    similar laws of any state, province, country entered against it?
  </>
);

export const defaultInitialValues = {
  type: "",
  bd_party_id: null,
  law_enforcement_bd_business_entity_id: null,
  case_name: "",
  docket_number: "",
  disposition_date: null,
  nature_of_charge: "",
  criminal_disposition: null,
  nature_of_judgement: "",
  fine_amount: null,
};

export const endpoint = "bdLegalHistory?type=antitrust&type=trade_judgement";
