import React from "react";
import ContractsTable from "./ContractsTable";
import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider";
import CustomLink from "components/Common/Link";
import { InfoAlert } from "components/Layout/Alert/InfoAlert.jsx";

const Contracts = ({ name }) => {
  return (
    <FormSectionWrapper
      headerComponent={
        <InfoAlert>
          <>
            Provide details with respect to all parties whom the Applicant has
            contracts or agreements over USD$250,000. Some jurisdictions will
            require details of contracts of less value - you will be redirected
            to this section to add those contracts as needed.
            <ul>
              <li>
                Do not include those listed in the
                <CustomLink to="bd_directors_trustees_officers" />
                section
              </li>
              <li>
                Only include employees if they are compensated additional to
                their salary and/or bonuses (example: independent contractor
                agreements)
              </li>
              <li>
                Employee compensation is captured in
                <CustomLink to="bd_employee_compensation" />
                section
              </li>
            </ul>
          </>
        </InfoAlert>
      }
      title={name}
    >
      <ContractsTable />
    </FormSectionWrapper>
  );
};

export default Contracts;
