import * as Sentry from "@sentry/react";
import * as Yup from "yup";
import { fieldTypes } from "components/Formik/formGroups/CrudSelect/CrudSelect";
import { address } from "helpers/initialValues";

const nullableString = Yup.string().nullable();

const nullInitial = {
  schema: nullableString,
  initialValue: null,
};

export const getFieldDefaults = (type) => {
  switch (type) {
    case fieldTypes.text:
      return { schema: nullableString, initialValue: "" };
    case fieldTypes.yesNo:
      return {
        schema: Yup.boolean().nullable(),
        initialValue: null,
      };
    case fieldTypes.email:
      return {
        schema: Yup.string().email("Must be a valid email"),
        initialValue: "",
      };

    case fieldTypes.date:
      return nullInitial;
    case fieldTypes.dependantDatePicker:
      return nullInitial;
    case fieldTypes.number:
      return nullInitial;
    case fieldTypes.select:
      return {
        schema: nullableString,
      };
    case fieldTypes.asyncSelect:
      return nullInitial;
    case fieldTypes.checkBoxGroup:
      return {
        schema: Yup.array().of(Yup.string()).nullable(),
        initialValue: null,
      };
    case fieldTypes.checkBox:
      return {
        schema: Yup.boolean().nullable(),
        initialValue: false,
      };
    // PD Selects
    case fieldTypes.pdBusinessSelect:
      return nullInitial;
    case fieldTypes.pdFinancialSelect:
      return nullInitial;
    case fieldTypes.pdLawEnforcementSelect:
      return nullInitial;
    case fieldTypes.pdLicenseAgencySelect:
      return nullInitial;
    case fieldTypes.pdPartySelect:
      return nullInitial;
    case fieldTypes.pdPersonSelect:
      return nullInitial;
    case fieldTypes.pdGamingSelect:
      return nullInitial;
    case fieldTypes.pdRelativeSelect:
      return nullInitial;

    case fieldTypes.addressFields:
      return {
        schema: Yup.object(),
        initialValue: address,
      };
    case fieldTypes.dropZone:
      return {
        schema: Yup.number().nullable(),
        initialValue: null,
      };
    case fieldTypes.businessAssociation:
      return nullInitial;

    // BD Selects
    case fieldTypes.bdPersonSelect:
      return nullInitial;
    case fieldTypes.bdBusinessSelect:
      return nullInitial;
    case fieldTypes.bdLicenseAgencySelect:
      return nullInitial;
    case fieldTypes.bdFinancialSelect:
      return nullInitial;
    case fieldTypes.bdBusinessFamilySelect:
      return nullInitial;
    case fieldTypes.bdGovernmentAgencySelect:
      return nullInitial;
    case fieldTypes.bdPartySelect:
      return nullInitial;
    case fieldTypes.bdLawEnforcementSelect:
      return nullInitial;

    default:
      Sentry.captureException("Field type was not provided in Form Generator");
      console.error("Field type was not provided in Form Generator");
  }
};
