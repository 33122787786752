import { Box } from "@material-ui/core";
import DeleteConfirmation from "components/Common/Dialogs/DeleteConfirmation";
import React from "react";
import useTabularData from "hooks/tableHooks/useTabularData";
import { FormikDialog } from "components/Formik/formContainers/FormikDialog";
import ActionsTable from "components/Common/Tables/Common/index";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import { sortByFromDate } from "../helpers";
import { EditFounderForm } from "./EditFounderForm";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const defaultInitialValues = {
  related_bd_party_id: null,
  related_bd_party_role: "founder",
};

const columns = [
  {
    title: "Founder",
    field: "related_party_name",
  },
  {
    title: "Occupation",
    field: "related_bd_party.bd_person.occupation",
  },
];

export const FoundersTab = () => {
  const {
    data,
    status,
    deleteDialogProps,
    formikProps,
    tableProps,
  } = useTabularData({
    defaultInitialValues,
    endpoint: `bdPosition?related_bd_party_role=founder`,
    validationSchemaKey: "bd_application_details_founders",
  });

  const sortedNames = React.useMemo(() => {
    if (status === "success") return sortByFromDate(data);
  }, [data, status]);

  return (
    <LoadingComponent status={status}>
      <FormikDialog {...formikProps} title="Edit Founder" maxWidth="sm">
        <EditFounderForm />
      </FormikDialog>

      <DeleteConfirmation {...deleteDialogProps} />
      <Box pb={2}>
        <InfoAlert>
          Provide details of all incorporators or founding persons of this
          business entity.
        </InfoAlert>
      </Box>
      <ActionsTable
        title="Founders"
        addButtonText="Add Founder"
        records={sortedNames}
        columns={columns}
        {...tableProps}
      />
    </LoadingComponent>
  );
};
