import PropTypes from "prop-types";
import { Grid, Paper, Button } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { KeyboardDatePickerBase } from "components/Formik/fields/FormikDatePicker";
import SimpleDialog from "components/Common/Dialogs/SimpleDialog";
import React from "react";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import DialogActions from "@material-ui/core/DialogActions";
import { FileDropzone } from "components/Common/FileDropZone/FileDropzone";
import { useDropzoneEndpoints } from "components/Common/FileDropZone/useDropzoneEndpoints";

export const EditAttachmentGroupDialog = ({
  open,
  onClose,
  onSelectYear,
  year,
  currentAttachmentGroup,
}) => {
  const {
    getAttachmentEndpoints,
    attachmentGroupEndpoint,
  } = useDropzoneEndpoints();

  return (
    <SimpleDialog
      onClose={onClose}
      open={open}
      title="Add / Edit Attachments"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <SectionSubHeader label="Select a Year:" />
            <KeyboardDatePickerBase
              label="Year"
              value={year}
              onChange={onSelectYear}
              views={["year"]}
              format="yyyy"
              size={6}
              fullWidth={false}
            />
          </Grid>
          <Grid item xs={12}>
            {currentAttachmentGroup && (
              <Paper
                variant="outlined"
                style={{ padding: "20px", marginTop: "20px" }}
              >
                <DialogContentText>
                  Upload Documents for selected year:
                </DialogContentText>
                <FileDropzone
                  attachmentList={currentAttachmentGroup.attachments}
                  setAttachmentGroupID={
                    //in this case attachment group is set by the parent when year is selected
                    () => null
                  }
                  attachmentGroupID={currentAttachmentGroup.id}
                  description={year || ""}
                  getAttachmentEndpoints={getAttachmentEndpoints}
                  attachmentGroupEndpoint={attachmentGroupEndpoint}
                />
              </Paper>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </SimpleDialog>
  );
};

EditAttachmentGroupDialog.propTypes = {
  currentAttachmentGroup: PropTypes.shape({
    attachments: PropTypes.array,
    description: PropTypes.string,
    id: PropTypes.number,
    parent_id: PropTypes.number,
  }),
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onSelectYear: PropTypes.func,
  open: PropTypes.bool,
  year: PropTypes.string,
  cancelTokens: PropTypes.any,
};
