import React, { useEffect, useState } from "react";
import { useAuth } from "context/auth";
import CheckTags from "./CheckTags";
import {
  Card,
  Select,
  InputLabel,
  FormControl,
  makeStyles,
  MenuItem,
  Button,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import LocateTags from "./LocateTags/LocateTags";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Debugger = (props) => {
  const { PDFValidationTools } = props;
  const classes = useStyles();
  const {
    employee_id,
    loading,
    bd_organization_id,
    bd_business_entity_id,
  } = useAuth();
  const [internalLoading, setInternalLoading] = useState(true);
  const [invalidInputs, setInvalidInputs] = useState("");
  const [tag, setTag] = useState("");
  const [tagLocation, setTagLocation] = useState("");
  const [jurisdictions, setJurisdictions] = useState([]);
  const [jurisdiction, setJurisdiciton] = useState("");
  const [applications, setApplications] = useState("");
  const [application, setApplication] = useState("");
  const [fetching, setFetching] = useState(false);

  const findTags = async () => {
    setInvalidInputs("");
    setFetching(true);
    if (!tag)
      return setTagLocation([
        {
          id: "",
          name: "Enter some information in the Search Bar",
          page: 0,
          pdf_form_id: 0,
          pdf_name: "Nothing searched",
          type: "",
          s3_url: "",
        },
      ]);
    const res = await PDFValidationTools.findTags({
      tag: tag,
      employee_id: employee_id,
      bd_organization_id,
      bd_business_entity_id,
    });

    setTagLocation(res);
    setFetching(false);
  };

  const getApplications = async (params) => {
    const res = await PDFValidationTools.getAllApplications(params);
    setApplications(res);
  };

  const validateApplication = async (pdf) => {
    setInvalidInputs("");
    setTagLocation("");
    const res = await PDFValidationTools.validatePdfFormat({
      employee_id: parseInt(employee_id) || null,
      bd_organization_id: parseInt(bd_organization_id) || null,
      bd_business_entity_id: parseInt(bd_business_entity_id) || null,
      pdf_form_id: pdf,
    });
    setInvalidInputs(res);
    setFetching(false);
  };

  const onClick = (e) => {
    e.preventDefault();
    switch (e.target.innerText) {
      case "RESET":
        setFetching(false);
        setApplication("");
        setApplications("");
        setJurisdiciton("");
        setInvalidInputs("");
        setTagLocation("");
        break;
      case "CHECK TAGS":
        validateApplication(application);
        setFetching(true);
        break;
      default:
        console.log("Something Went Wrong :(");
    }
  };

  const handleChange = (event) => {
    setJurisdiciton(event.target.value);
    getApplications({
      jurisdiction_id: event.target.value,
      is_business: !!bd_organization_id,
    });
  };

  const handleApplicationChange = (event) => {
    setApplication(event.target.value);
  };

  useEffect(() => {
    const getJusidictions = async () => {
      const res = await PDFValidationTools.getAllJusidiction();
      setJurisdictions(res || []);
      setInternalLoading(false);
    };
    !loading && getJusidictions();
  }, [props, PDFValidationTools, loading]);

  if (internalLoading) {
    return <div>loading...</div>;
  }

  return (
    <Card style={{ padding: "0px 0px 0px 10px" }}>
      <h3 style={{ fontWeight: "bold" }}>Application Debugger</h3>
      <div>
        <TextField
          className={classes.width}
          style={{ margin: "30px 0px 8px 0px", height: "50px", width: "700px" }}
          id="outlined-basic"
          label="Paste Tag To Check..."
          variant="outlined"
          InputProps={{ style: { height: "50px", padding: "0px" } }}
          onChange={(e) => setTag(e.target.value)}
        />
        <Button
          style={{ margin: "30px 8px 8px 8px" }}
          variant="contained"
          onClick={findTags}
        >
          Located Tag
        </Button>
      </div>
      <br />
      <FormControl variant="filled" className={classes.formControl}>
        <InputLabel>Jurisdiction</InputLabel>
        <Select value={jurisdiction} onChange={handleChange}>
          {jurisdictions.map((val) => (
            <MenuItem key={val.id} value={val.id}>
              {val.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {applications ? (
        <>
          <FormControl variant="filled" className={classes.formControl}>
            <InputLabel>Application</InputLabel>
            <Select value={application} onChange={handleApplicationChange}>
              {applications.map((val) => (
                <MenuItem key={val.id} value={val.id}>
                  {val.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      ) : (
        <></>
      )}
      {application ? (
        <>
          <Button
            style={{ margin: "8px 5px 0px 0px" }}
            variant="contained"
            onClick={onClick}
          >
            Check Tags
          </Button>
        </>
      ) : (
        <></>
      )}
      <Button
        style={{ margin: "8px 5px 0px 0px" }}
        variant="contained"
        onClick={onClick}
      >
        Reset
      </Button>
      {fetching ? (
        <>
          <br />
          <CircularProgress />
        </>
      ) : typeof invalidInputs === "object" ? (
        <CheckTags invalidInputs={invalidInputs} />
      ) : typeof tagLocation === "object" ? (
        <>
          {tagLocation.map((val, i) => (
            <LocateTags
              key={i}
              page_number={val.page_number}
              name={val.name}
              s3_url={val.s3_url.split("/")[1]}
              source_tag_name={val.source_tag_name}
            />
          ))}
        </>
      ) : (
        <h4>Select an Application</h4>
      )}
    </Card>
  );
};

export default Debugger;
