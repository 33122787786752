import { Grid } from "@material-ui/core";
import {
  Divider,
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import CustomLink from "components/Common/Link";
import { FormikTextField, YesNo } from "components/Formik/fields";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import BDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/BDPersonSelect";
import useForm from "hooks/useForm";
import React from "react";
import { defaultInitialValues } from "./config";
import useEndPoint from "hooks/useEndpoint";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <BDPersonSelect
        name="tennessee_vra.form_contact_bd_person_id"
        label="Contact Person"
        header="PERSON TO BE CONTACTED IN REFERENCE TO THESE FORMS:"
        xs={6}
      />
      <Divider />
      <SectionLabel
        label={
          <>
            Summary of relationship/proposed contract with sports gaming
            operator or supplier and nature/type of goods or services to be
            provided. Attach copy of agreement(s) in
            <CustomLink to="bd_contracts" />
          </>
        }
      />
      <FormikTextField
        multiline
        rows={4}
        label="Summary"
        name="tennessee_vra.summary_relationship_proposed_contract"
        xs={12}
      />
      <Divider />
      <YesNo
        label="Is the business entity minority owned or certified as an MBE, WBE, or VBE?"
        name="tennessee_vra.is_certified_mbe_wbe_vbe"
        xs={12}
      />
    </Grid>
  );
};

const BusinessDetailsForm = () => {
  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Business Entity" />
      <SectionLabel
        label={
          "Provide a summary of the types of business currently conducted in each jurisdiction and list of operators with which business is conducted, services are provided, or goods are supplied:"
        }
      />
      <FormikTextField
        label="Business Summary"
        name="business_summary"
        rows={4}
        multiline
        xs={12}
      />
    </Grid>
  );
};

const TennesseeVRAApplicationInformation = ({ title }) => {
  const { bdBusinessEntityEndpoint } = useEndPoint("");

  const formData = useForm({
    defaultInitialValues,
    endpoint: "bdSupplement",
  });

  const businessDetailsFormData = useForm({
    defaultInitialValues: { business_summary: "" },
    endpoint: bdBusinessEntityEndpoint,
  });

  return (
    <FormSectionWrapper title={title}>
      <FormContextProvider formData={formData}>
        <EnhancedFormikPage>
          <Form />
        </EnhancedFormikPage>
      </FormContextProvider>
      <FormContextProvider formData={businessDetailsFormData}>
        <EnhancedFormikPage>
          <BusinessDetailsForm />
        </EnhancedFormikPage>
      </FormContextProvider>
    </FormSectionWrapper>
  );
};

export default TennesseeVRAApplicationInformation;
