import React from "react";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import { NumberFormatTypes } from "components/Common/Inputs/NumberField";
import { formats } from "components/Common/Tables/Common/helpers";

export const infoAlerts = [
  {
    body: (
      <>
        Provide a copy of the Applicant&apos;s Credit Report, Analyst&apos;s
        Report or any credit ratings published by organisations such as:
        <ul>
          <li>Dun & Bradstreet</li>
          <li>Standard & Poors</li>
          <li>Moodys</li>
          <li>Fitch</li>
        </ul>
      </>
    ),
    severity: "info",
  },
  {
    body: (
      <>
        Please note, you are only required to provide your credit report for
        certain jurisdiction. You will be prompted in the corresponding
        supplemental section when needed.
      </>
    ),
    severity: "warning",
  },
];

export const modifySubmissionValues = (formValues) => ({
  ...formValues,
  report_type: "credit_report",
});

export const endpoint = "bdCreditReport?report_type=credit_report";

export const nonRequired = ["credit_rating_attachment_group_id"];

export const columns = [
  { field: "effective_year" },
  { field: "credit_agency", format: formats.startCase },
];

export const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Credit Report",
  },
  {
    type: fieldTypes.number,
    label: "Effective Year",
    name: "effective_year",
    format: NumberFormatTypes.year,
  },
  {
    name: "credit_agency",
    type: fieldTypes.select,
    defaultValue: null,
    dynamicMenuItems: ["bdCreditAgency"],
    required: true,
  },
  {
    type: fieldTypes.dropZone,
    description: "Credit Rating",
    label: "Upload Attachment",
    name: "credit_rating_attachment_group_id",
  },
];
