import { getFormComponents } from "componentGenerators/helpers";
import colorado_gsbsla_supplement_personal_details from "components/Pages/Supplemental/PD/ColoradoGSBSLA/ColoradoGSBSLAPersonalDetails/ColoradoGSBSLAPersonalDetails";
import colorado_gsbsla_supplement_applications_details from "components/Pages/Supplemental/PD/ColoradoGSBSLA/ColoradoGSBSLAApplicationDetails/ColoradoGSBSLAApplicationDetails";
import colorado_gsbsla_supplement_residential_history from "components/Pages/Supplemental/PD/ColoradoGSBSLA/ColoradoGSBSLAResidentialHistory/ColoradoGSBSLAResidentialHistory";
import colorado_gsbsla_supplement_gaming_licenses from "components/Pages/Supplemental/PD/ColoradoGSBSLA/ColoradoGSBSLAGamingLicenses/ColoradoGSBSLAGamingLicenses";
import colorado_gsbsla_supplement_arrests_charges from "components/Pages/Supplemental/PD/ColoradoGSBSLA/ColoradoGSBSLAArrestsCharges/ColoradoGSBSLAArrestsCharges";
import colorado_gsbsla_supplement_financial_information from "components/Pages/Supplemental/PD/ColoradoGSBSLA/ColoradoGSBSLAFinancialInformation/ColoradoGSBSLAFinancialInformation";
import colorado_gsbsla_supplement_important_notice from "components/Pages/Supplemental/PD/ColoradoGSBSLA/ColoradoGSBSLAImportantNotice/ColoradoGSBSLAImportantNotice";

export default getFormComponents({
  colorado_gsbsla_supplement_personal_details,
  colorado_gsbsla_supplement_applications_details,
  colorado_gsbsla_supplement_residential_history,
  colorado_gsbsla_supplement_gaming_licenses,
  colorado_gsbsla_supplement_arrests_charges,
  colorado_gsbsla_supplement_financial_information,
  colorado_gsbsla_supplement_important_notice,
});
