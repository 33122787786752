import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import YesNo from "components/Formik/formGroups/YesNo";
import FormikErrorScroll from "components/Formik/other/FormikErrorScroll";
import { Form, Formik } from "formik";
import useForm from "hooks/useForm";
import React from "react";
import styled from "styled-components";

const StyledCard = styled(Card)`
  & .MuiCardContent-root {
    padding-bottom: 8px;
  }
`;

export const MilitaryQuestion = ({ title, innerRef, ...rest }) => {
  const { formikProps } = useForm({
    defaultInitialValues: {
      has_military_service: null,
    },
    endpoint: "military_personalDisclosure",
  });
  const noValidationSchema = { ...formikProps };
  delete noValidationSchema["validationSchema"];

  return (
    <Box pb={1}>
      <StyledCard variant="outlined">
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2">{title}</Typography>
            </Grid>
          </Grid>

          <Formik
            enableReinitialize
            {...noValidationSchema}
            {...rest}
            innerRef={innerRef}
          >
            <Form>
              <FormikErrorScroll />
              <YesNo
                label="Have you ever served in a military organization of any country or have you been an active or inactive member of a reserve force of any country?"
                name="has_military_service"
              />
            </Form>
          </Formik>
        </CardContent>
      </StyledCard>
    </Box>
  );
};

export default MilitaryQuestion;
