import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import {
  columns,
  defaultInitialValues,
  endpoint,
  nonRequired,
  tableQuestions,
} from "./config.js";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <FormikDatePicker label="From date" name="from_date" />
      <FormikDatePicker label="To date" name="to_date" />
      <FormikTextField label="Destination" name="destination" xs={4} />
      <FormikSelect
        label="Purpose"
        name="purpose"
        options={[
          { name: "", value: "" },
          { name: "Business", value: "business" },
          { name: "Pleasure", value: "pleasure" },
        ]}
        xs={4}
      />
      <FormikTextField
        label="Description of Purpose"
        name="purpose_description"
        helperText="For Business Travel Only"
        xs={4}
      />
    </Grid>
  );
};

const ForeignTravel = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint,
    nonRequired,
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default ForeignTravel;
