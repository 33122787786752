import React from "react";
import { Grid } from "@material-ui/core";
import FormikAutocomplete from "components/Formik/fields/FormikAutocomplete";
import FormikTextField from "components/Formik/fields/FormikTextField";

import MultiInlineFields from "components/Formik/formGroups/MultiInlineFields/MultiInlineFields";
import { SectionSubHeader } from "components/Common/LayoutComponents";

const DepartmentForm = () => {
  return (
    <Grid container spacing={2}>
      <FormikTextField label="Department Name" name="name" xs={6} />

      <FormikAutocomplete
        label="Recipient Contact Email"
        name="notification_emails"
        xs={12}
        multiple
        freeSolo
        options={[].map(() => "")} //gotta update this later
      />
      <SectionSubHeader divider>Positions</SectionSubHeader>
      <MultiInlineFields
        name="lc_position_type"
        label="Position"
        addFirstRecordButtonText="Add A Position"
        addAdditonalRecordButtonText="Add Another Position"
      />
    </Grid>
  );
};

export default DepartmentForm;
