import React from "react";

import { SectionLabel } from "components/Common/LayoutComponents/";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import useForm from "hooks/useForm.js";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import { Grid } from "@material-ui/core";
import { defaultInitialValues, endpoint } from "./config";
import AlertEdit from "components/Common/Alerts/AlertEdit";

const Form = () => {
  return (
    <EnhancedFormikPage>
      <Grid container spacing={2}>
        <SectionLabel
          label="During the last ten (10) year period, have you held a five (5) percent or greater interest in or been a director, 
        officer, or principal employee of any entity that:"
        />

        <AlertEdit
          endpoint="new_york_financial_questions_pdFinancialQuestions"
          dependsOnQueryCount="Has made or has been charged with (either itself or through third parties acting for it) bribes or kickbacks to any government official, domestic or foreign, 
          to obtain favorable treatment or to any company, employee or organization to obtain a competitive advantage?"
          displayValue={"has_entity_government_kickbacks"}
        />

        <AlertEdit
          endpoint="new_york_financial_questions_pdFinancialQuestions"
          dependsOnQueryCount="Has maintained a bank account, or other account, whether domestic or foreign, which was not reflected on the books or records of the business?"
          displayValue={"has_entity_undeclared_bank"}
        />

        <AlertEdit
          endpoint="new_york_financial_questions_pdFinancialQuestions"
          dependsOnQueryCount="Has maintained a domestic or foreign numbered bank account or other bank account in a name other than the name of the business?"
          displayValue={"has_entity_nominee_bank"}
        />

        <AlertEdit
          endpoint="new_york_financial_questions_pdFinancialQuestions"
          dependsOnQueryCount="Has donated or loaned corporate funds or corporate property for the use or benefit of, or for the purpose of opposing, any government, 
          political party, candidate or committee either domestic or foreign?"
          displayValue={"has_entity_political_donation"}
        />

        <AlertEdit
          endpoint="new_york_financial_questions_pdFinancialQuestions"
          dependsOnQueryCount="Has compensated any of its directors, officers or employees for time and expenses incurred in performing 
          services for the benefit of or in opposition to any government or political party domestic or foreign? "
          displayValue={"has_entity_political_staff_compensation"}
        />

        <AlertEdit
          showIcon={false}
          endpoint="new_york_financial_questions_pdFinancialQuestions"
          dependsOnQueryCount="Has made any loans, donations or other disbursements to its directors, 
          officers or employees for the purpose of making political contributions or reimbursing such individuals for political contributions?"
          displayValue={"has_entity_political_staff_loans"}
          linkTo="financial_questions"
        />

        <SectionLabel label="If any of the above questions is answered 'Yes', please write below a narrative, fully detailing the facts and circumstances regarding such." />
        <FormikTextField
          multiline
          label="Details"
          rows={4}
          name="new_york.new_york_q5_b_details"
          xs={12}
        />
      </Grid>
    </EnhancedFormikPage>
  );
};

const NewYorkFinancialQuestions = ({ name }) => {
  const formData = useForm({
    defaultInitialValues: defaultInitialValues,
    endpoint: endpoint,
  });

  return (
    <FormSectionWrapper title={name}>
      <FormContextProvider formData={formData}>
        <Form />
      </FormContextProvider>
    </FormSectionWrapper>
  );
};

export default NewYorkFinancialQuestions;
