import React from "react";
import { formats } from "components/Common/Tables/Common/helpers";
import { familyOwnership } from "components/Pages/PD/uiStrings";
import { NumberFormatTypes } from "components/Common/Inputs/NumberField";
import {
  fieldTypes,
  formGroupTypes,
} from "componentGenerators/formGenerator/config";

const familyEmploymentColumns = [
  {
    field: "from_date",
    format: formats.date,
  },
  {
    field: "to_date",
    format: formats.date,
  },
  { title: "Business Name", field: "pd_business_entity.business_name" },
  {
    field: "position",
  },
  { title: "Relative Name", field: "relative_pd_person.full_name" },
  {
    title: "Relationship",
    field: "relative_pd_person.relative_type",
    format: "startCase",
  },
];

const familyEmploymentFormSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Business Entity",
  },
  {
    type: fieldTypes.pdBusinessSelect,
    label: "Business Entity",
    endpoint: "gaming_business_entities",
    getOptionLabel: (option) => option.business_name,
    getOptionValue: (option) => option.id,
    required: true,
    name: "pd_business_entity_id",
    overrideDefaultValues: {
      business_type_checkbox: { is_gaming: true, is_alcohol: true },
    },
  },
  {
    type: fieldTypes.subHeader,
    label: "Relative",
  },
  {
    type: fieldTypes.pdPersonSelect,
    entityType: "pdPerson",
    label: "Relative",
    name: "relative_pd_person_id",
    required: true,
  },
  {
    type: fieldTypes.subHeader,
    label: "Employment Details",
  },
  {
    type: fieldTypes.text,
    label: "Title/Position Held",
    name: "position",
    required: true,
  },
  {
    type: fieldTypes.subHeader,
    label: "Dates",
  },
  {
    formGroup: formGroupTypes.fromToCurrent,
  },
];

const familyEmploymentSchema = {
  type: "table",
  endpoint:
    "michigan_isbigo_supplement_family_gaming_involvement_familyGamingEmployment",
  formSchema: familyEmploymentFormSchema,
  columns: familyEmploymentColumns,
  infoAlerts: [
    {
      body: familyOwnership.employmentTableQuestion,
    },
  ],
};

const FamilyOwnershipColumns = [
  {
    field: "from_date",
    format: formats.date,
  },
  {
    field: "to_date",
    format: formats.date,
  },
  { title: "Business Name", field: "pd_business_entity.business_name" },
  {
    field: "ownership_percent",
  },
  { title: "Relative Name", field: "pd_person.full_name" },
];

const FamilyOwnershipFormSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Business Entity",
  },
  {
    type: fieldTypes.pdBusinessSelect,
    label: "Business Entity",
    endpoint: "gaming_business_entities",
    getOptionLabel: (option) => option.business_name,
    getOptionValue: (option) => option.id,
    required: true,
    name: "pd_business_entity_id",
    overrideDefaultValues: {
      business_type_checkbox: { is_gaming: true, is_alcohol: true },
    },
  },
  {
    type: fieldTypes.subHeader,
    label: "Relative",
    divider: true,
  },
  {
    type: fieldTypes.pdPersonSelect,
    entityType: "pdPerson",
    label: "Relative",
    name: "pd_person_id",
    required: true,
  },
  {
    type: fieldTypes.subHeader,
    label: "Ownership Details",
    divider: true,
  },
  {
    type: fieldTypes.checkBoxGroup,
    label: "Indicate how this person is related to the business:",
    name: "role_checkbox",
    dynamicMenuItems: ["businessInterestRoleCheckboxOptions"],
    defaultValue: null,
  },
  {
    type: fieldTypes.number,
    label: "Ownership % Held",
    name: "ownership_percent",
    format: NumberFormatTypes.percent,
    required: true,
  },
  {
    type: fieldTypes.subHeader,
    label: "Dates",
    divider: true,
  },
  {
    formGroup: formGroupTypes.fromToCurrent,
  },
];

const FamilyOwnershipSchema = {
  type: "table",
  endpoint:
    "michigan_isbigo_supplement_family_gaming_involvement_familyGamingInvolvement",
  formSchema: FamilyOwnershipFormSchema,
  columns: FamilyOwnershipColumns,
  infoAlerts: [
    {
      body: (
        <>
          Other than Employment, do any of your family members have an ownership
          interest or other association in any gaming related entity.
        </>
      ),
    },
  ],
};

export default [familyEmploymentSchema, FamilyOwnershipSchema];
