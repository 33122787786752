import React from "react";
import { tabularDataParams, columns } from "./config";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { Grid } from "@material-ui/core";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import useTabularData from "hooks/tableHooks/useTabularData";
import BDPartySelect from "components/Formik/formGroups/CrudSelect/selects/BDPartySelect";

const SubsidiaryCompaniesForm = () => {
  return (
    <Grid container spacing={2}>
      <SectionSubHeader>Political Contribution Recipient</SectionSubHeader>
      <BDPartySelect name="recipient_bd_party_id" label="Recipient" xs={6} />
    </Grid>
  );
};

const IndianaSupplierLicensePoliticalContribution = ({ name }) => {
  const subsidiaryData = useTabularData(tabularDataParams);
  return (
    <FormSectionProvider title={name}>
      <FormContextProvider formData={subsidiaryData}>
        <EnhancedFormikDialog>
          <SubsidiaryCompaniesForm />
        </EnhancedFormikDialog>
        <EnhancedActionsTable
          columns={columns}
          handleAddRecord={null}
          subheader={
            <SectionLabel>
              <strong>
                Add a recipient to any political contributions listed below
              </strong>
            </SectionLabel>
          }
        />
      </FormContextProvider>
    </FormSectionProvider>
  );
};

export default IndianaSupplierLicensePoliticalContribution;
