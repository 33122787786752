import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Credit Record",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Since the date of your last application, has your credit record been considered unsatisfactory, or have you ever been refused credit?",
    name: "pd_oklahoma_rkela.has_bad_credit",
  },
  {
    type: fieldTypes.label,
    label:
      "If you have answered YES, please provide below the dates, places, names of creditors and circumstances:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "pd_oklahoma_rkela.bad_credit_details",
    rows: 4,
    xs: 12,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "pd_oklahoma_rkela_supplement_credit_record_pdSupplement",
};
