import {
  columns,
  tableQuestions,
  endpoint,
  formSchema,
  nonRequired,
} from "./config.js";

const schema = {
  type: "table",
  endpoint,
  formSchema,
  nonRequired,
  columns,
  infoAlerts: [
    {
      body: tableQuestions,
    },
  ],
  modifySubmissionValues: (formValues) => ({
    ...formValues,
    type: "testimony",
  }),
};

export default [schema];
