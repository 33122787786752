import { Grid } from "@material-ui/core";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import { NumberFormatTypes } from "components/Common/Inputs/NumberField";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import FormikCheckBoxGroup from "components/Formik/formGroups/FormikCheckBoxGroup";
import YesNo from "components/Formik/formGroups/YesNo";
import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useForm from "hooks/useForm.js";
import React from "react";
import { defaultInitialValues } from "./config";

const IllinoisDisclosureQuestions = ({ name }) => {
  const formData = useForm({
    defaultInitialValues,
    noValidate: true,
    queryKey: "pdSupplement",
    endpoint: "illinois_disclosure_questions_pdSupplement",
  });
  const {
    il_kp_pd_license_types_options,
    il_license_relationship_options,
    il_qualification_roles_options,
  } = useMenuOptions();

  return (
    <FormSectionWrapper title={name} formData={formData}>
      <EnhancedFormikPage>
        <Grid container spacing={2}>
          <SectionLabel label="Name of affiliated Applicant or Licensee for which this form is submitted:" />
          <FormikTextField
            label="Name of Applicant"
            name="illinois.affiliated_licensee_name"
            xs={4}
          />
          <SectionSubHeader label="License Type" divider />
          <SectionLabel label="Indicate the type of License of the Applicant or Licensee for which this application will be submitted (check all that apply):" />

          <FormikCheckBoxGroup
            name="il_kp_pd_license_types_checkbox"
            options={il_kp_pd_license_types_options}
          />
          <SectionSubHeader label="Military Service Record" divider />
          <SectionLabel label="Please note: if you served in more then one Military Branch please contact us and we will provide a solution to enter the additional branches." />

          <SectionSubHeader label="Debt" divider />
          <YesNo
            label="Have you ever had any debt that was either adjusted, deferred, suspended or a payment plan was worked out to satisfy said debt?"
            name="illinois.pd_q21_a_boolean"
            xs={12}
          />
          <FormikTextField
            multiline
            rows={2}
            label="If yes, please explain"
            name="illinois.pd_q21_a_details"
            xs={8}
          />

          <SectionLabel label="Attach debt adjustment plan" />
          <FormikFileDropzone
            description="Investment Agreement for Transactions"
            name="il_debt_adjustment_plan_attachment_group_id"
            xs={12}
          />

          <SectionSubHeader label="Current Employer Addresses" />
          <SectionLabel label="Provide any additional address(es) of your current employer not previously disclosed." />
          <FormikTextField name="illinois.pd_3_details" xs={4} />

          <SectionSubHeader label="Position or Relationship Details" divider />
          <SectionLabel label="Indicate your Position or Relationship with the Applicant or Licensee (check all that apply):" />
          <FormikCheckBoxGroup
            name="il_qualification_roles_checkbox"
            options={il_qualification_roles_options}
          />
          <FormikTextField
            label="Other"
            name="illinois.other_position_text_field"
            helperText="Explain if Other"
            xs={8}
          />

          <SectionLabel label="Indicate your relationship to the License/Applicant or the parent company or substantial owner thereof for which you are submitting this Individual Key Person Disclosure Form (check all that apply):" />
          <FormikCheckBoxGroup
            name="il_license_relationship_checkbox"
            options={il_license_relationship_options}
          />
          <FormikTextField
            multiline
            rows={2}
            label="Relationship Explanation"
            name="illinois.applicant_relationship_explanation"
            xs={8}
          />
          <SectionSubHeader label="Operational Participation" divider />
          <SectionLabel label="If applicable, describe your planned or current participation in the management, or operation, or any parent company, of a holder of or Applicant for an Owners License, Supplier’s License, Organization Gaming License, Master Sports Wagering License, Management Services Provider." />
          <FormikTextField
            multiline
            label="Description of Participation"
            name="illinois.operational_participation_description"
            xs={8}
            rows={5}
          />
          <SectionSubHeader label="Investment / Ownership Interest" divider />
          <SectionLabel label="If applicable, describe your present or planned investment/ownership interest in any holder of or Applicant for an Owners License, Organization Gaming License, Master Sports Wagering License, Supplier’s License, Management Services Provider License, Tier 2 Official League Data Provider License, or in the parent company or Substantial Owner thereof." />

          <FormikNumberField
            label="Investment Amount"
            name="illinois.amount_invested_in_applicant"
            type={NumberFormatTypes.currency}
            helperText="Amount to be invested in the business"
          />
          <FormikNumberField
            label="Ownership %"
            name="illinois.percent_owned_of_applicant"
            helperText="Percentage of ownership this will represent"
            xs={4}
            type={NumberFormatTypes.percent}
          />
          <FormikTextField
            multiline
            label="List all sources the investment will be derived from"
            name="illinois.investment_sources_summary"
            xs={8}
            rows={5}
          />
          <SectionLabel label="Attach executed agreements for all financial transactions" />
          <FormikFileDropzone
            description="Investment Agreement for Transactions"
            name="il_investment_sources_attachment_group_id"
            xs={12}
          />

          <SectionSubHeader label="Potential Related Relationships" divider />
          <SectionLabel label="In addition to your relationship/position with any holder of or Applicant for an Owners License, Organization Gaming License, Master Sports Wagering License, Supplier’s License, Management Services Provider License, Tier 2 Official League Data Provider License, or the parent company or substantial owner thereof provide any potential or anticipated related party relationships with these entities (i.e. any management, lease, consulting, construction agreements, etc.).  Submit any additional information." />
          <FormikTextField
            multiline
            label="Description of Potential Related Relationships"
            name="illinois.potential_and_anticipated_relationships"
            xs={8}
            rows={5}
          />

          <SectionSubHeader
            label="Interest Assignment or Hypothecation"
            divider
          />
          <YesNo
            label={`Has any of your interest in this Gambling Operation been assigned, pledged, or hypothecated to any person, firm, or corporation, or has any agreement been entered into whereby your interest is to be assigned, pledged, or sold either in part or in whole?`}
            name="illinois.has_interest_assigned"
            // parentObject="illinois"
          />
          <SectionLabel label="If yes, upload additional information and copies of all agreements and memoranda, along with any other documentation maintained, and a detailed summary of all oral agreements concerning all of the financial transactions relating to the investment." />
          <FormikFileDropzone
            description="Financial Transaction Agreements"
            name="il_transaction_agreement_attachment_group_id"
            xs={12}
          />
          <SectionLabel label="Upload additional information and copies of all memoranda, along with any other documentation of and a detailed summary of all oral agreements relating to each assignment, pledge or agreement." />
          <FormikFileDropzone
            description="Assignment Agreements"
            name="il_assignment_agreement_attachment_group_id"
            xs={12}
          />

          <SectionSubHeader label="Expected Compensation" divider />
          <SectionLabel label="State the total amount of annual Compensation you expect to receive from the Applicant/Licensee, or from any parent company or Substantial Owners thereof (wages, bonuses, other)." />
          <FormikNumberField
            label="Total Compensation"
            name="illinois.expected_compensation_amount"
            // parentObject="illinois"
          />
          <SectionLabel label="Attach copies of any employment agreements or contracts, if applicable" />
          <FormikFileDropzone
            description="Employment Agreements or Contracts"
            name="il_employment_agreement_attachment_group_id"
            xs={12}
          />

          <SectionSubHeader label="Relative/Family Background" divider />
          <SectionLabel label="Are you or any of your Relatives a current or past member, employee, contractor (or work for a company that contracted), of the Illinois Gaming Board. If yes, provide all details, including dates, positions held, etc." />
          <FormikTextField
            multiline
            label="Details"
            name="illinois.relatives_employed_by_illinois_gaming_board"
            xs={8}
            rows={3}
          />
          <SectionLabel label="Are you or any of your Relatives a current or past member of the Illinois Legislature? If yes, provide all details, including dates, positions held, etc." />
          <FormikTextField
            multiline
            label="Details"
            name="illinois.relatives_member_illinois_legislature"
            xs={8}
            rows={3}
          />
          <SectionLabel label="State whether you or any of your Relatives:" />
          <YesNo
            label={`Have you or any of your relatives been convicted of any violation of Article 28, Gambling and Related Offenses, of theCriminal Code of 1961, 720 ILCS 5/28-1 et seq., or of similar laws of any other jurisdiction;`}
            name="illinois.has_relatives_violate_article_18"
            // parentObject="illinois"
          />
          <YesNo
            label={`Have you or any of your relatives been convicted of a misdemeanor involving dishonesty or moral turpitude under the laws ofIllinois, any other State or any other jurisdiction;`}
            name="illinois.has_relatives_convicted_of_misdemeanor"
            // parentObject="illinois"
          />
          <YesNo
            label={`Have you or any of your relatives had, or been affiliated with, a license which was withdrawn, denied, revoked, non-renewed,suspended, or disciplined, by the Illinois Gaming Board, or in any other jurisdiction, or by any other licensing authority;`}
            name="illinois.has_relatives_affiliated_with_license_condition"
            // parentObject="illinois"
          />
          <SectionLabel label="If the answer to any of the above subparagraphs is yes and to the extent not disclosed above, submit as Exhibit 19 a detailed statement concerning the nature, facts and circumstances of each conviction, denial, non-renewal, suspension, disciplinary action, or revocation of ownership interest in a license or license application, including but not limited to, dates, addresses, courts, prosecuting and arresting agencies." />
          <FormikTextField
            multiline
            label="Details"
            name="illinois.relatives_conviction_details"
            xs={8}
            rows={3}
          />

          <SectionSubHeader
            label="Tax Return Audits, Adjustments or Investigations"
            divider
          />
          <YesNo
            label={`Has your Federal Income Tax Return or any State Income Tax Return ever been audited, adjusted or criminally investigated?`}
            name="illinois.has_federal_tax_adjustment_or_criminal"
            // parentObject="illinois"
          />
          <FormikTextField
            multiline
            label="If yes, provide details."
            name="illinois.tax_adjustment_explanation"
            xs={8}
            rows={5}
          />
        </Grid>
      </EnhancedFormikPage>
    </FormSectionWrapper>
  );
};

export default IllinoisDisclosureQuestions;
