import { Auth0Provider } from "@auth0/auth0-react";
import "assets/scss/material-dashboard-pro-react.scss";
import axios from "axios";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";
import React from "react";
import { useQuery } from "react-query";

const useAuthClientId = () =>
  useQuery("authClientId", () => axios.get("/auth/getClientId"), {
    retry: 0,
    /**
     * refetched in the background every 24 hours
     *
     * IMPORTANT: client refetch interval must remain less than the auth0 session time.
     * Currently set to 3 days on auth0. If changed to less than 24hrs, client refetch interval must change.
     */
    staleTime: 1000 * 60 * 60 * 24, //24 hours
  });

const AuthWrapper = ({ children }) => {
  const { data, status } = useAuthClientId();

  return (
    <LoadingComponent status={status}>
      {data?.data ? (
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN}
          clientId={data.data}
          redirectUri={window.location.origin + "/auth/verify_login"}
          audience={`https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`}
        >
          {children}
        </Auth0Provider>
      ) : null}
    </LoadingComponent>
  );
};

export default AuthWrapper;
