import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.yesNo,
    label:
      "Have any Principal Officers, Directors, or Management Officials ever been or are they now on parole, probation, deferred or suspended sentence or supervised release?",
    name: "cherokee_vendor_license.section_7_b_bool",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Did an attorney tell any Principal Officers, Directors, or Management Officials that if anyone ever asked them if they had been convicted, they should state “no” because the sentence was deferred and charges would be dismissed?",
    name: "cherokee_vendor_license.section_7_c_bool",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Have any Principal Officers, Directors, or Management Officials ever had any records expunged or received a pardon?",
    name: "cherokee_vendor_license.section_7_d_bool",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Have any Principal Officers, Directors, or Management Officials ever been convicted of, or pled guilty, and/or nolo contendre to any criminal charge regardless of type?",
    name: "cherokee_vendor_license.section_7_e_bool",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Have any Principal Officers, Directors, or Management Officials ever been charged/convicted of any gaming offense regardless of classification as misdemeanour or felony?",
    name: "cherokee_vendor_license.section_7_f_bool",
  },
  {
    type: fieldTypes.label,
    label:
      "If you have answered Yes to any of the above questions, please provide details below. Please note applicant must declare whether or not he/she has served a deferred sentence and the status, regardless if expunged.",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "cherokee_vendor_license.section_7_details",
    xs: 12,
    rows: 4,
  },
];

export default [
  {
    type: "form",
    formSchema,
    endpoint: "bdSupplement",
  },
];
