import React from "react";
import Permission from "components/Permission/Permission";
import Button from "@material-ui/core/Button";
import styled from "styled-components";

import ConfirmationDialog from "components/Common/Dialogs/ConfirmationDialog";
import useToggle from "hooks/useToggle";
import { Box } from "@material-ui/core";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";
import usePermission from "./usePermissions";
import { useSnackbar } from "notistack";

const StyledConfirmationDialog = styled(ConfirmationDialog)`
  padding-top: 8px;
`;

const PermissionDialog = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleSnackBar = (variant) => (message) => {
    enqueueSnackbar(message, {
      variant,
    });
  };
  const { on, toggle, setOn } = useToggle();
  const { permissionState, permissionDispatch, postData } = usePermission({
    id: props.id,
  });

  return (
    <Box>
      <StyledConfirmationDialog
        title={`Set Permissions for Collaborator ${props.first_name} ${props.last_name}`}
        open={on}
        onClose={toggle}
        maxWidth="md"
        onCancel={toggle}
        onConfirm={toggle}
        divider={true}
        confirmButton={
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              postData(`account/collaborators/permissions`, {
                collaborator_account_id: props.id,
                permissions: permissionState,
              })
                .then(() => {
                  handleSnackBar("success")("Permissions Saved");
                  toggle();
                })
                .catch(() => {
                  toggle();
                  handleSnackBar("error")("Failed Saving Permission");
                });
            }}
          >
            Confirm
          </Button>
        }
      >
        <Box>
          <LoadingComponent
            status={
              permissionState?.["personal_disclosure"] ? "success" : " loading"
            }
          >
            <Permission state={permissionState} dispatch={permissionDispatch} />
          </LoadingComponent>
        </Box>
      </StyledConfirmationDialog>
      <Button variant="outlined" size="medium" onClick={setOn}>
        Set Permissions
      </Button>
    </Box>
  );
};

export default PermissionDialog;
