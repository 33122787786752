import React from "react";
import ActionsTable from "components/Common/Tables/Common";

const InlineTable = ({ columns, records, addButtonText, ...rest }) => (
  <ActionsTable
    showTableIfEmpty={false}
    columns={columns}
    records={records}
    addButtonText={addButtonText}
    isDense
    {...rest}
  />
);

export default InlineTable;
