import { MultiYearFileUploader } from "components/Common/MultiYearFileUploader/MultiYearFileUploader";
import { useField, useFormikContext } from "formik";
import React from "react";

const FormikMultiYearFileUploader = (props) => {
  const [field] = useField(props);
  const { setFieldValue } = useFormikContext();

  return (
    <MultiYearFileUploader
      {...props}
      {...field}
      setFieldValue={setFieldValue}
    />
  );
};

export default FormikMultiYearFileUploader;
