import { Container, Grid, Typography, Divider } from "@material-ui/core";
import SentryErrorBoundary from "error/SentryErrorBoundary";
import React from "react";
import { BusinessDisclosureTable } from "./BusinessDisclosureTable";
import { CollaboratorsTable } from "./CollaboratorsTable";
import {
  BusinessDisclosureDisclaimer,
  CollaboratorsDisclosureDisclaimer,
} from "./components/Disclaimers";

export default function ManageOrganization({ name }) {
  return (
    <SentryErrorBoundary>
      <Container>
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <Typography variant="h1" gutterBottom>
              {name}
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} style={{ height: "25px" }} />
          <Grid item xs={12}>
            <Typography variant="h2" gutterBottom>
              Business Disclosures
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <BusinessDisclosureDisclaimer />
          </Grid>
          <Grid item xs={12}>
            <BusinessDisclosureTable />
          </Grid>
          <Grid item xs={12} style={{ height: "25px" }} />
          <Grid item xs={12}>
            <Typography variant="h2" gutterBottom>
              Collaborators
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CollaboratorsDisclosureDisclaimer />
          </Grid>
          <Grid item xs={12}>
            <CollaboratorsTable />
          </Grid>
        </Grid>
      </Container>
    </SentryErrorBoundary>
  );
}
