//Some unused code commented out because we use this as a reference/boilerplate

import {
  columns,
  tableQuestions,
  nonRequired,
  endpoint,
  formSchema,
} from "./config";

const pageSchema = {
  type: "table",
  endpoint,
  formSchema,
  nonRequired,
  columns,
  infoAlerts: [
    {
      body: tableQuestions,
    },
  ],
  // To set default values:
  // modifySubmissionValues: (formValues) => ({
  //   ...formValues,
  //   type: "criminal",
  // }),
};

// To add additional schemas ... need to add to export below
// const secondSchema = {
//   type: "readOnlyTable",
//   endpoint: "views/bd_employees_criminal_charges",
//   infoAlert: employeesCriminalChargesQuestions,
//   columns: [
//     {
//       title: "Party Name",
//       field: "person_name",
//     },
//     etc ....
//   ],
// };

//add addtional schemas to export array
export default [pageSchema];
