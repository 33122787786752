import { Grid, Divider } from "@material-ui/core";
import {
  SectionSubHeader,
  SectionLabel,
} from "components/Common/LayoutComponents";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikGoogleLookup from "components/Formik/fields/FormikGoogleLookup";
import FormikSelect from "components/Formik/fields/FormikSelect";
import FormikTextField from "components/Formik/fields/FormikTextField";
import YesNo from "components/Formik/formGroups/YesNo.jsx";
import React from "react";
import FormikCheckBoxGroup from "components/Formik/formGroups/FormikCheckBoxGroup";
import FormikAsyncSelect from "components/Formik/fields/FormikAsyncSelect";
import { FormikAddressFields } from "components/Formik/formGroups/FormikAddressFields";
import FormikCheckBox from "components/Formik/fields/FormikCheckBox";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import GenerateAlertEdit from "components/Common/Alerts/GenerateAlertEdit";
import { DependantDatePicker } from "components/Formik/formGroups/FromToCurrent";
import PDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/PDPersonSelect.jsx";
import BusinessAssociation from "components/Common/BusinessAssociation/BusinessAssociation";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import PDLawEnforcementSelect from "components/Formik/formGroups/CrudSelect/selects/PDLawEnforcementSelect.jsx";
import PDBusinessSelect from "components/Formik/formGroups/CrudSelect/selects/PDBusinessSelect.jsx";
import PDFinancialSelect from "components/Formik/formGroups/CrudSelect/selects/PDFinancialSelect.jsx";
import PDLicenseAgencySelect from "components/Formik/formGroups/CrudSelect/selects/PDLicenseAgencySelect.jsx";
import PDPartySelect from "components/Formik/formGroups/CrudSelect/selects/PDPartySelect.jsx";
import BDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/BDPersonSelect.jsx";
import BDBusinessSelect from "components/Formik/formGroups/CrudSelect/selects/BDBusinessSelect.jsx";
import BDLicenseAgencySelect from "components/Formik/formGroups/CrudSelect/selects/BDLicenseAgencySelect.jsx";
import BDFinancialSelect from "components/Formik/formGroups/CrudSelect/selects/BDFinancialSelect.jsx";
import BDBusinessFamilySelect from "components/Formik/formGroups/CrudSelect/selects/BDBusinessFamilySelect.jsx";
import BDGovernmentAgencySelect from "components/Formik/formGroups/CrudSelect/selects/BDGovernmentAgencySelect.jsx";
import BDPartySelect from "components/Formik/formGroups/CrudSelect/selects/BDPartySelect.jsx";
import BDLawEnforcementSelect from "components/Formik/formGroups/CrudSelect/selects/BDLawEnforcementSelect.jsx";
import PDRelativeSelect from "components/Formik/formGroups/CrudSelect/selects/PDRelativeSelect";
import PDGamingSelect from "components/Formik/formGroups/CrudSelect/selects/PDGamingSelect";

export const getComponent = (type, props) => {
  switch (type) {
    case fieldTypes.text:
      return <FormikTextField {...props} />;
    case fieldTypes.yesNo:
      return <YesNo {...props} />;
    case fieldTypes.select:
      return <FormikSelect {...props} />;
    case fieldTypes.asyncSelect:
      return <FormikAsyncSelect {...props} />;
    case fieldTypes.date:
      return <FormikDatePicker {...props} />;
    case fieldTypes.number:
      return <FormikNumberField {...props} />;
    case fieldTypes.googleLookup:
      return (
        <Grid item xs={12} key={props.key}>
          <FormikGoogleLookup {...props} />
        </Grid>
      );
    case fieldTypes.checkBoxGroup:
      return <FormikCheckBoxGroup {...props} />;
    case fieldTypes.checkBox:
      return <FormikCheckBox {...props} />;
    case fieldTypes.addressFields:
      return (
        <FormikAddressFields addressPrefix={props.name + "."} {...props} />
      );

    case fieldTypes.dependantDatePicker:
      return <DependantDatePicker {...props} />;
    case fieldTypes.businessAssociation:
      return <BusinessAssociation {...props} />;

    //PD Selects
    case fieldTypes.pdBusinessSelect:
      return <PDBusinessSelect {...props} />;
    case fieldTypes.pdFinancialSelect:
      return <PDFinancialSelect {...props} />;
    case fieldTypes.pdLawEnforcementSelect:
      return <PDLawEnforcementSelect {...props} />;
    case fieldTypes.pdLicenseAgencySelect:
      return <PDLicenseAgencySelect {...props} />;
    case fieldTypes.pdPartySelect:
      return <PDPartySelect {...props} />;
    case fieldTypes.pdPersonSelect:
      return <PDPersonSelect {...props} />;
    case fieldTypes.pdGamingSelect:
      return <PDGamingSelect {...props} />;
    case fieldTypes.pdRelativeSelect:
      return <PDRelativeSelect {...props} />;

    //BD Selects
    case fieldTypes.bdPersonSelect:
      return <BDPersonSelect {...props} />;
    case fieldTypes.bdBusinessSelect:
      return <BDBusinessSelect {...props} />;
    case fieldTypes.bdLicenseAgencySelect:
      return <BDLicenseAgencySelect {...props} />;
    case fieldTypes.bdFinancialSelect:
      return <BDFinancialSelect {...props} />;
    case fieldTypes.bdBusinessFamilySelect:
      return <BDBusinessFamilySelect {...props} />;
    case fieldTypes.bdGovernmentAgencySelect:
      return <BDGovernmentAgencySelect {...props} />;
    case fieldTypes.bdPartySelect:
      return <BDPartySelect {...props} />;
    case fieldTypes.bdLawEnforcementSelect:
      return <BDLawEnforcementSelect {...props} />;

    // non-fields
    case fieldTypes.subHeader:
      return <SectionSubHeader {...props} />;
    case fieldTypes.label:
      return <SectionLabel {...props} />;
    case fieldTypes.divider:
      return (
        <Grid item xs={12} key={props.key}>
          <Divider {...props} />
        </Grid>
      );
    case fieldTypes.gridItem:
      return <Grid item {...props} data-testid="grid-item" />;

    case fieldTypes.dropZone:
      return <FormikFileDropzone {...props} />;

    case fieldTypes.alertEdit:
      return <GenerateAlertEdit {...props} />;
    default:
      return null;
  }
};
