import { Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import PDBusinessSelect from "components/Formik/formGroups/CrudSelect/selects/PDBusinessSelect.jsx";
import PDLawEnforcementSelect from "components/Formik/formGroups/CrudSelect/selects/PDLawEnforcementSelect.jsx";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import {
  columns,
  defaultInitialValues,
  endpoint,
  tableQuestions,
} from "./config.js";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Court" />
      <PDLawEnforcementSelect label="Court" />

      <SectionSubHeader label="Obligation Holder" />
      <PDBusinessSelect
        label="Obligation Holder"
        name="debt_holder_pd_business_entity_id"
      />

      <SectionSubHeader label="Details" divider />
      <FormikDatePicker label="Filing Date" name="filing_date" xs={4} />
      <FormikTextField label="Case Number" name="case_number" xs={4} />
      <FormikNumberField label="Obligation Amount" name="amount" xs={4} />
      <FormikTextField label="Nature of Obligation" name="nature" xs={8} />
    </Grid>
  );
};

const WageGarnishments = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint,
    nonRequired: [
      "law_enforcement_pd_business_entity_id",
      "debt_holder_pd_business_entity_id",
    ],
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default WageGarnishments;
