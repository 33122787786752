import PropTypes from "prop-types";
import FormikCheckBox from "components/Formik/fields/FormikCheckBox";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import { useFormikContext } from "formik";
import React from "react";
import { Box } from "@material-ui/core";

export const DependantDatePicker = ({
  dependsOn = "is_current",
  name,
  setValue = "11/11/2600",
  ...rest
}) => {
  const { values, setFieldValue } = useFormikContext();
  const dependOnValue = values?.[dependsOn];

  React.useEffect(() => {
    if (dependOnValue) setFieldValue(name, setValue);
    else if (values?.[name] === setValue) setFieldValue(name, null);
    else setFieldValue(name, values?.[name]);
  }, [dependOnValue, setFieldValue, name, setValue, values]);

  return <FormikDatePicker {...rest} name={name} disabled={dependOnValue} />;
};

DependantDatePicker.propTypes = {
  dependsOn: PropTypes.string,
  name: PropTypes.string.isRequired,
  setValue: PropTypes.string,
};

const FromToCurrent = ({ fromProps, toProps, ...rest }) => {
  return (
    <>
      <FormikDatePicker
        label="From"
        name="from_date"
        xs={4}
        {...fromProps}
        {...rest}
      />
      <DependantDatePicker
        label="To"
        name="to_date"
        xs={4}
        {...toProps}
        {...rest}
      />
      <Box pl={1} display="flex" justifyContent="center">
        <FormikCheckBox label="Is current" name="is_current" />
      </Box>
    </>
  );
};

FromToCurrent.propTypes = {
  fromProps: PropTypes.object,
  toProps: PropTypes.object,
};

export default FromToCurrent;
