import PropTypes from "prop-types";
import FormikTextField from "components/Formik/fields/FormikTextField";
import React from "react";
import { Grid } from "@material-ui/core";
import FormikSelect from "components/Formik/fields/FormikSelect";
import { useMenuOptions } from "hooks/useMenuOptions";

export const address = {
  street: "",
  city: "",
  state: "",
  postal_code: "",
  country: "",
  county: "",
};

export const FormikAddressFields = ({ addressPrefix = "address." }) => {
  const { listOfCountries } = useMenuOptions();
  return (
    <Grid container item spacing={1}>
      <FormikTextField
        label="Street"
        name={`${addressPrefix}street`}
        xs={12}
        md={5}
      />
      <FormikTextField
        label="City"
        name={`${addressPrefix}city`}
        xs={12}
        md={4}
      />
      <FormikTextField
        label="Zip/Postal"
        name={`${addressPrefix}postal_code`}
        xs={12}
        md={3}
      />
      <FormikTextField
        label="State"
        name={`${addressPrefix}state`}
        xs={12}
        md={4}
      />
      <FormikSelect
        label="Country"
        options={listOfCountries}
        name={`${addressPrefix}country`}
        xs={12}
        md={4}
      />
      <FormikTextField
        label="County"
        name={`${addressPrefix}county`}
        xs={12}
        md={4}
      />
    </Grid>
  );
};

FormikAddressFields.propTypes = {
  addressPrefix: PropTypes.string,
};
