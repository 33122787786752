import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { EmployeeCompensationForm } from "./EmployeeCompensationForm";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";

const EmployeeCompensation = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "bdPosition?related_bd_party_role=employee",
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog maxWidth="md" title="Details">
        <EmployeeCompensationForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
    </FormSectionProvider>
  );
};

export default EmployeeCompensation;
