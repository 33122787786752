import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import {
  Paragraph,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import CustomLink from "components/Common/Link.js";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone.jsx";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm";
import React from "react";
import { defaultInitialValues } from "./config.js";
import useEndPoint from "hooks/useEndpoint.js";

const Form = () => {
  return (
    <Grid container spacing={1}>
      <SectionSubHeader style={{ fontSize: "20px" }} label="Mandatory" />
      <SectionSubHeader label="Commonwealth business Description" divider />
      <Paragraph>
        <>
          Description of the business currently performed and the business
          intended to be performed in the Commonwealth. Additionally, applicant
          must indicate the relationship between it and its affiliated entities
          as it relates to the business intended to be performed in the
          Commonwealth in the form of an organization chart with a narrative
          description. Upload your documents in
          <CustomLink to="bd_corporate_documents" />
        </>
      </Paragraph>
      <SectionSubHeader label="Audited financial statements" />
      <Paragraph>
        <>
          Audited financial statements for the last five (5) years. If the
          Applicant does not normally have its financial statements audited,
          attach unaudited financial statements. Upload your documents in
          <CustomLink to="bd_financial_statements" />
        </>
      </Paragraph>
      <SectionSubHeader label="Annual Reports" />
      <Paragraph>
        <>
          Annual reports for the last five (5) years. Upload your documents in
          <CustomLink to="bd_financial_statements" />
        </>
      </Paragraph>
      <SectionSubHeader label="SEC’s 10K - Annual reports" />
      <Paragraph>
        <>
          Annual reports prepared on the SEC’s 10K for the last five (5) years.
          Upload your documents in
          <CustomLink to="bd_financial_statements" />
        </>
      </Paragraph>
      <SectionSubHeader label="quarterly financial statement" />
      <Paragraph>
        <>
          A copy of the last quarterly unaudited financial statement. Upload
          your documents in
          <CustomLink to="bd_financial_statements" />
        </>
      </Paragraph>
      <SectionSubHeader label="Proxy or information statement (SEC)" />
      <Paragraph>
        <>
          A copy of the last definitive Proxy or information statement (SEC).
          Upload your doucment in
          <CustomLink to="bd_financial_statements" />
        </>
      </Paragraph>
      <SectionSubHeader label="registration statements" />
      <Paragraph>
        <>
          A copy of all registration statements for the last five (5) years
          filed in accordance with the Securities Act of 1933. Upload your
          doucment in
          <CustomLink to="bd_financial_statements" />
        </>
      </Paragraph>
      <SectionSubHeader label="other reports" />
      <Paragraph>
        <>
          Copies of all other reports prepared in the last five (5) years by
          independent auditors of the applicant. Upload your doucment in
          <CustomLink to="bd_financial_statements" />
        </>
      </Paragraph>
      <SectionSubHeader label="ownership table" />
      <Paragraph>
        <>
          Current ownership table of organization. Provide in chart format
          illustrating ownership from applicant to shareholders. Upload your
          doucment in
          <CustomLink to="bd_corporate_documents" />
        </>
      </Paragraph>
      <SectionSubHeader label="management diagrams" />
      <Paragraph>
        <>
          Functional table of organization for applicant, with job descriptions.
          In addition, provide the names of employees earning in excess of
          $250,000 in annual compensation. Upload your doucment in
          <CustomLink to="bd_corporate_documents" />
        </>
      </Paragraph>
      <SectionSubHeader label="business-related tax forms" />
      <Paragraph>
        <>
          Copies of 1120 forms, 941 forms and all other business-related tax
          forms filed with the IRS in the last five (5) years. Upload your
          doucment in <CustomLink to="bd_tax_returns" />
        </>
      </Paragraph>
      <SectionSubHeader label="5500 forms filed with IRS" />
      <Paragraph>
        <>
          Copies of 5500 forms filed with the IRS in the last five (5) years.
          Upload your doucment in
          <CustomLink to="bd_tax_returns" />
        </>
      </Paragraph>
      <SectionSubHeader label="criminal history" />
      <Paragraph>
        <>
          Describe criminal history of applicant. This information must be
          provided in addition to the information provided in Schedule 23.
          Narrative information about the nature of charge or complaint and the
          disposition must be provided. Upload your doucment in
          <CustomLink to="bd_criminal_proceedings" />
        </>
      </Paragraph>
      <SectionSubHeader
        style={{ fontSize: "20px" }}
        label="Manufacturer Applicants Only"
      />
      <SectionSubHeader label="remuneration" divider />
      <Paragraph>
        <>
          If the applicant pays any royalties, has a revenue share or other
          ongoing remuneration for the use of any games or other software which
          will be used in Pennsylvania gaming operations provide a copy of the
          agreement. Upload your doucment in
          <CustomLink to="bd_contracts" />
        </>
      </Paragraph>
      <SectionSubHeader
        style={{ fontSize: "20px" }}
        label="Supplier Applicants Only"
      />
      <SectionSubHeader label="Slot Machine Sales Financial Details" divider />
      <Paragraph>
        <>
          Provide, at the time of licensure, a list of assets or available lines
          of credit to support the sale, financing, servicing and repair of all
          slot machines to be placed in service by the supplier. The assets and
          available lines of credit shall be from a source independent of slot
          machine manufacturers and licensed gaming entities. Notwithstanding
          the foregoing, a licensed manufacturer may extend financing or payment
          terms to a licensed supplier, at prevailing market rates and terms,
          for the acquisition or leasing of slot machines, to be secured by the
          slot machines sold, leased or transferred.
        </>
      </Paragraph>
      <FormikFileDropzone
        label="Slot Machine Sale Support"
        description="Slot Machine Sale Support"
        name="slot_machine_sale_support_attachment_group_id"
        xs={12}
      />
      <SectionSubHeader label="Board Review and Business Plans" />
      <Paragraph>
        <>
          Submit to the Board for review any agreements with a licensed
          manufacturer or with a licensed gaming entity and detailed business
          plans. The review may include, but not be limited to, all financing
          arrangements, inventory requirements, warehouse requirements,
          warehouse space, technical competency, compensative agreements and
          other terms or conditions to ensure the financial independence of the
          licensed supplier from the licensed manufacturer and licensed gaming
          entity. This shall not be construed to require that a manufacturer and
          a supplier enter either an exclusive or non-exclusive contractual
          agreement.
        </>
      </Paragraph>
      <FormikFileDropzone
        label="Supplier Board Review"
        description="Supplier Board Review"
        name="supplier_board_review_attachment_group_id"
        xs={12}
      />
      <SectionSubHeader
        style={{ fontSize: "20px" }}
        label="Interactive Gaming Operator Applicants Only"
      />
      <SectionSubHeader label="significant service providers details" divider />
      <Paragraph>
        <>
          Provide a list of significant service providers whose goods or
          services will be integrated into the platform or on which you will
          otherwise rely to facilitate online gambling operations and a detailed
          description of the services they will provide. Detailed information
          shall include the specific operational responsibilities of the service
          provider and the nature of the economic relationship with those
          service providers. Upload your documents in
          <CustomLink to="bd_contracts" /> for each significant service
          provider.
        </>
      </Paragraph>
      <SectionSubHeader label="Interactive Gaming Platform Diagram" />
      <Paragraph>
        <>
          Provide a detailed description and diagram of the technical protocols
          and parameters of the interactive gaming platform proposed to be
          utilized as well as the location of the hardware utilized.
        </>
      </Paragraph>
      <FormikFileDropzone
        label="Interactive Gaming Platform Diagram"
        description="Interactive Gaming Platform Diagram"
        name="interactive_gaming_platform_diagram_attachment_group_id"
        xs={12}
      />
      <SectionSubHeader label="cyber security protocols" />
      <Paragraph>
        <>
          Provide a description of the security protocols that will be utilized
          to protect the system and player data from cyber threats as well as
          the disaster recovery plan for the gaming platform.
        </>
      </Paragraph>
      <FormikFileDropzone
        label="Cyber Security Protocols"
        description="Cyber Security Protocols"
        name="cyber_security_protocols_attachment_group_id"
        xs={12}
      />
      <SectionSubHeader label="interactive games proposals" />
      <Paragraph>
        <>
          Provide a listing of the interactive games the applicant proposes to
          make available and the company or companies that will be providing the
          games. The listing should include a brief description and a summary of
          the ownership rights of the intellectual property and the location of
          the remote gaming server(s).
        </>
      </Paragraph>
      <FormikFileDropzone
        label="Interactive Games Proposal"
        description="Interactive Games Proposal"
        name="interactive_games_proposal_attachment_group_id"
        xs={12}
      />
      <SectionSubHeader label="interactive gaming certificate holder" />
      <Paragraph>
        <>
          Provide any contract with the interactive gaming certificate holder.
          If a contract has not been finalized, provide a copy of the
          draft/proposed contract. If a draft/proposed contract is provided with
          this form, the finalized contract must also be provided to the Bureau
          of Licensing within 10 days of being signed. Upload your doucment in
          <CustomLink to="bd_contracts" />
        </>
      </Paragraph>
      <SectionSubHeader
        style={{ fontSize: "20px" }}
        label="Interactive Gaming Manufacturer Applicants Only"
      />
      <SectionSubHeader
        label="ownership of any intellectual property"
        divider
      />
      <Paragraph>
        <>
          Provide details on the ownership of any intellectual property for
          games or other products that will be offered or used to facilitate
          online gaming in the Commonwealth. If a contract or agreement exists,
          a copy must be provided as part of this appendix.
        </>
      </Paragraph>
      <FormikFileDropzone
        label="Manufacturer Ownership Intellectual Property"
        description="Manufacturer Ownership Intellectual Property"
        name="intellectual_property_ownership_attachment_group_id"
        xs={12}
      />
      <SectionSubHeader label="gaming servers or studio location" />
      <Paragraph>
        <>
          Provide a detailed list of where remote gaming servers or studio (for
          live gaming studios) are or will be located. Provide information
          regarding the owner and address of the property. If there is an
          agreement or contract between the property and/or building owner and
          the applicant a copy must be provided as part of this appendix.
        </>
      </Paragraph>
      <FormikFileDropzone
        label="Manufacturer Gaming Servers Studio Location"
        description="Manufacturer Gaming Servers Studio Location"
        name="gaming_servers_studio_location_attachment_group_id"
        xs={12}
      />
      <SectionSubHeader
        style={{ fontSize: "20px" }}
        label="VGT Terminal Operator Applicants Only"
      />
      <SectionSubHeader label="agreements preliminary or final" divider />
      <Paragraph>
        <>
          Provide a copy of any agreement, preliminary or final, entered into
          with an entity applying for or holding an Establishment license in
          Pennsylvania. Agreements prepared after the application has been
          submitted must be provided to the Bureau of Licensing within 10 days
          of being signed.
        </>
      </Paragraph>
      <FormikFileDropzone
        label="VGT Agreements"
        description="VGT Agreements"
        name="vgt_agreements_attachment_group_id"
        xs={12}
      />
      <SectionSubHeader label="VGT maintenance and operation plan" />
      <Paragraph>
        <>
          Provide a description of your plan for the maintenance and operation
          of video gaming terminals in Pennsylvania. Include the names and roles
          of any third-party entities who will be involved in the maintenance
          and operation of the video gaming terminals in Pennsylvania. In
          addition, provide a narrative of how your plan for the maintenance and
          operation of video gaming terminals in Pennsylvania compares to video
          gaming terminal operations in other jurisdictions in which you
          operate. In addition, provide an overview of how the terminal operator
          will oversee the following:
          <ol>
            <li>VGT accounting system overview</li>
            <li>VGT drop and count/sensitive key controls</li>
            <li>
              Customer service issues, backup procedures for payouts (RT down,
              ticket jam, paper out, credit meter dispute)
            </li>
            <li>Surveillance overview</li>
            <li>Ticket redemption machine drops/fills</li>
            <li>
              Ticketing system – tickets only redeemable at same establishment,
              procedure for escheatment to PA Treasury (see Article XIII.I of
              The Fiscal Code 72 P. S. §§ 1301.1-- 1301.28a)
            </li>
            <li>Underage access signage and monitoring</li>
            <li>Revenue audit – cash logistics control</li>
          </ol>
        </>
      </Paragraph>
      <FormikFileDropzone
        label="VGT Maintenance Operation Plan"
        description="VGT Maintenance Operation Plan"
        name="vgt_maintenance_operation_plan_attachment_group_id"
        xs={12}
      />
      <SectionSubHeader label="VGT Competency" />
      <Paragraph>
        <>
          Provide a narrative description explaining how the applicant possesses
          sufficient business ability and experience to create and maintain
          successful and efficient video gaming terminal operations in
          Pennsylvania including a list of key executives who will have a role
          in Pennsylvania operations and the executive’s business experience.
        </>
      </Paragraph>
      <FormikFileDropzone
        label="VGT Competency"
        description="VGT Competency"
        name="vgt_competency_attachment_group_id"
        xs={12}
      />
      <SectionSubHeader label="terminal operator licensee" />
      <Paragraph>
        <>
          A terminal operator licensee must have or will establish a place of
          business in this Commonwealth. Provide details regarding this
          location.
        </>
      </Paragraph>
      <FormikFileDropzone
        label="VGT Terminal Operator Licensee"
        description="VGT Terminal Operator Licensee"
        name="vgt_terminal_operator_licensee_attachment_group_id"
        xs={12}
      />
      <SectionSubHeader label="licensure held by any procurement agent" />
      <Paragraph>
        <>
          Provide the name, address, phone number, and type of licensure held by
          any procurement agent that will be used by the VGT Terminal Operator.
        </>
      </Paragraph>
      <FormikFileDropzone
        label="VGT Licensure Held Procurement Agent"
        description="VGT Licensure Held Procurement Agent"
        name="vgt_licensure_held_procurement_agent_attachment_group_id"
        xs={12}
      />
      <SectionSubHeader
        style={{ fontSize: "20px" }}
        label="VGT Manufacturer Applicants Only"
      />
      <SectionSubHeader label="Summary Work Work" divider />
      <Paragraph>
        <>
          Provide a summary of the work to be performed with respect to
          Pennsylvania including the type of video gaming terminals, redemption
          terminals or associated equipment to be manufactured or repaired.
        </>
      </Paragraph>
      <FormikFileDropzone
        label="VGTM Summary Work Work"
        description="VGTM Summary Work Work"
        name="vgtm_work_summary_agent_attachment_group_id"
        xs={12}
      />
      <SectionSubHeader label="Maintenance Plan" />
      <Paragraph>
        <>
          Provide a description of your plan for the maintenance of video gaming
          terminals in Pennsylvania. Include the names and roles of any
          third-party entities who will be involved in the maintenance of the
          video gaming terminals in Pennsylvania.
        </>
      </Paragraph>
      <FormikFileDropzone
        label="VGTM Maintenance Plan"
        description="VGTM Maintenance Plan"
        name="vgtm_maintenance_plan_attachment_group_id"
        xs={12}
      />
      <SectionSubHeader
        style={{ fontSize: "20px" }}
        label="Fantasy Contest Operator Applicants Only"
      />
      <SectionSubHeader label="Commonwealth Business Plan" divider />
      <Paragraph>
        <>
          A fantasy contest operator licensee must maintain an office or place
          of business within this Commonwealth. The location must serve as a
          place for service of process in the Commonwealth as well as a place
          for the Board to be able to readily review documents, records and
          databases should the need arise without traveling out of state.
          Provide a detailed description of how the fantasy contest operator
          intends to meet these requirements.
        </>
      </Paragraph>
      <FormikFileDropzone
        label="Commonwealth Business Plan"
        description="Commonwealth Business Plan"
        name="fco_plan_attachment_group_id"
        xs={12}
      />
      <SectionSubHeader label="procedures Plan" />
      <Paragraph>
        <>
          4 Pa. C.S. § 325(1-18) requires as a condition of licensure that each
          fantasy contest operator establish and implement procedures to govern
          the conduct of fantasy contests in this Commonwealth. Provide plans,
          procedures, controls and any other necessary information for how the
          applicant will meet each of the specified requirements. The response
          should be numbered 1 through 18 and address each item with
          specificity.
        </>
      </Paragraph>
      <FormikFileDropzone
        label="FCO Procedures Plan"
        description="FCO Procedures Plan"
        name="fco_procedures_plan_attachment_group_id"
        xs={12}
      />
      <SectionSubHeader label="agreements terms and conditions" />
      <Paragraph>
        <>
          A copy of any agreement or agreements the applicant has entered into
          or a detailed description of the terms and conditions of any planned
          agreement with a facility to operate or conduct fantasy contests.
        </>
      </Paragraph>
      <FormikFileDropzone
        label="FCO Agreements Terms Conditions"
        description="FCO Agreements Terms Conditions"
        name="fco_agreements_terms_conditions_attachment_group_id"
        xs={12}
      />
      <SectionSubHeader
        style={{ fontSize: "20px" }}
        label="Sports Wagering Operator Applicants Only"
      />
      <SectionSubHeader label="Agreements Terms and Conditions" divider />
      <Paragraph>
        <>
          A copy of any agreement or agreements the applicant has entered into
          or a detailed description of the terms and conditions of any planned
          agreement with a facility to operate or conduct sports wagering.
        </>
      </Paragraph>
      <FormikFileDropzone
        label="SWO Agreements Terms Conditions"
        description="SWO Agreements Terms Conditions"
        name="swo_agreements_terms_conditions_attachment_group_id"
        xs={12}
      />
      <SectionSubHeader label="Agreements Third-Party Risk Management" />
      <Paragraph>
        <>
          A copy of any agreement or agreements the applicant has entered into
          or a detailed description of the terms and conditions of any planned
          agreement with a third-party integrity and risk monitoring provider
          and/or sports wagering data provider.
        </>
      </Paragraph>
      <FormikFileDropzone
        label="SWO Agreements Third Party Risk Management"
        description="SWO Agreements Third Party Risk Management"
        name="swo_agreements_risk_managment_attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};

const PennEEARequiredAttachments = ({ name }) => {
  const { bdBusinessEntityEndpoint } = useEndPoint("");
  const formData = useForm({
    endpoint: bdBusinessEntityEndpoint,
    defaultInitialValues,
  });

  return (
    <FormSectionProvider formData={formData} title={`${name}`}>
      <EnhancedFormikPage>
        <Form />
      </EnhancedFormikPage>
    </FormSectionProvider>
  );
};

export default PennEEARequiredAttachments;
