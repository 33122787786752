import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  usePdfDispatch,
  usePdfState,
  ACTIONS,
} from "components/DevTools/PdfTools/Context/PdfToolsProvider.jsx";

export default function FieldTypeSelector() {
  const dispatch = usePdfDispatch();
  const { checkTagType } = usePdfState();

  const setType = (e) =>
    dispatch({
      type: ACTIONS.SET_STATE,
      value: e.target.value,
      name: "checkTagType",
    });
  return (
    <div>
      <RadioGroup
        row
        value={checkTagType}
        onChange={setType}
        defaultValue="text"
      >
        <FormControlLabel
          value="text"
          control={<Radio color="primary" />}
          label="Text"
          labelPlacement="start"
        />
        <FormControlLabel
          value="checkbox"
          control={<Radio color="primary" />}
          label="Checkbox"
          labelPlacement="start"
        />
        <FormControlLabel
          value="radio"
          control={<Radio color="primary" />}
          label="Radio"
          labelPlacement="start"
        />
      </RadioGroup>
    </div>
  );
}
