import { Grid, Box } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm";
import React from "react";
import { defaultInitialValues, questions } from "./config.js";
import { InfoAlert } from "../../../../Layout/Alert/InfoAlert";
import YesNo from "components/Formik/formGroups/YesNo";

const FinancialQuestions = ({ name }) => {
  const formData = useForm({
    defaultInitialValues,
    endpoint: "financial_questions_pdFinancialQuestions",
  });

  return (
    <FormSectionWrapper title={name} formData={formData}>
      <EnhancedFormikPage>
        <>
          <Box pb={2}>
            <InfoAlert>
              During the last 10-year period, have you held a 5% or greater
              interest in or been a director, officer or principal employee of
              any entity that:
            </InfoAlert>
          </Box>
          <Grid container spacing={2}>
            {questions.map(({ label, name }) => (
              <YesNo key={name} label={label} name={name} xs={12} />
            ))}
          </Grid>
        </>
      </EnhancedFormikPage>
    </FormSectionWrapper>
  );
};

export default FinancialQuestions;
