import ControlledAutocomplete from "components/Common/ControlledAutocomplete/ControlledAutoComplete";
import { FormikDialog } from "components/Formik/formContainers/FormikDialog";
import { useCrudSelect } from "components/Formik/formGroups/CrudSelect/hooks/useCrudSelect";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import * as yup from "yup";
import { LicenceAgenciesForm } from "../LicenseAgencies/LicenceAgencies";
import { LicenseTypesForm } from "../LicenseTypes/LicenseTypes";
import {
  requiredString,
  requiredNumber,
} from "helpers/validationSchemaGenerator";

export const typeValidationSchema = yup.object().shape({
  name: requiredString,
  lc_licensing_agency_id: requiredNumber,
  category: requiredString,
});

export const licenseAgencyValidation = yup.object().shape({
  agency_name: requiredString,
});

export const LicenseAgencyAndType = ({ category }) => {
  const { values, setFieldValue } = useFormikContext();
  const licenseAgencyId = values.lc_license_type.lc_licensing_agency_id;
  const hasLicenseAgencySelected = typeof licenseAgencyId === "number";

  const licenseAgencyProps = useCrudSelect({
    name: "lc_license_type.lc_licensing_agency_id",
    endpoint: "licensing/lcLicensingAgency",
    defaultInitialValues: { agency_name: "" },
    getOptionLabel: ({ agency_name }) => agency_name,
  });

  const licenseTypeProps = useCrudSelect({
    name: "lc_license_type_id",
    endpoint: `licensing/lcLicenseType?category=${category}&lc_licensing_agency_id=${licenseAgencyId}`,
    defaultInitialValues: {
      name: "",
      lc_licensing_agency_id: null,
      category: category,
    },
    fetchOptions: {
      enabled: !!hasLicenseAgencySelected,
    },
  });

  const onChangeAgency = (event, newValue) => {
    licenseAgencyProps.fieldProps.onChange(event, newValue);
    setFieldValue("lc_license_type_id", null);
    licenseTypeProps.setDropDownValue();
  };

  const getLicenseTypeInitialValues = React.useCallback(
    () => ({
      ...licenseTypeProps.formProps.initialValues,
      lc_licensing_agency_id: licenseAgencyId,
    }),
    [licenseTypeProps.formProps.initialValues, licenseAgencyId]
  );

  return (
    <>
      <FormikDialog
        title="Add/Edit License Agency"
        {...licenseAgencyProps.formProps}
        validationSchema={licenseAgencyValidation}
      >
        <LicenceAgenciesForm />
      </FormikDialog>
      <FormikDialog
        title="Add/Edit License Type"
        {...licenseTypeProps.formProps}
        initialValues={getLicenseTypeInitialValues()}
        validationSchema={typeValidationSchema}
      >
        <LicenseTypesForm />
      </FormikDialog>

      <ControlledAutocomplete
        {...licenseAgencyProps.fieldProps}
        label="License Agency"
        onChange={onChangeAgency}
      />
      <ControlledAutocomplete
        {...licenseTypeProps.fieldProps}
        label="License Type"
        disabled={!hasLicenseAgencySelected}
        helperText={!hasLicenseAgencySelected && "Select License Agency First"}
      />
    </>
  );
};

LicenseAgencyAndType.propTypes = {
  category: PropTypes.oneOf(["personal", "business"]).isRequired,
};
