import styled from "styled-components";
import { TreeItem } from "@material-ui/lab";
import { Box, Divider, Typography } from "@material-ui/core";

const StyledTreeItemLeaf = styled(TreeItem)`
  padding: 4px;
`;

const StyledTreeItemTopLevel = styled(TreeItem)`
  padding: 4px;
`;

const StyledTreeItemMidLevel = styled(TreeItem)`
  padding: 4px;
`;

const StyledDivider = styled(Divider)`
  margin: 5px;
`;

const ActionsBox = styled(Box)`
  display: flex;
  align-items: center;
`;

const CustomTypography = styled(Typography)`
  width: 50%;
`;

export {
  StyledTreeItemLeaf,
  StyledTreeItemTopLevel,
  StyledTreeItemMidLevel,
  StyledDivider,
  ActionsBox,
  CustomTypography,
};
