import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { SectionSubHeader } from "components/Common/LayoutComponents/index.js";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikTextField from "components/Formik/fields/FormikTextField.jsx";
import FromToCurrent from "components/Formik/formGroups/FromToCurrent.jsx";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import {
  columns,
  defaultInitialValues,
  endpoint,
  tableQuestions,
} from "./config.js";
import BDBusinessFamilySelect from "components/Formik/formGroups/CrudSelect/selects/BDBusinessFamilySelect";
import BDBusinessSelect from "components/Formik/formGroups/CrudSelect/selects/BDBusinessSelect";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <BDBusinessSelect
        name="trust_bd_business_entity_id"
        label="Trust"
        endpoint="bdBusinessEntity?form_of_business=trust"
        additionalInitialValues={{ form_of_business: "trust" }}
        xs={6}
      />
      <BDBusinessFamilySelect name="bd_business_entity_id" />

      <SectionSubHeader label="Date's associate" divider />
      <FromToCurrent />
      <SectionSubHeader label="Description of trust" />
      <FormikTextField
        multiline
        rows={4}
        label="Description of Trust"
        name="description"
        xs={12}
      />
    </Grid>
  );
};

const BDTrust = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint,
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default BDTrust;
