import React from "react";

export const columns = [
  {
    title: "Applicant Party",
    field: "party_name",
  },
  {
    title: "Other Party",
    field: "related_party_name",
  },
  {
    title: "Original Amount",
    field: "amount_original",
    format: "currency",
  },
  {
    title: "Date",
    field: "from_date",
    format: "date",
  },
  {
    title: "Position",
    field: "position",
  },
];

export const tableQuestions = (
  <>
    During the last ten (10) years has the Applicant, its parent company,
    subsidiary, related companies or any director, employees or third parties of
    the Company performed the following transactions:
    <ul>
      <li>
        Donated or loaned funds for the purpose of opposing or supporting any
        government, political party, candidate or committee, whether domestic or
        foreign?
      </li>
      <li>
        Donated or loaned property or anything of value for the purpose of
        opposing or supporting any government, political party, candidate or
        committee, whether domestic or foreign?
      </li>
      <li>
        Made any loans, donations or other disbursements to any of its
        directors, officers, employees or any third parties for the purpose of
        reimbursing such individuals for political contributions, whether
        domestic or foreign?
      </li>
    </ul>
    <p>If yes, provide details.</p>
  </>
);

export const defaultInitialValues = {
  bribe_category: "political",
  bd_party_id: null,
  related_bd_party_id: null,
  related_bd_party_role: null,
  from_date: null,
  details: null,
  currency_type: null,
  amount_original: null,
  is_funds: null,
  is_property: null,
  is_reimbursement: null,
  position: "",
};

export const endpoint = "bdBribe?bribe_category=political";

export const tabularDataParams = {
  defaultInitialValues,
  endpoint,
};
