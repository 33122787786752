
export const defaultInitialValues = {
  new_jersey_31: {
    nj_31_contact_bd_person_id: null,
    nj_31_contact_address_id: null,
    license_checkbox: "",
    license_number: "",
    license_submission_date: null,
    holding_or_stackholder: ""
  }
}

export const endpoint = 'bdSupplement'