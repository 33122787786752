import { useMemo, useState } from "react";

import DisplayTable from "components/Common/Tables/DisplayTable";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import { columns } from "./config.js";
import useFetch from "queryHooks/useFetch";
import dayjs from "dayjs";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent.jsx";
import Card from "components/Common/Containers/Card/Card";
import CardBody from "components/Common/Containers/Card/CardBody";
import { FormControl, Select } from "@material-ui/core";

const CustomerDashboard = () => {
  const [queryString, setQueryString] = useState("");
  const options = useMemo(() => {
    const days = [];
    const start = dayjs("2020-04-01");
    const now = dayjs();
    var tmp = now; // daysjs is immutable
    while (tmp.isAfter(start)) {
      let formatted = tmp.format("YYYYMM");
      let formattedName = tmp.format("MMM - YYYY");
      days.push({ name: formattedName, value: formatted });
      tmp = tmp.add(-1, "month");
    }
    return days;
  }, []);

  const onDateChange = (event) => {
    setQueryString("?created_at__mon=" + event.target.value);
  };
  const MonthsDropDown = () => {
    return (
      <Card name="Filter">
        <CardBody>
          <FormControl variant="outlined" margin="dense" size="small">
            <Select
              native
              value={queryString.split("=")[1]}
              onChange={onDateChange}
            >
              {options.map(({ name, value }) => (
                <option key={name} value={value}>
                  {name}
                </option>
              ))}
            </Select>
          </FormControl>
        </CardBody>
      </Card>
    );
  };

  if (!queryString) {
    const currentMonth = dayjs().format("YYYYMM");
    setQueryString("?created_at__mon=" + currentMonth);
  }

  const { data = [], status } = useFetch(
    `admin/customerDashboard${queryString}`,
    `admin/customerDashboard${queryString}`,
    { useFullEndpoint: false }
  );

  return (
    <FormSectionProvider
      headerComponent={
        <InfoAlert icon={false}>
          {<h2 style={{ margin: "5px" }}>Analytics Dashboard</h2>}
        </InfoAlert>
      }
    >
      <LoadingComponent status={status}>
        <MonthsDropDown />
        <DisplayTable columns={columns} records={data} />
      </LoadingComponent>
    </FormSectionProvider>
  );
};

export default CustomerDashboard;
