import React from "react";
import { businessLicensing } from "../../uiStrings";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import { LicenseDatesTooltip } from "../sharedFields/LicenseDatesTooltip";
import Tooltip from "components/Common/Tooltip";
import licenseConditionsSchema from "../sharedFields/licenseConditionsSchema";

export const endpoint = "business_licensing_licenseBusiness";

export const tableQuestions = businessLicensing.tableQuestion;

export const infoAlerts = [
  {
    body: <>{tableQuestions}</>,
  },
  {
    body:
      "Do not include companies in which the applicant owns publicly traded stock, unless over 1%",
    severity: "warning",
  },
];

export const modifySubmissionValues = (formValues) => ({
  ...formValues,
  owner_type: "business",
});

export const nonRequired = [
  "disposition_reason",
  "condition_reason",
  "condition_date",
  "condition_type",
  "license_number",
];

export const columns = [
  {
    title: "Business Name",
    field: "pd_business_entity.business_name",
  },
  {
    title: "Industry",
    field: "type",
    format: "startCase",
  },
  {
    field: "application_date",
  },
  {
    title: "Licensing Agency",
    field: "license_agency_pd_business_entity.business_name",
  },
  {
    field: "license_type",
  },
];

export const formSchema = [
  { type: fieldTypes.subHeader, label: "Applicant Business entity" },
  {
    type: fieldTypes.pdBusinessSelect,
    name: "pd_business_entity_id",
    required: true,
  },
  { type: fieldTypes.subHeader, label: "License Agency" },
  {
    type: fieldTypes.pdLicenseAgencySelect,
    name: "license_agency_pd_business_entity_id",
    required: true,
  },
  {
    type: fieldTypes.subHeader,
    label: "License Dates",
    divider: true,
    tooltip: <LicenseDatesTooltip />,
  },
  {
    type: fieldTypes.date,
    label: "Application Date",
    name: "application_date",
  },
  { type: fieldTypes.date, label: "From Date", name: "from_date" },
  { type: fieldTypes.date, label: "Expiration Date", name: "to_date" },
  { type: fieldTypes.subHeader, label: "License Details", divider: true },
  {
    type: fieldTypes.select,
    label: "Industry",
    name: "type",
    dynamicMenuItems: ["licenseType"],
    required: true,
  },
  {
    type: fieldTypes.text,
    label: "Type of License",
    name: "license_type",
  },
  {
    type: fieldTypes.text,
    label: "License Number",
    name: "license_number",
    tooltip: (
      <Tooltip
        title="New Jersey (Form 33) requires to include VID# & LOG#"
        type="warning"
      />
    ),
  },
  {
    type: fieldTypes.select,
    label: "Disposition",
    name: "disposition",
    dynamicMenuItems: ["licenseDispositionType"],
    required: true,
  },
  {
    type: fieldTypes.text,
    label: "Disposition Reason (if applicable)",
    name: "disposition_reason",
    rows: 4,
    xs: 12,
    tooltip: (
      <Tooltip title="If the license application has been denied, withdrawn, suspended, conditioned or revoked, provide an explanation." />
    ),
  },
  ...licenseConditionsSchema,
];
