import useEndPoint from "hooks/useEndpoint";
import useFetch from "queryHooks/useFetch";
import usePatch from "queryHooks/usePatch";
import usePost from "queryHooks/usePost";
import React from "react";
import useInitialValues from "./useInitialValues";
import { useSnackbar } from "notistack";
import _ from "lodash";
import useFormPath from "./useFormPath";
import { validationSchemaGenerator } from "../helpers/validationSchemaGenerator";

const getRequiredValues = (nonRequired, defaultInitialValues) => {
  //any attribute that includes attachment_group_id wont be required. PS: UPDATE FUNCTION WHEN NEEDED TO ADD 2 items or more
  const blackListed = Object.keys(defaultInitialValues).filter((i) =>
    i.includes("attachment_group_id")
  );
  return _.omit(defaultInitialValues, [
    ...nonRequired,
    ...blackListed,
    "is_current",
    "attachment",
  ]);
};

const useForm = ({
  defaultInitialValues,
  endpoint = "",
  saveEndpoint,
  queryKey: queryKeyParam,
  postValues = (values) => values,
  patchValues = (values) => values,
  nonRequired = [],
  noValidate,
  isNewRecord = false,
  fetchEnabled = true,
  successCallback = () => null,
  shouldResetOnSuccess = false,
}) => {
  const queryKey = queryKeyParam || endpoint;
  const { path, noAttributeEndpoint } = useEndPoint(endpoint);

  const { data, status } = useFetch(queryKey, path, {
    enabled: !!fetchEnabled,
  });

  const record = data?.[0];

  isNewRecord = isNewRecord || !record;

  const initialValues = useInitialValues(record, defaultInitialValues);

  const { enqueueSnackbar } = useSnackbar();

  const { mutate: patchMutation } = usePatch(
    queryKey,
    saveEndpoint || noAttributeEndpoint,
    {}
  );

  const { mutate: postMutation } = usePost(
    queryKey,
    saveEndpoint || noAttributeEndpoint,
    {
      onSuccess: () => null,
    }
  );

  const addRecord = (values, options) =>
    postMutation(postValues(values), options);

  const editRecord = (values, options) => {
    const idAdded = { id: record?.id, ...values };
    patchMutation(patchValues(idAdded), options);
  };

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    const options = {
      onSuccess: (data) => {
        shouldResetOnSuccess && resetForm();
        setSubmitting(false);
        enqueueSnackbar("Record Saved", {
          variant: "success",
          autoHideDuration: 2000,
        });
        successCallback(data);
      },
      onError: () => {
        setSubmitting(false);
        enqueueSnackbar("Error", {
          variant: "error",
        });
      },
    };

    if (isNewRecord) addRecord(values, options);
    else editRecord(values, options);
  };

  const requiredValues = React.useMemo(
    () => getRequiredValues(nonRequired, defaultInitialValues),
    [nonRequired, defaultInitialValues]
  );

  const formPath = useFormPath();

  const validationSchema = React.useMemo(
    () => (noValidate ? null : validationSchemaGenerator(formPath)),
    [formPath, noValidate]
  );

  const formikProps = {
    initialValues: fetchEnabled ? initialValues : defaultInitialValues,
    validationSchema,
    onSubmit: handleSubmit,
  };

  return {
    data,
    status,
    formikProps,
    initialValues,
    handleSubmit,
    requiredValues,
  };
};

export default useForm;
