import { useFormInformation } from "context/formStatus";
import React from "react";

const useLoadDashboardData = () => {
  const [status, setStatus] = React.useState("loading");
  const { formStatus } = useFormInformation();

  React.useEffect(() => {
    if (Object.values(formStatus).length) setStatus("resolved");
  }, [formStatus]);

  return { formStatus, status };
};

export default useLoadDashboardData;
