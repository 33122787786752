import React from "react";
import Modal from "@material-ui/core/Modal";
import GridContainer from "components/Common/Containers/Grid/GridContainer";
import GridItem from "components/Common/Containers/Grid/GridItem";

import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import Center from "components/flex/Center";

const _ = require("lodash");
const useStyles = makeStyles((theme) => ({
  pdfModal: {
    position: "absolute",
    width: "80%",
    height: "80%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  imageCenter: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    maxHeight: "100%",
    maxWidth: "100%",
  },
}));

const checkIsImage = (url) => {
  return (
    _.includes(url, "png") || _.includes(url, "jpg") || _.includes(url, "jpeg")
  );
};

const DocumentFrame = ({ url }) => {
  const classes = useStyles();
  if (checkIsImage(url)) {
    return <img alt="Pdf" src={url} className={classes.imageCenter} />;
  }
  return <iframe title="Pdf" src={url} height="100%" width="100%" />;
};

export default function PdfModal({ isOpen, setIsOpen, url, loading }) {
  const classes = useStyles();
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  function onClose() {
    setIsOpen(false);
  }
  return (
    <div>
      <Modal
        onBackdropClick={onClose}
        aria-labelledby="simple-modal"
        aria-describedby="simple-modal-description"
        open={isOpen}
      >
        <div style={getModalStyle()} className={classes.pdfModal}>
          <GridContainer style={{ height: "100% " }}>
            <GridItem md={12} style={{ height: "95%" }}>
              {loading ? (
                <Center>
                  <CircularProgress />
                </Center>
              ) : (
                <DocumentFrame url={url} />
              )}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Modal>
    </div>
  );
}
