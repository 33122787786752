import axios from "axios";
import { useMutation } from "react-query";
import * as Sentry from "@sentry/react";
import { useSnackbar } from "notistack";

export default function usePatchPassword() {
  const { enqueueSnackbar } = useSnackbar();
  const handleSnackBar = (variant) => (message) => {
    enqueueSnackbar(message, {
      variant,
    });
  };
  function handleSuccess() {
    handleSnackBar("info")("Change password link sent to your email!");
  }
  function handleError() {
    handleSnackBar("error")("Problem while send the change password link!");
  }
  const patchPassword = async () => {
    try {
      const data = await axios.patch(`/account/changePassword`);
      handleSuccess();
      return data;
    } catch (error) {
      Sentry.captureException(error);
      handleError(error);
      return error;
    }
  };

  return useMutation(patchPassword);
}
