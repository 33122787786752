import { Grid, Typography, Box } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import { SectionSubHeader } from "components/Common/LayoutComponents/index.js";
import FormikRadioGroup from "components/Formik/formGroups/FormikRadioGroup.jsx";
import BDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/BDPersonSelect";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormikAsyncBDAddressSelect from "components/Formik/formGroups/FormikAsyncBDAddressSelect.jsx";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useForm from "hooks/useForm";
import React from "react";
import { defaultInitialValues, endpoint } from "./config.js";
import { List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import CustomLink from "components/Common/Link.js";

const NewJersey31AdditionalDetailsForm = () => {
  const { newJersey31LicenseCheckbox } = useMenuOptions();

  return (
    <Grid container spacing={2}>
      <BDPersonSelect
        name="new_jersey_31.nj_31_contact_bd_person_id"
        label="Main Contact Person"
        header="Main Contact Person"
        xs={6}
      />

      <SectionSubHeader>
        Address from which the enterprise will be conducting business
      </SectionSubHeader>

      <FormikAsyncBDAddressSelect
        name="new_jersey_31.nj_31_contact_address_id"
        label="Conducting Business Address"
        endpoint="bdAddress"
      />

      <FormikRadioGroup
        header="Check the appropriate box"
        options={newJersey31LicenseCheckbox}
        name="new_jersey_31.license_checkbox"
        xs={12}
        row={false}
      />

      <FormikTextField
        label="License Number"
        name="new_jersey_31.license_number"
        xs={4}
      />
      <FormikDatePicker
        label="License Submission Date"
        name="new_jersey_31.license_submission_date"
        xs={4}
      />

      <FormikTextField
        label="Holding Company or Principal Stockholder"
        name="new_jersey_31.holding_or_stackholder"
        xs={4}
      />

      <SectionSubHeader label="Description of Present Business" />

      <Box pt={1} p={2}>
        <List
          subheader={
            <Typography>
              Add attachment labeled ITEM 3 to{" "}
              <CustomLink to="bd_corporate_documents" noSpace />, describe the
              business presently conducted and the business intended to be
              conducted by the enterprise and its parent, holding, subsidiary
              and intermediary companies, and the general development of such
              business during the past five years, or such shorter period as the
              corporation or its parent, subsidiary and intermediary companies
              may have been engaged in business. The description shall include
              information on matters such as the following:
            </Typography>
          }
        >
          <ListItem>
            <ListItemText>
              <ListItemIcon>A.</ListItemIcon>
              Competitive conditions in the industry or industries involved and
              the competitive position of the enterprise, if known.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <ListItemIcon>B.</ListItemIcon>
              The principal products produced and services rendered by the
              enterprise and its parent, intermediary and subsidiary companies,
              the principal markets for said products or services, and the
              methods of distribution.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <ListItemIcon>C.</ListItemIcon>
              The sources and availability of raw materials essential to the
              business of the enterprise.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <ListItemIcon>D.</ListItemIcon>
              The importance to the business and the duration and effect of, all
              material patents, trademarks, licenses, franchises, and
              concessions held.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <ListItemIcon>E.</ListItemIcon>
              This description must contain a detailed account of the goods or
              services being provided or to be provided, to the casino industry.
              If your enterprise is conducting or intends to conduct both
              gaming-related and non-gaming-related business, differentiate
              between the gaming-related goods or services and the non-gaming
              goods or services.
            </ListItemText>
          </ListItem>
        </List>
      </Box>
    </Grid>
  );
};

const NewJersey31AdditionalDetails = ({ title }) => {
  const formData = useForm({
    defaultInitialValues,
    endpoint,
  });

  return (
    <FormSectionProvider formData={formData} title={title}>
      <EnhancedFormikPage>
        <NewJersey31AdditionalDetailsForm />
      </EnhancedFormikPage>
    </FormSectionProvider>
  );
};

export default NewJersey31AdditionalDetails;
