import {
  formGroupTypes,
  fieldTypes,
} from "componentGenerators/formGenerator/config";

const spouseNames = [
  {
    formGroup: formGroupTypes.firstMiddleLast,
    required: true,
  },
  {
    type: fieldTypes.text,
    label: "Maiden Name",
    name: "maiden_name",
  },
  {
    type: fieldTypes.select,
    label: "Relationship Type",
    name: "relative_type",
    dynamicMenuItems: ["spouseTypeValues"],
    defaultValue: null,
    required: true,
  },
];

const currentMarriagesColumns = [
  {
    field: "full_name",
  },
];

const currentMarriagesSchema = {
  type: "table",
  endpoint: "michigan_isbigo_supplement_marriages_currentSpouse",
  formSchema: spouseNames,
  title: "Current Partner",
  nonRequired: ["attachment_group_id", "suffix_name", "middle_name"],
  maxRecordSize: 1,
  columns: currentMarriagesColumns,
  infoAlerts: [
    {
      body: "Provide details regarding your current partner.",
    },
  ],
};

const maritalStatusFormSchema = [
  {
    type: fieldTypes.label,
    label: "Provide your current marital status.",
  },
  {
    type: fieldTypes.select,
    label: "Current Marital Status",
    name: "marital_status",
    dynamicMenuItems: ["maritalStatus"],
    defaultValue: null,
  },
];

const maritalStatusSchema = {
  type: "form",
  endpoint: "michigan_isbigo_supplement_marriages_personalDisclosure",
  title: "Marital Questions",
  formSchema: maritalStatusFormSchema,
};

export default [maritalStatusSchema, currentMarriagesSchema];
