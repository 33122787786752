import React, { useState } from "react";
import Autosuggest from "react-autosuggest";
import { Button } from "@material-ui/core";
import {
  usePdfState,
  usePdfDispatch,
  ACTIONS,
} from "components/DevTools/PdfTools/Context/PdfToolsProvider.jsx";

import "./index.css";

const AutoCompleteSearchBar = ({ children }) => {
  const dispatch = usePdfDispatch();
  const { dataIndex } = usePdfState();
  const [stateValue, setStateValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : dataIndex.filter((data) =>
          data.table.toLowerCase().includes(inputValue)
        );
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = (suggestion) => {
    dispatch({
      type: ACTIONS.SET_STATE,
      value: [suggestion],
      name: "focusTable",
    });
    dispatch({ type: ACTIONS.SET_STATE, value: "", name: "column" });
    dispatch({
      type: ACTIONS.SET_STATE,
      value: suggestion.table,
      name: "selected",
    });
    dispatch({
      type: ACTIONS.SET_STATE,
      value: suggestion.columns,
      name: "combo",
    });
    return suggestion.table;
  };

  // Use your imagination to render suggestions.
  const renderSuggestion = (suggestion) => <span>{suggestion.table}</span>;

  const onChange = (event, { newValue }) => {
    setStateValue(newValue);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
    dispatch({
      type: ACTIONS.SET_STATE,
      value: getSuggestions(value),
      name: "focusTable",
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    placeholder: "Search tables...",
    value: stateValue,
    onChange: onChange,
  };

  if (dataIndex.length < 1) {
    return <div>Loading...</div>;
  }

  return (
    <div
      style={{
        margin: "5px 5px 25px 25px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {children}
      <div
        style={{
          margin: "5px 5px 25px 0px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
        <Button
          style={{ margin: "0px 0px 0px 10px" }}
          variant="contained"
          onClick={() => dispatch({ type: ACTIONS.RESET })}
        >
          Reset
        </Button>
      </div>
    </div>
  );
};

export default AutoCompleteSearchBar;
