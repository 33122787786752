import React from "react";
import CustomLink from "components/Common/Link";

const BusinessRedirects = () => (
  <>
    Please note, if you do not see the business entity you are associated with
    in the drop down below, please make sure you have entered a record either in
    the
    <CustomLink to="employment_history" />
    section or in the
    <CustomLink to="business_interests" />
    section.
  </>
);

export default BusinessRedirects;
