import PropTypes from "prop-types";
import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { permReduceTypes } from "./permissionReducer";
import styled from "styled-components";
import { getIsAll } from "./permissionReducer";
const CustomTypography = styled(Typography)`
  min-width: max-content;
  text-transform: none;
`;

const BulkActions = ({ state, dispatch, name, dispatchOptions = null }) => {
  const isAllWrite = getIsAll(state, "write", name);
  const isAllRead = getIsAll(state, "read", name);

  return (
    <Box display="flex" alignItems="center" ml={6}>
      <CustomTypography variant="subtitle1">Set All:</CustomTypography>
      <Box size="50%" display="flex" pr={0.5} ml={1}>
        <Box>
          <Button
            onClick={(event) => {
              event.preventDefault();
              dispatch(
                dispatchOptions
                  ? { ...dispatchOptions["view_all"] }
                  : {
                      type: permReduceTypes.view_all,
                      name: name,
                    }
              );
            }}
            style={{ marginRight: "4px" }}
            size="small"
          >
            <Box color={isAllRead ? "#39aeef" : "inherit"}>
              <Typography color="inherit">View </Typography>
            </Box>
          </Button>
        </Box>
        <Box>
          <Button
            onClick={(event) => {
              event.preventDefault();
              dispatch(
                dispatchOptions
                  ? { ...dispatchOptions["edit_all"] }
                  : {
                      type: permReduceTypes.edit_all,
                      name: name,
                    }
              );
            }}
            style={{ marginRight: "4px" }}
            size="small"
          >
            <Box color={isAllWrite ? "#e9a02c" : "inherit"}>
              <Typography>Edit</Typography>
            </Box>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

BulkActions.propTypes = {
  dispatch: PropTypes.func,
  dispatchOptions: PropTypes.object,
  name: PropTypes.string,
  state: PropTypes.object,
};

export default BulkActions;
