import React from "react";

const createRowData = (columns, { id, data }) => {
  const sortedData = columns.reduce((prev, cur, index) => {
    return { ...prev, [cur.name]: data[index] };
  }, {});

  return {
    id,
    isEditMode: false,
    ...sortedData,
  };
};

const getInitialData = (columns, rowsData) =>
  [...Array(rowsData.length)].map((_, i) =>
    createRowData(columns, rowsData[i])
  );

const useEditableTable = ({
  columns,
  tableData,
  handleSubmit,
  newRowInitialData,
}) => {
  const [rows, setRows] = React.useState(getInitialData(columns, tableData));
  const [previous, setPrevious] = React.useState({});
  const [isAddingNewRow, setIsAddingNewRow] = React.useState(false);

  React.useEffect(() => {
    setRows(getInitialData(columns, tableData));
  }, [tableData, columns]);

  const onToggleEditMode = (id) => {
    const newRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, isEditMode: !row.isEditMode };
      }
      return row;
    });
    setRows(newRows);
  };

  const onSave = (id) => {
    const rowData = rows.find((row) => row.id === id);
    if (id) {
      const newRows = rows.map((row) => {
        if (row.id === id) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
      setRows(newRows);
      handleSubmit({ row: rowData, type: "update" }, () => setRows(newRows));
    } else {
      handleSubmit({ row: rowData, type: "create" }, (newID) => {
        const newRows = rows.map((row) => {
          if (row.id === id) {
            return { ...row, isEditMode: !row.isEditMode, id: newID };
          }
          return row;
        });
        setRows(newRows);
      });
      setIsAddingNewRow(false);
    }
  };

  const onAddNew = () => {
    if (rows.find((row) => row.id === 0)) {
      onSave(0);
      return;
    }
    setRows([...rows, newRowInitialData]);
    setIsAddingNewRow(true);
  };

  const onChange = ({ target: { value, name } }, row) => {
    const { id } = row;
    if (!previous[id]) {
      setPrevious((state) => ({ ...state, [id]: row }));
    }
    const newRows = rows.map((item) => {
      if (item.id === id) {
        return { ...item, [name]: value };
      }
      return item;
    });
    setRows(newRows);
  };

  const onDelete = (id) => {
    const newRows = rows.filter((row) => row.id !== id);
    handleSubmit({ row: { id }, type: "delete" }, () => setRows(newRows));
  };

  const onCancelAdd = () => {
    setRows(rows.filter((row) => row.id !== 0));
  };

  return {
    rows,
    isAddingNewRow,
    actions: {
      onToggleEditMode,
      onChange,
      onCancelAdd,
      onSave,
      onDelete,
      onAddNew,
    },
  };
};

export default useEditableTable;
