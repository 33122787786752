import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    header: "Representative",
    type: fieldTypes.bdPersonSelect,
    helperText:
      "Individual Authorized to Represent the Applicant to the Commission",
    label: "Authorized Individual ",
    name: "iowa_adsw.representant_id",
  },
  {
    type: fieldTypes.subHeader,
    label: "Monetary Obligations",
    divider: true,
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Has the applicant, its parent company or any subsidiaries EVER failed to meet a monetary obligation in connection with conducting sports betting?",
    name: "iowa_adsw.has_failed_monetary_obligation",
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label: "If yes, provide details below.",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "iowa_adsw.failed_monetary_obligation_details",
    xs: 12,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "bdSupplement",
};
