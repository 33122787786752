import React from "react";
import { formats } from "components/Common/Tables/Common/helpers";

export const columns = [
  { field: "filing_date" },
  { field: "nature" },
  { field: "case_number" },
  {
    title: "Agency Name",
    field: "law_enforcement_pd_business_entity.business_name",
  },
  { field: "disposition", format: formats.startCase },
  {
    field: "pd_business_entity.business_name",
    title: "Business",
  },
];

export const tableQuestions = (
  <>
    Provide details if, in the past fifteen (15) years:
    <ul>
      <li>
        You as an individual, member of a partnership, or owner, director, or
        officer of a corporation, ever been a party to a lawsuit, as either a
        plaintiff or defendant or an arbitration as either a claimant or
        defendant? (Include matrimonial matters, negligence matters, auto
        accident matters, contract matters, collection matters, debt matters,
        bankruptcies, etc.) OR;
      </li>
      <li>
        Any general partnership, business venture, sole proprietorship or
        closely held corporation, which you were associated with as an owner,
        officer, director or partner, been a party to a lawsuit, arbitration or
        bankruptcy
      </li>
    </ul>
  </>
);

export const nonRequired = [
  "role_in_dispute",
  "attachment_group_id",
  "pd_business_entity_id",
  "gaming_operation_impact_assessment",
  "law_enforcement_pd_business_entity_id",
  "relative_pd_person_id",
];

export const endpoint =
  "lawsuits_and_arbitrations_lawsuitsAndArbitrationLegalHistory";

export const defaultInitialValues = {
  pd_business_entity_id: null,
  role_in_dispute: "",
  type: "",
  case_number: "",
  nature: "",
  filing_date: null,
  other_parties: "",
  time_period: "",
  action_location: "",
  disposition_date: null,
  disposition: "",
  relief_sought: "",
  gaming_operation_impact_assessment: "",
  attachment_group_id: null,
  law_enforcement_pd_business_entity_id: null,
  relative_pd_person_id: null,
};

export const modalTitle = "Lawsuit/Arbitration Details";

export const dataHookProps = {
  defaultInitialValues,
  nonRequired,
  endpoint,
};
