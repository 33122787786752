export const defaultInitialValues = {
  colorado_vlma: {
    sports_betting_licensing_number: "",
    gaming_retailer_license_number: "",
    co_primary_contact_person_id: null,
    co_sales_tax: "",
    co_liquor_license: "",
    name_of_liquor_holder: "",
    co_form_of_business: null,
    co_qualification_date: null,
    business_associations_gaming: "",
    co_person_business_records_id: null,
    co_person_government_forms_id: null,
    co_records_location: "",
  },
};

export const endpoint = "bdSupplement";
