import React from "react";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import CustomLink from "components/Common/Link";
import format from "date-fns/format";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Application Type",
  },
  {
    type: fieldTypes.label,
    label: "Please select below the type of application your are applying for:",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "colorado_gsbsla.application_type_checkbox",
    dynamicMenuItems: ["coloradoGSBSLALicensesTypeCheckbox"],
  },
  {
    type: fieldTypes.subHeader,
    label: "Emergency Contact Person",
  },
  {
    type: fieldTypes.pdPersonSelect,
    label: "Person",
    name: "colorado_gsbsla.emergency_contact_person_id",
  },
  {
    type: fieldTypes.subHeader,
    label: "Identification Information",
  },
  {
    type: fieldTypes.label,
    label:
      "Please provide below your identification information by providing the type of document, the number and the issuing state (e.g.: Driver License, #55-055-5555, Colorado):",
  },
  {
    type: fieldTypes.text,
    label: "Identification Information",
    name: "colorado_gsbsla.identification_information",
  },
  {
    type: fieldTypes.subHeader,
    label: "Employer Information",
  },
  {
    type: fieldTypes.label,
    label: "Please select below your future employment:",
  },
  {
    type: fieldTypes.asyncSelect,
    name: "colorado_gsbsla.future_employment_id",
    endpoint:
      "colorado_gsbsla_supplement_applications_details_employments_applicant",
    label: "Future Employment",
    getOptionLabel: (option) => {
      let to_date = `${format(new Date(option.to_date), "MM/yyyy")}`;
      return `${format(new Date(option.from_date), "MM/yyyy")} - ${
        to_date === "11/2600" ? "Present" : to_date
      }: ${option.business_name}, ${option.position}`;
    },
    getOptionValue: (option) => option.id,
    xs: 6,
  },
  {
    type: fieldTypes.label,
    label:
      "Please select below your present employment (If different form the employment above):",
  },
  {
    type: fieldTypes.asyncSelect,
    name: "colorado_gsbsla.present_employment_id",
    endpoint:
      "colorado_gsbsla_supplement_applications_details_employments_applicant",
    label: "Present Employment",
    getOptionLabel: (option) => {
      let to_date = `${format(new Date(option.to_date), "MM/yyyy")}`;
      return `${format(new Date(option.from_date), "MM/yyyy")} - ${
        to_date === "11/2600" ? "Present" : to_date
      }: ${option.business_name}, ${option.position}`;
    },
    getOptionValue: (option) => option.id,
    xs: 6,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        NOTE: If you do not see any employment in the above dropdowns, please
        enter the necessary information in the{" "}
        <CustomLink to="employment_history" /> section.
      </>
    ),
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "colorado_gsbsla_supplement_applications_details_pdSupplement",
};
