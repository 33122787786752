import { Logo } from "assets/oneComply/img/Logo";
import Card from "components/Common/Containers/Card/Card";
import GridContainer from "components/Common/Containers/Grid/GridContainer";
import GridItem from "components/Common/Containers/Grid/GridItem";
import React from "react";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { makeStyles } from "@material-ui/core";
import styled from "styled-components";

const useStyles = makeStyles(styles);

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme: { palette } }) => palette.grey[200]};
  width: 100%;
  border-radius: 4px 4px 0px 0px;
  padding: 20px;
`;

function LoginCard({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem style={{ width: "375px" }}>
          <Card>
            <LogoWrapper>
              <Logo size="l" />
            </LogoWrapper>
            {children}
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default LoginCard;
