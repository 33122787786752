import { Box } from "@material-ui/core";

import React from "react";
import useFetch from "queryHooks/useFetch.js";
import DisplayTable from "components/Common/Tables/DisplayTable/index.js";
import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const AssetsAndLiabilitiesNonUs = ({ title }) => {
  const { data, status } = useFetch(
    "illinois_assets_and_liabilities_non_us_assets_and_liabilities_applicant_non_us"
  );

  return (
    <FormSectionWrapper title={"Illinois " + title}>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={status}>
          <DisplayTable
            records={data}
            columns={[
              { title: "Category", field: "category", format: "startCase" },
              { title: "Type", field: "url_path", format: "redirect" },
              { title: "Description", field: "description" },
              { title: "Amount", field: "current_amount", format: "currency" },
              { title: "Location", field: "country" },
            ]}
            dependsOnQueryCount="Do you own or control any assets or liabilities located outside the United States?"
          />
        </LoadingComponent>
      </Box>
    </FormSectionWrapper>
  );
};

export default AssetsAndLiabilitiesNonUs;
