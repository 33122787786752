import React from "react";

import MaterialTooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Info from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CommentIcon from "@material-ui/icons/Comment";

const styles = makeStyles({
  info: {
    padding: "10px",
    color: "#4e92ba",
  },
  warning: {
    color: "#c42e2b",
  },
});

function iconSwitch(iconType) {
  switch (iconType) {
    case "info":
      return <Info fontSize="small" />;
    case "warning":
      return <WarningIcon fontSize="small" />;
    case "note":
      return <CommentIcon fontSize="small" />;
    default:
      return <Info fontSize="small" />;
  }
}

const StyledTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 400,
    padding: "12px",
    border: "1px solid #dadde9",
  },
}))(MaterialTooltip);

export default function Tooltip(props) {
  const { type = "info", title, open } = props;
  const classes = styles();

  const overrite = {
    height: "fit-content",
    alignSelf: "top",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 1)",
    },
  };
  return (
    <StyledTooltip
      open={open}
      title={
        <React.Fragment>
          <Typography color="inherit">{title}</Typography>
        </React.Fragment>
      }
    >
      <IconButton
        style={overrite}
        className={classes[`${type}`]}
        aria-label="display"
      >
        {iconSwitch(type)}
      </IconButton>
    </StyledTooltip>
  );
}
