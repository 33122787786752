import React from "react";
import { tabularDataParams, columns } from "./config";
import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { Grid } from "@material-ui/core";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import useTabularData from "hooks/tableHooks/useTabularData";
import BDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/BDPersonSelect";

const SubsidiaryCompaniesForm = () => {
  return (
    <Grid container spacing={2}>
      <SectionSubHeader>Subsidiary Company Compliance</SectionSubHeader>
      <BDPersonSelect
        name="compliance_officer_bd_person_id"
        label="Compliance Officer"
        xs={6}
      />
    </Grid>
  );
};

const IowaClassDSubsidiaryCompliance = () => {
  const subsidiaryData = useTabularData(tabularDataParams);

  return (
    <>
      <FormContextProvider formData={subsidiaryData}>
        <EnhancedFormikDialog>
          <SubsidiaryCompaniesForm />
        </EnhancedFormikDialog>
        <EnhancedActionsTable
          columns={columns}
          handleAddRecord={null}
          subheader={
            <SectionLabel>
              <strong>
                Add a compliance officer to any subsidiary companies listed
                below
              </strong>
            </SectionLabel>
          }
        />
      </FormContextProvider>
    </>
  );
};
export default IowaClassDSubsidiaryCompliance;
