import { blue } from "@material-ui/core/colors";

export const dropzoneStyles = {
  dropzoneStyle: {
    padding: "15px",
    border: "1px dashed rgba(187, 187, 187, 0.87)",
    borderRadius: "5px",
    marginBottom: "10px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  onFileHover: {
    border: "2px dashed #E89818",
  },
  onRejectHover: {
    border: "2px dashed red",
  },
  container: {
    width: "100%",
  },

  icon: {
    marginRight: "10px",
    color: blue[400],
  },
};
