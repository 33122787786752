import PropTypes from "prop-types";
import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useField } from "formik";
import { BooleanRadio } from "components/Common/InputElements";
import { FormControlLabel, Radio, Box } from "@material-ui/core";

import withFieldTools from "hocs/withFieldTools";
import Tooltip from "components/Common/Tooltip";

function YesNo({ label, inlineToolTip, helperText, options, ...props }) {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <FormControl error={!!errorText} required={props.required}>
      <Box display="flex" alignItems="center">
        <FormLabel>{label}</FormLabel>
        {typeof inlineToolTip === "string" ? (
          <Tooltip title={inlineToolTip} />
        ) : (
          inlineToolTip
        )}
      </Box>
      <BooleanRadio {...field}>
        {options ? (
          options.map(({ name, value }) => (
            <FormControlLabel
              key={value}
              value={value}
              control={<Radio color="primary" />}
              label={name}
            />
          ))
        ) : (
          <>
            <FormControlLabel
              value="true"
              control={<Radio color="primary" />}
              label="Yes"
            />
            <FormControlLabel
              value="false"
              control={<Radio color="primary" />}
              label="No"
            />
          </>
        )}
      </BooleanRadio>
      {errorText ||
        (helperText && (
          <FormHelperText>{errorText || helperText}</FormHelperText>
        ))}
    </FormControl>
  );
}

YesNo.propTypes = {
  inlineToolTip: PropTypes.node,
  label: PropTypes.string,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  options: PropTypes.array,
};

const EnhancedYesNo = withFieldTools(YesNo);

// Prevents tooltip from being passed to HOC
const YesNoWrapper = ({ tooltip, ...rest }) => (
  <EnhancedYesNo xs={12} {...rest} inlineToolTip={tooltip} />
);

YesNoWrapper.propTypes = {
  tooltip: PropTypes.node,
};

export default YesNoWrapper;
