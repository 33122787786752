import { fieldTypes } from "componentGenerators/formGenerator/config";
import React from "react";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Associated Business Entity",
  },
  {
    type: fieldTypes.pdBusinessSelect,
    label: "Business Entity",
    name: "oneida_vlabp.associated_business_id",
  },
  {
    type: fieldTypes.yesNo,
    label: "Is or has your spouse ever been affiliated with this business?",
    name: "oneida_vlabp.is_spouse_employed_by_applicant_business",
  },
  {
    type: fieldTypes.yesNo,
    label: "Are you eligible for employment in the United States?",
    name: "oneida_vlabp.is_eligible_to_be_employed",
  },
  {
    type: fieldTypes.subHeader,
    label: "Tribal Association",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        If you identify as a Tribal Member, please enter the name your tribal
        affiliation and your Enrollment Number below.
        <br />
        Your Race can be changed in the <CustomLink to="personal_details" />
        section.
      </>
    ),
  },
  {
    type: fieldTypes.text,
    label: "Tribal Affiliation",
    name: "oneida_vlabp.tribal_affiliation",
  },
  {
    type: fieldTypes.text,
    label: "Enrollment Number",
    name: "oneida_vlabp.tribal_enrollment_number",
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "oneida_vlabp_supplement_disclosure_questions_pdSupplement",
};
