import React from "react";
import { address } from "helpers/initialValues";

export const columns = [
  { field: "business_name" },
  { field: "business_address", format: "address" },
  { field: "form_of_business", format: "startCase" },
  {
    title: "Is Gaming",
    field: "business_type_checkbox.is_gaming",
    format: "boolean",
  },
];

export const tableQuestions = (
  <>
    Central management of all Business Entities in your profile. The Business
    Entities in this section will be available to populate other records in
    various sections.
  </>
);

export const defaultInitialValues = {
  business_type_checkbox: {
    is_gaming: false,
    is_alcohol: false,
    is_government: false,
    is_license_agency: false,
    is_financial_institution: false,
    is_law_enforcement: false,
  },
  business_name: "",
  form_of_business: null,
  business_phone: "",
  business_website: "",
  business_address: address,
  incorporation_state: "",
  incorporation_country: "",
  business_status: "",
  business_description: "",
  gaming_activity_description: "",
};
