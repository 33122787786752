export const permissionTypes = [
  {
    id: 1,
    type: "manage_user",
    name: "Manage User",
    helperText: "Allows user to add and edit new user details.",
  },
  {
    id: 6,
    name: "Manage Business",
    type: "manage_business",
    helperText:
      "Allows user to manage businesses and collaborators for the Business Disclosure portal.",
  },
  {
    id: 2,
    type: "key_personal",
    name: "Key Personnel",
    helperText:
      "Allows user to create a personal disclosure profile to submit a personal disclosure application.",
    impactsBilling: true,
  },
  {
    id: 9,
    name: "Occupational",
    type: "occupational",
    helperText:
      "Allows user to create a personal disclosure profile to submit an occupational application.",
    impactsBilling: true,
  },
  {
    id: 3,
    type: "collaborator",
    name: "Collaborator",
    helperText:
      "Allows user to edit information and generate applications on another user's account.",
  },
  {
    id: 8,
    name: "License Control",
    type: "license_control",
    helperText:
      "Allows user to access License Control and view all licenses, employees and other information for the organization.",
    impactsBilling: false,
  },
];
