/* eslint-disable react/display-name */
import { getFormComponents } from "componentGenerators/helpers";
import AnnualIncome from "components/Pages/PD/Financial/AnnualIncome";
import indiana_attachments from "components/Pages/Supplemental/PD/Indiana/IndianaAttachments/IndianaAttachments";
import IndianaBusinessInvolvement from "components/Pages/Supplemental/PD/Indiana/IndianaBusinessInvolvement/IndianaBusinessInvolvement";
import IndianaCashTransaction from "components/Pages/Supplemental/PD/Indiana/IndianaCashTransaction/IndianaCashTransaction";
import IndianaDisclosureQuestions from "components/Pages/Supplemental/PD/Indiana/IndianaDisclosureQuestions/IndianaDisclosureQuestions";
import IndianaFinancialStatements from "components/Pages/Supplemental/PD/Indiana/IndianaFinancialStatements/IndianaFinancialStatements";
import IndianaForeignBusinesses from "components/Pages/Supplemental/PD/Indiana/IndianaForeignBusinesses/IndianaForeignBusinesses";
import IndianaGamingBusinessInvolvement from "components/Pages/Supplemental/PD/Indiana/IndianaGamingBusinessInvolvement/IndianaGamingBusinessInvolvement";
import IndianaPublicOfficial from "components/Pages/Supplemental/PD/Indiana/IndianaPublicOfficial/IndianaPublicOfficial";
import React from "react";

const indiana_pages = {
  indiana_disclosure_questions: IndianaDisclosureQuestions,
  indiana_public_official: IndianaPublicOfficial,
  indiana_cash_transaction: IndianaCashTransaction,
  indiana_business_involvement: IndianaBusinessInvolvement,
  indiana_gaming_business_involvement: IndianaGamingBusinessInvolvement,
  indiana_foreign_businesses: IndianaForeignBusinesses,
  indiana_income_statement: (props) => (
    <AnnualIncome
      {...props}
      jurisdiction="indiana"
      endpoint="indiana_income_statement_"
    />
  ),
  indiana_financial_statements: IndianaFinancialStatements,
  ...getFormComponents({ indiana_attachments }),
};

export default indiana_pages;
