import { Grid } from "@material-ui/core";
import ControlledAutocomplete from "components/Common/ControlledAutocomplete/ControlledAutoComplete";
import { FormikDialog } from "components/Formik/formContainers/FormikDialog";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { useCrudSelect } from "components/Formik/formGroups/CrudSelect/hooks/useCrudSelect";
import React from "react";
import { DepartmentAndPosition } from "components/Pages/LicensingControl/components/DepartmentAndPosition";
import { BusinessDropdown } from "components/Pages/LicensingControl/components/BusinessDropdown";
import AdditionalFieldsSection from "components/Formik/other/AdditionalFieldsSection";
import Tooltip from "components/Common/Tooltip";
import * as yup from "yup";
import { requiredString } from "helpers/validationSchemaGenerator";

const validationSchema = yup.object().shape({
  location_number: requiredString,
});

const LocationNumberDialog = (props) => (
  <FormikDialog {...props} validationSchema={validationSchema}>
    <FormikTextField label="Location" name="location_number" xs={6} />
  </FormikDialog>
);

const useWorkLocationDropdown = (name) => {
  return useCrudSelect({
    name,
    endpoint: "licensing/lcWorkLocation",
    defaultInitialValues: { location_number: "" },
    getOptionLabel: ({ location_number }) => location_number,
  });
};

const WorkLocationSelect = () => {
  const { formProps, fieldProps } = useWorkLocationDropdown(
    "lc_work_location_id"
  );

  return (
    <>
      <LocationNumberDialog title="Add / Edit Work Location" {...formProps} />
      <ControlledAutocomplete {...fieldProps} label="Work Location" />
    </>
  );
};

const HrLocationSelect = () => {
  const { formProps, fieldProps } =
    useWorkLocationDropdown("lc_hr_location_id");

  return (
    <>
      <LocationNumberDialog title="Add / Edit HR Location" {...formProps} />
      <ControlledAutocomplete {...fieldProps} label="HR Location" />
    </>
  );
};

export const EmploymentForm = () => {
  return (
    <>
      <Grid container spacing={2}>
        <SectionSubHeader>Employee Details</SectionSubHeader>
        <FormikTextField
          label="First Name"
          name="lc_person.first_name"
          xs={4}
        />
        <FormikTextField
          label="Middle Name"
          name="lc_person.middle_name"
          xs={4}
        />
        <FormikTextField label="Last Name" name="lc_person.last_name" xs={4} />
        <FormikTextField
          label="Unique Employee Identifier"
          name="lc_person.external_id"
          xs={6}
          tooltip={
            <Tooltip title="Please enter a unique number for each employee. Example: Human Resource Employee Number" />
          }
        />
        <SectionSubHeader divider>Position Details</SectionSubHeader>
        <BusinessDropdown />
        <Grid item xs={4} />
        <DepartmentAndPosition />

        <AdditionalFieldsSection
          dependantFields={[
            "lc_person.email",
            "lc_person.date_of_birth",
            "lc_person.ssn",
            "lc_work_location_id",
            "lc_hr_location_id",
            "hire_date",
          ]}
        >
          <SectionSubHeader>Additional Details</SectionSubHeader>
          <FormikTextField label="Email" name="lc_person.email" xs={6} />
          <FormikDatePicker
            label="Date of Birth"
            name="lc_person.date_of_birth"
            xs={6}
          />
          <FormikTextField
            label="Social Security #"
            name="lc_person.ssn"
            xs={6}
          />

          <WorkLocationSelect />
          <HrLocationSelect />

          <FormikDatePicker label="Hire Date" name="hire_date" xs={6} />
        </AdditionalFieldsSection>
      </Grid>
    </>
  );
};
