import React from "react";
import NumberFormat from "react-number-format";
import { PropTypes } from "prop-types";

export default function NumberFormatCustom({
  inputRef,
  handleValueChange,
  handleIsAllowed,
  ...other
}) {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={handleValueChange}
      isAllowed={handleIsAllowed}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func.isRequired,
};
