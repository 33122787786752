import {
  formSchema,
  columns,
  endpoint,
  infoAlerts,
  nonRequired,
  modifySubmissionValues,
} from "./config";

const schema = {
  type: "table",
  endpoint,
  formSchema,
  columns,
  nonRequired,
  infoAlerts,
  modifySubmissionValues,
};

export default [schema];
