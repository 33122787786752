import { fieldTypes } from "componentGenerators/formGenerator/config";
import React from "react";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Identification",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of the front AND back of your Drivers License in the
        <CustomLink to="vehicle_licenses" />
        section. OR Attach a copy of the front AND back of your State ID card
        and attach to the back of your application.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of your Social Security Card to the back of the
        application.
      </>
    ),
  },
];

export default {
  type: "readOnlyPage",
  formSchema,
  endpoint: "cherokee_niva_supplement_attachments_pdSupplement",
};
