import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import useFetch from "queryHooks/useFetch";
import { Box, Grid } from "@material-ui/core";
import DisplayTable from "components/Common/Tables/DisplayTable";
import styled from "styled-components";
import { AlertTitle, Alert } from "@material-ui/lab";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const StyledAlert = styled(Alert)`
  & .MuiAlert-message {
    width: 100%;
  }
`;

const AlertNotice = () => (
  <StyledAlert severity="warning">
    <AlertTitle>NOTICE:</AlertTitle>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <li>
          You must include ALL arrests, charges, and convictions since the age
          of 18 regardless of the outcome, even if the charges were dismissed or
          you were found not guilty.
        </li>
        <li>
          You must include ALL arrests, charges, and convictions regardless of
          the class of crime (felonies, misdemeanors, and/or petty offenses).
        </li>
        <li>
          You must include ALL serious traffic offenses, including DUI; DWAI;
          reckless driving; leaving the scene of an accident (hit and run);
          driving under denial, suspension or revocation; or any other offense
          which resulted in your being taken into custody.
        </li>
        <li>
          Do not rely upon your understanding that an arrest or charge is “not
          supposed to be on your record.” A criminal record was not cleared,
          erased, sealed or expunged unless you were given, and have in your
          possession, a written order from a judge directing that action.
        </li>
        <li>
          For each offense for which you were arrested or charged, YOU MUST
          OBTAIN OFFICIAL DOCUMENTATION FROM THE COURT WHERE YOU APPEARED,
          SHOWING THE FINAL DISPOSITION (OUTCOME) OF YOUR CASE. This information
          will include whether you were found guilty or not guilty; and the
          penalty (money fine, time in jail or prison, or probation or deferred
          sentence). If you received a deferred judgment, a deferred sentence,
          or probation, your documentation must include the date that you were
          discharged or released from probation or other supervision.
        </li>
      </Grid>
    </Grid>
  </StyledAlert>
);

const ColoradoCriminalHistory = ({ name }) => {
  const { data: criminal, status: criminalStatus } = useFetch(
    "colorado_rider_criminal_history_criminalLegalHistory"
  );

  const { data: pardon, status: pardonStatus } = useFetch(
    "colorado_rider_criminal_history_pardonLegalHistory"
  );

  const { data: familyArrest = [], status: familyArrestsStatus } = useFetch(
    "colorado_rider_criminal_history_familyArrestLegalHistory"
  );

  return (
    <FormSectionWrapper title={name}>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={criminalStatus}>
          <DisplayTable
            records={criminal}
            linkTo={["arrests"]}
            headerComponent={<AlertNotice />}
            columns={[
              {
                title: "Date of Charge",
                field: "charge_date",
                format: "date",
              },
              { title: "Nature of Charge", field: "nature" },
              { title: "Incident State", field: "incident_state" },
              {
                title: "Agency or Court",
                field: "law_enforcement_pd_business_entity.business_name",
              },
              { title: "Disposition", field: "disposition" },
              { title: "Name of Participant", field: "participant_name" },
            ]}
            dependsOnQueryCount="Since you turned age 18, have you ever been arrested, served with a criminal summons, charged with, or convicted of ANY crime or offense in any manner in this or any other country"
          />
        </LoadingComponent>
      </Box>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={pardonStatus}>
          <DisplayTable
            records={pardon}
            linkTo="pardons"
            columns={[
              {
                title: "Date of Action",
                field: "pardon_date",
                format: "date",
              },
              {
                title: "Name of Government Agency",
                field: "law_enforcement_pd_business_entity.business_name",
              },
              { title: "Type of Action Taken", field: "pardon_action" },
            ]}
            dependsOnQueryCount="Have you ever received a pardon or its equivalent for any criminal offense in this or any other country?"
          />
        </LoadingComponent>
      </Box>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={familyArrestsStatus}>
          <DisplayTable
            records={familyArrest}
            linkTo={["family_arrests"]}
            columns={[
              {
                title: "Date of Charge",
                field: "charge_date",
                format: "date",
              },
              { title: "Relative Name", field: "relative_pd_person.full_name" },
              {
                title: "Relative Type",
                field: "relative_pd_person.relative_type",
                format: "startCase",
              },
              { title: "Nature of Charge", field: "nature" },
              {
                title: "Law Enforcement or Court",
                field: "law_enforcement_pd_business_entity.business_name",
              },
            ]}
            dependsOnQueryCount="Has any member of your family or of your spouse’s family ever been convicted of a felony or any gambling-related offense in this or any other country?"
          />
        </LoadingComponent>
      </Box>
    </FormSectionWrapper>
  );
};

export default ColoradoCriminalHistory;
