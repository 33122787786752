import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import ColoradoFinancialHistoryForm from "./ColoradoFinancialHistoryForm";

const ColoradoEliminatoryQuestions = ({ name }) => {
  return (
    <FormSectionWrapper title={name}>
      <ColoradoFinancialHistoryForm />
    </FormSectionWrapper>
  );
};

export default ColoradoEliminatoryQuestions;
