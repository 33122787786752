import { fieldTypes } from "componentGenerators/formGenerator/config";
import React from "react";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Passport",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a clear copy of your birth certificate in the
        <CustomLink to="citizenship" /> section.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Social Security Card",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a clear copy of your Social Security card, or if you are not a US
        Citizen, its equivalent, to the back of your application.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Picture Identification ",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a clear copy of picture identification. You may upload a copy of
        your Driver&apos;s License in the
        <CustomLink to="vehicle_licenses" />
        section. You may upload a copy of your Passport in the
        <CustomLink to="passport" /> section. Or you may manually attach a copy
        of your State Identification to the back of the application.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Non-US Citizen Documentation",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a clear copy of appropriate alien registration, for non-US
        citizens in the <CustomLink to="citizenship" /> section.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Marriage/Divorce Documentation",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a clear copy all marriage licenses and/or any divorce decrees in
        the <CustomLink to="marriages" /> section.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Tax Documentation",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of the tax filing or any correspondence you received from
        or provided to, the taxing agency, as well as copies of your federal,
        state and local tax returns for the last three (3) years in the
        <CustomLink to="us_taxes" /> section.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Fingerprints",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach two (2) completed FBI fingerprint cards and a completed Livescan
        Form to the back of the application. Hard cards are not required if you
        schedule an appointment with the MGCB.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "IRS Form 4506-C",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Ensure you have completed an IRS Form 4506-C to include a request of
        account transcripts for the past four (4) tax filing periods.
      </>
    ),
  },
];

export default {
  type: "readOnlyPage",
  formSchema,
};
