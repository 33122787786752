import React from "react";
import Button from "components/Common/CustomButtons/Button";
import SaveIcon from "@material-ui/icons/Save";

import PropTypes from "prop-types";
import useGetPermission from "components/Permission/useGetPermission";

export default function SaveButton({
  type,
  style,
  children,
  onClick,
  size = "sm",
  ...rest
}) {
  const permission = useGetPermission();
  if (permission === "read") {
    return null;
  }

  return (
    <Button
      style={style}
      type={type}
      onClick={onClick}
      cy-data="save_button"
      color="primary"
      size={size}
      startIcon={<SaveIcon />}
      {...rest}
    >
      {children}
    </Button>
  );
}

SaveButton.propTypes = {
  type: PropTypes.string,
  style: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};
