import { Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikCheckBox from "components/Formik/fields/FormikCheckBox.jsx";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import FormikSelect from "components/Formik/fields/FormikSelect";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import PDPartySelect from "components/Formik/formGroups/CrudSelect/selects/PDPartySelect";
import PDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/PDPersonSelect.jsx";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import { useMenuOptions } from "hooks/useMenuOptions";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";

const Form = () => {
  const { pdLoansType } = useMenuOptions();

  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Lender Details" />
      <PDPartySelect name="creditor_pd_party_id" label="Lender" />

      <SectionSubHeader label="Borrower (If Applicable)" divider />
      <PDPersonSelect
        label="Relative (If Applicable)"
        helperText="Select, if the borrower was anyone other then you"
      />
      <SectionSubHeader label="Loan Details" divider />
      <FormikSelect
        label="Loan Category"
        name="type"
        options={pdLoansType}
        xs={4}
      />
      <FormikTextField label="Name of Cosigners" name="cosigner_names" xs={4} />
      <FormikNumberField
        label="Original Loan Amount"
        name="original_amount"
        xs={4}
      />
      <FormikNumberField
        type="percent"
        label="Interest Rate"
        name="interest_rate"
        xs={4}
      />
      <FormikDatePicker
        label="Loan Date"
        name="incurred_date"
        helperText="Date Received Loan"
        xs={4}
      />
      <FormikDatePicker
        label="Termination Date"
        name="termination_date"
        xs={4}
      />
      <FormikCheckBox
        label="Is Current - Current loans will be included in the Net Worth section."
        name="is_current"
        xs={12}
      />
    </Grid>
  );
};

const BorrowingHistory = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "borrowing_history_borrowingHistoryPayable",
    nonRequired: ["relative_pd_person_id", "cosigner_names"],
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default BorrowingHistory;
