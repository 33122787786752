import * as Sentry from "@sentry/react";
import "assets/scss/material-dashboard-pro-react.scss";
import axios from "axios";
import CacheBuster from "components/CacheBuster";
import SentryErrorBoundary from "error/SentryErrorBoundary";
import { createBrowserHistory } from "history";
import AppLayout from "layouts/AppLayout.js";
import AuthLayout from "layouts/Auth.js";
import React from "react";
import ReactDOM from "react-dom";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import AppProviders from "./AppProviders";
import AdminToolsRoute from "./routing/AdminToolsRoute";
import ProtectedRoute from "./routing/ProtectedRoute";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.js";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
  });
  Sentry.setUser({ email: localStorage.getItem("email") });
}

let appBaseUrl = window.location.origin + "/api/v1/";

if (process.env.NODE_ENV === "development") {
  appBaseUrl = appBaseUrl.replace("3000", "3001");
}

axios.defaults.baseURL = appBaseUrl;
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

const hist = createBrowserHistory();

function Application() {
  return (
    <SentryErrorBoundary>
      <CacheBuster />
      <AppProviders>
        <Router history={hist}>
          <Switch>
            <Redirect exact from="/" to="/auth/login_page" />
            <Route path="/auth" component={AuthLayout} />
            <ProtectedRoute path="/app" hist={hist} component={AppLayout} />
            <AdminToolsRoute />
          </Switch>
        </Router>
      </AppProviders>
    </SentryErrorBoundary>
  );
}

ReactDOM.render(<Application />, document.getElementById("root"));
