import React from "react";

export const columns = [
  { field: "fund_type" },
  {
    title: "Institution or Employer",
    field: "institution_pd_business_entity.business_name",
  },
  { title: "Relative Name", field: "relative_pd_person.full_name" },
  { field: "employee_contribution", format: "currency" },
  { field: "cash_value", format: "currency" },
];

export const tableQuestions = (
  <>
    Provide details with regard to the cash value of all retirement, investment
    and pension funds held by you, your spouse, domestic partner or dependent
    children. If you are in the United States include IRA, 401K and KEOGH plans.
  </>
);

export const nonRequired = [
  "relative_pd_person_id",
  "pension_funds_attachment_group_id",
  "employer_contribution",
];

export const endpoint = "pension_funds_pension";

export const defaultInitialValues = {
  relative_pd_person_id: null,
  institution_pd_business_entity_id: null,
  fund_type: "",
  broker_person_name: "",
  broker_phone: "",
  type_of_securities_held: "",
  account_number: "",
  employee_contribution: null,
  employer_contribution: null,
  cash_value: null,
  valuation_date: null,
  pension_funds_attachment_group_id: null,
};

export const modalTitle = "Fund Details";

export const dataHookProps = {
  defaultInitialValues,
  nonRequired,
  endpoint,
};
