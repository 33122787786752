import { fieldTypes } from "./config";

/*
  FormSchema Attributes:
  - name: identifier for formik fields
  - type (required): used to determine the component to render as well as generating the default initial value and validation schema if applicable
  - required: setting as true will make the field required and show validation
  - dynamicMenuItems: for fields with dynamic options coming from useMenuOptions's menuItems. Recieves an array of string id's that relate to menuItems
  - OTHER: additional props can be passed from the formSchema to components. They are not automatically forwarded, so if a new prop is needed, it has to be added in useFormGenerator
  - defaultValue: if passed will override the default value generated by the form generator, only pass if needed
  
  ! if type is "select", you must manually pass a defaultValue
*/

const pdBusinessDetailsSection = [
  {
    type: fieldTypes.subHeader,
    children: "Business Details",
    gutterTop: true,
  },
  {
    name: "business_name",
    type: fieldTypes.text,
    label: "Name",
    required: true,
    xs: 6,
  },
  {
    type: fieldTypes.gridItem,
    xs: 6,
  },
  {
    name: "business_phone",
    type: fieldTypes.text,
    label: "Phone",
    xs: 6,
  },
  {
    name: "business_website",
    type: fieldTypes.text,
    label: "Website",
    xs: 6,
  },
];

const bdBusinessDetailsSection = [
  {
    name: "business_name",
    type: fieldTypes.text,
    label: "Name",
    required: true,
    xs: 4,
  },
  {
    name: "bd_address.fax",
    type: fieldTypes.text,
    label: "Fax",
    xs: 4,
  },
  {
    name: "bd_address.phone",
    type: fieldTypes.text,
    label: "Phone",
    xs: 4,
  },
  {
    name: "bd_address.website",
    type: fieldTypes.text,
    label: "Website",
    xs: 6,
  },
  {
    name: "bd_address.email",
    type: fieldTypes.text,
    label: "Email",
    xs: 6,
  },
];

const pdBusinessLookupField = {
  type: fieldTypes.googleLookup,
  fields: {
    address: "business_address",
    name: "business_name",
    website: "business_website",
    phone: "business_phone",
  },
};

const bdBusinessLookupField = {
  type: fieldTypes.googleLookup,
  fields: {
    address: "bd_address.address",
    name: "business_name",
    website: "bd_address.website",
    phone: "bd_address.phone",
  },
};

const businessTypeCheckbox = [
  {
    type: fieldTypes.subHeader,
    children: "Check all that apply to this entity",
  },
  {
    name: "business_type_checkbox",
    type: fieldTypes.checkBoxGroup,
    row: true,
    dynamicMenuItems: ["businessTypeCheckboxOptions"],
  },
  {
    type: fieldTypes.divider,
  },
];

const personNames = [
  {
    name: "first_name",
    type: fieldTypes.text,
    required: true,
    label: "First Name",
    xs: 4,
  },
  {
    name: "middle_name",
    type: fieldTypes.text,
    label: "Middle Name",
    xs: 4,
  },
  {
    name: "last_name",
    type: fieldTypes.text,
    required: true,
    label: "Last Name",
    xs: 4,
  },
  {
    name: "suffix_name",
    type: fieldTypes.text,
    label: "Suffix",
    xs: 4,
  },
];

export const jurisdictionDropdownSchema = {
  name: "license_agency_jurisdiction",
  type: fieldTypes.asyncSelect,
  label: "Jurisdiction",
  endpoint: "jurisdiction?all=true",
  getOptionLabel: (option) => option.name,
  getOptionValue: (option) => option.tag,
  fetchOptions: { useFullEndpoint: false },
  xs: 6,
  required: true,
};

export const pdPersonSchema = [
  {
    name: "is_deceased",
    type: fieldTypes.yesNo,
    label: "Is this person deceased?",
  },
  {
    type: fieldTypes.divider,
  },
  {
    type: fieldTypes.subHeader,
    label: "Personal Details",
  },
  ...personNames,

  {
    name: "date_of_birth",
    type: fieldTypes.date,
    label: "Date of Birth",
    xs: 4,
  },
  {
    name: "place_of_birth",
    type: fieldTypes.text,
    label: "Place of Birth",
    helperText: "City, State/Province, Country",
    xs: 4,
  },
  {
    name: "relative_type",
    type: fieldTypes.select,
    label: "Relative Type",
    helperText: "Applicable Only If Related",
    defaultValue: null,
    xs: 6,
    dynamicMenuItems: [
      "spouseTypeValues",
      "parentTypeValues",
      "siblingSpouseTypeValues",
      "otherRelativeTypeValues",
    ],
  },
  {
    name: "occupation",
    type: fieldTypes.text,
    label: "Occupation",
    helperText: "If retired or deceased, list last occupation",
    xs: 6,
  },
  {
    type: fieldTypes.divider,
  },
  {
    type: fieldTypes.subHeader,
    label: "Contact Details",
  },
  {
    name: "email",
    type: fieldTypes.text,
    label: "Email",
    xs: 4,
  },
  {
    name: "phone_number",
    type: fieldTypes.text,
    label: "Home Phone Number",
    xs: 4,
  },
  {
    name: "mobile_phone",
    type: fieldTypes.text,
    label: "Mobile Phone Number",
    xs: 4,
  },
  {
    type: fieldTypes.divider,
  },
  {
    type: fieldTypes.subHeader,
    label: "Address",
  },
  {
    type: fieldTypes.googleLookup,
    fields: {
      address: "home_address",
    },
  },
  {
    type: fieldTypes.addressFields,
    name: "home_address",
  },
];

export const pdBusinessSchema = [
  ...businessTypeCheckbox,
  pdBusinessLookupField,
  ...pdBusinessDetailsSection,
  {
    type: fieldTypes.subHeader,
    children: "Business Address",
    gutterTop: true,
  },
  {
    type: fieldTypes.addressFields,
    name: "business_address",
  },
];

export const pdLicenseAgencySchema = [
  pdBusinessLookupField,
  ...pdBusinessDetailsSection,
  {
    type: fieldTypes.subHeader,
    children: "Jurisdiction",
    gutterTop: true,
  },
  jurisdictionDropdownSchema,

  {
    type: fieldTypes.subHeader,
    children: "License Address",
    gutterTop: true,
  },
  {
    type: fieldTypes.addressFields,
    name: "business_address",
  },
];

export const bdLicenseAgencySchema = [
  bdBusinessLookupField,
  ...bdBusinessDetailsSection,
  {
    type: fieldTypes.subHeader,
    children: "Jurisdiction",
    gutterTop: true,
  },
  jurisdictionDropdownSchema,

  {
    type: fieldTypes.subHeader,
    children: "License Address",
    gutterTop: true,
  },
  {
    type: fieldTypes.addressFields,
    name: "bd_address.address",
  },
];

export const bdBusinessSchema = [
  bdBusinessLookupField,
  {
    type: fieldTypes.subHeader,
    children: "Business Details",
    gutterTop: true,
  },
  ...bdBusinessDetailsSection,
  {
    type: fieldTypes.subHeader,
    children: "Business Address",
    gutterTop: true,
  },
  {
    type: fieldTypes.addressFields,
    name: "bd_address.address",
  },
];

export const bdLawEnforcementSchema = [
  bdBusinessLookupField,
  {
    type: fieldTypes.subHeader,
    children: "Agency Details",
    gutterTop: true,
  },
  ...bdBusinessDetailsSection,
  {
    type: fieldTypes.subHeader,
    children: "Agency Address",
    gutterTop: true,
  },
  {
    type: fieldTypes.addressFields,
    name: "bd_address.address",
  },
];

export const bdFinancialSchema = [
  {
    type: fieldTypes.subHeader,
    children: "Branch Name",
    gutterTop: true,
  },
  {
    name: "branch_name",
    type: fieldTypes.text,
    label: "Branch Name",
  },
  {
    type: fieldTypes.divider,
  },
  bdBusinessLookupField,
  {
    type: fieldTypes.subHeader,
    children: "Institution Details",
    gutterTop: true,
  },
  ...bdBusinessDetailsSection,
  {
    type: fieldTypes.text,
    name: "employer_id_number",
    label: "Federal Identification Number",
  },
  {
    type: fieldTypes.subHeader,
    children: "Institution Address",
    gutterTop: true,
  },
  {
    type: fieldTypes.addressFields,
    name: "bd_address.address",
  },
];

export const bdPersonSchema = [
  {
    type: fieldTypes.subHeader,
    children: "Personal Details",
    gutterTop: true,
  },
  ...personNames,
  {
    name: "date_of_birth",
    type: fieldTypes.date,
    label: "Date of Birth",
  },
  {
    name: "country_of_birth",
    type: fieldTypes.select,
    label: "Country of Birth",
    defaultValue: null,
    xs: 4,
    dynamicMenuItems: ["listOfCountries"],
  },
  {
    type: fieldTypes.subHeader,
    children: "Contact",
    divider: true,
  },
  {
    name: "home_phone",
    type: fieldTypes.text,
    label: "Home Phone",
    xs: 6,
  },
  {
    name: "mobile_phone",
    type: fieldTypes.text,
    label: "Mobile Phone",
    xs: 6,
  },
  {
    name: "business_phone",
    type: fieldTypes.text,
    label: "Business Phone",
    xs: 6,
  },
  {
    name: "fax",
    type: fieldTypes.text,
    label: "Fax",
    xs: 6,
  },
  {
    type: fieldTypes.subHeader,
    children: "Address",
    divider: true,
  },
  {
    type: fieldTypes.googleLookup,
    fields: {
      address: "home_address",
    },
  },
  {
    type: fieldTypes.addressFields,
    name: "home_address",
  },
  {
    type: fieldTypes.subHeader,
    children: "Professional Details",
    divider: true,
  },
  {
    name: "occupation",
    type: fieldTypes.text,
    label: "Occupation",
  },
  {
    name: "title",
    type: fieldTypes.text,
    label: "Title",
  },
  {
    name: "email",
    type: fieldTypes.text,
    label: "Email Address",
  },
];

export default {
  pdPersonSchema,
  pdBusinessSchema,
  pdLicenseAgencySchema,
  bdBusinessSchema,
  bdPersonSchema,
  bdLawEnforcementSchema,
  bdFinancialSchema,
  bdLicenseAgencySchema,
};
