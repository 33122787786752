import { FormikTextField } from ".";
import React from "react";

const validateEmail = (value) => {
  let error;
  if (!/^null|^$|^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Invalid email address";
  }
  return error;
};

const FormikEmailTextField = (props) => {
  return <FormikTextField xs={4} {...props} validate={validateEmail} />;
};
export default FormikEmailTextField;
