import { get } from "lodash";
import produce from "immer";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export const multiSelectFilter = (filter, attributeName, alternateName) => (
  record
) => {
  if (!filter.length) return true;
  return filter.some(
    (filter) =>
      filter[alternateName || attributeName] === get(record, attributeName)
  );
};

const filterByExpiry = (records, filters) => {
  return records.filter(({ license_expiry_date }) => {
    if (!filters.length) return true;
    const expiryDateObj = dayjs(license_expiry_date);

    return filters.some(({ expiryUrgency }) => {
      if (expiryUrgency === "expired" && expiryDateObj.isBefore(dayjs()))
        return true;
      if (
        expiryDateObj.isBefore(dayjs().add(expiryUrgency, "day")) &&
        expiryDateObj.isAfter(dayjs())
      ) {
        return true;
      }
      return false;
    });
  });
};

export const handleFilter = (filterState, filterAttributes) => (items) => {
  const multiSelectFiltered = filterAttributes.reduce((acc, filter) => {
    return acc.filter(
      multiSelectFilter(filterState[filter[0]], filter[0], filter[1])
    );
  }, items);

  return filterByExpiry(multiSelectFiltered, filterState.expiryUrgency);
};

export const filterReducer = (state, action) => {
  if (action.type === "UPDATE") {
    const updatedState = produce(state, (draft) => {
      draft[action.name] = action.payload;
      draft.filterCount = 1;
    });

    const filterCount = Object.keys(updatedState).reduce(
      (accumulator, currentValue) =>
        accumulator + (updatedState[currentValue].length || 0),
      0
    );

    return produce(updatedState, (draft) => {
      draft.filterCount = filterCount;
    });
  }
  return state;
};
