import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import DepartmentForm from "components/Pages/LicensingControl/Department/DepartmentForm.js";

import {
  columns,
  endpoint,
  defaultInitialValues,
  sortingOptions,
} from "./config.js";

const Department = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint,
    saveEndpoint: endpoint,
    fetchOptions: { useFullEndpoint: false },
  });

  return (
    <FormSectionProvider formData={formData} title={name}>
      <EnhancedFormikDialog title={name}>
        <DepartmentForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        columns={columns}
        addButtonText="Add Department"
        {...sortingOptions}
      />
    </FormSectionProvider>
  );
};

export default Department;
