import { ListSubheader } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import React from "react";
import { UploadCompleteItem } from "../FileDropZone/components";

export const AttachmentsList = ({
  groupsWithAttachments,
  onClickAttachment,
  removeAttachmentItem,
}) => {
  return (
    <List style={{ padding: "0px" }}>
      {groupsWithAttachments.map(
        ({ description, attachments, id: groupID }) => (
          <div key={groupID}>
            <ListSubheader>{description || "No Date"}</ListSubheader>
            {attachments.map((file) => (
              <UploadCompleteItem
                key={file.id}
                file={file}
                handleClickFile={() =>
                  onClickAttachment(file.id, file.attachment_group_id)
                }
                handleDeleteFile={() => removeAttachmentItem(groupID, file.id)}
              />
            ))}
            <Divider style={{ margin: "20px", marginBottom: "0px" }} />
          </div>
        )
      )}
    </List>
  );
};
