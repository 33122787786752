import React from "react";
import CustomLink from "components/Common/Link";

export const columns = [
  /*{
    title: "Entity",
    field: "bd_security_structure.bd_business_entity.business_name",
  },*/
  { title: "Holder", field: "party_name" },
  { title: "Option Name", field: "bd_security_structure.option_name" },
  { title: "Voting Shares", field: "voting_shares_held_count" },
  {
    title: "Non-Voting Shares",
    field: "non_voting_shares_held_count",
  },
];

export const tableQuestions = (
  <>
    Provide details of all persons holding the options described in the
    <CustomLink to="bd_security_options" /> section.
  </>
);

export const defaultInitialValues = {
  bd_party_id: null,
  bd_security_structure_id: null,
  voting_shares_held_count: null,
  non_voting_shares_held_count: null,
  issuance_market_value: null,
};
