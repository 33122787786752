import * as yup from "yup";

export const nullableString = yup
  .string("Required Field")
  .required("Required Field")
  .nullable();
export const requiredBoolean = yup
  .boolean()
  .required("Required Field")
  .typeError("Required Field");

export const requiredString = yup
  .string()
  .required("Required Field")
  .typeError("Required Field");
export const requiredNumber = yup
  .string()
  .required("Required Field")
  .typeError("Required Field");

export const nullableNumber = yup
  .string("Required Field")
  .required("Required Field")
  .nullable();

export const nonRequiredEmail = yup.string().email("Must be a valid Email");

export const multiEmail = yup
  .array()
  .of(yup.string().email("Must be a valid Email"));

export const multiEmailRequired = yup
  .array()
  .of(yup.string().email("Must be a valid Email"))
  .required("Email(s) is Required");

const yearValidation = yup
  .number()
  .required("Required field")
  .nullable()
  .positive("Year must be Positive")
  .integer("Year must be an Integer")
  .max(9999, "Year must be 4 digits")
  .min(1000, "Year must be 4 digits");

//Fields
// const bd_business_entity_id = nullableString;
const pd_business_entity_id = nullableString;
const related_bd_party_id = nullableString;
const relative_pd_person_id = requiredNumber;
const related_bd_party_role = nullableString;
const bd_party_id = requiredNumber;
const bank_bd_business_entity_id = nullableNumber;
// const name = nullableString;
const relative_type = requiredString;
const related_pd_business_entity_id = requiredNumber;

//Schema Groups
const relatedBusiness = {
  //bd_business_entity_id,  // don't need this validation now
  related_bd_party_id,
};

const relatedBusinessWithRole = {
  ...relatedBusiness,
  related_bd_party_role,
};

const relatedParty = {
  bd_party_id,
  related_bd_party_id,
};

const relatedPartyWithRole = {
  ...relatedParty,
  related_bd_party_role,
};

const bdSchemas = {
  //Directors & Officers
  bd_directors_trustees_officers: relatedBusinessWithRole,
  bd_related_business_entities: {
    business_name: nullableString,
    business_relationship_type: nullableString,
  },
  bd_employee_compensation: relatedBusinessWithRole,
  bd_partners: relatedBusinessWithRole,

  bd_nj_csiel_supplemental_qualifiers: {
    related_bd_party_id,
    related_bd_party_role: requiredString,
  },
  bd_owners: {
    related_bd_party_id,
  },
  //Service Providers
  bd_auditors_and_accountants: {
    related_bd_party_id: requiredNumber,
  },
  bd_contracts_over_250k: relatedBusinessWithRole,
  bd_legal_advisors: relatedBusiness,
  bd_lobbyists: relatedBusiness,
  bd_contracts: {
    //bd_business_entity_id,
    related_bd_party_id,
    related_bd_party_role: requiredString,
  },

  //Licenses & Permits
  bd_licenses: {
    industry_type: nullableString,
    bd_party_id,
    license_agency_bd_business_entity_id: nullableString,
  },

  //Financial
  bd_profit_share_plan: {
    trustee_bd_party_id: requiredNumber,
    //bd_business_entity_id: requiredNumber,
  },
  bd_share_structure: {
    //bd_business_entity_id,
    security_type: nullableString,
  },
  bd_shareholders: {
    bd_party_id,
    bd_security_structure_id: nullableNumber,
  },
  bd_off_book_accounts: {
    bank_bd_business_entity_id,
    bd_business_entity_id: requiredNumber,
  },
  bd_nominee_accounts: {
    bank_bd_business_entity_id,
    //bd_business_entity_id,
    related_bd_party_id,
  },
  bd_bank_accounts: {
    bank_bd_business_entity_id,
    //bd_business_entity_id
  },
  bd_loans: {
    ...relatedBusinessWithRole,
    loan_status: requiredString,
    purpose_of_loan: requiredString,
    instrument_type: requiredString,
    bd_business_entity_id: requiredNumber,
  },
  bd_trusts: {
    bd_business_entity_id: requiredNumber,
    trust_bd_business_entity_id: requiredNumber,
  },
  bd_publicly_traded: {},
  /*bd_financial_statements: { 
    bd_business_entity_id 
  },
  bd_tax_returns: {
    bd_business_entity_id,
  },*/
  bd_security_options: {
    //bd_business_entity_id,
    option_name: requiredString,
    security_type: requiredString,
    option_grant_years: yup
      .array()
      .of(yup.number().typeError("Must be a valid year"))
      .nullable(),
  },
  bd_security_options_holders: {
    bd_party_id,
    bd_security_structure_id: nullableNumber,
  },
  bd_options_holders: {
    bd_party_id: nullableNumber,
    bd_security_structure_id: nullableNumber,
  },

  //Contributions
  bd_bribes_and_kickbacks: {
    ...relatedPartyWithRole,
    bribe_category: nullableString,
  },
  bd_political_contributions: relatedPartyWithRole,

  bd_knowledge_of_contributions: {
    related_bd_party_id,
  },

  //Legal History
  bd_criminal_proceedings: { bd_party_id, offense_type: nullableString },
  bd_antitrust_and_violations: { bd_party_id },
  bd_litigations: {
    bd_party_id,
    role_in_dispute: requiredString,
    law_enforcement_bd_business_entity_id: requiredNumber,
    bd_related_party_id: requiredNumber,
  },

  //bd_government_reports: { bd_business_entity_id },

  //Businesses & People
  bd_people: { last_name: requiredString },
  bd_business_entities: { business_name: requiredString },

  bd_penn_eea_supplement_disclosure: {},
  //Bankruptcy
  bd_bankruptcies: { bd_party_id },
  bd_testimonies: {
    bd_party_id,
    type: requiredString,
    criminal_disposition: nullableString,
  },
  bd_antitrust_and_trade: {
    bd_party_id,
    type: requiredString,
  },
  bd_statutory_fines: {
    bd_party_id,
  },
  //bd_assets: { bd_business_entity_id },
  bd_elk_supplement_previous_company_name: {},
  bd_elk_supplement_gaming_services_provided: {},
  bd_elk_supplement_invested_or_loaned: {},
  bd_insurance_claims: {
    //bd_business_entity_id,
    carrier_bd_business_entity_id: requiredNumber,
  },

  bd_application_details_founders: {
    related_bd_party_id: requiredNumber,
  },
  bd_application_details_authorized: {
    state_code: requiredString,
  },
  bd_michigan_vra_supplement_application_details: {
    michigan_vra: yup.object({
      gaming_or_sports_betting: nullableString,
      initial_or_five_year: nullableString,
    }),
  },
  bd_michigan_vra_supplement_tax_liability: {
    bd_party_id: requiredNumber,
    tax_agency_entity_id: requiredNumber,
  },
  bd_tennessee_bed_supplement_application_details: {
    tennessee_bed: yup.object({
      license_type: nullableString,
    }),
  },
};

const pdSchemas = {
  passport: {},
  education: {
    school_name: nullableString,
  },
  residential_history: {},
  references: { pd_person_id: requiredNumber },
  foreign_travel: {},
  fiduciary_positions: {
    pd_business_entity_id,
    position_type: requiredString,
  },
  employment_history: {
    pd_business_entity_id,
  },
  spouse_employment: {
    pd_business_entity_id,
    relative_pd_person_id,
  },
  disciplinary_actions: {
    employment_id: nullableString,
    discipline_type: requiredString,
  },
  personal_licensing: {
    license_agency_pd_business_entity_id: nullableNumber,
    type: nullableString,
    disposition: nullableString,
  },
  business_licensing: {
    license_agency_pd_business_entity_id: requiredNumber,
    pd_business_entity_id,
    disposition: nullableString,
    type: nullableString,
  },
  government_positions: {
    pd_business_entity_id,
  },
  parents: {
    first_name: requiredString,
    last_name: requiredString,
    relative_type,
  },
  other_relatives: {
    first_name: requiredString,
    last_name: requiredString,
    relative_type,
  },
  siblings: {
    first_name: requiredString,
    last_name: requiredString,
    relative_type,
  },
  fiduciary_denials: {
    office_position_id: nullableString,
    discipline_type: requiredString,
  },

  business_interests: {
    pd_business_entity_id,
  },
  children: {
    first_name: requiredString,
    last_name: requiredString,
  },

  military: {
    law_enforcement_pd_business_entity_id: requiredNumber,
  },
  securities: {},
  vehicle_licenses: {
    is_primary_drivers_license: requiredBoolean,
  },
  family_ownership: {
    relative_pd_person_id,
    pd_business_entity_id: requiredNumber,
  },
  business_entities: {
    business_name: requiredString,
  },
  people: {
    first_name: requiredString,
    last_name: requiredString,
  },
  taxes_summary: {},

  bank_accounts: {
    bank_pd_business_entity_id: nullableString,
    check_account_with_brokerage: requiredBoolean,
  },
  cash: {},
  financial_questions: {},
  liens: {},
  tax_returns: {
    tax_year: yearValidation,
    region_type: requiredString,
    tax_agency_pd_business_entity_id: nullableString,
  },
  tax_audit: {
    tax_year: yearValidation,
    region_type: requiredString,
    activity_type: requiredString,
    tax_agency_pd_business_entity_id: nullableString,
  },

  bankruptcies: {},
  business_receiverships: {},
  repossessions: {},
  executor: {},
  assets_in_trust: {},
  nominee_holdings: {},
  lending_history: {
    debtor_pd_party_id: nullableNumber,
  },
  borrowing_history: {
    creditor_pd_party_id: nullableNumber,
    type: nullableString,
  },
  currency_exchanges: { was_report_filed: requiredBoolean },
  arrests: { charge_type: requiredString },
  family_arrests: { relative_pd_person_id },
  lawsuits_and_arbitrations: {
    type: requiredString,
    role_in_dispute: requiredString,
  },
  indictments: {},
  pardons: {},
  testimonies: {},
  government_investigations: {},
  citations: {},
  license_exclusions: {
    law_enforcement_pd_business_entity_id: requiredNumber,
  },
  loans_and_receivables: {
    payment_required: requiredNumber,
    payment_period_type: requiredString,
  },
  real_estate_interests: {},
  insurance_claims: {},
  margin_accounts: {},
  gifts: {
    donor_person_id: requiredNumber,
    donee_person_id: requiredNumber,
  },
  safe_deposit_boxes: {
    held_by_applicant: requiredBoolean,
    account_type: requiredString,
  },
  referral_fees: {},
  loan_guarantees: {},
  life_insurance: {},
  pension_funds: {},
  net_worth_statements: {},
  business_investments: {
    ownership_percent: requiredNumber,
    pd_business_entity_id,
  },
  colorado_rider_eliminatory_questions: {},
  vehicles: {
    // leased_or_owned: requiredString,
  },
  other_assets: {},
  notes_payable: {},
  loans_and_other_payables: {},
  taxes_payable: {},
  real_estate_loans: {},
  insurance_pension_loans: {},
  other_indebtedness: {},
  contingent_liabilities: {},
  arkansas_disclosure_questions: {},
  us_taxes: { tax_year: yearValidation },
  non_us_taxes: { tax_year: yearValidation },
  new_mexico_disclosure_questions: {
    new_mexico: yup.object({
      association_with_business_entity: requiredString,
    }),
  },
  pennsylvania_tax_information: {},
  pennsylvania_public_official: {},
  bd_nj_31_supplement_AD: {},
  iowa_attorneys: { related_pd_business_entity_id },
  iowa_accountants: { related_pd_business_entity_id },
  iowa_donations: { candidate_pd_person_id: requiredNumber },
  iowa_applicant_family_members: { relative_type: requiredString },
};

const adminSchema = {
  // customer_application_form_admin: {
  //   pdf_form: requiredNumber,
  //   customer: requiredNumber,
  // },
};

const lcSchemas = {
  lc_business_license: {
    current_status: requiredString,
    lc_business_entity_id: requiredString,
    license_number: requiredNumber,
  },
  lc_department_section: {
    name: requiredString,
    notification_emails: yup
      .array()
      .typeError("Email(s) is Required")
      .of(yup.string().email("Must be a valid Email")),
  },
  lc_location_section: {
    location_number: requiredString,
  },
  lc_agencies_section: {
    agency_name: requiredString,
  },
  lc_license_type_section: {
    lc_licensing_agency_id: requiredString,
    category: requiredString,
    name: requiredNumber,
  },
};

// custom validationSchemaKey
const otherSchemas = {
  employeeLicenses: {
    current_status: requiredString,
    lc_license_type: yup
      .object({
        lc_licensing_agency_id: requiredNumber,
      })
      .required("Required Field"),
    lc_license_type_id: requiredNumber,
    license_number: requiredNumber,
  },

  collaborators: { collaborator_account_id: requiredString },
};

export const validationSchemaGenerator = (formPath) => {
  const schema = {
    ...pdSchemas,
    ...bdSchemas,
    ...otherSchemas,
    ...lcSchemas,
    ...adminSchema,
  }[formPath];

  if (!schema) {
    console.warn("No validation schema found");
  }

  return yup.object().shape(schema);
};
