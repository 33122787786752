import { Collapse, Grid } from "@material-ui/core";
import ControlledAutocomplete from "components/Common/ControlledAutocomplete/ControlledAutoComplete";
import FormikSelect from "components/Formik/fields/FormikSelect";
import { useMenuOptions } from "hooks/useMenuOptions";
import { useField, useFormikContext } from "formik";
import PropTypes from "prop-types";
import { useBdOrganizationId } from "queryHooks/useBdOrganizationId";
import useFetch from "queryHooks/useFetch";
import React from "react";
import { BusinessDisclosureDisclaimer } from "./Disclaimers";

export const formActions = {
  ADD_NEW: "ADD_NEW",
  ADD_EXISTING: "ADD_EXISTING",
  CLEAR: "CLEAR",
};

export const formStateReducer = (state, { type, dropdownValue }) => {
  switch (type) {
    case formActions.ADD_NEW:
      return {
        showRelationshipDropdown: true,
        dropdownValue,
      };
    case formActions.ADD_EXISTING:
      return {
        showRelationshipDropdown: false,
        dropdownValue,
      };
    case formActions.CLEAR:
      return {
        showRelationshipDropdown: false,
        dropDownValue: null,
      };

    default:
      return state;
  }
};

export const AddNewForm = ({ setIsNewRecord, filterOptions }) => {
  const { id } = useBdOrganizationId();
  const { data, status } = useFetch(
    "businessDisclosure?business_relationship_type=not_null",
    `bdOrganization/manage/${id}/businessDisclosure?business_relationship_type=not_null`,
    {
      useFullEndpoint: false,
      enabled: !!id,
    }
  );

  const { setValues } = useFormikContext();
  const [, meta] = useField("business_name");

  const { businessRelationshipType } = useMenuOptions();

  const [formState, dispatch] = React.useReducer(formStateReducer, {
    showRelationshipDropdown: false,
    dropdownValue: null,
  });

  const clearSelection = () => {
    dispatch({
      type: formActions.CLEAR,
    });
    setIsNewRecord(true);
    setValues({
      business_name: "",
      business_relationship_type: null,
    });
  };

  const onChange = (_event, selectedItem) => {
    if (selectedItem) {
      //user enters new custom business name
      if (selectedItem.inputValue) {
        dispatch({
          type: formActions.ADD_NEW,
          dropdownValue: { business_name: selectedItem.inputValue },
        });
        setIsNewRecord(true);
        setValues({
          business_name: selectedItem.inputValue,
          business_relationship_type: null,
        });

        //user selects from existing businesses
      } else {
        dispatch({
          type: formActions.ADD_EXISTING,
          dropdownValue: selectedItem,
        });
        setIsNewRecord(false);
        const { business_name, business_relationship_type, id } = selectedItem;
        setValues({
          business_name,
          business_relationship_type,
          id,
        });
      }
    } else clearSelection();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <BusinessDisclosureDisclaimer />
      </Grid>
      <ControlledAutocomplete
        label="Business Name"
        value={formState.dropdownValue || null}
        options={data}
        loading={status !== "success"}
        onChange={onChange}
        getOptionLabel={({ business_name }) => business_name}
        freeSolo
        filterOptions={filterOptions}
        helperText="(Type to add a new business)"
        meta={meta}
        // autoSelect enabled only if there is not already an option selected
        autoSelect={!formState.dropdownValue}
        autoHighlight
        disableClearable
        onFocus={(e) => {
          clearSelection();
          //prevents browser autocomplete
          if (e.target.autocomplete) {
            e.target.autocomplete = null;
          }
        }}
        disableEdit={() => null}
      />
      <Grid item xs={6}>
        <Collapse in={formState.showRelationshipDropdown}>
          <FormikSelect
            label="Relationship"
            name="business_relationship_type"
            options={businessRelationshipType}
            xs={12}
          />
        </Collapse>
      </Grid>
    </Grid>
  );
};
AddNewForm.propTypes = {
  setIsNewRecord: PropTypes.func,
  filterOptions: PropTypes.func,
};
