import { Box, Card, IconButton, Typography } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import CardBody from "components/Common/Containers/Card/CardBody";
import usePatchPassword from "queryHooks/usePatchPassword";
import React from "react";
import { LoadingButton } from "../../../Common/CustomButtons/LoadingButton";
import { SectionLabel } from "../../../Common/LayoutComponents/index";
import Logs from "./Logs";
export default function Security() {
  const { mutate, status, reset } = usePatchPassword();

  return (
    <>
      <Card variant="outlined">
        <CardBody>
          <Box mt={1} mb={2}>
            <Typography variant="h2" gutterBottom>
              Reset Password
            </Typography>
          </Box>
          <SectionLabel style={{ maxWidth: "10px" }}>
            If you need to reset your password, click the button below to
            <br />
            receive an email with instructions on changing your password.
          </SectionLabel>
          <Box pb={2} pt={3}>
            {status === "success" ? (
              <Box display="flex" alignItems="center">
                <Box pr={1}>
                  <SectionLabel>Email has been sent!</SectionLabel>
                </Box>
                <IconButton size="small" onClick={reset}>
                  <RefreshIcon />
                </IconButton>
              </Box>
            ) : (
              <LoadingButton
                loading={status === "loading"}
                onClick={mutate}
                color="primary"
              >
                Reset Password
              </LoadingButton>
            )}
          </Box>
          <Box mt={1} mb={2}>
            <Logs />
          </Box>
        </CardBody>
      </Card>
    </>
  );
}
