import React from "react";
import { governmentInvestigations } from "../../uiStrings";
import { fieldTypes } from "componentGenerators/formGenerator/config";

export const columns = [
  {
    title: "Agency Name",
    field: "law_enforcement_pd_business_entity.business_name",
  },
  { field: "nature" },
  { field: "testimony_date" },
];

export const tableQuestions = <>{governmentInvestigations.tableQuestion}</>;

export const nonRequired = [];

export const endpoint = "government_investigations_investigationLegalHistory";

export const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Nature & Details",
  },
  {
    type: fieldTypes.text,
    label: "Nature of Proceeding or Investigation",
    name: "nature",
    xs: 12,
  },
  {
    type: fieldTypes.yesNo,
    label: "Was Testimony Given?",
    name: "was_testimony_given",
  },
  {
    type: fieldTypes.date,
    label: "Testimony Date",
    name: "testimony_date",
  },
  {
    type: fieldTypes.text,
    label: "Time Period of Investigation",
    name: "time_period",
  },
  {
    type: fieldTypes.subHeader,
    label: "Law Enforcement or Court",
  },
  {
    type: fieldTypes.pdLawEnforcementSelect,
    name: "law_enforcement_pd_business_entity_id",
  },
];

export const modalTitle = "Investigation";
