import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FromToCurrent from "components/Formik/formGroups/FromToCurrent";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import FormikNumberField from "components/Formik/fields/FormikNumberField.jsx";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";
import { useMenuOptions } from "hooks/useMenuOptions.js";
import BDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/BDPersonSelect.jsx";

const PartnersForm = () => {
  const { bdPartnerTypes } = useMenuOptions();
  return (
    <Grid container spacing={2}>
      <BDPersonSelect label="Partner" header="Partner" />

      <SectionSubHeader label="Details" divider />
      <FromToCurrent />
      <FormikSelect name="partner_type" options={bdPartnerTypes} />
      <FormikTextField label="Professional Position" name="position" />
      <FormikNumberField
        label="Amount of initial investment"
        name="initial_investment_value"
        xs={6}
      />
      <FormikNumberField
        label="Amount of Additional Contributions"
        name="additional_contributions_value"
        xs={6}
      />
      <FormikTextField
        label="Nature of any anticipated future investments"
        name="nature_of_future_investments"
        xs={6}
      />
      <FormikNumberField
        label="Amount of any anticipated future investments"
        name="future_investments_value"
        xs={6}
      />
      <FormikTextField
        label="Method of distributing profits"
        name="method_of_distribution"
        xs={6}
      />
      <FormikTextField
        label="Description of Participation (Degree of Control)"
        name="participation_description"
        multiline
        rows={2}
        xs={12}
      />
      <SectionSubHeader label="For Former Partners" divider />
      <FormikTextField
        label="Reason for Separation"
        name="reason_left"
        xs={6}
      />
      <FormikTextField
        label="Terms of Separation"
        name="separation_terms"
        xs={6}
      />
    </Grid>
  );
};

const Partners = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "bdPosition?related_bd_party_role=partner",
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <PartnersForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default Partners;
