import { InputAdornment, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import React from "react";
import styled, { css } from "styled-components";

const StyledTextField = styled(TextField)`
  ${({ $variant, theme }) =>
    $variant === "primary" &&
    css`
      & label.Mui-focused {
        color: ${theme.palette.primary.contrastText};
      }
      & label {
        color: ${theme.palette.primary.contrastText};
      }
      & .MuiInput-underline {
        color: ${theme.palette.primary.contrastText};
      }
    `}
`;

export const SearchInput = ({ colorVariant, disableUnderline, ...props }) => (
  <StyledTextField
    $variant={colorVariant}
    size="small"
    InputProps={{
      disableUnderline: Boolean(disableUnderline),
      startAdornment: (
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      ),
    }}
    {...props}
  />
);
