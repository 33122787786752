import { useFormikContext } from "formik";
import useDeleteDialog from "hooks/useDeleteDialog";
import useTableRecord from "hooks/tableHooks/useTableRecord";
import useInitialValues from "hooks/useInitialValues";
import { v4 as uuidv4 } from "uuid";
import React from "react";

const removeRecordById = (records, selectedId) =>
  records.filter((record) => record.tableId !== selectedId);

const addTableIds = (values) =>
  values.map((record) => ({ ...record, tableId: uuidv4() }));

const removeTableIds = (values) =>
  values.map((record) => {
    const { tableId: _tableId, ...rest } = record;
    return rest;
  });

const useFormikInlineTable = ({ name, defaultInitialValues }) => {
  const { values, setFieldValue } = useFormikContext();

  const [records, setRecords] = React.useState([]);

  React.useEffect(() => {
    setRecords(addTableIds(values[name]));
  }, [name, values]);

  const { reset, openDeleteDialog, ...deleteDialogProps } = useDeleteDialog({
    handleConfirm: (selectedId) => {
      setFieldValue(
        name,
        removeTableIds(removeRecordById(records, selectedId))
      );
      reset();
    },
  });

  const {
    currentRecord,
    isNewRecord,
    clearCurrentRecord,
    handleAddRecord,
    handleEditRecord,
  } = useTableRecord({ records, emptyRecord: defaultInitialValues });

  const initialValues = useInitialValues(currentRecord, defaultInitialValues);

  const open = Boolean(currentRecord);

  const addRecord = (newValue) => {
    setFieldValue(name, [...removeTableIds(records), newValue]);
  };

  const editRecord = (newValue) => {
    const editedRecords = records.map((record) => {
      if (currentRecord.tableId === record.tableId) return newValue;
      return record;
    });
    setFieldValue(name, removeTableIds(editedRecords));
  };

  const onSubmit = (newValue, { resetForm }) => {
    if (isNewRecord) addRecord(newValue);
    else editRecord(newValue);
    resetForm();
    clearCurrentRecord();
  };

  const formikProps = {
    initialValues,
    open,
    onSubmit,
    onCancel: clearCurrentRecord,
  };

  const tableProps = {
    handleEditRecord,
    handleAddRecord,
    handleDeleteRecord: openDeleteDialog,
    isDeleting: deleteDialogProps.open,
    confirmDelete: deleteDialogProps.onConfirm,
    cancelDelete: deleteDialogProps.onCancel,
  };

  return {
    records,
    tableProps,
    formikProps,
  };
};

export default useFormikInlineTable;
