import { Box, Link } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { DataGrid } from "@material-ui/data-grid";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { makeStyles } from "@material-ui/styles";
import FileIcon from "components/Common/DataDisplay/FileIcon";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    border: 0,
    borderRadius: 0,
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: palette.grey[100],
      color: palette.primary.main,
    },
  },
  columnHead: {
    "& .actions-column-header": {
      backgroundColor: palette.grey[100],
    },
  },
}));

const getColumns = ({
  dynamicColumns,
  handleViewFile,
  // handleDownloadFile,
  handleDeleteFile,
}) => {
  const additionalColumns = dynamicColumns.map(({ id, label }) => ({
    field: id,
    headerName: label,
    width: 200,
  }));

  const columns = [
    {
      field: "",
      headerName: "Actions",
      disableClickEventBubbling: true,
      width: 100,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      headerClassName: "actions-column-header",
      // eslint-disable-next-line react/display-name
      renderCell: (cellData) => (
        <ActionsCell
          handleClickView={handleViewFile}
          // handleDownloadFile={handleDownloadFile}
          handleDeleteFile={handleDeleteFile}
          {...cellData}
        />
      ),
    },
    {
      field: "name",
      headerName: "File Name",
      width: 300,
      // eslint-disable-next-line react/display-name
      renderCell: (cellData) => (
        <FileNameCell handleClickView={handleViewFile} {...cellData} />
      ),
    },
  ];

  return [...columns, ...additionalColumns];
};

const FileNameCell = ({
  handleClickView,
  row: { name, id, attachment_group_id },
}) => (
  <Link
    onClick={(e) => {
      e.preventDefault();
      handleClickView(id, attachment_group_id);
    }}
    style={{ cursor: "pointer", overflow: "auto" }}
  >
    <Box display="flex" alignItems="center">
      <Box display="flex" alignItems="center" mr={1} fontSize="20px">
        <FileIcon fileName={name} />
      </Box>
      {name}
    </Box>
  </Link>
);

FileNameCell.propTypes = {
  handleClickView: PropTypes.func,
  row: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    attachment_group_id: PropTypes.number,
  }),
};

const ActionsCell = ({
  handleClickView,
  handleDeleteFile,
  row: { id, attachment_group_id },
}) => {
  return (
    <Box display="flex" justifyContent="center" width={1}>
      <Box pr={1}>
        <IconButton
          size="small"
          onClick={() => handleClickView(id, attachment_group_id)}
        >
          <VisibilityIcon />
        </IconButton>
      </Box>

      <Box>
        <IconButton size="small" onClick={() => handleDeleteFile(id)}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

ActionsCell.propTypes = {
  handleClickView: PropTypes.func,
  handleDownloadFile: PropTypes.func,
  handleDeleteFile: PropTypes.func,
  row: PropTypes.shape({
    id: PropTypes.number,
    attachment_group_id: PropTypes.number,
  }),
};

export const DocumentsDataGrid = ({
  documents,
  handleViewFile,
  // handleDownloadFile,
  onSelectionModelChange,
  handleDeleteFile,
  columns,
}) => {
  const classes = useStyles();
  return (
    <div style={{ height: 730, width: "100%" }} className={classes.columnHead}>
      <DataGrid
        classes={{
          root: classes.root,
        }}
        onSelectionModelChange={onSelectionModelChange}
        rows={documents}
        columns={getColumns({
          dynamicColumns: columns,
          handleViewFile,
          // handleDownloadFile,
          handleDeleteFile,
        })}
        checkboxSelection
        disableSelectionOnClick
      />
    </div>
  );
};

DocumentsDataGrid.propTypes = {
  documents: PropTypes.array,
  handleDownloadFile: PropTypes.func,
  handleViewFile: PropTypes.func,
  onSelectionModelChange: PropTypes.func,
  handleDeleteFile: PropTypes.func,
  columns: PropTypes.array,
};
