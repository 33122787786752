import { Box, Grid } from "@material-ui/core";
import { SectionLabel } from "components/Common/LayoutComponents";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";
import DisplayTable from "components/Common/Tables/DisplayTable/index.js";
import Tooltip from "components/Common/Tooltip";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import YesNo from "components/Formik/formGroups/YesNo";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm";
import useFetch from "queryHooks/useFetch.js";
import React from "react";
import {
  defaultInitialValues,
  IllinoisLicensesAndPermitsItems,
} from "./config";

const { questions } = IllinoisLicensesAndPermitsItems;

const LincensesAndPermitsForm = () => {
  return (
    <Grid container spacing={2}>
      <YesNo
        label={questions["pd_q32_boolean"]}
        name="illinois.pd_q32_boolean"
        xs={12}
        tooltip={
          <Tooltip title="Act: Illinois Gambling Act, and Sports Wagering Act" />
        }
      />
      <SectionLabel label="If yes, enter details" />

      <FormikTextField
        label="Details"
        multiline
        rows={4}
        name="illinois.pd_q32_details"
        xs={12}
      />
    </Grid>
  );
};

const IllinoisLicensesAndPermits = ({ name }) => {
  const formData = useForm({
    defaultInitialValues,
    endpoint: "illinois_licenses_and_permits_pdSupplement",
    noValidate: true,
  });

  const { data: licensePersonal = [], status } = useFetch(
    "illinois_licenses_and_permits_licenses_applicant_personal"
  );

  const { data: licenseGaming = [], status: status2 } = useFetch(
    "illinois_licenses_and_permits_license_gaming_or_liquor_denied_business"
  );

  return (
    <>
      <FormSectionWrapper title={name}>
        <FormContextProvider formData={formData}>
          <EnhancedFormikPage>
            <LincensesAndPermitsForm />
          </EnhancedFormikPage>
        </FormContextProvider>

        <Box mb={1} bgcolor="white">
          <LoadingComponent status={status}>
            <DisplayTable
              records={licensePersonal}
              linkTo={["personal_licensing"]}
              columns={[
                { field: "type", format: "startCase" },
                { field: "license_type" },
                {
                  title: "Agency Name",
                  field: "agency_name",
                },
                {
                  title: "Business Name",
                  field: "business_name",
                },
              ]}
              dependsOnQueryCount="Have you had any business, technical or professional licenses, permits, certifications that you have held, applied for, or otherwise been affiliated with, in any state or jurisdiction, including Illinois?
            If yes, please review all your licenses below and ensure they are classified correctly. If necessary, please add any missing licenses using the button below"
            />
          </LoadingComponent>
        </Box>
        <Box mb={1} bgcolor="white">
          <LoadingComponent status={status2}>
            <DisplayTable
              records={licenseGaming}
              linkTo={["business_licensing"]}
              columns={[
                { field: "type", format: "startCase" },
                { field: "license_type" },
                { field: "agency_name" },
                { field: "business_name" },
              ]}
              dependsOnQueryCount="Has any Business Entity which you are, or have been associated with in any capacity, been denied a Gaming license, or a related finding of suitability, a license to sell or distribute Alcoholic Beverages, or any other right or privilege by any agency or governmental entity, in any jurisdiction regulating Gaming, or the sale or distribution of Alcoholic Beverages?"
            />
          </LoadingComponent>
        </Box>
      </FormSectionWrapper>
    </>
  );
};

export default IllinoisLicensesAndPermits;
