import { Box, Chip } from "@material-ui/core";
import DeleteConfirmation from "components/Common/Dialogs/DeleteConfirmation";
import React from "react";
import styled from "styled-components";
import useTabularData from "hooks/tableHooks/useTabularData";
import { FormikDialog } from "components/Formik/formContainers/FormikDialog";
import ActionsTable from "components/Common/Tables/Common/index";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import { formatValuesForSubmission, sortByFromDate } from "../helpers";
import { AddressesForm } from "./AddressesForm";
import { address } from "helpers/initialValues";
import { generateFullAddress } from "helpers/addressHelpers";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const StyledChip = styled(Chip)`
  margin: ${({ theme }) => theme.spacing(0.5, 0.5, 0, 0.5)};
`;

const defaultInitialValues = {
  purpose_checkbox: null,
  description: "",
  branch_name: "",
  from_date: null,
  to_date: null,
  phone: "",
  website: "",
  fax: "",
  email: "",
  address: address,
  is_current: false,
};

const AddressCell = ({ address }) => {
  return (
    <Box>
      {address ? <>{generateFullAddress(address)}</> : "No Address Provided"}
    </Box>
  );
};

const ExpandeableChip = ({ label }) => {
  return <StyledChip size="small" label={label} />;
};

const PurposesCell = ({
  purpose_checkbox: {
    is_registered_business_address,
    is_principal_activity_conducted,
    is_branch_office,
    is_mailing_address,
  },
}) => {
  return (
    <Box display="flex" flexWrap="wrap">
      {is_registered_business_address && <ExpandeableChip label="Registered" />}
      {is_principal_activity_conducted && <ExpandeableChip label="Principal" />}
      {is_branch_office && <ExpandeableChip label="Branch" />}
      {is_mailing_address && <ExpandeableChip label="Mailing" />}
    </Box>
  );
};

const columns = [
  {
    field: "address",
    title: "Address",
    render: AddressCell,
    disableSorting: true,
  },
  {
    field: "from_date",
    title: "From Date",
    sortType: "date",
  },
  {
    field: "to_date",
    title: "To Date",
    sortType: "date",
  },
  {
    field: "purposes",
    title: "Purposes",
    render: PurposesCell,
    disableSorting: true,
  },
];

const ApplicantInstructions = () => (
  <>
    <>
      Provide addresses at which this business entity has been registered or has
      conducted business for the past ten (10) years. Include:
    </>
    <ul>
      <li>Registered Business Address</li>
      <li>Address(es) where Principal Activities are Conducted</li>
      <li>Branch offices</li>
    </ul>
  </>
);

export const AddressesTab = ({ businessEntityId }) => {
  const formatValuesForPost = (values) => ({
    ...formatValuesForSubmission(values),
  });

  const {
    data,
    status,
    deleteDialogProps,
    formikProps: { initialValues, ...formikProps },
    tableProps,
  } = useTabularData({
    defaultInitialValues,
    endpoint: `bdAddress`,
    queryKey: ["bdAddress", businessEntityId],
    patchValues: formatValuesForSubmission,
    postValues: formatValuesForPost,
    nonRequired: [
      "description",
      "branch_name",
      "address_type",
      "phone",
      "website",
      "fax",
      "email",
    ],
    noValidate: true,
  });

  const records = React.useMemo(() => {
    return status === "success" ? sortByFromDate(data) : [];
  }, [data, status]);

  return (
    <LoadingComponent status={status}>
      <DeleteConfirmation {...deleteDialogProps} />
      <FormikDialog
        {...formikProps}
        initialValues={initialValues}
        title="Add / Edit Address"
        maxWidth="md"
      >
        <AddressesForm />
      </FormikDialog>
      <Box pb={2}>
        <InfoAlert>
          <ApplicantInstructions />
        </InfoAlert>
      </Box>

      <ActionsTable
        {...tableProps}
        title="Addresses"
        addButtonText="Add Address"
        columns={columns}
        records={records}
      />
    </LoadingComponent>
  );
};
