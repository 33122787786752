import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "License Type",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "colorado_sbba.license_type_checkbox",
    dynamicMenuItems: ["coloradoSBBALicenseTypeCheckbox"],
    defaultValue: null,
  },
  {
    type: fieldTypes.text,
    label: "Name of business associated with:",
    name: "colorado_sbba.business_associated_name",
    xs: 6,
  },

  {
    type: fieldTypes.bdPersonSelect,
    label: "Primary Contact Person",
    header: "Primary Contact Person",
    name: "colorado_sbba.co_sbba_primary_contact_person_id",
    xs: 6,
  },

  {
    type: fieldTypes.bdPersonSelect,
    label: "Person",
    header: "Business Records Contact Person",
    helperText: "Select Person who maintain applicant’s business records",
    name: "colorado_sbba.co_sbba_maintain_records_person_id",
    xs: 6,
  },

  {
    type: fieldTypes.bdPersonSelect,
    label: "Person",
    header: "Tax, Forms and Reports Contact Person",
    name: "colorado_sbba.co_sbba_prepare_govt_forms_person_id",
    helperText:
      "Select Person who prepares applicant’s tax returns, government forms & reports",
    xs: 6,
  },
  {
    type: fieldTypes.subHeader,
    label: "Colorado Licenses",
    divider: true,
  },
  {
    type: fieldTypes.text,
    label: "Colorado Sales Tax License #",
    name: "colorado_sbba.sales_tax_license",
    xs: 6,
  },
  {
    type: fieldTypes.text,
    label: "Colorado Liquor License #",
    name: "colorado_sbba.liquor_license_number",
    xs: 6,
  },
  {
    type: fieldTypes.text,
    label: "Name of Liquor License Holder",
    name: "colorado_sbba.liquor_license_holder_name",
    xs: 6,
  },

  {
    type: fieldTypes.subHeader,
    label: "Master Application Only",
    divider: true,
  },
  {
    type: fieldTypes.text,
    label: "Gaming Retailer License #",
    name: "colorado_sbba.gaming_retailer_license_number",
    xs: 6,
  },
  {
    type: fieldTypes.gridItem,
    xs: 6,
  },
  {
    type: fieldTypes.text,
    label: "Anticipated # of Total Devices",
    name: "colorado_sbba.total_devices",
    xs: 6,
  },
  {
    type: fieldTypes.text,
    label: "Anticipated # of Slot / Video Machines",
    name: "colorado_sbba.slot_machine_count",
    xs: 6,
  },
  {
    type: fieldTypes.text,
    label: "Anticipated # of Black Jack Tables",
    name: "colorado_sbba.black_jack_table_count",
    xs: 6,
  },
  {
    type: fieldTypes.text,
    label: "Anticipated # of Poker Tables",
    name: "colorado_sbba.poker_table_count",
    xs: 6,
  },
  {
    type: fieldTypes.text,
    label: "Total Square Footage of Building",
    name: "colorado_sbba.building_area",
    xs: 6,
  },
  {
    type: fieldTypes.text,
    label: "Total Square Footage to be Licensed",
    name: "colorado_sbba.gaming_area",
    tooltip: "To be licensed for gaming and sport betting operators",
    xs: 6,
  },
];

export default [
  {
    type: "form",
    formSchema,
    endpoint: "bdSupplement",
  },
];
