export const tableQuestions = null;

export const defaultInitialValues = {
  bd_pennsylvania_eea: {
    bd_pa_eea_contact_bd_person_id: null,
    bd_pa_eea_billing_contact_bd_person_id: null,
  },
};

export const endpoint = "bdSupplement";

export const tabularDataParams = {
  defaultInitialValues,
  endpoint,
};
