import React from "react";
import { Select, InputLabel, FormControl, MenuItem } from "@material-ui/core";
import {
  usePdfDispatch,
  usePdfState,
  ACTIONS,
} from "components/DevTools/PdfTools/Context/PdfToolsProvider.jsx";

export default function TimeRange() {
  const { tablesWithDates, timePeriod, timeRange, selected } = usePdfState();
  const dispatch = usePdfDispatch();
  const handleChangeTimePeriod = (e) => {
    dispatch({
      type: ACTIONS.SET_STATE,
      value: e.target.value,
      name: "timePeriod",
    });
  };

  const handleChangeTimeRange = (e) => {
    dispatch({
      type: ACTIONS.SET_STATE,
      value: e.target.value,
      name: "timeRange",
    });
    dispatch({
      type: ACTIONS.SET_STATE,
      value: "*" + timePeriod + ">" + e.target.value + "y",
      name: "timeFilter",
    });
  };

  if (
    typeof tablesWithDates === "object" &&
    selected &&
    tablesWithDates[selected]
  ) {
    return (
      <div>
        <FormControl
          variant="filled"
          style={{ width: "150px", marginRight: "10px" }}
        >
          <InputLabel>Time filter</InputLabel>
          <Select value={timePeriod} onChange={handleChangeTimePeriod}>
            {tablesWithDates[selected].map((val) => (
              <MenuItem key={val} value={val}>
                {val}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {timePeriod ? (
          <FormControl
            variant="filled"
            style={{ width: "150px", marginRight: "10px" }}
          >
            <InputLabel>Range Years</InputLabel>
            <Select value={timeRange} onChange={handleChangeTimeRange}>
              {new Array(30).fill(0).map((val, i) => (
                <MenuItem key={i + 1} value={i + 1}>
                  {i + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <></>
        )}
      </div>
    );
  } else {
    return <></>;
  }
}
