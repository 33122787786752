import { formats } from "components/Common/Tables/Common/helpers";
export const columns = [
  {
    title: "Type name",
    field: "name",
  },
  {
    field: "lc_licensing_agency.agency_name",
    title: "Agency Name",
  },
  {
    field: "category",
    format: formats.startCase,
  },
];

export const defaultInitialValues = {
  name: "",
  category: null,
  lc_licensing_agency_id: null,
};

export const sortingOptions = {
  defaultSortBy: "name",
  defaultSortOrder: "asc",
};

export const endpoint = "licensing/lcLicenseType";
