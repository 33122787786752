import React from "react";

import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import {
  columns,
  endpoint,
  defaultInitialValues,
  tableQuestions,
} from "./config.js";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import useTabularData from "hooks/tableHooks/useTabularData";
import { Grid } from "@material-ui/core";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import FormikNumberField from "components/Formik/fields/FormikNumberField.jsx";
import FromToCurrent from "components/Formik/formGroups/FromToCurrent";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField.jsx";
import BDPartySelect from "components/Formik/formGroups/CrudSelect/selects/BDPartySelect.jsx";

const OwnersForm = () => {
  return (
    <Grid container spacing={2}>
      <BDPartySelect
        name="related_bd_party_id"
        label="Owner"
        header="Owner"
        xs={6}
      />
      <SectionSubHeader label="Details" divider />
      <FromToCurrent />
      <FormikNumberField
        type="percent"
        label="Ownership Percentage"
        name="ownership_percent"
        xs={4}
      />
      <SectionLabel label="Describe nature, type, terms and conditions of ownership." />
      <FormikTextField
        rows={4}
        label="Nature, Term and Conditions"
        name="nature_terms_conditions_of_ownership"
        xs={12}
      />
      <SectionLabel label="Attach a copy of the contract or other instrument relating to the conditions under which the owner of record or subscriber holds and votes or has subscribed for the applicant’s stock." />
      <FormikFileDropzone
        description="Owner Stocks Contract"
        name="bd_position_attachment_group_id"
      />
    </Grid>
  );
};

const Owners = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint,
    nonRequired: ["bd_position_attachment_group_id"],
  });
  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <OwnersForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default Owners;
