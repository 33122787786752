import { Form, Formik } from "formik";
import React from "react";
import FormikErrorScroll from "components/Formik/other/FormikErrorScroll";
import YesNo from "components/Formik/formGroups/YesNo";
import SaveButton from "components/Common/CustomButtons/SaveButton";
import styled from "styled-components";
import { address } from "helpers/initialValues";

import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";

import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import useForm from "hooks/useForm";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormikGoogleLookup from "components/Formik/fields/FormikGoogleLookup";
import { FormikAddressFields } from "components/Formik/formGroups/FormikAddressFields";

const StyledCard = styled(Card)`
  & .MuiCardContent-root {
    padding-bottom: 8px;
  }
`;

const ActionsFooter = ({ onSubmit }) => (
  <Box>
    <SaveButton onClick={onSubmit}>Save</SaveButton>
  </Box>
);

export const ChildrenQuestions = ({
  onSubmit: _onSubmit,
  children: _children,
  title,
  ...rest
}) => {
  const { formikProps } = useForm({
    defaultInitialValues: {
      has_child_support_court_order: null,
      is_child_support_compliant: null,
      has_child_support_arrears: null,
      has_child_support_arrears_six_months: null,
      failed_child_support_health_insurance: null,
      failed_response_to_child_support_subpoena: null,
      subject_of_child_support_arrest: null,
      child_agency_name: "",
      child_agency_contact_person: "",
      child_support_court_attachment_group_id: null,
      child_agency_address: address,
      child_agency_phone: "",
    },
    endpoint: "children_personalDisclosure",
  });
  const noValidationSchema = { ...formikProps };
  delete noValidationSchema["validationSchema"];

  return (
    <>
      <StyledCard variant="outlined">
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2">{title}</Typography>
            </Grid>
          </Grid>

          <Formik enableReinitialize {...noValidationSchema} {...rest}>
            {({ submitForm }) => (
              <Form>
                <FormikErrorScroll />
                <Grid container spacing={2}>
                  <ChildrenQuestionsForm />
                  <Divider />
                  <Box pb={2} pl={1}>
                    <ActionsFooter onSubmit={submitForm} />
                  </Box>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </StyledCard>
    </>
  );
};

const ChildrenQuestionsForm = () => {
  return (
    <>
      <Grid item>
        <SectionSubHeader>Additional Information</SectionSubHeader>
      </Grid>
      <SectionLabel label="Please certify, under penalty of perjury, the following:" />
      <YesNo
        label={` Do you currently have a child support obligation?`}
        name="has_child_support_court_order"
      />
      <YesNo
        label={` Are you in compliance to a child support court order, with a plan approved by the public agency/court?`}
        name="is_child_support_compliant"
      />
      <YesNo
        label={`If "Yes", are you in arrears in payment of said obligations?`}
        name="has_child_support_arrears"
      />
      <YesNo
        label={`If "Yes", does the arrears relate to a period of six months?`}
        name="has_child_support_arrears_six_months"
      />
      <YesNo
        label={`Have you failed to provide any court-ordered health insurance?`}
        name="failed_child_support_health_insurance"
      />
      <YesNo
        label={`Have you failed to respond to a subpoena relating to either a
                paternity or child-support proceeding?`}
        name="failed_response_to_child_support_subpoena"
      />
      <YesNo
        label={`Are you subject to a child-support-related arrest warrant?`}
        name="subject_of_child_support_arrest"
      />

      <SectionSubHeader label="Child support agency information" />
      <SectionLabel label="If applicable, identify the agency/court responsible for enforcing the child support order." />
      <FormikTextField label="Child Agency Name" name="child_agency_name" />
      <FormikTextField
        label="Child Agency Contact"
        name="child_agency_contact_person"
      />
      <FormikTextField label="Child Agency Phone" name="child_agency_phone" />
      <FormikGoogleLookup
        fields={{
          address: "child_agency_address",
        }}
      />

      <FormikAddressFields addressPrefix="child_agency_address." />
      <SectionLabel label="Upload a copy of each court order" />
      <FormikFileDropzone
        description="Child Support Court Order"
        name="child_support_court_attachment_group_id"
      />
    </>
  );
};

export default ChildrenQuestions;
