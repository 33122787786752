import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.yesNo,
    label:
      "Have you ever been convicted of any gambling-related felony at any time?",
    name: "colorado_gsbsla.important_notice_q1_bool",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Have you ever been convicted of any felony involving theft by deception (including, but not limited to, embezzlement and other thefts using fraud, trickery, scams, con games, illegal schemes, etc.) at any time?",
    name: "colorado_gsbsla.important_notice_q2_bool",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Have you ever been convicted of any felony involving fraud or misrepresentation (including, but not limited to, bad checks, fraud, forgery, perjury, tax or welfare fraud, and crimes involving false documentation) at any time?",
    name: "colorado_gsbsla.important_notice_q3_bool",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Have you served a sentence, including probation or parole, within the past 10 years upon conviction for any felony, even if the conviction occurred more than 10 years ago?",
    name: "colorado_gsbsla.important_notice_q4_bool",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Have you served a sentence, including probation or parole, within the past 10 years upon conviction of any misdemeanor gambling-related offense, even if the conviction occurred more than 10 years ago?",
    name: "colorado_gsbsla.important_notice_q5_bool",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Have you served a sentence, including probation or parole, within the past 10 years upon conviction of any misdemeanor theft by deception offense (including, but not limited to, embezzlement and other thefts using fraud, trickery, scams, con games, illegal schemes, etc.), even if the conviction occurred more than 10 years ago?",
    name: "colorado_gsbsla.important_notice_q6_bool",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Have you served a sentence, including probation or parole, within the past 10 years upon conviction of any misdemeanor involving fraud or misrepresentation (including, but not limited to, bad checks, fraud, forgery, perjury, tax or welfare fraud, and crimes involving false documentation), even if the conviction occurred more than 10 years ago?",
    name: "colorado_gsbsla.important_notice_q7_bool",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Are you currently being prosecuted or facing pending charges, in any jurisdiction, for any of the above offenses, or are you on a deferred prosecution or a deferred judgment and sentence for any of the above offenses?",
    name: "colorado_gsbsla.important_notice_q8_bool",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Have you ever been convicted of professional gambling as defined by Colorado law?",
    name: "colorado_gsbsla.important_notice_q9_bool",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Have you ever been identified as a career offender or a member of a career offender cartel?",
    name: "colorado_gsbsla.important_notice_q10_bool",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Have you ever refused to cooperate with any legislative body or other official investigatory body involved in the investigation of crimes related to gaming, official corruption or organized crime?",
    name: "colorado_gsbsla.important_notice_q11_bool",
  },
  {
    type: fieldTypes.yesNo,
    label: "Are you under 21 years of age at the time of this application?",
    name: "colorado_gsbsla.important_notice_q12_bool",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Are you the spouse or child living in the household of any person employed by the Colorado Division of Gaming or the Limited Gaming Control Commission?",
    name: "colorado_gsbsla.important_notice_q13_bool",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Are you an officer, reserve police officer, agent, or employee of any law enforcement agency of the state of Colorado with the authority to investigate or prosecute crime in Teller or Gilpin counties or of any law enforcement agency or detention or correctional facility within Teller or Gilpin counties?",
    name: "colorado_gsbsla.important_notice_q14_bool",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Are you a district, county or municipal court judge whose jurisdiction includes all or part of Teller or Gilpin counties?",
    name: "colorado_gsbsla.important_notice_q15_bool",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Are you an elected municipal official or county commissioner of Teller or Gilpin counties or the cities of Central City, Black Hawk or Cripple Creek?",
    name: "colorado_gsbsla.important_notice_q16_bool",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Are you a Central City, Black Hawk or Cripple Creek city manager or planning commission member?",
    name: "colorado_gsbsla.important_notice_q17_bool",
  },
  {
    type: fieldTypes.subHeader,
    label: "Important Notice ",
  },
  {
    type: fieldTypes.label,
    label:
      "If you answered YES to any of the above questions, by Colorado law you cannot obtain or hold a Colorado gaming license. DO NOT CONTINUE WITH OR TURN IN THIS APPLICATION.",
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "colorado_gsbsla_supplement_important_notice_pdSupplement",
};
