import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";
import { SectionSubHeader } from "components/Common/LayoutComponents/index.js";

import FormikAsyncSelect from "components/Formik/fields/FormikAsyncSelect.jsx";
import CustomLink from "components/Common/Link";
import FormikNumberField from "components/Formik/fields/FormikNumberField.jsx";
import BDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/BDPersonSelect.jsx";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <BDPersonSelect
        name="bd_party_id"
        label="Option Holder"
        header="Option Holder"
      />

      <SectionSubHeader label="Option" />
      <Grid item xs={6}>
        <FormikAsyncSelect
          name="bd_security_structure_id"
          endpoint="bdSecurityStructure?security_type=option&security_type=warrant&security_type=right"
          label="Option, Warrant or Right"
          getOptionLabel={(option) =>
            option.option_name + ", Expires: " + option.option_expiration_date
          }
          getOptionValue={({ id }) => id}
          xs={12}
        />
        <CustomLink to="bd_security_options" />
      </Grid>

      <SectionSubHeader label="Details" divider />
      <FormikNumberField
        label="Number of Voting Shares"
        name="voting_shares_held_count"
        type="none"
        xs={4}
      />

      <FormikNumberField
        label="Number of Non-Voting Shares"
        name="non_voting_shares_held_count"
        type="none"
        xs={4}
      />

      <FormikNumberField
        label="Market Value at Issuance"
        name="issuance_market_value"
        type="dollar"
        xs={4}
      />
    </Grid>
  );
};

const SecurityOptionsHolders = ({ name }) => {
  const formData = useTabularData({
    overwriteMissingRequiredDisplay: {
      option_name: "Option Name",
      voting_shares_held_count: "Voting Shares",
      non_voting_shares_held_count: "Non-Voting Shares",
    },
    defaultInitialValues,
    endpoint:
      "bdShareholder?bd_security_structure.security_type=option&bd_security_structure.security_type=warrant&bd_security_structure.security_type=right",
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default SecurityOptionsHolders;
