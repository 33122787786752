import React from "react";

export const columns = [
  {
    field: "from_date",
  },
  {
    field: "to_date",
  },
  { title: "Business Name", field: "pd_business_entity.business_name" },
  {
    title: "Relative",
    field: "relative_pd_person.full_name",
  },
  {
    field: "position",
  },
];

export const tableQuestions = (
  <>
    List all government positions and offices, whether salaried or unsalaried,
    held by you during the last ten (10) year period.
  </>
);

export const familyMemberHelperText =
  "Certain Jurisdictions require you to report Government Positions held by immediate family members in the jurisdiction to which you are applying. Unless you have been instructed otherwise, only enter information for positions which YOU have held. If Family Positions are needed you will be prompted to provide this information in the supplemental section for that Jurisdiction.";

export const nonRequired = ["relative_pd_person_id"];

export const defaultInitialValues = {
  type: "government",
  pd_business_entity_id: null,
  relative_pd_person_id: null,
  position: "",
  duties: "",
  from_date: null,
  to_date: null,
  is_current: false,
};

export const endpoint = "government_positions_governmentOfficePosition";
