import { Grid } from "@material-ui/core";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import React from "react";
import FormikAsyncSelect from "components/Formik/fields/FormikAsyncSelect";
import { dayjsUTC } from "helpers/apiHelpers.js";
import YesNo from "components/Formik/formGroups/YesNo";
import CustomLink from "components/Common/Link";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Select license up for resubmission" />
      <SectionLabel
        label={
          <>
            Please review and verify your submission date for with this
            <CustomLink to="business_licensing" /> and associated
            <CustomLink to="employment_history" />
          </>
        }
      />
      <FormikAsyncSelect
        label="Submission date • License Number • Business Name"
        name="new_jersey_35_qr.associated_license_id"
        endpoint="new_jersey_35_qr_disclosure_questions_licenses"
        getOptionLabel={({ application_date, business_name, license_number }) =>
          `${dayjsUTC(
            application_date,
            "MM/DD/YYYY"
          )} • ${license_number} • ${business_name}`
        }
        getOptionValue={({ id }) => id}
        xs={12}
      />
      <YesNo
        label="Has your residence changed since your most recent submission?"
        name="new_jersey_35_qr.residence_change_boolean"
      />
      <YesNo
        label="Do you hold any positions for which you are compensated outside of your employment with the license?"
        name="new_jersey_35_qr.compensated_position_boolean"
      />
      <YesNo
        label="Has your employment changed since your most recent submission?"
        name="new_jersey_35_qr.employment_change_boolean"
      />
      <YesNo
        label="Have you had any license, permit or certification denied, suspended, or revoked by any government agency in New Jersey or elsewhere since your last submission?"
        name="new_jersey_35_qr.license_denied_boolean"
      />
      <YesNo
        label="Has any entity in which you were a director, officer, partner, or held/hold a 5% or greater interest, had a license, permit or certification denied, revoked or suspended since your last submission?"
        name="new_jersey_35_qr.business_license_disposition_boolean"
      />
      <YesNo
        label="Have you or your spouse acquired a greater than 5% ownership interest in any business(es) since your last submission?"
        name="new_jersey_35_qr.new_ownership_boolean"
      />
      <YesNo
        label="Have you or any member of your immediate family been arrested for or charged with any offenses which includes felonies, misdemeanors, disorderly persons offenses, or petty disorderly offenses, or other such violations of a criminal code that have NOT been expunged or sealed, in New Jersey or elsewhere, since your last submission?"
        name="new_jersey_35_qr.criminal_charge_boolean"
      />
      <YesNo
        label="Have you been arrested for, charged with, or convicted of driving while intoxicated/impaired, violating probation, or any other court order since your last submission?"
        name="new_jersey_35_qr.criminal_charge_driving_probation_boolean"
      />
      <YesNo
        label="Have you been named as an unindicted co-conspirator in New Jersey or any other jurisdiction since your last submission?"
        name="new_jersey_35_qr.coconspirator_boolean"
      />
      <YesNo
        label="Have you been named as a party to a lawsuit in any jurisdiction since your last submission?"
        name="new_jersey_35_qr.lawsuit_party_boolean"
      />
      <YesNo
        label=" Has any business in which you or your spouse hold a greater than 5% ownership interest or serve as an officer, director or partner, been adjudicated or filed a petition for any type of bankruptcy?"
        name="new_jersey_35_qr.bankruptcy_business_boolean"
      />
    </Grid>
  );
};

export default Form;
