import React from "react";

const useTableRecord = ({
  records,
  emptyRecord,
  isNewRecordInitialState = false,
  currentRecordInitialState = null,
}) => {
  const [currentRecord, setCurrentRecord] = React.useState(
    currentRecordInitialState
  );
  const [isNewRecord, setIsNewRecord] = React.useState(isNewRecordInitialState);
  const [isView, setIsView] = React.useState(false);
  const clearCurrentRecord = () => setCurrentRecord(null);
  const handleAddRecord = () => {
    setIsNewRecord(true);
    setIsView(false);
    setCurrentRecord(emptyRecord);
  };
  const handleEditRecord = (id) => {
    setIsNewRecord(false);
    setIsView(false);
    setCurrentRecord(records.find((name) => (name.tableId || name.id) === id));
  };
  const handleViewRecord = (id) => {
    setIsView(true);
    setIsNewRecord(false);
    setCurrentRecord(records.find((name) => (name.tableId || name.id) === id));
  };
  return {
    currentRecord,
    isNewRecord,
    setCurrentRecord,
    setIsNewRecord,
    clearCurrentRecord,
    handleAddRecord,
    handleEditRecord,
    handleViewRecord,
    isView,
    setIsView,
  };
};

export default useTableRecord;
