import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  TableHead as MuiTableHead,
  TableSortLabel,
} from "@material-ui/core";
import Center from "components/flex/Center";
import { FiltersMenu } from "components/Pages/LicensingControl/components/FilterTable/FiltersMenu.jsx";
import { FilterTools } from "components/Pages/LicensingControl/components/FilterTable/FilterTools.jsx";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import useAnchorEl from "hooks/useAnchorEl";

const StyledTableHeader = styled(MuiTableHead)`
  background-color: ${({ theme }) => theme.palette.grey[100]};
  border-top: solid ${({ theme }) => theme.palette.divider} 1px;
`;

const TableHeadCell = ({ disableSorting, label, sortDirection, ...rest }) => (
  <TableCell sortDirection={sortDirection}>
    {disableSorting ? (
      label
    ) : (
      <TableSortLabel {...rest}>{label}</TableSortLabel>
    )}
  </TableCell>
);

const TableContainer = styled(Table)`
  & thead th {
    font-weight: 600;
    color: ${({ theme }) => theme.palette.primary.light};
  }

  & tbody tr:hover {
    cursor: pointer;
  }
`;

const NoRecordsRow = () => (
  <Center width={1} height="50px">
    <Typography variant="body2" color="textSecondary">
      No Records To Display
    </Typography>
  </Center>
);

const TableHead = ({ handleSortRequest, orderBy, order, headCells }) => (
  <StyledTableHeader>
    <TableRow>
      {headCells.map(({ key, disableSorting, name }, index) => (
        <TableHeadCell
          key={key}
          style={{ paddingLeft: !index ? "15px" : "0px" }}
          disableSorting={disableSorting}
          label={name}
          sortDirection={orderBy === key ? order : false}
          active={orderBy === key}
          direction={orderBy === key ? order : "asc"}
          onClick={() => {
            handleSortRequest(key);
          }}
        />
      ))}
      <TableCell />
    </TableRow>
  </StyledTableHeader>
);

TableHead.propTypes = {
  handleSortRequest: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
};

const FilterTable = ({
  handleSearch,
  searchValue,
  filterCount,
  tableHeadProps,
  sortedRecords,
  title,
  row,
  headCells,
  filterForm,
  handleSetFilterFn,
  filterStyle,
  headerButton,
}) => {
  const {
    anchorEl,
    toggle: openFilterMenu,
    setOff: closeFilterMenu,
  } = useAnchorEl();

  const applyFilter = () => {
    closeFilterMenu();
    handleSetFilterFn();
  };
  return (
    <>
      <FiltersMenu
        applyFilter={applyFilter}
        anchorEl={anchorEl}
        handleClose={closeFilterMenu}
        filterForm={filterForm}
        filterStyle={filterStyle}
      />
      <Box
        display="flex"
        alignContent="flex-end"
        justifyContent="space-between"
        width={1}
        p={2}
      >
        <Box display="flex" flexDirection="column" justifyContent="center">
          <div>
            <Typography variant="h2">
              <Box fontWeight="fontWeightMedium">{title}</Box>
            </Typography>
          </div>
        </Box>

        <div>
          <FilterTools
            applyFilter={applyFilter}
            handleSearch={handleSearch}
            searchValue={searchValue}
            openFilterMenu={openFilterMenu}
            filterCount={filterCount}
            headerButton={headerButton}
          />
        </div>
      </Box>
      <TableContainer>
        <TableHead {...tableHeadProps} headCells={headCells} />
        <TableBody>{sortedRecords.map(row)}</TableBody>
      </TableContainer>

      {sortedRecords.length === 0 && <NoRecordsRow />}
    </>
  );
};

FilterTable.propTypes = {
  filterCount: PropTypes.any,
  filterForm: PropTypes.any,
  handleSearch: PropTypes.any,
  handleSetFilterFn: PropTypes.func,
  headCells: PropTypes.any,
  row: PropTypes.any,
  searchValue: PropTypes.any,
  sortedRecords: PropTypes.any,
  tableHeadProps: PropTypes.any,
  title: PropTypes.any,
  filterStyle: PropTypes.object,
  headerButton: PropTypes.node,
};

export default FilterTable;
