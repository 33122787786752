import { Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import Tooltip from "components/Common/Tooltip";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import PDBusinessSelect from "components/Formik/formGroups/CrudSelect/selects/PDBusinessSelect";
import PDLicenseAgencySelect from "components/Formik/formGroups/CrudSelect/selects/PDLicenseAgencySelect";
import PDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/PDPersonSelect.jsx";
import YesNo from "components/Formik/formGroups/YesNo";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { LicenseConditions } from "../sharedFields/LicenseConditions";
import { LicenseDatesTooltip } from "../sharedFields/LicenseDatesTooltip";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";

const Form = () => {
  const { licenseDispositionType, licenseType, licenseConditionType } =
    useMenuOptions();

  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Entity Associated With License" />
      <PDBusinessSelect
        label="Business Entity (If Applicable)"
        tooltip={
          <Tooltip title="Select if this license was associated with a Business Entity." />
        }
      />

      <PDPersonSelect
        label="Spouse (If Applicable)"
        header="Select if held by Spouse"
        divider
        tooltip={
          <Tooltip title="Select your spouse if they were the applicant for this license." />
        }
        endpoint="currentSpouse"
        saveEndpoint="currentSpouse"
        overrideDefaultValues={{ relative_type: "spouse" }}
      />

      <SectionSubHeader label="License Agency" divider />

      <PDLicenseAgencySelect />

      <SectionSubHeader
        label="License Dates"
        divider
        tooltip={<LicenseDatesTooltip />}
      />
      <FormikDatePicker label="Application Date" name="application_date" />
      <FormikDatePicker label="From Date" name="from_date" />
      <FormikDatePicker label="Expiration Date" name="to_date" />

      <FormikSelect
        label="Industry"
        name="type"
        options={licenseType}
        xs={4}
        header="License Details"
        divider
      />
      <FormikTextField label="Type of License" name="license_type" xs={4} />
      <FormikTextField label="License Number" name="license_number" xs={4} />

      <FormikSelect
        label="Disposition"
        name="disposition"
        options={licenseDispositionType}
        xs={4}
      />

      <FormikTextField
        label="Disposition Reason (if applicable)"
        name="disposition_reason"
        xs={12}
        multiline
        rows={3}
        tooltip={
          <Tooltip title="If the license application has been denied, withdrawn, suspended, conditioned or revoked, provide an explanation." />
        }
      />

      <LicenseConditions options={licenseConditionType} />

      <YesNo
        xs={12}
        label="Were you, your spouse or domestic partner ever called to appear to testify or participate in a hearing or proceeding before the licensing agency or commission?"
        name="has_hearing"
        header="Testimony"
        divider
      />
      <YesNo
        label="Was a testimony given?"
        name="was_testimony_given"
        xs={12}
      />
      <FormikDatePicker label="Date of Hearing" name="hearing_date" xs={4} />
      <FormikTextField
        label="Name of Participant"
        name="hearing_participant"
        xs={4}
      />
      <FormikTextField
        label="Nature of Hearing"
        name="hearing_nature"
        xs={12}
      />
    </Grid>
  );
};

const PersonalLicensing = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "personal_licensing_licensePersonal",
    nonRequired: [
      "pd_business_entity_id",
      "hearing_date",
      "hearing_nature",
      "was_testimony_given",
      "hearing_participant",
      "relative_pd_person_id",
      "disposition_reason",
      "condition_reason",
      "condition_date",
      "condition_type",
      "has_hearing",
      "has_condition",
    ],
  });

  return (
    <FormSectionProvider formData={formData} title={name}>
      <EnhancedFormikDialog title="License Details">
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        subheader={<InfoAlert>{tableQuestions}</InfoAlert>}
        columns={columns}
      />
    </FormSectionProvider>
  );
};

export default PersonalLicensing;
