import { Box, Card, CardContent, Typography } from "@material-ui/core";
import React from "react";
// import { employmentsQueryKey } from "components/Pages/LicensingControl/Employees/config.js";
import { TabContentContainer } from "components/Pages/LicensingControl/components/DetailsDialog/Modal.styles.js";
// import useLCAttachment from "components/Pages/LicensingControl/hooks/useLCAttachment";
import PropTypes from "prop-types";
// import { FileDropzone } from "components/Common/FileDropZone/FileDropzone";
// import { useDropzoneEndpoints } from "components/Common/FileDropZone/useDropzoneEndpoints";

// const removePositionAndLicenses = ({
//   lc_position_type: _lc_position_type,
//   lc_person: { lc_license: _lc_license, ...lc_person },
//   ...values
// }) => ({ lc_person, ...values });

export const RecordsTab = () =>
  // {
  // entityId,
  // attachmentGroupUseState: [attachmentID, setAttachmentID],
  // initialEmploymentValues,
  // }
  {
    // const shapeEntityAttachments = (lc_attachment_group_id) => {
    //   return removePositionAndLicenses({
    //     id: entityId,
    //     ...initialEmploymentValues,
    //     lc_person: {
    //       ...initialEmploymentValues.lc_person,
    //       lc_attachment_group_id,
    //     },
    //   });
    // };

    // const { attachments, onAttachmentsUploaded } = useLCAttachment({
    //   shapeEntityAttachments,
    //   queryKey: employmentsQueryKey,
    //   endpoint: "licensing/lcEmployment",
    //   attachmentID,
    //   setAttachmentID,
    // });

    // const { getAttachmentEndpoints, attachmentGroupEndpoint } =
    //   useDropzoneEndpoints();

    return (
      <TabContentContainer>
        <Box display="flex" flexDirection="column" alignItems="center" pt={2}>
          <Card variant="outlined" style={{ width: "100%" }}>
            <CardContent>
              <Typography variant="h3" gutterBottom>
                Upload Employee Records / Attachments
              </Typography>
              <Box pt={2}>
                {/* <FileDropzone
                attachmentList={attachments}
                setAttachmentGroupID={(id) =>
                  onAttachmentsUploaded("_name", id)
                }
                attachmentGroupID={attachmentID}
                description="Employee Attachment"
                getAttachmentEndpoints={getAttachmentEndpoints}
                attachmentGroupEndpoint={attachmentGroupEndpoint}
              /> */}
              </Box>
            </CardContent>
          </Card>
        </Box>
      </TabContentContainer>
    );
  };
RecordsTab.propTypes = {
  entityId: PropTypes.number,
  attachmentGroupUseState: PropTypes.array,
  initialEmploymentValues: PropTypes.object,
};
