import { fieldTypes } from "componentGenerators/formGenerator/config";

const employeeSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Citizenship",
  },
  {
    type: fieldTypes.select,
    name: "citizenship_country",
    dynamicMenuItems: ["listOfCountries"],
    label: "Country of Citizenship",
    defaultValue: null,
  },
];

const personalDisclosureSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Non US Citizen Information",
  },
  {
    type: fieldTypes.text,
    label: "Petition Number",
    name: "petition_number",
  },
  {
    type: fieldTypes.text,
    label: "USCIS “A” Number",
    name: "uscis_number",
  },
  {
    type: fieldTypes.subHeader,
    label: "Sponsor Information",
  },
  {
    type: fieldTypes.label,
    label: "Sponsor Name:",
  },
  {
    type: fieldTypes.text,
    label: "Sponsor Name",
    name: "sponsor_name",
  },
  {
    type: fieldTypes.label,
    label: "Sponsor Address:",
  },
  {
    type: fieldTypes.googleLookup,
    fields: {
      address: "sponsor_address",
    },
  },
  {
    type: fieldTypes.addressFields,
    name: "sponsor_address",
  },
];

export default [
  {
    type: "form",
    formSchema: employeeSchema,
    endpoint: "michigan_isbigo_supplement_citizenship_employee",
  },
  {
    type: "form",
    formSchema: personalDisclosureSchema,
    endpoint: "michigan_isbigo_supplement_citizenship_personalDisclosure",
  },
];
