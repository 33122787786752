import PropTypes from "prop-types";
import { FormGroup, FormLabel } from "@material-ui/core";
import { FormikCheckBoxBase } from "components/Formik/fields/FormikCheckBox";
import { useFormikContext } from "formik";
import React from "react";
import withFieldTools from "hocs/withFieldTools";

import _ from "lodash";

const convertOptionsToValues = (options) =>
  options.reduce((acc, { value }) => {
    return { ...acc, [value]: false };
  }, {});

const FormikCheckBoxGroupBase = ({
  label,
  menuOptions, // this only exists to be consistent with previous interface
  name: fieldName,
  arrayType = false,
  row,
  options: optionsProp,
  ...props
}) => {
  const { values, resetForm, initialValues } = useFormikContext();

  const [readyToMount, setReadyToMount] = React.useState(false);

  const options = optionsProp || menuOptions;

  const checkBoxGroupValues = _.get(values, fieldName);

  React.useEffect(() => {
    // ? When component mounts, the server data is spread on top of the menu options. This is to handle cases where we add new options after the users have saved records.
    if (!readyToMount || _.isEmpty(checkBoxGroupValues)) {
      const existingValues = _.get(values, fieldName);
      const optionValues = convertOptionsToValues(options);
      setReadyToMount(true);

      const fieldValue = _.setWith(
        {},
        fieldName,
        { ...optionValues, ...existingValues },
        Object
      );
      resetForm({
        values: _.merge(initialValues, fieldValue),
      });
    }
  }, [
    options,
    fieldName,
    readyToMount,
    values,
    checkBoxGroupValues,
    initialValues,
    resetForm,
  ]);

  if (_.isEmpty(checkBoxGroupValues) || !readyToMount) return null;

  return (
    <div>
      {label && <FormLabel required={props.required}>{label}</FormLabel>}
      <FormGroup row={row} style={{ display: "flex", flexDirection: "row" }}>
        {options.map(({ name, value }) => {
          const checked = arrayType
            ? {}
            : { checked: checkBoxGroupValues?.[value] };
          const checkBoxName = arrayType ? fieldName : `${fieldName}.${value}`;

          return (
            <FormikCheckBoxBase
              key={value}
              {...checked}
              name={checkBoxName}
              value={value}
              label={name}
              {...props}
            />
          );
        })}
      </FormGroup>
    </div>
  );
};

FormikCheckBoxGroupBase.propTypes = {
  arrayType: PropTypes.bool,
  label: PropTypes.string,
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.any,
    })
  ),
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.any,
    })
  ),
  row: PropTypes.bool,
};

const EnhancedCheckboxGroup = withFieldTools(FormikCheckBoxGroupBase);

const FormikCheckBoxGroup = ({ tooltip: _tooltip, ...rest }) => (
  <EnhancedCheckboxGroup xs={12} {...rest} />
);

FormikCheckBoxGroup.propTypes = {
  tooltip: PropTypes.any,
};

export default FormikCheckBoxGroup;
