import {
  Divider,
  Drawer,
  IconButton,
  Typography,
  Box,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardContent,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import styled from "styled-components";
import FlexRow from "components/flex/FlexRow";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import SecurityIcon from "@material-ui/icons/Security";
import DescriptionIcon from "@material-ui/icons/Description";
import DashboardIcon from "@material-ui/icons/Dashboard";
import useUIStore from "../../../../store/useUIStore";
import { Link as RouterLink } from "react-router-dom";

const DrawerContent = styled(Box)`
  background-color: ${({ theme }) => theme.palette.grey[100]};
  height: calc(100vh - 85px);
`;

const DrawerContainer = styled(Box)`
  width: ${({ theme }) => theme.sizing.drawerWidth}px;
`;

const Header = ({ onClose }) => (
  <>
    <FlexRow p={2} alignItems="center">
      <Typography variant="h2">Help</Typography>
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </FlexRow>
    <Divider />
  </>
);

const getQuickLinks = () => {
  return [
    {
      title: "Security Settings",
      icon: <SecurityIcon size="small" color="primary" />,
      linkTo: "security",
    },

    {
      title: "Manage Applications",
      icon: <DescriptionIcon size="small" color="primary" />,
      linkTo: "active_applications",
    },
    {
      title: "Dashboard",
      icon: <DashboardIcon size="small" color="primary" />,
      linkTo: "dashboard",
    },
  ];
};

const Subheader = ({ children, ...props }) => (
  <Box {...props} pb={2}>
    <SectionSubHeader label={children} />
  </Box>
);

const GettingStarted = ({ openGettingStarted }) => (
  <Card variant="outlined">
    <CardContent>
      <Typography color="textSecondary">
        Click “Getting Started” below for our suggestions on how to complete
        your profile and to access additional instructions.
      </Typography>
      <Box display="flex" justifyContent="center" pt={2}>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={openGettingStarted}
        >
          Getting Started
        </Button>
      </Box>
    </CardContent>
  </Card>
);

const HelpDrawer = (props) => {
  const uiStore = useUIStore();

  const quickLinks = getQuickLinks();

  return (
    <Drawer anchor="right" {...props}>
      <DrawerContainer height="100%">
        <Header onClose={props.onClose} />
        <DrawerContent p={2} pt={4} height="100%">
          <Subheader>Getting Support</Subheader>
          <Typography color="textSecondary">
            Need assistance? Submit an email to{" "}
            <Link component="a" href="mailto:support@onecomply.com">
              support@onecomply.com
            </Link>{" "}
            and we will get in touch as soon as possible.
          </Typography>

          <Subheader pt={4}>Quick Links</Subheader>
          <List>
            {quickLinks.map(({ title, icon, linkTo, noShow }) => {
              if (noShow) return null;
              return (
                <ListItem
                  button
                  key={title}
                  component={RouterLink}
                  to={linkTo}
                  onClick={props.onClose}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={title} />
                </ListItem>
              );
            })}
          </List>
          <Box pt={4}>
            <GettingStarted
              openGettingStarted={uiStore.openGettingStartedDialog}
            />
          </Box>
        </DrawerContent>
      </DrawerContainer>
    </Drawer>
  );
};

export default HelpDrawer;
