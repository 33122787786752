import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import {
  Divider,
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm";
import React from "react";
import { tabularDataParams } from "./config.js";
import YesNo from "components/Formik/formGroups/YesNo";

const PennEEAFinancialLegalForm = () => {
  return (
    <Grid container spacing={1}>
      <SectionSubHeader>Legal & Financial Information</SectionSubHeader>
      <YesNo
        label="Pursuant to §1512 of the Gaming Act, no executive-level state employee, public official, party officer or immediate family member thereof shall have a financial interest in or be employed, directly or indirectly, by any licensed racing entity or licensed gaming entity, or any holding, affiliate, intermediary or subsidiary company, thereof, or any such applicant. Has any public official or other prohibited person possessed a financial interest in or been employed directly or indirectly by the applicant or related entity at or following the effective date of the PA Gaming Act?"
        name="bd_pennsylvania_eea.pg2_financial_compliance_boolean"
        xs={12}
      />
      <YesNo
        label="Is applicant registered or authorized to do business in the commonwealth of Pennsylvania?"
        name="bd_pennsylvania_eea.is_registered_or_authorized_in_pa"
        xs={12}
      />
      <SectionLabel>
        List all states in which the applicant is currently registered or
        authorized to do business
      </SectionLabel>
      <FormikTextField
        name="bd_pennsylvania_eea.registered_or_authorized_states"
        label="Registered or Authorized States"
        multiline
        row={2}
        xs={12}
      />
      <YesNo
        label="Does the applicant have any outstanding tax liabilities to either the Commonwealth of Pennsylvania or any other state or the Federal government?"
        name="bd_pennsylvania_eea.has_outstanding_tax"
        xs={12}
      />
      <SectionLabel>
        If you answer YES, provide details concerning all outstanding tax
        liabilities.
      </SectionLabel>
      <FormikTextField
        name="bd_pennsylvania_eea.outstanding_tax_details"
        label="Details"
        xs={4}
      />
      <YesNo
        label="During the last ten (10) year period, has applicant, it’s parent company, or any of its affiliates, intermediaries, subsidiaries or holding companies formed or caused to be formed, a political action committee either under federal or state election laws?"
        name="bd_pennsylvania_eea.has_caused_political_committee"
        xs={12}
      />
      <YesNo
        label="As a result of the Citizen’s United v. FEV decision, has applicant, its parent company, or any of its affiliates, intermediaries, subsidiaries or holding companies made “independent expenditures”, as defined in SEC. 1621(e) of the Pennsylvania Election Code, for the purpose of influencing an election covered by the Pennsylvania Election Code?"
        name="bd_pennsylvania_eea.has_made_independent_expenditures"
        xs={12}
      />
      <YesNo
        label="Has applicant or any of its officers, directors, partners or trustees ever been indicted, charged with or convicted of a criminal offense or been party to or named as an indicted co-conspirator in any criminal proceeding in the Commonwealth or any other jurisdiction? "
        name="bd_pennsylvania_eea.q1a_boolean"
        xs={12}
      />
      <YesNo
        label=" Has applicant or any of its officers, directors, owners or key employees ever been convicted of a felony or gambling offense within the past fifteen (15) years? "
        name="bd_pennsylvania_eea.q1b_boolean"
        xs={12}
      />
      <Divider />
      <SectionSubHeader>Shareholders Information</SectionSubHeader>
      <SectionLabel>
        If the right of holders of any class of stock may be modified otherwise
        than by a vote of a majority or more outstanding shares so affected,
        voting as a class, so state and explain briefly:
      </SectionLabel>
      <FormikTextField
        label="Details"
        name="bd_pennsylvania_eea.right_share_holders_modified_details"
        xs={6}
      />
    </Grid>
  );
};

const PennEEAFiancialLegal = ({ name }) => {
  const formData = useForm(tabularDataParams);

  return (
    <FormSectionProvider formData={formData} title={`Pennsylvania EEA ${name}`}>
      <EnhancedFormikPage>
        <PennEEAFinancialLegalForm />
      </EnhancedFormikPage>
    </FormSectionProvider>
  );
};

export default PennEEAFiancialLegal;
