import React, { useState, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

export default function PaginationControl({
  setFocus = null,
  data,
  paginationSize,
  setIndex = null,
}) {
  const [selected, setSelected] = useState(1);

  const maxPages = () => Math.ceil(data.length / paginationSize);

  const flipPage = (e) => {
    if (e.target.id === "+") {
      setSelected(selected < maxPages() ? selected + 1 : selected);
      setIndex((selected < maxPages() ? selected + 1 : selected) - 1);
    } else {
      setSelected(selected > 1 ? selected - 1 : selected);
      setIndex((selected > 1 ? selected - 1 : selected) - 1);
    }
  };

  const setByLink = (e) => {
    setSelected(parseInt(e.target.innerText));
    setIndex(parseInt(e.target.innerText) - 1);
  };

  useEffect(() => {
    setFocus &&
      setFocus(
        data.filter(
          (val, i) =>
            i + 1 > selected * paginationSize - paginationSize &&
            i + 1 <= selected * paginationSize
        )
      );
  }, [selected, setFocus, data, paginationSize]);

  useEffect(() => {
    // works for paginationSize of one -- need to be tested with other sizes
    const conrtolFocusByChunk = () => {
      setIndex(data.length - 1);
      setSelected(data.length);
    };
    setIndex && conrtolFocusByChunk();
    !setIndex && setSelected(1);
  }, [data, setIndex]);

  return (
    !!data.length && (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        style={{ width: "100%", marginTop: "10px" }}
      >
        <ArrowBackIosIcon className="pointer" id="-" onClick={flipPage} />
        {new Array(maxPages()).fill(1).map((val, i) => (
          <Box key={"pagi-" + i} mr={1}>
            <Typography
              className="pointer"
              onClick={setByLink}
              style={1 + i === selected ? { color: "rgb(33, 150, 243)" } : {}}
            >
              {val + i}
            </Typography>
          </Box>
        ))}
        <ArrowForwardIosIcon className="pointer" id="+" onClick={flipPage} />
      </Box>
    )
  );
}
