import { fieldTypes } from "componentGenerators/formGenerator/config";
import React from "react";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "TAX information",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please attach a copy of each IRS form(s) filed and all supporting IRS
        schedules filed by you and/or your spouse for the last two (2) years in
        the <CustomLink to="us_taxes" />
        section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please attach a copy of each State Income Tax Return(s) filed and all
        supporting IRS schedules filed by you and/or your spouse for the last
        two (2) years in the <CustomLink to="us_taxes" />
        section.
      </>
    ),
  },
];

export default {
  type: "readOnlyPage",
  formSchema,
};
