import React from "react";

export const columns = [
  {
    title: "Business Entity",
    field: "bd_business_entity.business_name",
  },
  {
    title: "Other Party",
    field: "related_party_name",
  },
  {
    title: "Loan Date",
    field: "from_date",
  },
  {
    title: "Status",
    field: "loan_status",
    format: "startCase",
  },

  {
    title: "Purpose of Loan",
    field: "purpose_of_loan",
  },
  {
    title: "Original Amount",
    field: "amount_original",
    format: "currency",
  },
  {
    title: "Current Amount",
    field: "amount_current",
    format: "currency",
  },
];

export const tableQuestions = (
  <>
    Provide the Applicant&apos;s current loans, either as LENDER or BORROWER.
    Include the following:
    <ul>
      <li>
        Bonds, loans, mortgages, trust deeds, notes, debentures or other forms
        of debt
      </li>
      <li>Shareholder loans granted to the Applicant</li>
      <li>
        Debts that have yet to be repaid or debt forgiveness of an amount equal
        to or above $100,000 USD or as directed by the jurisdiction
      </li>
    </ul>
  </>
);

export const defaultInitialValues = {
  related_bd_party_role: "",
  related_bd_party_id: null,
  bd_business_entity_id: null,
  from_date: null,
  to_date: null,
  purpose_of_loan: "",
  loan_status: "",
  amount_original: null,
  amount_current: null,
  loan_documents_attachment_group_id: null,
  annual_interest_rate: null,
  renewable_date: null,
  is_renewable: false,
  term_and_conditions: "",
  instrument_type: "",
  instrument_type_other_description: "",
  collateral: "",
  has_government_debt_delinquency: null,
  government_debt_delinquency_attachment_group_id: null,
};

export const endpoint =
  "bdLoan?related_bd_party_role=lender&related_bd_party_role=borrower";

export const nonRequired = [
  "loan_documents_attachment_group_id",
  "loan_status",
  "instrument_type_other_description",
  "is_renewable",
  "renewable_date",
];

export const tabularDataParams = {
  defaultInitialValues,
  endpoint,
  nonRequired,
};
