import schemaHelpers from "componentGenerators/formGenerator/schemaHelpers";

export { schemaHelpers };

export const formGroupTypes = {
  addressLookupAndFields: "addressLookupAndFields",
  firstMiddleLast: "firstMiddleLast",
  fromToCurrent: "fromToCurrent",
};

export const pageTypes = {
  table: "table",
  form: "form",
  readOnlyTable: "readOnlyTable",
  readOnlyPage: "readOnlyPage",
};

export const fieldTypes = {
  //fields:
  text: "text",
  number: "number",
  yesNo: "yesNo",
  email: "email",
  select: "select",
  date: "date",
  googleLookup: "googleLookup",
  checkBoxGroup: "checkBoxGroup",
  asyncSelect: "asyncSelect",
  checkBox: "checkBox",
  dropZone: "dropZone",
  addressFields: "addressFields",
  dependantDatePicker: "dependantDatePicker",
  businessAssociation: "businessAssociation",

  //PD Selects:
  pdBusinessSelect: "pdBusinessSelect",
  pdFinancialSelect: "pdFinancialSelect",
  pdLawEnforcementSelect: "pdLawEnforcementSelect",
  pdLicenseAgencySelect: "pdLicenseAgencySelect",
  pdPartySelect: "pdPartySelect",
  pdPersonSelect: "pdPersonSelect",
  pdGamingSelect: "pdGamingSelect",
  pdRelativeSelect: "pdRelativeSelect",

  // BD Selects:
  bdPersonSelect: "bdPersonSelect",
  bdBusinessSelect: "bdBusinessSelect",
  bdLicenseAgencySelect: "bdLicenseAgencySelect",
  bdFinancialSelect: "bdFinancialSelect",
  bdBusinessFamilySelect: "bdBusinessFamilySelect",
  bdGovernmentAgencySelect: "bdGovernmentAgencySelect",
  bdPartySelect: "bdPartySelect",
  bdLawEnforcementSelect: "bdLawEnforcementSelect",

  //other:
  subHeader: "subheader",
  label: "label",
  divider: "divider",
  gridItem: "gridItem",
  alertEdit: "alertEdit",
};
