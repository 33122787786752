import React from "react";

import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents/";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import useForm from "hooks/useForm.js";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import { Grid } from "@material-ui/core";

import FormikRadioGroup from "components/Formik/formGroups/FormikRadioGroup";
import { useMenuOptions } from "hooks/useMenuOptions";
import YesNo from "components/Formik/formGroups/YesNo";
import FormikCheckBoxGroup from "components/Formik/formGroups/FormikCheckBoxGroup";
import FormikEmailTextField from "components/Formik/fields/FormikEmailTextField";
import BusinessAssociation from "components/Common/BusinessAssociation/BusinessAssociation";
import { address } from "helpers/initialValues";
import FormikGoogleLookup from "components/Formik/fields/FormikGoogleLookup";
import { FormikAddressFields } from "components/Formik/formGroups/FormikAddressFields";

const Form = () => {
  const { principalRoleOptions } = useMenuOptions();

  return (
    <EnhancedFormikPage>
      <Grid container spacing={2}>
        <BusinessAssociation
          title="Employer Information"
          header="Business name as it appears on the business entity’s certificate of incorporation, charter, bylaws, partnership agreement or other official documents:"
          label="Associated Company (Your Association)"
          name="pennsylvania.pd_business_entity_id"
        />
        <FormikEmailTextField
          xs={3}
          label="Email"
          name="pennsylvania.business_email"
        />

        <FormikTextField
          xs={3}
          label="Fax Number"
          name="pennsylvania.business_fax_number"
        />

        <SectionLabel label="Trade name(s) and Doing Business As (“DBA”) Names:" />
        <FormikTextField
          xs={4}
          label="Trade Name(s)"
          name="pennsylvania.employer_trade_name"
        />

        <SectionLabel label="Describe the relationship between applicant and the business entity/licensee named above, including amounts and terms of ownership and control:" />
        <FormikTextField
          multiline
          rows={4}
          xs={6}
          label="Description of Relationship"
          name="pennsylvania.employer_relationship_explanation"
        />

        <SectionSubHeader label="Business Address in Pennsylvania (If Applicable)" />
        <FormikGoogleLookup
          fields={{
            address: "pe_business_address",
          }}
        />
        <FormikAddressFields addressPrefix={"pe_business_address."} />

        <SectionSubHeader label="Criminal Investigation:" />

        <SectionLabel label="Provide all information relating to any issues involving alcohol or controlled substances:" />
        <FormikTextField
          multiline
          rows={4}
          xs={6}
          label="Issues involving alcohol / drugs"
          name="pennsylvania.alcohol_and_drugs_related_issues_explanation"
        />

        <SectionLabel label="Describe the nature, type, terms and conditions of all insurance claims relating to the business activities of applicant for the last ten (10) year period:" />
        <FormikTextField
          multiline
          rows={4}
          xs={6}
          label="Insurance claims"
          name="pennsylvania.insurance_claims_explanation"
        />
        <SectionSubHeader label="License Information" />

        <FormikRadioGroup
          options={[
            { name: "Principal", value: "principal" },
            { name: "Key Employee", value: "key_employee" },
          ]}
          label="Type of license applicant is applying:"
          name="pennsylvania.license_type"
          xs={12}
        />

        <FormikRadioGroup
          options={[
            { name: "Initial", value: "initial" },
            { name: "Renewal", value: "renewal" },
          ]}
          label="Application period:"
          name="pennsylvania.application_period"
          xs={12}
        />

        <SectionLabel label="Principal Role:" />

        <FormikCheckBoxGroup
          name="pe_principal_role_checkbox"
          options={principalRoleOptions}
        />

        <SectionSubHeader divider>VGT Applicants Only:</SectionSubHeader>

        <YesNo
          label="Is the applicant requesting conditional licensure:"
          name="pennsylvania.is_requesting_conditional_licensure"
        />
        <SectionLabel>
          If you answer <strong>YES</strong> to this question, the VGT
          Conditional Licensure Affidavit must be completed on page S9 and
          payment for each individual must be provided with the application
          ($100 per entity and individual in addition to the regular application
          fee.)
        </SectionLabel>
      </Grid>
    </EnhancedFormikPage>
  );
};

const PennsylvaniaDisclosureQuestions = ({ name }) => {
  const formData = useForm({
    defaultInitialValues: {
      pe_principal_role_checkbox: null,
      pe_business_address: address,
      pennsylvania: {
        contact_business_entity_id: null,
        pd_business_entity_id: null,
        license_type: "",
        application_period: "",
        is_requesting_conditional_licensure: null,
        employer_trade_name: "",
        employer_relationship_explanation: "",
        business_email: "",
        business_fax_number: "",
        alcohol_and_drugs_related_issues_explanation: "",
        insurance_claims_explanation: "",
      },
    },
    endpoint: "pennsylvania_disclosure_questions_pdSupplement",
    noValidate: true,
  });

  return (
    <FormSectionWrapper title={name}>
      <FormContextProvider formData={formData}>
        <Form />
      </FormContextProvider>
    </FormSectionWrapper>
  );
};

export default PennsylvaniaDisclosureQuestions;
