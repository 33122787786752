import { Grid } from "@material-ui/core";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import {
  columns,
  defaultInitialValues,
  endpoint,
  sortingOptions,
} from "./config.js";
import FormikSelect from "components/Formik/fields/FormikSelect";
import ControlledAutocomplete from "components/Common/ControlledAutocomplete/ControlledAutoComplete";
import { useCrudSelect } from "components/Formik/formGroups/CrudSelect/hooks/useCrudSelect";
import { LicenceAgenciesForm } from "components/Pages/LicensingControl/LicenseAgencies/LicenceAgencies";
import { useRouteMatch } from "react-router-dom";
import {
  EnhancedFormikDialog,
  FormikDialog,
} from "components/Formik/formContainers/FormikDialog";

import * as yup from "yup";
import { requiredString } from "helpers/validationSchemaGenerator.js";

export const validationSchema = yup.object().shape({
  name: requiredString,
});

export const LicenseTypesForm = () => {
  const licenseAgencyProps = useCrudSelect({
    name: "lc_licensing_agency_id",
    endpoint: "licensing/lcLicensingAgency",
    defaultInitialValues: { agency_name: "" },
    getOptionLabel: ({ agency_name }) => agency_name,
  });

  const isLicenseTypeSection = useRouteMatch("/app/lc_license_type_section");

  return (
    <>
      <FormikDialog
        title="Add/Edit License Agency"
        validationSchema={validationSchema}
        {...licenseAgencyProps.formProps}
      >
        <LicenceAgenciesForm />
      </FormikDialog>

      <Grid container spacing={2}>
        <FormikTextField label="License Type" name="name" xs={6} />

        {isLicenseTypeSection ? (
          <FormikSelect
            label="License Category"
            name="category"
            xs={6}
            options={[
              { value: "personal", name: "Personal" },
              { value: "business", name: "Business" },
            ]}
          />
        ) : null}

        <ControlledAutocomplete
          {...licenseAgencyProps.fieldProps}
          label="License Agency"
        />
      </Grid>
    </>
  );
};

const LicenseTypes = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint,
  });

  return (
    <FormSectionProvider formData={formData} title={name}>
      <EnhancedFormikDialog title={name}>
        <LicenseTypesForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        columns={columns}
        addButtonText="Add License Type"
        {...sortingOptions}
      />
    </FormSectionProvider>
  );
};

export default LicenseTypes;
