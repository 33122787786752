import { fieldTypes } from "componentGenerators/formGenerator/config";
import { NumberFormatTypes } from "components/Common/Inputs/NumberField";

const taxLiabilityColumns = [
  {
    title: "Agency Name",
    field: "tax_agency_name",
  },
  {
    title: "Type of Tax",
    field: "type_of_tax",
  },
  {
    title: "Amount",
    field: "amount",
  },
];

const taxLiabilityFormSchema = [
  {
    type: fieldTypes.bdBusinessFamilySelect,
    label: "Applicant",
    getOptionLabel: (option) => option.business_name,
    getOptionValue: (option) => option.id,
    required: true,
    name: "bd_party_id",
  },

  {
    type: fieldTypes.bdGovernmentAgencySelect,
    label: "Tax Agency",
    name: "tax_agency_entity_id",
    required: true,
  },
  {
    type: fieldTypes.subHeader,
    label: "Tax Type",
  },
  {
    type: fieldTypes.text,
    label: "Type of Tax",
    name: "type_of_tax",
    required: true,
  },
  {
    type: fieldTypes.subHeader,
    label: "Tax Amount",
  },
  {
    type: fieldTypes.label,
    label: "Amount of Tax Liability, if past due:",
  },
  {
    type: fieldTypes.number,
    label: "Amount",
    name: "amount",
    format: NumberFormatTypes.dollar,
    required: true,
  },
];

const taxLiabilitySchema = {
  type: "table",
  saveEndpoint: "bdTaxLiability",
  endpoint: "views/bd_tax_liabilities",
  formSchema: taxLiabilityFormSchema,
  columns: taxLiabilityColumns,
  infoAlerts: [
    {
      body: "Please provide information below if there are any federal, state and/or local outstanding tax liability owed by the applicant.",
    },
  ],
};

export default [taxLiabilitySchema];
