import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import useToggle from "hooks/useToggle";
import { AddOutlinedButton } from "components/Common/CustomButtons/AddOutlinedButton";
import DeleteConfirmation from "components/Common/Dialogs/DeleteConfirmation";
import { useFormikContext } from "formik";
import useInlineMultiForm from "hooks/useInlineMultiForm.js";

import ExistingOwnerForm from "./ExistingOwnerInlineForm";
import NewOwnerInlineForm from "./NewOwnerInlineForm";
const BusinessOwnerList = () => {
  const { on, toggle } = useToggle();

  let {
    values: { pd_business_entity_id, id },
  } = useFormikContext();

  if (id) {
    pd_business_entity_id = id;
  }

  const { records, editRecord, addRecord, deleteRecord, deleteDialogProps } =
    useInlineMultiForm({
      endpoint: `pdBusinessEntity/${pd_business_entity_id}/relatedPerson?pd_person_id=not_null`,
      queryKey: ["businessRelatedPersons", pd_business_entity_id],
      enabled: !!pd_business_entity_id,
    });

  return (
    <>
      <DeleteConfirmation
        open={deleteDialogProps.isDeleting}
        onConfirm={deleteDialogProps.confirmDelete}
        onCancel={deleteDialogProps.cancelDelete}
      />
      <Grid container item xs={12}>
        {records ? (
          <Grid item xs={12}>
            {records.map((record) => {
              return (
                <ExistingOwnerForm
                  key={record.id}
                  record={record}
                  editRecord={editRecord}
                  deleteRecord={deleteRecord}
                />
              );
            })}

            {on ? (
              <NewOwnerInlineForm toggle={toggle} addRecord={addRecord} />
            ) : (
              <Box p={1} display="flex" justifyContent="center">
                <AddOutlinedButton onClick={toggle}>
                  Add New Owner
                </AddOutlinedButton>
              </Box>
            )}
          </Grid>
        ) : id === undefined ? (
          <Box display="flex" justifyContent="center" width={1}>
            <Typography variant="body2">
              Select a Business First to View Owners
            </Typography>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" width={1}>
            <Typography variant="body2">Save Business to add Owners</Typography>
          </Box>
        )}
      </Grid>
    </>
  );
};

export default BusinessOwnerList;
