import React from "react";

import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";

import {
  SectionSubHeader,
  SectionLabel,
} from "components/Common/LayoutComponents";

//HOOKS
import useForm from "hooks/useForm";
import { defaultInitialValues, endpoint } from "./config";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import { Grid } from "@material-ui/core";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import { useAuth } from "context/auth";

const Form = () => {
  return (
    <Grid container spacing={1}>
      <SectionSubHeader>Formation Documents</SectionSubHeader>
      <SectionLabel>
        Attach copies of the Certificate of Incorporation, Constitution or
        Memorandum of Association and Articles of Association, Trust Documents
        (including Type of Trust, Trust beneficiaries and Trust Deed) or other
        formation documents and any amendments thereto.
      </SectionLabel>

      <FormikFileDropzone
        description="Formation Documents"
        name="formation_documents_attachment_group_id"
        xs={12}
      />

      <SectionSubHeader>
        Business Plan & Description of Business
      </SectionSubHeader>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <SectionLabel>Attach a copy of the Company's business plan.</SectionLabel>

      <FormikFileDropzone
        description="Business Plan & Description of Business"
        name="business_plan_attachment_group_id"
        xs={12}
      />

      <SectionSubHeader>Corporate Family Tree</SectionSubHeader>
      <SectionLabel>
        Provide a copy of the corporate family tree showing current corporate
        structure detailing the relationships of ALL related companies.
      </SectionLabel>

      <FormikFileDropzone
        description="Corporate Family Tree"
        name="corporate_family_tree_attachment_group_id"
        xs={12}
      />

      <SectionSubHeader>
        Management Diagrams & Organizational Charts
      </SectionSubHeader>
      <SectionLabel>
        Provide as an Appendix hereto, an organizational chart of the enterprise
        which includes position descriptions and the names of persons holding
        such positions.
      </SectionLabel>

      <FormikFileDropzone
        description="Management Diagrams & Organizational Charts"
        name="management_diagram_attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};

export default function CorporateDocuments({ title }) {
  const auth = useAuth();
  const formData = useForm({
    defaultInitialValues,
    endpoint: endpoint + "?id=" + auth.bd_business_entity_id,
    noValidate: true,
  });
  return (
    <FormSectionProvider formData={formData} title={title}>
      <EnhancedFormikPage>
        <Form />
      </EnhancedFormikPage>
    </FormSectionProvider>
  );
}
