import React from "react";

import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import { FormContext } from "components/Layout/SectionsLayout/FormSectionProvider";

const EnhancedFileDropzone = (props) => {
  const { currentRecord } = React.useContext(FormContext);

  const newProps = { ...props };

  return <FormikFileDropzone id={currentRecord?.id} {...newProps} />;
};

export default EnhancedFileDropzone;
