import { Box, Button, Typography } from "@material-ui/core";
import { lighten } from "@material-ui/core/styles";
import CloudUpload from "@material-ui/icons/CloudUploadTwoTone";
import styled, { css } from "styled-components";

export const UploadedFilesList = styled(Box)`
  max-height: 200px;
  overflow: auto;
`;

export const FileUploadButton = styled(Button)`
  background-color: ${({ theme }) => lighten(theme.palette.primary.light, 0.2)};
  color: white;
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
`;

export const DropText = styled(Typography)`
  color: ${({ theme }) => lighten(theme.palette.primary.light, 0.4)};
`;

export const StyledIcon = styled(CloudUpload)`
  color: ${({ theme }) => lighten(theme.palette.primary.light, 0.5)};
  font-size: 60px;
`;

export const DropzoneBox = styled(Box)`
padding: ${({ $isCompact }) =>
  `${$isCompact ? "10" : "20"}px 120px ${$isCompact ? "15" : "30"}px 120px`};
border: 1px dashed ${({ theme }) => theme.palette.grey[400]};
border-radius: 5px;
margin-bottom: 10px;
display: flex;
justify-content: space-around;
flex-direction: column;
align-items: center;
background-color: ${({ theme }) => theme.palette.grey[50]};

${({ $isDragActive }) =>
  $isDragActive &&
  css`
    background-color: ${({ theme }) =>
      lighten(theme.palette.primary.light, 0.9)};
  `}}

`;
