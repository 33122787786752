import * as yup from "yup";

export const validation = yup.object().shape({
  name: yup.string().required("Required Field").typeError("Required Field"),
});

export const columns = [
  {
    field: "agency_name",
  },
];

export const defaultInitialValues = {
  agency_name: "",
};

export const sortingOptions = {
  defaultSortBy: "agency_name",
  defaultSortOrder: "asc",
};

export const endpoint = "licensing/lcLicensingAgency";
