import { Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone.jsx";
import FormikSelect from "components/Formik/fields/FormikSelect";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import BDFinancialSelect from "components/Formik/formGroups/CrudSelect/selects/BDFinancialSelect.jsx";
import FromToCurrent from "components/Formik/formGroups/FromToCurrent.jsx";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import { useMenuOptions } from "hooks/useMenuOptions";
import React from "react";
import { columns, tableQuestions, tabularDataParams } from "./config.js";

const Form = () => {
  const { bdBankAccountTypes } = useMenuOptions();
  return (
    <Grid container spacing={2}>
      <BDFinancialSelect />
      <SectionSubHeader label="Account Details" divider />
      <FormikTextField label="Account Number" name="account_number" xs={4} />
      <FormikSelect
        label="Account Type"
        name="account_type"
        options={bdBankAccountTypes}
        xs={4}
      />
      <FormikTextField label="Account Purpose" name="nature" xs={4} />
      <SectionSubHeader label="Account Dates" divider />
      <FromToCurrent />
      <SectionSubHeader label="Account Statement" divider />
      <FormikFileDropzone
        description="BD Bank Account Statements"
        name="bank_account_statement_attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};

/**
 * Changed to "Financial Accounts" in nav
 */
const BankAccounts = ({ name }) => {
  const formData = useTabularData(tabularDataParams);

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
    </FormSectionProvider>
  );
};

export default BankAccounts;
