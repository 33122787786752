import { Grid, Link, Typography, Box } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";

export const Summary = ({
  hasExtraAttachments,
  handleTabChange,
  viewGeneratedApplication,
}) => {
  const InlineLink = ({
    tabIndex,
    children,
    color = "primary",
    onClick = () => {
      handleTabChange("_", tabIndex);
    },
  }) => (
    <>
      &nbsp;
      <Link
        color={color}
        component="button"
        variant="body2"
        underline="always"
        onClick={onClick}
      >
        <Box fontWeight="fontWeightMedium">{children}</Box>
      </Link>
      &nbsp;
    </>
  );

  return (
    <Grid container spacing={2}>
      {hasExtraAttachments && (
        <Grid item xs={12}>
          <Alert severity="warning">
            Extra pages were needed to complete this application, to review
            these pages, please select the
            <InlineLink tabIndex={3} color={"textPrimary"}>
              Extra Pages
            </InlineLink>
            tab.
          </Alert>
        </Grid>
      )}

      <Grid item xs={12}>
        <Alert severity="info">
          <AlertTitle>To prepare your application for submission:</AlertTitle>
          <Typography variant="body2" component="span">
            <Box lineHeight={2}>
              <strong>1. &nbsp;</strong>Review and print the
              <InlineLink onClick={viewGeneratedApplication}>
                Generated Application.
              </InlineLink>
              <br />
              <strong>2. &nbsp;</strong>Review and print all necessary
              <InlineLink tabIndex={1}>Attachments</InlineLink>as required by
              the Gaming Regulator.
              <br />
              <strong>3. &nbsp;</strong>Review and print all
              <InlineLink tabIndex={3}>Extra Pages.</InlineLink>
              <br />
              <strong>4. &nbsp;</strong>Collate the documents.
              <br />
              <strong>5. &nbsp;</strong>Review the Gaming Regulator instructions
              for submission, usually found in the first few pages of the
              application.
            </Box>
          </Typography>
        </Alert>
      </Grid>
    </Grid>
  );
};
