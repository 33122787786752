import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Sports Wagering Applicant/ Licensee",
  },
  {
    type: fieldTypes.label,
    label:
      "Please select in the below dropdown the Sports Wagering Applicant/ Licensee:",
  },
  {
    type: fieldTypes.bdBusinessSelect,
    label: "Applicant/ Licensee",
    name: "bd_maryland_swped.applicant_business_entity_id",
  },
  {
    type: fieldTypes.subHeader,
    label: "Point-of-contact",
  },
  {
    type: fieldTypes.label,
    label: "Please select below the point of contact for the principal entity:",
  },
  {
    type: fieldTypes.bdPersonSelect,
    label: "Contact Person",
    name: "bd_maryland_swped.contact_person_id",
  },
  {
    type: fieldTypes.subHeader,
    label: "Relationships",
  },
  {
    type: fieldTypes.label,
    label:
      "Please provide below a description of the relationship between the Principal Entity and the Applicant for the Sports Wagering License, and include any terms and conditions that affect ownership and control:",
  },
  {
    type: fieldTypes.text,
    label: "Description",
    name: "bd_maryland_swped.exhibit_1_f_details",
    rows: 4,
    xs: 12,
  },
];

export default [
  {
    type: "form",
    formSchema,
    endpoint: "bdSupplement",
  },
];
