import { Grid } from "@material-ui/core";
import { SectionLabel } from "components/Common/LayoutComponents";
import FormikCheckBoxGroup from "components/Formik/formGroups/FormikCheckBoxGroup";
import { FormikNumberField, FormikCheckBox } from "components/Formik/fields";
import { useMenuOptions } from "hooks/useMenuOptions";
import React from "react";

const RoleAndOwnershipFields = () => {
  const { businessInterestRoleCheckboxOptions } = useMenuOptions();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SectionLabel label="Indicate how this person is related to the business" />
        <FormikCheckBoxGroup
          options={businessInterestRoleCheckboxOptions}
          row
          name="role_checkbox"
          xs={12}
        />
      </Grid>
      <Grid item xs={12}>
        <SectionLabel label="Ownership Percentage" />
        <FormikNumberField
          label="Ownership Percentage"
          name="ownership_percent"
          xs={6}
          type="percent"
        />
        <FormikCheckBox label="Is Current Owner" name="is_current" />
      </Grid>
    </Grid>
  );
};

export default RoleAndOwnershipFields;
