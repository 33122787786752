import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import { Box, Paper } from "@material-ui/core";
import DisplayTable from "components/Common/Tables/DisplayTable";
import useFetch from "queryHooks/useFetch";
import {
  criminalColumns,
  criminalEndpoint,
  criminalTableQuestion,
  antitrustColumns,
  antitrustEndpoint,
  antitrustTableQuestion,
  litigationsColumns,
  litigationsEndpoint,
  litigationsTableQuestion,
  testimonyColumns,
  testimonyEndpoint,
  testimonyTableQuestion,
} from "./config.js";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent.jsx";

const TennesseeVRALegalHistory = ({ name }) => {
  const { data: criminalData, status: criminalStatus } = useFetch(
    criminalEndpoint
  );
  const { data: antitrustData, status: antitrustStatus } = useFetch(
    antitrustEndpoint
  );
  const { data: litigationsData, status: litigationsStatus } = useFetch(
    litigationsEndpoint
  );
  const { data: testimonyData, status: testimonyStatus } = useFetch(
    testimonyEndpoint
  );

  return (
    <FormSectionWrapper title={name}>
      <Box mb={1}>
        <Paper>
          <LoadingComponent status={criminalStatus}>
            <DisplayTable
              records={criminalData}
              linkTo={["bd_criminal_proceedings"]}
              columns={criminalColumns}
              dependsOnQueryCount={{
                question: criminalTableQuestion,
              }}
            />
          </LoadingComponent>
        </Paper>
      </Box>
      <Box mb={1}>
        <Paper>
          <LoadingComponent status={antitrustStatus}>
            <DisplayTable
              records={antitrustData}
              linkTo={["bd_antitrust_and_trade"]}
              columns={antitrustColumns}
              dependsOnQueryCount={{
                question: antitrustTableQuestion,
              }}
            />
          </LoadingComponent>
        </Paper>
      </Box>
      <Box mb={1}>
        <Paper>
          <LoadingComponent status={litigationsStatus}>
            <DisplayTable
              records={litigationsData}
              linkTo={["bd_litigations"]}
              columns={litigationsColumns}
              dependsOnQueryCount={{
                question: litigationsTableQuestion,
              }}
            />
          </LoadingComponent>
        </Paper>
      </Box>
      <Box mb={1}>
        <Paper>
          <LoadingComponent status={testimonyStatus}>
            <DisplayTable
              records={testimonyData}
              linkTo={["bd_testimonies"]}
              columns={testimonyColumns}
              dependsOnQueryCount={{
                question: testimonyTableQuestion,
              }}
            />
          </LoadingComponent>
        </Paper>
      </Box>
    </FormSectionWrapper>
  );
};

export default TennesseeVRALegalHistory;
