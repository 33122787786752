import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import {
  SectionSubHeader,
  SectionLabel,
  Divider,
} from "components/Common/LayoutComponents";
import YesNo from "components/Formik/formGroups/YesNo";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm.js";
import React from "react";
import { defaultInitialValues, financialHistoryQuestions } from "./config";
import { Box } from "@material-ui/core";

const ColoradoFinancialHistoryForm = () => {
  const formData = useForm({
    defaultInitialValues,
    noValidate: true,
    endpoint: "colorado_rider_financial_history_pdSupplement",
  });

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage>
        <Box mb={2}>
          <SectionSubHeader label="Financial History Information" />
        </Box>

        {financialHistoryQuestions.map(({ label, attribute }) => (
          <YesNo
            key={attribute}
            label={label}
            name={`colorado.${attribute}`}
            xs={12}
          />
        ))}
        <Box mb={2} mt={2}>
          <Divider />
        </Box>

        <SectionLabel
          label={
            <>
              If you answered <strong>YES</strong> to any of the questions above
              or checked any boxes above, provide details below. Include any
              items currently under formal dispute or legal appeal. Attach any
              documents to prove your settlement on any of these issues.
            </>
          }
        />

        <FormikTextField
          multiline
          rows={3}
          label="Details"
          name="colorado.financial_history_explanation"
          xs={12}
        />

        <SectionLabel label="Attachments:" />

        <FormikFileDropzone
          description="Colorado Financial"
          name="colorado_fin_history_attachment_group_id"
          size={12}
        />
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

export default ColoradoFinancialHistoryForm;
