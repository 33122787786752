import React from "react";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import { formats } from "components/Common/Tables/Common/helpers";

export const columns = [
  {
    field: "date_expired",
  },
  {
    field: "country_of_issue",
    format: formats.startCase,
  },
  {
    field: "passport_number",
  },
  {
    field: "place_of_issue",
  },
  {
    field: "date_issued",
  },
];

export const formSchema = [
  {
    type: fieldTypes.text,
    label: "Passport Number",
    name: "passport_number",
  },
  {
    type: fieldTypes.select,
    label: "Country Of Issue",
    name: "country_of_issue",
    dynamicMenuItems: ["listOfCountries"],
    defaultValue: "",
  },
  {
    type: fieldTypes.text,
    label: "Place Of Issue",
    name: "place_of_issue",
  },
  {
    type: fieldTypes.date,
    label: "Date Issued",
    name: "date_issued",
  },
  {
    type: fieldTypes.date,
    label: "Expiration Date",
    name: "date_expired",
  },
  {
    type: fieldTypes.label,
    label: "Upload a copy of your passport",
  },
  {
    type: fieldTypes.dropZone,
    name: "attachment_group_id",
  },
];

export const tableQuestions = (
  <>Provide details for all your current passport(s).</>
);

export const endpoint = "passport_passport";

export const dataHookProps = {
  endpoint,
};
