import { Grid, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import LockIcon from "@material-ui/icons/Lock";
import React from "react";
import {
  BoxWrapper,
  StyledFab,
  StyledProgress,
  GridWrapper,
  StyledDialog,
  StyledGridItem,
} from "./styles";

const SecureLoginScreen = ({ handleLoadingFinished }) => {
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const theme = useTheme();

  React.useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setSuccess(true);
      setLoading(false);
      setTimeout(() => {
        handleLoadingFinished();
      }, 1250);
    }, 2000);
  }, [handleLoadingFinished]);

  return (
    <StyledDialog fullScreen open>
      <GridWrapper
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Grid item xs={3}>
          <Grid container spacing={4} direction="column">
            <StyledGridItem item>
              <BoxWrapper theme={theme}>
                <StyledFab
                  aria-label="save"
                  color="primary"
                  success={success.toString()}
                >
                  {loading ? <LockIcon /> : <CheckIcon />}
                </StyledFab>
                {loading && <StyledProgress size={68} />}
              </BoxWrapper>
            </StyledGridItem>
            <Grid item>
              <Typography variant="h3">
                Logging in to your secure portal...
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </GridWrapper>
    </StyledDialog>
  );
};

export default SecureLoginScreen;
