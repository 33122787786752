import { getFormComponents } from "componentGenerators/helpers";

import pd_oklahoma_rkela_supplement_disclosure_questions from "components/Pages/Supplemental/PD/OklahomaRKELA/OklahomaRKELADisclosureQuestions/OklahomaRKELADisclosureQuestions";
import pd_oklahoma_rkela_supplement_marital_status from "components/Pages/Supplemental/PD/OklahomaRKELA/OklahomaRKELAMaritalStatus/OklahomaRKELAMaritalStatus";
import pd_oklahoma_rkela_supplement_credit_record from "components/Pages/Supplemental/PD/OklahomaRKELA/OklahomaRKELACreditRecord/OklahomaRKELACreditRecord";
import pd_oklahoma_rkela_supplement_court_record from "components/Pages/Supplemental/PD/OklahomaRKELA/OklahomaRKELACourtRecord/OklahomaRKELACourtRecord";

export default getFormComponents({
  pd_oklahoma_rkela_supplement_disclosure_questions,
  pd_oklahoma_rkela_supplement_marital_status,
  pd_oklahoma_rkela_supplement_credit_record,
  pd_oklahoma_rkela_supplement_court_record,
});
