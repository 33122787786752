import React from "react";
import { indictments } from "../../uiStrings";

export const columns = [
  { field: "indictment_date" },
  {
    title: "Agency Name",
    field: "law_enforcement_pd_business_entity.business_name",
  },
  { field: "nature" },
];

export const tableQuestions = <>{indictments.tableQuestion}</>;

export const nonRequired = [];

export const endpoint = "indictments_indictmentLegalHistory";

export const modalTitle = "Indictment & Complaint";

export const defaultInitialValues = {
  type: "indictment",
  indictment_date: null,
  nature: "",
  law_enforcement_pd_business_entity_id: null,
};

export const dataHookProps = {
  defaultInitialValues,
  nonRequired,
  endpoint,
};
