import { fieldTypes } from "componentGenerators/formGenerator/config";
import React from "react";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Military Service",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        If you have served in military, please attach a copy of your DD214 form
        in the <CustomLink to="military" />
        section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "citizenship",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        If are a naturalized US citizen, please attach a copy of your
        naturalization certificate in the <CustomLink to="citizenship" />
        section.
      </>
    ),
  },
];

export default {
  type: "readOnlyPage",
  formSchema,
  endpoint: "oneida_vlabp_supplement_attachments_pdSupplement",
};
