export const defaultInitialValues = {
    il_kp_pd_license_types_checkbox: '',
    il_debt_adjustment_plan_attachment_group_id: null,
    il_qualification_roles_checkbox: '',
    il_license_relationship_checkbox: '',
    il_investment_sources_attachment_group_id: null,
    il_transaction_agreement_attachment_group_id: null,
    il_assignment_agreement_attachment_group_id: null,
    il_employment_agreement_attachment_group_id: null,
    illinois: {
        affiliated_licensee_name: '',
        pd_q21_a_boolean: null,
        pd_q21_a_details: '',
        pd_3_details: '',
        other_position_text_field: '',
        applicant_relationship_explanation: '',
        operational_participation_description: '',
        amount_invested_in_applicant: null,
        percent_owned_of_applicant: null,
        investment_sources_summary: '',
        potential_and_anticipated_relationships: '',
        has_interest_assigned: null,
        expected_compensation_amount: null,
        relatives_employed_by_illinois_gaming_board: '',
        relatives_member_illinois_legislature: '',
        has_relatives_violate_article_18: null,
        has_relatives_convicted_of_misdemeanor: null,
        has_relatives_affiliated_with_license_condition: null,
        relatives_conviction_details: '',
        has_federal_tax_adjustment_or_criminal: null,
        tax_adjustment_explanation: '',
    }
};
