import useEndPoint from "hooks/useEndpoint";
import useDelete from "queryHooks/useDelete";
import useDeleteDialog from "hooks/useDeleteDialog";
import useFetch from "queryHooks/useFetch";
import usePatch from "queryHooks/usePatch";
import usePost from "queryHooks/usePost";
import React from "react";
import useInitialValues from "../useInitialValues";
import { useSnackbar } from "notistack";
import _ from "lodash";
import useTableRecord from "./useTableRecord";
import useFormPath from "../useFormPath";
import { validationSchemaGenerator } from "../../helpers/validationSchemaGenerator";
import { useQueryClient } from "react-query";
import useErrorSnackBar from "hooks/useErrorSnackbar";

const getRequiredValues = (nonRequired, defaultInitialValues) => {
  //any attribute that includes attachment_group_id wont be required. PS: UPDATE FUNCTION WHEN NEEDED TO ADD 2 items or more
  const blackListed = Object.keys(defaultInitialValues).filter((i) =>
    i.includes("attachment_group_id")
  );
  return _.omit(defaultInitialValues, [
    ...nonRequired,
    ...blackListed,
    "is_current",
    "attachment",
    "attachment_group_id",
    "cash_in_bank_attachment_group_id",
    "law_enforcement_pd_business_entity_id",
  ]);
};

const useTabularData = ({
  defaultInitialValues,
  endpoint,
  queryKey: queryKeyParam,
  postValues,
  patchValues,
  postOptions,
  deleteOptions,
  nonRequired = [],
  mutateRequiredValues,
  noValidate,
  noRequired,
  saveEndpoint, //overrides the default save endpoint from useEndPoint
  getEndpoint, // overrides the default get endpoint from useEndPoint
  validationSchemaKey,
  extraInvalidateQueryKeys = [],
  overwriteMissingRequiredDisplay = null,
  fetchOptions,
  initiallyOpen = false,
}) => {
  const { path, noAttributeEndpoint } = useEndPoint(endpoint || "");
  const queryKey = queryKeyParam || endpoint || getEndpoint;

  const {
    data: records,
    isLoading,
    isSuccess,
    isError,
    status,
    refetch,
  } = useFetch(queryKey, getEndpoint || path, {
    useFullEndpoint: getEndpoint ? false : true,
    ...fetchOptions,
  });

  const {
    currentRecord,
    isNewRecord,
    clearCurrentRecord,
    handleAddRecord,
    handleEditRecord,
    handleViewRecord,
    setCurrentRecord,
    setIsNewRecord,
    isView,
    setIsView,
  } = useTableRecord({
    records,
    emptyRecord: defaultInitialValues,
    isNewRecordInitialState: initiallyOpen,
    currentRecordInitialState: initiallyOpen ? defaultInitialValues : null,
  });

  const initialValues = useInitialValues(currentRecord, defaultInitialValues);

  const { enqueueSnackbar } = useSnackbar();

  const { handleErrorMessage } = useErrorSnackBar();

  const queryCache = useQueryClient();

  // checks if endpoint/getEndpoint & saveEndpoint exist and are different
  const refetchOnSave =
    saveEndpoint &&
    (endpoint || getEndpoint) &&
    (saveEndpoint !== endpoint || saveEndpoint !== getEndpoint);

  const confirmDelete = (id) => {
    // eslint-disable-next-line no-use-before-define
    deleteMutation({ id });
  };

  const { reset, openDeleteDialog, ...deleteDialogProps } = useDeleteDialog({
    handleConfirm: confirmDelete,
  });

  const mutationEndpoint = saveEndpoint || noAttributeEndpoint;

  function invalidateQueryKeys(queryKeys) {
    for (let i = 0; i < queryKeys.length; i++) {
      queryCache.invalidateQueries(queryKeys[i]);
    }
  }

  const { mutate: deleteMutation } = useDelete(queryKey, mutationEndpoint, {
    onMutate: reset,
    onSuccess: () => {
      refetchOnSave &&
        invalidateQueryKeys([queryKey].concat(extraInvalidateQueryKeys));
      enqueueSnackbar("Record Deleted", {
        variant: "success",
      });
    },
    onError: (error) => {
      if (deleteOptions?.onError) deleteOptions.onError(error);
      handleErrorMessage(error);
    },
  });

  const { mutate: patchMutation } = usePatch(queryKey, mutationEndpoint, {
    onSuccess: () => {
      refetchOnSave &&
        invalidateQueryKeys([queryKey].concat(extraInvalidateQueryKeys));
      clearCurrentRecord();
    },
  });

  const { mutate: postMutation } = usePost(queryKey, mutationEndpoint, {
    onSuccess: (data) => {
      refetchOnSave &&
        invalidateQueryKeys([queryKey].concat(extraInvalidateQueryKeys));
      if (postOptions?.onSuccess) postOptions.onSuccess(data);
      else clearCurrentRecord();
    },
  });

  const addRecord = (values, options) =>
    postMutation(postValues ? postValues(values) : values, options);

  const editRecord = (values, options) => {
    const idAdded = { id: currentRecord?.id, ...values };
    patchMutation(patchValues ? patchValues(idAdded) : idAdded, options);
  };

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    const options = {
      onSuccess: () => {
        resetForm();
        enqueueSnackbar("Record Saved", {
          autoHideDuration: 2000,
          variant: "success",
        });
      },
      onError: (error) => {
        setSubmitting(false);
        handleErrorMessage(error);
      },
    };
    if (isNewRecord) addRecord(values, options);
    else editRecord(values, options);
  };

  const editableListActions = {
    handleDeleteRecord: openDeleteDialog,
    handleEditRecord,
    handleAddRecord,
    handleViewRecord,
  };

  const requiredValues = React.useMemo(
    () =>
      noRequired ? {} : getRequiredValues(nonRequired, defaultInitialValues),
    [noRequired, nonRequired, defaultInitialValues]
  );

  const tableProps = {
    ...editableListActions,
    records,
    requiredValues,
    mutateRequiredValues,
    overwriteMissingRequiredDisplay,
    isDeleting: deleteDialogProps.open,
    confirmDelete: deleteDialogProps.onConfirm,
    cancelDelete: deleteDialogProps.onCancel,
  };

  const open = Boolean(currentRecord);

  const formPath = useFormPath();

  const validationSchema = React.useMemo(
    () =>
      noValidate
        ? null
        : validationSchemaGenerator(validationSchemaKey || formPath),
    [formPath, noValidate, validationSchemaKey]
  );

  const formikProps = {
    initialValues,
    open,
    validationSchema,
    onSubmit: handleSubmit,
    onCancel: clearCurrentRecord,
    isView,
  };

  return {
    data: records,
    status,
    isLoading,
    isSuccess,
    isError,
    refetch,
    editableListActions,
    deleteDialogProps,
    currentRecord,
    formikProps,
    setCurrentRecord,
    setIsNewRecord,
    clearCurrentRecord,
    handleSubmit,
    requiredValues,
    tableProps,
    open,
    isNewRecord,
    isView,
    setIsView,
  };
};

export default useTabularData;
