import { Box, Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import FromToCurrent from "components/Formik/formGroups/FromToCurrent";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { useMenuOptions } from "hooks/useMenuOptions";
import React from "react";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import FormikCheckBoxGroup from "components/Formik/formGroups/FormikCheckBoxGroup";
import BDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/BDPersonSelect.jsx";

export const EmployeeCompensationForm = () => {
  const { bdCompensationTypeCheckboxOptions } = useMenuOptions();
  return (
    <Grid container spacing={2}>
      <BDPersonSelect
        label="Person Holding the Office"
        header="Person Holding the Office"
      />

      <SectionSubHeader label="Job Details" divider />

      <FormikTextField label="Position/Title" name="position" xs={6} />
      <FormikTextField label="Duties" name="participation_description" xs={6} />
      <SectionSubHeader label="Dates" divider />

      <FromToCurrent />

      <SectionSubHeader label="Annual Compensation" divider />
      <FormikNumberField
        label="Previous Year"
        name="compensation_previous_year"
        md={4}
      />
      <FormikNumberField
        label="Subsequent Year"
        name="compensation_subsequent_year"
        md={4}
      />
      <SectionSubHeader label="Form of Compensation (select all that apply)" />
      <Box width={1} pl={1}>
        <FormikCheckBoxGroup
          menuOptions={bdCompensationTypeCheckboxOptions}
          name="compensation_type_checkbox"
        />
      </Box>
    </Grid>
  );
};
