import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  TableCell,
} from "@material-ui/core";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import useLicenseTable from "components/Pages/LicensingControl/useLicenseTable";
import { useMultiSelect } from "components/Pages/LicensingControl/components/FilterTable/useMultiSelect";
import { headCells } from "components/Pages/LicensingControl/Businesses/config.js";
import AgencyCell from "components/Pages/LicensingControl/components/AgencyCell";
import { StyledTableRow } from "components/Pages/LicensingControl/components/DetailsDialog/Modal.styles.js";
import LicenseCell from "components/Pages/LicensingControl/components/LicenseCell";
import {
  filterEntities,
  getNextExpiry,
  extractLicenses,
  getUniqueValues,
} from "components/Pages/LicensingControl/helpers.js";
import useAnchorEl from "hooks/useAnchorEl";
import useInput from "hooks/useInput";
import PropTypes from "prop-types";
import React from "react";
import FilterTable from "../components/FilterTable/FilterTable";
import { BusinessFilterForm } from "./components/BusinessFilterForm";

const defaultFilterFn = {
  fn: (items) => {
    return items;
  },
};

const Row = ({ handleClickRecord, item: { id, business_name, license } }) => {
  const nextExpiry = React.useMemo(() => {
    return license ? getNextExpiry(license) : [];
  }, [license]);

  const agencies = license.map(
    (item) => item.license_agency_bd_business_entity?.business_name || ""
  );
  const { anchorEl, toggle, setOff } = useAnchorEl();

  const ClickableCell = ({ children, ...rest }) => (
    <TableCell onClick={() => handleClickRecord(id)} {...rest}>
      {children}
    </TableCell>
  );
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={setOff}
      >
        <MenuItem
          onClick={() => {
            handleClickRecord(id);
            setOff();
          }}
        >
          <ListItemIcon>
            <AccountBoxIcon color="action" />
          </ListItemIcon>
          View Record
        </MenuItem>
      </Menu>
      <StyledTableRow hover>
        <ClickableCell style={{ paddingLeft: "20px" }}>
          {`${business_name || ""}`}
        </ClickableCell>
        <ClickableCell>
          {agencies?.length ? <AgencyCell agencies={agencies} /> : "N/A"}
        </ClickableCell>
        <ClickableCell>{nextExpiry}</ClickableCell>
        <ClickableCell width="25%">
          {license?.length ? <LicenseCell licenses={license} /> : "N/A"}
        </ClickableCell>
        <TableCell onClick={toggle}>
          <IconButton size="small">
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </StyledTableRow>
    </>
  );
};

Row.propTypes = {
  handleClickRecord: PropTypes.func,
  handleDeleteRecord: PropTypes.func,
  item: PropTypes.object,
};

export const BusinessEmploymentTable = ({
  records = [],
  openDetails,
  handleClickRecord,
  handleDeleteRecord,
}) => {
  const [filterFn, setFilterFn] = React.useState(defaultFilterFn);

  const [searchValue, handleSearch] = useInput();

  const { tableHeadProps, getSortedRecords } = useLicenseTable({
    records,
    filterFn,
    defaultOrderBy: "expiry",
    defaultOrder: "asc",
  });

  const extractedLicenses = extractLicenses(records);

  const jurisdictionFilterOptions = getUniqueValues({
    array: extractedLicenses,
    property: "license_agency_bd_business_entity.license_agency_jurisdiction",
  });

  const industryFilterOptions = getUniqueValues({
    array: extractedLicenses,
    property: "industry_type",
  });

  const dispositionFilterOptions = getUniqueValues({
    array: extractedLicenses,
    property: "disposition",
  });

  const licenseProps = useMultiSelect();
  const agencyProps = useMultiSelect();
  const jurisdictionProps = useMultiSelect();
  const industryProps = useMultiSelect();
  const dispositionProps = useMultiSelect();

  const { value: jurisdictionValue } = jurisdictionProps;
  const { value: industryValue } = industryProps;
  const { value: dispositionValue } = dispositionProps;

  const sortedRecords = getSortedRecords();

  const filterCount =
    jurisdictionValue.length + industryValue.length + dispositionValue.length;

  const handleSetFilterFn = () => {
    setFilterFn({
      fn: (items) =>
        filterEntities(
          {
            items,
            searchValue,
            jurisdiction: {
              filterValues: jurisdictionValue,
              name: "license",
              path: "license.license_agency_bd_business_entity",
              lookAt: "license_agency_jurisdiction",
            },
            disposition: {
              filterValues: dispositionValue,
              name: "license",
              path: "license",
              lookAt: "disposition",
            },
            industry: {
              filterValues: industryValue,
              name: "license",
              path: "license.license_agency_bd_business_entity",
              lookAt: "license_agency_jurisdiction",
            },
          },
          "business"
        ),
    });
  };
  return (
    <FilterTable
      handleSetFilterFn={handleSetFilterFn}
      handleSearch={handleSearch}
      searchValue={searchValue}
      filterCount={filterCount}
      tableHeadProps={tableHeadProps}
      sortedRecords={sortedRecords}
      title="Businesses"
      headCells={headCells}
      row={(item) => (
        <Row
          item={item}
          key={item.id}
          style={{ borderSpacing: "20px" }}
          openDetails={openDetails}
          handleClickRecord={handleClickRecord}
          handleDeleteRecord={handleDeleteRecord}
        />
      )}
      filterForm={
        <BusinessFilterForm
          licenseProps={licenseProps}
          agencyProps={agencyProps}
          jurisdictionProps={jurisdictionProps}
          industryProps={industryProps}
          dispositionProps={dispositionProps}
          jurisdictionOptions={jurisdictionFilterOptions}
          industryOptions={industryFilterOptions}
          dispositionOptions={dispositionFilterOptions}
        />
      }
    />
  );
};

BusinessEmploymentTable.propTypes = {
  handleClickRecord: PropTypes.func,
  handleDeleteRecord: PropTypes.func,
  openDetails: PropTypes.func,
  records: PropTypes.array,
};
