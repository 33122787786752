import useComponentData from "hooks/useComponentData";
import React from "react";
import EditBusinessEntity from "../EditBusinessEntity";
import useEndPoint from "hooks/useEndpoint";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { Grid } from "@material-ui/core";
import useForm from "hooks/useForm";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

export default function ApplicationDetails({ name }) {
  const [isNewEntity, setIsNewEntity] = React.useState(false);
  const { bdBusinessEntityEndpoint } = useEndPoint("");

  const onCreateSuccess = () => setIsNewEntity(true);

  const { isCreate, onCreate, onUpdate } = useComponentData(
    bdBusinessEntityEndpoint,
    false,
    onCreateSuccess
  );

  const fetchFn = isCreate ? onCreate : onUpdate;

  const formData = useForm({
    defaultInitialValues: {
      business_name: "",
      business_relationship_type: "",
      form_of_business: null,
      incorporation_state: "",
      incorporation_country: null,
      incorporation_date: null,
      to_date: null,
      is_current: false,
      employer_id_number: "",
      is_ein_applied: false,
      unique_entity_number: "",
      dun_bradstreet_id_number: "",
      business_description: "",
      cessation_reason: "",
      registered_agent_bd_party_id: null,
      is_public_company: null,
      business_summary: "",
      business_type_checkbox: {
        is_gaming: null,
        is_government: null,
        is_alcohol: null,
        is_license_agency: null,
        is_financial_institution: null,
        is_law_enforcement: null,
        is_tribal: null,
      },
    },
    endpoint: bdBusinessEntityEndpoint,
    noValidate: true,
  });
  const { data: state } = formData;

  return (
    <FormSectionProvider formData={formData} title={name}>
      <Grid container spacing={1}>
        <LoadingComponent status={formData.status}>
          <EditBusinessEntity
            businessEntityData={state?.[0] || []}
            fetchFn={fetchFn}
            isNewEntity={isNewEntity}
          />
        </LoadingComponent>
      </Grid>
    </FormSectionProvider>
  );
}
