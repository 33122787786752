import { Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import FromToCurrent from "components/Formik/formGroups/FromToCurrent";
import FormikTextField from "components/Formik/fields/FormikTextField";
import React from "react";

export const EditNameForm = () => {
  return (
    <Grid container spacing={2}>
      <FormikTextField
        label="Trade Name / Trademark"
        name="trade_name"
        xs={6}
      />
      <FormikTextField label="Purpose of Name" name="purpose_of_name" xs={6} />

      <SectionSubHeader label="Dates" divider />
      <FromToCurrent />
    </Grid>
  );
};
