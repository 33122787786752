import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormikNumberField, {
  NumberFormatTypes,
} from "components/Formik/fields/FormikNumberField";
import {
  columns,
  defaultInitialValues,
  tableQuestions,
  nonRequired,
} from "./config.js";

const GovermentReportsForm = () => {
  return (
    <Grid container spacing={2}>
      <FormikNumberField
        label="Effective Year"
        name="effective_year"
        type={NumberFormatTypes.year}
        xs={4}
      />
      <Grid item xs={8}>
        <FormikTextField label="Description" name="description" />
      </Grid>

      <FormikFileDropzone
        description="Business Tax Returns"
        label="Upload Attachment"
        name="corporate_report_attachment_group_id"
        size={12}
      />
    </Grid>
  );
};

const GovermentReports = ({ name }) => {
  const formData = useTabularData({
    nonRequired,
    defaultInitialValues,
    endpoint: "bdCorporateReport?report_type=government_agency_report",
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog maxWidth="sm" title="Report Details">
        <GovermentReportsForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
    </FormSectionProvider>
  );
};

export default GovermentReports;
