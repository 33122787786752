import React from "react";

export const columns = [
  {
    field: "repo_date",
  },
  {
    title: "Repossession Company",
    field: "repo_pd_business_entity.business_name",
  },
  {
    field: "property_type",
  },
];

export const tableQuestions = (
  <>
    In the past ten (10) years, have you ever had any property, real or
    personal, repossessed by a finance company in any jurisdiction?
  </>
);

export const defaultInitialValues = {
  property_type: "",
  reason: "",
  repo_date: null,
  repo_pd_business_entity_id: null,
};
