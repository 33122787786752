import { Grid } from "@material-ui/core";
import React from "react";
import { SectionLabel } from "components/Common/LayoutComponents";
import BDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/BDPersonSelect.jsx";

export const EditFounderForm = () => {
  return (
    <Grid container spacing={2}>
      <BDPersonSelect label="Founding Person" />
      <SectionLabel
        label="If the person does not have a current occupation you can update their occupation by editing the person."
        divider
      />
    </Grid>
  );
};
