import { Box } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import React from "react";
import useHover from "hooks/useHover";
import {
  ActionsContainer,
  TimelinePaper,
} from "components/Pages/LicensingControl/components/DetailsDialog/Modal.styles";

export const EditableTransitionCard = ({
  children,
  handleEdit,
  handleDelete,
}) => {
  const [hoverRef, isHovered] = useHover();
  return (
    <Box display="flex" ref={hoverRef}>
      <Box width={1}>
        <TimelinePaper elevation={isHovered ? 3 : 1} variant="outlined">
          <Box p={2}>{children}</Box>
        </TimelinePaper>
      </Box>
      <Box>
        <ActionsContainer
          $hovered={isHovered}
          height="90%"
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          pl={1}
        >
          <SpeedDialAction
            icon={<EditIcon />}
            tooltipTitle="Edit"
            open={false}
            onClick={handleEdit}
          />
          <SpeedDialAction
            icon={<DeleteIcon />}
            tooltipTitle="Delete"
            open={false}
            onClick={handleDelete}
          />
        </ActionsContainer>
      </Box>
    </Box>
  );
};
