import React from "react";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Personal Details",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please provide all requested information in the{" "}
        <CustomLink to="personal_details" /> section.
      </>
    ),
  },
];

export default {
  type: "readOnlyPage",
  formSchema,
  endpoint: "pdSupplement",
};
