import { formats } from "components/Common/Tables/Common/helpers";
import React from "react";

export const columns = [
  {
    field: "security_type",
    format: formats.startCase,
  },
  {
    title: "Avg Value Per Share",
    field: "average_value_per_share",
    format: formats.currency,
  },
  {
    title: "Shares Issued",
    field: "shares_issued_count",
    format: formats.number,
  },
  {
    title: "Shares Authorized",
    field: "shares_authorized_count",
    format: formats.number,
  },
  {
    title: "Shares Outstanding",
    field: "shares_oustanding_count",
    format: formats.number,
  },
  {
    field: "has_voting_rights",
    format: formats.boolean,
  },
  {
    field: "is_publicly_listed",
    format: formats.boolean,
    title: "Is Publicly Listed",
  },
];

export const tableQuestions = (
  <>
    Provide details for all classes of voting, non-voting and other stock
    issued, or to be issued, by the Applicant.
  </>
);

export const defaultInitialValues = {
  //bd_business_entity_id: null,
  security_type: null,
  average_value_per_share: null,
  shares_issued_count: null,
  shares_authorized_count: null,
  shares_oustanding_count: null,
  has_voting_rights: null,
  terms_and_conditions: null,
  is_publicly_listed: false,
  security_exchange_name: "",
  security_exchange_country: "",
  stock_announcement_attachment_group_id: null,
  security_exchange_symbol: "",
};

export const nonRequired = [
  "is_publicly_listed",
  "security_exchange_name",
  "security_exchange_country",
  "stock_announcement_attachment_group_id",
  "security_exchange_symbol",
];
// export const nonRequired = [
//   "average_value_per_share",
// ];
