export const defaultInitialValues = {
  new_york_association_type: null,
  new_york_application_type: null,
  new_york_position_type: null,
  new_york: {
    associated_pd_business_entity_id: null,
    associated_gaming_entity: null,
    position_nature: "",
    position_type_other: "",
  },
};

export const endpoint = "new_york_disclosure_questions_pdSupplement";
