import React from "react";
import { address } from "helpers/initialValues";

export const columns = [
  { field: "asset_type_other", title: "Asset Type" },
  { title: "Relative Name", field: "relative_pd_person.full_name" },
  { field: "nature", title: "Nature of Asset" },
  { title: "Acquisition", field: "acquisition_date", format: "date" },
  { field: "cost", format: "currency" },
  { field: "ownership_percent", title: "Ownership Percent" },
  { field: "market_value", format: "currency" },
];

export const tableQuestions = (
  <>
    Provide information for all other assets owned by you, your spouse, domestic
    partner or your dependent children. Other assets should include, but not be
    limited to, art collections, coin collections, and antiques.
  </>
);

export const nonRequired = ["relative_pd_person_id"];

export const endpoint = "other_assets_otherAsset";

export const defaultInitialValues = {
  relative_pd_person_id: null,
  asset_type_other: "",
  nature: "",
  is_insured: null,
  acquisition_date: null,
  cost: null,
  ownership_percent: null,
  valuation_date: null,
  market_value: null,
  valuation_method: "",
  asset_address: address,
};

export const modalTitle = "Other Asset";

export const dataHookProps = {
  defaultInitialValues,
  nonRequired,
  endpoint,
};
