export const columns = [
  //{ title: "Company", field: "bd_business_entity.business_name" },
  { title: "Year", field: "effective_year" },
  { field: "description" },
];

export const defaultInitialValues = {
  effective_year: null,
  corporate_report_attachment_group_id: null,
  //bd_business_entity_id: null,
  report_type: "bod_meeting_minutes_report",
  description: "",
};

export const tableQuestions = "If the business entity is a corporation, attach the minutes of the board of director' meetings for the previous three years.";
