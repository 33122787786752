import { Box, Divider } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { green } from "@material-ui/core/colors";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import React from "react";

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function FormStatus({ status, handleStatusChange }) {
  return (
    <Box pt={2} cy-data={"formStatus"} display="flex" justifyContent="flex-end">
      <div>
        <Divider />
        <Box pl={1}>
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={status?.status === "completed"}
                onChange={handleStatusChange}
                name="completed"
              />
            }
            label="I have completed this section"
          />
        </Box>
      </div>
    </Box>
  );
}
