import { SectionSubHeader } from "components/Common/LayoutComponents";
import YesNo from "components/Formik/formGroups/YesNo";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import React from "react";

export const LicenseConditions = ({ options }) => (
  <>
    <SectionSubHeader label="License Conditions" divider />
    <YesNo
      xs={12}
      label="Has this license ever been denied, suspended,revoked, withdrawn or subject to any conditions in any jurisdiction?"
      name="has_condition"
    />
    <FormikSelect
      label="Type of Condition"
      name="condition_type"
      options={options}
      xs={4}
    />
    <FormikDatePicker label="Condition Date" name="condition_date" xs={4} />
    <FormikTextField
      label="Reason for Condition"
      name="condition_reason"
      xs={8}
    />
  </>
);
