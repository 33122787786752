import { Box, Grid, Chip } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Form, Formik } from "formik";
import useInitialValues from "hooks/useInitialValues";
import React from "react";
import useToggle from "../../../hooks/useToggle";
import AccordianItem from "./AccordianItem";
import RoleAndOwnershipFields from "./RoleAndOwnershipFields";
import _ from "lodash";

const defaultInitialValues = {
  ownership_percent: null,
  is_current: true,
  role_checkbox: {
    key_person: false,
    officer: false,
    owner: true,
    trust: false,
  },
};

const RoleChips = ({ roleCheckbox }) => {
  return Object.keys(roleCheckbox).map((role) => {
    if (roleCheckbox[role]) {
      return (
        <Chip
          key={role}
          color="primary"
          style={{ marginRight: "5px" }}
          size="small"
          label={_.startCase(_.camelCase(role))}
        />
      );
    }
    return null;
  });
};

const ExistingOwnerForm = ({
  editRecord,
  deleteRecord,
  record: {
    id,
    pd_person: { full_name },
    role_checkbox,
    ownership_percent,
    is_current,
  },
}) => {
  const { on, toggle, setOff } = useToggle();

  const ownerData = { ownership_percent, role_checkbox, is_current };

  const initialValues = useInitialValues(ownerData, defaultInitialValues);

  const handleSaveSuccess = () => {
    setOff();
  };

  const handleDelete = () => {
    deleteRecord(id);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, formikBag) =>
        editRecord({ values, formikBag, id, handleSaveSuccess })
      }
    >
      {({ handleSubmit, isSubmitting }) => (
        <AccordianItem
          isSubmitting={isSubmitting}
          open={on}
          toggle={toggle}
          handleSubmit={handleSubmit}
          handleDelete={handleDelete}
          summary={
            <>
              <Typography>{full_name}</Typography>
              <Box pl={3}>
                <RoleChips roleCheckbox={role_checkbox} />
              </Box>
            </>
          }
        >
          <Grid container spacing={2}>
            <Form>
              <RoleAndOwnershipFields />
            </Form>
          </Grid>
        </AccordianItem>
      )}
    </Formik>
  );
};

export default ExistingOwnerForm;
