import { Box, Button, Card, CardContent, Typography } from "@material-ui/core";
import applicationProgressImg from "assets/screenshots/application-progress.png";
import finishedImg from "assets/screenshots/i-have-finished.png";
import { ReactComponent as GenerateDocImg } from "assets/svgs/undraw-done-checking.svg";
import { ReactComponent as OnboardingImg } from "assets/svgs/undraw-onboarding.svg";
import InlineLink from "components/Common/navigation/InlineLink";
import SvgContainer from "components/Common/SvgContainer";
import React from "react";
import {
  ImgContainer,
  InstructionsContainer,
  StyledCardHeader,
  StyledProgressImg,
} from "./GettingStarted.styles";

const Instruction = ({ title, children }) => (
  <Card variant="outlined">
    <StyledCardHeader
      title={
        <Typography variant="h2">
          <Box fontWeight="fontWeightMedium">{title}</Box>
        </Typography>
      }
    />
    <CardContent>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="525px"
      >
        {children}
      </Box>
    </CardContent>
  </Card>
);

const Span = ({ children }) => (
  <Typography gutterBottom component="span">
    <Box pb={1}>{children} </Box>
  </Typography>
);

const NextButton = (props) => (
  <Box display="flex" justifyContent="flex-end">
    <Button variant="contained" color="primary" size="small" {...props}>
      Next
    </Button>
  </Box>
);

const PersonalDetailsContent = () => (
  <>
    <SvgContainer image={<OnboardingImg />} />
    <InstructionsContainer>
      <Span>
        <strong>1. &nbsp;</strong>Navigate to the
        <InlineLink to="personal_details">Personal Details</InlineLink>section.
      </Span>
      <Span>
        <strong>2. &nbsp;</strong>Fill out your personal information and{" "}
        <b>be sure to click save</b> before leaving the page.
      </Span>
      <Span>
        <strong>3. &nbsp;</strong>Whenever you finish a section, select the “I
        have finished this section” checkbox.
      </Span>
      <Box display="flex" justifyContent="center">
        <ImgContainer src={finishedImg} />
      </Box>
    </InstructionsContainer>
  </>
);

const GenerateAnApplicationContent = () => (
  <>
    <SvgContainer image={<GenerateDocImg />} />

    <InstructionsContainer>
      <Span>
        <strong>1. &nbsp;</strong>Navigate to the
        <InlineLink to="active_applications">Application Management</InlineLink>
        page.
      </Span>
      <Span>
        <strong>2. &nbsp;</strong>Click the &quot;Generate a New
        Application&quot; button
      </Span>
      <Span>
        <strong>3. &nbsp;</strong>Select a Jurisdiction and Application, then
        click &quot;Generate&quot;
      </Span>
      <Span>
        Tip: We recommend starting at the top and working your way down.
        OneComply uses previously entered data for other parts of your profile,
        saving you time.
      </Span>
    </InstructionsContainer>
  </>
);

const FinishingYourProfileContent = ({ closeDialog }) => (
  <>
    <Box display="flex" justifyContent="center" width={1}>
      <StyledProgressImg src={applicationProgressImg} />
    </Box>
    <InstructionsContainer>
      <Span>
        From the
        <InlineLink to="dashboard" onClick={closeDialog}>
          Dashboard
        </InlineLink>
        you can quickly see what sections you still are required to complete.
      </Span>
      <Span>
        Complete each section so that you are ready to generate and submit an
        application
      </Span>
      <Span>
        Optional:
        <InlineLink to="collaborators">Invite Collaborator.</InlineLink> You can
        invite other people to help you with your application.
      </Span>
    </InstructionsContainer>
  </>
);

export const QuickStartInstructions = ({
  handleChangeItem,
  closeDialog,
  selectedItem,
}) => {
  const INSTRUCTIONS = {
    0: (
      <Instruction title="Start by Completing Your Personal Details">
        <PersonalDetailsContent />
        <NextButton onClick={() => handleChangeItem(1)} />
      </Instruction>
    ),
    1: (
      <Instruction title="Generating Your First Application">
        <GenerateAnApplicationContent />
        <NextButton onClick={() => handleChangeItem(2)} />
      </Instruction>
    ),
    2: (
      <Instruction title="Finishing your Profile">
        <FinishingYourProfileContent closeDialog={closeDialog} />
      </Instruction>
    ),
  };

  return <>{INSTRUCTIONS[selectedItem]}</>;
};
