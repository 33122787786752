import {
  fieldTypes,
  pageTypes,
} from "componentGenerators/formGenerator/config";
import React from "react";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Citizenship",
  },
  {
    type: fieldTypes.label,
    label: "Naturalized Citizen:",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        If you are a naturalized citizen of the United States, upload a copy of
        your Certificate of Naturalization in the
        <CustomLink to="citizenship" /> section and attach to your application
        at the back of Attachment 4A cover page.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: "Non-US Citizen:",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        If you are not a United States citizen, but you are a legally authorized
        permanent resident alien or you are authorized to be employed in the
        United States, please upload a copy of your USCIS identification card if
        applicable and/or any other USCIS document that conditions or restricts
        your employment in the
        <CustomLink to="citizenship" /> section and attach to your application
        at the back of Attachment 4B cover page.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "US Taxes",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload a copy of each IRS Form 1040 and 1040X (Amended Return) and all
        appropriate schedules filed by you in the last five years in the
        <CustomLink to="us_taxes" /> section. If you and your spouse filed
        separate tax returns for any year in the last five (5) years, also
        upload a copy of your spouse&apos;s tax return. Please attach all
        documents to your application at the back of Attachment 6A cover page.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Non US Taxes",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload a copy of each non US tax return and all appropriate schedules or
        other attachments required by the tax authorities of the foreign
        jurisdiction in the
        <CustomLink to="non_us_taxes" /> section. Please attach all documents to
        your application at the back of Attachment 6D cover page.
      </>
    ),
  },
];

export default [
  {
    type: pageTypes.readOnlyPage,
    formSchema,
  },
];
