import { Grid } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";

import { useField } from "formik";
import _ from "lodash";
import React, { useState } from "react";

const useStyles = makeStyles(() => ({
  passwordGroup: {
    position: "relative",
  },
}));

// ! This component should get redone to become more composable
export const UserPermissionField = ({ values = [], size, ...props }) => {
  const [field] = useField(props);
  const classes = useStyles();
  const [allPermission] = useState(values);
  const [selectedPermission, setSelectedPermission] = useState(
    field.value || []
  );

  const onPermissionChange = (e) => {
    let newSelectedPermission = [...selectedPermission];
    if (!e.target.checked) {
      // remove the permission from selectedPermission
      newSelectedPermission = _.filter(newSelectedPermission, function (p) {
        return p.id !== parseInt(e.target.value);
      });
    } else {
      // add the permission to selectedPermission
      const targetName = _.filter(allPermission, function (p) {
        return p.type === e.target.name;
      })[0].name;
      newSelectedPermission.push({
        id: parseInt(e.target.value),
        type: e.target.name,
        name: targetName,
      });
    }
    field.onChange({
      target: { value: newSelectedPermission, name: props.name },
    });
    setSelectedPermission(newSelectedPermission);
  };

  return (
    <Grid item md={size || 4}>
      <FormLabel className={classes.FormLabel}>{props.label}</FormLabel>
      {_.map(allPermission, function (permission, index) {
        let disabled = false;
        let checked =
          _.findIndex(selectedPermission, ["id", permission.id]) !== -1;

        return (
          <div key={index}>
            <Checkbox
              color="primary"
              checked={checked}
              name={permission.type}
              disabled={disabled}
              onChange={onPermissionChange}
              value={permission.id}
            />
            {permission.name}
          </div>
        );
      })}
    </Grid>
  );
};
