import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.label,
    label:
      "If you served in any armed forces, please indicate below if you were either a reservist or an active member:",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "colorado_kapa.army_status_type_checkbox",
    dynamicMenuItems: ["coloradoStatusArmyTypeCheckbox"],
    defaultValue: null,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "colorado_kapa_supplement_military_service_pdSupplement",
};
