import PropTypes from "prop-types";
import { SectionLabel } from "components/Common/LayoutComponents";
import React from "react";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import GridContainer from "components/Common/Containers/Grid/GridContainer";
import GridItem from "components/Common/Containers/Grid/GridItem";
import { IconButton } from "@material-ui/core";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import { blue } from "@material-ui/core/colors";
import { FormikDialog } from "components/Formik/formContainers/FormikDialog";
import useForm from "hooks/useForm";
import useToggle from "hooks/useToggle";

const NumFormat = ({ value }) => {
  return (
    <NumberFormat
      value={value}
      fixedDecimalScale={true}
      displayType={"text"}
      decimalSeparator={"."}
      decimalScale={2}
      thousandSeparator={true}
      prefix={"$"}
    />
  );
};

export const BottomTable = ({
  assetsTotal,
  liabilitiesTotal,
  netWorth,
  handleChange,
  classes,
}) => {
  return (
    <table className={`${classes.table} ${classes.footer}`}>
      <tbody>
        <tr>
          <td onClick={() => handleChange(null, 0)}>Total Assets</td>
          <td>
            <NumFormat value={assetsTotal} />
          </td>
        </tr>
        <tr>
          <td onClick={() => handleChange(null, 1)}>Total Liabilities</td>
          <td>
            <NumFormat value={liabilitiesTotal} />
          </td>
        </tr>
        <tr
          style={{ backgroundColor: "#D9D9D9", borderTop: " 2px solid #000" }}
        >
          <td>
            <strong>Net Worth</strong>
          </td>
          <td>
            <strong>
              <NumFormat value={netWorth} />
            </strong>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

BottomTable.propTypes = {
  assetsTotal: PropTypes.number,
  classes: PropTypes.shape({
    footer: PropTypes.object,
    table: PropTypes.object,
  }),
  handleChange: PropTypes.func,
  liabilitiesTotal: PropTypes.number,
  netWorth: PropTypes.number,
};

export const AssetsTableHeader = () => {
  const { on, setOn, setOff } = useToggle();

  const initialValues = {
    cash_value_date: null,
    receivable_value_date: null,
    security_value_date: null,
    realestate_value_date: null,
    life_insurance_value_date: null,
    pension_value_date: null,
    furniture_value_date: null,
    other_asset_value_date: null,
  };

  const formData = useForm({
    endpoint: "net_worth_statements_networth",
    noValidate: true,
    fetchOptions: { useFullEndpoint: false },
    defaultInitialValues: initialValues,
  });

  const {
    formikProps: { onSubmit, ...formikProps },
  } = formData;

  return (
    <thead key="assets">
      <tr>
        <th></th>
        <th>Value at Acquisition</th>
        <th>Current Market Value</th>
        <th>
          <GridContainer>
            <GridItem md={12}>
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div>
                  Special Value Date
                  <IconButton
                    fontSize="small"
                    style={{ padding: 0 }}
                    onClick={setOn}
                  >
                    <AddCircleOutline
                      style={{ fontSize: 18, color: blue[900] }}
                    />
                  </IconButton>
                </div>
              </div>
            </GridItem>
            <FormikDialog
              {...formikProps}
              title="Special Value Dates"
              open={on}
              onCancel={setOff}
              onSubmit={(submitValues, { setSubmitting, resetForm }) => {
                onSubmit(submitValues, { resetForm, setSubmitting });
                setOff();
              }}
              maxWidth="sm"
            >
              <SectionLabel label="If the present or current market value of the item cannot be reasonably done provide a special valuation date of the item.  This is only required if you are unable to provide the current market value." />
              <Grid container spacing={2}>
                <FormikDatePicker
                  label="Cash in Bank"
                  name="cash_value_date"
                  fullwidth
                />
                <FormikDatePicker
                  label="Loan & Receivables"
                  name="receivable_value_date"
                />
                <FormikDatePicker
                  label="Securities"
                  name="security_value_date"
                />
                <FormikDatePicker
                  label="Real Estate Interests"
                  name="realestate_value_date"
                />
                <FormikDatePicker
                  label="Cash Value Life Insurance"
                  name="life_insurance_value_date"
                />
                <FormikDatePicker
                  label="Cash Value Pension "
                  name="pension_value_date"
                />
                <FormikDatePicker
                  label="Furniture & Clothing"
                  name="furniture_value_date"
                />
                <FormikDatePicker label="Vehicles" name="vehicle_value_date" />
                <FormikDatePicker
                  label="Other Assets"
                  name="other_asset_value_date"
                />
              </Grid>
            </FormikDialog>
          </GridContainer>
        </th>
      </tr>
    </thead>
  );
};

export const LiabilitiesTableHeader = () => (
  <thead key="liabilities">
    <tr>
      <th></th>
      <th>Original Amount</th>
      <th>Amount Outstanding</th>
      <th></th>
    </tr>
  </thead>
);

export const Table = ({
  classes,
  items,
  header,
  footerLabel,
  column2Total,
  contingent,
  column1Total,
}) => {
  const history = useHistory();

  return (
    <table className={classes.table}>
      {header}
      <tbody style={{ wdith: "25%" }}>
        {Object.keys(items).map((item, index) => {
          return (
            <tr key={`${item}-${index}`}>
              <td
                onClick={() => {
                  history.push(`/app/${items[item]["route"]}`);
                }}
              >{`${item}`}</td>
              {items[item]["data"].map((i, key) => {
                if (
                  (item === "Cash on Hand" ||
                    item === "Cash In Bank" ||
                    item === "Life Insurance Cash Value") &&
                  key === 0
                ) {
                  return <td key={`${i}-${key}`}>N/A</td>;
                } else if (key === 2) {
                  if (item === "Cash on Hand") {
                    return <td key={`${i}-${key}`}>N/A</td>;
                  }
                  return <td key={`${i}-${key}`}>{i}</td>;
                } else {
                  return <td key={`${i}-${key}`}>{<NumFormat value={i} />}</td>;
                }
              })}
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <td>
            <strong>{footerLabel}</strong>
          </td>
          <td>
            <strong>
              <NumFormat value={column1Total} />
            </strong>
          </td>
          <td>
            <strong>
              <NumFormat value={column2Total} />
            </strong>
          </td>
          <td></td>
        </tr>
        {footerLabel === "Total Liabilities" &&
          Object.keys(contingent).map((item, index) => {
            return (
              <tr key={`${item}-${index}`}>
                <td
                  onClick={() =>
                    history.push(`/app/${contingent[item]["route"]}`)
                  }
                >{`${item}`}</td>
                {contingent[item]["data"].map((i, key) => {
                  return <td key={`${i}-${key}`}>{<NumFormat value={i} />}</td>;
                })}
              </tr>
            );
          })}
      </tfoot>
    </table>
  );
};
