import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LockIcon from "@material-ui/icons/Lock";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import cx from "classnames";
import AppNavbar from "components/Layout/Navbars/AppNavbar.js";
import Sidebar from "components/Layout/Sidebar/Sidebar.js";
import { useAuth } from "context/auth";
import CollaboratorsProvider from "context/collaborators";
import FormConstraintProvider from "context/formConstraint";
import FormStatusProvider from "context/formStatus";
import useLeftNav from "hooks/useLeftNav";
import React from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import AppPageRouter from "routing/AppPageRouter";
import styled from "styled-components";
import Center from "../components/flex/Center";
import GlobalComponents from "../components/GlobalComponents/GlobalComponents";
import useKeyPress from "../hooks/useKeyPress";
import SentryErrorBoundary from "error/SentryErrorBoundary";
import { useMenuOptions } from "hooks/useMenuOptions";

const StyledCenter = styled(Center)`
  width: calc(100vw - 20px);
  height: 70vh;
`;

const NoPermissionsDisplay = () => (
  <StyledCenter>
    <Box display="flex" flexDirection="column" alignItems="center">
      <LockIcon style={{ fontSize: "70px" }} />
      <Box m={2} />
      <Typography variant="h1" gutterBottom>
        This account has not been granted any permissions.
      </Typography>
      <Box m={1} />
      <Typography variant="h2">
        You may request permissions be granted from your account administrator.
      </Typography>
    </Box>
  </StyledCenter>
);

const useStyles = makeStyles(styles);

const CustomReactQueryDevtool = () => {
  const [showReactQueryDevTool, setShowReactQueryDevTool] =
    React.useState(false);

  const f1Pressed = useKeyPress("F1", process.env.NODE_ENV === "development");

  React.useEffect(() => {
    if (f1Pressed) setShowReactQueryDevTool((prev) => !prev);
  }, [f1Pressed]);

  if (showReactQueryDevTool)
    return <ReactQueryDevtools initialIsOpen={false} />;

  return null;
};

export default function AppLayout(props) {
  const {
    styleState: { navWidth },
    navState: { sidebarLoading, routes },
  } = useLeftNav();
  const { loading } = useAuth();
  const { isSuccess: isMenuItemsLoaded } = useMenuOptions();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);

  // styles
  const classes = useStyles(navWidth);

  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
    });
  // ref for main panel div
  const mainPanel = React.createRef();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };

  if (loading || sidebarLoading || !isMenuItemsLoaded) return null;

  return (
    <>
      <SentryErrorBoundary hasDashboardLink>
        <CustomReactQueryDevtool />

        <FormStatusProvider>
          <CollaboratorsProvider>
            <GlobalComponents />
            <div style={{ display: "flex" }}>
              {/* SideBar */}
              {routes?.length && (
                <Sidebar
                  routes={routes}
                  handleDrawerToggle={handleDrawerToggle}
                  open={mobileOpen}
                  miniActive={miniActive}
                  {...props}
                />
              )}
              {/* HEADER */}
              <AppNavbar
                sidebarMinimize={sidebarMinimize.bind(this)}
                miniActive={miniActive}
                handleDrawerToggle={handleDrawerToggle}
                {...props}
              />
              {/* Content */}
              <FormConstraintProvider>
                <div
                  cy-data="container"
                  className={mainPanelClasses}
                  ref={mainPanel}
                >
                  <div className={classes.content}>
                    <div className={classes.container}>
                      <SentryErrorBoundary hasDashboardLink>
                        {routes?.length ? (
                          <AppPageRouter routes={routes} />
                        ) : (
                          <NoPermissionsDisplay />
                        )}
                      </SentryErrorBoundary>
                    </div>
                  </div>
                </div>
              </FormConstraintProvider>
            </div>
          </CollaboratorsProvider>
        </FormStatusProvider>
      </SentryErrorBoundary>
    </>
  );
}
