import { fieldTypes } from "componentGenerators/formGenerator/config";
import { formats } from "components/Common/Tables/Common/helpers";

const bankruptciesColumns = [
  {
    field: "filing_date",
    format: formats.date,
  },
];

const bankruptciesFormSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Date",
  },
  {
    name: "filing_date",
    type: fieldTypes.date,
    label: "Date Filed",
    required: true,
  },
  {
    type: fieldTypes.subHeader,
    label: "Attachments",
  },
  {
    type: fieldTypes.label,
    label:
      "Please attach below a copy of the bankruptcy petition and discharge.",
  },
  {
    type: fieldTypes.dropZone,
    name: "attachment_group_id",
    description: "Bankruptcy",
    label: "Upload Attachment",
  },
];

const bankruptciesSchema = {
  type: "table",
  saveEndpoint:
    "michigan_isbigo_supplement_bankruptcies_bankruptcyLegalHistory",
  endpoint: `michigan_isbigo_supplement_bankruptcies_bankruptcy_last_seven_years`,
  formSchema: bankruptciesFormSchema,
  columns: bankruptciesColumns,
  infoAlerts: [
    {
      body: "Have you filed any type of bankruptcy within the last seven years?",
    },
  ],
  modifySubmissionValues: (formValues) => ({
    ...formValues,
    type: "bankruptcy",
  }),
};

export default [bankruptciesSchema];
