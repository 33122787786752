import React from "react";

export default {
  type: "readOnlyTable",
  endpoint: "views/bd_positions_owners_officers_directors_a",
  linkTo: ["bd_owners", "bd_directors_trustees_officers"],
  columns: [
    {
      title: "Party Name",
      field: "party_name",
    },
    {
      field: "related_bd_party_roles",
      title: "Relationship",
      format: "startCase",
    },
    {
      field: "party_full_address",
      title: "Address",
    },
    {
      field: "ownership_percent",
      title: "Ownership Percentage",
      format: "percent",
    },
  ],
  dependsOnQueryCount: {
    question: (
      <>
        Please identify associated individuals/entities per the following
        requirements:
        <ol>
          <li>
            Individuals and entities, including trusts andinstitutional
            investors, holding more than 5% beneficial interest inapplicant.
          </li>
          <li>
            Managerial employees of applicant who perform thefollowing functions
            or equivalent roles: principal executive officer,principal operation
            officer, and principal financial officer.
          </li>
          <li>
            Board of director members or individuals with equivalent roles of
            applicant, its ultimate parent company, and its holding companies,
            indicating committee participation
          </li>
        </ol>
      </>
    ),
  },
};
