import { formats } from "components/Common/Tables/Common/helpers";

export const assetsColumns = [
  {
    title: "Issuing Entity",
    field: "issuing_business_name",
  },
  {
    field: "asset_type",
    format: formats.startCase,
  },
  {
    field: "type_of_stock",
    format: formats.startCase,
  },
  {
    title: "Held From",
    field: "purchase_date",
    format: formats.date,
  },
  {
    field: "market_value",
    format: "currency",
  },
];
export const legalColumns = [
  {
    title: "Business Entity",
    field: "party_name",
  },
  {
    title: "Agency or Court",
    field: "law_enforcement_bd_busines_entity.business_name",
  },
  {
    title: "Disposition",
    field: "criminal_disposition",
  },
  {
    field: "docket_number",
  },
  {
    field: "case_name",
  },
  {
    field: "type",
    format: formats.startCase
  },
];
export const trustColumns = [
    {
      title: "Trust",
      field: "trust_bd_business_entity.business_name",
    },
    {
      title: "Associated Business",
      field: "bd_business_entity.business_name",
    },
    {
      field: "from_date",
      format: formats.date,
    },
    {
      field: "to_date",
      format: formats.date,
    },
];
export const litigationColumns = [
  {
    title: "Case Name",
    field: "case_name",
  },
  {
    field: "docket_number",
  },
  { title: "Business Entity", field: "party_name" },
  {
    title: "Agency or Court",
    field: "law_enforcement_bd_busines_entity.business_name",
  },

  {
    field: "nature_of_charge",
  },
];
export const taxColumns = [
  {
    title: "Report Type",
    field: "report_type",
    format: formats.startCase,
  },
  {
    title: "Effective Year",
    field: "effective_year",
  },
  {
    title: "Country Filed",
    field: "country_filed",
  },
  {
    title: "Has Attachments",
    field: "corporate_report_attachment_group_id",
    render: ({ corporate_report_attachment_group_id }) => {
      if (corporate_report_attachment_group_id) {
        return "Yes";
      }
      return "No";
    },
  },
];

export const gamingTableQuestion = "Has the applicant, the applicant's parent company or any other intermediary business entity ever held a financial interest in a gambling venture, including but not limited to, a sports betting operation, a race track, dog track, race horse or dog, lottery, casino, bookmaking operation, internet venture company, card room, bingo parlor or pull tabs, whether or not a license to hold such interest was applied for or received?";
export const nonUSTableQuestion = "Does the applicant, the applicant's parent company or any other intermediary business entity now own, has it ever owned, or does it otherwise derive a benefit from, assets held outside the United States, whether held in the business' name or another name, on its behalf or for another entity, or through other business entities, or in trust, or in any other fashion or status?";
export const trustTableQuestion = "Is the applicant, the applicant's parent company or any other intermediary business entity currently a party to, or has it ever been a party to, in any capacity, any business trust instrument?";
export const legalTableQuestion = "Has a complaint, judgment, consent decree, settlement or other disposition related to a violation of federal, state or similar foreign antitrust, trade or security law or regulation ever been filed or entered against the applicant, the applicant's parent company or any other intermediary business entity?";
export const litigationTableQuestion = "Has the applicant, the applicant's parent company or any other intermediary business entity ever been a party to a lawsuit, either as a plaintiff or defendant, complainant or respondent, or in any other fashion, in this or any other country?";
export const taxTableQuestion = "Has the applicant, the applicant's parent company or any other intermediary business entity filed a business tax return in the past three years?";

export const gamingEndpoint = "views/bd_assets?issuing_business_is_gaming=true"
export const nonUSEndpoint = "views/bd_assets_non_us"
export const trustEndpoint = "bdTrust"
export const legalEndpoint = "bdLegalHistory?type=antitrust&type=trade_judgement"
export const litigationEndpoint = "bdLegalHistory?type=litigation"
export const taxEndpoint = "bdCorporateReport?report_type=tax_return"