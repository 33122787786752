import { Grid } from "@material-ui/core";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { useMenuOptions } from "hooks/useMenuOptions";
import React from "react";

const DisciplinaryActionsForm = () => {
  const { employmentDisciplineType } = useMenuOptions();

  return (
    <Grid container spacing={2}>
      <FormikDatePicker name="discipline_date" />
      <FormikSelect
        name="discipline_type"
        options={employmentDisciplineType}
        xs={4}
      />
      <FormikTextField label="Name of Supervisor" name="supervisor" xs={4} />
      <FormikTextField
        label="Reason for Disciplinary Action"
        name="discipline_reason"
        xs={12}
        multiline
        rows={3}
      />
      <FormikTextField
        label="Severance Package"
        helperText="If received, specify details"
        name="severance_details"
        xs={6}
        multiline
        rows={3}
      />
      <FormikTextField
        label="Unemployment Benefits"
        helperText="If received, specify details"
        name="unemployment_benefit_details"
        xs={6}
        multiline
        rows={3}
      />
    </Grid>
  );
};

export default DisciplinaryActionsForm;
