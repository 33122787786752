import { Box, Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { FormikAddressFields } from "components/Formik/formGroups/FormikAddressFields";
import FormikGoogleLookup from "components/Formik/fields/FormikGoogleLookup";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FromToCurrent from "components/Formik/formGroups/FromToCurrent";
import { useMenuOptions } from "hooks/useMenuOptions";
import React from "react";
import FormikCheckBoxGroup from "../../../../../Formik/formGroups/FormikCheckBoxGroup";
import FormikEmailTextField from "components/Formik/fields/FormikEmailTextField";

export const AddressesForm = () => {
  const { bdAddressPurposeCheckboxOptions } = useMenuOptions();

  return (
    <Grid container spacing={1}>
      <SectionSubHeader label="Purpose of Address (select all that apply)" />
      <Box width={1} pl={1}>
        <FormikCheckBoxGroup
          menuOptions={bdAddressPurposeCheckboxOptions}
          name="purpose_checkbox"
        />
      </Box>
      <FormikTextField
        label="Principal Activities (If Applicable)"
        name="description"
        xs={6}
      />
      <FormikTextField
        label="Branch Name (If Applicable)"
        name="branch_name"
        xs={6}
      />

      <SectionSubHeader label="Dates" divider />
      <FromToCurrent type="yearMonth" />

      <Grid item xs={12}>
        <FormikGoogleLookup
          fields={{
            address: "address",
            website: "website",
            phone: "phone",
          }}
        />
      </Grid>

      <SectionSubHeader label="Address Details" divider />

      <Grid item xs={12}>
        <FormikAddressFields />
      </Grid>

      <SectionSubHeader label="Company Details" divider />

      <FormikTextField label="Business Phone" name="phone" xs={6} />
      <FormikTextField label="Business Fax" name="fax" xs={6} />
      <FormikTextField label="Business Website" name="website" xs={6} />
      <FormikEmailTextField label="Business Email" name="email" xs={6} />
    </Grid>
  );
};
