import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.label,
    label:
      "Please state the type of equipment if applicable that will be provided to the Cherokee Casino and the property to which it will be provided:",
  },
  {
    type: fieldTypes.text,
    label: "Equipment Provided Details",
    name: "cherokee_vendor_license.section_4_question_2",
    xs: 12,
    rows: 4,
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Does the Company or any of its Principal Officers, Directors, or Management Officials have any financial interest, ownership interest, or other relationship with a Casino Applicant/ Licensee?",
    name: "cherokee_vendor_license.has_casino_interest",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Does the Company or any of its Principal Officers, Directors, or Management Officials have any financial interest, ownership interest, or other relationship with a Supplier Applicant/ Licensee?",
    name: "cherokee_vendor_license.has_supplier_interest",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Does the Company or any of its Principal Officers, Directors, or Management Officials have any financial interest, ownership interest, or other relationship with a Casino or Supplier / Vendor?",
    name: "cherokee_vendor_license.has_vendor_interest",
  },
];

export default [
  {
    type: "form",
    formSchema,
    endpoint: "bdSupplement",
  },
];
