import { useQuery } from "react-query";
import axios from "axios";

const getInvite = async (inviteHash) => {
  const { data } = await axios.get(`/auth/invite/${inviteHash}`);
  return data;
};

export default function useInvite(inviteHash) {
  return useQuery(["user", inviteHash], () => getInvite(inviteHash));
}
