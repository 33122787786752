import PropTypes from "prop-types";
import CrudSelect, {
  formSchemas,
} from "components/Formik/formGroups/CrudSelect/CrudSelect";
import React from "react";
import useBdIdAddedToAddress from "../hooks/useBdIdAddedToAddress";
import GavelIcon from "@material-ui/icons/Gavel";

const BDLawEnforcementSelect = ({
  name = "law_enforcement_bd_business_entity_id",
  label = "Law Enforcement or Court",
  header = "Law Enforcement or Court",
  endpoint = "views/bd_business_entities?is_law_enforcement=true",
  formTitle = "Add / Edit Law Enforcement or Court",
  formSchema = formSchemas.bdLawEnforcementSchema,
  ...rest
}) => {
  const modifySubmittedValues = useBdIdAddedToAddress();

  return (
    <CrudSelect
      name={name}
      label={label}
      endpoint={endpoint}
      saveEndpoint="bdBusinessEntity"
      overrideDefaultValues={{
        business_type_checkbox: { is_law_enforcement: true },
      }}
      formSchema={formSchema}
      formTitle={formTitle}
      optionIcon={<GavelIcon color="primary" fontSize="small" />}
      modifySubmittedValues={modifySubmittedValues}
      header={header}
      {...rest}
    />
  );
};

BDLawEnforcementSelect.propTypes = {
  endpoint: PropTypes.string,
  formSchema: PropTypes.array,
  formTitle: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  header: PropTypes.string,
};

export default BDLawEnforcementSelect;
