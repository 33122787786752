import PropTypes from "prop-types";
import React from "react";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { useField } from "formik";
import { Box, FormHelperText } from "@material-ui/core";

export function fieldToToggleButtonGroup({
  field: { onChange: _onChange, onBlur: fieldOnBlur, ...field },
  onChange,
  onBlur,
  helpers,
  ...props
}) {
  return {
    onBlur:
      onBlur ??
      function () {
        fieldOnBlur(field.name);
      },
    onChange:
      onChange ??
      function (_event, newValue) {
        helpers.setValue(newValue);
      },
    ...field,
    ...props,
  };
}

export default function FormikToggleButtonGroup({ children, name, ...rest }) {
  const [field, meta, helpers] = useField(name);

  return (
    <Box display="flex" flexDirection="column">
      <ToggleButtonGroup
        {...fieldToToggleButtonGroup({ field, helpers, ...rest })}
      >
        {children}
      </ToggleButtonGroup>
      {meta.error && meta.touched && (
        <FormHelperText error>{meta.error}</FormHelperText>
      )}
    </Box>
  );
}

FormikToggleButtonGroup.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
};
