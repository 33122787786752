import React from "react";
import ConfirmationDialog from "components/Common/Dialogs/ConfirmationDialog";

const DeleteConfirmation = (props) => (
  <ConfirmationDialog
    title="Are you sure you want to delete this record?"
    confirmationButtonProps={{ variant: "contained", color: "error" }}
    dialogProps={{ fullWidth: false }}
    confirmationText="Delete"
    {...props}
  />
);

export default DeleteConfirmation;
