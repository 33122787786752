import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import {
  SectionSubHeader,
  SectionLabel,
} from "components/Common/LayoutComponents";

import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm.js";
import React from "react";
import FormikCheckBoxGroup from "components/Formik/formGroups/FormikCheckBoxGroup";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { useMenuOptions } from "hooks/useMenuOptions";
import BusinessAssociation from "components/Common/BusinessAssociation/BusinessAssociation";

const NewJersey33DisclosureQuestionsForm = () => {
  const { njFrom33Q1Checkbox, njFrom33Q2Checkbox } = useMenuOptions();
  const formData = useForm({
    defaultInitialValues: {
      nj_form33_q1_checkbox: null,
      nj_from33_q2_checkbox: null,
      new_jersey_33: {
        holding_pd_business_entity_id: null,
        qualifier_pd_business_entity_id: null,
        nj_from33_q2_other: null,
      },
    },
    endpoint: "new_jersey_33_disclosure_questions_pdSupplement",
  });

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage>
        <Grid container spacing={2}>
          <BusinessAssociation
            header="Provide the following information about the gaming enterprise applicant or licensee of which you are a qualifier and your position in it:"
            label="Business Entity (Your Association)"
            name={"new_jersey_33.qualifier_pd_business_entity_id"}
          />

          <SectionSubHeader label="Qualifications" />
          <SectionLabel label="I am applying for qualification in connection with:" />

          <FormikCheckBoxGroup
            name="nj_form33_q1_checkbox"
            options={njFrom33Q1Checkbox}
          />
          <SectionLabel label="I am a qualifier because I am a:" />
          <FormikCheckBoxGroup
            name="nj_from33_q2_checkbox"
            options={njFrom33Q2Checkbox}
          />
          <FormikTextField
            label="If other role"
            name={`new_jersey_33.nj_from33_q2_other`}
            xs={4}
          />

          <SectionSubHeader label="Holding" />

          <BusinessAssociation
            header="If you are not a qualifier of the gaming enterprise identified above, provide the name of the enterprise that is a holding company or qualifying entity of the gaming enterprise and your position in it:"
            label="Holding Business (Your Association)"
            name={"new_jersey_33.holding_pd_business_entity_id"}
          />
        </Grid>
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

export default NewJersey33DisclosureQuestionsForm;
