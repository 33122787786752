import React from "react";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import IowaClassDSubsidiaryComplianceForm from "./IowaClassDSubsidiaryCompliance";
import IowaClassDApplicantCompliance from "./IowaClassDApplicantCompliance";

const IowaClassDComplianceOfficer = ({ name }) => {
  return (
    <FormSectionProvider title={name}>
      <IowaClassDApplicantCompliance />
      <IowaClassDSubsidiaryComplianceForm />
    </FormSectionProvider>
  );
};

export default IowaClassDComplianceOfficer;