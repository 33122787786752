import React from "react";
import { formats } from "components/Common/Tables/Common/helpers";

export const columns = [
  //{ title: "Business Entity", field: "bd_business_entity.business_name" },
  { field: "option_name" },
  { field: "security_type", format: formats.startCase },
  { field: "option_grant_years", format: formats.chip },
  {
    title: "Option Expiration",
    field: "option_expiration_date",
    format: formats.date,
  },
];

export const tableQuestions = (
  <>
    Provide details of any security options - existing or to be created - issued
    by the Applicant.
    <br />
    <br />
    Note: For the purpose of this section, option means right, warrant or option
    to subscribe to or purchase securities issued by the Applicant.
  </>
);

export const defaultInitialValues = {
  security_type: "",
  option_grant_years: [],
  option_expiration_date: null,
  option_condition: "",
  option_name: "",
  attachment_group_id: null,
  shares_authorized_count: null,
};
