import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import useFetch from "queryHooks/useFetch";
import { Box } from "@material-ui/core";
import DisplayTable from "components/Common/Tables/DisplayTable";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const NevadaFamilyArrests = ({ name }) => {
  const { data = [], status } = useFetch(
    "nevada_family_arrests_familyArrestLegalHistory",
    "nevada_family_arrests_familyArrestLegalHistory"
  );
  return (
    <FormSectionWrapper title={name}>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={status}>
          <DisplayTable
            records={data}
            linkTo={["family_arrests"]}
            columns={[
              {
                title: "Person Name",
                field: "relative_pd_person.full_name",
              },
              {
                title: "Date of Charge",
                field: "charge_date",
                format: "date",
              },
              { title: "Nature", field: "nature" },
              { title: "Incident State", field: "incident_state" },
              {
                title: "Agency Name or Court",
                field: "law_enforcement_pd_business_entity.business_name",
              },
              { title: "Disposition", field: "disposition" },
            ]}
            dependsOnQueryCount="Has any member of your family or of your spouse’s family ever been convicted of a felony?"
          />
        </LoadingComponent>
      </Box>
    </FormSectionWrapper>
  );
};

export default NevadaFamilyArrests;
