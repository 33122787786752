import React from "react";

export const columns = [
  { title: "Firm", field: "related_pd_business_entity.business_name" },
  { title: "Attorney", field: "representative_name" },
  { title: "Services", field: "service_rendered" },
  { title: "From", field: "from_date", format: "date" },
  { title: "To", field: "to_date", format: "date" },
];

export const defaultInitialValues = {
  related_pd_business_entity_id: null,
  from_date: null,
  to_date: null,
  representative_name: "",
  service_rendered: "",
  service_role: "lawyer",
  is_current: false,
};
export const modalTitle = "Attorneys";

export const tableQuestions = (
  <>
    Identify current and past attorneys utilized in the last ten (10) years and
    services provided.
  </>
);
export const nonRequired = [];

export const endpoint = "iowa_attorneys_pdServiceProviderLawyer";

export const dataHookProps = {
  defaultInitialValues,
  nonRequired,
  endpoint,
};
