import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { Grid } from "@material-ui/core";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormikSelect from "components/Formik/fields/FormikSelect";
import { useMenuOptions } from "hooks/useMenuOptions";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import FromToCurrent from "components/Formik/formGroups/FromToCurrent";
import Tooltip from "components/Common/Tooltip";
import InlineAddressTable from "components/Formik/formGroups/InlineAddressTable.jsx";
import FormikCheckBoxGroup from "components/Formik/formGroups/FormikCheckBoxGroup";

const BusinessEntitiesForm = () => {
  const {
    businessRelationshipType,
    listOfCountries,
    formOfBusiness,
    businessTypeCheckboxOptions,
  } = useMenuOptions();

  return (
    <Grid container spacing={2}>
      <FormikTextField label="Business Name" name="business_name" xs={4} />
      <FormikSelect
        label="Relationship to Applicant"
        name="business_relationship_type"
        options={businessRelationshipType}
        xs={4}
      />

      <SectionSubHeader label="Incorporation Details" divider />
      <FromToCurrent
        fromProps={{ name: "incorporation_date", label: "Incorporation Date" }}
        toProps={{ label: "Cessation Date" }}
      />
      <FormikTextField
        label="Incorporation Place"
        name="incorporation_state"
        xs={4}
      />
      <FormikSelect
        label="Incorporation Country"
        name="incorporation_country"
        options={listOfCountries}
        xs={4}
      />
      <FormikSelect
        label="Form of Business"
        name="form_of_business"
        options={formOfBusiness}
        xs={4}
      />

      <SectionSubHeader label="Identification Numbers" divider />
      <FormikTextField
        label="Employer ID"
        name="employer_id_number"
        xs={4}
        tooltip={
          <Tooltip title="The Employer Identification Number (EIN), also known as the Federal Employer Identification Number (FEIN) or the Federal Tax Identification Number, is a unique nine-digit number assigned by the Internal Revenue Service (IRS) to business entities operating in the United States for the purposes of identification." />
        }
      />
      <FormikTextField
        label="Unique Entity Number"
        name="unique_entity_number"
        xs={4}
        tooltip={
          <Tooltip title="Unique Entity Number (UEN) is identification number of an entity registered outside the United States.  If the business entity is registered in the United States leave this blank and fill in the Employer ID Number field." />
        }
      />

      <SectionSubHeader label="Business Overview" divider />
      <FormikTextField
        label="Business Description"
        name="business_description"
        xs={8}
      />
      <FormikTextField
        label="Cessation Reason"
        name="cessation_reason"
        xs={4}
      />

      <SectionSubHeader>Check all that apply to this entity</SectionSubHeader>
      <Grid item xs={12}>
        <FormikCheckBoxGroup
          menuOptions={businessTypeCheckboxOptions}
          name="business_type_checkbox"
          row
        />
      </Grid>

      <SectionSubHeader label="Addresses" divider />
      <InlineAddressTable />
    </Grid>
  );
};

const BusinessEntities = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "bdBusinessEntity",
    nonRequired: ["business_relationship_type", "cessation_reason"],
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title="Business Entity">
        <BusinessEntitiesForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        columns={columns}
        addButtonText="Add Business Entity"
      />
    </FormSectionProvider>
  );
};

export default BusinessEntities;
