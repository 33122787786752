import { Box, Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import { SectionLabel } from "components/Common/LayoutComponents";
import DisplayTable from "components/Common/Tables/DisplayTable";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm.js";
import useFetch from "queryHooks/useFetch";
import React from "react";
import YesNo from "components/Formik/formGroups/YesNo";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { defaultInitialValues, endpoint, columns } from "./config";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const Form = () => {
  const formData = useForm({
    defaultInitialValues: defaultInitialValues,
    noValidate: true,
    endpoint: endpoint,
  });

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage title={"Personal Information"}>
        <Grid container spacing={2}>
          <YesNo
            label="Has your state or federal tax return been audited or adjusted in the last two years?"
            name="elk_valley.has_tax_audited_adjusted_last_2y"
          />
          <SectionLabel label="If YES, please provide dates & details below:" />
          <FormikTextField
            label="Details"
            name="elk_valley.tax_audited_adjusted_last_2y_details"
          />
        </Grid>
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

const ElkValleyFinancialHistory = ({ name }) => {
  const { data = [], status } = useFetch(
    "elk_valley_financial_history_criminal_lawsuits_last2yrs"
  );

  return (
    <FormSectionWrapper title={name}>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={status}>
          <DisplayTable
            records={data}
            linkTo={["lawsuits_and_arbitrations"]}
            columns={columns}
            dependsOnQueryCount="Did you have any changes to your Financial History in the last two years? (Bankruptcy, Plaintiff/Defendant in civil suit)"
          />
        </LoadingComponent>
      </Box>
      <Form />
    </FormSectionWrapper>
  );
};

export default ElkValleyFinancialHistory;
