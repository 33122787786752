import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Supplemental Allocation Application",
  },
  {
    type: fieldTypes.label,
    label:
      "If more than ten (10) tribes and/or more than ten (10) professional sports teams qualify for an Event Wagering Operator license, the Department shall allocate the licenses among the qualifying tribes and/or qualifying professional sports teams and ensure an equal opportunity for all qualified applicants required by A.R.S. § 5-1305(C) by considering criteria pursuant to A.A.C. R19-4-106(E).",
  },
  {
    type: fieldTypes.label,
    label:
      "Please demonstrate below how you meet each criterion. Responses may include answers and details relative to the event wagering operator applicant, a designee, and/or a management services provider. You may attach additional documentation to support your application. Please note, this application will only be used if there are more qualified applicants than available Event Wagering Operator licenses.",
  },
  {
    type: fieldTypes.divider,
  },
  {
    type: fieldTypes.label,
    label:
      "Please describe your business ability, experience, and track record, both local and international which establishes the ability to create and maintain a successful event wagering operation:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "arizona_designee.allocation_question_1",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label:
      "Please describe your experience and track record, both local and international, in the operation of gaming or related activity:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "arizona_designee.allocation_question_2",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label:
      "Please provide details regarding contributions to the surrounding community (to include consideration of the size of the community, use of revenue to assist the community, and the extent to which the community has already, or may in the future, benefit from gaming):",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "arizona_designee.allocation_question_3",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label:
      "Please describe your standing in terms of obtaining and maintaining licenses/permits in all markets:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "arizona_designee.allocation_question_4",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label:
      "Please provide details regarding your vision, willingness, and commitment to make local investments in Arizona, or on tribal lands, to include similar behavior in other states, if applicable:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "arizona_designee.allocation_question_5",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label:
      "Please provide details regarding your culture of player protection, investments in player protection, and governance program:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "arizona_designee.allocation_question_6",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label:
      "Please describe the responsiveness, approachability, and involvement of local management:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "arizona_designee.allocation_question_7",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label:
      "Please describe your competency to conduct event wagering, including proposed internal controls, and the maximization of privilege fees to the State:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "arizona_designee.allocation_question_8",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label:
      "Please provide details regarding your plans to begin operating event wagering within six (6) months after obtaining a license:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "arizona_designee.allocation_question_9",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label:
      "Please provide details regarding your financial stability, resources, integrity, business ability, and business acumen:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "arizona_designee.allocation_question_10",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label:
      "Please provide details regarding your regulatory compliance and cooperation with regulatory authorities:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "arizona_designee.allocation_question_11",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label:
      "If applicable, please describe any potential lack of opportunity to benefit from event wagering type activity in some manner or location without a license:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "arizona_designee.allocation_question_12",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label:
      "If applicable, please provide details regarding whether the issuance of the license will provide benefits to other qualified applicants through partnerships or other opportunities:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "arizona_designee.allocation_question_13",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label:
      "Please describe any potential increased employment and enhancement of the labor market in Arizona, or on tribal lands, relative to the issuance of an event wagering license:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "arizona_designee.allocation_question_14",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label:
      "Please describe whether you will be located and/or headquartered in the State or whether you will use a designee or management services provider, or are partners with an entity located and/or headquartered in the State:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "arizona_designee.allocation_question_15",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label:
      "Please describe how you would appeal to a unique or unaddressed market or introduce a unique brand or affiliate:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "arizona_designee.allocation_question_16",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label:
      "Please provide details regarding how the issuance of a license would increase the patron base in the State:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "arizona_designee.allocation_question_17",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label:
      "Please provide any other information you would like the Department to consider as part of this application:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "arizona_designee.allocation_question_18",
    rows: 4,
    xs: 12,
  },
];

export default [
  {
    type: "form",
    formSchema,
    endpoint: "bdSupplement",
  },
];
