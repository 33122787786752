import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const StyledFormControl = styled(FormControl)`
  margin: ${({ theme }) => theme.spacing(1, 0)};
  min-width: 130px;
`;

/*
Example menuItems format

If label different than value:
[
  { value: 2020, label: 'Twenty-Twenty' },
  { value: 2019, label: 'Twenty-Nineteen' },
  { value: 2018, label: 'Twenty-Eighteen' },
];

If label same as value:
[2020, 2019, 2018];
*/

const DropDown = ({ label, value, onChange, menuItems, name, ...props }) => (
  <StyledFormControl {...props}>
    {label && <InputLabel>{label}</InputLabel>}
    <Select value={value} onChange={onChange} label={label} name={name}>
      {menuItems.length &&
        menuItems.map((item) => (
          <MenuItem key={item.value || item} value={item.value || item}>
            {item.label || item}
          </MenuItem>
        ))}
    </Select>
  </StyledFormControl>
);

export default DropDown;
