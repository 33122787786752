import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import { SectionSubHeader } from "components/Common/LayoutComponents/index";
import { FormikAddressFields } from "components/Formik/formGroups/FormikAddressFields";
import FormikGoogleLookup from "components/Formik/fields/FormikGoogleLookup";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm";
import React from "react";
import { defaultInitialValues } from "./config";
import FormikEmailTextField from "components/Formik/fields/FormikEmailTextField";

const MichiganContactInformationForm = () => {
  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Contact Information of Authorized Contact" />

      <FormikTextField
        label="Name Of Authorized Contact (liaison to MGCB)"
        name="michigan.contact_name"
        xs={4}
      />

      <FormikTextField label="Title" name="michigan.contact_title" xs={4} />

      <FormikTextField
        label="Phone Number"
        name="michigan.contact_phone_number"
        xs={4}
      />

      <FormikEmailTextField
        label="Email"
        name="michigan.contact_email"
        xs={4}
      />

      <FormikGoogleLookup
        label="Address"
        fields={{
          address: "michigan_contact_address",
        }}
      />

      <FormikAddressFields addressPrefix="michigan_contact_address." />
    </Grid>
  );
};

const MichiganContactInformation = ({ name }) => {
  const formData = useForm({
    defaultInitialValues,
    endpoint: "michigan_contact_information_pdSupplement",
  });

  return (
    <FormSectionProvider formData={formData} title={`Michigan ${name}`}>
      <EnhancedFormikPage>
        <MichiganContactInformationForm />
      </EnhancedFormikPage>
    </FormSectionProvider>
  );
};

export default MichiganContactInformation;
