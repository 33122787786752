import React from "react";

export const columns = [
  {
    title: "Business",
    field: "pd_business_entity.business_name",
  },
  {
    title: "Trustee",
    field: "trustee_pd_business_entity.business_name",
  },
  {
    field: "case_number",
  },

  {
    field: "liquidation_date",
  },
  {
    field: "reason",
  },
  {
    field: "status",
  },
];

export const tableQuestions = (
  <>
    Have you as an individual, member of a partnership, or owner, director or
    officer of a corporation ever been in a business entity that has been in
    liquidation, receivership or been placed under some form of governmental
    administration or monitoring?
  </>
);

export const defaultInitialValues = {
  pd_business_entity_id: null,
  trustee_pd_business_entity_id: null,
  liquidation_date: null,
  relationship_to_business: "",
  status: "",
  reason: "",
  case_number: "",
};
