import { formats } from "components/Common/Tables/Common/helpers";

export const columns = [
  {
    title: "Qualifier",
    field: "party_name",
  },
  {
    title: "Role",
    field: "related_bd_party_role",
    format: formats.startCase,
  },
  {
    title: "Held From",
    field: "from_date",
    format: "date",
  },
  {
    title: "Held To",
    field: "to_date",
    format: "date",
  },
];

export const defaultInitialValues = {
  related_bd_party_id: null,
  related_bd_party_role: "",
  from_date: null,
  to_date: null,
  is_current: false,
};

export const endpoint = "views/bd_qualifiers_new_jersey_a";
