import {
  columns,
  nonRequired,
  endpoint,
  formSchema,
  infoAlerts,
  modifySubmissionValues,
} from "./config.js";

const schema = {
  type: "table",
  endpoint,
  formSchema,
  nonRequired,
  columns,
  infoAlerts,
  modifySubmissionValues,
};

export default [schema];
