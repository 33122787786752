import { Table, TableHead } from "@material-ui/core";
import styled, { css } from "styled-components";

export const TableContainer = styled(Table)`
  & thead th {
    font-weight: 600;
    color: ${({ theme }) => theme.palette.primary.light};
  }
`;

export const StyledTableHeader = styled(TableHead)`
  background-color: ${({ theme }) => theme.palette.grey[100]};

  ${({ $isDense, theme }) =>
    !$isDense &&
    css`
      border-top: solid ${theme.palette.divider} 1px;
    `}
`;
