import { fieldTypes } from "componentGenerators/formGenerator/config";

const bankAccountColumns = [
  {
    field: "bank_bd_business_entity.business_name",
    title: "Financial Institute",
  },
  {
    field: "bank_bd_business_entity.employer_id_number",
    title: "Federal Identification Number",
  },
  { field: "account_number" },
  { field: "to_date", title: "Held To" },
  { field: "from_date", title: "Held From" },
  { field: "nature", title: "Purpose" },
  { field: "account_closed_reason", title: "Reason for Closing" },
];

const bankAccountFormSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Financial Institution",
  },
  {
    type: fieldTypes.bdFinancialSelect,
    name: "bank_bd_business_entity_id",
    label: "Business Entity",
    required: true,
  },
  {
    type: fieldTypes.subHeader,
    label: "Account Details",
    divider: true,
  },
  {
    type: fieldTypes.text,
    label: "Account Number",
    name: "account_number",
    xs: 6,
  },
  {
    type: fieldTypes.select,
    name: "account_type",
    dynamicMenuItems: ["bdBankAccountTypes"],
    label: "Account Type",
    defaultValue: null,
    xs: 6,
  },
  {
    type: fieldTypes.text,
    label: "Account Purpose",
    name: "nature",
    xs: 6,
  },
  {
    type: fieldTypes.text,
    label: "Reason for Closing (if applicable)",
    name: "account_closed_reason",
    xs: 6,
  },
  {
    type: fieldTypes.subHeader,
    label: "Account Dates",
    divider: true,
  },
  {
    type: fieldTypes.date,
    label: "From",
    name: "from_date",
  },
  {
    type: fieldTypes.date,
    label: "To",
    name: "to_date",
  },
  {
    type: fieldTypes.checkBox,
    name: "is_current",
    label: "Is current",
    xs: 4,
  },
  {
    type: fieldTypes.subHeader,
    label: "Account Statement",
    divider: true,
  },
  {
    type: fieldTypes.dropZone,
    name: "bank_account_statement_attachment_group_id",
    xs: 12,
  },
];

const bankAccountSchema = {
  type: "table",
  formSchema: bankAccountFormSchema,
  endpoint: "bdBankAccount",
  title: "Financial Accounts",
  columns: bankAccountColumns,
  infoAlerts: [
    {
      body: `Provide information with respect to each bank, savings and loan association, and any other financial institution, 
          whether domestic or foreign, in which the Applicant or Holding Company has or has had an account over the last ten (10) year period, 
          regardless of whether the Applicant or Holding Company held the account in its own name or in the name of a nominee, or any other person over whom 
          the Applicant or Holding Company exercised direct or indirect control.`,
    },
    {
      body: `For each Financial Institution, please enter a Federal Identification Number as needed. 
      For each closed account, please provide a reason for closing.`,
    },
  ],
};

export default [bankAccountSchema];
