import { Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import Tooltip from "components/Common/Tooltip";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import PDLawEnforcementSelect from "components/Formik/formGroups/CrudSelect/selects/PDLawEnforcementSelect.jsx";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import {
  columns,
  dataHookProps,
  modalTitle,
  tableQuestions,
} from "./config.js";

const Form = () => {
  const { criminalDispositionType } = useMenuOptions();

  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Government Agency" />

      <PDLawEnforcementSelect endpoint="law_business_entities" />

      <Grid item xs={4} />

      <FormikTextField label="Nature of charge" name="nature" xs={4} />
      <FormikDatePicker label="Date" name="charge_date" xs={3} />
      <FormikSelect
        label="Disposition"
        name="disposition"
        options={criminalDispositionType}
        xs={4}
      />
      <FormikTextField
        label="Name of Participant"
        name="participant_name"
        xs={4}
      >
        <Tooltip
          title={
            "Enter your name or the name you used at the time of the citation or charge"
          }
        />
      </FormikTextField>
    </Grid>
  );
};

const Citations = ({ name }) => {
  const formData = useTabularData(dataHookProps);

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={modalTitle}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
    </FormSectionProvider>
  );
};

export default Citations;
