export default function tableReducer(state, { type, rowIndex, row, data }) {
  switch (type) {
    case "ADD_TO_TABLE": {
      return [row, ...state];
    }

    case "EDIT_ROW": {
      return [...state.slice(0, rowIndex), row, ...state.slice(rowIndex + 1)];
    }

    case "DELETE_ROW": {
      return [...state.slice(0, rowIndex), ...state.slice(rowIndex + 1)];
    }

    case "LOAD": {
      return data;
    }
    default: {
      return state;
    }
  }
}
