import DateFnsUtils from "@date-io/date-fns";
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "assets/scss/material-dashboard-pro-react.scss";
import { SnackBar } from "components/Common/SnackBar/SnackBar";
import AuthProvider from "context/auth";
import LoginRedirectPathProvider from "context/loginRedirectFrom";
import React from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import { ThemeProvider } from "styled-components";
import Auth0Wrapper from "./components/Common/AuthWrapper";
import theme from "./theme/theme";

const queryClient = new QueryClient();

const AppProviders = ({ children }) => (
  <StylesProvider injectFirst>
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <SnackBar>
            <LoginRedirectPathProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Auth0Wrapper>
                  <AuthProvider>{children}</AuthProvider>
                </Auth0Wrapper>
              </MuiPickersUtilsProvider>
            </LoginRedirectPathProvider>
          </SnackBar>
        </QueryClientProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  </StylesProvider>
);

export default AppProviders;
