import TennesseeDisclosureQuestions from "components/Pages/Supplemental/PD/Tennessee/TennesseeDisclosureQuestions/TennesseeDisclosureQuestions";
import TennesseeEmployment from "components/Pages/Supplemental/PD/Tennessee/TennesseeEmployment/TennesseeEmployment";
import TennesseeTaxInformation from "components/Pages/Supplemental/PD/Tennessee/TennesseeTaxInformation/TennesseeTaxInformation";

const tennessee_pages = {
  tennessee_disclosure_questions: TennesseeDisclosureQuestions,
  tennessee_employment: TennesseeEmployment,
  tennessee_tax_information: TennesseeTaxInformation,
};

export default tennessee_pages;
