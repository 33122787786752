/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Field } from "formik";
import React from "react";
import TextField from "../../Common/Inputs/TextField";
import withFieldTools from "hocs/withFieldTools";
import { useField } from "formik";

const fieldToAutocomplete = ({
  disabled,
  field,
  form: { isSubmitting, setFieldValue, values },
  onChange,
  onBlur,
  getSetFieldValue: _getSetFieldValue,
  ...props
}) => {
  const { onChange: _onChange, onBlur: _onBlur, ...fieldSubselection } = field;
  return {
    onBlur:
      onBlur ??
      function (event) {
        if (event.target.value) {
          const previousValues = values[field?.name] || [];
          if (previousValues?.length) {
            setFieldValue(
              field.name,
              previousValues.concat([event.target.value])
            );
          } else {
            setFieldValue(field.name, [event.target.value]);
          }
        }
      },
    onChange:
      onChange ??
      function (_event, value) {
        setFieldValue(field.name, value);
      },
    disabled: disabled ?? isSubmitting,
    loading: isSubmitting,
    ...fieldSubselection,
    ...props,
  };
};

export const FormikAutocompleteBase = ({
  name,
  options,
  getOptionLabel,
  label,
  loading,
  freeSolo,
  multiple,
  helperText,
  disabled,
}) => {
  const [, meta] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <Field
      disabled={disabled}
      freeSolo={freeSolo}
      multiple={multiple}
      options={options}
      name={name}
      component={(props) => <Autocomplete {...fieldToAutocomplete(props)} />}
      getOptionLabel={getOptionLabel}
      fullWidth={true}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          helperText={errorText || helperText}
          error={!!errorText}
        />
      )}
    />
  );
};

FormikAutocompleteBase.propTypes = {
  getOptionLabel: PropTypes.func,
  label: PropTypes.string,
  loading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
};

export default withFieldTools(FormikAutocompleteBase);
