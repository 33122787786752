import { fieldTypes } from "componentGenerators/formGenerator/config";
import React from "react";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Type of Application",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "west_virginia_swil.license_type_checkbox",
    dynamicMenuItems: ["westVirginiaSWILLicenseTypeCheckbox"],
    defaultValue: null,
  },
  {
    type: fieldTypes.subHeader,
    label: "Business Registration Number",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please provide your West Virginia State Tax Department Business
        Registration Number by entering a record for West Virginia in the
        Authorized States tab in the
        <CustomLink to="bd_application_details" label="Application Details" />
        section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Certificate of Authorization Number",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label:
      "Please provide your West Virginia Secretary of State Certificate of Authorization number:",
  },
  {
    type: fieldTypes.text,
    label: "Authorization Number",
    name: "west_virginia_swil.certificate_authorization_number",
  },
  {
    type: fieldTypes.subHeader,
    label: "Contact Person",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label:
      "Please select the designated person who will provide assistance and cooperation to the commission.",
  },
  {
    type: fieldTypes.bdPersonSelect,
    label: "Contact Person",
    name: "west_virginia_swil.contact_person_id",
    xs: 4,
  },
  {
    type: fieldTypes.subHeader,
    label: "Additional Questions",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label:
      "Explain the flow of services between the management services provider and/or supplier?",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "west_virginia_swil.question_1_details",
    xs: 12,
    rows: 4,
  },
  {
    type: fieldTypes.label,
    label:
      "What data is provided to the management services provider and/or supplier?",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "west_virginia_swil.question_2_details",
    xs: 12,
    rows: 4,
  },
  {
    type: fieldTypes.label,
    label:
      "How is the data provided to the management services provider and/or supplier (through vendor API, custom integration, etc.)?",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "west_virginia_swil.question_3_details",
    xs: 12,
    rows: 4,
  },
  {
    type: fieldTypes.label,
    label: "How is the revenue shared?",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "west_virginia_swil.revenue_shared_checkbox",
    dynamicMenuItems: ["westVirginiaSWILRevenueSharedTypeCheckbox"],
    defaultValue: null,
  },
  {
    type: fieldTypes.label,
    label: "If other, please explain how:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "west_virginia_swil.question_4_details",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Is the revenue shared directly with a West Virginia Casino/Operator?",
    name: "west_virginia_swil.question_5_boolean",
  },
  {
    type: fieldTypes.label,
    label: "If yes, please include fully executed contracts.",
  },
  {
    type: fieldTypes.dropZone,
    name: "west_virginia_swil.question_5_attachment_group_id",
    description: "Executed Contracts",
    label: "Upload Attachment",
    xs: 12,
  },
  {
    type: fieldTypes.yesNo,
    label: "Do the services touch the platform?",
    name: "west_virginia_swil.question_6_1_boolean",
  },
  {
    type: fieldTypes.yesNo,
    label: "Do the services provided involve gaming?",
    name: "west_virginia_swil.question_6_2_boolean",
  },
  {
    type: fieldTypes.yesNo,
    label: "Is the business advertising odds or statistics?",
    name: "west_virginia_swil.question_6_3_boolean",
  },
  {
    type: fieldTypes.yesNo,
    label: "Is the business setting offs or statistics?",
    name: "west_virginia_swil.question_6_4_boolean",
  },
  {
    type: fieldTypes.yesNo,
    label: "Do the services provided integrate with a mobile application?",
    name: "west_virginia_swil.question_6_5_boolean",
  },
  {
    type: fieldTypes.label,
    label:
      "If yes, please provide screen shots and access to your mobile application.",
  },
  {
    type: fieldTypes.dropZone,
    name: "west_virginia_swil.question_6_5_attachment_group_id",
    description: "Screen Shots and Access to your mobile application",
    label: "Upload Attachment",
    xs: 12,
  },
];

export default [
  {
    formSchema,
    endpoint: "bdSupplement",
    type: "form",
  },
];
