import React from "react";
import { address } from "helpers/initialValues";

export const columns = [
  { field: "owner_of_record" },
  { title: "Relative Name", field: "relative_pd_person.full_name" },
  { field: "acquisition_date", format: "date" },
  { field: "property_address", format: "address" },
  { field: "market_value", format: "currency" },
];

export const tableQuestions = (
  <>
    List all real property in any jurisdiction in which any direct, indirect,
    vested or contingent interest is held by you, your spouse or dependent
    children, along with the names of all individuals or entities who share a
    direct, indirect, vested or contingent interest therein. Include the
    location, size, general nature, acquisition date and other information
    requested
  </>
);

export const nonRequired = [
  "relative_pd_person_id",
  "real_estate_interests_attachment_group_id",
];

export const endpoint = "real_estate_interests_realestate";

export const defaultInitialValues = {
  property_purpose: "",
  relative_pd_person_id: null,
  acquisition_date: null,
  property_size: "",
  property_type: "",
  owner_of_record: "",
  ownership_percent: null,
  annual_income: null,
  down_payment: null,
  purchase_price: null,
  monthly_income: null,
  market_value: null,
  real_estate_interests_attachment_group_id: null,
  property_address: address,
  realestate_ownership: [],
};

export const modalTitle = "Real Estate Interests";

export const dataHookProps = {
  defaultInitialValues,
  nonRequired,
  endpoint,
};
