import { fieldTypes } from "componentGenerators/formGenerator/config";
import { NumberFormatTypes } from "components/Common/Inputs/NumberField";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Marital Status",
  },
  {
    type: fieldTypes.yesNo,
    label: "Since your last submission, has your marital status changed? ",
    name: "pd_oklahoma_rkela.has_marital_status_change",
  },
  {
    type: fieldTypes.label,
    label:
      "If you are divorced or separated, please provide the below information:",
  },
  {
    type: fieldTypes.number,
    label: "Monthly Alimony",
    name: "pd_oklahoma_rkela.monthly_alimony",
    format: NumberFormatTypes.dollar,
  },
  {
    type: fieldTypes.number,
    label: "Monthly Child Support",
    name: "pd_oklahoma_rkela.monthly_child_support",
    format: NumberFormatTypes.dollar,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "pd_oklahoma_rkela_supplement_marital_status_pdSupplement",
};
