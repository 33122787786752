import { Divider } from "@material-ui/core";
import { MultiSelect } from "components/Pages/LicensingControl/components/FilterTable/MultiSelect.jsx";
import React from "react";

export const BusinessFilterForm = ({
  jurisdictionProps,
  industryProps,
  dispositionProps,
  jurisdictionOptions,
  industryOptions,
  dispositionOptions,
}) => {
  return (
    <>
      <MultiSelect
        options={jurisdictionOptions}
        label={"Jurisdiction"}
        placeholder="Select Jurisdiction"
        {...jurisdictionProps}
      />
      <Divider style={{ margin: "20px 0px" }} />
      <MultiSelect
        options={industryOptions}
        label={"Industry"}
        placeholder="Select Industry"
        {...industryProps}
      />
      <Divider style={{ margin: "20px 0px" }} />
      <MultiSelect
        options={dispositionOptions}
        label={"Disposition"}
        placeholder="Select Disposition"
        {...dispositionProps}
      />
      <Divider style={{ margin: "20px 0px" }} />
    </>
  );
};
