export const columns = [
  {
    title: "From Date",
    field: "from_date",
    format: "date",
  },
  {
    title: "To Date",
    field: "to_date",
    format: "date",
  },
  { title: "Position", field: "position" },
  {
    title: "Person of Interest",
    field: "person_name",
  },
  {
    title: "Relationship",
    field: "person_relative_type",
    format: "startCase",
  },
];

export const dependsOnQueryCount =
  "Are you, your spouse, minor child or unemancipated child, currently or within the last 12 months, an executive-level public employee, public official or party officer?";
