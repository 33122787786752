import React from "react";
import { formats } from "components/Common/Tables/Common/helpers";

export const columns = [
  { title: "Name", field: "full_name" },
  {
    title: "Relationship",
    field: "relative_type",
    format: formats.startCase,
  },
  { field: "ssn", title: "Social Security Number" },
];
export const defaultInitialValues = {
  first_name: "",
  middle_name: "",
  last_name: "",
  suffix_name: "",
  relative_type: "",
  ssn: "",
};

export const modalTitle = "Applicant/Family Members";
export const tableQuestions = (
  <>
    Identify applicant’s Social Security number and those of all immediate
    family members and their relationship to the applicant (immediate family is
    defined as spouse, children, step-children, parents, siblings).
  </>
);
export const nonRequired = [];
export const endpoint = "iowa_applicant_family_members_immediateFamily";
export const dataHookProps = {
  defaultInitialValues,
  nonRequired,
  endpoint,
  overwriteMissingRequiredDisplay: {
    ssn: "Social Security Number",
  },
};
