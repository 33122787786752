import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import Grid from "@material-ui/core/Grid";

export default function GridItem(props) {
  const {
    children,
    className,
    size,
    cancelTokens: _cancelTokens,
    setFieldValue: _setFieldValue,
    ...rest
  } = props;
  return (
    <Grid {...size} item {...rest} className={className}>
      {children}
    </Grid>
  );
}

GridItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
