import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Accounting Year",
  },
  {
    type: fieldTypes.label,
    label:
      "Please choose your account year below and provide the date of the year end date:",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "arizona_designee.accounting_year_checkbox",
    dynamicMenuItems: ["arizonaDesigneeAccountingYearCheckbox"],
    defaultValue: null,
  },
  {
    type: fieldTypes.date,
    label: "Year End Date",
    name: "arizona_designee.year_end_date",
  },
  {
    type: fieldTypes.subHeader,
    label: "Adjusted Gross Event Wagering Receipts",
  },
  {
    type: fieldTypes.label,
    label: "What is the Designee’s Expected AGEWR? ",
  },
  {
    type: fieldTypes.text,
    label: "AGEWR",
    name: "arizona_designee.agewr_number",
  },
];

export default [
  {
    type: "form",
    formSchema,
    endpoint: "bdSupplement",
  },
];
