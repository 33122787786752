import { get, set } from "lodash";

export function getIsAll(object, lookup = "write", name) {
  if (!object) return false;
  if (name === "pd_supplement") {
    return Object.keys(object).every((i) =>
      Object.keys(object[i]).every((z) => object[i][z][lookup])
    );
  } else {
    return Object.keys(object).every((i) => {
      return object[i][lookup];
    });
  }
}
function supplementReduce(previousState, action) {
  return Object.keys(previousState).reduce((prev, curr) => {
    return {
      ...prev,
      [curr]: {
        ...previousState[curr],
        ...Object.keys(previousState[curr]).reduce((innerPrev, innerCurr) => {
          return {
            ...innerPrev,
            [innerCurr]: {
              ...previousState[curr][innerCurr],
              [action]: !previousState[curr][innerCurr][action],
            },
          };
        }, {}),
      },
    };
  }, {});
}

export const permReduceTypes = {
  read: "READ",
  write: "WRITE",
  view_all: "VIEW_ALL",
  edit_all: "EDIT_ALL",
  load: "LOAD",
  supplement_edit_all: "SUPPLEMENT_EDIT_ALL",
  supplement_view_all: "SUPPLEMENT_VIEW_ALL",
};

export default function reducer(state, action) {
  const stateCopy = { ...state };
  const previousState = get(stateCopy, `${action.name}`);
  const tokens = action.name
    ? action.name.split(".").slice(0, 2).join(".")
    : "";
  const oldObject = get(stateCopy, tokens);

  const isAllWrite = getIsAll(oldObject, "write");
  const isAllRead = getIsAll(oldObject, "read");

  if (action.type === permReduceTypes.load) {
    return { ...action.initialState };
  }

  if (action.type === permReduceTypes.supplement_view_all) {
    const newState = supplementReduce(previousState, "read");

    const SUPPLEMENT_VIEW_ALL = set(stateCopy, `${action.name}`, {
      ...newState,
    });
    return {
      ...SUPPLEMENT_VIEW_ALL,
    };
  }

  if (action.type === permReduceTypes.supplement_edit_all) {
    const newState = supplementReduce(previousState, "write");

    const SUPPLEMENT_EDIT_ALL = set(stateCopy, `${action.name}`, {
      ...newState,
    });
    return {
      ...SUPPLEMENT_EDIT_ALL,
    };
  }

  if (action.type === permReduceTypes.read) {
    const READ = set(stateCopy, `${action.name}`, {
      ...previousState,
      read: action.value,
    });

    return {
      ...READ,
    };
  }
  if (action.type === permReduceTypes.write) {
    const WRITE = set(stateCopy, `${action.name}`, {
      ...previousState,
      write: action.value,
      read: true,
    });

    return {
      ...WRITE,
    };
  }

  if (action.type === permReduceTypes.view_all) {
    let setTo = null;
    const newState = Object.keys(previousState).reduce((prev, curr) => {
      if (previousState[curr]["write"] === true) {
        setTo = true;
      } else {
        setTo = isAllRead ? false : true;
      }
      return {
        ...prev,
        [curr]: {
          ...previousState[curr],
          read: setTo,
        },
      };
    }, {});
    const VIEW_ALL = set(stateCopy, `${action.name}`, {
      ...newState,
    });
    return {
      ...VIEW_ALL,
    };
  }
  if (action.type === permReduceTypes.edit_all) {
    let setToWrite =
      isAllRead && isAllWrite ? false : isAllWrite ? false : true;
    let setToRead = !isAllRead && isAllWrite ? false : true;
    if (!setToWrite && !setToRead) {
      setToWrite = true;
      setToRead = true;
    }

    const newState = Object.keys(previousState).reduce((prev, curr) => {
      return {
        ...prev,
        [curr]: {
          ...previousState[curr],
          write: setToWrite,
          read: setToRead,
        },
      };
    }, {});
    const EDIT_ALL = set(stateCopy, `${action.name}`, {
      ...newState,
    });
    return {
      ...EDIT_ALL,
    };
  }

  return state;
}
