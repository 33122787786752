import React from "react";
import { formats } from "components/Common/Tables/Common/helpers";

export const columns = [
  {
    title: "Institution Name",
    field: "bank_pd_business_entity.business_name",
  },
  {
    field: "name_held",
  },
  {
    field: "account_type",
    format: formats.startCase,
  },
  {
    field: "account_number",
  },
];

export const tableQuestions = (
  <>
    If you have access to any safety deposit boxes, vaults or other security
    storage in any jurisdiction please provide details.
  </>
);

export const defaultInitialValues = {
  held_by_applicant: null,
  bank_pd_business_entity_id: null,
  account_type: null,
  name_held: "",
  account_number: "",
  content: "",
};
