import IowaApplicantFamilyMembers from "components/Pages/Supplemental/PD/Iowa/IowaApplicantFamilyMembers/IowaApplicantFamilyMembers";
import IowaDisclosureQuestions from "components/Pages/Supplemental/PD/Iowa/IowaDisclosureQuestions/IowaDisclosureQuestions";
import IowaVehicles from "components/Pages/Supplemental/PD/Iowa/IowaVehicles/IowaVehicles";
import IowaDonations from "components/Pages/Supplemental/PD/Iowa/IowaDonations/IowaDonations";
import IowaAttorneys from "components/Pages/Supplemental/PD/Iowa/IowaAttorneys/IowaAttorneys";
import IowaAccountants from "components/Pages/Supplemental/PD/Iowa/IowaAccountants/IowaAccountants";
import IowaAttachments from "components/Pages/Supplemental/PD/Iowa/IowaAttachments/IowaAttachments";

const iowa_pages = {
  iowa_disclosure_questions: IowaDisclosureQuestions,
  iowa_applicant_family_members: IowaApplicantFamilyMembers,
  iowa_vehicles: IowaVehicles,
  iowa_attorneys: IowaAttorneys,
  iowa_donations: IowaDonations,
  iowa_accountants: IowaAccountants,
  iowa_attachments: IowaAttachments,
};

export default iowa_pages;
