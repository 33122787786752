import { getFormComponents } from "componentGenerators/helpers";
import maryland_pea_supplement_disclosure_questions from "components/Pages/Supplemental/PD/MarylandPEA/MarylandPEADisclosureQuestions/MarylandPEADisclosureQuestions";
import maryland_pea_supplement_attachments from "components/Pages/Supplemental/PD/MarylandPEA/MarylandPEAAttachments/MarylandPEAAttachments";
import maryland_pea_supplement_criminal_history from "components/Pages/Supplemental/PD/MarylandPEA/MarylandPEACriminalHistory/MarylandPEACriminalHistory";
import maryland_pea_supplement_referral_fees from "components/Pages/Supplemental/PD/MarylandPEA/MarylandPEAReferralFees/MarylandPEAReferralFees";

export default getFormComponents({
  maryland_pea_supplement_disclosure_questions,
  maryland_pea_supplement_attachments,
  maryland_pea_supplement_criminal_history,
  maryland_pea_supplement_referral_fees,
});
