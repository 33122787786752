export const defaultInitialValues = {
  colorado: {
    associated_gaming_licensee_name: "",
    employer_name: "",
    employer_phone_number: "",
    job_title: "",
    current_employer_name: "",
    current_employer_phone_number: "",
    current_job_title: "",
    has_other_ssn: null,
    used_other_ssn_explanation: "",
    has_colorado_gaming_license_or_is_associated_person: null,
    colorado_gaming_license_or_is_associated_person_explanation: "",
  },
};

export const endpoint = "colorado_rider_disclosure_questions_pdSupplement";
