import React from "react";

//LAYOUT SECTIONS
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider";
import useForm from "hooks/useForm.js";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import useEndPoint from "hooks/useEndpoint";

import { Grid } from "@material-ui/core";
import { SectionLabel } from "components/Common/LayoutComponents/index.js";
import MultiFormWrapper from "components/Layout/SectionsLayout/MultiFormWrapper.jsx";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";

const CompensationPlansForm = ({ innerRef }) => {
  const { bdBusinessEntityEndpoint } = useEndPoint("");
  const formData = useForm({
    defaultInitialValues: { compensation_plan_attachment_group_id: null },
    endpoint: bdBusinessEntityEndpoint,
    noValidate: true,
  });

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage
        title={"Compensation Plans"}
        innerRef={innerRef}
        showSaveButton={false}
      >
        <Grid container spacing={2}>
          <SectionLabel>
            <>
              Attach details of any bonus, profit sharing, pension, retirement,
              deferred compensation and similar plans in existence, or to be
              created by the Applicant. The attachment should include include
              but not be limited to:
              <ul>
                <li>Title of the plan</li>
                <li>
                  Identity and address of the trustee of the plan or the person
                  administering such plan
                </li>
                <li>Methods of financing the plan</li>
                <li>
                  Identity of each class of person who is or will participate in
                  the plan
                </li>
                <li>Approximate number of persons in each such class</li>
                <li>
                  Amount distributed under the plan to each class of persons
                  during the last fiscal year, if the plan was in effect during
                  that time period
                </li>
              </ul>
            </>
          </SectionLabel>

          <FormikFileDropzone
            description="Compensation Plans"
            name="compensation_plan_attachment_group_id"
            size={12}
          />
        </Grid>
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};
const GovernmentDebtForm = ({ innerRef }) => {
  const { bdBusinessEntityEndpoint } = useEndPoint("");
  const formData = useForm({
    defaultInitialValues: { government_debt_attachment_group_id: null },
    endpoint: bdBusinessEntityEndpoint,
    noValidate: true,
  });

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage
        title={"Government Debt or Equity Offering Submissions"}
        innerRef={innerRef}
        showSaveButton={false}
      >
        <Grid container spacing={2}>
          <SectionLabel>
            Attach any legally required reports submitted to government agencies
            in relation to any debt or equity offerings during the past 12
            months. You may exclude reports already provided.
          </SectionLabel>

          <FormikFileDropzone
            description="Government Debt or Equity"
            name="government_debt_attachment_group_id"
            size={12}
          />
        </Grid>
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

export default function FinancialDocuments({ name }) {
  return (
    <FormSectionWrapper title={name}>
      <MultiFormWrapper>
        <CompensationPlansForm />
        <GovernmentDebtForm />
      </MultiFormWrapper>
    </FormSectionWrapper>
  );
}
