import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import { Card, CardContent } from "@material-ui/core";
import {
  SectionSubHeader,
  Paragraph,
} from "components/Common/LayoutComponents";
import CustomLink from "components/Common/Link";

const NewJerseyAncillaryAttachments = ({ name }) => {
  return (
    <FormSectionWrapper title={name}>
      <Card variant="outlined">
        <CardContent>
          <SectionSubHeader label="Certificate of Naturalization" />
          <Paragraph>
            <>
              Attach a copy of your Certificate of Naturalization to the back of
              this form and label as Attachment 3. Upload your document in
              <CustomLink to="citizenship" />
            </>
          </Paragraph>
          <SectionSubHeader label="UCIS document" />
          <Paragraph>
            <>
              If you are not a United States citizen but you are a
              legally-authorized permanent resident alien, or you are authorized
              to be employed in the United States, attach to this form a copy of
              your UCIS document that conditions or restricts your employment
              labeled as Attachment 5. Upload your documents in
              <CustomLink to="citizenship" />
            </>
          </Paragraph>
          <SectionSubHeader label="military records" />
          <Paragraph>
            <>
              Attach a copy of your military records* labeled as Attachment 14.
              If unavailable, attach a copy of a letter to the appropriate
              branch of the military requesting a copy of your military records*
              labeled as an Attachment 14. If in reserves, please attach a copy
              of your discharge papers. Upload your documents in
              <CustomLink to="military" />
            </>
          </Paragraph>
          <SectionSubHeader label="IRS and State Form" />
          <Paragraph>
            <>
              Attach to the back of this form and label as Attachment 60, a copy
              of each IRS and State Form, with any amendments, and all
              appropriate schedules filed by you in the last three years. If you
              and your spouse filed separate tax returns for any year in the
              last five years, also attach a copy of your spouse’s tax returns.
              Upload your documents in
              <CustomLink to="us_taxes" />
            </>
          </Paragraph>
          <SectionSubHeader label="foreign tax return" />
          <Paragraph>
            <>
              Attach to the back of this form and label as Attachment 63, a copy
              of foreign tax return and all appropriate schedules or other
              attachments required by the tax authorities of the foreign
              jurisdictions. Upload your documents in
              <CustomLink to="non_us_taxes" />
            </>
          </Paragraph>
        </CardContent>
      </Card>
    </FormSectionWrapper>
  );
};

export default NewJerseyAncillaryAttachments;
