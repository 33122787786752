import React from "react";
import { address } from "helpers/initialValues";

export const columns = [
  {
    field: "full_name",
  },
  {
    field: "date_of_birth",
  },
  { field: "home_address", format: "address" },

  {
    field: "occupation",
  },
  {
    field: "spouse_pd_person",
    render: (record) => {
      return record?.["spouse_pd_person"]?.["full_name"];
    },
    title: "Spouse",
  },
];

export const tableQuestions = (
  <>
    List names, dates of birth, home addresses and phone numbers, and the most
    recent occupations of brothers and sisters and of their respective spouses.
  </>
);

export const nonRequired = [
  "spouse_pd_person_id",
  "suffix_name",
  "middle_name",
];

export const defaultInitialValues = {
  is_deceased: null,
  is_retired: null,
  first_name: "",
  middle_name: "",
  last_name: "",
  suffix_name: "",
  relative_type: "sibling",
  occupation: "",
  date_of_birth: null,
  place_of_birth: "",
  phone_number: "",
  home_address: address,
  spouse_pd_person_id: null,
};

export const endpoint = "siblings_sibling";
