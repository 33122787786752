import { getFormComponents } from "componentGenerators/helpers";
import bd_cherokee_vendor_license_supplement_application_information from "components/Pages/Supplemental/BD/CherokeeVendorLicense/CherokeeVendorLicenseApplicationInformation/CherokeeVendorLicenseApplicationInformation";
import bd_cherokee_vendor_license_supplement_business_description from "components/Pages/Supplemental/BD/CherokeeVendorLicense/CherokeeVendorLicenseBusinessDescription/CherokeeVendorLicenseBusinessDescription";
import bd_cherokee_vendor_license_supplement_records from "components/Pages/Supplemental/BD/CherokeeVendorLicense/CherokeeVendorLicenseRecords/CherokeeVendorLicenseRecords";
import bd_cherokee_vendor_license_supplement_criminal_history from "components/Pages/Supplemental/BD/CherokeeVendorLicense/CherokeeVendorLicenseCriminalHistory/CherokeeVendorLicenseCriminalHistory";
import bd_cherokee_vendor_license_supplement_litigations from "components/Pages/Supplemental/BD/CherokeeVendorLicense/CherokeeVendorLicenseLitigations/CherokeeVendorLicenseLitigations";

export default getFormComponents({
  bd_cherokee_vendor_license_supplement_application_information,
  bd_cherokee_vendor_license_supplement_business_description,
  bd_cherokee_vendor_license_supplement_records,
  bd_cherokee_vendor_license_supplement_criminal_history,
  bd_cherokee_vendor_license_supplement_litigations,
});
