import BD_PennEEAApplicantInformation from "components/Pages/Supplemental/BD/PennsylvaniaEEA/ApplicantInformation/PennEEAApplicantInformation";
import BD_PennEEAContactInformation from "components/Pages/Supplemental/BD/PennsylvaniaEEA/ContactInformation/PennEEAContactInformation";
import BD_PennEEAIdentificationNumbers from "components/Pages/Supplemental/BD/PennsylvaniaEEA/IdentificationNumbers/PennEEAIdentificationNumbers";
import BD_PennEEAFinancialLegal from "components/Pages/Supplemental/BD/PennsylvaniaEEA/FinancialLegal/PennEEAFinancialLegal";
import BD_PennEEAApplicationInformation from "components/Pages/Supplemental/BD/PennsylvaniaEEA/ApplicationInformation/PennEEAApplicationInformation";
import BD_PennEEAManufacturerApplicants from "components/Pages/Supplemental/BD/PennsylvaniaEEA/ManufacturerApplicants/PennEEAManufacturerApplicants";
import BD_PennEEAPRequiredAttachments from "components/Pages/Supplemental/BD/PennsylvaniaEEA/RequiredAttachments/PennEEARequiredAttachments";

const bd_penn_supplement_pages = {
  bd_penn_eea_supplement_applicant_information: BD_PennEEAApplicantInformation,
  bd_penn_eea_supplement_contact_information: BD_PennEEAContactInformation,
  bd_penn_eea_supplement_identification_numbers: BD_PennEEAIdentificationNumbers,
  bd_penn_eea_supplement_financial_legal: BD_PennEEAFinancialLegal,
  bd_penn_eea_supplement_application_information: BD_PennEEAApplicationInformation,
  bd_penn_eea_supplement_manufacturer_applicants: BD_PennEEAManufacturerApplicants,
  bd_penn_eea_supplement_required_attachments: BD_PennEEAPRequiredAttachments,
};

export default bd_penn_supplement_pages;
