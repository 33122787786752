import {
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { lighten } from "@material-ui/core/styles";
import ElevatedHeaderCard from "components/Common/cards/ElevatedHeaderCard";
import Center from "components/flex/Center";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { dayjsUTC } from "helpers/apiHelpers";
import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

dayjs.extend(relativeTime);

const ListBox = styled(Box)`
  max-height: 300px;
  overflow: auto;
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
`;

export const StyledListItem = styled(ListItem)`
  ${({ $isUrgent, theme }) =>
    $isUrgent &&
    css`
      background-color: ${lighten(theme.palette.error.main, 0.9)};
    `}
`;

export const ErrorChip = styled(Chip)`
  background-color: ${({ theme }) => theme.palette.error.light};
  color: ${({ theme }) => theme.palette.error.contrastText};
`;

export const WarningChip = styled(Chip)`
  background-color: ${({ theme }) => lighten(theme.palette.warning.main, 0.7)};
`;

export const PriorityChip = ({ expiration_date, urgency }) => {
  const expiryDateObj = dayjs(expiration_date);

  const isPast = expiryDateObj.isBefore(dayjs());

  const expiryString = (
    <Typography variant="subtitle2">
      <Box fontWeight="fontWeightMedium">{`${
        isPast ? "Expired" : "Expires"
      } ${expiryDateObj.fromNow()}`}</Box>
    </Typography>
  );

  if (urgency === "high")
    return <ErrorChip variant="outlined" label={expiryString} />;
  if (urgency === "medium")
    return <WarningChip variant="outlined" label={expiryString} />;
  return <Chip variant="outlined" label={expiryString} />;
};

/* 
Urgency Levels:
high: expired or expiring within 7 days
medium: expiring within 30 days
*/

const getExpirationUrgencyLevel = (expiration_date) => {
  const expiryDateObj = dayjs(expiration_date);
  const sevenDaysAhead = dayjs().add(7, "day");
  const thirtyDaysAhead = dayjs().add(30, "day");

  const isSevenDaysAhead = expiryDateObj.isBefore(sevenDaysAhead);
  if (isSevenDaysAhead) return "high";

  const isThirtyDaysAhead = expiryDateObj.isBefore(thirtyDaysAhead);
  if (isThirtyDaysAhead) return "medium";
};

export const ExpiringListItem = ({
  handleClick,
  expiringItem: {
    name,
    to_date,
    expiration_date,
    license_type,
    license_type_name,
  },
}) => {
  to_date = to_date || expiration_date;
  license_type = license_type || license_type_name;

  const urgency = getExpirationUrgencyLevel(expiration_date || to_date);

  return (
    <>
      <StyledListItem
        $isUrgent={urgency === "high"}
        button
        onClick={handleClick}
      >
        <ListItemText
          primary={name}
          secondary={
            <>
              <Typography
                component="span"
                variant="subtitle2"
                color="textPrimary"
              >
                {`${license_type || ""}`}
              </Typography>
              <Typography
                component="span"
                variant="body2"
                color="textSecondary"
                style={{ display: "block" }}
              >
                {`Expiry: ${dayjsUTC(
                  to_date || expiration_date,
                  "MMM DD, YYYY"
                )}`}
              </Typography>
            </>
          }
        />
        <PriorityChip
          expiration_date={to_date || expiration_date}
          urgency={urgency}
        />
      </StyledListItem>
      <Divider component="li" />
    </>
  );
};

ExpiringListItem.propTypes = {
  expiringItem: PropTypes.object,
  handleClick: PropTypes.func,
  isUrgent: PropTypes.bool,
};

const HeaderText = ({ children }) => (
  <Box fontWeight="600">
    <Typography>{children}</Typography>
  </Box>
);

const Header = ({ label = "" }) => (
  <Box display="flex" justifyContent="center" width={1}>
    <Box display="flex" alignItems="center">
      <HeaderText>{label}</HeaderText>
    </Box>
  </Box>
);

export const ExpiringSoon = ({
  handleClickRecord,
  licensesData,
  label,
  keyLabel,
}) => {
  return (
    <ElevatedHeaderCard subheader={<Header label={label} />}>
      {licensesData?.length === 0 && (
        <Center style={{ minHeight: "30vh" }}>
          <Typography>There are no expiring licenses</Typography>
        </Center>
      )}
      {licensesData?.length > 0 && (
        <List style={{ minHeight: "30vh" }}>
          <ListBox>
            {licensesData.map((expiringItem) => {
              let employeeID =
                expiringItem.lc_person_id || expiringItem.employee_id;
              let businessID =
                expiringItem.lc_business_id || expiringItem.bd_party_id;
              let partyID =
                expiringItem.license_type_category === "personal" ||
                expiringItem.owner_type === "personal"
                  ? employeeID
                  : businessID;

              return (
                <ExpiringListItem
                  handleClick={() => {
                    console.log("expiringItem", expiringItem);
                    handleClickRecord(
                      partyID,
                      expiringItem,
                      expiringItem.license_type_category
                    );
                  }}
                  key={`${keyLabel}-${expiringItem.id}-${expiringItem?.license_type}`}
                  expiringItem={expiringItem}
                />
              );
            })}
          </ListBox>
        </List>
      )}
    </ElevatedHeaderCard>
  );
};

ExpiringSoon.propTypes = {
  handleClickRecord: PropTypes.func,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  keyLabel: PropTypes.any,
  label: PropTypes.string,
  licensesData: PropTypes.array.isRequired,
};
