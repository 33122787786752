import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm";
import React from "react";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";

const Form = () => {
  return (
    <Grid container spacing={1}>
      <SectionSubHeader label="Organizational Chart of the Entity" />
      <SectionLabel label="Provide an organizational chart of the business entity with its relationship to existing parent, subsidiary or affiliated companies." />
      <Grid item>
        Please review your information and make sure a flow chart illustrating
        the fully diluted ownership of the applicant. List all parent,
        subsidiary or intermediary companies until the flowchart 100% of the
        stock, partnership, membership or ownership interest as being held by a
        natural person(s) and other legal persons. If the ultimate parent
        company is publicly traded and no natural person controls more than 5%
        of publicly traded stock, indicate that in a footnote to the flowchart.{" "}
      </Grid>
      <FormikFileDropzone
        description="Management Diagrams & Organizational Charts"
        name="management_diagram_attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};

export default function OrganizationalChartForm({ innerRef }) {
  const formData = useForm({
    defaultInitialValues: { management_diagram_attachment_group_id: null },
    endpoint: "bdBusinessEntity",
  });

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage showSaveButton={false} innerRef={innerRef}>
        <Form />
      </EnhancedFormikPage>
    </FormContextProvider>
  );
}
