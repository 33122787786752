import { Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents/index.js";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikNumberField from "components/Formik/fields/FormikNumberField.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import PDBusinessSelect from "components/Formik/formGroups/CrudSelect/selects/PDBusinessSelect.jsx";
import PDRelativeSelect from "components/Formik/formGroups/CrudSelect/selects/PDRelativeSelect.jsx";
import FormikMultiYearFileUploader from "components/Formik/formGroups/FormikMultiYearFileUploader";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import ColumnSum from "components/Layout/SectionsLayout/ColumnSum.jsx";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import {
  columns,
  dataHookProps,
  modalTitle,
  tableQuestions,
} from "./config.js";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <PDRelativeSelect
        label="Spouse or Child (If Applicable)"
        header="Select if held by Spouse or Dependent Child"
      />
      <SectionSubHeader label="Institution Details" divider />
      <PDBusinessSelect
        name="institution_pd_business_entity_id"
        label="Institution/Employer"
      />

      <FormikTextField
        header="Account Details"
        divider
        label="Fund/Account Type"
        name="fund_type"
        xs={4}
      />
      <FormikTextField
        label="Name of Broker"
        name="broker_person_name"
        xs={4}
      />
      <FormikTextField label="Broker Phone" name="broker_phone" xs={4} />
      <FormikTextField
        label="Type of Securities Held"
        name="type_of_securities_held"
        xs={4}
      />
      <FormikNumberField
        label="Employer Contribution"
        name="employer_contribution"
        helperText="Cumulative"
      />
      <FormikNumberField
        label="Employee Contribution"
        name="employee_contribution"
        helperText="Cumulative"
        xs={4}
      />
      <FormikTextField label="Account Number" name="account_number" xs={4} />

      <FormikNumberField label="Current Cash Value" name="cash_value" xs={4} />
      <FormikDatePicker label="Cash Value Date" name="valuation_date" xs={4} />

      <FormikMultiYearFileUploader
        title="Fund Statements"
        name="pension_funds_attachment_group_id"
      />
    </Grid>
  );
};

const PensionFunds = ({ name }) => {
  const formData = useTabularData(dataHookProps);

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={modalTitle}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        footer={
          <ColumnSum
            name="cash_value"
            data={formData.data}
            label="Total Cash Value"
          />
        }
        columns={columns}
        addButtonText="Add Record"
      />
    </FormSectionProvider>
  );
};

export default PensionFunds;
