import { fieldTypes } from "componentGenerators/formGenerator/config";
import React from "react";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Identification",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please upload a copy of your passport in the{" "}
        <strong>
          <CustomLink to="passport" />
        </strong>
        section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please upload a copy of the front and back of your driver&apos;s license
        in the{" "}
        <strong>
          <CustomLink to="vehicle_licenses" />
        </strong>
        section, or attach a copy of the front and back of any government issued
        identification to the back of the application.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please upload any naturalization documentation in the{" "}
        <strong>
          <CustomLink to="citizenship" />
        </strong>
        section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Ensure you have completed the proper fingerprinting documentation and
        attach them to the back of the application.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Tax information",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please attach all Federal Tax Return Documentation from the last year in
        the{" "}
        <strong>
          <CustomLink to="us_taxes" />
        </strong>
        section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Financial Statements",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please attach a copy of all bank account and brokerage account
        statements for the last year in the{" "}
        <strong>
          <CustomLink to="bank_accounts" />
        </strong>
        and
        <strong>
          <CustomLink to="margin_accounts" />
        </strong>
        sections.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please attach all credit card statements for the last year in the{" "}
        <strong>
          <CustomLink to="loans_and_other_payables" />
        </strong>
        section.
      </>
    ),
  },
];

export default {
  type: "readOnlyPage",
  formSchema,
  endpoint: "tennessee_phdrf_supplement_attachments_pdSupplement",
};
