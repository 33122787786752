import { address } from "components/Formik/formGroups/FormikAddressFields";

export const defaultInitialValues = {
  michigan_contact_address: address,
  michigan: {
    contact_name: "",
    contact_title: "",
    contact_phone_number: "",
    contact_email: "",
  },
};
