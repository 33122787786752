import React from "react";
import { commonStrings } from "../../uiStrings";

export const columns = [
  {
    title: "Loan Date",
    field: "incurred_date",
    format: "date",
  },
  {
    title: "Category",
    field: "type",
    format: "startCase",
  },
  { title: "Lender", field: "creditor_pd_party_name" },
  {
    field: "original_amount",
    format: "currency",
  },
  {
    field: "termination_date",
    format: "date",
  },
  {
    title: "Active",
    field: "is_current",
    format: "boolean",
    sortType: "boolean",
  },
];

export const tableQuestions = (
  <>
    During the last ten (10) year period, have {commonStrings.youSpouseChildren}{" "}
    received a loan in excess of $25,000USD? Note: If you are applying in a
    jurisdiction other than the United States, the amount you are required to
    report is the equivalent of $25,000USD in the national currency of the
    jurisdiction where you will be filing this application.
  </>
);

export const defaultInitialValues = {
  type: "",
  relative_pd_person_id: null,
  creditor_pd_party_id: null,
  cosigner_names: "",
  original_amount: null,
  interest_rate: null,
  incurred_date: null,
  termination_date: null,
  is_current: false,
};
