import { useField } from "formik";
import useDeleteDialog from "hooks/useDeleteDialog";
import { v4 as uuidv4 } from "uuid";
import React from "react";

const removeRecordById = (records, selectedId) =>
  records.filter((record) => record.tempMeta.id !== selectedId);

// Adding tempMeta which is an object containing record data that does not get sent to the server.
const addTempRecordData = (values, newFieldAdded) => {
  //This is to handle autofocussing when the user adds a new field
  const checkIsNewlyAdded = (index) => {
    if (index === values.length - 1 && newFieldAdded) {
      return true;
    }
    return false;
  };

  return values.map((record, idx) => ({
    ...record,
    tempMeta: { id: uuidv4(), isNew: checkIsNewlyAdded(idx) },
  }));
};

const removeTempRecordData = (values) =>
  values.map((record) => {
    const { tempMeta: _tempMeta, ...rest } = record;
    return rest;
  });

// ! In order for this hook to work in pd and bd. Will likely have to add some additional logic that appends the bd_organization_id/employee_id like in the FormikInlineTable
const useMultiInlineFields = ({ name, attributeName }) => {
  const [{ value }, _meta, { setValue }] = useField({ name });

  const [records, setRecords] = React.useState([]);

  const [newFieldAdded, setNewFieldAdded] = React.useState(false);

  React.useEffect(() => {
    setRecords(addTempRecordData(value, newFieldAdded));
  }, [value, newFieldAdded]);

  const confirmDelete = (id) => {
    setValue(removeTempRecordData(removeRecordById(records, id)));
  };

  const { reset, openDeleteDialog, ...deleteDialogProps } = useDeleteDialog({
    handleConfirm: (selectedId) => {
      confirmDelete(selectedId);
      reset();
    },
  });

  const editRecord = (newValue, tempId) => {
    const editedRecords = records.map((record) => {
      if (tempId === record.tempMeta.id)
        return { ...record, [attributeName]: newValue };
      return record;
    });
    setValue(removeTempRecordData(editedRecords));
  };

  const handleInputBlur = (value, tempId) => {
    editRecord(value, tempId);
    setNewFieldAdded(false);
  };

  const handleAddRecord = () => {
    setValue([...removeTempRecordData(records), { [attributeName]: "" }]);
    setNewFieldAdded(true);
  };

  return {
    records,
    handleInputBlur,
    handleAddRecord,
    handleDeleteRecord: openDeleteDialog,
    deleteDialogProps,
  };
};

export default useMultiInlineFields;
