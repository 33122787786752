import Tooltip from "components/Common/Tooltip";
import React from "react";

export const LicenseDatesTooltip = () => (
  <Tooltip
    title={
      <>
        <p>
          <strong>Application date:</strong> The date the jurisdiction received
          the application.
        </p>
        <p>
          <strong>From date:</strong> The date the licensed was issued, often
          referred to as the disposition date.
        </p>
        <p>
          <strong>Expiration/To date:</strong> The date the license was last held or the
          date it will expire.
        </p>
      </>
    }
  />
);
