import { AsyncSearchDropDown } from "components/Common/AsyncSearchDropdown";
import ConfirmationDialog from "components/Common/Dialogs/ConfirmationDialog";
import React from "react";
import { useAuth } from "context/auth";

export const GenerateApplicationDialog = ({
  onApplicationChange,
  onJurisdictionChange,
  jurisdiction,
  isLoading,
  on,
  onSubmit,
  setOff,
  applicationEndpoint,
}) => {
  const { account_type } = useAuth();

  return (
    <ConfirmationDialog
      title="Generate New Application"
      confirmationButtonProps={{ variant: "contained", color: "primary" }}
      confirmationText="Generate"
      open={on}
      onCancel={setOff}
      onConfirm={onSubmit}
      loading={isLoading}
    >
      <AsyncSearchDropDown
        disableClearable
        label="1. Select Jurisdiction"
        size={8}
        endpoint="jurisdiction"
        nosubmit
        noEmployee
        params={{ is_business: account_type === "business" ? true : null }}
        optionLabel="{name}"
        onChange={onJurisdictionChange}
      />
      <AsyncSearchDropDown
        label="2. Select Application"
        disableClearable
        size={8}
        endpoint={applicationEndpoint}
        nosubmit
        noEmployee
        optionLabel="{name}"
        relatedFieldName="id"
        disabled={jurisdiction ? false : true}
        onChange={onApplicationChange}
      />
    </ConfirmationDialog>
  );
};
