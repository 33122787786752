import React from "react";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Taxes",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload copies of federal income tax returns for the past three (3) years
        in the <CustomLink to="us_taxes" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Military Service",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Complete a DD214 form, if applicable and attach it to your application.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Citizenship",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload a certified copy of birth certificate in the
        <CustomLink to="citizenship" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        If you are not a US Citizen, please provide all your USCIS documentation
        in the <CustomLink to="citizenship" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Education",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload copies of diplomas for all higher education degrees, if
        applicable in the <CustomLink to="education" />
        section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Marriages",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload copies of divorces decree(s), if applicable in the
        <CustomLink to="marriages" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Financial Statements",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload copies of 12 months of bank statements and six month of credit
        card statements in the
        <CustomLink to="bank_accounts" /> &
        <CustomLink to="loans_and_other_payables" />
        sections.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Criminal Charges",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        If you were arrested or charge for an offense, please obtain and upload
        in the <CustomLink to="arrests" /> section the official documentation
        from the court where you appeared, showing the final disposition
        (outcome) of your case. This information will include whether you were
        found guilty or not guilty; and the penalty (money fine, time in jail or
        prison, or probation or deferred sentence). If you received a deferred
        judgment, a deferred sentence, or probation, your documentation must
        include the date that you were discharged or released from probation or
        other supervision.
      </>
    ),
  },
];

export default {
  type: "readOnlyPage",
  formSchema,
};
