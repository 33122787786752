import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import { Card, CardContent, Link } from "@material-ui/core";
import {
  SectionSubHeader,
  Paragraph,
} from "components/Common/LayoutComponents";
import { Link as RouterLink } from "react-router-dom";

const IowaAttachments = ({ name }) => {
  return (
    <FormSectionWrapper title={name}>
      <Card variant="outlined">
        <CardContent>
          <SectionSubHeader label="Passport" />
          <Paragraph>
            <>
              Attach a copy of every page of your current and valid
              Passport/Visa in the{" "}
              <Link
                to="/app/passport"
                component={RouterLink}
                underline="always"
              >
                Passport
              </Link>{" "}
              section or print from your records.
            </>
          </Paragraph>
          <SectionSubHeader label="Citizenship" divider />
          <Paragraph>
            <>
              Attach a copy of your Certificate of Naturalization or a current
              identification card issued by the Bureau of Immigration and
              Customs Enforcement if you were not born in the United States & a
              certified copy of your birth certificate issued by a governmental
              authority with an official seal in the{" "}
              <Link
                component={RouterLink}
                underline="always"
                to="/app/citizenship"
              >
                Citizenship
              </Link>{" "}
              section or print from your records.
            </>
          </Paragraph>
          <SectionSubHeader label="Driver License" divider />
          <Paragraph>
            <>
              Attach a copy of your current government-issued driver’s license
              containing a photograph in the{" "}
              <Link
                to="/app/vehicle_licenses"
                component={RouterLink}
                underline="always"
              >
                Vehicle Licenses
              </Link>{" "}
              section or print from your records.
            </>
          </Paragraph>
          <SectionSubHeader label="Education" divider />
          <Paragraph>
            <>
              Attach a certified copy of your college transcripts for each
              college in the{" "}
              <Link
                to="/app/education"
                component={RouterLink}
                underline="always"
              >
                Education
              </Link>{" "}
              section or print from your records.
            </>
          </Paragraph>
          <SectionSubHeader label="Employment" divider />
          <Paragraph>
            <>
              Attach a copy of your current employment contract or agreement,
              including bonus structure or stock options for your present
              position for which you are submitting this gaming application in
              the{" "}
              <Link
                to="/app/employment_history"
                component={RouterLink}
                underline="always"
              >
                Employment
              </Link>{" "}
              section or print from your records.
            </>
          </Paragraph>
          <SectionSubHeader label="Bank Statements" divider />
          <Paragraph>
            <>
              Attach a copy of the previous 12 months account statements for
              each financial institution in the{" "}
              <Link
                to="/app/bank_accounts"
                component={RouterLink}
                underline="always"
              >
                Bank Accounts
              </Link>{" "}
              section or print from your records.
            </>
          </Paragraph>
          <SectionSubHeader label="Securities" divider />
          <Paragraph>
            <>
              Attach a copy of the previous 12 months account statements for
              each security in the{" "}
              <Link
                to="/app/securities"
                component={RouterLink}
                underline="always"
              >
                Securities
              </Link>{" "}
              section or print from your records.
            </>
          </Paragraph>
          <SectionSubHeader label="Real Estate" divider />
          <Paragraph>
            <>
              Attach a copy of purchase and sale documents relating to your real
              estate information in the{" "}
              <Link
                to="/app/real_estate_interests"
                component={RouterLink}
                underline="always"
              >
                Real Estate
              </Link>{" "}
              section or print from your records.
            </>
          </Paragraph>
          <SectionSubHeader label="Life Insurance" divider />
          <Paragraph>
            <>
              Attach a copy of supporting documentation for each life insurance
              policy and its corresponding cash value in the{" "}
              <Link
                to="/app/life_insurance"
                component={RouterLink}
                underline="always"
              >
                Life Insurance
              </Link>{" "}
              section or print from your records.
            </>
          </Paragraph>
          <SectionSubHeader label="Pension/Retirement Funds" divider />
          <Paragraph>
            <>
              Attach a copy of the previous 12 months account statements for
              each pension/retirement fund in the{" "}
              <Link
                to="/app/pension_funds"
                component={RouterLink}
                underline="always"
              >
                Pension/Retirement Funds
              </Link>{" "}
              section or print from your records.
            </>
          </Paragraph>
          <SectionSubHeader label="Loans" divider />
          <Paragraph>
            <>
              Attach a copy of the loan documents or satisfactions therein
              relating to your notes payable information for the previous 24
              months in the{" "}
              <Link
                to="/app/loans_and_other_payables"
                component={RouterLink}
                underline="always"
              >
                Loans & Other Payables
              </Link>{" "}
              section or print from your records.
            </>
          </Paragraph>
          <SectionSubHeader label="Taxes" divider />
          <Paragraph>
            <>
              Attach a copy of your federal and your state of residency (if
              applicable) tax returns for the previous three (3) years,
              including all schedules in the{" "}
              <Link
                to="/app/us_taxes"
                component={RouterLink}
                underline="always"
              >
                Taxes
              </Link>{" "}
              section or print from your records.
            </>
          </Paragraph>

          <SectionSubHeader label="Mortgages" divider />
          <Paragraph>
            <>
              Attach a copy of the mortgage loan documents or satisfaction
              therein relating to your information for the previous 24 months in
              the{" "}
              <Link
                to="/app/real_estate_loans"
                component={RouterLink}
                underline="always"
              >
                Real Estate Loans/Liens
              </Link>{" "}
              section or print from your records.
            </>
          </Paragraph>
        </CardContent>
      </Card>
    </FormSectionWrapper>
  );
};

export default IowaAttachments;
