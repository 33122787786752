import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.label,
    label: "Please answer the following:",
  },
  {
    type: fieldTypes.yesNo,
    label: "Have you ever been fingerprinted by any authority for any reason?",
    name: "cherokee_niva.is_criminal_history_q2",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Have you ever had a deferred sentence for a criminal charge filed against you?",
    name: "cherokee_niva.is_criminal_history_q6",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Have you ever had a suspended sentence for a criminal charge filed against you?",
    name: "cherokee_niva.is_criminal_history_q7",
  },
  {
    type: fieldTypes.yesNo,
    label: `Have you ever been arrested, detained, charged, indicted, or summoned to answer for any gambling 
            related offense, fraud, or misrepresentation regardless of the disposition of the event or even 
            if charges were not filed?`,
    name: "cherokee_niva.is_criminal_history_q10",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Have you ever been or are you currently on parole, probation, deferred or suspended sentence or supervised release?",
    name: "cherokee_niva.is_criminal_history_q12",
  },
  {
    type: fieldTypes.yesNo,
    label: `Did an attorney or Court tell you that if anyone ever asked you if you have been convicted of a crime that you should tell them 
          “no” because the sentence was deferred and the charges would either be dismissed or expunged?`,
    name: "cherokee_niva.is_criminal_history_q13",
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "cherokee_niva_supplement_criminal_history_pdSupplement",
};
