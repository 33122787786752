import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Business Partner",
  },
  {
    type: fieldTypes.label,
    label:
      "List the casino, tribe or platform provider the applicant intends to conduct or is currently conducting business with:",
  },
  {
    type: fieldTypes.bdBusinessSelect,
    label: "Business Partner",
    name: "michigan_supplier_license.business_partner_id",
  },
  {
    type: fieldTypes.label,
    label:
      "Estimate the annual dollar amount of goods and services to be provided to the casino, tribe or platform provider:",
  },
  {
    type: fieldTypes.number,
    label: "Estimated Amount",
    name: "michigan_supplier_license.goods_services_dollar_amount",
    xs: 4,
  },
  {
    type: fieldTypes.subHeader,
    label: "Contingencies & Conditions",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Are there any agreement contingencies or conditions between the Casino, Tribe or Platform Provider and applicant, applicant beneficial owners, suppliers, vendors or subcontractors which have not been disclosed?",
    name: "michigan_supplier_license.is_non_disclosed_agreement_conditions",
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label: "If yes, provide details below:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "michigan_supplier_license.non_disclosed_agreement_conditions_details",
    xs: 12,
    multiline: true,
    rows: 4,
  },
  {
    type: fieldTypes.subHeader,
    label: "Initial Applicant Only",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label:
      "Initial supplier applications must include a statement of intent to enter into an agreement, terms of unwritten agreement, or written agreement with a Casino, Tribe or Platform Provider, specifying the type of good/service to be supplied. The Board will not process initial applications without this statement or agreement.",
  },
  {
    type: fieldTypes.label,
    label: "Indicate from below which item will be attached:",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "michigan_supplier_license.services_type_checkbox",
    dynamicMenuItems: ["michiganSupplierLicenseServicesTypeCheckbox"],
    defaultValue: null,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "bdSupplement",
};
