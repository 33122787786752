import CustomLink from "components/Common/Link";
import React from "react";

export const defaultInitialValues = {
  iowa_class_d: {
    is_anticipate_lawsuit_party: null,
    is_arson_suspected: null,
  },
};

export const litigationTableQuestion =
  "List all lawsuits, civil and criminal, involving the business entity, parent company, subsidiary, or affiliated companies for the previous 10 years. Provide complaint and disposition for each item listed.";

export const criminalTableQuestion = (
  <>
    Has the business entity, affiliated companies, officers, directors, or
    principal employees ever been named as an unindicted party or co-conspirator
    in any criminal proceeding in Iowa or any other jurisdiction, whether in the
    United States or outside of the United States?
    <strong>
      {" "}
      If yes, attach supporting documentation to associated record in
      <CustomLink to="bd_criminal_proceedings" />
      detailing the date of investigation, governmental agency, nature of
      investigation and disposition of investigation.
    </strong>
  </>
);

export const insuranceTableQuestion = (
  <>
    Has the business entity sustained a loss where a significant insurance
    payment (ten thousand or more) was received?
    <strong>
      {" "}
      If yes, attach supporting documentation to associated record in
      <CustomLink to="bd_insurance_claims" /> section, detailing date of
      incident, nature of incident, disposition of incident, and name and
      address of insurance company making settlement.
    </strong>
  </>
);

export const investigationTableQuestion = (
  <>
    Has the business entity, affiliated companies, officers or directors ever
    been the subject of an investigation conducted by a governmental
    investigatory and/or regulatory agency for any reason?
    <strong>
      {" "}
      If yes, attach supporting documentation to associated record in
      <CustomLink to="bd_testimonies" />
      section, detailing the date of the investigation, governmental agency,
      nature of investigation and disposition of investigation.
    </strong>
  </>
);

export const testimonyTableQuestion = (
  <>
    Has the business entity ever been summoned, subpoenaed, requested or
    otherwise required to testify before any municipal, county, provincial,
    state, federal or national court, agency, committee, grand jury or
    investigatory or regulatory body, whether in the United States or outside of
    the United States, other than in response to a traffic summons?
    <strong>
      {" "}
      If yes, provide supporting documentation to associated record in
      <CustomLink to="bd_testimonies" label="Testimonies & Investigations" />
      section, detailing date, name and address of the court or agency involved,
      nature of the proceedings, and if testimony was given.
    </strong>
  </>
);

export const litigationColumns = [
  { title: "Date", field: "disposition_date", format: "date" },
  { title: "Court Name", field: "law_enforcement_name" },
  { title: "Court Address", field: "law_enforcement_full_address" },
  { title: "Docket Number", field: "docket_number" },
  { title: "Other Parties to Suit", field: "related_party_name" },
];

export const criminalColumns = [
  { title: "Date", field: "disposition_date", format: "date" },
  { title: "Court Name", field: "law_enforcement_name" },
  { title: "Court Address", field: "law_enforcement_full_address" },
  { title: "Docket Number", field: "docket_number" },
  { title: "Party Involved", field: "related_party_name" },
  { title: "Disposition", field: "criminal_disposition" },
];

export const insuranceColumns = [
  {
    title: "Business Entity",
    field: "bd_business_entity.business_name",
  },
  {
    field: "claim_date",
    format: "date",
  },
  {
    field: "nature_of_claim",
    format: "startCase",
  },
  {
    title: "Carrier Name",
    field: "carrier_bd_business_entity.business_name",
  },
  {
    title: "USD amount",
    field: "amount_usd",
    format: "currency",
  },
];

export const investigationColumns = [
  { title: "Date", field: "testimony_date", format: "date" },
  { title: "Court Name", field: "law_enforcement_name" },
  { title: "Court Address", field: "law_enforcement_full_address" },
  { title: "Other Parties to Suit", field: "related_party_name" },
];
export const testimonyColumns = [
  { title: "Date", field: "testimony_date", format: "date" },
  { title: "Court Name", field: "law_enforcement_name" },
  { title: "Court Address", field: "law_enforcement_full_address" },
  { title: "Other Parties to Suit", field: "related_party_name" },
];

export const litigationEndpoint =
  "views/bd_legal_histories?type=litigation&disposition_date__past_years=10";
export const criminalEndpoint =
  "views/bd_legal_histories?type=criminal&offense_type=unindicted_co_conspirator";
export const insuranceEndpoint = "bdInsuranceClaim?amount_usd__gte=10000";
export const investigationEndpoint =
  "views/bd_legal_histories?type=investigation";
export const testimonyEndpoint =
  "views/bd_legal_histories?type=testimony&has_disclosure=true";
