import React from "react";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "certificate of authority",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Provide certificate of authority from the Colorado secretary of state’s
        office. Upload documents in
        <CustomLink to="bd_application_details" />
        tab.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Corporate Documents",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Copy of all articles of incorporation, bylaws, articles of organization,
        or a true copy of any partnership or trust agreement, including any and
        all amendments to such. Upload documents in
        <CustomLink to="bd_corporate_documents" />
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Financial Documents",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        If a corporation, attach copies of all annual and bi-annual reports and
        SEC filings, if any, for past 3 years, and all minutes from all
        corporate meetings in the past 12 months. Upload documents in
        <CustomLink to="bd_financial_documents" />
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Uniform Commercial Code Report",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach current copy of any Uniform Commercial Code Report for all states
        where known to be filed. Upload documents in
        <CustomLink to="bd_financial_documents" />
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "gaming building documents",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach 8-1/2&quot;x11&quot; drawing to scale of the building and each
        floor in which gaming and/or sports betting will be conducted, including
        the locations of all self-service sports betting devices. Sports Betting
        areas should be outlined in red. Also attach a copy of your lease,
        rental agreement or other proof of legal possession of the premises.
      </>
    ),
  },
  {
    type: fieldTypes.dropZone,
    name: "colorado_sbba.co_sbba_gaming_building_info_attachment_group_id",
    description: "Gaming Building Info",
    label: "Upload Attachment",
    xs: 12,
  },
];

export default [
  {
    type: "form",
    formSchema,
    endpoint: "bdSupplement",
  },
];
