import { Card } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
// import { GettingStartedSection } from "./GettingStartedSection";
import { NewFeaturesSection } from "./NewFeaturesSection";

const StyledCard = styled(Card)`
  height: 100%;
`;

export const HeaderCard = () => (
  <StyledCard variant="outlined">
    {/* Uncomment this to show the "Welcome" / "Getting Started" component */}
    {/* <GettingStartedSection /> */}

    <NewFeaturesSection />
  </StyledCard>
);
