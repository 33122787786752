import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Financial Questions",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Are you delinquent in the filing of any tax return with any taxing agency anywhere?",
    name: "colorado_gsbsla.is_delinquent_tax_filing",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Are you delinquent in the payment of any taxes, interest, or penalties due to any taxing agency anywhere?",
    name: "colorado_gsbsla.is_delinquent_tax_payment",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Are you delinquent in the payment of any obligations to any governmental agency anywhere?",
    name: "colorado_gsbsla.is_delinquent_gvt_obligation",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Are you delinquent in the repayment of any government-insured student loans?",
    name: "colorado_gsbsla.is_delinquent_gvt_repayment",
  },
  {
    type: fieldTypes.yesNo,
    label: "Are you delinquent in the payment of any child support?",
    name: "colorado_gsbsla.is_delinquent_child_support",
  },
  {
    type: fieldTypes.label,
    label:
      "If you answered YES to any of the questions above, please provide details below:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "colorado_gsbsla.financial_questions_details",
    xs: 12,
    rows: 4,
  },
  {
    type: fieldTypes.subHeader,
    label: "Tax Information",
  },
  {
    type: fieldTypes.label,
    label:
      "Please list below all persons you have filed a joint tax return with in the past five years:",
  },
  {
    type: fieldTypes.text,
    label: "Name(s)",
    name: "colorado_gsbsla.filed_tax_return_persons",
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "colorado_gsbsla_supplement_financial_information_pdSupplement",
};
