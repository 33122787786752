import { useAuth } from "context/auth";
import React from "react";

export const useDropzoneEndpoints = () => {
  const { employee_id, bd_organization_id, account_type } = useAuth();

  const getAttachmentEndpoints = React.useCallback(
    (attachment_group_id) => {
      if (account_type === "license_control") {
        return {
          get: `/licensing/attachmentGroup/${attachment_group_id}/attachment/`,
          mutate: `/licensing/attachmentGroup/${attachment_group_id}/attachment/`,
          uploadUrlMutate: `/licensing/attachmentGroup/${attachment_group_id}/attachment/createSignedUrl`,
        };
      } else if (employee_id) {
        return {
          get: `/employee/${employee_id}/attachmentGroup/${attachment_group_id}/attachment/`,
          mutate: `/employee/${employee_id}/attachmentGroup/${attachment_group_id}/attachment/`,
          uploadUrlMutate: `/employee/${employee_id}/attachmentGroup/${attachment_group_id}/attachment/createSignedUrl`,
        };
      } else if (bd_organization_id) {
        return {
          get: `/bdOrganization/${bd_organization_id}/bdAttachmentGroup/${attachment_group_id}/bdAttachment/`,
          mutate: `/bdOrganization/${bd_organization_id}/bdAttachmentGroup/${attachment_group_id}/bdAttachment/`,
          uploadUrlMutate: `/bdOrganization/${bd_organization_id}/bdAttachmentGroup/${attachment_group_id}/bdAttachment/createSignedUrl`,
        };
      }
      return null;
    },
    [account_type, bd_organization_id, employee_id]
  );

  const attachmentGroupEndpoint = React.useMemo(() => {
    if (account_type === "license_control") {
      return {
        get: `/licensing/attachmentGroup/`,
        mutate: `/licensing/attachmentGroup/`,
      };
    } else if (employee_id) {
      return {
        get: `/employee/${employee_id}/attachmentGroup/`,
        mutate: `/employee/${employee_id}/attachmentGroup/`,
      };
    } else if (bd_organization_id) {
      return {
        get: `/bdOrganization/${bd_organization_id}/bdAttachmentGroup/`,
        mutate: `/bdOrganization/${bd_organization_id}/bdAttachmentGroup/`,
      };
    }
    return null;
  }, [account_type, bd_organization_id, employee_id]);

  return {
    getAttachmentEndpoints,
    attachmentGroupEndpoint,
  };
};
