import {
  fieldTypes,
  pageTypes,
} from "componentGenerators/formGenerator/config";
import React from "react";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Identification Document",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload a copy of one of the following: U.S. Birth Certificate, U.S.
        Passport, Naturalization Certificate, or Alien Registration Card in the
        <CustomLink to="citizenship" /> section or
        <CustomLink to="passport" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Secondary Identification Document",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload a copy of a secondary picture identification (driver&apos;s
        license, state ID, military ID, or passport) in the
        <CustomLink to="citizenship" /> section or
        <CustomLink to="vehicle_licenses" /> section.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "U.S. Military Service Record",
  },
  {
    type: fieldTypes.label,
    label:
      "Attach to the back of your application your U.S. Military Service Record (DD-214), when applicable.",
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "IRS Form",
  },
  {
    type: fieldTypes.label,
    label:
      "Attach to the back of your application a completed IRS Form 4506-C to include a request of account transcripts for the past four tax filing periods.",
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Liability Documents",
  },
  {
    type: fieldTypes.label,
    label:
      "Attach to the back of your application all supporting documents for liability.",
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Disclosed Liabilities",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach to the back of your application all supporting documentation for
        any liabilities disclosed per the Application. These may include, but
        are not limited to, contingent liabilities, personal guarantees, and
        pending tax balances.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Fingerprints Cards",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach to the back of your application two (2) completed FBI fingerprint
        cards and a completed Livescan Form. Hard cards are not required if you
        schedule an appointment with the MGCB.
      </>
    ),
  },
];

export default {
  type: pageTypes.readOnlyPage,
  formSchema,
};
