import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";

import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikNumberField from "components/Formik/fields/FormikNumberField.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import {
  columns,
  dataHookProps,
  modalTitle,
  tableQuestions,
} from "./config.js";
import { NumberFormatTypes } from "components/Common/Inputs/NumberField";
import PDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/PDPersonSelect.jsx";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <PDPersonSelect name="candidate_pd_person_id" label="Select Candidate" />
      <FormikDatePicker label="Donation Date" name="donation_date" xs={4} />
      <FormikTextField label="Position" name="candidate_position" xs={4} />
      <FormikNumberField
        label="Amount"
        name="donation_amount"
        xs={6}
        type={NumberFormatTypes.dollar}
      />
    </Grid>
  );
};

const IowaDonations = ({ name }) => {
  const formData = useTabularData(dataHookProps);

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={modalTitle}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
    </FormSectionProvider>
  );
};

export default IowaDonations;
