import PropTypes from "prop-types";
import {
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  Paper,
  Typography,
  Box,
  CircularProgress,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useToggle from "hooks/useToggle";
import React from "react";
import { ExpandButton } from "../ManageApplications.styles";
import { AttachmentLink } from "../components/AttachmentLink";
import CustomLink from "components/Common/Link";
import LaunchIcon from "@material-ui/icons/Launch";

const AttachmentCategory = ({ label, documents, link }) => {
  const { on, toggle } = useToggle();

  const attachmentCount = documents.length;
  return (
    <Paper variant="outlined">
      <CardHeader
        style={{ padding: "5px 15px", height: "60px" }}
        title={
          <Box display="flex" alignItems="center">
            <Typography
              variant="subtitle2"
              color="secondary"
              style={{ paddingRight: "10px" }}
            >
              <Box color="text.secondary">{label}</Box>
            </Typography>
            <Typography variant="subtitle1">
              <Box color="text.secondary">
                (
                {`${attachmentCount} Attachment${
                  attachmentCount === 1 ? "" : "s"
                }`}
                )
              </Box>
            </Typography>
            <CustomLink
              to={link}
              label={
                <Box pl={1} display="flex" alignItems="center" height={"100%"}>
                  <LaunchIcon fontSize="small" color="action" />
                </Box>
              }
            />
            {attachmentCount > 0 && (
              <ExpandButton onClick={toggle} open={on}>
                <ExpandMoreIcon />
              </ExpandButton>
            )}
          </Box>
        }
      />
      <Collapse in={on} timeout="auto" unmountOnExit>
        <CardContent style={{ paddingTop: "0px" }}>
          {documents.map((document) => (
            <AttachmentLink key={document.id} {...document} dense />
          ))}
        </CardContent>
      </Collapse>
    </Paper>
  );
};

AttachmentCategory.propTypes = {
  documents: PropTypes.array,
  label: PropTypes.string,
  link: PropTypes.string,
};

export const Attachments = ({
  attachmentsLoading,
  hasAttachments,
  attachmentData,
}) => {
  return (
    <Grid container spacing={1}>
      {attachmentsLoading ? (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      ) : hasAttachments ? (
        attachmentData.map((attachmentCategory) => (
          <Grid item xs={12} key={attachmentCategory.label}>
            <AttachmentCategory {...attachmentCategory} />
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <Typography>No Attachments</Typography>
        </Grid>
      )}
    </Grid>
  );
};

Attachments.propTypes = {
  attachmentData: PropTypes.array,
  attachmentsLoading: PropTypes.bool,
  hasAttachments: PropTypes.bool,
};
