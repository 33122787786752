import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Government Debt",
  },
  {
    type: fieldTypes.label,
    label:
      "Does the enterprise or any entity or individual (Owners, officers, directors and any person who entered into agreement or deal with the casino licensee/ applicant and their immediate supervisors), owe any debt to the State of New Jersey?",
  },
  {
    type: fieldTypes.label,
    label: "If yes, please explain below:",
  },
  {
    type: fieldTypes.text,
    label: "Explanation",
    name: "new_jersey_vrs.debt_nj_govt_explanation",
    xs: 12,
    rows: 4,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "bdSupplement",
};
