import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";
import PDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/PDPersonSelect.jsx";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <PDPersonSelect name="donor_person_id" label="Donee" />
      <PDPersonSelect name="donee_person_id" label="Donor" />
      <FormikTextField label="Description of Gift" name="description" xs={6} />
      <FormikNumberField label="Approximate Value" name="value" xs={6} />
      <FormikDatePicker label="Date Received" name="gift_date" xs={6} />
    </Grid>
  );
};

const Gifts = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "gifts_gift",
    overwriteMissingRequiredDisplay: {
      donor_person_id: "Donor",
      donee_person_id: "Donee",
    },
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default Gifts;
