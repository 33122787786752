import * as Sentry from "@sentry/react";
import { useMenuOptions } from "hooks/useMenuOptions";
import { uniqBy } from "lodash";
import React from "react";
import * as Yup from "yup";
import { getComponent } from "./componentHelpers";
import { v4 as uuidv4 } from "uuid";
import produce from "immer";
import { getFieldDefaults } from "./getFieldDefaults";
import { getFormGroup } from "componentGenerators/formGenerator/formGroups";
import { fieldTypes } from "componentGenerators/formGenerator/config";

/* 
Steps for adding new component/fieldType:
1. Add to fieldTypes enum 
2. Add component import to the getComponent function
3. Add a schema and initialValue case to getFieldDefaults function
4. Add test case to FormGenerator.test.js

Future refactor idea: enum, component, validation schema and initialValue all defined in a single config file.
*/

const generateValidationSchema = (formSchema) => {
  const validationSchema = formSchema.reduce((prev, fieldSchema) => {
    const { name } = fieldSchema;
    if (name) {
      const schema = getFieldDefaults(fieldSchema.type).schema;
      const schemaWithRequired = fieldSchema.required
        ? schema.required("Required")
        : schema;

      return {
        ...prev,
        [name]: schemaWithRequired,
      };
    }

    return prev;
  }, {});

  return Yup.object().shape(validationSchema);
};

const applyDefaultValueOverrides = (
  initialDefaultValues,
  overrideDefaultValues
) =>
  produce(initialDefaultValues, (draftState) => {
    Object.keys(overrideDefaultValues).forEach((valueKey) => {
      draftState[valueKey] = overrideDefaultValues[valueKey];
    });
  });

const generateDefaultInitialValues = (formSchema, overrideDefaultValues) => {
  const initialDefaultValues = formSchema.reduce((prev, fieldSchema) => {
    const { name, defaultValue, type } = fieldSchema;
    const hasDefaultValue = defaultValue !== undefined;

    if (!hasDefaultValue && type === fieldTypes.select) {
      Sentry.captureException(`${type} type requires a defaultValue`);
      console.error(`${type} type requires a defaultValue`);
    }

    if (name) {
      const initialValueType = hasDefaultValue
        ? defaultValue
        : getFieldDefaults(fieldSchema.type).initialValue;

      const splitNames = name.split(".");

      if (splitNames.length === 1)
        return {
          ...prev,
          [name]: initialValueType,
        };

      //! if we encounter a use case for more than 2 levels of nesting, we have to refactor this to work dynamically
      if (splitNames.length === 2)
        return {
          ...prev,
          [splitNames[0]]: {
            ...prev[splitNames[0]],
            [splitNames[1]]: initialValueType,
          },
        };
    }

    return prev;
  }, {});

  if (!overrideDefaultValues) return initialDefaultValues;

  return applyDefaultValueOverrides(
    initialDefaultValues,
    overrideDefaultValues
  );
};

const getMenuItems = (dynamicMenuItems, menuItems) => {
  if (!dynamicMenuItems.length) {
    Sentry.captureException("Menu Item Missing In Form Generator");
    console.error("Menu Item Missing In Form Generator");
    return [];
  }

  const mergedMenuItems = [];

  dynamicMenuItems.forEach((itemCategory) => {
    if (menuItems[itemCategory]) {
      mergedMenuItems.push(...menuItems[itemCategory]);
    }
  });

  return uniqBy(mergedMenuItems, "value");
};

const generateField = (fieldSchema, menuItems) => {
  const { name, type, options, dynamicMenuItems, ...rest } = fieldSchema;

  const optionsProp = options
    ? options
    : dynamicMenuItems && getMenuItems(dynamicMenuItems, menuItems);

  const props = {
    key: name || uuidv4(),
    name: name,
    ...(optionsProp ? { options: optionsProp } : {}),
    ...rest,
  };

  const component = getComponent(type, props);

  if (!component) throw new Error(`Invalid schema: ${type}`);

  return component;
};

const generateForm = (formSchema, menuItems) =>
  formSchema.map((fieldSchema) => generateField(fieldSchema, menuItems));

const addFormGroupElements = (formSchema) =>
  formSchema.reduce((acc, fieldSchema) => {
    if (fieldSchema.formGroup) {
      return [...acc, ...getFormGroup(fieldSchema)[fieldSchema.formGroup]];
    }
    return [...acc, fieldSchema];
  }, []);

const useFormGenerator = ({ formSchema, overrideDefaultValues }) => {
  const menuItems = useMenuOptions();

  return React.useMemo(() => {
    const formGroupsAdded = addFormGroupElements(formSchema);
    return {
      defaultInitialValues: generateDefaultInitialValues(
        formGroupsAdded,
        overrideDefaultValues
      ),
      validationSchema: generateValidationSchema(formGroupsAdded),
      formElements: generateForm(formGroupsAdded, menuItems),
    };
  }, [formSchema, menuItems, overrideDefaultValues]);
};

export default useFormGenerator;
