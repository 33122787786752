import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Contingencies & Conditions",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Are there any agreement contingencies or conditions between the Casino, Tribe or Platform Provider and applicant, applicant beneficial owners, suppliers, vendors or subcontractors which have not been disclosed?",
    name: "michigan_business_disclosure.has_non_disclosed_agreement_contingencies",
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label: "If yes, provide details below:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "michigan_business_disclosure.non_disclosed_agreement_contingencies_details",
    xs: 12,
    rows: 4,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "bdSupplement",
};
