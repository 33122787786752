import { fieldTypes } from "componentGenerators/formGenerator/config";
import React from "react";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "identification",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of your passport (if a US citizen), OR certificate of
        naturalization OR USCIS identification card (if you are not a non-US
        citizen) in the
        <CustomLink to="citizenship" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Bankruptcy",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        If ou personally ever been adjudicated bankrupt or filed a petition for
        any type of bankruptcy or insolvency under any bankruptcy or insolvency
        law, attach a copy of the bankruptcy petition and discharge, if granted
        in the.
        <CustomLink to="bankruptcies" /> section.
      </>
    ),
  },
];

export default {
  type: "readOnlyPage",
  formSchema,
  endpoint: "pdSupplement",
};
