import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Application Type",
  },
  {
    type: fieldTypes.label,
    label: "Please select below the type of application you are applying for:",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "bd_louisiana_level_two.application_type_checkbox",
    dynamicMenuItems: ["louisianaLevelTwoApplicationTypeCheckbox"],
    defaultValue: null,
  },
  {
    type: fieldTypes.label,
    label: "Is this an initial or renewal application?",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "bd_louisiana_level_two.application_period_checkbox",
    dynamicMenuItems: ["application_period_checkbox"],
    defaultValue: null,
  },
  {
    type: fieldTypes.subHeader,
    label: "Contact Person",
  },
  {
    type: fieldTypes.label,
    label:
      "Please select below the person to be contacted in reference to this application:",
  },
  {
    type: fieldTypes.bdPersonSelect,
    label: "Contact Person",
    name: "bd_louisiana_level_two.contact_person_id",
    defaultValue: null,
  },
  {
    type: fieldTypes.subHeader,
    label: "Principal Addresses",
  },
  {
    type: fieldTypes.label,
    label:
      "Please select below the physical address from which the applicant is or will be conducting business in Louisiana:",
  },
  {
    type: fieldTypes.asyncSelect,
    endpoint: "views/bd_addresses_a",
    label: "Principal Physical Address",
    getOptionLabel: (option) => option.full_address,
    getOptionValue: (option) => option.id,
    xs: 8,
    name: "bd_louisiana_level_two.principal_physical_address_id",
  },
  {
    type: fieldTypes.label,
    label: "Please select below the applicant Louisiana’s mailing address:",
  },
  {
    type: fieldTypes.asyncSelect,
    endpoint: "views/bd_addresses_a",
    label: "Principal Mailing Address",
    getOptionLabel: (option) => option.full_address,
    getOptionValue: (option) => option.id,
    xs: 8,
    name: "bd_louisiana_level_two.principal_mailing_address_id",
  },
  {
    type: fieldTypes.subHeader,
    label: "Record Keeping",
  },
  {
    type: fieldTypes.label,
    label:
      "Please select below the name of the business or individual who maintain the applicant’s records:",
  },
  {
    type: fieldTypes.asyncSelect,
    endpoint: "views/bd_parties",
    label: "Record Keeper",
    getOptionLabel: (option) => option.party_name,
    getOptionValue: (option) => option.id,
    xs: 8,
    name: "bd_louisiana_level_two.record_keeper_id",
  },
  {
    type: fieldTypes.label,
    label:
      "Name(s) of business(es) and individual(s) who prepare tax returns, financial statements and other financial documents and other government reports for the applicant:",
  },
  {
    type: fieldTypes.asyncSelect,
    endpoint: "views/bd_parties",
    label: "Entity Preparing Docs",
    getOptionLabel: (option) => option.party_name,
    getOptionValue: (option) => option.id,
    xs: 8,
    name: "bd_louisiana_level_two.financial_docs_preparer_id",
  },
];

export default [
  {
    type: "form",
    formSchema,
    endpoint: "bdSupplement",
  },
];
