import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm";
import React from "react";
import { useMenuOptions } from "hooks/useMenuOptions";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import FormikCheckBoxGroup from "components/Formik/formGroups/FormikCheckBoxGroup";

const Form = () => {
  const { gamingServicesCheckbox } = useMenuOptions();
  const formData = useForm({
    defaultInitialValues: {
      bd_elk_valley: {
        gaming_services: "",
        gaming_services_checkbox: "",
        other_gaming_services: "",
      },
    },
    endpoint: "bdSupplement",
  });
  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage>
        <Grid container spacing={2}>
          <FormikTextField
            header="Type of gaming service to be provided:"
            label="Gaming Services(must specify)"
            name="bd_elk_valley.gaming_services"
          />
          <SectionSubHeader>
            Check all that apply to this entity
          </SectionSubHeader>
          <FormikCheckBoxGroup
            menuOptions={gamingServicesCheckbox}
            name="bd_elk_valley.gaming_services_checkbox"
            xs={12}
          />

          <FormikTextField
            label="Other(please specify)"
            name="bd_elk_valley.other_gaming_services"
          />
        </Grid>
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

const ElkValleyGamingServicesProvided = ({ title }) => {
  return (
    <FormSectionWrapper title={title}>
      <Form />
    </FormSectionWrapper>
  );
};

export default ElkValleyGamingServicesProvided;
