import { fieldTypes } from "componentGenerators/formGenerator/config";
import format from "date-fns/format";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "License Type",
  },
  {
    type: fieldTypes.label,
    label: "Please chose one of the following options:",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "colorado_kapa.license_type_checkbox",
    dynamicMenuItems: ["coloradoLicenseTypeCheckbox"],
    defaultValue: null,
  },
  {
    type: fieldTypes.subHeader,
    label: "Associated Gaming License",
  },
  {
    type: fieldTypes.label,
    label: "Please select below the company you are associated with:",
  },
  {
    type: fieldTypes.pdBusinessSelect,
    label: "Associated Business Entity",
    name: "colorado_kapa.associated_business_id",
  },
  {
    type: fieldTypes.subHeader,
    label: "Social Security Numbers",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Have you used any other social security numbers other than your current one?",
    name: "colorado_kapa.has_other_ssn",
  },
  {
    type: fieldTypes.label,
    label: "If yes, please explain below:",
  },
  {
    type: fieldTypes.text,
    label: "Explanation",
    name: "colorado_kapa.other_ssn_explanation",
    xs: 12,
    rows: 4,
  },
  {
    type: fieldTypes.subHeader,
    label: "Employment",
  },
  {
    type: fieldTypes.label,
    label:
      "Please select below the employment record which represent your employment with the casino or licensed gaming business where you will be working:",
  },
  {
    type: fieldTypes.asyncSelect,
    name: "colorado_kapa.future_employment_id",
    endpoint:
      "colorado_kapa_supplement_disclosure_questions_employments_applicant",
    label: "Future Employment",
    getOptionLabel: (option) => {
      let to_date = `${format(new Date(option.to_date), "MM/yyyy")}`;
      return `${format(new Date(option.from_date), "MM/yyyy")} - ${
        to_date === "11/2600" ? "Present" : to_date
      }: ${option.business_name}, ${option.position}`;
    },
    getOptionValue: (option) => option.id,
    xs: 8,
  },
  {
    type: fieldTypes.label,
    label:
      "Please select below the employment record which represent your current employment if different from above:",
  },
  {
    type: fieldTypes.asyncSelect,
    name: "colorado_kapa.current_employment_id",
    endpoint:
      "colorado_kapa_supplement_disclosure_questions_employments_applicant",
    label: "Current Employment",
    getOptionLabel: (option) => {
      let to_date = `${format(new Date(option.to_date), "MM/yyyy")}`;
      return `${format(new Date(option.from_date), "MM/yyyy")} - ${
        to_date === "11/2600" ? "Present" : to_date
      }: ${option.business_name}, ${option.position}`;
    },
    getOptionValue: (option) => option.id,
    xs: 8,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "colorado_kapa_supplement_disclosure_questions_pdSupplement",
};
