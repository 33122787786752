import React from "react";

export const columns = [
  /*{
    title: "Business Name",
    field: "bd_business_entity.business_name",
  },*/
  {
    title: "Partner",
    field: "related_party_name",
  },
  {
    title: "Partner Type",
    field: "partner_type",
    format: "startCase",
  },
  {
    title: "From Date",
    field: "from_date",
    format: "date",
  },
  {
    title: "To Date",
    field: "to_date",
    format: "date",
  },
];

export const tableQuestions = (
  <>
    Provide details of all current & former partners for the last ten (10)
    years.
    <br />
    <strong>Note:</strong> Only Required for Partnerships, LLPs, Limited
    Partnerships.
  </>
);

export const defaultInitialValues = {
  //bd_business_entity_id: null,
  related_bd_party_id: null,
  related_bd_party_role: "partner",
  from_date: null,
  to_date: null,
  is_current: false,
  partner_type: null, //BaseModel.enum("bdPartnerTypes"),
  participation_description: "",
  initial_investment_value: null,
  additional_contributions_value: null,
  future_investments_value: null,
  nature_of_future_investments: "",
  method_of_distribution: "",
  reason_left: "",
  separation_terms: "",
  position: "",
};
