import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import {
  SectionSubHeader,
  SectionLabel,
} from "components/Common/LayoutComponents";
import YesNo from "components/Formik/formGroups/YesNo";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm.js";
import React from "react";
import { defaultInitialValues } from "./config";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import { Box } from "@material-ui/core";

const ColoradoPersonalFinancialHistoryForm = () => {
  const formData = useForm({
    defaultInitialValues,
    noValidate: true,
    endpoint: "colorado_rider_personal_information_pdSupplement",
  });

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage>
        <Box mb={2}>
          <SectionSubHeader label="Associated Persons and Associated Keys" />
        </Box>

        <SectionLabel label="Following questions are reserved for Associated Persons and Associated Keys Only. Please submit all executed agreements or documents that grant you any right to any percent of ownership or percent of income from the Colorado gaming business with which you are associated." />
        <SectionLabel label="Executed Agreement Upload:" />

        <FormikFileDropzone
          description="Colorado Personal Financial"
          name="colorado_per_fin_history_attachment_group_id"
          xs={12}
        />

        <SectionLabel label="Amount to be invested in business:" />
        <FormikNumberField
          label="Amount"
          name="colorado.amount_to_be_invested"
          xs={3}
        />
        <SectionLabel label="Percentage of ownership this amount represents:" />
        <FormikNumberField
          label="Percentage"
          name="colorado.percentage_of_ownership"
          xs={3}
          type="percent"
        />
        <SectionLabel label="Investment will be derived from the following sources:" />
        <FormikTextField
          multiline
          rows={4}
          label="List Sources"
          name="colorado.investment_sources_explanation"
          xs={6}
        />

        <YesNo
          label="Has your interest in this gaming establishment been assigned, pledged or hypothecated to any person, firm, or corporation, or has any agreement been entered into whereby your interest is to be assigned,pledged or sold, either in part or whole?"
          name="colorado.has_interest_in_gaming_establishment_explanation"
          xs={12}
        />
        <SectionLabel label="If YES, explain:" />
        <FormikTextField
          multiline
          rows={4}
          label="Explanation"
          name="colorado.interest_in_gaming_establishment_details"
          xs={6}
        />
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

export default ColoradoPersonalFinancialHistoryForm;
