import bd_maryland_principal_entity_supplement_application_information from "components/Pages/Supplemental/BD/MarylandPrincipalEntity/MarylandPrincipalEntityApplicationInformation/MarylandPrincipalEntityApplicationInformation";
import bd_maryland_principal_entity_supplement_attachments from "components/Pages/Supplemental/BD/MarylandPrincipalEntity/MarylandPrincipalEntityAttachments/MarylandPrincipalEntityAttachments";

import { getFormComponents } from "componentGenerators/helpers";

const pageSchemas = {
  bd_maryland_principal_entity_supplement_application_information,
  bd_maryland_principal_entity_supplement_attachments,
};

export default getFormComponents(pageSchemas);
