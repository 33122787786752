import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikSelect from "components/Formik/fields/FormikSelect";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import Tooltip from "components/Common/Tooltip";
import {
  columns,
  defaultInitialValues,
  tableQuestions,
  nonRequired,
} from "./config.js";
import YesNo from "components/Formik/formGroups/YesNo";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";

const StockDescriptionForm = () => {
  const { securityStructureStocks, listOfCountries } = useMenuOptions();
  return (
    <Grid container spacing={2}>
      <FormikSelect
        label="Security Type"
        name="security_type"
        options={securityStructureStocks}
        xs={4}
      >
        <Tooltip title="This is a list of commonly used security types.  Contact support@onecomply.com if this list is missing a security type you require." />
      </FormikSelect>

      <FormikNumberField
        label="Average Value per Share"
        name="average_value_per_share"
        xs={4}
      />

      <FormikNumberField
        label="Number Issued"
        name="shares_issued_count"
        type="none"
        xs={4}
      />
      <FormikNumberField
        label="Number Authorized"
        name="shares_authorized_count"
        type="none"
        xs={4}
      />
      <FormikNumberField
        label="Number Outstanding"
        name="shares_oustanding_count"
        type="none"
        xs={4}
      />
      <YesNo xs={12} label="Has Voting Rights?" name="has_voting_rights" />

      <FormikTextField
        multiline
        rows={2}
        label="Terms, Conditions, Rights and Privileges"
        name="terms_and_conditions"
        xs={12}
      />

      <SectionSubHeader label="Publicly Traded" />
      <YesNo
        xs={12}
        label="Is this security publicly listed?"
        name="is_publicly_listed"
      />
      <FormikTextField
        label="Security Exchange Name"
        name="security_exchange_name"
        xs={4}
      />
      <FormikTextField
        label="Stock Symbol"
        name="security_exchange_symbol"
        xs={4}
      />
      <FormikSelect
        label="Country of Exchange"
        name="security_exchange_country"
        options={listOfCountries}
        xs={4}
      />
      <SectionSubHeader>Stock Exchange Announcements</SectionSubHeader>
      <SectionLabel>
        Upload all announcements to any stock exchanges by the Applicant, its
        parent company or its related companies for the past five (5) years.
      </SectionLabel>
      <FormikFileDropzone
        description="Stock Exchange Announcements"
        label="Upload Attachment"
        name="stock_announcement_attachment_group_id"
        size={12}
      />
    </Grid>
  );
};

const StockDescription = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    nonRequired,
    endpoint:
      "bdSecurityStructure?security_type=ordinary_share&security_type=preference_share&security_type=bond&security_type=debenture&security_type=convertible_note",
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <StockDescriptionForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
    </FormSectionProvider>
  );
};

export default StockDescription;
