import React, { useEffect } from "react";
import AutoCompleteSearchBar from "./Components/AutoCompleteSearchBar";
import { Card } from "@material-ui/core";
import DataTable from "./DataTable";
import { useAuth } from "context/auth";
import ProfileInfo from "./Components/ProfileInfo";
import TagSearchBar from "components/DevTools/PdfTools/Tools/DataIndex/Components/TagSearchBar.jsx";
import FieldTypeSelector from "./Components/FieldTypeSelector";
import DataDisplay from "./Components/DataDisplay";
import TagCreationDisplay from "./Components/TagCreationDisplay";
import Filters from "./Components/Filters";
import Options from "./Components/Options";
import SQLViewer from "./Components/SQLViewer";
import ViewSQLViewer from "./Components/ViewSQLViewer";
import TabularCreation from "components/DevTools/PdfTools/Tools/DataIndex/Components/TabularCreation.jsx";
import {
  usePdfDispatch,
  usePdfState,
  ACTIONS,
} from "components/DevTools/PdfTools/Context/PdfToolsProvider.jsx";

const DataIndex = (props) => {
  const { query, dataIndex, selected, column, focusTable } = usePdfState();
  const dispatch = usePdfDispatch();
  const { PDFValidationTools } = props;
  const { employee_id, bd_business_entity_id, loading } = useAuth();

  useEffect(() => {
    const getDataStructure = async () => {
      const res = await PDFValidationTools.dataIndex();
      const data = employee_id
        ? res.filter(
            (val) =>
              val.table.slice(0, 3) !== "bd_" && val.table.slice(0, 3) !== "lc_"
          )
        : res.filter(
            (val) =>
              val.table.slice(0, 3) === "bd_" && val.table.slice(0, 3) !== "lc_"
          );
      dispatch({ type: ACTIONS.SET_STATE, value: data, name: "dataIndex" });
    };
    getDataStructure();
  }, [props, PDFValidationTools, employee_id, dispatch]);

  useEffect(() => {
    dispatch({
      type: ACTIONS.SET_STATE,
      value: "",
      name: "token",
    });
    dispatch({ type: ACTIONS.SET_STATE, value: 0, name: "tableRows" });
  }, [column, dispatch]);

  useEffect(() => {
    dispatch({ type: ACTIONS.RESET_FILTERS });
    dispatch({ type: ACTIONS.SORT_BY_AMOUNT });
    dispatch({ type: ACTIONS.SORT_BY_DATE });
  }, [selected, dataIndex, dispatch]);

  if (loading) return null;

  if (!dataIndex) {
    return <div>loading...</div>;
  }

  return (
    <Card style={{ minHeight: "80vh", paddingBottom: "300px" }}>
      <div>
        <h2 style={{ padding: "0px 0px 0px 25px" }}>Data Index</h2>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "30px",
          }}
        >
          <ProfileInfo
            employee_id={employee_id}
            bd_business_entity_id={bd_business_entity_id}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <TagSearchBar />
              <FieldTypeSelector />
            </div>
            <SQLViewer query={query} />
          </div>
        </div>
        <hr style={{ marginBottom: "0" }} />
        <DataDisplay />
        <TagCreationDisplay />
        <Filters />
        <Options />
        <TabularCreation />
      </div>
      <AutoCompleteSearchBar PDFValidationTools={props.PDFValidationTools}>
        <ViewSQLViewer />
      </AutoCompleteSearchBar>
      <DataTable data={focusTable.length > 0 ? focusTable : dataIndex} />
    </Card>
  );
};

export default DataIndex;
