import "assets/scss/material-dashboard-pro-react.scss";
import PdfTools from "components/DevTools/PdfTools";
import { useAuth } from "context/auth";
import React from "react";
import { Redirect, Route } from "react-router-dom";

export default function AdminToolsRoute() {
  const auth = useAuth();
  return auth.authenticated ? (
    <Route path="/pdf_tools" component={PdfTools} />
  ) : (
    <Redirect to="/auth" />
  );
}
