import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    wordBreak: "break-word",
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
    // backgroundColor: "#fff",
    "& thead tr,& tfoot tr": {
      backgroundColor: "#BBBBBB",
      color: "black",
    },
    "& td, & th ": {
      border: " 1px solid #ddd",
      padding: "5px 5px 0px 12px",
      width: "25%",
    },

    "& td:not(:first-child)": {
      textAlign: "right",
    },
    "& tfoot tr:first-child": {
      borderTop: " 2px solid #000",
    },
    "& tfoot tr:not(:first-child)": {
      backgroundColor: "#FFF",
    },
    "& tfoot tr:last-child:hover": { backgroundColor: "#EEE" },

    "& tfoot tr:last-child td:first-child:hover": {
      cursor: "pointer",
      backgroundColor: "#DDD",
    },

    "& tbody td:first-child:hover": {
      cursor: "pointer",
      backgroundColor: "#DDD",
    },

    "& tbody tr:hover": { backgroundColor: "#EEE" },
  },

  footer: {
    "& td:nth-child(1)": {
      width: "10%!important",
    },
    "& td:not(:first-child)": {
      textAlign: "left!important",
      paddingLeft: "24px",
    },
  },
}));
