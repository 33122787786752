import { Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents/";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import YesNo from "components/Formik/formGroups/YesNo";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm.js";
import React from "react";
import { defaultInitialValues, endpoint } from "./config";

const Form = () => {
  return (
    <EnhancedFormikPage>
      <Grid container spacing={2}>
        <SectionSubHeader label="Employment Information" />

        <YesNo
          label="Are you an employee of the Tennessee Education Lottery Corporation?"
          name="tennessee.is_education_lottery_employee"
        />

        <YesNo
          label="Are you a member of the Board of Directors of the Tennessee Education Lottery Corporation?"
          name="tennessee.is_education_lottery_board_member"
        />

        <YesNo
          label="Are you a member of the Sports Wagering Advisory Council of the Tennessee Education Lottery Corporation?"
          name="tennessee.is_sports_wagering_advisory_council_board_member"
        />

        <YesNo
          label="Are you an owner or employee of any professional, collegiate, or Olympic sports team?"
          name="tennessee.is_olympic_sports_team_owner_or_employee"
        />

        <YesNo
          label="Are you a member or employee of any governing body of a sports team, league, or association?"
          name="tennessee.is_sports_team_governing_member"
        />

        <YesNo
          label="Are you an official, referee, or any person having the ability to affect directly or holding a position through which decisions made by you would affect the outcome of a sporting event?"
          name="tennessee.can_affect_sporting_event_outcome"
        />
      </Grid>
    </EnhancedFormikPage>
  );
};

const TennesseeEmployment = ({ title }) => {
  const formData = useForm({
    defaultInitialValues: defaultInitialValues,
    endpoint: endpoint,
  });

  return (
    <FormSectionWrapper title={title}>
      <FormContextProvider formData={formData}>
        <Form />
      </FormContextProvider>
    </FormSectionWrapper>
  );
};

export default TennesseeEmployment;
