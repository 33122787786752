import React, { useReducer, createContext, useContext } from "react";

const LoginRedirectPath = createContext();
const SaveRedirectPath = createContext();

function redirectReducer(route, action) {
  if (action.type === "LOAD") {
    return action.redirectFromPath;
  }
  return route;
}

export default function LoginRedirectPathProvider({ children }) {
  const [route, dispatch] = useReducer(redirectReducer, null);

  return (
    <LoginRedirectPath.Provider value={route}>
      <SaveRedirectPath.Provider
        value={(path) => {
          dispatch({
            type: "LOAD",
            redirectFromPath: path,
          });
        }}
      >
        {children}
      </SaveRedirectPath.Provider>
    </LoginRedirectPath.Provider>
  );
}

export function useLoginRedirectFrom() {
  const context = useContext(LoginRedirectPath);
  if (context === undefined) {
    throw new Error(
      "useLoginRedirectFrom must be used within SaveLoginRedirectFrom"
    );
  }
  return context;
}

export function useSaveRedirectPath() {
  const context = useContext(SaveRedirectPath);
  if (context === undefined) {
    throw new Error(
      "useLoginRedirectFrom must be used within useSaveRedirectPath"
    );
  }
  return context;
}
