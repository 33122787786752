import React from "react";
const useTabs = (initialTab = 0) => {
  const [tab, setTab] = React.useState(initialTab);

  const handleTabEventChange = (e, newValue) => {
    setTab(newValue);
  };

  const handleTabChange = (newValue) => {
    setTab(newValue);
  };

  return [tab, handleTabEventChange, handleTabChange];
};

export default useTabs;
