import React from "react";
import { formats } from "components/Common/Tables/Common/helpers";

export const columns = [
  { field: "due_date" },
  { title: "Creditor", field: "creditor_pd_party_name" },
  { title: "Relative Name", field: "relative_pd_person.full_name" },
  { field: "nature_of_payable" },
  { field: "incurred_date" },
  { field: "outstanding_amount", format: formats.currency },
  {
    title: "Current",
    field: "is_current",
    format: "boolean",
    sortType: "boolean",
  },
];

export const tableQuestions = (
  <>
    List below the information requested with regard to all taxes payable for
    which you, your spouse, or your dependent children are obligated. Only real
    estate and income taxes need to be included.
  </>
);

export const nonRequired = ["relative_pd_person_id"];

export const endpoint = "taxes_payable_taxesPayablePayables";

export const defaultInitialValues = {
  type: "tax",
  relative_pd_person_id: null,
  creditor_pd_party_id: null,
  nature_of_payable: "",
  nature_of_security: "",
  incurred_date: null,
  original_amount: null,
  penality: "",
  outstanding_amount: null,
  due_date: null,
  interest_rate: null,
  is_current: null,
};

export const modalTitle = "Tax Payable";

export const dataHookProps = {
  defaultInitialValues,
  nonRequired,
  endpoint,
};
