import { Box } from "@material-ui/core";

import React from "react";
import useFetch from "queryHooks/useFetch.js";
import DisplayTable from "components/Common/Tables/DisplayTable/index.js";
import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider";
import { formats } from "components/Common/Tables/Common/helpers";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const IllinoisGamingBusinessOwnership = ({ title }) => {
  const { data: topData, status } = useFetch(
    "illinois_gaming_business_ownership_associated_business_entities_illinois_pd_q25"
  );
  const { data: bottomData, status: status2 } = useFetch(
    "illinois_gaming_business_ownership_company_ownership_over_five_percent"
  );

  return (
    <FormSectionWrapper title={"Illinois " + title}>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={status}>
          <DisplayTable
            records={topData}
            linkTo={["business_interests"]}
            columns={[
              {
                title: "From date",
                field: "from_date",
                format: formats.date,
              },
              {
                title: "To date",
                field: "to_date",
                format: formats.date,
              },
              {
                title:
                  "Name, address and telephone number of the Business Entity and of its Registered Agent",
                field: "business_and_registered_agents_details",
              },
              {
                title: "Description of the Business Entity",
                field: "business_description",
              },
              {
                title: "Association Description",
                field: "association_description_pretty",
              },
              {
                title:
                  "Reasons you terminated your association with the Business Entity",
                field: "reason_left",
              },
            ]}
            dependsOnQueryCount={{
              question:
                "Submit as Exhibit 25 a list of all Business Entities with which you have been associated as a Key Person, Substantial Owner or Substantial Creditor from age 18 to the present including: (a) Dates, by month and year, you were associated in any capacity with the Business Entity (b) Name, address and telephone number of the Business Entity and of its Registered Agent (c) Description of the Business Entity (d) Description of your association in all capacities with the Business Entity, including titles and duties relating to the Business Entity (e) Reasons you became associated with and, if applicable, terminated your association with the Business Entity (f) If Gaming was part of the operations of the Business Entity, provide all details",
              tooltip:
                "Substantial Owner: The holder of at least 25% of the total combined voting power of a corporation or at least 25% of the total value of shares of all classes of stock of a corporation; or at least a 25% interest in a partnership; or at least 25% of the value of a trust computed actuarially; or at least 25% of the legal or beneficial interest in any other Business Entity. For purposes of computing the foregoing percentages, a holder shall be deemed to own any stock or other interest in a Business Entity, whether owned directly or indirectly.",
            }}
          />
        </LoadingComponent>
      </Box>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={status2}>
          <DisplayTable
            records={bottomData}
            linkTo={["business_interests"]}
            columns={[
              {
                title: "From date",
                field: "from_date",
                format: formats.date,
              },
              {
                title: "To date",
                field: "to_date",
                format: formats.date,
              },
              {
                title: "Name, Address & Phone of Gaming Operator",
                field: "business_name_address_phone",
              },
              {
                title: "Name, Address & Phone of Registered Agent(s)",
                field: "business_registered_agent_name_address_phone",
              },
              {
                title: "Name, Address & Phone of Key Person(s)",
                field: "business_key_person_name_address_phone",
              },
              {
                title: "Gaming Activity Description",
                field: "gaming_activity_description",
              },
              {
                title: "Nature of Interest",
                field: "nature_of_interest",
              },
            ]}
            dependsOnQueryCount="Have you ever held greater than a 5% ownership, beneficial, pecuniary, financial or other interest in any Gaming Operation?"
          />
        </LoadingComponent>
      </Box>
    </FormSectionWrapper>
  );
};

export default IllinoisGamingBusinessOwnership;
