import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";
import React from "react";

export default function CopyText({ text, message = null, children }) {
  const { enqueueSnackbar } = useSnackbar();
  const handleSnackBar = (variant) => (message) => {
    enqueueSnackbar(message, {
      variant,
      autoHideDuration: 2000,
    });
  };

  return (
    <CopyToClipboard
      text={text}
      onCopy={() => {
        handleSnackBar("success")(message || "Tag copied to clipboard");
      }}
    >
      {children}
    </CopyToClipboard>
  );
}
