import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Associated Business Entity",
  },
  {
    type: fieldTypes.pdBusinessSelect,
    label: "Business Entity",
    name: "virginia_pea.associated_business_id",
    xs: 4,
  },
  {
    type: fieldTypes.subHeader,
    label: "Financial Information",
    divider: true,
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Is Applicant currently in default on the payment of any student loan?",
    name: "virginia_pea.has_student_loan",
  },
  {
    type: fieldTypes.pdBusinessSelect,
    label: "Creditor Agency",
    name: "virginia_pea.student_loan_creditor_id",
    xs: 4,
  },
  {
    type: fieldTypes.text,
    label: "Account/Loan Number",
    name: "virginia_pea.student_loan_account_number",
    xs: 4,
    rows: 1,
  },
  {
    type: fieldTypes.number,
    label: "Outstanding Amount of Liability",
    name: "virginia_pea.student_loan_outstanding_amount",
    xs: 4,
    rows: 1,
  },
  {
    type: fieldTypes.subHeader,
    label: "Relationships",
    divider: true,
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Does the Applicant have any personal or business relationship with any member, agent or employee of the virginia Lottery and Gaming Control Agency, virginia Lottery and Gaming Control Commission, virginia State Police or Office of the virginia Attorney General?",
    name: "virginia_pea.has_relationship_with_virginia_official",
  },
  {
    type: fieldTypes.pdPersonSelect,
    label: "Person with Relationship",
    name: "virginia_pea.virginia_official_person_id",
  },
  {
    type: fieldTypes.text,
    label: "Person's Employer",
    name: "virginia_pea.virginia_official_employer",
  },
  {
    type: fieldTypes.text,
    label: "Details of Relationship",
    name: "virginia_pea.virginia_official_relationship",
  },
  {
    type: fieldTypes.subHeader,
    label: "Illegal Use of controlled dangerous substances",
    divider: true,
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Do you currently engage in the illegal use of drugs, or have you ever been arrested for such use?",
    name: "virginia_pea.has_done_illegal_drugs",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "virginia_pea.illegal_drugs_details",
    xs: 12,
    rows: 1,
  },
  {
    type: fieldTypes.subHeader,
    label: "Use of alcohol in the workplace",
    divider: true,
  },
  {
    type: fieldTypes.yesNo,
    label:
      "The use of alcohol by licensees may be prohibited in a VLT facility, and any use of alcohol that adversely affects job performance or conduct maybe the basis for discipline of video lottery employees and revocation or suspension of a VLT license. Does this present a problem for you?",
    name: "virginia_pea.has_workplace_alcohol_problem",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "virginia_pea.workplace_alcohol_problem_details",
    xs: 12,
    rows: 1,
  },
  {
    type: fieldTypes.subHeader,
    label: "Gambling Addiction",
    divider: true,
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Are you a compulsive gambler, or have you ever been voluntarily or involuntarily excluded from any gaming facility?",
    name: "virginia_pea.is_compulsive_gambler",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "virginia_pea.compulsive_gambler_details",
    xs: 12,
    rows: 1,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "virginia_pea_supplement_disclosure_questions_pdSupplement",
};
