import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import AddressLookupAndFields from "components/Formik/formGroups/AddressLookupAndFields";
import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { address } from "helpers/initialValues";
import useForm from "hooks/useForm.js";
import React from "react";

export const MailingAddressForm = ({ innerRef }) => {
  const formData = useForm({
    defaultInitialValues: {
      mailing_address: address,
    },
    noValidate: true,
    endpoint: "personal_details_employeeAddress",
  });

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage
        title={"Mailing Address"}
        innerRef={innerRef}
        showSaveButton={false}
      >
        <Grid container spacing={2}>
          <AddressLookupAndFields name="mailing_address" />
        </Grid>
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

export const BusinessAddressForm = ({ innerRef }) => {
  const formData = useForm({
    defaultInitialValues: {
      current_business_address: address,
    },
    noValidate: true,
    endpoint: "personal_details_employeeAddress",
  });

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage
        title={"Business Address"}
        innerRef={innerRef}
        showSaveButton={false}
      >
        <Grid container spacing={2}>
          <AddressLookupAndFields name="current_business_address" />
        </Grid>
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};
