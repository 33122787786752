import React from "react";
import useFetch from "queryHooks/useFetch";
import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider";
import { Box } from "@material-ui/core";
import DisplayTable from "components/Common/Tables/DisplayTable";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import { columns } from "./config.js";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent.jsx";

export default function ColoradoBusinessInvestments({ name }) {
  const linkTo = ["business_investments", "business_interests"];

  const { data, status } = useFetch(
    "colorado_rider_business_investments_business_investments"
  );

  return (
    <FormSectionWrapper title={name}>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={status}>
          <DisplayTable
            records={data}
            linkTo={linkTo}
            headerComponent={
              <InfoAlert>
                List all business investments by clicking the button below, in
                which any direct, indirect, vested or contingent interest is
                held by you, your spouse or dependent children, or by any person
                or entity on your behalf in which you have a current,
                <strong> or anticipate a future, benefit</strong>. Include the
                names of all individuals or entities that share a direct,
                indirect, vested or contingent interest therein, including, but
                not limited to, joint ventures, partnerships, sole
                proprietorships and corporations.
              </InfoAlert>
            }
            columns={columns}
          />
        </LoadingComponent>
      </Box>
    </FormSectionWrapper>
  );
}
