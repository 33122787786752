import {
  Box,
  Chip,
  Divider,
  Grid,
  LinearProgress,
  Typography,
  CircularProgress,
  Collapse,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React from "react";
import styled from "styled-components";
import {
  ActionsContainer,
  HeaderContainer,
  StyledButton,
  SuccessChip,
} from "../ManageApplications.styles";
import { dayjsUTC } from "helpers/apiHelpers";
import Reward from "react-rewards";

const ApplicationProgressContainer = styled.div`
  padding: 20px 5px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
`;

const ActionButton = ({ children, ...props }) => (
  <StyledButton variant="outlined" color="primary" size="small" {...props}>
    {children}
  </StyledButton>
);

const LabelAndValue = ({ label, value }) => (
  <Box display="flex" alignItems="center" p={1} style={{ marginRight: "20px" }}>
    <Typography
      color="textSecondary"
      variant="subtitle2"
      style={{ paddingRight: "15px", minWidth: "80px" }}
    >
      {label}:
    </Typography>
    <Typography component="span">{value}</Typography>
  </Box>
);

export const ApplicationOverviewArea = ({
  applicationStatus,
  jurisdictionName,
  createdAt,
  applicationName,
  onDelete,
  onView,
  onDownload,
  hasPdfUrl,
  downloadLoading,
  rewardRef,
}) => {
  const StatusChip =
    applicationStatus === "submitted" ? (
      <SuccessChip label="Submitted" />
    ) : (
      <Chip label="Not Submitted" />
    );
  const formattedDate = dayjsUTC(createdAt, "MMM D YYYY");

  return (
    <HeaderContainer>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box p={1}>
            <Typography variant="h3">
              <strong>{applicationName}</strong>
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item md={12} lg={6}>
              <LabelAndValue label={"Status"} value={StatusChip} />
            </Grid>
            <Grid item md={12} lg={6}>
              <LabelAndValue label={"Created on"} value={formattedDate} />
            </Grid>
            <Grid item md={12} lg={6}>
              <LabelAndValue label={"Jurisdiction"} value={jurisdictionName} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider
        orientation="vertical"
        flexItem
        style={{ marginRight: "40px" }}
      />
      {!hasPdfUrl && (
        <ApplicationProgressContainer>
          <Typography variant="subtitle2">Generating Application...</Typography>
          <div style={{ width: "100%", paddingTop: "20px" }}>
            <LinearProgress />
          </div>
        </ApplicationProgressContainer>
      )}
      <Collapse
        in={hasPdfUrl}
        timeout={0}
        style={!hasPdfUrl && { width: "0px" }}
      >
        <ActionsContainer>
          <div>
            <Reward ref={rewardRef} type="confetti">
              <ActionButton onClick={onView} startIcon={<VisibilityIcon />}>
                View
              </ActionButton>
            </Reward>
          </div>
          <div>
            {downloadLoading ? (
              <CircularProgress size={20} />
            ) : (
              <ActionButton onClick={onDownload} startIcon={<GetAppIcon />}>
                Download
              </ActionButton>
            )}
          </div>
          <div>
            <ActionButton onClick={onDelete} startIcon={<DeleteIcon />}>
              Delete
            </ActionButton>
          </div>
        </ActionsContainer>
      </Collapse>
    </HeaderContainer>
  );
};
