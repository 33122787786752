import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Associated Business Entity",
  },
  {
    type: fieldTypes.pdBusinessSelect,
    label: "Business Entity",
    name: "pennsylvania_gsp.associated_business_id",
  },
  {
    type: fieldTypes.subHeader,
    label: "Billing Contact",
    divider: true,
  },
  {
    type: fieldTypes.pdPersonSelect,
    label: "Contact Person",
    name: "pennsylvania_gsp.billing_contact_person_id",
  },
  {
    type: fieldTypes.subHeader,
    label: "Debarment",
    divider: true,
  },
  {
    type: fieldTypes.yesNo,
    label: `Have you ever been placed on the Commonwealth Debarment List maintained by the
      Pennsylvania Department of General Services?`,
    name: "pennsylvania_gsp.is_on_debarment_list",
  },
  {
    type: fieldTypes.label,
    label: `If you answered 'YES' to the above question, please provide the date of debarment
    and the reason in the boxes provided.`,
  },
  {
    type: fieldTypes.date,
    label: `Date of Debarment`,
    name: "pennsylvania_gsp.debarment_date",
  },
  {
    type: fieldTypes.text,
    label: `Reason for Debarment`,
    name: "pennsylvania_gsp.debarment_reason",
  },
  {
    type: fieldTypes.subHeader,
    label: "Substance Abuse",
    divider: true,
  },
  {
    type: fieldTypes.yesNo,
    label: `Do you have or have you had a substance abuse problem?`,
    name: "pennsylvania_gsp.has_substance_abuse_problem",
  },
  {
    type: fieldTypes.yesNo,
    label: `Have you been treated for any health related issue involving alcohol or controlled substances?`,
    name: "pennsylvania_gsp.has_been_treated_alcohol_problem",
  },
  {
    type: fieldTypes.label,
    label: `If you answered 'YES' to the above question, please provide the date(s) of treatment
    and the condition for which you were treated.`,
  },
  {
    type: fieldTypes.date,
    label: `Date of Treatment`,
    name: "pennsylvania_gsp.treatment_date",
  },
  {
    type: fieldTypes.text,
    label: `Condition Treated`,
    name: "pennsylvania_gsp.condition_treated",
  },
  {
    type: fieldTypes.subHeader,
    label: "Financial Interest",
    divider: true,
  },
  {
    type: fieldTypes.yesNo,
    label: `Do you have any ownership interest, financial interest or financial investment in any business entity applying to, 
      or presently licensed by, the Pennsylvania Gaming Control Board?`,
    name: "pennsylvania_gsp.has_ownership_interest_penn_control_board",
  },
  {
    type: fieldTypes.label,
    label: `If you answered 'YES' to the above question, please provide the amount (number of shares/units), and 
    the description of your interest/investment/debt/holding/equity holding and the percent ownership in the business entity.`,
  },
  {
    type: fieldTypes.text,
    label: `Description`,
    name: "pennsylvania_gsp.amount_of_shares_description",
  },
  {
    type: fieldTypes.text,
    label: `Percent Ownership`,
    name: "pennsylvania_gsp.percent_ownership_in_business",
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "pennsylvania_gsp_supplement_disclosure_questions_pdSupplement",
};
