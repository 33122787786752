import {
  Box,
  CircularProgress,
  Container,
  Typography,
} from "@material-ui/core";
import FormStatus from "components/Layout/FormStatus/FormStatus";
import { useFormInformation, useUpdateFormStatus } from "context/formStatus";
import React from "react";
import SentryErrorBoundary from "../../../error/SentryErrorBoundary";
import useFormPath from "../../../hooks/useFormPath";
import FormSectionActions from "./FormSectionActions";
import { JurisdictionNotices } from "./JurisdictionNotices";
import { useUpdateFlaggedStatus } from "./useUpdateFormStatus";
import useUpdateNote from "./useUpdateNote";
import PropTypes from "prop-types";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";
export const FormContext = React.createContext({});

export const FormContextProvider = ({ formData, children }) => {
  return (
    <FormContext.Provider value={formData}>
      <LoadingComponent status={formData.status}>{children}</LoadingComponent>
    </FormContext.Provider>
  );
};

FormContextProvider.propTypes = {
  formData: PropTypes.shape({
    status: PropTypes.string,
  }),
  children: PropTypes.node,
};

export default function FormSectionWrapper({
  title,
  formPath: formPathProp,
  formData,
  headerComponent,
  footerComponent,
  children,
}) {
  const { loading, formStatus, hasEmployeeId } = useFormInformation();
  const updateStatus = useUpdateFormStatus();
  const formPath = useFormPath();
  const formId = formPathProp ? formPathProp : formPath;

  const [hasInitialStatus, setHasInitialStatus] = React.useState(
    formStatus?.[formPath]?.["status"] === ""
  );

  const status = formStatus[formId];

  const { updateNote } = useUpdateNote(status);

  const { handleStatusChange } = useUpdateFlaggedStatus(status);
  if (loading)
    return (
      <span cy-data="loading">
        <CircularProgress />
      </span>
    );

  if (
    formPath &&
    hasEmployeeId &&
    formStatus?.[formPath] &&
    formStatus?.[formPath]?.["status"] === "" &&
    hasInitialStatus
  ) {
    setHasInitialStatus(false);
    updateStatus({ status: "in_progress" }, status.id);
  }

  return (
    <Container>
      <SentryErrorBoundary>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h1">{title}</Typography>
          <FormSectionActions
            handleStatusChange={handleStatusChange}
            status={status}
            updateNote={updateNote}
          />
        </Box>
        <Box mt={1} mb={1}>
          <JurisdictionNotices formId={formId} />
          {headerComponent}
        </Box>
        {formData ? (
          <FormContextProvider formData={{ ...formData }}>
            {children}
          </FormContextProvider>
        ) : (
          children
        )}
        {footerComponent}
        {formPath && hasEmployeeId && formStatus?.[formId] ? (
          <Box pb={1} pt={1}>
            <FormStatus
              status={formStatus[formId]}
              updateStatus={updateStatus}
              handleStatusChange={handleStatusChange}
            />
          </Box>
        ) : null}
      </SentryErrorBoundary>
    </Container>
  );
}

FormSectionWrapper.propTypes = {
  title: PropTypes.string,
  formPath: PropTypes.string,
  formData: PropTypes.object,
  headerComponent: PropTypes.node,
  footerComponent: PropTypes.node,
  children: PropTypes.node,
};
