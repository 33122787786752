import { fieldTypes } from "componentGenerators/formGenerator/config";
import { formats } from "components/Common/Tables/Common/helpers";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Company Management",
  },
  {
    type: fieldTypes.label,
    label:
      "Do you anticipate active participation in the management and operation of the Company this application is filed in conjunction with? If YES, please provide the details below.",
  },
  {
    type: fieldTypes.yesNo,
    name: "louisiana_phfr.section_l_q_11_boolean",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "louisiana_phfr.section_l_q_11_details",
    xs: 12,
    rows: 4,
  },
  {
    type: fieldTypes.subHeader,
    label: "Investment Sources",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label:
      "The applicant’s investment will be derived from the following source(s):",
  },
  {
    type: fieldTypes.text,
    label: "Source A...",
    name: "louisiana_phfr.section_l_q_13_a",
    xs: 12,
  },
  {
    type: fieldTypes.text,
    label: "Source B...",
    name: "louisiana_phfr.section_l_q_13_b",
    xs: 12,
  },
  {
    type: fieldTypes.text,
    label: "Source C...",
    name: "louisiana_phfr.section_l_q_13_c",
    xs: 12,
  },
];

const financialTable = {
  type: "readOnlyTable",
  endpoint: "louisiana_phfr_supplement_financial_referralFeePastTenYears",
  linkTo: ["referral_fees"],
  columns: [
    {
      title: "Date Received",
      field: "date_received",
      format: formats.date,
    },
    {
      title: "Party Names and Addresses",
      field: "party_names_and_addresses",
    },
    {
      title: "Amount",
      field: "amount",
      format: formats.currency,
    },
  ],
  dependsOnQueryCount:
    "During the past ten (10) years, has the applicant received any referral, finder’s, or consulting fee in excess $10,000 USD? If YES, please review the information below for accuracy. Please note that Louisiana requests the incluion of consulting fees.",
};

export default [
  financialTable,
  {
    type: "form",
    formSchema,
    endpoint: "louisiana_phfr_supplement_financial_pdSupplement",
  },
];
