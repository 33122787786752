import { formats } from "components/Common/Tables/Common/helpers.js";
import useGet from "hooks/useGet";
import React from "react";
import usePermissionPath from "hooks/usePermissionPath";

const ownershipCellRender = (data, { owner_pd_party, owner_pd_party_id }) => {
  if (!owner_pd_party) {
    const selected = data.find(({ id }) => id === owner_pd_party_id);
    return selected?.party_name || "N/A";
  }
  return (
    owner_pd_party.pd_business_entity?.business_name ||
    owner_pd_party.pd_person?.full_name
  );
};

/*
 * Custom hook that generates the columns for an inline table that uses a party dropdown select.
 * Housing this in the RealEstateInterests folder now, if this functionality is required elsewhere,
 * this hook can easily be modified to be more generic
 */
const usePartyEntityColumns = () => {
  const permissionedEndpoint = usePermissionPath("pd_parties");

  const { data = [] } = useGet(permissionedEndpoint, permissionedEndpoint);

  const getPartyEntityColumns = React.useCallback(
    () => [
      {
        title: "Owner",
        field: "owner_pd_party.pd_business_entity.business_name",
        disableSorting: true, // disabling sort because sort does not work with multiple attribute i.e "business_name" and "full_name"
        render: (cellData) => ownershipCellRender(data, cellData),
      },
      { field: "ownership_percent", format: formats.percent },
    ],
    [data]
  );

  return { getPartyEntityColumns };
};

export default usePartyEntityColumns;
