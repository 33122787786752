import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label:
      "TALLY OF THE WORK FORCE SUPPORTING THE VENDOR’S PROVISION OF GOODS AND SERVICES TO THESPORTS BETTING PERMIT HOLDER OR SUPPLIER",
  },
  {
    type: fieldTypes.label,
    label:
      "Furnish the Department with the total or estimated number of employees IN VIRGINIA who will be directly associated with providing the goods or services to the sports betting permit holder or supplier.",
  },
  {
    type: fieldTypes.text,
    label: "Amount",
    name: "virginia_sbvr.q_h_8_e_in_virginia",
    xs: 4,
  },
  {
    type: fieldTypes.label,
    label: "The above amount is:",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "virginia_sbvr.q_h_8_e_in_virginia_type_checkbox",
    dynamicMenuItems: ["virginiaSBVRActualOrEstimatedTypeCheckbox"],
    defaultValue: null,
  },
  {
    type: fieldTypes.label,
    label:
      "Furnish the Department with the total or estimate number of employees OUTSIDE VIRGINIA who will be directly associated with providing the goods or services to the sports betting permit holder or supplier.",
  },
  {
    type: fieldTypes.text,
    label: "Amount",
    name: "virginia_sbvr.q_h_8_e_outside_virginia",
    xs: 4,
  },
  {
    type: fieldTypes.label,
    label: "The above amount is:",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "virginia_sbvr.q_h_8_e_outside_virginia_type_checkbox",
    dynamicMenuItems: ["virginiaSBVRActualOrEstimatedTypeCheckbox"],
    defaultValue: null,
  },
  {
    type: fieldTypes.subHeader,
    label:
      "DESCRIPTION OF THE VENDOR’S ABILITY TO PROVIDE GOODS OR SERVICES TO MORE THAN ONESPORTS BETTING OPERATOR OR SUPPLIER",
  },
  {
    type: fieldTypes.label,
    label:
      "Describe the Vendor’s ability to serve one, two, or more sports betting operators and/or suppliers in Virginia and if known, the other sports betting operators and/or suppliers with which the Vendor intends to conduct business.",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "virginia_sbvr.q_h_8_f_details",
    xs: 12,
    rows: 4,
  },
];

export default [
  {
    type: "form",
    formSchema,
    endpoint: "bdSupplement",
  },
];
