import React from "react";

export const columns = [
  { field: "party_name" },
  {
    title: "Agency or Court",
    field: "law_enforcement_bd_busines_entity.business_name",
  },
  {
    title: "Testimony Date",
    field: "testimony_date",
    format: "date",
  },
  {
    field: "nature_of_charge",
  },
  {
    field: "type",
    format: "startCase",
  },
];

export const tableQuestions = (
  <>
    <>
      Has the business entity or any of its subsidiaries, directors, trustees,
      or officers ever been called to testify before, been the subject of an
      investigation conducted by, or requested to take a polygraph exam by a
      government agency, court, committee, grand jury, or investigatory body of
      any kind other than in response to minor traffic related offenses?
    </>
  </>
);

export const defaultInitialValues = {
  bd_party_id: null,
  law_enforcement_bd_business_entity_id: null,
  is_testimony_given: null,
  is_testimony_refused: null,
  testimony_date: null,
  time_period: "",
  nature_of_charge: "",
  type: "",
  criminal_disposition: "",
  bd_legal_history_attachment_group_id: null,
};

export const endpoint = "bdLegalHistory?type=testimony&type=investigation";
export const nonRequired = ["bd_legal_history_attachment_group_id"];

export const tabularDataParams = {
  defaultInitialValues,
  endpoint,
  nonRequired,
};
