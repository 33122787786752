/* eslint-disable react/prop-types */

import React from "react";
import Button from "@material-ui/core/Button";
import { Box, Checkbox } from "@material-ui/core";
import { permReduceTypes } from "./permissionReducer";

export default function PermissionMenu({
  permissions: { write, read },
  name = "",
  dispatch,
}) {
  const handlePermission =
    ({ type, value }) =>
    (event) => {
      event.preventDefault();
      dispatch({ type, name, value });
    };

  return (
    <Box display="flex" pr={2.25}>
      <Button
        style={{ marginRight: "4px" }}
        size="small"
        onClick={handlePermission({
          type: permReduceTypes.read,
          value: write ? read : !read,
        })}
      >
        <Checkbox
          disabled={write}
          checked={read}
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </Button>

      <Button
        style={{ marginRight: "4px" }}
        size="small"
        onClick={handlePermission({
          type: permReduceTypes.write,
          value: !write,
        })}
      >
        <Checkbox
          checked={write}
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </Button>
    </Box>
  );
}
