import PropTypes from "prop-types";
import { Grid, Paper, Box } from "@material-ui/core";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import useFormGenerator from "componentGenerators/formGenerator/useFormGenerator.jsx";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import useForm from "../hooks/useForm";
import { v4 as uuidv4 } from "uuid";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";
import useFetch from "queryHooks/useFetch";
import DisplayTable from "components/Common/Tables/DisplayTable";
import useEndPoint from "hooks/useEndpoint.js";
import { pageTypes } from "./formGenerator/config";

const InfoAlerts = ({ infoAlerts }) => {
  if (!infoAlerts?.length) return null;
  return infoAlerts.map(({ body, ...props }, index) => (
    <InfoAlert
      {...props}
      key={uuidv4()}
      mb={infoAlerts.length > 1 && index !== infoAlerts.length - 1 ? 1 : 0}
    >
      {body}
    </InfoAlert>
  ));
};

const TableGenerator = ({
  schema: {
    infoAlert,
    formSchema,
    endpoint,
    nonRequired,
    columns,
    title,
    maxRecordSize,
    subheader,
    modifySubmissionValues = (formValues) => formValues,
    postValues,
    patchValues,
    infoAlerts,
    saveEndpoint,
    tableProps,
  },
}) => {
  const {
    defaultInitialValues,
    formElements,
    validationSchema,
  } = useFormGenerator({
    formSchema,
  });

  // this endpoint is only used if saveEndpoint Prop is passed
  const { fullEndpoint } = useEndPoint(saveEndpoint || "");

  const formData = useTabularData({
    defaultInitialValues,
    endpoint,
    nonRequired,
    ...tableProps,
    noValidate: true,
    postValues: postValues || modifySubmissionValues,
    patchValues: patchValues || modifySubmissionValues,
    saveEndpoint: saveEndpoint && fullEndpoint,
  });

  return (
    <FormContextProvider
      formData={formData}
      headerComponent={infoAlert && <InfoAlert>{infoAlert}</InfoAlert>}
    >
      <EnhancedFormikDialog validationSchema={validationSchema} title={title}>
        <Grid container spacing={2}>
          {formElements}
        </Grid>
      </EnhancedFormikDialog>

      <EnhancedActionsTable
        columns={columns}
        title={title}
        maxRecordSize={maxRecordSize}
        subheader={
          subheader || (infoAlerts && <InfoAlerts infoAlerts={infoAlerts} />)
        }
      />
    </FormContextProvider>
  );
};

const FormSectionGenerator = ({
  schema: { infoAlert, formSchema, endpoint, title, saveEndpoint },
}) => {
  const { defaultInitialValues, formElements } = useFormGenerator({
    formSchema,
  });

  const formData = useForm({
    defaultInitialValues,
    endpoint,
    saveEndpoint,
  });

  return (
    <FormContextProvider
      formData={formData}
      headerComponent={infoAlert && <InfoAlert>{infoAlert}</InfoAlert>}
    >
      <EnhancedFormikPage title={title}>
        <Grid container spacing={2}>
          {formElements}
        </Grid>
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

const ReadOnlyTableGenerator = ({ schema: { endpoint, ...props } }) => {
  const { data, status } = useFetch(endpoint);

  return (
    <Box mb={1}>
      <Paper variant="outlined">
        <LoadingComponent status={status}>
          <DisplayTable records={data} {...props} />
        </LoadingComponent>
      </Paper>
    </Box>
  );
};

const ReadOnlyPageGenerator = ({ schema: { formSchema, infoAlert } }) => {
  const { formElements } = useFormGenerator({
    formSchema,
  });

  return (
    <Box mb={1} mt={1}>
      {infoAlert ? (
        <Box mt={1} mb={1}>
          <InfoAlert>{infoAlert}</InfoAlert>
        </Box>
      ) : null}
      <Paper variant="outlined">
        <Box p={2}>
          <Grid container spacing={2}>
            {formElements}
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

const FormGenerator = ({ name, schemas }) => {
  return (
    <FormSectionWrapper title={name}>
      {schemas.map(({ type, ...schema }, i) => {
        if (type === pageTypes.table)
          return <TableGenerator schema={schema} key={i} />;
        if (type === pageTypes.form)
          return <FormSectionGenerator schema={schema} key={i} />;
        if (type === pageTypes.readOnlyTable)
          return <ReadOnlyTableGenerator schema={schema} key={i} />;
        if (type === pageTypes.readOnlyPage)
          return <ReadOnlyPageGenerator schema={schema} key={i} />;

        throw new Error("Invalid schema type provided");
      })}
    </FormSectionWrapper>
  );
};

TableGenerator.propTypes = {
  schema: PropTypes.shape({
    infoAlert: PropTypes.node,
    formSchema: PropTypes.array,
    endpoint: PropTypes.string,
    nonRequired: PropTypes.bool,
    columns: PropTypes.array,
    title: PropTypes.string,
    maxRecordSize: PropTypes.number,
    subheader: PropTypes.node,
    modifySubmissionValues: PropTypes.func,
    postValues: PropTypes.func,
    patchValues: PropTypes.func,
    infoAlerts: PropTypes.array,
    saveEndpoint: PropTypes.string,
  }),
};

FormSectionGenerator.propTypes = {
  schema: PropTypes.shape({
    infoAlert: PropTypes.node,
    formSchema: PropTypes.array,
    endpoint: PropTypes.string,
    title: PropTypes.string,
    saveEndpoint: PropTypes.string,
  }),
};

ReadOnlyTableGenerator.propTypes = {
  schema: PropTypes.shape({
    endpoint: PropTypes.string,
  }),
};

FormGenerator.propTypes = {
  name: PropTypes.string,
  schemas: PropTypes.any,
};

ReadOnlyPageGenerator.propTypes = {
  schema: PropTypes.shape({
    endpoint: PropTypes.string,
    formSchema: PropTypes.array,
    infoAlert: PropTypes.node,
  }),
};

export default FormGenerator;
