//PERSONAL INFORMATION
import PersonalDetails from "components/Pages/PD/PersonalInformation/PersonalDetails/PersonalDetails";
import passport from "components/Pages/PD/PersonalInformation/Passport/schemas";
import Citizenship from "components/Pages/PD/PersonalInformation/Citizenship/Citizenship";
import Education from "components/Pages/PD/PersonalInformation/Education/Education";
import references from "components/Pages/PD/PersonalInformation/References/config";

import ForeignTravel from "components/Pages/PD/PersonalInformation/ForeignTravel/ForeignTravel";

//RELATIVES
import Children from "components/Pages/PD/Relatives/Children/Children";
import Siblings from "components/Pages/PD/Relatives/Siblings/Siblings";
import marriages from "components/Pages/PD/Relatives/Spouses/schemas";
import Parents from "components/Pages/PD/Relatives/Parents/Parents";
import OtherRelatives from "components/Pages/PD/Relatives/OtherRelatives/OtherRelatives";

//EMPLOYMENT
import GeneralEmployment from "components/Pages/PD/Employment/EmploymentHistory/EmploymentHistory";
import SpouseEmployment from "components/Pages/PD/Employment/SpousalEmployment/SpousalEmployment";

import FiduciaryPositionsEmployment from "components/Pages/PD/Employment/FiduciaryPositions/FiduciaryPositions";
import GovernmentPositionEmployment from "components/Pages/PD/Employment/GovernmentPositions/GovernmentPositions";
import DenialsEmployment from "components/Pages/PD/Employment/FiduciaryDenials/FiduciaryDenials";
import Military from "components/Pages/PD/Employment/Military/Military";

//LICENSING
import PersonalLicensing from "components/Pages/PD/Licensing/PersonalLicensing/PersonalLicensing";
import family_ownership from "components/Pages/PD/Licensing/FamilyOwnershipGaming/schemas";
import BusinessInterests from "components/Pages/PD/Licensing/BusinessInterests/BusinessInterests";
import business_licensing from "components/Pages/PD/Licensing/BusinessLicensing/schemas";
import VehicleLicensing from "components/Pages/PD/Licensing/VehicleLicensing/VehicleLicensing";

//CRIMINAL
import ArrestsAndCharges from "components/Pages/PD/Criminal/ArrestAndCharges/ArrestAndCharges";
import government_investigations from "components/Pages/PD/Criminal/Government/schemas";
import testimonies from "components/Pages/PD/Criminal/Testimonies/schemas";
import Pardon from "components/Pages/PD/Criminal/Pardon/Pardon";
import Indictments from "components/Pages/PD/Criminal/Indictments/Indictments";
import FamilyArrest from "components/Pages/PD/Criminal/FamilyArrest/FamilyArrest";
import GamingExclusions from "components/Pages/PD/Criminal/GamingExclusions/GamingExclusions";
import LawsuitsAndArbitrations from "components/Pages/PD/Criminal/Lawsuits/Lawsuits";
import Citations from "components/Pages/PD/Criminal/Citations/Citations";

//FINANCIAL
import FinancialQuestions from "components/Pages/PD/Financial/FinancialQuestions/FinancialQuestions";
import LiensAndDebts from "components/Pages/PD/Financial/LiensAndDebts/LiensAndDebts";
import Bankruptcies from "components/Pages/PD/Financial/Bankruptcy/Bankruptcies";
import WageGarnishments from "components/Pages/PD/Financial/WageGarnishments/WageGarnishments";
import PropertyRepossession from "components/Pages/PD/Financial/PropertyRepossession/PropertyRepossession";
import Executor from "components/Pages/PD/Financial/Executor/Executor";
import MarginAccounts from "components/Pages/PD/Financial/MarginAccounts/MarginAccounts";
import InsuranceClaims from "components/Pages/PD/Financial/InsuranceClaims/InsuranceClaims";
import LendingHistory from "components/Pages/PD/Financial/LendingHistory/LendingHistory";
import CurrencyExchanges from "components/Pages/PD/Financial/CurrencyExchanges/CurrencyExchanges";
import BusinessReceivership from "components/Pages/PD/Financial/BusinessReceivership/BusinessReceivership";
import Gifts from "components/Pages/PD/Financial/Gifts/Gifts";
import SafetyDepositBox from "components/Pages/PD/Financial/SafetyDepositBox/SafetyDepositBox";
import LoanGuarantees from "components/Pages/PD/Financial/LoanGuarantees/LoanGuarantees";
import BorrowingHistory from "components/Pages/PD/Financial/BorrowingHistory/BorrowingHistory";
import AssetsInTrust from "components/Pages/PD/Financial/Trust/AssetsInTrust/AssetsInTrust";
import NomineeHoldings from "components/Pages/PD/Financial/Trust/NomineeHoldings/NomineeHoldings";
import ReferralFees from "components/Pages/PD/Financial/ReferralFees/ReferralFees";

//TAX
import USTaxes from "components/Pages/PD/Tax/USTaxes/USTaxes";
import NonUSTaxes from "components/Pages/PD/Tax/NonUSTaxes/NonUSTaxes";
import TaxAudit from "components/Pages/PD/Tax/TaxAudit/TaxAudit";

//NETWORTH
import BankAccounts from "components/Pages/PD/Networth/BankAccounts/BankAccounts";
import CashOnHand from "components/Pages/PD/Networth/CashOnHand/CashOnHand";
import LoansAndReceivables from "components/Pages/PD/Networth/LoansAndReceivables/LoansAndReceivables";
import Securities from "components/Pages/PD/Networth/Securities/Securities";
import RealEstateInterests from "components/Pages/PD/Networth/RealEstateInterests/RealEstateInterests";
import LifeInsurance from "components/Pages/PD/Networth/LifeInsurance/LifeInsurance";
import PensionFunds from "components/Pages/PD/Networth/PensionFunds/PensionFunds";
import Vehicles from "components/Pages/PD/Networth/Vehicles/Vehicles";
import OtherAssets from "components/Pages/PD/Networth/OtherAssets/OtherAssets";
import BusinessInvestments from "components/Pages/PD/Networth/BusinessInvestments/BusinessInvestments";
import NotesPayable from "components/Pages/PD/Networth/NotesPayable/NotesPayable";
import LoansAndOtherPayables from "components/Pages/PD/Networth/LoansAndOtherPayables/LoansAndOtherPayables";
import TaxesPayable from "components/Pages/PD/Networth/TaxesPayables/TaxesPayables";
import RealEstateLoans from "components/Pages/PD/Networth/RealEstateLoans/RealEstateLoans";
import InsurancePensionLoans from "components/Pages/PD/Networth/InsurancePensionLoans/InsurancePensionLoans";
import OtherIndebtedness from "components/Pages/PD/Networth/OtherIndebtedness/OtherIndebtedness";
import ContingentLiabilities from "components/Pages/PD/Networth/ContingentLiabilities/ContingentLiabilities";
import NetWorthStatement from "components/Pages/PD/Networth/NetWorthStatement/NetWorthStatement";
import NonResidentBankAccount from "components/Pages/PD/Networth/NonResidentBankAccount/NonResidentBankAccount";
import NonResidentAssetAndLiabilities from "components/Pages/PD/Networth/NonResidentAssetAndLiabilities";

// BUSINESS AND PEOPLE
import BusinessEntities from "components/Pages/PD/BusinessAndPeople/BusinessEntities/BusinessEntities";
import People from "components/Pages/PD/BusinessAndPeople/People/People";
import residential_history from "components/Pages/PD/PersonalInformation/ResidentialHistory/config";
import { getFormComponents } from "componentGenerators/helpers";

const pdPersonalInformationSectionSchemas = {
  residential_history,
  passport,
};

const pdRelativesSectionSchemas = {
  marriages,
  references,
};

const pdLicensingAndOwnership = {
  family_ownership,
  business_licensing,
};

const pdCriminalSectionSchemas = {
  testimonies,
  government_investigations,
};

const pd_pages = {
  ...pdPersonalInformationSectionSchemas,
  ...pdRelativesSectionSchemas,
  ...pdLicensingAndOwnership,
  ...pdCriminalSectionSchemas,
};

const pages = {
  ...getFormComponents(pd_pages),
  personal_details: PersonalDetails,
  citizenship: Citizenship,
  education: Education,
  foreign_travel: ForeignTravel,
  children: Children,
  parents: Parents,
  siblings: Siblings,
  other_relatives: OtherRelatives,
  fiduciary_positions: FiduciaryPositionsEmployment,
  government_positions: GovernmentPositionEmployment,
  employment_history: GeneralEmployment,
  military: Military,
  spouse_employment: SpouseEmployment,
  fiduciary_denials: DenialsEmployment,
  us_taxes: USTaxes,
  non_us_taxes: NonUSTaxes,
  tax_audit: TaxAudit,
  personal_licensing: PersonalLicensing,
  business_interests: BusinessInterests,
  vehicle_licenses: VehicleLicensing,
  arrests: ArrestsAndCharges,
  indictments: Indictments,
  pardons: Pardon,
  family_arrests: FamilyArrest,
  lawsuits_and_arbitrations: LawsuitsAndArbitrations,
  citations: Citations,
  license_exclusions: GamingExclusions,
  financial_questions: FinancialQuestions,
  liens: LiensAndDebts,
  bankruptcies: Bankruptcies,
  business_receiverships: BusinessReceivership,
  garnishments: WageGarnishments,
  repossessions: PropertyRepossession,
  executor: Executor,
  assets_in_trust: AssetsInTrust,
  nominee_holdings: NomineeHoldings,
  non_resident_bank_accounts: NonResidentBankAccount,
  non_resident_assets_and_liabilities: NonResidentAssetAndLiabilities,
  lending_history: LendingHistory,
  borrowing_history: BorrowingHistory,
  currency_exchanges: CurrencyExchanges,
  margin_accounts: MarginAccounts,
  insurance_claims: InsuranceClaims,
  gifts: Gifts,
  safe_deposit_boxes: SafetyDepositBox,
  referral_fees: ReferralFees,
  loan_guarantees: LoanGuarantees,
  net_worth_statements: NetWorthStatement,
  bank_accounts: BankAccounts,
  cash: CashOnHand,
  loans_and_receivables: LoansAndReceivables,
  securities: Securities,
  real_estate_interests: RealEstateInterests,
  life_insurance: LifeInsurance,
  pension_funds: PensionFunds,
  vehicles: Vehicles,
  business_investments: BusinessInvestments,
  other_assets: OtherAssets,
  notes_payable: NotesPayable,
  loans_and_other_payables: LoansAndOtherPayables,
  taxes_payable: TaxesPayable,
  real_estate_loans: RealEstateLoans,
  insurance_pension_loans: InsurancePensionLoans,
  other_indebtedness: OtherIndebtedness,
  contingent_liabilities: ContingentLiabilities,
  business_entities: BusinessEntities,
  people: People,
};

export default pages;
