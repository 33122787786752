import { getFormComponents } from "componentGenerators/helpers";
import bd_michigan_supplier_license_supplement_agreement from "components/Pages/Supplemental/BD/MichiganSupplierLicense/MichiganSupplierLicenseAgreement/MichiganSupplierLicenseAgreement";
import bd_michigan_supplier_license_supplement_application_information from "components/Pages/Supplemental/BD/MichiganSupplierLicense/MichiganSupplierLicenseApplicationInformation/MichiganSupplierLicenseApplicationInformation";
import bd_michigan_supplier_license_supplement_associated_entities from "components/Pages/Supplemental/BD/MichiganSupplierLicense/MichiganSupplierLicenseAssociatedEntities/MichiganSupplierLicenseAssociatedEntities";
import bd_michigan_supplier_license_supplement_attachments from "components/Pages/Supplemental/BD/MichiganSupplierLicense/MichiganSupplierLicenseAttachments/MichiganSupplierLicenseAttachments";
import bd_michigan_supplier_license_supplement_compliance from "components/Pages/Supplemental/BD/MichiganSupplierLicense/MichiganSupplierLicenseCompliance/MichiganSupplierLicenseCompliance";
import bd_michigan_supplier_license_supplement_ownership from "components/Pages/Supplemental/BD/MichiganSupplierLicense/MichiganSupplierLicenseOwnership/MichiganSupplierLicenseOwnership";
import bd_michigan_supplier_license_supplement_tax_liability from "components/Pages/Supplemental/BD/MichiganSupplierLicense/MichiganSupplierLicenseTaxLiability/MichiganSupplierLicenseTaxLiability";

export default getFormComponents({
  bd_michigan_supplier_license_supplement_associated_entities,
  bd_michigan_supplier_license_supplement_ownership,
  bd_michigan_supplier_license_supplement_tax_liability,
  bd_michigan_supplier_license_supplement_application_information,
  bd_michigan_supplier_license_supplement_agreement,
  bd_michigan_supplier_license_supplement_attachments,
  bd_michigan_supplier_license_supplement_compliance,
});
