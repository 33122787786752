import { Box } from "@material-ui/core";

import React from "react";
import useFetch from "queryHooks/useFetch.js";
import DisplayTable from "components/Common/Tables/DisplayTable/index.js";
import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const IllinoisNomineeAssets = ({ title }) => {
  const { data, status } = useFetch(
    "illinois_nominee_assets_asset_histories_third_party_gaming"
  );

  return (
    <FormSectionWrapper title={"Illinois " + title}>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={status}>
          <DisplayTable
            records={data}
            linkTo="nominee_holdings"
            columns={[
              {
                title: "Beneficial Owner",
                field: "beneficial_owner_name_address_phone",
              },
              { title: "Business Entity", field: "business_name_address" },
              { title: "Business Description", field: "business_description" },
              { title: "Number of Shares", field: "number_of_shares_pretty" },
              { title: "Interest Amount", field: "interest_amount_pretty" },
              { title: "Description", field: "description" },
            ]}
            dependsOnQueryCount="Are you or any of your Relatives the Nominee, or hold any interest in trust, for any Individual or Business Entity in any Gaming Operation, or hold a 5% or greater interest in trust for any Individual or Business Entity in the Parent Company of any Gaming Operation?"
          />
        </LoadingComponent>
      </Box>
    </FormSectionWrapper>
  );
};

export default IllinoisNomineeAssets;
