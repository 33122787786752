import React from "react";
import Button from "components/Common/CustomButtons/Button";
import PropTypes from "prop-types";

export default function CancelButton({ type, style, children, onClick }) {
  return (
    <Button
      style={style}
      type={type}
      onClick={onClick}
      cy-data="cancel_button"
      color="transparent"
      size="sm"
    >
      {children}
    </Button>
  );
}

CancelButton.propTypes = {
  type: PropTypes.string,
  style: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};
