import { formats } from "components/Common/Tables/Common/helpers";

export const columns = [
  {
    title: "Qualifier",
    field: "party_name",
  },
  {
    title: "Role",
    field: "related_bd_party_role",
    format: formats.startCase,
  },
];

export const endpoint = "views/bd_positions_a_qualifiers";
