import React from "react";
import { commonStrings } from "../../uiStrings";
import { formats } from "components/Common/Tables/Common/helpers";

export const columns = [
  {
    field: "gift_date",
  },
  {
    title: "Donee",
    field: "donee_person.full_name",
  },
  {
    title: "Donor",
    field: "donor_person.full_name",
  },

  {
    field: "value",
    format: formats.currency,
  },
  {
    field: "description",
  },
];

export const tableQuestions = (
  <>
    During the last five (5) year period, have {commonStrings.youSpouseChildren}{" "}
    given or received any gift or gifts, whether tangible or intangible which
    either individually or in the aggregate exceeded $10,000USD in value in any
    one year period? {commonStrings.usdCurrencyReporting}
    {commonStrings.provideDetails}
  </>
);

export const defaultInitialValues = {
  donor_person_id: null,
  donee_person_id: null,
  description: "",
  value: null,
  gift_date: null,
};
