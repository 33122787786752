import { Grid } from "@material-ui/core";
import { NumberFormatTypes } from "components/Common/Inputs/NumberField";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikCheckBox from "components/Formik/fields/FormikCheckBox.jsx";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikNumberField from "components/Formik/fields/FormikNumberField.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import PDRelativeSelect from "components/Formik/formGroups/CrudSelect/selects/PDRelativeSelect.jsx";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import ColumnSum from "components/Layout/SectionsLayout/ColumnSum.jsx";
import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import {
  columns,
  dataHookProps,
  modalTitle,
  tableQuestions,
} from "./config.js";

import PDPartySelect from "components/Formik/formGroups/CrudSelect/selects/PDPartySelect";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <PDRelativeSelect
        label="Spouse or Child (If Applicable)"
        header="Select if held by Spouse or Dependent Child"
      />

      <SectionSubHeader divider>Creditor Details</SectionSubHeader>

      <PDPartySelect name="creditor_pd_party_id" label="Creditor" />

      <FormikTextField
        header="Details"
        divider
        label="Nature of Payable"
        name="nature_of_payable"
        xs={8}
      />
      <FormikTextField
        label="Account Number (if any)"
        name="account_number"
        xs={4}
      />
      <FormikTextField
        label="Nature of Security"
        name="nature_of_security"
        xs={8}
      />
      <FormikDatePicker
        header="Terms"
        divider
        label="Date Incurred"
        name="incurred_date"
        xs={4}
      />
      <FormikDatePicker label="Due Date" name="due_date" xs={4} />
      <FormikNumberField
        label="Interest Rate"
        name="interest_rate"
        md={4}
        type={NumberFormatTypes.percent}
      />
      <FormikNumberField
        label="Original Amount"
        name="original_amount"
        xs={4}
      />
      <FormikTextField
        label="Total Payments"
        name="total_payments"
        placeholder="0"
        xs={4}
      />
      <FormikNumberField
        label="Outstanding Amount"
        name="outstanding_amount"
        xs={4}
      />
      <FormikTextField
        label="Payment Amount and Pay Period"
        name="periodic_payment_details"
        placeholder="eg: $5000 per month"
        xs={12}
      />
      <FormikCheckBox
        label="Is Current - Current notes will be included in the Net Worth Statement."
        name="is_current"
        xs={12}
      />
    </Grid>
  );
};

const NotesPayable = ({ name }) => {
  const formData = useTabularData({
    ...dataHookProps,
    overwriteMissingRequiredDisplay: { creditor_pd_party_id: "Creditor" },
  });

  return (
    <FormSectionWrapper
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={modalTitle}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        footer={
          <ColumnSum
            name="outstanding_amount"
            data={formData.data}
            label="Total Amount Of Outstanding Notes"
          />
        }
        columns={columns}
        addButtonText="Add Record"
      />
    </FormSectionWrapper>
  );
};

export default NotesPayable;
