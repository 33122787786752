import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { InfoAlert } from "components/Layout/Alert/InfoAlert.jsx";
import { Grid } from "@material-ui/core";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FromToCurrent from "components/Formik/formGroups/FromToCurrent";
import { SectionSubHeader } from "components/Common/LayoutComponents";

const columns = [
  { field: "from_date" },
  { field: "to_date" },
  { field: "first_name" },
  { field: "middle_name" },
  { field: "last_name" },
];

const Form = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <InfoAlert severity="warning">
          For one word nicknames, use the &quot;First Name&quot; field.
        </InfoAlert>
      </Grid>
      <SectionSubHeader>Name</SectionSubHeader>
      <FormikTextField label="First Name" name="first_name" xs={4} />
      <FormikTextField label="Middle Name" name="middle_name" xs={4} />
      <FormikTextField label="Last Name" name="last_name" xs={4} />
      <FormikTextField label="Suffix" name="suffix" xs={4} />
      <SectionSubHeader divider>Dates</SectionSubHeader>
      <FromToCurrent />
    </Grid>
  );
};

const NickNamesTable = () => {
  const formData = useTabularData({
    defaultInitialValues: {
      first_name: "",
      middle_name: "",
      last_name: "",
      suffix: "",
      from_date: null,
      to_date: null,
      is_current: null,
    },
    endpoint: "personal_details_nameHistory",
    noValidate: true,
    nonRequired: ["middle_name", "last_name", "suffix"],
  });

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikDialog title="Nicknames, Aliases, Other Names">
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        title="Nicknames, Aliases, Other Names"
        subheader={
          <InfoAlert>
            List any additional names below and specify dates of use for each.
            Include maiden name, aliases, nicknames or any other name.
          </InfoAlert>
        }
        columns={columns}
      />
    </FormContextProvider>
  );
};

export default NickNamesTable;
