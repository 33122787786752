import React from "react";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Identification Number",
  },
  {
    type: fieldTypes.label,
    label: "Indiana's Tax Identification Number:",
  },
  {
    type: fieldTypes.text,
    label: "Identification Number",
    name: "indiana_supplier_license.tax_identification_number",
  },
  {
    type: fieldTypes.subHeader,
    label: "Contacts",
  },

  {
    type: fieldTypes.asyncSelect,
    name: "indiana_supplier_license.attorney_contract_id",
    endpoint: "views/bd_service_providers_a?related_bd_party_role=lawyer",
    label: "Representative",
    getOptionLabel: (option) => option.related_party_name,
    getOptionValue: (option) => option.id,
    helperText: (
      <>
        <p style={{ margin: "0px" }}>
          Select the Law Firm representing the Applicant before the commission
        </p>
        Use the following to add new representative{" "}
        <CustomLink to="bd_legal_advisors" />
      </>
    ),
  },

  {
    type: fieldTypes.gridItem,
    xs: 12,
    style: { padding: "0px" },
  },
  {
    type: fieldTypes.bdPersonSelect,
    label: "Contact Person",
    name: "indiana_supplier_license.contact_person_id",
    helperText:
      "Select the primary Contact Person if this person is not your representative at the law firm you selected above",
  },
];

export default [
  {
    type: "form",
    formSchema,
    endpoint: "bdSupplement",
  },
];
