import { TextField } from "@material-ui/core";
import GridItem from "components/Common/Containers/Grid/GridItem";
import { PropTypes } from "prop-types";
import React from "react";
import NumberFormatCustom from "./NumberFormatCustom";
import GenericError from "../../GenericError";

export const NumberFormatTypes = {
  dollar: "dollar",
  percent: "percent",
  year: "year",
  none: "none",
};

const defaultTextFieldProps = {
  size: "small",
  margin: "dense",
  variant: "outlined",
  fullWidth: true,
};

const defaultNumberFormatProps = {
  allowNegative: false,
  thousandSeparator: true,
  decimalScale: 2,
  prefix: "",
  suffix: "",
};

function convertValue(value, returnType) {
  if (returnType === "decimal") {
    return Number.parseFloat(value);
  } else if (returnType === "integer") {
    return Number.parseInt(value);
  } else {
    return value;
  }
}

const getInputProps = (numberFormatProps, type) => {
  const inputProps = { ...defaultNumberFormatProps, ...numberFormatProps };

  if (type === NumberFormatTypes.dollar)
    return { ...inputProps, prefix: "$", suffix: "" };

  if (type === NumberFormatTypes.percent)
    return { ...inputProps, prefix: "", suffix: "%" };

  if (type === NumberFormatTypes.year)
    return {
      ...inputProps,
      allowNegative: false,
      format: "####",
      placeholder: "YYYY",
    };

  return inputProps;
};

export default function NumberField({
  textFieldProps,
  label,
  md = 3,
  errors,
  numberFormatProps,
  placeholder,
  shrink,
  disabled,
  onChange,
  returnType = "decimal",
  name,
  value,
  helperText,
  type = NumberFormatTypes.dollar,
  useGrid = true,
  touched,
  checkInputValue = () => null,
  format, // Created a duplicated of type due to name clash on form generator builder
  required,
}) {
  textFieldProps = { ...defaultTextFieldProps, ...textFieldProps };
  numberFormatProps = { ...defaultNumberFormatProps, ...numberFormatProps };

  const inputProps = React.useMemo(
    () => getInputProps(numberFormatProps, format || type),
    [numberFormatProps, type, format]
  );

  const handleValueChange = (values) => {
    onChange({
      target: {
        name,
        value:
          values.value === "" ? null : convertValue(values.value, returnType),
      },
    });
  };

  const handleIsAllowed = (values) => {
    checkInputValue(values);
    return true;
  };

  const textField = (
    <>
      <TextField
        error={errors}
        fullWidth
        {...(shrink && { InputLabelProps: { shrink: true } })}
        placeholder={placeholder}
        disabled={disabled}
        {...textFieldProps}
        helperText={helperText}
        value={value}
        label={label}
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
        inputProps={{
          ...inputProps,
          handleValueChange,
          handleIsAllowed,
        }}
        required={required}
      />
      <GenericError name={name} errors={errors} touched={touched} />
    </>
  );

  return <>{useGrid ? <GridItem md={md}>{textField}</GridItem> : textField}</>;
}

NumberField.propTypes = {
  checkInputValue: PropTypes.func,
  disabled: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  helperText: PropTypes.string,
  label: PropTypes.string,
  md: PropTypes.number,
  name: PropTypes.string.isRequired,
  numberFormatProps: PropTypes.object,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  returnType: PropTypes.oneOf(["decimal", "integer"]),
  shrink: PropTypes.any,
  textFieldProps: PropTypes.object,
  touched: PropTypes.object,
  type: PropTypes.oneOf(["dollar", "percent", "year", "none"]),
  useGrid: PropTypes.bool,
  value: PropTypes.any,
  format: PropTypes.string,
  required: PropTypes.bool,
};
