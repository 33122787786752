import { Grid } from "@material-ui/core";
import {
  SectionSubHeader,
  SectionLabel,
} from "components/Common/LayoutComponents";
import FormikInlineTable from "components/Common/Tables/FormikInlineTable/FormikInlineTable";
import Tooltip from "components/Common/Tooltip";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import FormikSelect from "components/Formik/fields/FormikSelect";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { useMenuOptions } from "hooks/useMenuOptions";
import React from "react";
import {
  licenseActivityInitialValues,
  licenseActivityColumns,
  validationSchema,
} from "./config";
import BDBusinessFamilySelect from "components/Formik/formGroups/CrudSelect/selects/BDBusinessFamilySelect";
import BDLicenseAgencySelect from "components/Formik/formGroups/CrudSelect/selects/BDLicenseAgencySelect";

const LicenseActivityForm = () => {
  const { licenseActivityType } = useMenuOptions();
  return (
    <Grid container spacing={2}>
      <FormikDatePicker label="Condition Date" name="activity_date" size={6} />
      <FormikSelect
        label="Condition Type"
        name="activity_type"
        options={licenseActivityType}
        xs={6}
      />
      <FormikTextField
        label="Reason for Condition"
        name="description"
        helpertext="Reason for Condition"
        xs={12}
        multiline
        rows={3}
      />
    </Grid>
  );
};

export const LicensesForm = () => {
  const { licenseType, bdLicenseDispositionType } = useMenuOptions();

  return (
    <Grid container spacing={2}>
      <BDBusinessFamilySelect />

      <BDLicenseAgencySelect divider />

      <SectionSubHeader label="License Details" divider />
      <FormikTextField label="Type of License" name="license_type" xs={4} />
      <FormikSelect
        label="Industry"
        name="industry_type"
        options={licenseType}
        xs={4}
      />
      <FormikTextField label="License Number" name="license_number" xs={4} />
      <FormikTextField
        label="Application Number"
        name="application_number"
        xs={4}
      />

      <SectionSubHeader label="License Dates" divider />

      <FormikDatePicker
        tooltip={
          <Tooltip
            title={"Date the application was completely filed with the agency"}
          />
        }
        label="Application Date"
        name="application_date"
      />

      <FormikDatePicker
        label="Expiration Date"
        name="expiration_date"
        size={12}
      />

      <SectionSubHeader label="Approval Details" divider />
      <FormikSelect
        label="Initial Disposition"
        name="disposition"
        options={bdLicenseDispositionType}
        xs={4}
      />

      <FormikDatePicker
        label="Disposition Date"
        name="disposition_date"
        size={12}
      />
      <SectionLabel>
        <b>NOTE:</b> If the License was NOT approved leave blank and provide
        details in the License History below
      </SectionLabel>

      <SectionSubHeader label="License History" divider />
      <SectionLabel>
        If the license has ever been denied, suspend, revoked, withdrawn, or
        subject to any conditions provide details:
      </SectionLabel>
      <Grid item xs={12}>
        <FormikInlineTable
          name="bd_license_activity"
          columns={licenseActivityColumns}
          addButtonText={"Add License History"}
          defaultInitialValues={licenseActivityInitialValues}
          form={<LicenseActivityForm />}
          dialogTitle="License History"
          validationSchema={validationSchema}
        />
      </Grid>
      <SectionSubHeader label="License Copy" divider />
      <SectionLabel>Attach a copy of your license</SectionLabel>
      <FormikFileDropzone
        description="License Copy"
        name="bd_license_attachment_group_id"
      />
    </Grid>
  );
};
