import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { Divider } from "components/Common/LayoutComponents/index";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import {
  createUILabel,
  selectEndpoint,
  columns,
  defaultInitialValues,
  tableQuestions,
  nonRequired,
  endpoint,
} from "./config.js";
import FormikAsyncSelect from "components/Formik/fields/FormikAsyncSelect";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import BDPartySelect from "components/Formik/formGroups/CrudSelect/selects/BDPartySelect.jsx";

const ShareholdersForm = () => {
  return (
    <Grid container spacing={2}>
      <BDPartySelect label="Shareholder" header="Shareholder" />

      <SectionSubHeader label="Share Structure" />
      <Grid item xs={6}>
        <FormikAsyncSelect
          name="bd_security_structure_id"
          endpoint={selectEndpoint}
          label="Share Structure"
          getOptionLabel={(option) => createUILabel(option)}
          getOptionValue={({ id }) => id}
          xs={12}
        />
        <Link component={RouterLink} to="bd_share_structure">
          Add Share Structure
        </Link>
      </Grid>

      <Divider />
      <SectionSubHeader label="Details" />
      <FormikDatePicker name="acquisition_date" />

      <FormikNumberField
        label="Number of Shares Held"
        name="shares_held_count"
        type="none"
        xs={4}
      />
      <FormikNumberField
        type="percent"
        label="Percent of Stock Held"
        name="stock_held_percent"
        xs={4}
      />
      <FormikNumberField
        type="percent"
        label="Percent of Outstanding Stock"
        name="outstanding_stock_percent"
        helperText="If Greater then 5%"
        xs={4}
      />
    </Grid>
  );
};

const Shareholders = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint,
    nonRequired,
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <ShareholdersForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
    </FormSectionProvider>
  );
};

export default Shareholders;
