import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import React from "react";
import { LicenseProgress } from "components/Pages/LicensingControl/components/DetailsDialog/Modal.styles.js";
import {
  formatDate,
  getDaysFromNow,
  getProgressValue,
  checkIfPastDate,
} from "components/Pages/LicensingControl/helpers.js";
import { EditableTransitionCard } from "./EditableTransitionCard";
import { ErrorChip } from "../../../Dashboard/widgets/AttentionNeeded/ExpiringDocuments.styles";

export const LicenseItem = ({
  licenseType,
  dispositionDate,
  expirationDate,
  handleEdit,
  handleDelete,
  agencyName,
}) => {
  const progressValue = React.useMemo(
    () => getProgressValue(dispositionDate, expirationDate),
    [dispositionDate, expirationDate]
  );

  checkIfPastDate(expirationDate);

  return (
    <EditableTransitionCard handleEdit={handleEdit} handleDelete={handleDelete}>
      <Box pb={1} display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h3">
            <Box fontWeight="500">{licenseType}</Box>
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {`Agency: ${agencyName}`}
          </Typography>
        </Box>
        <Typography variant="subtitle2">
          <Box fontWeight="fontWeightMedium">
            {checkIfPastDate(expirationDate) ? (
              <ErrorChip label="Expired" color="error" />
            ) : (
              getDaysFromNow(expirationDate)
            )}
          </Box>
        </Typography>
      </Box>
      <LicenseProgress variant="determinate" value={progressValue} />
      <Box pt={0.5} display="flex" justifyContent="space-between">
        <Typography variant="subtitle2">
          <Box lineHeight="normal">
            Issued <br /> {formatDate(dispositionDate)}
          </Box>
        </Typography>
        <Typography variant="subtitle2" align="right">
          <Box lineHeight="normal">
            Expires <br /> {formatDate(expirationDate)}
          </Box>
        </Typography>
      </Box>
    </EditableTransitionCard>
  );
};

LicenseItem.propTypes = {
  agencyName: PropTypes.string,
  dispositionDate: PropTypes.string,
  expirationDate: PropTypes.string,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  licenseType: PropTypes.string,
};
