import { Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents/index.js";
import { NumberFormatTypes } from "components/Common/Inputs/NumberField";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikGoogleLookup from "components/Formik/fields/FormikGoogleLookup.jsx";
import FormikNumberField from "components/Formik/fields/FormikNumberField.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import PDRelativeSelect from "components/Formik/formGroups/CrudSelect/selects/PDRelativeSelect.jsx";
import { FormikAddressFields } from "components/Formik/formGroups/FormikAddressFields.jsx";
import YesNo from "components/Formik/formGroups/YesNo.jsx";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import ColumnSum from "components/Layout/SectionsLayout/ColumnSum.jsx";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import useForm from "hooks/useForm.js";
import React from "react";
import {
  columns,
  dataHookProps,
  modalTitle,
  tableQuestions,
} from "./config.js";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <PDRelativeSelect
        label="Spouse or Child (If Applicable)"
        header="Select if held by Spouse or Dependent Child"
      />

      <FormikTextField
        header="Asset Details"
        divider
        label="Type of Asset"
        name="asset_type_other"
        xs={4}
      />
      <FormikTextField label="Nature of Asset" name="nature" xs={6} />

      <SectionSubHeader divider>Asset Location</SectionSubHeader>

      <FormikGoogleLookup
        fields={{
          address: "asset_address",
        }}
      />
      <FormikAddressFields addressPrefix="asset_address." />

      <YesNo label="Is the asset insured?" name="is_insured" xs={12} />
      <FormikDatePicker
        header="Acquisition"
        divider
        label="Acquisition Date"
        name="acquisition_date"
        xs={4}
      />
      <FormikNumberField label="Cost" name="cost" xs={4} />
      <FormikNumberField
        label="% Ownership"
        name="ownership_percent"
        xs={4}
        helperText="% Ownership of Interest"
        type={NumberFormatTypes.percent}
      />
      <FormikDatePicker
        header="Valuation"
        divider
        label="Valuation Date"
        name="valuation_date"
        xs={4}
      />
      <FormikNumberField
        label="Current Market Value"
        name="market_value"
        xs={4}
      />
      <FormikTextField
        label="Valuation Method"
        name="valuation_method"
        xs={4}
      />
    </Grid>
  );
};

const OtherAssets = ({ name }) => {
  const formData = useTabularData(dataHookProps);
  const otherAssetsQuestions = useForm({
    defaultInitialValues: {
      furniture_cost: null,
      furniture_value: null,
    },
    endpoint: "other_assets_personalDisclosure",
  });

  return (
    <FormSectionWrapper
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
      title={name}
    >
      <FormContextProvider
        headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
        formData={otherAssetsQuestions}
      >
        <EnhancedFormikPage title={""}>
          <Grid container spacing={2}>
            <FormikNumberField
              label="Furniture & Clothing"
              name="furniture_cost"
              xs={4}
              helperText="Cost or Purchase Amount"
            />
            <FormikNumberField
              label="Furniture & Clothing"
              name="furniture_value"
              xs={4}
              helperText="Current Estimated value"
            />
          </Grid>
        </EnhancedFormikPage>
      </FormContextProvider>
      <FormContextProvider formData={formData}>
        <EnhancedFormikDialog title={modalTitle}>
          <Form />
        </EnhancedFormikDialog>
        <EnhancedActionsTable
          footer={
            <ColumnSum
              name="market_value"
              data={formData.data}
              label="Current Market Value"
            />
          }
          columns={columns}
          addButtonText="Add Record"
        />
      </FormContextProvider>
    </FormSectionWrapper>
  );
};

export default OtherAssets;
