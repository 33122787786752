import { fieldTypes } from "componentGenerators/formGenerator/config";

const bd_business_entity_id = Number(
  localStorage.getItem("bd_business_entity_id")
);

export const infoAlerts = [
  {
    body: `Has the Applicant sustained a loss where a significant insurance payment was received?`,
  },
];

export const modifySubmissionValues = (submittedValues) => {
  return {
    ...submittedValues,
    bd_business_entity_id,
  };
};

export const columns = [
  {
    field: "claim_date",
  },
  {
    field: "nature_of_claim",
    format: "startCase",
  },
  {
    title: "Carrier Name",
    field: "carrier_bd_business_entity.business_name",
  },
  {
    title: "Amount",
    field: "amount_original",
    format: "currency",
  },
];

export const formSchema = [
  {
    type: fieldTypes.bdFinancialSelect,
    name: "carrier_bd_business_entity_id",
    label: "Carrier Name",
    header: "Carrier Name",
    required: true,
  },
  { type: fieldTypes.subHeader, label: "Details" },
  {
    type: fieldTypes.date,
    label: "Date of Claim",
    name: "claim_date",
  },
  {
    type: fieldTypes.text,
    label: "Nature of Claim",
    name: "nature_of_claim",
    xs: 8,
  },
  {
    type: fieldTypes.number,
    label: "Original Amount",
    name: "amount_original",
  },
  {
    type: fieldTypes.text,
    label: "Outcome",
    name: "outcome",
    xs: 8,
  },
  { type: fieldTypes.subHeader, label: "Upload Supporting Documents" },
  {
    type: fieldTypes.dropZone,
    description: "BD Insurance Claims",
    label: "Upload Supporting Documents",
    name: "attachment_group_id",
  },
];

export const tableQuestions = null;

export const endpoint = "bdInsuranceClaim";

export const nonRequired = ["amount_original"];

export const defaultInitialValues = {
  carrier_bd_business_entity_id: null,
  claim_date: null,
  nature_of_claim: "",
  outcome: "",
  currency_type: "",
  amount_original: null,
  exchange_rate: null,
  amount_usd: null,
  attachment_group_id: null,
};
