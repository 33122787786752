import { Box, TableBody } from "@material-ui/core";
import { Row } from "components/Common/Tables/Common/components/Row";
import useTable from "hooks/useTable";
import React from "react";
import styled from "styled-components";
import { CustomTableHead } from "../Common/components/CustomTableHead";
import { TableContainer } from "../Common/styles";

const StyledBox = styled(Box)`
  overflow: auto;
`;

const BasicTable = ({ columns, records, requiredValues, subheader = null }) => {
  const hasRecords = Boolean(records.length);
  const { tableHeadProps, getSortedRecords } = useTable({
    records,
    requiredValues,
  });
  const sortedRecords = getSortedRecords();

  return (
    <Box>
      {subheader && (
        <Box pt={2} pl={2} pr={2}>
          {subheader}
        </Box>
      )}

      <StyledBox component="div" overflow="hidden">
        <TableContainer>
          <CustomTableHead
            {...tableHeadProps}
            hasActions={false}
            columns={columns}
          />

          <TableBody>
            {hasRecords &&
              sortedRecords.map(({ missingRequiredItems, ...record }) => (
                <Row
                  missingRequiredItems={missingRequiredItems}
                  record={record}
                  key={`${record.created_at}-${record.id}`} //need to update key here
                  textStyle={{ padding: "16px 8px" }}
                  columns={columns}
                  hasActions={false}
                />
              ))}
          </TableBody>
        </TableContainer>
      </StyledBox>
    </Box>
  );
};

export default BasicTable;
