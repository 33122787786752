import _ from "lodash";

// verifies if routeName is the one active (in browser input)
export const getActiveRoute = (routeName) => {
  return window.location.href.indexOf(routeName) > -1 ? "active" : "";
};

export const getNewRoutes = (routes, selected) => {
  const filteredRoutes = routes.filter((route) => route.name === selected);
  if (_.size(filteredRoutes) && _.size(filteredRoutes[0].views)) {
    return filteredRoutes[0].views;
  } else return filteredRoutes;
};
