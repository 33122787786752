import { Box, Paper } from "@material-ui/core";
import React from "react";
import { endpoint, columns } from "./config.js";
import useFetch from "queryHooks/useFetch.js";
import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import DisplayTable from "components/Common/Tables/DisplayTable/DisplayTable.jsx";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent.jsx";

const tableQuestion = (
  <>
    all persons or entities affiliated with your business entity corresponding
    to the listbelow. If any of the roles or responsibilities listed below as
    applicable to your business entity areneither part of your business entity
    nor applicable, please indicate the reason on the attachment.
    <p>
      <strong>Note: </strong>If you are applying for a sports gaming operator
      license or a supplierregistration, the multijurisdictional personal
      history disclosure form andtennessee education lottery supplemental
      personal history disclosure form(together, “application for suitability”)
      must be completed by every natural personnoted below.
    </p>
    <p>
      <strong>Additional Note: </strong>The tennessee lottery education
      corporation and its board haveabsolute discretion to require an
      application for suitability for any natural personor completion of a
      business entity disclosure form for associates, affiliates,employees,
      contractors, or any other party connected with the applicant’s
      sportsgaming or supplier business.
    </p>
    <ol type="A">
      <li>
        All persons who have signed or will sign any agreement related to sports
        gaming conducted in the State of Tennessee on behalf of applicant.
      </li>
      <li>
        All officers (appointed by the Board of Directors) of the business
        entity, including any holding orintermediary company. For
        publicly-traded business entities, a list of all officers shall be
        provided. The Chief Executive Officer or equivalent; Chief Financial
        Officer of equivalent; and any officer, employee, or other person acting
        with authority on behalf of the company responsible for supervising
        sports gaming conducted in the State of Tennessee (“key personnel”)
        shall complete an application for suitability.
      </li>
      <li>
        All directors, limited liability company managers, general partners, or
        trustees of the businessentity. For publicly-traded business entities, a
        list of all directors shall be provided. The Chair ofthe Board of
        Directors and any director responsible for sports gaming conducted in
        the State ofTennessee shall complete an application for suitability.
      </li>
      <li>
        he sole proprietor, if the business entity is a sole proprietorship.
      </li>
      <li>
        Each natural person who directly holds any beneficial ownership interest
        with voting rights offive percent or more must complete an application
        for suitability.
        <p>
          For all business entities that are not publicly-traded business
          entities, a list of all voting and non-voting owners, regardless of
          percentage of ownership interest held, must be provided, includingfor
          each holding or intermediary business entity. Each natural person who
          indirectly holdsbeneficial ownership with voting rights of five
          percent or more of the business entity may berequired file an
          application for suitability.
        </p>
        <p>
          If a business entity is publicly traded, a list of all voting and
          non-voting owners with ownershipof five percent or more must be
          provided. Each natural person who indirectly holds beneficialownership
          with voting rights of five percent or more may be required to complete
          an application for suitability.
        </p>
        <p>
          For any institutional investor that holds direct or indirect
          beneficial ownership of five percent ormore in a business entity, the
          name, address, and date of birth for the representative of
          theinstitutional investor responsible for managing the ownership must
          be provided. An institutionalinvestor may hold up to 25% of direct or
          indirect beneficial ownership of any business entityrelating to sports
          gaming so long as it is held for investment purposes only and there is
          noinvolvement with management of the business entity or sports gaming
          business.
        </p>
      </li>
    </ol>
  </>
);

export default function TennesseeBEDQualifiers({ name }) {
  const { data, status } = useFetch(endpoint);

  return (
    <FormSectionWrapper title={name}>
      <Paper>
        <Box mb={1}>
          <LoadingComponent status={status}>
            <DisplayTable
              records={data}
              linkTo={["bd_qualifiers"]}
              columns={columns}
              dependsOnQueryCount={{
                question: tableQuestion,
              }}
            />
          </LoadingComponent>
        </Box>
      </Paper>
    </FormSectionWrapper>
  );
}
