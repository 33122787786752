import { Collapse, Container, Grid, Typography } from "@material-ui/core";
import useTableData from "hooks/useTableData";
import React from "react";
import { AnnualIncomeCategory } from "./AnnualIncomeCategory";
import { getFormattedTableData, calculateAnnualTotal } from "./helpers";
import { SubHeader } from "./Subheader";
import useFetchAnnualIncome from "./useFetchAnnualIncome";
import { NumberField } from "components/Common/InputElements";
import DropDown from "components/Common/Inputs/DropDown/DropDown";
import { categoryMenuOptions, informationAlert } from "./config";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";

const standardColumns = [
  { label: "Source", name: "source" },
  {
    label: "Amount",
    name: "amount",
    type: "number",
    editComponent: (
      <NumberField
        name=""
        useGrid={false}
        textFieldProps={{ variant: "standard", style: { width: 130 } }}
      />
    ),
  },
];

const otherColumns = [
  ...standardColumns.slice(0, 1),
  {
    label: "Category",
    name: "category",
    editComponent: <DropDown menuItems={categoryMenuOptions} />,
    type: "startCase",
  },
  ...standardColumns.slice(1),
];

const AnnualIncome = ({ jurisdiction, endpoint = "" }) => {
  const [year, setYear] = React.useState(null);
  const [totals, setTotals] = React.useState({
    salary: 0,
    interest: 0,
    dividend: 0,
    other: 0,
  });

  const handleYearSelect = (event) => setYear(event.target.value);
  const { state: data, loading } = useTableData(
    `${endpoint}incomeStatement?year=${year}`,
    false
  );
  const formattedData = React.useMemo(() => getFormattedTableData(data), [
    data,
  ]);

  const yearlyTotal = React.useMemo(() => calculateAnnualTotal(totals), [
    totals,
  ]);

  const handleSubmit = useFetchAnnualIncome({ year });

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1">Annual Income</Typography>
        </Grid>
        {informationAlert[jurisdiction] && (
          <Grid item xs={12}>
            <InfoAlert>{informationAlert[jurisdiction]}</InfoAlert>
          </Grid>
        )}
        <Grid item xs={12}>
          <SubHeader
            total={yearlyTotal}
            handleYearSelect={handleYearSelect}
            year={year}
            loading={loading}
          />
        </Grid>
        <Grid item xs={12}>
          <Collapse in={year && !loading} unmountOnExit>
            <Grid container spacing={3}>
              <Grid item xs>
                <Grid container spacing={2}>
                  {formattedData.map((props) => {
                    const { id } = props;
                    return (
                      <Grid item xs={12} lg={6} key={id}>
                        <AnnualIncomeCategory
                          total={totals[id]}
                          setTotals={setTotals}
                          year={year}
                          handleSubmit={handleSubmit}
                          columns={
                            id === "other" ? otherColumns : standardColumns
                          }
                          {...props}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AnnualIncome;
