import { getFormComponents } from "componentGenerators/helpers";
import MichiganDisclosureQuestions from "components/Pages/Supplemental/PD/Michigan/MichiganDisclosureQuestions/MichiganDisclosureQuestions";
import MichiganContactInformation from "components/Pages/Supplemental/PD/Michigan/MichiganContactInformation/MichiganContactInformation";
import MichiganFinancials from "components/Pages/Supplemental/PD/Michigan/MichiganFinancials/MichiganFinancials";
import MichiganPoliticalContributions from "components/Pages/Supplemental/PD/Michigan/MichiganPoliticalContributions/MichiganPoliticalContributions";
import MichiganCriminalHistory from "components/Pages/Supplemental/PD/Michigan/MichiganCriminalHistory/MichiganCriminalHistory";
import MichiganGamblingProblems from "components/Pages/Supplemental/PD/Michigan/MichiganGamblingProblems/MichiganGamblingProblems";
import MichiganAttachments from "components/Pages/Supplemental/PD/Michigan/MichiganAttachments/MichiganAttachments";

const michigan_pages = {
  michigan_disclosure_questions: MichiganDisclosureQuestions,
  michigan_contact_information: MichiganContactInformation,
  michigan_financials: MichiganFinancials,
  michigan_political_contributions: MichiganPoliticalContributions,
  michigan_criminal_history: MichiganCriminalHistory,
  michigan_gambling_problems: MichiganGamblingProblems,
  ...getFormComponents({ michigan_attachments: MichiganAttachments }),
};

export default michigan_pages;
