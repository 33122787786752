import { Grid } from "@material-ui/core";
import { FormikPage } from "components/Formik/formContainers/FormikPage";
import {
  SectionLabel,
  SectionSubHeader,
  Divider,
} from "components/Common/LayoutComponents/index.js";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React, { useState } from "react";
import { FormikDatePicker, FormikTextField } from "components/Formik/fields";
import DropZoneEmail from "components/Common/DropZoneEmail/DropZoneEmail";
import usePost from "queryHooks/usePost";
import { useSnackbar } from "notistack";
import SendButton from "components/Common/CustomButtons/SendButton/SendButton";

const Form = ({ files, setFiles }) => {
  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Details" />
      <Divider />
      <SectionLabel label="Provide us with the name(s) of application(s), the jurisdiction, date you required it in our system and attach a copy if readily available" />
      <FormikTextField label="Jurisdiction" name="jurisdiction" xs={4} />
      <FormikTextField label="Application Name(s)" name="application" xs={8} />
      <FormikDatePicker label="date required" name="required_date" xs={12} />
      <DropZoneEmail name="attachments" files={files} setFiles={setFiles} />
    </Grid>
  );
};

const RequestApplications = ({ title }) => {
  const [files, setFiles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: postMutation } = usePost(
    "common/support/requestApplication",
    "common/support/requestApplication"
  );

  const handleSubmit = (
    { attachments: _attachments, ...data },
    { resetForm, setSubmitting }
  ) => {
    postMutation(
      {
        attachments: files.map((val) => ({ name: val.name, file: val.file })),
        ...data,
      },
      {
        onSuccess: () => {
          setFiles([]);
          enqueueSnackbar("Request Sent", {
            variant: "success",
          });
          setSubmitting(false);
          resetForm();
        },
        onError: () => {
          enqueueSnackbar("Error", {
            variant: "error",
          });
          setSubmitting(false);
        },
      }
    );
  };

  return (
    <FormSectionProvider title={title}>
      <FormikPage
        onSubmit={handleSubmit}
        initialValues={{
          jurisdiction: "",
          application: "",
          required_date: null,
          attachments: null,
        }}
        customButton={(isSubmitting) => <SendButton disabled={isSubmitting} />}
      >
        <Form files={files} setFiles={setFiles} />
      </FormikPage>
    </FormSectionProvider>
  );
};

export default RequestApplications;
