import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikSelect from "components/Formik/fields/FormikSelect";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useForm from "hooks/useForm.js";
import React from "react";

const PersonalDetailsForm = ({ innerRef }) => {
  const formData = useForm({
    defaultInitialValues: {
      title: "",
      first_name: "",
      last_name: "",
      middle_name: "",
      suffix_name: "",
      maiden_name: "",
      home_phone: "",
      business_phone: "",
      fax_phone: "",
      mobile_phone: "",
      ssn: "",
      no_ssn_explain: "",
      date_of_birth: null,
      birth_city: "",
      birth_state: "",
      birth_county: "",
      birth_country: null,
    },
    noValidate: true,
    endpoint: "personal_details_employee",
  });

  const { listOfCountries } = useMenuOptions();

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage
        title={"Citizenship & Residence"}
        innerRef={innerRef}
        showSaveButton={false}
      >
        <Grid container spacing={2}>
          <SectionSubHeader divider>Name</SectionSubHeader>
          <FormikTextField label="First Name" name="first_name" xs={6} />
          <FormikTextField label="Last Name" name="last_name" xs={6} />
          <FormikTextField label="Middle Name" name="middle_name" xs={3} />
          <FormikTextField label="Maiden Name" name="maiden_name" xs={3} />
          <FormikTextField label="Suffix" name="suffix_name" xs={3} />
          <FormikTextField label="Title" name="title" xs={3} />

          <SectionSubHeader divider>Phone Numbers</SectionSubHeader>
          <FormikTextField label="Home Phone" name="home_phone" xs={4} />
          <FormikTextField
            label="Business Phone"
            name="business_phone"
            xs={4}
          />
          <FormikTextField label="Fax Number" name="fax_phone" xs={4} />
          <FormikTextField label="Mobile Number" name="mobile_phone" xs={4} />

          <SectionSubHeader divider>Social Security </SectionSubHeader>
          <FormikTextField label="Social Security #" name="ssn" xs={6} />
          <FormikTextField
            label="If you do not have a SSN provide an explanation"
            name="no_ssn_explain"
            xs={12}
            multiline
            rows={2}
          />

          <SectionSubHeader divider>Birth Details</SectionSubHeader>
          <FormikDatePicker label="Date of Birth" name="date_of_birth" xs={4} />
          <FormikTextField label="City of Birth" name="birth_city" xs={4} />
          <FormikTextField label="State of Birth" name="birth_state" xs={4} />
          <FormikTextField label="County of Birth" name="birth_county" xs={4} />
          <FormikSelect
            label="Country of Birth"
            name="birth_country"
            options={listOfCountries}
            xs={4}
          />
        </Grid>
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

export default PersonalDetailsForm;
