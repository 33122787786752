import { formats } from "components/Common/Tables/Common/helpers";
import * as Yup from "yup";
import { nullableString } from "../../../helpers/validationSchemaGenerator";

export const businessDisclosureColumns = [
  {
    field: "business_name",
  },
  {
    title: "Relationship Type",
    field: "business_relationship_type",
    format: formats.startCase,
  },
];

export const collaboratorsColumns = [
  {
    field: "first_name",
  },
  {
    field: "last_name",
  },
  {
    field: "email",
  },
];

export const businessDisclosureValidationSchema = Yup.object().shape({
  business_name: nullableString,
  business_relationship_type: nullableString,
});

export const collaboratorsValidationSchema = Yup.object().shape({
  account_id: nullableString,
});
