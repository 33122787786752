import React from "react";
import * as Sentry from "@sentry/react";
import { Grid, Box, Typography } from "@material-ui/core";
import Center from "components/flex/Center";
import Link from "@material-ui/core/Link";

const ErrorBox = (
  <Box
    style={{
      fontWeight: "bold",
    }}
  >
    An Error Has Occurred.
  </Box>
);
const ErrorWithDashboardLink = () => (
  <Center pt={5}>
    <div>
      <Typography gutterBottom>An Error Has Occurred.</Typography>
      <Link href="/app/dashboard" variant="body2" underline="always">
        Return to the Dashboard
      </Link>
    </div>
  </Center>
);

function FallbackComponent({ useGrid }) {
  return useGrid ? <Grid item>{ErrorBox}</Grid> : <>{ErrorBox}</>;
}

export default function SentryErrorBoundary({
  useGrid,
  children,
  hasDashboardLink,
  ...props
}) {
  return (
    <Sentry.ErrorBoundary
      fallback={
        hasDashboardLink ? (
          <ErrorWithDashboardLink />
        ) : (
          <FallbackComponent useGrid={useGrid} />
        )
      }
      {...props}
    >
      {children || null}
    </Sentry.ErrorBoundary>
  );
}
