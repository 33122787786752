import PropTypes from "prop-types";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  CardHeader,
  Divider,
  Avatar,
} from "@material-ui/core";
import React from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { lighten } from "@material-ui/core/styles";

const StyledAvatar = styled(Avatar)`
  height: 45px;
  width: 45px;
  background-color: ${({ theme }) => theme.palette.primary.light};
`;

const useStyles = makeStyles(({ palette }) => ({
  selected: {
    backgroundColor: `${lighten(palette.primary.main, 0.9)} !important`,
  },
}));

const DocumentCategoryListItem = ({
  id,
  label,
  attachmentCount,
  selectDocumentCategory,
  selected,
}) => {
  const classes = useStyles();
  return (
    <ListItem
      button
      key={id}
      value={id}
      onClick={() => selectDocumentCategory(label)}
      selected={selected}
      dense
      classes={classes}
    >
      <ListItemText
        primaryTypographyProps={{ noWrap: true }}
        primary={label}
        secondary={`${attachmentCount} files`}
      />
      {selected ? (
        <ListItemSecondaryAction>
          <ArrowForwardIosIcon
            fontSize="inherit"
            style={{ fontSize: "15px" }}
          />
        </ListItemSecondaryAction>
      ) : null}
    </ListItem>
  );
};

DocumentCategoryListItem.propTypes = {
  attachmentCount: PropTypes.number,
  id: PropTypes.string,
  label: PropTypes.string,
  selectDocumentCategory: PropTypes.func,
  selected: PropTypes.bool,
};

export const DocumentCategoriesList = ({
  documentsData,
  selectDocumentCategory,
  currentDocumentCategory,
}) => {
  return (
    <Box display="flex" flexDirection="column" width={1}>
      <Box height={"120px"} display="flex" pl={1} alignItems="center">
        <CardHeader
          avatar={
            <StyledAvatar>
              <InsertDriveFileIcon
                fontSize="inherit"
                style={{ fontSize: "30px" }}
              />
            </StyledAvatar>
          }
          title="Document Repository"
          titleTypographyProps={{
            color: "textSecondary",
            variant: "subtitle1",
          }}
        />
      </Box>
      <Divider />
      <List style={{ maxHeight: `${725}px`, overflow: "auto" }} dense>
        {documentsData.map(({ documents, label }) => (
          <DocumentCategoryListItem
            key={label}
            id={label} // ! id should be changed to a unique id coming from the server
            selectDocumentCategory={selectDocumentCategory}
            selected={currentDocumentCategory?.label === label}
            label={label}
            attachmentCount={documents.length || 0}
          />
        ))}
      </List>
    </Box>
  );
};

DocumentCategoriesList.propTypes = {
  attachmentData: PropTypes.array,
  currentDocumentCategory: PropTypes.object,
  selectDocumentCategory: PropTypes.func,
};
