import React from "react";

export const columns = [
  {
    field: "business_name",
  },
  {
    title: "Inc. Date",
    field: "incorporation_date",
  },
  {
    title: "Inc. Place",
    field: "incorporation_state",
  },

  {
    field: "form_of_business",
    format: "startCase",
  },
  {
    title: "Relationship",
    field: "business_relationship_type",
    format: "startCase",
  },

  {
    field: "incorporation_country",
  },
];

export const tableQuestions = (
  <>
    Central management of all Business Entities in your profile. The Business
    Entities in this section will be available to populate other records in
    various sections.
  </>
);

export const defaultInitialValues = {
  business_name: "",
  business_relationship_type: null,
  incorporation_date: null,
  to_date: null,
  is_current: false,
  incorporation_state: "",
  incorporation_country: null,
  form_of_business: null,
  employer_id_number: "",
  unique_entity_number: "",
  business_description: "",
  cessation_reason: "",
  bd_address: [],
  business_type_checkbox: {
    is_gaming: false,
    is_government: false,
    is_alcohol: false,
    is_license_agency: false,
    is_financial_institution: false,
    is_law_enforcement: false,
  },
};
