import React from "react";

export const columns = [
  {
    title: "Business Name",
    field: "business_name",
  },
  {
    title: "Formation",
    field: "incorporation_date",
    format: "date",
  },
  {
    title: "Relationship",
    format: "startCase",
    field: "business_relationship_type",
  },
  {
    title: "Form of Business",
    format: "startCase",
    field: "form_of_business",
  },
  {
    title: "Employer ID Number",
    field: "employer_id_number",
  },
];

export const tableQuestions = (
  <>
    Provide details of all companies which are related to the Applicant as
    intermediary, holding, subsidiary and parent companies.{" "}
    <strong>Include all former businesses</strong> which the Applicant or its
    related companies have engaged for the past ten (10) years.
  </>
);

export const defaultInitialValues = {
  business_name: "",
  business_relationship_type: null,
  form_of_business: null,
  incorporation_country: "",
  incorporation_date: null,
  is_current: false,
  employer_id_number: "",
  business_description: "",
  unique_entity_number: "",
  to_date: null,
  cessation_reason: "",
  incorporation_state: "",
  bd_address: [],
  business_type_checkbox: {
    is_gaming: false,
    is_government: false,
    is_alcohol: false,
    is_license_agency: false,
    is_financial_institution: false,
    is_law_enforcement: false,
  },
  is_public_company: null,
};

export const nonRequired = [
  "business_description",
  "unique_entity_number",
  "to_date",
  "cessation_reason",
  "incorporation_state",
];
