import React from "react";
import { formats } from "components/Common/Tables/Common/helpers";

export const columns = [
  {
    field: "from_date",
    format: formats.date,
  },
  {
    field: "to_date",
    format: formats.date,
  },
  {
    title: "Business Name",
    field: "pd_business_entity.business_name",
  },
  {
    title: "Ownership %",
    field: "ownership_percent",
    format: "percent",
  },
  {
    title: "Gaming",
    field: "pd_business_entity.business_type_checkbox.is_gaming",
    format: "boolean",
  },
];

export const tableQuestions = (
  <>
    List any group, firm, partnership, corporation or any other businesses in
    which you have held an ownership interest of 5% or more or have been a
    substantial creditor for the past twenty (20) years, or since the age of 18,
    whichever is less. (Do not include publicly traded corporations in which you
    owned stock.)
  </>
);

export const defaultInitialValues = {
  pd_business_entity_id: null,
  from_date: null,
  to_date: null,
  is_current: false,
  role_checkbox: null,
  ownership_percent: null,
  debt_owed: null,
  number_shares_owned: null,
  termination_of_involvement: "",
};
