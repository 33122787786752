import axios from "axios";
import { useMutation } from "react-query";

export function useInitiatePasswordReset() {
  const initiatePasswordReset = async (payload) => {
    const { data } = await axios.post(`/auth/forgetPassword/initiate`, payload);
    return data;
  };

  return useMutation(initiatePasswordReset);
}
