export const formActions = {
  RESET: "RESET",
  EDIT_RECORD: "EDIT_RECORD",
  ADD_RECORD: "ADD_RECORD",
};

export const formRecordReducer = (state, action) => {
  switch (action.type) {
    case formActions.RESET:
      return {
        formValues: action.formValues,
        isEditing: false,
        open: false,
      };
    case formActions.EDIT_RECORD:
      return {
        formValues: action.formValues,
        isEditing: true,
        open: true,
      };
    case formActions.ADD_RECORD:
      return {
        ...state,
        isEditing: false,
        open: true,
      };

    default:
      return state;
  }
};
