import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "associated business entity",
  },
  {
    type: fieldTypes.label,
    label: "Applicant this disclosure form relates to:",
  },
  {
    type: fieldTypes.pdBusinessSelect,
    label: "Associated Business Entity",
    name: "michigan_limited_pd.associated_business_entity_id",
    endpoint: "associated_business_entities_a_grouped",
  },
  {
    type: fieldTypes.subHeader,
    label: "Authorized Contact",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label:
      "Authorized person who will communicate with the Michigan Gaming Control Board:",
  },
  {
    type: fieldTypes.pdPersonSelect,
    label: "Authorized Contact",
    name: "michigan_limited_pd.contact_person_id",
    endpoint: "pdPerson",
  },
  {
    type: fieldTypes.text,
    label: "Contact's Title",
    name: "michigan_limited_pd.contact_person_title",
    xs: 4,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "michigan_limited_pd_supplement_disclosure_questions_pdSupplement",
};
