import { getFormComponents } from "componentGenerators/helpers";
import bd_south_dakota_bhd_supplement_application_information from "components/Pages/Supplemental/BD/SouthDakotaBHD/SouthDakotaBHDApplicationInformation/SouthDakotaBHDApplicationInformation";
import bd_south_dakota_bhd_supplement_attachments from "components/Pages/Supplemental/BD/SouthDakotaBHD/SouthDakotaBHDAttachments/SouthDakotaBHDAttachments";
import bd_south_dakota_bhd_supplement_business_ownership from "components/Pages/Supplemental/BD/SouthDakotaBHD/SouthDakotaBHDBusinessOwnership/SouthDakotaBHDBusinessOwnership";

export default {
  ...getFormComponents({
    bd_south_dakota_bhd_supplement_application_information,
    bd_south_dakota_bhd_supplement_attachments,
  }),
  bd_south_dakota_bhd_supplement_business_ownership,
};
