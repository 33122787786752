import React from "react";

export const columns = [
  {
    title: "Report Type",
    field: "report_type",
    format: "startCase",
  },
  {
    title: "Effective Year",
    field: "effective_year",
  },
  {
    title: "Has Attachments",
    field: "corporate_report_attachment_group_id",
    render: ({ corporate_report_attachment_group_id }) => {
      if (corporate_report_attachment_group_id) {
        return "Yes";
      }
      return "No";
    },
  },
];

export const tableQuestions = (
  <>
    Provide a copy of the audited and/or published financial statements,
    including notes to the accounts, any exceptions taken to such statements by
    the independent auditor retained by the Applicant and the management
    response thereto, and annual reports of the Applicant for the past 5 years.
    In addition, provide interim financials if current year audited statements
    are not available. If the Applicant normally does not have its financial
    statements audited, provide all unaudited financial statements prepared in
    the past 5 years. For the purposes of this question, an audited financial
    statement includes, but is not limited to, an income statement, balance
    sheet, statement of sources, and application of funds.
  </>
);

export const defaultInitialValues = {
  //bd_business_entity_id: null,
  report_type: "financial_statement",
  effective_year: null,
  corporate_report_attachment_group_id: null,
};

export const nonRequired = ["corporate_report_attachment_group_id"];
