import React from "react";
import PeopleIcon from "@material-ui/icons/People";
import BusinessIcon from "@material-ui/icons/Business";
import { AttentionNeeded } from "components/Pages/LicensingControl/components/AttentionNeeded";

import { ExpiringSoon } from "components/Pages/LicensingControl/components/ExpiringCard";
import { licensesQueryKey } from "../Businesses/config";
import {
  Box,
  Card,
  Container,
  Grid,
  Typography,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { splitLicensesInDays } from "components/Pages/LicensingControl/helpers";
import useFetch from "queryHooks/useFetch";
import Center from "components/flex/Center";
import { ApplicationAssignmentsWidget } from "../components/ApplicationAssignmentsWidget";
import { useLCApplicationAssignments } from "queryHooks/useApplicationAssignments";
import OpenModalButton from "components/Common/OpenModalButton";

const InfoCard = ({ icon, number1, text1, number2, text2 }) => (
  <Card
    style={{ height: "175px", width: "350px", marginBottom: "20px" }}
    variant="outlined"
  >
    <Box
      pt={2}
      pb={2}
      pl={5}
      style={{ height: "100%" }}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      {icon}
      <Box display="flex" pt={0.5} pr={2}>
        <Box mr={5}>
          <Typography variant="h1" gutterBottom>
            <Box fontWeight="600" fontStyle="italic" fontSize={30}>
              {number1}
            </Box>
          </Typography>
          <Typography variant="h4">
            <Box fontStyle="italic">{text1}</Box>
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box ml={5} width={"20px"}>
          <Typography variant="h1" gutterBottom>
            <Box fontWeight="600" fontStyle="italic" fontSize={30}>
              {number2}
            </Box>
          </Typography>
          <Typography variant="h4">
            <Box fontStyle="italic">{text2}</Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  </Card>
);

const Dashboard = () => {
  const { status, isSuccess, isLoading, data } = useFetch(
    [licensesQueryKey, "lc_licenses_expiring_six_month"],
    "licensing/views/lc_licenses_expiring_six_month?is_archived=false",
    { useFullEndpoint: false }
  );

  const { isSuccess: statsIsSuccess, data: statsData } = useFetch(
    "lc_business_person_license_count",
    "licensing/views/lc_business_person_license_count",
    { useFullEndpoint: false }
  );

  const { data: assignmentsData, status: assignmentsStatus } =
    useLCApplicationAssignments();

  const lcStats = statsIsSuccess && statsData[0];

  const history = useHistory();

  const handleClickLicenseRecord = (id, licenseItem) => {
    const page =
      licenseItem?.license_type_category === "personal"
        ? "lc_employees"
        : "lc_businesses";

    history.push({
      pathname: `/app/${page}`,
      search: `?selected=${id}`,
    });
  };

  const handleClickAssignmentRecord = (selectedAssignment) => {
    history.push({
      pathname: `/app/lc_application_assignments`,
      search: `?selected=${selectedAssignment.id}`,
    });
  };

  const linkToAssignmentsPage = () =>
    history.push(`/app/lc_application_assignments`);

  // expiringSoon: licenses expiring within the next 7 day + any expired licenses
  // notExpiringSoon: licenses expiring within 6 months
  const { expiringSoon, notExpiringSoon } = React.useMemo(
    () =>
      status === "success"
        ? splitLicensesInDays({ records: data, days: 7 })
        : { expiringSoon: [], notExpiringSoon: [] },
    [status, data]
  );

  return (
    <Container maxWidth="md">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h2">License Control</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-around">
            {statsIsSuccess ? (
              <>
                <InfoCard
                  icon={<PeopleIcon fontSize="large" color="primary" />}
                  number1={lcStats.lc_person_count}
                  text1={"Employees"}
                  number2={lcStats.lc_person_license_count}
                  text2={"Employee Licenses"}
                />
                <InfoCard
                  icon={<BusinessIcon fontSize="large" color="primary" />}
                  number1={lcStats.lc_business_count}
                  text1={"Businesses"}
                  number2={lcStats.lc_business_license_count}
                  text2={"Business Licenses"}
                />
              </>
            ) : (
              <Center>
                <CircularProgress />
              </Center>
            )}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <ApplicationAssignmentsWidget
            status={assignmentsStatus}
            handleClickRecord={handleClickAssignmentRecord}
            data={assignmentsData}
            label="Application Assignments"
            headerAction={
              <OpenModalButton
                variant="contained"
                color="primary"
                text="Manage"
                onClick={linkToAssignmentsPage}
              />
            }
          />
        </Grid>
        <Grid item xs={6}>
          <AttentionNeeded
            status={status}
            handleClickRecord={handleClickLicenseRecord}
            data={expiringSoon}
            label="Licenses Needing Attention"
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt={4}>
            <ExpiringSoon
              handleClickRecord={handleClickLicenseRecord}
              licensesData={notExpiringSoon}
              isSuccess={isSuccess}
              isLoading={isLoading}
              label="Upcoming Expiring Licenses (Within 6 Months)"
              keyLabel={"dashboard"}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
