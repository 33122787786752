import React from "react";
import { Box } from "@material-ui/core";
import CopyText from "components/DevTools/PdfTools/Components/Navbar/CopyText";
import { usePdfState } from "components/DevTools/PdfTools/Context/PdfToolsProvider.jsx";

export default function TagCreationDisplay() {
  const { timeFilter, amountFilter, token, selected, column } = usePdfState();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        component="div"
        style={{
          padding: "10px 0px 20px 25px",
          fontWeight: "bold",
          marginRight: "10px",
        }}
        fontSize={16}
        color="primary.main"
      >
        Tag creation station:
      </Box>
      <CopyText
        text={
          !selected ? (
            <></>
          ) : (
            `${selected}${timeFilter}${amountFilter}.${column}${token}`
          )
        }
      >
        <Box
          component="div"
          style={{
            padding: "10px 0px 20px 25px",
            fontWeight: "bold",
            marginRight: "30px",
            cursor: "pointer",
          }}
          fontSize={16}
          color="info.main"
        >
          {!selected ? (
            <></>
          ) : (
            `${selected}${timeFilter}${amountFilter}.${column}${token}`
          )}
        </Box>
      </CopyText>
    </div>
  );
}
