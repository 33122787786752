import React from "react";
import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import MultiFormWrapper from "components/Layout/SectionsLayout/MultiFormWrapper";
import ApplicantBackgroundForm from "./ApplicantBackgroundForm"
import ApplicantInformationForm from "./ApplicantInformationForm"

const PennEEAApplicantInformation = ({ name }) => {

  return (
    <FormSectionWrapper title={"Pennsylvania EEA" + name}>
      <MultiFormWrapper>
        <ApplicantInformationForm />
        <ApplicantBackgroundForm />
      </MultiFormWrapper>
    </FormSectionWrapper>
  );
};

export default PennEEAApplicantInformation;
