import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import MultiFormWrapper from "components/Layout/SectionsLayout/MultiFormWrapper";
import NewMexicoTaxLastReturnForm from "./NewMexicoTaxLastReturnForm/NewMexicoTaxLastReturnForm"
import NewMexicoTaxReviewForm from "./NewMexicoTaxReviewForm/NewMexicoTaxReviewForm";

const NewMexicoTaxInformation = ({ name }) => {
  return (
    <FormSectionWrapper title={name}>
      <MultiFormWrapper>
        <NewMexicoTaxLastReturnForm />
        <NewMexicoTaxReviewForm/>
      </MultiFormWrapper>
    </FormSectionWrapper>
  );
};

export default NewMexicoTaxInformation;