import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {
  assetsTableInfo,
  contingentTableInfo,
  liabilitiesTableInfo,
} from "./config";
import { a11yProps, getData } from "./helpers";
import { useStyles } from "./styles";
import {
  AssetsTableHeader,
  BottomTable,
  LiabilitiesTableHeader,
  Table,
} from "./TableComponents";
import useGet from "hooks/useGet";
import { CircularProgress } from "@material-ui/core";
import Center from "components/flex/Center";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box pb={2}>{children}</Box>}
    </Typography>
  );
}

const useGetNetworthViewData = (endpoint, attributeName) => {
  const { data, isSuccess } = useGet(endpoint);
  return [data?.[0]?.[attributeName], isSuccess];
};

const useNetWorthData = () => {
  const [assetsTotal, assetsTotalIsSuccess] = useGetNetworthViewData(
    "net_worth_statements_total_assets",
    "assets_total"
  );
  const [liabilitiesTotal, liabilitiesTotalIsSuccess] = useGetNetworthViewData(
    "net_worth_statements_total_liabilities",
    "liabilities_total"
  );
  const [
    assetsAtDateAcquiredTotal,
    assetsAtDateAcquiredTotalIsSuccess,
  ] = useGetNetworthViewData(
    "net_worth_statements_total_assets_at_date_acquired",
    "assets_total"
  );
  const [
    liabilitiesAtDateAcquiredTotal,
    liabilitiesAtDateAcquiredTotalIsSuccess,
  ] = useGetNetworthViewData(
    "net_worth_statements_total_liabilities_at_date_acquired",
    "liabilities_total"
  );
  const [netWorth, netWorthIsSuccess] = useGetNetworthViewData(
    "net_worth_statements_networth",
    "networth"
  );

  const isSuccess =
    assetsTotalIsSuccess &&
    liabilitiesTotalIsSuccess &&
    netWorthIsSuccess &&
    assetsAtDateAcquiredTotalIsSuccess &&
    liabilitiesAtDateAcquiredTotalIsSuccess;

  return {
    assetsTotal,
    liabilitiesTotal,
    netWorth,
    assetsAtDateAcquiredTotal,
    liabilitiesAtDateAcquiredTotal,
    isSuccess,
  };
};

export default function NetWorthTable({ personalDisclosureData }) {
  const {
    assetsTotal,
    liabilitiesTotal,
    netWorth,
    assetsAtDateAcquiredTotal,
    liabilitiesAtDateAcquiredTotal,
    isSuccess,
  } = useNetWorthData();

  const [assets, setAssets] = React.useState({});

  const [contingent, setContingent] = React.useState({});
  const [liabilities, setLiabilities] = React.useState({});
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    getData(assetsTableInfo, personalDisclosureData)
      .then((vals) => {
        setAssets(vals);
      })
      .catch((error) => {
        console.log(error);
      });
    getData(liabilitiesTableInfo, personalDisclosureData)
      .then((vals) => {
        setLiabilities(vals);
      })
      .catch((error) => {
        console.log(error);
      });

    getData(contingentTableInfo, personalDisclosureData)
      .then((vals) => {
        setContingent(vals);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [personalDisclosureData]);

  const classes = useStyles();

  const bottomTable = (
    <Box pt={3}>
      <BottomTable
        handleChange={handleChange}
        classes={classes}
        assetsTotal={assetsTotal}
        liabilitiesTotal={liabilitiesTotal}
        contingent={contingent}
        netWorth={netWorth}
      />
    </Box>
  );

  return (
    <>
      {isSuccess ? (
        <div className={classes.root}>
          <AppBar position="static">
            <Tabs
              TabIndicatorProps={{ style: { background: "#E89819" } }}
              style={{ backgroundColor: "#243B53" }}
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="Assets" {...a11yProps(0)} />
              <Tab label="Liabilities" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0} bottomTable={bottomTable}>
            <Table
              items={assets}
              type="assets"
              column1Total={assetsAtDateAcquiredTotal}
              column2Total={assetsTotal}
              header={
                <AssetsTableHeader
                  personalDisclosure={personalDisclosureData}
                />
              }
              footerLabel="Total Assets"
              classes={classes}
            />
            {bottomTable}
          </TabPanel>

          <TabPanel value={value} index={1} bottomTable={bottomTable}>
            <Table
              items={liabilities}
              contingent={contingent}
              column1Total={liabilitiesAtDateAcquiredTotal}
              column2Total={liabilitiesTotal}
              header={<LiabilitiesTableHeader />}
              footerLabel="Total Liabilities"
              classes={classes}
            />
            {bottomTable}
          </TabPanel>
        </div>
      ) : (
        <Center>
          <CircularProgress />
        </Center>
      )}
    </>
  );
}
