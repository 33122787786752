export const defaultInitialValues = {
  michigan: {
    has_been_bonded_refused_or_denied_bonds: null,
    bond_employer_name: "",
    bond_reason: "",
    bond_issuer: "",
    is_bond_called: null,
    bond_called_date: "",
    bond_called_reason: "",
    has_filed_all_tax_returns_last_five_years: null,
    filed_all_tax_returns_last_five_years_details: "",
  }
}