import { formats } from "components/Common/Tables/Common/helpers";
import { fieldTypes } from "componentGenerators/formGenerator/config";

const vehicleLicensesColumns = [
  {
    title: "Expiry Date",
    field: "to_date",
    format: formats.date,
  },
  {
    title: "License Number",
    field: "license_number",
  },
  {
    title: "Jurisdiction",
    field: "issuing_jurisdiction",
    format: formats.startCase,
  },
];

const vehicleLicensesFormSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Nature of Charge",
  },
  {
    name: "to_date",
    type: fieldTypes.date,
    label: "Expiry Date",
    required: true,
  },
  {
    type: fieldTypes.text,
    label: "License Number",
    name: "license_number",
    required: true,
  },
  {
    type: fieldTypes.asyncSelect,
    endpoint: "jurisdiction",
    label: "Issuing Jurisdiction",
    getOptionLabel: (option) => option.name,
    getOptionValue: (option) => option.tag,
    xs: 8,
    required: true,
    name: "issuing_jurisdiction",
    fetchOptions: { useFullEndpoint: false },
  },
];

const vehicleLicensesSchema = {
  type: "table",
  endpoint: "michigan_isbigo_supplement_vehicle_licenses_vehicleLicensePrimary",
  formSchema: vehicleLicensesFormSchema,
  columns: vehicleLicensesColumns,
  infoAlerts: [
    {
      body: "Please provide below your current driver's license information.",
    },
  ],

  modifySubmissionValues: (formValues) => ({
    ...formValues,
    is_primary_drivers_license: true,
  }),
};

export default [vehicleLicensesSchema];
