import { withStyles } from "@material-ui/core";
import { StyledChip } from "components/Pages/LicensingControl/components/DetailsDialog/Modal.styles.js";
import React from "react";

const TruncatedChip = withStyles(() => ({
  root: {
    maxWidth: 150,
  },
  label: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}))(StyledChip);

export const TruncatedChips = ({ data, getLabel = ({ name }) => name }) => {
  if (!data?.length) return null;
  return (
    <>
      <TruncatedChip size="small" label={getLabel(data[0]) || "N/A"} />
      {data.length > 1 && (
        <TruncatedChip size="small" label={`+${data.length - 1}`} />
      )}
    </>
  );
};
