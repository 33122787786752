import React, { useState } from "react";
import { Select, InputLabel, FormControl, MenuItem } from "@material-ui/core";
import {
  usePdfDispatch,
  usePdfState,
  ACTIONS,
} from "components/DevTools/PdfTools/Context/PdfToolsProvider.jsx";

export default function CombinedColumns() {
  const dispatch = usePdfDispatch();
  const { combo, column } = usePdfState();
  const [element, setElement] = useState("");
  const handleChange = (e) => {
    setElement(e.target.value);
    dispatch({
      type: ACTIONS.SET_STATE,
      value: "-" + e.target.value + "@" + column + "_and_" + e.target.value,
      name: "token",
    });
  };

  if (column && combo.length > 1) {
    return (
      <FormControl
        variant="filled"
        style={{ width: "150px", marginRight: "10px" }}
      >
        <InputLabel>Combine</InputLabel>
        <Select value={element} onChange={handleChange}>
          {combo
            .filter((val) => val !== column)
            .map((val) => (
              <MenuItem key={val} value={val}>
                {val}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  } else {
    return <></>;
  }
}
