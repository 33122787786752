import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import {
  Divider,
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm";
import React from "react";
import FormikFileDropzone from "../../../../../Formik/fields/FormikFileDropzone";
import { tabularDataParams } from "./config.js";
import YesNo from "components/Formik/formGroups/YesNo";

const Form = () => {
  return (
    <Grid container spacing={1}>
      <SectionSubHeader>Applicant Information</SectionSubHeader>
      <YesNo
        label="Has the applicant been verified as a minority or women’s business enterprise by the Pennsylvania Department of General Services (DGS) Bureau of Small Business Opportunities?"
        name="bd_pennsylvania_eea.is_minority_or_women_business"
        xs={8}
      />
      <SectionLabel>
        If yes, provide the name of the entity that certified the applicant as a
        minority or women’s business enterprise and upon which the DGS
        verification was made.
      </SectionLabel>
      <FormikTextField
        label="Entity Name"
        name="bd_pennsylvania_eea.verified_minority_women_agency"
        xs={6}
      />
      <SectionLabel>
        If yes, attach the verification letter from the Bureau of Small Business
        Opportunities and/ or the Small Business Procurement Initiative that
        identifies the company as a small diverse business (not only as a small
        business) and provide the certification number:
      </SectionLabel>
      <FormikTextField
        label="Certification Number "
        name="bd_pennsylvania_eea.small_diverse_business_certificate_number"
        xs={6}
      />
      <FormikFileDropzone
        description="EEA Pennsylvania"
        label="Upload Attachment"
        name="small_diverse_business_attachment_group_id"
        size={12}
      />
      <Divider />
      <SectionSubHeader>Affiliation</SectionSubHeader>
      <SectionLabel>
        If the entity applying for licensure as an affiliate, intermediary,
        subsidiary, or holding company of an enterprise entity, provide the name
        of the enterprise entity.
      </SectionLabel>
      <FormikTextField
        label="Enterprise Name"
        name="bd_pennsylvania_eea.main_company_name"
        xs={6}
      />
      <SectionSubHeader divider >commonwealth principal place of business</SectionSubHeader>
      <SectionLabel>
        <>
        Provide a plan to establish and maintain a principal place of business in the Commonwealth within one (1) year of the Board’s issuance of the supplier license.
        </>
      </SectionLabel>
      <FormikFileDropzone
        label="SWO Commonwealth Principal Place of Business"
        description="SWO Commonwealth Principal Place of Business"
        name="commonwealth_principal_location_attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};

const ApplicantInformation = ({ innerRef }) => {
  const formData = useForm(tabularDataParams);
  return (
    <FormContextProvider
      formData={formData}
      showSaveButton={false}
      innerRef={innerRef}
    >
      <EnhancedFormikPage showSaveButton={false} innerRef={innerRef}>
        <Form />
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

export default ApplicantInformation;
