import { fieldTypes } from "componentGenerators/formGenerator/config";
import React from "react";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Contracts",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload contracts entered into between you or the business entity and any
        other business or person concerning your gaming business. This includes
        but not limited to contracts with Geo-location providers, identification
        verification service providers, data service providers, risk management
        services ie; traders, payment processing service providers, associated
        equipment manufacturers, landlords, lending institutions, private
        investors or any other relevant agreements or contracts.
      </>
    ),
  },
  {
    type: fieldTypes.dropZone,
    label: "Contracts",
    name: "south_dakota_phd.contracts_attachment_id",
    xs: 12,
    rows: 4,
  },
  {
    type: fieldTypes.subHeader,
    label: "notes and mortgages payable",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload a copy of all notes and mortgages payable and notes receivable in
        the
        <CustomLink to="real_estate_loans" />
        and
        <CustomLink to="loans_and_receivables" />
        sections.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "business investments",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of your most recent financial statements for all business
        investments in the
        <CustomLink to="business_investments" />
        section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "life insurance policies",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload copies of all life insurance policies and most recent statement
        of cash value in the
        <CustomLink to="life_insurance" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "IRA, 401K plan or retirement plan",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload copies of most recent statement of IRA, 401K plan or retirement
        plan of any kind that shows the most recent value in the
        <CustomLink to="pension_funds" /> section.
      </>
    ),
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "south_dakota_phd_supplement_attachments_pdSupplement",
};
