import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import useFetch from "queryHooks/useFetch";
import { Box } from "@material-ui/core";
import DisplayTable from "components/Common/Tables/DisplayTable";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const PennsylvaniaPublicOfficial = () => {
  const { data = [], status } = useFetch(
    "pennsylvania_public_official_government_employment_one_year"
  );
  return (
    <FormSectionWrapper title={"Pennsylvania Public Official Questions"}>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={status}>
          <DisplayTable
            records={data}
            linkTo="government_positions"
            columns={[
              { title: "From Date", field: "from_date", format: "date" },
              { title: "To Date", field: "to_date", format: "date" },
              {
                title: "Entity Name",
                field: "business_name",
              },
              { title: "Position", field: "position", format: "startCase" },
              {
                title: "Person of Interest",
                field: "person_name",
              },
              {
                title: "Relationship",
                field: "person_relative_type",
                format: "startCase",
              },
            ]}
            dependsOnQueryCount="Are you, your spouse, minor child or unemancipated child, currently or within the last 12 months, an executive-level public employee, public official or party officer?"
          />
        </LoadingComponent>
      </Box>
    </FormSectionWrapper>
  );
};

export default PennsylvaniaPublicOfficial;
