// import Button from "components/Common/CustomButtons/Button";
import React from "react";
import LoginCard from "../../components/Common/LoginCard";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { useSnackbar } from "notistack";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button } from "@material-ui/core";
const _ = require("lodash");

const useStyles = makeStyles(styles);

export default function LoginPage() {
  const { loginWithRedirect, logout } = useAuth0();
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const handleSnackBar = (variant) => (message) => {
    enqueueSnackbar(message, {
      variant,
    });
  };

  // Showing error from Auth0 in snackbar
  if (window.location.search.includes("error")) {
    const errorMessage = window.location.search.split("=")[1];
    handleSnackBar("error")(_.startCase(decodeURIComponent(errorMessage)));
  }

  if (window.location.search.includes("success")) {
    const successMessage = window.location.search.split("=")[1];
    handleSnackBar("info")(_.startCase(decodeURIComponent(successMessage)));
  }

  // Auto Redirect to Auth0 Login page: Not a good user experience
  /*if(!isAuthenticated) {
    loginWithRedirect();
  }*/

  return (
    <div className={classes.container}>
      <LoginCard>
        <Box m={2} />
        <Button
          type="submit"
          style={{
            width: "50%",
            alignSelf: "center",
          }}
          size="large"
          variant="contained"
          color="primary"
          onClick={loginWithRedirect}
        >
          Log In
        </Button>
        <Box m={1} />
        <Button
          style={{
            width: "50%",
            alignSelf: "center",
          }}
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          RESET LOGIN
        </Button>
        <Box m={2} />
      </LoginCard>
    </div>
  );
}
