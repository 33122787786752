import { fieldTypes } from "componentGenerators/formGenerator/config";
import { NumberFormatTypes } from "components/Common/Inputs/NumberField";
import { formats } from "components/Common/Tables/Common/helpers";

const bdTaxActivityForm = [
  {
    name: "bd_party_id",
    type: fieldTypes.bdBusinessFamilySelect,
    getOptionLabel: (option) => option.business_name,
    getOptionValue: (option) => option.id,
    required: true,
  },
  {
    name: "tax_agency_bd_business_entity_id",
    type: fieldTypes.bdGovernmentAgencySelect,
    required: true,
  },
  {
    type: fieldTypes.subHeader,
    label: "Time Period",
  },
  {
    type: fieldTypes.date,
    name: "from_date",
  },
  {
    type: fieldTypes.date,
    name: "to_date",
  },
  {
    type: fieldTypes.subHeader,
    label: "Details",
  },
  {
    name: "activity_type",
    type: fieldTypes.select,
    dynamicMenuItems: ["bdTaxActivityType"],
    required: true,
  },
  {
    name: "region_type",
    label: "Tax Region Type",
    type: fieldTypes.select,
    dynamicMenuItems: ["bdTaxRegionType"],
    required: true,
  },
  {
    type: fieldTypes.number,
    label: "Amount",
    name: "amount",
    format: NumberFormatTypes.dollar,
  },
  {
    type: fieldTypes.label,
    label:
      "Attach any copies of forms, protest letters and other correspondence relating to the audit or adjustment.",
  },
  {
    type: fieldTypes.dropZone,
    description: "BD Audits Adjustment Deliquency",
    label: "Upload Attachment",
    name: "attachment_group_id",
  },
];

const bdTaxActivityColumns = [
  { field: "from_date" },
  { field: "tax_agency_bd_business_entity.business_name", title: "Tax Agency" },
  { field: "activity_type", format: formats.startCase },
  { field: "amount", format: formats.dollar },
];

const bdTaxActivitySchema = {
  type: "table",
  endpoint: "bdTaxActivity",
  formSchema: bdTaxActivityForm,
  columns: bdTaxActivityColumns,
  nonRequired: ["credit_rating_attachment_group_id"],
  infoAlerts: [
    {
      body: "Provide below whether Applicant ever had its federal, state or municipal taxes audited, adjusted or is delinquent in the payment of such tax.",
    },
  ],
};

export default [bdTaxActivitySchema];
