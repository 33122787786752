export const columns = [
  {
    title: "Trust",
    field: "trust_bd_business_entity.business_name",
  },
  {
    title: "Associated Business",
    field: "bd_business_entity.business_name",
  },
  {
    field: "from_date",
    format: "date",
  },
  {
    field: "to_date",
    format: "date",
  },
];

export const defaultInitialValues = {
  bd_business_entity_id: null,
  trust_bd_business_entity_id: null,
  from_date: null,
  to_date: null,
  is_current: false,
  description: "",
};

export const tableQuestions =
  "Has the business entity or any of its subsidiaries companies currently a party to, or has it ever been a party to, in any capacity, any business trust instrument";

export const endpoint = "bdTrust";
