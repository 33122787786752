import React from "react";
import CustomLink from "components/Common/Link";

export const columns = [
  {
    field: "description",
  },
  {
    field: "trustee_names",
  },
  {
    field: "names_of_others",
  },
];

export const tableQuestions = (
  <>
    Do you own, hold, or have an interest in any assets in a trust in any
    jurisdiction (you may exclude those assets disclosed in
    <CustomLink to="executor" />
    )?
    <br />
    <br />
    If yes, provide details below.
  </>
);

export const defaultInitialValues = {
  type: "personal_trust",
  description: "",
  trustee_names: "",
  location: "",
  names_of_others: "",
};
