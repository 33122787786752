import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { getAttachmentUrl, logout401 } from "helpers/apiHelpers";
import PropTypes from "prop-types";
import React from "react";
import { Prompt } from "react-router-dom";
import useFetch from "queryHooks/useFetch";
import {
  DropArea,
  FileViewer,
  RecentlyAddedFiles,
  RejectedFiles,
  UploadedFiles,
} from "./components";
import { dropzoneStyles } from "./styles";
import { uploadsReducer } from "./uploadsReducer";
import { useCustomDropZone } from "./useCustomDropZone";

const useStyles = makeStyles(() => dropzoneStyles);

const getDropZoneClass = (classes, isDragActive, isDragReject) => {
  return [
    classes.dropzoneStyle,
    isDragActive && !isDragReject ? classes.onFileHover : "",
    isDragActive && isDragReject ? classes.onRejectHover : "",
  ].join(" ");
};

export const FileDropzone = ({
  setAttachmentGroupID,
  attachmentGroupID,
  children,
  getAttachmentEndpoints,
  attachmentGroupEndpoint,
  description,
}) => {
  const [refetchingAttachments, setRefetchingAttachments] =
    React.useState(false);

  const [attachmentBeingViewed, setAttachmentBeingViewed] =
    React.useState(null);

  const [uploadsInProgress, uploadsInProgressDispatch] = React.useReducer(
    uploadsReducer,
    []
  );

  const classes = useStyles();
  const { get: attachmentEndpoint, mutate: mutateAttachmentEndpoint } =
    getAttachmentEndpoints(attachmentGroupID);

  const {
    data = [],
    refetch,
    status,
  } = useFetch(["uploadedFiles", attachmentGroupID], attachmentEndpoint, {
    useFullEndpoint: false,
    enabled: !!attachmentGroupID,
    onSuccess: () => setRefetchingAttachments(false),
  });

  const refetchFiles = () => {
    refetch();
    setRefetchingAttachments(true);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useCustomDropZone({
    setAttachmentGroupID,
    attachmentGroupID,
    description,
    getAttachmentEndpoints,
    attachmentGroupEndpoint,
    refetchFiles,
    uploadsInProgressDispatch,
  });

  const dropzoneClass = getDropZoneClass(classes, isDragActive, isDragReject);

  const handleDeleteAttachment = (file) => {
    axios
      .delete(mutateAttachmentEndpoint + file.id)
      .then(() => refetch())
      .catch((err) => {
        logout401(err);
      });
  };

  const handleClickFile = (file) => {
    getAttachmentUrl(attachmentEndpoint + file.id)
      .then(({ data }) => {
        setAttachmentBeingViewed(data);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
      <Prompt
        when={uploadsInProgress.length > 0}
        message="File upload in progress. If you leave the page, the upload will not complete."
      />
      <FileViewer attachment={attachmentBeingViewed} />

      <Box className={classes.container} p={1}>
        <div>
          <div>
            <DropArea
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              dropzoneClass={dropzoneClass}
              classes={classes}
            >
              {children}
            </DropArea>

            {fileRejections.length ? (
              <RejectedFiles fileRejections={fileRejections} />
            ) : null}
          </div>

          <div>
            {uploadsInProgress.length ? (
              <RecentlyAddedFiles recentlyAddedFiles={uploadsInProgress} />
            ) : null}
            {(status !== "idle" || data.length > 0) && (
              <UploadedFiles
                refetchingAttachments={refetchingAttachments}
                status={status}
                files={data}
                handleDeleteAttachment={handleDeleteAttachment}
                handleClickFile={handleClickFile}
              />
            )}
          </div>
        </div>
      </Box>
    </Sentry.ErrorBoundary>
  );
};

FileDropzone.propTypes = {
  attachmentGroupID: PropTypes.any,
  children: PropTypes.node,
  description: PropTypes.string,
  getAttachmentEndpoints: PropTypes.func,
  attachmentGroupEndpoint: PropTypes.shape({
    get: PropTypes.string,
    mutate: PropTypes.string,
  }),
  setAttachmentGroupID: PropTypes.func,
};
