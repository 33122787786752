import axios from "axios";

export const PDFValidationTools = {
  async getAllJusidiction() {
    try {
      const response = await axios.get("/jurisdiction");
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async getAllApplications(params) {
    try {
      const { jurisdiction_id, is_business } = params;
      const response = await axios.get(
        `/jurisdiction/${jurisdiction_id}/application${
          is_business ? "?is_business=true" : ""
        }`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async productionPdfs() {
    try {
      const response = await axios.get("/validatePdf/pdfs");
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async validatePdfFormat(data) {
    try {
      const response = await axios.post("/validatePdf", data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async validatePdfsFormat(data) {
    try {
      const response = await axios.post("/validatePdf/allPdfs", data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async dataIndex() {
    try {
      const response = await axios.get("/validatePdf");
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async tagQuery(tag) {
    try {
      const response = await axios.post("/validatePdf/testTagParams", tag);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  /**
   * send employee id and pdf form id
   * @param { employee_id, pdf_form_id } params
   */

  async trackData(params) {
    try {
      const response = await axios.post("/validatePdf/trackData", params);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async startTrackingPdf(params) {
    try {
      const { pdf_id, pdf_data, employeeId } = params;
      const response = await axios.post(
        `/employee/${employeeId}/trackPdf/${pdf_id}`,
        { verified: true, pdf_data }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async updateTrackedPdf(params) {
    try {
      const { employeeId, data } = params;
      const response = await axios.patch(
        `/employee/${employeeId}/trackPdf/${data.id}`,
        data
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async findTags(params) {
    try {
      const response = await axios.post(`/validatePdf/findTags`, params);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async pdfFormTagMappingCreate(params) {
    try {
      const response = await axios.post(`/pdfFormTagMapping`, params);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async pdfFormTagMappingUpdate(params) {
    try {
      const response = await axios.patch(`/pdfFormTagMapping`, params);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async pdfFormTagMappingUpdateMultiple(params) {
    try {
      const response = await axios.patch(`/pdfFormTagMapping/multiple`, params);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async pdfFormTagMappingCreateMultiple(params) {
    try {
      const response = await axios.post(`/pdfFormTagMapping/multiple`, params);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async getViewSQL(params) {
    try {
      const response = await axios.post(`/validatePdf/viewSQL`, params);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async getTopHundredTags(params) {
    try {
      const response = await axios.post(`/validatePdf/topHundred`, params);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
};
