import { dayjsUTC } from "helpers/apiHelpers";

export const generateDateString = (fromDate, toDate, isCurrent) => {
  const formattedFromDate = dayjsUTC(fromDate, "MMM D, YYYY");
  const formattedToDate = dayjsUTC(toDate, "MMM D, YYYY");
  if (fromDate && isCurrent) {
    return `${formattedFromDate} to Current`;
  }
  if (fromDate && toDate) {
    return `${formattedFromDate} to ${formattedToDate}`;
  }
  if (fromDate && !toDate) {
    return `From ${formattedFromDate}`;
  }
  if (!fromDate && toDate) {
    return `To ${formattedToDate}`;
  }
  if (!fromDate && !toDate) {
    return `No Dates Provided`;
  }
};

export const sortByFromDate = (data) =>
  data.slice().sort((a, b) => new Date(b.from_date) - new Date(a.from_date));

export const formatValuesForSubmission = (values) => ({
  ...values,
  address: {
    ...values.address,
  },
});
