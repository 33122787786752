export const columns = [
  {
    title: "Other Party",
    field: "related_party_name",
  },
  {
    title: "Recipient",
    field: "recipient_party_name",
  },
  {
    title: "USD Amount",
    field: "amount_usd",
  },
  {
    title: "Date",
    field: "from_date",
    format: "date",
  },
  {
    title: "Position",
    field: "position",
  },
];

export const defaultInitialValues = {
  recipient_bd_party_id: null,
};

export const endpoint = "bdBribe?bribe_category=political";
export const tabularDataParams = {
  defaultInitialValues,
  endpoint,
};
