import React from "react";
import { formats } from "components/Common/Tables/Common/helpers";
import { commonStrings } from "../../uiStrings";

export const columns = [
  {
    field: "date_received",
  },
  {
    field: "party_names_and_addresses",
  },
  {
    field: "amount",
    format: formats.currency,
  },
];

export const tableQuestions = (
  <>
    In the past ten (10) years have you received any referral or finder&apos;s
    fee in excess of $10,000 USD.
    {commonStrings.provideDetails}
  </>
);

export const defaultInitialValues = {
  party_names_and_addresses: "",
  nature: "",
  amount: null,
  date_received: null,
  renumeration_type: "",
  is_gaming_related: null,
};
