import { Box } from "@material-ui/core";
import React from "react";
import useFetch from "queryHooks/useFetch.js";
import DisplayTable from "components/Common/Tables/DisplayTable/index.js";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";
import YesNo from "components/Formik/formGroups/YesNo";
import useForm from "hooks/useForm";
import { defaultInitialValues } from "./config";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import { SectionLabel } from "components/Common/LayoutComponents";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { SectionSubHeader } from "components/Common/LayoutComponents/index";

const MichiganCriminalHistory = ({ name }) => {
  const formData = useForm({
    defaultInitialValues,
    endpoint: "michigan_criminal_history_pdSupplement",
  });

  const {
    data: isExpungedOrSealedData,
    status: isExpungedOrSealedStatus,
  } = useFetch("michigan_criminal_history_sealedOrExpunged");
  const { data: criminalChargesData, status: criminalChargesStatus } = useFetch(
    "michigan_criminal_history_criminalLegalHistory"
  );
  const {
    data: criminalIndictmentsData,
    status: criminalIndictmentsStatus,
  } = useFetch("michigan_criminal_history_indictmentLegalHistory");
  const {
    data: criminalInvestigationData,
    status: criminalInvestigationStatus,
  } = useFetch("michigan_criminal_history_investigationLegalHistory");

  return (
    <FormSectionProvider formData={formData} title={`Michigan ${name}`}>
      <EnhancedFormikPage>
        <Box mb={1} pb={4} bgcolor="white">
          <SectionSubHeader label="Criminal Convictions" />
          <LoadingComponent status={isExpungedOrSealedStatus}>
            <DisplayTable
              records={isExpungedOrSealedData}
              linkTo={["arrests", "pardons"]}
              columns={[
                {
                  title: "Date",
                  field: "expunged_date",
                  format: "date",
                },
                { title: "Details", field: "nature" },
              ]}
              dependsOnQueryCount="Do you have any criminal convictions that have been expunged or pardoned?"
            />
          </LoadingComponent>
        </Box>
        <SectionSubHeader label="Alcohol Arrests & Charges" />
        <Box mb={1} pt={2}>
          <YesNo
            label={
              <>
                Are there any alcohol related arrests and/or charges (i.e.
                driving under the influence of, impared by alcohol or drugs,
                open alcohol, etc) that were not recorded on the
                Multi-Jurisdictional Personal History Disclosure Form?
              </>
            }
            name="michigan.is_bond_called"
            xs={12}
          />
        </Box>
        <Box pb={2}>
          <SectionLabel label="Please note that we have pulled your answers of questions 28, 29 and 30 on the Multi-Jurisdictional Personal History Disclosure Form below for your review." />
        </Box>
        <Box pb={2}>
          <SectionLabel label="If answered Yes, please provide explanation below:" />
        </Box>
        <Box pb={2}>
          <FormikTextField
            multiline
            rows={4}
            label="Explanation"
            name="michigan.additional_criminal_history"
            xs={12}
          />
        </Box>
        <Box mb={1} bgcolor="white">
          <LoadingComponent status={criminalChargesStatus}>
            <DisplayTable
              records={criminalChargesData}
              linkTo="arrests"
              columns={[
                {
                  title: "Date of Charge",
                  field: "charge_date",
                  format: "date",
                },
                {
                  title: "Nature",
                  field: "nature",
                },
                { title: "Incident State", field: "incident_state" },
                {
                  title: "Agency Name or Court",
                  field: "law_enforcement_pd_business_entity.business_name",
                },
                { title: "Sentence", field: "sentence" },
              ]}
              dependsOnQueryCount="Question 28, have you ever been arrested or charged with any crime or offense in any jurisdiction?"
            />
          </LoadingComponent>
        </Box>
        <Box mb={1} bgcolor="white">
          <LoadingComponent status={criminalIndictmentsStatus}>
            <DisplayTable
              records={criminalIndictmentsData}
              linkTo="indictments"
              columns={[
                {
                  title: "Indictment Date",
                  field: "indictment_date",
                  format: "date",
                },
                { title: "Nature", field: "nature" },
                { title: "Government Agency", field: "nature" },
              ]}
              dependsOnQueryCount="Question 29, to the best of your knowledge, has a criminal indictment, information or complaint 
          ever been filed or returned against you, but for which you were not arrested or in which you were named as an unindicted 
          party or unindicted co-conspirator in any criminal proceeding in any jurisdiction?"
            />
          </LoadingComponent>
        </Box>
        <Box mb={1} bgcolor="white">
          <LoadingComponent status={criminalInvestigationStatus}>
            <DisplayTable
              records={criminalInvestigationData}
              linkTo="government_investigations"
              columns={[
                {
                  title: "Testimony Date",
                  field: "testimony_date",
                  format: "date",
                },
                { title: "Nature", field: "nature" },
                {
                  title: "Name of Government Agency",
                  field: "law_enforcement_pd_business_entity.business_name",
                },
              ]}
              dependsOnQueryCount="Question 30, have you ever been the subject of an investigation conducted by any governmental agency/organization, court, commission, 
          committee, grand jury or investigatory body (local, state, county, provincial, federal, national, etc.) other than in response to a traffic summons?"
            />
          </LoadingComponent>
        </Box>
      </EnhancedFormikPage>
    </FormSectionProvider>
  );
};

export default MichiganCriminalHistory;
