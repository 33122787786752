import { useEffect } from "react";
import { useFormikContext } from "formik";
import { get } from "lodash";

const getErrorElement = (errors, errorKeys) => {
  let errorElement = "";
  let error = "";

  if (!errors || !errorKeys) return null;

  error = get(errors, errorKeys[0]);

  if (typeof get(errors, errorKeys[0]) === "object") {
    errorElement = document.querySelector(
      `[name="${errorKeys?.[0]}.${error && Object.keys(error)?.[0]}"]`
    );
  } else {
    errorElement = document.querySelector(`[name="${errorKeys?.[0]}"]`);
  }
  if (errorElement) {
    errorElement.focus();
  }
  return null;
};

const FormikErrorScroll = () => {
  const { isSubmitting, isValidating, errors } = useFormikContext();

  useEffect(() => {
    const errorKeys = Object.keys(errors);
    const hasError = errorKeys.length > 0 && isSubmitting && !isValidating;
    hasError && getErrorElement(errors, errorKeys);
  }, [isSubmitting, isValidating, errors]);

  return null;
};

export default FormikErrorScroll;
