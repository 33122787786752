import React from "react";

export const columns = [
  {
    field: "estate_name",
  },
  {
    field: "estate_location",
  },
  {
    field: "position",
  },
  {
    field: "dates_position_held",
  },
];

export const tableQuestions = (
  <>
    During the last ten (10) year period, have you been:
    <ol type="a">
      <li>An executor(trix), administrator or other fiduciary of any estate</li>
      <li>
        A beneficiary or legatee under a will or received any thing of value
        under an intestacy statute; or
      </li>
      <li>A settlor/grantor, beneficiary or trustee of any trust</li>
    </ol>
    <br />
    If yes, provide details below.
  </>
);

export const defaultInitialValues = {
  estate_name: "",
  estate_location: "",
  position: "",
  dates_position_held: "",
  compensation_amount: null,
  nature_of_benefit: "",
};
