import { formats } from "components/Common/Tables/Common/helpers";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import { NumberFormatTypes } from "components/Formik/fields/FormikNumberField";

const nonFiledTaxesColumns = [
  {
    title: "Tax Agency",
    field: "tax_agency_pd_business_entity.business_name",
  },
  {
    title: "Tax Year",
    field: "tax_year",
  },
  {
    title: "Return Type",
    field: "region_type",
    format: formats.startCase,
  },
];

const nonFiledTaxesFormSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Tax Agency",
  },
  {
    type: fieldTypes.pdBusinessSelect,
    endpoint: "government_business_entities",
    label: "Tax Agency",
    xs: 8,
    required: true,
    name: "tax_agency_pd_business_entity_id",
  },
  {
    name: "region_type",
    type: fieldTypes.select,
    dynamicMenuItems: ["taxReturnType"],
    label: "Return Type",
    required: true,
  },
  {
    type: fieldTypes.number,
    label: "Tax Year",
    name: "tax_year",
    format: NumberFormatTypes.year,
  },
  {
    type: fieldTypes.number,
    label: "Amount",
    name: "amount",
    format: NumberFormatTypes.dollar,
  },
];

const nonFiledTaxesSchema = {
  type: "table",
  endpoint:
    "michigan_isbigo_supplement_non_filed_taxes_taxReturnActivityNotFiled",
  formSchema: nonFiledTaxesFormSchema,
  columns: nonFiledTaxesColumns,
  infoAlerts: [
    {
      body:
        "Have you ever failed to file your Federal or State Income Tax Return?",
    },
  ],
  modifySubmissionValues: (formValues) => ({
    ...formValues,
    activity_type: "not_filed",
  }),
};

export default [nonFiledTaxesSchema];
