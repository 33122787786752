import axios from "axios";
import { useMutation } from "react-query";

export function usePostNewPassword() {
  const postNewPassword = async (payload) => {
    const { data } = await axios.post(`/auth/forgetPassword/update`, payload);
    return data;
  };

  return useMutation(postNewPassword);
}
