import DashboardRouter from "components/Pages/Dashboard/DashboardRouter";

//PERSONAL DISCLOSURE
import personal_disclosure from "routeHelpers/components/PersonalDisclosure/BasePages";
import pd_aditional_pages from "routeHelpers/components/PersonalDisclosure/AdditionalPages";

//PD SUPPLEMENTALS
import new_jersey from "routeHelpers/components/PersonalDisclosure/Supplementals/NewJersey";
import new_jersey_ancillary from "routeHelpers/components/PersonalDisclosure/Supplementals/NewJerseyAncillary";
import missouri from "routeHelpers/components/PersonalDisclosure/Supplementals/Missouri";
import illinois from "routeHelpers/components/PersonalDisclosure/Supplementals/Illinois";
import indiana from "routeHelpers/components/PersonalDisclosure/Supplementals/Indiana";
import colorado from "routeHelpers/components/PersonalDisclosure/Supplementals/Colorado";
import new_jersey_33 from "routeHelpers/components/PersonalDisclosure/Supplementals/NewJersey33";
import new_jersey_35_qr from "routeHelpers/components/PersonalDisclosure/Supplementals/NewJersey35QR";
import pennsylvania from "routeHelpers/components/PersonalDisclosure/Supplementals/Pennsylvania";
import new_mexico from "routeHelpers/components/PersonalDisclosure/Supplementals/NewMexico";
import tennessee from "routeHelpers/components/PersonalDisclosure/Supplementals/Tennessee";
import new_york from "routeHelpers/components/PersonalDisclosure/Supplementals/NewYork";
import michigan from "routeHelpers/components/PersonalDisclosure/Supplementals/Michigan";
import montana from "routeHelpers/components/PersonalDisclosure/Supplementals/Montana";
import nevada from "routeHelpers/components/PersonalDisclosure/Supplementals/Nevada";
import delaware from "routeHelpers/components/PersonalDisclosure/Supplementals/Delaware";
import elk_valley from "routeHelpers/components/PersonalDisclosure/Supplementals/ElkValley";
import arkansas from "routeHelpers/components/PersonalDisclosure/Supplementals/Arkansas";
import iowa from "routeHelpers/components/PersonalDisclosure/Supplementals/Iowa";
import louisiana from "routeHelpers/components/PersonalDisclosure/Supplementals/Louisiana";
import louisiana_r06_07 from "routeHelpers/components/PersonalDisclosure/Supplementals/LouisianaR06";
import nevada_phr from "routeHelpers/components/PersonalDisclosure/Supplementals/NevadaPhr";
import rhode_island_phd_supplement from "routeHelpers/components/PersonalDisclosure/Supplementals/RhodeIsland";
import ohio_supplement from "routeHelpers/components/PersonalDisclosure/Supplementals/Ohio";
import south_dakota_supplement from "routeHelpers/components/PersonalDisclosure/Supplementals/SouthDakota";
import louisiana_phfr_supplement from "routeHelpers/components/PersonalDisclosure/Supplementals/LouisianaPHFR";
import west_virginia_swil_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDWestVirginiaSWIL";
import michigan_limited_pd_supplement from "routeHelpers/components/PersonalDisclosure/Supplementals/MichiganLimitedPD";
import maryland_pea_supplement from "routeHelpers/components/PersonalDisclosure/Supplementals/MarylandPEA";
import colorado_kapa_supplement from "routeHelpers/components/PersonalDisclosure/Supplementals/ColoradoKapa";
import cherokee_niva_supplement from "routeHelpers/components/PersonalDisclosure/Supplementals/CherokeeNIVA";
import colorado_gsbsla_supplement from "routeHelpers/components/PersonalDisclosure/Supplementals/ColoradoGSBSLA";
import michigan_isbigo_supplement from "routeHelpers/components/PersonalDisclosure/Supplementals/MichiganISBIGO";
import new_york_cvke_supplement from "routeHelpers/components/PersonalDisclosure/Supplementals/NewYorkCVKE";
import virginia_pea_supplement from "routeHelpers/components/PersonalDisclosure/Supplementals/VirginiaPEA";
import oklahoma_rkela_supplement from "routeHelpers/components/PersonalDisclosure/Supplementals/OklahomaRKELA";
import new_jersey_phdf2_supplement from "routeHelpers/components/PersonalDisclosure/Supplementals/NewJerseyPHDF2";
import tennessee_phdrf_supplement from "routeHelpers/components/PersonalDisclosure/Supplementals/TennesseePHDRF";
import pd_shakopee_mdewakanton_sioux_community_ira_supplement from "routeHelpers/components/PersonalDisclosure/Supplementals/ShakopeeIRA";
import oneida_vlabp_supplement from "routeHelpers/components/PersonalDisclosure/Supplementals/OneidaVLABP";
import pd_nova_scotia_ira_supplement from "routeHelpers/components/PersonalDisclosure/Supplementals/NovaScotiaIRA";
import pennsylvania_gsp_supplement from "routeHelpers/components/PersonalDisclosure/Supplementals/PennsylvaniaGSP";

//BUSINESS DISCLOSURE
import business_disclosure from "routeHelpers/components/BusinessDisclosure/BasePages";
import bd_additional_pages from "routeHelpers/components/BusinessDisclosure/AdditionalPages";

//BD SUPPLEMENTALS
import bd_nj_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDNewJersey";
import bd_penn_eea_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDPennsylvania";
import bd_pa_certification_supplemental from "routeHelpers/components/BusinessDisclosure/Supplementals/BDPennsylvaniaCert";
import bd_elk_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDElkValley";
import bd_nj_31_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDNewJersey31";
import bd_iowa_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDIowaClassD";
import bd_indiana_swr_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDIndianaSWR";
import bd_nj_csiel_supplemental from "routeHelpers/components/BusinessDisclosure/Supplementals/BDNewJerseyCSIEL";
import bd_colorado_vlma_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDColoradoVLMA";
import bd_colorado_sbba_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDColoradoSBBA";
import bd_michigan_vra_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDMichiganVRA";
import bd_tennessee_vra_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDTennesseeVRA";
import bd_tennessee_bed_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDTennesseeBED";
import bd_iowa_adsw_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDIowaADSW";
import bd_south_dakota_bhd_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDSouthDakotaBHD";
import bd_michigan_supplier_license_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDMichiganSupplierLicense";
import bd_michigan_business_disclosure_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDMichiganBusinessDisclosure";
import bd_virginia_sbvr_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDVirginiaSBVR";
import bd_new_jersey_vrs_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDNewJerseyVRS";
import bd_pennsylvania_gspr_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDPennsylvniaGSPR";
import bd_indiana_supplier_license_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDIndianaSupplierLicense";
import bd_cherokee_vendor_license_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDCherokeeVendorLicense";
import bd_arizona_designee_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDArizonaDesignee";
import bd_maryland_principal_entity_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDMarylandPrincipalEntity";
import bd_new_york_cvavls_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDNewYorkCVAVLS";
import bd_ohio_rvla_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDOhioRVLA";
import bd_louisiana_level_two_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDLouisianaLevelTwo";
import bd_maryland_swped_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDMarylandSWPED";
import bd_maryland_swcla_supplement from "routeHelpers/components/BusinessDisclosure/Supplementals/BDMarylandSWCLA";

//LICENSE CONTROL
import license_control from "routeHelpers/components/LicensingControl/BasePages";
import LCDashboard from "components/Pages/LicensingControl/Dashboard/Dashboard";

import DocumentRepository from "../components/Pages/DocumentRepository/DocumentRepository";

function testKeys(components, mustInclude) {
  Object.keys(components).forEach((item) => {
    if (!item.includes(mustInclude)) {
      throw new Error(`${item} must contain prefix ${mustInclude}`);
    }
  });
}

//PD
testKeys(new_jersey, "new_jersey_");
testKeys(missouri, "missouri_");
testKeys(illinois, "illinois_");
testKeys(colorado, "colorado_");
testKeys(indiana, "indiana_");
testKeys(new_jersey_33, "new_jersey_33_");
testKeys(new_jersey_35_qr, "new_jersey_35_qr");
testKeys(new_jersey_ancillary, "new_jersey_ancillary_");
testKeys(pennsylvania, "pennsylvania_");
testKeys(new_mexico, "new_mexico_");
testKeys(tennessee, "tennessee_");
testKeys(new_york, "new_york_");
testKeys(michigan, "michigan_");
testKeys(montana, "montana_");
testKeys(nevada, "nevada_");
testKeys(delaware, "delaware_");
testKeys(elk_valley, "elk_valley_");
testKeys(arkansas, "arkansas_");
testKeys(iowa, "iowa_");
testKeys(louisiana, "louisiana_");
testKeys(louisiana_r06_07, "louisiana_r06_07_");
testKeys(nevada_phr, "nevada_phr_");
testKeys(rhode_island_phd_supplement, "rhode_island_phd_supplement");
testKeys(ohio_supplement, "ohio_");
testKeys(south_dakota_supplement, "south_dakota_");
testKeys(louisiana_phfr_supplement, "louisiana_phfr_supplement_");
testKeys(michigan_limited_pd_supplement, "michigan_limited_pd_supplement_");
testKeys(maryland_pea_supplement, "maryland_pea_supplement_");
testKeys(colorado_kapa_supplement, "colorado_kapa_supplement_");
testKeys(cherokee_niva_supplement, "cherokee_niva_supplement_");
testKeys(colorado_gsbsla_supplement, "colorado_gsbsla_supplement_");
testKeys(michigan_isbigo_supplement, "michigan_isbigo_supplement_");
testKeys(new_york_cvke_supplement, "new_york_cvke_supplement_");
testKeys(virginia_pea_supplement, "virginia_pea_supplement_");
testKeys(oklahoma_rkela_supplement, "oklahoma_rkela_supplement_");
testKeys(new_jersey_phdf2_supplement, "new_jersey_phdf2_supplement");
testKeys(tennessee_phdrf_supplement, "tennessee_phdrf_supplement_");
testKeys(
  pd_shakopee_mdewakanton_sioux_community_ira_supplement,
  "pd_shakopee_mdewakanton_sioux_community_ira_supplement_"
);
testKeys(oneida_vlabp_supplement, "oneida_vlabp_supplement_");
testKeys(pd_nova_scotia_ira_supplement, "pd_nova_scotia_ira_supplement_");
testKeys(pennsylvania_gsp_supplement, "pennsylvania_gsp_supplement_");

//BD
testKeys(business_disclosure, "bd_");
testKeys(bd_nj_supplement, "bd_nj_supplement");
testKeys(bd_nj_31_supplement, "bd_nj_31_supplement");
testKeys(bd_penn_eea_supplement, "bd_penn_eea_supplement");
testKeys(bd_pa_certification_supplemental, "bd_pa_certification_supplemental");
testKeys(bd_elk_supplement, "bd_elk_supplement");
testKeys(bd_iowa_supplement, "bd_iowa_supplement");
testKeys(bd_indiana_swr_supplement, "bd_indiana_swr_supplement");
testKeys(bd_nj_csiel_supplemental, "bd_nj_csiel_supplemental");
testKeys(bd_colorado_vlma_supplement, "bd_colorado_vlma_supplement");
testKeys(bd_colorado_sbba_supplement, "bd_colorado_sbba_supplement");
testKeys(bd_michigan_vra_supplement, "bd_michigan_vra_supplement");
testKeys(bd_tennessee_vra_supplement, "bd_tennessee_vra_supplement");
testKeys(bd_tennessee_bed_supplement, "bd_tennessee_bed_supplement");
testKeys(bd_south_dakota_bhd_supplement, "bd_south_dakota_bhd_supplement");
testKeys(
  bd_michigan_supplier_license_supplement,
  "bd_michigan_supplier_license_supplement"
);
testKeys(
  bd_michigan_business_disclosure_supplement,
  "bd_michigan_business_disclosure_supplement"
);
testKeys(bd_virginia_sbvr_supplement, "bd_virginia_sbvr_supplement");
testKeys(west_virginia_swil_supplement, "west_virginia_swil_supplement");
testKeys(bd_new_jersey_vrs_supplement, "bd_new_jersey_vrs_supplement");
testKeys(bd_pennsylvania_gspr_supplement, "bd_pennsylvania_gspr_supplement");
testKeys(
  bd_indiana_supplier_license_supplement,
  "bd_indiana_supplier_license_supplement"
);
testKeys(
  bd_cherokee_vendor_license_supplement,
  "bd_cherokee_vendor_license_supplement"
);
testKeys(bd_arizona_designee_supplement, "bd_arizona_designee_supplement");
testKeys(
  bd_maryland_principal_entity_supplement,
  "bd_maryland_principal_entity_supplement"
);
testKeys(bd_new_york_cvavls_supplement, "bd_new_york_cvavls_supplement");
testKeys(bd_ohio_rvla_supplement, "bd_ohio_rvla_supplement");
testKeys(
  bd_louisiana_level_two_supplement,
  "bd_louisiana_level_two_supplement"
);
testKeys(bd_maryland_swped_supplement, "bd_maryland_swped_supplement");
testKeys(bd_maryland_swcla_supplement, "bd_maryland_swcla_supplement");

export default {
  dashboard: DashboardRouter,
  lc_dashboard: LCDashboard,
  document_repository: DocumentRepository,
  ...business_disclosure,
  ...personal_disclosure,
  ...bd_additional_pages,
  ...pd_aditional_pages,
  ...new_york,
  ...tennessee,
  ...new_jersey_33,
  ...new_jersey_35_qr,
  ...new_jersey_ancillary,
  ...pennsylvania,
  ...new_mexico,
  ...indiana,
  ...colorado,
  ...illinois,
  ...missouri,
  ...new_jersey,
  ...bd_nj_supplement,
  ...bd_nj_31_supplement,
  ...bd_nj_csiel_supplemental,
  ...bd_penn_eea_supplement,
  ...bd_pa_certification_supplemental,
  ...bd_elk_supplement,
  ...bd_iowa_supplement,
  ...bd_indiana_swr_supplement,
  ...bd_colorado_sbba_supplement,
  ...bd_colorado_vlma_supplement,
  ...bd_michigan_vra_supplement,
  ...bd_tennessee_vra_supplement,
  ...bd_tennessee_bed_supplement,
  ...bd_iowa_adsw_supplement,
  ...bd_south_dakota_bhd_supplement,
  ...bd_michigan_supplier_license_supplement,
  ...bd_michigan_business_disclosure_supplement,
  ...bd_pennsylvania_gspr_supplement,
  ...bd_virginia_sbvr_supplement,
  ...bd_new_jersey_vrs_supplement,
  ...west_virginia_swil_supplement,
  ...michigan,
  ...montana,
  ...nevada,
  ...delaware,
  ...elk_valley,
  ...arkansas,
  ...license_control,
  ...iowa,
  ...louisiana,
  ...louisiana_r06_07,
  ...nevada_phr,
  ...rhode_island_phd_supplement,
  ...ohio_supplement,
  ...south_dakota_supplement,
  ...louisiana_phfr_supplement,
  ...michigan_limited_pd_supplement,
  ...bd_indiana_supplier_license_supplement,
  ...bd_cherokee_vendor_license_supplement,
  ...maryland_pea_supplement,
  ...colorado_kapa_supplement,
  ...cherokee_niva_supplement,
  ...colorado_gsbsla_supplement,
  ...michigan_isbigo_supplement,
  ...new_york_cvke_supplement,
  ...virginia_pea_supplement,
  ...bd_arizona_designee_supplement,
  ...bd_maryland_principal_entity_supplement,
  ...bd_new_york_cvavls_supplement,
  ...oklahoma_rkela_supplement,
  ...bd_ohio_rvla_supplement,
  ...new_jersey_phdf2_supplement,
  ...bd_louisiana_level_two_supplement,
  ...tennessee_phdrf_supplement,
  ...pd_shakopee_mdewakanton_sioux_community_ira_supplement,
  ...oneida_vlabp_supplement,
  ...pd_nova_scotia_ira_supplement,
  ...pennsylvania_gsp_supplement,
  ...bd_maryland_swped_supplement,
  ...bd_maryland_swcla_supplement,
};
