import React from "react";
import { Box } from "@material-ui/core";
import TableRowTag from "components/DevTools/PdfTools/Tools/DataIndex/Components/TableRowTag.js";
import { usePdfState } from "components/DevTools/PdfTools/Context/PdfToolsProvider.jsx";

export default function TabularCreation() {
  const { tableRows } = usePdfState();

  function countRows(num) {
    const rows = [];
    for (let i = 0; i < num; i++) {
      rows.push(parseInt(`${i + 1}`));
    }
    return rows;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        padding: "0px 30px 0px 25px",
        alignContent: "flex-start",
      }}
    >
      {tableRows ? (
        <>
          <Box
            component="div"
            style={{
              padding: "0px 0px 0px 0px",
              fontWeight: "bold",
              marginTop: "0px",
              marginRight: "10px",
            }}
            fontSize={16}
            color="primary.main"
          >
            Table Rows:
          </Box>
          <div>
            {[...countRows(tableRows)].map((i) => (
              <TableRowTag key={i} index={i - 1} />
            ))}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
