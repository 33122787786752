import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import ColoradoDisclosureQuestionsForm from "./ColoradoDisclosureQuestionsForm";

const ColoradoDisclosureQuestions = ({ name }) => {
  return (
    <FormSectionWrapper title={name}>
      <ColoradoDisclosureQuestionsForm />
    </FormSectionWrapper>
  );
};

export default ColoradoDisclosureQuestions;
