import React from "react";

import Card from "components/Common/Containers/Card/Card";
import CardBody from "components/Common/Containers/Card/CardBody";

import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Common/Containers/Grid/GridContainer";
import GridItem from "components/Common/Containers/Grid/GridItem";

import CustomLink from "components/Common/Link";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexFlow: "wrap",
  },
  container: {
    height: "100%",
    width: "49%",
    margin: "0 2px",
    flex: "1 1 auto",
    minWidth: "380px",
  },
  item: {
    padding: "8px 16px",
    width: "100%",
  },
}));

const sectionList = [
  { path: "customer_dashboard_admin", label: "Analytics Dashboard" },
  {
    path: "application_form_admin",
    label: "Application Forms",
  },
  {
    path: "customer_application_form_admin",
    label: "Customer Application Form",
  },
];

export default function BusinessDashboard() {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h1">Admin Dashboard</Typography>
      <Card style={{ width: "100%" }}>
        <CardBody className={classes.wrapper}>
          <GridContainer className={classes.container}>
            {sectionList.map((section) => {
              return (
                <GridItem md={12} key={section.label} className={classes.item}>
                  <h3>
                    <CustomLink to={section.path} label={section.label} />
                  </h3>
                </GridItem>
              );
            })}
          </GridContainer>
        </CardBody>
      </Card>
    </>
  );
}
