//NEW MEXICO
import NewMexicoCompensation from "components/Pages/Supplemental/PD/NewMexico/NewMexicoCompensation/NewMexicoCompensation";
import NewMexicoDisclosureQuestions from "components/Pages/Supplemental/PD/NewMexico/NewMexicoDisclosureQuestions/NewMexicoDisclosureQuestions";
import NewMexicoFinancialInterest from "components/Pages/Supplemental/PD/NewMexico/NewMexicoFinancialInterest/NewMexicoFinancialInterest";
import NewMexicoPublicOfficial from "components/Pages/Supplemental/PD/NewMexico/NewMexicoPublicOfficial/NewMexicoPublicOfficial";
import NewMexicoTaxInformation from "components/Pages/Supplemental/PD/NewMexico/NewMexicoTaxInformation/NewMexicoTaxInformation";

const new_mexico_pages = {
  new_mexico_compensation: NewMexicoCompensation,
  new_mexico_disclosure_questions: NewMexicoDisclosureQuestions,
  new_mexico_financial_interest: NewMexicoFinancialInterest,
  new_mexico_public_official: NewMexicoPublicOfficial,
  new_mexico_tax_information: NewMexicoTaxInformation,
};

export default new_mexico_pages;
