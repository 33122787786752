import { Grid } from "@material-ui/core";
import {
  Paragraph,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import CustomLink from "components/Common/Link";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone.jsx";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm.js";
import React from "react";
import { defaultInitialValues, endpoint } from "./config.js";

const Form = () => {
  return (
    <Grid container spacing={1}>
      <SectionSubHeader label="business plan" />
      <Paragraph>
        <>
          Provide a detailed business plan for intended operations in the State
          of Tennessee, including financial projections for the first three (3)
          years of operation. Provide a summary of business currently conducted
          in any jurisdiction outside of Tennessee and include anaudited
          financial report of operations in each jurisdiction for the past three
          (3) years of operationor since commencement of business, whichever is
          shorter. Upload your document in
          <CustomLink to="bd_corporate_documents" />
        </>
      </Paragraph>
      <SectionSubHeader label="Ownership Chart" />
      <Paragraph>
        <>
          Prepare a flowchart which illustrates the full ownership and executive
          structure (e.g., officers,directors, key personnel, managers, etc.) of
          the applicant. List all parent or holding, intermediary,and subsidiary
          (whether or not wholly-owned) entities and identify the ownership and
          executivestructure for each. For non-publicly traded business entities
          identified in the flowchart, all voting. Upload your document in
          <CustomLink to="bd_corporate_documents" />
        </>
      </Paragraph>
      <SectionSubHeader label="formation documents" />
      <Paragraph>
        <>
          Articles of incorporation, organization, or other legal formation
          documents with the jurisdiction where company was formed, and any
          amendments thereto. Upload your document in
          <CustomLink to="bd_corporate_documents" />
        </>
      </Paragraph>
      <SectionSubHeader label="governing documentation" />
      <Paragraph>
        <>
          Copy of bylaws, shareholder agreement, operating agreement,
          partnership agreement, trust agreement, or other governing
          documentation of the business entity. Upload your document in
          <CustomLink to="bd_corporate_documents" />
        </>
      </Paragraph>
      <SectionSubHeader label="organizational structure" />
      <Paragraph>
        <>
          Complete organizational structure of applicant identifying owners,
          officers/directors, managers, general partners, trustees, and key
          personnel, including for any parent, holding, intermediary,
          affiliated, associated, or subsidiary business entities. Upload your
          document in
          <CustomLink to="bd_corporate_documents" />
        </>
      </Paragraph>
      <SectionSubHeader label="List of owners" />
      <Paragraph>
        <>
          List of owners, including names, home and business addresses,
          telephone numbers, and dates of birth, together with percentage of
          ownership interests held; for publicly-traded organizations, a list of
          the names and addresses of beneficial owners holding 5% or more.
          Upload your document in
          <CustomLink to="bd_corporate_documents" />
        </>
      </Paragraph>
      <SectionSubHeader label="tax returns" />
      <Paragraph>
        <>
          Copies of business entity U.S. income tax returns for the past five
          (5) years or since formation if less (parent or affiliated company tax
          returns may be required if entity is new). Upload your document in
          <CustomLink to="bd_tax_returns" />
        </>
      </Paragraph>
      <SectionSubHeader label="bank account statements" />
      <Paragraph>
        <>
          Copies of business entity bank account statements for the past three
          (3) years or since formation if less (parent or affiliated company tax
          returns may be required if entity is new). Upload your document in
          <CustomLink to="bd_bank_accounts" />
        </>
      </Paragraph>

      <SectionSubHeader label="registration" divider />
      <Paragraph>
        <>
          Copy of business registration certificate or other valid business
          registration issued by the State of Tennessee Department of Revenue.
        </>
      </Paragraph>
      <FormikFileDropzone
        description="Tennesse Business Registation"
        name="tennessee_bed.tn_business_registration_attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};

const TennesseeBEDAttachments = ({ name }) => {
  const formData = useForm({ defaultInitialValues, endpoint });

  return (
    <FormSectionProvider formData={formData} title={`${name}`}>
      <EnhancedFormikPage>
        <Form />
      </EnhancedFormikPage>
    </FormSectionProvider>
  );
};

export default TennesseeBEDAttachments;
