import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React from "react";
import styled from "styled-components";
import OpenModalButton from "components/Common/OpenModalButton";
import useTableData from "hooks/useTableData";
import { useHistory } from "react-router-dom";
import Center from "components/flex/Center";
import ErrorIcon from "@material-ui/icons/Error";
import InlineLink from "components/Common/navigation/InlineLink";
import CustomCardHeader from "components/Common/Containers/Card/CustomCardHeader";
import { dayjsUTC } from "helpers/apiHelpers";
import { useAuth } from "context/auth";

const StyledCard = styled(Card)`
  height: 50%;
`;

const SuccessIcon = styled(CheckCircleIcon)`
  color: ${({ theme }) => theme.palette.success.main};
`;

const StatusIcon = ({ status }) => (
  <ListItemIcon>
    {status === "submitted" ? (
      <Tooltip title="Submitted">
        <SuccessIcon />
      </Tooltip>
    ) : (
      <Tooltip title="Not Submitted">
        <ErrorIcon />
      </Tooltip>
    )}
  </ListItemIcon>
);

const ApplicationItem = ({
  application: {
    pdf_form: {
      name: applicationName,
      created_at,
      jurisdiction: { name: jurisdictionName },
    },
    application_status,
  },
  handleClick,
}) => (
  <>
    <ListItem alignItems="flex-start" button onClick={handleClick}>
      <StatusIcon status={application_status} />

      <ListItemText
        primary={applicationName}
        secondary={
          <>
            <Typography component="span" variant="body2" color="textPrimary">
              {jurisdictionName}
            </Typography>
            {` - ${dayjsUTC(created_at, "MM-DD-YYYY")}`}
          </>
        }
      />
    </ListItem>
    <Divider variant="inset" component="li" />
  </>
);

const ApplicationList = ({ applications, linkToApplicationsPage }) => {
  if (!applications.length) {
    return (
      <Center flexDirection="column">
        <Typography component="span">
          You haven&apos;t generated any applications yet.
        </Typography>
        <Typography component="span">
          Go to the
          <InlineLink to="active_applications">Manage Applications</InlineLink>
          page.
        </Typography>
      </Center>
    );
  }

  return (
    <Box
      height="100%"
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
    >
      <Box height="100%" overflow="auto">
        <List>
          {applications.slice(0, 12).map((application) => (
            <ApplicationItem
              key={application.id}
              application={application}
              handleClick={linkToApplicationsPage}
            />
          ))}
        </List>
      </Box>
      <Divider />
      <Box display="flex" justifyContent="center" pb={1} pt={1}>
        <Button color="primary" onClick={linkToApplicationsPage}>
          See All
        </Button>
      </Box>
    </Box>
  );
};

export const GeneratedApplications = () => {
  const { account_type } = useAuth();
  const controlledPath =
    account_type === "employee"
      ? "personal_details_generatedPdf"
      : "generatedPdf";
  const { loading, state: applications } = useTableData(controlledPath, false);
  const history = useHistory();

  const linkToApplicationsPage = () =>
    account_type === "business"
      ? history.push(`/app/bd_active_applications`)
      : history.push(`/app/active_applications`);

  return (
    <StyledCard variant="outlined">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <Box>
          <CustomCardHeader
            title="Application Manager"
            action={
              <OpenModalButton
                variant="contained"
                color="primary"
                text="Generate New"
                onClick={linkToApplicationsPage}
              />
            }
          />
          <Box height="350px">
            {loading ? (
              <Center>
                <CircularProgress />
              </Center>
            ) : (
              <ApplicationList
                applications={applications}
                linkToApplicationsPage={linkToApplicationsPage}
              />
            )}
          </Box>
        </Box>
      </Box>
    </StyledCard>
  );
};
