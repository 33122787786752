import { address } from "helpers/initialValues";

export const defaultInitialValues = {
  new_mexico: {
    associated_gaming_entity_email: "",
    associated_business_entity_id: null,
    associated_gaming_entity_fax_number: "",
    gaming_entity_relationship_description: "",
    trade_and_dba_names: "",
    association_with_business_entity: "",
    alcohol_and_drugs_related_issues_explanation: "",
    insurance_claims_explanation: "",
  },
  new_mexico_business_address: address,
  new_mexico_principal_role: null,
};

export const endpoint = "new_mexico_disclosure_questions_pdSupplement";
