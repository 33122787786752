import React from "react";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Certificate of Naturalization",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        If you are a naturalized citizen of the United States, attach a copy of
        your Certificate of Naturalization, labeled as Exhibit 6OH in the
        <CustomLink to="citizenship" /> section.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Criminal Records",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Copy of your criminal records report issued in the last 90 days by a
        duly authorized entity in the country of which you are a citizen. If you
        are unable to provide such a report, provide a written explanation as to
        the circumstances preventing you from doing so. Label responsive
        information as Exhibit 7EOH.
      </>
    ),
  },
  {
    type: fieldTypes.dropZone,
    name: "ohio.criminal_records_attachment_group_id",
    description: "Criminal Records",
    label: "Upload Attachment",
    xs: 12,
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Credit Check Report",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Copy of credit check report issued in the last 90 days by a duly
        authorized entity in the country of which you are a citizen. If you are
        unable to provide such a report, provide a written explanation as to the
        circumstances preventing you from doing so. Label responsive information
        as Exhibit 7FOH.
      </>
    ),
  },
  {
    type: fieldTypes.dropZone,
    name: "ohio.credit_check_attachment_group_id",
    description: "Credit Check Report",
    label: "Upload Attachment",
    xs: 12,
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "USCIS Documentation",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        If you are not a United States citizen, but you are a legally authorized
        permanent resident alien or you are authorized to be employed in the
        United States, provide your USCIS “A” Number or other USCIS
        authorization in the space provided, and attach to this form, labeled as
        Exhibit 8OH, a copy of your USCIS identification card and/or any other
        USCIS document that permits, conditions, or restricts your employment.
        Upload documents in the
        <CustomLink to="citizenship" /> section.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Taxes",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach to this form, labeled as Exhibit 11OH, a copy of each IRS Form
        1040 and 1040X (Amended Return) and all appropriate schedules filed by
        you in the last five years. If you and your spouse filed separate tax
        returns for any year in the last five years, also attach a copy of your
        spouse&apos;s tax returns. Upload documents in the
        <CustomLink to="us_taxes" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Have you or your spouse ever filed any type of tax return, statement, or
        form in any jurisdiction outside the United States within the last ten
        years? Attach to this form, labeled as Exhibit 14OH, a copy of each such
        tax return and all appropriate schedules or other attachments required
        by the tax authorities of the foreign jurisdiction. Upload documents in
        the <CustomLink to="non_us_taxes" /> section.
      </>
    ),
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "ohio_attachments_pdSupplement",
};
