import React from "react";

export const columns = [
  {
    title: "Insurer",
    field: "insurer_pd_business_entity.business_name",
  },
  {
    title: "Relative Name",
    field: "relative_pd_person.full_name",
  },
  { field: "purchase_date", format: "date" },
  { field: "policy_number" },
  { field: "beneficiaries" },
  { field: "face_value", format: "currency" },
  { field: "surrender_value", format: "currency" },
];

export const tableQuestions = (
  <>
    Provide details on the cash value of all life insurance policies held by
    you, your spouse or your dependent children.
  </>
);

export const nonRequired = ["relative_pd_person_id"];

export const endpoint = "life_insurance_lifeInsurance";

export const defaultInitialValues = {
  relative_pd_person_id: null,
  insurer_pd_business_entity_id: null,
  purchase_date: null,
  policy_number: "",
  beneficiaries: "",
  face_value: null,
  annual_premium: null,
  surrender_value: null,
  surrender_value_date: null,
  policy_attachment_group_id: null,
};

export const modalTitle = "Insurance Details";

export const dataHookProps = {
  defaultInitialValues,
  nonRequired,
  endpoint,
};
