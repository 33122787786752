/* eslint-disable react/display-name */
import React from "react";
import { getFormComponents } from "componentGenerators/helpers";
import AnnualIncome from "components/Pages/PD/Financial/AnnualIncome";
import colorado_kapa_supplement_disclosure_questions from "components/Pages/Supplemental/PD/ColoradoKAPA/ColoradoKAPADisclosureQuestions/ColoradoKAPADisclosureQuestions";
import colorado_kapa_supplement_associated_person_only from "components/Pages/Supplemental/PD/ColoradoKAPA/ColoradoKAPAAssociatedPersonOnly/ColoradoKAPAAssociatedPersonOnly";
import colorado_kapa_supplement_attachments from "components/Pages/Supplemental/PD/ColoradoKAPA/ColoradoKAPAAttachments/ColoradoKAPAAttachments";
import colorado_kapa_supplement_financial_history from "components/Pages/Supplemental/PD/ColoradoKAPA/ColoradoKAPAFinancialHistory/ColoradoKAPAFinancialHistory";
import colorado_kapa_supplement_important_notice from "components/Pages/Supplemental/PD/ColoradoKAPA/ColoradoKAPAImportantNotice/ColoradoKAPAImportantNotice";
import colorado_kapa_supplement_licenses from "components/Pages/Supplemental/PD/ColoradoKAPA/ColoradoKAPALicenses/ColoradoKAPALicenses";
import colorado_kapa_supplement_military_service from "components/Pages/Supplemental/PD/ColoradoKAPA/ColoradoKAPAMilitaryService/ColoradoKAPAMilitaryService";

export default {
  ...getFormComponents({
    colorado_kapa_supplement_disclosure_questions,
    colorado_kapa_supplement_associated_person_only,
    colorado_kapa_supplement_attachments,
    colorado_kapa_supplement_financial_history,
    colorado_kapa_supplement_important_notice,
    colorado_kapa_supplement_licenses,
    colorado_kapa_supplement_military_service,
  }),
  colorado_kapa_supplement_annual_income: (props) => (
    <AnnualIncome
      {...props}
      jurisdiction="colorado"
      endpoint="colorado_kapa_supplement_annual_income_"
    />
  ),
};
