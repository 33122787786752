import React from "react";
import { Route } from "react-router-dom";
import { useAuth } from "context/auth";
import { Card } from "@material-ui/core";
import "./pdftools.css";

// API
import { PDFValidationTools } from "controllers/developerTools.js";

// Toolkit
import Debug from "./Tools/Debug";
import DataIndex from "./Tools/DataIndex";
import Report from "./Tools/Report";
import CollaboratorsProvider from "context/collaborators";
import AccountDropdown from "components/Common/AccountDropdown/AccountDropdown";
import PageCreation from "./Tools/PageCreation/PageCreation";
import Navbar from "components/DevTools/PdfTools/Components/Navbar/Navbar";
import { PdfToolsProvider } from "./Context/PdfToolsProvider";

const PDFTools = () => {
  const { loading, employee_id, bd_organization_id } = useAuth();

  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <CollaboratorsProvider>
      <PdfToolsProvider>
        <div>
          <Card style={{ padding: "0px 0px 0px 10px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h1>Onecomply Applications Tools</h1>
              <AccountDropdown redirect={false} />
            </div>
          </Card>
          <Navbar />
          <Route path="/pdf_tools/debug">
            <Debug
              PDFValidationTools={PDFValidationTools}
              employee_id={employee_id}
              bd_organization_id={bd_organization_id}
            />
          </Route>
          <Route path="/pdf_tools/index">
            <DataIndex PDFValidationTools={PDFValidationTools} />
          </Route>
          <Route path="/pdf_tools/page-creation">
            <PageCreation PDFValidationTools={PDFValidationTools} />
          </Route>
          <Route path="/pdf_tools/report">
            <Report PDFValidationTools={PDFValidationTools} />
          </Route>
        </div>
      </PdfToolsProvider>
    </CollaboratorsProvider>
  );
};

export default PDFTools;
