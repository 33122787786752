import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";
import FormikNumberField from "components/Formik/fields/FormikNumberField";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <FormikTextField label="Estate/Trust Name" name="estate_name" xs={4} />
      <FormikTextField
        label="Location of Estate/Trust"
        name="estate_location"
        xs={8}
      />
      <FormikTextField label="Position/Interest Held" name="position" xs={4} />
      <FormikTextField
        label="Dates on which positions where held or Interest Received"
        name="dates_position_held"
        xs={8}
      />
      <FormikNumberField
        label="Compensation Value"
        name="compensation_amount"
        xs={4}
      />
      <FormikTextField
        label="Nature of Benefit Received"
        name="nature_of_benefit"
        xs={8}
      />
    </Grid>
  );
};

const Executor = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "executor_executorship",
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default Executor;
