import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import {
  SectionSubHeader,
  SectionLabel,
} from "components/Common/LayoutComponents";

import FormikTextField from "components/Formik/fields/FormikTextField";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useForm from "hooks/useForm.js";
import { defaultInitialValues, endpoint } from "./config";

import React from "react";
import FormikCheckBoxGroup from "components/Formik/formGroups/FormikCheckBoxGroup";
import BusinessAssociation from "components/Common/BusinessAssociation/BusinessAssociation";

const Form = () => {
  const {
    newYorkAssociationType,
    newYorkApplicationType,
    newYorkPositionType,
  } = useMenuOptions();

  return (
    <EnhancedFormikPage>
      <Grid container spacing={2}>
        <BusinessAssociation
          header="Associated Gaming Entity (Your Association)"
          label={"Name of Entity"}
          name={"new_york.associated_pd_business_entity_id"}
        />

        <SectionSubHeader label="Reason for filling" />

        <SectionLabel label="I am applying in association with a:" />

        <FormikCheckBoxGroup
          name="new_york_association_type"
          options={newYorkAssociationType}
        />

        <SectionLabel label="I am applying for a:" />

        <FormikCheckBoxGroup
          name="new_york_application_type"
          options={newYorkApplicationType}
        />

        <SectionLabel label="I am a:" />

        <FormikCheckBoxGroup
          name="new_york_position_type"
          options={newYorkPositionType}
        />

        <FormikTextField
          label="Other"
          name="new_york.position_type_other"
          xs={8}
        />
      </Grid>
    </EnhancedFormikPage>
  );
};

const NewYorkDisclosureQuestions = ({ name }) => {
  const formData = useForm({
    defaultInitialValues: defaultInitialValues,
    endpoint: endpoint,
    noValidate: true,
  });

  return (
    <FormSectionWrapper title={name}>
      <FormContextProvider formData={formData}>
        <Form />
      </FormContextProvider>
    </FormSectionWrapper>
  );
};

export default NewYorkDisclosureQuestions;
