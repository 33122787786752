import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Contractor Business",
  },
  {
    type: fieldTypes.label,
    label:
      "Describe the type of product and/or service(s) you intend to provide.",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "bd_maryland_swcla.contractor_business",
    rows: 2,
    xs: 12,
  },
  {
    type: fieldTypes.subHeader,
    label: "Licensee Association",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label:
      "Name the Sports Wagering Licensee(s) or Sports Wagering License Applicant(s) with whom you have an agreement.",
  },
  {
    type: fieldTypes.text,
    label: "Name of Licensee or Applicant",
    name: "bd_maryland_swcla.licensee_association",
    rows: 1,
    xs: 12,
  },
  {
    type: fieldTypes.subHeader,
    label: "Contact Person",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: "Select a Point-of-Contact for the applicant.",
  },
  {
    type: fieldTypes.bdPersonSelect,
    label: "Select Person",
    name: "bd_maryland_swcla.contact_person_id",
  },
];

export default [
  {
    type: "form",
    formSchema,
    endpoint: "bdSupplement",
  },
];
