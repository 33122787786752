import { Grid } from "@material-ui/core";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import PDRelativeSelect from "components/Formik/formGroups/CrudSelect/selects/PDRelativeSelect.jsx";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import {
  columns,
  dataHookProps,
  modalTitle,
  tableQuestions,
} from "./config.js";
import PDLawEnforcementSelect from "components/Formik/formGroups/CrudSelect/selects/PDLawEnforcementSelect.jsx";
import EnhancedFileDropzone from "components/Common/FileDropZone/EnhancedFileDropzone.jsx";

const Form = () => {
  const { criminalDispositionType } = useMenuOptions();

  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Relative" />
      <PDRelativeSelect />

      <SectionSubHeader label="Law Enforcement or Court" divider />
      <PDLawEnforcementSelect />

      <SectionSubHeader label="Incident Details" divider />
      <FormikDatePicker label="Date of Charge" name="charge_date" xs={3} />
      <FormikTextField label="Age at the Time" name="age_at_time" xs={3} />
      <FormikTextField label="Nature of Charge" name="nature" xs={3} />
      <FormikTextField label="Case Number" name="case_number" xs={3} />

      <SectionSubHeader label="Incident Location" divider />
      <FormikTextField label="Incident City" name="incident_city" xs={4} />
      <FormikTextField label="Incident County" name="incident_county" xs={4} />
      <FormikTextField label="Incident State" name="incident_state" xs={4} />

      <SectionSubHeader label="Outcome" divider />
      <FormikSelect
        label="Disposition"
        name="disposition"
        options={criminalDispositionType}
        xs={4}
      />
      <FormikTextField label="Sentence" name="sentence" xs={4} />
      <FormikTextField
        label="Length of Incarceration"
        name="incarceration_length"
        xs={4}
      />
      <FormikTextField
        label="Location of Incarceration"
        name="incarceration_location"
        xs={4}
      />
      <SectionSubHeader label="Documentation" divider />
      <SectionLabel label="Attach a narrative of the event (not required by all jurisdictions)" />
      <EnhancedFileDropzone
        description="Family Arrests & Charges"
        name="attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};

const FamilyArrest = ({ name }) => {
  const formData = useTabularData(dataHookProps);

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={modalTitle}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
    </FormSectionProvider>
  );
};

export default FamilyArrest;
