import PropTypes from "prop-types";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/Visibility";

import InfoIcon from "@material-ui/icons/Info";
import _ from "lodash";
import React from "react";
import styled, { css } from "styled-components";
import { formatRecord } from "../helpers";
import SentryErrorBoundary from "error/SentryErrorBoundary";

const StyledRow = styled(TableRow)`
  ${({ $hasMissingRequired, theme }) =>
    $hasMissingRequired &&
    css`
      background-color: ${theme.palette.error.lightest};
      min-height: 60vh;
      max-height: 60vh;
      overflow: auto;
    `}
`;

const MissingValuesTooltip = ({
  missingRequiredItems,
  overwriteMissingRequiredDisplay = null,
}) => (
  <Tooltip
    data-testid="missing-values"
    title={
      <>
        <Typography color="inherit">Missing Values</Typography>
        {missingRequiredItems.map((item) =>
          overwriteMissingRequiredDisplay?.[item] ? (
            <Typography color="inherit" key={item}>
              - {overwriteMissingRequiredDisplay[item]}
            </Typography>
          ) : (
            <Typography color="inherit" key={item}>
              - {_.startCase(item)}
            </Typography>
          )
        )}
      </>
    }
  >
    <InfoIcon color="secondary" fontSize="small" />
  </Tooltip>
);

export const Row = ({
  columns,
  record,
  handleEdit,
  handleDelete,
  handleView,
  missingRequiredItems,
  hasActions = true,
  hasMissingRequired,
  viewOnly = false,
  textStyle = {},
  overwriteMissingRequiredDisplay,
  getRecordId = null,
}) => {
  const id = getRecordId ? getRecordId(record) : record.id || record.tableId;
  const onEdit = (e) => handleEdit && handleEdit(id, e);
  const onDelete = (e) => handleDelete && handleDelete(id, e);
  const onView = (e) => handleView && handleView(id, e);

  return (
    <>
      <StyledRow $hasMissingRequired={hasMissingRequired}>
        {hasActions ? (
          <TableCell width="10%">
            <Box display="flex" alignItems="center">
              <Box width={"25px"} display="flex" alignItems="center">
                {hasMissingRequired && (
                  <MissingValuesTooltip
                    missingRequiredItems={missingRequiredItems}
                    overwriteMissingRequiredDisplay={
                      overwriteMissingRequiredDisplay
                    }
                  />
                )}
              </Box>

              {handleEdit ? (
                <IconButton
                  onClick={onEdit}
                  style={{ marginRight: "0px" }}
                  data-testid="edit-record"
                >
                  {viewOnly ? <ViewIcon /> : <EditIcon />}
                </IconButton>
              ) : null}
              {handleView ? (
                <IconButton
                  onClick={onView}
                  style={{ marginRight: "0px" }}
                  data-testid="edit-record"
                >
                  <ViewIcon />
                </IconButton>
              ) : null}
              {handleDelete ? (
                <IconButton onClick={onDelete} data-testid="delete-record">
                  <DeleteIcon />
                </IconButton>
              ) : null}
            </Box>
          </TableCell>
        ) : (
          <TableCell width="5%"></TableCell>
        )}
        {columns.map(({ field, render, cellProps, format }) => {
          return (
            <TableCell key={field} style={{ ...textStyle }} {...cellProps}>
              <SentryErrorBoundary fallback={() => "Error rendering"}>
                {render ? render(record) : formatRecord(record, field, format)}
              </SentryErrorBoundary>
            </TableCell>
          );
        })}
      </StyledRow>
    </>
  );
};

Row.propTypes = {
  columns: PropTypes.array,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  handleView: PropTypes.func,
  getRecordId: PropTypes.func,
  hasActions: PropTypes.bool,
  hasMissingRequired: PropTypes.bool,
  missingRequiredItems: PropTypes.array,
  overwriteMissingRequiredDisplay: PropTypes.object,
  record: PropTypes.object,
  textStyle: PropTypes.object,
  viewOnly: PropTypes.bool,
};
