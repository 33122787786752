import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "pending litigation",
  },
  {
    type: fieldTypes.label,
    label:
      "If the Applicant has any pending litigation provide below an assessment of the impact that the action may have on Applicant’s business:",
  },
  {
    type: fieldTypes.text,
    label: "Statement",
    name: "indiana_supplier_license.pending_litigation_assessment",
    rows: 4,
    xs: 12,
  },
];

export default [
  {
    type: "form",
    formSchema,
    endpoint: "bdSupplement",
  },
];
