import PropTypes from "prop-types";
import React from "react";
import { get, startCase } from "lodash";
import { Box } from "@material-ui/core";
import BulkActions from "../BulkActions";
import PermissionMenu from "../PermissionMenu";
import { renameTopLevel } from "../helpers";
import {
  ActionsBox,
  CustomTypography,
  StyledDivider,
  StyledTreeItemLeaf,
  StyledTreeItemMidLevel,
  StyledTreeItemTopLevel,
} from "./StyledComponents";

const CorePagesTab = ({ topLevel, state, dispatch, topLevelNode, index }) => (
  <Box key={topLevel}>
    <StyledTreeItemTopLevel
      key={topLevel}
      nodeId={topLevel}
      label={renameTopLevel(topLevel)}
    >
      {Object.keys(state[topLevel]).map((midLevel) => {
        return (
          <StyledTreeItemMidLevel
            key={midLevel}
            nodeId={midLevel}
            label={
              <ActionsBox justifyContent="space-around">
                <CustomTypography variant="body1">
                  {startCase(midLevel)}
                </CustomTypography>
                <BulkActions
                  state={state[topLevel][midLevel]}
                  name={`${topLevel}.${midLevel}`}
                  dispatch={dispatch}
                />
              </ActionsBox>
            }
          >
            {Object.keys(state[topLevel][midLevel]).map((leafName) => {
              const path = `${topLevel}.${midLevel}.${leafName}`;
              return (
                <StyledTreeItemLeaf
                  key={leafName}
                  nodeId={leafName}
                  label={
                    <ActionsBox justifyContent="space-between">
                      <CustomTypography variant="body1">
                        {startCase(leafName)}
                      </CustomTypography>
                      <PermissionMenu
                        name={path}
                        permissions={get(state, path)}
                        dispatch={dispatch}
                      />
                    </ActionsBox>
                  }
                />
              );
            })}
          </StyledTreeItemMidLevel>
        );
      })}
    </StyledTreeItemTopLevel>
    {topLevelNode.length - 1 > index ? <StyledDivider /> : null}
  </Box>
);

CorePagesTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  state: PropTypes.object.isRequired,
  topLevel: PropTypes.string.isRequired,
  topLevelNode: PropTypes.array.isRequired,
};

export default CorePagesTab;
