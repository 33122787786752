import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import {
  SectionLabel,
  SectionSubHeader,
  Divider,
} from "components/Common/LayoutComponents";
import FormikCheckBoxGroup from "components/Formik/formGroups/FormikCheckBoxGroup";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useForm from "hooks/useForm";
import React from "react";
import { tabularDataParams } from "./config.js";

const PennEEAApplicantInformationForm = () => {
  const {
    slot_machine_checkbox,
    table_game_checkbox,
    interactive_gaming_checkbox,
    video_gaming_checkbox,
    fantasy_contest_checkbox,
    sports_wagering_checkbox,
    application_period_checkbox,
  } = useMenuOptions();

  return (
    <Grid container spacing={1}>
      <SectionSubHeader>License Type</SectionSubHeader>
      <SectionLabel>
        Check the appropriate box to indicate the type of license for which the
        entity is applying. If applicant is an affiliate, intermediary,
        subsidiary, or holding company of a company applying for an Enterprise
        license type below, DO NOT complete any of the check boxes below.
      </SectionLabel>
      <FormikCheckBoxGroup
        label="Slot Machine Related"
        name="bd_pennsylvania_eea.bd_pa_eea_slot_machine_checkbox"
        menuOptions={slot_machine_checkbox}
        xs={4}
        flexDirection={"column"}
      />
      <FormikCheckBoxGroup
        label="Table Game Related"
        name="bd_pennsylvania_eea.bd_pa_eea_table_game_checkbox"
        menuOptions={table_game_checkbox}
        xs={4}
        flexDirection={"column"}
      />
      <FormikCheckBoxGroup
        label="Interactive Gaming (iGaming) Related"
        name="bd_pennsylvania_eea.bd_pa_eea_interactive_gaming_checkbox"
        menuOptions={interactive_gaming_checkbox}
        xs={4}
        flexDirection={"column"}
      />
      <FormikCheckBoxGroup
        label="Video Gaming Terminal (VGT) Related"
        name="bd_pennsylvania_eea.bd_pa_eea_video_gaming_checkbox"
        menuOptions={video_gaming_checkbox}
        xs={4}
        flexDirection={"column"}
      />
      <FormikCheckBoxGroup
        label="Fantasy Contest Related"
        name="bd_pennsylvania_eea.bd_pa_eea_fantasy_contest_checkbox"
        menuOptions={fantasy_contest_checkbox}
        xs={4}
        flexDirection={"column"}
      />
      <FormikCheckBoxGroup
        label="Sports Wagering Related"
        name="bd_pennsylvania_eea.bd_pa_eea_sports_wagering_checkbox"
        menuOptions={sports_wagering_checkbox}
        xs={4}
        flexDirection={"column"}
      />
      <Divider />

      <SectionSubHeader>Period</SectionSubHeader>
      <FormikCheckBoxGroup
        label="Application Period"
        name="bd_pennsylvania_eea.bd_pa_eea_application_period_checkbox"
        menuOptions={application_period_checkbox}
        xs={4}
      />
    </Grid>
  );
};

const PennEEAApplicantInformation = ({ name }) => {
  const formData = useForm(tabularDataParams);

  return (
    <FormSectionProvider formData={formData} title={`Pennsylvania EEA ${name}`}>
      <EnhancedFormikPage>
        <PennEEAApplicantInformationForm />
      </EnhancedFormikPage>
    </FormSectionProvider>
  );
};

export default PennEEAApplicantInformation;
