import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Business Ownership",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Does the applicant have any financial, ownership interest or other relationship with an Applicant or Licensee with the Michigan Gaming Control Board?",
    name: "michigan_supplier_license.has_ownership_in_michigan_licensee",
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label:
      "If yes, please explain the nature of interest or relationship below:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "michigan_supplier_license.ownership_in_michigan_licensee_details",
    xs: 12,
    multiline: true,
    rows: 4,
  },
];

const nonUSAssets = {
  type: "readOnlyTable",
  endpoint: "views/bd_assets_non_us",
  linkTo: ["bd_assets"],
  columns: [
    {
      title: "Issuing Entity",
      field: "issuing_business_name",
    },
    {
      field: "asset_type",
      format: "startCase",
    },
    {
      field: "type_of_stock",
      format: "startCase",
    },
    {
      title: "Held From",
      field: "purchase_date",
      format: "date",
    },
    {
      field: "market_value",
      format: "currency",
    },
  ],
  dependsOnQueryCount:
    "Does the applicant have any direct, indirect or attributed legal or beneficial interest in any business entity outside the United States?",
};

export default [
  {
    type: "form",
    formSchema,
    endpoint: "bdSupplement",
  },
  nonUSAssets,
];
