import { fieldTypes } from "componentGenerators/formGenerator/config";
import React from "react";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Passport",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a Certified Copy of your Birth Certificate in the
        <CustomLink to="citizenship" /> section.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Bankruptcies",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Has the applicant ever filed a petition for any type of bankruptcy,
        insolvency or liquidation under any bankruptcy or insolvency law in any
        jurisdiction or had a petition for involuntary bankruptcy filed against
        the applicant? If YES, attach certified copies of the petition and order
        of discharge or plan of confirmation relating to each such filing to
        this application and reference as Section F-10 in the
        <CustomLink to="bankruptcies" /> section.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Criminal History",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Has the applicant ever been convicted of, or plead guilty or nolo
        contendere to, any charge or offense? Applicant shall include all
        DWI/DUI convictions; however, minor traffic convictions need not be
        included. Attach certified copies of documents relating to each matter
        to this application and reference as Section F-2 in the
        <CustomLink to="arrests" /> section.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Military Service History",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Has applicant ever served in a military organization or been an active
        or inactive member of the reserve forces of the United States or any
        other country? If YES, please upload a copy of DD214, or equivalent of,
        and reference as Section E-1 Military Service
        <CustomLink to="military" /> section.
      </>
    ),
  },
];

export default {
  type: "readOnlyPage",
  formSchema,
  endpoint: "pdSupplement",
};
