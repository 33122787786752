import React from "react";

import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents/";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm.js";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import { Grid } from "@material-ui/core";
import { YesNo } from "components/Formik/fields";
import { defaultInitialValues, endpoint } from "./config.js";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <SectionSubHeader>
        CIVIL, CRIMINAL AND INVESTIGATORY PROCEEDINGS
      </SectionSubHeader>
      <SectionLabel label="During the last 10-year period, have you held a 5% or greater interest in or been a director, officer or principal employee of any entity that:" />
      <YesNo
        label="Has made or has been charged with (either itself or through third parties for it), bribes or kickbacks to any government official, domestic
        or foreign, to obtain favorable treatment or to any company, employee or organization to obtain a favorable advantage?"
        name="new_jersey_ancillary.nj_ancillary_q_35_a"
        xs={12}
      />
      <YesNo
        label="Has held a foreign bank account or has had authority to control disbursements from a foreign bank account?"
        name="new_jersey_ancillary.nj_ancillary_q_35_b"
        xs={12}
      />
      <YesNo
        label="Has maintained a bank account or other account, whether domestic or foreign, which was not reflected on the books or records of the
        business?"
        name="new_jersey_ancillary.nj_ancillary_q_35_c"
        xs={12}
      />
      <YesNo
        label="Has maintained a domestic or foreign-numbered bank account or other bank account in a name other than the name of the business?"
        name="new_jersey_ancillary.nj_ancillary_q_35_d"
        xs={12}
      />
      <YesNo
        label="Has donated or loaned corporate funds or corporate property for the use or benefit of, or for the purpose of opposing, any government, political party, candidate or committee, either domestic or foreign?"
        name="new_jersey_ancillary.nj_ancillary_q_35_e"
        xs={12}
      />
      <YesNo
        label="Has compensated any of its directors, officers or employees for time and expenses incurred in performing services for the benefit of or in
        opposing any government or political party, either domestic or foreign?"
        name="new_jersey_ancillary.nj_ancillary_q_35_f"
        xs={12}
      />
      <YesNo
        label="Has made any loans, donations or other disbursements to its directors, officers or employees, for the purpose of making political
        contributions or reimbursing such individuals for political contributions?"
        name="new_jersey_ancillary.nj_ancillary_q_35_g"
        xs={12}
      />
    </Grid>
  );
};

const NewJerseyAncillaryLegalProceedings = ({ title }) => {
  const formData = useForm({
    defaultInitialValues,
    endpoint,
  });

  return (
    <FormSectionWrapper title={title}>
      <FormContextProvider formData={formData}>
        <EnhancedFormikPage>
          <Form />
        </EnhancedFormikPage>
      </FormContextProvider>
    </FormSectionWrapper>
  );
};

export default NewJerseyAncillaryLegalProceedings;
