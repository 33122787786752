export const defaultInitialValues = {
  new_jersey_ancillary: {
    nj_ancillary_q_35_a: null,
    nj_ancillary_q_35_b: null,
    nj_ancillary_q_35_c: null,
    nj_ancillary_q_35_d: null,
    nj_ancillary_q_35_e: null,
    nj_ancillary_q_35_f: null,
    nj_ancillary_q_35_g: null,
  },
};

export const endpoint = "new_jersey_ancillary_legal_proceedings_pdSupplement";
