import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import FormikCheckBoxGroup from "components/Formik/formGroups/FormikCheckBoxGroup";
import YesNo from "components/Formik/formGroups/YesNo";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useForm from "hooks/useForm.js";
import React from "react";

const Form = () => {
  const { arkEmploymentFacilityCheckbox } = useMenuOptions();
  const formData = useForm({
    defaultInitialValues: {
      arkansas: {
        facility_where_employed: null,
        has_pari_mutuel_license: null,
      },
    },
    endpoint: "arkansas_disclosure_questions_pdSupplement",
  });

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormikCheckBoxGroup
              label="Facility where employed and/or doing business"
              menuOptions={arkEmploymentFacilityCheckbox}
              name="arkansas.facility_where_employed"
              row
            />
          </Grid>
          <YesNo
            label="Have you ever held an Arkansas pari-mutuel license?"
            name="arkansas.has_pari_mutuel_license"
          />
        </Grid>
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

const ArkansasDisclosureQuestions = (props) => {
  return (
    <FormSectionWrapper {...props}>
      <Form />
    </FormSectionWrapper>
  );
};

export default ArkansasDisclosureQuestions;
