import { Box, Card, Container, Grid, Typography } from "@material-ui/core";
import useTabularData from "hooks/tableHooks/useTabularData";
import useToggle from "hooks/useToggle";
import React from "react";
import ActionsAndStats from "components/Pages/LicensingControl/components/ActionsAndStats";
import AddEntity from "components/Pages/LicensingControl/components/AddEntity";
import { EmployeeEmploymentTable } from "components/Pages/LicensingControl/Employees/EmployeeEmploymentTable";
import { ExpiringSoon } from "components/Pages/LicensingControl/components/ExpiringCard";
import { GenerateReportDialog } from "components/Pages/LicensingControl/components/GenerateReportDialog";
import { EmployeeDetailsModal } from "components/Pages/LicensingControl/Employees/components/EmployeeDetailsModal";
import DeleteConfirmation from "components/Common/Dialogs/DeleteConfirmation";
import {
  employeeInitialValues,
  validationSchema,
} from "components/Pages/LicensingControl/Employees/config";
import { EmploymentForm } from "components/Pages/LicensingControl/Employees/EmploymentForm";

import { ArchiveEmployee } from "./ArchiveEmployee";
import { employmentsQueryKey } from "./config";
import { useRecordFromQueryParam } from "components/Pages/LicensingControl/hooks/useRecordFromQueryParam";
import {
  findNonCompliant,
  findExpiringSoon,
  extractLicenses,
} from "components/Pages/LicensingControl/helpers.js";
import withErrorBoundary from "hocs/withErrorBoundary";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const HeaderArea = () => (
  <Box display="flex" alignItems="center">
    <Typography variant="h1" style={{ paddingRight: "20px" }} gutterBottom>
      Employees
    </Typography>
  </Box>
);

const Employees = ({ location }) => {
  const employeeDetailsDialog = useToggle();

  const generateReportDialog = useToggle();

  const addEntityDialog = useToggle();

  const [
    licensesFormInitiallyOpen,
    setLicensesFormInitiallyOpen,
  ] = React.useState(false);

  const formData = useTabularData({
    defaultInitialValues: employeeInitialValues,
    queryKey: employmentsQueryKey,
    endpoint: "licensing/lcPerson",
    noValidate: true,
    fetchOptions: { useFullEndpoint: false },
  });

  const {
    data = [],
    status,
    editableListActions: { handleEditRecord, handleDeleteRecord },
    currentRecord,
    open,
    clearCurrentRecord,
    isNewRecord,
    deleteDialogProps,
    formikProps: { open: _open, onCancel: _onCancel, ...employmentFormikProps },
  } = formData;

  const { clearQueryParam } = useRecordFromQueryParam({
    handleEditRecord,
    isSuccess: status === "success",
    location,
  });

  const handleEmployeeDetailsModalClose = () => {
    clearCurrentRecord();
    setLicensesFormInitiallyOpen(false);
    clearQueryParam();
  };

  const nonCompliantEmployees = React.useMemo(
    () => (status === "success" ? findNonCompliant(data) : []),
    [status, data]
  );

  const expiringSoonEmployees = React.useMemo(
    () => (status === "success" ? findExpiringSoon(data) : []),
    [status, data]
  );

  const [employeeToBeArchived, setEmployeeToBeArchived] = React.useState(null);

  const handleArchiveRecord = (id) => {
    setEmployeeToBeArchived(id);
  };

  const getSortedRecords = (records) =>
    records
      .filter(({ to_date }) => !!to_date)
      .sort((a, b) => new Date(a.to_date) - new Date(b.to_date));

  const sortedRecords = React.useMemo(
    () => (status === "success" ? getSortedRecords(extractLicenses(data)) : []),
    [data, status]
  );

  return (
    <>
      <ArchiveEmployee
        employeeToBeArchived={employeeToBeArchived}
        setEmployeeToBeArchived={setEmployeeToBeArchived}
      />

      <DeleteConfirmation {...deleteDialogProps} />
      {currentRecord && (
        <EmployeeDetailsModal
          currentRecord={currentRecord}
          open={!isNewRecord && open}
          onClose={handleEmployeeDetailsModalClose}
          formikProps={employmentFormikProps}
          licensesFormInitiallyOpen={licensesFormInitiallyOpen}
        />
      )}
      {generateReportDialog.on && (
        <GenerateReportDialog
          open={generateReportDialog.on}
          onCancel={generateReportDialog.setOff}
          closeDialog={generateReportDialog.setOff}
        />
      )}
      <AddEntity
        open={addEntityDialog.on}
        handleClose={addEntityDialog.setOff}
        handleEditEntityRecord={handleEditRecord}
        setLicensesFormInitiallyOpen={setLicensesFormInitiallyOpen}
        initialValues={employeeInitialValues}
        validationSchema={validationSchema}
        endpoint="licensing/LcEmployment"
        queryKey="employments"
        modelTitle="Add Employee"
      >
        <EmploymentForm />
      </AddEntity>
      <Container>
        <Grid container spacing={6} direction="row" justify="center">
          <Grid item xs={12}>
            <HeaderArea />
          </Grid>
          <Grid item xs={5}>
            <LoadingComponent status={status}>
              <ActionsAndStats
                addEntityButtonLink="manage_user"
                employeeCount={data.length}
                openAddEntityForm={addEntityDialog.setOn}
                openGenerateReportForm={generateReportDialog.setOn}
                nonCompliantCount={nonCompliantEmployees.length}
                expiringSoonCount={expiringSoonEmployees.length}
                expiringSoonLabel={
                  <>
                    Employees expiring <br /> in the next 30 days
                  </>
                }
                nonCompliantLabel="Employees compliant"
                addButtonLabel="Add Employee"
              />
            </LoadingComponent>
          </Grid>
          <Grid item xs={7}>
            <LoadingComponent status={status}>
              <ExpiringSoon
                handleClickRecord={handleEditRecord}
                licensesData={sortedRecords}
                keyLabel={"employees"}
                label="Expiring Employee Licenses"
              />
            </LoadingComponent>
          </Grid>
          <Grid item xs={12}>
            <LoadingComponent status={status}>
              <Card variant="outlined">
                <EmployeeEmploymentTable
                  records={data}
                  openEmployeeDetails={employeeDetailsDialog.setOn}
                  handleClickRecord={handleEditRecord}
                  handleDeleteRecord={handleDeleteRecord}
                  handleArchiveRecord={handleArchiveRecord}
                />
              </Card>
            </LoadingComponent>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default withErrorBoundary(Employees);
