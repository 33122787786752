import { getFormComponents } from "componentGenerators/helpers";
import bd_new_york_cvavls_supplement_application_information from "components/Pages/Supplemental/BD/NewYorkCVAVLS/NewYorkCVAVLSApplicationInformation/NewYorkCVAVLSApplicationInformation";
import bd_new_york_cvavls_supplement_tax_delinquency from "components/Pages/Supplemental/BD/NewYorkCVAVLS/NewYorkCVAVLSTaxDelinquency/NewYorkCVAVLSTaxDelinquency";
import bd_new_york_cvavls_supplement_miscellaneous from "components/Pages/Supplemental/BD/NewYorkCVAVLS/NewYorkCVAVLSMiscellaneous/NewYorkCVAVLSMiscellaneous";

export default getFormComponents({
  bd_new_york_cvavls_supplement_application_information,
  bd_new_york_cvavls_supplement_tax_delinquency,
  bd_new_york_cvavls_supplement_miscellaneous,
});
