import { formats } from "components/Common/Tables/Common/helpers";
import _ from "lodash";
import React from "react";
import { TruncatedChips } from "../../Common/Tables/Common/components/TruncatedChips";
import { checkAbilityToModifyUserAccess } from "./EditUserForm.jsx";

const PermissionChips = ({ permission }) => (
  <TruncatedChips data={permission} />
);

const allColumns = [
  {
    field: "first_name",
  },
  {
    field: "last_name",
  },
  {
    field: "email",
  },
  {
    field: "is_active",
    format: formats.boolean,
  },
  {
    field: "is_internal",
    format: formats.boolean,
  },
  {
    title: "Permissions",
    field: "permission",
    render: PermissionChips,
  },
  {
    field: "invitation_status",
    format: formats.startCase,
  },
];

let filteredColumns = allColumns;
if (!checkAbilityToModifyUserAccess()) {
  filteredColumns = _.filter(allColumns, (col) => col.field !== "is_internal");
}

export const columns = filteredColumns;
