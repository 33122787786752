import React from "react";
import PropTypes from "prop-types";
import { Attachment } from "@styled-icons/material";
import {
  FileImage,
  FileText,
  FilePdf,
  FileSpreadsheet,
} from "@styled-icons/bootstrap";

const FileIconsEnum = {
  // Text File Extensions
  doc: FileText,
  docx: FileText,
  pages: FileText,
  text: FileText,
  txt: FileText,

  // PDF File Extensions
  pdf: FilePdf,

  // Image File Extensions
  png: FileImage,
  jpeg: FileImage,
  jpg: FileImage,
  bmp: FileImage,

  // Spreadsheet File Extensions
  csv: FileSpreadsheet,
  xls: FileSpreadsheet,
  xlsx: FileSpreadsheet,
};

const FileIcon = ({ fileName }) => {
  const fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1);

  const Icon = FileIconsEnum[fileExtension] || Attachment;

  return <Icon size="18px" title={`${fileExtension.toUpperCase()} File`} />;
};

FileIcon.propTypes = {
  fileName: PropTypes.string.isRequired,
};

export default FileIcon;
