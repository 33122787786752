import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React from "react";

export const InfoAlert = ({ icon, children, severity = "info", ...rest }) => {
  return severity === "info" ? (
    <Box
      border={1}
      borderColor="grey.300"
      borderRadius="borderRadius"
      {...rest}
    >
      <Alert icon={icon} severity={severity}>
        {children}
      </Alert>
    </Box>
  ) : (
    <Box
      border={1}
      borderColor="grey.300"
      borderRadius="borderRadius"
      mb={1}
      {...rest}
    >
      <Alert icon={icon} severity={severity}>
        {children}
      </Alert>
    </Box>
  );
};

InfoAlert.propTypes = {
  children: PropTypes.node,
  severity: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
};
