import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm";
import React from "react";
import useEndPoint from "hooks/useEndpoint";

const Form = () => {
  return (
    <Grid container spacing={1}>
      <SectionSubHeader>Business Background</SectionSubHeader>
      <SectionLabel label="Description Of Competitive Conditions" />
      <FormikTextField
        multiline
        rows={4}
        label="Description"
        name="competitive_conditions"
        xs={12}
      />
      <SectionLabel label="Principal Products Produced and/ or services rendered" />
      <FormikTextField
        multiline
        rows={4}
        label="Description"
        name="principal_products_or_service"
        xs={12}
      />
      <SectionLabel label="Availability of Raw Materials, Critical Technology & Employees" />
      <FormikTextField
        multiline
        rows={4}
        label="Description"
        name="availability_of_resources"
        xs={12}
      />
      <SectionLabel label="Description of Any Other Material Reorganization, Readjustment or Succession of Applicant or Any of its Subsidiaries OR Acquisitions" />
      <FormikTextField
        multiline
        rows={4}
        label="Description"
        name="material_reorganization_readjustment_succession"
        xs={12}
      />
    </Grid>
  );
};

const ApplicantBackground = ({ innerRef }) => {
  const { bdBusinessEntityEndpoint } = useEndPoint("");
  const formDataBusinessBackground = useForm({
    defaultInitialValues: {
      competitive_conditions: "",
      principal_products_or_service: "",
      availability_of_resources: "",
      material_reorganization_readjustment_succession: "",
    },
    endpoint: bdBusinessEntityEndpoint,
  });
  return (
    <FormContextProvider
      formData={formDataBusinessBackground}
      showSaveButton={false}
      innerRef={innerRef}
    >
      <EnhancedFormikPage showSaveButton={false} innerRef={innerRef}>
        <Form />
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

export default ApplicantBackground;
