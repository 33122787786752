import { Box, Grid } from "@material-ui/core";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikSelect from "components/Formik/fields/FormikSelect";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";

import PDBusinessSelect from "components/Formik/formGroups/CrudSelect/selects/PDBusinessSelect.jsx";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import MultiFormWrapper from "components/Layout/SectionsLayout/MultiFormWrapper";
import { useMenuOptions } from "hooks/useMenuOptions.js";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { military } from "../../uiStrings";
import { columns, defaultInitialValues, endpoint } from "./config.js";
import MilitaryQuestion from "./MilitaryQuestion";
import MilitaryService from "./MilitaryService";

const MilitaryForm = () => {
  const { criminalDispositionType } = useMenuOptions();
  return (
    <Grid container spacing={2}>
      <PDBusinessSelect
        name="law_enforcement_pd_business_entity_id"
        label="Military Organization"
        endpoint="government_law_business_entities"
        formTitle="Add / Edit Military Organization"
        overrideDefaultValues={{
          business_type_checkbox: {
            is_law_enforcement: true,
            is_government: true,
          },
        }}
      />

      <FormikDatePicker label="Charge Date" name="charge_date" />
      <FormikSelect
        label="Disposition"
        name="disposition"
        options={criminalDispositionType}
      />

      <FormikTextField label="Nature of charge" name="nature" />
      <FormikTextField label="Sentence" name="sentence" />
    </Grid>
  );
};

const Military = ({ name }) => {
  const formData = useTabularData({ defaultInitialValues, endpoint });

  return (
    <FormSectionProvider formData={formData} title={name}>
      <MultiFormWrapper>
        <MilitaryQuestion />
        <MilitaryService title="Military Service" />
      </MultiFormWrapper>
      <Box pb={1}>
        <InfoAlert>{military.chargeQuestion}</InfoAlert>
      </Box>

      <EnhancedActionsTable
        title="Military Charges"
        columns={columns}
        addButtonText="Add Record"
      />
      <EnhancedFormikDialog title={name}>
        <MilitaryForm />
      </EnhancedFormikDialog>
    </FormSectionProvider>
  );
};

export default Military;
