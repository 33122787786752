import PropTypes from "prop-types";
import { Box, Button, ButtonGroup, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import React from "react";
import CustomLink from "components/Common/Link";
import LaunchIcon from "@material-ui/icons/Launch";
import { startCase } from "lodash";
import styled from "styled-components";

export const StyledIcon = styled(LaunchIcon)`
  font-size: 18px;
`;

const BulkActions = ({ handleDownloadSelected, handleDeleteSelected }) => (
  <ButtonGroup color="primary" size="small">
    <Button endIcon={<GetAppIcon />} onClick={handleDownloadSelected}>
      Download
    </Button>
    <Button endIcon={<DeleteIcon />} onClick={handleDeleteSelected}>
      Delete
    </Button>
  </ButtonGroup>
);

BulkActions.propTypes = {
  handleDownloadSelected: PropTypes.func,
  handleDeleteSelected: PropTypes.func,
};
const DownloadAll = ({ handleDownloadAll }) => (
  <ButtonGroup color="primary" size="small">
    <Button endIcon={<GetAppIcon />} onClick={handleDownloadAll}>
      Download All
    </Button>
  </ButtonGroup>
);

DownloadAll.propTypes = {
  handleDownloadAll: PropTypes.func,
};

export const Header = ({
  label,
  link,
  showBulkActions,
  filters,
  handleDownloadSelected,
  handleDownloadAll,
  handleDeleteSelected,
}) => {
  return (
    <Box p={2} height={"120px"}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box pr={3} display="flex" alignItems="center" height="55px">
          <Typography variant="h2" noWrap gutterBottom>
            {label}
          </Typography>
          <Box
            pl={link.length > 1 ? 1.5 : 0.5}
            display="flex"
            flexDirection="column"
          >
            {link.map((l) => (
              <CustomLink
                to={l}
                key={`link-key-${l}`}
                label={
                  <Box p={0.5}>
                    {link.length > 1 ? (
                      <Box display="flex">
                        <StyledIcon />
                        <Box ml={1}>{startCase(l)}</Box>
                      </Box>
                    ) : (
                      <LaunchIcon fontSize="small" />
                    )}
                  </Box>
                }
              />
            ))}
          </Box>
        </Box>

        {filters}
      </Box>
      <Box pt={1} height={"100%"}>
        {showBulkActions ? (
          <BulkActions
            handleDownloadSelected={handleDownloadSelected}
            handleDeleteSelected={handleDeleteSelected}
          />
        ) : (
          <DownloadAll handleDownloadAll={handleDownloadAll} />
        )}
      </Box>
    </Box>
  );
};

Header.propTypes = {
  filters: PropTypes.node,
  label: PropTypes.string,
  showBulkActions: PropTypes.bool,
  handleDownloadSelected: PropTypes.func,
  handleDownloadAll: PropTypes.func,
  handleDeleteSelected: PropTypes.func,
  link: PropTypes.array,
};
