import { Grid } from "@material-ui/core";

import React from "react";
import FormikInlineTable from "components/Common/Tables/FormikInlineTable/FormikInlineTable";
import { useQueryClient } from "react-query";
import { useFormikContext } from "formik";
import { FormikAsyncSelect } from "components/Formik/fields";
import useDelete from "queryHooks/useDelete";
import usePatch from "queryHooks/usePatch";
import { useSnackbar } from "notistack";

const CollaboratorInlineTable = () => {
  const account = useQueryClient().getQueryData("account");
  const {
    values: { id },
    values,
    setFieldValue,
  } = useFormikContext();

  const { mutateAsync: onDelete } = useDelete(
    "account",
    `account/${id}/collaborators/permissions`, //id represents the owner
    {
      onSuccess: ({ data }) => {
        setFieldValue(
          "collaborators",
          //When removing directly after adding the id of the new employee is saved as collaborator_id instead of employee_id
          values.collaborators.filter(({ employee_id, ...rest }) =>
            rest.collaborator_id
              ? rest.collaborator_id !== data.employee_id
              : employee_id !== data.employee_id
          )
        );
        return null;
      },
    }
  );

  const { mutateAsync: onPatch } = usePatch(
    [`account`, id],
    `account/${id}/collaborators/permissions` //id represents the owner
  );

  const { enqueueSnackbar } = useSnackbar();
  const handleSnackBar = (variant) => (message) => {
    enqueueSnackbar(message, {
      variant,
      autoHideDuration: 2000,
    });
  };

  return (
    <Grid item xs={12}>
      <FormikInlineTable
        name="collaborators"
        columns={[
          {
            field: "name",
            render: (item) => {
              let name = item.employee?.[0].name;
              if (!name) {
                const accountWithEmployeeName = account
                  .filter(
                    ({ employee }) =>
                      employee && employee.id === item.collaborator_id
                  )
                  .shift();
                name = `${accountWithEmployeeName?.first_name || ""} ${
                  accountWithEmployeeName?.last_name || ""
                }`;
              }

              return name;
            },
          },
        ]}
        getRecordId={(records) => records.collaborator_id || records.id}
        onSubmit={async ({ collaborator_id }) => {
          try {
            await onPatch({ id: collaborator_id });
            handleSnackBar("success")("Collaborator has been Added");
            return Promise.resolve();
          } catch (e) {
            handleSnackBar("error")("Could not Added Collaborator");
            return Promise.reject(e);
          }
        }}
        handleEditRecord={false}
        handleDeleteRecord={async (id) => {
          try {
            await onDelete({ id });
            handleSnackBar("success")("Collaborator has been Removed");
            return Promise.resolve();
          } catch (e) {
            handleSnackBar("error")("Could not Remove Collaborator");
            return Promise.reject(e);
          }
        }}
        addButtonText={"Add Collaborator"}
        defaultInitialValues={{
          id: null,
        }}
        validationSchema={null}
        form={
          <Grid container spacing={2}>
            <FormikAsyncSelect
              name="collaborator_id"
              queryKey={[`account`, id]}
              endpoint={`account/collaborators/account/${id}`}
              label="Collaborator"
              getOptionLabel={(option) =>
                `${option.first_name} ${option.last_name}`
              }
              fetchOptions={{ useFullEndpoint: false }}
              getOptionValue={(option) => {
                return option.employee_id;
              }}
            />
          </Grid>
        }
        dialogTitle="Collaborator"
      />
    </Grid>
  );
};

export default CollaboratorInlineTable;
