export const address = {
  street: "",
  city: "",
  state: "",
  postal_code: "",
  country: "",
  county: "",
};

export const compensationTypes = {
  salary: false,
  bonus: false,
  profit_sharing: false,
  pension: false,
  retirement: false,
  deferred_compensation: false,
};

export const businessTypeCheckBox = {
  business_type_checkbox: {
    is_gaming: false,
    is_alcohol: false,
    is_government: false,
    is_license_agency: false,
    is_financial_institution: false,
    is_law_enforcement: false,
    is_tribal: false,
  },
};
