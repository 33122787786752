import { Box, List, Typography } from "@material-ui/core";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";
import {
  StyledCardContent,
  VerifiedIcon,
} from "components/Pages/Dashboard/widgets/AttentionNeeded/ExpiringDocuments.styles";
import { ExpiringListItem } from "components/Pages/LicensingControl/components/ExpiringCard";
import React from "react";
import ListWidget from "./ListWidget";

const CenteredWrapper = ({ children }) => (
  <Box
    pt={15}
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
  >
    {children}
  </Box>
);

const ExpiringList = ({ expiringItems, handleClickRecord = () => null }) => {
  if (!expiringItems.length) {
    return (
      <CenteredWrapper>
        <VerifiedIcon fontSize="large" />
        <Box pt={1}>
          <Typography component="span">Licenses up to date</Typography>
        </Box>
      </CenteredWrapper>
    );
  }

  return (
    <StyledCardContent>
      <List>
        {expiringItems.map((expiringItem) => {
          let employeeID =
            expiringItem.lc_person_id || expiringItem.employee_id;
          let businessID =
            expiringItem.lc_business_id || expiringItem.bd_party_id;
          let partyID =
            expiringItem.license_type_category === "personal"
              ? employeeID
              : businessID;

          return (
            <ExpiringListItem
              handleClick={() => handleClickRecord(partyID, expiringItem)}
              key={`${partyID}-${expiringItem?.license_type_name}`}
              expiringItem={expiringItem}
            />
          );
        })}
      </List>
    </StyledCardContent>
  );
};

export const AttentionNeeded = ({
  status,
  data = [],
  handleClickRecord,
  label,
}) => {
  return (
    <ListWidget title={label}>
      <LoadingComponent status={status}>
        <ExpiringList
          expiringItems={data}
          handleClickRecord={handleClickRecord}
        />
      </LoadingComponent>
    </ListWidget>
  );
};
