import React from "react";

export const columns = [
  {
    title: "Bank Name",
    field: "bank_pd_business_entity.business_name",
  },
  { field: "account_number" },
  {
    title: "Account Holders",
    field: "other_account_holders",
  },
  {
    title: "Country",
    field: "bank_pd_business_entity.business_address.country",
  },
  {
    title: "Active",
    field: "is_current",
    format: "boolean",
    sortType: "boolean",
  },
  {
    field: "current_balance",
    format: "currency",
  },
];

export const tableQuestions = (
  <>
    List all bank accounts (checking, savings, time deposits, certificates of
    deposit, money market funds or other) resident, non-resident, foreign and
    domestic, maintained by you, your spouse, domestic partner or dependent
    children.
    <br />
    <br />
    Include all non-resident accounts closed in the last ten (10) years.
  </>
);

export const defaultInitialValues = {
  cash_in_bank_attachment_group_id: null,
  key_person_has_control: true,
  bank_pd_business_entity_id: null,
  check_account_with_brokerage: null,
  from_date: null,
  to_date: null,
  is_current: false,
  account_number: "",
  nature: null,
  other_account_holders: null,
  current_balance: null,
  interest_rate: null,
  balance_date: null,
};
