import { Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import BDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/BDPersonSelect";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import { tabularDataParams, columns } from "./config.js";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog.jsx";
import { EnhancedActionsTable } from "components/Common/Tables/Common/index.js";
import useTabularData from "hooks/tableHooks/useTabularData.js";

const Form = () => {
  return (
    <Grid container spacing={1}>
      <SectionSubHeader label="Select Manager" />
      <BDPersonSelect name="related_bd_party_id" label="Manager" xs={6} />
    </Grid>
  );
};

const TennesseeVRAManagers = ({ name }) => {
  const formData = useTabularData(tabularDataParams);

  return (
    <FormSectionProvider formData={formData} title={`${name}`}>
      <EnhancedFormikDialog title="Managers">
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
    </FormSectionProvider>
  );
};

export default TennesseeVRAManagers;
