import { fieldTypes } from "componentGenerators/formGenerator/config";
import { formats } from "components/Common/Tables/Common/helpers";

const bdBusinessEntityEndpoint = `bdBusinessEntity?id=${localStorage.getItem(
  "bd_business_entity_id"
)}`;

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Applicant’s Point-of-Contact",
  },
  {
    type: fieldTypes.bdPersonSelect,
    label: "Contact Person",
    name: "virginia_sbvr.contact_bd_person_id",
    xs: 4,
  },
  {
    type: fieldTypes.subHeader,
    label: "Compliance With MD SDAT Registration",
  },
  {
    type: fieldTypes.label,
    label: "Please note, Compliance is required.",
  },
  {
    type: fieldTypes.text,
    label: "VA SCC Department ID Number",
    name: "virginia_sbvr.va_scc_id_number",
    xs: 4,
  },
  {
    type: fieldTypes.subHeader,
    label: "Company Association",
  },
  {
    type: fieldTypes.label,
    label:
      "Select below the Sports Betting Permit Holder or Supplier with which the Vendor has contracted. If the vendor has not yet contracted with a sports betting permit holder or supplier please leave blank:",
  },
  {
    type: fieldTypes.bdBusinessSelect,
    label: "Business Entity",
    name: "virginia_sbvr.associated_company_id",
    xs: 4,
  },
  {
    type: fieldTypes.subHeader,
    label: "Combined Total value of goods and services",
  },
  {
    type: fieldTypes.label,
    label:
      "Every vendor applicant shall provide the Department with the combined total value of goods and services the vendor expects to provide, or has been contracted to provide, during a calendar year.",
  },
  {
    type: fieldTypes.label,
    label: "The contracted value of goods and services will be:",
  },
  {
    type: fieldTypes.number,
    label: "Amount",
    name: "virginia_sbvr.combined_value_amount",
    xs: 4,
    format: formats.amount,
  },
  {
    type: fieldTypes.label,
    label: "The above amount is:",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "virginia_sbvr.combined_value_type_checkbox",
    dynamicMenuItems: ["virginiaSBVRActualOrEstimatedTypeCheckbox"],
    defaultValue: null,
  },
];

const businessDetailsSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Business Summary",
  },
  {
    type: fieldTypes.label,
    label:
      "Provide a summary of the types of business currently conducted in each jurisdiction and list of operators with which business is conducted, services are provided, or goods are supplied:",
  },
  {
    type: fieldTypes.text,
    label: "Business Summary",
    name: "business_summary",
    xs: 12,
  },
];

export default [
  {
    type: "form",
    formSchema,
    endpoint: "bdSupplement",
  },
  {
    type: "form",
    formSchema: businessDetailsSchema,
    endpoint: bdBusinessEntityEndpoint,
  },
];
