import CustomLink from "components/Common/Link";
import Tooltip from "components/Common/Tooltip";
import React from "react";

export const commonStrings = {
  youSpouseChildren: "you, your spouse, domestic partner or dependent children",
  youSpouse: "you, your spouse or domestic partner",
  provideDetails: (
    <>
      <p>If yes, provide details below.</p>
    </>
  ),
  usdCurrencyReporting:
    "Note: If you are applying in a jurisdiction other than the United States, the amount you are required to report is the equivalent of $10,000USD in the national currency of the jurisdiction where you will be filing this application.",
  arrestChargeOffense: (
    <ol type="a">
      <li>
        <strong>Arrest:</strong> includes any detaining, holding, or taking into
        custody by any police or other law enforcement authorities to answer for
        the alleged performance of any “offense.”
      </li>
      <li>
        <strong>Charge:</strong> includes any indictment, complaint,
        information, summons, or other notice of the alleged commission of any
        “offense.”
      </li>
      <li>
        <strong>Offense:</strong> includes all felonies, crimes, high
        misdemeanors, misdemeanors, disorderly persons offenses, petty
        disorderly offenses, driving while intoxicated/impaired motor vehicle
        offenses and violations of probation or any other court order. Juvenile
        offenses that occurred within the most recent 10 year period are also
        included within the definition of “offenses.”
      </li>
    </ol>
  ),
  additionalJurisdictions: (
    <>
      Please contact <strong>support@onecomply.com</strong> if you require
      access to additional jurisdictions.
    </>
  ),
  gamblingCompany: (
    <>
      Casino or gaming/gambling related company includes any form or type of
      casino, gaming/gambling related operation, any manufacturer of
      gaming/gambling equipment, junket enterprise, horse racing, dog racing,
      pari­mutuel operation, lottery, sports betting, Internet gaming, etc.
    </>
  ),
  nonGamblingCompany: (
    <>
      Non-Gaming licenses include, but are not limited to the following: real
      estate broker or salesman, accountant, attorney, medical, boxing promoter,
      manager or matchmaker, race horse owner, trainer or manager, jockey, race
      dog owner, securities dealer, contractor, pilot, insurance, or any other
      type of professional license
    </>
  ),
  keyPerson: (
    <>
      <strong>Key Person:</strong> Any officer, director, executive, employee,
      trustee, Substantial Owner, independent contractor, or agent of a Business
      Entity, having the power to exercise, either alone or in conjunction with
      others, management or operating authority over a Business Entity or
      Affiliate(s) thereof.
    </>
  ),
  officer: (
    <>
      <strong>Officer includes:</strong> President, Secretary, Treasurer,
      Chairperson of the Board, Vice-President, General/Corporate Counsel,
      Senior Management Personal or any other Officers
    </>
  ),
  accountant: (
    <>If you have chosen a business in the Auditor or Accountant dropdown</>
  ),
};

export const employmentHistory = {
  tableQuestion: (
    <>
      Provide details of ALL part-time and full-time employment for the past
      twenty years or from the age 18, whichever is less. For any casino or
      gambling related employment provide ALL positions ever held.{" "}
      <Tooltip title={commonStrings.gamblingCompany} />
      <ul>
        <li>Include dates of any unemployment between jobs</li>
        <li>Include any military service</li>
        <li>
          Include ALL employment where you were discharged, suspended or asked
          to resign.
        </li>
      </ul>
    </>
  ),
};

export const bankruptcies = {
  tableQuestion: (
    <>
      Provide details if:
      <ul>
        <li>
          You have ever personally been adjudicated bankrupt or filed a petition
          for any type of bankruptcy, insolvency or liquidation under any
          bankruptcy or insolvency law in any jurisdiction OR;
        </li>
        <li>
          In the past twenty (20) years or since the age of 18, whichever is
          less, has any business entity in which you held a 5% or greater
          ownership interest, or in which you served as an officer or director
          been adjudicated bankrupt or filed a petition for any type of
          bankruptcy or insolvency under any bankruptcy or insolvency law
        </li>
      </ul>
    </>
  ),
};

export const businessReceiverships = {
  tableQuestion: (
    <>
      Have you as an individual, member of a partnership, or owner, director or
      officer of a corporation ever been in a business entity that has been in
      liquidation, receivership or been placed under some form of governmental
      administration or monitoring?
    </>
  ),
};

export const garnishments = {
  tableQuestion: (
    <>
      Have your wages, earnings, or other income been subject to garnishment,
      attachment, charging order, voluntary wage execution, bankruptcy or the
      like during the past ten (10) year period?
    </>
  ),
};

export const repossessions = {
  tableQuestion: (
    <>
      In the past ten (10) years, have you ever had any property, real or
      personal, repossessed by a finance company in any jurisdiction?
      {commonStrings.provideDetails}
    </>
  ),
};

export const executor = {
  tableQuestion: (
    <>
      During the last ten (10) year period, have you been:
      <ol type="a">
        <li>
          An executor(trix), administrator or other fiduciary of any estate
        </li>
        <li>
          A beneficiary or legatee under a will or received any thing of value
          under an intestacy statute; or
        </li>
        <li>A settlor/grantor, beneficiary or trustee of any trust</li>
      </ol>
      {commonStrings.provideDetails}
    </>
  ),
};

export const assetsInTrust = {
  tableQuestion: (
    <>
      Do you own, hold, or have an interest in any assets in a trust in any
      jurisdiction (you may exclude those assets disclosed in
      <CustomLink to="executor" />
      )?
      {commonStrings.provideDetails}
    </>
  ),
};

export const lendingHistory = {
  tableQuestion: (
    <>
      During the last ten year period, have {commonStrings.youSpouseChildren}{" "}
      made any loan in excess of $10,000USD?{" "}
      {commonStrings.usdCurrencyReporting}
      {commonStrings.provideDetails}
    </>
  ),
};

export const borrowingHistory = {
  tableQuestion: (
    <>
      During the last ten (10) year period, have{" "}
      {commonStrings.youSpouseChildren} received a loan in excess of $25,000USD?{" "}
      {commonStrings.usdCurrencyReporting}
      {commonStrings.provideDetails}
    </>
  ),
};

export const currencyExchanges = {
  tableQuestion: (
    <>
      Have you ever exchanged currency in an amount of more than $10,000USD
      within the past ten (10) years? {commonStrings.usdCurrencyReporting}
      {commonStrings.provideDetails}
    </>
  ),
};

export const marginAccounts = {
  tableQuestion: (
    <>
      Do you maintain a brokerage or margin account with any securities or
      commodities dealer?
      {commonStrings.provideDetails}
    </>
  ),
};

export const insuranceClaims = {
  tableQuestion: (
    <>
      Have {commonStrings.youSpouseChildren} filed any claims in excess of
      $100,000USD under any fire, theft, automobile or insurance policy within
      the past ten (10) year period? {commonStrings.usdCurrencyReporting}
      {commonStrings.provideDetails}
    </>
  ),
};

export const gifts = {
  tableQuestion: (
    <>
      During the last five (5) year period, have{" "}
      {commonStrings.youSpouseChildren} given or received any gift or gifts,
      whether tangible or intangible which either individually or in the
      aggregate exceeded $10,000USD in value in any one year period?{" "}
      {commonStrings.usdCurrencyReporting}
      {commonStrings.provideDetails}
    </>
  ),
};

export const referralFees = {
  tableQuestion: (
    <>
      In the past ten (10) years have you received any referral or finder&apos;s
      fee in excess of $10,000 USD.
      {commonStrings.provideDetails}
    </>
  ),
};

export const loanGuarantees = {
  tableQuestion: (
    <>
      Have {commonStrings.youSpouse}, in the past ten (10) years given a
      guarantee, co­signed or otherwise insured payment of a loan, debt or other
      financial obligation in any jurisdiction?
      {commonStrings.provideDetails}
    </>
  ),
};

export const arrests = {
  tableQuestion: (
    <>
      Have you ever been cited for, arrested or charged with any crime or
      offense in any jurisdiction? For the purpose of this question:
      <div>{commonStrings.arrestChargeOffense}</div>
      {commonStrings.provideDetails}
    </>
  ),
};

export const familyArrests = {
  tableQuestion: (
    <>
      Has your spouse, domestic partner or any of your children, step-­children
      or adopted children ever been arrested or charged with any crime or
      offense in any jurisdiction? For the purpose of this question:
      <div>{commonStrings.arrestChargeOffense}</div>
      {commonStrings.provideDetails}
    </>
  ),
};

export const citations = {
  tableQuestion: (
    <>
      In the past ten (10) years, have you been cited or charged with, or
      formally accused of, any violation of a statute, regulation or code of any
      local, state, county, municipal, provincial, federal or national
      government other than a criminal, disorderly persons, petty disorderly
      person or motor vehicle violation?
      {commonStrings.provideDetails}
    </>
  ),
};

export const governmentInvestigations = {
  tableQuestion: (
    <>
      Have you ever been the subject of an investigation conducted by any
      governmental agency/organization, court, commission, committee, grand jury
      or investigatory body (local, state, county, provincial, federal,
      national, etc.) other than in response to a traffic summons?
      {commonStrings.provideDetails}
    </>
  ),
};

export const indictments = {
  tableQuestion: (
    <>
      To the best of your knowledge, has a criminal indictment, information or
      complaint ever been filed or returned against you, but for which you were
      not arrested or in which you were named as an unindicted party or
      unindicted co-conspirator in any criminal proceeding in any jurisdiction?
    </>
  ),
};

export const pardons = {
  tableQuestion: (
    <>
      Have you ever received a pardon, or has any government agency/organization
      agreed to dismiss, suspend or defer any criminal investigation or
      prosecution against you for any criminal offense?
      {commonStrings.provideDetails}
    </>
  ),
};

export const military = {
  chargeQuestion: (
    <>
      Have you ever been tried by military court marital or have you had charges
      filed against you?
      <Tooltip title="Charges filed against you by the military authorities in any country would fall under the Code of Military Justice applicable to that jurisdiction. In the United States, this means any charges filed against you under Article 15 of the Uniform Code of Military Justice (summary court, deck court, captain’s mast, company punishment, etc.)" />
    </>
  ),
  recordDefinition:
    "In the United States, a military record is called a DD214. If you have served in the U.S. military, you should provide a copy of this record. If your military service was in another country, you should provide a copy of whatever official documentation was provided to you at the time of your discharge.",
};

export const liens = {
  tableQuestion: (
    <>
      Have any individual, local, city, county, provincial, state, Federal,
      national, or any other governmental liens/debts been filed against you as
      an individual, sole proprietor, member of a partnership, or owner of a
      corporation in any jurisdiction?
      {commonStrings.provideDetails}
    </>
  ),
};

export const businessLicensing = {
  tableQuestion: (
    <>
      Provide details if:
      <ul>
        <li>
          You held a direct or indirect financial or ownership interest in any
          Business Entity that has applied to any licensing agency in any
          jurisdiction for any license, permit, registration, finding of
          suitability, or qualification in connection with any form or type of a
          casino, gaming/gambling related operation or alcoholic beverage
          operations OR;
        </li>
        <li>
          Any entity in which {commonStrings.youSpouse} is/was a director,
          officer, partner or an owner of a 5% or greater interest ever had any
          license, permit or certificate issued by a governmental agency in any
          jurisdiction denied, suspended, revoked, or subject to any conditions?
        </li>
      </ul>
    </>
  ),
};

export const personalLicensing = {
  tableQuestion: (
    <>
      Provide details if {commonStrings.youSpouse} ever made application for or
      held:
      <ul>
        <li>
          Any professional or occupational license, permit or certification, in
          any jurisdiction (excluding driver’s license) OR;
        </li>
        <li>
          A license, permit, registration, finding of suitability, qualification
          or other authorization to participate in any form or type of casino or
          gaming/gambling related operation or alcoholic beverage operation in
          any jurisdiction.
        </li>
      </ul>
      You must provide details if you ever applied and your application was
      granted, denied, returned to you by the licensing agency for any reason,
      withdrawn or is currently pending.
    </>
  ),
};

export const employmentDiscipline = {
  tableQuestion: (
    <>
      During this employment if you were discharged, suspended, asked to resign
      or charged with any infraction which was the subject of any disciplinary
      action provide details.
    </>
  ),
};

export const fiduciaryDenials = {
  tableQuestion: (
    <>
      Provide details if {commonStrings.youSpouse} ever:
      <ul>
        <li>
          Sought and been denied a position as a trustee or other fiduciary
          officer OR;
        </li>
        <li>
          Ever been suspended or removed from a position as a trustee or other
          fiduciary office
        </li>
      </ul>
    </>
  ),
};

export const familyOwnership = {
  employmentTableQuestion: (
    <>
      Are any members of your family (spouse, parents, grandparents, children,
      grandchildren, siblings, uncles, aunts, nephews, nieces, fathers-in-law,
      mothers-in-law, sons-in-law, daughters-in-law, brothers-in-law and
      sisters-in-law whether by whole or half blood, by marriage, adoption or
      natural relationship) associated with or employed in any form or type of
      casino or gaming/gambling related operation in any jurisdiction?
      {commonStrings.provideDetails}
    </>
  ),
  ownershipTableQuestion: (
    <>
      Other then Employment, do any of your family members have an ownership
      interest or other association in any alcoholic or gaming related entity.
    </>
  ),
};

export const personalTaxes = {
  tableQuestion: (
    <>
      Provide the following tax filing details:
      <ul>
        <li>
          United States tax returns (Federal and State) for the last (5) years
        </li>
        <li>
          Your spouse or domestic partner&apos;s tax returns, if filed
          separately from yours
        </li>
        <li>
          All tax returns that have been adjusted, audited or failed to file for
          ALL years
        </li>
        <li>
          Filings outside the United States by your, your spouse or domestic
          partner within the last ten (10) years
        </li>
      </ul>
    </>
  ),
};

export const taxReturnActivity = {
  tableQuestion: (
    <>
      If this tax return has ever been audited, adjusted or it was failed to be
      filed provide details below.
    </>
  ),
};

export const foreignTaxes = {
  tableQuestion: (
    <>
      Provide details if you, your spouse or domestic partner ever filed any
      type of tax return, statement, or form, in any jurisdiction, outside the
      United States, within the last ten (10) years.
    </>
  ),
};

///USED FOR COPY & PASTE
export const boilerPlate = {
  tableQuestion: (
    <>
      boilerPlate
      {commonStrings.provideDetails}
    </>
  ),
};
