import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import CustomLink from "components/Common/Link";
import useForm from "hooks/useForm";

const IndianaFinancialStatements = ({ name }) => {
  const formData = useForm({
    defaultInitialValues: {},
    noValidate: true,
    endpoint: "indiana_financial_statements_pdSupplement",
  });

  return (
    <FormSectionWrapper title={name} formData={formData}>
      <EnhancedFormikPage showSaveButton={false}>
        <SectionLabel
          label={
            <>
              Indiana requires you to attach statements for your financial
              records as detailed in each section below. You can upload the
              statements to OneComply and print from your application page OR
              you can print from your own records. When you generate your
              application a cover page will be provided in each place in the
              application where statements or other supporting documents are
              required.
            </>
          }
        />
        <SectionSubHeader label="Bank Statements" divider />
        <SectionLabel
          label={
            <>
              Provide copies of the last thirty-size (36) monthly bank
              statements for all your personal checking and savings accounts.
              This includes, but is not limited to, individual and joint
              accounts and any account closed within the thirty-six (36) month
              period.
            </>
          }
        />
        <SectionLabel
          label={
            <>
              Attach the statements to each of your bank accounts in the
              <CustomLink to="bank_accounts" /> section or print from your
              records.
            </>
          }
        />
        <SectionSubHeader label="Retirement Plans" divider />
        <SectionLabel
          label={
            <>
              Provide the most recent annual copy of all retirement plan
              statements for the last three (3) years. This includes 401(k) and
              IRA statements.
            </>
          }
        />
        <SectionLabel
          label={
            <>
              Attach the statements to each of your records in the
              <CustomLink to="pension_funds" />
              section or print from your records.
            </>
          }
        />
        <SectionSubHeader label="Brokerage Accounts" divider />
        <SectionLabel
          label={
            <>
              Provide copies of the last thirty-six (36) monthly statements for
              all of your personal brokerage accounts. This includes, but is not
              limited to, individual and joint accounts.
            </>
          }
        />
        <SectionLabel
          label={
            <>
              Attach the statements to each of your records (held by you
              individually or jointly) in the
              <CustomLink to="margin_accounts" />
              section or print from your records.
            </>
          }
        />
        <SectionSubHeader label="Mortgage Statements" divider />
        <SectionLabel
          label={
            <>
              Provide copies of your and your spouse&apos;s most recent year-end
              Mortgage Statements for all properties owned.
            </>
          }
        />
        <SectionLabel
          label={
            <>
              Attach the statements to each of your records in the
              <CustomLink to="real_estate_loans" />
              section or print from your records.
            </>
          }
        />
        <SectionSubHeader label="Credit Card Statements" divider />
        <SectionLabel
          label={
            <>
              Provide a copy of the most recent twelve (12) months of credit
              card statements for all accounts.
            </>
          }
        />
        <SectionLabel
          label={
            <>
              Attach the statements to each of your credit card records in the
              <CustomLink to="loans_and_other_payables" />
              section or print from your records.
            </>
          }
        />
        <SectionSubHeader label="Tax Returns" divider />
        <SectionLabel
          label={
            <>
              Provide true and accurate copies of your personal state and
              federal tax returns for the last three (3) years. Include all
              W-2&apos;s, K-1&apos;s, schedules and attachments.
              <ol type="a">
                <li>
                  If you owed any payment on the tax returns submit proof of
                  payment or established payment plan.
                </li>
              </ol>
            </>
          }
        />
        <SectionLabel
          label={
            <>
              Provide copies of any tax returns that are associated with the
              filing of your individual tax return, including but no limited to
              Partnership Tax Returns (Form 1065) and/or Sub S Corporation Tax
              Returns (Form 1120S) for the last three (3) years. This does not
              apply to passive investors.
            </>
          }
        />
        <SectionLabel
          label={
            <>
              Attach all requested tax information in the
              <CustomLink to="us_taxes" /> section or the
              <CustomLink to="non_us_taxes" />
              section or print from your records.
            </>
          }
        />
      </EnhancedFormikPage>
    </FormSectionWrapper>
  );
};

export default IndianaFinancialStatements;
