import { Grid } from "@material-ui/core";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikNumberField from "components/Formik/fields/FormikNumberField.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import {
  columns,
  defaultInitialValues,
  endpoint,
  tableQuestions,
} from "./config.js";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <FormikNumberField label="Amount" name="amount" />
      <FormikDatePicker label="Date" name="transaction_date" />
      <FormikTextField
        label="Purpose for the transaction"
        name="purpose"
        xs={6}
      />
      <FormikTextField
        label="With whom you made the transaction"
        name="other_parties"
        helperText="If Applicable"
        xs={8}
      />
    </Grid>
  );
};

const IndianaCashTransaction = ({ name }) => {
  const formData = useTabularData({
    endpoint,
    defaultInitialValues,
    noValidate: true,
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default IndianaCashTransaction;
