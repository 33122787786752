import { fieldTypes } from "componentGenerators/formGenerator/config";
import React from "react";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Location",
  },
  {
    type: fieldTypes.yesNo,
    label: "Are you a New York State resident?",
    name: "new_york_cvke.is_new_york_resident",
  },
  {
    type: fieldTypes.subHeader,
    label: "Associated Business Entity",
    divider: true,
  },
  {
    type: fieldTypes.pdBusinessSelect,
    label: "Business Entity",
    name: "new_york_cvke.associated_business_id",
  },
  {
    type: fieldTypes.subHeader,
    label: "Gaming Facility Location",
    divider: true,
  },
  {
    type: fieldTypes.checkBoxGroup,
    label:
      "Applicant is applying for a Casino Key Employee License for the following gaming facility:",
    name: "new_york_cvke.gaming_facility_checkbox",
    dynamicMenuItems: ["newYorkFacilityCheckbox"],
  },
  {
    type: fieldTypes.subHeader,
    label: "Qualifications",
    divider: true,
  },
  {
    type: fieldTypes.checkBoxGroup,
    label: "Applicant is applying for qualification in connection with",
    name: "new_york_cvke.app_qualification_checkbox",
    dynamicMenuItems: ["newYorkQualification"],
  },
  {
    type: fieldTypes.label,
    label: "If you are a casino key employee, what is your official job title:",
  },
  {
    type: fieldTypes.text,
    label: "Job Title",
    name: "new_york_cvke.casino_employee_job_title",
  },
  {
    type: fieldTypes.label,
    label:
      "If you are a vendor owner, manager or supervisor, what is your official job title:",
  },
  {
    type: fieldTypes.text,
    label: "Job Title",
    name: "new_york_cvke.vendor_key_person_job_title",
  },
  {
    type: fieldTypes.label,
    label:
      "Please provide a brief description of the duties for the job position entered above:",
  },
  {
    type: fieldTypes.text,
    label: "Job Duties",
    name: "new_york_cvke.job_duties",
    xs: 12,
    rows: 3,
  },
  {
    type: fieldTypes.yesNo,
    label:
      "During the course of your employment with the gaming facility, will you have any supervisory or managerial responsibilities?",
    name: "new_york_cvke.has_supervisory_responsibilities",
  },
  {
    type: fieldTypes.subHeader,
    label: "Parent, holding, intermediary or subsidiary companies",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label:
      "If applicable, the name of parent, holding, intermediary or subsidiary companies of the licensee or applicant with which you are a principal:",
  },
  {
    type: fieldTypes.pdBusinessSelect,
    label: "Business Entity",
    name: "new_york_cvke.principal_parent_company_id",
  },
  {
    type: fieldTypes.subHeader,
    label: "Tribal Governments",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        For the following sections, please ensure that your response includes
        Tribal governments:{" "}
        <ul>
          <li>
            <CustomLink to="government_investigations" />
          </li>
          <li>
            <CustomLink to="testimonies" />
          </li>
          <li>
            <CustomLink to="citations" />
          </li>
          <li>
            <CustomLink to="liens" />
          </li>
        </ul>
      </>
    ),
  },
];

const childSupportSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Child Support",
  },
  {
    type: fieldTypes.yesNo,
    label: "Are you delinquent in the payment of any child support?",
    name: "has_child_support_arrears",
  },
  {
    type: fieldTypes.label,
    label: "If you answered yes, please provide the details in the box below.",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "arrears_child_support_reason",
    xs: 12,
    rows: 3,
  },
];

export default [
  {
    type: "form",
    formSchema,
    endpoint: "new_york_cvke_supplement_disclosure_questions_pdSupplement",
  },
  {
    type: "form",
    formSchema: childSupportSchema,
    endpoint:
      "new_york_cvke_supplement_disclosure_questions_personalDisclosure",
  },
];
