import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import ColoradoPersonalFinancialHistoryForm from "./ColoradoPersonalFinancialHistoryForm";

const ColoradoEliminatoryQuestions = ({ name }) => {
  return (
    <FormSectionWrapper title={name}>
      <ColoradoPersonalFinancialHistoryForm />
    </FormSectionWrapper>
  );
};

export default ColoradoEliminatoryQuestions;
