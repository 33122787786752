import {
  CardHeader,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import React from "react";
import { SuccessChip } from "../ManageApplications.styles";
import useScreenWidth from "hooks/useScreenWidth";
import DoneIcon from "@material-ui/icons/Done";
import { dayjsUTC } from "helpers/apiHelpers";

const ApplicationItem = ({ application, selected, onClick }) => {
  const {
    id,
    created_at,
    application_status,
    pdf_form: {
      name: applicationName,
      jurisdiction: { name: jurisdictionName },
    },
  } = application;

  let secondary = `${jurisdictionName} - ${dayjsUTC(created_at, "MM-DD-YYYY")}`;

  // only for BD application
  if (
    application.bd_business_entity &&
    application.bd_business_entity.business_name
  ) {
    secondary += ` (${application.bd_business_entity.business_name})`;
  }

  const { isLg } = useScreenWidth();

  const StatusChip =
    application_status === "submitted" ? (
      <SuccessChip
        size="small"
        label={isLg ? <DoneIcon style={{ paddingTop: "5px" }} /> : "Submitted"}
      />
    ) : (
      <>
        {isLg ? null : (
          <Chip size="small" label="Not Submitted" variant="outlined" />
        )}
      </>
    );

  return (
    <ListItem
      divider
      button
      key={id}
      value={id}
      selected={selected}
      onClick={onClick}
      style={{ paddingRight: "0px" }}
    >
      <div style={{ width: "240px" }}>
        <ListItemText
          primaryTypographyProps={{ variant: "body1", noWrap: true }}
          primary={applicationName}
          secondary={secondary}
        />
      </div>
      <ListItemSecondaryAction>{StatusChip}</ListItemSecondaryAction>
    </ListItem>
  );
};

export const ApplicationsList = ({
  applications,
  handleSelectApplication,
  selectedId,
  ...rest
}) => (
  <>
    <CardHeader
      color="textSecondary"
      title="Select an Application"
      titleTypographyProps={{ color: "textSecondary", variant: "subtitle1" }}
    />
    <Divider />
    <List
      {...rest}
      style={{ width: "100%", height: "655px", overflow: "overlay" }}
    >
      {applications.map(({ id, ...application }) => (
        <ApplicationItem
          key={id}
          application={application}
          selected={selectedId === id}
          onClick={() => handleSelectApplication(id)}
        />
      ))}
    </List>
  </>
);
