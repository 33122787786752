import { Box } from "@material-ui/core";

import React from "react";
import useFetch from "queryHooks/useFetch.js";
import DisplayTable from "components/Common/Tables/DisplayTable/index.js";
import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider";
import { columns } from "./config";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const IndianaPublicOfficial = ({ title }) => {
  const { data, status } = useFetch(
    "indiana_public_official_government_employment_includes_family_indiana"
  );
  return (
    <FormSectionWrapper title={"Indiana " + title}>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={status}>
          <DisplayTable
            records={data}
            linkTo={["government_positions"]}
            columns={columns}
            dependsOnQueryCount="Have you or any of your Immediate Family are, or have been within the last five (5) years, an Indiana Public Official or any officer or employee of any government entity in Indiana."
          />
        </LoadingComponent>
      </Box>
    </FormSectionWrapper>
  );
};

export default IndianaPublicOfficial;
