import React from "react";
import { Grid } from "@material-ui/core";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import useForm from "hooks/useForm";
import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider";
import BDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/BDPersonSelect";
import useEndPoint from "hooks/useEndpoint";

const ApplicantForm = () => {
  return (
    <Grid container spacing={1}>
      <SectionSubHeader>Company Compliance</SectionSubHeader>
      <SectionLabel>Add your company compliance officer</SectionLabel>
      <BDPersonSelect
        name="compliance_officer_bd_person_id"
        label="Compliance Officer"
        xs={6}
      />
    </Grid>
  );
};

const IowaClassDApplicantCompliance = () => {
  const { bdBusinessEntityEndpoint } = useEndPoint("");
  const applicantData = useForm({
    defaultInitialValues: {
      compliance_officer_bd_person_id: null,
    },
    endpoint: bdBusinessEntityEndpoint,
  });

  return (
    <FormContextProvider formData={applicantData}>
      <EnhancedFormikPage>
        <ApplicantForm />
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};
export default IowaClassDApplicantCompliance;
