import React from "react";

import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { columns, endpoint, defaultInitialValues } from "./config.js";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import useTabularData from "hooks/tableHooks/useTabularData";
import { Grid } from "@material-ui/core";
import BDPartySelect from "components/Formik/formGroups/CrudSelect/selects/BDPartySelect";
import { useMenuOptions } from "hooks/useMenuOptions.js";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import useEndPoint from "hooks/useEndpoint.js";
import CustomLink from "components/Common/Link.js";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import FromToCurrent from "components/Formik/formGroups/FromToCurrent";

const tableQuestions = (
  <>
    All persons or entities in the Applicant&apos;s enterprise that correspond
    to the sub-items listed below are considered qualifiers. Review the
    categories and make sure the appropriate parties are listed as qualifiers in
    the following table.
    <p>
      <strong>Note: </strong>If the Applicant is applying for a Casino Service
      Industry Enterprise License then A Multi-jurisdictional Personal History
      Disclosure Form and Gaming Enterprise New Jersey Supplemental form must be
      completed by every natural person noted in item A through I below.
    </p>
    <ol type="A">
      <li>
        All persons who will act as sales representatives or otherwise regularly
        engage in the solicitation of business from a casino licensee or
        applicant.
      </li>
      <li>
        If your company is a junket enterprise, each person who indirectly holds
        any beneficial or ownership interest of 10% or more of that enterprise,
        and each junket representative who will deal directly with casino
        licensee or applicant or their employees. A junket representative is
        defined as any person who negotiates the terms of, or engages in the
        referral, procurement or selection of persons who may participate in any
        junket to a licensed casino, regardless of whether or not those
        activities occur within the State of New Jersey. (N.J.S.A. 5:12-29.2.)
      </li>
      <li>
        All persons who have signed or will sign any agreement with a casino
        licensee or applicant.
      </li>
      <li>
        The management employee supervising the regional or local office which
        employs the sales or junket representative(s) described in either
        sub-section A. or B.
      </li>
      <li>All officers of the enterprise.</li>
      <li>All inside directors or trustees of the enterprise.</li>
      <li>All partners, whether general, limited or otherwise.</li>
      <li>The sole proprietor, if the enterprise is a sole proprietorship.</li>
      <li>
        If the enterprise is applying for a Casino Service Industry Enterprise
        License, each natural person or business entity that directly or
        indirectly holds any beneficial or ownership interest of five percent or
        more of the enterprise. If the enterprise is or is to become a
        subsidiary, each holding company and intermediary company of the
        enterprise must complete a Business Entity Disclosure Form-CSI.
      </li>
    </ol>
    <p>
      To add Qualifiers either visit the links to the relevant sections or add
      using the form below.
    </p>
    <ul>
      <li>
        <CustomLink to="bd_directors_trustees_officers" />
      </li>
      <li>
        <CustomLink to="bd_partners" />
      </li>
      <li>
        <CustomLink to="bd_owners" />
      </li>
    </ul>
  </>
);

const Form = () => {
  const { bdQualifiersNewJersey } = useMenuOptions();

  return (
    <Grid container spacing={2}>
      <BDPartySelect
        name="related_bd_party_id"
        label="Qualifier"
        header="Qualifier"
        xs={6}
      />
      <FormikSelect
        label="Qualifier Role"
        header="Position"
        name="related_bd_party_role"
        xs={6}
        options={bdQualifiersNewJersey}
      />
      <SectionSubHeader label="Dates" divider />
      <FromToCurrent />
    </Grid>
  );
};

const NewJerseyCSIELQualifiers = ({ name }) => {
  const { fullEndpoint } = useEndPoint("bdPosition");

  const formData = useTabularData({
    defaultInitialValues,
    endpoint,
    saveEndpoint: fullEndpoint,
    nonRequired: ["related_bd_party_role"],
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default NewJerseyCSIELQualifiers;
