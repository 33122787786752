import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import { FormikAddressFields } from "components/Formik/formGroups/FormikAddressFields";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import YesNo from "components/Formik/formGroups/YesNo";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikGoogleLookup from "components/Formik/fields/FormikGoogleLookup";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { FirstMiddleLast } from "components/Formik/formGroups/FirstMiddleLast.jsx";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import {
  columns,
  defaultInitialValues,
  endpoint,
  tableQuestions,
  nonRequired,
} from "./config.js";

const OtherRelativesForm = () => {
  const { otherRelativeTypeValues, siblingSpouseTypeValues } = useMenuOptions();

  const mergedOtherRelatives = otherRelativeTypeValues.concat(
    siblingSpouseTypeValues.slice(1)
  );
  return (
    <Grid container spacing={2}>
      <FirstMiddleLast />

      <SectionSubHeader label="Personal Details" divider />
      <YesNo label="Is this person deceased?" name="is_deceased" />
      <YesNo label="Is this person retired?" name="is_retired" />

      <FormikSelect
        label="Relative Type"
        name="relative_type"
        options={mergedOtherRelatives}
      />
      <FormikTextField label="Phone Number" name="phone_number" />
      <FormikDatePicker label="Date of Birth" name="date_of_birth" />
      <FormikTextField
        label="Place of Birth"
        name="place_of_birth"
        placeholder="City, State, Country"
        helperText="City, State/Province, Country"
      />
      <FormikTextField
        label="Occupation"
        name="occupation"
        helperText="If retired or deceased, list last occupation"
      />

      <SectionSubHeader label="Home Address" divider />
      <FormikGoogleLookup
        fields={{
          address: "home_address",
        }}
      />
      <FormikAddressFields addressPrefix="home_address." />
    </Grid>
  );
};

const OtherRelatives = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint,
    nonRequired,
  });

  return (
    <FormSectionProvider
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
      formData={formData}
      title={name}
    >
      <EnhancedFormikDialog title={name}>
        <OtherRelativesForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
    </FormSectionProvider>
  );
};

export default OtherRelatives;
