import React, { useReducer } from "react";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useAuth } from "context/auth";
import _ from "lodash";
import { getSideNavbar } from "helpers/apiHelpers";
import { useLocation } from "react-router-dom";

function styleReducer(styleState, { type, navBarWrapper }) {
  switch (type) {
    case "UPDATE_WRAPPER": {
      return {
        ...styleState,
        navBarWrapper: navBarWrapper,
      };
    }

    default: {
      return styleState;
    }
  }
}

function navReducer(navState, { type, data }) {
  switch (type) {
    case "LOAD": {
      return {
        ...navState,
        ...data,
      };
    }

    case "UPDATE_ITEM": {
      return {
        ...navState,
        ...data,
      };
    }
    case "DISPLAY_NEW": {
      return {
        ...navState,
        ...data,
      };
    }

    default: {
      return navState;
    }
  }
}

function lookForRoute(lookupRoutes, path) {
  let found = {};
  lookupRoutes.reduce((previous, current) => {
    if (current.views) {
      current.views.forEach((item) => {
        if (item.views) {
          item.views.forEach((innerItem) => {
            if (innerItem.path === `/${path}`) {
              found = {
                parentName: current.name,
                collapseState: item.state,
                objectKey: _.snakeCase(item.name),
              };
            }
          });
        } else {
          if (item.path === `/${path}`) {
            found = {
              parentName: current.name,
              collapseState: item.state,
              objectKey: _.snakeCase(item.name),
            };
          }
        }
      });
    }
    return {};
  }, {});
  return found;
}

function removeSupplementalWhenEmpty(formNav, routes, account_type) {
  const supplemental_tag =
    account_type === "business" ? "bd_supplement" : "pd_supplement";
  const hasSupplemental = routes.some(
    ({ tag, views }) => tag === supplemental_tag && views.length === 0
  );

  if (hasSupplemental) {
    for (const [index, { tag }] of formNav.entries()) {
      if (tag === supplemental_tag) {
        //just going until we find the supplemental tag.. and remove it from the array
        return [...formNav.slice(0, index), ...formNav.slice(index + 1)];
      }
    }
  }

  return formNav;
}

export default function useLeftNav() {
  const { width: windowWidth } = useWindowDimensions();
  const { account_type, employee_id } = useAuth();
  let path = useLocation().pathname.replace(/\/app\//g, "");

  const [styleState, styleDispatch] = useReducer(styleReducer, {
    navBarWrapper: 350,
    navWidth: 350,
  });

  const [navState, navDispatch] = useReducer(navReducer, {
    navBarTagMap: {},
    sidebarLoading: true,
    miniActive: true,
    selectedMainMenu: null,
    selectedNavBarMenu: null,
    navBarMapObj: null,
    mainNavBar: null,
    routes: null,
  });

  React.useEffect(() => {
    styleDispatch({
      type: "UPDATE_WRAPPER",
      navBarWrapper: windowWidth < 960 ? 0 : 350,
    });
  }, [windowWidth]);

  function updateItem(selectedNavName, selectedNavTag) {
    navDispatch({
      type: "UPDATE_ITEM",
      data: {
        selectedMainMenu: selectedNavName,
        selectedNavBarMenu: selectedNavTag,
      },
    });
  }

  function displaySelectedItem(newItem) {
    navDispatch({
      type: "DISPLAY_NEW",
      data: {
        ...newItem,
      },
    });
  }

  React.useEffect(() => {
    if (navState.navBarMapObj && navState.routes) {
      const initialNavState = lookForRoute(navState.routes, path);

      displaySelectedItem({ [initialNavState.collapseState]: true });
      updateItem(
        initialNavState.parentName,
        navState.navBarMapObj[initialNavState.objectKey]
      );
    }
  }, [navState.navBarMapObj, navState.routes, path]);

  React.useEffect(() => {
    getSideNavbar({ account_type, employee_id })
      .then(({ formNav, routes }) => {
        const filteredFormNav = removeSupplementalWhenEmpty(
          formNav,
          routes,
          account_type
        );
        routes = routes.map((item) => {
          if (
            item.name === "Supplemental" ||
            item.name === "Business Supplemental"
          ) {
            const views = item["views"];
            const orderedViews = _.orderBy(views, ["name"], ["asc"]);
            item["views"] = orderedViews;
          }
          return item;
        });

        let navBarMapObj = {};
        let navBarTagMap = {};
        let mainNavBar = [];
        _.forEach(filteredFormNav, function (item) {
          navBarTagMap[item.tag] = item;
          if (item.level === 1) {
            mainNavBar.push(item);
          } else {
            if (!_.size(navBarMapObj[item.parent_tag])) {
              navBarMapObj[item.parent_tag] = [];
            }
            navBarMapObj[item.parent_tag].push(item);
          }
        });

        navDispatch({
          type: "LOAD",
          data: {
            selectedMainMenu: null,
            navBarMapObj: navBarMapObj,
            selectedNavBarMenu: null,
            mainNavBar: mainNavBar,
            sidebarLoading: false,
            navBarTagMap: navBarTagMap,
            routes: routes,
          },
        });
      })
      .catch((err) => console.log(err));
  }, [account_type, employee_id]);

  return {
    styleState,
    navState,
    updateItem,
    navDispatch,
    displaySelectedItem,
  };
}
