import { Box, CircularProgress, Typography } from "@material-ui/core";
import Center from "components/flex/Center";
import React from "react";
import withErrorBoundary from "hocs/withErrorBoundary";
import PropTypes from "prop-types";

export const Loader = () => (
  <Center>
    <Box p={1}>
      <CircularProgress />
    </Box>
  </Center>
);

export const Error = () => (
  <Center>
    <Box p={1}>
      <Typography color="error" variant="body2">
        Component Unable To Load
      </Typography>
    </Box>
  </Center>
);

const LoadingComponent = ({ loadingOverwrite = null, status, children }) => {
  if (status === "loading" || status === "idle") {
    if (loadingOverwrite) {
      return loadingOverwrite;
    }
    return <Loader />;
  } else if (status === "error") {
    return <Error />;
  } else if (status === "success") {
    return children;
  }

  return null;
};

LoadingComponent.propTypes = {
  status: PropTypes.oneOf(["loading", "error", "success"]),
  children: PropTypes.node,
  overwriteLoading: PropTypes.node,
};

export default withErrorBoundary(LoadingComponent);
