import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import FormikSelect from "components/Formik/fields/FormikSelect";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useForm from "hooks/useForm.js";
import React from "react";
import { address } from "helpers/initialValues";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import { SectionLabel } from "components/Common/LayoutComponents";
import { SectionSubHeader } from "components/Common/LayoutComponents/";
import AddressLookupAndFields from "components/Formik/formGroups/AddressLookupAndFields";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import Tooltip from "components/Common/Tooltip";
import MultiFormWrapper from "components/Layout/SectionsLayout/MultiFormWrapper";

const CitizenshipAndResidenceForm = ({ innerRef }) => {
  const formData = useForm({
    defaultInitialValues: {
      citizenship_country: null,
      residence_country: null,
    },
    noValidate: true,
    endpoint: "citizenship_employee",
  });

  const { listOfCountries } = useMenuOptions();

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage
        title={"Citizenship & Residence"}
        innerRef={innerRef}
        showSaveButton={false}
      >
        <Grid container spacing={2}>
          <FormikSelect
            label="Country of Citizenship"
            name="citizenship_country"
            options={listOfCountries}
            xs={6}
          />
          <FormikSelect
            label="Country of Residence"
            name="residence_country"
            options={listOfCountries}
            xs={6}
          />
        </Grid>
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

const CitizenshipAdditionalForm = ({ innerRef }) => {
  const formData = useForm({
    defaultInitialValues: {
      uscis_number: "",
      uscis_expiration_date: null,
      petition_number: "",
      naturalization_court: "",
      naturalization_date: null,
      naturalization_court_location: "",
      naturalization_certificate_number: "",
      port_of_entry: "",
      sponsor_name: "",
      sponsor_address: address,
    },
    noValidate: true,
    endpoint: "citizenship_personalDisclosure",
  });

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage
        title={"Additional (If Applicable)"}
        innerRef={innerRef}
        showSaveButton={false}
      >
        <Grid container spacing={2}>
          <FormikTextField
            label="USCIS “A” Number"
            name="uscis_number"
            size={3}
          />
          <FormikDatePicker
            label="Expiration Date"
            name="uscis_expiration_date"
            size={3}
          />
          <SectionLabel
            label="If you are a naturalized citizen of the United States, provide the
            following."
          />
          <FormikTextField
            label="Petition Number"
            name="petition_number"
            size={3}
          />
          <FormikTextField label="Court" name="naturalization_court" size={3} />
          <FormikDatePicker
            label="Naturalization Date"
            name="naturalization_date"
            size={3}
          />
          <FormikTextField
            label="Court City & State"
            name="naturalization_court_location"
            size={5}
          />
          <FormikTextField
            label="Certificate Number"
            name="naturalization_certificate_number"
            size={3}
          />
          <SectionLabel
            label=" If you are not a citizen of the United States, please provide the
            following."
          />
          <FormikTextField
            label="Port of Entry into the US"
            name="port_of_entry"
            size={3}
          />
          <SectionSubHeader divider label="Sponsor Details" />
          <FormikTextField label="Sponsor Name" name="sponsor_name" size={3} />
          <AddressLookupAndFields name="sponsor_address" />
        </Grid>
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

const CitizenshipAttachmentForm = ({ innerRef }) => {
  const formData = useForm({
    defaultInitialValues: {
      birth_certificate_attachment_group: null,
      naturalization_cert_attachment_group_id: null,
      usis_document_attachment_group_id: null,
    },
    noValidate: true,
    endpoint: "citizenship_personalDisclosure",
  });

  return (
    <FormContextProvider
      formData={formData}
      innerRef={innerRef}
      showSaveButton={false}
    >
      <EnhancedFormikPage
        title={"Citizenship Attachments"}
        innerRef={innerRef}
        showSaveButton={false}
      >
        <Grid container spacing={2}>
          <SectionLabel label="Upload Birth Certificate" />
          <FormikFileDropzone
            description="Personal Disclosure"
            label="Upload Attachment"
            name="birth_certificate_attachment_group"
            size={12}
          />
          <SectionLabel
            label="Upload Certificate of Naturalization (If Applicable)"
            tooltip={<Tooltip type="warning" title="Required for New Jersey" />}
          />

          <FormikFileDropzone
            description="Personal Disclosure"
            label="Upload Attachment"
            name="naturalization_cert_attachment_group_id"
            size={12}
          />
          <SectionLabel
            label="Upload USCIS document (If Applicable)"
            tooltip={<Tooltip type="warning" title="Required for New Jersey" />}
          />

          <FormikFileDropzone
            description="Personal Disclosure"
            label="Upload Attachment"
            name="usis_document_attachment_group_id"
            size={12}
          />
        </Grid>
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

const Citizenship = ({ name }) => {
  return (
    <FormSectionWrapper title={name}>
      <MultiFormWrapper>
        <CitizenshipAndResidenceForm />
        <CitizenshipAdditionalForm />
        <CitizenshipAttachmentForm />
      </MultiFormWrapper>
    </FormSectionWrapper>
  );
};

export default Citizenship;
