import React from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  radioGroup: {
    flexDirection: "row",
  },
}));

const BooleanRadio = ({ children, ...props }) => {
  const classes = useStyles();
  const customProps = {
    value: JSON.stringify(props.value) || null,
    onChange: (event) => {
      props.onChange({
        target: { value: JSON.parse(event.target.value), name: props.name },
      });
    },
  };

  return (
    <RadioGroup
      cy-field={props.name}
      cy-type={"radio_group"}
      name={props.name}
      className={classes.radioGroup}
      {...customProps}
    >
      {children}
    </RadioGroup>
  );
};
BooleanRadio.defaultProps = {
  default: false,
};

export default BooleanRadio;
