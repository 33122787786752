import { Button, Chip, IconButton, Grid } from "@material-ui/core";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  text-align: left;
  max-width: 120px;
  max-height: 30px;
  min-width: 120px;
  min-height: 30px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 10px 20px 0px 10px;
  height: 140px;
`;

export const SuccessChip = styled(Chip)`
  background-color: ${({ theme }) => theme.palette.success.light};
  color: ${({ theme }) => theme.palette.success.contrastText};
`;

export const ExpandButton = styled(IconButton)`
  transform: rotate(0deg);
  margin-left: auto;
  transition: ${({ theme }) =>
    theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    })};

  ${({ open }) =>
    open &&
    `
    transform: rotate(180deg);
  `}
`;

export const StyledTabPanel = styled.div`
  height: 500px;
  overflow: auto;
  padding: 20px 15px 20px 0px;
`;

export const ApplicationListGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
