import React, { useState } from "react";

// Components
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./RTE.scss";
import { toolbarOptions } from "./config";
import { Typography } from "@material-ui/core";

const RTE = ({ value = "", preview, onChange, ref }) => {
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(value || ""))
    )
  );

  const onEditorStateChange = (state) => {
    setEditorState(state);
    return onChange(draftToHtml(convertToRaw(state.getCurrentContent())));
  };
  return (
    <div>
      {(!value || value.trim() === "<p></p>" || value.trim() === "") &&
      preview ? (
        <Typography
          color="textSecondary"
          variant="subtitle2"
          style={{ padding: "20px" }}
        >
          Add notes...
        </Typography>
      ) : (
        <div className="editor">
          <Editor
            ref={ref}
            editorState={editorState}
            toolbarHidden={preview ? true : false}
            toolbarClassName="toolbar-class"
            wrapperClassName="wrapper-class"
            editorStyle={{ maxWidth: "inherit", minHeight: "75px" }}
            onEditorStateChange={onEditorStateChange}
            toolbar={toolbarOptions}
          />
        </div>
      )}
    </div>
  );
};

export default RTE;
