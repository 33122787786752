import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Gaming Facility",
  },
  {
    type: fieldTypes.label,
    label: "Please select below the gaming facility you are applying for:",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "new_york_cvavls.application_type_checkbox",
    dynamicMenuItems: ["newYorkCVAVLSApplicationTypeCheckbox"],
    defaultValue: null,
  },
  {
    type: fieldTypes.subHeader,
    label: "Contact Person",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: "Please select below your contact person for this application:",
  },
  {
    type: fieldTypes.bdPersonSelect,
    label: "Contact Person",
    name: "new_york_cvavls.contact_person_id",
  },
  {
    type: fieldTypes.subHeader,
    label: "Qualifications",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: "You are applying for qualification in connection with:",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "new_york_cvavls.application_qualification_checkbox",
    dynamicMenuItems: ["newYorkCVAVLSQualificationCheckbox"],
    defaultValue: null,
  },
];

export default [
  {
    type: "form",
    formSchema,
    endpoint: "bdSupplement",
  },
];
