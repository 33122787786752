import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import useFetch from "queryHooks/useFetch";
import { Box } from "@material-ui/core";
import DisplayTable from "components/Common/Tables/DisplayTable";
import { columns, dependsOnQueryCount } from "./config";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const NewMexicoFinancialHistory = ({ name }) => {
  const { data = [], status } = useFetch(
    "new_mexico_financial_interest_licenses"
  );
  return (
    <FormSectionWrapper title={name}>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={status}>
          <DisplayTable
            records={data}
            linkTo={["business_licensing"]}
            columns={columns}
            dependsOnQueryCount={dependsOnQueryCount}
          />
        </LoadingComponent>
      </Box>
    </FormSectionWrapper>
  );
};

export default NewMexicoFinancialHistory;
