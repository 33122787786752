import PropTypes from "prop-types";
import { formSchemas } from "components/Formik/formGroups/CrudSelect/CrudSelect";
import React from "react";
import GavelIcon from "@material-ui/icons/Gavel";
import PermissionedCrudSelect from "./PermissionedCrudSelect";

const PDLawEnforcementSelect = ({
  name = "law_enforcement_pd_business_entity_id",
  label = "Law Enforcement or Court",
  endpoint = "law_business_entities",
  formTitle = "Add / Edit Law Enforcement or Court",
  usesPermission = true,
  ...rest
}) => {
  return (
    <PermissionedCrudSelect
      name={name}
      label={label}
      endpoint={endpoint}
      saveEndpoint="pdBusinessEntity"
      usesPermission={usesPermission}
      formSchema={formSchemas.pdBusinessSchema}
      formTitle={formTitle}
      overrideDefaultValues={{
        business_type_checkbox: { is_law_enforcement: true },
      }}
      optionIcon={<GavelIcon color="primary" fontSize="small" />}
      {...rest}
    />
  );
};

PDLawEnforcementSelect.propTypes = {
  endpoint: PropTypes.string,
  formTitle: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  xs: PropTypes.number,
  usesPermission: PropTypes.bool,
};

export default PDLawEnforcementSelect;
