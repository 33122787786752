import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Application Type",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "michigan_supplier_license.inital_or_renewal_checkbox",
    dynamicMenuItems: ["michiganSupplierLicenseInitialOrRenewalCheckbox"],
    defaultValue: null,
  },
  {
    type: fieldTypes.subHeader,
    label: "License Type",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "michigan_supplier_license.application_type_checkbox",
    dynamicMenuItems: ["michiganSupplierLicenseApplicationTypeCheckbox"],
    defaultValue: null,
  },
  {
    type: fieldTypes.subHeader,
    label: "Authorized Contact",
    divider: true,
  },
  {
    type: fieldTypes.bdPersonSelect,
    label: "Contact Person",
    name: "michigan_supplier_license.authorized_contact_person_id",
    xs: 6,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "bdSupplement",
};
