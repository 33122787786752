import { Grid } from "@material-ui/core";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, dataHookProps, tableQuestions } from "./config.js";
import { FirstMiddleLast } from "components/Formik/formGroups/FirstMiddleLast.jsx";

const Form = () => {
  const {
    spouseTypeValues,
    parentTypeValues,
    siblingSpouseTypeValues,
    otherRelativeTypeValues,
  } = useMenuOptions();

  const allRelatives = []
    .concat(spouseTypeValues)
    .concat(parentTypeValues.slice(1))
    .concat(siblingSpouseTypeValues.slice(1))
    .concat(otherRelativeTypeValues.slice(1));

  return (
    <Grid container spacing={2}>
      <FirstMiddleLast />

      <FormikSelect
        label="Relationship"
        name="relative_type"
        options={allRelatives}
        helperText="If Related"
        xs={4}
      />
      <FormikTextField label="Social Security Number" name="ssn" xs={4} />
    </Grid>
  );
};

const IowaApplicantFamilyMembers = ({ name }) => {
  const formData = useTabularData(dataHookProps);

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title="Person Details">
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Person" />
    </FormSectionProvider>
  );
};

export default IowaApplicantFamilyMembers;
