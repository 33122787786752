export const tableQuestions = null;

export const defaultInitialValues = {
  bd_pennsylvania_eea: {
    unemployment_compensation_number: "",
    revenue_corporate_box_number: "",
    small_diverse_business_certificate_number: "",
    liquor_control_license_number: "",
    workers_compensation_number: "",
    state_entity_number: "",
  },
};

export const endpoint = "bdSupplement";

export const tabularDataParams = {
  defaultInitialValues,
  endpoint,
};
