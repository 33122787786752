import { Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import Tooltip from "components/Common/Tooltip";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import PDFinancialSelect from "components/Formik/formGroups/CrudSelect/selects/PDFinancialSelect.jsx";
import PDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/PDPersonSelect.jsx";
import FormikMultiYearFileUploader from "components/Formik/formGroups/FormikMultiYearFileUploader";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Brokerage or Dealer" />

      <PDFinancialSelect
        label="Brokerage or Dealer"
        name="dealer_pd_business_entity_id"
      />

      <SectionSubHeader label="Broker Details" divider />
      <FormikTextField
        label="Name of Broker"
        name="broker_person_name"
        xs={4}
      />
      <FormikTextField label="Broker Phone" name="broker_phone" xs={4} />

      <SectionSubHeader
        label="Family Member (If Applicable)"
        divider
        tooltip={
          <Tooltip
            title="If this account is held by an Immediate Family member select the
            person."
          />
        }
      />

      <PDPersonSelect label="Family Member" />

      <SectionSubHeader label="Account Details" divider />
      <FormikTextField label="Account Number" name="account_number" xs={4} />
      <FormikTextField label="Type of Account" name="account_type" xs={4} />
      <FormikNumberField label="Margin Amount" name="margin_amount" xs={4} />
      <FormikNumberField
        label="Account Balance"
        name="account_balance"
        xs={4}
      />
      <FormikDatePicker label="Date of Balance" name="balance_date" xs={3} />

      <FormikMultiYearFileUploader
        title="Account Statements"
        name="attachment_group_id"
      />
    </Grid>
  );
};

const MarginAccounts = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "margin_accounts_marginAccount",
    nonRequired: ["relative_pd_person_id"],
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default MarginAccounts;
