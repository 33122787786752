import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import {
  SectionSubHeader,
  SectionLabel,
} from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikCheckBoxGroup from "components/Formik/formGroups/FormikCheckBoxGroup";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import FormikGoogleLookup from "components/Formik/fields/FormikGoogleLookup.jsx";
import { FormikAddressFields } from "components/Formik/formGroups/FormikAddressFields.jsx";
import useEndPoint from "hooks/useEndpoint.js";

const Form = () => {
  const { listOfCountries, formOfBusiness, businessTypeCheckboxOptions } =
    useMenuOptions();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormikCheckBoxGroup
          label="Check all that apply to this business entity"
          name="business_type_checkbox"
          menuOptions={businessTypeCheckboxOptions}
          row
        />
      </Grid>

      <SectionSubHeader label="Business Address" divider />

      <FormikGoogleLookup
        fields={{
          address: "business_address",
          name: "business_name",
          phone: "business_phone",
          website: "business_website",
        }}
      />

      <FormikTextField
        header="Business Details"
        divider
        label="Business Name"
        name="business_name"
        xs={4}
      />

      <FormikSelect
        label="Form of Business"
        name="form_of_business"
        options={formOfBusiness}
        xs={4}
      />
      <FormikTextField label="Business Phone" name="business_phone" xs={4} />

      <FormikTextField
        label="Business Website"
        name="business_website"
        xs={4}
      />

      <SectionSubHeader label="Business Address" divider />

      <FormikAddressFields addressPrefix="business_address." />

      <SectionSubHeader label="Incorporation Details" divider />

      <FormikTextField
        label="Incorporation State"
        name="incorporation_state"
        xs={4}
      />
      <FormikSelect
        label="Incorporation Country"
        name="incorporation_country"
        options={listOfCountries}
        xs={4}
      />
      <SectionSubHeader label="Business Overview" divider />
      <FormikTextField
        label="Current Business Status"
        name="business_status"
        xs={4}
      />
      <FormikTextField
        label="Business Description"
        name="business_description"
        xs={12}
        multiline
        rows={2}
      />
      <SectionLabel label="If Gaming was part of the operations of the Business Entity, describe how." />
      <FormikTextField
        label="Gaming Activity Description"
        name="gaming_activity_description"
        xs={12}
        multiline
        rows={2}
      />
    </Grid>
  );
};

const BusinessEntities = ({ name }) => {
  const { fullEndpoint } = useEndPoint("business_entities_pdBusinessEntity");

  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "business_entities_business_entities",
    saveEndpoint: fullEndpoint,
    nonRequired: [
      "incorporation_state",
      "incorporation_country",
      "business_status",
      "business_description",
      "gaming_activity_description",
      "form_of_business",
    ],
  });

  return (
    <FormSectionProvider formData={formData} title={name}>
      <EnhancedFormikDialog title="Business Interest Details">
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        subheader={<InfoAlert>{tableQuestions}</InfoAlert>}
        columns={columns}
        addButtonText="Add Business"
      />
    </FormSectionProvider>
  );
};

export default BusinessEntities;
