import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  TableCell,
} from "@material-ui/core";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ArchiveIcon from "@material-ui/icons/Archive";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import useLicenseTable from "components/Pages/LicensingControl/useLicenseTable";
import { useMultiSelect } from "components/Pages/LicensingControl/components/FilterTable/useMultiSelect";
import AgencyCell from "components/Pages/LicensingControl/components/AgencyCell";
import { StyledTableRow } from "components/Pages/LicensingControl/components/DetailsDialog/Modal.styles.js";
import LicenseCell from "components/Pages/LicensingControl/components/LicenseCell";
import { headCells } from "components/Pages/LicensingControl/Employees/config.js";
import {
  filterEntities,
  getNextExpiry,
  extractLicenses,
  getUniqueValues,
} from "components/Pages/LicensingControl/helpers.js";
import useAnchorEl from "hooks/useAnchorEl";
import useInput from "hooks/useInput";
import PropTypes from "prop-types";
import React from "react";
import FilterTable from "../components/FilterTable/FilterTable";
import { EmployeeFilterForm } from "./components/EmployeeFilterForm";

const defaultFilterFn = {
  fn: (items) => {
    return items;
  },
};

const Row = ({
  handleClickRecord,
  handleArchiveRecord,
  item: { id, license, full_name },
}) => {
  const personName = full_name;
  const positionName = "";
  const departmentName = "";

  const agencies = license.map(
    (item) => item?.license_agency_pd_business_entity?.agency_name || ""
  );

  const lcLicense = license;

  const nextExpiry = React.useMemo(() => {
    return lcLicense ? getNextExpiry(lcLicense) : [];
  }, [lcLicense]);

  const { anchorEl, toggle, setOff } = useAnchorEl();

  const ClickableCell = ({ children, ...rest }) => (
    <TableCell onClick={() => handleClickRecord(id)} {...rest}>
      {children}
    </TableCell>
  );
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={setOff}
      >
        <MenuItem
          onClick={() => {
            handleClickRecord(id);
            setOff();
          }}
        >
          <ListItemIcon>
            <AccountBoxIcon color="action" />
          </ListItemIcon>
          View Record
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleArchiveRecord(id);
            setOff();
          }}
        >
          <ListItemIcon>
            <ArchiveIcon color="action" />
          </ListItemIcon>
          Archive Employee
        </MenuItem>
      </Menu>
      <StyledTableRow hover>
        <ClickableCell style={{ paddingLeft: "20px" }}>
          {personName}
        </ClickableCell>
        <ClickableCell>{departmentName}</ClickableCell>
        <ClickableCell>{positionName}</ClickableCell>
        <ClickableCell>
          {agencies?.length ? <AgencyCell agencies={agencies} /> : "N/A"}
        </ClickableCell>
        <ClickableCell>{nextExpiry}</ClickableCell>
        <ClickableCell width="25%">
          {lcLicense?.length ? <LicenseCell licenses={lcLicense} /> : "N/A"}
        </ClickableCell>
        <TableCell onClick={toggle}>
          <IconButton size="small">
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </StyledTableRow>
    </>
  );
};

Row.propTypes = {
  handleArchiveRecord: PropTypes.func,
  handleClickRecord: PropTypes.func,
  handleDeleteRecord: PropTypes.func,
  item: PropTypes.object,
};

export const EmployeeEmploymentTable = ({
  records,
  openDetails,
  handleClickRecord,
  handleDeleteRecord,
  handleArchiveRecord,
}) => {
  const [filterFn, setFilterFn] = React.useState(defaultFilterFn);

  const [searchValue, handleSearch] = useInput();

  const { tableHeadProps, getSortedRecords } = useLicenseTable({
    records,
    filterFn,
    defaultOrderBy: "expiry",
    defaultOrder: "asc",
  });

  const extractedLicenses = extractLicenses(records);

  const industryFilterOptions = getUniqueValues({
    array: extractedLicenses,
    property: "type",
  });

  const dispositionFilterOptions = getUniqueValues({
    array: extractedLicenses,
    property: "disposition",
  });

  const industryProps = useMultiSelect();
  const dispositionProps = useMultiSelect();

  const { value: industryValue } = industryProps;
  const { value: dispositionValue } = dispositionProps;

  const sortedRecords = getSortedRecords();

  const filterCount = industryValue.length + dispositionValue.length;

  const handleSetFilterFn = () => {
    setFilterFn({
      fn: (items) =>
        filterEntities(
          {
            items,
            searchValue,
            industry: {
              filterValues: industryValue,
              name: "license",
              path: "license",
              lookAt: "type",
            },
            disposition: {
              filterValues: dispositionValue,
              name: "license",
              path: "license",
              lookAt: "disposition",
            },
          },
          "employee"
        ),
    });
  };

  return (
    <>
      <FilterTable
        handleSetFilterFn={handleSetFilterFn}
        handleSearch={handleSearch}
        searchValue={searchValue}
        filterCount={filterCount}
        tableHeadProps={tableHeadProps}
        sortedRecords={sortedRecords}
        title="Employees"
        headCells={headCells}
        row={(item) => (
          <Row
            item={item}
            key={item.id}
            style={{ borderSpacing: "20px" }}
            openDetails={openDetails}
            handleClickRecord={handleClickRecord}
            handleDeleteRecord={handleDeleteRecord}
            handleArchiveRecord={handleArchiveRecord}
          />
        )}
        filterForm={
          <EmployeeFilterForm
            industryProps={industryProps}
            dispositionProps={dispositionProps}
            industryOptions={industryFilterOptions}
            dispositionOptions={dispositionFilterOptions}
          />
        }
      />
    </>
  );
};

EmployeeEmploymentTable.propTypes = {
  handleArchiveRecord: PropTypes.func,
  handleClickRecord: PropTypes.func,
  handleDeleteRecord: PropTypes.func,
  openDetails: PropTypes.func,
  records: PropTypes.array,
};
