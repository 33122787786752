import { InputBase, Paper } from "@material-ui/core";
import styled from "styled-components";

export const CopyUrlTextArea = styled(InputBase)`
  resize: none;
  width: 100%;
  overflow: scroll;
  border-color: ${({ theme }) => theme.palette.grey[200]};
  border-radius: 5px;
  font-family: Helvetica, sans-serif;
  color: ${({ theme }) => theme.palette.grey[800]};
  background-color: white;
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const CopyToClipboardWrapper = styled(Paper)`
  background-color: ${({ theme }) => theme.palette.grey[100]};
  padding: ${({ theme }) => theme.spacing(1)}px;
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  display: flex;
  align-items: center;
  width: 100%;
`;
