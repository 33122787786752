import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import { Card, CardContent } from "@material-ui/core";
import {
  SectionSubHeader,
  Paragraph,
} from "components/Common/LayoutComponents";
import CustomLink from "components/Common/Link";

const IndianaSWRRequiredAttachments = ({ name }) => {
  return (
    <FormSectionWrapper title={name}>
      <Card variant="outlined">
        <CardContent>
          <SectionSubHeader label="Ownership Chart" />
          <Paragraph>
            <>
              Ownership chart showing all parent, subsidiary, sister companies,
              or affiliates of the applicant. Upload your documents in
              <CustomLink to="bd_corporate_documents" />
              under the corporate family tree section.
            </>
          </Paragraph>
          <SectionSubHeader label="Organizational Charts" />
          <Paragraph>
            <>
              Organizational charts for the Applicant illustrating its
              operations. Upload your documents in
              <CustomLink to="bd_corporate_documents" />
              under the management diagrams & organizational charts section.
            </>
          </Paragraph>
          <SectionSubHeader label="Business Service Descriptions" />
          <Paragraph>
            <>
              A description of the services applicant will provide along with
              the entities that you will provide such services. Upload your
              documents in
              <CustomLink to="bd_corporate_documents" />
              under the business plan & description of business section.
            </>
          </Paragraph>
        </CardContent>
      </Card>
    </FormSectionWrapper>
  );
};

export default IndianaSWRRequiredAttachments;
