import PropTypes from "prop-types";
import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { useField } from "formik";
import { FormControlLabel, Radio, FormHelperText } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import styled from "styled-components";
import withFieldTools from "hocs/withFieldTools";

const StyledRadioGroup = styled(RadioGroup)`
  flex-direction: ${({ $row }) => $row ? 'row' : "column"};
`;

function FormikRadioGroup({ row = true, options = [], label, ...rest }) {
  const [field, meta] = useField(rest);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <FormControl error={!!errorText}>
      <FormLabel> {label}</FormLabel>
      <StyledRadioGroup $row={row} {...field}>
        {options.map(({ name, value }) => (
          <FormControlLabel
            key={value}
            value={value}
            control={<Radio color="primary" />}
            label={name}
          />
        ))}
      </StyledRadioGroup>
      {errorText && (
        <FormHelperText id="my-helper-error">{errorText}</FormHelperText>
      )}
    </FormControl>
  );
}

FormikRadioGroup.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.any,
    })
  ),
};

export default withFieldTools(FormikRadioGroup);
