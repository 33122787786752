import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { SectionLabel } from "components/Common/LayoutComponents/index.js";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import Tooltip from "components/Common/Tooltip.js";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone.jsx";
import FormikNumberField from "components/Formik/fields/FormikNumberField.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import useForm from "hooks/useForm";
import React from "react";
import { defaultInitialValues, tableInfo } from "./config";

const USTaxesQuestions = () => {
  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Federal Tax" divider />
      <FormikDatePicker
        label="Date Filed"
        name="federal_tax_date_filed"
        xs={3}
      />
      <FormikTextField
        label="Period Covered"
        name="federal_tax_period_covered"
        xs={3}
      />
      <FormikTextField
        label="IRS/Federal Office Location"
        name="federal_tax_irs_office_location"
        xs={6}
      />
      <SectionSubHeader label="State Tax" divider />
      <FormikDatePicker label="Date Filed" name="state_tax_date_filed" xs={3} />
      <FormikTextField
        label="Period Covered"
        name="state_tax_period_covered"
        xs={3}
      />
      <FormikTextField
        label="IRS/State Office Location"
        name="state_tax_irs_office_location"
        xs={6}
      />
    </Grid>
  );
};

const TaxDocumentsForm = () => {
  return (
    <Grid container spacing={2}>
      <FormikNumberField
        numberFormatProps={{
          allowNegative: false,
          format: "####",
          placeholder: "YYYY",
        }}
        label="Tax Year"
        name="tax_year"
        type="none"
        xs={4}
      />
      <SectionLabel label="Upload a copy of each IRS and State Form, with any amendments, and all appropriate schedules filed by you.">
        <Tooltip title="Note: If you and your spouse filed separate tax returns upload a copy of your spouse’s tax return here." />
      </SectionLabel>
      <FormikFileDropzone
        description="US Taxes"
        name="attachment_group_id"
        xs={12}
      ></FormikFileDropzone>
    </Grid>
  );
};

const USTaxes = ({ name }) => {
  const usTaxesQuestions = useForm({
    noValidate: true,
    defaultInitialValues: {
      federal_tax_date_filed: null,
      federal_tax_period_covered: "",
      federal_tax_irs_office_location: "",
      state_tax_date_filed: null,
      state_tax_period_covered: "",
      state_tax_irs_office_location: "",
    },
    endpoint: "us_taxes_personalDisclosure",
  });

  const taxDocumentsData = useTabularData({
    defaultInitialValues,
    endpoint: "us_taxes_taxReturnUS",
  });
  return (
    <FormSectionWrapper title={name}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormContextProvider formData={usTaxesQuestions}>
            <EnhancedFormikPage
              title="Tax Questions"
              subheader={
                <InfoAlert>
                  State when you filed your last Federal Income Tax and any and
                  all State Income Tax Returns, to what IRS Center and State
                  Center it was send and the tax period it covered.
                </InfoAlert>
              }
            >
              <USTaxesQuestions />
            </EnhancedFormikPage>
          </FormContextProvider>
        </Grid>
        <Grid item xs={12}>
          <FormContextProvider formData={taxDocumentsData}>
            <EnhancedFormikDialog>
              <TaxDocumentsForm />
            </EnhancedFormikDialog>
            <EnhancedActionsTable
              subheader={<InfoAlert>{tableInfo}</InfoAlert>}
              title="US Tax Documents"
              columns={[{ field: "tax_year" }]}
            />
          </FormContextProvider>
        </Grid>
      </Grid>
    </FormSectionWrapper>
  );
};

export default USTaxes;
