import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import MultiFormWrapper from "components/Layout/SectionsLayout/MultiFormWrapper.jsx";
import ApplicantInformationForm from "components/Pages/Supplemental/BD/PennsylvaniaCert/PennsylvaniaCertApplicantInformation/ApplicantInformationForm";
import ApplicantIDNumbersForm from "components/Pages/Supplemental/BD/PennsylvaniaCert/PennsylvaniaCertApplicantInformation/ApplicantIDNumbersForm";

import React from "react";

const PennsylvaniaCertApplicantInformation = ({ name }) => {
  return (
    <FormSectionWrapper title={name}>
      <MultiFormWrapper>
        <ApplicantInformationForm />
        <ApplicantIDNumbersForm />
      </MultiFormWrapper>
    </FormSectionWrapper>
  );
};

export default PennsylvaniaCertApplicantInformation;
