export const defaultInitialValues = {
  iowa_class_d: {
    is_state_tax_filed: null,
    is_state_tax_extension_filed: null,
    io_state_tax_extension_explain: "",
    io_state_tax_department_address: "",
    is_federal_tax_filed: null,
    is_federal_tax_extension_filed: null,
    io_federal_tax_extension_explain: "",
    io_federal_irs_office_location: "",
    io_all_company_names_owned: "",
    io_all_company_names_abondoned: "",
  },
};

export const endpoint = "bdSupplement";
