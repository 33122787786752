//MONTANA
import MontanaDisclosureQuestions from "components/Pages/Supplemental/PD/Montana/MontanaDisclosureQuestions/MontanaDisclosureQuestions";
import MontanaPersonalCriminalHistoryStatement from "components/Pages/Supplemental/PD/Montana/MontanaPersonalCriminalHistoryStatement/MontanaPersonalCriminalHistoryStatement";

const colorado_pages = {
  montana_disclosure_questions: MontanaDisclosureQuestions,
  montana_personal_criminal_history_statement: MontanaPersonalCriminalHistoryStatement,
};

export default colorado_pages;
