import React from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
// import AuthNavbar from "components/Layout/Navbars/AuthNavbar.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import LoginPage from "views/Pages/LoginPage.js";
import Auth0VerifyPage from "views/Pages/Auth0VerifyPage.js";

import login from "assets/img/login.jpeg";
import AcceptInvite from "views/Pages/AcceptInvite";
import ResetPassword from "views/Pages/ResetPassword";
import SelectNewPassword from "views/Pages/SelectNewPassword";

const useStyles = makeStyles(styles);

export default function Pages() {
  const auth = [
    {
      path: "/invite/:inviteHash",
      component: AcceptInvite,
      layout: "/auth",
    },
    {
      path: "/resetPassword/:resetHash",
      component: SelectNewPassword,
      layout: "/auth",
    },
    {
      path: "/reset_password",
      component: ResetPassword,
      layout: "/auth",
    },
    {
      path: "/login_page",
      name: "Login",
      component: LoginPage,
      layout: "/auth",
    },
    {
      path: "/verify_login",
      name: "Verify Login",
      component: Auth0VerifyPage,
      layout: "/auth",
    },
  ];

  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
  }, []);

  const getRoutes = () => {
    return auth.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const loginMatch = useRouteMatch("/auth/login_page");
  const inviteMatch = useRouteMatch("/auth/invite");
  const resetMatch = useRouteMatch("/auth/reset_password");
  const selectPasswordMatch = useRouteMatch("/auth/resetPassword");

  const getBgImage = () => {
    if (loginMatch || inviteMatch || resetMatch || selectPasswordMatch) {
      return login;
    }
  };

  return (
    <div className={classes.wrapper} ref={wrapper}>
      <div
        className={classes.fullPage}
        style={{ backgroundImage: "url(" + getBgImage() + ")" }}
      >
        <Switch>
          {getRoutes(auth)}
          <Redirect from="/auth" exact to="/auth/login_page" />
          <Redirect from="/auth/invite" exact to="/auth/login_page" />
        </Switch>
      </div>
    </div>
  );
}
