import * as yup from "yup";

export const validationSchema = yup.object().shape({
  discipline_type: yup.string().required("Required Field"),
});
//
export const columns = [
  {
    field: "from_date",
    format: "date",
  },
  {
    field: "to_date",
    format: "date",
  },
  {
    title: "Business Entity",
    field: "pd_business_entity.business_name",
  },
  {
    field: "position",
  },
  {
    field: "is_key_person",
    format: "boolean",
  },
];

export const defaultInitialValues = {
  pd_business_entity_id: null,
  is_key_person: false,
  supervisor: "",
  employment_location: "",
  compensation_annual: null,
  compensation_monthly: null,
  position: "",
  duties: "",
  from_date: null,
  to_date: null,
  is_current: false,
  reason_left: "",
  compensation_at_departure: "",
  employment_discipline: [],
  attachment_group_id: null,
};

export const disciplineColumns = [
  { field: "discipline_date" },

  {
    title: "Action",
    field: "discipline_type",
    format: "startCase",
  },
  {
    field: "discipline_reason",
  },
];

export const disciplineDefaultInitialValues = {
  employment_id: null,
  discipline_date: null,
  supervisor: "",
  discipline_reason: "",
  severance_details: "",
  unemployment_benefit_details: "",
  discipline_type: "",
};
