import React from "react";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Business Documents",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload certified copies of all charters, articles of incorporation,
        by-laws, articles of organization, operating agreements, partnership
        agreements, trust agreements, or other similar documents of the
        applicant, including all amendments thereto in the{" "}
        <CustomLink to="bd_corporate_documents" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload a proof of Registration with the Louisiana Secretary of State if
        domiciled outside of Louisiana in the{" "}
        <CustomLink to="bd_application_details" /> section under the Authorized
        States Tab.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label:
      "Provide the signature Authorization Card(s) for each operating account of the applicant.",
  },
  {
    type: fieldTypes.subHeader,
    label: "Annual Reports",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload the most recent annual report of the applicant that was submitted
        to shareholders, partners, members or other persons and meeting minutes
        from the last 12 months in the
        <CustomLink to="bd_financial_statements" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload The most recent annual report(s) filed with the Secretary of
        State or similar official for all states in which the applicant conducts
        business in the <CustomLink to="bd_financial_statements" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Publicly Traded Entities",
  },
  {
    type: fieldTypes.label,
    label:
      "A corporation that is a registrant under the Securities Act of 1933 or the Securities Exchange Act of 1934 shall submit a copy of the most recent annual report prepared on Form 10K.",
  },
  {
    type: fieldTypes.label,
    label:
      "A corporation that is a registrant with the Securities and Exchange Commission (SEC) shall submit a copy of the Form 1 OQ last filed with the SEC, if filed after the latest 1 OK filing.",
  },
  {
    type: fieldTypes.label,
    label:
      "A corporation that is a registrant with the SEC shall submit a copy of the most recent Form 8K filed with the SEC if filed after the latest 1 OK filing.",
  },
  {
    type: fieldTypes.subHeader,
    label: "Organization Charts",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        A flowchart illustrating the fully diluted ownership of the applicant.
        List all parent, subsidiary or intermediary companies until the
        flowchart reflects 100% of the stock, partnership, membership or
        ownership interest as being held by a natural person(s) and not other
        legal persons. If the ultimate parent company is publicly traded and no
        natural person controls more than 5% of the publicly traded stock,
        indicate that in a footnote to the flowchart. Upload the flowchart in
        the <CustomLink to="bd_corporate_documents" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload a chart showing the corporate structure of the applicant{" "}
        <CustomLink to="bd_corporate_documents" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload an organizational chart identifying all officers, directors,
        managers, managing members, partners and key/managerial employees of the
        applicant. Include position descriptions and the names of persons
        holding such positions
        <CustomLink to="bd_corporate_documents" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Tax Returns",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload all U.S. Corporate Income Tax Returns, or all U.S. Partnership
        Returns, or personal tax returns, and state business or personal tax
        returns, for the past year. Include all amended returns, schedules and
        attachments to tax returns in the <CustomLink to="bd_tax_returns" />
        section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload a list of all IRS 1099 recipients for the past year in the{" "}
        <CustomLink to="bd_tax_returns" />
        section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Photograph Requirements",
  },
  {
    type: fieldTypes.label,
    label:
      "Exterior of applicant's business address(es) showing the address, production facility, storage/warehouse and record keeping area.",
  },
  {
    type: fieldTypes.subHeader,
    label: "Additional Required Attachments",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        The following attachments are only required by:
        <ul>
          <li>Manufacturer of Slot Machine and Video Draw Poker Devices</li>
          <li>
            Manufacturer of Gaming Equipment other than Slot Machines and Video
            Draw Poker Devices
          </li>
          <li>Gaming Supplier</li>
          <li>Sport Wagering Service Provider</li>
          <li>Sports Wagering Distributor</li>
        </ul>
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label:
      "Certificate of registration required by Title 15 of the United States Code.",
  },
  {
    type: fieldTypes.label,
    label:
      "Current list of locations and account numbers for all Uniform Commercial Code filings.",
  },
];

export default [
  {
    type: "readOnlyPage",
    formSchema,
    endpoint: "bdSupplement",
  },
];
