import { Box } from "@material-ui/core";
import BusinessIcon from "@material-ui/icons/Business";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import BigButton from "components/Common/CustomButtons/BigButton";
import SimpleDialog from "components/Common/Dialogs/SimpleDialog";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledDialog = styled(SimpleDialog)`
  & .MuiDialogContent-root {
    padding: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }
`;

const SelectBusinessOrPersonDialog = ({
  handleAddPerson,
  handleAddBusiness,
  ...rest
}) => (
  <StyledDialog
    title={"Would you like to add a business or person?"}
    divider
    {...rest}
  >
    <Box
      display="flex"
      justifyContent="space-around"
      alignItems="center"
      mt={2}
      m={4}
      height="200px"
    >
      <BigButton
        onClick={handleAddPerson}
        icon={<PersonAddIcon color="primary" fontSize="large" />}
        label="Add Person"
      />
      <BigButton
        onClick={handleAddBusiness}
        icon={<BusinessIcon color="primary" fontSize="large" />}
        label="Add Business"
      />
    </Box>
  </StyledDialog>
);

SelectBusinessOrPersonDialog.propTypes = {
  handleAddBusiness: PropTypes.func,
  handleAddPerson: PropTypes.func,
};

export default SelectBusinessOrPersonDialog;
