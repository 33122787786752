import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import IndianaDisclosureQuestionsForm from "./IndianaDisclosureQuestionsForm";
import { defaultInitialValues, endpoint } from "./config";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import useForm from "hooks/useForm";

const IndianaDisclosureQuestions = ({ name }) => {
  const formData = useForm({
    defaultInitialValues,
    noValidate: true,
    endpoint,
  });

  return (
    <FormSectionWrapper title={name} formData={formData}>
      <EnhancedFormikPage>
        <IndianaDisclosureQuestionsForm />
      </EnhancedFormikPage>
    </FormSectionWrapper>
  );
};

export default IndianaDisclosureQuestions;
