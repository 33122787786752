import { foreignTaxes } from "../../uiStrings";

export const columns = [
  { field: "tax_year" },
  { field: "country_filed" },
  { field: "tax_amount", format: "currency" },
];

export const tableQuestions = foreignTaxes.tableQuestion;

export const defaultInitialValues = {
  region_type: "non_united_states",
  tax_year: "",
  country_filed: "",
  tax_amount: null,
  filing_reason: "",
  attachment_group_id: null,
};
