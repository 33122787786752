import { TableCell, TableRow } from "@material-ui/core";
import { dayjsUTC } from "helpers/apiHelpers";
import { startCase } from "lodash";
import React from "react";
import styled from "styled-components";

const StyledTableRow = styled(TableRow)`
  padding: 80px;
  padding-top: 10px;
  margin: 80px;
  font-weight: 300;
`;

export const Row = ({ item }) => {
  return (
    <>
      <StyledTableRow>
        <TableCell style={{ paddingLeft: "20px" }}>{item.name}</TableCell>
        <TableCell>{item.related_business_name}</TableCell>
        <TableCell>{item.license_type_name}</TableCell>
        <TableCell>{item.agency_name}</TableCell>
        <TableCell>{startCase(item.license_type_category)}</TableCell>
        <TableCell>{startCase(item.current_status)}</TableCell>
        <TableCell>
          {item.license_expiry_date &&
            dayjsUTC(item.license_expiry_date, "MMM/DD/YYYY")}
        </TableCell>
      </StyledTableRow>
    </>
  );
};
