import IconButton from "@material-ui/core/IconButton";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import EditIcon from "@material-ui/icons/EditOutlined";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import React from "react";
import Input from "@material-ui/core/Input";
import { startCase } from "lodash";
import {
  StyledCell,
  StyledSelectCell,
  StyledTable,
  StyledTableRow,
  StyledTableHead,
} from "./EditableTable.styles";
import { TableContainer, Typography } from "@material-ui/core";

const InputComponent = ({ editComponent, ref, ...props }) => (
  <>
    {editComponent ? (
      React.cloneElement(editComponent, { ...props })
    ) : (
      <Input ref={ref} {...props} />
    )}
  </>
);

const ActionButtons = ({
  isEditMode,
  id,
  onSave,
  onCancelAdd,
  onToggleEditMode,
  handleDelete,
}) => (
  <>
    {isEditMode ? (
      <>
        <IconButton
          color="primary"
          aria-label="done"
          onClick={() => onSave(id)}
        >
          <SaveIcon />
        </IconButton>
        {id === 0 && (
          <IconButton aria-label="revert" onClick={() => onCancelAdd()}>
            <RevertIcon />
          </IconButton>
        )}
      </>
    ) : (
      <>
        <IconButton aria-label="delete" onClick={() => onToggleEditMode(id)}>
          <EditIcon />
        </IconButton>
        <IconButton aria-label="delete" onClick={() => handleDelete(id)}>
          <DeleteIcon />
        </IconButton>
      </>
    )}
  </>
);

const ViewComponent = ({ value, type }) => {
  let displayValue = value;
  if (type === "startCase") {
    displayValue = startCase(displayValue);
  }
  if (type === "number") {
    displayValue = "$" + value;
  }

  return <Typography>{displayValue}</Typography>;
};

const EditableTableRow = ({ actionButtons, columns, row, onChange }) => {
  const { isEditMode } = row;
  const inputEl = React.useRef();

  React.useEffect(() => {
    if (isEditMode) inputEl.current.focus();
  }, [isEditMode]);

  return (
    <StyledTableRow $isEditMode={isEditMode}>
      <StyledSelectCell>{actionButtons}</StyledSelectCell>
      {columns.map(({ name, editComponent, type }) => (
        <StyledCell align="left" key={name}>
          {isEditMode ? (
            <InputComponent
              inputRef={inputEl}
              editComponent={editComponent}
              value={row[name]}
              name={name}
              onChange={(e) => onChange(e, row)}
            />
          ) : (
            <ViewComponent value={row[name]} type={type} />
          )}
        </StyledCell>
      ))}
    </StyledTableRow>
  );
};

const EditableTable = ({ columns, rows, actions }) => {
  return (
    <TableContainer style={{ maxHeight: 250 }}>
      <StyledTable>
        <StyledTableHead>
          <TableRow>
            <TableCell align="left" />
            {columns.map(({ label, name }) => (
              <TableCell key={name} align="left">
                {label}
              </TableCell>
            ))}
          </TableRow>
        </StyledTableHead>
        <TableBody style={{ overflow: "scroll", height: "150px" }}>
          {rows.map((row) => (
            <EditableTableRow
              key={row.id}
              columns={columns}
              row={row}
              onChange={actions.onChange}
              actionButtons={
                <ActionButtons
                  isEditMode={row.isEditMode}
                  id={row.id}
                  {...actions}
                />
              }
            />
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default EditableTable;
