import { Button, Snackbar, CircularProgress } from "@material-ui/core";
import React from "react";
import { CopyToClipboardWrapper, CopyUrlTextArea, FlexWrapper } from "./styles";
import Center from "../../flex/Center";

const CopyToClipboard = ({ copyText, loading }) => {
  const textAreaRef = React.useRef(null);

  const [copiedSnackbarOpen, setCopiedSnackbarOpen] = React.useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setCopiedSnackbarOpen(false);
  };

  const copyToClipboard = (e) => {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    setCopiedSnackbarOpen(true);
  };
  return (
    <>
      <Snackbar
        open={copiedSnackbarOpen}
        onClose={handleCloseSnackbar}
        autoHideDuration={6000}
        message="Link Copied!"
      />
      <FlexWrapper>
        <CopyToClipboardWrapper elevation={0}>
          {loading ? (
            <Center>
              <CircularProgress size={20} />
            </Center>
          ) : (
            <CopyUrlTextArea
              margin="dense"
              rows={1}
              onClick={copyToClipboard}
              inputRef={textAreaRef}
              readOnly
              value={copyText}
              inputProps={{ style: { fontSize: 14 } }}
            />
          )}
        </CopyToClipboardWrapper>
        <Button
          color="primary"
          size="small"
          onClick={copyToClipboard}
          style={{ width: 100 }}
        >
          Copy Link
        </Button>
      </FlexWrapper>
    </>
  );
};

export default CopyToClipboard;
