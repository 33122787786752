import NewJerseyAncillaryDisclosureQuestions from "components/Pages/Supplemental/PD/NewJerseyAncillary/NewJerseyAncillaryDisclosureQuestions/NewJerseyAncillaryDisclosureQuestions";
import NewJerseyAncillaryLegalProceedings from "components/Pages/Supplemental/PD/NewJerseyAncillary/NewJerseyAncillaryLegalProceedings/NewJerseyAncillaryLegalProceedings";
import NewJerseyAncillaryAttachments from "components/Pages/Supplemental/PD/NewJerseyAncillary/NewJerseyAncillaryAttachments/NewJerseyAncillaryAttachments";

const new_jersey_ancillary_pages = {
  new_jersey_ancillary_disclosure_questions: NewJerseyAncillaryDisclosureQuestions,
  new_jersey_ancillary_legal_proceedings: NewJerseyAncillaryLegalProceedings,
  new_jersey_ancillary_attachments: NewJerseyAncillaryAttachments,
};

export default new_jersey_ancillary_pages;
