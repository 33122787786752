import { Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikSelect from "components/Formik/fields/FormikSelect";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import BDPartySelect from "components/Formik/formGroups/CrudSelect/selects/BDPartySelect";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import { useMenuOptions } from "hooks/useMenuOptions";
import React from "react";
import { Divider } from "../../../../Common/LayoutComponents/index";
import FormikNumberField from "../../../../Formik/fields/FormikNumberField";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";

const BribesAndKickbacksForm = () => {
  const { currencyExchanges, bribeOtherPartyRole, bribeCategory } =
    useMenuOptions();
  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Applicant Party" />
      <BDPartySelect name="bd_party_id" label="Applicant Party" />

      <SectionSubHeader label="Other Party" />
      <BDPartySelect name="related_bd_party_id" label="Other Party" />

      <FormikSelect
        label="Other Party's Role"
        name="related_bd_party_role"
        options={bribeOtherPartyRole}
        xs={4}
      />
      <Divider style={{ padding: "20px" }} />

      <SectionSubHeader label="Payment Details" />

      <FormikSelect
        label="Payment Type"
        name="bribe_category"
        options={bribeCategory}
        xs={4}
      />

      <FormikDatePicker label="Date" name="from_date" />

      <FormikTextField label="Payment Method" name="payment_method" xs={4} />

      <FormikTextField
        rows={2}
        multiline
        label="Details"
        name="details"
        xs={12}
      />

      <BDPartySelect label="Intermediary" name="intermediary_pd_party_id" />

      <FormikTextField label="Position Sought" name="position_sought" xs={6} />
      <SectionSubHeader label="Amount" divider />
      <FormikSelect
        label="Currency Type"
        name="currency_type"
        options={currencyExchanges}
        xs={3}
      />
      <FormikNumberField
        textFieldProps={{ type: "none" }}
        label="Original Amount"
        name="amount_original"
        xs={3}
      />
    </Grid>
  );
};

const BribesAndKickbacks = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "bdBribe?bribe_category!=political",
    nonRequired: [
      "intermediary_pd_party_id",
      "position_sought",
      "payment_method",
    ],
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title="Details">
        <BribesAndKickbacksForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
    </FormSectionProvider>
  );
};

export default BribesAndKickbacks;
