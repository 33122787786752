import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import {
  columns,
  defaultInitialValues,
  tableQuestions,
  nonRequired,
} from "./config.js";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import PDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/PDPersonSelect.jsx";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <PDPersonSelect
        label="Spouse (If Applicable)"
        header="Select if Obligation holder was your Spouse"
      />
      <SectionSubHeader label="Nature & Borrower" divider />
      <FormikTextField
        label="Person(s) responsible for Obligation"
        name="borrower_names"
        xs={6}
      />
      <FormikTextField
        label="Nature of Obligation"
        name="nature"
        helperText="Personal Guarantee, etc"
        xs={6}
      />
      <SectionSubHeader label="Obligation Details" divider />
      <FormikDatePicker
        label="Date Obligation Made"
        name="obligation_date"
        xs={4}
      />
      <FormikNumberField
        textFieldProps={{ type: "none" }}
        label="Obligation Amount"
        name="amount_original"
        xs={4}
      />
      <FormikTextField
        label="Status of Underlying Obligation"
        name="obligation_status"
        xs={4}
      />
    </Grid>
  );
};

const LoanGuarantees = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "loan_guarantees_loanCosign",
    nonRequired,
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default LoanGuarantees;
