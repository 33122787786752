import React from "react";
import { Select, InputLabel, FormControl, MenuItem } from "@material-ui/core";
import {
  usePdfState,
  usePdfDispatch,
  ACTIONS,
} from "components/DevTools/PdfTools/Context/PdfToolsProvider.jsx";

export default function TableRows() {
  const dispatch = usePdfDispatch();
  const { tableRows, column } = usePdfState();
  const handleChange = (e) => {
    dispatch({
      type: ACTIONS.SET_STATE,
      value: e.target.value,
      name: "tableRows",
    });
  };

  function countRows(num) {
    const rows = [];
    for (let i = 0; i < num; i++) {
      rows.push(parseInt(`${i + 1}`));
    }
    return rows;
  }

  if (column) {
    return (
      <FormControl
        variant="filled"
        style={{ width: "150px", marginRight: "10px" }}
      >
        <InputLabel>Table Rows</InputLabel>
        <Select value={tableRows || ""} onChange={handleChange}>
          {[...countRows(10)].map((val) => (
            <MenuItem key={val} value={val}>
              {val}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else {
    return <></>;
  }
}
