import { getFormComponents } from "componentGenerators/helpers";
import bd_pennsylvania_gspr_supplement_application_information from "components/Pages/Supplemental/BD/PennsylvaniaGSPR/PennsylvaniaGSPRApplicationInformation/PennsylvaniaGSPRApplicationInformation";
import bd_pennsylvania_gspr_supplement_gaming_service_provider from "components/Pages/Supplemental/BD/PennsylvaniaGSPR/PennsylvaniaGSPRGamingServiceProvider/PennsylvaniaGSPRGamingServiceProvider";
import bd_pennsylvania_gspr_supplement_agreement from "components/Pages/Supplemental/BD/PennsylvaniaGSPR/PennsylvaniaGSPRAgreement/PennsylvaniaGSPRAgreement";
import bd_pennsylvania_gspr_supplement_attachments from "components/Pages/Supplemental/BD/PennsylvaniaGSPR/PennsylvaniaGSPRAttachments/PennsylvaniaGSPRAttachments";

export default getFormComponents({
  bd_pennsylvania_gspr_supplement_application_information,
  bd_pennsylvania_gspr_supplement_gaming_service_provider,
  bd_pennsylvania_gspr_supplement_agreement,
  bd_pennsylvania_gspr_supplement_attachments,
});
