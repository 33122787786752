import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.yesNo,
    label:
      "Is the Company or any of its Principal Officers, Directors, or Management Officials currently party to any civil lawsuits?",
    name: "cherokee_vendor_license.is_in_lawsuit_currently",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Has the Company or any of its Principal Officers, Directors, or Management Officials in the previous ten (10) years been accused of intentional misconduct?",
    name: "cherokee_vendor_license.has_misconduct",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Has the Company or any of its Principal Officers, Directors, or Management Officials been party to any other litigation in which an ultimate decision would have or could have a current or future effect on the business?",
    name: "cherokee_vendor_license.has_litigation_affecting_business",
  },
  {
    type: fieldTypes.label,
    label:
      " If you answered Yes to any of the above questions. Please provide details below including the agency name, address (address, city, state, zip), the action and status.",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "cherokee_vendor_license.litigation_explanation",
    xs: 12,
    rows: 4,
  },
];

export default [
  {
    type: "form",
    formSchema,
    endpoint: "bdSupplement",
  },
];
