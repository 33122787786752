import React from "react";
import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormikSelect from "components/Formik/fields/FormikSelect";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import { useMenuOptions } from "hooks/useMenuOptions";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikNumberField from "../../../../Formik/fields/FormikNumberField";

import { columns, tableQuestions, tabularDataParams } from "./config.js";
import YesNo from "components/Formik/formGroups/YesNo";
import BDPartySelect from "components/Formik/formGroups/CrudSelect/selects/BDPartySelect";

const PoliticalContributionsForm = () => {
  const { currencyExchanges, bribeOtherPartyRole } = useMenuOptions();

  return (
    <Grid container spacing={2}>
      <BDPartySelect
        label="Applicant Party"
        header="Applicant Party"
        name="bd_party_id"
      />

      <BDPartySelect
        label="Other Party"
        header="Other Party"
        name="related_bd_party_id"
      />

      <FormikSelect
        label="Other Parties Role"
        name="related_bd_party_role"
        options={bribeOtherPartyRole}
        xs={4}
      />
      <FormikTextField
        xs={8}
        label="Candidate Position (if applicable)"
        name="position"
      />

      <SectionSubHeader label="Petition and Appointments" divider />
      <YesNo
        label="Did the Applicant Party loan funds?"
        name="is_funds"
        xs={12}
      />
      <YesNo
        label="Did the applicant Party donate or loan property or any other thing of value?"
        name="is_property"
        xs={12}
      />
      <YesNo
        label="Did the Applicant Party loan, donate, or make disbursements to principals, employees or any third parties for the purpose of reimbursing those individuals/parties?"
        name="is_reimbursement"
        xs={12}
      />

      <SectionSubHeader label="Details" divider />
      <FormikDatePicker label="Date" name="from_date" />
      <FormikTextField xs={8} label="Details" name="details" />

      <SectionSubHeader label="Value" divider />
      <FormikSelect
        label="Currency Type"
        name="currency_type"
        options={currencyExchanges}
        xs={3}
      />

      <FormikNumberField
        textFieldProps={{ type: "none" }}
        label="Original Amount"
        name="amount_original"
        xs={3}
      />
    </Grid>
  );
};

const PoliticalContributions = ({ name }) => {
  const formData = useTabularData(tabularDataParams);

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <PoliticalContributionsForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
    </FormSectionProvider>
  );
};

export default PoliticalContributions;
