import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { FormikDialog } from "components/Formik/formContainers/FormikDialog";
import useForm from "hooks/useForm";
import PropTypes from "prop-types";
import React from "react";

const AddLicensePrompt = ({ handleClose, open, addLicenseNow }) => (
  <Dialog open={open} onClose={handleClose}>
    <DialogTitle>Would you like to add licences/certifications?</DialogTitle>
    <DialogContent>
      <DialogContentText>
        The employee record was saved successfully. You may add licenses or
        certification now, or return later by selecting the employee in the
        employees table.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Add Later
      </Button>
      <Button
        onClick={addLicenseNow}
        color="primary"
        autoFocus
        variant="contained"
      >
        Add Now
      </Button>
    </DialogActions>
  </Dialog>
);

AddLicensePrompt.propTypes = {
  addLicenseNow: PropTypes.func,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};

const AddEntity = ({
  open,
  handleClose,
  handleEditEntityRecord,
  setLicensesFormInitiallyOpen,
  initialValues,
  validationSchema,
  children,
  endpoint,
  queryKey,
  modelTitle = "",
}) => {
  const [newEntity, setNewEntity] = React.useState(null);

  const successCallback = (data) => {
    setNewEntity(data);
    handleClose();
  };

  const cleanNewEntity = () => setNewEntity(null);

  const addLicenseNow = () => {
    handleEditEntityRecord(newEntity.id);
    setLicensesFormInitiallyOpen(true);
    cleanNewEntity();
  };

  const formData = useForm({
    defaultInitialValues: initialValues,
    endpoint: endpoint,
    queryKey: queryKey,
    noValidate: true,
    fetchEnabled: false,
    isNewRecord: true,
    fetchOptions: { useFullEndpoint: false },
    successCallback,
    shouldResetOnSuccess: true,
    postValues: ({ lc_position_type: _lc_position_type, ...values }) => values,
  });

  const {
    formikProps: { open: _open, ...formikProps },
  } = formData;

  return (
    <>
      <FormikDialog
        title={modelTitle}
        open={open}
        onCancel={handleClose}
        {...formikProps}
        validationSchema={validationSchema}
      >
        {children}
      </FormikDialog>
      <AddLicensePrompt
        handleClose={cleanNewEntity}
        addLicenseNow={addLicenseNow}
        open={!!newEntity}
      />
    </>
  );
};

AddEntity.propTypes = {
  handleClose: PropTypes.func,
  handleEditEntityRecord: PropTypes.func,
  open: PropTypes.bool,
  setLicensesFormInitiallyOpen: PropTypes.func,
  endpoint: PropTypes.string.isRequired,
  queryKey: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  modelTitle: PropTypes.string,
};

export default AddEntity;
