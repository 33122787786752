import DeniedPageDisplay from "components/Permission/DeniedPageDisplay";
import React from "react";
import { Route } from "react-router-dom";
import { Link } from "react-router-dom";

import components from "routeHelpers/components";

const getRoutes = (routesLocal, collab_permissions) => {
  const hasCollabPermission = collab_permissions !== null;

  return routesLocal.map((prop, key) => {
    if (prop.collapse) {
      //if the container contains a view - pass the correspondent object from collab_permissions as collab_permissions
      const permissionMapping =
        collab_permissions?.[prop.tag] && prop.views
          ? collab_permissions?.[prop.tag]
          : null;

      return getRoutes(prop.views, permissionMapping, hasCollabPermission);
    }
    if (prop.layout === "/app") {
      const Component =
        components[prop.form_status_id] ||
        (() => {
          return (
            <>
              <h1>Page under Construction</h1>
              <Link to="/app/dashboard">Back to Dashboard</Link>
            </>
          );
        });

      //Only block user from view page if the user is a collab and if collab_permissions were created for the specific section of the app.
      if (hasCollabPermission && collab_permissions) {
        const path = prop.path.replace("/", "");
        const currentLookup = collab_permissions?.[path] || {
          read: false,
          write: false,
        };

        const hasPermission =
          currentLookup["read"] || currentLookup["write"] ? true : false;

        //currentLookup points to the current page's path inside the collab permission object
        if (!currentLookup || !hasPermission) {
          return (
            <Route
              path={prop.layout + prop.path}
              key={key}
              component={DeniedPageDisplay}
            />
          );
        }

        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            render={(renderProps) => <Component {...prop} {...renderProps} />}
          />
        );
      }

      return (
        <Route
          path={prop.layout + prop.path}
          key={key}
          render={(renderProps) => <Component {...prop} {...renderProps} />}
        />
      );
    } else {
      return null;
    }
  });
};

export default getRoutes;
