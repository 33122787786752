import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Popover,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

export const FiltersMenu = ({
  anchorEl,
  handleClose,
  applyFilter,
  filterForm,
  filterStyle,
}) => {
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Card>
        <CardHeader title="Filters" />
        <CardContent style={filterStyle ? filterStyle : { width: "300px" }}>
          {filterForm}
        </CardContent>
        <CardActionArea>
          <Button
            fullWidth
            color="primary"
            onClick={applyFilter}
            component="div"
          >
            Apply
          </Button>
        </CardActionArea>
      </Card>
    </Popover>
  );
};

FiltersMenu.propTypes = {
  agencyProps: PropTypes.object,
  anchorEl: PropTypes.object,
  applyFilter: PropTypes.func,
  filterForm: PropTypes.node,
  handleClose: PropTypes.func,
};
