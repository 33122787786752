import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import {
  columns,
  defaultInitialValues,
  endpoint,
  sortingOptions,
} from "./config.js";

export const LocationsForm = () => {
  return (
    <Grid container spacing={2}>
      <FormikTextField label="Location" name="location_number" xs={6} />
    </Grid>
  );
};

const Locations = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint,
  });

  return (
    <FormSectionProvider formData={formData} title={name}>
      <EnhancedFormikDialog title={name}>
        <LocationsForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        columns={columns}
        addButtonText="Add Location"
        {...sortingOptions}
      />
    </FormSectionProvider>
  );
};

export default Locations;
