import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import * as yup from "yup";

const defaultValidationSchema = yup.object().shape({});

const FormWrapper = ({
  initialValues,
  fetchFn = () => null,
  handleSubmit,
  validationSchema = defaultValidationSchema,
  children,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const submitData = async (values, { onSuccess, onError }) => {
    await fetchFn(values).then(onSuccess).catch(onError);
  };

  const defaultHandleSubmit = (values, { setSubmitting }) => {
    submitData(values, {
      onSuccess: () => {
        setSubmitting(false);
        enqueueSnackbar("Form Saved", {
          variant: "success",
          autoHideDuration: 2000,
        });
      },
      onError: () => {
        setSubmitting(false);
        enqueueSnackbar("Error", {
          variant: "error",
        });
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit || defaultHandleSubmit}
      validationSchema={validationSchema}
      {...rest}
    >
      <Form>{children}</Form>
    </Formik>
  );
};

export default FormWrapper;
