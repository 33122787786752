import useFetch from "queryHooks/useFetch";
import { useAuth } from "context/auth.js";

export const useLCApplicationAssignments = () => {
  return useFetch("lcApplicationAssignments", "licensing/lcEmployeePdfForm", {
    useFullEndpoint: false,
    select: (assignments) => {
      return assignments.map((assignment) => ({
        ...assignment,
        name: `${assignment.employee.first_name} ${assignment.employee.last_name}`,
        pdf_form_name: assignment.pdf_form.name,
      }));
    },
  });
};

export const usePDApplicationAssignments = () => {
  const { employee_id } = useAuth();

  return useFetch(
    ["pdApplicationAssignments", employee_id],
    "personal_details_employee_pdf_forms",
    {
      select: (assignments) => {
        return assignments.map((assignment) => ({
          ...assignment,
          name: assignment.created_by_account_name,
        }));
      },
    }
  );
};
