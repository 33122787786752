import React from "react";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Business Documents",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        <ul>
          <li>
            Provide Certified copies of all charters, articles of incorporation,
            by-laws, articles of organization, operating agreements, partnership
            agreements, trust agreements or other similar documents of the
            Applicant, including all amendments. These documents can be uploaded
            in the
            <CustomLink to="bd_corporate_documents" />
            section.
          </li>
          <br />
          <li>
            Attach a copy of your Proof of Registration with the Maryland State
            Department of Assessments and Taxation (Maryland SDAT) in the
            <CustomLink to="bd_application_details" />
            section, under the <b>Authorized States</b> Tab.
          </li>
        </ul>
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Financial Statements",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        <ul>
          <li>
            Attach a copy of the two (2) most recent years&apos; financial
            statements, specifically &apos;Balance Sheets&apos; and &apos;Profit
            and Loss Statements&apos;, in the
            <CustomLink to="bd_financial_statements" />
            section.
          </li>
        </ul>
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Publically traded entities",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        If your business entity is a corporation that:
        <ul>
          <li>
            Is a registrant under the Securities Act of 1933 or the Securities
            Exchange Act of 1934, please attach a copy of the your most recent
            annual reports prepared on Form 10K.
          </li>
          <br />
          <li>
            Is a registrant with the Securities and Exchange Commission (SEC),
            please attach a copy of the Form 10Q for the last two filings.
          </li>
          <br />
          <li>
            Is a registrant with the SEC, please attach a copy of the most
            recent Form 8K filed with the SEC, if filed after the latest 10K
            filing.
          </li>
        </ul>
        You may upload the documents listed above in the
        <CustomLink to="bd_government_reports" />
        section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Organizational Charts",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please attach a copy of the following in the{" "}
        <CustomLink to="bd_corporate_documents" />
        section.
        <ul>
          <li>
            A flowchart illustrating the fully diluted ownership of the
            Applicant. List all parent, subsidiary or intermediary companies
            until the flowchart reflects 100% of the stock, partnership,
            membership or ownership interest as being held by an individual(s)
            and not other legal persons. If the ultimate parent company is
            publicly traded and no natural person controls more than 5% of the
            publicly traded stock, indicate that in a footnote to the flowchart.
          </li>
          <br />
          <li>A chart showing the corporate structure of the Applicant</li>
          <br />
          <li>
            An organizational chart identifying all officers, directors,
            managers, managing members, partners and key/managerial employees of
            the Applicant. Include position descriptions and the names of
            persons holding such positions.
          </li>
        </ul>
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Tax Returns",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please upload a copy of
        <ul>
          <li>
            Please attach a copy of all U. S. Corporate Income Tax Returns, or
            all U. S. Partnership Returns, or personal tax returns and state
            business or personal tax returns for the past three years (include
            all amended returns, exhibits and attachments to tax returns) in the{" "}
            <CustomLink to="bd_tax_returns" />
            section.
          </li>
          <br />
          <li>Attach list of all IRS 1099 recipients for the past year.</li>
        </ul>
      </>
    ),
  },
];

export default [
  {
    type: "readOnlyPage",
    formSchema,
  },
];
