import { Box, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const BigButtonContainer = styled(Box)`
  border-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 5px;
  &:hover {
    cursor: pointer;
  }
`;

const BigButton = ({ icon, label, ...props }) => (
  <BigButtonContainer
    display="flex"
    flexDirection="column"
    border={1}
    p={3}
    {...props}
  >
    <Box display="flex" justifyContent="center" pb={2}>
      {icon}
    </Box>

    <Typography variant="button" color="primary" align="center">
      {label}
    </Typography>
  </BigButtonContainer>
);
export default BigButton;
