import React from "react";
import { SectionSubHeader } from "components/Common/LayoutComponents/";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormikAsyncSelect from "components/Formik/fields/FormikAsyncSelect";
import useForm from "hooks/useForm.js";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import { Grid } from "@material-ui/core";
import { defaultInitialValues, endpoint } from "./config.js";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <SectionSubHeader>Associated Business Entity</SectionSubHeader>
      <FormikAsyncSelect
        label="Business Entity"
        name="nevada_phr.associated_business_entity_id"
        endpoint="nevada_phr_disclosure_questions_associated_business_entities_a_grouped"
        getOptionLabel={(option) =>
          `${option.business_name} (${option.association})`
        }
        getOptionValue={({ pd_business_entity_id }) => pd_business_entity_id}
      />

      <FormikTextField
        label="Type of Business Entity"
        name="nevada_phr.business_entity_type"
        xs={6}
      />

      <FormikTextField
        label="Employer Identification Number"
        name="nevada_phr.employer_identification_number"
        xs={6}
      />

      <SectionSubHeader divider>Application Details</SectionSubHeader>

      <FormikTextField
        label="Name of Game"
        name="nevada_phr.game_name"
        xs={4}
      />
    </Grid>
  );
};

const NevadaPhryDisclosureQuestions = ({ title }) => {
  const formData = useForm({
    defaultInitialValues,
    endpoint,
  });

  return (
    <FormSectionWrapper title={title}>
      <FormContextProvider formData={formData}>
        <EnhancedFormikPage>
          <Form />
        </EnhancedFormikPage>
      </FormContextProvider>
    </FormSectionWrapper>
  );
};

export default NevadaPhryDisclosureQuestions;
