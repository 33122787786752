import React from "react";

import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents/";
import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import useForm from "hooks/useForm.js";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import { Grid } from "@material-ui/core";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import { defaultInitialValues, endpoint } from "./config";

const NewMexicoTaxLastReturnForm = ({ innerRef }) => {
  const formData = useForm({
    defaultInitialValues: defaultInitialValues,
    endpoint: endpoint,
  });

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage innerRef={innerRef} showSaveButton={false}>
        <Grid container spacing={2}>
          <SectionSubHeader label="Federal, State And Foreign Tax Information" />
          <SectionLabel label="When did you file your last state income tax return:" />

          <FormikDatePicker
            label="Last Statement Date"
            name="new_mexico.last_state_tax_return_date"
            xs={4}
          />

          <FormikTextField
            label="Period Covered"
            name="new_mexico.last_state_tax_return_period"
            xs={4}
          />

          <FormikTextField
            label="State of Filling"
            name="new_mexico.last_state_tax_return_filling_state"
            xs={4}
          />
        </Grid>
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

export default NewMexicoTaxLastReturnForm;
