import PropTypes from "prop-types";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  CircularProgress,
  Paper,
  Divider,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import LinearProgress from "@material-ui/core/LinearProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AttachmentIcon from "@material-ui/icons/Attachment";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import * as Sentry from "@sentry/react";
import PdfModal from "components/Common/PdfModal";
import { dayjsUTC } from "helpers/apiHelpers";
import React, { useRef } from "react";
import styled from "styled-components";
import { get } from "lodash";

const UploadsList = styled(List)`
  max-height: 250px;
  overflow: auto;
`;

const BorderLinearProgress = withStyles(() => ({
  bar: {
    backgroundColor: (props) => props.isComplete && "#19b366",
  },
}))(LinearProgress);

const getExtension = (name) => name.substring(name.lastIndexOf(".") + 1);

const getIcon = (extension) => {
  if (extension === "pdf") return <PictureAsPdfIcon />;
  return <AttachmentIcon />;
};

const RejectedItem = ({ file, errors }) => (
  <ListItem>
    {file?.name || ""}
    <List>
      {errors.map(({ code, message }) => {
        return (
          <ListItem key={code}>
            <Typography color="error">{message}</Typography>
          </ListItem>
        );
      })}
    </List>
  </ListItem>
);

RejectedItem.propTypes = {
  errors: PropTypes.array.isRequired,
  file: PropTypes.shape({
    name: PropTypes.string,
  }),
};

export const RejectedFiles = ({ fileRejections }) => {
  const shouldSendError = useRef(false);
  const SentryGenericMessage = ({ error }) => {
    shouldSendError.current = true;
    Sentry.captureMessage(error);
    return null;
  };
  return (
    <div>
      <h4>Rejected files</h4>
      <List>
        {fileRejections.map(({ file, errors, i }) => (
          <RejectedItem key={`${file.path}_${i}`} file={file} errors={errors} />
        ))}
      </List>
      {shouldSendError.current === false && (
        <SentryGenericMessage
          error={JSON.stringify(
            fileRejections.reduce((prev, cur) => {
              return prev.concat(cur.errors);
            }, [])
          )}
        />
      )}
    </div>
  );
};

RejectedFiles.propTypes = {
  fileRejections: PropTypes.array,
};

export const DropArea = ({
  children,
  getRootProps,
  getInputProps,
  dropzoneClass,
  classes,
}) => (
  <section>
    {children}
    <div {...getRootProps()}>
      <input {...getInputProps()} />

      <Typography data-testid="drop-area" className={dropzoneClass}>
        <CloudUploadOutlinedIcon className={classes.icon} />
        <span style={{ color: red[300], marginRight: "5px" }}>Drop</span>
        files to upload, or{" "}
        <span style={{ color: red[300], margin: "0 5px" }}>Click</span> to
        browse files
      </Typography>
    </div>
  </section>
);

DropArea.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  dropzoneClass: PropTypes.string,
  getInputProps: PropTypes.func,
  getRootProps: PropTypes.func,
};

const UploadInProgressItem = ({ name, progress }) => {
  return (
    <ListItem>
      <Box display="flex" flexDirection="column" width={0.5} pb={1}>
        <ListItemText secondary={name} />
        <Box pt={0.5}>
          <BorderLinearProgress
            variant="determinate"
            value={progress}
            isComplete={progress === 100}
          />
        </Box>
      </Box>
    </ListItem>
  );
};

UploadInProgressItem.propTypes = {
  name: PropTypes.string,
  progress: PropTypes.number,
};

export const RecentlyAddedFiles = ({ recentlyAddedFiles }) => (
  <List>
    {recentlyAddedFiles.map(({ file, progress }, i) => {
      if (!get(recentlyAddedFiles, `${i}.file.name`)) return null;
      return (
        <UploadInProgressItem
          key={file.name + i}
          name={file.name || ""}
          progress={progress}
        />
      );
    })}
  </List>
);

RecentlyAddedFiles.propTypes = {
  recentlyAddedFiles: PropTypes.array,
};

export const UploadCompleteItem = ({
  file: { name, created_at },
  handleClickFile,
  handleDeleteFile,
}) => {
  const extension = getExtension(name);

  const icon = getIcon(extension);

  const formattedDate = dayjsUTC(created_at, "MMM D YYYY");
  return (
    <ListItem button onClick={handleClickFile}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={name} secondary={formattedDate} />
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="delete" onClick={handleDeleteFile}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

UploadCompleteItem.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string,
    created_at: PropTypes.string,
  }).isRequired,
  handleClickFile: PropTypes.func,
  handleDeleteFile: PropTypes.func,
};

export const FileViewer = ({ attachment, isLoading = false }) => {
  const fileLink = useRef();
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    if (attachment) {
      const extension = attachment.name.split(".").pop();
      const modalExtensions = ["pdf", "jpg", "jpeg", "png"];
      // opening iframe modal only for files with extension in modalExtensions
      if (modalExtensions.indexOf(extension) !== -1) {
        setIsOpen(true);
      } else {
        fileLink.current.click();
      }
    }
  }, [attachment]);

  if (isOpen) {
    return (
      <PdfModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        url={attachment.url}
        loading={isLoading}
      />
    );
  }

  return (
    <a
      href={attachment ? attachment.url : "#"}
      style={{ display: "none" }}
      ref={fileLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      hidden link
    </a>
  );
};

FileViewer.propTypes = {
  attachment: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
};

export function UploadedFiles({
  files,
  handleDeleteAttachment,
  handleClickFile,
  status,
  refetchingAttachments,
}) {
  const isFetchingAttachment = status === "loading" || refetchingAttachments;

  const sortedFiles = React.useMemo(
    () =>
      files
        .slice()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
    [files]
  );

  return (
    <Paper variant="outlined">
      <Box p={2} display="flex" justifyContent="space-between">
        <Typography variant="body2">
          <Box fontWeight="fontWeightMedium" component="span">
            Uploaded Files:
          </Box>
        </Typography>
        <div>
          {!isFetchingAttachment && (
            <Typography variant="body2">{`${files.length} files`}</Typography>
          )}
        </div>
      </Box>
      <Divider />
      {files.length === 0 && !isFetchingAttachment && (
        <Box display="flex" justifyContent="center" align-items="center" pt={2}>
          <Typography>No Uploaded Files</Typography>
        </Box>
      )}
      <UploadsList>
        {sortedFiles.map((file, i) => (
          <UploadCompleteItem
            key={file.name + i}
            file={file}
            handleClickFile={() => handleClickFile(file)}
            handleDeleteFile={() => handleDeleteAttachment(file)}
          />
        ))}
      </UploadsList>
      {isFetchingAttachment && (
        <Box display="flex" justifyContent="center" align-items="center" mb={1}>
          <CircularProgress size={25} />
        </Box>
      )}
    </Paper>
  );
}

UploadedFiles.propTypes = {
  files: PropTypes.array.isRequired,
  handleClickFile: PropTypes.func,
  handleDeleteAttachment: PropTypes.func,
  refetchingAttachments: PropTypes.bool,
  status: PropTypes.string,
};
