import { Grid } from "@material-ui/core";
import {
  Paragraph,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import CustomLink from "components/Common/Link";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone.jsx";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm.js";
import React from "react";
import { defaultInitialValues, endpoint } from "./config.js";

const Form = () => {
  return (
    <Grid container spacing={1}>
      <SectionSubHeader label="Applicant's Organization Documents" />
      <Paragraph>
        <>
          State of incorporation, registration or other type of formation
          (attach Certified copies Date of Formation of the Articles of
          Incorporation, Charter, Bylaws, Partnership Agreement or other
          official documents and all amendments and proposed amendments). Upload
          your document in
          <CustomLink to="bd_corporate_documents" />
        </>
      </Paragraph>
      <SectionSubHeader label="Ownership and management tables" />
      <Paragraph>
        <>
          Current ownership and management tables of organization. Upload your
          documents in
          <CustomLink to="bd_corporate_documents" />
        </>
      </Paragraph>
      <SectionSubHeader label="Contracts" />
      <Paragraph>
        <>
          Provide the name(s), telephone number, mailing address and goods or
          service provided for all second tier and lower subcontractors
          contracted for this project. Upload your documents in
          <CustomLink to="bd_contracts" />
        </>
      </Paragraph>
      <SectionSubHeader label="Federal tax returns and related documents" />
      <Paragraph>
        <>
          Provide Federal tax returns and related documents for the last three
          years and, where appropriate, State tax returns and related documents
          for the one year preceding this application. Upload your documents in
          <CustomLink to="bd_tax_returns" />
        </>
      </Paragraph>
      <SectionSubHeader label="Commonwealth Debarment List" />
      <Paragraph>
        <>
          Has the applicant ever been placed on the Commonwealth Debarment List
          maintained by the Pennsylvania Department of General Services? If yes,
          provide the dates of any such debarment and explain the reason for the
          debarment.
        </>
      </Paragraph>
      <FormikFileDropzone
        description="Commonwealth Debarment List"
        name="commonwealth_debarment_list_attachment_group_id"
        xs={12}
      />
      <SectionSubHeader label="Bus Services" />
      <Paragraph>
        <>
          If your enterprise will be providing bus services to any Pennsylvania
          casino, either directly or indirectly through an arrangement with a
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          bus company, supply copies of the enterprise's Certificates and/or
          Registrations issued by the Pennsylvania Public Utilities Commission
          and/or Federal Motor Carrier Safety Administration that allow the
          enterprise to provide bus services.
        </>
      </Paragraph>
      <FormikFileDropzone
        description="PA Bus Services"
        name="pa_bus_services_list_attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};

const PennsylvaniaCertRequiredAttachments = ({ name }) => {
  const formData = useForm({ defaultInitialValues, endpoint });

  return (
    <FormSectionProvider formData={formData} title={`${name}`}>
      <EnhancedFormikPage>
        <Form />
      </EnhancedFormikPage>
    </FormSectionProvider>
  );
};

export default PennsylvaniaCertRequiredAttachments;
