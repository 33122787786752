export function compareKeys(a, b) {
  var aKeys = Object.keys(a).sort();
  var bKeys = Object.keys(b).sort();
  return JSON.stringify(aKeys) === JSON.stringify(bKeys);
}

export function arrayOfNumbersFromYear([yearMinus, size]) {
  yearMinus *= -1;
  yearMinus--;
  size++;
  return Array.from(
    { length: size },
    (_, i) => yearMinus + size + new Date().getFullYear() - i
  );
}

export function returnKeyBooleanPair(options = null) {
  return Array.isArray(options)
    ? options.reduce(
        (prev, curr) => ({
          ...prev,
          [curr["value"]]: false,
        }),
        {}
      )
    : {};
}

export function isObject(field) {
  return (
    !Array.isArray(field) &&
    typeof field === "object" &&
    field !== null &&
    field !== undefined
  );
}

export const formatCurrency = (number) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(number);

export const downloadURI = (uri) => {
  const link = document.createElement("a");
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// sort from most files to least
export const sortDocumentSections = (data) => {
  return data.sort((a, b) =>
    a.documents.length < b.documents.length ? 1 : -1
  );
};
