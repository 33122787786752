import React from "react";

export const columns = [
  {
    title: "Report Type",
    field: "report_type",
    format: "startCase",
  },
  {
    title: "Effective Year",
    field: "effective_year",
  },
  {
    title: "Description",
    field: "description",
  },
  {
    title: "Has Attachments",
    field: "corporate_report_attachment_group_id",
    render: ({ corporate_report_attachment_group_id }) => {
      if (corporate_report_attachment_group_id) {
        return "Yes";
      }
      return "No";
    },
  },
];

export const tableQuestions = (
  <>
    Provide a copy of any quarterly, semi annual or annual reports prepared for
    submission to government agencies for past 10 (ten) years.
  </>
);

export const nonRequired = ["corporate_report_attachment_group_id"];

export const defaultInitialValues = {
  //bd_business_entity_id: null,
  report_type: "government_agency_report",
  effective_year: null,
  description: "",
  corporate_report_attachment_group_id: null,
};
