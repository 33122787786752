import React from "react";

export const columns = [
  { title: "Held By", field: "held_by_name" },
  {
    field: "quantity_held",
  },
  {
    title: "Issuing Company",
    field: "issuing_pd_business_entity.business_name",
  },
  { field: "purchase_date" },
  {
    field: "adjusted_cost_base",
    format: "currency",
  },
  {
    field: "market_value",
    format: "currency",
  },
  { field: "valuation_date" },
];

export const tableQuestions = (
  <>
    Provide information for all stocks, bonds, mutual funds, commodity accounts,
    options, warrants, etc., held or controlled by you, your spouse, domestic
    partner or dependent children in any jurisdiction. Whenever interest exists
    through a mutual fund or holding company, the individual stocks or bonds
    held by such mutual fund or holding company need not be listed; whenever
    such interest exists through a beneficial interest in a trust, the
    securities held in such trust shall be listed if you, your spouse or
    dependent children have knowledge of what securities are so held.
  </>
);

export const nonRequired = [
  "ownership_percent",
  "ownership_percent",
  "relative_pd_person_id",
  "attachment_group_id",
];

export const defaultInitialValues = {
  relative_pd_person_id: null,
  issuing_pd_business_entity_id: null,
  dealer_pd_business_entity_id: null,
  security_type: "",
  quantity_held: null,
  acquisition_unit_price: null,
  adjusted_cost_base: null,
  purchase_date: null,
  ownership_percent: null,
  market_value: null,
  valuation_date: null,
  is_publicly_traded: null,
  attachment_group_id: null,
};

export const endpoint = "securities_security";
