export const columns = [
  {
    title: "From",
    field: "from_date",
    format: "date",
  },
  {
    title: "To",
    field: "to_date",
    format: "date",
  },
  {
    title: "Business Name, Address, Web, Phone",
    field: "business_name_address_website_phone",
  },
  { title: "Business Description", field: "business_description" },
  { title: "Association Description", field: "association_description" },
  { title: "Reason for leaving", field: "reason_left" },
  { title: "Gaming Operations", field: "gaming_activity_description" },
]