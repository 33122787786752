import React, { useReducer, createContext, useContext } from "react";

import { getCollabProfile } from "helpers/apiHelpers";

import { useAuth } from "context/auth";
import _ from "lodash";

const LoadCollaborators = createContext();
const ReloadCollaborators = createContext();

function dropDownReducer(state, action) {
  if (action.type === "LOAD") {
    const employee = action.empOptions;
    const business = action.busOptions;
    const license_control = action.lcOptions;
    return {
      ...state,
      collaboratorLoading: false,
      showMenu: action.showMenu,
      empOptions: employee,
      busOptions: business,
      lcOptions: license_control,
      display: action.display,
      value: action.value,
    };
  }
  if (action.type === "RELOAD") {
    return {
      ...state,
      collaboratorLoading: true,
      showMenu: false,
      empOptions: [],
      busOptions: [],
      lcOptions: [],
      display: {},
      value: null,
      selectedType: null,
    };
  }
  return state;
}

function getDisplay(data, employee_id, bd_business_entity_id) {
  if (employee_id) {
    const { name, category } = _.find(data.employee, ({ employee_id: id }) => {
      return id === employee_id;
    }) || { name: "", category: "" };

    return { name, category };
  }
  if (bd_business_entity_id) {
    const { name, category } = _.find(
      data.business,
      ({ bd_business_entity_id: id }) => {
        return id === bd_business_entity_id;
      }
    );

    return { name, category };
  }
  return { name: "", category: "" };
}

export default function CollaboratorsProvider({ children }) {
  const { loading, employee_id, account_type, bd_business_entity_id } =
    useAuth();

  const [state, dispatch] = useReducer(dropDownReducer, {
    collaboratorLoading: true,
    showMenu: false,
    empOptions: [],
    busOptions: [],
    lcOptions: [],
    display: {},
    value: employee_id || null,
    selectedType: account_type || null,
  });

  React.useEffect(() => {
    // get all key personal list for the logged in account only when employee is loaded
    if (!loading && account_type !== "super_admin") {
      getCollabProfile()
        .then(({ data }) => {
          dispatch({
            type: "LOAD",
            empOptions: data ? data.employee : [],
            busOptions: data ? data.business : [],
            lcOptions: data ? data.license_control : [],
            selectedType: account_type,
            display: data
              ? getDisplay(data, employee_id, bd_business_entity_id)
              : "Select User",
            showMenu:
              data &&
              (data.employee.length !== 0 || data.business.length !== 0),
            value: employee_id,
          });
        })
        .catch((err) => console.log(err));
    }
  }, [
    state.collaboratorLoading,
    loading,
    employee_id,
    account_type,
    bd_business_entity_id,
  ]);

  return (
    <LoadCollaborators.Provider value={state}>
      <ReloadCollaborators.Provider
        value={() => {
          dispatch({
            type: "RELOAD",
          });
        }}
      >
        {children}
      </ReloadCollaborators.Provider>
    </LoadCollaborators.Provider>
  );
}

export function useLoadCollaborators() {
  const context = useContext(LoadCollaborators);
  if (context === undefined) {
    throw new Error("useAuth must be used within LoadCollaborators");
  }
  return context;
}

export function useReloadCollaborators() {
  const context = useContext(ReloadCollaborators);
  if (context === undefined) {
    throw new Error("useAuth must be used within AddCollaboratorSelection");
  }
  return context;
}
