import { Box, Divider } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/icons/Menu";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import { Logo } from "assets/oneComply/img/Logo";
import cx from "classnames";
import Button from "components/Common/CustomButtons/Button";
import PropTypes from "prop-types";
import React from "react";
import AppNavbarLinks from "./AppNavbarLinks";
import { SearchField } from "./SearchField";
import AccountDropdown from "components/Common/AccountDropdown/AccountDropdown";
import { useAuth } from "context/auth";

const useStyles = makeStyles(styles);

export default function AppNavbar(props) {
  const { account_type } = useAuth();
  const classes = useStyles();
  const { color } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color,
  });

  return (
    <AppBar
      id="main_header"
      style={{ borderBottom: "solid 0.2em #E89819" }}
      className={classes.appBar + appBarClasses}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "nowrap",
          height: "inherit",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex" }}>
          <Hidden mdUp implementation="css">
            <Box p={1}>
              <Button
                color="transparent"
                justIcon
                aria-label="open drawer"
                onClick={props.handleDrawerToggle}
              >
                <Menu />
              </Button>
            </Box>
          </Hidden>

          <Box
            m={1}
            pr={4}
            mb={1}
            display="flex"
            justifyContent="center"
            width={333}
          >
            <Logo />
          </Box>
          <Hidden smDown>
            <Divider
              orientation="vertical"
              flexItem
              style={{ marginBottom: "10px" }}
            />
            <Box cy-data="accountSelector">
              <AccountDropdown />
            </Box>
          </Hidden>
        </div>

        <Box display="flex">
          <Hidden smDown>
            <Box display="flex" alignItems="center" pb={1} width={1}>
              {account_type !== "super_admin" && <SearchField />}
            </Box>
          </Hidden>

          <Box display="flex" alignItems="center" pb={0.5}>
            <AppNavbarLinks />
          </Box>
        </Box>
      </div>
    </AppBar>
  );
}

AppNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func,
};
