import { Grid } from "@material-ui/core";
import {
  Divider,
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import { NumberFormatTypes } from "components/Common/Inputs/NumberField";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import PDBusinessSelect from "components/Formik/formGroups/CrudSelect/selects/PDBusinessSelect.jsx";
import PDFinancialSelect from "components/Formik/formGroups/CrudSelect/selects/PDFinancialSelect.jsx";
import PDRelativeSelect from "components/Formik/formGroups/CrudSelect/selects/PDRelativeSelect.jsx";
import YesNo from "components/Formik/formGroups/YesNo";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import ColumnSum from "components/Layout/SectionsLayout/ColumnSum.jsx";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import {
  columns,
  defaultInitialValues,
  endpoint,
  nonRequired,
  tableQuestions,
} from "./config.js";

const SecuritiesForm = () => {
  return (
    <Grid container spacing={2}>
      <PDRelativeSelect
        label="Spouse or Child (If Applicable)"
        header="Select if held by Spouse or Dependent Child"
      />

      <SectionSubHeader header="Dealer" divider />
      <PDFinancialSelect label="Dealer" name="dealer_pd_business_entity_id" />

      <SectionSubHeader header="Issuing Company" divider />
      <PDBusinessSelect
        name="issuing_pd_business_entity_id"
        label="Issuing Company"
      />

      <SectionSubHeader label="Security Details" divider />
      <FormikTextField label="Type of Security" name="security_type" xs={4} />
      <FormikNumberField
        label="Quantity Held"
        name="quantity_held"
        helperText="Securities or Contracts"
        type={NumberFormatTypes.none}
        xs={4}
      />
      <FormikNumberField
        label="Acquisition Unit Price"
        name="acquisition_unit_price"
        helperText="Unit Price"
        numberFormatProps={{ decimalScale: 4 }}
        xs={4}
      />
      <FormikNumberField
        label="Adjusted Cost"
        name="adjusted_cost_base"
        xs={4}
        helperText="Total Invested (Book Value)"
      />
      <FormikDatePicker label="Purchase Date" name="purchase_date" xs={4} />
      <FormikNumberField
        label="Ownership"
        name="ownership_percent"
        helperText="If Greater Then 5%"
        type={NumberFormatTypes.percent}
        xs={4}
      />
      <FormikNumberField
        label="Current Market Value"
        name="market_value"
        xs={4}
      />
      <FormikDatePicker label="Valuation Date" name="valuation_date" />
      <YesNo
        label="Is this publicly traded?"
        name="is_publicly_traded"
        default={true}
        xs={12}
      />
      <Divider />
      <SectionLabel label="Optional: Upload a statement instead of entering each security.   If you upload a statement please enter the total holdings in the Current Market Value and fill in the  Valuation Date field" />
      <FormikFileDropzone
        description="Securities"
        label="Upload Attachment"
        name="attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};

const Securities = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint,
    nonRequired,
    overwriteMissingRequiredDisplay: {
      dealer_pd_business_entity_id: "Dealer",
      issuing_pd_business_entity_id: "Issuing Company",
    },
  });

  return (
    <FormSectionProvider formData={formData} title={name}>
      <EnhancedFormikDialog title={name}>
        <SecuritiesForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        columns={columns}
        addButtonText="Add Record"
        subheader={<InfoAlert>{tableQuestions}</InfoAlert>}
        footer={
          <ColumnSum
            name="market_value"
            data={formData.data}
            label="Current Market Value"
          />
        }
      />
    </FormSectionProvider>
  );
};

export default Securities;
