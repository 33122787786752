import Department from "components/Pages/LicensingControl/Department/Department";
import Employees from "components/Pages/LicensingControl/Employees/Employees";
import Businesses from "components/Pages/LicensingControl/Businesses/Businesses";
import Licenses from "components/Pages/LicensingControl/Reports/Licenses";
import EmployeeFileUpload from "components/Pages/LicensingControl/EmployeeFileUpload/EmployeeFileUpload";
import LicenseAgencies from "components/Pages/LicensingControl/LicenseAgencies/LicenceAgencies";
import LicenseTypes from "components/Pages/LicensingControl/LicenseTypes/LicenseTypes";
import Locations from "components/Pages/LicensingControl/Locations/Locations";
import ApplicationAssignments from "components/Pages/LicensingControl/ApplicationAssignments/ApplicationAssignments";

export default {
  lc_employees: Employees,
  lc_businesses: Businesses,
  lc_licenses: Licenses,
  lc_employee_license_upload: EmployeeFileUpload,
  lc_application_assignments: ApplicationAssignments,
  lc_agencies_section: LicenseAgencies,
  lc_license_type_section: LicenseTypes,
  lc_department_section: Department,
  lc_location_section: Locations,
};
