import React from "react";
import styled from "styled-components";
import { Chip, Box } from "@material-ui/core";

const StyledChip = styled(Chip)`
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`;

const ListOfPositions = ({ list = [] }) => {
  return (
    <Box display="flex" maxWidth="300px" overflow="auto">
      {list.map(({ type }) => (
        <StyledChip key={type} size="small" label={type} />
      ))}
    </Box>
  );
};

export const userColumns = [
  { field: "first_name" },
  { field: "last_name" },
  { field: "is_active", format: "boolean" },
  { field: "last_login", format: "date" },
  {
    field: "permissions",
    title: "Type",
    // eslint-disable-next-line react/display-name
    render: ({ permissions }) => <ListOfPositions list={permissions} />,
  },
];

export const appColumns = [
  { field: "jurisdiction_name" },
  { field: "name" },
  { field: "form_added_at", type: "date", format: "date" },
];

export const defaultInitialValues = {
  id: null,
  customer_id: null,
};

export const modalTitle = "Customer Analytics";

export const endpoint = "admin/customerDashboard?id=1";

export const dataHookProps = {
  defaultInitialValues,
  endpoint,
  fetchOptions: { useFullEndpoint: false },
  userColumns,
};
