import { Collapse, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { Form, Formik } from "formik";
import React from "react";
import AccordianItem from "./AccordianItem";
import RoleAndOwnershipFields from "./RoleAndOwnershipFields";
import PDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/PDPersonSelect.jsx";
import { FormikNumberField, FormikCheckBox } from "components/Formik/fields";

const initialValues = {
  id: null,
  ownership_percent: null,
  is_current: true,
  role_checkbox: {
    owner: true,
    key_person: false,
    officer: false,
    registered_agent: false,
  },
};

const NewOwnerInlineForm = ({ toggle, addRecord }) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, formikBag) =>
        addRecord({
          values,
          formikBag,
          handleSaveSuccess: toggle,
        })
      }
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <AccordianItem
          open={true}
          toggle={toggle}
          summary={<Typography>Add New Owner</Typography>}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        >
          <Form>
            <Grid container spacing={2}>
              <Grid item container xs={12}>
                <SectionSubHeader>Select an Existing Person</SectionSubHeader>
                <PDPersonSelect name="pd_person_id" label="Person" xs={12} />
                <FormikNumberField
                  label="Ownership %"
                  name="ownership_percent"
                  xs={8}
                  type="percent"
                />
              </Grid>
              <FormikCheckBox label="Is Current Owner" name="is_current" />
              <Collapse in={Boolean(values.id)} timeout="auto" unmountOnExit>
                <RoleAndOwnershipFields />
              </Collapse>
            </Grid>
          </Form>
        </AccordianItem>
      )}
    </Formik>
  );
};

export default NewOwnerInlineForm;
