import { getModel } from "helpers/apiHelpers";

const getPromises = (tableInfo, personalDisclosure) => {
  const employee_id = personalDisclosure.employee_id;
  return Object.keys(tableInfo).map((item, key) => {
    const endpoint = tableInfo[key].endpoint;
    return endpoint !== "pd"
      ? getModel(`employee/${employee_id}/${endpoint}`)
      : null;
  });
};

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const getData = (tableInfo, personalDisclosure) => {
  return Promise.all(getPromises(tableInfo, personalDisclosure)).then(
    (vals) => {
      return Object.keys(tableInfo).reduce((previous, key) => {
        return {
          ...previous,
          [tableInfo[key].name]: (function () {
            const endpoint = tableInfo[key].endpoint;
            return {
              route: tableInfo[key].route,
              data: tableInfo[key].data.map((item, index) => {
                if (!item) return "";
                switch (index) {
                  case 0:
                  case 1:
                    if (endpoint === "pd") {
                      return personalDisclosure[`${item}`] || "0";
                    } else {
                      return vals[key]
                        ? vals[key].reduce((prev, cur) => {
                            return cur[`${item}`]
                              ? parseFloat(prev) + parseFloat(cur[`${item}`])
                              : parseFloat(prev);
                          }, 0)
                        : "";
                    }
                  case 2:
                    return personalDisclosure[`${item}`] || "";
                  default:
                    return "";
                }
              }),
            };
          })(),
        };
      }, {});
    }
  );
};
