import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import React from "react";
import { size } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const ColapsibleLogList = ({ title, rows }) => {
  return (
    <AccordionDetails>
      <Box display="flex" flexDirection="column">
        <Typography variant="h4" color="error">
          {title}
        </Typography>
        {size(rows) ? (
          Object.entries(rows).map((val, i) => (
            <Typography key={i}>{val[0] + ": " + val[1]}</Typography>
          ))
        ) : (
          <Typography>none.</Typography>
        )}
      </Box>
    </AccordionDetails>
  );
};

const StyledFiberManualRecordIcon = styled(FiberManualRecordIcon)`
  color: ${({ status }) => {
    if (status.includes("Invalid")) {
      return "#ff3d00";
    } else if (status.includes("BREAKING")) {
      return "#d500f9";
    } else {
      return "#76ff03";
    }
  }};
`;

export default function ColapsibleLog({ status, messages }) {
  const classes = useStyles();

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <StyledFiberManualRecordIcon status={status} />
        <Typography className={classes.heading}>{status} </Typography>
      </AccordionSummary>
      {messages.map((val, i) => (
        <ColapsibleLogList
          key={i}
          title={Object.keys(val)[0]}
          rows={Object.values(val)[0]}
        />
      ))}
    </Accordion>
  );
}
