import React from "react";
import { Typography, Box } from "@material-ui/core";
const _ = require("lodash");

const CheckTags = (props) => {
  const { invalidInputs } = props;
  console.log(invalidInputs);
  return (
    <Typography component="div">
      {_.size(invalidInputs.invalid_fields) ? (
        <>
          <Box style={{ color: "red", fontWeight: "bold" }}>Invalid Tags</Box>
          {_.map(invalidInputs.invalid_fields, (err, tagWithPage) => (
            <Box key={tagWithPage}>{`Page: ${
              tagWithPage.split("_").slice(-1)[0]
            } - Tag: ${tagWithPage}: Error: ${err}`}</Box>
          ))}
        </>
      ) : (
        <Box style={{ color: "green", fontWeight: "bold" }}>
          Tags Validated!
        </Box>
      )}

      {_.size(invalidInputs.missing_extra_pages) ? (
        <>
          <Box style={{ color: "red", fontWeight: "bold" }}>
            Missing Extra Pages
          </Box>
          {_.map(
            invalidInputs.missing_extra_pages,
            (pageNumber, extraPageTag) => (
              <Box
                key={extraPageTag}
              >{`${pageNumber} - Tag: ${extraPageTag}`}</Box>
            )
          )}
        </>
      ) : (
        <Box style={{ color: "green", fontWeight: "bold" }}>
          No missing extra page as per the data on local!
        </Box>
      )}
    </Typography>
  );
};

export default CheckTags;
