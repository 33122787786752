import React from "react";

export const columns = [
  {
    title: "Business Entity",
    field: "bd_business_entity.business_name",
  },
  {
    title: "Institution",
    field: "bank_bd_business_entity.business_name",
  },
  {
    field: "account_number",
  },
  {
    field: "account_type",
    format: "startCase",
  },
];

export const tableQuestions = (
  <>
    During the last ten (10) years, has the Applicant, its parent company or any
    subsidiary maintained any bank account, whether domestic or foreign, which
    is not reflected on the Company&apos;s books or records?
  </>
);

export const defaultInitialValues = {
  bd_business_entity_id: null,
  bank_bd_business_entity_id: null,
  balance_date: null,
  from_date: null,
  to_date: null,
  is_current: false,
  account_number: "",
  account_type: null,
  nature: "",
};

export const endpoint = "bdBankAccountOffBook";
export const nonRequired = ["balance_date"];

export const tabularDataParams = {
  defaultInitialValues,
  endpoint,
  nonRequired,
};
