import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import useFetch from "queryHooks/useFetch";
import { Box } from "@material-ui/core";
import DisplayTable from "components/Common/Tables/DisplayTable";
import { columns, dependsOnQueryCount } from "./config";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const NewMexicoPublicOfficial = ({ name }) => {
  const { data = [], status } = useFetch(
    `new_mexico_public_official_government_employment_one_year`,
    `new_mexico_public_official_government_employment_one_year`
  );
  return (
    <FormSectionWrapper title={name}>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={status}>
          <DisplayTable
            records={data}
            linkTo={["government_positions"]}
            columns={columns}
            dependsOnQueryCount={dependsOnQueryCount}
          />
        </LoadingComponent>
      </Box>
    </FormSectionWrapper>
  );
};

export default NewMexicoPublicOfficial;
