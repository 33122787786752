import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.businessAssociation,
    label: "Associated Business Entity",
    name: "pd_nova_scotia_ira.associated_business_entity_id",
  },
  {
    type: fieldTypes.subHeader,
    label: "Reason for Submitting Application",
  },
  {
    type: fieldTypes.label,
    label:
      "Please select below the reason your are submitting this application:",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "pd_nova_scotia_ira.reason_for_submission",
    dynamicMenuItems: ["pdNovaScotiaReasonForSubmissionCheckbox"],
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "pd_nova_scotia_ira_supplement_disclosure_questions_pdSupplement",
};
