import PropTypes from "prop-types";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Field, useField } from "formik";
import withFieldTools from "hocs/withFieldTools";
import { startCase } from "lodash";
import React from "react";
import styled from "styled-components";

const StyledSelect = styled(Select)`
  & .hide-when-selected {
    visibility: hidden;
    height: 0px;
  }
`;

const AddOptionMenuItemContainer = styled(MenuItem)`
  background-color: ${({ theme }) => theme.palette.grey[200]};
  color: ${({ theme }) => theme.palette.primary.main};
  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[300]};
  }
`;

const alphabeticSort = (a, b) => {
  if (!a || !b) {
    return 0;
  }
  const nameA = a.toLowerCase();
  const nameB = b.toLowerCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};

const AddOptionMenuItem = React.forwardRef(
  ({ handleAddOption, onClick: _onClick, ...props }, ref) => (
    <AddOptionMenuItemContainer
      value={null}
      {...props}
      onClick={handleAddOption}
      ref={ref}
    >
      <Box pr={1.5} display="flex">
        <AddCircleOutlineIcon color="primary" />
      </Box>
      Add New
    </AddOptionMenuItemContainer>
  )
);

AddOptionMenuItem.propTypes = {
  handleAddOption: PropTypes.func,
  onClick: PropTypes.func,
};

AddOptionMenuItem.displayName = "AddOptionMenuItem";

function fieldToSelect({
  disabled,
  field: { onChange: _onChange, onBlur: fieldOnBlur, value, ...field },
  onChange,
  form: { isSubmitting, setFieldValue },
  onBlur,
  ...props
}) {
  return {
    disabled: disabled ?? isSubmitting,
    onBlur:
      onBlur ??
      function (e) {
        fieldOnBlur(e ?? field.name);
      },
    onChange:
      onChange ??
      function (e) {
        const value = e.target.value;
        setFieldValue(field.name, value === "" ? null : value);
      },
    value: value ?? "",
    ...field,
    ...props,
  };
}

export const FormikSelectBase = ({
  options,
  fullWidth = true,
  margin = "dense",
  variant = "outlined",
  label,
  name,
  getOptionValue,
  getOptionLabel,
  loading,
  handleAddOption,
  helperText,
  menuItemComponent,
  sortAlphabetically,
  ...props
}) => {
  const [, meta] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : "";

  const sortOptions = React.useCallback(() => {
    if (sortAlphabetically)
      return options.sort((a, b) =>
        alphabeticSort(
          getOptionLabel ? getOptionLabel(a) : a.name,
          getOptionLabel ? getOptionLabel(b) : b.name
        )
      );
    return options;
  }, [getOptionLabel, options, sortAlphabetically]);

  return (
    <FormControl
      variant={variant}
      margin={margin}
      fullWidth={fullWidth}
      error={!!errorText}
      required={props.required}
    >
      <InputLabel>{label || startCase(name)}</InputLabel>
      <Field
        {...props}
        label={label || startCase(name)}
        component={(props) => (
          <StyledSelect {...fieldToSelect(props)} fullWidth={fullWidth} />
        )}
        name={name}
      >
        {handleAddOption && (
          <AddOptionMenuItem handleAddOption={handleAddOption} />
        )}
        {loading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress color="inherit" size={20} />
          </Box>
        ) : (
          sortOptions().map((option) => {
            const value = getOptionValue
              ? getOptionValue(option)
              : option.value;

            const labelName = getOptionLabel
              ? getOptionLabel(option)
              : option.name;

            return (
              <MenuItem key={value} value={value}>
                {menuItemComponent
                  ? menuItemComponent(labelName, option, value)
                  : labelName}
              </MenuItem>
            );
          })
        )}
      </Field>
      <FormHelperText>{errorText || helperText}</FormHelperText>
    </FormControl>
  );
};

FormikSelectBase.propTypes = {
  fullWidth: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  handleAddOption: PropTypes.func,
  helperText: PropTypes.node,
  label: PropTypes.string,
  loading: PropTypes.bool,
  required: PropTypes.bool,
  margin: PropTypes.oneOf(["dense", "none", "normal"]),
  menuItemComponent: PropTypes.func,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  sortAlphabetically: PropTypes.bool,
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
};

const EnhancedFormikSelectBase = withFieldTools(FormikSelectBase);

const FormikSelect = (props) => <EnhancedFormikSelectBase xs={4} {...props} />;

FormikSelect.propTypes = {
  tooltip: PropTypes.node,
};

export default FormikSelect;
