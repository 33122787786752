import React from "react";
import { Grid, Box } from "@material-ui/core";
import useFetch from "queryHooks/useFetch.js";
import DisplayTable from "components/Common/Tables/DisplayTable/index.js";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm.js";
import {
  defaultInitialValues,
  litigationColumns,
  litigationTableQuestion,
  litigationEndpoint,
  criminalEndpoint,
  criminalTableQuestion,
  criminalColumns,
  insuranceTableQuestion,
  insuranceColumns,
  insuranceEndpoint,
  testimonyEndpoint,
  testimonyTableQuestion,
  testimonyColumns,
  investigationEndpoint,
  investigationTableQuestion,
  investigationColumns,
} from "./config";
import YesNo from "components/Formik/formGroups/YesNo";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import CustomLink from "components/Common/Link";
import { SectionLabel } from "components/Common/LayoutComponents";
import useEndPoint from "hooks/useEndpoint";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <YesNo
        label={
          <>
            Does the business entity, officers, or directors anticipate being a
            party to a lawsuit? If yes, Attach supporting documentation to
            associate record in
            <CustomLink to="bd_litigations" />
          </>
        }
        name="iowa_class_d.is_anticipate_lawsuit_party"
      />
      <YesNo
        label="Has the business entity sustained a loss by fire where arson was suspected? If yes, explain in detail, listing circumstances surrounding the fire and the name and address of the investigating agency. Attach supporting documentation below."
        name="iowa_class_d.is_arson_suspected"
      />
    </Grid>
  );
};

const IowaLegalProceedings = ({ title }) => {
  const { bdBusinessEntityEndpoint } = useEndPoint("");
  const arsonFormData = useForm({
    defaultInitialValues: {
      arson_suspected_attachment_group_id: null,
    },
    noValidate: true,
    endpoint: bdBusinessEntityEndpoint,
  });

  const formData = useForm({
    defaultInitialValues,
    noValidate: true,
    endpoint: "BDSupplement",
  });

  const { data: litigationData, status: litigationStatus } =
    useFetch(litigationEndpoint);
  const { data: criminalData, status: criminalStatus } =
    useFetch(criminalEndpoint);
  const { data: insuranceData, status: insuranceStatus } =
    useFetch(insuranceEndpoint);
  const { data: investigationData, status: investigationStatus } = useFetch(
    investigationEndpoint
  );
  const { data: testimonyData, status: testimonyStatus } =
    useFetch(testimonyEndpoint);

  return (
    <FormSectionWrapper title={"Iowa " + title}>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={litigationStatus}>
          <DisplayTable
            records={litigationData}
            linkTo={["bd_litigations"]}
            columns={litigationColumns}
            dependsOnQueryCount={{
              question: litigationTableQuestion,
            }}
          />
        </LoadingComponent>
      </Box>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={criminalStatus}>
          <DisplayTable
            records={criminalData}
            linkTo={["bd_criminal_proceedings"]}
            columns={criminalColumns}
            dependsOnQueryCount={{
              question: criminalTableQuestion,
            }}
          />
        </LoadingComponent>
      </Box>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={insuranceStatus}>
          <DisplayTable
            records={insuranceData}
            linkTo={["bd_insurance_claims"]}
            columns={insuranceColumns}
            dependsOnQueryCount={{
              question: insuranceTableQuestion,
            }}
          />
        </LoadingComponent>
      </Box>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={investigationStatus}>
          <DisplayTable
            records={investigationData}
            linkTo={["bd_testimonies"]}
            columns={investigationColumns}
            dependsOnQueryCount={{
              question: investigationTableQuestion,
            }}
          />
        </LoadingComponent>
      </Box>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={testimonyStatus}>
          <DisplayTable
            records={testimonyData}
            linkTo={["bd_testimonies"]}
            columns={testimonyColumns}
            dependsOnQueryCount={{
              question: testimonyTableQuestion,
            }}
          />
        </LoadingComponent>
      </Box>
      <FormContextProvider formData={formData}>
        <EnhancedFormikPage title={"Legal Information"}>
          <Form />
        </EnhancedFormikPage>
      </FormContextProvider>
      <FormContextProvider formData={arsonFormData}>
        <EnhancedFormikPage>
          <Grid>
            <SectionLabel label="Attach supporting documentation related to the question above, has the business entity sustained a loss by fire where arson was suspected" />
            <FormikFileDropzone
              description="Iowa Arson Suspected"
              name="arson_suspected_attachment_group_id"
              xs={12}
            />
          </Grid>
        </EnhancedFormikPage>
      </FormContextProvider>
    </FormSectionWrapper>
  );
};

export default IowaLegalProceedings;
