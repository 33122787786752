import React, { Suspense } from "react";
import _ from "lodash";
const InviteUserSection = ({ currentRow }) => {
  if (!currentRow || _.get(currentRow, "invitation_status") === "accepted")
    return null;
  else {
    const Section = React.lazy(() => import("./InviteUserSection"));
    return (
      <Suspense fallback={null}>
        <Section name="invitation_status" currentRow={currentRow} />
      </Suspense>
    );
  }
};

export default InviteUserSection;
