import produce from "immer";

export const insert = (schema, index, newFieldSchema) => [
  // part of the array before the specified index
  ...schema.slice(0, index),
  // inserted item
  ...(Array.isArray(newFieldSchema) ? newFieldSchema : [newFieldSchema]),
  // part of the array after the specified index
  ...schema.slice(index),
];

export const update = (schema, name, modifierCallback) => {
  return produce(schema, (draft) => {
    const index = draft.findIndex((item) => item.name === name);
    if (index !== -1) modifierCallback(draft[index]);
  });
};

export default { insert, update };
