import { DialogContent, Divider, Tab } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import useTabs from "hooks/useTabs";
import { InfoTab } from "components/Pages/LicensingControl/Businesses/components/BusinessInfoTab";
import { Header } from "components/Pages/LicensingControl/components/DetailsDialog/DialogComponents.jsx";
import { Dialog } from "components/Pages/LicensingControl/components/DetailsDialog/DialogComponents.jsx";
import { StyledTabs } from "components/Pages/LicensingControl/components/DetailsDialog/DialogComponents.jsx";
import { TabPanel } from "components/Pages/LicensingControl/components/DetailsDialog/DialogComponents.jsx";
import { RecordsTab } from "components/Pages/LicensingControl/Businesses/components/RecordsTab";

export const BusinessDetailsModal = ({
  open,
  onClose,
  currentRecord,
  formikProps,
  licensesFormInitiallyOpen,
}) => {
  const [tab, handleTabChange] = useTabs();

  const { business_name, id, lc_attachment_group_id } = currentRecord;

  const attachmentGroupUseState = React.useState(
    lc_attachment_group_id || null
  );

  const initialValues = formikProps.initialValues;

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        title={<Header label={business_name} />}
        fullWidth
        maxWidth="md"
      >
        <StyledTabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
        >
          <Tab label="Info" />
          <Tab label="Attachments" />
        </StyledTabs>
        <Divider />
        <DialogContent style={{ height: "80vh" }}>
          <TabPanel tab={tab} index={0}>
            <InfoTab
              data={currentRecord}
              formikProps={formikProps}
              licensesFormInitiallyOpen={licensesFormInitiallyOpen}
              id={id}
            />
          </TabPanel>
          <TabPanel tab={tab} index={1}>
            <RecordsTab
              entityId={id}
              attachmentGroupUseState={attachmentGroupUseState}
              initialValues={initialValues}
            />
          </TabPanel>
        </DialogContent>
      </Dialog>
    </>
  );
};

BusinessDetailsModal.propTypes = {
  currentRecord: PropTypes.any,
  formikProps: PropTypes.object,
  licensesFormInitiallyOpen: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
