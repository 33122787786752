import { createModel, deleteModel, updateModel } from "helpers/apiHelpers";
import { useSnackbar } from "notistack";
import useFormPath from "hooks/useFormPath";
import useEndPoint from "hooks/useEndpoint.js";

const useFetchAnnualIncome = ({ year }) => {
  const slug = useFormPath();
  const { fullEndpoint } = useEndPoint(`${slug}_incomeStatement`);

  const { enqueueSnackbar } = useSnackbar();
  const showErrorSnackbar = () => {
    enqueueSnackbar("Error saving data", {
      variant: "error",
    });
  };

  const handleUpdate = ({ source, amount, category, id }, successCallback) => {
    return updateModel(fullEndpoint, id, {
      source,
      amount,
      category,
      year,
    })
      .then((response) => {
        if (!response) throw new Error();
        else {
          successCallback();
          return;
        }
      })
      .catch(() => {
        showErrorSnackbar();
      });
  };

  const handleDelete = ({ id }, successCallback) => {
    return deleteModel(fullEndpoint, id)
      .then((response) => {
        if (!response) throw new Error();
        else return response;
      })
      .then((response) => {
        if (!response) throw new Error();
        else {
          successCallback();
          return;
        }
      })
      .catch(() => {
        showErrorSnackbar();
      });
  };

  const handleCreate = ({ source, amount, category }, successCallback) => {
    return createModel(fullEndpoint, {
      source,
      amount,
      category,
      year,
    })
      .then((response) => {
        if (!response) throw new Error();
        else return response;
      })
      .then(({ data: { id } }) => {
        successCallback(id);
        return;
      })
      .catch(() => {
        showErrorSnackbar();
      });
  };

  const handleSubmit = ({ row, type }, successCallback) => {
    if (type === "update") handleUpdate(row, successCallback);
    if (type === "delete") handleDelete(row, successCallback);
    if (type === "create") handleCreate(row, successCallback);
  };

  return handleSubmit;
};

export default useFetchAnnualIncome;
