import React from "react";

import { SectionLabel } from "components/Common/LayoutComponents";

import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { Grid, Box } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm.js";
import useFetch from "queryHooks/useFetch";
import DisplayTable from "components/Common/Tables/DisplayTable";
import { defaultInitialValues, endpoint, columns } from "./config";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const Form = () => {
  const formData = useForm({
    defaultInitialValues: defaultInitialValues,
    noValidate: true,
    endpoint: endpoint,
  });

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage title={"Personal Information"}>
        <Grid container spacing={2}>
          <SectionLabel label="If any of the above marital changes impacted your relationship with your children/ dependents, please provide their information (dates, full names/aliases, birth dates, and relationship):" />
          <FormikTextField
            label="Details"
            name="elk_valley.marital_changes_last_2y_children"
          />
        </Grid>
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

const ElkValleyMaritalStatus = ({ name }) => {
  const { data = [], status } = useFetch(
    "elk_valley_marital_status_marital_status_last2yrs"
  );

  return (
    <FormSectionWrapper title={name}>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={status}>
          <DisplayTable
            records={data}
            dependsOnQueryCount="Has your marital status changed within the last two years?"
            linkTo={["marriages"]}
            columns={columns}
          />
        </LoadingComponent>
      </Box>
      <Form />
    </FormSectionWrapper>
  );
};

export default ElkValleyMaritalStatus;
