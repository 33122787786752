import { formats } from "components/Common/Tables/Common/helpers";
import positionList from "./positionList";
import * as yup from "yup";

export const validationSchema = yup.object().shape({
  name: yup.string().required("Required Field"),
});

export const positionsColumns = [
  {
    title: "Position Name",
    field: "name",
  },
];

export const positionsDefaultInitialValues = { name: "" };

export const columns = [
  {
    field: "name",
    title: "Department",
  },
  {
    field: "lc_position_type.lc_position_type",
    title: "Positions",
    render: positionList,
  },

  {
    field: "notification_emails",
    format: formats.chip,
  },
];

export const defaultInitialValues = {
  name: "",
  lc_position_type: [],
  notification_emails: [],
};

export const sortingOptions = {
  defaultSortBy: "name",
  defaultSortOrder: "asc",
};

export const endpoint = "licensing/lcDepartment";
