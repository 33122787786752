export const defaultInitialValues = {
  iowa_class_d: {
    ia_class_d_contact_bd_person_id: null,
    ia_registered_agent_bd_person_id: null,
    application_prepare_bd_person_id: null,
    ia_form_of_business: null,
    ia_form_of_business_corporation_type: "",
    ia_form_of_business_other_description: "",
    is_ucc_problem_areas: null,
    ia_ucc_problem_explain: "",
    business_records_maintenance_agent: "",
    business_records_maintenance_agent_phone: "",
  },
};

export const endpoint = "bdSupplement";
