import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import useFetch from "queryHooks/useFetch";
import { Box } from "@material-ui/core";
import DisplayTable from "components/Common/Tables/DisplayTable";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const NevadaArbitrationAssociation = ({ name }) => {
  const { data = [], status } = useFetch(
    "nevada_arbitration_association_criminal_business_arbitration_resolved_a"
  );
  return (
    <FormSectionWrapper title={name}>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={status}>
          <DisplayTable
            records={data}
            linkTo={["lawsuits_and_arbitrations"]}
            columns={[
              { field: "filing_date", format: "date" },
              { field: "nature" },
              { field: "case_number" },
              { field: "agency_name" },
              { field: "disposition", format: "startCase" },
              { title: "Business", field: "business_name" },
            ]}
            dependsOnQueryCount="Have you ever reached a settlement or had a settlement reached by another person or entity, on behalf of a company with which you were/are affiliated, prior to litigation or criminal charges having been filed?"
          />
        </LoadingComponent>
      </Box>
    </FormSectionWrapper>
  );
};

export default NevadaArbitrationAssociation;
