import { Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import BDLawEnforcementSelect from "components/Formik/formGroups/CrudSelect/selects/BDLawEnforcementSelect.jsx";
import FormikRadioGroup from "components/Formik/formGroups/FormikRadioGroup.jsx";

import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import { useMenuOptions } from "hooks/useMenuOptions";
import React from "react";
import BDBusinessFamilySelect from "components/Formik/formGroups/CrudSelect/selects/BDBusinessFamilySelect";
import {
  columns,
  defaultInitialValues,
  endpoint,
  tableQuestions,
} from "./config.js";

const AntitrustAndTradeJudgementsForm = () => {
  const { criminalDispositionType } = useMenuOptions();
  return (
    <Grid container spacing={2}>
      <BDBusinessFamilySelect />

      <BDLawEnforcementSelect />

      <SectionSubHeader label="Type" divider />
      <FormikRadioGroup
        options={[
          { name: "Antitrust", value: "antitrust" },
          { name: "Trade Judgement", value: "trade_judgement" },
        ]}
        name="type"
        xs={12}
      />
      <SectionSubHeader label="Nature & Details" divider />
      <FormikTextField label="Case Name" name="case_name" xs={4} />
      <FormikTextField label="Docket Number" name="docket_number" xs={4} />
      <FormikDatePicker
        label="Date of Judgement"
        name="disposition_date"
        xs={4}
      />
      <FormikTextField
        label="Nature of Charge or Complaint"
        name="nature_of_charge"
        xs={12}
      />

      <SectionSubHeader label="Outcome" divider />
      <FormikSelect
        label="Disposition"
        name="criminal_disposition"
        options={criminalDispositionType}
        xs={6}
      />
      <FormikNumberField
        label="Fine Amount"
        name="fine_amount"
        xs={6}
        helperText="If applicable"
      />
      <FormikTextField
        label="Nature of Judgement"
        name="nature_of_judgement"
        xs={12}
      />
    </Grid>
  );
};

const AntitrustAndTradeJudgements = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint,
    nonRequired: ["fine_amount"],
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <AntitrustAndTradeJudgementsForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
    </FormSectionProvider>
  );
};

export default AntitrustAndTradeJudgements;
