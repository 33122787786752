export const columns = [
  {
    title: "From",
    field: "from_date",
    format: "date",
  },
  {
    title: "To",
    field: "to_date",
    format: "date",
  },
  { title: "Business Name", field: "business_name" },
  { title: "Association Description", field: "association_description_pretty" },
];

export const dependsOnQueryCount =
  "Did you have any changes to your Business Interests in the last two years?";
