import { formats } from "components/Common/Tables/Common/helpers";
import { multiEmail, requiredString } from "helpers/validationSchemaGenerator";
import * as yup from "yup";

export const columns = [
  {
    field: "business_name",
  },
  {
    field: "external_id",
  },
  {
    field: "notification_emails",
    format: formats.chip,
  },
];

export const defaultInitialValues = {
  business_name: "",
  external_id: null,
  notification_emails: [],
};

export const validationSchema = yup.object().shape({
  external_id: requiredString,
  notification_emails: multiEmail,
});
export const endpoint = "licensing/lcBusinessEntity";
export const queryKey = "lcBusinessEntity";
