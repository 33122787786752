import React from "react";
import { address } from "helpers/initialValues";

export const columns = [];

export const tableQuestions = <></>;

export const nonRequired = [];

export const endpoint = "net_worth_statements_netWorth";

export const defaultInitialValues = {
  networth_statement_date: null,
  networth_author_name: "",
  networth_author_phone: "",
  networth_author_address: address,
};

export const modalTitle = "Statement Information";

export const dataHookProps = {
  defaultInitialValues,
  nonRequired,
  endpoint,
};
