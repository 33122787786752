import { useQuery } from "react-query";
import axios from "axios";
import useEndPoint from "hooks/useEndpoint";
import useErrorSnackBar from "hooks/useErrorSnackbar";
import { logout401 } from "helpers/apiHelpers";

const genericErrorMessage = "Error Retrieving Data";

const getData = async (endpoint) => {
  const { data } = await axios.get(endpoint);
  return Array.isArray(data) ? data : [data];
};

const useFetch = (
  queryKey,
  path = null,
  {
    showSnackbar = true,
    errorMessage = null,
    useFullEndpoint = true,
    enabled = true,
    ...rest
  } = {}
) => {
  if (path === null) {
    path = queryKey;
  }
  const { fullEndpoint } = useEndPoint(path);
  const { handleErrorMessage } = useErrorSnackBar();

  const onError = (error) => {
    logout401(error);

    if (showSnackbar)
      handleErrorMessage(error, errorMessage || genericErrorMessage);
  };

  return useQuery(
    queryKey,
    () => getData(useFullEndpoint ? fullEndpoint : path),
    {
      retry: false,
      onError,
      enabled,
      ...rest,
    }
  );
};

export default useFetch;
