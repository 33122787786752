import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

const useScreenWidth = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isXl = useMediaQuery(theme.breakpoints.down("xl"));

  const screenWidth =
    (isXs && "xs") ||
    (isSm && "sm") ||
    (isMd && "md") ||
    (isLg && "lg") ||
    (isXl && "xl");

  // ! If  one of these values return true, it means the the screen width is that breakpoint OR smaller
  // ! e.g If the screen width is md, then isMd, isLg and isXl will return true
  return { screenWidth, isXs, isSm, isMd, isLg, isXl };
};

export default useScreenWidth;
