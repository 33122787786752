import { fieldTypes } from "componentGenerators/formGenerator/config";
import React from "react";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Identification",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of photo identification in the{" "}
        <strong>
          <CustomLink to="passport" />
        </strong>
        section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Ensure you have completed the proper fingerprinting documentation and
        attach them to the back of the application.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Tax information",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of each IRS Form 1040 and 1040X to the back of the
        application, as well as all appropriate schedules filed by you in the
        last (3) three years in the
        <strong>
          <CustomLink to="us_taxes" />
        </strong>
        section. If you and your spouse filed separate tax returns for any year
        in the last three years, also attach a copy of your spouse’s tax
        returns.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of your non-us tax returns and any applicable attachments
        in the{" "}
        <strong>
          <CustomLink to="non_us_taxes" />
        </strong>
        section.
      </>
    ),
  },
];

export default {
  type: "readOnlyPage",
  formSchema,
  endpoint: "pdSupplement",
};
