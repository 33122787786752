import { has, get, startCase } from "lodash";

export const permissionTypes = {
  read: "read",
  write: "write",
  noAccess: "noAccess", //not currently used
};

export function renameTopLevel(topLevel) {
  if (topLevel === "pd_supplement") {
    return "Supplemental";
  }
  return startCase(topLevel);
}

export function getCurrentPermissions(flattened_permissions, formPath) {
  if (!flattened_permissions || !formPath) {
    return permissionTypes.write;
  }

  const item = get(flattened_permissions, formPath);

  if (!has(item, "read") || !has(item, "write")) {
    return permissionTypes.write;
  }

  const { read, write } = item;

  if (write) {
    return permissionTypes.write;
  } else if (read) {
    return permissionTypes.read;
  }
  return permissionTypes.noAccess;
}
