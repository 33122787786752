import React from "react";

import { SectionLabel } from "components/Common/LayoutComponents/";
import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import useForm from "hooks/useForm.js";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import { Grid } from "@material-ui/core";
import AlertEdit from "components/Common/Alerts/AlertEdit";
import { defaultInitialValues, endpoint } from "./config";

const NewMexicoTaxReviewForm = ({ innerRef }) => {
  const formData = useForm({
    defaultInitialValues: defaultInitialValues,
    endpoint: endpoint,
  });

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage innerRef={innerRef} showSaveButton={false}>
        <Grid container spacing={2}>
          <AlertEdit
            xs={12}
            linkTo="tax_audit"
            title="Review your information"
            endpoint="new_mexico_tax_information_taxReturnActivityFederalAuditedAdjusted"
            dependsOnQueryCount="Has your Federal Income Tax Return ever been audited or adjusted?"
            displayValue={"id"}
          />

          <SectionLabel label="If yes, describe the nature and resolution of the audit and the tax year(s)." />

          <FormikTextField
            multiline
            rows={4}
            name="years_nature_and_resolution_tax_audit"
            xs={12}
          />

          <AlertEdit
            xs={12}
            linkTo="us_taxes"
            title="Review your information"
            endpoint="new_mexico_tax_information_taxReturnActivityStateFederalNotFiled"
            dependsOnQueryCount="Have you ever failed to file Federal or State Income Tax Returns?"
            displayValue={"id"}
          />

          <SectionLabel label="If yes, describe the reason for failure to file and the tax year(s)." />

          <FormikTextField
            multiline
            rows={4}
            name="reason_and_years_of_tax_failure"
            xs={12}
          />
        </Grid>
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

export default NewMexicoTaxReviewForm;
