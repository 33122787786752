import { useMutation, useQueryClient } from "react-query";
import { deleteModel } from "helpers/apiHelpers";

const filterValues = (previousValue, editedValue) =>
  previousValue.filter((eachValue) => eachValue.id !== editedValue.id);

const useDelete = (
  queryKey,
  path,
  { onSuccess, onMutate: handleMutate, onError, queryFn } = {}
) => {
  const queryCache = useQueryClient();
  const handleDelete = queryFn
    ? queryFn
    : (user_id) => {
        const id = user_id?.id ?? user_id;
        return deleteModel(path, id);
      };

  const onMutate = (editedValue) => {
    handleMutate && handleMutate();
    const previousValue = queryCache.getQueryData(queryKey);
    queryCache.setQueryData(queryKey, filterValues(previousValue, editedValue));
    return () => queryCache.setQueryData(queryKey, previousValue);
  };

  const onSettled = (data, error, editedValue) => {
    queryCache.removeQueries([queryKey, editedValue.id]);
    queryCache.refetchQueries(queryKey);
  };

  return useMutation(handleDelete, {
    onMutate,
    onError: (error, editedValue, rollback) => {
      onError && onError(error);
      rollback && rollback();
    },
    onSettled,
    onSuccess,
  });
};

export default useDelete;
