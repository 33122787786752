import React from "react";
import { Box } from "@material-ui/core";
import TimeRange from "../Filters/TimeRange";
import FilterByAmount from "../Filters/FilterByAmount";

export default function Filters() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginBottom: "20px",
      }}
    >
      <Box
        component="div"
        style={{
          padding: "10px 0px 20px 25px",
          fontWeight: "bold",
          marginRight: "20.6px",
        }}
        fontSize={16}
      >
        Filters:
      </Box>
      <TimeRange />
      <FilterByAmount />
    </div>
  );
}
