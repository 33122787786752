import React from "react";

export const columns = [
  { title: "", field: "transaction_date", format: "date" },
  { title: "", field: "amount", format: "currency" },
  { title: "", field: "other_parties" },
  { title: "", field: "purpose" },
];

export const tableQuestions = (
  <>
    If in the past ten (10) years, you have ever made a cash transaction of
    $10,000 or more, or multiple transactions totalling $10,000 or more within a
    thirty (30) day period, provide the following details for each transaction
    or set of multiple transactions.
  </>
);

export const endpoint = "indiana_cash_transaction_cashTransaction";

export const defaultInitialValues = {
  amount: null,
  transaction_date: null,
  purpose: "",
  other_parties: "",
};
