import React from "react";
import GridItem from "components/Common/Containers/Grid/GridItem";

import TextField from "@material-ui/core/TextField";
import GenericError from "components/Common/GenericError";

const DefaultTextField = ({
  params,
  children,
  xs,
  helperText,
  placeholder,
  onChange,
  type,
  disabled,
  name,
  value,
  errors,
  touched,
  label,
  onBlur,
  variant,
  InputProps,
  id,
}) => {
  return (
    <GridItem style={{ display: "flex", flexDirection: "column" }} xs={xs}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <TextField
          fullWidth={true}
          size="small"
          margin="dense"
          variant={variant || "outlined"}
          helperText={helperText}
          disabled={disabled}
          placeholder={placeholder}
          id={id}
          name={name}
          value={value || ""}
          onChange={onChange}
          onBlur={onBlur}
          label={label}
          type={type}
          InputProps={InputProps}
          autoComplete="off"
          {...params}
        />
        {children}
      </div>
      <GenericError name={name} errors={errors} touched={touched} />
    </GridItem>
  );
};
DefaultTextField.defaultProps = {
  default: "",
};

export default DefaultTextField;
