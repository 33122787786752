export const defaultInitialValues = {

  illinois: {
    "pd_q10_b_boolean": null,
    "pd_q10_b_details": "",
    "pd_q10_c_boolean": null,
    "pd_q10_c_details": "",
    "pd_q10_d_boolean": null,
    "pd_q10_d_details": "",
    "pd_q10_e_boolean": null,
    "pd_q10_e_details": "",
    "pd_q10_f_boolean": null,
    "pd_q10_f_details": "",
    "pd_q10_h_boolean": null,
    "pd_q10_h_details": "",
    "pd_q36_boolean": null,
  }
};
