import React from "react";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Internal Revenue Service Returns",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Copies of personal Internal Revenue Service returns for the past three
        years with each personal history disclosure form for the business
        application.
      </>
    ),
  },
  {
    type: fieldTypes.dropZone,
    name: "south_dakota_bhd.personal_revenue_service_attachment_id",
    description: "Internal Revenue Service Returns",
    label: "Upload Attachment",
    xs: 12,
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Financial Statements",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Copy of bank and brokerage statements for the past 12 months for all
        checking, savings and brokerage accounts held. Upload documents in the
        <CustomLink to="bd_bank_accounts" /> section.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "IRS tax returns",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        If you are licensing an ongoing concern, submit the IRS tax returns for
        that business of the prior years. Upload documents in the
        <CustomLink to="bd_bank_accounts" /> section.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Articles of incorporation or partnership agreements",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Copy of all articles of incorporation or partnership agreements for all
        business investments held. Upload documents in the
        <CustomLink to="bd_corporate_documents" />
        section.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Stock Certificates",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Submit copies of all stock certificates that you own. Upload documents
        in the <CustomLink to="bd_assets" /> section.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Articles of incorporation or partnership agreements",
  },
  {
    type: fieldTypes.yesNo,
    label: `
    Can you provide a copy of all articles of incorporation or partnership agreements for all
    business investments held and attach it to your application?`,
    name: "south_dakota_bhd.is_incorporation_articles_attached",
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label: "If no, please state reason below:",
  },
  {
    type: fieldTypes.text,
    label: "Reason",
    name: "south_dakota_bhd.incorporation_articles_not_attached_reason",
    xs: 12,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "bdSupplement",
};
