import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import useFetch from "queryHooks/useFetch";
import { Box } from "@material-ui/core";
import DisplayTable from "components/Common/Tables/DisplayTable";
import { config } from "./config";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const PennsylvaniaFinancialInterest = () => {
  const { data = [], status } = useFetch(
    "pennsylvania_gaming_financial_interest_pe_business_gaming_license_a"
  );
  return (
    <FormSectionWrapper title="Pennsylvania Financial Interest Questions">
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={status}>
          <DisplayTable
            records={data}
            linkTo="business_licensing"
            columns={config}
            dependsOnQueryCount="Do you have any ownership interest, financial interest or financial investment in any business entity applying to, or presently licensed by, the Pennsylvania Gaming Control Board?"
          />
        </LoadingComponent>
      </Box>
    </FormSectionWrapper>
  );
};

export default PennsylvaniaFinancialInterest;
