/* eslint-disable react/display-name */

import React from "react";
import FormGenerator from "componentGenerators/FormGenerator";

export const getFormComponents = (pageSchemasParam) => {
  return Object.keys(pageSchemasParam).reduce((acc, curr) => {
    //allows getFormComponents to receive an array of schemas or a single schema object
    const schemas = Array.isArray(pageSchemasParam[curr])
      ? pageSchemasParam[curr]
      : [pageSchemasParam[curr]];

    return {
      ...acc,
      [curr]: (props) => <FormGenerator schemas={schemas} {...props} />,
    };
  }, {});
};
