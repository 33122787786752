import { Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents/index";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm";
import React from "react";
import PDBusinessSelect from "components/Formik/formGroups/CrudSelect/selects/PDBusinessSelect";

const MichiganDisclosureQuestionsForm = () => {
  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Associated Gaming Entity" />

      <PDBusinessSelect
        label="Gaming Entity ( Your Association )"
        name="michigan.associated_business_entity_id"
        endpoint="associated_business_entities_a_grouped"
        getOptionLabel={(option) =>
          `${option.business_name} (${option.association})`
        }
        getOptionValue={({ pd_business_entity_id, id }) =>
          id || pd_business_entity_id
        }
      />
    </Grid>
  );
};

const MichiganDisclosureQuestions = ({ name }) => {
  const formData = useForm({
    defaultInitialValues: {
      michigan: {
        associated_business_entity_id: null,
      },
    },
    endpoint: "michigan_disclosure_questions_pdSupplement",
  });

  return (
    <FormSectionProvider formData={formData} title={`Michigan ${name}`}>
      <EnhancedFormikPage>
        <MichiganDisclosureQuestionsForm />
      </EnhancedFormikPage>
    </FormSectionProvider>
  );
};

export default MichiganDisclosureQuestions;
