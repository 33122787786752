import React, { useState, useEffect } from "react";
import { Select, InputLabel, FormControl, MenuItem } from "@material-ui/core";
import {
  usePdfState,
  usePdfDispatch,
  ACTIONS,
} from "components/DevTools/PdfTools/Context/PdfToolsProvider.jsx";

export default function OrderBy() {
  const dispatch = usePdfDispatch();
  const { column, combo } = usePdfState();
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("desc");

  const handleDispatch = (value) => {
    dispatch({
      type: ACTIONS.SET_STATE,
      value,
      name: "token",
    });
  };

  const handleChange = (e) => {
    let value;
    if (e.target.name === "orderBy") {
      value = "?order_by=" + e.target.value + "|" + order;
      setOrderBy(e.target.value);
    } else {
      value = "?order_by=" + orderBy + "|" + e.target.value;
      setOrder(e.target.value);
    }
    handleDispatch(value);
  };

  useEffect(() => {
    setOrderBy("");
  }, [column, combo]);

  if (column) {
    return (
      <>
        <FormControl
          variant="filled"
          style={{ width: "150px", marginRight: "10px" }}
        >
          <InputLabel>Order By</InputLabel>
          <Select value={orderBy} onChange={handleChange} name="orderBy">
            {combo.map((val) => (
              <MenuItem key={val} value={val}>
                {val}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {!!orderBy && (
          <FormControl
            variant="filled"
            style={{ width: "150px", marginRight: "10px" }}
          >
            <InputLabel>Order</InputLabel>
            <Select value={order} onChange={handleChange} name="order">
              {["desc", "asc"].map((val) => (
                <MenuItem key={val} value={val} name="order">
                  {val}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </>
    );
  } else {
    return <></>;
  }
}
