import { fieldTypes } from "componentGenerators/formGenerator/config";
import React from "react";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Identification",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of your Birth Certificate in the
        <CustomLink to="citizenship" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of your Social Security Card to the back of the
        application.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        If applicable, attach a copy of your Naturalization Certificate in the
        <CustomLink to="citizenship" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        If applicable, attach a copy of the front AND back of your Permanent
        Resident Card, Work Visa (H1B, H2B, TN1 etc.) to the back of the
        application.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of your passport in the
        <CustomLink to="citizenship" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Vehicle Licenses and Records",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of the front AND back of your Drivers License in the
        <CustomLink to="vehicle_licenses" /> section. OR Attach a copy of the
        front AND back of your State ID card and attach to the back of your
        application.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of registration for any vehicles, aircraft, or boats in
        the <CustomLink to="vehicle_licenses" />
        section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach an official copy of your Driving Record(s) from any State in
        which you were licensed and attach to the back of your application.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Education",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of your High School Diploma, an official High School
        transcript, or copy of your G.E.D. certificate in the
        <CustomLink to="education" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Request a certified copy of college transcripts from all colleges and
        universities where you have attended. Transcripts must be received in
        the Institution(s) original envelope, unopened. It is preferred that you
        request the Institution(s) mail the transcript directly to the MLGCA
        investigator.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Military Service",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        If applicable, attach a copy of your military DD214 or National Guard
        NGB 22 in the <CustomLink to="military" label="Military History" />
        section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        If applicable, complete and sign Form 180 to request Military Records.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a completed and signed copy of the F.B.I. Privacy Waiver and
        Certification of Identity to the back of the application.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Licenses",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of any professional license(s) held and documents relative
        to any sanctions to the back of the application.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of any gaming licenses you hold currently or have held in
        the past and documents relative to any sanctions, fines or suspension to
        the back of the document.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Taxes",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of each tax return (Federal, State & Local), each Internal
        Revenue Service form filed with or concerning that tax return and all
        Internal Revenue Service schedules filed by you in the last five (5)
        years. If you and your spouse did not file joint returns at any time in
        the last five (5) years, please provide and attach your spouses’ tax
        returns. These documents can be uploaded in the
        <CustomLink to="us_taxes" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>Complete Form 4506-T to request a Transcript of your Tax Return.</>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Assets",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Request letters from each bank on their stationary relative to attesting
        to all accounts you have signatory authority.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of the last bank statement on all bank accounts for which
        you have signatory authority listed on the net worth statement, and the
        last brokerage statement for all securities, in the
        <CustomLink to="bank_accounts" /> and the
        <CustomLink to="margin_accounts" />
        section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of any Notes Receivable (including receivables in the name
        of a corporation in which you have over a 5% interest) in the
        <CustomLink to="loans_and_receivables" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach copies of all mortgage statements for the last three (3) months,
        documents and settlement sheets for all real estate properties in which
        you have an interest, and documentation supporting the fair market value
        of all real estate and outstanding amounts owed on mortgages and lien
        amounts on real estate in the
        <CustomLink to="real_estate_interests" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of any Life Insurance Policies that have a cash value and
        the name(s) of all beneficiaries in the
        <CustomLink to="life_insurance" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of the last statement relative to all
        retirement/investment/pension funds including, but not limited to, 401K
        retirement programs, in the
        <CustomLink to="pension_funds" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of any documents indicating ownership of any assets not
        listed above, such as ownership documents for aircraft, vehicles or
        boats to the back of the application.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Liabilities",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of any Notes, Loans, or Taxes Payable (including payables
        in the name of a corporation in which you have over a 5% interest) in
        the <CustomLink to="loans_and_other_payables" />
        section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of any documents relative to any Contingent Liabilities to
        the back of the application.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of last three (3) months Credit Card Statement(s) to the
        back of the application.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of documentation (i.e. partnership papers, stock
        registry-stock certificates) of any company you currently hold 5% in the
        <CustomLink to="business_investments" />
        section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of any documents indicating any other indebtedness to the
        back of the application.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of any liens, judgments or taxes payable under your name
        to the back of the application.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a one or two paragraph summary of all litigation during the past
        fifteen years, which should include, docket number, court information
        regarding the cause of action, named litigants, copy of complaint,
        disposition or current status if pending to the back of the application.
      </>
    ),
  },
];

export default {
  type: "readOnlyPage",
  formSchema,
  endpoint: "pdSupplement",
};
