import React, { useReducer, useEffect, createContext, useContext } from "react";
import axios from "axios";
import authReducer, { convertToNumber, convertToBoolean } from "reducers/auth";
import { get, forEach } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import useEventListener from "hooks/useEventListener";

const AuthUserContext = createContext();
const AuthLoginContext = createContext();
const AuthLogoutContext = createContext();
const AuthCollaboratorContext = createContext();

function getName(data) {
  const firstName = get(data, "account_info.first_name") || "";
  const lastName = get(data, "account_info.last_name") || "";
  return `${firstName} ${lastName}`;
}

function flattenPermission(data) {
  const result = {};
  forEach(data, (parent) => {
    forEach(parent, (middle) => {
      forEach(middle, (value, key) => {
        result[key] = value;
      });
    });
  });
  return result;
}

async function returnPermission({ id = null, is_account_owner = null }) {
  try {
    const employee_id = convertToNumber(localStorage.getItem("employee_id"));
    const local_is_account_owner = convertToBoolean(
      localStorage.getItem("is_account_owner")
    );
    const updated_id = id || employee_id;
    const updated_is_account_owner = is_account_owner || local_is_account_owner;

    const { data } = await axios.get(
      `employee/${updated_id}/collaborator_permissions/`
    );
    return {
      flattened_permissions: updated_is_account_owner
        ? null
        : flattenPermission(data),
      collab_permissions: data,
    };
  } catch (e) {
    return {
      flattened_permissions: null,
      collab_permissions: null,
    };
  }
}

export default function AuthProvider({ children }) {
  const auth0 = useAuth0();
  const [state, dispatch] = useReducer(authReducer, {
    employee_id: null,
    account_id: null,
    bd_business_entity_id: null,
    bd_organization_id: null,
    loading: true,
    authenticated: false,
    token: null,
    collab_permissions: null,
    flattened_permissions: null,
    name: "",
    account_type: null,
    email: null,
    is_account_owner: null,
  });
  // If user logouts from other tab, should logout from all OC-4958
  useEventListener("storage", (e) => {
    if (e.key === "accessToken" && e.newValue === null) {
      auth0.logout();
      dispatch({ type: "LOGOUT" });
    }
  });
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const employee_id = convertToNumber(localStorage.getItem("employee_id"));
    const is_account_owner = convertToBoolean(
      localStorage.getItem("is_account_owner")
    );
    const bd_business_entity_id = convertToNumber(
      localStorage.getItem("bd_business_entity_id")
    );
    const name = localStorage.getItem("name");
    const email = localStorage.getItem("email");
    const account_id = convertToNumber(localStorage.getItem("account_id"));
    const account_type = localStorage.getItem("account_type");
    const bd_organization_id = localStorage.getItem("bd_organization_id");

    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      (async () => {
        const { collab_permissions, flattened_permissions } =
          account_type === "employee"
            ? await returnPermission({ employee_id, is_account_owner })
            : {};

        dispatch({
          type: "REFRESH",
          token,
          employee_id,
          email,
          bd_organization_id,
          account_id,
          account_type,
          bd_business_entity_id,
          collab_permissions,
          flattened_permissions,
          name,
          is_account_owner,
        });
      })();
    } else {
      dispatch({ type: "LOGOUT" });
    }
  }, []);

  return (
    <AuthUserContext.Provider value={state}>
      <AuthLoginContext.Provider
        value={async () => {
          try {
            if (auth0.isAuthenticated) {
              const auth0Token = await auth0.getAccessTokenSilently();
              axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${auth0Token}`;

              const { data, status, ...rest } = await axios.get(
                "/auth/checkUser"
              );
              const name = getName(data);
              const is_account_owner = data.owns_account;

              if (status === 200 && data.access_token) {
                if (data.permission.indexOf("super_admin") !== -1) {
                  data.account_type = "super_admin";
                }
                const permissions = await returnPermission({
                  id: data.employee_id,
                  is_account_owner,
                });

                dispatch({
                  type: "LOGIN",
                  token: auth0Token,
                  employee_id: data.employee_id,
                  bd_organization_id: data.bd_organization_id,
                  bd_business_entity_id: data.bd_business_entity_id,
                  account_id: data.account_id,
                  email: data.email,
                  is_account_owner,
                  name: name,
                  account_type: data.account_type,
                  ...permissions,
                });
              } else {
                dispatch({ type: "LOGOUT" });
              }
              return { data, status, ...rest };
            }
          } catch (e) {
            Sentry.captureException(e);
          }
        }}
      >
        <AuthLogoutContext.Provider
          value={() => {
            // TODO: need a better way there to avoid multiple redirects
            auth0.logout(); // this redirects to the /auth/login_path
            dispatch({ type: "LOGOUT" });
          }}
        >
          <AuthCollaboratorContext.Provider
            value={async (collabObj, account_type) => {
              try {
                const { data } = await axios.get("/auth/checkUser");
                const is_account_owner =
                  collabObj.account_id === data.account_id;
                const bd_organization_id = collabObj.bd_organization_id;
                const bd_business_entity_id = collabObj.bd_business_entity_id;
                const employee_id = collabObj.employee_id;
                const name = collabObj.name;

                const permissions = await returnPermission({
                  id: employee_id,
                  is_account_owner,
                });

                dispatch({
                  type: "CHANGE_ACCOUNT",
                  bd_business_entity_id,
                  bd_organization_id,
                  employee_id,
                  account_type,
                  is_account_owner,
                  name,
                  ...permissions,
                });
              } catch (e) {
                Sentry.captureException(e);
              }
            }}
          >
            {!state.loading && children}
          </AuthCollaboratorContext.Provider>
        </AuthLogoutContext.Provider>
      </AuthLoginContext.Provider>
    </AuthUserContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthUserContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within AuthProvider");
  }
  return context;
}

export function useCollaborator() {
  const context = useContext(AuthCollaboratorContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within AuthProvider");
  }
  return context;
}

export function useLogin() {
  const context = useContext(AuthLoginContext);
  if (context === undefined) {
    throw new Error("useLogin must be used within AuthProvider");
  }
  return context;
}

export function useLogout() {
  const context = useContext(AuthLogoutContext);
  if (context === undefined) {
    throw new Error("useLogout must be used within AuthProvider");
  }
  return context;
}
