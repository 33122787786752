import { address } from "components/Formik/formGroups/FormikAddressFields";
import React from "react";
import { arrests } from "../../uiStrings";

export const columns = [
  { field: "charge_date", format: "date" },
  { field: "nature" },
  { field: "incident_state" },
  {
    title: "Agency Name",
    field: "law_enforcement_pd_business_entity.business_name",
  },
  { field: "disposition" },
  { field: "participant_name" },
];

export const tableQuestions = <>{arrests.tableQuestion}</>;

export const nonRequired = [
  "expunged_address",
  "expunged_date",
  "is_expunged_or_sealed",
  "law_enforcement_pd_business_entity_id",
];

export const endpoint = "arrests_criminalLegalHistory";

export const modalTitle = "Add Arrest or Charge";

export const defaultInitialValues = {
  type: "criminal",
  is_expunged_or_sealed: null,
  expunged_address: address,
  nature: "",
  charge_date: null,
  participant_name: "",
  age_at_time: "",
  incident_city: "",
  incident_county: "",
  incident_state: "",
  law_enforcement_pd_business_entity_id: null,
  case_number: "",
  disposition: "",
  disposition_date: null,
  charge_type: "",
  sentence: "",
  incarceration_length: "",
  incarceration_location: "",
  expunged_date: null,
  attachment_group_id: null,
};

export const dataHookProps = {
  defaultInitialValues,
  nonRequired,
  endpoint,
};

export const alertMessage = (
  <>
    Information provided in this section may be required for your organization’s
    business disclosure and will be shared with the business. If you have any
    questions, please contact your Administrative User.
  </>
);
