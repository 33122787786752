import React from "react";
import { useHistory } from "react-router-dom";

export const useRecordFromQueryParam = ({
  handleEditRecord,
  isSuccess,
  location,
}) => {
  const history = useHistory();

  const handleEditRecordHasBeenCalled = React.useRef();

  React.useEffect(() => {
    const queryParam = location.search;
    if (queryParam && isSuccess && !handleEditRecordHasBeenCalled.current) {
      handleEditRecord(parseInt(queryParam.replace("?selected=", "")));
      handleEditRecordHasBeenCalled.current = true;
    }
  }, [location, isSuccess, handleEditRecord]);

  const clearQueryParam = () => {
    history.push({
      search: "",
    });
  };

  return { clearQueryParam };
};
