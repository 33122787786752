import axios from "axios";
import { useQuery } from "react-query";
import { captureSentryError } from "../error/helpers";

const getDropDownOptions = async (endpoint) => {
  if (window.location.hostname.split(".").shift() === "admin") {
    //TODO: once we refactor all pages we can make this check using auth on account_type === super_admin
    return [];
  }
  const { data } = await axios.get(endpoint);
  return data;
};

const objectMap = (obj, fn) =>
  Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));

// flattening for more ergonomic access of menuOptions
const flattenToMenuItems = (data) =>
  objectMap(data, (menuCategory) => menuCategory.items);

export const useMenuOptions = () => {
  const { data, isSuccess } = useQuery(
    ["menuOptions"],
    () => getDropDownOptions("common/getDropDownOptions"),
    {
      onError: captureSentryError,
      staleTime: 3600 * 24, // 24 hours
      select: (dataToShape) => flattenToMenuItems(dataToShape),
    }
  );

  return { ...data, isSuccess };
};
