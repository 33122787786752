import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import withStyles from "@material-ui/core/styles/withStyles";
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";
import cx from "classnames";
import useLeftNav from "hooks/useLeftNav";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import icons from "routeHelpers/icons";
import CollapseNavSubheader from "./CollapseNavSubheader";
import NavItemLink from "./NavItemLink";
import SidebarWrapper from "./SidebarWrapper";
import { getNewRoutes } from "./helpers";

const MobileDrawer = ({ children, onClose, classes, open }) => (
  <Drawer
    variant="temporary"
    anchor="right"
    open={open}
    classes={classes}
    onClose={onClose}
    ModalProps={{
      keepMounted: true, // Better open performance on mobile.
    }}
  >
    {children}
  </Drawer>
);

function Sidebar({ classes, routes, miniActive, handleDrawerToggle, open }) {
  const { styleState, navState, updateItem, displaySelectedItem } =
    useLeftNav();

  const bgColor = "black";

  const { navWidth, navBarWrapper } = styleState;
  const { navBarTagMap, selectedMainMenu, navBarMapObj } = navState;

  function onChangeMainNav(selectedNav) {
    updateItem(selectedNav.name, navBarMapObj[selectedNav.tag]);
  }

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes, level) => {
    return routes
      .filter(({ layout }) => layout !== "/auth")
      .map((route, key) => {
        if (route.collapse === true) {
          /*
           * PD + BD routes have an icon string defined server-side.
           * LC does not. Icon is determined based of the route tag matching a value in the icons enum.
           * In the future we should consolidate to one strategy, client side icon definition is likely the more flexible option.
           */
          if (typeof route.icon === "string") {
            route.icon = icons[route.icon] || icons["default"];
          } else if (icons[route.tag]) {
            route.icon = icons[route.tag];
          }
        }

        if ((route.tag && !_.size(navBarTagMap[route.tag])) || route.redirect) {
          return null;
        }
        const commonProps = { route, miniActive };
        if (route.collapse) {
          return (
            <CollapseNavSubheader
              {...commonProps}
              key={key}
              displaySelectedItem={displaySelectedItem}
              navState={navState}
              createLinks={createLinks}
            />
          );
        }
        return <NavItemLink {...commonProps} key={key} level={level} />;
      });
  };

  const drawerPaper =
    classes.drawerPaper +
    " " +
    cx({
      [classes.drawerPaperMini]: miniActive,
    });

  const sidebarWrapperClass =
    classes.sidebarWrapper +
    " " +
    cx({
      [classes.drawerPaperMini]: miniActive,
    });

  const drawerClasses = {
    paper: drawerPaper + " " + classes[bgColor + "Background"],
  };

  const listLinks =
    selectedMainMenu === "Personal Supplemental"
      ? _.orderBy(
          getNewRoutes(routes, selectedMainMenu),
          [(a) => a.name],
          ["asc"]
        )
      : getNewRoutes(routes, selectedMainMenu);

  const sidebarWrapper = (
    <SidebarWrapper
      className={sidebarWrapperClass}
      navWidth={navWidth}
      navState={navState}
      onChangeMainNav={onChangeMainNav}
      links={<List className={classes.list}>{createLinks(listLinks, 1)}</List>}
    />
  );

  return (
    <div style={{ width: navBarWrapper }}>
      <Hidden mdUp implementation="css">
        <MobileDrawer
          open={open}
          classes={drawerClasses}
          onClose={handleDrawerToggle}
        >
          {sidebarWrapper}
        </MobileDrawer>
      </Hidden>

      <Hidden smDown implementation="css">
        <Drawer anchor="left" variant="permanent" classes={drawerClasses}>
          {sidebarWrapper}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  miniActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  userName: PropTypes.string,
};

export default withStyles(sidebarStyle)(Sidebar);
