import { getFormComponents } from "componentGenerators/helpers";

import tennessee_phdrf_supplement_disclosure_questions from "components/Pages/Supplemental/PD/TennesseePHDRF/TennesseePHDRFDisclosureQuestions/TennesseePHDRFDisclosureQuestions";
import tennessee_phdrf_supplement_tax_information from "components/Pages/Supplemental/PD/TennesseePHDRF/TennesseePHDRFTaxInformation/TennesseePHDRFTaxInformation";
import tennessee_phdrf_supplement_attachments from "components/Pages/Supplemental/PD/TennesseePHDRF/TennesseePHDRFAttachments/TennesseePHDRFAttachments";

export default getFormComponents({
  tennessee_phdrf_supplement_disclosure_questions,
  tennessee_phdrf_supplement_tax_information,
  tennessee_phdrf_supplement_attachments,
});
