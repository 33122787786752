import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItemText,
  Typography,
} from "@material-ui/core";
import CustomCardHeader from "components/Common/Containers/Card/CustomCardHeader";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import useGet from "hooks/useGet";
import React from "react";
import { useHistory } from "react-router-dom";
import FlexRow from "components/flex/FlexRow";
import {
  ErrorChip,
  StyledCard,
  StyledCardContent,
  StyledListItem,
  VerifiedIcon,
  WarningChip,
} from "./ExpiringDocuments.styles";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";
import { useAuth } from "context/auth";

dayjs.extend(relativeTime);

const PriorityChip = ({ priority }) => {
  if (priority === "expired") return <ErrorChip size="small" label="Expired" />;
  return <WarningChip size="small" label="Update Soon" />;
};

const getExpiryDateString = (date) => {
  if (!date) return "Needs Attention";

  const isPastDate = dayjs(date).isBefore(dayjs());
  return `${isPastDate ? "Expired" : "Expires"} ${dayjs(date).fromNow()}`;
};

const ExpiringListItem = ({
  expiringItem: { description, expiry_date, priority_flag, url_tag },
  linkTo,
}) => {
  return (
    <>
      <StyledListItem
        button
        $isError={priority_flag === "expired"}
        onClick={() => linkTo(url_tag)}
      >
        <FlexRow pt={1} pb={1}>
          <ListItemText
            primary={description}
            secondary={getExpiryDateString(expiry_date)}
          />
          <PriorityChip priority={priority_flag} />
        </FlexRow>
      </StyledListItem>
      <Divider component="li" />
    </>
  );
};

const CenteredWrapper = ({ children }) => (
  <Box
    pt={10}
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
  >
    {children}
  </Box>
);

const ExpiringList = ({ expiringItems, linkTo }) => {
  if (!expiringItems.length) {
    return (
      <CenteredWrapper>
        <VerifiedIcon fontSize="large" />
        <Box pt={1}>
          <Typography component="span">Documents up to date</Typography>
        </Box>
      </CenteredWrapper>
    );
  }

  return (
    <StyledCardContent>
      <List>
        {expiringItems.map((expiringItem, index) => (
          <ExpiringListItem
            key={index}
            expiringItem={expiringItem}
            linkTo={linkTo}
          />
        ))}
      </List>
    </StyledCardContent>
  );
};

export const ExpiringDocuments = () => {
  const { id } = useAuth();
  const { data, status } = useGet("personal_details_stale_items", [
    "personal_details_stale_items",
    id,
  ]);
  const history = useHistory();

  const linkTo = (url) => history.push(`/app/${url}`);
  return (
    <StyledCard variant="outlined">
      <CustomCardHeader title="Attention Needed" />
      <LoadingComponent
        loadingOverwrite={
          <CenteredWrapper>
            <CircularProgress />
          </CenteredWrapper>
        }
        status={status}
      >
        <ExpiringList expiringItems={data} linkTo={linkTo} />
      </LoadingComponent>
    </StyledCard>
  );
};
