import {
  Box,
  Button,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import SimpleDialog from "components/Common/Dialogs/SimpleDialog";
import React from "react";
import { Document, Page } from "react-pdf";
import { GettingStartedNav } from "./GettingStartedNav";
import { QuickStartInstructions } from "./QuickStartInstructions";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import useTabs from "hooks/useTabs";

const PdfViewer = () => {
  return (
    <Box display="flex" justifyContent="center">
      <Document file={"/pdfs/getting-started.pdf"}>
        <Page pageNumber={1} />
      </Document>
    </Box>
  );
};

const QuickStart = ({ closeDialog, setScreen }) => {
  // eslint-disable-next-line no-unused-vars
  const [selectedItem, _, handleChangeItem] = useTabs();
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <div>
          <Typography>Quickstart:</Typography>
          <GettingStartedNav
            handleChangeItem={handleChangeItem}
            selectedItem={selectedItem}
          />
        </div>

        <Box mt={3}>
          <Typography>Welcome Guide:</Typography>
          <Box mt={2} display="flex" justifyContent="center">
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => setScreen("welcomeGuide")}
              startIcon={<PictureAsPdfIcon />}
            >
              Open Welcome Guide
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={8}>
        <Box pb={1}>
          <QuickStartInstructions
            handleChangeItem={handleChangeItem}
            closeDialog={closeDialog}
            selectedItem={selectedItem}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export const GettingStartedDialog = (props) => {
  const [screen, setScreen] = React.useState("quickstart");
  return (
    <SimpleDialog
      title="Getting Started"
      fullWidth={true}
      maxWidth={screen === "quickstart" ? "md" : "xl"}
      TransitionProps={{
        onExited: () => {
          setScreen("quickstart");
        },
      }}
      {...props}
    >
      {screen === "quickstart" && (
        <DialogContent>
          <QuickStart
            closeDialog={props.onClose}
            screen={screen}
            setScreen={setScreen}
          />
        </DialogContent>
      )}

      {screen === "welcomeGuide" && (
        <>
          <DialogContent>
            <PdfViewer />
          </DialogContent>
          <Box p={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setScreen("quickstart")}
            >
              Back
            </Button>
          </Box>
        </>
      )}
    </SimpleDialog>
  );
};
