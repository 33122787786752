import { fieldTypes } from "componentGenerators/formGenerator/config";
import { formats } from "components/Common/Tables/Common/helpers";
import React from "react";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "criminal history questions",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Have you ever refused to cooperate with any legislative body or other official investigatory body involved in the investigation of crimes? If answer is yes, please provide details in the box below.",
    name: "new_york_cvke.has_refused_to_cooperate_investigation",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "new_york_cvke.refused_to_cooperate_investigation_details",
    xs: 12,
    rows: 3,
  },
  {
    type: fieldTypes.yesNo,
    label: (
      <>
        Are you a career offender or a member of a career offender cartel, as
        those terms are defined by New York law?
        <br />
        <br />
        <em>
          Career offender is defined as any person whose behavior is pursued in
          an occupational manner or context for the purpose of economic gain,
          utilizing such methods as are deemed criminal violations of the public
          policy of the State of New York. Career offender cartel is defined as
          any group of persons who operate together as career offenders.
        </em>
        <br />
        <br />
        If answer is yes, please provide details in the box below.
      </>
    ),
    name: "new_york_cvke.is_career_offender",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "new_york_cvke.career_offender_details",
    xs: 12,
    rows: 3,
  },
  {
    type: fieldTypes.yesNo,
    label: `Do you have affiliates or close associates whose relationship with you may pose any 
      injurious threat to the interest of the State of New York? If answer is yes, please provide 
      details in the box below.`,
    name: "new_york_cvke.is_associated_threat",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "new_york_cvke.associated_threat_details",
    xs: 12,
    rows: 3,
  },
];

const personalLawsuitsColumns = [
  { field: "filing_date" },
  {
    field: "type",
    format: formats.startCase,
  },
  {
    field: "law_enforcement_pd_business_entity.business_name",
    title: "Name of Court",
  },
  { field: "case_number" },
  {
    field: "disposition",
    format: formats.startCase,
  },
  {
    field: "pd_business_entity.business_name",
    title: "Business Name",
  },
];

const personalLawsuitsFormSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Relative (if applicable)",
  },
  {
    type: fieldTypes.pdPersonSelect,
    name: "relative_pd_person_id",
    label: "Relative (if applicable)",
    defaultValue: null,
  },
  {
    type: fieldTypes.subHeader,
    label: "Resolution Method & Role",
    divider: true,
  },
  {
    type: fieldTypes.select,
    dynamicMenuItems: ["disputeResolutionMethod"],
    name: "type",
    label: "Select Type",
    defaultValue: null,
  },
  {
    type: fieldTypes.select,
    dynamicMenuItems: ["roleInDispute"],
    name: "role_in_dispute",
    label: "Select Role",
    defaultValue: null,
  },
  {
    type: fieldTypes.subHeader,
    label: "Associated Business Entity (If Applicable)",
    divider: true,
  },
  {
    type: fieldTypes.pdBusinessSelect,
    name: "pd_business_entity_id",
    label: "Business Entity (If Applicable)",
    defaultValue: null,
  },
  {
    type: fieldTypes.subHeader,
    label: "Law Enforcement or Court",
    divider: true,
  },
  {
    type: fieldTypes.pdLawEnforcementSelect,
    name: "law_enforcement_pd_business_entity_id",
    label: "Law Enforcement or Court",
    defaultValue: null,
  },
  {
    type: fieldTypes.subHeader,
    label: "Lawsuit Details",
    divider: true,
  },
  {
    type: fieldTypes.text,
    name: "case_number",
  },
  {
    type: fieldTypes.text,
    name: "nature",
    label: "Nature of Suit",
  },
  {
    type: fieldTypes.date,
    name: "filing_date",
  },
  {
    type: fieldTypes.text,
    name: "other_parties",
    label: "Other Parties to Suit",
  },
  {
    type: fieldTypes.text,
    name: "time_period",
    label: "Approximate Date(s)",
  },
  {
    type: fieldTypes.text,
    name: "action_location",
    label: "Location Action Filed",
    helperText: "(City, State, County)",
  },
  {
    type: fieldTypes.subHeader,
    label: "Disposition",
    divider: true,
  },
  {
    type: fieldTypes.date,
    name: "disposition_date",
  },
  {
    type: fieldTypes.select,
    dynamicMenuItems: ["lawsuitAndArbitrationDispositionType"],
    name: "disposition",
    label: "Disposition",
    defaultValue: null,
  },
  {
    type: fieldTypes.label,
    label: `For all pending litigation, unsatisfied judgments, decrees, restraining 
      orders, protective orders, or injunctive orders, provide the following.`,
  },
  {
    type: fieldTypes.text,
    name: "relief_sought",
    label: "Type and Amount of Relief Sought",
    xs: 12,
    rows: 3,
  },
  {
    type: fieldTypes.text,
    name: "gaming_operation_impact_assessment",
    label:
      "Assessment of impact, if any, the action may have on your gaming operation",
    xs: 12,
    rows: 3,
  },
  {
    type: fieldTypes.dropZone,
    name: "attachment_group_id",
    description: "Upload a copy of all documentation in the matter",
    label: "Upload Attachment",
    xs: 12,
  },
];

const personalLawsuitsSchema = {
  type: "table",
  formSchema: personalLawsuitsFormSchema,
  endpoint:
    "new_york_cvke_supplement_criminal_history_lawsuitsAndArbitrationLegalHistory",
  columns: personalLawsuitsColumns,
  infoAlerts: [
    {
      body: (
        <>
          For the following questions, please attach a copy of the official
          complaint, summons,and/or discharge papers to each record(s):
          <ul>
            <li>
              In the past fifteen (15) years, have you as an individual, member
              of a partnership, or owner, director, or officer of a
              corporation,ever been a party to a lawsuit, as either a plaintiff
              or defendant pr an arbitration as either a claimant or defendant?
            </li>
            <br />
            <li>
              In the past fifteen (15) years, has any general partnership,
              business venture, sole propritorship or closely held corporation,
              which you were associated with as an owner, officer, director or
              partner, been a party to a lawsuit, arbitration or bankruptcy?
            </li>
          </ul>
        </>
      ),
    },
  ],
};

export default [
  {
    type: "form",
    formSchema,
    endpoint: "new_york_cvke_supplement_tax_information_pdSupplement",
  },
  personalLawsuitsSchema,
];
