import { Box, Grid, Typography, Button } from "@material-ui/core";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import FormikTextField from "components/Formik/fields/FormikTextField";
import YesNo from "components/Formik/formGroups/YesNo.jsx";
import useFetch from "queryHooks/useFetch";
import React from "react";
import styled from "styled-components";
import { Alert, AlertTitle } from "@material-ui/lab";
import { red } from "@material-ui/core/colors";
import useToggle from "hooks/useToggle";
import DeleteUserConfirmation from "./DeleteUserConfirmation";
import { UserPermissionField } from "./UserPermissionField";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";
import CollaboratorInlineTable from "./CollaboratorInlineTable";
import InviteUserSection from "components/Pages/ManageUser/inviteUserSection/index.js";

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.palette.getContrastText(red[500])};
  background-color: ${red[500]};
  &:hover {
    background-color: ${red[300]};
  }
`;

// ! This is for 888, will need to add special variable to env
export const checkAbilityToModifyUserAccess = () => {
  const subdomain =
    window.location.host.split(".")[1] && window.location.host.split(".")[0];

  const userAccessAllowedTenant =
    process.env.REACT_APP_CHANGE_USER_ACCESS_PRIVILEGE_TENANTS || "";
  return !!(
    userAccessAllowedTenant.split(",").includes(subdomain)
    //subdomain === "888" && process.env.REACT_APP_CHANGE_USER_ACCESS_PRIVILEGE
  );
};

export const ModifyUserAccessField = ({ currentRow }) => {
  // hiding for is_internal = true
  if (currentRow && currentRow.is_internal) return null;

  if (!checkAbilityToModifyUserAccess()) return null;

  return (
    <>
      <SectionSubHeader label="User Access" divider />
      <SectionLabel label="Internal users will be able to login directly using SSO. External users will receive an invitation link to create an account." />

      <YesNo
        name="is_internal"
        options={[
          { name: "Internal (SSO)", value: "true" },
          { name: "External", value: "false" },
        ]}
      />
    </>
  );
};

export const EditUserForm = ({ currentRecord, closeRecord }) => {
  const hasAcceptedInvitation = currentRecord?.invitation_status === "accepted";
  const { on, setOn, setOff } = useToggle();
  const { data: permissions = [], status } = useFetch(
    "account/permission",
    "account/permission",
    {
      useFullEndpoint: false,
    }
  );

  const { disclosurePermissions, adminPermissions } = React.useMemo(() => {
    return {
      disclosurePermissions: permissions.filter(
        ({ type }) => type === "occupational" || type === "key_personal"
      ),
      adminPermissions: permissions.filter(
        ({ type }) => type !== "occupational" && type !== "key_personal"
      ),
    };
  }, [permissions]);

  return (
    <Grid container spacing={2}>
      <DeleteUserConfirmation
        on={on}
        setOff={setOff}
        closeRecord={closeRecord}
      />

      <InviteUserSection name="invitation_status" currentRow={currentRecord} />

      <SectionSubHeader label="User Details (Used for Account Management only)" />
      <FormikTextField label="First Name" name="first_name" size={4} />
      <FormikTextField label="Last Name" name="last_name" size={4} />
      <FormikTextField label="Email" name="email" size={4} disabled />

      <ModifyUserAccessField currentRow={currentRecord} />

      <LoadingComponent status={status}>
        <SectionSubHeader label="Disclosure Roles" divider />

        <SectionLabel label="Checking the boxes below will enable the desired Disclosure features of the system." />

        <UserPermissionField
          label=""
          values={disclosurePermissions}
          name="permission"
          size={6}
        />

        <Alert icon={false} severity="error" style={{ width: "100%" }}>
          <Typography variant="body2">
            &bull; Adding a new Disclosure will result in additional monthly
            charges/fees.
          </Typography>
          <Typography variant="body2">
            &bull; In order to turn off a Disclosure, please contact OneComply.
          </Typography>
          <Typography variant="body2">
            &bull; Please notify the newly enabled user to logout and log back
            in to apply the new features.
          </Typography>
        </Alert>

        <SectionSubHeader label="Admin Permissions" divider />

        <UserPermissionField
          values={adminPermissions}
          label=""
          name="permission"
          size={6}
        />

        <SectionSubHeader label="Collaborators" divider />
        <CollaboratorInlineTable />
      </LoadingComponent>

      <Grid item xs={12}>
        {hasAcceptedInvitation && (
          <>
            <SectionSubHeader label="Deactivate User" divider />

            <YesNo
              name="is_active"
              disabled={true}
              options={[
                { name: "Active", value: "true" },
                { name: "Deactivated", value: "false" },
              ]}
            />
          </>
        )}

        <Box pt={4}>
          <SectionSubHeader>Danger Zone</SectionSubHeader>
        </Box>

        <Box pt={1}>
          <Alert
            action={
              <StyledButton onClick={setOn} variant="contained" size="medium">
                DELETE
              </StyledButton>
            }
            icon={false}
            severity="error"
            variant="outlined"
          >
            <AlertTitle>Delete User</AlertTitle>
            The user will be deleted and all it&lsquo;s data wiped
          </Alert>
        </Box>
      </Grid>
    </Grid>
  );
};
