import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Divider,
  Popover,
} from "@material-ui/core";
import { MultiSelect } from "components/Pages/LicensingControl/components/FilterTable/MultiSelect.jsx";
import _ from "lodash";
import React from "react";
import { FilterTools } from "./FilterTools";
import useAnchorEl from "hooks/useAnchorEl";

const FilterForm = ({ filterTags, filterProps }) => {
  return (
    <>
      {Object.keys(filterTags).map((filterCategory, idx) => {
        if (filterTags[filterCategory].length <= 1) return null;
        return (
          <React.Fragment key={filterCategory}>
            <MultiSelect
              options={filterTags[filterCategory]}
              label={_.startCase(filterCategory)}
              placeholder="Select Category"
              getOptionLabel={(option) => option}
              onChange={(e, value) => filterProps.onChange(value, idx)}
              value={filterProps.filters[idx]}
            />
            <Divider style={{ margin: "20px 0px" }} />
          </React.Fragment>
        );
      })}
    </>
  );
};

FilterForm.propTypes = {
  filterProps: PropTypes.object,
  filterTags: PropTypes.object,
};

export const Filters = ({
  handleFilter,
  filterTags,
  filterProps,
  filterCount,
  searchValue,
  handleSearch,
}) => {
  const { anchorEl, toggle, setOff } = useAnchorEl();

  const applyFilter = () => {
    setOff();
    handleFilter();
  };

  const hasFilters = !!Object.keys(filterTags).find(
    (item) => filterTags[item].length > 1
  );

  return (
    <>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={setOff}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Card>
          <CardHeader title="Filters" />
          <CardContent style={{ width: "350px" }}>
            <FilterForm filterTags={filterTags} filterProps={filterProps} />
          </CardContent>
          <CardActionArea>
            <Button
              fullWidth
              color="primary"
              onClick={applyFilter}
              component="div"
            >
              Apply
            </Button>
          </CardActionArea>
        </Card>
      </Popover>
      <FilterTools
        hasFilterMenu={hasFilters}
        handleSearch={handleSearch}
        searchValue={searchValue}
        openFilterMenu={toggle}
        filterCount={filterCount}
        applyFilter={applyFilter}
        headerButton={null}
      />
    </>
  );
};

Filters.propTypes = {
  filterCount: PropTypes.number,
  filterProps: PropTypes.object,
  filterTags: PropTypes.object,
  handleFilter: PropTypes.func,
  searchValue: PropTypes.string,
  handleSearch: PropTypes.func,
};
