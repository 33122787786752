import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Typography } from "@material-ui/core";
import useGet from "hooks/useGet";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

dayjs.extend(relativeTime);

const getType = (type) => {
  switch (type) {
    case "seacft":
      return "Success Exchange";
    case "ssa":
      return "Success Silent Auth";
    case "fp":
      return "Failed Login (Incorrect Password)";
    case "slo":
      return "Success Logout";
    case "s":
      return "Success Login";
    default:
      return "";
  }
};

export default function Logs() {
  const { data = [], status } = useGet("account/userLogs", "account/userLogs", {
    useFullEndpoint: false,
  });
  return (
    <>
      <Typography>Last 10 Events</Typography>
      <LoadingComponent status={status}>
        <TableContainer component={Box} height={"500px"}>
          <Table aria-label="logs" overflow={"scroll"}>
            <TableHead>
              <TableRow>
                <TableCell align="center">Event</TableCell>
                <TableCell align="center"> When </TableCell>
                <TableCell align="center">From</TableCell>
                <TableCell align="center">IP </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => {
                return (
                  <TableRow key={row.date}>
                    <TableCell align="center">{getType(row.type)}</TableCell>
                    <TableCell align="center">
                      {dayjs(row.date).fromNow()}
                    </TableCell>
                    <TableCell align="center">
                      {`${row.location_info.city_name}, ${row.location_info.country_name}`}
                    </TableCell>
                    <TableCell align="center">{row.ip}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </LoadingComponent>
    </>
  );
}
