export const config = [
  {
    title: "Application Date",
    field: "application_date",
    format: "date",
  },
  {
    title: "Business Entity Name",
    field: "business_name",

  },
  {
    title: "Agency Name",
    field: "agency_name",

  },
  {
    title: "License name",
    field: "license_type",

  },
  {
    title: "Shares Owned",
    field: "number_shares_owned",

  },
  {
    title: "Nature of Interest",
    field: "nature_of_interest",

  },
]