import React from "react";
import Tooltip from "components/Common/Tooltip";

export const columns = [
  {
    title: "Beneficial Owner",
    field: "beneficial_owner_pd_person.full_name",
  },
  {
    title: "Business Entity",
    field: "pd_business_entity.business_name",
  },
  {
    field: "description",
  },
  {
    field: "location",
  },
  {
    title: "Family Member",
    field: "relative_pd_person.full_name",
  },
];

export const tableQuestions = (
  <>
    If you are the Nominee, or hold, manage or control in trust, or otherwise,
    any assets or liabilities for another person or entity in any jurisdiction
    provide details below. (You may exclude those assets or liabilities
    disclosed in Executors & Beneficiary.)
    <Tooltip
      type="info"
      title="Nominee is any person that holds as owner of record the legal title to tangible or intangible personal real property, including, without limitations, any stock, bond debenture, note, investment contract or real estate on behalf of another Individual or Business Entity, and as such is designated and authorized to act on his, her, or its behalf with respect to such property."
    />
  </>
);

export const defaultInitialValues = {
  type: "third_party",
  relative_pd_person_id: null,
  number_of_shares: null,
  interest_amount: "",
  beneficial_owner_pd_person_id: null,
  pd_business_entity_id: null,
  description: "",
  location: "",
  trust_attachment_group_id: null,
};
