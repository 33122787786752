import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm";
import React from "react";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import YesNo from "components/Formik/formGroups/YesNo";

const Form = () => {
  const formData = useForm({
    defaultInitialValues: {
      bd_elk_valley: {
        is_loaning_money_to_gaming_operation: null,
        amount_cash_invested: null,
        source_of_funds: "",
      },
    },
    endpoint: "bdSupplement",
  });
  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage>
        <Grid container spacing={2}>
          <YesNo
            label="Will the business be investing in or loaning money to the gaming operation?"
            name="bd_elk_valley.is_loaning_money_to_gaming_operation"
          />

          <FormikNumberField
            header="If YES, enter the following:"
            label="Amount of Cash or Other Investment"
            name="bd_elk_valley.amount_cash_invested"
          />

          <FormikTextField
            label="Please describe the source of funds for this investment:"
            name="bd_elk_valley.source_of_funds"
            multiline
            rows={2}
            xs={12}
          />
        </Grid>
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

const ElkValleyInvestedOrLoaned = ({ title }) => {
  return (
    <FormSectionWrapper title={title}>
      <Form />
    </FormSectionWrapper>
  );
};

export default ElkValleyInvestedOrLoaned;
