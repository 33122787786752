import PropTypes from "prop-types";
import React from "react";
import useFormikInlineTable from "./useFormikInlineTable";
import { FormikDialog } from "components/Formik/formContainers/FormikDialog";
import InlineTable from "./InlineTable";
import { useAuth } from "context/auth";

const buildAdditionalInitialValues = (bd_organization_id, employee_id) => ({
  ...(bd_organization_id && {
    bd_organization_id: parseInt(bd_organization_id),
  }),
  ...(employee_id && { employee_id: parseInt(employee_id) }),
});

const FormikInlineTable = ({
  name,
  columns,
  addButtonText,
  defaultInitialValues,
  form,
  dialogTitle,
  validationSchema,
  maxRecordSize,
  handleEditRecord = true,
  onSubmit = null,
  ...rest
}) => {
  const { bd_organization_id, employee_id } = useAuth();

  const { records, tableProps, formikProps } = useFormikInlineTable({
    name,
    defaultInitialValues: {
      ...defaultInitialValues,
      ...buildAdditionalInitialValues(bd_organization_id, employee_id),
    },
  });

  return (
    <>
      <FormikDialog
        title={dialogTitle}
        validationSchema={validationSchema}
        {...formikProps}
        onSubmit={(e, actions) => {
          return onSubmit
            ? onSubmit(e).then(() => {
                return formikProps.onSubmit(e, actions);
              })
            : formikProps.onSubmit(e, actions);
        }}
        maxWidth="sm"
      >
        {form}
      </FormikDialog>
      <InlineTable
        records={records}
        columns={columns}
        addButtonText={addButtonText}
        {...tableProps}
        handleEditRecord={
          handleEditRecord ? tableProps.handleEditRecord : handleEditRecord
        }
        maxRecordSize={maxRecordSize}
        {...rest}
      />
    </>
  );
};

FormikInlineTable.propTypes = {
  addButtonText: PropTypes.string,
  onSubmit: PropTypes.func,
  columns: PropTypes.array,
  defaultInitialValues: PropTypes.any,
  dialogTitle: PropTypes.string,
  form: PropTypes.any,
  handleEditRecord: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  maxRecordSize: PropTypes.number,
  name: PropTypes.any,
  validationSchema: PropTypes.any,
};

export default FormikInlineTable;
