import _, { isEmpty } from "lodash";
import React from "react";
import { Typography, Chip, Box } from "@material-ui/core";
import CustomLink from "components/Common/Link";
import { generateFullAddress } from "helpers/addressHelpers";
import styled from "styled-components";
import * as Sentry from "@sentry/react";
import { dayjsUTC } from "helpers/apiHelpers";

export const StyledChip = styled(Chip)`
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`;

export const formats = {
  startCase: "startCase",
  link: "link",
  boolean: "boolean",
  currency: "currency",
  checkbox: "checkbox",
  number: "number",
  percent: "percent",
  address: "address",
  date: "date",
  monthYear: "monthYear",
  redirect: "redirect",
  party: "party",
  chip: "chip",
};

export const columnDataRenderTypeCheck = (data, field) => {
  const errorPrefix = `Invalid column data type for '${field}':`;
  if (typeof data === "object" && data !== null) {
    throw new Error(
      `${errorPrefix} Objects are not valid as a React child. Make sure the correct column format is being provided`
    );
  }
  if (typeof data === "boolean") {
    throw new Error(
      `${errorPrefix} Booleans will not render on table. Make sure the correct column format is being provided`
    );
  }
};

const checkForUndefinedParentField = (record, field) => {
  const splitFields = field.split(".");
  if (record[splitFields[0]] === undefined) {
    Sentry.captureMessage(
      `'${field}' attribute was provided to the table but does not exist on the record. Verify the field values being passed in the columns prop are correct.`
    );

    console.error(
      `'${field}' attribute was provided to the table but does not exist on the record. Verify the field values being passed in the columns prop are correct.`
    );
  }
};

export const formatRecord = (record, field, format) => {
  const fieldData = _.get(record, field);

  checkForUndefinedParentField(record, field);

  if (fieldData === "11/11/2600") return "Present";

  switch (format) {
    case formats.startCase:
      return _.startCase(fieldData);

    case formats.link:
      return (
        <Typography>
          <a
            href={new URL(fieldData)}
            rel="noopener noreferrer"
            target="_blank"
          >
            {fieldData}
          </a>
        </Typography>
      );

    case formats.boolean:
      if (fieldData === true) {
        return "Yes";
      } else if (fieldData === false) {
        return "No";
      }
      return "";

    case formats.currency:
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 4,
      }).format(fieldData);

    case formats.checkbox:
      return Object.keys(fieldData)
        .filter((value) => fieldData[value])
        .map((item) => _.startCase(item))
        .join(", ");

    case formats.number:
      return new Intl.NumberFormat("en-US", {}).format(fieldData);

    case formats.percent:
      if (fieldData) return fieldData + "%";
      return null;

    case formats.address:
      return generateFullAddress(fieldData);

    case formats.date: {
      if (!fieldData || typeof fieldData === "number") return fieldData;
      let formatedDate = dayjsUTC(fieldData, "MM/DD/YYYY");
      if (formatedDate === "11/11/2600" || formatedDate === "11/10/2600")
        return "Present";
      return formatedDate;
    }

    case formats.monthYear: {
      if (!fieldData || typeof fieldData === "number") return fieldData;
      const formatedDate = dayjsUTC(fieldData, "MM/YYYY");
      if (formatedDate === "11/2600") return "Present";
      return formatedDate;
    }

    case formats.redirect:
      return <CustomLink to={fieldData} />;

    case formats.chip:
      if (isEmpty(fieldData)) {
        return "";
      } else if (Array.isArray(fieldData)) {
        return (
          <Box display="flex" maxWidth="400px" overflow="auto">
            {fieldData.map((label) => (
              <StyledChip key={label} size="small" label={label} />
            ))}
          </Box>
        );
      } else {
        Sentry.captureMessage(`'${field}' Chip format Should receive an ARRAY`);
        console.error(`'${field}' Chip format Should receive an ARRAY`);
        return "Error Loading";
      }

    case formats.party:
      if (fieldData.type === "person") {
        return fieldData?.bd_person?.full_name;
      } else if (fieldData.type === "business_entity") {
        return fieldData?.bd_business_entity?.business_name;
      } else {
        return "";
      }

    default:
      if (format) {
        console.error(`'${format}' is not a valid column format`);
      }
      columnDataRenderTypeCheck(fieldData, field);
      return fieldData || null;
  }
};
