import AnnualIncome from "components/Pages/PD/Financial/AnnualIncome";
import AssetsAndLiabilitiesNonUs from "components/Pages/Supplemental/PD/Illinois/AssetsAndLiabilitiesNonUs/AssetsAndLiabilitiesNonUs";
import IllinoisDisclosureQuestions from "components/Pages/Supplemental/PD/Illinois/IllinoisDisclosureQuestions/IllinoisDisclosureQuestions";
import IllinoisGamingBusinessOwnership from "components/Pages/Supplemental/PD/Illinois/IllinoisGamingBusinessOwnership/IllinoisGamingBusinessOwnership";
import IllinoisLegalHistory from "components/Pages/Supplemental/PD/Illinois/IllinoisLegalHistory/IllinoisLegalHistory";
import IllinoisLicenseAndGamingHistory from "components/Pages/Supplemental/PD/Illinois/IllinoisLicenseAndGamingHistory/IllinoisLicenseAndGamingHistory";
import IllinoisLicensesAndPermits from "components/Pages/Supplemental/PD/Illinois/IllinoisLicensesAndPermits/IllinoisLicensesAndPermits";
import IllinoisNetWorthStatement from "components/Pages/Supplemental/PD/Illinois/IllinoisNetWorthStatement/IllinoisNetWorthStatement";
import IllinoisNomineeAssets from "components/Pages/Supplemental/PD/Illinois/IllinoisNomineeAssets/IllinoisNomineeAssets";
/* eslint-disable react/display-name */
import React from "react";

const illinois_pages = {
  illinois_disclosure_questions: IllinoisDisclosureQuestions,
  illinois_legal_history: IllinoisLegalHistory,
  illinois_licenses_and_permits: IllinoisLicensesAndPermits,
  illinois_license_and_gaming_history: IllinoisLicenseAndGamingHistory,
  illinois_gaming_business_ownership: IllinoisGamingBusinessOwnership,
  illinois_income_statement: (props) => (
    <AnnualIncome
      {...props}
      jurisdiction="illinois"
      endpoint="illinois_income_statement_"
    />
  ),
  illinois_assets_and_liabilities_non_us: AssetsAndLiabilitiesNonUs,
  illinois_nominee_assets: IllinoisNomineeAssets,
  illinois_net_worth_statement: IllinoisNetWorthStatement,
};

export default illinois_pages;
