import React from "react";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Require Items due upon submission",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload articles or certificate of incorporation, organization, charter,
        assumed name, by-laws, operating agreement or similar official
        documentation. Upload documents in the
        <CustomLink to="bd_corporate_documents" />
        section.
      </>
    ),
  },
  {
    type: fieldTypes.divider,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload an ownership flowchart illustrating the fully diluted beneficial
        ownership of the applicant. List intermediary entities (e.g. operating
        entities, holding companies, or trusts) holding ownership until the
        flowchart reflects the ownership interest as being held by
        individual(s), not other business(es). If the ultimate parent company is
        publicly traded and no individual controls more than 5% of the publicly
        traded stock, indicate that in a footnote to the flowchart. Upload
        documents in the
        <CustomLink to="bd_corporate_documents" />
        section.
      </>
    ),
  },
  {
    type: fieldTypes.divider,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload an organizational chart showing the corporate structure of
        Applicant, identifying all officers of Applicant and all members of the
        board of directors. Include position descriptions and names of
        individuals in such roles. Upload documents in the
        <CustomLink to="bd_corporate_documents" />
        section.
      </>
    ),
  },
  {
    type: fieldTypes.divider,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload a completed federal, state and local tax returns for the past
        three years including schedules, attachments, amendments and extensions
        (unless previously submitted to the Board). Upload documents in the
        <CustomLink to="bd_tax_returns" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.divider,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload a completed IRS Form 4506-C to include a request of account
        transcripts for the past four tax filing periods for corporate (
        1065/1120), and employer&apos;s (940 and 941 /944) tax returns. Upload
        documents in the <CustomLink to="bd_tax_returns" />
        section.
      </>
    ),
  },
  {
    type: fieldTypes.divider,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Financial statements including income statement, balance sheet, cash
        flow statement, corresponding financial notes, and schedules for the
        past three years. Upload documents in the
        <CustomLink to="bd_financial_statements" />
        section.
      </>
    ),
  },
  {
    type: fieldTypes.divider,
  },
  {
    type: fieldTypes.subHeader,
    label: "SUPPLEMENTAL ITEMS (OPTIONAL UPFRONT SUBMISSION)",
  },
  {
    type: fieldTypes.label,
    label: <>Financial statements for most recent quarter-end.</>,
  },
  {
    type: fieldTypes.divider,
  },
  {
    type: fieldTypes.label,
    label: <>Detailed trial balance for last three years.</>,
  },
  {
    type: fieldTypes.divider,
  },
  {
    type: fieldTypes.label,
    label: <>Audit findings for last three years, if applicable.</>,
  },
  {
    type: fieldTypes.divider,
  },
  {
    type: fieldTypes.label,
    label: (
      <>Check register for last three years and most recent quarter-end.</>
    ),
  },
  {
    type: fieldTypes.divider,
  },
  {
    type: fieldTypes.label,
    label: <>Cash receipt journal for last three years.</>,
  },
  {
    type: fieldTypes.divider,
  },
  {
    type: fieldTypes.label,
    label: (
      <>Accounts receivable and payables aging reports for last three years.</>
    ),
  },
  {
    type: fieldTypes.divider,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Listing of expense report payments made to associated individuals during
        last three years including: employee name, expense description, date,
        and amount.
      </>
    ),
  },
  {
    type: fieldTypes.divider,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Listing of all 1099 vendors including: vendor name, vendor
        identification number and accumulative payments made for last three
        years.
      </>
    ),
  },
  {
    type: fieldTypes.divider,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Petty cash transaction journal including payee name, description,
        amount, and date for activity during last three years.
      </>
    ),
  },
  {
    type: fieldTypes.divider,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Summary of annual capital contributions and distributions made during
        the last three years.
      </>
    ),
  },
  {
    type: fieldTypes.divider,
  },
  {
    type: fieldTypes.label,
    label: (
      <>Listing of all related-party transactions during last three years.</>
    ),
  },
  {
    type: fieldTypes.divider,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Summary of material events which occurred during last three years
        including, but not limited to, adverse audit findings, material legal
        matters, changes in accounting assumptions, regulatory violations, or
        significant operational changes.
      </>
    ),
  },
  {
    type: fieldTypes.divider,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "bdSupplement",
};
