import PropTypes from "prop-types";
import {
  Button,
  InputAdornment,
  TextField,
  Typography,
  Box,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import FilterListIcon from "@material-ui/icons/FilterList";
import React from "react";
import styled from "styled-components";

const FilterButton = styled.div`
  width: 200px;
  /* padding-top: 10px; */
  display: flex;
  flex-direction: column;
`;

const SearchInput = ({ ...rest }) => (
  <TextField
    size="small"
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      ),
    }}
    {...rest}
  />
);

export const FilterTools = ({
  handleSearch,
  searchValue,
  openFilterMenu,
  filterCount,
  applyFilter,
  headerButton,
}) => (
  <Box display="flex">
    {headerButton ? (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        pr={2}
        pt={1.5}
      >
        {headerButton}
      </Box>
    ) : null}
    {handleSearch ? (
      <Box display="flex" flexDirection="column" justifyContent="center" pr={2}>
        <SearchInput
          onBlur={applyFilter}
          onKeyPress={(ev) => {
            if (ev.key === "Enter") {
              applyFilter();
            }
          }}
          onChange={handleSearch}
          label="Search"
          value={searchValue}
        />
      </Box>
    ) : null}

    <FilterButton>
      <Typography
        variant="overline"
        color="textSecondary"
        style={{ margin: "0px", padding: "0px", lineHeight: "15px" }}
      >
        {`(${filterCount} Filters Applied)`}
      </Typography>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={openFilterMenu}
        endIcon={<FilterListIcon />}
      >
        Filter
      </Button>
    </FilterButton>
  </Box>
);

FilterTools.propTypes = {
  applyFilter: PropTypes.func,
  filterCount: PropTypes.number,
  handleSearch: PropTypes.func,
  headerButton: PropTypes.node,
  openFilterMenu: PropTypes.func,
  searchValue: PropTypes.string,
};
