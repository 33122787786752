import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useForm from "hooks/useForm.js";
import React from "react";
import FormikCheckBoxGroup from "components/Formik/formGroups/FormikCheckBoxGroup";
import PDBusinessSelect from "components/Formik/formGroups/CrudSelect/selects/PDBusinessSelect.jsx";

const MontanaDisclosureQuestionsForm = ({ innerRef }) => {
  const {
    MontanaApplicationReasonCheckbox,
    MontanaPositionWithBusinessCheckbox,
  } = useMenuOptions();
  const formData = useForm({
    defaultInitialValues: {
      montana: {
        pd_business_entity_id: null,
        position_with_business_checkbox: null,
        position_with_business_other: "",
        application_reason_checkbox: null,
        application_reason_other: "",
      },
    },
    noValidate: true,
    endpoint: "montana_disclosure_questions_pdSupplement",
  });

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage
        title={"Citizenship & Residence"}
        innerRef={innerRef}
        showSaveButton={false}
      >
        <Grid container spacing={2}>
          <PDBusinessSelect
            name="montana.pd_business_entity_id"
            header="Associated Gaming Entity"
          />
          <SectionSubHeader label="Position with Business" />

          <FormikCheckBoxGroup
            name="montana.position_with_business_checkbox"
            options={MontanaPositionWithBusinessCheckbox}
          />
          <FormikTextField
            label="Other"
            name="montana.position_with_business_other"
          />
          <SectionSubHeader label="Reason for Application" />
          <FormikCheckBoxGroup
            name="montana.application_reason_checkbox"
            options={MontanaApplicationReasonCheckbox}
          />
          <FormikTextField
            label="Other"
            name="montana.application_reason_other"
          />
        </Grid>
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

export default MontanaDisclosureQuestionsForm;
