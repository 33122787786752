import PropTypes from "prop-types";
import React from "react";
import ConfirmationDialog from "components/Common/Dialogs/ConfirmationDialog";
import { useFormikContext } from "formik";
import { Box, Typography } from "@material-ui/core";
import useDelete from "queryHooks/useDelete";
import axios from "axios";
import { useQueryClient } from "react-query";
import { FormikTextField } from "components/Formik/fields";
import useErrorSnackBar from "hooks/useErrorSnackbar";
import { useSnackbar } from "notistack";

const DeleteUserConfirmation = ({ on, setOff, closeRecord }) => {
  const queryCache = useQueryClient();
  const { handleErrorMessage } = useErrorSnackBar();

  const { enqueueSnackbar } = useSnackbar();

  const {
    values: { email, first_name, last_name, id, permission },
  } = useFormikContext();
  const isPersonal = React.useMemo(() => {
    return permission
      ? permission.some(({ type }) => type === "key_personal")
      : false;
  }, [permission]);
  const [confirmationEmail, setConfirmationEmail] = React.useState("");

  function closeAll() {
    closeRecord();
    setConfirmationEmail("");
    setOff();
    queryCache.refetchQueries("account");
  }
  const { mutate: deleteMutation } = useDelete("account", `account/${id}`, {
    queryFn: () => axios.delete(`account/${id}`),
    onSuccess: () => {
      enqueueSnackbar("Account Deleted Successfully", {
        autoHideDuration: 2000,
        variant: "success",
      });
      closeAll();
    },
    onError: (error) => {
      handleErrorMessage(error);
      setConfirmationEmail("");
      setOff();
    },
  });

  const onCancel = () => {
    setConfirmationEmail("");
    setOff();
  };
  return (
    <ConfirmationDialog
      title="Delete user"
      confirmationButtonProps={{
        variant: "contained",
        color: "error",
        size: "sm",
        disabled: confirmationEmail !== email,
      }}
      confirmationText="Yes, Delete it"
      open={on}
      onCancel={onCancel}
      onConfirm={deleteMutation}
    >
      {isPersonal ? (
        <Box pb={2}>
          <Typography>
            {`${first_name} ${last_name} is a Key Personnel`}
          </Typography>
          <Typography>
            {`Deleting this user will result in permanent data loss.`}
          </Typography>
        </Box>
      ) : null}
      <Typography>
        {`Are you really sure you want to delete ${first_name} ${last_name}?`}
      </Typography>
      <Typography gutterBottom={true}>{`This cannot be undone!`}</Typography>
      <FormikTextField
        label="Enter user's email to proceed"
        name="confirmationEmail"
        value={confirmationEmail}
        onChange={(event) => {
          event.preventDefault();
          const value = event.target.value;
          setConfirmationEmail(value);
        }}
        xs={null}
        helperText={email}
      />
    </ConfirmationDialog>
  );
};

DeleteUserConfirmation.propTypes = {
  closeRecord: PropTypes.func,
  on: PropTypes.bool,
  setOff: PropTypes.func,
};

export default DeleteUserConfirmation;
