import { fieldTypes } from "componentGenerators/formGenerator/config";

//Form Groups
const fromToCurrent = [
  {
    name: "from_date",
    type: fieldTypes.date,
    label: "From",
    xs: 4,
  },
  {
    name: "to_date",
    type: fieldTypes.dependantDatePicker,
    label: "To",
    xs: 4,
  },
  {
    name: "is_current",
    type: fieldTypes.checkBox,
    label: "Is current",
    xs: 4,
  },
];

const addressLookupAndFields = ({
  label = "Business Address",
  fields = {
    address: "business_address",
  },
  name = "business_address",
  divider = true,
}) => [
  {
    type: fieldTypes.subHeader,
    divider,
    label,
  },
  {
    type: fieldTypes.googleLookup,
    fields,
  },
  {
    type: fieldTypes.addressFields,
    name,
  },
];

const firstMiddleLast = ({ required }) => [
  {
    type: fieldTypes.text,
    label: "First Name",
    name: "first_name",
    xs: 12,
    md: 4,
    required: required,
  },
  {
    type: fieldTypes.text,
    label: "Last Name",
    name: "last_name",
    xs: 12,
    md: 4,
    required: required,
  },
  {
    type: fieldTypes.gridItem,
    xs: 4,
  },
  {
    type: fieldTypes.text,
    label: "Middle Name",
    name: "middle_name",
    xs: 12,
    md: 4,
  },
  {
    type: fieldTypes.text,
    label: "Suffix",
    name: "suffix_name",
    xs: 6,
    md: 2,
  },
  {
    type: fieldTypes.gridItem,
    xs: 4,
  },
];

export const getFormGroup = (params) => ({
  addressLookupAndFields: addressLookupAndFields(params),
  firstMiddleLast: firstMiddleLast(params),
  fromToCurrent,
});
