import { Box } from "@material-ui/core";
import { useFormConstraint } from "context/formConstraint";
import React from "react";
import { InfoAlert } from "../Alert/InfoAlert";

const formatDataForDisplay = (pageFormConstraints) =>
  Object.entries(pageFormConstraints).map((constraint) => ({
    jurisdiction: constraint[1][0],
    notice: constraint[0],
  }));

// This replace the "FormConstraint" component in the new framework
export const JurisdictionNotices = ({ formId }) => {
  const { formConstraint, loading } = useFormConstraint(formId);
  const constraints = formatDataForDisplay(formConstraint[formId] || {});

  if (loading || !constraints.length) return null;

  return (
    <InfoAlert severity="warning">
      {constraints.map(({ jurisdiction, notice }, index) => (
        <Box key={index} pb={index < constraints.length - 1 ? 1 : 0}>
          {jurisdiction} {notice}
        </Box>
      ))}
    </InfoAlert>
  );
};
