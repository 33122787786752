import {
  Box,
  CircularProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import AttachmentIcon from "@material-ui/icons/Attachment";
import Center from "components/flex/Center";
import React, { useState } from "react";
import { UploadedFilesList } from "../../Pages/LicensingControl/EmployeeFileUpload/styles";
import useFileLoad from "components/Common/DropZoneEmail/useFileLoad";
import { DropArea } from "../Dropzone/DropZone";

const AttachmentLink = ({ name, dense = false }) => {
  return (
    <ListItem dense={dense}>
      <ListItemIcon>
        <AttachmentIcon />
      </ListItemIcon>
      <ListItemText primary={name} />
    </ListItem>
  );
};

const DropZone = ({ compact = true, accept, files, setFiles }) => {
  const [loading, setLoading] = useState(false);

  const { onDrop } = useFileLoad({
    files,
    setFiles,
    setLoading,
  });

  return (
    <>
      <Box pl={1} pr={1} style={{ width: "100%" }}>
        <DropArea onDrop={onDrop} compact={compact} accept={accept} />
      </Box>
      {loading && (
        <Center>
          <CircularProgress />
        </Center>
      )}
      {!loading && (
        <Box pt={2} style={{ width: "100%" }}>
          {files.length > 0 && (
            <>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                gutterBottom
              >
                Uploaded Files:
              </Typography>
              <UploadedFilesList pt={2}>
                {files.map(({ name }, i) => (
                  <Box pb={2} key={i}>
                    <AttachmentLink
                      key={name}
                      name={name}
                      updated_at={new Date()}
                    />
                  </Box>
                ))}
              </UploadedFilesList>
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default DropZone;
