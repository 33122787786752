import React, { useState } from "react";
import { Select, InputLabel, FormControl, MenuItem } from "@material-ui/core";
import {
  usePdfDispatch,
  usePdfState,
  ACTIONS,
} from "components/DevTools/PdfTools/Context/PdfToolsProvider.jsx";

export default function ShortDate() {
  const dispatch = usePdfDispatch();
  const { column } = usePdfState();
  const [shortDate, setShortDate] = useState("");
  const handleChange = (e) => {
    dispatch({ type: ACTIONS.SET_STATE, value: e.target.value, name: "token" });
    setShortDate(e.target.value);
  };

  if (column && column.includes("date")) {
    return (
      <FormControl
        variant="filled"
        style={{ width: "150px", marginRight: "10px" }}
      >
        <InputLabel>Format Date</InputLabel>
        <Select value={shortDate} onChange={handleChange}>
          {[
            { format: "Month and Year", token: "$" },
            { format: "Month", token: "%" },
            { format: "Year", token: "!" },
            { format: "Day", token: "?date_day" },
            { format: "Month Name", token: "?date_name_month" },
            { format: "Day Name", token: "?date_name_day" },
            { format: "full date", token: "" },
          ].map((val) => (
            <MenuItem key={val.token} value={val.token}>
              {val.format}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else {
    return <></>;
  }
}
