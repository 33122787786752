import { formats } from "components/Common/Tables/Common/helpers";

export const criminalColumns = [
  { title: "Party Name", field: "related_party_name" },
  { field: "nature_of_charge", format: formats.startCase },
  {
    title: "Charge Date",
    field: "charge_date",
    format: formats.date,
  },
  {
    title: "Agency or Court",
    field: "law_enforcement_name",
  },
  {
    title: "Agency or Court Address",
    field: "law_enforcement_full_address",
  },
  {
    title: "Disposition",
    field: "criminal_disposition",
  },
  {
    field: "sentence",
  },
];
export const antitrustColumns = [
  { title: "Party Name", field: "related_party_name" },
 { field: "nature_of_charge", format: formats.startCase },
  {
    title: "Charge Date",
    field: "charge_date",
    format: formats.date,
  },
  {
    field: "docket_number",
  },
  {
    title: "Agency or Court",
    field: "law_enforcement_name",
  },
  {
    title: "Agency or Court Address",
    field: "law_enforcement_full_address",
  },
  {
    title: "Disposition",
    field: "criminal_disposition",
  },
];
export const litigationsColumns = [
  { title: "Party Name", field: "related_party_name" },
  { field: "nature_of_charge", format: formats.startCase },
  {
    title: "Charge Date",
    field: "charge_date",
    format: formats.date,
  },
  {
    field: "docket_number",
  },
  {
    title: "Agency or Court",
    field: "law_enforcement_name",
  },
  {
    title: "Agency or Court Address",
    field: "law_enforcement_full_address",
  },
  {
    title: "Disposition",
    field: "criminal_disposition",
  },
];
export const testimonyColumns = [
  { title: "Party Name", field: "related_party_name" },
  { field: "nature_of_charge", format: formats.startCase },
  {
    title: "Charge Date",
    field: "charge_date",
    format: formats.date,
  },
  {
    field: "docket_number",
  },
  {
    title: "Agency or Court",
    field: "law_enforcement_name",
  },
  {
    title: "Agency or Court Address",
    field: "law_enforcement_full_address",
  },
  {
    title: "Disposition",
    field: "criminal_disposition",
  },
];

export const criminalTableQuestion =
  "Has the business, or any of its officers, directors, managers, general partners, trustees, or key personnel ever been arrested, indicted, charged with or convicted of any crime or offense, or been a party to or named as an unindicted co-conspirator in any criminal proceeding, in the State of Tennessee or any other jurisdiction?";
export const antitrustTableQuestion =
  "In the past 10 years, has the business or any of its officers, directors, managers, general partners, trustees, or key personnel had a judgment, order, consent decree, or consent order pertaining to a violation, or alleged violation, of any state, local, or federal statute, regulation, or code (or foreign equivalent) that resulted in a penalty or fine? If you answered";
export const litigationsTableQuestion =
  "Provide information describing all civil litigation at equity or law that is active, current, or filed within the last three (3) years to which the business, its parent, holding, intermediary, or any subsidiary (whether or not wholly owned) company or any owner, officer, director, manager, general partner, trustee, or key personnel in their official capacity as a representative of the business is a party in any jurisdiction. You do not need to include cases in the ordinary course of business for monetary damages in controversy of less than $100,000 or claims in the ordinary course of business that are expected by be fully and completelycovered under an insurance carrier. Attach additional page(s) as necessary.";
export const testimonyTableQuestion =
  "Provide information describing all disciplinary actions; government or law enforcement subpoenas; nonroutine government investigations or audits; cease and desist letters; attorney general or government legal opinions currently or within the past ten (10) years against the business, its parent, holding, intermediary, or any subsidiary (whether or not wholly owned) company in any jurisdiction. Attach additional page(s) as necessary.";

export const criminalEndpoint = "views/bd_legal_histories_a?type=criminal";
export const antitrustEndpoint = "views/bd_legal_histories_a?type=antitrust&type=trade_judgement";
export const litigationsEndpoint = "views/bd_criminal_litigations?disposition_date__past_years=3";
export const testimonyEndpoint = "views/bd_legal_histories?type=testimony&type=investigation&testimony_date__past_years=10";
