// PENNSYLVANIA
import PennsylvaniaDisclosureQuestions from "components/Pages/Supplemental/PD/Pennsylvania/PennsylvaniaDisclosureQuestions/PennsylvaniaDisclosureQuestions";
import PennsylvaniaFinancialInterest from "components/Pages/Supplemental/PD/Pennsylvania/PennsylvaniaFinancialInterest/PennsylvaniaFinancialInterest";
import PennsylvaniaPublicOfficial from "components/Pages/Supplemental/PD/Pennsylvania/PennsylvaniaPublicOfficial/PennsylvaniaPublicOfficial";
import PennsylvaniaTaxInformation from "components/Pages/Supplemental/PD/Pennsylvania/PennsylvaniaTaxInformation/PennsylvaniaTaxInformation";

const pennsylvania_pages = {
  pennsylvania_disclosure_questions: PennsylvaniaDisclosureQuestions,
  pennsylvania_gaming_financial_interest: PennsylvaniaFinancialInterest,
  pennsylvania_public_official: PennsylvaniaPublicOfficial,
  pennsylvania_tax_information: PennsylvaniaTaxInformation,
};

export default pennsylvania_pages;
