import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  Divider,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { FormikDialog } from "components/Formik/formContainers/FormikDialog";
import useToggle from "hooks/useToggle";
import PropTypes from "prop-types";
import React from "react";
import { validationSchema } from "components/Pages/LicensingControl/Business/config.js";
import BusinessesForm from "components/Pages/LicensingControl/Business/BusinessForm.js";
import { TabContentContainer } from "components/Pages/LicensingControl/components/DetailsDialog/Modal.styles";
import { BusinessLicenses } from "./BusinessLicenses";

const LabeledText = ({ label, children }) => (
  <>
    <Typography variant="overline" color="textSecondary">
      {label}
    </Typography>
    <Typography variant="body1">
      <Box fontWeight="fontWeightMedium">{children}</Box>
    </Typography>
  </>
);

LabeledText.propTypes = {
  children: PropTypes.node,
  label: PropTypes.node,
};

const getFormattedBusinessData = ({ id, notification_emails }) => {
  return [
    { label: "Business ID:", value: id },
    { label: "Contact Email(s):", value: notification_emails },
  ];
};

const BusinessInfo = ({ businessData, openEditDialog }) => {
  const formattedData = React.useMemo(
    () => getFormattedBusinessData(businessData),
    [businessData]
  );

  return (
    <Box display="flex" justifyContent="space-between">
      <Box pt={1}>
        <Grid container spacing={2}>
          {formattedData.map(({ label, value }) => {
            if (Array.isArray(value)) {
              return (
                <Grid item key="label" xs={12}>
                  <LabeledText label={label}>
                    {value.length > 0
                      ? value.map((item) => {
                          return <LabeledText key={item}>{item}</LabeledText>;
                        })
                      : "N/A"}
                  </LabeledText>
                </Grid>
              );
            } else {
              return (
                <Grid item key="label" xs={12}>
                  <LabeledText label={label}>{value || "N/A"}</LabeledText>
                </Grid>
              );
            }
          })}
        </Grid>
      </Box>
      <Box>
        <IconButton color="primary" disabled>
          <EditIcon size="small" onClick={openEditDialog} />
        </IconButton>
      </Box>
    </Box>
  );
};

BusinessInfo.propTypes = {
  businessData: PropTypes.object,
  openEditDialog: PropTypes.func,
};

export const InfoTab = ({
  data,
  formikProps,
  licensesFormInitiallyOpen,
  id,
}) => {
  const addDialog = useToggle();

  return (
    <>
      <FormikDialog
        title="Edit Business"
        open={addDialog.on}
        onCancel={addDialog.setOff}
        {...formikProps}
        validationSchema={validationSchema}
      >
        <BusinessesForm />
      </FormikDialog>
      <TabContentContainer>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box width={1} mb={3} p={1} maxWidth={0.9}>
            <Card variant="outlined">
              <CardContent>
                <BusinessInfo
                  businessData={data}
                  openEditDialog={addDialog.setOn}
                />
              </CardContent>
            </Card>
          </Box>
          <Box mt={2} mb={5} width={1}>
            <Divider variant="middle" />
          </Box>
          <Box maxWidth={0.9} minWidth={0.9}>
            <BusinessLicenses
              id={id}
              licensesData={data.license}
              licensesFormInitiallyOpen={licensesFormInitiallyOpen}
            />
          </Box>
        </Box>
      </TabContentContainer>
    </>
  );
};

InfoTab.propTypes = {
  businessData: PropTypes.object,
  employmentFormikProps: PropTypes.object,
  data: PropTypes.object,
};
