import { formats } from "components/Common/Tables/Common/helpers";
import { fieldTypes } from "componentGenerators/formGenerator/config";

const gamingLicensesColumns = [
  {
    title: "From",
    field: "from_date",
    format: formats.date,
  },
  {
    title: "To",
    field: "to_date",
    format: formats.date,
  },
  {
    title: "Licensing Agency",
    field: "license_agency_pd_business_entity.business_name",
  },
  {
    title: "Industry",
    field: "type",
    format: "startCase",
  },
  {
    title: "Number",
    field: "license_number",
  },
  {
    field: "disposition",
    format: "startCase",
  },
  {
    title: "Business",
    field: "pd_business_entity.business_name",
  },
];

const gamingLicensesFormSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Entity Associated With License",
  },
  {
    type: fieldTypes.pdBusinessSelect,
    label: "Business Entity (If Applicable)",
    required: true,
    name: "pd_business_entity_id",
    xs: 8,
  },
  {
    type: fieldTypes.subHeader,
    divider: true,
    label: "Licensing Agency",
  },
  {
    type: fieldTypes.pdLawEnforcementSelect,
    label: "Agency",
    getOptionLabel: (option) => option.business_name,
    getOptionValue: (option) => option.id,
    xs: 8,
    required: true,
    name: "license_agency_pd_business_entity_id",
  },
  {
    type: fieldTypes.subHeader,
    divider: true,
    label: "License Dates",
  },
  {
    name: "application_date",
    type: fieldTypes.date,
    label: "Application Date",
    required: true,
  },
  {
    name: "from_date",
    type: fieldTypes.date,
    label: "From",
  },
  {
    name: "to_date",
    type: fieldTypes.dependantDatePicker,
    label: "To",
  },
  {
    type: fieldTypes.subHeader,
    divider: true,
    label: "License Details",
  },
  {
    name: "disposition",
    type: fieldTypes.select,
    dynamicMenuItems: ["licenseDispositionType"],
    required: true,
  },
  {
    type: fieldTypes.text,
    label: "Type of License",
    name: "license_type",
    required: true,
  },
  {
    type: fieldTypes.text,
    label: "License Number",
    name: "license_number",
    required: true,
  },
  {
    type: fieldTypes.text,
    label: "Disposition Reason (if applicable)",
    name: "disposition_reason",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.subHeader,
    divider: true,
    label: "License Conditions",
  },
  {
    type: fieldTypes.label,
    label:
      "Has this license ever been denied, suspended, revoked, withdrawn or subject to any conditions in any jurisdiction?",
  },
  {
    name: "condition_type",
    type: fieldTypes.select,
    dynamicMenuItems: ["licenseConditionType"],
  },
  {
    name: "condition_date",
    type: fieldTypes.date,
    label: "Condition Date",
  },
  {
    type: fieldTypes.text,
    label: "Condition Reason",
    name: "condition_reason",
    rows: 4,
    xs: 12,
  },
];

const gamingLicensesSchema = {
  type: "table",
  endpoint: "michigan_isbigo_supplement_gaming_licenses_licenseGaming",
  formSchema: gamingLicensesFormSchema,
  columns: gamingLicensesColumns,
  infoAlerts: [
    {
      body:
        "Please provide below all your gaming licenses information, whether or not the license was ever issued. Provide all details if the gaming license was denied, suspended or revoked.",
    },
  ],

  modifySubmissionValues: (formValues) => ({
    ...formValues,
    type: "gaming",
    owner_type: "personal",
  }),
};

export default [gamingLicensesSchema];
