export const defaultInitialValues = {

  illinois: {
    pd_q20_c_boolean: null,
    pd_q20_c_details: "",
    pd_q20_d_boolean: null,
    pd_q20_d_details: "",
    pd_q20_e_boolean: null,
    pd_q20_e_details: "",
    pd_q20_f_boolean: null,
    pd_q20_f_date: null,
    pd_q20_f_location: "",
    pd_q20_f_details: "",
    pd_q20_j_details: "",
  }
};
