import React from "react";
import { Grid } from "@material-ui/core";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import useForm from "hooks/useForm";
import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import useEndPoint from "hooks/useEndpoint";

const IowaUniformCommercialCodeFilingForm = () => {
  return (
    <Grid>
      <SectionSubHeader label="Uniform Commercial Code Filings" divider />
      <SectionLabel
        label="Provide a current list of locations and account numbers for all Uniform
        Commercial Code filling."
      />
      <FormikFileDropzone
        description="Uniform Commercial Code File"
        name="uniform_commercial_code_filing_attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};

const IowaUniformCommercialCodeFiling = () => {
  const { bdBusinessEntityEndpoint } = useEndPoint("");
  const formData = useForm({
    defaultInitialValues: {
      uniform_commercial_code_filing_attachment_group_id: null,
    },
    endpoint: bdBusinessEntityEndpoint,
  });

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage>
        <IowaUniformCommercialCodeFilingForm />
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};
export default IowaUniformCommercialCodeFiling;
