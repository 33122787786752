import { Grid, Paper, RadioGroup, Typography } from "@material-ui/core";
import FormikRadio from "components/Formik/fields/FormikRadio";
import { Form, Formik } from "formik";
import useFetch from "queryHooks/useFetch";
import React from "react";
import { useDropzoneEndpoints } from "components/Common/FileDropZone/useDropzoneEndpoints";
import { FileDropzone } from "components/Common/FileDropZone/FileDropzone";

export const permissionTypes = [
  {
    id: "submitted",
    name: "Yes",
  },
  {
    id: "",
    name: "No",
  },
];

const SubmissionForm = ({ initialValues, onSubmit }) => (
  <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
    {({ handleSubmit }) => (
      <Form>
        <Typography variant="subtitle1" gutterBottom>
          Have you submitted your application?
        </Typography>
        <RadioGroup row onChange={handleSubmit}>
          {permissionTypes.map(({ id, name }) => (
            <FormikRadio
              key={id}
              name="application_status"
              value={id.toString()}
              label={name}
            />
          ))}
        </RadioGroup>
      </Form>
    )}
  </Formik>
);

export const FinalSubmission = ({
  mutateGeneratedPdf,
  applicationStatus,
  submitted_documents_attachment_group_id,
}) => {
  const onAttachmentsUploaded = (id) => {
    mutateGeneratedPdf({ submitted_documents_attachment_group_id: id });
  };

  const onSubmitSubmissionStatus = (values) => {
    mutateGeneratedPdf(values);
  };

  const { data } = useFetch(
    ["finalAttachments", submitted_documents_attachment_group_id],
    `attachmentGroup/${submitted_documents_attachment_group_id}`,
    { enabled: !!submitted_documents_attachment_group_id }
  );
  const attachments = data?.[0].attachments || [];

  const { getAttachmentEndpoints, attachmentGroupEndpoint } =
    useDropzoneEndpoints();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="subtitle1" gutterBottom>
          Final Application Upload:
        </Typography>
        <Paper variant="outlined" style={{ padding: "20px" }}>
          <Typography variant="subtitle2">
            Please upload your final signed and notarized copy of your
            submission that has been sent to the regulatory body. Please also
            included any additional documents that may have been requested by
            the regulatory body.
          </Typography>
          <FileDropzone
            attachmentList={attachments}
            setAttachmentGroupID={onAttachmentsUploaded}
            attachmentGroupID={submitted_documents_attachment_group_id}
            description={"Final Submission"}
            getAttachmentEndpoints={getAttachmentEndpoints}
            attachmentGroupEndpoint={attachmentGroupEndpoint}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <SubmissionForm
          initialValues={{ application_status: applicationStatus }}
          onSubmit={onSubmitSubmissionStatus}
        />
      </Grid>
    </Grid>
  );
};
