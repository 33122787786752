import React from "react";
import SaveButton from "../../Common/CustomButtons/SaveButton";
import { Box } from "@material-ui/core";

const checkAllFormsValid = (formRefs) =>
  !formRefs.current.some((formRef) => formRef.current.isValid === false);

export const useMultiFormSubmit = (refCount) => {
  const formRefs = React.useRef([]);

  if (formRefs.current.length !== refCount) {
    formRefs.current = Array(refCount)
      .fill()
      .map((_, i) => formRefs.current[i] || React.createRef());
  }

  const handleSubmit = () => {
    if (checkAllFormsValid(formRefs)) {
      formRefs.current.forEach((formRef) => {
        if (formRef.current) {
          formRef.current.handleSubmit();
        }
      });
    }
  };

  return { handleSubmit, formRefs: formRefs.current };
};

const MultiFormWrapper = ({ children }) => {
  const { handleSubmit, formRefs } = useMultiFormSubmit(children.length);
  return (
    <>
      {React.Children.map(children, (child, idx) => {
        return React.cloneElement(child, {
          ...child.props,
          innerRef: formRefs[idx],
        });
      })}
      <Box pt={1} pb={2}>
        <SaveButton onClick={handleSubmit}>Save</SaveButton>
      </Box>
    </>
  );
};

export default MultiFormWrapper;
