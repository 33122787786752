import React from "react";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import styled from "styled-components";
import { Box } from "@material-ui/core";
import PermissionTree from "./PermissionTree";

const StyledTreeViewContainer = styled(TreeView)`
  flex-grow: 1;
  max-height: 660px;
  overflow-y: auto;
  padding-top: 0px;
`;

export default function Permission({ state, dispatch }) {
  return (
    <Box>
      <StyledTreeViewContainer
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        <PermissionTree state={state} dispatch={dispatch} />
      </StyledTreeViewContainer>
    </Box>
  );
}
