import { Box, Grid } from "@material-ui/core";

import React from "react";
import useFetch from "queryHooks/useFetch.js";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import DisplayTable from "components/Common/Tables/DisplayTable/index.js";
import { defaultInitialValues } from "./config";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import YesNo from "components/Formik/formGroups/YesNo";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import useForm from "hooks/useForm";
import CustomLink from "components/Common/Link";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const IllinoisLicenseAndGamingHistoryForm = () => {
  return (
    <Grid container spacing={2}>
      <YesNo
        label={`Has had, or been affiliated with, any license, permit, or certification that has been subject to denial, non-renewal, suspension, revocation, fine, or any regulatory action, or criminal proceeding, in any jurisdiction?`}
        name="illinois.pd_q10_b_boolean"
      />
      <FormikTextField
        label="If yes, provide details."
        name="illinois.pd_q10_b_details"
        multiline
        xs={12}
        rows={5}
      />
      <SectionSubHeader label="" divider />
      <YesNo
        label={`Has withdrawn an application for any license, permit or certification in any jurisdiction?`}
        name="illinois.pd_q10_c_boolean"
      />
      <FormikTextField
        label="If yes, provide details."
        name="illinois.pd_q10_c_details"
        multiline
        xs={12}
        rows={5}
      />
      <SectionSubHeader label="" divider />
      <YesNo
        label={`Have you ever been involved in a gambling related business in any state, country, or other jurisdiction, though never issued a license or permit?`}
        name="illinois.pd_q10_d_boolean"
      />
      <FormikTextField
        label="If yes, provide details."
        name="illinois.pd_q10_d_details"
        multiline
        xs={12}
        rows={5}
      />
      <SectionSubHeader label="" divider />
      <YesNo
        label={`Have you ever owned, sold, operated, leased, provided for use, facilitated, enabled, participated in, or been alleged by any governmental agency of, the use of coin-operated amusement devices for gambling purposes?`}
        name="illinois.pd_q10_e_boolean"
      />
      <FormikTextField
        label="If yes, provide complete details, including dates for all such activities"
        name="illinois.pd_q10_e_details"
        multiline
        xs={12}
        rows={5}
      />
      <SectionSubHeader label="" divider />
      <YesNo
        label={`Have you ever owned, sold, operated, leased, provided for use, facilitated, enabled, participated in, or been alleged by any governmental agency of, the use of any type of coupon or sweepstakes machine?`}
        name="illinois.pd_q10_f_boolean"
      />
      <FormikTextField
        label="If yes, provide complete details, including dates for all such activities."
        name="illinois.pd_q10_f_details"
        multiline
        xs={12}
        rows={5}
      />
      <SectionSubHeader label="" divider />
      <YesNo
        label={`Have you ever been the subject of any order, judgment, or decree of any court or any order of an administrative agency, board, or body prohibiting you from, or otherwise limiting your type of business, practice or authority?`}
        name="illinois.pd_q10_h_boolean"
      />
      <FormikTextField
        label="If yes, provide details."
        name="illinois.pd_q10_h_details"
        multiline
        xs={12}
        rows={5}
      />
      <SectionSubHeader label="" divider />
      <YesNo
        label={
          <>
            Do any of your Relatives have any other interest or affiliation of
            any kind with a Business Entity engaged in a Gaming Operation or in
            the Alcoholic Beverage industry which has not been previously
            disclosed? If yes, add details in the
            <CustomLink to="family_ownership" />
            section.
          </>
        }
        name="illinois.pd_q36_boolean"
      />
    </Grid>
  );
};

const IllinoisLicenseAndGamingHistory = ({ name }) => {
  const formData = useForm({
    noValidate: true,
    defaultInitialValues,
    endpoint: "illinois_license_and_gaming_history_pdSupplement",
  });
  const { data = [], status } = useFetch(
    "illinois_license_and_gaming_history_associated_business_entities_relative_gaming_or_alcohol"
  );

  return (
    <FormSectionWrapper title={name}>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={status}>
          <DisplayTable
            records={data}
            linkTo={["family_ownership"]}
            columns={[
              { title: "Business Name", field: "business_name" },
              { title: "Relative", field: "person_name" },
              {
                title: "Association or Employment",
                field: "association_description_pretty",
              },
            ]}
            dependsOnQueryCount="Do you have any relatives either associated with or employed in the gaming or liquor industry?"
          />
        </LoadingComponent>
      </Box>
      <FormContextProvider formData={formData}>
        <EnhancedFormikPage>
          <IllinoisLicenseAndGamingHistoryForm />
        </EnhancedFormikPage>
      </FormContextProvider>
    </FormSectionWrapper>
  );
};

export default IllinoisLicenseAndGamingHistory;
