import { fieldTypes } from "componentGenerators/formGenerator/config";
import { NumberFormatTypes } from "components/Formik/fields/FormikNumberField";

const formSchema = [
  {
    type: fieldTypes.label,
    label: "Amount to be invested in business:",
  },
  {
    type: fieldTypes.number,
    label: "Amount",
    name: "colorado_kapa.amount_invested",
  },
  {
    type: fieldTypes.label,
    label: "Percentage of ownership this amount represent:",
  },
  {
    type: fieldTypes.number,
    label: "Ownership Percentage",
    name: "colorado_kapa.ownership_percentage",
    format: NumberFormatTypes.percent,
  },
  {
    type: fieldTypes.label,
    label: "Investment will be derived from the following sources:",
  },
  {
    type: fieldTypes.text,
    label: "Sources",
    name: "colorado_kapa.investment_sources",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Has your interest in this gaming establishment been assigned, pledged or hypothecated to any person, firm or corporation, or has any agreement been entered into whereby your interest is to be assigned, pledge or sold, either in part or whole?",
    name: "colorado_kapa.has_pledged_interest",
  },
  {
    type: fieldTypes.label,
    label: "If yes, explain below:",
  },
  {
    type: fieldTypes.text,
    label: "Explanation",
    name: "colorado_kapa.pledged_interest_details",
    xs: 12,
    rows: 4,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "colorado_kapa_supplement_associated_person_only_pdSupplement",
};
