import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import FormikErrorScroll from "../other/FormikErrorScroll";
import withFormContext from "../../../hocs/withFormContext";
import FormikDebugger from "components/Formik/other/FormikDebugger";
import PropTypes from "prop-types";
import SaveButton from "components/Common/CustomButtons/SaveButton";
import { PromptIfDirty } from "components/Formik/other/PromptIfDirty";
import SentryErrorBoundary from "error/SentryErrorBoundary";

export const FormikPage = ({
  children,
  title,
  subheader,
  permission,
  showSaveButton = true,
  customButton = false,
  ...rest
}) => {
  showSaveButton = !showSaveButton ? showSaveButton : permission !== "read";
  return (
    <SentryErrorBoundary>
      <Box pb={1}>
        <Card variant="outlined">
          <CardContent>
            {title && (
              <Box pb={2}>
                <Typography variant="h2">{title}</Typography>
              </Box>
            )}
            {subheader && <Box pb={2}>{subheader}</Box>}

            <Formik enableReinitialize {...rest}>
              {({ submitForm, isSubmitting }) => (
                <Form>
                  <FormikDebugger />
                  <FormikErrorScroll />
                  <PromptIfDirty />
                  {children}
                  <Box pt={1}>
                    {customButton
                      ? customButton(isSubmitting)
                      : showSaveButton && (
                          <SaveButton
                            disabled={isSubmitting}
                            onClick={submitForm}
                          >
                            Save
                          </SaveButton>
                        )}
                  </Box>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </SentryErrorBoundary>
  );
};

FormikPage.propTypes = {
  children: PropTypes.node,
  showSaveButton: PropTypes.bool,
  subheader: PropTypes.node,
  title: PropTypes.string,
  permission: PropTypes.oneOf(["write", "read", "noAccess"]),
};

export const EnhancedFormikPage = withFormContext(FormikPage);
