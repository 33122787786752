import { ListItemIcon, ListItemText } from "@material-ui/core";
import React from "react";
import {
  NumberIcon,
  StyledList,
  StyledListItem,
} from "./GettingStarted.styles";

const gettingStartedOptions = [
  "Personal Details",
  "Generate an Application",
  "Finish your Profile",
];

export const GettingStartedNav = ({ handleChangeItem, selectedItem }) => (
  <StyledList>
    {gettingStartedOptions.map((option, index) => (
      <StyledListItem
        key={option}
        button
        selected={selectedItem === index}
        onClick={() => handleChangeItem(index)}
      >
        <ListItemIcon>
          <NumberIcon>{index + 1}</NumberIcon>
        </ListItemIcon>

        <ListItemText primary={option} />
      </StyledListItem>
    ))}
  </StyledList>
);
