import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { DefaultInput } from "./DefaultInput";
import { DefaultOption } from "./DefaultOption";
import withFieldTools from "hocs/withFieldTools";
import { Box } from "@material-ui/core";

export const ControlledAutocompleteBase = ({
  value,
  onChange,
  filterOptions,
  getOptionLabel,
  options,
  loading,
  onEditOption,
  label,
  size = "small",
  fullWidth = true,
  inputRef = null,
  meta,
  helperText,
  optionIcon,
  getSecondaryLabel,
  getOptionIcon,
  disableEdit,
  ...rest
}) => {
  const errorText = meta?.touched && meta?.error;
  return (
    <Box width={1} pt={1}>
      <Autocomplete
        onFocus={(e) => {
          //prevents browser autocomplete
          if (e.target.autocomplete) {
            e.target.autocomplete = null;
          }
        }}
        fullWidth={fullWidth}
        loading={loading}
        size={size}
        value={value}
        onChange={onChange}
        filterOptions={filterOptions}
        options={loading ? [] : options}
        getOptionLabel={getOptionLabel}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(option, autoCompleteState) => (
          <DefaultOption
            {...autoCompleteState}
            icon={getOptionIcon ? getOptionIcon(option) : optionIcon}
            option={option}
            onEditOption={disableEdit(option) && onEditOption}
            getOptionLabel={getOptionLabel}
            getSecondaryLabel={getSecondaryLabel}
          />
        )}
        renderInput={(params) => (
          <DefaultInput
            params={params}
            loading={loading}
            label={label}
            inputRef={inputRef}
            error={!!errorText}
            helperText={errorText || helperText}
            required={rest.required}
          />
        )}
        {...rest}
      />
    </Box>
  );
};

ControlledAutocompleteBase.propTypes = {
  filterOptions: PropTypes.func,
  optionIcon: PropTypes.node,
  getOptionLabel: PropTypes.func,
  getSecondaryLabel: PropTypes.func,
  onChange: PropTypes.func,
  disableEdit: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.any,
  loading: PropTypes.bool,
  onEditOption: PropTypes.func,
  label: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium"]),
  fullWidth: PropTypes.bool,
  helperText: PropTypes.node,
  getOptionIcon: PropTypes.func,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Component) }),
  ]),
};

const EnhancedControlledAutocomplete = withFieldTools(
  ControlledAutocompleteBase
);

const ControlledAutocomplete = (props) => (
  <EnhancedControlledAutocomplete xs={6} {...props} />
);

export default ControlledAutocomplete;
