import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import * as Sentry from "@sentry/react";
import { useField } from "formik";
import useEndPoint from "hooks/useEndpoint";
import useGet from "hooks/useGet";
import useInitialValues from "hooks/useInitialValues";
import usePatch from "queryHooks/usePatch";
import usePost from "queryHooks/usePost";
import React from "react";
import useErrorSnackBar from "hooks/useErrorSnackbar";
import { formRecordReducer, formActions } from "../helpers";

const filter = createFilterOptions();

const getInitialValue = (options, fieldValue, getOptionValue) => {
  return (
    options.find((option) => getOptionValue(option) === fieldValue) ?? null
  );
};

const useSubmission = ({
  endpoint,
  onCancel,
  formRecord: { isEditing, formValues },
  updateSelectedValue,
  postValues,
  patchValues,
  queryKey,
  getOptionValue,
}) => {
  const { noAttributeEndpoint } = useEndPoint(endpoint);

  const { handleErrorMessage } = useErrorSnackBar();

  const onSuccess = (values) => {
    updateSelectedValue(values);
    onCancel();
    return values;
  };

  const onError = (error) => {
    Sentry.captureException(error);
    handleErrorMessage(error);
    throw new Error("Request Failed");
  };

  const { mutateAsync: patchMutation } = usePatch(
    queryKey,
    noAttributeEndpoint,
    {
      onSuccess,
      onError,
    }
  );

  const { mutateAsync: postMutation } = usePost(queryKey, noAttributeEndpoint, {
    onSuccess,
    onError,
  });

  const shapePayload = (values) => {
    return isEditing
      ? patchValues({ id: getOptionValue(formValues), ...values })
      : postValues(values);
  };

  const onSubmit = async (values, { resetForm }) => {
    const queryFunction = isEditing ? patchMutation : postMutation;

    await queryFunction(shapePayload(values))
      .then(() => resetForm())
      .catch((error) => console.error(error));
  };

  return onSubmit;
};

export const useCrudSelect = ({
  name,
  endpoint,
  saveEndpoint,
  defaultInitialValues,
  fetchOptions,
  getOptionValue = ({ id }) => id,
  autoSelectFirstOption = false,
  getOptionLabel = ({ name }) => {
    return name || "";
  },
  postValues = (values) => values,
  patchValues = (values) => values,
  initialDropDownValue = null,
  queryKey: queryKeyParam,
  handleAddNewOverride,
  addEnabled = true,
}) => {
  const [dropDownValue, setDropDownValue] =
    React.useState(initialDropDownValue);
  const [formRecord, dispatch] = React.useReducer(formRecordReducer, {
    formValues: defaultInitialValues,
    isEditing: false,
    open: false,
  });

  const [field, meta, helpers] = useField(name);

  const queryKey = queryKeyParam ? queryKeyParam : [endpoint, "options"];

  const { data, status } = useGet(endpoint, queryKey, {
    ...fetchOptions,
    onSuccess: (options) => {
      fetchOptions?.onSuccess?.() && fetchOptions.onSuccess();

      return setDropDownValue(
        getInitialValue(options, field.value, getOptionValue)
      );
    },
  });

  const onCancel = () => {
    dispatch({ type: formActions.RESET, formValues: defaultInitialValues });
  };

  const updateSelectedValue = React.useCallback(
    (newValue) => {
      helpers.setValue(newValue ? getOptionValue(newValue) : null);
      setDropDownValue(newValue);
    },
    [helpers, getOptionValue]
  );

  const isInitialOptionSet = React.useRef(false);

  React.useEffect(() => {
    if (!autoSelectFirstOption || isInitialOptionSet.current) return;
    if (data?.length === 1) {
      isInitialOptionSet.current = true;
      updateSelectedValue(data[0]);
    }
  }, [status, data, updateSelectedValue, autoSelectFirstOption]);

  React.useEffect(() => {
    if (field.value && meta.error) {
      helpers.setError(null);
    }
  }, [field, meta, helpers]);

  const onSubmit = useSubmission({
    endpoint: saveEndpoint || endpoint,
    onCancel,
    formRecord,
    updateSelectedValue,
    postValues,
    patchValues,
    queryKey,
    getOptionValue,
  });

  const onChange = (event, newValue) => {
    helpers.setTouched(true);
    if (newValue && newValue.addNew) {
      if (handleAddNewOverride) handleAddNewOverride(dispatch);
      else dispatch({ type: formActions.ADD_RECORD });
    } else {
      updateSelectedValue(newValue);
    }
  };

  const onEditOption = (option) => {
    dispatch({ type: formActions.EDIT_RECORD, formValues: option });
  };

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);

    if (addEnabled) {
      filtered.unshift({
        addNew: true,
        name: "",
      });
    }

    return filtered;
  };

  const getOptionLabelOverride = (option) => {
    // value selected with enter, right from the input
    if (typeof option === "string") {
      return option;
    }
    if (option.inputValue) {
      return option.inputValue;
    }
    return getOptionLabel(option);
  };

  const initialValues = useInitialValues(
    formRecord.formValues,
    defaultInitialValues
  );

  const fieldProps = {
    value: dropDownValue,
    options: data ? data : [],
    loading: status === "loading",
    meta,
    onEditOption,
    onChange,
    filterOptions,
    getOptionLabel: getOptionLabelOverride,
  };

  const formProps = {
    open: formRecord.open,
    initialValues,
    onCancel,
    onSubmit,
    maxWidth: "sm",
  };

  return {
    status: status,
    fieldProps,
    formProps,
    setDropDownValue,
  };
};
