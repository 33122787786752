import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikSelect from "components/Formik/fields/FormikSelect";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import { SectionLabel } from "components/Common/LayoutComponents";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import {
  columns,
  defaultInitialValues,
  tableQuestions,
  nonRequired,
} from "./config.js";
import FormikNumberField, {
  NumberFormatTypes,
} from "components/Formik/fields/FormikNumberField";

const TaxReturnsForm = () => {
  const { listOfCountries } = useMenuOptions();
  return (
    <Grid container spacing={2}>
      <FormikNumberField
        label="Effective Year"
        name="effective_year"
        type={NumberFormatTypes.year}
        xs={4}
      />

      <FormikSelect
        label="Country Filed"
        name="country_filed"
        options={listOfCountries}
        xs={8}
      />
      <SectionLabel label="Upload a copy of all forms with any amendments and appropriate schedules." />
      <FormikFileDropzone
        description="Business Tax Returns"
        label="Upload Attachment"
        name="corporate_report_attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};

const TaxReturns = ({ name }) => {
  const formData = useTabularData({
    nonRequired,
    defaultInitialValues,
    endpoint: "bdCorporateReport?report_type=tax_return",
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog maxWidth="sm" title="Details">
        <TaxReturnsForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
    </FormSectionProvider>
  );
};

export default TaxReturns;
