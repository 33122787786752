import { useAuth } from "context/auth";

const useBdIdAddedToAddress = () => {
  const { bd_organization_id } = useAuth();

  const modifySubmittedValues = (values) => {
    const bd_address = {
      ...values?.bd_address,
      bd_organization_id: parseInt(bd_organization_id),
    };

    return { ...values, bd_address };
  };

  return modifySubmittedValues;
};

export default useBdIdAddedToAddress;
