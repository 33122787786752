import Avatar from "@material-ui/core/Avatar";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Popper from "@material-ui/core/Popper";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Divider } from "components/Common/LayoutComponents";
import { useCollaborator } from "context/auth";
import {
  useLoadCollaborators,
  useReloadCollaborators,
} from "context/collaborators";
import React from "react";
import { useHistory } from "react-router-dom";
import { getInitials } from "./helpers";
import { Typography } from "@material-ui/core";
import { stringToColor } from "helpers";
import styled from "styled-components";
import { startCase } from "lodash";

const StyledExpandMore = styled(ExpandMoreIcon)`
  transform: rotate(0deg);
  margin-left: auto;
  transition: ${({ theme }) =>
    theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    })};

  ${({ $open }) =>
    $open &&
    `
    transform: rotate(180deg);
  `}
`;

const AvatarMenuWrapper = styled(Box)`
  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[200]};
    cursor: pointer;
    border-radius: 5px;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: "10px",
    fontSize: 12,
  },
  menuItem: {
    borderBottom: "1px solid #E89819",
    "&:last-child": {
      borderBottom: "0px solid #E89819",
    },
  },
  menuWrapper: {
    paddingBottom: "0px",
    paddingTop: "0px",
  },
}));

const CollaboratorOption = ({ handleSelect, option, type, cyData }) => {
  const classes = useStyles();
  const collabName = option?.name;
  return (
    <MenuItem
      cy-data={cyData}
      className={classes.menuItem}
      onClick={() => {
        handleSelect(option, type);
      }}
      key={collabName}
      value={option.value}
    >
      <Avatar
        className={classes.avatar}
        style={{
          backgroundColor: `${stringToColor(collabName)}`,
        }}
      >
        {getInitials(collabName)}
      </Avatar>
      {startCase(collabName)}
    </MenuItem>
  );
};

const AccountTypeList = ({ options, handleSelect, header, cyData }) => {
  return (
    <>
      {options?.length ? (
        <div key={cyData}>
          <MenuHeader>{header}</MenuHeader>
          <div>
            {options.map((option) => {
              return (
                <CollaboratorOption
                  cyData={cyData}
                  key={option.name || option.business_name}
                  option={option}
                  handleSelect={handleSelect}
                  type={option.account_type}
                />
              );
            })}
          </div>
          <Divider />
        </div>
      ) : null}
    </>
  );
};

const CollaboratorMenu = ({ setOpen, open, children, anchorRef }) => {
  const classes = useStyles();
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                autoFocusItem={open}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
                className={classes.menuWrapper}
              >
                <Divider />
                {children}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

const ProfileSelectorButton = ({
  anchorRef,
  handleClick,
  displayName,
  displayCategory,
  open,
}) => (
  <AvatarMenuWrapper
    height="83%"
    pt={0.5}
    pb={0.5}
    pr={1}
    pl={1}
    ml={2}
    display="flex"
    alignItems="center"
    onClick={handleClick}
    ref={anchorRef}
  >
    <Box pt={1}>
      <Avatar
        style={{
          backgroundColor: `${stringToColor(displayName)}`,
          height: "30px",
          width: "30px",
        }}
      >
        <Typography>{getInitials(displayName)}</Typography>
      </Avatar>
    </Box>
    <Box pt={1} pl={2} pr={2} display="flex" flexDirection="column">
      <Typography variant="h4" noWrap>
        {displayName}
      </Typography>
      <Typography variant="overline" component="span" noWrap>
        <Box lineHeight={2} display="flex" fontSize={10}>
          {displayCategory}
        </Box>
      </Typography>
    </Box>
    <StyledExpandMore $open={open} />
  </AvatarMenuWrapper>
);

export default function AccountDropdown({ redirect = true }) {
  const { empOptions, busOptions, collaboratorLoading, showMenu, display } =
    useLoadCollaborators();
  const history = useHistory();
  const updateCollab = useCollaborator();
  const reloadCollab = useReloadCollaborators();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const onSelectCollab = (option, type) => {
    updateCollab(option, type);
    reloadCollab();
    setOpen(false);
    redirect && history.push(`/app/dashboard`);
  };

  if (collaboratorLoading) return <span></span>;

  return (
    <>
      {showMenu ? (
        <>
          <ProfileSelectorButton
            anchorRef={anchorRef}
            handleClick={handleToggle}
            displayName={display?.name || ""}
            displayCategory={display?.category || ""}
            open={open}
          />

          <CollaboratorMenu setOpen={setOpen} open={open} anchorRef={anchorRef}>
            <AccountTypeList
              options={empOptions}
              handleSelect={onSelectCollab}
              header="People"
            />
            <AccountTypeList
              options={busOptions}
              handleSelect={onSelectCollab}
              header="Businesses"
            />
          </CollaboratorMenu>
        </>
      ) : null}
    </>
  );
}

const MenuHeader = ({ children }) => {
  return (
    <div
      style={{
        paddingLeft: "10px",
        textAlign: "left",
        backgroundColor: "#EEE",
      }}
    >
      <b>{children}</b>
    </div>
  );
};
