import { useSnackbar } from "notistack";

const getErrorMessage = ({
  serverErrorType,
  statusText,
  clientErrorMessage,
}) => {
  if (clientErrorMessage) {
    return clientErrorMessage;
  }
  if (serverErrorType === "ForeignKeyViolationError") {
    return "Error: In order to delete this record. You must first remove any related record(s).";
  }
  if (serverErrorType === "ExistingUserError") {
    return "Error: Contact OneComply to turn off Disclosure or Delete User.";
  }
  if (serverErrorType === "UniqueViolationError") {
    return "Error: Record must not use a previously used identifier e.g Name";
  }

  if (serverErrorType === "Forbidden") {
    return "Error: You have no permission to perform this action";
  }

  if (statusText) {
    return `Error: ${statusText}`;
  }

  return "Error";
};

const useErrorSnackBar = () => {
  const { enqueueSnackbar } = useSnackbar();

  const handleErrorMessage = (error, clientErrorMessage = "") => {
    let serverErrorType = "";
    if (error?.response?.data) {
      serverErrorType =
        typeof error?.response?.data === "string"
          ? error.response.data
          : error.response?.data?.name;
    }

    enqueueSnackbar(getErrorMessage({ serverErrorType, clientErrorMessage }), {
      variant: "error",
    });
  };

  return { handleErrorMessage };
};

export default useErrorSnackBar;
