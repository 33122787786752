import { Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import PDBusinessSelect from "components/Formik/formGroups/CrudSelect/selects/PDBusinessSelect.jsx";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <FormikTextField label="Type of Property" name="property_type" xs={4} />
      <FormikTextField label="Reason for Repossession" name="reason" xs={4} />
      <FormikDatePicker label="Repossession Date" name="repo_date" xs={4} />
      <SectionSubHeader label="Repossession Company" divider />
      <PDBusinessSelect
        label="Repossession Company"
        name="repo_pd_business_entity_id"
      />
    </Grid>
  );
};

const PropertyRepossession = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "repossessions_repossession",
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default PropertyRepossession;
