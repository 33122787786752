import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Taxes",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Have you filed all required federal, state, and local tax returns with the appropriate agencies for yourself and all related business entities in which you have a beneficial interest during the last five years (initial applicants) or since your last disclosure (renewal applicants)?",
    name: "michigan_limited_pd.question_6_3_boolean",
  },
  {
    type: fieldTypes.label,
    label: "If yes, please provide details below:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "michigan_limited_pd.question_6_3_details",
    xs: 12,
    rows: 4,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "michigan_limited_pd_supplement_compliance_pdSupplement",
};
