import React from "react";
import { fieldTypes } from "componentGenerators/formGenerator/config";

export const formSchema = [
  {
    type: fieldTypes.bdPartySelect,
    label: "Party Involved",
    name: "bd_party_id",
    required: true,
  },
  {
    type: fieldTypes.bdLawEnforcementSelect,
    label: "Law Enforcement Or Court",
    name: "law_enforcement_bd_business_entity_id",
  },
  {
    type: fieldTypes.subHeader,
    label: "Nature & Details",
    divider: true,
  },
  {
    type: fieldTypes.date,
    label: "Charge Date",
    name: "charge_date",
  },
  {
    type: fieldTypes.text,
    label: "Case Name",
    name: "case_name",
  },
  {
    type: fieldTypes.select,
    label: "Offense Type",
    name: "offense_type",
    dynamicMenuItems: ["bdLegalOffenseType"],
    required: true,
  },
  {
    type: fieldTypes.text,
    label: "Nature of Charge or Complaint",
    name: "nature_of_charge",
    xs: 8,
  },
  {
    type: fieldTypes.text,
    label: "Docket Number",
    name: "docket_number",
  },
  {
    type: fieldTypes.subHeader,
    label: "Outcome",
    divider: true,
  },
  {
    type: fieldTypes.select,
    label: "Disposition",
    name: "criminal_disposition",
    dynamicMenuItems: ["criminalDispositionType"],
  },
  {
    type: fieldTypes.date,
    label: "Disposition Date",
    name: "disposition_date",
  },
  {
    type: fieldTypes.text,
    label: "Sentence",
    name: "sentence",
  },
  {
    type: fieldTypes.text,
    label: "Incarceration Length",
    name: "incarceration_length",
    helperText: "If Applicable",
  },
  {
    type: fieldTypes.text,
    label: "Incarceration Location",
    name: "incarceration_location",
    helperText: "If Applicable",
  },
  {
    type: fieldTypes.subHeader,
    label: "Narrative",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label:
      "Provide a narrative about the nature of the charge or complaint (not required by all jurisdictions)",
  },
  {
    type: fieldTypes.text,
    label: "Narrative",
    name: "narrative",
    multiline: true,
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.subHeader,
    label: "Documents",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: "Attach related documents (if applicable)",
  },
  {
    type: fieldTypes.dropZone,
    description: "Criminal History",
    label: "Upload Attachment",
    name: "bd_legal_history_attachment_group_id",
  },
];

export const columns = [
  { field: "party_name" },
  {
    title: "Agency or Court",
    field: "law_enforcement_bd_busines_entity.business_name",
  },
  {
    field: "criminal_disposition",
  },
  {
    field: "offense_type",
    format: "startCase",
  },
  {
    field: "docket_number",
  },
  {
    field: "case_name",
  },
  {
    title: "Charge Date",
    field: "charge_date",
    format: "date",
  },
];

export const tableQuestions = (
  <>
    <>
      Has the business entity or any of its subsidiaries, directors, trustees,
      or officers ever been indicted, charged with or convicted of a criminal or
      disorderly persons offence or been a party to or named as an unindicted
      co-conspirator in any criminal proceeding in any jurisdiction? Answer yes
      even if: (1) the business entity, its directors, trustees, or officers did
      not commit the offence charged; (2) the charges were dismissed; (3) the
      business entity, its directors, trustees, or officers were not convicted;
      or (4) the charges or offences happened a long time ago.
    </>
    <p>Definitions for purposes of this question</p>
    <ol>
      <li>
        <strong>Crime or Offense</strong> includes all felonies and
        misdemeanors, as well as summary offenses that required appearance
        before a law enforcement agency, state or federal grand jury, justice
        court, municipal court, city court, military court or any other court
        EXCEPT Juvenile Court. Include all DUI/DWI offenses.
      </li>
      <li>
        <strong>Arrest</strong> includes any time that an person was stopped by
        a police officer or other law enforcement officer and advised that they
        were under arrest, detained, held for questioning, requested by a police
        officer or law enforcement officer to come to a police station and
        answer questions, taken into custody by any police officer or other law
        enforcement officer, fingerprinted, held in jail, or instructed to
        appear in court or subpoenaed to answer for conduct which is a crime as
        has been defined 1 (Crime or Offense) above.
      </li>
      <li>
        <strong>Charge</strong> includes any indictment, complaint, information,
        summons, citation or other notice of the alleged commission of any crime
        or offense as defined in 1 (Crime or Offense) above.
      </li>
    </ol>
  </>
);

export const employeesCriminalChargesQuestions = (
  <>
    <>
      The following details are pulled from Key Person profiles within
      OneComply. Where relevant, this information will be presented on
      applications generated in the system.
    </>
  </>
);

export const endpoint = "bdLegalHistory?type=criminal";

export const nonRequired = [
  "bd_legal_history_attachment_group_id",
  "incarceration_length",
  "incarceration_location",
];
