import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import {
  SectionSubHeader,
  SectionLabel,
} from "components/Common/LayoutComponents";

import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm.js";
import React from "react";
import YesNo from "components/Formik/formGroups/YesNo";
import { defaultInitialValues, endpoint } from "./config";
import FormikTextField from "components/Formik/fields/FormikTextField";

const ColoradoDisclosureQuestionsForm = () => {
  const formData = useForm({
    defaultInitialValues,
    noValidate: true,
    endpoint,
  });

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage>
        <Grid container spacing={2}>
          <SectionSubHeader label="Associated Gaming Entity" />
          <SectionLabel label="If Associated Key or Associated Person, Name of Gaming Licensee Associated With:" />
          <FormikTextField
            xs={4}
            label="Name of Gaming Licensee "
            name="colorado.associated_gaming_licensee_name"
          />
          <SectionSubHeader label="Future Employer Information" divider />
          <SectionLabel label="Name of casino or licensed gaming business where you will be working:" />
          <FormikTextField
            xs={4}
            label="Employer Name"
            name="colorado.employer_name"
          />
          <SectionLabel label="Employer phone number:" />
          <FormikTextField
            xs={4}
            label="Employer Phone Number"
            name="colorado.employer_phone_number"
          />
          <SectionLabel label="Job title:" />
          <FormikTextField xs={4} label="Job Title" name="colorado.job_title" />
          <SectionSubHeader label="Current Employer Information" />
          <SectionLabel label="Name of present employer:" />
          <FormikTextField
            xs={4}
            label="Name of Present Employer"
            name="colorado.current_employer_name"
          />
          <SectionLabel label="Current Employer phone number:" />
          <FormikTextField
            xs={4}
            label="Employer Phone Number"
            name="colorado.current_employer_phone_number"
          />
          <SectionLabel label="Current occupation or job title:" />
          <FormikTextField
            xs={4}
            label="Current Occupation"
            name="colorado.current_job_title"
          />
          <SectionSubHeader label="Personal Information" divider />
          <YesNo
            label="Have you used any other social security numbers?"
            name="colorado.has_other_ssn"
            xs={12}
          />
          <FormikTextField
            multiline
            rows={4}
            label="If yes, please explain"
            name="colorado.used_other_ssn_explanation"
            xs={8}
          />
          <SectionSubHeader label="License Information" divider />
          <YesNo
            label="Do you currently possess a Colorado support gaming license or are you an associated person in any other type of Colorado gaming license?"
            name="colorado.has_colorado_gaming_license_or_is_associated_person"
            xs={12}
          />
          <FormikTextField
            multiline
            rows={4}
            label="If yes, please explain"
            name="colorado.colorado_gaming_license_or_is_associated_person_explanation"
            xs={8}
          />
        </Grid>
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

export default ColoradoDisclosureQuestionsForm;
