import styled, { css } from "styled-components";
import {
  TableRow,
  TableHead,
  Chip,
  Box,
  Paper,
  LinearProgress,
} from "@material-ui/core";

export const StyledTableRow = styled(TableRow)`
  padding: 80px;
  padding-top: 10px;
  margin: 80px;
  font-weight: 300;
`;

export const StyledTableHeader = styled(TableHead)`
  background-color: ${({ theme }) => theme.palette.grey[100]};
  border-top: solid ${({ theme }) => theme.palette.divider} 1px;
`;

export const StyledChip = styled(Chip)`
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  background-color: ${({ theme, $color }) =>
    $color === "warning" ? theme.palette.warning.light : "default"};
`;

export const ActionsContainer = styled(Box)`
  width: 0px;
  transition: width 0.3s;
  ${({ $hovered }) =>
    $hovered &&
    css`
      width: 50px;
    `}
`;

export const TimelinePaper = styled(Paper)`
  margin-bottom: ${({ theme }) => theme.spacing(1.5)}px;
  cursor: pointer;
`;

export const LicenseProgress = styled(LinearProgress)`
  height: 15px;
  border-radius: 5px;
`;

export const TabContentContainer = styled(Box)`
  height: 100%;
  padding: ${({ theme }) => theme.spacing(1, 8)};
`;
