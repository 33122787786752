import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { SectionLabel } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import FormikNumberField, {
  NumberFormatTypes,
} from "components/Formik/fields/FormikNumberField";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";

const Form = () => {
  const { listOfCountries } = useMenuOptions();

  return (
    <Grid container spacing={2}>
      <FormikNumberField
        label="Tax Year"
        name="tax_year"
        type={NumberFormatTypes.year}
        xs={4}
      />
      <FormikSelect
        label="Country Filed"
        name="country_filed"
        options={listOfCountries}
        xs={4}
      />
      <FormikNumberField label="Tax Amount" name="tax_amount" md={4} />
      <FormikTextField
        label="Filing Reason"
        name="filing_reason"
        multiline
        rows={2}
        xs={12}
      />
      <SectionLabel label="Upload a copy of the tax return and all appropriate schedules or other attachments required by the tax authorities of the foreign jurisdiction." />
      <FormikFileDropzone
        description="Foreign Taxes"
        label="Upload Attachment"
        name="attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};

const NonUSTaxes = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "non_us_taxes_taxReturnNonUS",
    nonRequired: ["filing_reason", "attachment_group_id"],
  });

  return (
    <FormSectionProvider formData={formData} title={name}>
      <EnhancedFormikDialog title="Non-US Taxes">
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        subheader={<InfoAlert>{tableQuestions}</InfoAlert>}
        columns={columns}
      />
    </FormSectionProvider>
  );
};

export default NonUSTaxes;
