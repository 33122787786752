import {
  Box,
  DialogContent,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import SimpleDialog from "components/Common/Dialogs/SimpleDialog";
import useToggle from "hooks/useToggle";
import React from "react";
import { Document, Page } from "react-pdf";
import styled from "styled-components";

const LearnMore = styled(Link)`
  padding-left: 7px;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 15px;
`;

export const NewFeatureDialog = ({ filePath, ...props }) => {
  return (
    <SimpleDialog fullWidth={true} maxWidth="lg" {...props}>
      <DialogContent>
        <Box display="flex" justifyContent="center">
          <Document file={filePath}>
            <Page pageNumber={1} width={950} />
          </Document>
        </Box>
      </DialogContent>
    </SimpleDialog>
  );
};

const NewFeatureItem = ({ primary, filePath, ...props }) => {
  const { on, setOn, setOff } = useToggle();
  return (
    <>
      <NewFeatureDialog
        open={on}
        onClose={setOff}
        title={primary}
        filePath={filePath}
      />
      <ListItem style={{ display: "flex", flexDirection: "column" }}>
        <ListItemText
          primaryTypographyProps={{
            variant: "body1",
            gutterBottom: true,
          }}
          secondaryProps={{
            variant: "body2",
            color: "textSecondary",
            gutterBottom: true,
          }}
          primary={
            <Box display="flex" alignItems="center">
              {primary}{" "}
              <LearnMore variant="subtitle2" component="button" onClick={setOn}>
                (Learn More)
              </LearnMore>
            </Box>
          }
          {...props}
        />
      </ListItem>
    </>
  );
};

export const NewFeaturesSection = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p={2}
    >
      <Typography variant="h3" color="textSecondary">
        <Box fontWeight="fontWeightMedium">New Features:</Box>
      </Typography>

      <List>
        <NewFeatureItem
          primary="Document Repository"
          secondary="The document repository is a central store for all files attached to a user profile or organization on the OneComply platform."
          filePath="/pdfs/doc-repo-instructions.pdf"
        />
        <NewFeatureItem
          primary="Permissions"
          secondary="OneComply collaborator’s access allows businesses and applicants to add users to their disclosure portals."
          filePath="/pdfs/permissions-instructions.pdf"
        />
      </List>
    </Box>
  );
};
