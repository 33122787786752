import React from "react";
import { Typography, Box } from "@material-ui/core";
import useInvalidOnlyFilter from "../hooks/useInvalidOnlyFilter";
import useBreakingOnlyFilter from "../hooks/useBreakingOnlyFilter";

export default function ReportMessage({ pdfValidity, reportType }) {
  const invalidOnlyFilter = useInvalidOnlyFilter(pdfValidity);
  const breakingOnlyFilter = useBreakingOnlyFilter(pdfValidity);

  const message = React.useMemo(() => {
    switch (reportType) {
      case "validate_tags":
        return `${invalidOnlyFilter.length} out of ${
          pdfValidity.length
        } PDF's have invalid tags. ${
          breakingOnlyFilter.length
            ? `${breakingOnlyFilter.length} PDF's Breaking System.`
            : ""
        }`;
      default:
        return "";
    }
  }, [
    reportType,
    breakingOnlyFilter.length,
    invalidOnlyFilter.length,
    pdfValidity.length,
  ]);

  return (
    <Box fontWeight="fontWeightBold">
      <Typography>{message}</Typography>
    </Box>
  );
}
