const generateFullName = (option) => {
  const {
    first_name,
    middle_name,
    last_name,
    persons_first_name,
    persons_middle_name,
    persons_last_name,
  } = option;

  return `${first_name || persons_first_name || ""} ${
    middle_name || persons_middle_name || ""
  } ${last_name || persons_last_name || ""}`;
};

export default generateFullName;
