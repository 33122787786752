//LAYOUT SECTIONS
import React from "react";
import DisplayTable from "components/Common/Tables/DisplayTable";
import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider";
import { Paper } from "@material-ui/core";
import useFetch from "queryHooks/useFetch";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

export default function Index({ title }) {
  const { data = [], status } = useFetch(
    "non_resident_assets_and_liabilities_assetsAndLiabilitiesNonResident"
  );

  return (
    <FormSectionWrapper title={title}>
      <Paper>
        <LoadingComponent status={status}>
          <DisplayTable
            records={data}
            dependsOnQueryCount="Do you own, manage, or control any assets or are you responsible for any liabilities, that are located outside your country of residence (excluding any foreign bank accounts)?"
            columns={[
              { title: "Category", field: "category", format: "startCase" },
              {
                title: "Type",
                field: "url_path",
                format: "redirect",
              },
              { title: "Description", field: "description" },
              { title: "Amount", field: "current_amount", format: "currency" },
              { title: "Location", field: "country" },
            ]}
          />
        </LoadingComponent>
      </Paper>
    </FormSectionWrapper>
  );
}
