import React from "react";
import { formats } from "components/Common/Tables/Common/helpers";

export const columns = [
  { title: "Creditor", field: "creditor_pd_party_name" },
  { title: "Relative Name", field: "relative_pd_person.full_name" },
  { field: "incurred_date" },
  { field: "original_amount", format: formats.currency },
  { field: "primary_debtor" },
  { field: "outstanding_amount", format: formats.currency },
  {
    title: "Current",
    field: "is_current",
    format: "boolean",
    sortType: "boolean",
  },
];

export const tableQuestions = (
  <>
    List below the information requested with regard to all contingent
    liabilities for which you, your spouse or your dependent children are
    obligated.
  </>
);

export const nonRequired = ["relative_pd_person_id", "liable_persons"];

export const endpoint = "contingent_liabilities_contingentPayable";

export const defaultInitialValues = {
  type: "contingent",
  relative_pd_person_id: null,
  creditor_pd_party_id: null,
  nature_of_payable: "",
  nature_of_security: "",
  account_number: "",
  primary_debtor: "",
  interest_rate: null,
  incurred_date: null,
  due_date: null,
  original_amount: null,
  outstanding_amount: null,
  is_current: true,
  liable_persons: "",
};

export const modalTitle = "Contingent Liabilities";

export const dataHookProps = {
  defaultInitialValues,
  nonRequired,
  endpoint,
};
