import React from "react";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "APPLICANT'S ORGANIZATION DOCUMENTS",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload the current ownership and management tables of your organization
        in the
        <CustomLink to="bd_corporate_documents" />
        section and attach it at the back of the Appendix 1 cover page of your
        application.
      </>
    ),
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "bdSupplement",
};
