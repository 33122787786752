import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import {
  SectionSubHeader,
  SectionLabel,
} from "components/Common/LayoutComponents";
import YesNo from "components/Formik/formGroups/YesNo";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikNumberField from "components/Formik/fields/FormikNumberField.jsx";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField.jsx";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions.js";
import useTabularData from "hooks/tableHooks/useTabularData";
import ColumnSum from "components/Layout/SectionsLayout/ColumnSum.jsx";
import BDBusinessSelect from "components/Formik/formGroups/CrudSelect/selects/BDBusinessSelect.jsx";

import React from "react";
import {
  columns,
  defaultInitialValues,
  nonRequired,
  tableQuestions,
} from "./config.js";

const BusinessAssetsForm = () => {
  const { bdAssetTypes, bdStockTypes } = useMenuOptions();
  return (
    <Grid container spacing={2}>
      <BDBusinessSelect
        name="issuing_bd_business_entity_id"
        label="Issuing Business Entity"
        header="Issuing Business Entity"
      />

      <SectionSubHeader label="Details" divider />
      <FormikSelect name="asset_type" options={bdAssetTypes} />
      <FormikSelect name="type_of_stock" options={bdStockTypes} />

      <FormikTextField name="exchange" />

      <FormikNumberField
        name="acquisition_unit_price"
        numberFormatProps={{ decimalScale: 4 }}
        xs={4}
      />

      <FormikNumberField type="none" name="quantity_held" />
      <FormikNumberField
        type="percent"
        name="ownership_percent"
        helperText="If Greater then 5%"
      />

      <YesNo label="Does the Applicant have voting rights?" name="is_voting" />
      <YesNo label="Is the Issuing Company publicly traded?" name="is_public" />

      <FormikDatePicker label="Purchase Date" name="purchase_date" />
      <FormikDatePicker label="Valuation Date" name="valuation_date" />

      <FormikNumberField name="adjusted_cost_base" />
      <FormikNumberField name="market_value" />
      <FormikNumberField name="annual_income" />
      <FormikTextField
        label="Terms, Conditions, Rights & Privileges"
        name="terms"
        multiline
        rows={2}
        xs={12}
      />
      <SectionSubHeader label="Attachments" divider />
      <SectionLabel label="Some jurisdictions require additional documents.  If needed, you will be prompted with instructions outlining what documents are needed." />
      <FormikFileDropzone
        description="Loans And Receivables"
        label="Upload Attachment"
        name="attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};

const BusinessAssets = ({ name }) => {
  const formData = useTabularData({
    nonRequired,
    defaultInitialValues,
    endpoint: "bdAsset",
    overwriteMissingRequiredDisplay: {
      issuing_bd_business_entity_id: "Issuing Business Entity",
    },
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title="Details">
        <BusinessAssetsForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        footer={
          <ColumnSum
            name="market_value"
            data={formData.data}
            label="Total Market Value"
          />
        }
        columns={columns}
        addButtonText="Add Record"
      />
    </FormSectionProvider>
  );
};

export default BusinessAssets;
