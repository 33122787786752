import { Grid } from "@material-ui/core";
import {
  Divider,
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents/index.js";
import FormikNumberField, {
  NumberFormatTypes,
} from "components/Formik/fields/FormikNumberField";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import FormikRadioGroup from "components/Formik/formGroups/FormikRadioGroup";
import BDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/BDPersonSelect";
import YesNo from "components/Formik/formGroups/YesNo";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm";
import React from "react";
import { useMenuOptions } from "hooks/useMenuOptions";
import { defaultInitialValues } from "./config";

const ApplicantInformationSection = () => {
  const { michigan_gaming_or_sports_betting, michigan_initial_or_five_year } =
    useMenuOptions();
  return (
    <>
      <SectionSubHeader label="Applicant Information" />
      <FormikRadioGroup
        label="Application Type"
        options={michigan_gaming_or_sports_betting}
        name="michigan_vra.gaming_or_sports_betting"
        xs={12}
      />
      <FormikRadioGroup
        label="Initial or Renewal"
        options={michigan_initial_or_five_year}
        name="michigan_vra.initial_or_five_year"
        xs={12}
      />
      <Divider />
      <YesNo
        label="Registered with Michigan Department of Licensing and Regulatory Affairs (LARA)?"
        name="michigan_vra.is_registered_with_lara"
        xs={12}
      />
      <FormikTextField
        label="If No, explain:"
        name="michigan_vra.registered_with_lara_explanation"
        xs={12}
        multiline
        rows={2}
      />
      <Divider />
      <FormikTextField
        label="Type of Tax Return Filed"
        name="michigan_vra.type_of_tax"
        xs={6}
        helperText="(e.g 1120, 1065)"
      />
      <YesNo
        label="Is applicant mailing address the same as the physical business address?"
        name="michigan_vra.is_phys_bus_address_same"
        xs={12}
      />
      <SectionLabel>
        Authorized contact person responsible for correspondence regarding
        application:
      </SectionLabel>
      <BDPersonSelect
        name="michigan_vra.authorized_contact_person_id"
        label="Person"
        xs={6}
      />
    </>
  );
};

const BusinessInformationSection = () => (
  <>
    <SectionSubHeader
      label="Business information and goods and/or services provided "
      divider
    />
    <SectionLabel>
      Name of the casino(s), tribe(s), or supplier licensee(s) to which the
      applicant intends to conduct or is currently conducting business with in
      Michigan:
    </SectionLabel>
    <FormikTextField
      label="Casino(s), tribe(s), or supplier licensee(s)"
      name="michigan_vra.name_entities_intends_conducts"
      xs={12}
      multiline
      rows={2}
    />
    <SectionLabel>
      Description of the goods and/or services the applicant intends to provide
      or is currently providing in Michigan.{" "}
      <b>Please be specific and provide a detailed response,</b> including
      variations to goods and services to different platform providers and/or
      operators in Michigan:
    </SectionLabel>
    <FormikTextField
      label="Description"
      name="michigan_vra.description_goods_services"
      xs={12}
      multiline
      rows={2}
    />
    <Divider />
    <YesNo
      label="Is the applicant an affiliate marketer"
      name="michigan_vra.is_affiliated_marketer"
      xs={12}
    />
    <InfoAlert severity="warning">
      If Yes, the applicant is required to complete an Affiliate Marketer
      Certification and submit with this application.
    </InfoAlert>
    <Divider />

    <YesNo
      label="Will the applicant have a payment agreement based on gross receipts or adjusted gross receipts?"
      name="michigan_vra.is_gross_or_adjusted_receipts"
      xs={12}
    />
    <InfoAlert severity="warning">
      If Yes, the applicant may not qualify for a vendor registration. Please
      contact the MGCB prior to completing this application.
    </InfoAlert>
    <Divider />

    <YesNo
      label="Is the applicant a publicly traded domestic corporation under the regulation of the United States Securities and Exchange Commission (SEC), or a wholly owned subsidiary of such a corporation?"
      name="michigan_vra.is_publicly_traded"
      xs={12}
    />
    <InfoAlert severity="warning">
      If Yes, the applicant may skip the questions that pertain to pages 4 and 5
      of the application (Individual Disclosure, Entity Disclosure, Tax
      Liability, and Criminal History). Please submit the documentary evidence
      to demonstrate the applicant is a publicly traded corporation under the
      regulation of the U.S SEC, or a wholly owned subsidiary of such a
      corporation.
    </InfoAlert>
  </>
);

const EntityDisclosureSection = () => (
  <>
    <SectionSubHeader label="Entity Disclosure" divider />
    <SectionLabel>
      Total ownership percentage of individuals and entities owning{" "}
      <b>less than 25%</b> direct or indirect equity interest (e.g shareholders,
      partners, members) in the applicant
    </SectionLabel>
    <FormikNumberField
      label="Ownership %"
      name="michigan_vra.total_ownership_percentage"
      xs={6}
      type={NumberFormatTypes.percent}
    />
  </>
);

const Form = () => {
  return (
    <Grid container spacing={2}>
      <ApplicantInformationSection />
      <BusinessInformationSection />
      <EntityDisclosureSection />
    </Grid>
  );
};

const MichiganVRAApplicationDetails = ({ title }) => {
  const formData = useForm({
    defaultInitialValues,
    endpoint: "bdSupplement",
  });

  return (
    <FormSectionProvider formData={formData} title={title}>
      <EnhancedFormikPage>
        <Form />
      </EnhancedFormikPage>
    </FormSectionProvider>
  );
};

export default MichiganVRAApplicationDetails;
