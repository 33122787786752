import { CardContent } from "@material-ui/core";
import styled from "styled-components";
import DropDown from "../../../../Common/Inputs/DropDown/index";

export const SubheaderCardContent = styled(CardContent)`
  padding-right: ${({ theme }) => theme.spacing(4)}px;
  padding-left: ${({ theme }) => theme.spacing(4)}px;
`;

export const TotalIncomeWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const StyledDropDown = styled(DropDown)`
  & .MuiSelect-root {
    font-size: ${({ theme }) => theme.typography.h4.fontSize};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${({ theme }) => theme.spacing(2)}px;
  /* flex-grow: 1;
  align-items: center; */
`;

export const NoDataButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  align-items: center;
`;

export const StyledCardContent = styled(CardContent)`
  min-height: 400px;
  display: flex;
  flex-direction: column;
`;
