import React from "react";

export const columns = [
  {
    title: "Report Type",
    field: "report_type",
    format: "startCase",
  },
  {
    title: "Effective Year",
    field: "effective_year",
    format: "date",
  },
  {
    title: "Has Attachments",
    field: "corporate_report_attachment_group_id",
    render: ({ corporate_report_attachment_group_id }) => {
      if (corporate_report_attachment_group_id) {
        return "Yes";
      }
      return "No";
    },
  },
];

export const endpoint = "bdCorporateReport?report_type=financial_statement";

export const tableQuestions = (
  <>
    Please review your information below and If the business entity is a
    corporation, attach copies of all annual reports and SEC fillings. if any,
    for the previous three years.
  </>
);

export const tabularDataParams = {
  endpoint,
  tableQuestions,
};

export const defaultInitialValues = {};
