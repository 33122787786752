import { CardHeader, List, ListItem } from "@material-ui/core";
import styled from "styled-components";

export const StyledListItem = styled(ListItem)`
  height: 75px;
`;

export const InstructionsContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.grey[100]};
  padding: ${({ theme }) => theme.spacing(3)}px;
  border-radius: 5px;
`;

export const ImgContainer = styled.img`
  padding: ${({ theme }) => theme.spacing(1)}px;
  background-color: white;
  border: solid 1px ${({ theme }) => theme.palette.divider};
`;

export const StyledProgressImg = styled.img`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  border: solid 1px ${({ theme }) => theme.palette.divider};
  border-radius: 5px;
  width: auto;
  height: 275px;
`;

export const StyledList = styled(List)`
  & .MuiListItem-root {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

export const StyledCardHeader = styled(CardHeader)`
  padding-bottom: 0px;
`;

export const NumberIcon = styled.div`
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  font-size: 15px;
  color: #fff;
  text-align: center;
  background: ${({ theme }) => theme.palette.primary.light};
`;
