import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import NewJersey33DisclosureQuestionsForm from "./NewJersey33DisclosureQuestionsForm";
import useFetch from "queryHooks/useFetch";
import { Box } from "@material-ui/core";
import DisplayTable from "components/Common/Tables/DisplayTable";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const NewJersey33DisclosureQuestions = ({ name }) => {
  const { data = [], status } = useFetch(
    "new_jersey_33_disclosure_questions_nj_gaming_license",
    "new_jersey_33_disclosure_questions_nj_gaming_license"
  );

  return (
    <FormSectionWrapper title={name}>
      <NewJersey33DisclosureQuestionsForm />
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={status}>
          <DisplayTable
            records={data}
            linkTo={["business_licensing"]}
            columns={[
              {
                title: "Nature of Interest",
                field: "nature_of_interest",
                format: "startCase",
              },
              {
                title: "Ownership Percent",
                field: "ownership_percent",
                format: "percent",
              },
              { title: "License Number", field: "license_number" },
            ]}
            dependsOnQueryCount="Do you have any ownership interest, financial interest or financial investment in any business entity applying to, or presently licensed by, the New Jersey Casino Control Commission and/or the Division of Gaming Enforcement?"
          />
        </LoadingComponent>
      </Box>
    </FormSectionWrapper>
  );
};

export default NewJersey33DisclosureQuestions;
