//Adapted from https://github.com/CodAffection/React-Material-UI-Table-Paging-Sorting-and-Filtering.

import React from "react";
import { useSnackbar } from "notistack";
import { get } from "lodash";

const checkIsMissingRequired = (record, requiredValues) => {
  const missingRequiredItems = Object.keys(requiredValues).filter(
    (requiredKey) =>
      record[requiredKey] === undefined || record[requiredKey] === null
  );

  return missingRequiredItems;
};

const addMissingRequiredFlag = (
  records,
  requiredValues,
  overwriteMissingRequiredDisplay,
  mutateRequiredValues
) => {
  return records.map((record) => ({
    ...record,
    overwriteMissingRequiredDisplay,
    missingRequiredItems: checkIsMissingRequired(
      record,
      mutateRequiredValues
        ? mutateRequiredValues({ requiredValues, record })
        : requiredValues
    ),
  }));
};

const defaultFilterFn = {
  fn: (items) => {
    return items;
  },
};

export const isDate = (date) => {
  return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
};

const getComparisonValue = (value, sortType) => {
  if (isDate(value)) {
    return value ? new Date(value) : null;
  }
  if (sortType === "standard") return value;
  if (sortType === "date") {
    return value ? new Date(value) : null;
  }
};

const descendingComparator = (a, b, orderBy, sortType) => {
  const aValue = getComparisonValue(get(a, orderBy), sortType);
  const bValue = getComparisonValue(get(b, orderBy), sortType);

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
};

export default function useTable({
  records,
  filterFn,
  requiredValues = {},
  mutateRequiredValues = null,
  overwriteMissingRequiredDisplay,
  initialRowsPerPage = 1000, // 1000 is somewhat arbitrary, but it is here because if we do not have a limit the excessive dom elements would render the page unusably slow
  defaultSortBy,
  defaultSortOrder = "desc",
}) {
  const filterFunction = filterFn ? filterFn : defaultFilterFn;
  const pages = [5, 10, 25];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage);
  const [order, setOrder] = React.useState(defaultSortOrder);
  const [orderBy, setOrderBy] = React.useState(defaultSortBy);
  const [sortType, setSortType] = React.useState("standard");

  const { enqueueSnackbar } = useSnackbar();

  const recordsWithRequiredFlag = React.useMemo(() => {
    return addMissingRequiredFlag(
      records,
      requiredValues,
      overwriteMissingRequiredDisplay,
      mutateRequiredValues
    );
  }, [
    records,
    requiredValues,
    overwriteMissingRequiredDisplay,
    mutateRequiredValues,
  ]);

  const handleSortRequest = (cellId, type) => {
    setSortType(type ? type : "standard");
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const onChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) =>
    order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy, sortType)
      : (a, b) => -descendingComparator(a, b, orderBy, sortType);

  const getSortedRecords = () =>
    stableSort(
      filterFunction.fn(recordsWithRequiredFlag),
      getComparator(order, orderBy)
    ).slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  React.useEffect(() => {
    if (records.length > 1000) {
      enqueueSnackbar("Table Limit Exceeded", {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, records]);

  const tableHeadProps = {
    orderBy,
    order,
    handleSortRequest,
  };

  const paginationProps = {
    page,
    pages,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
  };

  return {
    tableHeadProps,
    paginationProps,
    getSortedRecords,
  };
}
