import { getFormComponents } from "componentGenerators/helpers";
import bd_colorado_sbba_supplement_application_information from "components/Pages/Supplemental/BD/ColoradoSBBA/ColoradoSBBAApplicationInformation/ColoradoSBBAApplicationInformation";
import bd_colorado_sbba_supplement_attachments from "components/Pages/Supplemental/BD/ColoradoSBBA/ColoradoSBBAAttachments/ColoradoSBBAAttachments";
import bd_colorado_sbba_supplement_compliance_report from "components/Pages/Supplemental/BD/ColoradoSBBA/ColoradoSBBAComplianceReport/ColoradoSBBAComplianceReport";
import bd_colorado_sbba_supplement_financial_history from "components/Pages/Supplemental/BD/ColoradoSBBA/ColoradoSBBAFinancialHistory/ColoradoSBBAFinancialHistory";
import bd_colorado_sbba_supplement_ownership_structure from "components/Pages/Supplemental/BD/ColoradoSBBA/ColoradoSBBAOwnershipStructure/ColoradoSBBAOwnershipStructure";

export default {
  ...getFormComponents({
    bd_colorado_sbba_supplement_application_information,
    bd_colorado_sbba_supplement_attachments,
    bd_colorado_sbba_supplement_financial_history,
    bd_colorado_sbba_supplement_ownership_structure,
  }),
  bd_colorado_sbba_supplement_compliance_report,
};
