import { fieldTypes } from "componentGenerators/formGenerator/config";

const indictmentsColumns = [
  { field: "indictment_date" },
  {
    field: "law_enforcement_pd_business_entity.business_name",
    title: "Law Enforcement or Court",
  },
  { field: "nature" },
  { field: "disposition" },
];

const indictmentsFormSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Nature",
  },
  {
    type: fieldTypes.date,
    name: "indictment_date",
  },
  {
    type: fieldTypes.text,
    name: "nature",
  },
  {
    type: fieldTypes.subHeader,
    divider: true,
    label: "Law Enforcement or Court",
  },
  {
    type: fieldTypes.pdLawEnforcementSelect,
    name: "law_enforcement_pd_business_entity_id",
    defaultValue: null,
  },
  {
    type: fieldTypes.select,
    dynamicMenuItems: ["criminalDispositionType"],
    name: "disposition",
    label: "Outcome/Disposition",
    defaultValue: null,
  },
];

const indictmentsSchema = {
  type: "table",
  formSchema: indictmentsFormSchema,
  endpoint: "indictments_indictmentLegalHistory",
  title: "Indictments and Complaints",
  columns: indictmentsColumns,
  infoAlerts: [
    {
      body: `To the best of your knowledge, has a criminal indictment, information or 
      complaint ever been filed or returned against you, or named you as an 
      unindicted party or unindicted co-conspirator in any criminal proceeding 
      in any jurisdiction? Please review the entries below, and add an outcome/disposition 
      to each record.`,
    },
  ],

  modifySubmissionValues: (formValues) => ({
    type: "indictment",
    ...formValues,
  }),
};

export default [indictmentsSchema];
