import PropTypes from "prop-types";
import React from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import SupplementTab from "./PermissionTabs/SupplementTab";
import CorePagesTab from "./PermissionTabs/CorePagesTab";

const PermissionTree = ({ state, dispatch }) => {
  const topLevelNode = Object.keys(state);
  return (
    <Box p={1} pb={0} pt={0}>
      {topLevelNode.map((topLevel, i) => {
        if (topLevel === "pd_supplement") {
          return (
            <SupplementTab
              key={"supplement_tab"}
              topLevel={topLevel}
              state={state}
              dispatch={dispatch}
              topLevelNode={topLevelNode}
              index={i}
            />
          );
        } else {
          return (
            <CorePagesTab
              key={"core_tab"}
              topLevel={topLevel}
              state={state}
              dispatch={dispatch}
              topLevelNode={topLevelNode}
              index={i}
            />
          );
        }
      })}
    </Box>
  );
};

PermissionTree.propTypes = {
  dispatch: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
};

export const PermissionLoadingComponent = () => {
  return (
    <Box display="flex">
      <Typography style={{ alignSelf: "center", marginRight: "30%" }}>
        Loading Permissions
      </Typography>
      <CircularProgress />
    </Box>
  );
};

export default PermissionTree;
