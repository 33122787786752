import { getFormComponents } from "componentGenerators/helpers";
import bd_michigan_business_disclosure_supplement_agreement from "components/Pages/Supplemental/BD/MichiganBusinessDisclosure/MichiganBusinessDisclosureAgreement/MichiganBusinessDisclosureAgreement";
import bd_michigan_business_disclosure_supplement_application_information from "components/Pages/Supplemental/BD/MichiganBusinessDisclosure/MichiganBusinessDisclosureApplicationInformation/MichiganBusinessDisclosureApplicationInformation";
import bd_michigan_business_disclosure_supplement_associated_entities from "components/Pages/Supplemental/BD/MichiganBusinessDisclosure/MichiganBusinessDisclosureAssociatedEntities/MichiganBusinessDisclosureAssociatedEntities";
import bd_michigan_business_disclosure_supplement_attachments from "components/Pages/Supplemental/BD/MichiganBusinessDisclosure/MichiganBusinessDisclosureAttachments/MichiganBusinessDisclosureAttachments";
import bd_michigan_business_disclosure_supplement_compliance from "components/Pages/Supplemental/BD/MichiganBusinessDisclosure/MichiganBusinessDisclosureCompliance/MichiganBusinessDisclosureComplicance";
import bd_michigan_business_disclosure_supplement_ownership from "components/Pages/Supplemental/BD/MichiganBusinessDisclosure/MichiganBusinessDisclosureOwnership/MichiganBusinessDisclosureOwnership";

export default getFormComponents({
  bd_michigan_business_disclosure_supplement_application_information,
  bd_michigan_business_disclosure_supplement_agreement,
  bd_michigan_business_disclosure_supplement_associated_entities,
  bd_michigan_business_disclosure_supplement_attachments,
  bd_michigan_business_disclosure_supplement_compliance,
  bd_michigan_business_disclosure_supplement_ownership,
});
