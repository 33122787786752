import React from "react";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import useForm from "hooks/useForm.js";
import { SectionLabel } from "components/Common/LayoutComponents/index.js";
import FormikTextField from "components/Formik/fields/FormikTextField.jsx";
import { Box, Paper } from "@material-ui/core";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent.jsx";
import DisplayTable from "components/Common/Tables/DisplayTable";
import { endpoint, question, columns, defaultInitialValues } from "./config.js";
import useFetch from "queryHooks/useFetch";

function SupplementQuestion() {
  const formData = useForm({
    defaultInitialValues,
    endpoint: "bdSupplement",
  });

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage>
        <Grid>
          <SectionLabel>
            If your answer is NO, please state the reason(s) below:
          </SectionLabel>
        </Grid>
        <Grid>
          <FormikTextField
            label="Reason(s)"
            name="south_dakota_bhd.question_6_d_reason"
            xs={12}
          />
        </Grid>
      </EnhancedFormikPage>
    </FormContextProvider>
  );
}

function ComplianceReportTable() {
  const { data: data, status: status } = useFetch(endpoint);

  return (
    <Box mb={1}>
      <Paper>
        <LoadingComponent status={status}>
          <DisplayTable
            records={data}
            linkTo={["bd_partners", "bd_owners", "bd_shareholders"]}
            columns={columns}
            dependsOnQueryCount={{
              question: question,
            }}
          />
        </LoadingComponent>
      </Paper>
    </Box>
  );
}

const SouthDakotaBHDBusinessOwnership = ({ name }) => {
  return (
    <FormSectionWrapper title={name}>
      <ComplianceReportTable />
      <SupplementQuestion />
    </FormSectionWrapper>
  );
};

export default SouthDakotaBHDBusinessOwnership;
