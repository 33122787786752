import { useAuth } from "context/auth";
import { getAttachmentUrl } from "helpers/apiHelpers";
import React from "react";

// ! Not being used currently, but leaving for future use
// const downloadFile = (dataurl, filename) => {
//   fetch(dataurl, { method: "GET" })
//     .then((res) => {
//       return res.blob();
//     })
//     .then((blob) => {
//       const url = window.URL.createObjectURL(blob);
//       const a = document.createElement("a");
//       a.href = url;
//       a.download = filename;
//       document.body.appendChild(a);
//       a.click();
//       setTimeout((_) => {
//         window.URL.revokeObjectURL(url);
//       }, 60000);
//       a.remove();
//     })
//     .catch((err) => {
//       console.error("err: ", err);
//     });
// };

export const useViewAttachment = () => {
  const { employee_id } = useAuth();

  const [attachmentBeingViewed, setAttachmentBeingViewed] =
    React.useState(null);

  const handleGetAttachment = (fileID, attachment_group_id, onSuccess) => {
    getAttachmentUrl(
      `/employee/${employee_id}/attachmentGroup/${attachment_group_id}/attachment/${fileID}`
    )
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch((err) => console.log(err));
  };

  const handleViewFile = (fileID, attachment_group_id) =>
    handleGetAttachment(fileID, attachment_group_id, (data) =>
      setAttachmentBeingViewed(data)
    );

  // const handleDownloadFile = (fileID, attachment_group_id) =>
  //   handleGetAttachment(fileID, attachment_group_id, ({ url, name }) =>
  //     downloadFile(url, name)
  //   );

  return {
    attachmentBeingViewed,
    handleViewFile,
    // handleDownloadFile
  };
};
