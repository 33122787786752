import { useAuth } from "context/auth";
import React from "react";
import { generateEndpoint, removeAttributes } from "helpers/routeHelpers";

const useEndPoint = (path) => {
  const auth = useAuth();

  const fullEndpoint = React.useMemo(
    () => generateEndpoint(path, auth),
    [path, auth]
  );

  const noAttributeEndpoint = React.useMemo(
    () => removeAttributes(fullEndpoint),
    [fullEndpoint]
  );

  const cleanEndpoint = React.useMemo(() => removeAttributes(path), [path]);

  const bdBusinessEntityEndpoint = `bdBusinessEntity?id=${auth.bd_business_entity_id}`;

  return {
    fullEndpoint,
    noAttributeEndpoint,
    cleanEndpoint,
    path,
    bdBusinessEntityEndpoint,
  };
};

export default useEndPoint;
