import { useAuth0 } from "@auth0/auth0-react";
import "assets/scss/material-dashboard-pro-react.scss";
import { useAuth } from "context/auth";
import { useSaveRedirectPath } from "context/loginRedirectFrom";
import React, { useRef } from "react";
import { Redirect, Route } from "react-router-dom";

function ProtectedRoute(props) {
  const { path, component, hist } = props;
  const updatePath = useSaveRedirectPath();
  const auth = useAuth();
  const { isAuthenticated } = useAuth0();
  const route = useRef(null);

  React.useEffect(() => {
    //we want the first redirect that comes from the original link
    route.current = hist.location.pathname;
  }, [auth, hist]);

  React.useEffect(() => {
    return () => {
      updatePath(route.current);
    };
  });

  if (auth.authenticated)
    return <Route props={props} path={path} component={component} />;

  if (!isAuthenticated) return <Redirect to="/auth" />;

  return null;
}

export default ProtectedRoute;
