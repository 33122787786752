import React from "react";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";

export const columns = [
  { title: "Registered Owner", field: "registered_owner" },
  { title: "Year", field: "vehicle_year" },
  { title: "Make & Model", field: "vehicle_model" },
  { title: "License Plate Number", field: "license_plate_number" },
];

export const defaultInitialValues = {
  relative_pd_person_id: null,
  vehicle_model: "",
  vehicle_year: null,
  license_plate_number: "",
};
export const modalTitle = "Motor Vehicle Data";
export const tableQuestions = (
  <>
    In addition to the information provided in{" "}
    <Link component={RouterLink} to="/app/vehicles">
      Vehicles
    </Link>{" "}
    on the multi-jurisdictional form, complete the following information as to
    all personal vehicles currently registered or leased to you, your spouse and
    those persons living with you. Include all vehicles such as automobiles,
    motorcycles, recreational vehicles, aircraft and watercraft.
  </>
);
export const nonRequired = ["relative_pd_person_id"];

export const endpoint = "iowa_vehicles_vehicles";

export const dataHookProps = {
  defaultInitialValues,
  nonRequired,
  endpoint,
  noValidate: true,
  overwriteMissingRequiredDisplay: {
    relative_pd_person_id: "Registered Owner",
  },
};
