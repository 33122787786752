import React from "react";

export const columns = [
  {
    title: "Applicant Party",
    field: "party_name",
  },
  {
    title: "Other party",
    field: "related_party_name",
  },
  {
    field: "bribe_category",
    format: "startCase",
  },
  {
    title: "Original Amount",
    field: "amount_original",
    format: "currency",
  },

  {
    title: "From Date",
    field: "from_date",
    format: "date",
  },
];

export const tableQuestions = (
  <>
    During the last 10 years (or any other period as advised by your GR), has
    the Applicant, its parent company, subsidiary, director, officer, employee
    or any third party who is acting on behalf of the Company, made or received
    any bribe or kickback or made or received any payments alleged to have been
    bribe or kickback to any employee, company, organization or any
    government/public official, whether domestic or foreign, to obtain favorable
    treatment?
  </>
);

export const defaultInitialValues = {
  bd_party_id: null,
  related_bd_party_id: null,
  bribe_category: null,
  related_bd_party_role: null,
  currency_type: null,
  from_date: null,
  amount_original: null,
  intermediary_pd_party_id: null,
  details: "",
  payment_method: "",
  position_sought: "",
};
