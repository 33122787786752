import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import { SectionSubHeader } from "components/Common/LayoutComponents";

import { Alert } from "@material-ui/lab";

import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm.js";
import React from "react";
import YesNo from "components/Formik/formGroups/YesNo";
import { eliminatoryQuestions, defaultInitialValues } from "./config";

const alertMessage = (
  <>
    <strong>NOTICE:</strong>If you provide false information on your gaming
    license application and/or do not disclose all information the application
    asks, your license is subject to denial or revocation, and you may be
    subject to criminal prosecution. The Division of Gaming will conduct a
    complete background investigation and will check all sources of information.
    You are advised that it is better to disclose all information than face
    denial, revocation or criminal prosecution.
  </>
);

const footerMessage = (
  <>
    If you answered <strong>YES</strong> to any of the above questions, by
    Colorado law you cannot obtain or hold a Colorado gaming license.{" "}
    <strong>DO NOT CONTINUE WITH OR TURN IN THIS APPLICATION.</strong>
  </>
);

const ColoradoEliminatoryQuestionsForm = () => {
  const formData = useForm({
    defaultInitialValues,
    noValidate: true,
    endpoint: "colorado_rider_eliminatory_questions_pdSupplement",
  });

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage>
        <Grid container spacing={2}>
          <Alert severity="warning" style={{ margin: "20px 0" }}>
            {alertMessage}
          </Alert>
          <SectionSubHeader label="Eliminatory Questions" />

          {eliminatoryQuestions.map(({ label, attribute }) => (
            <YesNo
              key={attribute}
              label={label}
              name={`colorado.${attribute}`}
            />
          ))}
        </Grid>
        <Alert severity="warning" style={{ margin: "20px 0" }}>
          {footerMessage}
        </Alert>
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

export default ColoradoEliminatoryQuestionsForm;
