import PropTypes from "prop-types";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { FormikDialog } from "components/Formik/formContainers/FormikDialog";
import Center from "components/flex/Center";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import DeleteConfirmation from "components/Common/Dialogs/DeleteConfirmation";
import { LicenseItem } from "components/Pages/LicensingControl/components/DetailsDialog/LicenseItem";

import {
  employmentsQueryKey,
  licensesQueryKey,
  licenseTypeCategory,
} from "components/Pages/LicensingControl/Employees/config";

import LicenseForm from "components/Pages/LicensingControl/components/sharedForms/LicenseForm.jsx";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const initialValues = {
  lc_license_type: { lc_licensing_agency_id: null },
  lc_license_type_id: null,
  license_number: "",
  expiration_date: null,
  current_status: "",
  start_date: null,
  lc_attachment_group_id: null,
};

export const EmployeeLicenses = ({
  id: employee_id,
  licensesFormInitiallyOpen = false,
  licenseData = [],
}) => {
  const formData = useTabularData({
    defaultInitialValues: {
      ...initialValues,
      lc_person_id: employee_id,
    },
    endpoint: "licensing/lcPerson",
    validationSchemaKey: "employeeLicenses",
    extraInvalidateQueryKeys: [employmentsQueryKey, licensesQueryKey],
    fetchOptions: { useFullEndpoint: false },
    postValues: ({ lc_license_type: _lc_license_type, ...values }) => values,
    patchValues: ({ lc_license_type: _lc_license_type, ...values }) => values,
    initiallyOpen: licensesFormInitiallyOpen,
  });

  const {
    // data = [],
    status,
    formikProps,
    editableListActions: {
      handleAddRecord,
      handleDeleteRecord,
      handleEditRecord,
    },
    deleteDialogProps,
  } = formData;

  return (
    <>
      <DeleteConfirmation {...deleteDialogProps} />
      <FormikDialog {...formikProps} title="License / Certification">
        <Grid container spacing={2}>
          <LicenseForm
            category={licenseTypeCategory}
            description="Personal License Attachment"
          />
        </Grid>
      </FormikDialog>
      <Box pb={2}>
        <Typography variant="h3" gutterBottom>
          Licenses & Certifications
        </Typography>
      </Box>
      <LoadingComponent status={status}>
        {licenseData.length === 0 && <Box p={2} />}
        {licenseData.map(
          ({
            pd_business_entity,
            id,
            to_date,
            from_date,
            license_agency_pd_business_entity,
          }) => {
            const licenseName = pd_business_entity?.business_name || "N/A";
            const agencyName =
              license_agency_pd_business_entity?.business_name || "N/A";

            return (
              <LicenseItem
                handleEdit={() => handleEditRecord(id)}
                handleDelete={() => handleDeleteRecord(id)}
                key={id}
                licenseType={`${licenseName}`}
                dispositionDate={from_date}
                expirationDate={to_date}
                agencyName={agencyName}
              />
            );
          }
        )}
        <Center pt={1} pb={5}>
          <Button
            disabled={true}
            onClick={handleAddRecord}
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            color="primary"
          >
            Add New License
          </Button>
        </Center>
      </LoadingComponent>
    </>
  );
};

EmployeeLicenses.propTypes = {
  employeeId: PropTypes.number,
  licensesFormInitiallyOpen: PropTypes.bool,
  id: PropTypes.number,
  licenseData: PropTypes.array,
};
