import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Associated Business Entity",
  },
  {
    type: fieldTypes.label,
    label: "Please select the business associated with this application:",
  },
  {
    type: fieldTypes.pdBusinessSelect,
    label: "Business Entity",
    name: "cherokee_niva.associated_business_id",
  },
  {
    type: fieldTypes.subHeader,
    label: "Access Data",
    divider: true,
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Will remote (dial in) access be needed to any of the Cherokee Nation Gaming Facilities?",
    name: "cherokee_niva.is_access_data_q1",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Will physical access to any of the Cherokee Nation Gaming Facilities be needed?",
    name: "cherokee_niva.is_access_data_q2",
  },
  {
    type: fieldTypes.checkBoxGroup,
    label:
      "If physical access is needed, please select which facilities access is requested for.",
    name: "cherokee_niva.physical_access_checkbox",
    dynamicMenuItems: ["cherokeeLocationCheckbox"],
  },
  {
    type: fieldTypes.checkBoxGroup,
    label:
      "If you answered 'Yes' to the question above, please select the facility you will pick up your License at. You will need to show a picture ID.",
    name: "cherokee_niva.license_pickup_checkbox",
    dynamicMenuItems: ["cherokeeLocationCheckbox"],
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "cherokee_niva_supplement_disclosure_questions_pdSupplement",
};
