import * as yup from "yup";
export const employmentsQueryKey = "employments";
export const licensesQueryKey = "licenses";
export const licenseTypeCategory = "personal";

export const headCells = [
  { key: "person_name", name: "Name" },
  { key: "department", name: "Department" },
  { key: "position", name: "Position" },
  { key: "agency", name: "Agency" },
  { key: "expiry", name: "Next Expiry", format: "date" },
  { key: "licenses", name: "Licenses / Certifications" },
];

export const employeeInitialValues = {
  lc_person: {
    first_name: "",
    last_name: "",
    email: "",
    external_id: "",
    date_of_birth: null,
    lc_attachment_group_id: null,
  },
  lc_business_entity_id: null,
  lc_position_type_id: null,
  lc_position_type: {
    lc_department_id: null,
  },
  hire_date: null,
  lc_work_location_id: null,
  lc_hr_location_id: null,
};

export const validationSchema = yup.object().shape({
  lc_person: yup.object({
    external_id: yup.string().required("Required Field"),
    email: yup.string().email("Wrong Email Format"),
  }),
  lc_business_entity_id: yup
    .number()
    .required("Required Field")
    .typeError("Required Field"),
});
