import { formats } from "components/Common/Tables/Common/helpers";

export const defaultInitialValues = {
  bd_party_id: null,
  tax_agency_entity_id: null,
  type_of_tax: "",
  taxing_period: null,
  amount: null,
};

export const tableQuestions =
  "Please complete if there are any federal, state and/or local outstanding tax liability owed by the applicant, owners, officers and/or any related business entities which have a financial or ownership interest.";

export const columns = [
  { title: "Name", field: "party_name" },
  { title: "Agency Name", field: "tax_agency_entity.business_name" },
  { field: "type_of_tax" },
  { field: "taxing_period", format: formats.monthYear },
  { title: "Amount", field: "amount", format: formats.currency },
];
