import { Grid, Paper } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import GridItem from "components/Common/Containers/Grid/GridItem";
import Button from "components/Common/CustomButtons/Button";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import useToggle from "hooks/useToggle";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { useViewAttachment } from "hooks/useViewAttachment";
import { FileViewer } from "../FileDropZone/components";
import { AttachmentsList } from "./AttachmentList";
import { EditAttachmentGroupDialog } from "./EditAttachmentGroupDialog";
import { useMultiFileDropzone } from "./useMultiFileDropzone";

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const useEditStatementDialog = ({
  groupList,
  addAttachmentGroup,
  syncGroupList,
}) => {
  const [year, setYear] = React.useState(null);
  const [currentAttachmentGroup, setCurrentAttachmentGroup] = React.useState(
    null
  );
  const { on, toggle, setOff } = useToggle();

  const onSelectYear = (e) => {
    const selectedYear = e.target?.value || e;
    if (selectedYear) {
      setYear(selectedYear);
      const existingAttachmentGroup = groupList.find(
        (group) => group.description == selectedYear // type casting is intentional here..
      );
      if (existingAttachmentGroup) {
        setCurrentAttachmentGroup(existingAttachmentGroup);
      } else {
        setCurrentAttachmentGroup(null);
        addAttachmentGroup(selectedYear, (newGroup) =>
          setCurrentAttachmentGroup(newGroup)
        );
      }
    }
  };

  const onClose = () => {
    setOff();
    syncGroupList();
    setYear(null);
    setCurrentAttachmentGroup(null);
  };

  return { onSelectYear, on, toggle, year, onClose, currentAttachmentGroup };
};

export const MultiYearFileUploader = ({
  value,
  name,
  title,
  addButtonLabel = "Add Attachments",
  ...props
}) => {
  const [loading, setLoading] = React.useState(true);
  const [groupList, setGroupList] = React.useState([]);

  const { attachmentBeingViewed, handleViewFile } = useViewAttachment();

  const {
    addAttachmentGroup,
    removeAttachmentItem,
    syncGroupList,
  } = useMultiFileDropzone({
    groupId: value,
    setFieldValue: props.setFieldValue,
    name,
    loading,
    setLoading,
    setGroupList,
    groupList,
  });

  const {
    onSelectYear,
    on,
    toggle,
    year,
    onClose,
    currentAttachmentGroup,
  } = useEditStatementDialog({
    groupList,
    addAttachmentGroup,
    syncGroupList,
  });

  const groupsWithAttachments = React.useMemo(
    () => groupList.filter((group) => group.attachments.length),
    [groupList]
  );

  const hasAttachments = groupsWithAttachments.length > 0;

  if (loading) return null;

  return (
    <>
      <SectionSubHeader label={title} divider />
      <FileViewer attachment={attachmentBeingViewed} />
      <EditAttachmentGroupDialog
        open={on}
        onClose={onClose}
        year={year}
        onSelectYear={onSelectYear}
        currentAttachmentGroup={currentAttachmentGroup}
      />
      <Grid item xs={12}>
        <Paper variant="outlined">
          {hasAttachments ? (
            <GridItem md={12} style={{ paddingTop: "0px" }}>
              <AttachmentsList
                groupsWithAttachments={groupsWithAttachments}
                onClickAttachment={handleViewFile}
                removeAttachmentItem={removeAttachmentItem}
              />
              <ButtonWrapper style={{ padding: "10px" }}>
                <Button
                  color="primary"
                  size="sm"
                  startIcon={<AddIcon />}
                  onClick={toggle}
                >
                  {addButtonLabel}
                </Button>
              </ButtonWrapper>
            </GridItem>
          ) : (
            <ButtonWrapper style={{ padding: "20px" }}>
              <Button color="primary" startIcon={<AddIcon />} onClick={toggle}>
                {addButtonLabel}
              </Button>
            </ButtonWrapper>
          )}
        </Paper>
      </Grid>
    </>
  );
};

MultiYearFileUploader.propTypes = {
  isMultiAttachmentGroup: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  setFieldValue: PropTypes.func,
  addButtonLabel: PropTypes.node,
};

MultiYearFileUploader.defaultProps = {
  isMultiAttachmentGroup: true,
};
