import { formats } from "components/Common/Tables/Common/helpers";
import { fieldTypes } from "components/Formik/formGroups/CrudSelect/CrudSelect";

const columns = [
  {
    field: "from_date",
  },
  {
    field: "to_date",
  },
  {
    field: "own_or_rent",
  },

  {
    field: "address",
    format: formats.address,
  },
  {
    field: "is_primary",
    format: formats.boolean,
  },
];

const infoAlert =
  "Beginning with your current residence(s) and working backward, provide the following information with respect to each place where you have lived (including residences while attending college or while in military service) during the past fifteen (15) years or since the age of 18, whichever is less.";

const fromToCurrentSchema = [
  {
    name: "from_date",
    type: fieldTypes.date,
    label: "From",
    xs: 4,
  },
  {
    name: "to_date",
    type: fieldTypes.dependantDatePicker,
    label: "To",
    xs: 4,
  },
  {
    name: "is_current",
    type: fieldTypes.checkBox,
    label: "Is current",
    xs: 4,
  },
];

const formSchema = [
  {
    name: "is_primary",
    type: fieldTypes.yesNo,
    label: "Is this your primary home address?",
    tooltip:
      "Please only select ONE address as your primary home address. Many jurisdictions require a single home address. The one you have selected as your primary home address will be used.",
  },
  {
    type: fieldTypes.subHeader,
    divider: true,
    label: "Home Address",
  },
  {
    type: fieldTypes.googleLookup,
    fields: {
      address: "address",
    },
  },
  {
    type: fieldTypes.addressFields,
    name: "address",
  },
  {
    type: fieldTypes.subHeader,
    divider: true,
    label: "Dates",
  },
  ...fromToCurrentSchema,
  {
    type: fieldTypes.subHeader,
    divider: true,
    label: "Details",
  },
  {
    type: fieldTypes.select,
    name: "own_or_rent",
    defaultValue: null,
    dynamicMenuItems: ["residentialOwnership"],
  },

  {
    type: fieldTypes.pdPartySelect,
    label: "Landlord or Mortgage Holder",
    name: "landlord_lender_pd_party_id",
  },
  {
    name: "roommate_information",
    type: fieldTypes.text,
    label: "Name & Contact Information of Roommates",
    xs: 12,
    rows: 2,
    tooltip: "Required for: Missouri, Indiana",
    tooltipProps: { type: "warning" },
  },
];

export default [
  {
    type: "table",
    infoAlert,
    columns,
    formSchema,
    endpoint: "residential_history_residence",
    nonRequired: ["roommate_information", "landlord_lender_pd_party_id"],
    infoAlerts: [
      {
        body: infoAlert,
      },
    ],
  },
];
