import PropTypes from "prop-types";
import { Grid, Paper, Divider, IconButton } from "@material-ui/core";
import CancelButton from "components/Common/CustomButtons/CancelButton";
import SaveButton from "components/Common/CustomButtons/SaveButton";
import React from "react";
import EditIcon from "@material-ui/icons/Edit";

import styled from "styled-components";

// ! Previously this component was lazy loaded. Was removed due to a bug (OC-4606)
// const RTE = React.lazy(() => import("components/Common/RTE/index.js"));
import RTE from "../RTE/index";

const NotesContainer = styled(Paper)`
  background-color: ${({ theme, hovered }) =>
    hovered && theme.palette.grey[100]};
`;

export const Notes = ({
  handleSave,
  note = "",
  submissionCallback = () => null,
}) => {
  const [preview, setPreview] = React.useState(true);
  const [hovered, setHovered] = React.useState(false);
  const [value, setvalue] = React.useState(note);

  const ref = React.useRef();

  const handleSubmit = () => {
    handleSave({ note: value });
    setPreview(true);
    submissionCallback();
  };

  const handleCancel = () => {
    setPreview(true);
    submissionCallback();
  };

  const reset = React.useCallback(() => {
    setvalue("");
  }, [setvalue]);

  React.useEffect(() => {
    setHovered(false);
  }, [preview]);

  React.useEffect(() => {
    reset();
  }, [reset, note]);

  return (
    <NotesContainer
      variant="outlined"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      $hovered={hovered && preview}
      onClick={() => {
        preview && setPreview(!preview);
      }}
    >
      {/* <Suspense fallback={<div>Loading Editor..</div>}> */}
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <RTE preview={preview} value={value} onChange={setvalue} ref={ref} />
          {!preview && (
            <>
              <Divider />
              <div>
                <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                <SaveButton onClick={handleSubmit}>Save</SaveButton>
              </div>
            </>
          )}
          {preview && (
            <IconButton style={{ height: "40px" }}>
              <EditIcon style={{ fontSize: "18px" }} />
            </IconButton>
          )}
        </div>
      </Grid>
      {/* </Suspense> */}
    </NotesContainer>
  );
};

Notes.propTypes = {
  handleSave: PropTypes.func,
  note: PropTypes.string,
  submissionCallback: PropTypes.func,
};
