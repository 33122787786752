import { formats } from "components/Common/Tables/Common/helpers";

export const columns = [
  {
    field: "from_date",
    format: formats.date,
  },
  {
    field: "to_date",
    format: formats.date,
  },
  {
    title: "Business Name",
    field: "business_name",
  },
  {
    field: "position",
  },
];

export const defaultInitialValues = {
  pd_business_entity_id: null,
  relative_pd_person_id: null,
  position: "",
  from_date: null,
  to_date: null,
  is_current: false,
};
