import React, { useState } from "react";
import { Select, InputLabel, FormControl, MenuItem } from "@material-ui/core";
import {
  usePdfState,
  usePdfDispatch,
  ACTIONS,
} from "components/DevTools/PdfTools/Context/PdfToolsProvider.jsx";

export default function FilterByAmount() {
  const { amountField, amount, tablesWithAmount, selected } = usePdfState();
  const dispatch = usePdfDispatch();

  const [evalOp, setEvalOp] = useState("<");

  const handleChangeAmountField = (e) => {
    dispatch({
      type: ACTIONS.SET_STATE,
      value: e.target.value,
      name: "amountField",
    });
  };

  const handleChangeEvaluation = (e) => {
    setEvalOp(e.target.value);
    dispatch({
      type: ACTIONS.SET_STATE,
      value: "*" + amountField + e.target.value + amount,
      name: "amountFilter",
    });
  };

  const handleChangeAmount = (e) => {
    dispatch({
      type: ACTIONS.SET_STATE,
      value: e.target.value,
      name: "amount",
    });
  };

  if (
    typeof tablesWithAmount === "object" &&
    selected &&
    tablesWithAmount[selected]
  ) {
    return (
      <div>
        <FormControl
          variant="filled"
          style={{ width: "150px", marginRight: "10px" }}
        >
          <InputLabel>Amount Filter</InputLabel>
          <Select value={amountField} onChange={handleChangeAmountField}>
            {tablesWithAmount[selected].map((val) => (
              <MenuItem key={val} value={val}>
                {val}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {amountField ? (
          <>
            <FormControl
              variant="filled"
              style={{ width: "150px", marginRight: "10px" }}
            >
              <InputLabel>$ Amount</InputLabel>
              <Select value={amount} onChange={handleChangeAmount}>
                {new Array(101).fill(0).map((val, i) => (
                  <MenuItem key={i + 1} value={"$" + i * 1000}>
                    {"$" + i * 1000}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="filled"
              style={{ width: "150px", marginRight: "10px" }}
            >
              <InputLabel>Evaluation</InputLabel>
              <Select value={evalOp} onChange={handleChangeEvaluation}>
                {[
                  { kind: "Less Than", op: "<" },
                  { kind: "Greater Than", op: ">" },
                ].map((val) => (
                  <MenuItem key={val.kind} value={val.op} id={val.kind}>
                    {val.kind}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }
  return <></>;
}
