import PropTypes from "prop-types";
import { formSchemas } from "components/Formik/formGroups/CrudSelect/CrudSelect";
import React from "react";
import PersonIcon from "@material-ui/icons/Person";
import _ from "lodash";
import PermissionedCrudSelect from "./PermissionedCrudSelect";

const getRelativeLabel = ({ relative_type }) => {
  if (!relative_type) return null;
  return `(${_.startCase(relative_type)})`;
};

const PDPersonSelect = ({
  name = "relative_pd_person_id",
  endpoint = "pdPerson",
  saveEndpoint = "pdPerson",
  formSchema = formSchemas.pdPersonSchema,
  label,
  usesPermission = true,
  ...rest
}) => {
  return (
    <PermissionedCrudSelect
      name={name}
      label={label}
      endpoint={endpoint}
      usesPermission={usesPermission}
      saveEndpoint={saveEndpoint}
      formSchema={formSchema}
      formTitle="Add / Edit Person"
      optionIcon={<PersonIcon color="primary" fontSize="small" />}
      getSecondaryLabel={getRelativeLabel}
      {...rest}
    />
  );
};

PDPersonSelect.propTypes = {
  overrideDefaultValues: PropTypes.object,
  endpoint: PropTypes.string,
  formSchema: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string,
  xs: PropTypes.number,
};

export default PDPersonSelect;
