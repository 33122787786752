import { Grid } from "@material-ui/core";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikNumberField, {
  NumberFormatTypes,
} from "components/Formik/fields/FormikNumberField";
import FormikSelect from "components/Formik/fields/FormikSelect";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import PDBusinessSelect from "components/Formik/formGroups/CrudSelect/selects/PDBusinessSelect.jsx";
import { InfoAlert } from "components/Layout/Alert/InfoAlert.jsx";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";

const Form = () => {
  const { taxReturnType, taxReturnActivityType } = useMenuOptions();

  return (
    <Grid container spacing={2}>
      <FormikSelect
        name="activity_type"
        label="Tax Activity Type"
        options={taxReturnActivityType}
        xs={4}
      />

      <PDBusinessSelect
        name="tax_agency_pd_business_entity_id"
        label="Tax Office"
        endpoint="government_business_entities"
        overrideDefaultValues={{
          business_type_checkbox: {
            is_government: true,
          },
        }}
      />

      <FormikNumberField
        label="Audit Year (If Applicable)"
        name="audit_year"
        type={NumberFormatTypes.year}
        xs={4}
      />

      <FormikNumberField
        label="Tax Year"
        name="tax_year"
        type={NumberFormatTypes.year}
        xs={4}
      />

      <FormikSelect
        name="region_type"
        label="Return Type"
        options={taxReturnType}
        xs={6}
      />

      <FormikNumberField
        label="Amount"
        name="amount"
        xs={4}
        helperText="If Applicable"
      />
    </Grid>
  );
};

const TaxAudit = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "tax_audit_taxReturnActivity",
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title="Audit, Adjustments & Non-Filings">
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default TaxAudit;
