import React from "react";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Letter(s) of Intent or Agreement",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload a copy of Letter(s) of Intent or Agreement(s) including any
        amendments, revisions or extensions to same with any/all Iowa excursion
        gambling boat(s), gambling structure(s) or racetrack enclosure(s) or
        parent companies as it relates to Iowa operations
      </>
    ),
  },
  {
    type: fieldTypes.dropZone,
    name: "iowa_adsw.iowa_adsw_letters_intent_attachment_group_id",
    description: "Gaming Building Info",
    label: "Upload Attachment",
    xs: 12,
  },

  {
    type: fieldTypes.divider,
  },
  {
    type: fieldTypes.subHeader,
    label: "Financial statements",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload your financial statement for the previous three (3) years in
        <CustomLink to="bd_financial_statements" />
      </>
    ),
  },
  {
    type: fieldTypes.divider,
  },
  {
    type: fieldTypes.subHeader,
    label: "Ownership Structure",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload a chart demonstrating ownership structure of applicant. Chart
        must show ultimate parent company’s ownership of applicant and all other
        subsidiaries in
        <CustomLink to="bd_corporate_documents" />
      </>
    ),
  },
  {
    type: fieldTypes.divider,
  },
  {
    type: fieldTypes.subHeader,
    label: "Gambling Treatment Program",
  },
  {
    type: fieldTypes.label,
    label:
      "Upload a copy of or details on your Problem Gambling Treatment Program.",
  },
  {
    type: fieldTypes.dropZone,
    name: "iowa_adsw.iowa_adsw_gambling_problem_treatment_attachment_group_id",
    description: "Gaming Building Info",
    label: "Upload Attachment",
    xs: 12,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "bdSupplement",
};
