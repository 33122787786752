import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <FormikTextField label="Description of Trust" name="description" xs={6} />
      <FormikTextField label="Name of Trustee(s)" name="trustee_names" xs={6} />
      <FormikTextField label="Location of Trust" name="location" xs={6} />
      <FormikTextField
        label="Names of other(s) with Interest in Trust"
        name="names_of_others"
        xs={6}
      />
    </Grid>
  );
};

const AssetsInTrust = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "assets_in_trust_assetInTrust",
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default AssetsInTrust;
