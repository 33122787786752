import React from "react";
import {
  formGroupTypes,
  fieldTypes,
} from "componentGenerators/formGenerator/config";
import { NumberFormatTypes } from "components/Common/Inputs/NumberField";
import { commonStrings } from "../../../PD/uiStrings";
import Tooltip from "components/Common/Tooltip";

export const formSchema = [
  {
    type: fieldTypes.bdPersonSelect,
    label: "Person Holding the Office",
    name: "related_bd_party_id",
    header: "Person Holding the Office",
    required: true,
  },
  {
    type: fieldTypes.subHeader,
    label: "Position Details",
    divider: true,
  },
  {
    type: fieldTypes.select,
    label: "Role",
    name: "related_bd_party_role",
    dynamicMenuItems: ["seniorEmploymentPositions"],
    tooltip: <Tooltip title={commonStrings.officer} />,
    required: true,
  },
  {
    type: fieldTypes.text,
    label: "Position/Title",
    name: "position",
    xs: 6,
  },
  {
    type: fieldTypes.text,
    label: "Duties",
    name: "participation_description",
    xs: 6,
  },
  {
    type: fieldTypes.text,
    label: "Reason For Leaving",
    name: "reason_left",
    xs: 10,
    helperText: "If applicable",
  },
  {
    type: fieldTypes.subHeader,
    divider: true,
    label: "Dates",
  },
  {
    formGroup: formGroupTypes.fromToCurrent,
  },
  {
    type: fieldTypes.subHeader,
    label: "Annual Compensation",
    divider: true,
  },
  {
    type: fieldTypes.number,
    label: "Previous Year",
    name: "compensation_previous_year",
    format: NumberFormatTypes.dollar,
  },
  {
    type: fieldTypes.number,
    label: "Subsequent Year",
    name: "compensation_subsequent_year",
    format: NumberFormatTypes.dollar,
  },
  {
    type: fieldTypes.subHeader,
    label: "Form of Compensation (select all that apply)",
    divider: true,
  },
  {
    type: fieldTypes.checkBoxGroup,
    //label: "",
    name: "compensation_type_checkbox",
    dynamicMenuItems: ["bdCompensationTypeCheckboxOptions"],
  },
];

export const columns = [
  {
    title: "Office Holder",
    field: "related_party_name",
  },
  {
    title: "Title/Position",
    field: "position",
  },
  {
    title: "Role",
    field: "related_bd_party_role",
    format: "startCase",
  },

  {
    title: "Held From",
    field: "from_date",
    format: "date",
  },
  {
    title: "Held To",
    field: "to_date",
    format: "date",
  },
];

export const tableQuestions = (
  <>
    Provide details of all Directors, Officers and Trustees who are or were
    involved in the operation of the gaming business that:
    <ul>
      <li>Currently hold office; or </li>
      <li>Have ceased to hold office in the last ten (10) years</li>
    </ul>
    {commonStrings.officer}
  </>
);

export const endpoint =
  "bdPosition?related_bd_party_role=director&related_bd_party_role=officer&related_bd_party_role=trustee&related_bd_party_role=manager";

export const nonRequired = [
  //"add_non_required_attributes_here",
];
