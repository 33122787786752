import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm";
import React, { useCallback } from "react";
import { useMenuOptions } from "hooks/useMenuOptions";
import { endpoint, defaultInitialValues } from "./config.js";
import BDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/BDPersonSelect";
import FormikTextField from "components/Formik/fields/FormikTextField.jsx";
import YesNo from "components/Formik/formGroups/YesNo";
import useGet from "hooks/useGet.js";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import CustomLink from "components/Common/Link";
import useEndPoint from "hooks/useEndpoint";

const Form = () => {
  const { iowaFormOfBusiness } = useMenuOptions();

  return (
    <Grid container spacing={1}>
      <SectionSubHeader>Application Contact Person</SectionSubHeader>
      <BDPersonSelect
        name="iowa_class_d.ia_class_d_contact_bd_person_id"
        label="Contact"
        xs={6}
      />
      <SectionSubHeader>
        Name of individual preparing this application
      </SectionSubHeader>
      <BDPersonSelect
        name="iowa_class_d.application_prepare_bd_person_id"
        label="Individual"
        xs={6}
      />
      <SectionSubHeader>maintenance of business records</SectionSubHeader>
      <SectionLabel>
        Name of person(s) or business(es) who maintain these records and
        telephone number:
      </SectionLabel>
      <FormikTextField
        label="Person(s) or Business(es)"
        name="iowa_class_d.business_records_maintenance_agent"
        xs={6}
      />
      <FormikTextField
        label="Telephone number"
        name="iowa_class_d.business_records_maintenance_agent_phone"
        xs={4}
      />
      <SectionSubHeader label="Iowa Class D Incorporation Details" divider />
      <SectionLabel>
        Iowa offers 3 additional types of businesses on the incorporation
        details section. Please review carefully and select the most appropriate
        answer.
      </SectionLabel>
      <FormikSelect
        label="Form of Business"
        name="iowa_class_d.ia_form_of_business"
        options={iowaFormOfBusiness}
        xs={4}
      />
      <FormikTextField
        label="Corporation Type"
        name="iowa_class_d.ia_form_of_business_corporation_type"
        helperText="(If Corporation Selected)"
        xs={3}
      />
      <FormikTextField
        label="Other"
        name="iowa_class_d.ia_form_of_business_other_description"
        helperText="(If Other Selected)"
        xs={4}
      />
      <SectionSubHeader label="Background Investigation" divider />
      <YesNo
        label="Are there any problem areas that you would like to discuss with an agent before the background investigation is initiated?"
        name="iowa_class_d.is_ucc_problem_areas"
      />
      <FormikTextField
        helperText="If yes explain"
        label="Explanation"
        name="iowa_class_d.ia_ucc_problem_explain"
        multiline
        rows={4}
        xs={12}
      />
      <SectionSubHeader
        label="forms of indebtedness issued or executed"
        divider
      />
      <SectionLabel label="Provide supporting documentation for the nature, type, terms, covenants and priorities of any outstanding bonds, loans, mortgages, trust deeds, notes, debentures or other forms of indebtedness issued or executed, or to be issued or executed by the corporation, which mature more than one (1)year from the date of issuance. Include the type, date, amount of initial and current debt, repayment terms, maturity date, interest rate, collateral used for each debt instrument and reason for each debtinstrument." />
      <Grid item>
        Please review your information in
        <CustomLink to="bd_loans" />
      </Grid>
    </Grid>
  );
};

export default function ApplicationInformationForm({ innerRef }) {
  // Fetch previously selected form_of_business from Applicant Details for Reference
  const { bdBusinessEntityEndpoint } = useEndPoint("");
  const { data, status } = useGet(bdBusinessEntityEndpoint);

  // return a new object containing intial values modified to increase client usability
  // in the INCORPORATION DETAILS section
  const getInitialValues = useCallback(() => {
    return status !== "success"
      ? defaultInitialValues
      : {
          ...defaultInitialValues,
          iowa_class_d: {
            ...defaultInitialValues.iowa_class_d,
            ia_form_of_business: data[0].form_of_business?.includes(
              "_corporation"
            )
              ? "corporation"
              : data[0].form_of_business,
          },
        };
  }, [data, status]);

  const formData = useForm({
    defaultInitialValues: getInitialValues(),
    endpoint,
  });

  return (
    <>
      {status === "success" && (
        <FormContextProvider formData={formData}>
          <EnhancedFormikPage showSaveButton={false} innerRef={innerRef}>
            <Form />
          </EnhancedFormikPage>
        </FormContextProvider>
      )}
    </>
  );
}
