import TennesseeVRAApplicationInformation from "components/Pages/Supplemental/BD/TennesseeVRA/TennesseeVRAApplicationInformation/TennesseeVRAApplicationInformation.jsx";
import TennesseeVRARequiredAttachments from "components/Pages/Supplemental/BD/TennesseeVRA/TennesseeVRARequiredAttachments/TennesseeVRARequiredAttachments.jsx";
import TennesseeVRALegalHistory from "components/Pages/Supplemental/BD/TennesseeVRA/TennesseeVRALegalHistory/TennesseeVRALegalHistory.jsx";
import TennesseeVRAManagers from "components/Pages/Supplemental/BD/TennesseeVRA/TennesseeVRAManagers/TennesseeVRAManagers.jsx";

export default {
  bd_tennessee_vra_supplement_application_details: TennesseeVRAApplicationInformation,
  bd_tennessee_vra_supplement_legal_history: TennesseeVRALegalHistory,
  bd_tennessee_vra_supplement_attachments: TennesseeVRARequiredAttachments,
  bd_tennessee_vra_supplement_managers: TennesseeVRAManagers,
};
