//BD
//BUSINESS INFORMATION
import BD_ApplicationDetails from "components/Pages/BD/BusinessInformation/ApplicationDetails";
import BD_RelatedBusinessEntities from "components/Pages/BD/BusinessInformation/RelatedBusinessEntities/RelatedBusinessEntities";
import BD_CorporateDocuments from "components/Pages/BD/BusinessInformation/CorporateDocuments/CorporateDocuments";

//OFFICERS & EMPLOYEES
import bd_directors_trustees_officers from "components/Pages/BD/OfficersAndEmployees/DirectorsTrusteesOfficers/schemas";

import BD_Partners from "components/Pages/BD/OfficersAndEmployees/Partners/Partners";
import BD_Owners from "components/Pages/BD/OfficersAndEmployees/Owners/Owners";
import BD_EmployeeCompensation from "components/Pages/BD/OfficersAndEmployees/EmployeeCompensation";

//LICENSES & PERMITS
import BD_Licenses from "components/Pages/BD/LicenseAndPermits/Licenses";

//Legal HISTORY
import bd_criminal_proceedings from "components/Pages/BD/Criminal/CriminalProceedings/schemas";
import BD_Litigations from "components/Pages/BD/Criminal/Litigations/Litigations";
import BD_AntitrustAndTradeJudgements from "components/Pages/BD/Criminal/AntitrustAndTradeJudgements/AntitrustAndTradeJudgements";
import BD_StatutoryAndRegulatoryFines from "components/Pages/BD/Criminal/StatutoryAndRegulatoryFines/StatutoryAndRegulatoryFines";
import BD_Bankruptcies from "components/Pages/BD/Criminal/Bankruptcies/Bankruptcies";
import BD_Testimonies from "components/Pages/BD/Criminal/Testimonies/Testimonies";
import BD_GovernmentReports from "components/Pages/BD/Criminal/GovermentReports";

//SERVICES PROVIDERS
import BD_AuditorsAndAccountants from "components/Pages/BD/ServiceProviders/AuditorsAndAccountants/AuditorsAndAccountants";
import BD_LegalAdvisors from "components/Pages/BD/ServiceProviders/LegalAdvisors/LegalAdvisors";
import BD_Lobbyists from "components/Pages/BD/ServiceProviders/Lobbyists/Lobbyists";
import BD_Contracts from "components/Pages/BD/ServiceProviders/Contracts/Contracts";

//FINANCIAL
import BD_StockDescription from "components/Pages/BD/Financial/StockDescription/StockDescription";
import BD_Shareholders from "components/Pages/BD/Financial/Shareholders/Shareholders";
import BD_SecurityOptions from "components/Pages/BD/Financial/SecurityOptions/SecurityOptions";
import BD_SecurityOptionsHolders from "components/Pages/BD/Financial/SecurityOptionsHolders/SecurityOptionsHolders";
import BD_OffBookAccounts from "components/Pages/BD/Financial/OffBookAccounts/OffBookAccounts";
import BD_NomineeAccounts from "components/Pages/BD/Financial/NomineeAccounts/NomineeAccounts";
import BD_BankAccounts from "components/Pages/BD/Financial/BankAccounts/BankAccounts";
import BD_Loans from "components/Pages/BD/Financial/Loans/Loans";
import BD_FinancialDocuments from "components/Pages/BD/Financial/FinancialDocuments/FinancialDocuments";
import BD_TaxReturns from "components/Pages/BD/Financial/TaxReturns/TaxReturns";
import bd_tax_activity from "components/Pages/BD/Financial/AuditAdjustments/AuditAdjustments";
import BD_FinancialStatements from "components/Pages/BD/Financial/FinancialStatements/FinancialStatements";
import BD_Assets from "components/Pages/BD/Financial/BusinessAssets/BusinessAssets";
import bd_insurance_claims from "components/Pages/BD/Financial/BDInsuranceClaims/schemas";
import BD_ProfitSharePlan from "components/Pages/BD/Financial/ProfitSharePlan/BDProfitSharePlan";
import BD_Trust from "components/Pages/BD/Financial/Trust/Trust";
import bd_credit_report from "components/Pages/BD/Financial/CorporateReports/schemas";

//CONTRIBUTIONS
import BD_BridesAndKickbacks from "components/Pages/BD/Contributions/BribesAndKickbacks/BribesAndKickbacks";
import BD_PolicaticalContributions from "components/Pages/BD/Contributions/PoliticalContributions/PoliticalContributions";
import BD_KnowledgeOfContributions from "components/Pages/BD/Contributions/KnowledgeOfContributions/KnowledgeOfContributions";

//BUSINESS & PEOPLE
import BD_People from "components/Pages/BD/BusinessesAndPeople/People/People";
import BD_BusinessEntities from "components/Pages/BD/BusinessesAndPeople/BusinessEntities/BusinessEntities";
import { getFormComponents } from "componentGenerators/helpers";

const pageSchemas = {
  bd_credit_report,
  bd_tax_activity,
  bd_criminal_proceedings,
  bd_directors_trustees_officers,
  bd_insurance_claims,
};

const bd_pages = {
  ...getFormComponents(pageSchemas),
  //BUSINESS INFORMATION
  bd_application_details: BD_ApplicationDetails,
  bd_related_business_entities: BD_RelatedBusinessEntities,
  bd_corporate_documents: BD_CorporateDocuments,

  //LICENSE & PERMITS
  bd_licenses: BD_Licenses,

  //FINANCIAL
  bd_off_book_accounts: BD_OffBookAccounts,
  bd_nominee_accounts: BD_NomineeAccounts,
  bd_bank_accounts: BD_BankAccounts,
  bd_loans: BD_Loans,
  bd_share_structure: BD_StockDescription,
  bd_shareholders: BD_Shareholders,
  bd_financial_documents: BD_FinancialDocuments,
  bd_financial_statements: BD_FinancialStatements,
  bd_tax_returns: BD_TaxReturns,

  bd_assets: BD_Assets,
  bd_security_options: BD_SecurityOptions,
  bd_options_holders: BD_SecurityOptionsHolders,
  bd_profit_share_plan: BD_ProfitSharePlan,
  bd_trusts: BD_Trust,

  //Legal HISTORY
  bd_litigations: BD_Litigations,
  bd_testimonies: BD_Testimonies,
  bd_government_reports: BD_GovernmentReports,
  bd_antitrust_and_trade: BD_AntitrustAndTradeJudgements,
  bd_statutory_fines: BD_StatutoryAndRegulatoryFines,
  bd_bankruptcies: BD_Bankruptcies,

  //CONTRIBUTIONS
  bd_political_contributions: BD_PolicaticalContributions,
  bd_bribes_and_kickbacks: BD_BridesAndKickbacks,
  bd_knowledge_of_contributions: BD_KnowledgeOfContributions,

  //OFFICERS & EMPLOYEEES
  bd_employee_compensation: BD_EmployeeCompensation,
  bd_partners: BD_Partners,
  bd_owners: BD_Owners,

  //SERVICES PROVIDERS
  bd_auditors_and_accountants: BD_AuditorsAndAccountants,
  bd_legal_advisors: BD_LegalAdvisors,
  bd_lobbyists: BD_Lobbyists,
  bd_contracts: BD_Contracts,

  //BUSINESSES & PEOPLE
  bd_people: BD_People,
  bd_business_entities: BD_BusinessEntities,
};

export default bd_pages;
