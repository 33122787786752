import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import Grid from "@material-ui/core/Grid";

export default function GridContainer({ ...props }) {
  const { children, className, ...rest } = props;
  return (
    <Grid container spacing={1} className={className} {...rest}>
      {children}
    </Grid>
  );
}

GridContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
