import React from "react";
import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import DisplayTable from "components/Common/Tables/DisplayTable";
import useFetch from "queryHooks/useFetch";
import { columns, dependsOnQueryCount } from "./config";
import { Box } from "@material-ui/core";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const ElkValleyBusinessInterests = ({ name }) => {
  const { data = [], status } = useFetch(
    "elk_valley_business_interests_associated_business_entities_is_kp_or_5_percent_owner_last2yrs",
    "elk_valley_business_interests_associated_business_entities_is_kp_or_5_percent_owner_last2yrs"
  );
  return (
    <FormSectionWrapper title={name}>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={status}>
          <DisplayTable
            records={data}
            linkTo={["business_interests"]}
            columns={columns}
            dependsOnQueryCount={dependsOnQueryCount}
          />
        </LoadingComponent>
      </Box>
    </FormSectionWrapper>
  );
};

export default ElkValleyBusinessInterests;
