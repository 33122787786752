import React from "react";

export const columns = [
  {
    title: "Business Entity",
    field: "bd_business_entity.business_name",
  },
  {
    title: "Nominee Name",
    field: "related_party_name",
  },
  {
    title: "Institution",
    field: "bank_bd_business_entity.business_name",
  },
  {
    title: "Account Number",
    field: "account_number",
  },
  {
    field: "account_type",
    format: "startCase",
  },
];

export const tableQuestions = (
  <>
    During the last ten (10) years, has the Applicant, its parent company or any
    subsidiary maintained any numbered account or any account in the name of a
    nominee for the Company?
  </>
);

export const defaultInitialValues = {
  related_bd_party_role: "nominee",
  bd_business_entity_id: null,
  related_bd_party_id: null,
  bank_bd_business_entity_id: null,
  from_date: null,
  to_date: null,
  is_current: false,
  account_number: null,
  account_type: null,
  nature: null,
};
