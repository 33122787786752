import React from "react";
import { formats } from "components/Common/Tables/Common/helpers";

export const columns = [
  { title: "Creditor", field: "creditor_pd_party_name" },
  { title: "Relative Name", field: "relative_pd_person.full_name" },
  { field: "account_number" },
  { field: "incurred_date", format: "date" },
  { field: "original_amount", format: formats.currency },
  { field: "outstanding_amount", format: formats.currency },
  {
    title: "Current",
    field: "is_current",
    format: "boolean",
    sortType: "boolean",
  },
];

export const tableQuestions = (
  <>
    List below the information requested with regard to all mortgages or liens
    due and owing on real estate for which you, your spouse or your dependent
    children are obligated.
  </>
);

export const nonRequired = [
  "relative_pd_person_id",
  "real_estate_loans_attachment_group_id",
];

export const endpoint = "real_estate_loans_mortgagePayable";

export const defaultInitialValues = {
  type: "mortgage",
  relative_pd_person_id: null,
  creditor_pd_party_id: null,
  nature_of_payable: "",
  account_number: "",
  incurred_date: null,
  original_amount: null,
  periodic_payment_details: "",
  mortgage_details: "",
  interest_rate: null,
  outstanding_amount: null,
  due_date: null,
  real_estate_loans_attachment_group_id: null,
  is_current: true,
};

export const modalTitle = "Mortgage and Liens";

export const dataHookProps = {
  defaultInitialValues,
  nonRequired,
  endpoint,
};
