import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import AttachmentIcon from "@material-ui/icons/Attachment";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { dayjsUTC } from "helpers/apiHelpers";
import useFetch from "queryHooks/useFetch";
import React from "react";
import { FileViewer } from "../../../../Common/FileDropZone/components";

export const AttachmentLink = ({
  id,
  name,
  updated_at,
  attachment_group_id,
  dense = false,
}) => {
  const formattedDate = dayjsUTC(updated_at, "MMM D YYYY");
  const isPdf = name.substring(name.lastIndexOf(".") + 1) === "pdf";

  const { data, refetch, isSuccess } = useFetch(
    ["attachment", id],
    `attachmentGroup/${attachment_group_id}/attachment/${id}`,
    {
      enabled: false,
    }
  );

  return (
    <>
      <FileViewer attachment={data?.[0]} isLoading={!isSuccess} />

      <ListItem button dense={dense} onClick={refetch}>
        <ListItemIcon>
          {isPdf ? <PictureAsPdfIcon /> : <AttachmentIcon />}
        </ListItemIcon>
        {dense ? (
          <ListItemText primary={name} />
        ) : (
          <ListItemText
            primary={name}
            secondary={`Last Updated: ${formattedDate}`}
          />
        )}
      </ListItem>
    </>
  );
};
