import { useEffect, useReducer, useState } from "react";
import reducer from "reducers/table";
import { useAuth } from "context/auth";

import {
  createModel,
  updateModel,
  deleteModel,
  getModel,
} from "helpers/apiHelpers";

import { generateEndpoint, removeAttributes } from "helpers/routeHelpers";

export default function useTableData(endpoint, noId, triggerFetch) {
  const auth = useAuth();
  const { employee_id, bd_organization_id, account_type } = useAuth();
  const [state, dispatch] = useReducer(reducer, []);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(true);

  //Driving the logic using employee_id, if we dont have it it must be a business
  let fullEndpoint = !noId ? generateEndpoint(endpoint, auth) : endpoint;

  //remove everything after the endpoint for patch operations
  let noAttributeEndpoint = removeAttributes(fullEndpoint);
  let cleanEndpoint = removeAttributes(endpoint);

  function loadTableData(data) {
    if (data !== undefined) {
      setLoading(false);
    }
    dispatch({
      type: "LOAD",
      data,
    });
  }

  function editDispatch(row, item) {
    dispatch({
      type: "EDIT_ROW",
      rowIndex: row,
      row: item,
    });
  }

  function deleteDispatch(item) {
    dispatch({
      type: "DELETE_ROW",
      rowIndex: item,
    });
  }

  function addDispatch(item) {
    dispatch({
      type: "ADD_TO_TABLE",
      row: item,
    });
  }
  function onAdd(row) {
    return createModel(noAttributeEndpoint, row).then(({ data }) => {
      addDispatch(data);
      return data;
    });
  }

  function onEdit(row) {
    return updateModel(noAttributeEndpoint, state[selectedRow].id, row).then(
      ({ data }) => {
        editDispatch(selectedRow, data);
        return data;
      }
    );
  }

  function onDelete(row) {
    // const { attachment_group_id } = row
    // if (attachment_group_id) {
    //TODO: DELETE ATTACHMENT GROUP

    // }
    return deleteModel(noAttributeEndpoint, row.id).then(() => {
      deleteDispatch(row.tableData.id);
      return row.tableData.id;
    });
  }

  useEffect(() => {
    getModel(fullEndpoint)
      .then(loadTableData)
      .catch((error) => {
        return { error: error };
      });
  }, [employee_id, bd_organization_id, fullEndpoint, triggerFetch]);

  return {
    loading,
    state,
    currentRow: (selectedRow !== null && state[selectedRow]) || {},
    onAdd,
    onEdit,
    onDelete,
    editDispatch,
    deleteDispatch,
    addDispatch,
    selectedRow,
    fullEndpoint,
    noAttributeEndpoint,
    cleanEndpoint,
    employee_id,
    bd_organization_id,
    setSelectedRow,
    loadTableData,
    account_type,
  };
}
