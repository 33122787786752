import { useCallback } from "react";

const readFile = (file, handleSuccess, setLoading) => {
  setLoading(true);
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onabort = () => console.warn("file reading was aborted");
  reader.onerror = () => console.warn("file reading has failed");
  reader.onload = () => {
    handleSuccess(reader.result);
    setLoading(false);
  };
};

const useFileLoad = ({ files, setFiles, setLoading }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((fileData) => {
        readFile(
          fileData,
          (parsedFile) => {
            setFiles([{ name: fileData.name, file: parsedFile }].concat(files));
          },
          setLoading
        );
      });
    },
    [files, setLoading, setFiles]
  );
  return { onDrop };
};

export default useFileLoad;
