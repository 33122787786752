export const columns = [
  //{ title: "Company", field: "bd_business_entity.business_name" },
  { title: "Year", field: "effective_year" },
  { field: "description" },
];

export const defaultInitialValues = {
  effective_year: null,
  corporate_report_attachment_group_id: null,
  //bd_business_entity_id: null,
  report_type: "compliance_officer_report",
  description: "",
};

export const endpoint =
  "bdCorporateReport?report_type=compliance_officer_report";
