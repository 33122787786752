import { Grid } from "@material-ui/core";
import { NumberFormatTypes } from "components/Common/Inputs/NumberField";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikCheckBox from "components/Formik/fields/FormikCheckBox.jsx";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import FormikNumberField from "components/Formik/fields/FormikNumberField.jsx";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import PDPartySelect from "components/Formik/formGroups/CrudSelect/selects/PDPartySelect";
import PDRelativeSelect from "components/Formik/formGroups/CrudSelect/selects/PDRelativeSelect.jsx";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import ColumnSum from "components/Layout/SectionsLayout/ColumnSum.jsx";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import { useMenuOptions } from "hooks/useMenuOptions.js";
import React from "react";
import {
  columns,
  dataHookProps,
  modalTitle,
  sortingOptions,
  tableQuestions,
} from "./config.js";

const Form = () => {
  const { paymentPeriod } = useMenuOptions();

  return (
    <Grid container spacing={2}>
      <PDRelativeSelect
        label="Spouse or Child (If Applicable)"
        header="Select if held by Spouse or Dependent Child"
      />

      <SectionSubHeader header="Debtor" divider />
      <PDPartySelect name="debtor_pd_party_id" label="Debtor" />

      <SectionSubHeader header="Loan Details" divider />
      <FormikNumberField
        label="Interest Rate"
        name="interest_rate"
        xs={4}
        type={NumberFormatTypes.percent}
      />
      <FormikNumberField
        label="Original Loan Amount"
        name="original_loan_amount"
        xs={4}
      />
      <FormikDatePicker label="Loan Date" name="original_loan_date" />
      <FormikDatePicker label="Due Date" name="date_due" />
      <FormikTextField
        label="Total Payments"
        name="total_payments"
        placeholder="0"
        xs={4}
      />
      <FormikNumberField
        label="Current Balance"
        name="current_balance"
        xs={4}
      />
      <FormikNumberField
        label="Required Payment"
        name="payment_required"
        xs={4}
      />
      <FormikSelect
        label="Payment Period"
        name="payment_period_type"
        options={paymentPeriod}
        xs={4}
      />
      <FormikCheckBox
        label="Is Current - Current loans will be included in the Net Worth Statement."
        name="is_current"
        helperText="test"
        xs={12}
      />
      <FormikTextField
        header="Nature & Security"
        divider
        label="Nature/Purpose of Loan"
        name="nature_of_loan"
        xs={6}
      />
      <FormikTextField
        label="Collateral/Security, if any"
        name="security_of_loan"
        rows={2}
        xs={6}
      />
      <SectionSubHeader label="Attachments" divider />
      <SectionLabel label="Some jurisdictions require additional documents.  If needed, you will be prompted with instructions outlining what documents are needed." />
      <FormikFileDropzone
        description="Loans And Receivables"
        label="Upload Attachment"
        name="attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};

const LoansAndReceivables = ({ name }) => {
  const formData = useTabularData(dataHookProps);

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={modalTitle}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        footer={
          <ColumnSum
            name="current_balance"
            data={formData.data}
            label="Total Current Balance"
          />
        }
        columns={columns}
        addButtonText="Add Record"
        {...sortingOptions}
      />
    </FormSectionProvider>
  );
};

export default LoansAndReceivables;
