import { Box, Card, Container, Grid, Typography } from "@material-ui/core";
import BusinessIcon from "@material-ui/icons/Business";
import DeleteConfirmation from "components/Common/Dialogs/DeleteConfirmation";
import BusinessForm from "components/Pages/LicensingControl/Business/BusinessForm.js";
import {
  defaultInitialValues,
  endpoint,
  queryKey,
  validationSchema,
} from "components/Pages/LicensingControl/Business/config.js";
import { BusinessEmploymentTable } from "components/Pages/LicensingControl/Businesses/BusinessEmploymentTable";
import { BusinessDetailsModal } from "components/Pages/LicensingControl/Businesses/components/BusinessDetailsModal";
import ActionsAndStats from "components/Pages/LicensingControl/components/ActionsAndStats";
import AddEntity from "components/Pages/LicensingControl/components/AddEntity";
import { ExpiringSoon } from "components/Pages/LicensingControl/components/ExpiringCard";
import { GenerateReportDialog } from "components/Pages/LicensingControl/components/GenerateReportDialog";
import {
  findExpiringSoon,
  findNonCompliant,
  extractLicenses,
} from "components/Pages/LicensingControl/helpers.js";
import { useRecordFromQueryParam } from "components/Pages/LicensingControl/hooks/useRecordFromQueryParam";
import useTabularData from "hooks/tableHooks/useTabularData";
import useToggle from "hooks/useToggle";
import React from "react";
import withErrorBoundary from "hocs/withErrorBoundary";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const HeaderArea = () => (
  <Box display="flex" alignItems="center">
    <Typography variant="h1" style={{ paddingRight: "20px" }} gutterBottom>
      Businesses
    </Typography>
  </Box>
);

const Businesses = ({ location }) => {
  const detailsDialog = useToggle();

  const generateReportDialog = useToggle();

  const addEntityDialog = useToggle();

  const [
    licensesFormInitiallyOpen,
    setLicensesFormInitiallyOpen,
  ] = React.useState(false);

  const formData = useTabularData({
    defaultInitialValues,
    queryKey: queryKey,
    endpoint: endpoint,
    noValidate: true,
    fetchOptions: { useFullEndpoint: false },
  });

  const {
    data = [],
    status,
    editableListActions: { handleEditRecord, handleDeleteRecord },
    currentRecord,
    open,
    clearCurrentRecord,
    isNewRecord,
    deleteDialogProps,
    formikProps: { open: _open, onCancel: _onCancel, ...businessFormikProps },
  } = formData;

  const { clearQueryParam } = useRecordFromQueryParam({
    handleEditRecord,
    isSuccess: status === "success",
    location,
  });

  const handleDetailsModalClose = () => {
    clearCurrentRecord();
    setLicensesFormInitiallyOpen(false);
    clearQueryParam();
  };

  const nonCompliantBusinesses = React.useMemo(
    () => (status === "success" ? findNonCompliant(data) : []),
    [data, status]
  );

  const expiringSoonBusinesses = React.useMemo(
    () => (status === "success" ? findExpiringSoon(data) : []),
    [data, status]
  );

  const getSortedRecords = (records) =>
    records
      .filter(({ expiration_date }) => !!expiration_date)
      .sort(
        (a, b) => new Date(a.expiration_date) - new Date(b.expiration_date)
      );

  const sortedRecords = React.useMemo(
    () => (status === "success" ? getSortedRecords(extractLicenses(data)) : []),
    [data, status]
  );

  return (
    <>
      <DeleteConfirmation {...deleteDialogProps} />
      {currentRecord && (
        <BusinessDetailsModal
          currentRecord={currentRecord}
          open={!isNewRecord && open}
          onClose={handleDetailsModalClose}
          formikProps={businessFormikProps}
          licensesFormInitiallyOpen={licensesFormInitiallyOpen}
        />
      )}
      {generateReportDialog.on && (
        <GenerateReportDialog
          open={generateReportDialog.on}
          onCancel={generateReportDialog.setOff}
          closeDialog={generateReportDialog.setOff}
        />
      )}
      <AddEntity
        open={addEntityDialog.on}
        handleClose={addEntityDialog.setOff}
        handleEditEntityRecord={handleEditRecord}
        setLicensesFormInitiallyOpen={setLicensesFormInitiallyOpen}
        initialValues={defaultInitialValues}
        validationSchema={validationSchema}
        endpoint={endpoint}
        queryKey={queryKey}
        modelTitle="Add Business"
      >
        <BusinessForm />
      </AddEntity>
      <Container>
        <Grid container spacing={6} direction="row" justify="center">
          <Grid item xs={12}>
            <HeaderArea />
          </Grid>
          <Grid item xs={5}>
            <LoadingComponent status={status}>
              <ActionsAndStats
                employeeCount={data.length}
                openAddEntityForm={addEntityDialog.setOn}
                openGenerateReportForm={generateReportDialog.setOn}
                nonCompliantCount={nonCompliantBusinesses.length}
                expiringSoonCount={expiringSoonBusinesses.length}
                expiringSoonLabel={
                  <>
                    Businesses expiring <br /> in the next 30 days
                  </>
                }
                icon={<BusinessIcon fontSize="large" color="primary" />}
                nonCompliantLabel="Businesses compliant"
                addButtonLabel="Add Business"
                addEntityButtonLin="manage_business"
              />
            </LoadingComponent>
          </Grid>
          <Grid item xs={7}>
            <ExpiringSoon
              handleClickRecord={handleEditRecord}
              licensesData={sortedRecords}
              label="Expiring Business Licenses"
              keyLabel={"businesses"}
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingComponent status={status}>
              <Card variant="outlined">
                <BusinessEmploymentTable
                  records={data}
                  openDetails={detailsDialog.setOn}
                  handleClickRecord={handleEditRecord}
                  handleDeleteRecord={handleDeleteRecord}
                />
              </Card>
            </LoadingComponent>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default withErrorBoundary(Businesses);
