import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import Tooltip from "components/Common/Tooltip";
import { useMenuOptions } from "hooks/useMenuOptions.js";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents/index.js";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone.jsx";
import FormikAutocomplete from "components/Formik/fields/FormikAutocomplete.jsx";
import { parseInt } from "lodash";

const Form = () => {
  const { securityStructureOptions } = useMenuOptions();

  return (
    <Grid container spacing={2}>
      <FormikTextField label="Option Name" name="option_name" xs={4} />

      <FormikSelect
        label="Security Type"
        name="security_type"
        options={securityStructureOptions}
        xs={4}
      />

      <FormikNumberField
        label="Number Authorized"
        name="shares_authorized_count"
        type="none"
        xs={4}
      >
        <Tooltip title="Amount of securities subject to option" />
      </FormikNumberField>

      <FormikAutocomplete
        label="Option Grant Years"
        name="option_grant_years"
        xs={12}
        multiple
        freeSolo
        helperText="(enter year then press return)"
        options={[].map(() => "")} //gotta update this later
      />

      <FormikDatePicker
        label="Option Expiration"
        name="option_expiration_date"
      />

      <SectionLabel>
        Conditions under which the options were or will be granted
      </SectionLabel>

      <FormikTextField
        multiline
        rows={4}
        label="Conditions"
        name="option_condition"
        xs={12}
      />

      <SectionSubHeader divider label="Option Plans" />

      <SectionLabel>
        Attach copies of any option plans or proxy statements that provide
        additional information, but not limited to, the consideration for
        granting the option and the year or years during, and the terms under
        which optionees became or will become entitled to exercise the options
        and when such options expire.
      </SectionLabel>

      <FormikFileDropzone
        description="Option Plan"
        label="Upload Attachment"
        name="attachment_group_id"
        size={12}
      />
    </Grid>
  );
};

const SecurityOptions = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint:
      "bdSecurityStructure?security_type=option&security_type=warrant&security_type=right",
    // to convert strings to type integer, FormikAutocomplete only returns strings in the array currently
    postValues: ({ option_grant_years, ...values }) => ({
      option_grant_years: option_grant_years.map((val) => parseInt(val)),
      ...values,
    }),
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default SecurityOptions;
