import { requiredString } from "helpers/validationSchemaGenerator";
import * as yup from "yup";

export const validation = yup.object().shape({
  name: requiredString,
});

export const headCells = [
  { key: "business_name", name: "Name" },
  { key: "agency", name: "Agency" },
  { key: "expiry", name: "Next Expiry" },
  { key: "licenses", name: "Licenses / Certifications" },
];
export const employmentsQueryKey = "lcBusinessEntity";
export const licensesQueryKey = "licenses";
export const licenseTypeCategory = "business";

export const endpoint = "licensing/lcBusinessEntity";

export const defaultInitialValues = {
  lc_license_type: { lc_licensing_agency_id: null },
  lc_business_entity_id: null,
  lc_license_type_id: null,
  expiration_date: null,
  current_status: "",
  license_number: null,
  start_date: null,
};
