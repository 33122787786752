import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";

import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm";
import React from "react";
import { SectionSubHeader } from "components/Common/LayoutComponents/index";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { defaultInitialValues } from "./config";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import YesNo from "components/Formik/formGroups/YesNo";

const MichiganFinancialsForm = () => {
  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Bond Information" />
      <YesNo
        label="Have you ever been bonded for any purpose or been refused or denied any type of bond?"
        name="michigan.has_been_bonded_refused_or_denied_bonds"
        xs={12}
      />
      <FormikTextField
        label="Employer For Whom You Were Bonded"
        name="michigan.bond_employer_name"
        xs={4}
      />
      <FormikTextField
        label="Reason for Bond"
        name="michigan.bond_reason"
        xs={4}
      />
      <FormikTextField label="Bond Issuer" name="michigan.bond_issuer" xs={4} />
      <YesNo label="Was bond Called?" name="michigan.is_bond_called" xs={12} />
      <FormikDatePicker
        label="Date Bond Was Called"
        name="michigan.bond_called_date"
        xs={4}
      />
      <FormikTextField
        label="Reason Bond Was Called"
        name="michigan.bond_called_reason"
        xs={4}
      />

      <SectionSubHeader label="Tax Information" />
      <YesNo
        label="Have you filed all required federal, state, and local tax returns with the appropriate agencies for yourself and all related business entities in which you 
          have a beneficial interest during the last five years (initial applicants) or since your last disclosure (renewal applicants)?"
        name="michigan.has_filed_all_tax_returns_last_five_years"
        xs={12}
      />
      <FormikTextField
        multiline
        rows={4}
        label="Brief Explanation"
        name="michigan.filed_all_tax_returns_last_five_years_details"
        xs={12}
      />
    </Grid>
  );
};

const MichiganFinancials = ({ name }) => {
  const formData = useForm({
    defaultInitialValues,
    endpoint: "michigan_financials_pdSupplement",
  });

  return (
    <FormSectionProvider formData={formData} title={`Michigan ${name}`}>
      <EnhancedFormikPage>
        <MichiganFinancialsForm />
      </EnhancedFormikPage>
    </FormSectionProvider>
  );
};

export default MichiganFinancials;
