import { Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import FromToCurrent from "components/Formik/formGroups/FromToCurrent";
import FormikTextField from "components/Formik/fields/FormikTextField";
import React from "react";
import Tooltip from "components/Common/Tooltip";
import { commonStrings } from "../../../PD/uiStrings";
import BDPartySelect from "components/Formik/formGroups/CrudSelect/selects/BDPartySelect.jsx";

export const AuditorsAndAccountantsForm = () => {
  return (
    <Grid container spacing={2}>
      <BDPartySelect
        name="related_bd_party_id"
        label="Auditor or Accountant"
        header="Auditor or Accountant"
      />

      <FormikTextField
        name="representative_name"
        label="Name of Accountant (if applicable)"
        header="Accountant Name"
        xs={6}
      >
        <Tooltip title={commonStrings.accountant} />
      </FormikTextField>

      <SectionSubHeader label="Dates" divider />

      <FromToCurrent type="yearMonth" />

      <FormikTextField
        multiline={true}
        rows={4}
        label="Investigation Reason"
        name="investigation_reason"
        xs={12}
      />
    </Grid>
  );
};
