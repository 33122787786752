import React from "react";
import { formats } from "components/Common/Tables/Common/helpers";
import { address } from "helpers/initialValues";

export const columns = [
  {
    field: "from_date",
    format: formats.monthYear,
  },
  {
    field: "to_date",
    format: formats.monthYear,
  },
  {
    field: "school_name",
  },
  {
    field: "school_type",
    format: formats.startCase,
  },
  {
    field: "program",
  },
  {
    field: "degree",
  },
];

export const tableQuestions = (
  <>
    Beginning with secondary school (high school), provide details with respect
    to each school, college, graduate or post graduate school you have attended.
  </>
);

export const defaultInitialValues = {
  school_name: "",
  school_phone: "",
  school_address: address,
  from_date: null,
  to_date: null,
  program: "",
  school_type: null,
  degree: "",
  did_graduate: true,
  reason_for_leaving: "",
  education_attachment_group_id: null,
};
