import { Grid } from "@material-ui/core";
import useEndPoint from "hooks/useEndpoint";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";

import FormikNumberField from "components/Formik/fields/FormikNumberField.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, dataHookProps, tableQuestions } from "./config.js";

import { NumberFormatTypes } from "components/Common/Inputs/NumberField";
import PDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/PDPersonSelect.jsx";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents/index.js";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Registered owner" />
      <SectionLabel label="Registered owner will default to applicant" />
      <PDPersonSelect
        name="relative_pd_person_id"
        label="Owner"
        helperText="Select if held by another person"
      />
      <SectionSubHeader label="Vehicle Information" />
      <FormikTextField label="Make / Model" name="vehicle_model" xs={4} />
      <FormikNumberField
        label="Vehicle Year"
        name="vehicle_year"
        type={NumberFormatTypes.year}
        xs={4}
      />
      <FormikTextField
        label="License Plate Number"
        name="license_plate_number"
        xs={12}
      />
    </Grid>
  );
};

const IowaVehicles = ({ name }) => {
  const { noAttributeEndpoint } = useEndPoint("iowa_vehicles_vehicle");
  const formData = useTabularData({
    ...dataHookProps,
    saveEndpoint: noAttributeEndpoint,
    queryKey: "vehicle",
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title="Motor Vehicle Data">
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default IowaVehicles;
