import { fieldTypes } from "componentGenerators/formGenerator/config";
import { NumberFormatTypes } from "components/Common/Inputs/NumberField";
import { formats } from "components/Common/Tables/Common/helpers";

const bdTaxActivityForm = [
  {
    type: fieldTypes.subHeader,
    label: "Applicant",
  },
  {
    type: fieldTypes.asyncSelect,
    name: "bd_party_id",
    endpoint: "views/bd_business_entities_family",
    label: "Applicant",
    getOptionLabel: (option) => option.business_name,
    getOptionValue: (option) => option.id,
    required: true,
  },
  {
    type: fieldTypes.bdBusinessSelect,
    label: "Tax Agency",
    header: "Tax Agency",
    name: "tax_agency_bd_business_entity_id",
    required: true,
    endpoint: "views/bd_business_entities?is_government=true",
    overrideDefaultValues: {
      business_type_checkbox: { is_government: true },
    },
  },
  {
    type: fieldTypes.subHeader,
    label: "Time Period",
  },
  {
    type: fieldTypes.date,
    name: "from_date",
  },
  {
    type: fieldTypes.date,
    name: "to_date",
  },
  {
    type: fieldTypes.subHeader,
    label: "Details",
  },
  {
    name: "region_type",
    label: "Tax Region Type",
    type: fieldTypes.select,
    dynamicMenuItems: ["bdTaxRegionType"],
    required: true,
  },
  {
    type: fieldTypes.number,
    label: "Amount",
    name: "amount",
    format: NumberFormatTypes.dollar,
  },
  {
    type: fieldTypes.label,
    label:
      "Attach any copies of forms, protest letters and other correspondence relating to the audit or adjustment.",
  },
  {
    type: fieldTypes.dropZone,
    description: "BD Audits Adjustment Deliquency",
    label: "Upload Attachment",
    name: "attachment_group_id",
  },
];

const bdTaxActivityColumns = [
  { field: "from_date", format: formats.date },
  { field: "tax_agency_name", title: "Tax Agency" },
  { field: "activity_type", format: formats.startCase },
  { field: "amount", format: formats.dollar },
];

const bdTaxActivitySchema = {
  type: "table",
  endpoint: "views/bd_tax_activities_a?activity_type=not_filed",
  saveEndpoint: "bdTaxActivity",
  formSchema: bdTaxActivityForm,
  columns: bdTaxActivityColumns,
  nonRequired: ["credit_rating_attachment_group_id"],
  infoAlerts: [
    {
      body:
        "Is the Applicant delinquent in the filing of any tax return with any taxing agency anywhere?",
    },
  ],
  modifySubmissionValues: (formValues) => ({
    ...formValues,
    activity_type: "not_filed",
  }),
};

export default [bdTaxActivitySchema];
