import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  Divider,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { FormikDialog } from "components/Formik/formContainers/FormikDialog";
import useToggle from "hooks/useToggle";
import PropTypes from "prop-types";
import React from "react";
import { validationSchema } from "components/Pages/LicensingControl/Employees/config.js";
import { EmploymentForm } from "components/Pages/LicensingControl/Employees/EmploymentForm";
import { TabContentContainer } from "components/Pages/LicensingControl/components/DetailsDialog/Modal.styles";
import { EmployeeLicenses } from "components/Pages/LicensingControl/Employees/components/EmployeeLicenses.jsx";

const LabeledText = ({ label, children }) => (
  <>
    <Typography variant="overline" color="textSecondary">
      {label}
    </Typography>
    <Typography variant="body1">
      <Box fontWeight={550}>{children}</Box>
    </Typography>
  </>
);

LabeledText.propTypes = {
  children: PropTypes.node,
  label: PropTypes.node,
};

const getFormattedEmployeeData = ({
  id,
  date_of_birth,
  hire_date,
  lc_position_type,
  lc_hr_location,
  lc_work_location,
}) => {
  const positionName = lc_position_type?.name;
  const departmentName = lc_position_type?.lc_department?.name;
  const hrLocationNumber = lc_hr_location?.location_number;
  const workLocation = lc_work_location?.location_number;

  return [
    { label: "ID", value: id },
    { label: "Position", value: positionName },
    { label: "Department", value: departmentName },
    { label: "Hire Date", value: hire_date },
    { label: "Date of Birth", value: date_of_birth },
    { label: "HR Location", value: hrLocationNumber },
    { label: "Work Location", value: workLocation },
  ];
};

const EmployeeInfo = ({ employeeData, openEditDialog }) => {
  const formattedData = React.useMemo(
    () => getFormattedEmployeeData(employeeData),
    [employeeData]
  );

  return (
    <Box display="flex">
      <Box pt={1}>
        <Grid container spacing={2}>
          {formattedData.map(({ label, value }) => (
            <Grid item key={label} xs={6} md={4}>
              <LabeledText label={label}>{value || "N/A"}</LabeledText>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box>
        <IconButton onClick={openEditDialog} color="primary" disabled={true}>
          <EditIcon size="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

EmployeeInfo.propTypes = {
  employeeData: PropTypes.object,
  openEditDialog: PropTypes.func,
};

export const InfoTab = ({
  data,
  formikProps,
  title = "",
  id,
  licensesFormInitiallyOpen,
}) => {
  const addEmployeeDialog = useToggle();

  return (
    <>
      <FormikDialog
        title={title}
        open={addEmployeeDialog.on}
        onCancel={addEmployeeDialog.setOff}
        {...formikProps}
        validationSchema={validationSchema}
      >
        <EmploymentForm />
      </FormikDialog>
      <TabContentContainer>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box width={1} mb={3} p={1} maxWidth={1}>
            <Card variant="outlined">
              <CardContent>
                <EmployeeInfo
                  employeeData={data}
                  openEditDialog={addEmployeeDialog.setOn}
                />
              </CardContent>
            </Card>
          </Box>
          <Box mt={2} mb={5} width={1}>
            <Divider variant="middle" />
          </Box>
          <Box maxWidth={1} minWidth={1}>
            <EmployeeLicenses
              id={id}
              licensesFormInitiallyOpen={licensesFormInitiallyOpen}
              licenseData={data?.license}
            />
          </Box>
        </Box>
      </TabContentContainer>
    </>
  );
};

InfoTab.propTypes = {
  data: PropTypes.object,
  formikProps: PropTypes.object,
  title: PropTypes.string,
  id: PropTypes.number,
  licensesFormInitiallyOpen: PropTypes.bool,
};
