import React from "react";
import { SnackbarProvider } from "notistack";
import { makeStyles } from "@material-ui/core/styles";

import { SvgIcon } from "@material-ui/core";
import BeenhereOutlinedIcon from "@material-ui/icons/BeenhereOutlined";

const useStyles = makeStyles(() => ({
  success: { backgroundColor: "#065586" },
  info: { backgroundColor: "green" },
  error: { cursor: "pointer" },
}));

export function SnackBar({ children }) {
  const classes = useStyles();
  return (
    <SnackbarProvider
      maxSnack={2}
      preventDuplicate={true}
      autoHideDuration={4000}
      cy-data={"snackBar"}
      dense
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      classes={{
        variantSuccess: classes.success,
        variantInfo: classes.info,
        variantError: classes.error,
      }}
      iconVariant={{
        info: (
          <SvgIcon
            style={{ opacity: " 0.9", fontSize: "20px", marginRight: "8px" }}
          >
            <BeenhereOutlinedIcon />
          </SvgIcon>
        ),
      }}
    >
      {children}
    </SnackbarProvider>
  );
}
