import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import useForm from "hooks/useForm.js";
import Form from "./Form";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";

const NewJersey35QRDisclosureQuestions = ({ name }) => {
  const formData = useForm({
    defaultInitialValues: {
      new_jersey_35_qr: {
        associated_license_id: null,
        residence_change_boolean: null,
        compensated_position_boolean: null,
        employment_change_boolean: null,
        license_denied_boolean: null,
        business_license_disposition_boolean: null,
        new_ownership_boolean: null,
        criminal_charge_boolean: null,
        criminal_charge_driving_probation_boolean: null,
        coconspirator_boolean: null,
        lawsuit_party_boolean: null,
        bankruptcy_business_boolean: null,
      },
    },
    endpoint: "new_jersey_35_qr_disclosure_questions_pdSupplement",
  });

  return (
    <FormSectionProvider formData={formData} title={name}>
      <EnhancedFormikPage>
        <Form />
      </EnhancedFormikPage>
    </FormSectionProvider>
  );
};

export default NewJersey35QRDisclosureQuestions;
