import BallotOutlinedIcon from "@material-ui/icons/BallotOutlined";
import BusinessIcon from "@material-ui/icons/Business";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DescriptionIcon from "@material-ui/icons/Description";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import PersonalDisclosureIcon from "@material-ui/icons/HowToReg";
import LockIcon from "@material-ui/icons/Lock";
import SettingsIcon from "@material-ui/icons/Settings";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
import React from "react";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";

export const mainNavBarIcon = {
  main_dashboard: <DashboardIcon />,
  dashboard: <DashboardIcon />, // this can be removed later, since we have main_dashboard now
  admin_dashboard: <DashboardIcon />, // this can be removed later, since we have main_dashboard now
  personal_disclosure: <PersonalDisclosureIcon />,
  bd_business_disclosure: <BusinessIcon />,
  pd_supplement: <BallotOutlinedIcon />,
  bd_supplement: <BallotOutlinedIcon />,
  state_applications: <DescriptionIcon />,
  jurisdiction_applications: <DescriptionIcon />,
  license_control: <PeopleOutlineOutlinedIcon />,
  admin_settings: <SettingsOutlinedIcon />,
  manage_page: <SettingsIcon />,
  main_collaborator: <GroupWorkIcon />,
  admin_section: <LockIcon />,
  certn_section: <LockIcon />,
  documents: <InsertDriveFileIcon />,
};
