import React from "react";

export const columns = [
  { title: "Candidate", field: "candidate_pd_person.full_name" },
  { title: "Date", field: "donation_date", format: "date" },
  { title: "Position", field: "candidate_position" },
  { title: "Amount", field: "donation_amount", format: "currency" },
];

export const defaultInitialValues = {
  candidate_pd_person_id: null,
  donation_date: null,
  candidate_position: "",
  donation_amount: null,
};
export const modalTitle = "Donations";

export const tableQuestions = (
  <>
    Identify political contributions in Iowa or any other jurisdictions for the
    last six (6) years.
  </>
);
export const nonRequired = [];

export const endpoint = "iowa_donations_pdDonation";

export const dataHookProps = {
  defaultInitialValues,
  nonRequired,
  endpoint,
};
