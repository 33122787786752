import { fieldTypes } from "componentGenerators/formGenerator/config";
import { formats } from "components/Common/Tables/Common/helpers";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Business Interests",
  },

  {
    type: fieldTypes.label,
    label:
      "Has the applicant’s interest in any company listed in response to the question above been assigned, pledged or hypothecated to any person or company or has any agreement been entered into whereby the applicant’s interest is to be assigned, pledged, or sold either in part or in whole? If YES, please provide details for each below.",
  },
  {
    type: fieldTypes.yesNo,
    name: "louisiana_phfr.section_i_q_5_boolean",
    xs: 12,
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "louisiana_phfr.section_i_q_5_details",
    xs: 12,
    rows: 4,
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Gaming related equipment",
  },
  {
    type: fieldTypes.label,
    label:
      "Has the applicant ever held or controlled, directly or indirectly, any economic, beneficial, revenue, profit, income or ownership interest in any equipment leased or provided to any casino or gaming/gambling related operation? If YES, please provide details for each below.",
  },
  {
    type: fieldTypes.yesNo,
    name: "louisiana_phfr.section_i_q_2_boolean",
    xs: 12,
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "louisiana_phfr.section_i_q_2_details",
    xs: 12,
    rows: 4,
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Renumeration",
  },
  {
    type: fieldTypes.label,
    label:
      "Has the applicant ever received remuneration in cash, goods, or services of any kind, directly or indirectly, from any person in connection with any casino or gaming/gambling related operation? Exclude employment compensation. If YES, please provide the details below.",
  },
  {
    type: fieldTypes.yesNo,
    name: "louisiana_phfr.section_i_q_3_boolean",
    xs: 12,
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "louisiana_phfr.section_i_q_3_details",
    xs: 12,
    rows: 4,
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Native American Associations",
  },
  {
    type: fieldTypes.label,
    label:
      "Does the applicant have or has the applicant ever had a business association with any Indian tribe? If YES, please provide details for each below.",
  },
  {
    type: fieldTypes.yesNo,
    name: "louisiana_phfr.section_i_q_4_boolean",
    xs: 12,
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "louisiana_phfr.section_i_q_4_details",
    xs: 12,
    rows: 4,
  },
];

const ownershipTable = {
  type: "readOnlyTable",
  endpoint:
    "louisiana_phfr_supplement_ownership_information_company_ownership_over_five_percent_past_10_years",
  linkTo: ["business_interests"],
  columns: [
    {
      title: "From",
      field: "from_date",
      format: formats.date,
    },
    {
      title: "To",
      field: "to_date",
      format: formats.date,
    },
    {
      title: "Business Name",
      field: "business_name",
    },
    {
      title: "Ownership %",
      field: "ownership_percent",
      format: formats.percent,
    },
    {
      title: "Gaming",
      field: "business_is_gaming",
      format: formats.boolean,
    },
  ],
  dependsOnQueryCount:
    "List any company in which the applicant held or controlled, directly or indirectly any economic, beneficial, revenue, profit or ownership interest of 5% or more during the past ten (10) years. Please review businesses below listed for accuracy.",
};

export default [
  ownershipTable,
  {
    type: "form",
    formSchema,
    endpoint: "louisiana_phfr_supplement_ownership_information_pdSupplement",
  },
];
