import { CircularProgress } from "@material-ui/core";
import { darken } from "@material-ui/core/styles";
import Button from "components/Common/CustomButtons/Button";
import React from "react";
import styled from "styled-components";

const StyledCircularProgress = styled(CircularProgress)`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  color: ${({ theme }) => theme.palette.grey[100]};
`;

const StyledConfirmButton = styled(Button)`
  ${({ theme: { palette }, color }) => `
  background-color: ${palette[color] && palette[color].main};
  color: ${palette[color] && palette.getContrastText(palette[color].main)};
  &:hover, &:focus {
    background-color: ${palette[color] && darken(palette[color].main, 0.2)};
  }
`}
`;

export const LoadingButton = ({
  onClick,
  loading,
  label,
  endIcon,
  ...props
}) => (
  <StyledConfirmButton
    onClick={onClick}
    disabled={loading}
    endIcon={loading ? <StyledCircularProgress size={20} /> : endIcon}
    {...props}
  >
    {label || props.children}
  </StyledConfirmButton>
);
