import {
  Box,
  List,
  Typography,
  ListItem,
  ListItemText,
  Divider,
  Chip,
} from "@material-ui/core";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";
import { StyledCardContent } from "components/Pages/Dashboard/widgets/AttentionNeeded/ExpiringDocuments.styles";
import React from "react";
import ListWidget from "./ListWidget";
import styled, { css } from "styled-components";
import { lighten } from "@material-ui/core/styles";
import { dayjsUTC } from "helpers/apiHelpers";
import _ from "lodash";

export const StyledListItem = styled(ListItem)`
  ${({ $isUrgent, theme }) =>
    $isUrgent &&
    css`
      background-color: ${lighten(theme.palette.error.main, 0.9)};
    `}
`;

const CenteredWrapper = ({ children }) => (
  <Box
    pt={5}
    pb={5}
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
  >
    {children}
  </Box>
);

export const AssignmentItem = ({
  handleClick,
  name,
  pdf_form_name,
  status_code,
  due_date,
  nameLabel = "",
}) => {
  return (
    <>
      <StyledListItem
        $isUrgent={false}
        onClick={handleClick}
        button={!!handleClick}
      >
        <ListItemText
          primary={pdf_form_name}
          primaryTypographyProps={{ noWrap: true, variant: "subtitle1" }}
          secondary={
            <>
              <Typography
                component="span"
                variant="subtitle2"
                color="textPrimary"
              >
                {nameLabel + name}
              </Typography>
              <Typography
                style={{ paddingLeft: "10px", paddingRight: "10px" }}
                component="span"
                variant="subtitle2"
                color="textPrimary"
              >
                -
              </Typography>
              <Typography
                component="span"
                variant="body2"
                color="textSecondary"
              >
                {`Due: ${dayjsUTC(due_date, "MMM DD, YYYY")}`}
              </Typography>
            </>
          }
        />
        <Box pl={4}>
          {status_code ? (
            <Chip label={_.startCase(status_code)} size="small" />
          ) : null}
        </Box>
      </StyledListItem>
      <Divider component="li" />
    </>
  );
};

const AssignmentsList = ({ records = [], nameLabel, handleClickRecord }) => {
  if (!records.length) {
    return (
      <CenteredWrapper>
        <Box pt={1}>
          <Typography component="span">No Application Assignments</Typography>
        </Box>
      </CenteredWrapper>
    );
  }

  return (
    <StyledCardContent>
      <List>
        {records.map((applicationAssignment) => {
          const handleClick = handleClickRecord
            ? () => handleClickRecord(applicationAssignment)
            : null;

          return (
            <AssignmentItem
              handleClick={handleClick}
              key={applicationAssignment.id}
              nameLabel={nameLabel}
              {...applicationAssignment}
            />
          );
        })}
      </List>
    </StyledCardContent>
  );
};

export const ApplicationAssignmentsWidget = ({
  status,
  data,
  handleClickRecord,
  label,
  nameLabel,
  headerAction,
}) => {
  return (
    <ListWidget title={label} headerAction={headerAction}>
      <Box style={{ maxHeight: "300px", overflowY: "auto" }}>
        <LoadingComponent status={status}>
          <AssignmentsList
            records={data}
            handleClickRecord={handleClickRecord}
            nameLabel={nameLabel}
          />
        </LoadingComponent>
      </Box>
    </ListWidget>
  );
};
