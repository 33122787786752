import Card from "components/Common/Containers/Card/Card";
// core components
import GridContainer from "components/Common/Containers/Grid/GridContainer";
import GridItem from "components/Common/Containers/Grid/GridItem";
import React from "react";
import { Logo } from "assets/oneComply/img/Logo";

import { Typography } from "@material-ui/core";

function ExpiredLinkCard({ children }) {
  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        <GridItem style={{ width: "375px" }}>
          <Card
            style={{
              paddingBottom: "12px",
              paddingTop: "20px",
              alignItems: "center",
            }}
          >
            <div style={{ paddingBottom: "20px" }}>
              <Logo size="l" />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography gutterBottom variant="h2">
                Expired Link
              </Typography>
            </div>
            {children}
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default ExpiredLinkCard;
