import { Box } from "@material-ui/core";
import React from "react";

const FlexRow = ({
  children,
  width = 1,
  justifyContent = "space-between",
  alignContent = "center",
  ...rest
}) => (
  <Box
    width={width}
    display="flex"
    justifyContent={justifyContent}
    alignContent={alignContent}
    {...rest}
  >
    {children}
  </Box>
);

export default FlexRow;
