import { fieldTypes } from "componentGenerators/formGenerator/config";

const ownershipColumns = [
  { field: "pd_business_entity.business_name", title: "Court" },
  { field: "case_number", title: "Docket/ Case Number" },
  { field: "other_parties", title: "Involved Parties" },
  { field: "nature", title: "Nature of Claim(s)" },
];

const ownershipFormSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Court Involved",
  },
  {
    type: fieldTypes.pdBusinessSelect,
    name: "pd_business_entity_id",
    label: "Court",
    required: true,
    xs: 6,
  },
  {
    type: fieldTypes.subHeader,
    label: "Details",
  },
  {
    type: fieldTypes.text,
    label: "Case Number",
    name: "case_number",
    xs: 6,
  },
  {
    type: fieldTypes.text,
    label: "Other Parties",
    name: "other_parties",
    xs: 6,
  },
  {
    type: fieldTypes.text,
    label: "Nature of Claim(s)",
    name: "nature",
    xs: 12,
  },
];

const ownershipSchema = {
  type: "table",
  formSchema: ownershipFormSchema,
  endpoint: "michigan_limited_pd_supplement_criminal_history_bonds",
  title: "Bonds",
  columns: ownershipColumns,
  infoAlerts: [
    {
      body: `Have you been bonded for any purpose or been refused or denied any type of bond?`,
    },
  ],

  modifySubmissionValues: (formValues) => ({
    type: "bond",
    ...formValues,
  }),
};

export default [ownershipSchema];
