import PropTypes from "prop-types";
import useGet from "hooks/useGet";
import React from "react";
import FormikSelect from "components/Formik/fields/FormikSelect";

export const FormikAsyncSelectBase = ({
  endpoint,
  queryKey = null,
  fetchOptions,
  filterOptions = (data) => data,
  ...props
}) => {
  const { data, status } = useGet(endpoint, queryKey, fetchOptions);
  const options = React.useMemo(() => {
    if (status === "success") return filterOptions(data);
    return [];
  }, [data, filterOptions, status]);

  return (
    <FormikSelect options={options} {...props} loading={status === "loading"} />
  );
};

FormikAsyncSelectBase.propTypes = {
  endpoint: PropTypes.string.isRequired,
  fetchOptions: PropTypes.object,
  queryKey: PropTypes.oneOfType([(PropTypes.string, PropTypes.array)]),
};

export default FormikAsyncSelectBase;
