import { SectionSubHeader } from "components/Common/LayoutComponents/index.js";
import CustomLink from "components/Common/Link";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm";
import React from "react";
import { SectionLabel } from "components/Common/LayoutComponents";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import { Grid } from "@material-ui/core";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Signed Letter of Intent:" />
      <SectionLabel>
        Signed Letter of Intent from a sports betting operator, sports betting
        supplier, internet gaming operator, or internet gaming supplier{" "}
        <b>(Initial applications only)</b>
      </SectionLabel>
      <FormikFileDropzone
        description="Signed Letter of Intent"
        name="michigan_vra.signed_letter_attachment_group_id"
        xs={12}
      />
      <SectionSubHeader
        label="Required Items (For U.S. Publicly Traded Entities Only):"
        divider
      />
      <SectionLabel>
        <li>
          Documentary evidence to demonstrate the applicant is a publicly traded
          corporation under the regulation of the U.S. SEC, or a wholly owned
          subsidiary of such a corporation.
          <CustomLink to="bd_share_structure" />.
        </li>
      </SectionLabel>

      <SectionSubHeader
        label="Required Items (If not a U.S. Publicly Traded Entity):"
        divider
      />
      <SectionLabel>
        <li>
          Fully diluted ownership chart.
          <CustomLink to="bd_corporate_documents" />.
        </li>
      </SectionLabel>
      <SectionLabel>
        <li>
          4506-C Request for Transcript of Tax Returns for all entities and
          individuals listed on the application.
          <CustomLink to="bd_tax_returns" />.
        </li>
      </SectionLabel>
    </Grid>
  );
};

const removeExtraValues = ({
  michigan_vra: { signed_letter_attachment_group_id },
  ...values
}) => ({
  michigan_vra: {
    signed_letter_attachment_group_id: signed_letter_attachment_group_id,
  },
  ...values,
});

const MichiganVRAAttachments = ({ title }) => {
  const formData = useForm({
    defaultInitialValues: {
      michigan_vra: {
        signed_letter_attachment_group_id: null,
      },
    },
    postValues: removeExtraValues,
    patchValues: removeExtraValues,
    endpoint: "bdSupplement",
  });

  return (
    <FormSectionProvider formData={formData} title={title}>
      <EnhancedFormikPage>
        <Form />
      </EnhancedFormikPage>
    </FormSectionProvider>
  );
};

export default MichiganVRAAttachments;
