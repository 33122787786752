import { Box, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const ContentContainer = styled(Box)`
  padding: 10px;
  padding-right: 20px;
  text-align: end;
`;

const calculateTotal = (data, name) => {
  if (data?.length > 0)
    return data.reduce((prev, cur) => prev + parseFloat(cur[name] || 0), 0);
  return 0;
};

const formatOptions = {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 4,
};

const ColumnSum = ({ name, label, data }) => {
  const total = calculateTotal(data, name);

  const parsedTotal = new Intl.NumberFormat("en-US", formatOptions).format(
    total
  );
  return total > 0 ? (
    <ContentContainer>
      <Typography>
        {label}: {parsedTotal}
      </Typography>
    </ContentContainer>
  ) : null;
};

export default ColumnSum;
