import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import YesNo from "components/Formik/formGroups/YesNo.jsx";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm";
import React from "react";
import { tabularDataParams } from "./config.js";

const Form = () => {
  return (
    <Grid container spacing={1}>
      <SectionSubHeader label="Manufacturer Applicants/ Licensees Only" />
      <YesNo
        label="Does the applicant wholly own the intellectual property of all games and software
        that is or will be used in Pennsylvania gaming operations?"
        name="bd_pennsylvania_eea.is_intellectual_property_owner"
        xs={12}
      />
      <SectionLabel label="If YES, provide a summary below. Include in this response the name of the game or software being used, the individual(s) or entity that own the game or software, the individual or entity that is being compensated, how the individual and entity are being compensated and how the game or software is utilized." />
      <FormikTextField
        multiline
        rows={4}
        label="Summary"
        name="bd_pennsylvania_eea.intellectual_property_summary"
        xs={12}
      />
      <YesNo
        label="Does the applicant pay any royalties, a revenue share or other ongoing remuneration for the use of any games or other software which will be used in Pennsylvania gaming operations?"
        name="bd_pennsylvania_eea.is_remuneration_games_software"
        xs={12}
      />
      <SectionLabel
        label="If you answer YES, provide a summary below.  Include the game name or
        software being used, the individual or entity that makes the game or software, the
        individual or entity that is being compensated and how the game or software is
        utilized."
      />
      <FormikTextField
        multiline
        rows={4}
        label="Summary"
        name="bd_pennsylvania_eea.remuneration_games_software_summary"
        xs={12}
      />
      <SectionLabel label="Provide a copy of any agreement" />
      <FormikFileDropzone
        description="BD Loans Payable"
        name="remunerations_agreement_attachment_group_id"
        size={12}
      />
      <SectionSubHeader label="VGT Applicants Only" />
      <YesNo
        label="Is the applicant requesting conditional licensure?"
        name="bd_pennsylvania_eea.is_conditional_licensure_vgt"
        xs={12}
      />
      <SectionLabel label="If YES, make sure the VGT Conditional Licensure Affidavit is completed and provide the payment for each entity and individual with the application." />
      <SectionSubHeader label="iGaming Related, Sports Wagering Related, Table Game Manufacturer Applicants Only" />
      <YesNo
        label="Is the applicant requesting conditional licensure?"
        name="bd_pennsylvania_eea.is_conditional_licensure_igaming_sport_table"
        xs={12}
      />
    </Grid>
  );
};

const PennEEAManufacturerApplicants = ({ name }) => {
  const formData = useForm(tabularDataParams);

  return (
    <FormSectionProvider formData={formData} title={`${name}`}>
      <EnhancedFormikPage>
        <Form />
      </EnhancedFormikPage>
    </FormSectionProvider>
  );
};

export default PennEEAManufacturerApplicants;
