import { Grid, Link, Box } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { SectionLabel } from "components/Common/LayoutComponents/";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import YesNo from "components/Formik/formGroups/YesNo";
import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm.js";
import useFetch from "queryHooks/useFetch.js";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { defaultInitialValues } from "./config";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";
import DisplayTable from "components/Common/Tables/DisplayTable/index.js";
import BusinessAssociation from "components/Common/BusinessAssociation/BusinessAssociation";

const AncillaryEnterpriseSelect = () => (
  <>
    <SectionLabel
      label={
        <>
          Select the Business Entity that is the Licensee or Applicant for a
          license in connection with this application/disclosure form. If the
          enterprise is not listed add entries in one or more of the following
          sections:
          <ul>
            <li>
              <Link component={RouterLink} to="employment_history">
                Employment History
              </Link>
            </li>
            <li>
              <Link component={RouterLink} to="business_interests">
                Business Interests
              </Link>
            </li>
          </ul>
        </>
      }
    />

    <BusinessAssociation
      title="Business Entity (Your Association)"
      label="Company Name (Your Position)"
      name="louisiana_r06_07.qualifier_pd_business_entity_id"
    />
  </>
);

const Investigatory = () => (
  <>
    <SectionSubHeader label="Investigatory Information" divider />
    <YesNo
      label="Is the applicant currently in default on the payment of any student loan?"
      name="louisiana.has_student_loan_default"
      xs={12}
    />
    <SectionLabel label="If Yes, please provide details in an attachment below:" />
    <FormikFileDropzone
      description="Student Loans Default"
      name="la_student_loan_attachment_group_id"
      xs={12}
    />
    <SectionSubHeader label="Legal Information" divider />
    <YesNo
      label="Has the applicant ever had a conviction set aside, a prosecution dismissed under La. C.Cr.P. Article 893 or 894 or
            similar law in any jurisdiction, been discharged or had a proceeding dismissed under La. C.Cr.P. art. 978(B)(3) or
            similar law in any jurisdiction, or had a conviction expunged or received a pardon or executive clemency?"
      name="louisiana.q_e3_bool"
      xs={12}
    />

    <SectionLabel label="If Yes, please provide details including name, title and agency of employment for each agent, member or employee in an attachment below:" />
    <FormikFileDropzone
      description="Legal Information"
      name="la_q_e3_attachment_group_id"
      xs={12}
    />
    <SectionSubHeader label="Relations with Government" divider />
    <YesNo
      label="Do you have any personal or business relationship with any member, agent or
            employee of the Louisiana Gaming Control Board, the Louisiana Department of
            Justice, Office of the Attorney General, or the Louisiana Department of
            Public Safety, Office of the State Police?"
      name="louisiana.q_f3_bool"
      xs={12}
    />
    <SectionLabel label="If Yes, please provide details including name, title and agency of employment for each agent, member or employee in an attachment below:" />
    <FormikFileDropzone
      description="Relationships with Louisiana's government"
      name="la_q_f3_attachment_group_id"
      xs={12}
    />
  </>
);

const LouisianaR06DisclosureQuestions = ({ name }) => {
  const formData = useForm({
    defaultInitialValues,
    noValidate: true,
    queryKey: "pdSupplement",
    endpoint: "louisiana_r06_07_disclosure_questions_pdSupplement",
  });
  const { data = [], status } = useFetch(
    "louisiana_r06_07_disclosure_questions_bankruptcy_business_liquidation"
  );
  return (
    <FormSectionWrapper title={name} formData={formData}>
      <EnhancedFormikPage>
        <Grid container spacing={2}>
          <SectionSubHeader label="Applicant Business Entity" />
          <AncillaryEnterpriseSelect />

          <SectionSubHeader label="Personal Information" divider />
          <SectionLabel
            label={
              <>
                Visit the{" "}
                <Link component={RouterLink} to="marriages">
                  Marriages section{" "}
                </Link>{" "}
                and verify you have entered your spouses full name, including
                their maiden name.
              </>
            }
          />
          <FormikDatePicker
            label="Date you started living in your country of residence:"
            name="louisiana_r06_07.residence_country_date"
            xs={6}
          />
          <FormikTextField
            label="List Languages Spoken Other Than English:"
            name="louisiana_r06_07.languages_spoken"
            xs={4}
          />

          <Investigatory />
        </Grid>
      </EnhancedFormikPage>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={status}>
          <DisplayTable
            records={data}
            linkTo={["bankruptcies", "business_receiverships"]}
            columns={[
              {
                field: "filing_date",
                format: "date",
              },
              {
                title: "Court",
                field: "court_name",
              },
              {
                title: "Court Address",
                field: "court_address",
                format: "address",
              },
              {
                title: "Details",
                field: "nature",
              },
            ]}
            dependsOnQueryCount="In the past twenty (20) years, has any business entity in which the applicant held a 5% or more ownership interest, or in which the applicant served as an officer, director, partner or manager filed a petition for any type of bankruptcy or insolvency or had a petition for involuntary bankruptcy filed against the business entity or been in liquidation, receivership or been placed under some form of governmental administration or monitoring in any jurisdiction?"
          />
        </LoadingComponent>
      </Box>
    </FormSectionWrapper>
  );
};

export default LouisianaR06DisclosureQuestions;
