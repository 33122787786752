import { Grid } from "@material-ui/core";
import { NumberFormatTypes } from "components/Common/Inputs/NumberField";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikCheckBox from "components/Formik/fields/FormikCheckBox.jsx";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikNumberField from "components/Formik/fields/FormikNumberField.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import PDPartySelect from "components/Formik/formGroups/CrudSelect/selects/PDPartySelect";
import PDRelativeSelect from "components/Formik/formGroups/CrudSelect/selects/PDRelativeSelect.jsx";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import ColumnSum from "components/Layout/SectionsLayout/ColumnSum.jsx";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import {
  columns,
  dataHookProps,
  modalTitle,
  tableQuestions,
} from "./config.js";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <PDRelativeSelect
        label="Spouse or Child (If Applicable)"
        header="Select if held by Spouse or Dependent Child"
      />

      <SectionSubHeader label="Creditor Details" divider />
      <PDPartySelect name="creditor_pd_party_id" label="Creditor" />

      <FormikTextField
        header="Details"
        divider
        label="Description/Purpose"
        name="nature_of_payable"
      />
      <FormikTextField label="Nature of Security" name="nature_of_security" />
      <FormikTextField label="Account Number" name="account_number" />
      <FormikTextField label="Primary Debtor" name="primary_debtor" />
      <FormikNumberField
        label="Interest Rate"
        name="interest_rate"
        type={NumberFormatTypes.percent}
      />
      <FormikDatePicker label="Date Incurred" name="incurred_date" />
      <FormikDatePicker label="Due Date" name="due_date" />
      <FormikNumberField label="Original Amount" name="original_amount" />
      <FormikNumberField label="Current Amount" name="outstanding_amount" />
      <FormikCheckBox
        label="Is Current - Current loans will be included in the Net Worth Statement."
        name="is_current"
        xs={12}
      />
      <FormikTextField
        label="Persons Liable Besides You or Your Spouse"
        name="liable_persons"
        rows={2}
        xs={6}
      />
    </Grid>
  );
};

const ContingentLiabilities = ({ name }) => {
  const formData = useTabularData({
    ...dataHookProps,
    overwriteMissingRequiredDisplay: { creditor_pd_party_id: "Creditor" },
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={modalTitle}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        footer={
          <ColumnSum
            name="outstanding_amount"
            data={formData.data}
            label="Total Outstanding"
          />
        }
        columns={columns}
        addButtonText="Add Record"
      />
    </FormSectionProvider>
  );
};

export default ContingentLiabilities;
