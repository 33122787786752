import React from "react";

export const columns = [
  { field: "exclusion_date" },
  {
    title: "Agency Name",
    field: "law_enforcement_pd_business_entity.business_name",
  },
  { field: "exclusion_reason" },
];

export const tableQuestions = (
  <>
    Provide details if you have ever been barred or otherwise excluded,  for
    any  reason,  other than for the denial, suspension or revocation of a
    license or registration, from any form or type of casino or gaming/gambling
    related operation in any jurisdiction (even if the disbarment or
    exclusion is no longer in effect or has been lifted).
  </>
);

export const nonRequired = ["law_enforcement_pd_business_entity_id"];

export const endpoint = "license_exclusions_gamingExclusionLegalHistory";

export const defaultInitialValues = {
  type: "gaming_exclusion",
  law_enforcement_pd_business_entity_id: null,
  exclusion_reason: "",
  exclusion_date: null,
};

export const modalTitle = "Gaming Exclusion";

export const dataHookProps = {
  defaultInitialValues,
  nonRequired,
  endpoint,
};
