import PropTypes from "prop-types";
import FormikAutocomplete from "components/Formik/fields/FormikAutocomplete";
import useGet from "hooks/useGet";
import React from "react";

export const AsyncFormikAutocompleteBase = ({
  endpoint,
  queryKey = null,
  fetchOptions,
  filterOptions = (data) => data,
  ...props
}) => {
  const { data, status } = useGet(endpoint, queryKey, fetchOptions);
  const options = React.useMemo(() => {
    if (status === "success") return filterOptions(data);
    return [];
  }, [data, filterOptions, status]);

  return (
    <FormikAutocomplete
      options={options}
      {...props}
      loading={status === "loading"}
    />
  );
};

AsyncFormikAutocompleteBase.propTypes = {
  endpoint: PropTypes.string.isRequired,
};

export default AsyncFormikAutocompleteBase;
