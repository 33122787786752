import React from "react";
import { Box, Typography } from "@material-ui/core";

const DeniedPageDisplay = () => {
  return (
    <Box p={2}>
      <Typography variant="h2">
        {`The account owner has not granted you access to this page.`}
      </Typography>
      <Typography variant="h2">
        {`Please contact the account owner to gain access if you wish to view this page`}
      </Typography>
    </Box>
  );
};

export default DeniedPageDisplay;
