export const defaultInitialValues = {
  nj_ancillary_qualification_in_connection_checkbox: null,
  nj_qualification_roles_checkbox: null,
  new_jersey_ancillary: {
    qualifier_pd_business_entity_id: null,
    qualification_roles_other: "",
  },
};

export const endpoint =
  "new_jersey_ancillary_disclosure_questions_pdSupplement";
