//APPLICATIONS
import ManageApplications from "components/Pages/Applications/ManageApplications";

//SETTINGS
import Collaborator from "components/Pages/Settings/Collaborator/Collaborator";

// MANAGE
import ManageUser from "components/Pages/ManageUser";
import ManageBusiness from "components/Pages/ManageBusiness";


import ApplicationFormAdmin from "components/Pages/Admin/ApplicationForm/ApplicationForm";
import CustomerDashboardAdmin from "components/Pages/Admin/CustomerDashboard/CustomerDashboard";
import CustomerApplicationFormAdmin from "components/Pages/Admin/CustomerApplicationForm/CustomerApplicationForm";
import CustomerAnalytics from "components/Pages/Admin/CustomerAnalytics/CustomerAnalytics";
import RequestApplications from "components/Pages/Applications/RequestApplications/RequestApplications";

const additional_pages = {
  active_applications: ManageApplications,
  request_applications: RequestApplications,
  collaborators: Collaborator,
  manage_user: ManageUser,
  manage_business: ManageBusiness,
  application_form_admin: ApplicationFormAdmin,
  customer_dashboard_admin: CustomerDashboardAdmin,
  customer_analytics_admin: CustomerAnalytics,
  customer_application_form_admin: CustomerApplicationFormAdmin,
};

export default additional_pages;
