import { Box } from "@material-ui/core";
import DeleteConfirmation from "components/Common/Dialogs/DeleteConfirmation";
import React from "react";
import useTabularData from "hooks/tableHooks/useTabularData";
import { FormikDialog } from "components/Formik/formContainers/FormikDialog";
import ActionsTable from "components/Common/Tables/Common/index";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import { EditAuthorizedStateForm } from "./EditAuthorizedStateForm";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";
import { formats } from "components/Common/Tables/Common/helpers";

const defaultInitialValues = {
  state_code: "",
  state_employer_id_number: "",
  certificate_of_authority_attachment_group_id: null,
  date_authorized: null,
};

const columns = [
  {
    title: "Authorized State",
    field: "state_code",
    format: "startCase",
  },
  {
    title: "State Employer ID Number",
    field: "state_employer_id_number",
  },
  {
    field: "date_authorized",
    format: formats.date,
  },
];

export const AuthorizedTab = () => {
  const { data, status, deleteDialogProps, formikProps, tableProps } =
    useTabularData({
      defaultInitialValues,
      endpoint: `bdAuthorizedState`,
      validationSchemaKey: "bd_application_details_authorized",
    });

  return (
    <LoadingComponent status={status}>
      <FormikDialog
        {...formikProps}
        title="Edit Authorized State"
        maxWidth="sm"
      >
        <EditAuthorizedStateForm />
      </FormikDialog>

      <DeleteConfirmation {...deleteDialogProps} />
      <Box pb={2}>
        <InfoAlert>
          List all states where the business is registered to do business
        </InfoAlert>
      </Box>
      <ActionsTable
        title="Authorized States"
        addButtonText="Add State"
        records={data}
        columns={columns}
        {...tableProps}
      />
    </LoadingComponent>
  );
};
