import { Grid } from "@material-ui/core";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikSelect from "components/Formik/fields/FormikSelect";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useTabularData from "hooks/tableHooks/useTabularData";
import { useBdOrganizationId } from "queryHooks/useBdOrganizationId";
import React from "react";
import { AddNewForm } from "./components/AddNewBusinessDisclosureForm";
import {
  businessDisclosureColumns,
  businessDisclosureValidationSchema,
} from "./config";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";

const filter = createFilterOptions();

const EditForm = () => {
  const { businessRelationshipType } = useMenuOptions();
  return (
    <Grid container spacing={2}>
      <FormikTextField label="Business Name" name="business_name" xs={6} />

      <FormikSelect
        label="Relationship"
        name="business_relationship_type"
        options={businessRelationshipType}
        xs={6}
      />
    </Grid>
  );
};

export const BusinessDisclosureTable = () => {
  const { id } = useBdOrganizationId();

  const formData = useTabularData({
    defaultInitialValues: {
      business_name: "",
      business_relationship_type: null,
    },
    postValues: (values) => ({
      ...values,
      has_disclosure: true,
    }),
    patchValues: (values) => ({
      ...values,
      has_disclosure: true,
    }),
    getEndpoint: `bdOrganization/manage/${id}/businessDisclosure?has_disclosure=true`,
    saveEndpoint: `bdOrganization/manage/${id}/businessDisclosure`,
    fetchOptions: { enabled: !!id },
  });

  const [formType, setFormType] = React.useState();

  const {
    open,
    setIsNewRecord,
    editableListActions: { handleEditRecord, handleAddRecord },
  } = formData;

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);
    if (params.inputValue !== "") {
      filtered.push({
        inputValue: params.inputValue,
        business_name: `Add "${params.inputValue}"`,
      });
    }
    return filtered.filter(
      ({ id }) => !formData.data.some((option) => option.id === id)
    );
  };

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikDialog
        validationSchema={businessDisclosureValidationSchema}
        title={"Add Business Disclosure"}
        open={open && formType == "addNew"}
      >
        <AddNewForm
          setIsNewRecord={setIsNewRecord}
          filterOptions={filterOptions}
        />
      </EnhancedFormikDialog>
      <EnhancedFormikDialog
        validationSchema={businessDisclosureValidationSchema}
        title={"Edit Business Disclosure"}
        open={open && formType == "edit"}
      >
        <EditForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        addButtonText="Add Business Disclosure"
        columns={businessDisclosureColumns}
        handleDeleteRecord={null}
        handleEditRecord={(props) => {
          handleEditRecord(props);
          setFormType("edit");
        }}
        handleAddRecord={(props) => {
          handleAddRecord(props);
          setFormType("addNew");
        }}
      />
    </FormContextProvider>
  );
};
