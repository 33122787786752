import { Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import Tooltip from "components/Common/Tooltip";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { fieldTypes } from "components/Formik/formGroups/CrudSelect/CrudSelect";
import PDBusinessSelect from "components/Formik/formGroups/CrudSelect/selects/PDBusinessSelect.jsx";
import PDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/PDPersonSelect.jsx";
import FromToCurrent from "components/Formik/formGroups/FromToCurrent";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";

const additionalFieldsSchema = [
  {
    type: fieldTypes.subHeader,
    children: "Extra Details",
    gutterTop: true,
  },
  {
    name: "business_description",
    type: fieldTypes.text,
    label: "Nature of Trust/Entity",
    xs: 12,
  },
];

const Form = () => {
  const { positionType } = useMenuOptions();

  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Trust Entity, Business Entity or For Whom Held" />

      <PDBusinessSelect
        modifySchema={(schema) => [...schema, ...additionalFieldsSchema]}
        tooltip={
          <Tooltip title="Firm, Corporation, Association, Partnership or other Business Entity" />
        }
      />
      <SectionSubHeader label="Spouse (if Position Held By Spouse)" />
      <PDPersonSelect
        label="Spouse"
        endpoint="currentSpouse"
        overrideDefaultValues={{ relative_type: "spouse" }}
        tooltip={
          <Tooltip title="Select your spouse if they held this position." />
        }
      />
      <SectionSubHeader label="Position Details" />
      <FormikTextField label="Title/Position/Capacity" name="position" xs={4} />
      <FormikTextField name="compensation" xs={4} />
      <FormikSelect
        name="position_type"
        options={positionType}
        label="Position Type"
      />
      <SectionSubHeader label="Dates" divider />
      <FromToCurrent />
    </Grid>
  );
};

const FiduciaryPositions = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "fiduciary_positions_trustOfficePosition",
    nonRequired: ["relative_pd_person_id"],
  });
  return (
    <FormSectionProvider formData={formData} title={name}>
      <EnhancedFormikDialog title="Fiduciary Position Details">
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        subheader={<InfoAlert>{tableQuestions}</InfoAlert>}
        columns={columns}
        addButtonText="Add Record"
      />
    </FormSectionProvider>
  );
};

export default FiduciaryPositions;
