import { Box, Typography, Tabs } from "@material-ui/core";
import React from "react";
import SimpleDialog from "components/Common/Dialogs/SimpleDialog";
import styled from "styled-components";

export const TabPanel = ({ children, tab, index, ...rest }) => (
  <div hidden={tab !== index} {...rest} style={{ height: "500px" }}>
    {tab === index && children}
  </div>
);

export const Header = ({ label }) => (
  <Box p={2} display="flex" alignItems="center">
    <Box pr={3}>
      <Typography variant="h2">{label}</Typography>
    </Box>
  </Box>
);

export const Dialog = styled(SimpleDialog)`
  & .MuiDialog-paper {
    background-color: ${({ theme }) => theme.palette.grey[200]};
  }
`;

export const StyledTabs = styled(Tabs)`
  padding-left: ${({ theme }) => theme.spacing(4)}px;
`;
