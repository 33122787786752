import { Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import PDFinancialSelect from "components/Formik/formGroups/CrudSelect/selects/PDFinancialSelect";
import FormikMultiYearFileUploader from "components/Formik/formGroups/FormikMultiYearFileUploader";
import FromToCurrent from "components/Formik/formGroups/FromToCurrent";
import YesNo from "components/Formik/formGroups/YesNo";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import ColumnSum from "components/Layout/SectionsLayout/ColumnSum.jsx";
import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";

export const BankAccountsForm = () => {
  return (
    <Grid container spacing={2}>
      <YesNo
        label="Have you had any right of ownership in, control over or interest in this bank account?"
        name="key_person_has_control"
        xs={12}
      />

      <SectionSubHeader label="Financial Institution" />
      <PDFinancialSelect />

      <SectionSubHeader label="Account Details" divider />
      <YesNo
        label="Is this account a check writing account held with a brokerage house, insurance company, etc?"
        name="check_account_with_brokerage"
        xs={12}
      />
      <FromToCurrent />
      <FormikTextField label="Account Number" name="account_number" xs={6} />
      <FormikTextField label="Nature of Account" name="nature" xs={6} />
      <FormikTextField
        label="Name & Tax IDs of Person(s) Appearing On Account"
        name="other_account_holders"
        xs={12}
        multiline
        rows={2}
      />

      <SectionSubHeader label="Balance" divider />
      <FormikNumberField label="Balance" name="current_balance" xs={4} />
      <FormikNumberField
        label="Interest Rate"
        name="interest_rate"
        xs={4}
        type="percent"
      />
      <FormikDatePicker label="Date of Balance" name="balance_date" xs={4} />

      <FormikMultiYearFileUploader
        title="Bank Statements"
        name="cash_in_bank_attachment_group_id"
      />
    </Grid>
  );
};

const BankAccountsAndCash = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "bank_accounts_bankAccount",
    nonRequired: ["to_date", "current_balance", "interest_rate"],
    validationSchema: {},
  });

  return (
    <FormSectionWrapper
      title={name}
      formData={formData}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title="Bank Account Details">
        <BankAccountsForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        columns={columns}
        footer={
          <ColumnSum
            name="current_balance"
            data={formData.data}
            label="Total Current Balance"
          />
        }
      />
    </FormSectionWrapper>
  );
};

export default BankAccountsAndCash;
