/* eslint-disable react/no-unescaped-entities */
import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm";
import React from "react";
import { tabularDataParams } from "./config.js";

const PennEEAIdentificationNumbersForm = () => {
  return (
    <Grid container spacing={1}>
      <SectionSubHeader>Applicant’s Identification Numbers</SectionSubHeader>
      <SectionLabel>
        Pennsylvania's Unemployment Compensation Account Number
      </SectionLabel>
      <FormikTextField
        label="Unemployment Compensation Account Number"
        name="bd_pennsylvania_eea.unemployment_compensation_number"
        xs={6}
      />
      <SectionLabel>
        Pennsylvania's Department of Revenue Corporate Box Number
      </SectionLabel>
      <FormikTextField
        label="Department of Revenue Corporate Box Number"
        name="bd_pennsylvania_eea.revenue_corporate_box_number"
        xs={6}
      />
      <SectionLabel>
        Pennsylvania's Liquor Control Board License Number
      </SectionLabel>
      <FormikTextField
        label="Liquor Control Board License Number"
        name="bd_pennsylvania_eea.liquor_control_license_number"
        xs={6}
      />
      <SectionLabel>
        Pennsylvania's Workers Compensation Policy Number
      </SectionLabel>
      <FormikTextField
        label="Workers Compensation Policy Number"
        name="bd_pennsylvania_eea.workers_compensation_number"
        xs={6}
      />
      <SectionLabel>
        Pennsylvania's Department of State Entity Number
      </SectionLabel>
      <FormikTextField
        label="Department of State Entity Number"
        name="bd_pennsylvania_eea.state_entity_number"
        xs={6}
      />
    </Grid>
  );
};

const PennEEAIdentificationNumbers = ({ name }) => {
  const formData = useForm(tabularDataParams);

  return (
    <FormSectionProvider formData={formData} title={`${name}`}>
      <EnhancedFormikPage>
        <PennEEAIdentificationNumbersForm />
      </EnhancedFormikPage>
    </FormSectionProvider>
  );
};

export default PennEEAIdentificationNumbers;
