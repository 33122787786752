import React from "react";

export const columns = [
  {
    title: "Business Entity",
    field: "party_name",
  },
  {
    title: "Agency or Court",
    field: "law_enforcement_bd_busines_entity.business_name",
  },
  {
    title: "Disposition",
    field: "criminal_disposition",
  },
  {
    field: "docket_number",
  },
  {
    field: "case_name",
  },
  {
    title: "Fine",
    field: "fine_amount",
  },
];

export const tableQuestions = (
  <>
    In the past ten (10) years, has the Applicant or its related companies had a
    judgement, order, consent decree or consent order pertaining to any state or
    federal statue, regulation or code that resulted in a fine or penalty of
    $50,000 or more entered against it?
  </>
);

export const defaultInitialValues = {
  type: "statutory",
  bd_party_id: null,
  law_enforcement_bd_business_entity_id: null,
  case_name: "",
  docket_number: "",
  disposition_date: null,
  nature_of_charge: "",
  criminal_disposition: null,
  nature_of_judgement: "",
  fine_amount: null,
};

export const endpoint = "bdLegalHistory?type=statutory";
