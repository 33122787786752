export const endpoint = "pdSupplement";

export const defaultInitialValues = {
  colorado: {
    amount_to_be_invested: null,
    percentage_of_ownership: null,
    investment_sources_explanation: "",
    has_interest_in_gaming_establishment_explanation: null,
    interest_in_gaming_establishment_details: "",
  },
  colorado_per_fin_history_attachment_group_id: null,
};
