import { Box, Grid, Paper } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import { SectionLabel } from "components/Common/LayoutComponents";
import { FormikAddressFields } from "components/Formik/formGroups/FormikAddressFields.jsx";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikGoogleLookup from "components/Formik/fields/FormikGoogleLookup.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm.js";
import React from "react";
import { dataHookProps, modalTitle } from "./config.js";
import NetWorthTable from "./NetWorthTable/NetWorthTable";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent.jsx";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <FormikDatePicker
        header="Statement Information"
        label="Statement Date"
        name="networth_statement_date"
        xs={4}
      />
      <SectionLabel label="Please provide the following for the person completing the net worth statement if it is not completed by you." />
      <FormikTextField label="Name" name="networth_author_name" xs={4} />
      <FormikTextField label="Phone" name="networth_author_phone" xs={4} />
      <FormikGoogleLookup
        fields={{
          address: "networth_author_address",
        }}
      />

      <FormikAddressFields addressPrefix="networth_author_address." />
    </Grid>
  );
};

const NetWorthStatement = ({ name }) => {
  const formData = useForm(dataHookProps);

  const data = formData.status === "success" ? formData.data[0] : [];
  return (
    <FormSectionWrapper title={name}>
      <Box mb={2}>
        <Paper variant="outlined">
          <Box p={2}>
            <LoadingComponent status={formData.status}>
              <NetWorthTable personalDisclosureData={data} />
            </LoadingComponent>
          </Box>
        </Paper>
      </Box>
      <FormContextProvider formData={formData}>
        <EnhancedFormikPage title={modalTitle}>
          <Form />
        </EnhancedFormikPage>
      </FormContextProvider>
    </FormSectionWrapper>
  );
};

export default NetWorthStatement;
