import React from "react";
import { commonStrings } from "components/Pages/PD/uiStrings";
import { formats } from "components/Common/Tables/Common/helpers";
import { NumberFormatTypes } from "components/Common/Inputs/NumberField";
import { fieldTypes } from "componentGenerators/formGenerator/config";

const fromToCurrentSchema = [
  {
    type: fieldTypes.date,
    label: "From",
    name: "from_date",
    xs: 4,
  },
  {
    type: fieldTypes.dependantDatePicker,
    label: "To",
    name: "to_date",
    xs: 4,
  },
  {
    type: fieldTypes.checkBox,
    label: "Is current",
    name: "is_current",
    xs: 4,
  },
];

/**
 * Family Employment properties
 */
export const employmentTableColumns = [
  {
    field: "from_date",
    format: formats.date,
  },
  {
    field: "to_date",
    format: formats.date,
  },
  {
    field: "business_name",
  },
  {
    field: "position",
  },
  {
    field: "person_name",
    title: "Relative Name",
  },
  {
    field: "person_relative_type",
    title: "Relationship",
    format: formats.startCase,
  },
];

export const employmentTableEndpoint =
  "family_ownership_employments_family_gaming";
export const employmentTableSaveEndpoint =
  "family_ownership_familyGamingEmployment";

export const employmentTableFormSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Business Entity",
  },
  {
    type: fieldTypes.pdGamingSelect,
    label: "Gaming Business",
    name: "pd_business_entity_id",
    required: true,
  },
  {
    type: fieldTypes.subHeader,
    label: "Relative",
    divider: true,
  },
  {
    type: fieldTypes.pdRelativeSelect,
    label: "Relative",
    name: "relative_pd_person_id",
    required: true,
  },
  {
    type: fieldTypes.subHeader,
    label: "Employment Details",
    divider: true,
  },
  {
    type: fieldTypes.text,
    label: "Title/Position Held",
    name: "position",
    xs: 6,
  },
  {
    type: fieldTypes.subHeader,
    label: "Dates",
  },
  ...fromToCurrentSchema,
];

export const employmentTableQuestions = (
  <>
    Are any members of your family (spouse, parents, grandparents, children,
    grandchildren, siblings, uncles, aunts, nephews, nieces, fathers-in-law,
    mothers-in-law, sons-in-law, daughters-in-law, brothers-in-law and
    sisters-in-law whether by whole or half blood, by marriage, adoption or
    natural relationship) associated with or employed in any form or type of
    casino or gaming/gambling related operation in any jurisdiction?
    {commonStrings.provideDetails}
  </>
);

/**
 * Family Business Involvement properties
 */
export const ownershipTableColumns = [
  {
    field: "from_date",
    format: formats.date,
  },
  {
    field: "to_date",
    format: formats.date,
  },
  {
    field: "business_name",
  },
  {
    field: "person_name",
    title: "Relative Name",
  },
  {
    field: "ownership_percent",
  },
];

export const ownershipTableEndpoint =
  "family_ownership_company_ownerships_gaming_alcohol_relative";
export const ownershipTableSaveEndpoint =
  "family_ownership_familyGamingInvolvement";

export const ownershipTableFormSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Business Entity",
  },
  {
    type: fieldTypes.pdBusinessSelect,
    label: "Business Entity",
    name: "pd_business_entity_id",
    required: true,
  },
  {
    type: fieldTypes.subHeader,
    label: "Relative",
    divider: true,
  },
  {
    type: fieldTypes.pdRelativeSelect,
    label: "Relative",
    name: "pd_person_id",
    required: true,
  },
  {
    type: fieldTypes.checkBoxGroup,
    label: "Indicate how this person is related to the business",
    dynamicMenuItems: ["businessInterestRoleCheckboxOptions"],
    name: "role_checkbox",
  },
  {
    type: fieldTypes.number,
    format: NumberFormatTypes.percent,
    label: "Ownership % Held",
    helperText: "% Held by Family Member, if any",
    name: "ownership_percent",
  },
  {
    type: fieldTypes.subHeader,
    label: "Involvement Dates",
    divider: true,
  },
  ...fromToCurrentSchema,
  {
    type: fieldTypes.subHeader,
    label: "Other Details",
  },
  {
    type: fieldTypes.label,
    label:
      "State the amount of debt owed to your Immediate Family member by the Gaming Entity, if any",
  },
  {
    type: fieldTypes.number,
    label: "Debt Owed",
    name: "debt_owed",
  },
];

export const ownershipTableQuestions = (
  <>
    Other then Employment, do any of your family members have an ownership
    interest or other association in any alcoholic or gaming related entity.
  </>
);
