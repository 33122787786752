import { Grid } from "@material-ui/core";
import BusinessAssociation from "components/Common/BusinessAssociation/BusinessAssociation";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import { FormikTextField } from "components/Formik/fields";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import FormikCheckBoxGroup from "components/Formik/formGroups/FormikCheckBoxGroup";
import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useForm from "hooks/useForm.js";
import React from "react";
import { defaultInitialValues } from "./config";

const Form = () => {
  const {
    delawareLicenseTypeCheckbox,
    delawareQualifierLicenseRoleCheckbox,
  } = useMenuOptions();
  return (
    <Grid container spacing={2}>
      <BusinessAssociation
        title="EMPLOYMENT INFORMATION"
        header="Provide the following employment information regarding the video lottery, gaming vendor, 
        non gaming vendor or casino excursion company you are associated with:"
        label="Associated Company (Your Association)"
        name="delaware.pd_business_entity_id"
      />

      <SectionSubHeader label="Personal Information" />

      <FormikTextField
        label="Language Written or Spoken"
        name="delaware.language_written_or_spoken"
        xs={4}
      />

      <SectionSubHeader label="License Information " />

      <SectionLabel label="I am applying for:" />

      <FormikCheckBoxGroup
        menuOptions={delawareLicenseTypeCheckbox}
        name="delaware_license_type_checkbox"
        row
      />

      <SectionLabel label="If I am a qualifier for a gaming vendor, non-gaming vendor or gaming excursion company, I am an:" />

      <FormikCheckBoxGroup
        menuOptions={delawareQualifierLicenseRoleCheckbox}
        name="delaware_qualifier_license_role_checkbox"
        row
      />

      <FormikTextField
        label="Other"
        name="delaware.qualifier_license_role_other_details"
        xs={6}
      />

      <SectionLabel label="If applicable, the name of holding company(ies) affiliated with the company with which I have any positions:" />

      <FormikTextField
        label="Affiliated Company Names"
        name="delaware.affiliated_company_names"
        xs={6}
      />
    </Grid>
  );
};

const DelawareDisclosureQuestions = ({ name }) => {
  const formData = useForm({
    defaultInitialValues,
    noValidate: true,
    queryKey: "pdSupplement",
    endpoint: "delaware_disclosure_questions_pdSupplement",
  });
  return (
    <FormSectionWrapper title={name} formData={formData}>
      <EnhancedFormikPage>
        <Form />
      </EnhancedFormikPage>
    </FormSectionWrapper>
  );
};

export default DelawareDisclosureQuestions;
