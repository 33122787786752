import {
  ListItem,
  ListItemIcon,
  Paper,
  Box,
  Typography,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import FlagIcon from "@material-ui/icons/Flag";
import { grey } from "@material-ui/core/colors";
import Tooltip from "components/Common/Tooltip";
import JsxParser from "react-jsx-parser";

import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { startCase } from "lodash";

const InProgressIcon = styled(ErrorIcon)`
  color: ${({ theme }) => theme.palette.warning.main};
`;

const NotStarted = styled(ErrorIcon)`
  ${{ color: grey[400] }}
`;

const FlaggedIcon = styled(FlagIcon)`
  color: ${({ theme }) => theme.palette.error.main};
`;

const SuccessIcon = styled(CheckCircleIcon)`
  color: ${({ theme }) => theme.palette.success.main};
`;

export const LicenseListItem = styled(Paper)`
  margin: ${({ theme }) => theme.spacing(1, 0, 1, 0)};
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const LicenseRow = ({ status, name, path, notes }) => {
  const history = useHistory();
  let formDisplayName = name;
  if (formDisplayName === "Disclosure Questions") {
    formDisplayName = startCase(path);
  }

  const linkTo = () => {
    history.push(`/app/${path}`);
  };

  return (
    <ListItem divider button onClick={linkTo}>
      <Box display="flex" width={1} alignItems="center" pt={1} pb={1}>
        <Box display="flex" flexGrow={1} width={0.5} mr={3}>
          <Typography color="textSecondary">{formDisplayName}</Typography>
        </Box>
        <Box display="flex" width={0.5}>
          <ListItemIcon>
            {status === "" && <NotStarted />}
            {status === "completed" && <SuccessIcon />}
            {status === "in_progress" && <InProgressIcon />}
            {status === "flagged" && <FlaggedIcon />}
          </ListItemIcon>
          <Box>
            <Typography variant="body2" color="textSecondary">
              {status === "completed" && "Completed"}
              {status === "" && "Not Started"}
              {status === "in_progress" && "Incomplete"}
              {status === "flagged" && "Flagged"}
            </Typography>
          </Box>
        </Box>
        <Box
          justifyContent="center"
          display="flex"
          flexGrow={1}
          width={0.5}
          mr={3}
        >
          {notes ? (
            <Tooltip type="note" title={<JsxParser jsx={notes} />} />
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </ListItem>
  );
};
