import { formatCurrency } from "helpers/utils";
export const currentYear = new Date().getUTCFullYear();

export const yearSelectMenuOptions = Array(currentYear - (currentYear - 6))
  .fill("")
  .map((v, idx) => currentYear - idx);

const categories = {
  salary: "salary",
  dividend: "dividend",
  interest: "interest",
  other: "other",
};

const categoryReducer = (acc, item) => ({
  ...acc,
  [categories[item.category] || "other"]: [
    ...acc[categories[item.category] || "other"],
    item,
  ],
});

export const filterIntoCategories = (data) =>
  data.reduce(categoryReducer, {
    salary: [],
    interest: [],
    dividend: [],
    other: [],
  });

export const getTotalAmount = (data) => {
  let total = 0;
  if (data.length) {
    total = data.reduce((a, b) => ({
      amount: parseInt(a.amount) + (b.amount ? parseInt(b.amount) : 0),
    })).amount;
  }
  return total;
};

export const formatDataForTable = (data, includeCategory) => {
  const formattedData = [];
  data.map(({ source, amount, id, category }) => {
    const tableData = includeCategory
      ? [source, category, amount]
      : [source, amount];
    return formattedData.push({ id, data: tableData });
  });
  return formattedData;
};

export const getFormattedTableData = (data) => {
  const filteredData = filterIntoCategories(data);
  const { salary, interest, dividend, other } = filteredData;

  const getNewRowInitialData = (category) => ({
    id: 0,
    amount: 0,
    isEditMode: true,
    source: "",
    category: category,
  });

  return [
    {
      id: categories.salary,
      name: "Salary",
      tableData: formatDataForTable(salary),
      newRowInitialData: getNewRowInitialData(categories.salary),
    },
    {
      id: categories.interest,
      name: "Interest",
      tableData: formatDataForTable(interest),
      newRowInitialData: getNewRowInitialData(categories.interest),
    },
    {
      id: categories.dividend,
      name: "Dividend",
      tableData: formatDataForTable(dividend),
      newRowInitialData: getNewRowInitialData(categories.dividend),
    },
    {
      id: categories.other,
      name: "Other",
      tableData: formatDataForTable(other, true),
      newRowInitialData: getNewRowInitialData(""),
    },
  ];
};

const toNumber = (value) => (isNaN(parseInt(value)) ? 0 : parseInt(value));

export const calculateAnnualTotal = (totals) => {
  const total = Object.values(totals).reduce(
    (a, b) => toNumber(a) + toNumber(b)
  );
  return formatCurrency(total);
};
