import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Registration Type",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "pennsylvania_gspr.registration_type_checkbox",
    dynamicMenuItems: ["pennsylvaniaGSPRRegistrationTypeCheckbox"],
    defaultValue: null,
  },
  {
    type: fieldTypes.subHeader,
    label: "Application Period",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "pennsylvania_gspr.application_period_checkbox",
    dynamicMenuItems: ["application_period_checkbox"],
    defaultValue: null,
  },
  {
    type: fieldTypes.subHeader,
    label: "Contact Persons",
  },

  {
    type: fieldTypes.bdPersonSelect,
    label: "Primary contact person",
    helperText: "Primary Contact Person",
    name: "pennsylvania_gspr.contact_person_id",
  },
  {
    type: fieldTypes.gridItem,
    xs: 12,
    style: { padding: "0px" },
  },

  {
    type: fieldTypes.bdPersonSelect,
    label: "Information Supplier",
    helperText: "Person who completed the Spplication",
    name: "pennsylvania_gspr.information_supplier_person_id",
  },
  {
    type: fieldTypes.gridItem,
    xs: 12,
    style: { padding: "0px" },
  },

  {
    type: fieldTypes.bdPersonSelect,
    label: "Billing Contact",
    helperText: "Billing Contact",
    name: "pennsylvania_gspr.billing_contact_person_id",
  },
  {
    type: fieldTypes.subHeader,
    label: "Minority or Women’s business enterprise",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Has the applicant been verified as a minority or women’s business enterprise by the Pennsylvania Department of General Services’ (DGS) Bureau of Small Business Opportunities?",
    name: "pennsylvania_gspr.has_minority_women",
  },
  {
    type: fieldTypes.label,
    label:
      "If yes, attach the verification letter from the Bureau of Small Business Opportunities and/or the Small Business Procurement Initiative certificate that identifies the company as a small diverse business (not only as a small business) and provide the certification number:",
  },
  {
    type: fieldTypes.text,
    label: "Certification Number",
    name: "pennsylvania_gspr.small_business_certificate_number",
  },
  {
    type: fieldTypes.dropZone,
    name: "pennsylvania_gspr.small_business_certificate_attachment_id",
    description: "Business Certificate",
    label: "Upload Attachment",
    xs: 12,
  },
  {
    type: fieldTypes.subHeader,
    label: "Pennsylvania’s Identification Numbers",
  },
  {
    type: fieldTypes.label,
    label: "Pennsylvania's Department of Revenue Corporate Box Number:",
  },
  {
    type: fieldTypes.text,
    label: "Box Number",
    name: "pennsylvania_gspr.revenue_box_number",
  },
  {
    type: fieldTypes.label,
    label: "Pennsylvania's Compensation Policy Number:",
  },
  {
    type: fieldTypes.text,
    label: "Policy Number",
    name: "pennsylvania_gspr.compensation_policy_number",
  },
  {
    type: fieldTypes.label,
    label: "Pennsylvania's Unemployment Compensation Account Number:",
  },
  {
    type: fieldTypes.text,
    label: "Account Number",
    name: "pennsylvania_gspr.compensation_account_number",
  },
  {
    type: fieldTypes.label,
    label: "Pennsylvania's Liquor Control Board License Number:",
  },
  {
    type: fieldTypes.text,
    label: "License Number",
    name: "pennsylvania_gspr.liquor_license_number",
  },
  {
    type: fieldTypes.label,
    label: "Pennsylvania's Control Board License Number:",
  },
  {
    type: fieldTypes.text,
    label: "License Number",
    name: "pennsylvania_gspr.control_board_license_number",
  },
  {
    type: fieldTypes.label,
    label: "Pennsylvania's Department of State - Entity Number:",
  },
  {
    type: fieldTypes.text,
    label: "Entity Number",
    name: "pennsylvania_gspr.department_entity_number",
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "bdSupplement",
};
