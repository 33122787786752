import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import {
  Divider,
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import Tooltip from "components/Common/Tooltip";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikErrorScroll from "components/Formik/other/FormikErrorScroll";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import FormikSelect from "components/Formik/fields/FormikSelect";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { useMenuOptions } from "hooks/useMenuOptions";
import { Form, Formik } from "formik";
import useForm from "hooks/useForm";
import React from "react";
import styled from "styled-components";
import { military } from "../../uiStrings";

const StyledCard = styled(Card)`
  & .MuiCardContent-root {
    padding-bottom: 8px;
  }
`;

export const MilitaryQuestion = ({ title, innerRef, ...rest }) => {
  const { formikProps } = useForm({
    defaultInitialValues: {
      country_of_service: "",
      branch: "",
      serial_number: "",
      rank: "",
      service_1_start_date: null,
      service_1_end_date: null,
      service_2_start_date: null,
      service_2_end_date: null,
      discharge_date: null,
      discharge_type: "",
      attachment_group_id: null,
    },
    endpoint: "military_militaryService",
  });
  const noValidationSchema = { ...formikProps };
  delete noValidationSchema["validationSchema"];

  return (
    <Box pb={1}>
      <StyledCard variant="outlined">
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2">{title}</Typography>
            </Grid>
          </Grid>

          <Formik
            enableReinitialize
            innerRef={innerRef}
            {...noValidationSchema}
            {...rest}
          >
            <Form>
              <FormikErrorScroll />
              <MilitaryServiceForm />
            </Form>
          </Formik>
        </CardContent>
      </StyledCard>
    </Box>
  );
};

export default MilitaryQuestion;

const MilitaryServiceForm = () => {
  const { listOfCountries, dischargeType } = useMenuOptions();
  return (
    <Grid container spacing={2}>
      <FormikSelect
        label="Country of Service"
        name="country_of_service"
        options={listOfCountries}
        xs={4}
      />
      <FormikTextField label="Branch" name="branch" xs={4} />
      <FormikTextField label="Serial Number" name="serial_number" xs={4} />
      <FormikTextField label="Rank" name="rank" xs={4} />
      <SectionSubHeader label="Period(s) of Active Service:" divider />
      <FormikDatePicker
        label="1st Service From"
        name="service_1_start_date"
        xs={4}
      />
      <FormikDatePicker
        label="1st Service To"
        name="service_1_end_date"
        xs={4}
      />
      <Divider xs={4} noline />
      <FormikDatePicker
        label="2nd Service From"
        name="service_2_start_date"
        xs={4}
      />
      <FormikDatePicker
        label="2nd Service To"
        name="service_2_end_date"
        xs={4}
      />
      <SectionSubHeader label="Discharge Details" divider />
      <FormikDatePicker label="Discharge Date" name="discharge_date" xs={4} />
      <FormikSelect
        label="Discharge Type"
        name="discharge_type"
        options={dischargeType}
        xs={4}
        default=""
      />
      <SectionSubHeader label="Records" divider>
        <Tooltip title={military.recordDefinition} />
      </SectionSubHeader>
      <SectionLabel label="Upload a copy of your military records.  If unavailable, upload a copy of a letter to the appropriate branch of the military requesting a copy of your military records. If in reserves, please upload a copy of your discharge papers."></SectionLabel>

      <Grid item xs={12}>
        <FormikFileDropzone description="Military" name="attachment_group_id" />
      </Grid>
    </Grid>
  );
};
