import { Box, CircularProgress, Dialog, Fab, Grid } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { styled } from "@material-ui/core/styles";
import React from "react";

export const BoxWrapper = styled((props) => <Box {...props} />)({
  margin: ({ theme }) => theme.spacing(1),
  position: "relative",
});

export const StyledFab = styled((props) => <Fab {...props} />)({
  backgroundColor: ({ success }) => success === "true" && green[500],
});

export const StyledProgress = styled(CircularProgress)({
  position: "absolute",
  top: -6,
  left: -6,
  zIndex: 1,
});

export const GridWrapper = styled(Grid)({
  minHeight: "100vh",
});

export const StyledGridItem = styled(Grid)({
  display: "flex",
  justifyContent: "center",
});

export const StyledDialog = styled(Dialog)({
  opacity: 0.8,
});
