import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import React from "react";
import styled from "styled-components";

const StyledCardHeader = styled(CardHeader)`
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: ${({ theme }) => theme.spacing(2)}px;
  margin: -40px auto 0;
  width: 88%;
  & .MuiCardHeader-title {
    color: ${({ theme: { palette } }) => palette.primary.contrastText};
    font-weight: bold;
  }

  & .MuiCardHeader-subheader {
    color: ${({ theme: { palette } }) => palette.primary.contrastText};
  }
`;

const StyledCardContent = styled(CardContent)`
  padding-top: 0;
  text-align: left;
  & table {
    margin-bottom: 0;
  }
`;

const StyledCard = styled(Card)`
  max-width: 345;
  overflow: visible;
  & .MuiCardHeader-subheader {
    color: ${({ theme: { palette } }) => palette.primary.contrastText};
  }
`;

const ElevatedHeaderCard = ({ title, subheader, children }) => {
  return (
    <StyledCard variant="outlined">
      <CardContent>
        <StyledCardHeader title={title} subheader={subheader} />
      </CardContent>
      <StyledCardContent>{children}</StyledCardContent>
    </StyledCard>
  );
};

export default ElevatedHeaderCard;
