export const tableQuestions = null;

export const defaultInitialValues = {
  bd_pennsylvania_eea: {
    is_intellectual_property_owner: null,
    is_remuneration_games_software: null,
    is_conditional_licensure_vgt: null,
    is_conditional_licensure_igaming_sport_table: null,
    intellectual_property_summary: "",
    remuneration_games_software_summary: "",
  },
  remunerations_agreement_attachment_group_id: null,
};

export const endpoint = "bdSupplement";

export const tabularDataParams = {
  defaultInitialValues,
  endpoint,
};
