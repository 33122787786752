import React from "react";
import { Box } from "@material-ui/core";
import CopyText from "components/DevTools/PdfTools/Components/Navbar/CopyText.jsx";
import { usePdfState } from "components/DevTools/PdfTools/Context/PdfToolsProvider.jsx";

export default function TableRowTag({ text, index }) {
  const { timeFilter, amountFilter, token, selected, column } = usePdfState();
  return (
    <CopyText
      text={
        !selected
          ? `${text}`
          : `${selected}${timeFilter}${amountFilter}.${column}${token}.${index}`
      }
    >
      <Box
        component="div"
        style={{
          padding: "0px 0px 3px 0px",
          fontWeight: "bold",
          cursor: "pointer",
        }}
        fontSize={16}
        color="info.main"
      >
        {!selected
          ? `${text}`
          : `${selected}${timeFilter}${amountFilter}.${column}${token}.${index}`}
      </Box>
    </CopyText>
  );
}
