import PropTypes from "prop-types";
import ControlledAutocomplete from "components/Common/ControlledAutocomplete/ControlledAutoComplete";
import { FormikDialog } from "components/Formik/formContainers/FormikDialog";
import { useCrudSelect } from "components/Formik/formGroups/CrudSelect/hooks/useCrudSelect";
import React from "react";
import BusinessForm from "components/Pages/LicensingControl/Business/BusinessForm.js";
import * as yup from "yup";
import { multiEmail, requiredString } from "helpers/validationSchemaGenerator";

const validationSchema = yup.object().shape({
  external_id: requiredString,
  notification_emails: multiEmail,
});

export const BusinessDropdown = ({ label = "Employer" }) => {
  const businessEntityProps = useCrudSelect({
    autoSelectFirstOption: true,
    name: "lc_business_entity_id",
    endpoint: "licensing/lcBusinessEntity",
    defaultInitialValues: {
      external_id: null,
      business_name: "",
      notification_emails: [],
    },
    getOptionLabel: ({ business_name }) => business_name,
  });
  return (
    <>
      <FormikDialog
        title="Add / Edit Business"
        {...businessEntityProps.formProps}
        validationSchema={validationSchema}
      >
        <BusinessForm />
      </FormikDialog>
      <ControlledAutocomplete
        {...businessEntityProps.fieldProps}
        label={label}
      />
    </>
  );
};

BusinessDropdown.propTypes = {
  label: PropTypes.string,
};
