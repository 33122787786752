import { Grid } from "@material-ui/core";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import Tooltip from "components/Common/Tooltip";
import FromToCurrent from "components/Formik/formGroups/FromToCurrent";
import InlineAddressTable from "components/Formik/formGroups/InlineAddressTable.jsx";
import FormikSelect from "components/Formik/fields/FormikSelect";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { useMenuOptions } from "hooks/useMenuOptions";
import React from "react";
import FormikCheckBoxGroup from "components/Formik/formGroups/FormikCheckBoxGroup";
import YesNo from "components/Formik/formGroups/YesNo";

const RelatedBusinessForm = () => {
  const {
    formOfBusiness,
    businessRelationshipType,
    listOfCountries,
    businessTypeCheckboxOptions,
  } = useMenuOptions();

  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Incorporation Name" />
      <FormikTextField label="Incorporation Name" name="business_name" xs={4} />
      <FormikSelect
        label="Relationship"
        name="business_relationship_type"
        options={businessRelationshipType}
        xs={4}
      />

      <SectionSubHeader label="Incorporation Details" divider />
      <YesNo
        xs={12}
        label="Is this business entity publicly listed?"
        name="is_public_company"
      />
      <FormikSelect
        label="Form of Business"
        name="form_of_business"
        options={formOfBusiness}
        xs={4}
      />
      <FormikTextField
        label="State of Incorporation"
        name="incorporation_state"
        xs={4}
      />
      <FormikSelect
        label="Country of Incorporation"
        name="incorporation_country"
        options={listOfCountries}
        xs={4}
      />

      <SectionSubHeader label="Dates" divider />
      <FromToCurrent
        fromProps={{ label: "Date of Formation", name: "incorporation_date" }}
        toProps={{ label: "End Date of Business" }}
      />

      <SectionSubHeader label="Identification Numbers" divider />
      <FormikTextField
        label="Employer ID Number"
        helperText="For US Entities: EIN, FEIN or Federal Tax ID"
        name="employer_id_number"
        xs={4}
      >
        <Tooltip title="The Employer Identification Number (EIN), also known as the Federal Employer Identification Number (FEIN) or the Federal Tax Identification Number, is a unique nine-digit number assigned by the Internal Revenue Service (IRS) to business entities operating in the United States for the purposes of identification." />
      </FormikTextField>
      <FormikTextField
        label="Unique Entity Number"
        helperText="For Non-US Entities"
        name="unique_entity_number"
        xs={4}
      >
        <Tooltip title="Unique Entity Number (UEN) is identification number of an entity registered outside the United States.  If the business entity is registered in the United States leave this blank and fill in the Employer ID Number field." />
      </FormikTextField>

      <SectionSubHeader label="Business Description" divider />
      <SectionLabel label="Provide a description of the business, including principal products, services, markets and methods of distribution." />
      <FormikTextField
        label="Business Description"
        name="business_description"
        multiline
        rows={3}
        xs={8}
      />
      <FormikTextField
        label="Reason for Cessation"
        name="cessation_reason"
        multiline
        rows={3}
        xs={4}
      />
      <SectionSubHeader>Check all that apply to this entity</SectionSubHeader>
      <Grid item xs={12}>
        <FormikCheckBoxGroup
          menuOptions={businessTypeCheckboxOptions}
          name="business_type_checkbox"
          row
        />
      </Grid>

      <SectionSubHeader label="Addresses" divider />
      <InlineAddressTable xs={12} />
    </Grid>
  );
};

export default RelatedBusinessForm;
