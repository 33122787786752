import React from "react";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Litigations",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Provide all existing Litigation description and uploaded in the
        <CustomLink to="bd_litigations" /> section. Description of any settled
        or closed litigation against the Principal Entity for the past three (3)
        years. Also describe any existing or settled or closed litigation for
        the past three (3) years for any holding, intermediary, subsidiary or
        affiliate. A description of any judgments against Applicant, holding,
        intermediary, subsidiary or affiliate for the past five (5) years.
        Describe if any judgments were covered by insurance and if so the
        insurance company.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Financial Statements",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Provide all audited Financial statements for the last five years,
        including the Principal Entity’s last fiscal year. If audited financial
        statements do not exist, then provide unaudited financial statements.
        Please upload the documentation in the
        <CustomLink to="bd_financial_statements" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Financial Statements",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Provide all Annual Reports for the last five years in the
        <CustomLink to="bd_financial_statements" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Security Options",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Provide all description of Security Options for the Principal Entity in
        the
        <CustomLink to="bd_security_options" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Audited Financial Statements",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Provide all Audited Financial Statements for the last five years,
        including the Principal Entity’s last fiscal year. If audited financial
        statement do not exist, then provide unaudited financial statements in
        the
        <CustomLink to="bd_financial_statements" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Annual Reports",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Provide all Annual Reports prepared on the SEC’s form 10K for the last 5
        years in the
        <CustomLink to="bd_government_reports" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Unaudited Financial Statement",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Provide a copy of the last quarterly Unaudited Financial Statement in
        the
        <CustomLink to="bd_financial_statements" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Interim Reports",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Provide copy(ies) of any Interim Reports in the
        <CustomLink to="bd_financial_statements" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Proxy or Information Statement",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Provide a copy of the last definitive Proxy or Information Statement
        (SEC) in the
        <CustomLink to="bd_financial_statements" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Registration Statements",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Provide a copy of all Registration Statements for the last five years
        filed in accordance with the Securities Act of 1933 in the
        <CustomLink to="bd_financial_statements" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Independent Auditors",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Provide Copies of all other reports prepared in the last five years by
        independent auditors of the Principal Entity in the
        <CustomLink to="bd_financial_statements" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Independent Auditors",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Provide certified copies of the Articles of Incorporation, Charter and
        By-laws, and all amendments and proposed amendments in the
        <CustomLink to="bd_corporate_documents" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Ownership Table",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Provide Current ownership table of organization of the Principal Entity
        in the
        <CustomLink to="bd_corporate_documents" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "1120 forms and 941 forms",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Provide copies of 1120 forms and 941 forms filed with the IRS in the
        last five years in the
        <CustomLink to="bd_tax_returns" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "IRS 5500 form",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Provide copies of IRS 5500 form filed in the last 5 years in the
        <CustomLink to="bd_tax_returns" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Letter of reference",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        If Principal Entity has held a sports wagering or gaming license in
        another jurisdiction, provide a letter of reference from the sports
        wagering, gaming or casino enforcement or regulatory agency. The letter
        shall specify the agency’s experiences with the Principal Entity, the
        Principal Entity’s associates and Principal Entity’s sports wagering or
        gaming operation.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Existing Vendors",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Provide a chart of existing vendors including the name, address, phone
        and tax identification number of the vendor, type of good and/or
        services provided by the vendor, total amount of business with vendor in
        the past twenty-four (24) months.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Materials",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Along with the description provided in Exhibit 8, provide the nature and
        results of any other material reorganization, readjustment or success of
        the Principal Entity, holding, intermediary, subsidiary or affiliate.
        Describe the acquisition or disposition of any material amount of assets
        otherwise then in the normal course of business and any material change
        in the way business was conducted by the Principal Entity, holding
        company, intermediary, subsidiary or affiliate because of such an event
        or proceeding.
      </>
    ),
  },
];

export default [
  {
    type: "readOnlyPage",
    formSchema,
  },
];
