import React from "react";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { Grid } from "@material-ui/core";
import YesNo from "components/Formik/formGroups/YesNo.jsx";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import useTabularData from "hooks/tableHooks/useTabularData.js";
import { EnhancedActionsTable } from "components/Common/Tables/Common/index.js";
import { columns, defaultInitialValues, endpoint } from "./config.js";
import useForm from "hooks/useForm.js";
import { SectionSubHeader } from "components/Common/LayoutComponents/index.js";
import FormikNumberField from "components/Formik/fields/FormikNumberField.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField.jsx";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone.jsx";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog.jsx";

function SupplementQuestion() {
  const formData = useForm({
    defaultInitialValues: { colorado_sbba: { is_party_to_lease: null } },
    endpoint: "bdSupplement",
  });

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage>
        <Grid>
          <YesNo
            label="Does the applicant have a compliance committee or compliance officer? If yes, attach a compliance officer reports from the past twelve months."
            name="colorado_sbba.is_compliance_committee"
            xs={12}
          />
        </Grid>
      </EnhancedFormikPage>
    </FormContextProvider>
  );
}

function Form() {
  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Compliance Officer's Report" />
      <FormikNumberField
        label="Effective Year"
        name="effective_year"
        type="year"
        xs={4}
      />
      <FormikTextField label="Description" name="description" xs={12} />
      <FormikFileDropzone
        label="Compliance Officer Report"
        description="Compliance Officer Report"
        name="corporate_report_attachment_group_id"
        xs={12}
      />
    </Grid>
  );
}

function ComplianceReportTable() {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint,
  });

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikDialog>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
    </FormContextProvider>
  );
}

const ColoradoSBBAComplianceReport = ({ name }) => {
  return (
    <FormSectionWrapper title={name}>
      <SupplementQuestion />
      <ComplianceReportTable />
    </FormSectionWrapper>
  );
};

export default ColoradoSBBAComplianceReport;
