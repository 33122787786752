import ActionsTable from "../ActionsTable/ActionsTable";
import React from "react";
import useGetPermission from "components/Permission/useGetPermission.jsx";

import { FormContext } from "../../../Layout/SectionsLayout/FormSectionProvider";
export const EnhancedActionsTable = (props) => {
  const { tableProps } = React.useContext(FormContext);
  const permission = useGetPermission();

  if (!tableProps) {
    throw new Error("tableProps not available in context");
  }

  //We are disabling Delete and Add functions if permission is read only
  const viewOnlyProps = {
    ...(permission === "read" && {
      handleDeleteRecord: null,
      handleAddRecord: null,
      viewOnly: true,
    }),
  };

  const newProps = { ...tableProps, ...viewOnlyProps, ...props };

  return <ActionsTable {...newProps} />;
};

export default ActionsTable;
