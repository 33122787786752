import PropTypes from "prop-types";
import React from "react";
import { useField } from "formik";
import { NumberField } from "components/Common/InputElements";
import withFieldTools from "hocs/withFieldTools";
import { startCase } from "lodash";
import { NumberFormatTypes as NumberFormatTypesImport } from "components/Common/Inputs/NumberField";

export const NumberFormatTypes = NumberFormatTypesImport;

const FormikNumberFieldBase = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const errorText = meta.error ? meta.error : "";

  const checkInputValue = (values) => {
    if (values.value === "") {
      helpers.setError("Invalid Input");
    } else {
      helpers.setError(null);
    }
  };

  return (
    <NumberField
      {...field}
      label={label || startCase(props.name)}
      helperText={errorText}
      errors={!!errorText}
      useGrid={false}
      {...props}
      checkInputValue={checkInputValue}
    />
  );
};

FormikNumberFieldBase.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

const EnhancedNumberField = withFieldTools(FormikNumberFieldBase);

const FormikNumberField = (rest) => <EnhancedNumberField xs={4} {...rest} />;

FormikNumberField.propTypes = {
  tooltip: PropTypes.node,
};

export default FormikNumberField;
