import {
  DialogContentText,
  FormLabel,
  Grid,
  Link,
  Box,
} from "@material-ui/core";
import axios from "axios";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import * as yup from "yup";
import ConfirmationDialog from "../../Common/Dialogs/ConfirmationDialog";
import InviteUserForm from "../../Forms/InviteUser/InviteUserForm";
import { permissionTypes } from "./config";
import CopyToClipboard from "./CopyToClipBoard";

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  is_internal: false,
  permissionIDs: [],
};

const validationSchema = yup.object().shape({
  email: yup.string().email("Must be valid email").required("Required Field"),
  first_name: yup.string().required("Required Field"),
  last_name: yup.string().required("Required Field"),
});

// converts permissionIDs e.g ["1"] to permission array e.g [{id: 1, type: "manage_user", name: "Manage User"}]
const shapeValuesForSubmission = ({ permissionIDs, ...values }) => ({
  permission: permissionIDs.map((id) =>
    permissionTypes.find((permission) => permission.id.toString() === id)
  ),
  ...values,
});

const InternalUser = () => (
  <Grid item xs={12}>
    <Box p={2}>
      <DialogContentText>
        A new Internal User has been created! They can now login via SSO at:{" "}
        <Link href={window.location.protocol + "//" + window.location.host}>
          {window.location.host}
        </Link>
      </DialogContentText>
    </Box>
  </Grid>
);

const ExternalUser = ({ link }) => (
  <>
    <Grid item xs={12}>
      <DialogContentText>
        An email has been sent to the email address you provided. You may also
        copy and share the invite link below.
      </DialogContentText>
    </Grid>
    <Grid item xs={12}>
      <FormLabel style={{ paddingBottom: "10px" }}>Invite Link</FormLabel>
      <CopyToClipboard copyText={link} />
    </Grid>
  </>
);
const ExistingUser = ({ email }) => (
  <>
    <Grid item xs={12}>
      <DialogContentText>
        User {email} added to your organization. {email} can now login with
        their credentials at{" "}
        <Link href={window.location.protocol + "//" + window.location.host}>
          {window.location.protocol + "//" + window.location.host}
        </Link>
      </DialogContentText>
    </Grid>
  </>
);
const InviteSentConfirmation = ({ link, is_internal, existing_user }) => (
  <>
    <Grid container spacing={2}>
      {(() => {
        if (is_internal) {
          return <InternalUser />;
        } else if (existing_user.existing) {
          return <ExistingUser email={existing_user.email} />;
        } else {
          return <ExternalUser link={link}>{link}</ExternalUser>;
        }
      })()}
    </Grid>
  </>
);

const handlePost = (data, successCallback, errorCallback) => {
  axios
    .post("account/", data)
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error?.response?.data?.message);
    });
};

const InviteUserDialog = ({ refetchTableData, open, onCancel }) => {
  const [step, setStep] = React.useState(1);
  const isStep2 = step === 2;
  const [invitationHash, setInvitationHash] = React.useState(null);
  const [isInternal, setIsInternal] = React.useState(false);
  const [existingUser, setExistingUser] = React.useState({
    existing: false,
    email: "",
  });
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (values, { setSubmitting }) => {
    const valuesForSubmission = shapeValuesForSubmission(values);
    const onSuccess = ({ data }) => {
      setInvitationHash(data.invitation_url);
      setIsInternal(data.is_internal);
      if (!data.invitation_url && data.invitation_status && data.created_at) {
        setExistingUser({ existing: true, email: data.email });
      }
      setStep((prev) => prev + 1);
      setSubmitting(false);
      refetchTableData();

      if (data.invitation_status === "error") {
        enqueueSnackbar(
          "We encountered an error sending your email invitation. Please share the invitation link manually.",
          { variant: "error" }
        );
      }
    };
    const onError = (errorMessage) => {
      enqueueSnackbar(
        errorMessage ||
          "We encountered an error. Please retry or contact us for assistance!",
        { variant: "error" }
      );
      setSubmitting(false);
    };
    handlePost(valuesForSubmission, onSuccess, onError);
  };
  const onDialogExited = () => setStep(1);

  const link = `${invitationHash}`;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isSubmitting, resetForm }) => (
        <Form>
          <ConfirmationDialog
            open={open}
            onCancel={onCancel}
            onConfirm={handleSubmit}
            title="Invite a User"
            confirmationText="Invite User"
            confirmationButtonProps={{ color: "primary" }}
            showDialogActions={!isStep2}
            loading={isSubmitting}
            divider
            TransitionProps={{
              onExited: () => {
                resetForm();
                onDialogExited();
              },
            }}
          >
            {step === 1 && <InviteUserForm />}
            {isStep2 && (
              <InviteSentConfirmation
                link={link}
                is_internal={isInternal}
                existing_user={existingUser}
              />
            )}
          </ConfirmationDialog>
        </Form>
      )}
    </Formik>
  );
};

export default InviteUserDialog;
