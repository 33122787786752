import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { SectionLabel } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import Tooltip from "components/Common/Tooltip";
import YesNo from "components/Formik/formGroups/YesNo";
import FormikAsyncSelect from "components/Formik/fields/FormikAsyncSelect";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import {
  columns,
  defaultInitialValues,
  nonRequired,
  tableQuestions,
} from "./config.js";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <YesNo
        label="Current Driver's License"
        name="is_primary_drivers_license"
        xs={12}
      />
      <FormikDatePicker label="Date Last Issued" name="from_date" xs={4} />
      <FormikDatePicker label="Expiration Date" name="to_date" xs={4} />
      <FormikTextField label="License Number" name="license_number" xs={4} />
      <FormikTextField label="Type of License" name="license_type" xs={4} />

      <FormikAsyncSelect
        name="issuing_jurisdiction"
        endpoint="jurisdiction?all=true"
        label="Jurisdiction"
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.tag}
        fetchOptions={{ useFullEndpoint: false }}
        xs={8}
      />

      <SectionLabel label="Upload a copy of your Driver's License (not required for all states)">
        <Tooltip title="Required by: Missouri." type="warning" />
      </SectionLabel>

      <FormikFileDropzone
        description="Vehicle Licensing"
        label="Upload Attachment"
        name="attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};

const VehicleLicensing = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "vehicle_licenses_vehicleLicense",
    nonRequired,
  });

  return (
    <FormSectionProvider formData={formData} title={name}>
      <EnhancedFormikDialog title="Vehicle Licensing">
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        subheader={<InfoAlert>{tableQuestions}</InfoAlert>}
        columns={columns}
        addButtonText="Add Vehicle License"
      />
    </FormSectionProvider>
  );
};

export default VehicleLicensing;
