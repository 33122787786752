export const columns = [
  { title: "Tax Office", field: "tax_agency_pd_business_entity.business_name" },
  { field: "tax_year" },
  { field: "activity_type", format: "startCase" },
  { title: "Return Type", field: "region_type", format: "startCase" },
];

export const defaultInitialValues = {
  tax_agency_pd_business_entity_id: null,
  tax_year: null,
  audit_year: null,
  activity_type: null,
  region_type: null,
};

export const tableQuestions =
  "Has your Federal or State Income Tax Return ever been audited, adjusted or have you failed to file either?";
