export const liabilitiesTableInfo = [
  {
    name: "Notes Payable",
    route: "notes_payable",
    endpoint: `net_worth_statements_notePayable`,
    // "payable?type=note",
    data: ["original_amount", "outstanding_amount", null],
  },
  {
    name: "Loans & Other Payables",
    route: "loans_and_other_payables",
    endpoint: `net_worth_statements_loanPayable`,
    // "payable?type=loan&is_current=true",
    data: ["original_amount", "outstanding_amount", null],
  },
  {
    name: "Taxes Payable",
    route: "taxes_payable",
    endpoint: `net_worth_statements_taxesPayablePayables`,
    // "payable?type=tax",
    data: ["original_amount", "outstanding_amount", null],
  },
  {
    name: "Real Estate Mortgages/Liens",
    route: "real_estate_loans",
    endpoint: `net_worth_statements_mortgagePayable`,
    // "payable?type=mortgage&is_current=true",
    data: ["original_amount", "outstanding_amount", null],
  },
  {
    name: "Insurance/Pension Loans",
    route: "insurance_pension_loans",
    endpoint: `net_worth_statements_insurancePayable`,
    // "payable?type=insurance&is_current=true",
    data: ["original_amount", "outstanding_amount", null],
  },
  {
    name: "Other Indebtedness",
    route: "other_indebtedness",
    endpoint: `net_worth_statements_otherPayable`,
    // "payable?type=other",
    data: ["original_amount", "outstanding_amount", null],
  },
];

export const assetsTableInfo = [
  {
    name: "Cash on Hand",
    route: "cash",
    endpoint: `net_worth_statements_cash`,
    // "pd",
    data: [null, "cash_on_hand", null],
  },
  {
    name: "Cash In Bank",
    route: "bank_accounts",
    endpoint: `net_worth_statements_bankAccount`,
    // "bankAccount",
    data: [null, "current_balance", "cash_value_date"],
  },
  {
    name: "Loans & Receivables",
    route: "loans_and_receivables",
    endpoint: `net_worth_statements_receivable`,
    // "receivable?is_current=true",
    data: ["original_loan_amount", "current_balance", "receivable_value_date"],
  },
  {
    name: "Securities",
    endpoint: `net_worth_statements_securities`,
    route: "securities",
    data: ["purchase_price", "market_value", "security_value_date"],
  },
  {
    name: "Real Estate Interests",
    route: "real_estate_interests",
    endpoint: `net_worth_statements_realestate`,
    // "realestate",
    data: ["purchase_price", "market_value", "realestate_value_date"],
  },
  {
    name: "Life Insurance Cash Value",
    route: "life_insurance",
    endpoint: `net_worth_statements_lifeInsurance`,
    // "lifeInsurance",
    data: [null, "surrender_value", "life_insurance_value_date"],
  },
  {
    name: "Pension/Retirement Funds",
    route: "pension_funds",
    endpoint: `net_worth_statements_pension`,
    // "pension",
    data: ["employee_contribution", "cash_value", "pension_value_date"],
  },
  {
    name: "Furniture & Clothing",
    route: "other_assets",
    endpoint: `net_worth_statements_networth`,
    // "pd",
    data: ["furniture_cost", "furniture_value", "furniture_value_date"],
  },
  {
    name: "Vehicles",
    route: "vehicles",
    endpoint: `net_worth_statements_vehicle`,
    // "vehicle",
    data: ["cost", "current_value", "vehicle_value_date"],
  },
  {
    name: "Business Investments",
    route: "business_investments",
    endpoint: `net_worth_statements_business_investments`,
    data: ["acquisition_cost", "market_value", null],
  },
  {
    name: "Other",
    route: "other_assets",
    endpoint: `net_worth_statements_otherAsset`,
    // "otherAsset",
    data: ["cost", "market_value", "other_asset_value_date"],
  },
];

export const contingentTableInfo = [
  {
    name: "Contingent Liabilities",
    route: "contingent_liabilities",
    endpoint: `net_worth_statements_contingent_totals`,
    data: ["original_amount_total", "outstanding_amount_total", null],
  },
];
