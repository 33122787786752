export const defaultInitialValues = {
  in_pd1_job_description_attachment_group: null,
  in_pd1_police_clearance_attachment_group_id: null,
  in_pd1: {
    current_employer_business_entity_id: null,
    associated_business_entity_id: null,
    position_type_and_location: "",
    job_qualifications: "",
    compensation_from_associated_entity: "",
    job_description_of_positon: "",
    gender_identity: "",
    gender_identity_explanation: "",
    vehicle_license_suspension_statement: "",
    gaming_nominee_details: "",
    business_entity_advantage_payment_explanation: "",
    household_income_monthly: "",
    additional_business_addresses: "",
    sealed_civil_record_details: "",
    child_support_court_order_details: "",
    tax_audit_description: "",
  },
};

export const endpoint = "indiana_disclosure_questions_pdSupplement";
