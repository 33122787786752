import React from "react";
import GridContainer from "components/Common/Containers/Grid/GridContainer";
import GridItem from "components/Common/Containers/Grid/GridItem";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useState, useEffect } from "react";

import { getModel } from "helpers/apiHelpers";

export default function ApplicationPdfViewer({
  onClose,
  data,
  id,
  account_type,
}) {
  const [pdfUrl, setPdfUrl] = useState("");
  const refAccountType = React.useRef(account_type);
  if (account_type === "business") {
    refAccountType.current = "bdOrganization";
  } else if (account_type === "employee") {
    refAccountType.current = "employee";
  }
  useEffect(() => {
    (async () => {
      const controlledPath =
        account_type === "employee" ? "personal_details_pdfForm" : "pdfForm";
      const endpoint = `/${refAccountType.current}/${id}/${controlledPath}/${data.pdf_form_id}/generatedPdf/${data.id}/getPdfUrl`;
      await getModel(endpoint)
        .then(function (result) {
          //const encodedUrl = encodeURIComponent(result.url || "");
          //const embeddedUrl = `https://docs.google.com/viewer?url=${encodedUrl}&embedded=true`;
          setPdfUrl(result.url);
        })
        .catch(function () {
          setPdfUrl("");
        });
    })();
  }, [data.id, data.pdf_form_id, id, account_type]);

  if (pdfUrl === "") {
    return (
      <GridContainer style={{ height: "100% " }}>
        <GridItem md={12} style={{ height: "95%", textAlign: "center" }}>
          <CircularProgress style={{ marginTop: "100px" }} />
        </GridItem>
      </GridContainer>
    );
  }

  return (
    <GridContainer style={{ height: "100% " }}>
      <GridItem md={12} style={{ height: "95%" }}>
        <iframe
          title="Application"
          src={pdfUrl}
          height="100%"
          width="100%"
        ></iframe>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Close
        </Button>
      </GridItem>
    </GridContainer>
  );
}
