import React from "react";
import { FormContext } from "../components/Layout/SectionsLayout/FormSectionProvider";
import useGetPermission from "components/Permission/useGetPermission";

// eslint-disable-next-line react/display-name
const withFormContext = (Component) => (props) => {
  const { formikProps } = React.useContext(FormContext);
  const permission = useGetPermission();

  if (!formikProps) {
    throw new Error("formikProps not available in context");
  }
  const newProps = { ...formikProps, permission, ...props };
  return <Component {...newProps} />;
};

export default withFormContext;
