import { fieldTypes } from "componentGenerators/formGenerator/config";

const accountantsColumns = [
  { field: "related_pd_business_entity.business_name" },
  { field: "representative_name" },
  { field: "from_date" },
  { field: "to_date" },
];

const accountantFormSchema = [
  {
    type: fieldTypes.subHeader,
    divider: true,
    label: "Bookkeepers, Accountants, Accounting Firms",
  },
  {
    type: fieldTypes.pdBusinessSelect,
    name: "related_pd_business_entity_id",
    label: "Business Entity",
    defaultValue: null,
    required: true,
  },
  {
    type: fieldTypes.text,
    label: "Representative Name",
    name: "representative_name",
  },
  {
    type: fieldTypes.date,
    label: "From Date",
    name: "from_date",
  },
  {
    type: fieldTypes.date,
    label: "To Date",
    name: "to_date",
  },
];

const accountantsSchema = {
  type: "table",
  formSchema: accountantFormSchema,
  endpoint: "louisiana_phfr_supplement_accountants_pdServiceProviderAccountant",
  title: "Accountants",
  columns: accountantsColumns,
  infoAlerts: [
    {
      body: `Has the applicant employed any bookkeeper, accountant, accounting firm, 
      Certified Public Accountant or other person to assist in the preparation of financial 
      statements, tax returns or financial documents or records during the past seven
      (7)	years?`,
    },
  ],

  modifySubmissionValues: (formValues) => ({
    service_role: "accountant",
    ...formValues,
  }),
};

export default [accountantsSchema];
