import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Transportation Service",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Does your enterprise provide transportation services to any Pennsylvania casino, either directly or indirectly through an arrangement with a transportation company?",
    name: "pennsylvania_gspr.has_transportation_services",
  },
  {
    type: fieldTypes.label,
    label:
      "If yes, provide the name (s) for each enterprise that will provide the transportation service:",
  },
  {
    type: fieldTypes.text,
    label: "Company Name(s)",
    name: "pennsylvania_gspr.transportation_services_company_names",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.subHeader,
    label: "Gaming Service Providers",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Does the applicant have any association with any other gaming service providers or gaming service provider applicants who conduct or anticipate conducting business with Pennsylvania licensees/applicants?",
    name: "pennsylvania_gspr.has_gaming_penn_service_providers",
  },
  {
    type: fieldTypes.label,
    label:
      "If yes, provide the name of the gaming service providers or gaming service provider applicants and explain the association between the companies:",
  },
  {
    type: fieldTypes.text,
    label: "Name(s) & Association(s)",
    name: "pennsylvania_gspr.penn_service_providers_details",
    rows: 4,
    xs: 12,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "bdSupplement",
};
