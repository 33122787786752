import { Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikGoogleLookup from "components/Formik/fields/FormikGoogleLookup";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import PDRelativeSelect from "components/Formik/formGroups/CrudSelect/selects/PDRelativeSelect.jsx";
import { FirstMiddleLast } from "components/Formik/formGroups/FirstMiddleLast.jsx";
import { FormikAddressFields } from "components/Formik/formGroups/FormikAddressFields";
import YesNo from "components/Formik/formGroups/YesNo";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import {
  columns,
  defaultInitialValues,
  endpoint,
  nonRequired,
  tableQuestions,
} from "./config.js";

const SiblingsForm = () => {
  return (
    <Grid container spacing={2}>
      <FirstMiddleLast />

      <SectionSubHeader label="Personal Details" divider />
      <YesNo xs={12} label="Is this sibling deceased?" name="is_deceased" />
      <YesNo xs={12} label="Is this sibling retired?" name="is_retired" />

      <FormikDatePicker label="Date of Birth" name="date_of_birth" />
      <FormikTextField
        label="Place of Birth"
        name="place_of_birth"
        placeholder="City, State, Country"
        helperText="City, State/Province, Country"
      />
      <FormikTextField label="Phone Number" name="phone_number" />
      <FormikTextField
        label="Occupation"
        name="occupation"
        helperText="If retired or deceased, list last occupation"
      />

      <SectionSubHeader label="Home Address" divider />
      <FormikGoogleLookup
        fields={{
          address: "home_address",
        }}
      />
      <FormikAddressFields addressPrefix="home_address." />

      <SectionSubHeader
        header="Sibling's Spouse (if unmarried, leave blank)"
        divider
      />
      <PDRelativeSelect label="Sibling's Spouse" name="spouse_pd_person_id" />
    </Grid>
  );
};

const Siblings = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint,
    nonRequired,
  });

  return (
    <FormSectionProvider formData={formData} title={name}>
      <EnhancedFormikDialog title={name}>
        <SiblingsForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        subheader={<InfoAlert>{tableQuestions}</InfoAlert>}
        columns={columns}
        addButtonText="Add Record"
      />
    </FormSectionProvider>
  );
};

export default Siblings;
