import { DialogContent, Divider, Tab } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import useTabs from "hooks/useTabs";
import { InfoTab } from "components/Pages/LicensingControl/Employees/components/EmployeeInfoTab.jsx";
import { Header } from "components/Pages/LicensingControl/components/DetailsDialog/DialogComponents.jsx";
import { Dialog } from "components/Pages/LicensingControl/components/DetailsDialog/DialogComponents.jsx";
import { StyledTabs } from "components/Pages/LicensingControl/components/DetailsDialog/DialogComponents.jsx";
import { TabPanel } from "components/Pages/LicensingControl/components/DetailsDialog/DialogComponents.jsx";
import { RecordsTab } from "components/Pages/LicensingControl/Employees/components/RecordsTab.jsx";

export const EmployeeDetailsModal = ({
  open,
  onClose,
  currentRecord,
  formikProps,
  licensesFormInitiallyOpen,
}) => {
  const [tab, handleTabChange] = useTabs(0);

  const {
    first_name,
    last_name,
    middle_name,
    lc_attachment_group_id,
    id: personId,
    id: employmentId,
  } = currentRecord;

  const attachmentGroupUseState = React.useState(
    lc_attachment_group_id || null
  );

  const fullName = `${first_name || ""} ${middle_name || ""} ${
    last_name || ""
  } `;

  const initialEmploymentValues = formikProps.initialValues;

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        title={<Header label={fullName} />}
        fullWidth
        maxWidth="md"
      >
        <StyledTabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
        >
          <Tab label="Info" />
          <Tab label="Attachments" />
        </StyledTabs>
        <Divider />
        <DialogContent style={{ height: "80vh" }}>
          <TabPanel tab={tab} index={0}>
            <InfoTab
              data={currentRecord}
              formikProps={formikProps}
              title="Edit Employee"
              licensesFormInitiallyOpen={licensesFormInitiallyOpen}
              id={personId}
            />
          </TabPanel>
          <TabPanel tab={tab} index={1}>
            <RecordsTab
              entityId={employmentId}
              attachmentGroupUseState={attachmentGroupUseState}
              initialEmploymentValues={initialEmploymentValues}
            />
          </TabPanel>
        </DialogContent>
      </Dialog>
    </>
  );
};

EmployeeDetailsModal.propTypes = {
  currentRecord: PropTypes.any,
  formikProps: PropTypes.object,
  licensesFormInitiallyOpen: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
