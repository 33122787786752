import React from "react";
import { Card, Box } from "@material-ui/core";

export default function LocateTags(props) {
  return (
    <Card
      key={props.key}
      style={{
        display: "flex",
        flexDirection: "row",
        padding: "0px 0px 0px 10px",
        fontSize: "14px",
      }}
    >
      <Box style={{ marginRight: "10px" }}>
        Page Number: <strong>{props.page_number}</strong>
      </Box>
      <Box style={{ marginRight: "10px" }}>
        PDF: <strong>{props.name}</strong>
      </Box>
      <Box style={{ marginRight: "10px" }}>
        Jurisdiction: <strong>{props.s3_url}</strong>
      </Box>
      <Box style={{ marginRight: "10px" }}>
        Tag: <strong>{props.source_tag_name}</strong>
      </Box>
    </Card>
  );
}
