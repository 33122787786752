import { formats } from "components/Common/Tables/Common/helpers";
import { fieldTypes } from "componentGenerators/formGenerator/config";

const residentialHistoryColumns = [
  {
    field: "from_date",
  },
  {
    field: "to_date",
  },
  {
    field: "address",
    format: formats.address,
  },
  {
    field: "is_primary",
    format: formats.boolean,
  },
];

const residentialHistoryFormSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Address",
  },
  {
    type: fieldTypes.googleLookup,
    fields: {
      address: "address",
    },
  },
  {
    type: fieldTypes.addressFields,
    name: "address",
  },
  {
    type: fieldTypes.subHeader,
    divider: true,
    label: "Dates",
  },
  {
    name: "from_date",
    type: fieldTypes.date,
    label: "From",
  },
  {
    name: "is_current",
    type: fieldTypes.checkBox,
    label: "Is current",
    disabled: true,
    checked: true,
  },
];

const residentialHistorySchema = {
  type: "table",
  endpoint:
    "michigan_isbigo_supplement_residential_history_residencialHistoryCurrentPrimary",
  formSchema: residentialHistoryFormSchema,
  columns: residentialHistoryColumns,
  infoAlerts: [
    {
      body: "Please provide below your current residence address information.",
    },
  ],
  modifySubmissionValues: (formValues) => ({
    ...formValues,
    is_primary: true,
    is_current: true,
  }),
};

export default [residentialHistorySchema];
