import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";
import { LicensesForm } from "./LicensesForm";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";

const Licenses = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "bdLicense",
    nonRequired: ["disposition_reason", "bd_license_attachment_group_id"],
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title="Licenses">
        <LicensesForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Licenses" />
    </FormSectionProvider>
  );
};

export default Licenses;
