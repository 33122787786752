import React from "react";

export const columns = [
  {
    title: "Advisor",
    field: "related_party_name",
  },
  {
    title: "To Date",
    field: "to_date",
    format: "date",
  },
  {
    title: "From Date",
    field: "from_date",
    format: "date",
  },
  /*{
    title: "Business Name",
    field: "bd_business_entity.business_name",
  },*/
  {
    title: "Representative Name",
    field: "representative_name",
  },
];

export const tableQuestions = (
  <>
    Provide details of any legal advisers, solicitors and/or legal consultants
    engaged by the Applicant in the jurisdiction during the last ten (10) years.
  </>
);

export const defaultInitialValues = {
  //bd_business_entity_id: null,
  related_bd_party_id: null,
  from_date: null,
  to_date: null,
  is_current: false,
  service_rendered: "",
  related_bd_party_role: "lawyer",
  representative_name: "",
};
