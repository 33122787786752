export const filterDocuments = (documents, selectedIDs) =>
  documents.filter((item) => {
    for (const id of selectedIDs) {
      if (id === item.id) {
        return true;
      }
    }
    return false;
  });

const removeEmptyValues = (array, key) => {
  return array.reduce((acc, curr) => {
    if (curr[key] || curr[key] === 0) {
      return [...acc, curr[key]];
    }
    return acc;
  }, []);
};

export const getUniqueSet = (array, key) => {
  return [...new Set(removeEmptyValues(array, key))];
};

const filterMultiSelect = (doc, key, filters) => {
  if (!filters.length) return true;
  return !!filters.find((filterValue) => doc[key] === filterValue);
};

const cleanStringsForSearch = (strings) =>
  strings.reduce((acc, curr) => {
    return acc + curr.trim().toLowerCase();
  }, "");

const searchFilter = ({ name }, columns, searchValue) => {
  if (searchValue == "") return true;

  const categoryIDs = columns.map(({ id }) => id);

  return cleanStringsForSearch([name, ...categoryIDs]).includes(
    cleanStringsForSearch([searchValue])
  );
};

const applyMultiSelectFilters = (
  documents,
  multiSelectFilterProps,
  columns
) => {
  let filteredDocuments = documents;

  columns.forEach((column, idx) => {
    filteredDocuments = filteredDocuments.filter((item) =>
      filterMultiSelect(item, column.id, multiSelectFilterProps.filters[idx])
    );
  });

  return filteredDocuments;
};

export const applyDocumentFilter = (
  multiSelectFilterProps,
  searchValue,
  { documents, columns }
) => {
  const multiSelectFiltered = applyMultiSelectFilters(
    documents,
    multiSelectFilterProps,
    columns
  );
  return multiSelectFiltered.filter((item) =>
    searchFilter(item, columns, searchValue)
  );
};
