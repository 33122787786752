import PropTypes from "prop-types";
import { formSchemas } from "components/Formik/formGroups/CrudSelect/CrudSelect";
import React from "react";
import BusinessIcon from "@material-ui/icons/Business";
import PermissionedCrudSelect from "./PermissionedCrudSelect";

const PDLicenseAgencySelect = ({
  name = "license_agency_pd_business_entity_id",
  label = "License Agency",
  endpoint = "license_agency_business_entities",
  formTitle = "Add / Edit License Agency",
  formSchema = formSchemas.pdLicenseAgencySchema,
  usesPermission = true,
  ...rest
}) => {
  return (
    <PermissionedCrudSelect
      name={name}
      label={label}
      endpoint={endpoint}
      saveEndpoint="pdBusinessEntity"
      usesPermission={usesPermission}
      formSchema={formSchema}
      formTitle={formTitle}
      overrideDefaultValues={{
        business_type_checkbox: { is_license_agency: true },
      }}
      optionIcon={<BusinessIcon color="primary" fontSize="small" />}
      {...rest}
    />
  );
};

PDLicenseAgencySelect.propTypes = {
  endpoint: PropTypes.string,
  formSchema: PropTypes.array,
  formTitle: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  usesPermission: PropTypes.bool,
};

export default PDLicenseAgencySelect;
