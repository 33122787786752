export const headCells = [
  { key: "name", name: "License Holder" },
  { key: "related_business_name", name: "Business" },
  { key: "license_type_name", name: "License Type" },
  { key: "agency_name", name: "Agency" },
  { key: "license_type_category", name: "Category" },
  { key: "current_status", name: "Status" },
  { key: "license_expiry_date", name: "Expiration Date" },
];

export const csvHeaders = [
  { label: "License Number", key: "license_number" },
  { label: "License Name", key: "license_type_name" },
  { label: "License Agency", key: "agency_name" },
  { label: "License Status", key: "current_status" },
  { label: "License Start Date", key: "start_date" },
  { label: "Expiry Date", key: "expiration_date" },
  { label: "License Category", key: "license_type_category" },
  { label: "License Holder", key: "name" },
  { label: "Employee Email", key: "person_email" },
  { label: "Employee SSN", key: "person_ssn" },
  { label: "External ID", key: "external_id" },
];
