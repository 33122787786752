import useEndPoint from "hooks/useEndpoint";
import useGet from "hooks/useGet";
import { useSnackbar } from "notistack";
import usePatch from "queryHooks/usePatch";
import usePost from "../queryHooks/usePost";
import useDelete from "queryHooks/useDelete";
import useDeleteDialog from "hooks/useDeleteDialog";

const useInlineMultiForm = ({ endpoint, queryKey, enabled }) => {
  const { path, noAttributeEndpoint } = useEndPoint(endpoint);

  const { data, status } = useGet(path, queryKey, {
    enabled: !!enabled,
  });

  const { enqueueSnackbar } = useSnackbar();

  const { mutate: patchMutation } = usePatch(queryKey, noAttributeEndpoint, {});

  const { mutate: postMutation } = usePost(queryKey, noAttributeEndpoint, {});

  const handleFetchSuccess = (handleSaveSuccess, setSubmitting) => {
    handleSaveSuccess();
    setSubmitting(false);
    enqueueSnackbar("Record Saved", {
      autoHideDuration: 2000,
      variant: "success",
    });
  };

  const handleFetchError = (setSubmitting) => {
    setSubmitting(false);
    enqueueSnackbar("Error", {
      variant: "error",
    });
  };

  const addRecord = ({
    values,
    formikBag: { setSubmitting },
    handleSaveSuccess,
  }) => {
    const { id, ...submittedValues } = values;
    postMutation(
      { pd_person_id: id, ...submittedValues },
      {
        onSuccess: () => handleFetchSuccess(handleSaveSuccess, setSubmitting),
        onError: () => handleFetchError(setSubmitting),
      }
    );
  };

  const editRecord = ({
    values,
    formikBag: { setSubmitting },
    id,
    handleSaveSuccess,
  }) => {
    patchMutation(
      { id, ...values },
      {
        onSuccess: () => handleFetchSuccess(handleSaveSuccess, setSubmitting),
        onError: () => handleFetchError(setSubmitting),
      }
    );
  };

  const confirmDelete = (id) => {
    // eslint-disable-next-line no-use-before-define
    deleteMutation({ id });
  };

  const { reset, openDeleteDialog, open, onConfirm, onCancel } =
    useDeleteDialog({
      handleConfirm: confirmDelete,
    });

  const { mutate: deleteMutation } = useDelete(queryKey, noAttributeEndpoint, {
    onMutate: reset,
    onError: () => {
      enqueueSnackbar("Error Deleting Record", {
        variant: "error",
      });
    },
  });

  const deleteDialogProps = {
    isDeleting: open,
    confirmDelete: onConfirm,
    cancelDelete: onCancel,
    deleteRecord: openDeleteDialog,
  };

  return {
    records: data,
    status,
    editRecord,
    addRecord,
    deleteDialogProps,
    deleteRecord: openDeleteDialog,
  };
};

export default useInlineMultiForm;
