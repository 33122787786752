import { getFormComponents } from "componentGenerators/helpers";
import bd_arizona_designee_supplement_application_information from "components/Pages/Supplemental/BD/ArizonaDesignee/ArizonaDesigneeApplicationInformation/ArizonaDesigneeApplicationInformation";
import bd_arizona_designee_supplement_financial_information from "components/Pages/Supplemental/BD/ArizonaDesignee/ArizonaDesigneeFinancialInformation/ArizonaDesigneeFinancialInformation";
import bd_arizona_designee_supplement_supplemental_allocation_application from "components/Pages/Supplemental/BD/ArizonaDesignee/ArizonaDesigneeSupplementalAllocationApplication/ArizonaDesigneeSupplementalAllocationApplication";
import bd_arizona_designee_supplement_tax_liability from "components/Pages/Supplemental/BD/ArizonaDesignee/ArizonaDesigneeTaxLiability/ArizonaDesigneeTaxLiability";

export default getFormComponents({
  bd_arizona_designee_supplement_application_information,
  bd_arizona_designee_supplement_financial_information,
  bd_arizona_designee_supplement_supplemental_allocation_application,
  bd_arizona_designee_supplement_tax_liability,
});
