import axios from "axios";
import { useMutation } from "react-query";

export function useSaveInvitePassword(id) {
  const saveInvitePassword = async (payload) => {
    const { data } = await axios.patch(`/auth/invite/${id}`, payload);
    return data;
  };

  return useMutation(saveInvitePassword);
}
