export const getAddressJson = (addr) => {
  let addr_json = {
    street: [],
    city: "",
    state: "",
    postal_code: "",
    country: "",
    county: "",
  };
  if (!addr.address_components) {
    return addr_json;
  }
  addr.address_components.map(function (addr_comp) {
    const type = addr_comp.types;
    if (type.indexOf("postal_code") !== -1) {
      addr_json["postal_code"] = addr_comp.long_name;
    } else if (type.indexOf("country") !== -1) {
      addr_json["country"] = addr_comp.long_name;
    } else if (
      type.indexOf("street_number") !== -1 ||
      type.indexOf("route") !== -1 ||
      type.indexOf("premise") !== -1 ||
      type.indexOf("subpremise") !== -1
    ) {
      addr_json["street"].push(addr_comp.short_name);
    } else if (type.indexOf("locality") !== -1) {
      addr_json["city"] = addr_comp.long_name;
    } else if (type.indexOf("administrative_area_level_2") !== -1) {
      addr_json["county"] = addr_comp.long_name;
    } else if (type.indexOf("administrative_area_level_1") !== -1) {
      addr_json["state"] = addr_comp.long_name;
    }
    return null;
  });
  addr_json["street"] = addr_json["street"].join(" ");
  return addr_json;
};

export const generateFullAddress = (address) => {
  if (!address) return "";
  const order = ["street", "city", "state", "postal_code", "country"];
  return order.reduce((acc, key) => {
    const val = address?.[key] || "";
    if ((key === "city" || key === "state") && val.length) {
      acc += `${val}, `;
    } else {
      acc += `${val} `;
    }
    return acc;
  }, "");
};
