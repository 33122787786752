import React from "react";
import { pardons } from "../../uiStrings";

export const columns = [
  { field: "pardon_date" },
  {
    title: "Agency Name",
    field: "law_enforcement_pd_business_entity.business_name",
  },
  { field: "pardon_action" },
];

export const tableQuestions = <>{pardons.tableQuestion}</>;

export const nonRequired = [];

export const endpoint = "pardons_pardonLegalHistory";

export const defaultInitialValues = {
  type: "pardon",
  pardon_action: "",
  pardon_date: null,
  law_enforcement_pd_business_entity_id: null,
};

export const modalTitle = "Pardon";

export const dataHookProps = {
  defaultInitialValues,
  nonRequired,
  endpoint,
};
