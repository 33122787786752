/* eslint-disable react/display-name */
import { Grid, Divider } from "@material-ui/core";
import React from "react";
import FlexRow from "../components/flex/FlexRow";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import SentryErrorBoundary from "error/SentryErrorBoundary";
import Tooltip from "../components/Common/Tooltip";

const getGridSizing = ({ xs, sm, md, lg, xl }) => {
  const xsValue = xs || 12;
  const smValue = sm || xsValue;
  const mdValue = md || smValue;
  const lgValue = lg || mdValue;
  const xlValue = xl || lgValue;
  return {
    xs: xsValue,
    sm: smValue,
    md: mdValue,
    lg: lgValue,
    xl: xlValue,
  };
};

const shouldUseGrid = ({ xs, sm, md, lg, xl }) => {
  return Boolean(xs || sm || md || lg || xl);
};

const GridWrapper = ({ children, ...rest }) => {
  if (shouldUseGrid(rest)) {
    return (
      <Grid item {...getGridSizing(rest)}>
        {children}
      </Grid>
    );
  }
  return children;
};

const withFieldTools =
  (Component) =>
  ({ tooltip, children, header, divider, tooltipProps, ...rest }) => {
    return (
      <SentryErrorBoundary useGrid>
        {divider && (
          <Grid item xs={12}>
            <Divider />
          </Grid>
        )}
        {header && <SectionSubHeader label={header} />}
        <GridWrapper {...rest}>
          <FlexRow>
            <Component {...rest} />
            {typeof tooltip === "string" ? (
              <Tooltip title={tooltip} {...tooltipProps} />
            ) : (
              tooltip
            )}
            {children}
          </FlexRow>
        </GridWrapper>
      </SentryErrorBoundary>
    );
  };

export default withFieldTools;
