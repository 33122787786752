import { columns, tableQuestions, endpoint, formSchema } from "./config.js";

const schema = {
  type: "table",
  endpoint,
  formSchema,
  columns,
  infoAlerts: [
    {
      severity: "info",
      body: tableQuestions,
    },
  ],
};

export default [schema];
