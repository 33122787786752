import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import DeleteConfirmation from "components/Common/Dialogs/DeleteConfirmation";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";
import Center from "components/flex/Center";
import { useDynamicMultiSelect } from "components/Pages/LicensingControl/components/FilterTable/useMultiSelect";
import { captureSentryError } from "error/helpers";
import { createModel } from "helpers/apiHelpers";
import { downloadURI, sortDocumentSections } from "helpers/utils";
import useDeleteDialog from "hooks/useDeleteDialog";
import useEndPoint from "hooks/useEndpoint";
import useInput from "hooks/useInput";
import { useViewAttachment } from "hooks/useViewAttachment";
import { useSnackbar } from "notistack";
import useFetch from "queryHooks/useFetch";
import React from "react";
import { FileViewer } from "../../Common/FileDropZone/components";
import { DocumentCategoriesList } from "./components/DocumentCategoriesList";
import { DocumentsDataGrid } from "./components/DocumentsDataGrid";
import { Filters } from "./components/Filters";
import { Header } from "./components/Header";
import { applyDocumentFilter, filterDocuments, getUniqueSet } from "./helpers";
import { useAuth } from "context/auth";

// handles the delete confirmation modal
const useDeleteAttachments = (singleFileDelete) => {
  const {
    reset,
    openDeleteDialog: handleDeleteFile,
    setOn: _setOn,
    setOff: _setOff,
    ...deleteDialogProps
  } = useDeleteDialog({
    handleConfirm: singleFileDelete,
  });

  return { handleDeleteFile, reset, ...deleteDialogProps };
};

const useFilter = ({ currentDocumentCategory }) => {
  const filterCount = currentDocumentCategory?.columns?.length || 0;

  const multiSelectFilterProps = useDynamicMultiSelect(filterCount);

  const filterTags = React.useMemo(() => {
    if (!currentDocumentCategory) return {};

    return currentDocumentCategory.columns.reduce(
      (acc, currCol) => ({
        ...acc,
        [currCol.id]: getUniqueSet(
          currentDocumentCategory.documents,
          currCol.id
        ),
      }),
      {}
    );
  }, [currentDocumentCategory]);

  return {
    multiSelectFilterProps,
    filterTags,
    filterCount: multiSelectFilterProps.filters.flat().length,
    resetFilters: multiSelectFilterProps.reset,
  };
};

const MissingFilesAlert = ({ missingFiles }) => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="subtitle1">
        <b>Warning</b> The following files were not downloaded:
      </Typography>
      <ul>
        {missingFiles.map(({ name, id }) => (
          <li key={id}>{name} test</li>
        ))}
      </ul>
    </Box>
  );
};

const useRepositoryActions = ({ handleDeleteSuccess }) => {
  const { fullEndpoint } = useEndPoint("personal_details_documentRepository");

  const { enqueueSnackbar } = useSnackbar();

  const triggerMissingFilesWarning = (missingFiles) => {
    enqueueSnackbar(<MissingFilesAlert missingFiles={missingFiles} />, {
      variant: "warning",
    });
  };

  const getFileIds = (selectedDocuments) =>
    selectedDocuments.map(({ id }) => id);

  const downloadZipFile = (selectedDocuments) => {
    createModel(fullEndpoint, {
      attachmentIds: getFileIds(selectedDocuments),
    })
      .then(({ data }) => {
        if (data.zip) downloadURI(data.zip);
        if (data.missingFiles.length)
          triggerMissingFilesWarning(data.missingFiles);
      })
      .catch((err) => {
        captureSentryError(err);
        enqueueSnackbar("Error Downloading File(s)", {
          variant: "error",
        });
      });
  };

  const deleteFiles = (attachmentIds) => {
    createModel(fullEndpoint + "/deleteAll", {
      attachmentIds,
    })
      .then(({ data }) => {
        enqueueSnackbar("File(s) Deleted", {
          variant: "success",
        });
        handleDeleteSuccess(data);
      })
      .catch((err) => {
        captureSentryError(err);
        enqueueSnackbar("Error Deleting File(s)", {
          variant: "error",
        });
      });
  };

  const bulkFileDelete = (selectedDocuments) => {
    deleteFiles(getFileIds(selectedDocuments));
  };

  const singleFileDelete = (id) => {
    deleteFiles([id]);
  };

  return { downloadZipFile, bulkFileDelete, singleFileDelete };
};

const DocumentRepository = () => {
  const [currentDocumentCategory, setCurrentDocumentCategory] =
    React.useState(null);
  const [filteredDocuments, setFilteredDocuments] = React.useState([]);
  const [selectedDocuments, setSelectedDocuments] = React.useState([]);
  const { account_type } = useAuth();

  const {
    attachmentBeingViewed,
    handleViewFile,
    // handleDownloadFile
  } = useViewAttachment();

  const { multiSelectFilterProps, filterTags, filterCount, resetFilters } =
    useFilter({
      currentDocumentCategory,
    });

  const [searchValue, handleSearch, resetSearchInput] = useInput();

  // currentDocumentCategory is defaulted on first page load but not subsequent
  const didInitialPageLoad = React.useRef(null);

  const handleDocumentFetchSuccess = (data) => {
    if (didInitialPageLoad.current) {
      const selected = data.find(
        ({ label }) => currentDocumentCategory.label === label
      );

      setCurrentDocumentCategory(selected);
      setFilteredDocuments(
        applyDocumentFilter(multiSelectFilterProps, searchValue, selected)
      );
    } else {
      didInitialPageLoad.current = true;
      setCurrentDocumentCategory(data[0]);
      setFilteredDocuments(data[0].documents);
    }
  };

  const {
    data: documentsData = [],
    status,
    refetch,
  } = useFetch(
    ["documentsData", account_type],
    "personal_details_documentRepository",
    {
      errorMessage: "Error Retrieving Document",
      onSuccess: handleDocumentFetchSuccess,
      select: sortDocumentSections,
    }
  );

  const onSelectionModelChange = (selectionModel) => {
    setSelectedDocuments(
      filterDocuments(currentDocumentCategory?.documents, selectionModel)
    );
  };

  const clearFiltersAndSelections = () => {
    resetFilters();
    resetSearchInput();
    setSelectedDocuments([]);
  };

  const selectDocumentCategory = (label) => {
    const selected = documentsData.find((category) => label === category.label);
    setCurrentDocumentCategory(selected);
    setFilteredDocuments(selected.documents);
    clearFiltersAndSelections();
  };

  const handleFilter = () => {
    setFilteredDocuments(
      applyDocumentFilter(
        multiSelectFilterProps,
        searchValue,
        currentDocumentCategory
      )
    );
  };

  const handleDeleteSuccess = (data) => {
    const deletedRemoved = filteredDocuments.filter(
      (doc) => !data.some(({ id }) => id === doc.id)
    );

    setFilteredDocuments(deletedRemoved);
    refetch();
    // eslint-disable-next-line no-use-before-define
    resetDeleteConfirmations();
  };

  const { downloadZipFile, bulkFileDelete, singleFileDelete } =
    useRepositoryActions({
      handleDeleteSuccess,
    });

  const {
    handleDeleteFile: handleSingleDelete,
    reset: singleDeleteReset,
    ...singleDeleteDialogProps
  } = useDeleteAttachments(singleFileDelete);

  const {
    handleDeleteFile: handleBulkDelete,
    reset: bulkDeleteReset,
    ...bulkDeleteDialogProps
  } = useDeleteAttachments(() => bulkFileDelete(selectedDocuments));

  const resetDeleteConfirmations = () => {
    singleDeleteReset();
    bulkDeleteReset();
  };

  const handleDownloadSelected = () => downloadZipFile(selectedDocuments);

  const handleDownloadAll = () =>
    downloadZipFile(currentDocumentCategory.documents);

  return (
    <LoadingComponent status={status}>
      <DeleteConfirmation
        {...singleDeleteDialogProps}
        title="Are you sure you want to delete this document?"
      />
      <DeleteConfirmation
        {...bulkDeleteDialogProps}
        title={`Are you sure you want to delete ${
          selectedDocuments.length
        } document${selectedDocuments.length === 1 ? "" : "s"}?`}
      />
      <FileViewer attachment={attachmentBeingViewed} />
      <Container maxWidth="xl" disableGutters>
        <Box pb={1} />
        <Paper variant="outlined">
          <Grid container style={{ height: "850px" }}>
            <Grid item xs={3}>
              <Box height={1} display="flex" justifyContent="space-between">
                <DocumentCategoriesList
                  documentsData={documentsData}
                  selectDocumentCategory={selectDocumentCategory}
                  currentDocumentCategory={currentDocumentCategory}
                />
                <Divider orientation="vertical" />
              </Box>
            </Grid>
            <Grid item xs={9}>
              {!currentDocumentCategory ? (
                <Center>
                  <CircularProgress />
                </Center>
              ) : (
                <>
                  <Header
                    {...currentDocumentCategory}
                    showBulkActions={!!selectedDocuments.length}
                    handleDownloadSelected={handleDownloadSelected}
                    handleDownloadAll={handleDownloadAll}
                    handleDeleteSelected={handleBulkDelete}
                    filters={
                      <Filters
                        handleFilter={handleFilter}
                        filterTags={filterTags}
                        filterProps={multiSelectFilterProps}
                        filterCount={filterCount}
                        searchValue={searchValue}
                        handleSearch={handleSearch}
                      />
                    }
                  />
                  <Divider />

                  <DocumentsDataGrid
                    handleViewFile={handleViewFile}
                    // handleDownloadFile={handleDownloadFile}
                    handleDeleteFile={handleSingleDelete}
                    columns={currentDocumentCategory?.columns || []}
                    documents={filteredDocuments}
                    onSelectionModelChange={onSelectionModelChange}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </LoadingComponent>
  );
};

export default DocumentRepository;
