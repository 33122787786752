import {
  columns,
  tableQuestions,
  nonRequired,
  endpoint,
  formSchema,
  employeesCriminalChargesQuestions,
} from "./config.js";

const criminalProceedingsSchema = {
  type: "table",
  endpoint,
  formSchema,
  nonRequired,
  columns,
  infoAlerts: [
    {
      body: tableQuestions,
    },
  ],
  modifySubmissionValues: (formValues) => ({
    ...formValues,
    type: "criminal",
  }),
};

const employeesCriminalChargesSchema = {
  type: "readOnlyTable",
  endpoint: "views/bd_employees_criminal_charges",
  infoAlert: employeesCriminalChargesQuestions,
  columns: [
    {
      title: "Party Name",
      field: "person_name",
    },
    {
      title: "Agency or Court",
      field: "agency_name",
    },
    {
      title: "Criminal Disposition",
      field: "disposition_pretty",
    },
    {
      title: "Offense Type",
      field: "charge_type",
      format: "startCase",
    },
    {
      title: "Docket Number",
      field: "case_number",
    },
    {
      title: "Charge Date",
      field: "charge_date",
      format: "date",
    },
  ],
};

export default [criminalProceedingsSchema, employeesCriminalChargesSchema];
