import React from "react";
import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikSelect from "components/Formik/fields/FormikSelect";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import useTabularData from "hooks/tableHooks/useTabularData";

import { columns, tabularDataParams, tableQuestions } from "./config.js";
import FormikCheckBox from "components/Formik/fields/FormikCheckBox.jsx";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import YesNo from "components/Formik/formGroups/YesNo.jsx";
import BDBusinessSelect from "components/Formik/formGroups/CrudSelect/selects/BDBusinessSelect.jsx";
import BDPartySelect from "components/Formik/formGroups/CrudSelect/selects/BDPartySelect.jsx";

const LoansPayableForm = () => {
  const { loanOtherPartyRole, loanStatus, bdLoansInstrumentType } =
    useMenuOptions();

  return (
    <Grid container spacing={2}>
      <BDBusinessSelect label="Add Applicant" header="Applicant" />
      <BDPartySelect
        name="related_bd_party_id"
        label="Other Party"
        header="Other Party"
        xs={6}
      />

      <FormikSelect
        label="Other Party's Role"
        name="related_bd_party_role"
        options={loanOtherPartyRole}
        xs={4}
      />
      <SectionSubHeader label="Instrument Type" />
      <FormikSelect
        name="instrument_type"
        options={bdLoansInstrumentType}
        xs={4}
      />
      <FormikTextField
        label="Other Instrument Type"
        name="instrument_type_other_description"
        helperText="If Applicable"
        xs={4}
      />
      <SectionSubHeader label="Dates" divider />
      <FormikDatePicker label="Issue Date" name="from_date" xs={4} />
      <FormikDatePicker label="Repayment Due Date" name="to_date" xs={4} />
      <SectionSubHeader label="" />
      <FormikDatePicker
        label="Date Renewable Until"
        name="renewable_date"
        xs={4}
        helperText="(If Applicable)"
      />
      <FormikCheckBox label="Is Renewable" name="is_renewable" xs={4} />

      <SectionSubHeader label="Amount" divider />
      <FormikNumberField
        textFieldProps={{ type: "none" }}
        label="Original Amount"
        name="amount_original"
        xs={4}
      />
      <FormikNumberField
        textFieldProps={{ type: "none" }}
        label="Current Amount"
        name="amount_current"
        xs={4}
      />
      <FormikNumberField
        type="percent"
        label="Annual Interest Rate"
        name="annual_interest_rate"
        xs={4}
      />

      <SectionSubHeader label="Details" divider />
      <FormikTextField label="Purpose of Loan" name="purpose_of_loan" xs={4} />
      <FormikTextField label="Collateral" name="collateral" xs={4} />
      <FormikSelect
        label="Loan Status"
        name="loan_status"
        options={loanStatus}
        xs={4}
      />
      <FormikTextField
        multiline
        rows={3}
        label="Terms, covenants, conditions and priorities for this debt instrument"
        name="term_and_conditions"
        xs={12}
      />

      <SectionSubHeader label="Loan Documents" divider />
      <FormikFileDropzone
        description="BD Loans Payable"
        label="Upload copies of the loan and finance agreements."
        name="loan_documents_attachment_group_id"
        size={12}
      />
      <SectionSubHeader label="Delinquency to Government" />
      <YesNo
        label="Is this debt related to a delinquency in the payment of any obligations to any governmental agency?"
        name="has_government_debt_delinquency"
      />
      <SectionLabel label="If YES, upload any documents to prove settlement or resolution of the delinquency." />
      <FormikFileDropzone
        description="Settlement or resolution of the delinquency"
        name="government_debt_delinquency_attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};

const LoansPayable = ({ name }) => {
  const formData = useTabularData(tabularDataParams);

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <LoansPayableForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
    </FormSectionProvider>
  );
};

export default LoansPayable;
