import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import { useMenuOptions } from "hooks/useMenuOptions";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, tableQuestions, tabularDataParams } from "./config.js";
import YesNo from "components/Formik/formGroups/YesNo";
import FormikRadioGroup from "components/Formik/formGroups/FormikRadioGroup.jsx";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone.jsx";
import BDPartySelect from "components/Formik/formGroups/CrudSelect/selects/BDPartySelect.jsx";
import BDLawEnforcementSelect from "components/Formik/formGroups/CrudSelect/selects/BDLawEnforcementSelect.jsx";

const CriminalProceedingsForm = () => {
  const { criminalDispositionType } = useMenuOptions();
  return (
    <Grid container spacing={2}>
      <BDPartySelect />

      <BDLawEnforcementSelect />

      <SectionSubHeader label="Type" divider />
      <SectionLabel label="Is this a Testimony or Investigations" />
      <FormikRadioGroup
        options={[
          { name: "Testimony", value: "testimony" },
          { name: "Investigations", value: "investigation" },
        ]}
        name="type"
        xs={12}
      />
      <SectionSubHeader label="Nature & Details" divider />
      <YesNo
        label="Was a testimony or polygraph given?"
        name="is_testimony_given"
        xs={12}
      />
      <YesNo
        label="Was the testimony or polygraph refused?"
        name="is_testimony_refused"
        xs={12}
      />

      <FormikDatePicker label="Testimony Date" name="testimony_date" xs={4} />
      <FormikTextField
        label="Time Period of Investigation"
        name="time_period"
        xs={4}
      />

      <FormikTextField
        label="Nature of Proceeding or Investigation"
        name="nature_of_charge"
        xs={8}
      />
      <FormikSelect
        label="Disposition"
        name="criminal_disposition"
        options={criminalDispositionType}
        xs={4}
      />
      <SectionLabel label="Attach any supporting documents" />
      <FormikFileDropzone
        description="BD Testimony & Investigation"
        name="bd_legal_history_attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};

const CriminalProceedings = ({ name }) => {
  const formData = useTabularData(tabularDataParams);

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <CriminalProceedingsForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
    </FormSectionProvider>
  );
};

export default CriminalProceedings;
