import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";
import { useMenuOptions } from "hooks/useMenuOptions";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import { SectionSubHeader } from "components/Common/LayoutComponents/";
import FormikGoogleLookup from "components/Formik/fields/FormikGoogleLookup.jsx";
import { FormikAddressFields } from "components/Formik/formGroups/FormikAddressFields";
import YesNo from "components/Formik/formGroups/YesNo";
import Tooltip from "components/Common/Tooltip";

const Form = () => {
  const { schoolOptions } = useMenuOptions();

  return (
    <Grid container spacing={2}>
      <SectionSubHeader>Institution Address</SectionSubHeader>

      <FormikGoogleLookup
        fields={{
          name: "school_name",
          phone: "school_phone",
          address: "school_address",
        }}
      />
      <FormikTextField label="School Name" name="school_name" xs={6} />
      <FormikTextField label="School Phone" name="school_phone" xs={6} />
      <FormikAddressFields addressPrefix="school_address." />

      <FormikDatePicker
        header="Dates"
        divider
        type="yearMonth"
        label="From Date"
        name="from_date"
        xs={3}
      />
      <FormikDatePicker
        type="yearMonth"
        label="To Date"
        name="to_date"
        xs={3}
      />

      <SectionSubHeader label="Details" divider />
      <FormikTextField label="Program" name="program" xs={4} />
      <FormikSelect
        label=" Type of School"
        name="school_type"
        options={schoolOptions}
        xs={4}
      />
      <FormikTextField label="Degree" name="degree" xs={4} />
      <YesNo label="Did you Graduate?" name="did_graduate" xs={12} />
      <FormikTextField
        label="Reason for leaving"
        name="reason_for_leaving"
        helperText="If you did not graduate"
        multiline
        rows={2}
        xs={12}
      />
      <SectionSubHeader label="Transcripts" divider>
        <Tooltip
          type="warning"
          title={
            <>
              <p>Not required by all jurisdictions.</p>
              <p>
                Missouri requires a certified copy of your college transcripts
              </p>
            </>
          }
        />
      </SectionSubHeader>
      <FormikFileDropzone
        description="Education"
        label="Upload Attachment"
        name="education_attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};

const Education = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "education_education",
    nonRequired: ["reason_for_leaving"],
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default Education;
