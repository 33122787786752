import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Relationships",
  },
  {
    type: fieldTypes.label,
    label:
      "Do you have any personal or business relationship with any member, agent or employee of the Louisiana Gaming Control Board, the Louisiana Department of Justice, Office of the Attorney General, or the Louisiana Department of Public Safety, Office of the State Police? If YES, please provide the details below.",
  },
  {
    type: fieldTypes.yesNo,
    name: "louisiana_phfr.section_g_q_6_boolean",
    xs: 12,
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "louisiana_phfr.section_g_q_6_details",
    xs: 12,
    rows: 4,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "louisiana_phfr_supplement_licensing_information_pdSupplement",
};
