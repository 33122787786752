import React from "react";
import { userColumns, appColumns } from "./config";
import DisplayTable from "components/Common/Tables/DisplayTable";
import useFetch from "queryHooks/useFetch";
import { useLocation } from "react-router-dom";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent.jsx";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";

const CustomerAnalytics = () => {
  //  const formData = useTabularData(dataHookProps);
  const id = new URLSearchParams(useLocation().search).get("id");
  const { data = [], status } = useFetch(
    `admin/customerDashboard/${id}`,
    `admin/customerDashboard/${id}`,
    { useFullEndpoint: false }
  );
  return (
    <div>
      <FormSectionProvider
        title={data[0]?.customer.name}
        headerComponent={
          <InfoAlert>
            <b>Users:</b> {data[0]?.new_key_account} New Key Accounts,{" "}
            {data[0]?.active_key_account} Active Key Accounts,{" "}
            {data[0]?.business_accounts} Business Accounts{" "}
          </InfoAlert>
        }
      >
        <LoadingComponent status={status}>
          <DisplayTable columns={userColumns} records={data[0]?.all_accounts} />
        </LoadingComponent>
      </FormSectionProvider>
      <FormSectionProvider
        title={data[0]?.customer.name}
        headerComponent={
          <InfoAlert>
            <b>Active Applications:</b> {data[0]?.active_applications.length}{" "}
            Active Applications, {data[0]?.new_applications} New Applications
          </InfoAlert>
        }
      >
        <LoadingComponent status={status}>
          <DisplayTable
            columns={appColumns}
            records={data[0]?.active_applications}
          />
        </LoadingComponent>
      </FormSectionProvider>
    </div>
  );
};

export default CustomerAnalytics;
