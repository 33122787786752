import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm";
import React from "react";
import { defaultInitialValues } from "./config.js";

const CashForm = () => (
  <Grid container spacing={2}>
    <FormikNumberField label="Total Cash on Hand" name="cash_on_hand" xs={4} />
  </Grid>
);

const CashOnHand = ({ name }) => {
  const formData = useForm({
    defaultInitialValues,
    endpoint: "cash_cash",
    noValidate: true,
  });

  return (
    <FormSectionWrapper title={name} formData={formData}>
      <EnhancedFormikPage>
        <CashForm />
      </EnhancedFormikPage>
    </FormSectionWrapper>
  );
};

export default CashOnHand;
