export const defaultInitialValues = {
  tennessee_bed: {
    license_type: null,
    sports_gaming_operator_name: "",
    supplier_business_type: "",
    tn_bed_application_contact_bd_person_id: null,
    proposed_operation_date: null,
    is_certified_mbe_wbe_vbe: null,
  },
};

export const endpoint = "bdSupplement";
