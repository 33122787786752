import {
  fieldTypes,
  formGroupTypes,
} from "componentGenerators/formGenerator/config";
import { NumberFormatTypes } from "components/Formik/fields/FormikNumberField";

const ownershipColumns = [
  { field: "person_name", title: "Parent's Name" },
  { field: "business_name", title: "Business Name" },
  { field: "ownership_percent", title: "Ownership Percentage" },
];

const ownershipFormSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Parent",
  },
  {
    type: fieldTypes.pdPersonSelect,
    name: "pd_person_id",
    label: "Parent Name",
    required: true,
    endpoint: "parent",
  },
  {
    type: fieldTypes.subHeader,
    label: "Business Owned",
  },
  {
    type: fieldTypes.pdBusinessSelect,
    entityType: "pdBusiness",
    name: "pd_business_entity_id",
    label: "Business Owned",
    required: true,
  },
  {
    type: fieldTypes.subHeader,
    label: "Ownership",
  },
  {
    type: fieldTypes.number,
    label: "Ownership Percentage",
    name: "ownership_percent",
    format: NumberFormatTypes.percent,
  },
  {
    type: fieldTypes.subHeader,
    label: "Dates",
  },
  {
    formGroup: formGroupTypes.fromToCurrent,
  },
];

const ownershipSchema = {
  type: "table",
  formSchema: ownershipFormSchema,
  saveEndpoint: "michigan_limited_pd_supplement_ownership_companyOwnership",
  endpoint:
    "michigan_limited_pd_supplement_ownership_company_ownership_parents_over_five_percent",
  title: "Parent's Ownership",
  columns: ownershipColumns,
  infoAlerts: [
    {
      body: `Do your parent(s) have an equity interest of 5% or more in any business other than the subject of this application?`,
    },
  ],
};

export default [ownershipSchema];
