import { Grid } from "@material-ui/core";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import PDBusinessSelect from "components/Formik/formGroups/CrudSelect/selects/PDBusinessSelect.jsx";
import PDRelativeSelect from "components/Formik/formGroups/CrudSelect/selects/PDRelativeSelect.jsx";
import FormikCheckBoxGroup from "components/Formik/formGroups/FormikCheckBoxGroup";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";
import useEndPoint from "hooks/useEndpoint";

const Form = () => {
  const { businessInterestRoleCheckboxOptions } = useMenuOptions();
  return (
    <Grid container spacing={2}>
      <PDRelativeSelect
        name="pd_person_id"
        label="Spouse or Child (If Applicable)"
        header="Select if held by Spouse or Dependent Child"
      />
      <PDBusinessSelect
        label="Business Entity"
        header="Business Entity"
        divider
      />

      <SectionSubHeader label="Investment Details" divider />

      <FormikCheckBoxGroup
        menuOptions={businessInterestRoleCheckboxOptions}
        name="role_checkbox"
        label="Indicate how you are related to the business"
        row
        xs={12}
      />
      <FormikNumberField label="Annual Income" name="annual_income" xs={4} />
      <FormikNumberField
        type="percent"
        label="Ownership %"
        name="ownership_percent"
        helperText="% Interest held by you, if any"
        xs={4}
      />
      <SectionSubHeader label="Acquisition" divider />
      <FormikDatePicker
        label="Acquisition Date"
        name="acquisition_date"
        xs={4}
      />
      <FormikNumberField label="Cost" name="acquisition_cost" xs={4} />
      <FormikTextField
        label="Years Associated"
        name="years_associated"
        xs={4}
      />
      <SectionSubHeader label="Valuation" divider />
      <FormikDatePicker label="Valuation Date" name="valuation_date" xs={4} />
      <FormikNumberField
        label="Current Market Value"
        name="market_value"
        xs={4}
      />
      <FormikTextField
        label="Valuation Method"
        name="valuation_method"
        xs={6}
      />
      <SectionSubHeader label="Attachments" divider />
      <SectionLabel label="Some jurisdictions require additional documents.  If needed, you will be prompted with instructions outlining what documents are needed." />
      <FormikFileDropzone
        description="Loans And Receivables"
        label="Upload Attachment"
        name="attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};

const BusinessInvestments = ({ name }) => {
  const { fullEndpoint } = useEndPoint("business_investments_companyOwnership");

  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "business_investments_business_investments",
    saveEndpoint: fullEndpoint,
    nonRequired: ["pd_person_id"],
    overwriteMissingRequiredDisplay: {
      role_checkbox: "Role in Company",
    },
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default BusinessInvestments;
