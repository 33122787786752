import { SectionSubHeader } from "components/Common/LayoutComponents";
import {
  Box,
  DialogContentText,
  Divider,
  FormGroup,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import FormikCheckBox from "components/Formik/fields/FormikCheckBox";
import FormikEmailTextField from "components/Formik/fields/FormikEmailTextField";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { ModifyUserAccessField } from "components/Pages/ManageUser/EditUserForm";
import React from "react";
import styled from "styled-components";
import { permissionTypes } from "./config";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import FormikDebugger from "components/Formik/other/FormikDebugger";

const HeaderArea = styled.div`
  background-color: ${({ theme }) => theme.palette.grey[200]};
`;

const StyledListItem = styled(ListItem)`
  padding: ${({ theme }) => theme.spacing(0.5, 0, 0.5, 0)};
`;

const PermissionListItem = ({ id, helperText, impactsBilling, name }) => (
  <StyledListItem>
    <Box ml={3}>
      <ListItemIcon>
        <FormikCheckBox name="permissionIDs" value={id.toString()} />
      </ListItemIcon>
    </Box>
    <ListItemText
      primary={name}
      secondary={
        <>
          {helperText}
          {impactsBilling ? (
            <Box display="flex" pt={1}>
              <Box pr={1}>
                <MonetizationOnIcon fontSize="small" />
              </Box>
              <Typography variant="subtitle2">
                <Box fontWeight="fontWeightMedium">
                  Adding this permission may impact billing
                </Box>
              </Typography>
            </Box>
          ) : null}
        </>
      }
    />
  </StyledListItem>
);

function InviteUserForm() {
  return (
    <Grid container spacing={3}>
      <FormikDebugger />
      <Grid item xs={12}>
        <DialogContentText>
          To invite a user, please enter their name and email address here. We
          will send an invitation link.
        </DialogContentText>
      </Grid>
      <Grid item xs={12}>
        <SectionSubHeader label="User Details" divider />
        <FormGroup>
          <Grid container spacing={1}>
            <FormikTextField label="First Name" name="first_name" xs={6} />
            <FormikTextField label="Last Name" name="last_name" xs={6} />
            <FormikEmailTextField label="Email" name="email" xs={12} />

            <ModifyUserAccessField />
          </Grid>
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <Paper variant="outlined">
          <HeaderArea>
            <Box p={1.5}>
              <Typography
                variant="h3"
                fontWeight="fontWeightMedium"
                gutterBottom
              >
                User Role(s)
              </Typography>
              <Box display="flex" pt={1}>
                <Box pr={1}>
                  <ErrorOutlineIcon fontSize="small" />
                </Box>
                <Typography variant="subtitle2">
                  <Box fontWeight="fontWeightMedium">
                    Adding certain roles may result in additional billing
                  </Box>
                </Typography>
              </Box>
            </Box>
          </HeaderArea>
          <Divider />
          <FormGroup>
            {permissionTypes.map((permissionObject, index) => (
              <div key={permissionObject.id}>
                <PermissionListItem {...permissionObject} />
                {index < permissionTypes.length - 1 && (
                  <Divider variant="inset" />
                )}
              </div>
            ))}
          </FormGroup>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default InviteUserForm;
