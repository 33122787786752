import React from "react";

export const columns = [
  /*{
    title: "Business Entity",
    field: "bd_business_entity.business_name",
  },*/
  {
    title: "Issuing Entity",
    field: "issuing_bd_business_entity.business_name",
  },
  {
    field: "asset_type",
    format: "startCase",
  },
  {
    field: "type_of_stock",
    format: "startCase",
  },
  {
    title: "Held From",
    field: "purchase_date",
    format: "date",
  },
  {
    field: "market_value",
    format: "currency",
  },
];

export const tableQuestions = (
  <>
    List any group, firm, partnership, corporation or any other businesses in
    which the Applicant holds an ownership interest of 5% or more.
  </>
);

export const defaultInitialValues = {
  //bd_business_entity_id: null,
  issuing_bd_business_entity_id: null,
  asset_type: null, //BaseModel.enum("bdAssetTypes"),
  type_of_stock: null, //BaseModel.enum("bdStockTypes"),
  exchange: "",
  acquisition_unit_price: null,
  quantity_held: null,
  ownership_percent: null,
  is_voting: null,
  is_public: null,
  valuation_date: null,
  purchase_date: null,
  adjusted_cost_base: null,
  market_value: null,
  annual_income: null,
  attachment_group_id: null,
  terms: "",
};

export const nonRequired = [""];
