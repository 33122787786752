import { Box, Button, Card, Container } from "@material-ui/core";
import CardBody from "components/Common/Containers/Card/CardBody";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";
import DisplayTable from "components/Common/Tables/DisplayTable";
import useFetch from "queryHooks/useFetch";
import React from "react";
import FormikAsyncSelect from "components/Formik/fields/FormikAsyncSelect";

import { Formik } from "formik";

const columns = [
  { title: "Application Name", field: "name" },
  { title: "Tag", field: "tag" },
  { title: "Jurisdiction", field: "jurisdiction.name" },
];

const ApplicationForm = () => {
  const { data, status } = useFetch(
    "/admin/applicationForm",
    "/admin/applicationForm",
    { useFullEndpoint: false }
  );

  return (
    <LoadingComponent status={status}>
      <Formik initialValues={{ jurisdiction: "" }}>
        {({ values, setFieldValue }) => {
          const filteredData = data.filter(({ jurisdiction }) => {
            return values.jurisdiction
              ? jurisdiction.tag === values.jurisdiction
              : true;
          });

          const handleJurisdictionChange = ({ target: { value } }) => {
            setFieldValue("jurisdiction", value === "reset" ? null : value);
          };

          return (
            <Container>
              <Box pb={1}>
                <Card name="Filter">
                  <CardBody>
                    <Box display="flex" alignItems="center">
                      <FormikAsyncSelect
                        name="jurisdiction"
                        endpoint="/admin/jurisdiction"
                        label="Filter by Jurisdiction"
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.tag}
                        fetchOptions={{ useFullEndpoint: false }}
                        onChange={handleJurisdictionChange}
                      />
                      <Box ml={1} height={0.5}>
                        <Button
                          variant="contained"
                          color="primary"
                          value="reset"
                          onClick={handleJurisdictionChange}
                        >
                          Clear Filter
                        </Button>
                      </Box>
                    </Box>
                  </CardBody>
                </Card>
              </Box>
              <Box bgcolor="white">
                <DisplayTable records={filteredData} columns={columns} />\{" "}
              </Box>
            </Container>
          );
        }}
      </Formik>
    </LoadingComponent>
  );
};

export default ApplicationForm;
