import React from "react";

export const columns = [
  /*{
    title: "Business Name",
    field: "bd_business_entity.business_name",
    render: (record) => {
      return record?.bd_business_entity?.business_name;
    },
  },*/
  {
    title: "Auditors/Accountant",
    field: "related_party_name",
  },
  {
    title: "To Date",
    field: "to_date",
    format: "date",

  },
  {
    title: "From Date",
    field: "from_date",
    format: "date",

  },
  {
    title: "Reason for Investigation",
    field: "investigation_reason",
  },
];

export const tableQuestions = (
  <>
    Provide details of the Applicant&lsquo;s auditors/accountants for the past
    ten (10) years. To the best of the Applicant&lsquo;s knowledge, while
    engaged by the Applicant indicate if the auditors or external accountants
    were subject to any investigation of misconduct for any reason.
  </>
);

export const defaultInitialValues = {
  //bd_business_entity_id: null,
  related_bd_party_id: null,
  from_date: null,
  to_date: null,
  is_current: false,
  investigation_reason: "",
  representative_name: "",
};
