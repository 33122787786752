import React from "react";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Applicant to which goods or services will be provided",
  },
  {
    type: fieldTypes.label,
    label:
      "Select below the licensee/applicant, to which goods or services will be provided:",
  },
  {
    type: fieldTypes.asyncSelect,
    name: "pennsylvania_gspr.contract_agreement_id",
    endpoint: "views/bd_service_providers_a",
    label: "License/ Applicant",
    getOptionLabel: (option) => option.related_party_name,
    getOptionValue: (option) => option.id,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please note, if you do not see the licensee/applicant in the above
        dropdown, please go to the
        <CustomLink to="bd_contracts" /> section and create a new record.
      </>
    ),
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "bdSupplement",
};
