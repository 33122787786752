import React from "react";
import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Applicant’s Gaming Credentials",
  },
  {
    type: fieldTypes.text,
    label: "Job Title",
    name: "ohio.job_title",
    xs: 4,
  },
  {
    type: fieldTypes.text,
    label: "Experience",
    name: "ohio.job_experience",
    rows: 2,
    xs: 12,
  },
  {
    type: fieldTypes.businessAssociation,
    title:
      "EMPLOYING CASINO OPERATOR, MANAGEMENT COMPANY, HOLDING COMPANY, OR GAMING-RELATED VENDOR",
    label: "Company Name (Your Position)",
    name: "ohio.associated_business_id",
  },
  {
    type: fieldTypes.subHeader,
    label: "Applicant’s Compensation",
  },
  {
    type: fieldTypes.label,
    label: "The total amount of compensation you earned last year:",
  },
  {
    type: fieldTypes.text,
    label: "Last Year's Compensation",
    name: "ohio.previous_annual_compensation",
    xs: 6,
  },
  {
    type: fieldTypes.label,
    label: "The total amount of compensation you expect to earn this year:",
  },
  {
    type: fieldTypes.text,
    label: "Expected Compensation",
    name: "ohio.current_annual_compensation",
    xs: 6,
  },
  {
    type: fieldTypes.label,
    label:
      "The structure of your compensation (i.e., salary, wages, bonus, fees, commissions, options, etc.):",
  },
  {
    type: fieldTypes.text,
    label: "Compensation Structure",
    name: "ohio.structure_compensation",
    xs: 6,
  },
  {
    type: fieldTypes.subHeader,
    label: "Reason for Submitting this Application",
  },
  {
    type: fieldTypes.label,
    label: "I am applying in association with:",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "ohio.association_type_checkbox",
    dynamicMenuItems: ["ohioAssociationTypeCheckbox"],
  },
  {
    type: fieldTypes.label,
    label: "I am applying for a(n):",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "ohio.license_type_checkbox",
    dynamicMenuItems: ["ohioLicenseTypeCheckbox"],
  },
  {
    type: fieldTypes.label,
    label: "I am a Key Employee because I am a(n):",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "ohio.key_employee_checkbox",
    dynamicMenuItems: ["ohioKeyEmployeeCheckbox"],
  },
  {
    type: fieldTypes.label,
    label: "If you selected 'Other' above, please specify:",
  },
  {
    type: fieldTypes.text,
    label: "Other",
    name: "ohio.key_employee_other",
    xs: 6,
  },
  {
    type: fieldTypes.subHeader,
    label: "Provisional License",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Provisional license applicants must demonstrate exigent circumstances
        necessitating the immediate issuance of a provisional Key Employee
        License pending the Commission&apos;s complete review of the
        application. If you are seeking a provisional license, describe the
        exigent circumstances:
      </>
    ),
  },
  {
    type: fieldTypes.text,
    label: "Circumstances",
    name: "ohio.license_exigent_circumstances",
    xs: 12,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "ohio_disclosure_questions_pdSupplement",
};
