//NEW YORK
import { getFormComponents } from "componentGenerators/helpers";
import NewYorkDisclosureQuestions from "components/Pages/Supplemental/PD/NewYork/NewYorkDisclosureQuestions/NewYorkDisclosureQuestions";
import NewYorkTaxInformation from "components/Pages/Supplemental/PD/NewYork/NewYorkTaxInformation/NewYorkTaxInformation";
import NewYorkFinancialQuestions from "components/Pages/Supplemental/PD/NewYork/NewYorkFinancialQuestions/NewYorkFinancialQuestions";
import new_york_attachments from "components/Pages/Supplemental/PD/NewYork/NewYorkAttachments/NewYorkAttachments";

export default {
  new_york_disclosure_questions: NewYorkDisclosureQuestions,
  new_york_tax_information: NewYorkTaxInformation,
  new_york_financial_questions: NewYorkFinancialQuestions,
  ...getFormComponents({ new_york_attachments }),
};
