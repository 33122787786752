import PropTypes from "prop-types";
import { Box, Button, Collapse, Divider, Grid } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Center from "components/flex/Center";
import { useFormikContext } from "formik";
import useToggle from "hooks/useToggle";
import React from "react";
import _ from "lodash";

const checkIfDependantFieldValueExists = (dependantFields, values) =>
  dependantFields.some((name) => _.get(values, name));

const AdditionalFieldsSection = ({ children, dependantFields = [] }) => {
  const { values } = useFormikContext();
  const { on, toggle } = useToggle(
    checkIfDependantFieldValueExists(dependantFields, values)
  );

  return (
    <>
      <Grid item xs={12}>
        <Box pt={2}>
          <Divider />
        </Box>
      </Grid>
      <Center pt={2}>
        <Collapse in={!on} timeout="auto">
          <Button
            color="primary"
            startIcon={<AddCircleOutlineIcon />}
            onClick={toggle}
          >
            Additional Information
          </Button>
        </Collapse>
      </Center>
      <Grid item xs={12}>
        <Collapse in={on} timeout="auto">
          <Grid container spacing={2}>
            {children}
          </Grid>
        </Collapse>
      </Grid>
    </>
  );
};

AdditionalFieldsSection.propTypes = {
  children: PropTypes.node,
  dependantFields: PropTypes.array,
};

export default AdditionalFieldsSection;
