import { Grid } from "@material-ui/core";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import PDGamingSelect from "components/Formik/formGroups/CrudSelect/selects/PDGamingSelect.jsx";
import PDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/PDPersonSelect.jsx";
import PDRelativeSelect from "components/Formik/formGroups/CrudSelect/selects/PDRelativeSelect.jsx";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <PDRelativeSelect
        label="Relative (If Applicable)"
        header="Select if held by Immediate Family"
        endpoint="pdPerson"
        saveEndpoint="pdPerson"
      />

      <SectionSubHeader label="Shares" divider />

      <FormikNumberField
        label="Number of Shares"
        name="number_of_shares"
        type="none"
        xs={4}
      />
      <FormikTextField label="Interest Amount" name="interest_amount" xs={4} />

      <PDPersonSelect
        name="beneficial_owner_pd_person_id"
        label="Beneficial Owner"
        header="Beneficial Owner"
        divider
      />

      <SectionSubHeader
        label="Business Entity - if asset is a Business Entity"
        divider
      />
      <PDGamingSelect label="Select Business Entity (If applicable)" />

      <SectionSubHeader label="Trust Details" divider />
      <FormikTextField
        label="Description of Trust"
        name="description"
        helperText="Include details of the instrument creating the fiduciary obligation."
        xs={12}
        multiline
        rows={2}
      />
      <FormikTextField label="Location of Trust" name="location" xs={6} />

      <SectionSubHeader label="Trust Documents" divider />
      <SectionLabel label="Upload a copy of all documents related to the trust. Include Trust Agreements, Trust Identification & Disclosure Forms and a Schedule of Assets held by each trust." />

      <FormikFileDropzone
        description="Trust Documents"
        label="Upload Attachment"
        name="trust_attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};

const NomineeHoldings = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "nominee_holdings_nomineeHoldings",
    nonRequired: [
      "relative_pd_person_id",
      "number_of_shares",
      "interest_amount",
      "pd_business_entity_id",
      "trust_attachment_group_id",
    ],
    overwriteMissingRequiredDisplay: {
      beneficial_owner_pd_person_id: "Beneficial Owner",
    },
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default NomineeHoldings;
