export const defaultInitialValues = {
  elk_valley: {
    has_tax_audited_adjusted_last_2y: null,
    tax_audited_adjusted_last_2y_details: "",
  },
};

export const endpoint = "elk_valley_marital_status_pdSupplement";

export const columns = [
  {
    title: "Date Filed",
    field: "filing_date",
    format: "date",
  },
  {
    field: "type",
    format: "startCase",
  },
  {
    title: "Agency Name & Address",
    field: "agency_name_and_address",
  },
  {
    field: "case_number",
  },
  {
    title: "Disposition",
    field: "disposition_pretty",
  },
  {
    field: "disposition_date",
    format: "date",
  },
];
