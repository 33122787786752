export const categoryMenuOptions = [
  { value: "alimony_received", label: "Alimony received" },
  { value: "business_income", label: "Business Income" },
  { value: "capital_gain", label: "Capital Gains" },
  {
    value: "qualified_plan_distribution",
    label: "Qualified Plan Distributions",
  },
  { value: "other", label: "Other" },
];

export const informationAlert = {
  colorado: "Provide your last year of annual income information.",
  indiana: "Provide your last 3 years of annual income information.",
  illinois: "Provide your last 3 years of annual income information.",
};
