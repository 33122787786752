import React from "react";
import { formats } from "components/Common/Tables/Common/helpers";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import { indictments } from "components/Pages/PD/uiStrings";

const indictmentsColumns = [
  {
    title: "Date",
    field: "indictment_date",
    format: formats.date,
  },
  {
    title: "Agency Name",
    field: "law_enforcement_pd_business_entity.business_name",
  },
  { title: "Nature", field: "nature" },
];

const indictmentsFormSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Law Enforcement or Court",
  },
  {
    type: fieldTypes.asyncSelect,
    name: "law_enforcement_pd_business_entity_id",
    endpoint: "indictments_law_business_entities",
    label: "Law Enforcement or Court",
    getOptionLabel: (option) => option.business_name,
    getOptionValue: (option) => option.id,
  },
  {
    type: fieldTypes.subHeader,
    label: "Nature",
  },
  {
    name: "indictment_date",
    type: fieldTypes.date,
    label: "Date of Proceeding",
  },
  {
    type: fieldTypes.text,
    label: "Nature of Proceeding",
    name: "nature",
    xs: 12,
    rows: 4,
    required: true,
  },
];

const indictmentsSchema = {
  type: "table",
  endpoint:
    "michigan_isbigo_supplement_indictments_complaints_indictmentLegalHistory",
  formSchema: indictmentsFormSchema,
  columns: indictmentsColumns,
  infoAlerts: [
    {
      body: <>{indictments.tableQuestion}</>,
    },
  ],

  modifySubmissionValues: (formValues) => ({
    ...formValues,
    type: "indictment",
  }),
};

export default [indictmentsSchema];
