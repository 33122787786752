// @material-ui/core components
import CardBody from "components/Common/Containers/Card/CardBody";
import Button from "components/Common/CustomButtons/Button";
import { DefaultTextField } from "components/Common/InputElements";
import { useAuth } from "context/auth";
import { useFormik } from "formik";
import { withPreventDefault } from "helpers/eventHelpers";

import { Link as RouterLink } from "react-router-dom";

import { useSnackbar } from "notistack";
import React from "react";
import ResetCard from "./ResetCard";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { LoginButton, StyledCircularProgress } from "./styles";
import { useInitiatePasswordReset } from "queryHooks/useInitiatePasswordReset";

const useStyles = makeStyles(styles);

export default function ResetPassword(props) {
  const [email, setEmail] = React.useState(props.location.state || "");
  const { mutate, status } = useInitiatePasswordReset();
  const { loading } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant) => (message) => {
    enqueueSnackbar(message, {
      ...variant,
    });
  };

  const { values, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: {
      email: email || "",
    },
    onSubmit: ({ email }) => {
      resetForm();
      mutate(
        { email },
        {
          onSuccess: () => {
            setEmail("");
            handleSnackBar({
              variant: "success",
              autoHideDuration: 6000,
            })("Reset password link has been sent to your email");
          },
          onError: (err) => {
            setEmail("");
            handleSnackBar({
              variant: "error",
              autoHideDuration: 6000,
            })(
              "Password reset email could NOT be sent.  Please check your email address or contact OneComply for assistance."
            );
            console.log(err);
          },
        }
      );
    },
    enableReinitialize: true,
  });

  const classes = useStyles();

  if (loading) return null;

  return (
    <div className={classes.container}>
      <LoginButton
        className={classes.container}
        component={RouterLink}
        variant="outlined"
        to="/auth"
      >
        Login
      </LoginButton>
      <ResetCard>
        <form onSubmit={withPreventDefault(handleSubmit)}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <CardBody>
              <DefaultTextField
                label="Email"
                name="email"
                autoComplete="off"
                value={values.email}
                onChange={handleChange}
              />
            </CardBody>
            <Button
              type="submit"
              style={{
                width: "60%",
                alignSelf: "center",
              }}
              variant="contained"
              color="primary"
            >
              Reset my password{" "}
              {status === "loading" && <StyledCircularProgress size={20} />}
            </Button>
          </div>
        </form>
      </ResetCard>
    </div>
  );
}
