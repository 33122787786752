import Card from "components/Common/Containers/Card/Card";
import { Logo } from "assets/oneComply/img/Logo";

// core components
import GridContainer from "components/Common/Containers/Grid/GridContainer";
import GridItem from "components/Common/Containers/Grid/GridItem";
import React from "react";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(styles);

function ResetCard({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem style={{ width: "375px" }}>
          <Card style={{ paddingBottom: "12px", paddingTop: "20px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ paddingBottom: "20px" }}>
                <Logo size="l" />
              </div>
              <Typography gutterBottom variant="h2">
                Reset Password
              </Typography>
              <Typography variant="body2">
                Enter the email address you use to sign in to.
              </Typography>
              <div style={{ paddingTop: "15px" }}></div>
            </div>
            {children}
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default ResetCard;
