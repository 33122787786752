import { getFormComponents } from "componentGenerators/helpers";
import michigan_isbigo_supplement_personal_details from "components/Pages/Supplemental/PD/MichiganISBIGO/MichiganISBIGOPersonalDetails/MichiganISBIGOPersonalDetails";
import michigan_isbigo_supplement_applications_details from "components/Pages/Supplemental/PD/MichiganISBIGO/MichiganISBIGOApplicationDetails/MichiganISBIGOApplicationDetails";
import michigan_isbigo_supplement_residential_history from "components/Pages/Supplemental/PD/MichiganISBIGO/MichiganISBIGOResidentialHistory/MichiganISBIGOResidentialHistory";
import michigan_isbigo_supplement_vehicle_licenses from "components/Pages/Supplemental/PD/MichiganISBIGO/MichiganISBIGOVehicleLicenses/MichiganISBIGOVehicleLicenses";
import michigan_isbigo_supplement_citizenship from "components/Pages/Supplemental/PD/MichiganISBIGO/MichiganISBIGOCitizenship/MichiganISBIGOCitizenship";
import michigan_isbigo_supplement_marriages from "components/Pages/Supplemental/PD/MichiganISBIGO/MichiganISBIGOMarriages/MichiganISBIGOMarriages";
import michigan_isbigo_supplement_arrests_charges from "components/Pages/Supplemental/PD/MichiganISBIGO/MichiganISBIGOArrestsCharges/MichiganISBIGOArrestsCharges";
import michigan_isbigo_supplement_indictments_complaints from "components/Pages/Supplemental/PD/MichiganISBIGO/MichiganISBIGOIndictmentsComplaints/MichiganISBIGOIndictmentsComplaints";
import michigan_isbigo_supplement_non_filed_taxes from "components/Pages/Supplemental/PD/MichiganISBIGO/MichiganISBIGONonFiledTaxes/MichiganISBIGONonFiledTaxes";
import michigan_isbigo_supplement_tax_payables from "components/Pages/Supplemental/PD/MichiganISBIGO/MichiganISBIGOTaxPayables/MichiganISBIGOTaxPayables";
import michigan_isbigo_supplement_gaming_licenses from "components/Pages/Supplemental/PD/MichiganISBIGO/MichiganISBIGOGamingLicenses/MichiganISBIGOGamingLicenses";
import michigan_isbigo_supplement_bankruptcies from "components/Pages/Supplemental/PD/MichiganISBIGO/MichiganISBIGOBankruptcies/MichiganISBIGOBankruptcies";
import michigan_isbigo_supplement_family_gaming_involvement from "components/Pages/Supplemental/PD/MichiganISBIGO/MichiganISBIGOFamilyGamingInvolvement/MichiganISBIGOFamilyGamingInvolvement";

export default getFormComponents({
  michigan_isbigo_supplement_personal_details,
  michigan_isbigo_supplement_applications_details,
  michigan_isbigo_supplement_residential_history,
  michigan_isbigo_supplement_vehicle_licenses,
  michigan_isbigo_supplement_citizenship,
  michigan_isbigo_supplement_marriages,
  michigan_isbigo_supplement_arrests_charges,
  michigan_isbigo_supplement_indictments_complaints,
  michigan_isbigo_supplement_non_filed_taxes,
  michigan_isbigo_supplement_tax_payables,
  michigan_isbigo_supplement_gaming_licenses,
  michigan_isbigo_supplement_bankruptcies,
  michigan_isbigo_supplement_family_gaming_involvement,
});
