import { Collapse, Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikGoogleLookup from "components/Formik/fields/FormikGoogleLookup.jsx";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormikEmailTextField from "components/Formik/fields/FormikEmailTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import _ from "lodash";
import PDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/PDPersonSelect.jsx";
import { FirstMiddleLast } from "components/Formik/formGroups/FirstMiddleLast.jsx";

import { FormikAddressFields } from "components/Formik/formGroups/FormikAddressFields.jsx";
import YesNo from "components/Formik/formGroups/YesNo";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import { useFormikContext } from "formik";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";

const Form = () => {
  const {
    spouseTypeValues,
    parentTypeValues,
    siblingSpouseTypeValues,
    otherRelativeTypeValues,
  } = useMenuOptions();

  const allRelatives = [{ name: "No Relation", value: null }].concat(
    _.sortBy(
      []
        .concat(spouseTypeValues)
        .concat(parentTypeValues.slice(1))
        .concat(siblingSpouseTypeValues.slice(1))
        .concat(otherRelativeTypeValues.slice(1))
        .filter((option) => option.value !== null),
      "name"
    )
  );

  const {
    values: { relative_type },
  } = useFormikContext();

  return (
    <Grid container spacing={2}>
      <FirstMiddleLast />
      {/* <FormikTextField
        label="Full Name"
        name="name"
        helperText="Include Sr, Jr, ect, if applicable"
        xs={4}
      /> */}
      <YesNo label="Is this person deceased?" name="is_deceased" xs={12} />
      <YesNo label="Is this person a dependent?" name="is_dependent" xs={12} />
      <FormikSelect
        label="Relationship"
        name="relative_type"
        options={allRelatives}
        helperText="If Related"
        xs={4}
      />
      <SectionSubHeader header="Home Address" divider />

      <FormikGoogleLookup
        fields={{
          address: "home_address",
        }}
      />
      <FormikAddressFields addressPrefix="home_address." />
      <Grid style={{ padding: "0 6px" }} item xs={12}>
        <Collapse in={relative_type === "sibling"}>
          <Grid container spacing={1}>
            <SectionSubHeader
              label="SIBLING'S SPOUSE (IF UNMARRIED, LEAVE BLANK)"
              divider
            />
            <PDPersonSelect
              name="spouse_pd_person_id"
              label="Spouse (If Applicable)"
            />
          </Grid>
        </Collapse>
      </Grid>

      <Grid style={{ padding: "0 6px" }} item xs={12}>
        <Collapse
          in={
            relative_type === "brother_in_law" ||
            relative_type === "sister_in_law"
          }
        >
          <Grid container spacing={1}>
            <SectionSubHeader
              label="IN-LAW'S SPOUSE (IF UNMARRIED, LEAVE BLANK)"
              divider
            />

            <PDPersonSelect
              name="spouse_pd_person_id"
              label="Spouse (If Applicable)"
            />
          </Grid>
        </Collapse>
      </Grid>

      <FormikDatePicker
        header="Additional Information"
        divider
        label="Date of Birth"
        name="date_of_birth"
        xs={4}
      />
      <FormikTextField
        label="Place of Birth"
        name="place_of_birth"
        placeholder="City, State, Country"
        helperText="City, State/Province, Country"
        xs={8}
      />
      <FormikTextField label="Occupation" name="occupation" xs={4} />
      <FormikTextField label="Home Phone" name="phone_number" xs={4} />
      <FormikTextField label="Mobile Phone" name="mobile_phone" xs={4} />
      <FormikEmailTextField label="Email" name="email" xs={4} />
      <FormikTextField
        label="Amount of Support"
        name="support_amount"
        helperText="If Dependent"
      />
    </Grid>
  );
};

const People = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "people_pdPerson",
    nonRequired: [
      "relative_type",
      "spouse_pd_person_id",
      "is_dependent",
      "suffix_name",
      "middle_name",
      "maiden_name",
      "email",
      "support_amount",
    ],
  });

  return (
    <FormSectionProvider formData={formData} title={name}>
      <EnhancedFormikDialog title="Person Details">
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        subheader={<InfoAlert>{tableQuestions}</InfoAlert>}
        columns={columns}
        addButtonText="Add Person"
      />
    </FormSectionProvider>
  );
};

export default People;
