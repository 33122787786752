import useFetch from "queryHooks/useFetch";

export const useBdOrganizationId = () => {
  const { data, status } = useFetch(
    "bdOrganizationId",
    "/bdOrganization/manage",
    {
      useFullEndpoint: false,
    }
  );

  const id = data?.[0]?.id;

  return { status, data, id };
};
