import React from "react";

export const columns = [
  {
    field: "filing_date",
  },
  {
    field: "occurrence_date",
  },
  {
    field: "nature",
  },
  {
    field: "filing_location",
  },
  {
    field: "status",
  },
];

export const tableQuestions = (
  <>
    Provide details if any individual, local, city, county, provincial, state,
    Federal, national, or any other governmental liens/debts been filed against
    you as an individual, sole proprietor, member of a partnership, or owner of
    a corporation in any jurisdiction?
  </>
);

export const defaultInitialValues = {
  nature: "",
  occurrence_date: null,
  filing_date: null,
  filing_location: "",
  status: "",
};
