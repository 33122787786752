export const defaultInitialValues = {
  louisiana: {
    pd_business_entity_id: null,
    has_student_loan_default: null,
    q_e3_bool: null,
    q_f3_bool: null,
  },
  la_student_loan_attachment_group_id: null,
  la_q_e3_attachment_group_id: null,
  la_q_f3_attachment_group_id: null,
};

export const endpoint = "louisiana_disclosure_questions_pdSupplement";
