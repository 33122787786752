import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm";
import React from "react";
import { SectionLabel } from "components/Common/LayoutComponents";

const Form = () => {
  const formData = useForm({
    defaultInitialValues: {
      bd_elk_valley: {
        former_name: "",
      },
    },
    endpoint: "bdSupplement",
  });
  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage>
        <Grid container spacing={2}>
          <SectionLabel>
            If the application is to replace a license obtained under another
            name at the same location, state former name
          </SectionLabel>
          <FormikTextField
            label="Former name"
            name="bd_elk_valley.former_name"
          />
        </Grid>
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

const ElkValleyPreviousCompanyName = ({ title }) => {
  return (
    <FormSectionWrapper title={title}>
      <Form />
    </FormSectionWrapper>
  );
};

export default ElkValleyPreviousCompanyName;
