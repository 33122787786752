export const defaultInitialValues = {
  delaware: {
    pd_business_entity_id: null,
    language_written_or_spoken:"",
    qualifier_license_role_other_details: "",
    affiliated_company_names: "",
  },
  delaware_license_type_checkbox: null,
  delaware_qualifier_license_role_checkbox: null,
  la_q_f3_attachment_group_id: null,
};

export const endpoint = "pdSupplement";
