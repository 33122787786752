import BD_ElkValleyGamingServicesProvided from "components/Pages/Supplemental/BD/ElkValley/ElkValleyGamingServiceProvided/ElkValleyGamingServiceProvided";
import BD_ElkValleyInvestedOrLoaned from "components/Pages/Supplemental/BD/ElkValley/ElkValleyInvestedOrLoaned/ElkValleyInvestedOrLoaned";
import BD_ElkValleyPreviousCompanyName from "components/Pages/Supplemental/BD/ElkValley/ElkValleyPreviousCompanyName/ElkValleyPreviousCompanyName";

const bd_elk_supplement_pages = {
  bd_elk_supplement_gaming_services_provided: BD_ElkValleyGamingServicesProvided,
  bd_elk_supplement_invested_or_loaned: BD_ElkValleyInvestedOrLoaned,
  bd_elk_supplement_previous_company_name: BD_ElkValleyPreviousCompanyName,
};

export default bd_elk_supplement_pages;
