import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { get } from "lodash";

import { dayjsUTC } from "helpers/apiHelpers";
dayjs.extend(isBetween);

// const multiSelectFilter = (person, filterValues, name) => {
//   if (!filterValues.length) return true;
//   if (!get(person, name)) return false;

//   return filterValues
//     .map((i) => i.name)
//     .some((item) => item === get(person, name));
// };

const arrayMultiSelectFilter = ({
  employmentRecord,
  filterValues,
  name,
  path,
  lookAt,
}) => {
  if (!filterValues.length) return true;
  if (!get(employmentRecord, name).length) return false;
  return filterValues
    .map((i) => i[lookAt])
    .every((item) =>
      get(employmentRecord, name)
        .map((i) => get(i, `${path}.${lookAt}`))
        .includes(item)
    );
};

//I am merging person and business together into the same lookAt - I don't see any edge case that might break this
//but we can clean it up if needed.
const searchFilter = (employmentRecord, searchValue) => {
  searchValue = searchValue.trim()?.toLowerCase();
  if (searchValue == "") return true;

  const firstName = (get(employmentRecord, "first_name") || "").toLowerCase();
  const lastName = (get(employmentRecord, "last_name") || "").toLowerCase();

  const businessName = (
    get(employmentRecord, "business_name") || ""
  ).toLowerCase();

  const lookAt = businessName + firstName + lastName;

  return lookAt.includes(searchValue);
};

export const filterEntities = ({
  items,
  searchValue = "",
  industry,
  disposition,
  jurisdiction,
}) => {
  return items
    .filter((person) => searchFilter(person, searchValue))
    .filter((entity) =>
      arrayMultiSelectFilter({
        employmentRecord: entity,
        filterValues: jurisdiction?.filterValues || [],
        name: jurisdiction?.name,
        path: jurisdiction?.path,
        lookAt: jurisdiction?.lookAt,
      })
    )
    .filter((entity) =>
      arrayMultiSelectFilter({
        employmentRecord: entity,
        filterValues: industry?.filterValues || [],
        name: industry?.name,
        path: industry?.path,
        lookAt: industry?.lookAt,
      })
    )
    .filter((entity) =>
      arrayMultiSelectFilter({
        employmentRecord: entity,
        filterValues: disposition?.filterValues || [],
        name: disposition?.name,
        path: disposition?.path,
        lookAt: disposition?.lookAt,
      })
    );
};

export const formatDate = (date) => {
  if (!date) return "N/A";
  const formattedDate = dayjsUTC(date, "MMM D, YYYY");
  if (formattedDate === "Nov 11, 2600") return "Present";
  return dayjsUTC(date, "MMM D, YYYY");
};

export const checkIfPastDate = (date) =>
  dayjs(date).isBefore(dayjs(new Date()));

export const getDaysFromNow = (date) => {
  if (!date) return "N/A";
  return `${dayjs().to(date, true)} to expiration`;
};

const getDifferenceInDays = (from, to) =>
  Math.round((to - from) / (1000 * 60 * 60 * 24));

export const getProgressValue = (fromDate, toDate) => {
  const fromDateObj = dayjs(fromDate);
  const toDateObj = dayjs(toDate);
  const totalDays = getDifferenceInDays(fromDateObj, toDateObj);
  const daysPast = getDifferenceInDays(fromDateObj, dayjs());

  if (daysPast <= 0) return 0;

  return (daysPast / totalDays) * 100;
};

const recordsReducer = (acc, record, comparisonFn) => {
  // person has licenses inside lc_person, business does not
  // const licenses = record.lc_person
  //   ? record.lc_person.lc_license
  //   : record.lc_license;
  // using view - structure should be the same regadless of business or person
  // licenses will be nested inside bd_persons or bd_business_entities ... for now keeping backwards compatibility ...
  const licenses = record.license ? record.license : record.lc_license;
  if (licenses.some(comparisonFn)) {
    return [...acc, record];
  }
  return acc;
};

export const findNonCompliant = (records) => {
  return records.reduce(
    (acc, curr) =>
      recordsReducer(acc, curr, ({ to_date, expiration_date }) =>
        dayjs(expiration_date || to_date).isBefore(dayjs())
      ),
    []
  );
};

export const findExpiringSoon = (records) => {
  return records.reduce(
    (acc, curr) =>
      recordsReducer(
        acc,
        curr,
        ({ to_date, expiration_date }) =>
          dayjs(expiration_date || to_date).isBefore(dayjs().add(30, "day")) &&
          dayjs(expiration_date || to_date).isAfter(dayjs())
      ),
    []
  );
};

export const extractLicenses = (records) => {
  return records.reduce(
    (entityLicenses, { license, full_name, business_name, id }) => {
      return [
        ...entityLicenses,
        ...license.reduce((acc, curr) => {
          return [
            ...acc,
            { full_name, business_name, employee_id: id, ...curr },
          ];
        }, []),
      ];
    },
    []
  );
};

export const splitLicensesInDays = ({ records, days }) => {
  const expiringSoon = [];
  const notExpiringSoon = [];
  records.forEach((item) => {
    if (item.expiration_date || item.to_date) {
      if (
        dayjs(item?.expiration_date || item?.to_date).isBefore(
          dayjs().add(days, "day")
        )
      ) {
        expiringSoon.push(item);
      }
      notExpiringSoon.push(item);
    }
  });
  return { expiringSoon, notExpiringSoon };
};

export const getNextExpiry = (licenses = []) => {
  if (!licenses.length) return "N/A";

  if (
    licenses.length === 1 &&
    !licenses[0]["to_date"] &&
    !licenses[0]["expiration_date"]
  ) {
    return "N/A";
  }
  const sorted = licenses.sort((a, b) => {
    if (!b.to_date || !b.expiration_date) return -1;
    else if (!a.to_date || !a.expiration_date) return 1;
    return (
      new Date(a.to_date || !a.expiration_date) -
      new Date(b.to_date || !b.expiration_date)
    );
  });
  return sorted[0].to_date || sorted[0].expiration_date;
};

export const getUniqueValues = ({ array, property }) => {
  return [
    ...new Set(
      array.map((item) => {
        if (property.includes(".")) {
          const properties = property.split(".");
          const objectKey = item[properties[0]];
          return objectKey?.[properties[1]];
        }
        return item[property];
      })
    ),
  ].map((option) => {
    return { name: option || "No Value" };
  });
};
