import React from "react";

export const columns = [
  /*{
    title: "Business Entity",
    field: "bd_business_entity.business_name",
  },*/
  {
    title: "Institution",
    field: "bank_bd_business_entity.business_name",
  },
  {
    field: "account_number",
  },
  {
    field: "account_type",
    format: "startCase",
  },
  {
    title: "Held From",
    field: "from_date",
    format: "date",
  },
  {
    title: "Held To",
    field: "to_date",
    format: "date",
  },
];

export const tableQuestions = (
  <>
    Provide details for all bank, savings, loan association or other financial
    institutions, whether domestic or foreign, in which the Applicant has had an
    account in the past ten (10) year period.
  </>
);

export const defaultInitialValues = {
  //bd_business_entity_id: null,
  bank_bd_business_entity_id: null,
  balance_date: null,
  from_date: null,
  to_date: null,
  is_current: false,
  account_number: "",
  account_type: null,
  bank_account_statement_attachment_group_id: null,
  nature: "",
};

export const endpoint = "bdBankAccount";
export const nonRequired = ["balance_date"];

export const tabularDataParams = {
  defaultInitialValues,
  endpoint,
  nonRequired,
};
