import React, { useState } from "react";
import SQLViewer from "./SQLViewer";
import { usePdfState } from "components/DevTools/PdfTools/Context/PdfToolsProvider.jsx";

const ViewSQLViewer = () => {
  const { selected, sql } = usePdfState();
  const [open, setOpen] = useState(false);
  return (
    <>
      {selected && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => setOpen(!open)}
            className="pointer"
            style={{
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            SQL: {selected} {open ? "" : "(click to open)"}
          </div>
        </div>
      )}
      {sql && open && <SQLViewer query={sql} />}
    </>
  );
};

export default ViewSQLViewer;
