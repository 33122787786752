import { Button, Card } from "@material-ui/core";
import TextField from "components/Common/Inputs/TextField";
import React, { useState } from "react";
import PaginationControl from "../../Components/PaginationControl";
import Column from "components/DevTools/PdfTools/Tools/PageCreation/Components/Column";
import H3 from "components/DevTools/PdfTools/Components/H3.jsx";
import CopyText from "components/DevTools/PdfTools/Components/Navbar/CopyText";

export default function PageCreation() {
  const [json, setJSON] = useState({
    title: "",
    description: "",
    table: "",
    columns: [],
  });
  const [columnRow, setColumnRow] = useState([]);
  const [newPageTag, setNewPageTag] = useState("");
  const [index, setIndex] = useState(0);

  const updateJSON = (e) => {
    json[e.target.name] = e.target.value;
    setJSON(json);
    setNewPageTag(JSON.stringify(json));
  };

  const setState = (crState, jState) => {
    setColumnRow(crState);
    setNewPageTag(JSON.stringify(jState));
    setJSON(jState);
  };

  const columnControl = (e) => {
    if (e.target.innerText === "ADD") {
      json.columns.push({ data: "", header: "" });
      setState(columnRow.concat([Column]), json);
      return null;
    }
    json.columns = json.columns.slice(0, -1);
    setState(columnRow.slice(0, -1), json);
  };

  return (
    <Card style={{ padding: "25px" }}>
      <h2 style={{ padding: "0px 0px 0px 0px" }}>Page Creation</h2>
      <div style={{ width: "75vw" }}>
        <H3>Title:</H3>
        <TextField label="Title" name="title" onChange={updateJSON} />
        <H3>Description:</H3>
        <TextField
          label="Description"
          name="description"
          onChange={updateJSON}
        />
        <H3>Data Table:</H3>
        <TextField
          label="table/view (parmas if required)"
          name="table"
          onChange={updateJSON}
        />
        <H3>Data Column:</H3>
        {
          columnRow.map((row, key) =>
            row({ key, json, setJSON, setNewPageTag })
          )[index]
        }
        <PaginationControl
          data={columnRow}
          paginationSize={1}
          setIndex={setIndex}
        />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            name="add"
            style={{ margin: "10px 10px 0px 0px" }}
            variant="contained"
            onClick={columnControl}
          >
            Add
          </Button>
          {!!columnRow.length && (
            <Button
              name="remove"
              style={{ margin: "10px 10px 0px 0px" }}
              variant="contained"
              onClick={columnControl}
            >
              Remove Last
            </Button>
          )}
        </div>
      </div>
      <H3>New Page TAG/JSON:</H3>
      <CopyText text={newPageTag}>
        <div
          className="pointer"
          style={{
            maxWidth: "80vw",
            wordBreak: "break-all",
            fontSize: "14px",
            fontFamily: "Courier New",
            fontWeight: "Bold",
            padding: "10px 10px 10px 10px",
            backgroundColor: "lightgrey",
            borderRadius: "5px",
          }}
        >
          {newPageTag}
        </div>
      </CopyText>
    </Card>
  );
}
