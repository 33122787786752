import PropTypes from "prop-types";
import CrudSelect, {
  formSchemas,
} from "components/Formik/formGroups/CrudSelect/CrudSelect";
import React from "react";
import useBdIdAddedToAddress from "../hooks/useBdIdAddedToAddress";
import BusinessIcon from "@material-ui/icons/Business";

const BDFinancialSelect = ({
  name = "bank_bd_business_entity_id",
  label = "Financial Institution",
  header = "Financial Institution",
  endpoint = "views/bd_business_entities?is_financial_institution=true",
  formTitle = "Add / Edit Financial Institution",
  formSchema = formSchemas.bdFinancialSchema,
  ...rest
}) => {
  const modifySubmittedValues = useBdIdAddedToAddress();

  return (
    <CrudSelect
      name={name}
      label={label}
      endpoint={endpoint}
      saveEndpoint="bdBusinessEntity"
      formSchema={formSchema}
      formTitle={formTitle}
      overrideDefaultValues={{
        business_type_checkbox: { is_financial_institution: true },
      }}
      optionIcon={<BusinessIcon color="primary" fontSize="small" />}
      modifySubmittedValues={modifySubmittedValues}
      header={header}
      {...rest}
    />
  );
};

BDFinancialSelect.propTypes = {
  endpoint: PropTypes.string,
  formSchema: PropTypes.array,
  formTitle: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  header: PropTypes.string,
};

export default BDFinancialSelect;
