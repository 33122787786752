import React from "react";
import { formats } from "components/Common/Tables/Common/helpers";

export const columns = [
  /*{
    title: "Applicant Business Entity",
    field: "bd_business_entity.business_name",
  },*/
  {
    title: "Owner",
    field: "related_bd_party",
    format: formats.party,
  },
  {
    title: "Ownership",
    field: "ownership_percent",
    format: formats.percent,
  },
  {
    title: "From Date",
    field: "from_date",
    format: "date",
  },
  {
    title: "To Date",
    field: "to_date",
    format: "date",
  },
];

export const tableQuestions = (
  <>
    Provide all persons and/or entities with an ownership interest in the
    Applicant. If the Applicant is a sole proprietorship enter the sole
    proprietor.
  </>
);
export const defaultInitialValues = {
  //bd_business_entity_id: null,
  bd_position_attachment_group_id: null,
  related_bd_party_id: null,
  related_bd_party_role: "owner",
  ownership_percent: null,
  from_date: null,
  to_date: null,
  is_current: false,
  nature_terms_conditions_of_ownership: "",
};
export const endpoint = "bdPosition?related_bd_party_role=owner";
