import { formats } from "components/Common/Tables/Common/helpers";

export const columns = [
  {
    field: "bank_name",
  },
  { field: "from_date", format: formats.date },
  { field: "to_date", format: formats.date },
  { field: "account_number" },
  { field: "current_balance", format: formats.currency },
  {
    title: "Account Holders",
    field: "other_account_holders",
  },
  {
    title: "Country",
    field: "bank_address.country",
  },
];
