import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";

import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm.js";
import { defaultInitialValues, endpoint } from "./config";

import React from "react";
import FormikRadioGroup from "components/Formik/formGroups/FormikRadioGroup";
import BusinessAssociation from "components/Common/BusinessAssociation/BusinessAssociation";

const Form = () => {
  return (
    <EnhancedFormikPage>
      <Grid container spacing={2}>
        <BusinessAssociation
          title="Associated Gaming Entity"
          header={
            <>
              The questions and forms in this section are required by the
              Missouri Gaming Commission to complete the{" "}
              <strong>
                Key Person and Level 1 Application (Personal Disclosure Form 1).
              </strong>
            </>
          }
          label="Company Name (Your Position)"
          name="missouri_kp_level1.associated_business_entity_id"
        />

        <FormikRadioGroup
          header="License Details"
          label="Indicate the type of license for which you are applying for"
          options={[
            { value: "key_person", name: "Key Person" },
            { value: "occupational_license", name: "Occupational License" },
          ]}
          name="missouri_kp_level1.license_type"
          xs={12}
        />
      </Grid>
    </EnhancedFormikPage>
  );
};

const MissouriDisclosureQuestions = ({ name }) => {
  const formData = useForm({
    defaultInitialValues: defaultInitialValues,
    endpoint: endpoint,
    noValidate: true,
  });

  return (
    <FormSectionWrapper title={name}>
      <FormContextProvider formData={formData}>
        <Form />
      </FormContextProvider>
    </FormSectionWrapper>
  );
};

export default MissouriDisclosureQuestions;
