export const tableQuestions = null;

export const defaultInitialValues = {
  bd_pennsylvania_eea: {
    main_company_name: "",
    is_minority_or_women_business: null,
    verified_minority_women_agency: "",
    small_diverse_business_certificate_number: "",
  },
  small_diverse_business_attachment_group_id: null,
  commonwealth_principal_location_attachment_group_id: null,
};

export const endpoint = "bdSupplement";

export const tabularDataParams = {
  defaultInitialValues,
  endpoint,
};
