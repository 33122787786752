import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common/index";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { AuditorsAndAccountantsForm } from "./AuditorsAndAccountantsForm";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";

export default function Index({ name }) {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "bdServiceProvider?related_bd_party_role=accountant",
    nonRequired: ["representative_name", "related_bd_party_id"],
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <AuditorsAndAccountantsForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
    </FormSectionProvider>
  );
}
