// @material-ui/core components
import { useAuth } from "context/auth";
// import { useFormik } from "formik";
import { Form, Formik } from "formik";

import { useParams, useHistory } from "react-router-dom";
import { Button, CircularProgress, FormLabel, Grid } from "@material-ui/core";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormikToggleText from "components/Formik/fields/FormikToggleText";

import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { StyledCardActions, StyledCardContent } from "./styles";
import { usePostNewPassword } from "queryHooks/usePostNewPassword";
import NewPasswordCard from "./NewPasswordCard";
import ExpiredLinkCard from "./ExpiredLinkCard";

import { PasswordValidationDisplay } from "../AcceptInvite/PasswordStep";

export default function SelectNewPassword() {
  const { mutate, status } = usePostNewPassword();
  const [isMFA, setIsMFA] = React.useState(null);
  const [expiredLink, setExpiredLink] = React.useState(null);
  const [hasError, setHasError] = useState(true);
  const history = useHistory();

  const { loading } = useAuth();
  let { resetHash } = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const handleSnackBar = (variant) => (message) => {
    enqueueSnackbar(message, {
      ...variant,
    });
  };

  React.useEffect(() => {
    mutate(
      {
        hash: resetHash,
      },
      {
        onSuccess: ({ MFA }) => {
          setIsMFA(MFA);
          setExpiredLink(false);
        },
        onError: (err) => {
          setIsMFA(false);
          setExpiredLink(true);
          console.log(err);
        },
      }
    );
  }, [mutate, resetHash]);

  if (loading || isMFA === null || expiredLink === null) return null;

  if (expiredLink) {
    return (
      <div style={{ zIndex: "3", margin: "auto", width: "50%" }}>
        <ExpiredLinkCard>
          <StyledCardContent style={{ paddingTop: "20px" }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    history.push("/auth/login_page");
                  }}
                  variant="contained"
                  color="primary"
                >
                  Return to Login
                </Button>
              </Grid>
            </Grid>
          </StyledCardContent>
        </ExpiredLinkCard>
      </div>
    );
  }
  return (
    <Formik
      initialValues={{
        password: "",
        passwordConfirm: "",
        hash: resetHash,
        code: "",
      }}
      onSubmit={({ password, hash, code }, actions) => {
        if (!hasError) {
          mutate(
            {
              code,
              new_password: password,
              hash,
            },
            {
              onSuccess: () => {
                handleSnackBar({ variant: "success", autoHideDuration: 6000 })(
                  "Password Reset Successfully"
                );
                history.push("/auth/login_page");
              },
              onError: (error) => {
                const errMsg = error.response?.data.message;
                let displayError = "";

                if (errMsg === "invalid 2fa code") {
                  displayError = "Invalid 2FA Code";
                } else if (
                  errMsg === "New password can't be same as current password"
                ) {
                  displayError = "Cannot use previously used password";
                } else {
                  displayError = "Error when setting new password";
                }
                handleSnackBar("error")(displayError);
                actions.setSubmitting(false);
              },
            }
          );
        } else {
          actions.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form style={{ zIndex: "3", margin: "auto", width: "50%" }}>
            <NewPasswordCard>
              <StyledCardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FormLabel>
                      Enter a strong password that is at least 8 characters
                      long.
                    </FormLabel>
                  </Grid>

                  <FormikToggleText label="Password" name="password" xs={12} />
                  <FormikToggleText
                    label="Confirm Password"
                    name="passwordConfirm"
                    xs={12}
                  />
                  <Grid item xs={12}>
                    <PasswordValidationDisplay setHasError={setHasError} />
                  </Grid>

                  {isMFA && (
                    <FormikTextField
                      label="MFA Code"
                      type="text"
                      name="code"
                      xs={12}
                    />
                  )}
                </Grid>
                <StyledCardActions style={{ paddingTop: "20px" }}>
                  {status === "loading" ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      type="submit"
                      style={{
                        width: "50%",
                      }}
                      disabled={isSubmitting}
                      variant="contained"
                      color="primary"
                    >
                      Submit
                    </Button>
                  )}
                </StyledCardActions>
              </StyledCardContent>
            </NewPasswordCard>
          </Form>
        );
      }}
    </Formik>
  );
}
