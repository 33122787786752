import { fieldTypes } from "componentGenerators/formGenerator/config";
import { formats } from "components/Common/Tables/Common/helpers";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Referral, Finder's, and Consulting Fees",
  },
  {
    type: fieldTypes.label,
    label:
      "If necessary, please reference the information above to answer the following question:",
  },
  {
    type: fieldTypes.yesNo,
    label: `Has the Applicant ever received remuneration in cash, goods, or services of any kind, 
            directly or indirectly, from any person in connection with any casino or gaming/gambling related operation, 
            including any referral, finder’s or consulting fee?  (Exclude employment)`,
    name: "maryland_pea.exhibit11_question5_boolean",
  },
  {
    type: fieldTypes.pdPersonSelect,
    label: "Name of Person Involved",
    name: "maryland_pea.exhibit11_question5_person_id",
  },
  {
    type: fieldTypes.date,
    name: "maryland_pea.exhibit11_question5_date",
    label: "Date Received",
  },
  {
    type: fieldTypes.number,
    name: "maryland_pea.exhibit11_question5_amount",
    label: "Amount Received",
  },
  {
    type: fieldTypes.text,
    name: "maryland_pea.exhibit11_question5_reason",
    label: "Reason for Renumeration",
    xs: 12,
    rows: 3,
  },
];

const financialTable = {
  type: "readOnlyTable",
  endpoint: "maryland_pea_supplement_referral_fees_referralFee",
  linkTo: ["referral_fees"],
  columns: [
    {
      title: "Date Received",
      field: "date_received",
      format: formats.date,
    },
    {
      title: "Party Names and Addresses",
      field: "party_names_and_addresses",
    },
    {
      title: "Amount",
      field: "amount",
      format: formats.currency,
    },
  ],
  dependsOnQueryCount:
    "During the past ten (10) years, has the applicant received any referral, finder’s, or consulting fee in excess $10,000 USD?",
};

export default [
  financialTable,
  {
    type: "form",
    formSchema,
    endpoint: "maryland_pea_supplement_referral_fees_pdSupplement",
  },
];
