import { Grid } from "@material-ui/core";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents/index.js";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone.jsx";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField.jsx";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import FormikCheckBoxGroup from "components/Formik/formGroups/FormikCheckBoxGroup.jsx";
import BDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/BDPersonSelect";
import YesNo from "components/Formik/formGroups/YesNo.jsx";
import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions.js";
import useEndPoint from "hooks/useEndpoint.js";
import useForm from "hooks/useForm";
import useGet from "hooks/useGet.js";
import React, { useCallback } from "react";
import { defaultInitialValues, endpoint } from "./config.js";

const Form = () => {
  const { paCertificationTypeCheckbox, paCertFormOfBusiness } =
    useMenuOptions();
  return (
    <Grid container spacing={1}>
      <SectionSubHeader label="Type of Certification" />
      <SectionLabel label="Check the appropriate box to indicate the type of certification for which the above-named entity is applying." />
      <FormikCheckBoxGroup
        menuOptions={paCertificationTypeCheckbox}
        name="pa_certification.certification_type_checkbox"
      />
      <SectionSubHeader label="Contact Name for Applicant" />
      <BDPersonSelect
        name="pa_certification.pa_cert_contact_bd_person_id"
        label="Contact Person"
        xs={6}
      />
      <SectionSubHeader
        label="minority or women's business enterprise"
        divider
      />
      <YesNo
        label="Has the applicant been verified as a minority or women's business enterprise by the Pennsylvania Department of General Services' (DGS) Bureau of Small Business Opportunities?"
        name="pa_certification.is_minority_women_business"
        xs={12}
      />
      <SectionLabel label="If yes, provide the name of the entity that certified the applicant as a minority or women's business enterprise and upon" />
      <FormikTextField
        label="Name of the entity"
        name="pa_certification.certified_applicant_minority_women_business"
        xs={6}
      />
      <SectionLabel label="If yes, attach the verification letter from the Bureau of Small Business Opportunities and/or the Small Business Procurement Initiative certificate that identifies the company as a small diverse business (not only as a small business) and provide the certification number" />
      <FormikTextField
        label="certification number"
        name="pa_certification.minority_women_business_certification_number"
        xs={6}
      />
      <FormikFileDropzone
        description="Certificate Minority Or Women's Business"
        name="pa_minority_women_business_cert_attachment_group_id"
        xs={12}
      />
      <SectionSubHeader
        label="Individual who supplied information in the Application"
        divider
      />
      <BDPersonSelect
        name="pa_certification.pa_applications_bd_person_id"
        label="Applications Person"
        xs={6}
      />
      <SectionSubHeader
        label="Applicant's Billing Contact Information"
        divider
      />
      <BDPersonSelect
        name="pa_certification.pa_billing_contact_bd_party_id"
        label="Billing Contact"
        xs={6}
      />
      <SectionSubHeader label="Applicant's Form of Organization" divider />
      <SectionLabel>
        Colorado offers an additional type of business in the form of
        organization section. Please review carefully and select the most
        appropriate answer.
      </SectionLabel>
      <FormikSelect
        label="Form of Business"
        name="pa_certification.pa_form_of_business"
        options={paCertFormOfBusiness}
        xs={6}
      />
      <FormikTextField
        label="Other Description"
        name="pa_certification.form_of_business_other_description"
        helperText="(If Applicable)"
        xs={6}
      />
      <SectionSubHeader label="business background" divider />
      <YesNo
        label="Does your enterprise provide transportation services to any Pennsylvania casino, either directly or indirectly through an arrangement with a transportation company?"
        name="pa_certification.is_transportation_company_arrangement"
        xs={12}
      />
      <SectionLabel label="If yes, provide the name (s) for each enterprise that will provide the transportation service" />
      <FormikTextField
        label="Transportation Company Name(s)"
        name="pa_certification.transportation_company_names"
        helperText="(If Applicable)"
        xs={12}
      />
      <SectionLabel label="Type of goods or services to be provided by gaming service provider" />
      <FormikTextField
        multiline
        rows={4}
        label="Goods Or Services"
        name="pa_certification.goods_services_gaming_service_provider"
        xs={12}
      />
      <SectionLabel label="Name of licensees/applicants to which such goods or services will be provided" />
      <FormikTextField
        label="Gaming Service Provider Name"
        name="pa_certification.gaming_service_provider_name"
        xs={12}
      />
      <YesNo
        label="Does the applicant have any association with any other gaming service providers or gaming service provider applicants who conduct or anticipate conducting business with Pennsylvania licensees/applicants?"
        name="pa_certification.is_associated_gaming_service_provider"
        xs={12}
      />
      <SectionLabel label="If yes, provide the name of the gaming service providers or gaming service provider applicants and explain the association between the companies." />
      <FormikTextField
        label="Gaming Service Providers Name(s)"
        name="pa_certification.associated_gaming_service_providers_names"
        xs={12}
      />
    </Grid>
  );
};

const ApplicantInformation = ({ innerRef }) => {
  const { bdBusinessEntityEndpoint } = useEndPoint("");
  const { data, status } = useGet(bdBusinessEntityEndpoint);

  const getInitialValues = useCallback(() => {
    return status !== "success"
      ? defaultInitialValues
      : {
          ...defaultInitialValues,
          pa_certification: {
            ...defaultInitialValues.pa_certification,
            pa_form_of_business:
              defaultInitialValues.pa_certification.pa_form_of_business ||
              data[0].form_of_business,
          },
        };
  }, [data, status]);

  const formData = useForm({
    defaultInitialValues: getInitialValues(),
    endpoint,
  });

  return (
    <FormContextProvider
      formData={formData}
      showSaveButton={false}
      innerRef={innerRef}
    >
      <EnhancedFormikPage showSaveButton={false} innerRef={innerRef}>
        <Form />
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

export default ApplicantInformation;
