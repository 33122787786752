export const defaultInitialValues = {
  has_entity_government_kickbacks: null,
  has_entity_foreign_bank: null,
  has_entity_undeclared_bank: null,
  has_entity_nominee_bank: null,
  has_entity_political_donation: null,
  has_entity_political_staff_compensation: null,
  has_entity_political_staff_loans: null,
};

export const questions = [
  {
    label:
      "Has made or has been charged with (either itself or through third parties for it), bribes or kickbacks to any government official, domestic or foreign, to obtain favorable treatment or to any company, employee or organization to obtain a favorable advantage?",
    name: "has_entity_government_kickbacks",
  },
  {
    label:
      "Has held a foreign bank account or has had authority to control disbursements from a foreign bank account?",
    name: "has_entity_foreign_bank",
  },
  {
    label:
      "Has maintained a bank account or other account, whether domestic or foreign, which was not reflected on the books or records of the business?",
    name: "has_entity_undeclared_bank",
  },
  {
    label:
      "Has maintained a domestic or foreign-numbered bank account or other bank account in a name other than the name of the business?",
    name: "has_entity_nominee_bank",
  },
  {
    label:
      "Has donated or loaned corporate funds or corporate property for the use or benefit of, or for the purpose of opposing, any government, political party, candidate or committee, either domestic or foreign?",
    name: "has_entity_political_donation",
  },
  {
    label:
      "Has compensated any of its directors, officers or employees for time and expenses incurred in performing services for the benefit of or in opposing any government or political party, either domestic or foreign?",
    name: "has_entity_political_staff_compensation",
  },
  {
    label:
      "Has made any loans, donations or other disbursements to its directors, officers or employees, for the purpose of making political contributions or reimbursing such individuals for political contributions?",
    name: "has_entity_political_staff_loans",
  },
];
