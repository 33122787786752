import React from "react";

export const useMultiSelect = () => {
  const [value, setValue] = React.useState([]);

  const onChange = (e, value) => {
    setValue(value);
  };

  const reset = () => setValue([]);

  return { value, onChange, reset };
};

const getInitialFilters = (filterCount) => {
  return Array(filterCount)
    .fill(null)
    .map(() => []);
};

// same as useMultiSelect but accepts a dynamic number of filters
export const useDynamicMultiSelect = (filterCount) => {
  const initialFilterState = getInitialFilters(filterCount);
  const [filters, setFilters] = React.useState(() => initialFilterState);

  const onChange = (value, indexToChange) => {
    const newFilters = filters.map((filter, idx) => {
      return indexToChange === idx ? value : filter;
    });

    setFilters(newFilters);
  };

  const reset = () => setFilters(initialFilterState);

  React.useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCount]);

  return { filters, onChange, reset };
};
