import PropTypes from "prop-types";
import CrudSelect from "components/Formik/formGroups/CrudSelect/CrudSelect";
import React from "react";
import usePermissionPath from "hooks/usePermissionPath";

const PDEntityCrudSelect = ({
  endpoint,
  saveEndpoint,
  usesPermission,
  ...rest
}) => {
  const permissionedEndpoint = usePermissionPath(endpoint);
  const permissionedSaveEndpoint = usePermissionPath(saveEndpoint);

  return (
    <CrudSelect
      endpoint={usesPermission ? permissionedEndpoint : endpoint}
      saveEndpoint={usesPermission ? permissionedSaveEndpoint : saveEndpoint}
      {...rest}
    />
  );
};

PDEntityCrudSelect.propTypes = {
  endpoint: PropTypes.string,
  saveEndpoint: PropTypes.string,
  usesPermission: PropTypes.bool,
};

export default PDEntityCrudSelect;
