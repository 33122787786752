import React from "react";
import styled from "styled-components";
import { Chip, Box } from "@material-ui/core";

const StyledChip = styled(Chip)`
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`;

const ListOfPositions = ({ list = [] }) => {
  return (
    <Box display="flex" maxWidth="300px" overflow="auto">
      {list.map(({ name }) => (
        <StyledChip key={name} size="small" label={name} />
      ))}
    </Box>
  );
};

function positionList({ lc_position_type }) {
  return <ListOfPositions list={lc_position_type} />;
}

export default positionList;
