import React from "react";
import { address } from "helpers/initialValues";

export const columns = [
  { field: "full_name" },
  { field: "phone_number" },
  {
    title: "Relationship",
    field: "relative_type",
    format: "startCase",
  },
  {
    field: "home_address",
    format: "address",
  },
];

export const tableQuestions = (
  <>
    The following are all people referred to in your personal disclosure
    profile.
  </>
);

export const defaultInitialValues = {
  is_deceased: null,
  is_dependent: null,
  first_name: "",
  middle_name: "",
  suffix_name: "",
  last_name: "",
  maiden_name: "",
  home_address: address,
  relative_type: null,
  date_of_birth: null,
  place_of_birth: "",
  occupation: "",
  phone_number: "",
  mobile_phone: "",
  spouse_pd_person_id: null,
  email: "",
  support_amount: "",
};
