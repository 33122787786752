import { useEffect, useState } from "react";
import { useAuth } from "context/auth";

import { updateModel, getModel, createModel } from "helpers/apiHelpers";
import { generateEndpoint, removeAttributes } from "helpers/routeHelpers";

export default function useComponentData(
  endpoint,
  noId = false,
  onCreateSuccess = () => null
) {
  const auth = useAuth();
  const { employee_id } = auth;
  
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(true);
  const [isCreate, setIsCreate] = useState(true);

  //Driving the logic using employee_id, if we dont have it it must be a business
  let fullEndpoint = !noId
    ? generateEndpoint(endpoint, auth)
    : endpoint;

  //remove everything after the endpoint for patch operations
  let noAttributeEndpoint = removeAttributes(fullEndpoint);
  let cleanEndpoint = removeAttributes(endpoint);

  useEffect(() => {
    function posting() {
      setState([]);
      setIsCreate(true);
    }

    function patching(data) {
      setState(Array.isArray(data) ? data[0] : data); //item can come as array or not..
      setIsCreate(false);
    }

    setLoading(true);

    getModel(fullEndpoint)
      .then((data) => {
        data.length === 0 ? posting() : patching(data);
        setLoading(false);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [employee_id, fullEndpoint]);

  function onUpdate(payload) {
    //special case for bdOrganization - we need to remove id from the string because it is added inside updateModel function
    if (endpoint === "") {
      noAttributeEndpoint = noAttributeEndpoint.substring(
        0,
        noAttributeEndpoint.length - 3
      );
    }
    return updateModel(noAttributeEndpoint, state.id, payload).then(
      ({ data }) => {
        setState(data);
        return data;
      }
    );
  }

  function onCreate(payload) {
    return createModel(noAttributeEndpoint, payload).then(({ data }) => {
      onCreateSuccess();
      setState(data);
      return data;
    });
  }
  return {
    loading,
    isCreate,
    state,
    onUpdate,
    onCreate,
    fullEndpoint,
    noAttributeEndpoint,
    cleanEndpoint,
    employee_id,
    setState,
  };
}
