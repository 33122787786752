import { fieldTypes } from "componentGenerators/formGenerator/config";

export default [
  {
    type: fieldTypes.subHeader,
    label: "License Conditions",
    divider: true,
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Has this license ever been denied, suspended, revoked, withdrawn or subject to any conditions in any jurisdiction?",
    name: "has_condition",
  },
  {
    type: fieldTypes.select,
    label: "Type of Condition",
    name: "condition_type",
    dynamicMenuItems: ["licenseConditionType"],
  },
  {
    type: fieldTypes.date,
    name: "condition_date",
    label: "Condition Date",
  },
  {
    type: fieldTypes.text,
    name: "condition_reason",
    label: "Reason for Condition",
    xs: 12,
  },
];
