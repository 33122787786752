import React from "react";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Personal Details",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please provide all requested information in the{" "}
        <CustomLink to="personal_details" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Social Security Number",
  },
  {
    type: fieldTypes.yesNo,
    label: "Have you ever used any other Social Security numbers? ",
    name: "colorado_gsbsla.has_used_other_ssn",
  },
  {
    type: fieldTypes.label,
    label:
      "If yes, please provide below all details regarding any other Social Security Numbers used:",
  },
  {
    type: fieldTypes.text,
    label: "Explanation",
    name: "colorado_gsbsla.used_other_ssn_details",
    xs: 12,
    rows: 4,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "colorado_gsbsla_supplement_personal_details_pdSupplement",
};
