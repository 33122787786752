export const endpoint = "pdSupplement";

export const defaultInitialValues = {
  colorado: {
    has_delinquent_filling_tax_return: null,
    has_delinquent_payment_taxes: null,
    has_delinquent_payment_obligations: null,
    has_delinquent_repayment_student_loans: null,
    has_bankruptcy_petition_history: null,
    has_related_to_assets: null,
    has_a_complaint: null,
    financial_history_explanation: "",
  },
  colorado_fin_history_attachment_group_id: null,
};

export const financialHistoryQuestions = [
  {
    label:
      "Are you delinquent in the filing of any tax return with any taxing agency anywhere?",
    attribute: "has_delinquent_filling_tax_return",
  },
  {
    label:
      "Are you delinquent in the payment of any taxes, interest, or penalties due to any taxing agency anywhere?",
    attribute: "has_delinquent_payment_taxes",
  },
  {
    label:
      "Are you delinquent in the payment of any obligations to any governmental agency anywhere?",
    attribute: "has_delinquent_payment_obligations",
  },
  {
    label:
      "Are you delinquent in the repayment of any government-insured student loans?",
    attribute: "has_delinquent_repayment_student_loans",
  },
  {
    label:
      "Have you, as an individual, principal of any form of business entity, or as an owner, officer or director of a corporation, ever filed a bankruptcy petition, had such a petition filed against you or the business entity or the corporation; or had a receiver, fiscal agent, trustee, reorganization trustee or similar person appointed for you or the business entity or corporation?",
    attribute: "has_bankruptcy_petition_history",
  },
  {
    label:
      "Do you now own, have ever owned, or otherwise derive a benefit from assets held outside the United States, whether held in your own name or another name, on your behalf or for another person or entity, or through other individuals or business entities, or in trust, or in any other fashion or status?",
    attribute: "has_related_to_assets",
  },
  {
    label:
      "Has a complaint, judgment, consent decree, settlement or other disposition related to a violation of federal, state or similar foreign antitrust, trade or security law or regulation ever been filed or entered against you or a business entity of which you were a principal or against a corporation for which you were an owner, officer or director.",
    attribute: "has_a_complaint",
  },
];
