import React from "react";
import { address } from "helpers/initialValues";
import CustomLink from "components/Common/Link";

export const columns = [
  {
    field: "full_name",
  },
  {
    field: "date_of_birth",
  },
  { field: "home_address", format: "address" },
  { field: "support_amount" },
  { field: "relative_type", format: "startCase" },
];

export const tableQuestions = (
  <>
    <div style={{ paddingLeft: "15px" }}>Provide details of the following:</div>
    <ul>
      <li>All your children, step-children and adopted children</li>
      <li>
        All other persons who you are supporting or contributing to the support
        of
      </li>
    </ul>
    To add a person as a dependent that already exist in your profile visit the
    <CustomLink to="people" label="Person Section" />
    and select Is Dependent.
  </>
);

export const defaultInitialValues = {
  relative_type: "child",
  is_deceased: null,
  is_dependent: null,
  first_name: "",
  middle_name: "",
  last_name: "",
  suffix_name: "",
  occupation: "",
  phone_number: "",
  date_of_birth: null,
  place_of_birth: "",
  support_amount: "",
  home_address: address,
};

export const endpoint = "children_pdPerson";
