import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FromToCurrent from "components/Formik/formGroups/FromToCurrent";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import FormikSelect from "components/Formik/fields/FormikSelect";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { useMenuOptions } from "hooks/useMenuOptions";
import { FormikDatePicker } from "components/Formik/fields";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import FormikCheckBoxGroup from "components/Formik/formGroups/FormikCheckBoxGroup";
import BDPartySelect from "components/Formik/formGroups/CrudSelect/selects/BDPartySelect.jsx";

const columns = [
  {
    title: "Contracting Party",
    field: "related_party_name",
  },
  {
    title: "Type",
    field: "related_bd_party_role",
    format: "startCase",
  },
  {
    title: "Nature of Contract",
    field: "service_rendered",
  },
  {
    title: "Annual Amount",
    field: "contract_amount_annual",
    format: "currency",
  },
  {
    title: "Held From",
    field: "from_date",
    format: "date",
  },
  {
    title: "Held To",
    field: "to_date",
    format: "date",
  },
];

const Components = () => {
  let { bdContractsOtherPartyRole, bdCompensationTypeCheckboxOptions } =
    useMenuOptions();

  bdCompensationTypeCheckboxOptions = bdCompensationTypeCheckboxOptions.filter(
    (item) => item.value === "profit_sharing"
  );

  return (
    <Grid container spacing={2}>
      <BDPartySelect
        name="related_bd_party_id"
        label="Contracting Party"
        header="Contracting Party"
      />
      <FormikSelect
        label="Other Party's Role"
        name="related_bd_party_role"
        options={bdContractsOtherPartyRole}
        xs={4}
      />

      <SectionSubHeader label="Contract Details" divider />
      <FormikTextField
        label="Nature of Contract"
        name="service_rendered"
        xs={4}
      />
      <FormikNumberField
        label="Contract Value"
        name="contract_amount_annual"
        helperText="Annual Contract Value"
        xs={4}
      />
      <FormikTextField label="Contract Number" name="contract_number" xs={4} />
      <FormikTextField
        label="Terms of Compensation"
        name="compensation_terms"
        xs={10}
        md={8}
      />
      <FormikDatePicker
        label="Signed or Agreement Date"
        name="signed_or_agreement_date"
        xs={4}
      />

      <SectionSubHeader label="Form of Compensation" />

      <FormikCheckBoxGroup
        menuOptions={bdCompensationTypeCheckboxOptions}
        name="compensation_type_checkbox"
      />

      <SectionSubHeader label="Dates" divider />
      <FromToCurrent />
      <SectionSubHeader label="Contract Agreement and Associated Documents" />
      <FormikFileDropzone
        label="Contract"
        description="BD Contract"
        name="contract_attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};

const ContractsTable = () => {
  const formData = useTabularData({
    defaultInitialValues: {
      bd_business_entity_id: null,
      related_bd_party_id: null,
      related_bd_party_role: "",
      service_rendered: "",
      contract_amount_annual: null,
      signed_or_agreement_date: null,
      from_date: null,
      to_date: null,
      is_current: false,
      compensation_terms: "",
      contract_number: "",
      contract_attachment_group_id: null,
      compensation_type_checkbox: null,
    },
    endpoint:
      "bdServiceProvider?related_bd_party_role!=knowledge_of_contribution",
    nonRequired: [],
    overwriteMissingRequiredDisplay: { service_rendered: "Nature of Contract" },
  });

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikDialog title="Business Contracts">
        <Components />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormContextProvider>
  );
};

export default ContractsTable;
