import { Chip, Grid } from "@material-ui/core";
import { AsyncSearchDropDown } from "components/Common/AsyncSearchDropdown";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import { formats } from "components/Common/Tables/Common/helpers";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useAuth } from "context/auth";
import { useField, useFormikContext } from "formik";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import useGenerateApplicationDialog from "../../Applications/ManageApplications/hooks/useGenerateApplicationDialog";
import FormikAsyncSelect from "components/Formik/fields/FormikAsyncSelect";
import * as yup from "yup";
import { nullableString } from "../../../../helpers/validationSchemaGenerator";
import _ from "lodash";
import { useRecordFromQueryParam } from "components/Pages/LicensingControl/hooks/useRecordFromQueryParam";

export const validationSchema = yup.object().shape({
  employee_id: nullableString,
  pdf_form_id: nullableString,
});

export const columns = [
  {
    title: "Assignee",
    field: "employee.first_name",
    render: ({ employee }) => `${employee.first_name} ${employee.last_name}`,
  },
  {
    field: "pdf_form.name",
    title: "Application",
  },
  {
    field: "pdf_form.jurisdiction.name",
    title: "Jurisdiction",
  },
  {
    field: "status_code",
    title: "Status",
    format: formats.startCase,
    // eslint-disable-next-line react/display-name
    render: ({ status_code }) => (
      <Chip label={_.startCase(status_code)} size="small" />
    ),
  },
  {
    field: "due_date",
    title: "Due Date",
    format: formats.date,
  },
  {
    title: "Assignor",
    field: "created_by_account.first_name",
    render: ({ created_by_account }) =>
      `${created_by_account.first_name} ${created_by_account.last_name}`,
  },
];

const ApplicationAssignmentsForm = () => {
  const { account_type } = useAuth();

  const [, , helpers] = useField("pdf_form_id");

  const { errors } = useFormikContext();

  const { onJurisdictionChange, jurisdiction, applicationEndpoint } =
    useGenerateApplicationDialog();
  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Application Type" />

      <AsyncSearchDropDown
        disableClearable
        label="1. Select Jurisdiction"
        size={6}
        endpoint="jurisdiction"
        noEmployee
        params={{ is_business: account_type === "business" ? true : null }}
        optionLabel="{name}"
        onChange={onJurisdictionChange}
      />
      <AsyncSearchDropDown
        label="2. Select Application*"
        disableClearable
        size={6}
        endpoint={applicationEndpoint}
        noEmployee
        optionLabel="{name}"
        relatedFieldName="id"
        disabled={jurisdiction ? false : true}
        onChange={(e) => {
          helpers.setValue(e.target.value);
        }}
        textFieldProps={{
          name: "pdf_form_id",
          errors,
        }}
      />

      <SectionSubHeader label="Select Assignee" divider />

      <FormikAsyncSelect
        name="employee_id"
        endpoint="licensing/views/employees" //TODO: change to lc_persons
        label="Employee"
        getOptionLabel={({ first_name, last_name }) =>
          `${first_name} ${last_name}`
        }
        getOptionValue={({ id }) => id}
        xs={6}
      />
      <SectionSubHeader label="Application Due Date" divider />
      <FormikDatePicker label="Due Date" name="due_date" xs={6} />
    </Grid>
  );
};

const ApplicationAssignments = ({ name, location }) => {
  const { account_id } = useAuth();
  const formData = useTabularData({
    defaultInitialValues: {
      pdf_form_id: null,
      due_date: null,
      employee_id: null,
      created_by_account_id: account_id,
      status_code: "to_do",
    },
    endpoint: "licensing/lcEmployeePdfForm",
  });

  const { clearQueryParam } = useRecordFromQueryParam({
    handleEditRecord: formData.editableListActions.handleEditRecord,
    isSuccess: formData.status === "success",
    location,
  });

  const onCancel = () => {
    formData.clearCurrentRecord();
    clearQueryParam();
  };

  return (
    <FormSectionProvider formData={formData} title={name}>
      <EnhancedFormikDialog
        title={name}
        validationSchema={validationSchema}
        onCancel={onCancel}
      >
        <ApplicationAssignmentsForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Assignment" />
    </FormSectionProvider>
  );
};

export default ApplicationAssignments;
