export const defaultInitialValues = {
  elk_valley: {
    marital_changes_last_2y_children: "",
  },
};

export const endpoint = "elk_valley_financial_history_pdSupplement";

export const columns = [
  {
    title: "Ex Name",
    field: "person_name",
  },
  {
    title: "Marriage Date",
    field: "person_date_of_marriage",
    format: "date",
  },
  {
    title: "Divorce Date",
    field: "person_divorce_date",
    format: "date",
  },
  {
    title: "Phone Number",
    field: "person_phone_number",
  },
];
