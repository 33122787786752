import React from "react";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import { generateFullAddress } from "helpers/addressHelpers";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Identification Number",
  },
  {
    type: fieldTypes.text,
    label: "Vendor ID Number",
    name: "new_jersey_vrs.vendor_id_number",
  },
  {
    type: fieldTypes.subHeader,
    label: "Associated Casino Licensee or Applicants",
  },
  {
    type: fieldTypes.label,
    label:
      "Address from which business is conducted with casino licensee or applicants:",
  },
  {
    type: fieldTypes.asyncSelect,
    name: "new_jersey_vrs.associated_business_address_id",
    endpoint: "views/bd_addresses_a",
    label: "Address",
    getOptionLabel: (option) => generateFullAddress(option.address),
    getOptionValue: (option) => option.id,
    xs: 8,
  },
  {
    type: fieldTypes.label,
    label: "Mailing address, if different:",
  },
  {
    type: fieldTypes.asyncSelect,
    name: "new_jersey_vrs.associated_business_mailing_address_id",
    endpoint: "views/bd_addresses_a",
    label: "Address",
    getOptionLabel: (option) => generateFullAddress(option.address),
    getOptionValue: (option) => option.id,
    xs: 8,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please note, if you do not see the desired address showing in the above
        dropdowns, please add the address in the
        <CustomLink to="bd_application_details" label="Business Details" />
        section to see it appear.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Person Supplying the Information or filling this form",
  },
  {
    type: fieldTypes.bdPersonSelect,
    label: "Person",
    name: "new_jersey_vrs.person_filling_form_id",
    xs: 4,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "bdSupplement",
};
