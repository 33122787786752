import { Grid } from "@material-ui/core";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import PDBusinessSelect from "components/Formik/formGroups/CrudSelect/selects/PDBusinessSelect.jsx";
import FromToCurrent from "components/Formik/formGroups/FromToCurrent";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import {
  columns,
  dataHookProps,
  modalTitle,
  tableQuestions,
} from "./config.js";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <FormikTextField
        label="Name of Accountant/CPA"
        name="representative_name"
        xs={4}
      />
      <PDBusinessSelect
        name="related_pd_business_entity_id"
        label="Firm"
        xs={6}
      />
      <FromToCurrent />
    </Grid>
  );
};

const IowaAccountants = ({ name }) => {
  const formData = useTabularData(dataHookProps);

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={modalTitle}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default IowaAccountants;
