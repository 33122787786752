import axios from "axios";
import dayjs from "dayjs";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

export function logout401(error) {
  if (error?.response?.status === 401) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("id");
    localStorage.removeItem("email");
    window.location.reload();
  }
}

/*
 * Getting the list of navbar
 */
export async function getSideNavbar({ account_type = "", employee_id }) {
  const permission = [];
  if (account_type === "business") {
    permission.push("business");
  }
  try {
    const { data } = await axios.post(`/common/getFormNav`, {
      account_type,
      collaborator_employee_id: employee_id,
      collaborator_permission: permission,
    });
    return data;
  } catch (error) {
    logout401(error);
    return [];
  }
}

export async function getCollabProfile() {
  try {
    return await axios.get(`/account/getCollabProfile`);
  } catch (error) {
    logout401(error);
    return {};
  }
}

/*
 * Getting attachment url
 */
export async function getAllAttachmentsByGroup(
  employee_id,
  attachment_group_id
) {
  try {
    return await axios.get(
      `/employee/${employee_id}/attachmentGroup/${attachment_group_id}`
    );
  } catch (error) {
    logout401(error);
    return [];
  }
}

export async function getAllAttachmentsByEmployeeId(employee_id) {
  try {
    return await axios.get(`/employee/${employee_id}/attachmentGroup`);
  } catch (error) {
    return [];
  }
}

export async function getAttachmentUrl(attachmentUrl) {
  try {
    return await axios.get(attachmentUrl);
  } catch (error) {
    logout401(error);
    return [];
  }
}

/*
 * create new attachment
 */
export async function createAttachment(employee_id, payload) {
  const data = new FormData();
  data.append("objectName", payload.objectName);
  data.append("objectId", payload.objectId);
  data.append("attachment", payload.attachment);
  data.append("employeeId", employee_id);

  try {
    return await axios.post(`/employee/${employee_id}/attachment/`, data);
  } catch (error) {
    logout401(error);
    console.error(error);
  }
}

/*
 * Update attachment
 */
export async function updateAttachment(employee_id, payload, attachment_id) {
  const data = new FormData();
  data.append("objectName", payload.objectName);
  data.append("objectId", payload.objectId);
  data.append("attachment", payload.attachment);
  data.append("employeeId", employee_id);
  data.append("attachmentId", attachment_id);

  try {
    return await axios.post(`/employee/${employee_id}/attachment/`, data);
  } catch (error) {
    logout401(error);
    console.error(error);
  }
}

export async function deleteAttachment(employee_id, attachment_id) {
  try {
    return await axios.delete(
      `/employee/${employee_id}/attachment/${attachment_id}`
    );
  } catch (error) {
    logout401(error);
    console.error(error);
  }
}

export async function createGeneratedPdf(
  model,
  model_id,
  pdfForm_id,
  bd_business_entity_id
) {
  try {
    let endpoint = `/${model}/${model_id}/personal_details_pdfForm/${pdfForm_id}/generatedPdf/`;
    if (model === "bdOrganization" && bd_business_entity_id) {
      endpoint = `/${model}/${model_id}/bd/${bd_business_entity_id}/pdfForm/${pdfForm_id}/generatedPdf/`;
    }
    const { data } = await axios.post(endpoint);
    return data;
  } catch (error) {
    logout401(error);
    console.error(error);
  }
}

export async function updateGeneratedPdf(
  model,
  model_id,
  pdf_form_id,
  generated_pdf_id,
  status
) {
  const controlledPath =
    model === "employee" ? "personal_details_pdfForm" : "pdfForm";
  try {
    const { data } = await axios.patch(
      `/${model}/${model_id}/${controlledPath}/${pdf_form_id}/generatedPdf/${generated_pdf_id}`,
      status
    );
    return data;
  } catch (error) {
    logout401(error);
    return {};
  }
}

export async function getCurrentlyGeneratedPdf(
  model,
  model_id,
  pdf_form_id,
  id
) {
  const controlledPath =
    model === "employee" ? "personal_details_pdfForm" : "pdfForm";
  try {
    const { data } = await axios.get(
      `/${model}/${model_id}/${controlledPath}/${pdf_form_id}/generatedPdf/${id}`
    );
    return data;
  } catch (error) {
    logout401(error);
    console.error(error);
  }
}

export async function getModel(model, params = null) {
  const { data } = await axios
    .get(`${model}`, {
      params,
      cancelToken: source.token,
    })
    .catch(function (thrown) {
      if (axios.isCancel(thrown)) {
        console.log("Request canceled", thrown.message);
      } else {
        // handle error
      }
      logout401(thrown);
    });
  // source.cancel('Operation canceled by the user.');
  return data;
}

export async function createModel(model, data) {
  try {
    return await axios.post(`${model}/`, data);
  } catch (error) {
    logout401(error);
    throw error;
  }
}

export async function updateModel(model, model_id, data) {
  try {
    return await axios.patch(`${model}/${model_id}/`, data);
  } catch (error) {
    logout401(error);
    console.error(error);
    throw error;
  }
}

export async function deleteModel(model, model_id) {
  try {
    return await axios.delete(`${model}/${model_id}`);
  } catch (error) {
    logout401(error);
    console.error(error);
    throw error;
  }
}

export async function deleteWithBody(model, data) {
  try {
    return await axios.delete(`${model}`, {
      data,
    });
  } catch (error) {
    logout401(error);
    console.error(error);
    throw error;
  }
}

// https://onecomply.atlassian.net/browse/OC-3389
// The pages using views on API returns dates as datetime JSON format with UTC tag
// For Ex: 2038-12-31T09:00:00.000Z
// Causes client to converts it to local TZ,
// We are getting rid of UTC TAG if there is
export const dayjsUTC = (date, format = "YYYY-MM-DD") => {
  var tmp = null;

  if (typeof date === "string" && date.charAt(date.length - 1) === "Z") {
    tmp = new Date(date.substring(0, date.length - 1));
  } else {
    tmp = date;
  }

  return dayjs(tmp).format(format);
};
