import React from "react";
import CustomLink from "components/Common/Link";

export const columns = [
  /*{
    title: "Business Entity",
    field: "bd_business_entity.business_name",
  },*/
  {
    title: "Office Holder",
    field: "related_party_name",
  },
  {
    title: "Title/Position",
    field: "position",
  },
  {
    title: "Salary",
    field: "compensation_subsequent_year",
    format: "currency",
  },
  {
    title: "Role",
    field: "related_bd_party_role",
    format: "startCase",
  },

  {
    title: "Held From",
    field: "from_date",
    format: "date",
  },
  {
    title: "Held To",
    field: "to_date",
    format: "date",
  },
];

export const tableQuestions = (
  <>
    <div>
      Provide details of all individuals who currently receive, or who
      reasonably can be expected to receive compensation exceeding $250,000 per
      year
      <ul>
        <li>
          Do not include those listed in the
          <CustomLink to="bd_directors_trustees_officers" />
          section
        </li>
        <li>
          <strong>Compensation includes:</strong> Salary, wages, commissions,
          fees, stock options, bonuses or otherwise.
        </li>
      </ul>
    </div>
  </>
);

export const defaultInitialValues = {
  //bd_business_entity_id: null,
  related_bd_party_id: null,
  related_bd_party_role: "employee",
  position: "",
  participation_description: "",
  from_date: null,
  to_date: null,
  is_current: false,
  compensation_previous_year: null,
  compensation_subsequent_year: null,
  compensation_type_checkbox: {
    salary: false,
    bonus: false,
    profit_sharing: false,
    pension: false,
    retirement: false,
    deferred_compensation: false,
  },
};
