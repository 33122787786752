import React from "react";
import _ from "lodash";

/**
 * created because genericViews control does not have ability to filter on mulitple type values yet,
 * this needs to be build in-order to use the ui_label column built in the view
 */

export const createUILabel = (option) =>
  _.startCase(option.security_type) +
  (option.has_voting_rights ? " - Voting" : " - Non-Voting");

export const selectEndpoint =
  "bdSecurityStructure?security_type=ordinary_share&security_type=preference_share&security_type=bond&security_type=debenture&security_type=convertible_note";

export const columns = [
  /*{
    title: "Entity",
    field: "bd_security_structure.bd_business_entity.business_name",
  },*/
  {
    title: "Shareholder",
    field: "party_name",
  },
  {
    title: "Stock Type",
    field: "bd_security_structure.security_type",
    format: "startCase",
  },
  {
    title: "# Shares Held",
    field: "shares_held_count",
    format: "number",
  },
  {
    title: "Date Acquired",
    field: "acquisition_date",
    format: "date",
  },
  {
    title: "% of Outstanding Stock",
    field: "outstanding_stock_percent",
    format: "percent",
  },
];

export const tableQuestions = (
  <>Provide the following information for the Applicant&lsquo;s shareholders.</>
);

export const defaultInitialValues = {
  bd_party_id: null,
  bd_security_structure_id: null,
  acquisition_date: null,
  shares_held_count: null,
  outstanding_stock_percent: null,
  stock_held_percent: null,
};

export const nonRequired = ["outstanding_stock_percent"];

export const endpoint =
  "bdShareholder?bd_security_structure.security_type=ordinary_share&bd_security_structure.security_type=preference_share&bd_security_structure.security_type=bond&bd_security_structure.security_type=debenture&bd_security_structure.security_type=convertible_note";
