import PropTypes from "prop-types";
import CrudSelect, {
  formSchemas,
} from "components/Formik/formGroups/CrudSelect/CrudSelect";
import React from "react";
import BusinessIcon from "@material-ui/icons/Business";
import useBdIdAddedToAddress from "../hooks/useBdIdAddedToAddress";

const BDBusinessSelect = ({
  name = "bd_business_entity_id",
  label = "Business",
  endpoint = "views/bd_business_entities",
  formTitle = "Add / Edit Business",
  formSchema = formSchemas.bdBusinessSchema,
  ...rest
}) => {
  const modifySubmittedValues = useBdIdAddedToAddress();

  return (
    <CrudSelect
      name={name}
      label={label}
      endpoint={endpoint}
      saveEndpoint="bdBusinessEntity"
      formSchema={formSchema}
      formTitle={formTitle}
      optionIcon={<BusinessIcon color="primary" fontSize="small" />}
      modifySubmittedValues={modifySubmittedValues}
      {...rest}
    />
  );
};

BDBusinessSelect.propTypes = {
  endpoint: PropTypes.string,
  formSchema: PropTypes.array,
  formTitle: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
};

export default BDBusinessSelect;
