import React from "react";

import {
  SectionSubHeader,
  SectionLabel,
} from "components/Common/LayoutComponents/";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm.js";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import { Grid } from "@material-ui/core";
import YesNo from "components/Formik/formGroups/YesNo";
import { defaultInitialValues, endpoint } from "./config";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikNumberField from "components/Formik/fields/FormikNumberField";

const Form = () => {
  return (
    <EnhancedFormikPage>
      <Grid container spacing={2}>
        <SectionSubHeader label="Federal, State And Foreign Tax Information" />
        <SectionLabel
          label="Date & tax year of the last federal U.S. tax return (or foreign equivalent) was filed:"
          divider
        />

        <FormikNumberField
          numberFormatProps={{
            allowNegative: false,
            format: "####",
            placeholder: "YYYY",
          }}
          label="Tax Year"
          name="tennessee.last_federal_tax_return_year"
          xs={4}
        />

        <FormikDatePicker
          label="Date Filed"
          name="tennessee.last_federal_tax_return_date"
          xs={4}
        />

        <YesNo
          label="Have you ever been subject to a federal U.S. income tax audit (or foreign equivalent)?"
          name="tennessee.has_federal_tax_return_audit"
          xs={12}
        />
        <SectionLabel label="If yes, provide the following:" divider />

        <FormikNumberField
          numberFormatProps={{
            allowNegative: false,
            format: "####",
            placeholder: "YYYY",
          }}
          label="Tax Year"
          name="tennessee.federal_tax_return_audit_tax_year"
          xs={4}
        />

        <FormikNumberField
          numberFormatProps={{
            allowNegative: false,
            format: "####",
            placeholder: "YYYY",
          }}
          label="Audit Year"
          name="tennessee.federal_tax_return_audit_year"
          xs={4}
        />

        <FormikTextField
          label="Agency or Entity Conducting Audit"
          name="tennessee.federal_tax_return_audit_agency_name"
          xs={4}
        />

        <FormikTextField
          label="Action Due or Required As a Result of Audit"
          name="tennessee.federal_tax_return_audit_resulting_action"
          xs={6}
        />

        <FormikTextField
          label="Action Due or Required As a Result of Audit"
          name="tennessee.federal_tax_return_audit_resulting_action_status"
          xs={6}
        />

        <YesNo
          label="Have you ever not filed a federal U.S. tax return (or foreign equivalent)?"
          name="tennessee.has_not_filed_federal_tax_return"
          xs={12}
        />

        <SectionLabel label="If yes, provide the following:" divider />

        <FormikTextField
          label="Reason and status of non-filed return"
          name="tennessee.not_filed_federal_tax_return_reason_and_status"
          xs={12}
        />
      </Grid>
    </EnhancedFormikPage>
  );
};

const TennesseeEmployment = ({ title }) => {
  const formData = useForm({
    defaultInitialValues: defaultInitialValues,
    endpoint: endpoint,
  });

  return (
    <FormSectionWrapper title={title}>
      <FormContextProvider formData={formData}>
        <Form />
      </FormContextProvider>
    </FormSectionWrapper>
  );
};

export default TennesseeEmployment;
