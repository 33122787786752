import { useState, useEffect } from "react";
import socketIOClient from "socket.io-client";

const CacheBuster = () => {
  const [response, setResponse] = useState("");

  const refreshCacheAndReload = async () => {
    // Check cache
    const cacheArray = await caches.keys();
    if (cacheArray.length > 0) {
      // Service worker cache should be cleared with caches.delete()
      for await (let name of cacheArray) caches.delete(name);
      // delete browser cache and hard reload
    }
    window.location.reload();
  };

  useEffect(() => {
    let appBaseUrl = window.location.origin;
    if (process.env.NODE_ENV === "development") {
      appBaseUrl = appBaseUrl.replace("3000", "3001");
    }
    const socket = socketIOClient(appBaseUrl);
    socket.on("VersionMonitor", (data) => {
      setResponse(data);
      if (data !== localStorage.appVersion) {
        // Update version
        localStorage.appVersion = data;
        refreshCacheAndReload();
      }
    });
    return () => socket.disconnect();
  }, [response]);

  return null;
};

export default CacheBuster;
