import { Box, InputAdornment, TextField } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useLeftNav from "hooks/useLeftNav";
import useToggle from "hooks/useToggle";
import React from "react";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";

const StyledAutocomplete = styled(Autocomplete)`
  & .MuiAutocomplete-option {
    width: 900px;
    background-color: red;
  }
`;

const SearchBox = styled(Box)`
  width: 250px;
  transition: width 0.3s;
  ${({ $active }) =>
    $active &&
    css`
      width: 300px;
    `}
`;

const StyledTextField = styled(TextField)`
  background-color: ${({ theme }) => theme.palette.grey[50]};
  border-radius: 5px;
`;

const findPaths = (routes) =>
  routes.reduce(
    (acc, { views, path, search_display }) =>
      views
        ? acc.concat(findPaths(views))
        : path
        ? acc.concat({ path, search_display })
        : acc,
    []
  );

const removeBackSlash = (string) => string.replace("/", "");

export const SearchField = () => {
  const history = useHistory();

  const {
    navState: { routes },
  } = useLeftNav();

  const searchPaths = React.useMemo(() => {
    if (routes) return findPaths(routes);
    return [];
  }, [routes]);

  const handleSelect = (e, newValue) => {
    if (newValue?.path) history.push(removeBackSlash(newValue.path));
  };

  const { on, setOn, setOff } = useToggle();

  return (
    <SearchBox p={1} $active={on}>
      <StyledAutocomplete
        clearOnBlur
        blurOnSelect
        autoHighlight
        onBlur={setOff}
        onOpen={setOn}
        options={searchPaths}
        getOptionLabel={(option) => option.search_display}
        onChange={handleSelect}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            placeholder="Search..."
            fullWidth
            variant="outlined"
            margin="dense"
            InputLabelProps={{ ...params.InputLabelProps }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </SearchBox>
  );
};
