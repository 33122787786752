import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
  Divider,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HelpIcon from "@material-ui/icons/HelpOutlineOutlined";
import Person from "@material-ui/icons/Person";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import HelpDrawer from "components/Common/drawers/HelpDrawer/HelpDrawer";
import { useLogout } from "context/auth";
import useToggle from "hooks/useToggle";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import FlexRow from "../../flex/FlexRow";
import SimpleDialog from "components/Common/Dialogs/SimpleDialog";
import Security from "components/Pages/Settings/Security/index.js";

const AccountButton = styled(Box)`
  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[200]};
    cursor: pointer;
    border-radius: 5px;
  }
`;

const StyledAccountbutton = styled(IconButton)`
  &:hover {
    background-color: inherit;
  }
  color: ${({ theme }) => theme.palette.button.second};
`;

const StyledIconbutton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.button.second};
`;

const StyledMenu = styled(Menu)`
  & .MuiMenu-paper {
    border: 1px solid ${({ theme }) => theme.palette.divider};
  }
  & .MuiMenu-list {
    /* padding-top: 0px; */
  }
`;

const Help = () => {
  const { on, toggle, setOff } = useToggle();

  return (
    <Box pr={1}>
      <StyledIconbutton onClick={toggle}>
        <HelpIcon size="small" />
      </StyledIconbutton>
      <HelpDrawer open={on} onClose={setOff} />
    </Box>
  );
};

const MenuItemWithIcon = ({ name, Icon, ...props }) => (
  <MenuItem {...props}>
    <ListItemIcon>
      <Icon fontSize="small" />
    </ListItemIcon>
    <Typography variant="inherit">{name}</Typography>
  </MenuItem>
);

const Profile = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    on: accountOn,
    setOff: accountSetOff,
    setOn: accountSetOn,
  } = useToggle();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = useLogout();

  const email = localStorage.getItem("email");

  return (
    <>
      <SimpleDialog
        onClose={accountSetOff}
        open={accountOn}
        title="Account Details and Actions"
        fullWidth={true}
        maxWidth={"lg"}
      >
        <Security />
      </SimpleDialog>
      <AccountButton
        alignItems="center"
        display="flex"
        pr={1}
        ml={1}
        onClick={handleClick}
      >
        <StyledAccountbutton disableRipple>
          <PersonOutlineOutlinedIcon size="small" />
        </StyledAccountbutton>
        <Typography variant="overline">
          <Box fontSize={11}>{email}</Box>
        </Typography>
      </AccountButton>
      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Box width={200} />

        <MenuItemWithIcon
          onClick={() => {
            handleClose();
            accountSetOn();
          }}
          name="Account"
          Icon={Person}
        />

        <MenuItemWithIcon onClick={logout} name="Logout" Icon={ExitToAppIcon} />
      </StyledMenu>
    </>
  );
};

export default function AppNavbarLinks() {
  return (
    <FlexRow p={1}>
      <Help />
      <Divider orientation="vertical" flexItem />
      <Profile />
    </FlexRow>
  );
}

AppNavbarLinks.propTypes = {
  rtlActive: PropTypes.bool,
};
