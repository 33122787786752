import React from "react";
import { Grid, makeStyles, Box } from "@material-ui/core";
import {
  usePdfState,
  usePdfDispatch,
  ACTIONS,
} from "components/DevTools/PdfTools/Context/PdfToolsProvider.jsx";
import { PDFValidationTools } from "controllers/developerTools.js";

const useStyles = makeStyles(() => ({
  boxStyle: {
    fontWeight: "bold",
    overflowX: "scroll",
    scrollbarHeight: "none",
  },
  "@global": {
    "*::-webkit-scrollbar": {
      height: "0",
      display: "none",
    },
  },
}));

export default function DataTable({ data }) {
  const dispatch = usePdfDispatch();
  const { selected } = usePdfState();
  function changeBackground(e) {
    e.target.style.background === "lightgray"
      ? (e.target.style.background = "white")
      : (e.target.style.background = "LightGray");
    e.target.style.color === "rgb(33, 150, 243)" && e.target.id === "column"
      ? (e.target.style.cursor = "pointer")
      : (e.target.style.color = "default");
  }

  async function fetchViewSQL(view) {
    const res = await PDFValidationTools.getViewSQL({ view });
    dispatch({ type: ACTIONS.SET_STATE, value: res.sql, name: "sql" });
  }

  function openTable(e) {
    if (e.target.id === selected) {
      dispatch({ type: ACTIONS.SET_STATE, value: "", name: "selected" });
      dispatch({ type: ACTIONS.SET_STATE, value: "", name: "column" });
      return;
    }
    dispatch({ type: ACTIONS.SET_STATE, value: "", name: "column" });
    dispatch({ type: ACTIONS.SET_STATE, value: e.target.id, name: "selected" });
    dispatch({
      type: ACTIONS.SET_STATE,
      value: e.target.getAttribute("datatag").split(","),
      name: "combo",
    });
    fetchViewSQL(e.target.id);
  }

  function getColumn(e) {
    dispatch({
      type: ACTIONS.SET_STATE,
      value: e.target.innerText,
      name: "column",
    });
  }

  const classes = useStyles();
  return (
    <Grid container style={{ padding: "0px 25px 0px 25px" }}>
      {data.map((val, i) => (
        <Grid item style={{ margin: "1.5px" }} xs={12} sm={2} key={i}>
          <Box
            className={classes.boxStyle}
            boxShadow={1}
            p={2}
            onMouseOver={changeBackground}
            onMouseOut={changeBackground}
            onClick={openTable}
            datatag={val.columns}
            id={val.table}
            fontSize={14}
          >
            {val.table}
          </Box>
          {selected === val.table ? (
            <>
              {val.columns.sort().map((v) => (
                <Box
                  key={v}
                  pl={1}
                  pt={1}
                  style={{
                    fontWeight: "bold",
                    color: "rgb(33, 150, 243)",
                    borderRadius: "2.5px",
                    paddingBottom: "5px",
                  }}
                  fontSize={16}
                  onClick={getColumn}
                  onMouseOver={changeBackground}
                  onMouseOut={changeBackground}
                  id={"column"}
                >
                  {v}
                </Box>
              ))}
            </>
          ) : (
            <></>
          )}
        </Grid>
      ))}
    </Grid>
  );
}
