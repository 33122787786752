import React from "react";
import CustomLink from "components/Common/Link";

export const columns = [
  {
    title: "Person",
    field: "related_party_name",
  },
];

export const tableQuestions = (
  <>
    List any present or former, directors, officers, employees or third parties
    who would have knowledge or information concerning the contributions and/or
    disbursements listed in
    <CustomLink to="bd_bribes_and_kickbacks" />
    or
    <CustomLink to="bd_political_contributions" />
    during the last (10) year period.
  </>
);

export const defaultInitialValues = {
  //bd_business_entity_id: null,
  related_bd_party_id: null,
  related_bd_party_role: "knowledge_of_contribution",
};
