import React from "react";

export const columns = [
  { title: "Plan Name", field: "plan_name" },
  /*{
    title: "Business Entity",
    field: "bd_business_entity.business_name",
  },*/
  {
    title: "Plan Trustee Name",
    field: "trustee_bd_party",
    format: "party",
  },
];

export const profitSharePlanPersonClassColums = [
  {
    title: "Person Class",
    field: "person_class",
  },
  {
    title: "Person Number",
    field: "person_number",
  },
  {
    title: "Distributed Amount last year",
    field: "distributed_amount_last_year",
  },
];

export const defaultInitialValues = {
  trustee_bd_party_id: null,
  //bd_business_entity_id: null,
  plan_name: "",
  bd_profit_share_plan_person_class: [],
  attachment_group_id: null,
  material_features: "",
  financing_methods: "",
};

export const profitSharePlanPersonClassInitialValues = {
  person_class: "",
  person_number: null,
  distributed_amount_last_year: null,
};

export const modalTitle = "Plan";

export const tableQuestions = (
  <>
    <ul>
      <li>
        Provide the following description of all bonus, profit sharing, pension,
        retirement, deferred compensation
      </li>
      <li>Attach a descriptions of plans.</li>
      <li>
        Additionally, attach copies of applicant&apos;s 5500 form field with the
        IRS for the past five (5) years.
      </li>
    </ul>
  </>
);

export const endpoint = "bdProfitSharePlan";

export const dataHookProps = {
  defaultInitialValues,
  endpoint,
};
