import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.businessAssociation,
    label: "Associated Business Entity",
    name: "pd_shakopee_ira.associated_business_entity_id",
  },
];

export default {
  type: "form",
  formSchema,
  endpoint:
    "pd_shakopee_mdewakanton_sioux_community_ira_supplement_disclosure_questions_pdSupplement",
};
