import { Box, Card, Chip, ListItem } from "@material-ui/core";
import { lighten } from "@material-ui/core/styles";
import VerifiedUserTwoToneIcon from "@material-ui/icons/VerifiedUserTwoTone";
import styled, { css } from "styled-components";

export const StyledListItem = styled(ListItem)`
  ${({ $isError, theme }) =>
    $isError &&
    css`
      background-color: ${lighten(theme.palette.error.main, 0.9)};
    `}
`;

export const VerifiedIcon = styled(VerifiedUserTwoToneIcon)`
  color: ${({ theme }) => theme.palette.success.main};
`;

export const StyledCardContent = styled(Box)`
  overflow: auto;
  height: 100%;
  padding-bottom: 60px;
`;

export const StyledCard = styled(Card)`
  height: 300px;
`;

export const ErrorChip = styled(Chip)`
  background-color: ${({ theme }) => theme.palette.error.light};
  color: ${({ theme }) => theme.palette.error.contrastText};
`;

export const WarningChip = styled(Chip)`
  background-color: ${({ theme }) => theme.palette.warning.light};
  color: ${({ theme }) => theme.palette.warning.contrastText};
`;
