import React from "react";

export default function ProfileInfo({ employee_id, bd_business_entity_id }) {
  return (
    <div>
      <div
        style={{
          marginLeft: "25px",
          padding: "10px",
          border: "1px solid #9a9a9a",
        }}
      >
        <h3>Current Selected Profile</h3>
        {employee_id ? (
          <p>Personal Disclosure ID: {employee_id} </p>
        ) : (
          <p>Business Disclosure ID: {bd_business_entity_id} </p>
        )}
      </div>
    </div>
  );
}
