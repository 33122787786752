import { Box, Paper, TableBody, Typography } from "@material-ui/core";
import DeleteConfirmation from "components/Common/Dialogs/DeleteConfirmation";
import OpenModalButton from "components/Common/OpenModalButton";
import Center from "components/flex/Center";
import React from "react";
import styled from "styled-components";
import useTable from "hooks/useTable";
import { CustomTableHead } from "../Common/components/CustomTableHead";
import { PermissionedRow } from "../Common/components/PermissionedRow";
import { TableContainer } from "../Common/styles";
import PropTypes from "prop-types";
import SentryErrorBoundary from "error/SentryErrorBoundary";
import { uniqueId } from "lodash";
const StyledBox = styled(Box)`
  overflow: auto;
`;

const NoRecordsRow = () => (
  <Center width={1} height="50px">
    <Typography variant="body2" color="textSecondary">
      No Records To Display
    </Typography>
  </Center>
);

/*
 * column sortBy order of precedence:
 * 1. defaultSortBy prop
 * 2. "to_date" (only if "to_date" is a column)
 * 3. first column of table
 */

const getDefaultSortBy = (columns) => {
  if (!columns.length) return;
  if (columns.some((col) => col.field === "to_date")) {
    return "to_date";
  }
  return columns[0].field;
};

const ActionsTable = ({
  records,
  title,
  subheader,
  footer,
  columns,
  handleEditRecord,
  handleDeleteRecord,
  handleAddRecord,
  handleViewRecord,
  addButtonText = "Add Record",
  maxRecordSize = null,
  viewOnly = false,
  requiredValues,
  mutateRequiredValues,
  isDeleting,
  overwriteMissingRequiredDisplay,
  confirmDelete,
  cancelDelete,
  showTableIfEmpty = true,
  getRecordId = null,
  isDense = false,
  defaultSortBy, // optional column name which table will be sorted by when table loads
  defaultSortOrder, // to be used in conjunction with defaultSortBy if the order needs to be reversed. Options are: 1. "asc" 2. "desc"  (default)
}) => {
  const { tableHeadProps, getSortedRecords } = useTable({
    records,
    mutateRequiredValues,
    requiredValues,
    overwriteMissingRequiredDisplay,
    defaultSortBy: defaultSortBy || getDefaultSortBy(columns),
    defaultSortOrder,
  });

  const sortedRecords = getSortedRecords();

  const hasRecords = Boolean(records.length);
  const disableAddButton = maxRecordSize
    ? maxRecordSize && maxRecordSize <= records.length
    : false;

  const AddRecordButton = (props) => (
    <OpenModalButton
      disabled={disableAddButton}
      text={addButtonText}
      onClick={handleAddRecord}
      variant={isDense ? "outlined" : "contained"}
      {...props}
    />
  );

  if (!hasRecords && !showTableIfEmpty)
    return (
      <Box width={1} p={2} display="flex" justifyContent="center">
        <AddRecordButton />
      </Box>
    );
  return (
    <SentryErrorBoundary>
      <Box pb={1}>
        <DeleteConfirmation
          open={isDeleting}
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
        />
        <Paper variant="outlined">
          {title && (
            <Box pt={2} pl={2}>
              <Typography variant="h2">{title}</Typography>
            </Box>
          )}
          {subheader && (
            <Box pt={2} pl={2} pr={2}>
              {subheader}
            </Box>
          )}
          {!isDense && (
            <Box width={1} p={handleAddRecord ? 2 : 1}>
              {handleAddRecord ? <AddRecordButton /> : null}
            </Box>
          )}
          <StyledBox component="div" overflow="hidden">
            <TableContainer size={isDense ? "small" : "medium"}>
              <CustomTableHead
                {...tableHeadProps}
                isDense={isDense}
                columns={columns}
              />
              <TableBody>
                {hasRecords &&
                  sortedRecords.map(
                    ({
                      overwriteMissingRequiredDisplay,
                      missingRequiredItems,
                      ...record
                    }) => {
                      return (
                        <PermissionedRow
                          overwriteMissingRequiredDisplay={
                            overwriteMissingRequiredDisplay
                          }
                          viewOnly={viewOnly}
                          missingRequiredItems={missingRequiredItems}
                          record={record}
                          key={
                            record.created_at || record.id
                              ? `${record.created_at}-${record.id}`
                              : uniqueId()
                          }
                          style={{ borderSpacing: "20px" }}
                          columns={columns}
                          handleEdit={handleEditRecord}
                          handleDelete={handleDeleteRecord}
                          handleView={handleViewRecord}
                          getRecordId={getRecordId}
                        />
                      );
                    }
                  )}
              </TableBody>
            </TableContainer>
            {!hasRecords && <NoRecordsRow />}
            {isDense && (
              <Center width={1} height="50px">
                <AddRecordButton />
              </Center>
            )}
            {footer}
          </StyledBox>
        </Paper>
      </Box>
    </SentryErrorBoundary>
  );
};

export default ActionsTable;

ActionsTable.propTypes = {
  addButtonText: PropTypes.string,
  cancelDelete: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  confirmDelete: PropTypes.func,
  defaultSortBy: PropTypes.string,
  defaultSortOrder: PropTypes.oneOf(["asc", "desc"]),
  footer: PropTypes.node,
  handleAddRecord: PropTypes.func,
  handleDeleteRecord: PropTypes.func,
  handleViewRecord: PropTypes.func,
  handleEditRecord: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  isDeleting: PropTypes.bool,
  isDense: PropTypes.bool,
  maxRecordSize: PropTypes.number,
  overwriteMissingRequiredDisplay: PropTypes.object,
  records: PropTypes.arrayOf(PropTypes.object).isRequired,
  requiredValues: PropTypes.object,
  mutateRequiredValues: PropTypes.func,
  getRecordId: PropTypes.func,
  showTableIfEmpty: PropTypes.bool,
  subheader: PropTypes.node,
  title: PropTypes.string,
  viewOnly: PropTypes.bool,
};
