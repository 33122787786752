import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Tax Filing",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Has applicant filed all required federal, state, and/or local tax returns with the appropriate agencies for itself and all related business entities in which it has a beneficial interest?",
    name: "michigan_supplier_license.has_filed_all_tax_returns",
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label: "If yes, please provide a brief explanation below:",
  },
  {
    type: fieldTypes.text,
    label: "Explanation",
    name: "michigan_supplier_license.filed_all_tax_returns_explanation",
    xs: 12,
    multiline: true,
    rows: 4,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "bdSupplement",
};
