import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.bdPersonSelect,
    label: "Contact Person",
    header: "Contact Person",
    name: "arizona_designee.contact_person_id",
    helperText: "Select your Contact Person",
  },

  {
    type: fieldTypes.bdBusinessSelect,
    label: "Operator Partner",
    header: "Operator Partner",
    name: "arizona_designee.operator_partner_id",
    helperText: "Select your Operator Partner",
  },

  {
    type: fieldTypes.bdPersonSelect,
    label: "Operator Partner Contact Person",
    header: "Operator Partner Contact Person",
    name: "arizona_designee.operator_partner_contact_id",
    helperText: "Select your Operator Partner Contact Person",
  },

  {
    type: fieldTypes.bdPersonSelect,
    header: "Management Service Provider",
    label: "Management Service Provider",
    name: "arizona_designee.management_service_provider_id",
    helperText: "Select your Management Service Provider",
  },

  {
    type: fieldTypes.bdPersonSelect,
    label: "Service Provider Contact Person",
    header: "Service Provider Contact Person",
    name: "arizona_designee.management_service_provider_contact_id",
    helperText: "Select your Management Service Provider Contact Person",
  },

  {
    type: fieldTypes.text,
    label: "Description",
    header: "Physical Facility Description",
    name: "arizona_designee.physical_facility_description",
    helperText:
      "Provide the description of any physical facility operated by the Applicant in Arizona",
    rows: 4,
    xs: 12,
  },
];

export default [
  {
    type: "form",
    formSchema,
    endpoint: "bdSupplement",
  },
];
