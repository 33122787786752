import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import useFetch from "queryHooks/useFetch";
import { Box } from "@material-ui/core";
import DisplayTable from "components/Common/Tables/DisplayTable";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const NJGamingBusinessOwnership = ({ name }) => {
  const { data = [], status } = useFetch(
    "new_jersey_gaming_business_ownership_nj_gaming_license"
  );
  return (
    <FormSectionWrapper title={name}>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={status}>
          <DisplayTable
            records={data}
            linkTo="business_licensing"
            columns={[
              { title: "Business Entity Name", field: "business_name" },
              {
                title: "Application Date",
                field: "application_date",
                format: "date",
              },
              { title: "Agency Name", field: "agency_name" },
              { title: "License name", field: "license_type" },
            ]}
            dependsOnQueryCount="Do you have any ownership interest, financial interest of financial investment in any business entity applying to, or presently licensed by, the New Jersey Casino Control Commission and/or the Division of Gaming Enforcement?"
          />
        </LoadingComponent>
      </Box>
    </FormSectionWrapper>
  );
};

export default NJGamingBusinessOwnership;
