import {
  CircularProgress,
  Grid,
  List,
  ListSubheader,
  Paper,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import { AttachmentLink } from "../components/AttachmentLink";
import { MissingPagesList } from "../hooks/useExtraPageWarning";

export const MissingExtraPageAlert = ({ missingExtraPageMessage }) =>
  missingExtraPageMessage ? (
    <Grid item xs={12}>
      <Alert severity="warning">
        <MissingPagesList missingExtraPageMessage={missingExtraPageMessage} />
      </Alert>
    </Grid>
  ) : null;

export const ExtraPages = ({
  isLoadingExtraAttachments,
  hasExtraAttachments,
  extraAttachments,
  missingExtraPageMessage,
}) => (
  <Grid container spacing={1}>
    <MissingExtraPageAlert missingExtraPageMessage={missingExtraPageMessage} />
    {isLoadingExtraAttachments ? (
      <Grid item xs={12}>
        <CircularProgress />
      </Grid>
    ) : hasExtraAttachments ? (
      <>
        <Grid item xs={12}>
          <Paper variant="outlined">
            <List subheader={<ListSubheader>Extra Attachments</ListSubheader>}>
              {extraAttachments.map(AttachmentLink)}
            </List>
          </Paper>
        </Grid>
      </>
    ) : (
      <>
        <Grid item xs={12}>
          <Typography>No Extra Attachments</Typography>
        </Grid>
      </>
    )}
  </Grid>
);
