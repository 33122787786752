import React from "react";
import { commonStrings } from "../../uiStrings";

export const columns = [
  {
    field: "claim_date",
  },
  {
    field: "nature",
  },
  {
    title: "Carrier Name",
    field: "carrier_pd_business_entity.business_name",
  },
  {
    field: "disposition",
  },
];

export const tableQuestions = (
  <>
    Have {commonStrings.youSpouseChildren} filed any claims in excess of
    $100,000USD under any fire, theft, automobile or insurance policy within the
    past ten (10) year period? {commonStrings.usdCurrencyReporting}
    {commonStrings.provideDetails}
  </>
);

export const defaultInitialValues = {
  carrier_pd_business_entity_id: null,
  relative_pd_person_id: null,
  claim_date: null,
  claim_amount: null,
  nature: "",
  disposition: "",
};
