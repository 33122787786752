import {
  Box,
  Button,
  Card,
  Divider,
  Paper,
  TableContainer,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ConfirmationDialog from "components/Common/Dialogs/ConfirmationDialog";
import React from "react";
import styled from "styled-components";
import { formatCurrency } from "helpers/utils";
import useToggle from "hooks/useToggle";
import EditableTable from "../../../../Common/EditableTable/index";
import useEditableTable from "../../../../Common/EditableTable/useEditableTable";
import {
  ButtonWrapper,
  NoDataButtonWrapper,
  StyledCardContent,
} from "./AnnualIncome.styles";
import { getTotalAmount } from "./helpers";

const StyledTableContainer = styled(TableContainer)`
  max-height: 250;
`;

const CardHeaderArea = ({ total, name, year }) => (
  <Box display="flex" justifyContent="space-between">
    <Typography variant="h3">{`${name} (${year})`}</Typography>
    <Paper variant="outlined" style={{ padding: "0px 10px 10px 10px" }}>
      <Typography variant="caption">Total {name}</Typography>
      <Typography variant="h4">
        <Box fontWeight="fontWeightBold">{total}</Box>
      </Typography>
    </Paper>
  </Box>
);

const DeleteConfirmation = (props) => (
  <ConfirmationDialog
    title="Are you sure you want to delete this record?"
    confirmationButtonProps={{ variant: "contained", color: "error" }}
    dialogProps={{ fullWidth: false }}
    confirmationText="Delete"
    {...props}
  />
);

export const AnnualIncomeCategory = ({
  id,
  setTotals,
  name,
  tableData,
  handleSubmit,
  total,
  year,
  columns,
  newRowInitialData,
}) => {
  const { rows, actions } = useEditableTable({
    tableId: id,
    columns,
    tableData,
    handleSubmit,
    newRowInitialData,
  });

  React.useEffect(() => {
    setTotals((prev) => ({ ...prev, [id]: getTotalAmount(rows) }));
  }, [rows, id, setTotals]);

  const [currentRow, setCurrentRow] = React.useState(null);

  const {
    onSave,
    onCancelAdd,
    onToggleEditMode,
    onDelete,
    onAddNew,
    onChange,
  } = actions;

  const hasData = rows.length > 0;

  const {
    on: isDeleteDialogOpen,
    setOn: openDeleteDialog,
    setOff: closeDeleteDialog,
  } = useToggle();

  const handleDelete = (rowId) => {
    setCurrentRow(rowId);
    openDeleteDialog();
  };

  const onConfirmDelete = () => {
    onDelete(currentRow);
    closeDeleteDialog();
    setCurrentRow(null);
  };

  const displayTotal = formatCurrency(total);

  return (
    <>
      <DeleteConfirmation
        open={isDeleteDialogOpen}
        onCancel={closeDeleteDialog}
        onConfirm={onConfirmDelete}
      />
      <Card>
        <StyledCardContent
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <CardHeaderArea total={displayTotal} name={name} year={year} />
            <Divider style={{ marginTop: 10 }} />
            {hasData && (
              <StyledTableContainer>
                <EditableTable
                  columns={columns}
                  rowsData={rows}
                  isAddingNewRow={false}
                  rows={rows}
                  actions={{
                    onSave,
                    onCancelAdd,
                    onToggleEditMode,
                    handleDelete,
                    onChange,
                  }}
                />
              </StyledTableContainer>
            )}
          </div>
          {hasData ? (
            <div>
              <Divider />
              <ButtonWrapper>
                <Button
                  variant="contained"
                  color="primary"
                  size={"small"}
                  onClick={onAddNew}
                >
                  Add Another Source
                  <AddIcon fontSize="small" style={{ paddingLeft: 5 }} />
                </Button>
              </ButtonWrapper>
            </div>
          ) : (
            <NoDataButtonWrapper>
              <Button
                variant="contained"
                color="primary"
                size={"medium"}
                onClick={onAddNew}
              >
                Add An Income Source
                <AddIcon fontSize="small" style={{ paddingLeft: 5 }} />
              </Button>
            </NoDataButtonWrapper>
          )}
        </StyledCardContent>
      </Card>
    </>
  );
};
