import { Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents/index.js";
import { FormikTextField } from "components/Formik/fields";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useEndPoint from "hooks/useEndpoint";
import useForm from "hooks/useForm";
import React from "react";

const Form = () => {
  return (
    <Grid container spacing={1}>
      <SectionSubHeader label="Type of Certification" />

      <FormikTextField
        label="Federal Employer Identification Number/TIN"
        name="employer_id_number"
        xs={6}
      />
      <FormikTextField
        label="PA Department of Revenue Corporate Box Number"
        name="pa_department_of_revenue_corporate_box_number"
        xs={6}
      />
      <FormikTextField
        label="PA Worker's Compensation Policy Number"
        name="pa_workers_compensation_policy_number"
        xs={6}
      />
      <FormikTextField
        label="PA Unemployment Compensation Account Number"
        name="pa_unemployment_compensation_account_number"
        xs={6}
      />
      <FormikTextField
        label="PA Liquor Control Board License Number"
        name="pa_liquor_control_board_license_number"
        xs={6}
      />
      <FormikTextField
        label="PA Department of State Entity Number"
        name="pa_department_of_state_entity_number"
        xs={6}
      />
    </Grid>
  );
};

const ApplicantIDNumbers = ({ innerRef }) => {
  const { bdBusinessEntityEndpoint } = useEndPoint("");

  const formData = useForm({
    defaultInitialValues: {
      employer_id_number: null,
      pa_department_of_revenue_corporate_box_number: null,
      pa_workers_compensation_policy_number: null,
      pa_unemployment_compensation_account_number: null,
      pa_liquor_control_board_license_number: null,
      pa_department_of_state_entity_number: null,
    },
    endpoint: bdBusinessEntityEndpoint,
  });

  return (
    <FormContextProvider
      formData={formData}
      showSaveButton={false}
      innerRef={innerRef}
    >
      <EnhancedFormikPage showSaveButton={false} innerRef={innerRef}>
        <Form />
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

export default ApplicantIDNumbers;
