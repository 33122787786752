import { fieldTypes } from "componentGenerators/formGenerator/config";
import React from "react";

const date = new Date();
const financialStatementEndpoint =
  "bdCorporateReport?report_type=financial_statement&effective_year__gte=" +
  (date.getFullYear() - 3);

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Additional Questions",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Is the business a prospective business or has it recently begun operations? If so, submit an estimated beginning balance sheet (proforma) and a statement of amount(s) and source(s) or funding for the business and specific documentation to support the declaration",
    name: "colorado_sbba.is_operations_balance_sheet",
    xs: 12,
  },
  {
    type: fieldTypes.dropZone,
    name: "colorado_sbba.co_sbba_operations_balance_sheet_attachment_group_id",
    description: "Colorado SBBA Gaming Operations Balance Sheet",
    label: "Upload Attachment",
    xs: 12,
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Is the business a party to a lease (other than the lease already submitted as part of a retailer application)? If YES, attach copies of all leases to which the business is a party.",
    name: "colorado_sbba.is_party_to_lease",
    xs: 12,
  },
  {
    type: fieldTypes.dropZone,
    name: "colorado_sbba.co_sbba_party_to_lease_attachment_group_id",
    xs: 12,
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Has any interest or share in the profit of limited gaming or hypothecated as security for a debt or deposited as a security for the performance of an act or to secure the performance of a contract?",
    name: "colorado_sbba.is_limited_gaming_profit",
    xs: 12,
  },
  {
    type: fieldTypes.dropZone,
    name: "colorado_sbba.co_sbba_limited_gaming_profit_attachment_group_id",
    xs: 12,
  },
  {
    type: fieldTypes.subHeader,
    label: "Other Entities Receiving Compensation from Gaming Venture",
    divider: true,
  },
  {
    type: fieldTypes.yesNo,
    label: (
      <>
        Are there any other persons, other than those listed in the Ownership
        Structure section, including but not limited to suppliers, lenders and
        landlords, who will receive, directly or indirectly, any compensation or
        rents based upon a percentage or share of gross proceeds or income of
        the gaming venture?
      </>
    ),
    name: "colorado_sbba.is_other_entities_compensation",
  },
  {
    type: fieldTypes.label,
    label:
      "If YES, attach list of persons and submit Key & Associate Person License Application forms for each person.",
  },
  {
    type: fieldTypes.dropZone,
    name: "colorado_sbba.co_sbba_other_entities_compensation_attachment_group_id",
    description:
      "List of persons who need to complete Key & Associate Person License Application",
    label: "Upload Attachment",
    xs: 12,
  },
];

const gamblingVentures = {
  type: "readOnlyTable",
  endpoint: "views/bd_assets?issuing_business_is_gaming=true",
  linkTo: ["bd_assets"],
  dependsOnQueryCount:
    "Has the applicant, the applicant’s parent company or any other intermediary business entity ever held financial interest in a gambling venture, including but not limited to, a sports betting operation a race track, dog track, race horse or dog, lottery, casino, bookmaking operation, internet venture company, card room, bingo parlor or pull tabs, whether or not a license to hold such interest was applied for or received?",
  columns: [
    {
      title: "Issuing Entity",
      field: "issuing_business_name",
      format: "startCase",
    },
    {
      field: "asset_type",
      format: "startCase",
    },
    {
      field: "type_of_stock",
      format: "startCase",
    },
    {
      title: "Held From",
      field: "purchase_date",
      format: "date",
    },
    {
      field: "market_value",
      format: "currency",
    },
  ],
};

const assetsOutsideUS = {
  type: "readOnlyTable",
  endpoint: "views/bd_assets_non_us",
  linkTo: ["bd_assets"],
  columns: [
    {
      title: "Issuing Entity",
      field: "issuing_business_name",
    },
    {
      field: "asset_type",
      format: "startCase",
    },
    {
      field: "type_of_stock",
      format: "startCase",
    },
    {
      title: "Held From",
      field: "purchase_date",
      format: "date",
    },
    {
      field: "market_value",
      format: "currency",
    },
  ],
  dependsOnQueryCount:
    "Does the applicant, the applicant’s parent company or any other intermediary business entity now own. has it ever owned, or does it otherwise derive a benefit fro, assets help outside the United States, whether held in the business’ name or another name, on its behalf or for another entity, or through other business entities, or in trust, or in any other fashion or status?",
};

const financialStatements = {
  type: "readOnlyTable",
  linkTo: ["bd_financial_statements"],
  dependsOnQueryCount:
    "Has the applicant, the applicant’s parent company or any other intermediary business entity completed financial statements, either audited or unaudited, in the past three years",
  endpoint: financialStatementEndpoint,
  columns: [
    {
      title: "Report Type",
      field: "report_type",
      format: "startCase",
    },
    {
      title: "Effective Year",
      field: "effective_year",
    },
    {
      title: "Has Attachments",
      field: "corporate_report_attachment_group_id",
      render: ({ corporate_report_attachment_group_id }) =>
        corporate_report_attachment_group_id ? "Yes" : "No",
    },
  ],
};

export default [
  gamblingVentures,
  assetsOutsideUS,
  financialStatements,
  {
    type: "form",
    formSchema,
    endpoint: "bdSupplement",
  },
];
