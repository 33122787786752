import { getFormComponents } from "componentGenerators/helpers";
import bd_ohio_rvla_supplement_application_information from "components/Pages/Supplemental/BD/OhioRVLA/OhioRVLAApplicationInformation/OhioRVLAApplicationInformation";
import bd_ohio_rvla_supplement_financial_insitutions from "components/Pages/Supplemental/BD/OhioRVLA/OhioRVLAFinanacialInstitutions/OhioRVLAFinanacialInstitutions";
import bd_ohio_rvla_supplement_business_description from "components/Pages/Supplemental/BD/OhioRVLA/OhioRVLABusinessDescription/OhioRVLABusinessDescription";

export default getFormComponents({
  bd_ohio_rvla_supplement_application_information,
  bd_ohio_rvla_supplement_financial_insitutions,
  bd_ohio_rvla_supplement_business_description,
});
