import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Fade,
  Typography,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import useDelete from "queryHooks/useDelete";
import useFetch from "queryHooks/useFetch";
import React from "react";
import { ApplicationsList } from "./components/ApplicationsList";
import { queryKey } from "./config";
import { GenerateApplicationDialog } from "./components/GenerateApplicationDialog";
import { SelectedApplication } from "./components/SelectedApplication";
import useGenerateApplicationDialog from "./hooks/useGenerateApplicationDialog";
import { ApplicationListGrid } from "./ManageApplications.styles";
import useToggle from "hooks/useToggle";
import DeleteConfirmation from "components/Common/Dialogs/DeleteConfirmation";
import Center from "components/flex/Center";
import useEndPoint from "hooks/useEndpoint";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";
import { useAuth } from "context/auth";

const GenerateApplicationButton = (props) => (
  <Button
    size="large"
    variant="contained"
    color="primary"
    startIcon={<AddCircleOutlineIcon />}
    {...props}
  >
    Generate a new Application
  </Button>
);

const NoApplicationsDisplay = ({ setOn }) => (
  <Grid
    item
    container
    spacing={5}
    direction="column"
    alignItems="center"
    justify="center"
    style={{ minHeight: "70vh" }}
  >
    <Grid item xs={12}>
      <GenerateApplicationButton onClick={setOn} />
    </Grid>
  </Grid>
);

const useFadeIn = ({ timeout = 200 } = {}) => {
  const [fadeIn, setFadeIn] = React.useState(true);

  const handleFadeIn = () => {
    setFadeIn(false);
    setTimeout(() => {
      setFadeIn(true);
    }, timeout);
  };

  return { fadeIn, handleFadeIn };
};

const useApplicationsData = ({ onDeleteSuccess }) => {
  const { account_type } = useAuth();
  const controlledPath =
    account_type === "employee"
      ? "personal_details_generatedPdf"
      : "generatedPdf";
  const { data, status } = useFetch(queryKey, controlledPath);

  const sortedApplications = React.useMemo(() => {
    return status === "success"
      ? data
          .slice()
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      : [];
  }, [data, status]);

  const { noAttributeEndpoint } = useEndPoint(controlledPath);

  const { mutate: deleteMutation } = useDelete(queryKey, noAttributeEndpoint, {
    onSuccess: ({ data }) => {
      onDeleteSuccess(data);
    },
  });

  return { data, sortedApplications, status, deleteMutation };
};

const ManageApplications = () => {
  const [selectedApplicationId, setSelectedApplicationId] = React.useState(
    null
  );

  const onDeleteSuccess = (data) => {
    // eslint-disable-next-line no-use-before-define
    const nextApplication = sortedApplications.find(
      (application) => application.id !== data.id
    );
    setSelectedApplicationId(nextApplication ? nextApplication.id : null);
  };

  const {
    data,
    sortedApplications,
    status,
    deleteMutation,
  } = useApplicationsData({
    onDeleteSuccess,
  });

  const { fadeIn, handleFadeIn } = useFadeIn();

  const generateApplicationDialogProps = useGenerateApplicationDialog(
    setSelectedApplicationId
  );
  const { setOn } = generateApplicationDialogProps;

  const {
    on: isDeleteDialogOpen,
    setOn: openDeleteDialog,
    setOff: closeDeleteDialog,
  } = useToggle();

  const hasInitiallyMounted = React.useRef(false);

  React.useEffect(() => {
    if (sortedApplications?.length && !hasInitiallyMounted.current) {
      hasInitiallyMounted.current = true;
      setSelectedApplicationId(sortedApplications[0].id);
    }
  }, [sortedApplications]);

  const selectedApplication = React.useMemo(() => {
    if (selectedApplicationId) {
      return data.find(
        (application) => application.id === selectedApplicationId
      );
    }
  }, [selectedApplicationId, data]);

  const onDeleteApplication = () => {
    openDeleteDialog();
  };

  const onConfirmDelete = () => {
    deleteMutation({ id: selectedApplication.id });
    closeDeleteDialog();
  };

  return (
    <>
      <DeleteConfirmation
        open={isDeleteDialogOpen}
        onCancel={closeDeleteDialog}
        onConfirm={onConfirmDelete}
      />
      <GenerateApplicationDialog {...generateApplicationDialogProps} />
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h1">Manage Applications</Typography>
          </Grid>

          <LoadingComponent status={status}>
            {sortedApplications?.length ? (
              <>
                <ApplicationListGrid item sm={12} lg={4}>
                  <GenerateApplicationButton
                    style={{ marginBottom: "15px" }}
                    onClick={setOn}
                  />
                  <Card style={{ width: "100%" }}>
                    <ApplicationsList
                      selectedId={selectedApplication?.id}
                      applications={sortedApplications}
                      handleSelectApplication={(id) => {
                        handleFadeIn();
                        setSelectedApplicationId(id);
                      }}
                    />
                  </Card>
                </ApplicationListGrid>

                <Grid item sm={12} lg={8}>
                  <Fade in={fadeIn} timeout={{ enter: 200, exit: 150 }}>
                    <Card style={{ height: "774px" }}>
                      <CardContent>
                        {selectedApplication ? (
                          <SelectedApplication
                            key={selectedApplication.id}
                            setSelectedApplicationId={setSelectedApplicationId}
                            selectedApplication={selectedApplication}
                            onDelete={onDeleteApplication}
                          />
                        ) : (
                          <Center>
                            <Typography>Select an Application</Typography>
                          </Center>
                        )}
                      </CardContent>
                    </Card>
                  </Fade>
                </Grid>
              </>
            ) : (
              <NoApplicationsDisplay setOn={setOn} />
            )}
          </LoadingComponent>
        </Grid>
      </Container>
    </>
  );
};

export default ManageApplications;
