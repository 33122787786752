import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { FormikAddressFields } from "components/Formik/formGroups/FormikAddressFields";
import React from "react";
import FormikGoogleLookup from "components/Formik/fields/FormikGoogleLookup";

const AddressLookupAndFields = ({ label, name }) => (
  <>
    {label && <SectionSubHeader divider>{label}</SectionSubHeader>}
    <Grid item xs={12}>
      <FormikGoogleLookup
        fields={{
          address: name,
        }}
      />
    </Grid>
    <FormikAddressFields addressPrefix={name + "."} />
  </>
);

AddressLookupAndFields.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default AddressLookupAndFields;
