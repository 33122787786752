import { Box } from "@material-ui/core";
import DeleteConfirmation from "components/Common/Dialogs/DeleteConfirmation";
import React from "react";
import useTabularData from "hooks/tableHooks/useTabularData";
import { FormikDialog } from "components/Formik/formContainers/FormikDialog";
import ActionsTable from "components/Common/Tables/Common/index";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import { sortByFromDate } from "../helpers";
import { EditNameForm } from "./EditNameForm";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const defaultInitialValues = {
  trade_name: "",
  purpose_of_name: "",
  from_date: null,
  to_date: null,
  is_current: false,
};

const ToDateCell = ({ to_date, is_current }) => {
  if (is_current) return "Current";
  return <>{to_date}</>;
};

const columns = [
  {
    field: "trade_name",
    title: "Name",
  },
  {
    field: "from_date",
    title: "From Date",
    sortType: "date",
  },
  {
    field: "to_date",
    title: "To Date",
    render: ToDateCell,
    sortType: "date",
  },
];

export const PreviousNamesTab = ({ businessEntityId }) => {
  const {
    data,
    status,
    deleteDialogProps,
    formikProps,
    tableProps,
  } = useTabularData({
    defaultInitialValues,
    endpoint: `bdNameHistory`,
    queryKey: ["bdNameHistory", businessEntityId],
    noValidate: true,
  });

  const sortedNames = React.useMemo(() => {
    return status === "success" ? sortByFromDate(data) : [];
  }, [data, status]);

  return (
    <LoadingComponent status={status}>
      <FormikDialog {...formikProps} title="Edit Name">
        <EditNameForm />
      </FormikDialog>

      <DeleteConfirmation {...deleteDialogProps} />
      <Box pb={2}>
        <InfoAlert>
          Provide details of all trading names and trademarks under which this
          business entity has operated within the last 3 years.
        </InfoAlert>
      </Box>
      <ActionsTable
        title="Other Names"
        addButtonText="Add Another Name"
        records={sortedNames}
        columns={columns}
        {...tableProps}
      />
    </LoadingComponent>
  );
};
