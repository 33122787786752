import React from "react";
import { get } from "lodash";
import { isDate } from "../../../../hooks/useTable";

const defaultFilterFn = {
  fn: (items) => {
    return items;
  },
};

const getComparisonValue = (value) => {
  if (isDate(value)) {
    return value ? new Date(value) : null;
  }
  return value;
};

const descendingComparator = (a, b, orderBy) => {
  const aValue = getComparisonValue(get(a, orderBy));
  const bValue = getComparisonValue(get(b, orderBy));

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
};

export default function useFilterTable({ records, handleFilter }) {
  const pages = [5, 10, 25];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [order, setOrder] = React.useState(null);
  const [orderBy, setOrderBy] = React.useState(null);
  const [filterFn, setFilterFn] = React.useState(defaultFilterFn);

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const onChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array
      .map((el, index) => [el, index])
      .sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) =>
    order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);

  const tableHeadProps = {
    orderBy,
    order,
    handleSortRequest,
  };

  const paginationProps = {
    page,
    pages,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
  };

  const getSortedRecords = React.useCallback(
    () => stableSort(filterFn.fn(records), getComparator(order, orderBy)),
    [filterFn, order, orderBy, records]
  );

  const sortedRecords = React.useMemo(() => getSortedRecords(), [
    getSortedRecords,
  ]);

  const applyFilter = () => {
    setFilterFn({
      fn: handleFilter,
    });
  };

  return {
    tableHeadProps,
    paginationProps,
    getSortedRecords,
    applyFilter,
    sortedRecords,
  };
}
