import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

export const MultiSelect = ({
  defaultValue = [],
  label,
  placeholder,
  ...rest
}) => {
  function toSentence(string) {
    return string
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  return (
    <Autocomplete
      multiple
      size="small"
      limitTags={3}
      getOptionLabel={(option) => toSentence(option.name)}
      defaultValue={defaultValue}
      {...rest}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
};
