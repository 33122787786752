import { Grid } from "@material-ui/core";
import EnhancedFileDropzone from "components/Common/FileDropZone/EnhancedFileDropzone.jsx";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import Tooltip from "components/Common/Tooltip";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import PDLawEnforcementSelect from "components/Formik/formGroups/CrudSelect/selects/PDLawEnforcementSelect.jsx";
import YesNo from "components/Formik/formGroups/YesNo.jsx";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import {
  columns,
  dataHookProps,
  modalTitle,
  tableQuestions,
  alertMessage,
} from "./config.js";

const Form = () => {
  const { criminalDispositionType, chargeType } = useMenuOptions();

  return (
    <Grid container spacing={2}>
      <YesNo
        label="Has this record been sealed or expunged?"
        name="is_expunged_or_sealed"
        xs={12}
      />
      <SectionLabel label="If Yes, please provide details below:" />

      <FormikTextField
        header="Expunged Extra Info"
        label="City"
        name="expunged_address.city"
        xs={4}
      />
      <FormikTextField label="State" name="expunged_address.state" xs={4} />
      <FormikTextField label="County" name="expunged_address.county" xs={4} />

      <FormikDatePicker label="Expunged Date" name="expunged_date" xs={4} />

      <FormikTextField
        header="Nature of Charge"
        label="Nature of Charge"
        divider
        name="nature"
        xs={8}
      />

      <FormikDatePicker label="Date of Charge" name="charge_date" xs={3} />

      <FormikTextField
        label="Name of Participant"
        name="participant_name"
        xs={4}
      >
        <Tooltip
          title={
            <>
              Most frequently you should enter your name as the participant. In
              some situations the participant’s name could be different, for
              example: identity theft, clerical errors, name change through
              court action, marriage or divorce.
            </>
          }
        />
      </FormikTextField>
      <FormikTextField label="Your Age at the Time" name="age_at_time" xs={4} />
      <FormikTextField label="Case Number" name="case_number" xs={4} />

      <FormikTextField
        header="Incident Location"
        divider
        label="Incident City"
        name="incident_city"
        xs={4}
      />
      <FormikTextField label="Incident County" name="incident_county" xs={4} />
      <FormikTextField label="Incident State" name="incident_state" xs={4} />

      <SectionSubHeader label="Law Enforcement or Court" divider />
      <PDLawEnforcementSelect endpoint="law_business_entities" />

      <FormikSelect
        label="Disposition"
        name="disposition"
        header="Outcome"
        divider
        options={criminalDispositionType}
        xs={4}
      />
      <FormikDatePicker
        label="Date of Disposition"
        name="disposition_date"
        xs={3}
      />
      <FormikSelect
        label="Charge Type"
        name="charge_type"
        options={chargeType}
        xs={4}
      />
      <FormikTextField label="Sentence" name="sentence" xs={4} />
      <FormikTextField
        label="Length of Incarceration"
        name="incarceration_length"
        xs={4}
      />
      <FormikTextField
        label="Location of Incarceration"
        name="incarceration_location"
        xs={4}
      />
      <SectionSubHeader label="Documentation" divider />
      <SectionLabel label="Attach a narrative of the event (not required by all jurisdictions)" />
      <EnhancedFileDropzone
        description="Arrests & Charges"
        name="attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};

const ArrestsAndCharges = ({ name }) => {
  const formData = useTabularData({
    ...dataHookProps,
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={
        <>
          <InfoAlert>{tableQuestions}</InfoAlert>
          <InfoAlert mt={1}>{alertMessage}</InfoAlert>
        </>
      }
    >
      <EnhancedFormikDialog title={modalTitle}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
    </FormSectionProvider>
  );
};

export default ArrestsAndCharges;
