import { garnishments } from "../../uiStrings";

export const columns = [
  { field: "filing_date", format: "date" },
  { field: "case_number" },
  {
    title: "Court",
    field: "law_enforcement_pd_business_entity.business_name",
  },
  {
    title: "Obligation Holder",
    field: "debt_holder_pd_business_entity.business_name",
  },
  {
    field: "amount",
    format: "currency",
  },
];

export const tableQuestions = garnishments.tableQuestion;
export const endpoint = "garnishments_wageGarnishmentLegalHistory";

export const defaultInitialValues = {
  type: "wage_garnishment",
  filing_date: null,
  case_number: "",
  amount: null,
  law_enforcement_pd_business_entity_id: null,
  debt_holder_pd_business_entity_id: null,
  nature: "",
};
