import PropTypes from "prop-types";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { Field } from "formik";
import React from "react";
import withFieldTools from "hocs/withFieldTools";

export const FormikCheckBoxBase = ({ label, color = "primary", ...props }) => {
  return (
    <FormControlLabel
      control={<Field {...props} type="checkbox" as={Checkbox} color={color} />}
      label={label}
    />
  );
};

FormikCheckBoxBase.propTypes = {
  color: PropTypes.oneOf(["default", "primary", "secondary"]),
  label: PropTypes.string,
};

export default withFieldTools(FormikCheckBoxBase);
