import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import MontanaDisclosureQuestionsForm from "./MontanaDisclosureQuestionsForm";
import MultiFormWrapper from "components/Layout/SectionsLayout/MultiFormWrapper";

const MontanaDisclosureQuestions = ({ name }) => {
  return (
    <FormSectionWrapper title={name}>
      <MultiFormWrapper>
        <MontanaDisclosureQuestionsForm />
      </MultiFormWrapper>
    </FormSectionWrapper>
  );
};

export default MontanaDisclosureQuestions;
