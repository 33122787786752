import PropTypes from "prop-types";
import React from "react";

import GridItem from "components/Common/Containers/Grid/GridItem";
import MaterialDivider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import { Box } from "@material-ui/core";
import Tooltip from "components/Common/Tooltip";

const SubheaderGrid = styled(GridItem)`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SectionLabel = ({
  label,
  children,
  gutterTop,
  tooltip,
  tooltipProps,
}) => {
  return (
    <GridItem xs={12}>
      <Box pt={gutterTop ? 2 : 0}>
        <HeaderWrapper>
          <Typography variant="body2">{label}</Typography>
          {typeof tooltip === "string" ? (
            <Tooltip title={tooltip} {...tooltipProps} />
          ) : (
            tooltip
          )}
          <Typography variant="body2">{children}</Typography>
        </HeaderWrapper>
      </Box>
    </GridItem>
  );
};

SectionLabel.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  gutterTop: PropTypes.bool,
};

SectionLabel.defaultProps = {
  default: "layout",
  nosubmit: "true", // ! delete once all forms converted
};

export const SectionSubHeader = ({
  label,
  header,
  children,
  style = {},
  divider = false,
  tooltip,
  gutterTop,
  ...rest
}) => {
  return (
    <>
      {divider && <Divider style={{ paddingBottom: "12px" }} />}
      <SubheaderGrid xs={12}>
        <Box pt={gutterTop ? 2 : 0}>
          <Typography
            style={{ fontWeight: 600, textTransform: "uppercase", ...style }}
            variant="h5"
            {...rest}
          >
            {label || header}
            {children}
            {tooltip}
          </Typography>
        </Box>
      </SubheaderGrid>
    </>
  );
};

SectionSubHeader.propTypes = {
  children: PropTypes.node,
  divider: PropTypes.bool,
  header: PropTypes.node,
  label: PropTypes.string,
  style: PropTypes.object,
  tooltip: PropTypes.node,
  gutterTop: PropTypes.bool,
};

SectionSubHeader.defaultProps = {
  default: "layout",
  nosubmit: "true", // ! delete once all forms converted
};

export const Divider = ({ style }) => {
  return (
    <GridItem style={style} xs={12}>
      <MaterialDivider />
    </GridItem>
  );
};

Divider.propTypes = {
  style: PropTypes.any,
};
Divider.defaultProps = {
  default: "layout",
  nosubmit: "true", // ! delete once all forms converted
};

Divider.defaultProps = {
  default: "layout",
  nosubmit: "true", // ! delete once all forms converted
};

const StyledTypography = styled(Typography)`
  padding: ${({ theme }) => theme.spacing(2, 0, 2, 2)};
`;

export const Paragraph = ({ children }) => (
  <StyledTypography variant="subtitle2">{children}</StyledTypography>
);
