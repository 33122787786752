import {
  Box,
  Divider,
  Grid,
  Tab,
  Tabs as MuiTabs,
  Typography,
} from "@material-ui/core";
import * as Sentry from "@sentry/react";
import { Notes } from "components/Common/Notes/Notes";
import { useAuth } from "context/auth";
import useTabs from "hooks/useTabs";
import useToggle from "hooks/useToggle";
import useFetch from "queryHooks/useFetch";
import React from "react";
import { queryKey } from "../config";
import useAttachmentData from "../hooks/useAttachmentData";
import { StyledTabPanel } from "../ManageApplications.styles";
import { Attachments } from "../tabs/Attachments";
import { ExtraPages } from "../tabs/ExtraPages";
import { FinalSubmission } from "../tabs/FinalSubmission";
import { Summary } from "../tabs/Summary";
import { ApplicationOverviewArea } from "./ApplicationOverviewArea";
import { PdfViewerDialog } from "./PdfViewerDialog";
import ErrorIcon from "@material-ui/icons/Error";
import { useExtraPageWarning } from "../hooks/useExtraPageWarning";
import styled from "styled-components";

const TabPanel = ({ children, tab, index, ...rest }) => (
  <StyledTabPanel hidden={tab !== index} {...rest}>
    {tab === index && children}
  </StyledTabPanel>
);

const StyledErrorIcon = styled(ErrorIcon)`
  font-size: 16px;
  margin-left: 3px;
  color: ${({ theme }) => theme.palette.error.main};
`;

const StyledBox = styled(Box)`
  color: ${({ theme }) => theme.palette.error.main};
`;

const MissingExtraPageTabTitle = () => (
  <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
    <StyledBox>Extra Pages</StyledBox>
    <StyledErrorIcon />
  </Box>
);

const tabTitles = (missingExtraPageMessage) => [
  ["Summary"],
  ["Attachments"],
  ["Notes"],
  [missingExtraPageMessage ? <MissingExtraPageTabTitle /> : <>Extra Pages</>],
  ["Final Submission"],
];

const Tabs = ({ tab, onChange, missingExtraPageMessage }) => {
  return (
    <MuiTabs
      variant="scrollable"
      value={tab}
      onChange={onChange}
      indicatorColor="primary"
      textColor="primary"
    >
      {tabTitles(missingExtraPageMessage).map((title, index) => (
        <Tab
          style={{ minWidth: "140px" }}
          key={index}
          label={
            <>
              {title.map((titleLine, index) => (
                <Typography key={index} variant="caption">
                  {titleLine}
                </Typography>
              ))}
            </>
          }
        />
      ))}
    </MuiTabs>
  );
};

const useFetchGeneratedPdf = ({ pdf_form_id, id, onSuccess, account_type }) => {
  const controlledPath =
    account_type === "employee" ? "personal_details_pdfForm" : "pdfForm";
  return useFetch(
    ["generatedPdfs", id],
    `${controlledPath}/${pdf_form_id}/generatedPdf/${id}/getPdfUrl`,
    {
      onSuccess,
      enabled: false,
    }
  );
};

const usePollGeneratedApplicationStatus = ({
  initialStatus,
  id,
  handleSuccessOnce,
  account_type,
}) => {
  const [attemptCount, setAttemptCount] = React.useState(0);
  const [hasPdfUrl, setHasPdfUrl] = React.useState(
    initialStatus === "completed"
  );

  // Prevents confetti if this application was previously generated
  const isNewlyGenerated = initialStatus !== "completed";
  const controlledPath =
    account_type === "employee"
      ? "personal_details_generatedPdf"
      : "generatedPdf";

  const { refetch } = useFetch(queryKey, controlledPath, {
    enabled: !hasPdfUrl,
    onSuccess: (data) => {
      const selectedApplication = data.find(
        (application) => application.id === id
      );
      if (selectedApplication?.status === "completed") {
        if (isNewlyGenerated) {
          handleSuccessOnce();
        }
        setHasPdfUrl(true);
        return;
      }
      if (attemptCount < 40) {
        setTimeout(() => {
          setAttemptCount((prev) => prev + 1);
          refetch();
        }, 1000);
      }
    },
  });
  return { hasPdfUrl };
};

export const SelectedApplication = ({ selectedApplication, onDelete }) => {
  const {
    application_status,
    created_at,
    employee_id,
    bd_organization_id,
    pdf_form_id,
    id,
    submitted_documents_attachment_group_id,
    note,
    extra_pages_attachment_group_id,
    missing_extra_page_message,
    pdf_form: {
      name,
      jurisdiction: { name: jurisdictionName },
    },
    status,
  } = selectedApplication;
  const { account_type } = useAuth();

  const rewardRef = React.useRef(null);

  const { setExtraPageAlert } = useExtraPageWarning(missing_extra_page_message);

  const handleSuccessOnce = () => {
    rewardRef.current.rewardMe();
    setExtraPageAlert(true);
  };

  const { hasPdfUrl } = usePollGeneratedApplicationStatus({
    initialStatus: status,
    id,
    handleSuccessOnce,
    account_type,
  });

  const { refetch, isLoading } = useFetchGeneratedPdf({
    pdf_form_id,
    id,
    onSuccess: (data) => {
      window.open(data?.[0].url, "_blank");
    },
    account_type,
  });

  const [tab, handleTabChange] = useTabs();

  const { on, setOn, setOff } = useToggle();

  const {
    attachmentsLoading,
    attachmentData,
    isLoadingExtraAttachments,
    hasAttachments,
    hasExtraAttachments,
    extraAttachments,
    mutateGeneratedPdf,
  } = useAttachmentData({
    extra_pages_attachment_group_id,
    pdfFormId: pdf_form_id,
    generatedPdfId: id,
    submitted_documents_attachment_group_id,
    hasPdfUrl,
  });

  const onDownload = () => {
    refetch();
  };

  const tabPanels = tabTitles(missing_extra_page_message).map(
    (title, index) => (
      <TabPanel tab={tab} index={index} key={title}>
        {
          [
            <Summary
              key={title}
              hasExtraAttachments={hasExtraAttachments}
              handleTabChange={handleTabChange}
              viewGeneratedApplication={setOn}
            />,
            <Attachments
              key={title}
              attachmentData={attachmentData}
              attachmentsLoading={attachmentsLoading}
              hasAttachments={hasAttachments}
            />,
            <Notes
              key={title}
              handleSave={({ note }) => mutateGeneratedPdf({ note: note })}
              note={note}
            />,
            <ExtraPages
              key={title}
              isLoadingExtraAttachments={isLoadingExtraAttachments}
              hasExtraAttachments={hasExtraAttachments}
              extraAttachments={extraAttachments}
              missingExtraPageMessage={missing_extra_page_message}
            />,
            <FinalSubmission
              key={title}
              mutateGeneratedPdf={mutateGeneratedPdf}
              applicationStatus={application_status}
              submitted_documents_attachment_group_id={
                submitted_documents_attachment_group_id
              }
            />,
          ][index]
        }
      </TabPanel>
    )
  );

  return (
    <>
      <PdfViewerDialog
        open={on}
        onClose={setOff}
        selectedApplication={selectedApplication}
        id={employee_id || bd_organization_id}
        account_type={account_type}
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ApplicationOverviewArea
            applicationStatus={application_status}
            jurisdictionName={jurisdictionName}
            createdAt={created_at}
            applicationName={name}
            onDelete={onDelete}
            onView={setOn}
            downloadLoading={isLoading}
            onDownload={onDownload}
            hasPdfUrl={hasPdfUrl}
            rewardRef={rewardRef}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Tabs
            tab={tab}
            onChange={handleTabChange}
            missingExtraPageMessage={missing_extra_page_message}
          />
          <Divider />
          <Sentry.ErrorBoundary>{tabPanels}</Sentry.ErrorBoundary>
        </Grid>
      </Grid>
    </>
  );
};
