import React from "react";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { PDFValidationTools } from "controllers/developerTools.js";
import {
  usePdfState,
  usePdfDispatch,
  ACTIONS,
} from "components/DevTools/PdfTools/Context/PdfToolsProvider.jsx";
import { useAuth } from "context/auth";

const useStyles = makeStyles({
  width: {
    width: "50vw",
  },
});

export default function TagSearchBar() {
  const { employee_id, bd_business_entity_id, bd_organization_id } = useAuth();
  const dispatch = usePdfDispatch();
  const { checkTag, checkTagType } = usePdfState();
  const classes = useStyles();

  async function fetchData() {
    if (checkTag) {
      const response = await PDFValidationTools.tagQuery({
        employee_id,
        bd_organization_id,
        bd_business_entity_id,
        tag: [
          {
            name: checkTag,
            page: 0,
            value: "",
            id: 0,
            type: checkTagType || "text",
          },
        ],
      });
      dispatch({
        type: ACTIONS.FETCH_TAG_DATA,
        value: response,
      });
    }
  }

  return (
    <>
      <div style={{ height: "70px", marginLeft: "25px" }}>
        <Button
          style={{ margin: "0px 8px 8px 8px" }}
          variant="contained"
          onClick={fetchData}
        >
          Test
        </Button>
      </div>
      <TextField
        className={classes.width}
        style={{ margin: "0px 0px 8px 0px", height: "50px" }}
        id="outlined-basic"
        label="Paste Tag To Check..."
        variant="outlined"
        onChange={(e) =>
          dispatch({
            type: ACTIONS.SET_STATE,
            value: e.target.value,
            name: "checkTag",
          })
        }
        onKeyPress={(e) => {
          e.key === "Enter" && fetchData(e);
        }}
        InputProps={{ style: { height: "50px", padding: "0px" } }}
      />
    </>
  );
}
