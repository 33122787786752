import { Box, Button, Typography } from "@material-ui/core";
import { Divider } from "components/Common/LayoutComponents";

import BasicTable from "components/Common/Tables/BasicTable";
import AlertHeader from "components/Layout/Alert/AlertHeader";
import React from "react";
import EditDialog from "components/Layout/Dialog/EditDialog";
import useToggle from "hooks/useToggle";
import Center from "components/flex/Center";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import PropTypes from "prop-types";

const DisplayTable = ({
  linkTo = [],
  requiredValues = [],
  dependsOnQueryCount,
  headerComponent = null,
  records,
  infoAlert,
  ...props
}) => {
  const {
    on: isEditDialogOpen,
    toggle: toggleEditDialog,
    setOff: closeEditDialog,
  } = useToggle();

  const linkToList = [].concat(linkTo);
  const hasLink = linkToList.length > 0;

  return (
    <>
      <EditDialog
        open={isEditDialogOpen}
        onClose={closeEditDialog}
        linkToList={linkToList}
      />
      {dependsOnQueryCount && (
        <Box p={2} pb={hasLink ? 0 : 2}>
          <AlertHeader
            title="Review your information"
            dependsOnQueryCount={dependsOnQueryCount}
            question={records}
            default="layout"
          />
        </Box>
      )}
      {infoAlert && (
        <Box m={2}>
          <InfoAlert>{infoAlert}</InfoAlert>
        </Box>
      )}
      {headerComponent && <Box p={2}>{headerComponent}</Box>}
      {linkToList.length > 0 ? (
        <Box p={2} pt={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={toggleEditDialog}
            size="small"
          >
            View Related Page(s)
          </Button>
        </Box>
      ) : null}
      {records.length ? (
        <BasicTable
          actions={false}
          records={records}
          requiredValues={requiredValues}
          {...props}
        />
      ) : (
        <>
          <Divider />
          <Center p={3}>
            <Typography>No Records to Display</Typography>
          </Center>
        </>
      )}
    </>
  );
};

DisplayTable.propTypes = {
  dependsOnQueryCount: PropTypes.bool,
  headerComponent: PropTypes.node,
  infoAlert: PropTypes.node,
  linkTo: PropTypes.array,
  records: PropTypes.shape({
    length: PropTypes.number,
  }),
  requiredValues: PropTypes.array,
};

export default DisplayTable;
