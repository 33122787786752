export const defaultInitialValues = {
  slot_machine_sale_support_attachment_group_id: null,
  supplier_board_review_attachment_group_id: null,
  intellectual_property_ownership_attachment_group_id: null,
  interactive_gaming_platform_diagram_attachment_group_id: null,
  cyber_security_protocols_attachment_group_id: null,
  interactive_games_proposal_attachment_group_id: null,
  gaming_servers_studio_location_attachment_group_id: null,
  vgt_agreements_attachment_group_id: null,
  vgt_maintenance_operation_plan_attachment_group_id: null,
  vgt_competency_attachment_group_id: null,
  vgt_terminal_operator_licensee_attachment_group_id: null,
  vgt_licensure_held_procurement_agent_attachment_group_id: null,
  vgtm_work_summary_agent_attachment_group_id: null,
  vgtm_maintenance_plan_attachment_group_id: null,
  fco_plan_attachment_group_id: null,
  fco_procedures_plan_attachment_group_id: null,
  fco_agreements_terms_conditions_attachment_group_id: null,
  swo_agreements_terms_conditions_attachment_group_id: null,
  swo_agreements_risk_managment_attachment_group_id: null,
};