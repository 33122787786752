import React from "react";
import Tooltip from "components/Common/Tooltip";

import {
  formGroupTypes,
  fieldTypes,
} from "componentGenerators/formGenerator/config";

const columns = [
  {
    title: "Reference",
    field: "pd_person.full_name",
  },
  {
    title: "Email",
    field: "pd_person.email",
  },
  {
    title: "Occupation",
    field: "pd_person.occupation",
  },
  {
    title: "Phone Number",
    field: "pd_person.phone_number",
  },
  {
    field: "relationship",
  },
];

const infoAlert = (
  <>
    Provide information for four (4) references over the age of 18 who have
    known you for at least five years and can attest to your good character and
    reputation. No person can be a reference who is a member of your family.
    <Tooltip
      title={
        "Members of your family include: Spouse, parents, grandparents, children, grandchildren, siblings, uncles, aunts, nephews, nieces, fathers-­in-­law, mothers-­in-­law, sons-­in-­law, daughters­-in-­law, brothers-­in-­law and sisters­-in-law whether by whole or half blood, by marriage, adoption or natural relationship."
      }
    />
  </>
);

const formSchema = [
  {
    type: fieldTypes.pdPersonSelect,
    label: "Reference",
    name: "pd_person_id",
    required: true,
  },

  {
    formGroup: formGroupTypes.addressLookupAndFields,
  },
  {
    type: fieldTypes.subHeader,
    divider: true,
    label: "Employer",
  },
  {
    type: fieldTypes.pdBusinessSelect,
    label: "Employer",
    required: true,
    name: "employer_pd_business_entity_id",
  },
  {
    type: fieldTypes.subHeader,
    divider: true,
    label: "Other Information",
  },
  {
    type: fieldTypes.text,
    name: "business_phone",
  },
  {
    type: fieldTypes.text,
    name: "relationship",
  },
  {
    type: fieldTypes.text,
    label: "Years Known",
    name: "relationship_duration",
    helperText: "Years you have known the reference",
  },
];

export default [
  {
    type: "table",
    infoAlert,
    columns,
    formSchema,
    tableProps: {
      overwriteMissingRequiredDisplay: {
        employer_pd_business_entity_id: "Employer",
      },
    },
    endpoint: "references_reference",
    nonRequired: [],
    infoAlerts: [
      {
        body: infoAlert,
      },
    ],
  },
];
