import React from "react";

import {
  Divider,
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents/";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { FormikAddressFields } from "components/Formik/formGroups/FormikAddressFields";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormikCheckBoxGroup from "components/Formik/formGroups/FormikCheckBoxGroup";
import { useMenuOptions } from "hooks/useMenuOptions";
import useForm from "hooks/useForm.js";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import FormikGoogleLookup from "components/Formik/fields/FormikGoogleLookup";
import { Grid } from "@material-ui/core";
import FormikRadioGroup from "components/Formik/formGroups/FormikRadioGroup";
import { defaultInitialValues, endpoint } from "./config";
import BusinessAssociation from "components/Common/BusinessAssociation/BusinessAssociation";
import FormikEmailTextField from "components/Formik/fields/FormikEmailTextField";

const Form = () => {
  const { principalRoleOptions } = useMenuOptions();

  return (
    <EnhancedFormikPage>
      <Grid container spacing={2}>
        <BusinessAssociation
          header="Applicant is submitting this application as a Key Executive of:"
          label="Associated Casino (Your Association)"
          name={"new_mexico.associated_business_entity_id"}
          xs={4}
        />
        <FormikEmailTextField
          label="Email"
          name="new_mexico.associated_gaming_entity_email"
          xs={4}
        />
        <FormikTextField
          label="Fax Number"
          name="new_mexico.associated_gaming_entity_fax_number"
          xs={4}
        />

        <FormikRadioGroup
          options={[
            { name: "Principal", value: "principal" },
            { name: "Key Employee", value: "key_employee" },
          ]}
          label="Are you a key employee or principal of the business entity?"
          name="new_mexico.association_with_business_entity"
          xs={12}
        />
        <SectionLabel label="Principal Role" />
        <FormikCheckBoxGroup
          name="new_mexico_principal_role"
          options={principalRoleOptions.filter(
            (option) => option.value !== "procurement_agent" //REMOVE procurement_agent - new mexico does not have that option
          )}
        />
        <Divider />
        <SectionLabel label="Describe the relationship between the applicant and the business entity/licensee named above, including amounts and terms of ownership and control" />

        <FormikTextField
          multiline
          rows={4}
          name="new_mexico.gaming_entity_relationship_description"
          xs={12}
        />

        <SectionLabel label="Trade name(s) and Doing Business As (“DBA”) Names:" />

        <FormikTextField
          label="Trade and DBA"
          name="new_mexico.trade_and_dba_names"
          xs={6}
        />

        <SectionSubHeader
          label="BUSINESS ENTITY ADDRESS IN NEW MEXICO (IF APPLICABLE)"
          divider
        />

        <FormikGoogleLookup
          fields={{
            address: "new_mexico_business_address",
          }}
        />

        <FormikAddressFields addressPrefix="new_mexico_business_address." />

        <SectionSubHeader label="Criminal Investigation" divider />
        <SectionLabel label="Provide all information relating to any issues involving alcohol or controlled substances:" />
        <FormikTextField
          xs={12}
          multiline
          rows={4}
          name="new_mexico.alcohol_and_drugs_related_issues_explanation"
        />

        <SectionLabel label="Describe the nature, type, terms and conditions of all insurance claims relating to the business activities of applicant for the last ten (10) year period:" />
        <FormikTextField
          xs={12}
          multiline
          rows={4}
          name="new_mexico.insurance_claims_explanation"
        />
      </Grid>
    </EnhancedFormikPage>
  );
};

const NewMexicoDisclosureQuestions = ({ title }) => {
  const formData = useForm({
    defaultInitialValues: defaultInitialValues,
    endpoint: endpoint,
  });

  return (
    <FormSectionWrapper title={title}>
      <FormContextProvider formData={formData}>
        <Form />
      </FormContextProvider>
    </FormSectionWrapper>
  );
};

export default NewMexicoDisclosureQuestions;
