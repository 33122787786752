import PropTypes from "prop-types";
import { Box, Divider, Tab, Tabs, Link } from "@material-ui/core";
import React from "react";
import useTabs from "hooks/useTabs";
import { AddressesTab } from "./components/AddessesTab";
import { ApplicantInformation } from "./components/ApplicantInformationTab";
import { PreviousNamesTab } from "./components/PreviousNamesTab";
import { FoundersTab } from "./components/FoundersTab";
import { AuthorizedTab } from "./components/AuthorizedTab";
import styled from "styled-components";
import useInitialValues from "hooks/useInitialValues";
import { InfoAlert } from "../../../../Layout/Alert/InfoAlert";

const StyledLink = styled(Link)`
  &:hover {
    cursor: pointer;
  }
`;

const BoxContainer = styled(Box)`
  width: 100%;
`;

const TabLink = ({ children, ...rest }) => (
  <StyledLink {...rest} color="inherit">
    <b>{children}</b>
  </StyledLink>
);

const TabPanel = ({ children, tab, index }) => (
  <div hidden={tab !== index}>
    <Box>{tab === index && children}</Box>
  </div>
);

const NewEntityAlert = ({ handleTabChange }) => (
  <InfoAlert severity="warning">
    Please make sure to add any{" "}
    <TabLink onClick={() => handleTabChange("_", 1)}>Addresses </TabLink> and/or
    <TabLink onClick={() => handleTabChange("_", 2)}>
      &nbsp;Other Names
    </TabLink>{" "}
    this business entity has used.
  </InfoAlert>
);

const defaultInitialValues = {
  business_name: "",
  business_relationship_type: "",
  form_of_business: null,
  incorporation_state: "",
  incorporation_country: null,
  incorporation_date: null,
  to_date: null,
  is_current: false,
  employer_id_number: "",
  is_ein_applied: false,
  unique_entity_number: "",
  dun_bradstreet_id_number: "",
  business_description: "",
  cessation_reason: "",
  registered_agent_bd_party_id: null,
  is_public_company: null,
  business_summary: "",
  business_type_checkbox: {
    is_gaming: null,
    is_government: null,
    is_alcohol: null,
    is_license_agency: null,
    is_financial_institution: null,
    is_law_enforcement: null,
    is_tribal: null,
  },
};

const EditBusinessEntity = ({
  businessEntityData,
  fetchFn,
  handleSubmit,
  isNewEntity,
}) => {
  const { id } = businessEntityData;

  const initialValues = useInitialValues(
    businessEntityData,
    defaultInitialValues
  );

  const businessExists = Boolean(id);

  const [tab, handleTabChange] = useTabs(0);

  return (
    <BoxContainer>
      {isNewEntity && <NewEntityAlert handleTabChange={handleTabChange} />}
      <Box pb={3}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Business Information" />
          <Tab label="Addresses" disabled={!businessExists} />
          <Tab label="Other Names" disabled={!businessExists} />
          <Tab label="Founders" disabled={!businessExists} />
          <Tab label="Authorized States" disabled={!businessExists} />
        </Tabs>
        <Divider />
      </Box>
      <TabPanel tab={tab} index={0}>
        <ApplicantInformation
          initialValues={initialValues}
          fetchFn={fetchFn}
          handleSubmit={handleSubmit}
        />
      </TabPanel>
      {businessExists && (
        <>
          <TabPanel tab={tab} index={1}>
            <AddressesTab businessEntityId={id} />
          </TabPanel>
          <TabPanel tab={tab} index={2}>
            <PreviousNamesTab businessEntityId={id} />
          </TabPanel>
          <TabPanel tab={tab} index={3}>
            <FoundersTab businessEntityId={id} />
          </TabPanel>
          <TabPanel tab={tab} index={4}>
            <AuthorizedTab />
          </TabPanel>
        </>
      )}
    </BoxContainer>
  );
};

EditBusinessEntity.propTypes = {
  businessEntityData: PropTypes.object,
  fetchFn: PropTypes.func,
  handleSubmit: PropTypes.func,
  isNewEntity: PropTypes.bool,
};

export default EditBusinessEntity;
