import React from "react";

import { SectionLabel } from "components/Common/LayoutComponents/";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormikCheckBoxGroup from "components/Formik/formGroups/FormikCheckBoxGroup";
import { useMenuOptions } from "hooks/useMenuOptions";
import useForm from "hooks/useForm.js";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import { Grid } from "@material-ui/core";
import BusinessAssociation from "components/Common/BusinessAssociation/BusinessAssociation";
import PDBusinessSelect from "components/Formik/formGroups/CrudSelect/selects/PDBusinessSelect";

const Form = () => {
  const {
    njQualificationRolesCheckbox,
    njQualificationInConnectionCheckbox,
  } = useMenuOptions();

  const formData = useForm({
    defaultInitialValues: {
      nj_qualification_in_connection_checkbox: null,
      nj_qualification_roles_checkbox: null,
      nj_qualifier_holding_companies: "",
      new_jersey: {
        qualifier_licensee_id: null,
        associated_casino_pd_business_entity_id: null,
        other_description: "",
      },
    },
    endpoint: "new_jersey_disclosure_questions_pdSupplement",
  });

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage>
        <Grid container spacing={2}>
          <BusinessAssociation
            header="Provide the following information about the casino with which you are seeking to be associated with and your position in it"
            label="Associated Casino (Your Association)"
            name={"new_jersey.associated_casino_pd_business_entity_id"}
          />

          <SectionLabel label="Check all appropriate options indicating the reason for submitting this application" />

          <SectionLabel label="I am applying for qualification in connection with:" />
          <FormikCheckBoxGroup
            name="nj_qualification_in_connection_checkbox"
            options={njQualificationInConnectionCheckbox}
          />

          <SectionLabel label="I am a qualifier because I am a:" />

          <FormikCheckBoxGroup
            name="nj_qualification_roles_checkbox"
            options={njQualificationRolesCheckbox}
          />
          <FormikTextField
            label="Other Description"
            name="new_jersey.other_description"
            helperText="Check other to enable"
            xs={6}
          />
          <SectionLabel label="Name of the casino applicant or licensee of which you are a qualifier" />

          <PDBusinessSelect
            label="Applicant / License Name"
            name={"new_jersey.qualifier_licensee_id"}
          />
          <SectionLabel label="”If applicable, the name of the holding company(ies) of the casino applicant or licensee with which you have any positions" />

          <FormikTextField
            label="Holding Company(ies)"
            name="nj_qualifier_holding_companies"
            xs={12}
          />
        </Grid>
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

const NewJerseyDisclosureQuestions = ({ title }) => {
  return (
    <FormSectionWrapper title={title}>
      <Form />
    </FormSectionWrapper>
  );
};

export default NewJerseyDisclosureQuestions;
