import PersonalInformationIcon from "@material-ui/icons/PersonOutlined";
import TaxIcon from "@material-ui/icons/AssessmentOutlined";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import WorkIcon from "@material-ui/icons/WorkOutlineOutlined";
import AccountBalanceIcon from "@material-ui/icons/AccountBalanceOutlined";
import GavelIcon from "@material-ui/icons/GavelOutlined";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOnOutlined";
import PolicyOutlinedIcon from "@material-ui/icons/PolicyOutlined";
import GroupWorkOutlinedIcon from "@material-ui/icons/GroupWorkOutlined";
import BusinessIcon from "@material-ui/icons/Business";
import PeopleIcon from "@material-ui/icons/People";
import RecentActorsOutlinedIcon from "@material-ui/icons/RecentActorsOutlined";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import SupervisorAccountOutlinedIcon from "@material-ui/icons/SupervisorAccountOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";

const icons = {
  //PD
  license_permits: PersonalInformationIcon,
  personal_information: PersonalInformationIcon,
  relatives: SupervisedUserCircleIcon,
  employment: WorkIcon,
  tax_information: TaxIcon,
  licensing_and_ownership: AccountBalanceIcon,
  criminal_and_investigations: GavelIcon,
  financial_information: PolicyOutlinedIcon,
  net_worth: MonetizationOnIcon,
  businesses_and_people: SupervisorAccountOutlinedIcon,

  //BD
  bd_business_information: BusinessIcon,
  bd_employees_and_officers: PeopleIcon,
  bd_service_providers: RecentActorsOutlinedIcon,
  bd_license_and_permit: AccountBalanceIcon,
  bd_financial: PolicyOutlinedIcon,
  bd_contributions: ReceiptOutlinedIcon,
  bd_legal_history: GavelIcon,
  bd_businesses_and_people: SupervisorAccountOutlinedIcon,

  //LC
  lc_reports: AssessmentOutlinedIcon,
  lc_manage: SupervisorAccountOutlinedIcon,
  lc_upload: BackupOutlinedIcon,

  //ADDITIONAL
  // security: SecurityOutlinedIcon,
  collaborators: GroupWorkOutlinedIcon,

  default: AssignmentOutlinedIcon,
};

export default icons;
