import React from "react";

export const columns = [
  { title: "Creditor", field: "held_by_name" },
  { title: "Debtor", field: "debtor_pd_party_name" },
  { field: "original_loan_amount", format: "currency" },
  { field: "original_loan_date", format: "date" },
  { field: "date_due", format: "date" },
  { field: "current_balance", format: "currency" },
  {
    title: "Current",
    field: "is_current",
    format: "boolean",
    sortType: "boolean",
  },
];

export const tableQuestions = (
  <>
    Enter all loans, notes and other receivables held by you, your spouse,
    domestic partner or dependent children.
  </>
);

export const nonRequired = [
  "relative_pd_person_id",
  "security_of_loan",
  "payment_required",
  "payment_period_type",
];

export const endpoint = "loans_and_receivables_loansAndReceivables";

export const defaultInitialValues = {
  relative_pd_person_id: null,
  debtor_pd_party_id: null,
  interest_rate: null,
  original_loan_amount: null,
  original_loan_date: null,
  date_due: null,
  total_payments: "",
  current_balance: null,
  nature_of_loan: "",
  security_of_loan: "",
  attachment_group_id: null,
  is_current: true,
  payment_required: null,
  payment_period_type: "",
};

export const sortingOptions = {
  defaultSortBy: "current_balance",
  defaultSortOrder: "desc",
};

export const modalTitle = "Receivables";

export const dataHookProps = {
  defaultInitialValues,
  nonRequired,
  endpoint,
};
