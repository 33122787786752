import { Typography } from "@material-ui/core";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import useToggle from "hooks/useToggle";
import { get } from "lodash";

import React from "react";
import * as Yup from "yup";
import InviteUserDialog from "../../Forms/InviteUser/InviteUserDialog";
import { columns } from "./config";
import { EditUserForm } from "./EditUserForm";

const editUserValidation = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().min(8, "Password must be at least 8 characters"),
});

export default function ManageUser() {
  const {
    on: isInviteUserDialogOpen,
    setOn: openInviteUserDialog,
    setOff: closeInviteUserDialog,
  } = useToggle();

  const formData = useTabularData({
    defaultInitialValues: {
      first_name: "",
      last_name: "",
      email: "",
      is_active: null,
      is_internal: null,
      permission: null,
      id: null,
      collaborators: [],
    },
    getEndpoint: "account",
    saveEndpoint: "account",
    queryKey: "account",
    nonRequired: ["invitation_status", "collaborators"],
  });

  const { refetch, currentRecord } = formData;
  const closeRecord = get(formData, "formikProps.onCancel");
  return (
    <>
      <Typography variant="h1" gutterBottom>
        Manage Users
      </Typography>
      <FormContextProvider formData={formData}>
        <EnhancedFormikDialog
          title="Manage User"
          validationSchema={editUserValidation}
        >
          <EditUserForm
            currentRecord={currentRecord}
            closeRecord={closeRecord}
          />
        </EnhancedFormikDialog>
        <EnhancedActionsTable
          addButtonText="Invite User"
          columns={columns}
          handleDeleteRecord={null}
          handleAddRecord={openInviteUserDialog}
        />
      </FormContextProvider>
      <InviteUserDialog
        refetchTableData={refetch}
        open={isInviteUserDialogOpen}
        onCancel={closeInviteUserDialog}
      />
    </>
  );
}
