import { Grid } from "@material-ui/core";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import CustomLink from "components/Common/Link";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import FormikTextField from "components/Formik/fields/FormikTextField";
import React from "react";
import FormikAsyncSelect from "components/Formik/fields/FormikAsyncSelect";

const IndianaDisclosureQuestionsForm = () => {
  return (
    <Grid container spacing={2}>
      <SectionLabel label="Position and associated Gaming Entity for which this application is being submitted." />

      <FormikAsyncSelect
        label="Gaming Entity (Your Association)"
        name="in_pd1.associated_business_entity_id"
        endpoint="indiana_disclosure_questions_associated_business_entities_a_grouped"
        getOptionLabel={(option) =>
          option.business_name + " (" + option.association + " )"
        }
        getOptionValue={({ pd_business_entity_id }) => pd_business_entity_id}
        xs={6}
      />
      <SectionLabel label="Is this position a corporate or property-level position?  From where will this position operate?" />
      <FormikTextField
        label="Position Type and Location"
        name="in_pd1.position_type_and_location"
        xs={8}
      />

      <FormikAsyncSelect
        label="Current Employer (Your Association)"
        name="in_pd1.current_employer_business_entity_id"
        endpoint="indiana_disclosure_questions_associated_business_entities_a_grouped"
        getOptionLabel={(option) =>
          option.business_name + " (" + option.association + " )"
        }
        getOptionValue={({ pd_business_entity_id }) => pd_business_entity_id}
        xs={6}
      />
      <FormikTextField
        multiline
        label="Describe your Job Qualifications"
        name="in_pd1.job_qualifications"
        xs={8}
        rows={5}
      />
      <SectionLabel label="Compensation, including bonus structure (if applicable), that you expect to receive for the position listed in this section." />
      <FormikNumberField
        label="Compensation"
        name="in_pd1.compensation_from_associated_entity"
      />
      <SectionSubHeader label="Job Duties & Job Description" divider />
      <FormikTextField
        multiline
        label="Describe your job duties for the position for which you are applying."
        name="in_pd1.job_description_of_positon"
        xs={8}
        rows={5}
      />
      <SectionLabel label="Attach job description of the position for which you are applying." />
      <FormikFileDropzone
        description="Indiana PD1 Job Description"
        name="in_pd1_job_description_attachment_group"
        xs={12}
      />
      <SectionSubHeader label="Gender Identity" divider />
      <FormikTextField
        label="Gender Identity"
        name="in_pd1.gender_identity"
        xs={4}
      />
      <SectionLabel
        label={
          <>
            If your gender identity is different from what you indicated in the
            <CustomLink to="personal_details" />
            section provide an explanation.
          </>
        }
      />
      <FormikTextField
        multiline
        label="Gender Identity Explanation"
        name="in_pd1.gender_identity_explanation"
        xs={8}
        rows={5}
      />
      <SectionSubHeader label="Vehicle License Suspensions" divider />
      <SectionLabel
        label={
          <>
            If you have ever had your motor vehicle registration certificate,
            chauffeur’s license, driver’s license, driver’s permit or operator’s
            permit revoked or suspended provide a written statement that
            includes:
            <ol type="a">
              <li>Date action was taken</li>
              <li>Name and Address of agency taking action</li>
              <li>Type of license</li>
              <li>Reason for Suspension or Revocation</li>
              <li>Date Restored, if any</li>
            </ol>
          </>
        }
      />
      <FormikTextField
        multiline
        label="Vehicle License Suspension Statement(s)"
        name="in_pd1.vehicle_license_suspension_statement"
        xs={8}
        rows={5}
      />
      <SectionSubHeader label="Gaming Nominee or Interest in Trust" divider />
      <SectionLabel
        label={
          <>
            If you or any member of your family are the Nominee, or hold any
            interest in trust, for any Gaming Entity, provide the following for
            each interest:
            <ol type="a">
              <li>Immediate Family member name and relationship to you</li>
              <li>
                Number of shares or the amount of other interest held by you or
                your Immediate Family member
              </li>
              <li>
                A detailed description of the instrument creating the fiduciary
                obligation
              </li>
              <li>
                A detailed description of the Gaming Entity, and of the interest
                held by your or your Immediate Family member
              </li>
            </ol>
          </>
        }
      />
      <FormikTextField
        multiline
        label="Gaming Nominee or Interest in Trust Details"
        name="in_pd1.gaming_nominee_details"
        xs={8}
        rows={5}
      />
      <SectionSubHeader label="Education Records" divider />
      <SectionLabel
        label={
          <>
            You are required to provide official sealed transcripts for all
            post-secondary education. If you do not have any post-secondary
            education, upload a copy of your high school diploma in the
            <CustomLink to="education" /> section.
          </>
        }
      />
      <SectionSubHeader label="Corporate Bankruptcy" divider />
      <SectionLabel
        label={
          <>
            For any records you provided in the
            <CustomLink to="bankruptcies" />
            section open each record and attach a copy of the bankruptcy
            petition.
          </>
        }
      />
      <SectionSubHeader
        label="Business Entity Payments for Advantage"
        divider
      />
      <SectionLabel label="Provide a complete explanation of the circumstances of any Business Entity that has made (either itself or through third parties acting for it) payments or gratuities to any employee, company, or organization to obtain a competitive advantage, or to any foreign or domestic government official, to obtain favorable treatment at any time during the past fifteen (15) years and while you were either a Substantial Owner or Key Person of the Business Entity." />
      <FormikTextField
        multiline
        label="Explanation of Circumstances"
        name="in_pd1.business_entity_advantage_payment_explanation"
        xs={8}
      />
      <SectionSubHeader label="Total Household Income" divider />
      <SectionLabel label="Your monthly household income after taxes, from all sources, including spousal income." />
      <FormikNumberField
        label="Household Income"
        name="in_pd1.household_income_monthly"
        // parentObject="in_pd1"
      />
      <SectionSubHeader label="Additional Business Addresses" divider />

      <SectionLabel
        label={
          <>
            Add any additional business addresses that are different from the
            business address you disclosed in the
            <CustomLink to="personal_details" />
            Section.
          </>
        }
      />

      <FormikTextField
        multiline
        label="Additional Business Addresses"
        name="in_pd1.additional_business_addresses"
        xs={8}
        rows={5}
      />
      <SectionSubHeader label="Sealed Civil Record" divider />
      <SectionLabel
        label={
          <>
            If you have ever had a civil record sealed by court order provide
            all details.
          </>
        }
      />
      <FormikTextField
        multiline
        label="Sealed Civil Record Details"
        name="in_pd1.sealed_civil_record_details"
        xs={8}
        rows={5}
      />
      <SectionSubHeader label="Foreign (non-US) Police Clearances " divider />
      <SectionLabel label="If your legal residence is outside the United Sates or you reside the majority of the time outside of the United States provide recent national law enforcement/police clearances for those jurisdictions." />
      <FormikFileDropzone
        description="Indiana PD1 Foreign Police Clearance"
        name="in_pd1_police_clearance_attachment_group_id"
        xs={12}
      />
      <SectionSubHeader label="Child Support Court Order" divider />
      <SectionLabel
        label={
          <>
            If you, at any time, have been court-ordered or agreed to pay child
            support or alimony, include the following for each order:
            <ol type="a">
              <li>Name of other parties involved</li>
              <li>Name and location of issuing court</li>
              <li>Date order was issued (monty, day, year)</li>
              <li>Schedule of payments and amount to be paid</li>
              <li>
                The date of each instance when you were more then thirty (30)
                days late with the payment
              </li>
              <li>
                Whether you are current on your payments or the order has been
                satisfied
              </li>
            </ol>
            Upload a copy of each court order in the
            <CustomLink to="children" /> section.
          </>
        }
      />
      <FormikTextField
        multiline
        label="Child Support Court Order Details"
        name="in_pd1.child_support_court_order_details"
        xs={8}
        rows={5}
      />
      <SectionSubHeader label="Non-Retirement Investments" divider />
      <SectionLabel
        label={
          <>
            Indiana requires you to list all accounts held by you, your spouse,
            or your dependent children.
            <CustomLink to="margin_accounts" />
            not already listed in the Margin (Brokerage) Account Section.
          </>
        }
      />
      <SectionSubHeader label="Tax Audit Information" divider />
      <SectionLabel
        label={
          <>
            In the last ten (10) years, if you ever had any municipal, state, or
            federal tax returns audited or adjusted submit a description of the
            facts, circumstances, and results of each audit or adjustment.
          </>
        }
      />
      <FormikTextField
        multiline
        label="Tax Audit Description"
        name="in_pd1.tax_audit_description"
        xs={8}
        rows={5}
      />
    </Grid>
  );
};

export default IndianaDisclosureQuestionsForm;
