import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    header: "Point of Contact",
    type: fieldTypes.bdPersonSelect,
    label: "Contact Person",
    name: "cherokee_vendor_license.tax_prep_person_id",
    helperText:
      "Select the Individual responsible for preparing Tax Returns, Government forms, and reports for the business",
  },
  {
    type: fieldTypes.gridItem,
  },

  {
    type: fieldTypes.bdPersonSelect,
    label: "Contact Person",
    name: "cherokee_vendor_license.financial_records_person_id",
    helperText:
      "Select the individual responsible for maintaining the Financial records and Books of the business",
  },
];

export default [
  {
    type: "form",
    formSchema,
    endpoint: "bdSupplement",
  },
];
