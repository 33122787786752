import ControlledAutocomplete from "components/Common/ControlledAutocomplete/ControlledAutoComplete";
import { useCrudSelect } from "components/Formik/formGroups/CrudSelect/hooks/useCrudSelect";
import { useFormikContext } from "formik";
import React from "react";
import { FormikDialog } from "components/Formik/formContainers/FormikDialog";
import FormikTextField from "components/Formik/fields/FormikTextField";

import * as yup from "yup";
import { requiredString } from "helpers/validationSchemaGenerator";

const validationSchema = yup.object().shape({
  name: requiredString,
});

export const DepartmentAndPosition = () => {
  const { values, setFieldValue } = useFormikContext();

  const positionAutoSelectRef = React.useRef();

  const departmentId = values.lc_position_type.lc_department_id;

  const hasDepartmentSelected = typeof departmentId === "number";
  const positionInputRef = React.useRef();

  const selectDepartmentProps = useCrudSelect({
    name: "lc_position_type.lc_department_id",
    endpoint: "licensing/lcDepartment",
    defaultInitialValues: { name: "", notification_emails: [] },
  });

  const handlePositionOnSuccess = () => {
    if (
      positionAutoSelectRef.current &&
      !selectDepartmentProps.formProps.open
    ) {
      positionInputRef.current.focus();
      positionAutoSelectRef.current = false;
    }
  };

  const addDepartmentId = (newValues) => ({
    ...newValues,
    lc_department_id: departmentId,
  });

  const selectPositionProps = useCrudSelect({
    name: "lc_position_type_id",
    endpoint: `licensing/lcPositionType?lc_department_id=${departmentId}`,
    defaultInitialValues: {
      name: "",
      lc_department_id: null,
    },
    fetchOptions: {
      enabled: !!hasDepartmentSelected,
      onSuccess: handlePositionOnSuccess,
    },
    postValues: addDepartmentId,
    patchValues: addDepartmentId,
  });

  const onChangeDepartment = (event, newValue) => {
    selectDepartmentProps.fieldProps.onChange(event, newValue);
    setFieldValue("lc_position_type_id", null);
    positionAutoSelectRef.current = true;
    selectPositionProps.setDropDownValue();
  };

  React.useEffect(() => {
    if (!selectPositionProps.formProps.open) return;
    positionInputRef.current.blur();
  }, [selectPositionProps.formProps.open]);

  return (
    <>
      <ControlledAutocomplete
        {...selectDepartmentProps.fieldProps}
        label="Department"
        onChange={onChangeDepartment}
      />
      <FormikDialog
        title="Add/Edit Department"
        {...selectDepartmentProps.formProps}
        validationSchema={validationSchema}
      >
        <FormikTextField label="Department Name" name="name" xs={6} />
      </FormikDialog>

      <ControlledAutocomplete
        openOnFocus
        inputRef={positionInputRef}
        {...selectPositionProps.fieldProps}
        label="Position"
        disabled={!hasDepartmentSelected}
      />
      <FormikDialog
        title="Add/Edit Position"
        {...selectPositionProps.formProps}
        validationSchema={validationSchema}
      >
        <FormikTextField label="Position Name" name="name" xs={6} />
      </FormikDialog>
    </>
  );
};
