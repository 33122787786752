import PropTypes from "prop-types";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import FormikAsyncSelect from "components/Formik/fields/FormikAsyncSelect";
import usePermission from "components/Permission/usePermissions";

import Permission from "components/Permission/Permission";
import PermissionDialog from "components/Permission/PermissionDialog";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";
import { PermissionLoadingComponent } from "components/Permission/PermissionTree";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { Box } from "@material-ui/core";
import Tooltip from "components/Common/Tooltip";

export const columns = [
  {
    field: "first_name",
  },
  {
    field: "last_name",
  },
  {
    field: "email",
  },
  {
    field: "permission",
    // eslint-disable-next-line react/display-name
    render: (props) => <PermissionDialog {...props} />,
  },
];

const Collaborator = ({ name }) => {
  const { permissionState, permissionDispatch } = usePermission({ id: null });
  const fullEndpoint = "account/collaborators/permissions";

  const formData = useTabularData({
    defaultInitialValues: {
      collaborator_account_id: null,
    },
    saveEndpoint: fullEndpoint,
    getEndpoint: "account/collaborators",
    nonRequired: ["collaborator_account_id"],
    postValues: ({ ...values }) => ({
      ...values,
      permissions: permissionState,
    }),
  });

  return (
    <FormSectionProvider formData={formData} title={name}>
      <EnhancedFormikDialog title={name}>
        <FormikAsyncSelect
          name="collaborator_account_id"
          endpoint="account/collaborators/account"
          fetchOptions={{ useFullEndpoint: false }}
          label="Select User"
          getOptionLabel={({ first_name, last_name, email }) =>
            `${first_name} ${last_name} (${email})`
          }
          getOptionValue={(option) => option.id}
          xs={12}
        />
        <LoadingComponent
          loadingOverwrite={PermissionLoadingComponent}
          status={
            permissionState?.["personal_disclosure"] ? "success" : " loading"
          }
        >
          <Box p={1}>
            <SectionSubHeader
              tooltip={
                <>
                  <Tooltip title="Collaborators have full permission by default" />
                  <Tooltip
                    type="warning"
                    title="Collaborators will have full document access under the document repository"
                  />
                </>
              }
            >
              Use dropdown to edit collabotar&#39;s permissions
            </SectionSubHeader>
            <Box pt={1} />
            <Permission state={permissionState} dispatch={permissionDispatch} />
          </Box>
        </LoadingComponent>
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        columns={columns}
        addButtonText="Add Collaborator"
        handleEditRecord={null}
      />
    </FormSectionProvider>
  );
};

Collaborator.propTypes = {
  name: PropTypes.string,
};

export default Collaborator;
