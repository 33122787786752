import React from "react";

import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/AddCircleOutline";

import styled from "styled-components";

const StyledButton = styled(Button)``;

const Icon = styled(AddIcon)`
  padding-right: 5px;
`;

export default function OpenModalButton({
  onClick,
  text,
  disabled,
  size = "small",
  ...rest
}) {
  return (
    <StyledButton
      disabled={disabled}
      cy-data="open_modal_button"
      color="primary"
      variant="contained"
      onClick={onClick}
      size={size}
      {...rest}
    >
      <Icon />
      {text}
    </StyledButton>
  );
}
