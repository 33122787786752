import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import SecureLoginScreen from "./SecureLoginScreen/SecureLoginScreen";
import { useLogin } from "context/auth";
import { useLoginRedirectFrom } from "context/loginRedirectFrom";
import { Redirect } from "react-router-dom";

export default function Auth0VerifyPage() {
  const login = useLogin();
  const auth0 = useAuth0();
  const route = useLoginRedirectFrom();

  const [error, setError] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [isSecureLoginComplete, setIsSecureLoginComplete] =
    React.useState(false);

  const loginHasBeenCalled = React.useRef(false);

  React.useEffect(() => {
    if (!auth0.isAuthenticated || loginHasBeenCalled.current) return;
    login();
    loginHasBeenCalled.current = true;
  }, [auth0.isAuthenticated, login]);

  React.useEffect(() => {
    if (auth0.error && !auth0.isLoading) {
      setError(true);
    }
    if (!auth0.isLoading && window.location.search.includes("success")) {
      setSuccessMessage(window.location.search.split("&")[1].split("=")[1]);
    }
  }, [auth0.isAuthenticated, auth0.error, auth0.isLoading]);

  /* TODO: need to display error and success message on the current page only without redirection
   *  for both error and success, we have to have a link for Login, where we can directly call loginWithRedirect()
   *  e.g. const { loginWithRedirect } = useAuth0();
   */

  // Redirecting to Login page on error from Auth0
  if (error) {
    const redirectObj = {
      pathname: `/auth/login_page`,
      search: `?error=${auth0.error.error_description}`,
    };
    return <Redirect to={redirectObj} />;
  }

  // Redirecting to Login page with success message in search string
  if (successMessage) {
    const redirectObj = {
      pathname: `/auth/login_page`,
      search: `?success=${successMessage}`,
    };
    return <Redirect to={redirectObj} />;
  }

  const redirectRoute = route ?? "/app/dashboard";
  if (isSecureLoginComplete && auth0.isAuthenticated) {
    return <Redirect to={redirectRoute} />;
  }

  return (
    <SecureLoginScreen
      handleLoadingFinished={() => setIsSecureLoginComplete(true)}
    />
  );
}
