import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";

import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import YesNo from "components/Formik/formGroups/YesNo";
import {
  columns,
  defaultInitialValues,
  endpoint,
  tableQuestions,
} from "./config.js";
import BDBusinessFamilySelect from "components/Formik/formGroups/CrudSelect/selects/BDBusinessFamilySelect";
import BDLawEnforcementSelect from "components/Formik/formGroups/CrudSelect/selects/BDLawEnforcementSelect";
import BDPartySelect from "components/Formik/formGroups/CrudSelect/selects/BDPartySelect";

const BankruptciesForm = () => {
  return (
    <Grid container spacing={2}>
      <BDBusinessFamilySelect />

      <BDLawEnforcementSelect />

      <BDPartySelect
        name="agent_bd_party_id"
        label="Court Appointed Receiver or Trustee"
        header="Court Appointed Receiver or Trustee"
      />

      <SectionSubHeader label="Petition and Appointments" divider />
      <YesNo
        label="Was any petition under any provision of the Federal Bankruptcy Code or any insolvency law filed?"
        name="is_bankruptcy_petition"
        xs={12}
      />
      <YesNo
        label="Was relief sought under any provision of the Federal Bankruptcy Code or any insolvency law?"
        name="is_bankruptcy_relief_sought"
        xs={12}
      />
      <YesNo
        label="Did a court appoint any receiver, fiscal agent, reorganization trustee, or similar officer?"
        name="is_bankruptcy_court_appointed_officer"
        xs={12}
      />

      <SectionSubHeader label="Nature & Details" divider />
      <FormikTextField label="Case Name" name="case_name" xs={4} />
      <FormikTextField label="Docket Number" name="docket_number" xs={4} />
      <FormikTextField
        label="Disposition"
        name="bankruptcy_disposition"
        xs={4}
      />
      <FormikTextField
        label="Nature of Judgement or Relief"
        name="nature_of_judgement"
        xs={12}
      />
      <SectionSubHeader label="Dates" divider />
      <FormikDatePicker
        label="Date Petition Filed or Relief Sought"
        name="charge_date"
        xs={5}
      />
      <FormikDatePicker
        label="Date Judgement or Relief Entered"
        name="disposition_date"
        xs={5}
      />
      <FormikDatePicker
        label="Date Receiver/Agent/Trustee Appointed"
        name="appointed_agent_date"
        xs={5}
      />
    </Grid>
  );
};

const Bankruptcies = ({ name }) => {
  const formData = useTabularData({ defaultInitialValues, endpoint });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <BankruptciesForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
    </FormSectionProvider>
  );
};

export default Bankruptcies;
