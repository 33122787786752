import { Button, CardActions, CardContent } from "@material-ui/core";
import { darken } from "@material-ui/core/styles";
import styled from "styled-components";

export const StyledCardActions = styled(CardActions)`
  display: flex;
  flex-direction: column;
`;

export const LoginButton = styled(Button)`
  border-color: white;
  color: white;
  position: absolute;
  width: 40px;
  right: 15px;
  top: 15px;
  &:hover {
    color: ${darken("#FFFFFF", 0.2)};
    border-color: ${darken("#FFFFFF", 0.2)};
  }
`;

export const StyledCardContent = styled(CardContent)`
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
