import React from "react";

const useAnchorEl = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const toggle = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const setOff = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return { anchorEl, toggle, open, setOff };
};

export default useAnchorEl;
