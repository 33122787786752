import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import { FormikAddressFields } from "components/Formik/formGroups/FormikAddressFields";
import YesNo from "components/Formik/formGroups/YesNo";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikGoogleLookup from "components/Formik/fields/FormikGoogleLookup";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { FirstMiddleLast } from "components/Formik/formGroups/FirstMiddleLast.jsx";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import {
  columns,
  defaultInitialValues,
  endpoint,
  tableQuestions,
  nonRequired,
} from "./config.js";

const ParentsForm = () => {
  const { parentTypeValues } = useMenuOptions();

  return (
    <Grid container spacing={2}>
      <FirstMiddleLast />

      <SectionSubHeader label="Personal Details" divider />
      <YesNo label="Is this parent deceased?" name="is_deceased" />
      <YesNo label="Is this parent retired?" name="is_retired" />

      <FormikSelect
        label="Parent Type"
        name="relative_type"
        options={parentTypeValues}
      />
      <FormikDatePicker label="Date of Birth" name="date_of_birth" />
      <FormikTextField
        label="Place of Birth"
        name="place_of_birth"
        placeholder="City, State, Country"
        helperText="City, State/Province, Country"
      />
      <FormikTextField label="Phone Number" name="phone_number" />
      <FormikTextField
        label="Occupation"
        name="occupation"
        helperText="If retired or deceased, list last occupation"
      />

      <SectionSubHeader label="Home Address" divider />
      <FormikGoogleLookup
        fields={{
          address: "home_address",
        }}
      />

      <FormikAddressFields addressPrefix="home_address." />
    </Grid>
  );
};

const Parents = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint,
    nonRequired,
    mutateRequiredValues: ({ record, requiredValues }) => {
      if (record["is_deceased"]) {
        const { is_retired: _is_retired, ...rest } = requiredValues;
        return rest;
      }
      return requiredValues;
    },
  });

  return (
    <FormSectionProvider formData={formData} title={name}>
      <EnhancedFormikDialog title={name}>
        <ParentsForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        subheader={<InfoAlert>{tableQuestions}</InfoAlert>}
        columns={columns}
        addButtonText="Add Record"
      />
    </FormSectionProvider>
  );
};

export default Parents;
