import React from "react";
import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { defaultInitialValues } from "./config";
import useForm from "hooks/useForm.js";
import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import { SectionLabel } from "components/Common/LayoutComponents";
import FormikAsyncSelect from "components/Formik/fields/FormikAsyncSelect";

const IowaDisclosureQuestions = ({ name }) => {
  const formData = useForm({
    defaultInitialValues,
    noValidate: true,
    queryKey: "pdSupplement",
    endpoint: "iowa_disclosure_questions_pdSupplement",
  });

  return (
    <FormSectionWrapper title={name} formData={formData}>
      <EnhancedFormikPage>
        <Grid container spacing={2}>
          <SectionLabel label="Associated Gaming Entity" />
          <FormikAsyncSelect
            label="Gaming Entity ( Your Association )"
            name="iowa.associated_business_entity_id"
            endpoint="iowa_disclosure_questions_associated_business_entities_a_grouped"
            getOptionLabel={(option) =>
              `${option.business_name} (${option.association})`
            }
            getOptionValue={({ pd_business_entity_id }) =>
              pd_business_entity_id
            }
            xs={6}
          />
        </Grid>
      </EnhancedFormikPage>
    </FormSectionWrapper>
  );
};

export default IowaDisclosureQuestions;
