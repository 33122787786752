export default [
  {
    type: "readOnlyTable",
    linkTo: ["bd_directors_trustees_officers"],
    dependsOnQueryCount:
      " List all persons and/or entities with ownership interest, and all officers and directors, whether they have ownership interest or not. If an entity (corporation, partnership, LLC, etc.) has interest, list all persons associated with such entity, their ownership in the entity, and their effective ownership in the license. List all parent, holding or other intermediary business interest. A Key & Associated Person License Application form must be submitted for all persons with 10% or more effective ownership in either a privately held company or a publicly traded corporation, and all officers and directors. A Limited Ownership Application form must be submitted for all persons with less than 10% effective ownership in a privately held company. If a PTC, submit recent shareholder list from your transfer agent for all shares of common and preferred stock. Make additional copies of this page and/or submit attachments, if necessary.",
    endpoint: "views/bd_positions_owners_officers_directors_a",
    columns: [
      {
        title: "Name",
        field: "party_name",
      },
      {
        title: "Title",
        field: "person_title",
      },
      {
        title: "Address",
        field: "person_home_full_address",
      },
      {
        title: "Phone",
        field: "person_home_phone",
      },
    ],
  },
];
