import { getFormComponents } from "componentGenerators/helpers";
import cherokee_niva_supplement_disclosure_questions from "components/Pages/Supplemental/PD/CherokeeNIVA/CherokeeNIVADisclosureQuestions/CherokeeNIVADisclosureQuestions";
import cherokee_niva_supplement_attachments from "components/Pages/Supplemental/PD/CherokeeNIVA/CherokeeNIVAAttachments/CherokeeNIVAAttachments";
import cherokee_niva_supplement_criminal_history from "components/Pages/Supplemental/PD/CherokeeNIVA/CherokeeNIVACriminalHistory/CherokeeNIVACriminalHistory";

export default getFormComponents({
  cherokee_niva_supplement_disclosure_questions,
  cherokee_niva_supplement_attachments,
  cherokee_niva_supplement_criminal_history,
});
