import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";

import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm";
import React from "react";
import { SectionSubHeader } from "components/Common/LayoutComponents/index";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { defaultInitialValues } from "./config";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import YesNo from "components/Formik/formGroups/YesNo";
import FormikNumberField from "components/Formik/fields/FormikNumberField";

const MichiganPoliticalContributionsForm = () => {
  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Political Contribution Questions" />
      <YesNo
        label="Within one (1) year of this application, have you, either directly or indirectly, made a political contribution?"
        xs={12}
        name="michigan.has_made_political_contribution"
      />
      <FormikTextField
        label="Contributor"
        name="michigan.political_contribution_contributor"
        xs={4}
      />

      <FormikTextField
        label="Candidate/ Official/ Committee Name"
        name="michigan.political_contribution_candidate_name"
        xs={4}
      />

      <FormikTextField
        label="Position Sought/ Held"
        name="michigan.political_contribution_position_held"
        xs={4}
      />

      <FormikNumberField
        label="Amount"
        name="michigan.political_contribution_amount"
        xs={4}
      />

      <FormikTextField
        label="Method of Payment"
        name="michigan.political_contribution_method_of_payment"
        xs={4}
        type="dollar"
      />

      <FormikTextField
        label="Intermediary (If Any)"
        name="michigan.political_contribution_intermediary"
        xs={4}
      />
      <FormikDatePicker
        label="Date"
        name="michigan.political_contribution_date"
        xs={4}
      />
    </Grid>
  );
};

const MichiganPoliticalContributions = ({ name }) => {
  const formData = useForm({
    defaultInitialValues,
    endpoint: "michigan_political_contributions_pdSupplement",
  });

  return (
    <FormSectionProvider formData={formData} title={`Michigan ${name}`}>
      <EnhancedFormikPage>
        <MichiganPoliticalContributionsForm />
      </EnhancedFormikPage>
    </FormSectionProvider>
  );
};

export default MichiganPoliticalContributions;
