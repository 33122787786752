import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Political Donations",
  },
  {
    type: fieldTypes.label,
    label:
      "Has the applicant, the applicant’s spouse or any of the applicant’s dependent children made, directly or indirectly, any contribution, loan or other payment exceeding $400.00 to any individual candidate for public office, campaign committee, governmental office holder or political action committee within the past (5) five years? If YES, please provide the details below.",
  },
  {
    type: fieldTypes.yesNo,
    name: "louisiana_phfr.section_j_q_2_boolean",
    xs: 12,
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "louisiana_phfr.section_j_q_2_details",
    xs: 12,
    rows: 4,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "louisiana_phfr_supplement_political_activity_pdSupplement",
};
