import PropTypes from "prop-types";
import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { getIn, useField, useFormikContext } from "formik";
import withFieldTools from "hocs/withFieldTools";
import { dayjsUTC } from "helpers/apiHelpers";
import { startCase } from "lodash";

const handleError = (fieldError, fieldName, setFieldError) => {
  return (error) => {
    if (error !== fieldError && error !== "") {
      setFieldError(fieldName, error ? String(error) : undefined);
    }
  };
};

function validateDate(value) {
  if (value?.includes("Invalid Date")) {
    return "Invalid Date";
  }
}

const fieldToKeyboardDatePicker = ({
  disabled,
  field: { onChange: _onChange, onBlur: fieldOnBlur, value, ...field },
  form: { isSubmitting, touched, errors, setFieldValue, setFieldError },
  onBlur,
  onError,
  onChange,
  ...props
}) => {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    ...field,
    ...props,
    error: showError,
    helperText: showError ? fieldError : props.helperText,
    disabled: disabled ?? isSubmitting,
    onChange:
      onChange ??
      function (date) {
        setFieldValue(field.name, date ? dayjsUTC(date, "MM/DD/YYYY") : null);
      },
    onBlur:
      onBlur ??
      function (e) {
        fieldOnBlur(e ?? field.name);
      },
    onError: onError ?? handleError(fieldError, field.name, setFieldError),
    value: value === "11/11/2600" ? null : value,
  };
};

export const KeyboardDatePickerBase = ({
  children,
  fullWidth = true,
  InputLabelProps = {
    shrink: true,
  },
  autoOk = true,
  size = "small",
  margin = "dense",
  variant = "inline",
  inputVariant = "outlined",
  views = ["year", "month", "date"],
  format = "MM/dd/yyyy",
  openTo = "year",
  ...props
}) => {
  return (
    <KeyboardDatePicker
      {...props}
      fullWidth={fullWidth}
      InputLabelProps={InputLabelProps}
      autoOk={autoOk}
      size={size}
      margin={margin}
      variant={variant}
      inputVariant={inputVariant}
      views={views}
      format={format}
      openTo={openTo}
      placeholder={format.toLowerCase()}
      maxDate={new Date("11/11/2600")}
      minDate={new Date("01/01/1000")}
    >
      {children}
    </KeyboardDatePicker>
  );
};

KeyboardDatePickerBase.propTypes = {
  InputLabelProps: PropTypes.shape({
    shrink: PropTypes.bool,
  }),
  autoOk: PropTypes.bool,
  children: PropTypes.node,
  format: PropTypes.string,
  fullWidth: PropTypes.bool,
  inputVariant: PropTypes.string,
  margin: PropTypes.string,
  openTo: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  views: PropTypes.array,
};

export const FormikKeyboardDatePickerBase = (props) => {
  const [field] = useField({ validate: validateDate, ...props });
  const form = useFormikContext();

  return (
    <KeyboardDatePickerBase
      {...props}
      {...fieldToKeyboardDatePicker({ field, form, ...props })}
    />
  );
};

const EnhancedKeyboardDatePicker = withFieldTools(FormikKeyboardDatePickerBase);

const defaults = {
  yearMonthDay: { views: ["year", "month", "date"], format: "MM/dd/yyyy" },
  yearMonth: { views: ["year", "month"], format: "MM/yyyy" },
};

const FormikKeyboardDatePicker = ({ type = "yearMonthDay", ...props }) => {
  return (
    <EnhancedKeyboardDatePicker
      label={props.label || startCase(props.name)}
      views={defaults[type].views}
      format={defaults[type].format}
      xs={4}
      {...props}
    />
  );
};

FormikKeyboardDatePicker.propTypes = {
  type: PropTypes.string,
};

export default FormikKeyboardDatePicker;
