import { Button, CircularProgress } from "@material-ui/core";
import { darken } from "@material-ui/core/styles";
import styled from "styled-components";

export const LoginButton = styled(Button)`
  border-color: white;
  color: white;
  position: absolute;
  width: 40px;
  right: 15px;
  top: 15px;
  &:hover {
    color: ${darken("#FFFFFF", 0.2)};
    border-color: ${darken("#FFFFFF", 0.2)};
  }
`;

export const StyledCircularProgress = styled(CircularProgress)`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  color: ${({ theme }) => theme.palette.grey[100]};
`;
