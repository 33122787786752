import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import { Box, Grid, Paper } from "@material-ui/core";
import CustomLink from "components/Common/Link";

const Page = () => {
  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="US Tax Return" />
      <SectionLabel
        label={
          <>
            Please attach a copy of each Federal and New Jersey (or State you
            reside) income tax returns, and all appropriate schedules, filed by
            you since your last submission. If you and your spouse filed
            separate tax returns for any such year, also attach a copy of your
            spouse’s returns. Upload documents in
            <CustomLink to="us_taxes" />
          </>
        }
      />
    </Grid>
  );
};

const NewJersey35QRDisclosureQuestions = ({ name }) => {
  return (
    <FormSectionProvider title={name}>
      <Box mb={1}>
        <Paper variant="outlined">
          <Box p={2}>
            <Page />
          </Box>
        </Paper>
      </Box>
    </FormSectionProvider>
  );
};

export default NewJersey35QRDisclosureQuestions;
