export const defaultInitialValues = {
  nevada_phr: {
    associated_business_entity_id: null,
    business_entity_type: "",
    employer_identification_number: "",
    game_name: "",
  },
};

export const endpoint = "nevada_phr_disclosure_questions_pdSupplement";
