import Card from "components/Common/Containers/Card/Card";
// core components
import GridContainer from "components/Common/Containers/Grid/GridContainer";
import GridItem from "components/Common/Containers/Grid/GridItem";
import React from "react";

import { Typography } from "@material-ui/core";

function NewPasswordCard({ children }) {
  return (
    <div>
      <GridContainer justify="center">
        <GridItem style={{ width: "375px" }}>
          <Card style={{ paddingBottom: "12px", paddingTop: "20px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography gutterBottom variant="h2">
                Enter New Password
              </Typography>

              <div style={{ paddingTop: "15px" }}></div>
            </div>
            {children}
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default NewPasswordCard;
