import React from "react";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { FormikTextField } from "components/Formik/fields";
import { Grid } from "@material-ui/core";

export default function Index({ name }) {
  const formData = useForm({
    defaultInitialValues: {
      new_jersey: {
        application_contact_name: "",
        application_contact_email: "",
        application_contact_website: "",
        application_contact_businss_phone: "",
        application_contact_fax_phone: "",
        application_contact_alternate_name: "",
        application_contact_alternate_email: "",
        application_contact_alternate_phone: "",
      },
    },
    endpoint: "bdSupplement",
  });

  return (
    <FormSectionProvider formData={formData} title={name}>
      <EnhancedFormikPage>
        <Grid container spacing={2}>
          <SectionSubHeader label="Main Contact" />
          <FormikTextField
            label="Name"
            name="new_jersey.application_contact_name"
            xs={4}
          />
          <FormikTextField
            label="Email"
            name="new_jersey.application_contact_email"
            xs={4}
          />
          <FormikTextField
            label="Website"
            name="new_jersey.application_contact_website"
            xs={4}
          />
          <FormikTextField
            label="Business Phone"
            name="new_jersey.application_contact_businss_phone"
            xs={3}
          />
          <FormikTextField
            label="Fax Number"
            name="new_jersey.application_contact_fax_phone"
            xs={3}
          />
          <SectionSubHeader label="Alternate Contact" divider />
          <FormikTextField
            label="Name"
            name="new_jersey.application_contact_alternate_name"
            xs={4}
          />
          <FormikTextField
            label="Email"
            name="new_jersey.application_contact_alternate_email"
            xs={4}
          />
          <FormikTextField
            label="Phone"
            name="new_jersey.application_contact_alternate_phone"
            xs={3}
          />
        </Grid>
      </EnhancedFormikPage>
    </FormSectionProvider>
  );
}
