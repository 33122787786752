import { useUpdateFormStatus } from "context/formStatus";
import { useSnackbar } from "notistack";
import React from "react";

export const useUpdateFlaggedStatus = (status) => {
  const [pending, setPending] = React.useState(false);
  const updateStatus = useUpdateFormStatus();

  const [flaggedState, setFlaggedState] = React.useState({
    currentStatus: status?.status,
    completed: status?.status === "completed" ? true : false,
    flagged: status?.status === "flagged" ? true : false,
  });
  const { enqueueSnackbar } = useSnackbar();

  const getNewState = (name, checked) => {
    if (name === "flagged" || name === "completed") {
      return {
        [name]: checked,
        completed: false,
        currentStatus: name,
      };
    }
    return flaggedState;
  };

  const handleSnackBar = (variant) => (message) => {
    enqueueSnackbar(message, {
      variant,
    });
  };

  const handleStatusChange = ({ target: { checked, name } }) => {
    if (!pending) {
      setPending(true);
      updateStatus({ status: checked ? name : "in_progress" }, status?.id)
        .then(() => {
          setPending(false);
          handleSnackBar("info")("Section Progress Saved");
          setFlaggedState(getNewState(name, checked));
        })
        .catch(() => {
          handleSnackBar("error")("Unable to Save Progress Change");
        });
    }
  };

  React.useEffect(() => {
    if (status?.status !== flaggedState?.currentStatus) {
      setFlaggedState({
        currentStatus: status?.status,
        completed: status?.status === "completed" ? true : false,
        flagged: status?.status === "flagged" ? true : false,
      });
    }
  }, [status, flaggedState]);

  return { handleStatusChange };
};
