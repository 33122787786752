import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Type of gaming license",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "south_dakota_bhd.license_type_checkbox",
    dynamicMenuItems: ["southDakotaLicenseTypeCheckbox"],
  },
  {
    type: fieldTypes.subHeader,
    label: "License Numbers",
  },
  {
    type: fieldTypes.label,
    label: "South Dakota Sales Tax License Number:",
  },
  {
    type: fieldTypes.text,
    label: "Sales Tax License Number",
    name: "south_dakota_bhd.sales_tax_licence_number",
    xs: 6,
  },
  {
    type: fieldTypes.label,
    label: "South Dakota Lottery License Number:",
  },
  {
    type: fieldTypes.text,
    label: "Lottery License Number",
    name: "south_dakota_bhd.lottery_licence_number",
    xs: 6,
  },
  {
    type: fieldTypes.subHeader,
    label: "Contact Persons",
  },

  {
    type: fieldTypes.bdPersonSelect,
    label: "Primary Contact Person",
    helperText: "Primary Contact Person",
    name: "south_dakota_bhd.primary_contact_id",
    xs: 6,
  },
  {
    type: fieldTypes.gridItem,
    xs: 12,
    style: { padding: "0px" },
  },
  {
    type: fieldTypes.bdPersonSelect,
    label: "Tax Contact Person",
    name: "south_dakota_bhd.contact_person_id",
    helperText: "Tax Contact Person",
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "bdSupplement",
};
