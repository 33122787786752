import { Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikCheckBox from "components/Formik/fields/FormikCheckBox.jsx";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import PDPartySelect from "components/Formik/formGroups/CrudSelect/selects/PDPartySelect";
import PDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/PDPersonSelect.jsx";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Borrower Details" />
      <PDPartySelect name="debtor_pd_party_id" label="Borrower" />

      <PDPersonSelect
        label="Relative (If Applicable)"
        header="Lender (If Applicable)"
        divider
        helperText="Select, if the lender was anyone other then you"
      />

      <SectionSubHeader label="Loan Details" divider />
      <FormikTextField
        label="Co-Parties to Loan"
        name="loan_co_parties"
        xs={6}
      />
      <FormikTextField
        label="Security Pledged"
        name="security_of_loan"
        xs={6}
      />
      <FormikNumberField
        label="Original Amount"
        name="original_loan_amount"
        md={4}
      />
      <FormikNumberField name="current_balance" md={4} />
      <FormikNumberField
        label="Interest Rate"
        name="interest_rate"
        md={4}
        type="percent"
      />
      <FormikDatePicker label="Loan Date" name="original_loan_date" xs={4} />
      <FormikDatePicker
        label="Termination Date"
        name="termination_date"
        xs={4}
      />
      <FormikCheckBox
        label="Is Current - Current loans will be included in the Net Worth section."
        name="is_current"
        xs={12}
      />
    </Grid>
  );
};

const LendingHistory = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "lending_history_receivable",
    nonRequired: ["relative_pd_person_id"],
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default LendingHistory;
