import ConfirmationDialog from "components/Common/Dialogs/ConfirmationDialog";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import useToggle from "hooks/useToggle";
import React from "react";
import RelatedBusinessForm from "./RelatedBusinessForm";
import {
  columns,
  defaultInitialValues,
  nonRequired,
  tableQuestions,
} from "./config";

const ForeignKeyError = (props) => (
  <ConfirmationDialog
    title="Related Records Error."
    dialogProps={{ fullWidth: false }}
    cancellationText="Confirm"
    content="In order to delete this business entity. You must first remove any related addresses and other names."
    {...props}
  />
);

export default function RelatedBusinessEntities({ name }) {
  const foreignKeyError = useToggle();

  const deleteOptions = {
    onError: (error) => {
      if (error.response?.data?.message === "ForeignKeyViolationError") {
        foreignKeyError.setOn();
      }
    },
  };

  const formData = useTabularData({
    nonRequired: nonRequired,
    defaultInitialValues,
    endpoint: "bdBusinessEntity?business_relationship_type=not_null",
    deleteOptions,
  });

  return (
    <>
      <ForeignKeyError
        open={foreignKeyError.on}
        onCancel={foreignKeyError.setOff}
      />
      <FormSectionProvider
        formData={formData}
        title={name}
        headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
      >
        <EnhancedFormikDialog title="Related Business">
          <RelatedBusinessForm />
        </EnhancedFormikDialog>
        <EnhancedActionsTable
          columns={columns}
          title="Related Business Entities"
          addButtonText="Add Business Entity"
        />
      </FormSectionProvider>
    </>
  );
}
