import React from "react";
import { useAuth } from "context/auth";

import BusinessDashboard from "./BusinessDashboard";
import PersonalDashboard from "./PersonalDashboard/PersonalDashboard";
import SuperAdminDashboard from "./SuperAdminDashboard";

export default function DashboardRouter() {
  function renderSwitch(param) {
    switch (param) {
      case "employee":
        return <PersonalDashboard />;
      case "business":
        return <BusinessDashboard />;
      case "super_admin":
        return <SuperAdminDashboard />;
      default:
        return null;
    }
  }
  const { account_type, loading } = useAuth();

  if (loading) return null;
  return <>{renderSwitch(account_type)}</>;
}
