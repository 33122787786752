import {
  formGroupTypes,
  fieldTypes,
} from "componentGenerators/formGenerator/config";

const spouseNames = [
  {
    formGroup: formGroupTypes.firstMiddleLast,
    required: true,
  },
  {
    type: fieldTypes.text,
    label: "Maiden Name",
    name: "maiden_name",
  },
  {
    type: fieldTypes.select,
    label: "Relationship Type",
    name: "relative_type",
    dynamicMenuItems: ["spouseTypeValues"],
    defaultValue: null,
    required: true,
  },
];

const marriageInformation = [
  {
    type: fieldTypes.subHeader,
    divider: true,
    label: "Marriage Information",
  },
  {
    type: fieldTypes.date,
    label: "Date of Marriage",
    name: "date_of_marriage",
  },

  {
    type: fieldTypes.text,
    label: "Place of Marriage",
    name: "place_of_marriage",
    helperText: "City, County, State, Country",
  },
];

const currentMarriagesFormSchema = [
  ...spouseNames,
  {
    type: fieldTypes.subHeader,
    divider: true,
    label: "Personal Details",
  },
  {
    type: fieldTypes.text,
    label: "Home Phone",
    name: "phone_number",
  },
  {
    type: fieldTypes.text,
    label: "Mobile Phone",
    name: "mobile_phone",
  },
  {
    type: fieldTypes.date,
    label: "Date of Birth",
    name: "date_of_birth",
  },
  {
    type: fieldTypes.text,
    label: "Social Security Number",
    name: "ssn",
  },
  {
    type: fieldTypes.text,
    label: "Occupation",
    name: "occupation",
  },
  {
    type: fieldTypes.text,
    label: "Place of Birth",
    name: "place_of_birth",
    helperText: "City, State, Country",
  },
  {
    type: fieldTypes.text,
    label: "Driver's License Number",
    name: "drivers_license_number",
    helperText: "(If applicable to application)",
  },
  {
    type: fieldTypes.text,
    label: "Driver's License Jurisdiction",
    name: "drivers_license_jurisdiction",
    helperText: "(If applicable to application)",
  },
  {
    formGroup: formGroupTypes.addressLookupAndFields,
    label: "Address",
    fields: {
      address: "home_address",
    },
    name: "home_address",
  },
  ...marriageInformation,

  {
    type: fieldTypes.subHeader,
    divider: true,
    label: "Marriage Documents",
  },
  {
    type: fieldTypes.label,
    divider: true,
    label: "Upload a copy of your Marriage License",
    tooltip: "Marriage License required by Missouri.",
    tooltipProps: { type: "warning" },
  },
  {
    type: fieldTypes.dropZone,
    description: "Current Marriage",
    label: "Upload Attachment",
    name: "attachment_group_id",
  },
];

const currentMarriagesColumns = [
  {
    field: "full_name",
  },
  {
    field: "phone_number",
  },
  { field: "ssn" },
  { field: "home_address", format: "address" },
];

const currentMarriagesSchema = {
  type: "table",
  endpoint: "marriages_currentSpouse",
  formSchema: currentMarriagesFormSchema,
  title: "Current Marriages",
  nonRequired: ["attachment_group_id", "suffix_name", "middle_name"],
  maxRecordSize: 1,
  columns: currentMarriagesColumns,
  infoAlerts: [
    {
      body:
        "Provide detail regarding your current Marriage, Civic Union or Domestic Partnership.",
    },
  ],

  modifySubmissionValues: (formValues) => ({
    ...formValues,
  }),
};

const maritalStatusFormSchema = [
  {
    type: fieldTypes.label,
    label:
      "Provide your current marital status and number of times you have been married. Please note: if  Civic Union or Domestic Partnership is selected and that option is not available on a jurisdiction's application “Single” will be selected.",
  },
  {
    type: fieldTypes.select,
    label: "Current Marital Status",
    name: "marital_status",
    dynamicMenuItems: ["maritalStatus"],
    defaultValue: null,
  },
  {
    type: fieldTypes.text,
    label: "Number of Marriages",
    name: "marriage_count",
    placeholder: "0",
  },
];

const maritalStatusSchema = {
  type: "form",
  endpoint: "marriages_personalDisclosure",
  title: "Marital Questions",
  formSchema: maritalStatusFormSchema,
};

const previousMarriagesColumns = [
  {
    field: "full_name",
  },
  {
    field: "divorce_court_name",
  },
  { field: "home_address", format: "address" },
  { field: "divorce_case" },
  { field: "divorce_date" },
];

const previousMarriagesFormSchema = [
  ...spouseNames,
  {
    type: fieldTypes.subHeader,
    divider: true,
    label: "Personal Details",
  },
  {
    type: fieldTypes.text,
    label: "Phone Number",
    name: "phone_number",
  },
  {
    type: fieldTypes.date,
    label: "Date of Birth",
    name: "date_of_birth",
  },
  {
    formGroup: formGroupTypes.addressLookupAndFields,
    label: "Address",
    fields: {
      address: "home_address",
    },
    name: "home_address",
  },
  ...marriageInformation,
  {
    type: fieldTypes.subHeader,
    divider: true,
    label: "Dissolution Information",
  },
  {
    type: fieldTypes.select,
    label: "Nature of Action",
    name: "marriage_dissolution_action",
    dynamicMenuItems: ["marriageDissolutionAction"],
    defaultValue: null,
    required: true,
  },
  {
    type: fieldTypes.text,
    label: "Divorce Court Name",
    name: "divorce_court_name",
  },
  {
    type: fieldTypes.text,
    label: "Divorce Jurisdiction",
    name: "divorce_jurisdiction",
    tooltip: "City, county & state are required by Nevada & Colorado.",
    tooltipProps: { type: "warning" },
  },
  {
    type: fieldTypes.text,
    label: "Divorce Case #",
    name: "divorce_case",
  },
  {
    type: fieldTypes.date,
    label: "Divorce Date",
    name: "divorce_date",
  },
  {
    type: fieldTypes.subHeader,
    divider: true,
    label: "Divorce Documents",
  },
  {
    type: fieldTypes.label,
    divider: true,
    label: "Upload all documentation pertaining to your divorce.",
    tooltip: "Divorce documents are required by Missouri.",
    tooltipProps: { type: "warning" },
  },
  {
    type: fieldTypes.dropZone,
    description: "Previous Marriages",
    label: "Upload Attachment",
    name: "attachment_group_id",
  },
];

const previousMarriagesSchema = {
  type: "table",
  endpoint: "marriages_previousSpouse",
  formSchema: previousMarriagesFormSchema,
  title: "Previous Marriages",
  nonRequired: [
    "attachment_group_id",
    "suffix_name",
    "middle_name",
    "drivers_license_number",
    "drivers_license_jurisdiction",
  ],
  columns: previousMarriagesColumns,
  infoAlerts: [
    {
      body:
        "Provide detail regarding your previous Marriages, Civic Unions or Domestic Partnerships.",
    },
  ],

  modifySubmissionValues: (formValues) => ({
    ...formValues,
  }),
};

export default [
  maritalStatusSchema,
  currentMarriagesSchema,
  previousMarriagesSchema,
];
