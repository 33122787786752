import React, { useState } from "react";
import { useAuth } from "context/auth";
import { Card, Box, CircularProgress } from "@material-ui/core";
import ValidateAllTags from "./Components/ValidateAllTags";
import DropDown from "components/Common/Inputs/DropDown";
import ReportMessage from "./Components/ReportMessage";
import TableRowTag from "components/DevTools/PdfTools/Tools/DataIndex/Components/TableRowTag";

export default function Report(props) {
  const { PDFValidationTools } = props;
  const [loading, setLoading] = useState(false);
  const [reportType, setReportType] = useState("");
  const [pdfValidity, setPdfValidity] = useState([]);
  const [topHundred, setTopHundred] = useState([]);

  const { employee_id, bd_business_entity_id, bd_organization_id, account_id } =
    useAuth();

  const resetState = () => {
    setReportType("");
    setPdfValidity([]);
    setTopHundred([]);
  };

  async function getTopHundredTags(params) {
    const res = await PDFValidationTools.getTopHundredTags(params);
    setTopHundred(res.top_hundred);
  }

  const fetchTagValidation = async () => {
    const res = await PDFValidationTools.validatePdfsFormat({
      employee_id,
      bd_business_entity_id,
      bd_organization_id,
      account_id,
    });
    setPdfValidity(res);
  };

  const selectReport = async (e) => {
    setReportType(e.target.value);
    setLoading(true);
    switch (e.target.value) {
      case "validate_tags":
        resetState();
        await fetchTagValidation();
        break;
      case "top_hundred_tags":
        resetState();
        await getTopHundredTags({ isBD: !!bd_organization_id });
        break;
      default:
        break;
    }
    setLoading(false);
  };

  return (
    <Card style={{ padding: "25px" }}>
      {!loading ? (
        <>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box style={{ width: "200px" }} mr={1}>
              <DropDown
                variant="filled"
                label="Select Report Type"
                menuItems={[
                  { value: "validate_tags", label: "Validate All Tags" },
                  { value: "top_hundred_tags", label: "Top Hundred Tags" },
                ]}
                value={reportType}
                onChange={selectReport}
                fullWidth
              />
            </Box>
            <ReportMessage pdfValidity={pdfValidity} reportType={reportType} />
          </Box>
          <ValidateAllTags pdfValidity={pdfValidity} />
          {topHundred.map((val) => (
            <TableRowTag key={val.source_tag_name} text={val.source_tag_name} />
          ))}
        </>
      ) : (
        <CircularProgress />
      )}
    </Card>
  );
}
