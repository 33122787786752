import BD_IowaClassDApplicationInformation from "components/Pages/Supplemental/BD/IowaClassD/IowaClassDApplicationInformation/IowaClassDApplicationInformation";
import BD_IowaClassDDocuments from "components/Pages/Supplemental/BD/IowaClassD/IowaClassDDocuments/IowaClassDDocuments";
import BD_IowaClassDBoardMeetings from "components/Pages/Supplemental/BD/IowaClassD/IowaClassDBoardMeetings/IowaClassDBoardMeetings";
import BD_IowaClassDLegalProceeding from "components/Pages/Supplemental/BD/IowaClassD/IowaClassDIowaLegalProceeding/IowaLegalProceeding";
import BD_IowaClassDFinancialData from "components/Pages/Supplemental/BD/IowaClassD/IowaClassDFinancialData/IowaClassDFinancialData";
import BD_IowaClassDComplianceReport from "components/Pages/Supplemental/BD/IowaClassD/IowaClassDComplianceReport/IowaClassDComplianceReport";
import BD_IowaClassDComplianceOfficer from "components/Pages/Supplemental/BD/IowaClassD/IowaClassDComplianceOfficer/IowaClassDComplianceOfficer";

const bd_ia_class_d_pages = {
  bd_iowa_supplement_application_information: BD_IowaClassDApplicationInformation,
  bd_iowa_supplement_legal_proceeding: BD_IowaClassDLegalProceeding,
  bd_iowa_supplement_documents: BD_IowaClassDDocuments,
  bd_iowa_supplement_board_meetings: BD_IowaClassDBoardMeetings,
  bd_iowa_supplement_financial_data: BD_IowaClassDFinancialData,
  bd_iowa_supplement_compliance_report: BD_IowaClassDComplianceReport,
  bd_iowa_supplement_compliance_officer: BD_IowaClassDComplianceOfficer,
};

export default bd_ia_class_d_pages;
