import { Grid, Box } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import YesNo from "components/Formik/formGroups/YesNo";
import { SectionSubHeader } from "components/Common/LayoutComponents";

import {
  columns,
  defaultInitialValues,
  endpoint,
  tableQuestions,
} from "./config.js";
import ChildrenQuestions from "./ChildrenQuestions";
import { InfoAlert } from "components/Layout/Alert/InfoAlert.jsx";
import FormikGoogleLookup from "components/Formik/fields/FormikGoogleLookup.jsx";
import { FormikAddressFields } from "components/Formik/formGroups/FormikAddressFields.jsx";
import { useMenuOptions } from "hooks/useMenuOptions.js";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import { FirstMiddleLast } from "components/Formik/formGroups/FirstMiddleLast.jsx";

const ChildrenForm = () => {
  const {
    spouseTypeValues,
    parentTypeValues,
    siblingSpouseTypeValues,
    otherRelativeTypeValues,
  } = useMenuOptions();

  const allRelatives = []
    .concat(spouseTypeValues)
    .concat(parentTypeValues.slice(1))
    .concat(siblingSpouseTypeValues.slice(1))
    .concat(otherRelativeTypeValues.slice(1));

  return (
    <Grid container spacing={2}>
      <FirstMiddleLast />

      <SectionSubHeader label="Personal Details" divider />
      <FormikSelect
        label="Relationship"
        name="relative_type"
        options={allRelatives}
        helperText="If Related"
        xs={4}
      />
      <YesNo label="Is this person deceased?" name="is_deceased" />
      <YesNo label="Is this person a dependent?" name="is_dependent" xs={12} />
      <FormikTextField
        label="Occupation"
        name="occupation"
        helperText="If retired or deceased, list last occupation"
      />
      <FormikTextField label="Phone Number" name="phone_number" xs={4} />
      <FormikDatePicker label="Date of Birth" name="date_of_birth" />
      <FormikTextField
        label="Place of Birth"
        name="place_of_birth"
        placeholder="City, State, Country"
        helperText="City, State/Province, Country"
      />
      <FormikTextField
        label="Amount of Support"
        name="support_amount"
        helperText="If Related"
      />

      <SectionSubHeader label="Home Address" divider />
      <FormikGoogleLookup
        fields={{
          address: "home_address",
        }}
      />
      <FormikAddressFields addressPrefix="home_address." />
    </Grid>
  );
};

const Children = ({ title }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint,
    nonRequired: ["support_amount", "suffix_name", "middle_name"],
  });

  return (
    <FormSectionProvider
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
      formData={formData}
      title={title}
    >
      <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
      <EnhancedFormikDialog title={"Children"}>
        <ChildrenForm />
      </EnhancedFormikDialog>
      <Box pt={1}>
        <ChildrenQuestions />
      </Box>
    </FormSectionProvider>
  );
};

export default Children;
