import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import { FormikAddressFields } from "components/Formik/formGroups/FormikAddressFields";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikGoogleLookup from "components/Formik/fields/FormikGoogleLookup";
import FormikSelect from "components/Formik/fields/FormikSelect";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";
import { FirstMiddleLast } from "../../../../Formik/formGroups/FirstMiddleLast";
import FormikEmailTextField from "components/Formik/fields/FormikEmailTextField.jsx";

const PeopleForm = () => {
  const { listOfCountries } = useMenuOptions();

  return (
    <Grid container spacing={2}>
      <FirstMiddleLast />
      <SectionSubHeader label="Professional Details" divider />
      <FormikTextField label="Occupation" name="occupation" xs={4} />
      <FormikTextField label="Title" name="title" xs={4} />

      <SectionSubHeader label="Home Address" divider />
      <Grid item xs={12}>
        <FormikGoogleLookup
          fields={{
            address: "home_address",
          }}
        />
      </Grid>
      <FormikAddressFields addressPrefix="home_address." />

      <SectionSubHeader label="Birth Information" divider />
      <FormikDatePicker
        label="Date of Birth"
        name="date_of_birth"
        xs={12}
        md={6}
      />
      <FormikSelect
        label="Country of Birth"
        name="country_of_birth"
        options={listOfCountries}
        xs={4}
      />

      <SectionSubHeader label="Contact Details" divider />
      <FormikTextField label="Home Phone" name="home_phone" xs={4} />
      <FormikTextField label="Mobile Phone" name="mobile_phone" xs={4} />
      <FormikTextField label="Business Phone" name="business_phone" xs={4} />
      <FormikEmailTextField label="Email Address" name="email" xs={6} />
      <FormikTextField label="Fax Number" name="fax" xs={6} />
      <FormikTextField label="Social Security Number" name="ssn" xs={6} />
    </Grid>
  );
};

const People = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "bdPerson",
    nonRequired: ["to_date", "ssn", "title", "fax"],
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title="Person Details">
        <PeopleForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Person" />
    </FormSectionProvider>
  );
};

export default People;
