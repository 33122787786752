import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.yesNo,
    label:
      "Are you delinquent in the payment of any obligations to any governmental agency anywhere?",
    name: "colorado_kapa.is_tax_delinquent",
  },
  {
    type: fieldTypes.label,
    label: "If yes, please provide details below:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "colorado_kapa.tax_delinquent_details",
    xs: 12,
    rows: 4,
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Are you delinquent in the repayment of any government-insured student loans?",
    name: "colorado_kapa.is_student_loan_delinquent",
  },
  {
    type: fieldTypes.label,
    label: "If yes, please provide details below:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "colorado_kapa.student_loan_delinquent_details",
    xs: 12,
    rows: 4,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "colorado_kapa_supplement_financial_history_pdSupplement",
};
