import { getFormComponents } from "componentGenerators/helpers";
import bd_maryland_swcla_supplement_application_information from "components/Pages/Supplemental/BD/MarylandSWCLA/MarylandSWCLAApplicationInformation/MarylandSWCLAApplicationInformation";
import bd_maryland_swcla_supplement_attachments from "components/Pages/Supplemental/BD/MarylandSWCLA/MarylandSWCLAAttachments/MarylandSWCLAAttachments";
import bd_maryland_swcla_supplement_business_description from "components/Pages/Supplemental/BD/MarylandSWCLA/MarylandSWCLABusinessDescription/MarylandSWCLABusinessDescription";

export default getFormComponents({
  bd_maryland_swcla_supplement_application_information,
  bd_maryland_swcla_supplement_attachments,
  bd_maryland_swcla_supplement_business_description,
});
