import { getFormComponents } from "componentGenerators/helpers";
import bd_virginia_sbvr_supplement_application_information from "components/Pages/Supplemental/BD/VirginiaSBVR/VirginiaSBVRApplicationInformation/VirginiaSBVRApplicationInformation";
import bd_virginia_sbvr_supplement_business_background from "components/Pages/Supplemental/BD/VirginiaSBVR/VirginiaSBVRBusinessBackground/VirginiaSBVRBusinessBackground";
import bd_virginia_sbvr_supplement_qualifiers from "components/Pages/Supplemental/BD/VirginiaSBVR/VirginiaSBVRQualifiers/VirginiaSBVRQualifiers";

export default getFormComponents({
  bd_virginia_sbvr_supplement_application_information,
  bd_virginia_sbvr_supplement_business_background,
  bd_virginia_sbvr_supplement_qualifiers,
});
