import React from "react";
import * as yup from "yup";

export const validationSchema = yup.object().shape({
  activity_type: yup.string().required("Required Field"),
});

export const columns = [
  {
    title: "Business Entity",
    field: "party_name",
  },
  {
    title: "Licensing Agency",
    field: "license_agency_bd_business_entity.business_name",
  },
  {
    title: "Type",
    field: "license_type",
  },
  {
    title: "Disposition",
    field: "disposition",
    format: "startCase",
  },
  {
    title: "License Number",
    field: "license_number",
  },
  {
    title: "Expiration Date",
    field: "expiration_date",
    format: "date",
  },
];

export const tableQuestions = (
  <>
    Provide details if the Applicant, its parent or any subsidiary ever applied
    in any jurisdiction for a license or permit issued by a government agency
    charged with:
    <ul>
      <li>
        Regulating gambling including but not limited to slot machines, video
        lottery terminals, table games, horse racing, sports betting; or
      </li>
      <li>
        Regulating other activities not related to games of chance (e.g.
        alcoholic beverages)
      </li>
    </ul>
    Note: A government agency includes any federal, state, Native American or
    local government created to carry out a governmental function or to
    implement a statute or statutes.
  </>
);

export const defaultInitialValues = {
  license_type: "",
  industry_type: null,
  license_number: "",
  application_number: "",

  application_date: null,
  bd_party_id: null,
  license_agency_bd_business_entity_id: null,
  expiration_date: null,
  disposition_date: null,
  disposition: null,
  bd_license_attachment_group_id: null,
  bd_license_activity: [],
};

export const licenseActivityColumns = [
  {
    title: "Date",
    field: "activity_date",
    format: "date",
  },
  {
    title: "Type",
    field: "activity_type",
    format: "startCase",
  },
  {
    title: "Description",
    field: "description",
  },
];

export const licenseActivityInitialValues = {
  activity_date: null,
  activity_type: "",
  description: "",
};
