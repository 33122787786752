import axios from "axios";
import { logout401 } from "helpers/apiHelpers";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { uploadActions } from "./uploadsReducer";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";
import { captureSentryError } from "error/helpers";

const ACCEPTED_FILE_TYPES =
  "application/pdf, application/xml, image/png, image/jpeg, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, text/plain, image/gif, image/bmp, application/vnd.oasis.opendocument.presentation, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.text, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, image/svg+xml, application/vnd.visio,";

const onFilePostSuccess =
  ({ data, options, handleUploadComplete, handleError, handleS3Success }) =>
  (resp) => {
    return data.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const axiosInstance = axios.create();
        delete axiosInstance.defaults.headers.common["Authorization"];

        return axiosInstance
          .put(resp.data.upload_link, reader.result, options)
          .then((s3Response) => {
            if (s3Response.status !== 200) {
              throw new Error(
                "S3 upload failed for file : " +
                  resp.data.upload_link +
                  ",  Response status :" +
                  s3Response.status
              );
            }
            return resp.data.upload_link;
          })
          .then((s3URL) => {
            return handleS3Success(s3URL.split("?")[0]);
          })
          .then(() => {
            handleUploadComplete();
          })
          .catch(handleError);
      };
      reader.readAsArrayBuffer(file);
    });
  };

function myCustomFileGetter(event) {
  const newFiles = [];
  const fileList = event.dataTransfer
    ? event.dataTransfer.files
    : event.target.files;

  for (var i = 0; i < fileList.length; i++) {
    const file = fileList.item(i);

    Object.defineProperty(file, "myProp", {
      value: true,
    });

    newFiles.push(file);
  }
  return newFiles;
}

const calculateProgressPercent = (progressEvent) =>
  Math.floor((progressEvent.loaded * 100) / progressEvent.total);

export const useCustomDropZone = ({
  setAttachmentGroupID,
  attachmentGroupID,
  getAttachmentEndpoints,
  attachmentGroupEndpoint,
  refetchFiles,
  uploadsInProgressDispatch,
  description,
  acceptedFileTypes = ACCEPTED_FILE_TYPES,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const onFileUpload = useCallback(
    (payload, route, getAttachmentEndpoints, fileTrackingID) => {
      const data = new FormData();
      data.append("attachment", payload);

      const onUploadProgress = (progressEvent) => {
        uploadsInProgressDispatch({
          type: uploadActions.UPDATE_UPLOAD_PROGRESS,
          fileTrackingID,
          progress: calculateProgressPercent(progressEvent),
        });
      };

      const options = {
        headers: {
          "Content-Type": payload.type,
          "X-Actual-Content-Length": payload.size,
          "content-disposition": "inline",
        },
        onUploadProgress,
        withCredentials: false,
      };

      const handleUploadComplete = () => {
        uploadsInProgressDispatch({
          type: uploadActions.REMOVE_UPLOAD,
          fileTrackingID,
        });
        refetchFiles();
      };

      const handleError = (err) => {
        logout401(err);
        enqueueSnackbar("There was an error while uploading the attachment", {
          variant: "error",
        });
        captureSentryError(err);
      };

      const handleS3Success = (s3URL) => {
        const { mutate } = route;
        return axios
          .post(mutate, { name: payload.name, url: s3URL })
          .then((resp) => {
            onFilePostSuccess({
              data: resp.data,
              options,
              getAttachmentEndpoints,
              handleUploadComplete,
              handleError,
            });
          });
      };
      const { uploadUrlMutate } = route;
      return axios.post(uploadUrlMutate, { name: payload.name }).then(
        onFilePostSuccess({
          data,
          options,
          getAttachmentEndpoints,
          handleUploadComplete,
          handleS3Success,
        })
      );
    },
    [enqueueSnackbar, refetchFiles, uploadsInProgressDispatch]
  );

  const handleAcceptedFile = useCallback(
    (file, attachmentGroupIdTemp) => {
      const route = getAttachmentEndpoints(attachmentGroupIdTemp);
      const fileTrackingID = uuidv4();

      uploadsInProgressDispatch({
        type: uploadActions.ADD_UPLOAD,
        fileTrackingID,
        payload: file,
      });

      onFileUpload(file, route, getAttachmentEndpoints, fileTrackingID);
    },
    [getAttachmentEndpoints, onFileUpload, uploadsInProgressDispatch]
  );

  const handleDropWithNoAttachmentGroup = useCallback(
    async (acceptedFiles) => {
      try {
        const { mutate } = attachmentGroupEndpoint;
        const { data } = await axios.post(mutate, {
          description,
        });

        setAttachmentGroupID(data.id);

        acceptedFiles.forEach((file) => {
          handleAcceptedFile(file, data.id);
        });
      } catch (error) {
        logout401(error);
      }
    },
    [
      attachmentGroupEndpoint,
      description,
      setAttachmentGroupID,
      handleAcceptedFile,
    ]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      //Create and assign attachment_group_id to the record if does not exist
      if (!attachmentGroupID) {
        handleDropWithNoAttachmentGroup(acceptedFiles);
      } else {
        acceptedFiles.forEach((file) => {
          handleAcceptedFile(file, attachmentGroupID);
        });
      }
    },
    [attachmentGroupID, handleDropWithNoAttachmentGroup, handleAcceptedFile]
  );

  return useDropzone({
    getFilesFromEvent: (event) => myCustomFileGetter(event),
    onDrop,
    multiple: true,
    accept: acceptedFileTypes,
  });
};
