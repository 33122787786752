export const defaultInitialValues = {
  tennessee: {
    last_federal_tax_return_year: null,
    last_federal_tax_return_date: null,
    has_federal_tax_return_audit: null,
    federal_tax_return_audit_tax_year: null,
    federal_tax_return_audit_year: null,
    federal_tax_return_audit_agency_name: "",
    federal_tax_return_audit_resulting_action: "",
    federal_tax_return_audit_resulting_action_status: "",
    has_not_filed_federal_tax_return: null,
    not_filed_federal_tax_return_reason_and_status: "",
  },
};

export const endpoint = "tennessee_tax_information_pdSupplement";
