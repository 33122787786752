//Adapted from https://github.com/CodAffection/React-Material-UI-Table-Paging-Sorting-and-Filtering.

import React from "react";
import { get, has } from "lodash";
import { getNextExpiry } from "components/Pages/LicensingControl/helpers.js";
import dayjs from "dayjs";

export default function useLicenseTable({
  records,
  filterFn,
  defaultOrderBy = null,
  defaultOrder = null,
}) {
  const pages = [5, 10, 25];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [order, setOrder] = React.useState(defaultOrder);
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const onChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array
      .map((el, index) => [el, index])
      .sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
    return stabilizedThis.map((el) => el[0]);
  };

  const getNextExpiryDescendingComparator = (a, b) => {
    if (a === null || a === "N/A") {
      return -1;
    }
    if (b === null || b === "N/A") {
      return 1;
    }
    if (dayjs(b).isBefore(dayjs(a))) {
      return -1;
    }
    if (dayjs(b).isAfter(dayjs(a))) {
      return 1;
    }
    return 0;
  };

  const descendingComparator = (a, b, orderBy) => {
    //a bit goofy but the objects for person and for business are different
    let expiryOrderBy = null;
    if (orderBy === "expiry") {
      if (has(a, "lc_person")) {
        expiryOrderBy = "lc_person.lc_license";
      } else {
        expiryOrderBy = "lc_license";
      }
      const expiryA = getNextExpiry(get(a, expiryOrderBy));
      const expiryB = getNextExpiry(get(b, expiryOrderBy));
      return getNextExpiryDescendingComparator(expiryA, expiryB);
    }
    if (get(b, orderBy) === undefined || get(a, orderBy) === undefined) {
      return 1;
    }
    if (get(b, orderBy) < get(a, orderBy)) {
      return -1;
    }
    if (get(b, orderBy) > get(a, orderBy)) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) =>
    order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);

  const refineOrderBy = (orderBy) => {
    if (orderBy === "person_name") {
      return "full_name";
    } else if (orderBy === "department") {
      return "lc_position_type.lc_department.name";
    }
    return orderBy;
  };

  const getSortedRecords = () => {
    const sortedRecords = stableSort(
      filterFn.fn(records),
      getComparator(order, refineOrderBy(orderBy))
    );
    return sortedRecords;
  };

  const tableHeadProps = {
    orderBy,
    order,
    handleSortRequest,
  };

  const paginationProps = {
    page,
    pages,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
  };

  return {
    tableHeadProps,
    paginationProps,
    getSortedRecords,
  };
}
