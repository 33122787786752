import { DialogContent } from "@material-ui/core";
import SimpleDialog from "components/Common/Dialogs/SimpleDialog";
import ApplicationPdfViewer from "components/Pages/Applications/ApplicationPdfViewer";
import React from "react";

export const PdfViewerDialog = ({
  open,
  onClose,
  selectedApplication,
  id,
  account_type,
}) => (
  <SimpleDialog
    title="Generated PDF"
    open={open}
    onClose={onClose}
    fullWidth
    maxWidth={"lg"}
  >
    <DialogContent>
      <div style={{ height: "80vh" }}>
        <ApplicationPdfViewer
          data={selectedApplication}
          onClose={onClose}
          id={id}
          account_type={account_type}
        />
      </div>
    </DialogContent>
  </SimpleDialog>
);
