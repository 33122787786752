import { Box, Grid } from "@material-ui/core";

import React from "react";
import useFetch from "queryHooks/useFetch.js";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import DisplayTable from "components/Common/Tables/DisplayTable/index.js";
import { defaultInitialValues } from "./config";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import YesNo from "components/Formik/formGroups/YesNo";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { SectionLabel } from "components/Common/LayoutComponents";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import useForm from "hooks/useForm";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const LegalHistoryTableForm = () => {
  return (
    <Grid container spacing={2}>
      <YesNo
        header="Additional Legal Background"
        label={`Have you ever been questioned or deposed by a city, state, federal, or law enforcement agency, commission or committee?`}
        name="illinois.pd_q20_c_boolean"
      />
      <FormikTextField
        label="Details"
        name="illinois.pd_q20_c_details"
        multiline
        xs={8}
        rows={4}
      />
      <YesNo
        label={`Have you ever been subpoenaed to appear or testify before a federal, state, or county grand jury, board or commission?`}
        name="illinois.pd_q20_d_boolean"
      />
      <FormikTextField
        label="Details"
        multiline
        name="illinois.pd_q20_d_details"
        xs={8}
        rows={4}
      />
      <YesNo
        label={`Have you ever been subpoenaed to testify for any civil, criminal, or administrative proceeding or hearing?`}
        name="illinois.pd_q20_e_boolean"
      />
      <FormikTextField
        label="Details"
        name="illinois.pd_q20_e_details"
        multiline
        xs={8}
        rows={5}
      />
      <YesNo
        label={`Have you ever had a criminal record expunged or sealed by a court order?`}
        name="illinois.pd_q20_f_boolean"
      />
      <SectionLabel label="If yes, provide the following" />
      <FormikDatePicker label="Date" name="illinois.pd_q20_f_date" xs={3} />
      <FormikTextField
        label="Location (City, County & State)"
        name="illinois.pd_q20_f_location"
        xs={4}
      />
      <FormikTextField
        label="Details"
        name="illinois.pd_q20_f_details"
        multiline
        xs={8}
        rows={4}
      />
      <SectionLabel label="Provide a detailed statement of any threatened Material Litigation in any jurisdiction for you as an individual, member of a partnership or limited liability company, or owner, director, or officer of a corporation." />
      <FormikTextField
        label="Details"
        name="illinois.pd_q20_j_details"
        multiline
        xs={8}
        rows={4}
      />
    </Grid>
  );
};

const IllinoisLegalHistory = ({ name }) => {
  const formData = useForm({
    noValidate: true,
    defaultInitialValues,
    endpoint: "illinois_legal_history_pdSupplement",
  });
  const { data = [], status } = useFetch(
    "illinois_legal_history_criminal_charges"
  );

  return (
    <FormSectionWrapper title={name}>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={status}>
          <DisplayTable
            records={data}
            linkTo={["arrests"]}
            columns={[
              {
                title: "Date of Arrest",
                field: "charge_date",
                format: "date",
              },
              { title: "Offense/Charge", field: "nature" },
              { title: "Arresting/Charging Agency", field: "agency_name" },
              { title: "Incident Location", field: "incident_location" },
              { title: "Disposition", field: "disposition" },
              {
                title: "Disposition Date",
                field: "disposition_date",
                format: "date",
              },
            ]}
            dependsOnQueryCount="Have you ever been arrested, detained, charged, indicted, or summoned to answer for any criminal offense or violation for any reason whatsoever, regardless of the disposition of the event? (Except minor traffic citations.)"
          />
        </LoadingComponent>
      </Box>
      <FormContextProvider formData={formData}>
        <EnhancedFormikPage>
          <LegalHistoryTableForm />
        </EnhancedFormikPage>
      </FormContextProvider>
    </FormSectionWrapper>
  );
};

export default IllinoisLegalHistory;
