import { Box, Button, Card, Grid, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import PeopleIcon from "@material-ui/icons/People";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const ComplianceBadge = styled(VerifiedUserIcon)`
  color: ${({ theme, $isCompliant }) =>
    $isCompliant ? theme.palette.success.main : theme.palette.warning.light};
`;

const InfoCard = ({ icon, number, text }) => (
  <Card style={{ height: "100%" }} variant="outlined">
    <Box
      pt={2}
      pb={2}
      pl={5}
      style={{ height: "100%" }}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      {icon}
      <Box pt={0.5} pr={2}>
        <Typography variant="h1" gutterBottom>
          <Box fontWeight="600" fontStyle="italic" fontSize={30}>
            {number}
          </Box>
        </Typography>
        <Typography variant="h4">
          <Box fontStyle="italic">{text}</Box>
        </Typography>
      </Box>
    </Box>
  </Card>
);

const ActionButton = ({ children, ...rest }) => (
  <Box display="flex" justifyContent="center">
    <Box width={0.8}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        size="large"
        {...rest}
      >
        {children}
      </Button>
    </Box>
  </Box>
);

const ActionsAndStats = ({
  openAddLicenseForm,
  employeeCount,
  nonCompliantCount,
  nonCompliantLabel,
  expiringSoonCount,
  expiringSoonLabel,
  addButtonLabel,
  secondAddButtonLabel,
  icon,
  addEntityButtonLink,
}) => {
  const history = useHistory();

  return (
    <Box height="100%">
      <Grid container spacing={3} justify="center" style={{ height: "100%" }}>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            height="100%"
          >
            <ActionButton
              startIcon={<AddIcon />}
              onClick={() => history.push(addEntityButtonLink)}
            >
              {addButtonLabel}
            </ActionButton>
            {secondAddButtonLabel && (
              <Box mt={2}>
                <ActionButton
                  startIcon={<AddIcon />}
                  onClick={openAddLicenseForm}
                >
                  {secondAddButtonLabel}
                </ActionButton>
              </Box>
            )}
          </Box>
        </Grid>

        <Grid item xs={6}>
          <InfoCard
            icon={icon || <PeopleIcon fontSize="large" color="primary" />}
            number={`${employeeCount - nonCompliantCount} / ${employeeCount}`}
            text={nonCompliantLabel}
          />
        </Grid>
        <Grid item xs={6}>
          <InfoCard
            icon={
              <ComplianceBadge
                fontSize="large"
                $isCompliant={expiringSoonCount === 0}
              />
            }
            number={expiringSoonCount}
            text={expiringSoonLabel}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ActionsAndStats;
