import { fieldTypes } from "componentGenerators/formGenerator/config";
import { NumberFormatTypes } from "components/Formik/fields/FormikNumberField";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Tax Information",
  },
  {
    type: fieldTypes.yesNo,
    label: `Since last application, have you been subject to a federal U.S. income tax audit (or foreign equivalent)?`,
    name: "tennessee_phdrf.has_taxes_been_audited",
  },
  {
    type: fieldTypes.yesNo,
    label: `Do you currently have any tax obligations, fines, court orders, legal obligations, or judgments outstanding, owed, or past due?`,
    name: "tennessee_phdrf.has_tax_obligations",
  },
  {
    type: fieldTypes.label,
    label: `If you answered 'Yes' to the above question, please enter the following informataion`,
  },
  {
    type: fieldTypes.text,
    label: "Nature of Tax Obligation",
    name: "tennessee_phdrf.tax_obligation_type",
  },
  {
    type: fieldTypes.number,
    format: NumberFormatTypes.dollar,
    label: "Amount or Action Owed or Due",
    name: "tennessee_phdrf.tax_obligation_amount_due",
  },
  {
    type: fieldTypes.date,
    label: "Date of Disposition",
    name: "tennessee_phdrf.tax_obligation_disposition_date",
  },
  {
    type: fieldTypes.text,
    label: "Reason for Status ",
    name: "tennessee_phdrf.tax_obligation_reason",
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "tennessee_phdrf_supplement_tax_information_pdSupplement",
};
