import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Law",
  },
  {
    type: fieldTypes.label,
    label:
      "If Applicant or any of its Substantial Owners is a Business Entity other than a corporation, for each Business Entity that is not organized under Indiana law, provide below a statement identifying the law under which it is organized and stating whether it is authorized to conduct business in Indiana:",
  },
  {
    type: fieldTypes.text,
    label: "Statement",
    name: "indiana_supplier_license.organized_law_explanation",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.subHeader,
    label: "Workforce",
  },
  {
    type: fieldTypes.label,
    label:
      "Provide below a detailed statement describing the methodology to be used to ensure that the entire work force to be employed by Applicant in connection with the provision of its products will be in accordance with the requirements of pertinent federal and Indiana equal employment opportunity laws:",
  },
  {
    type: fieldTypes.text,
    label: "Statement",
    name: "indiana_supplier_license.workforce_methodology",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.subHeader,
    label: "Customers",
  },
  {
    type: fieldTypes.label,
    label:
      "If a material part of Applicant’s business is dependent upon five (5) or fewer customers, for each customer list Its name, business address, and telephone number and	Its relationship with and its importance to Applicant’s business:",
  },
  {
    type: fieldTypes.text,
    label: "Statement",
    name: "indiana_supplier_license.customer_dependent_details",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.subHeader,
    label: "Backlog",
  },
  {
    type: fieldTypes.label,
    label:
      "If backlog is a factor affecting Applicant’s business operations the dollar amount of backlog orders believed to be firm as of a date during the current fiscal year and as of comparable dates during the preceding two (2) fiscal years The portion of current backlog orders not reasonably expected to be filled within the current fiscal year and (3) the seasonal or other material aspects of the backlog:",
  },
  {
    type: fieldTypes.text,
    label: "Statement",
    name: "indiana_supplier_license.backlog_affected_details",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.subHeader,
    label: "primary products and materials",
  },
  {
    type: fieldTypes.label,
    label:
      "Provide below the sources and availability of primary products and materials essential to Applicant’s business:",
  },
  {
    type: fieldTypes.text,
    label: "Statement",
    name: "indiana_supplier_license.essential_details",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.subHeader,
    label: "Intellectual property",
  },
  {
    type: fieldTypes.label,
    label:
      "Provide below the duration, importance, effect, and holders of all patents, trademarks, copyrights, licenses, franchises and concessions that are material to Applicant’s business:",
  },
  {
    type: fieldTypes.text,
    label: "Statement",
    name: "indiana_supplier_license.asset_material_details",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.subHeader,
    label: "bankruptcy and receivership",
  },
  {
    type: fieldTypes.label,
    label:
      "Provide below the circumstances surrounding and results of bankruptcy, receivership, or similar proceedings affecting Applicant’s business that were filed by a person other than the Applicant:",
  },
  {
    type: fieldTypes.text,
    label: "Statement",
    name: "indiana_supplier_license.bankrupcty_circumstances_details",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.subHeader,
    label: "reorganization",
  },
  {
    type: fieldTypes.label,
    label:
      "Provide below the circumstances surrounding and the effect of a material reorganization, merger, consolidation, readjustment, or succession of Applicant’s business:",
  },
  {
    type: fieldTypes.text,
    label: "Statement",
    name: "indiana_supplier_license.reorganization_circumstances_details",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.subHeader,
    label: "assets",
  },
  {
    type: fieldTypes.label,
    label:
      "Provide below the effect of acquisition or disposition of a material amount of assets other than in the ordinary course of Applicant’s business during the past three (3) fiscal years:",
  },
  {
    type: fieldTypes.text,
    label: "Statement",
    name: "indiana_supplier_license.acquisition_effect_details",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.subHeader,
    label: "Material changes",
  },
  {
    type: fieldTypes.label,
    label:
      "Provide below the effect of material changes in the mode of conducting Applicant’s business during the past three (3) fiscal years:",
  },
  {
    type: fieldTypes.text,
    label: "Statement",
    name: "indiana_supplier_license.material_change_effect_details",
    rows: 4,
    xs: 12,
  },
];

export default [
  {
    type: "form",
    formSchema,
    endpoint: "bdSupplement",
  },
];
