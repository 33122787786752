export function withPreventDefault(fn) {
  return (event) => {
    event.preventDefault();
    fn(event);
  };
}

export function withStopPropagation(fn) {
  return (event) => {
    event.stopPropagation();
    fn(event);
  };
}
