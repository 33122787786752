import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import MontanaPersonalCriminalHistoryStatementForm from "./MontanaPersonalCriminalHistoryStatementForm";
import MultiFormWrapper from "components/Layout/SectionsLayout/MultiFormWrapper";
import useFetch from "queryHooks/useFetch";
import { Box } from "@material-ui/core";
import DisplayTable from "components/Common/Tables/DisplayTable";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const MontanaPersonalCriminalHistoryStatement = ({ name }) => {
  const { data = [], status } = useFetch(
    "montana_personal_criminal_history_statement_criminalLegalHistory"
  );
  return (
    <FormSectionWrapper title={name}>
      <MultiFormWrapper>
        <MontanaPersonalCriminalHistoryStatementForm />
      </MultiFormWrapper>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={status}>
          <DisplayTable
            records={data}
            linkTo={["arrests"]}
            columns={[
              {
                title: "Type",
                field: "type",
                format: "startCase",
              },
              {
                title: "Agency Name",
                field: "law_enforcement_pd_business_entity.business_name",
              },
              {
                title: "Case Number",
                field: "case_number",
              },
              {
                title: "Disposition",
                field: "disposition",
                format: "startCase",
              },
              {
                title: "Disposition Date",
                field: "disposition_date",
                format: "date",
              },
            ]}
            dependsOnQueryCount="Did you have any changes to your Financial History in the last two years? (Bankruptcy, Plaintiff/Defendant in civil suit)"
          />
        </LoadingComponent>
      </Box>
    </FormSectionWrapper>
  );
};

export default MontanaPersonalCriminalHistoryStatement;
