import { getFormComponents } from "componentGenerators/helpers";
import virginia_pea_supplement_disclosure_questions from "components/Pages/Supplemental/PD/VirginiaPEA/VirginiaPEADisclosureQuestions/VirginiaPEADisclosureQuestions";
import virginia_pea_supplement_attachments from "components/Pages/Supplemental/PD/VirginiaPEA/VirginiaPEAAttachments/VirginiaPEAAttachments";
import virginia_pea_supplement_criminal_history from "components/Pages/Supplemental/PD/VirginiaPEA/VirginiaPEACriminalHistory/VirginiaPEACriminalHistory";
import virginia_pea_supplement_referral_fees from "components/Pages/Supplemental/PD/VirginiaPEA/VirginiaPEAReferralFees/VirginiaPEAReferralFees";

export default getFormComponents({
  virginia_pea_supplement_disclosure_questions,
  virginia_pea_supplement_attachments,
  virginia_pea_supplement_criminal_history,
  virginia_pea_supplement_referral_fees,
});
