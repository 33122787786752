import { getFormComponents } from "componentGenerators/helpers";
import louisiana_phfr_supplement_accountants from "components/Pages/Supplemental/PD/LouisianaPHFR/LouisianaPHFRAccountants/LouisianaPHFRAccountants";
import louisiana_phfr_supplement_attachments from "components/Pages/Supplemental/PD/LouisianaPHFR/LouisianaPHFRAttachments/LouisianaPHFRAttachments";
import louisiana_phfr_supplement_criminal_history from "components/Pages/Supplemental/PD/LouisianaPHFR/LouisianaPHFRCriminalHistory/LouisianaPHFRCriminalHistory";
import louisiana_phfr_supplement_disclosure_questions from "components/Pages/Supplemental/PD/LouisianaPHFR/LouisianaPHFRDisclosureQuestions/LouisianaPHFRDisclosureQuestions";
import louisiana_phfr_supplement_financial from "components/Pages/Supplemental/PD/LouisianaPHFR/LouisianaPHFRFinancial/LouisianaPHFRFinancial";
import louisiana_phfr_supplement_licensing_information from "components/Pages/Supplemental/PD/LouisianaPHFR/LouisianaPHFRLicensingInformation/LouisianaPHFRLicensingInformation";
import louisiana_phfr_supplement_ownership_information from "components/Pages/Supplemental/PD/LouisianaPHFR/LouisianaPHFROwnershipInformation/LouisianaPHFROwnershipInformation";
import louisiana_phfr_supplement_political_activity from "components/Pages/Supplemental/PD/LouisianaPHFR/LouisianaPHFRPoliticalActivity/LouisianaPHFRPoliticalActivity";

export default getFormComponents({
  louisiana_phfr_supplement_ownership_information,
  louisiana_phfr_supplement_financial,
  louisiana_phfr_supplement_accountants,
  louisiana_phfr_supplement_disclosure_questions,
  louisiana_phfr_supplement_attachments,
  louisiana_phfr_supplement_criminal_history,
  louisiana_phfr_supplement_licensing_information,
  louisiana_phfr_supplement_political_activity,
});
