import React from "react";
import { formats } from "components/Common/Tables/Common/helpers";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import { arrests } from "components/Pages/PD/uiStrings";

const arrestsChargesColumns = [
  {
    title: "Charge Date",
    field: "charge_date",
    format: formats.date,
  },
  {
    title: "Nature",
    field: "nature",
  },
  {
    title: "Agency Name",
    field: "law_enforcement_pd_business_entity.business_name",
  },
  {
    title: "Disposition",
    field: "disposition",
  },
];

const arrestsChargesFormSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Nature of Charge",
  },
  {
    name: "charge_date",
    type: fieldTypes.date,
    label: "Date of Charge",
    required: true,
  },
  {
    type: fieldTypes.text,
    label: "Case Number",
    name: "case_number",
    required: true,
  },
  {
    type: fieldTypes.text,
    label: "Nature of Charge",
    name: "nature",
    required: true,
    xs: 12,
    rows: 4,
  },
  {
    type: fieldTypes.subHeader,
    divider: true,
    label: "Incident Location",
  },
  {
    type: fieldTypes.text,
    label: "Incident City",
    name: "incident_city",
    required: true,
  },
  {
    type: fieldTypes.text,
    label: "Incident County",
    name: "incident_county",
    required: true,
  },
  {
    type: fieldTypes.text,
    label: "Incident State",
    name: "incident_state",
    required: true,
  },
  {
    type: fieldTypes.subHeader,
    label: "Law Enforcement or Court",
    divider: true,
  },
  {
    type: fieldTypes.pdBusinessSelect,
    endpoint: "law_business_entities",
    label: "Law Enforcement or Court",
    getOptionLabel: (option) => option.business_name,
    getOptionValue: (option) => option.id,
    xs: 8,
    required: true,
    name: "law_enforcement_pd_business_entity_id",
  },
  {
    type: fieldTypes.subHeader,
    label: "Outcome",
    divider: true,
  },
  {
    name: "disposition",
    type: fieldTypes.select,
    dynamicMenuItems: ["criminalDispositionType"],
    required: true,
  },
  {
    name: "disposition_date",
    type: fieldTypes.date,
    label: "Disposition Date",
    required: true,
  },
  {
    label: "Charge Type",
    name: "charge_type",
    type: fieldTypes.select,
    dynamicMenuItems: ["chargeType"],
    required: true,
  },
  {
    type: fieldTypes.text,
    label: "Sentence",
    name: "sentence",
    xs: 12,
    rows: 4,
  },
  {
    type: fieldTypes.text,
    label: "Length of Incarceration",
    name: "incarceration_length",
  },
  {
    type: fieldTypes.text,
    label: "Location of Incarceration",
    name: "incarceration_location",
    xs: 8,
  },
  {
    type: fieldTypes.subHeader,
    label: "Documentation",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: "Attach a narrative of the event:",
  },
  {
    type: fieldTypes.dropZone,
    label: "Arrests & Charges",
    name: "attachment_group_id",
    xs: 12,
    rows: 4,
  },
];

const arrestsChargesSchema = {
  type: "table",
  endpoint:
    "michigan_isbigo_supplement_arrests_charges_criminalLegalHistoryApplicant",
  formSchema: arrestsChargesFormSchema,
  columns: arrestsChargesColumns,
  infoAlerts: [
    {
      body: <>{arrests.tableQuestion}</>,
    },
  ],

  modifySubmissionValues: (formValues) => ({
    ...formValues,
    type: "criminal",
  }),
};

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Diversionary Program",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Have you ever been placed on a diversionary program to avoid criminal arrest or conviction?",
    name: "michigan_isbigo.has_diversionary_program",
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label:
      "If you answered yes, please describe the circumstances, outcome, and efforts being made to pay back any debt incurred.",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "michigan_isbigo.diversionary_program_details",
    xs: 12,
    rows: 4,
  },
  {
    type: fieldTypes.label,
    label: "Upload below any court or repayment documentation:",
  },
  {
    type: fieldTypes.dropZone,
    name: "michigan_isbigo.diversionary_program_attachment_id",
    description: "Diversionary Program Documentation",
    label: "Upload Attachment",
    xs: 12,
  },
  {
    type: fieldTypes.subHeader,
    label: "Unformal Criminal Charges",
    divider: true,
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Do you have any arrests, which did not result in a formal criminal charge?",
    name: "michigan_isbigo.has_no_formal_arrest",
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label: "If please describe below:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "michigan_isbigo.no_formal_arrest_details",
    xs: 12,
    rows: 4,
  },
  {
    type: fieldTypes.label,
    label: "Upload below any court or police agency documentation:",
  },
  {
    type: fieldTypes.dropZone,
    name: "michigan_isbigo.no_formal_arrest_attachment_id",
    description: "No Formal Arrests Documentation",
    label: "Upload Attachment",
    xs: 12,
  },
  {
    type: fieldTypes.subHeader,
    label: "Additional Questions",
    divider: true,
  },
  {
    type: fieldTypes.yesNo,
    label: " Have you been arrested or detained?",
    name: "michigan_isbigo.has_arrests_detained",
    xs: 12,
  },
  {
    type: fieldTypes.yesNo,
    label: "Have you been indicted or charged?",
    name: "michigan_isbigo.has_indictment_charged",
    xs: 12,
  },
  {
    type: fieldTypes.yesNo,
    label: "Have you pleaded guilty?",
    name: "michigan_isbigo.has_pleaded_guilty",
    xs: 12,
  },
  {
    type: fieldTypes.yesNo,
    label: "Have you pled no contest?",
    name: "michigan_isbigo.has_no_contest",
    xs: 12,
  },
  {
    type: fieldTypes.yesNo,
    label: "Have you forfeited bail?",
    name: "michigan_isbigo.has_forfeited_bail",
    xs: 12,
  },
  {
    type: fieldTypes.yesNo,
    label: "Have you ever been granted immunity?",
    name: "michigan_isbigo.has_granted_immunity",
    xs: 12,
  },
];

export default [
  arrestsChargesSchema,
  {
    type: "form",
    formSchema,
    endpoint: "michigan_isbigo_supplement_arrests_charges_pdSupplement",
  },
];
