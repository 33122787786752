export const tableQuestions = null;

export const defaultInitialValues = {
  bd_pennsylvania_eea: {
    pg2_financial_compliance_boolean: null,
    is_registered_or_authorized_in_pa: null,
    has_outstanding_tax: null,
    has_caused_political_committee: null,
    has_made_independent_expenditures: null,
    outstanding_tax_details: "",
    registered_or_authorized_states: "",
    bd_pa_eea_contact_bd_person_id: null,
    bd_pa_eea_billing_contact_bd_person_id: null,
    q1a_boolean: null,
    q1b_boolean: null,
    right_share_holders_modified_details: "",
  },
};

export const endpoint = "bdSupplement";

export const tabularDataParams = {
  defaultInitialValues,
  endpoint,
};
