import React from "react";

import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { SectionLabel } from "components/Common/LayoutComponents";
import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";

import useForm from "hooks/useForm";

const IllinoisNetWorthStatement = ({ name }) => {
  const formData = useForm({
    noValidate: true,
    defaultInitialValues: {
      il_net_worth_statement_attachment_group_id: null,
    },
    endpoint: "illinois_net_worth_statement_pdSupplement",
  });
  return (
    <FormSectionWrapper title={name}>
      <FormContextProvider formData={formData}>
        <EnhancedFormikPage>
          <SectionLabel
            label={
              <>
                Submit as Exhibit 43, a current Net Worth Summary, and
                Schedules, in the Excel format provided on the IGB website. Net
                Worth information for each Key Person is generally required on a
                calendar year basis. However, if the Key Person is funding the
                Licensee via contributions or other forms of financing, a more
                current net worth statement may be required. Please refer to the
                ‘Period of Submission Reference Chart’, located on the{" "}
                <a href="http://www.igb.illinois.gov/riverboatforms.aspx">
                  IGB website
                </a>
                , for specific date requirements. Using a date more current than
                listed in the chart is always allowed.
                <ul type="a">
                  <li>
                    When preparing the net worth schedules, please complete
                    schedules ‘A’ through ‘O’ first. The totals on these
                    schedules will automatically carry to the Summary schedule.
                  </li>
                  <li>
                    Please note the details requested within the Net Worth
                    schedules. Failure to timely submit all requested
                    information may subject the Licensee to disciplinary action
                    and/or impact final action for licensure by the IGB.
                  </li>
                  <li>
                    For your convenience, you can retrieve the Net Worth Summary
                    and Schedules document in an excel format clicking the
                    following link:{" "}
                    <a href="https://www.igb.illinois.gov/FilesVideoForms/NetWorthTemplate.xlsx">
                      Net Worth Template
                    </a>
                  </li>
                </ul>
              </>
            }
          />
          <FormikFileDropzone
            description="Illinois Net Worth Statement"
            name="il_net_worth_statement_attachment_group_id"
            size={12}
          />
        </EnhancedFormikPage>
      </FormContextProvider>
    </FormSectionWrapper>
  );
};

export default IllinoisNetWorthStatement;
