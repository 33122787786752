import React from "react";
import { Grid } from "@material-ui/core";
import useTabularData from "hooks/tableHooks/useTabularData";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import { useMenuOptions } from "hooks/useMenuOptions";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import {
  columns,
  defaultInitialValues,
  endpoint,
  tableQuestions,
} from "./config.js";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone.jsx";
import BDLawEnforcementSelect from "components/Formik/formGroups/CrudSelect/selects/BDLawEnforcementSelect.jsx";
import BDBusinessFamilySelect from "components/Formik/formGroups/CrudSelect/selects/BDBusinessFamilySelect";
import BDPartySelect from "components/Formik/formGroups/CrudSelect/selects/BDPartySelect";

const LitigationsForm = () => {
  const { litigationStatus, roleInDispute } = useMenuOptions();
  return (
    <Grid container spacing={2}>
      <BDBusinessFamilySelect required />

      <FormikSelect
        label="Your role in the dispute"
        name="role_in_dispute"
        options={roleInDispute}
        xs={6}
        required
      />

      <BDPartySelect
        name="bd_related_party_id"
        label="Related Party to the Litigation"
        header="Related Party to the Litigation"
        required
      />

      <BDLawEnforcementSelect required />

      <SectionSubHeader label="Details" divider />
      <FormikSelect
        label="Status"
        name="litigation_status"
        options={litigationStatus}
        xs={4}
      />
      <FormikTextField label="Case Title" name="case_name" xs={4} />
      <FormikTextField label="Docket Number" name="docket_number" xs={4} />
      <FormikDatePicker label="Filing Date" name="filing_date" xs={4} />
      <FormikDatePicker
        label="Date of Judgement"
        name="disposition_date"
        xs={4}
      />
      <FormikTextField
        label="Nature of Charge or Complaint"
        name="nature_of_charge"
        xs={12}
      />
      <SectionLabel label="Attach below all related documentation to the litigation." />
      <FormikFileDropzone
        description="BD Litigations"
        name="bd_legal_history_attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};

const Litigations = ({ name }) => {
  const formData = useTabularData({ defaultInitialValues, endpoint });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <LitigationsForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
    </FormSectionProvider>
  );
};

export default Litigations;
