//COLORADO
import ColoradoDisclosureQuestions from "components/Pages/Supplemental/PD/ColoradoRider/ColoradoDisclosureQuestions/ColoradoDisclosureQuestions";
import ColoradoEliminatoryQuestions from "components/Pages/Supplemental/PD/ColoradoRider/ColoradoEliminatoryQuestions/ColoradoEliminatoryQuestions";
import ColoradoFinancialHistory from "components/Pages/Supplemental/PD/ColoradoRider/ColoradoFinancialHistory/ColoradoFinancialHistory";
import ColoradoPersonalFinancialHistory from "components/Pages/Supplemental/PD/ColoradoRider/ColoradoPersonalFinancialHistory/ColoradoPersonalFinancialHistory";
import ColoradoCriminalHistory from "components/Pages/Supplemental/PD/ColoradoRider/ColoradoCriminalHistory/ColoradoCriminalHistory";
import ColoradoBusinessInvestments from "components/Pages/Supplemental/PD/ColoradoRider/ColoradoBusinessInvestments/ColoradoBusinessInvestments";
import AnnualIncome from "components/Pages/PD/Financial/AnnualIncome";
/* eslint-disable react/display-name */
import React from "react";

const colorado_pages = {
  colorado_rider_disclosure_questions: ColoradoDisclosureQuestions,
  colorado_rider_eliminatory_questions: ColoradoEliminatoryQuestions,
  colorado_rider_financial_history: ColoradoFinancialHistory,
  colorado_rider_personal_information: ColoradoPersonalFinancialHistory,
  colorado_rider_annual_income: (props) => (
    <AnnualIncome
      {...props}
      jurisdiction="colorado"
      endpoint="colorado_rider_annual_income_"
    />
  ),
  colorado_rider_criminal_history: ColoradoCriminalHistory,
  colorado_rider_business_investments: ColoradoBusinessInvestments,
};

export default colorado_pages;
