import { Box } from "@material-ui/core";
import React from "react";
import styled, { css } from "styled-components";

const StyledBox = styled(Box)`
  ${({ $isFullPage }) =>
    $isFullPage &&
    css`
      min-height: calc(
        100vh - 110px
      ); //full height minus header height (approx)
    `}
`;

const Center = ({ children, isFullPage, ...rest }) => (
  <StyledBox
    $isFullPage={isFullPage}
    display="flex"
    alignItems="center"
    justifyContent="center"
    height="100%"
    width={1}
    {...rest}
  >
    {children}
  </StyledBox>
);

export default Center;
