import React from "react";

import { SectionLabel } from "components/Common/LayoutComponents/";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import useForm from "hooks/useForm.js";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import { Grid } from "@material-ui/core";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import { defaultInitialValues, endpoint } from "./config";

const Form = () => {
  return (
    <EnhancedFormikPage>
      <Grid container spacing={2}>
        <SectionLabel label="Provide the following information about your compensation" />
        <SectionLabel label="The total amount of compensation you earned last year:" />
        <FormikNumberField
          label="Total Compensation"
          name="new_mexico.last_year_total_compensation"
          xs={4}
        />

        <SectionLabel label="The total amount of compensation you expected to earn this year:" />
        <FormikNumberField
          label="Total Expected Compensation"
          name="new_mexico.current_year_expected_compensation"
          xs={4}
        />

        <SectionLabel label="The structure of your compensation (i.e., salary, wages, bonus, fees, commissions, options, etc.)" />
        <FormikTextField
          multiline
          xs={12}
          rows={4}
          label="Compensation Structure"
          name="new_mexico.compensation_structure_explanation"
        />
      </Grid>
    </EnhancedFormikPage>
  );
};

const NewMexicoCompensation = ({ title }) => {
  const formData = useForm({
    defaultInitialValues: defaultInitialValues,
    endpoint: endpoint,
  });

  return (
    <FormSectionWrapper title={title}>
      <FormContextProvider formData={formData}>
        <Form />
      </FormContextProvider>
    </FormSectionWrapper>
  );
};

export default NewMexicoCompensation;
