import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Box,
  Button,
} from "@material-ui/core";
import Center from "components/flex/Center";
import React from "react";
import DropZone from "../../../Common/Dropzone/DropZone";
import { CSVLink } from "react-csv";
import CSVData from "./config";
import styled from "styled-components";

const StyledCard = styled(Card)`
  & .MuiCardHeader-root {
    padding: 16px 24px 0px 24px;
  }
`;

const EmployeeFileUpload = () => {
  return (
    <>
      <Container>
        <Center height="75vh">
          <StyledCard variant="outlined">
            <CardHeader
              title="Import Employee Records"
              subheader={
                <Box>
                  Your uploaded files should be .CSV format
                  <Box pt={1}>
                    <Box textAlign="right">
                      <CSVLink
                        data={CSVData}
                        filename="OneComply-Import-Sample-Template"
                      >
                        <Button
                          color="primary"
                          size="small"
                          variant="contained"
                        >
                          Sample Template
                        </Button>
                      </CSVLink>
                    </Box>
                  </Box>
                </Box>
              }
              titleTypographyProps={{ variant: "h3", gutterBottom: true }}
              subheaderTypographyProps={{
                variant: "subtitle2",
                color: "textSecondary",
              }}
            />
            <CardContent>
              <DropZone
                endpoint="licensing/lcUploader"
                compact={false}
                accept=".csv"
              />
            </CardContent>
          </StyledCard>
        </Center>
      </Container>
    </>
  );
};

export default EmployeeFileUpload;
