import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import YesNo from "components/Formik/formGroups/YesNo";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <FormikDatePicker label="Date" name="exchange_date" xs={6} />
      <FormikTextField
        label="Location where Exchange Made"
        name="exchange_location"
        xs={6}
      />
      <FormikNumberField label="Amount of Exchange" name="amount" md={6} />
      <FormikTextField label="Reason for Exchange" name="reason" xs={6} />
      <YesNo
        label={"Did you file a government report?"}
        name="was_report_filed"
        xs={12}
      />
    </Grid>
  );
};

const CurrencyExchanges = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "currency_exchanges_currencyExchange",
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default CurrencyExchanges;
