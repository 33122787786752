import React from "react";
import useFetch from "queryHooks/useFetch";
import { Box } from "@material-ui/core";
import DisplayTable from "components/Common/Tables/DisplayTable";
import { endpoint, columns, tableQuestions } from "./config";
import {
  SectionSubHeader,
  SectionLabel,
} from "components/Common/LayoutComponents";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import IowaUniformCommercialCodeFiling from "./IowaUniformCommercialCodeFiling";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const IowaClassDDocuments = ({ name }) => {
  const { data, status } = useFetch(endpoint, endpoint);

  return (
    <FormSectionProvider title={name}>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={status}>
          <DisplayTable
            linkTo={["bd_financial_statements"]}
            records={data}
            columns={columns}
            headerComponent={
              <>
                <SectionSubHeader>
                  Annual Reports & SEC filings
                </SectionSubHeader>
                <SectionLabel>{tableQuestions}</SectionLabel>
              </>
            }
          />
        </LoadingComponent>
      </Box>
      <IowaUniformCommercialCodeFiling />
    </FormSectionProvider>
  );
};

export default IowaClassDDocuments;
