import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import FormikAsyncSelect from "components/Formik/fields/FormikAsyncSelect";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import { useMenuOptions } from "hooks/useMenuOptions";
import React from "react";
import {
  columns,
  defaultInitialValues,
  endpoint,
  tableQuestions,
} from "./config.js";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import { InfoAlert } from "components/Layout/Alert/InfoAlert.jsx";

const FiduciaryDenials = () => {
  const { employmentDisciplineType } = useMenuOptions();

  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Position" />
      <Grid item xs={12}>
        <FormikAsyncSelect
          name="office_position_id"
          endpoint="fiduciary_positions_trustOfficePosition"
          label="Select Position"
          getOptionLabel={({ pd_business_entity }) =>
            pd_business_entity.business_name
          }
          getOptionValue={({ id }) => id}
          xs={6}
        />
        <Link component={RouterLink} to="fiduciary_positions">
          Add New Position
        </Link>{" "}
        (you must have a position to add a denial)
      </Grid>

      <SectionSubHeader label="Details" divider />
      <FormikDatePicker name="discipline_date" />

      <FormikSelect
        name="discipline_type"
        options={employmentDisciplineType}
        xs={4}
      />

      <FormikTextField name="discipline_reason" xs={8} multiline rows={3} />
    </Grid>
  );
};

const OtherRelatives = ({ name }) => {
  const formData = useTabularData({ defaultInitialValues, endpoint });

  return (
    <FormSectionProvider formData={formData} title={name}>
      <EnhancedFormikDialog title={name}>
        <FiduciaryDenials />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        subheader={<InfoAlert>{tableQuestions}</InfoAlert>}
        columns={columns}
        addButtonText="Add Record"
      />
    </FormSectionProvider>
  );
};

export default OtherRelatives;
