import { bankruptcies } from "../../uiStrings";

export const columns = [
  {
    field: "filing_date",
  },
  {
    field: "case_number",
  },
  {
    title: "Court",
    field: "law_enforcement_pd_business_entity.business_name",
  },
  {
    title: "Trustee",
    field: "trustee_pd_business_entity.business_name",
  },
  {
    title: "Filing Business",
    field: "pd_business_entity.business_name",
  },
  {
    title: "Nature & Reason",
    field: "nature",
  },
];

export const tableQuestions = bankruptcies.tableQuestion;

export const nonRequired = ["pd_business_entity_id", "nature"];

export const defaultInitialValues = {
  type: "bankruptcy",
  filing_date: null,
  case_number: "",
  disposition: "",
  nature: "",
  law_enforcement_pd_business_entity_id: null,
  trustee_pd_business_entity_id: null,
  pd_business_entity_id: null,
  attachment_group_id: null,
};
