import React from "react";
import { formats } from "components/Common/Tables/Common/helpers";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import { arrests } from "components/Pages/PD/uiStrings";

const arrestsChargesColumns = [
  {
    title: "Charge Date",
    field: "charge_date",
    format: formats.date,
  },
  {
    title: "Nature",
    field: "nature",
  },
  {
    title: "Agency Name",
    field: "law_enforcement_pd_business_entity.business_name",
  },
  {
    title: "Disposition",
    field: "disposition",
  },
];

const arrestsChargesFormSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Nature of Charge",
  },
  {
    name: "charge_date",
    type: fieldTypes.date,
    label: "Date of Charge",
    xs: 4,
    required: true,
  },
  {
    type: fieldTypes.text,
    label: "Case Number",
    name: "case_number",
    required: true,
  },
  {
    type: fieldTypes.text,
    label: "Nature of Charge",
    name: "nature",
    required: true,
    xs: 12,
    rows: 4,
  },
  {
    type: fieldTypes.subHeader,
    divider: true,
    label: "Incident Location",
  },
  {
    type: fieldTypes.text,
    label: "Incident City",
    name: "incident_city",
    required: true,
  },
  {
    type: fieldTypes.text,
    label: "Incident County",
    name: "incident_county",
    required: true,
  },
  {
    type: fieldTypes.text,
    label: "Incident State",
    name: "incident_state",
    required: true,
  },
  {
    type: fieldTypes.subHeader,
    label: "Law Enforcement or Court",
    divider: true,
  },
  {
    type: fieldTypes.asyncSelect,
    endpoint:
      "colorado_gsbsla_supplement_arrests_charges_law_business_entities",
    label: "Law Enforcement or Court",
    getOptionLabel: (option) => option.business_name,
    getOptionValue: (option) => option.id,
    xs: 8,
    required: true,
    name: "law_enforcement_pd_business_entity_id",
  },
  {
    type: fieldTypes.subHeader,
    label: "Outcome",
    divider: true,
  },
  {
    name: "disposition",
    type: fieldTypes.select,
    dynamicMenuItems: ["criminalDispositionType"],
    required: true,
  },
  {
    name: "disposition_date",
    type: fieldTypes.date,
    label: "Disposition Date",
    xs: 4,
    required: true,
  },
  {
    label: "Charge Type",
    name: "charge_type",
    type: fieldTypes.select,
    dynamicMenuItems: ["chargeType"],
    required: true,
  },
  {
    type: fieldTypes.text,
    label: "Sentence",
    name: "sentence",
    xs: 12,
    rows: 4,
  },
  {
    type: fieldTypes.text,
    label: "Length of Incarceration",
    name: "incarceration_length",
  },
  {
    type: fieldTypes.text,
    label: "Location of Incarceration",
    name: "incarceration_location",
    xs: 8,
  },
  {
    type: fieldTypes.subHeader,
    label: "Documentation",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: "Attach a narrative of the event:",
  },
  {
    type: fieldTypes.dropZone,
    label: "Arrests & Charges",
    name: "attachment_group_id",
    xs: 12,
    rows: 4,
  },
];

const arrestsChargesSchema = {
  type: "table",
  endpoint:
    "colorado_gsbsla_supplement_arrests_charges_criminalLegalHistoryApplicant",
  formSchema: arrestsChargesFormSchema,
  columns: arrestsChargesColumns,
  infoAlerts: [
    {
      body: <>{arrests.tableQuestion}</>,
    },
  ],

  modifySubmissionValues: (formValues) => ({
    ...formValues,
    type: "criminal",
  }),
};

export default [arrestsChargesSchema];
