import * as Sentry from "@sentry/react";

export const captureSentryError = (err) => {
  const status = err?.response?.status;

  // We don't throw sentry alerts if 401 Unauthorized
  if (status === 401) return;

  Sentry.captureException(err);
};
