import React from "react";
import { SectionLabel, SectionSubHeader } from "../LayoutComponents";
import PropTypes from "prop-types";
import FormikAsyncSelect from "components/Formik/fields/FormikAsyncSelect";
import BusinessRedirects from "./BusinessRedirects";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import usePermissionPath from "hooks/usePermissionPath";

const StyledGrid = styled(Grid)`
  font-size: 1.2em;
`;

function interpolatePercent(record, cur, i) {
  return ` ${i !== 0 ? "(" : ""} ${record[cur] || ""} ${
    i !== 0
      ? `${cur === "ownership_percent_pretty" && record[cur] ? "% )" : ")"}`
      : ""
  }`;
}

function customGetOptionLabel(record, options) {
  if (options && Array.isArray(options)) {
    return options.reduce((prev, cur, i) => {
      return prev + interpolatePercent(record, cur, i);
    }, ``);
  }
}

const BusinessAssociation = ({
  title = "Applicant Business Entity",
  label = "Business Entity (Your Association)",
  header,
  links = null,
  name,
  endpoint = "associated_business_entities_a_grouped",
  options = ["business_name", "association"],
  usesPermission = true,
  xs = 6,
}) => {
  const permissionedEndpoint = usePermissionPath(endpoint);

  return (
    <>
      {title ? <SectionSubHeader>{title}</SectionSubHeader> : null}
      {header ? <SectionLabel label={header}></SectionLabel> : null}
      <StyledGrid item xs={12}>
        {links ? links : <BusinessRedirects />}
      </StyledGrid>
      <FormikAsyncSelect
        label={label}
        name={name}
        endpoint={usesPermission ? permissionedEndpoint : endpoint}
        getOptionLabel={(record) => customGetOptionLabel(record, options)}
        getOptionValue={({ pd_business_entity_id }) => pd_business_entity_id}
        xs={xs}
      />
    </>
  );
};
export default BusinessAssociation;

BusinessAssociation.propTypes = {
  title: PropTypes.string,
  header: PropTypes.string,
  endpoint: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  xs: PropTypes.number,
  links: PropTypes.array,
  options: PropTypes.array,
  usesPermission: PropTypes.bool,
};
