import PropTypes from "prop-types";
import ControlledAutocomplete from "components/Common/ControlledAutocomplete/ControlledAutoComplete";
import { FormikDialog } from "components/Formik/formContainers/FormikDialog.jsx";
import { useCrudSelect } from "components/Formik/formGroups/CrudSelect/hooks/useCrudSelect";
import React from "react";
import useFormGenerator from "componentGenerators/formGenerator/useFormGenerator";
import { fieldTypes as fieldTypesImport } from "componentGenerators/formGenerator/config";
import { Grid } from "@material-ui/core";
import formSchemasImport from "componentGenerators/formGenerator/formSchemas";
import generateFullName from "helpers/generateFullName";
import schemaHelpersImport from "componentGenerators/formGenerator/schemaHelpers";

export const formSchemas = formSchemasImport;
export const fieldTypes = fieldTypesImport;
export const schemaHelpers = schemaHelpersImport;

const getOptionLabelDefault = (option) =>
  option.person_full_name ||
  option.full_name ||
  option.business_name ||
  option.party_name ||
  option.name ||
  option.person_name ||
  generateFullName(option);

const CrudSelect = ({
  name,
  endpoint,
  formSchema,
  formTitle,
  getOptionLabel,
  saveEndpoint,
  getOptionValue,
  xs = 6,
  overrideDefaultValues,
  modifySchema,
  handleAddNewOverride,
  disableEdit = (option) => !option.ext_employee_id,
  modifySubmittedValues = (values) => values,
  editEnabled = true,
  addEnabled,
  isParty,
  ...rest
}) => {
  const modifiedFormSchema = React.useMemo(
    () => (modifySchema ? modifySchema(formSchema) : formSchema),
    [modifySchema, formSchema]
  );

  const { defaultInitialValues, formElements, validationSchema } =
    useFormGenerator({
      formSchema: modifiedFormSchema,
      overrideDefaultValues,
    });

  const { formProps, fieldProps } = useCrudSelect({
    getOptionLabel: getOptionLabel || getOptionLabelDefault,
    name,
    addEnabled,
    endpoint,
    saveEndpoint,
    defaultInitialValues,
    handleAddNewOverride,
    patchValues: modifySubmittedValues,
    postValues: modifySubmittedValues,
    getOptionValue,
  });

  return (
    <>
      <FormikDialog
        title={formTitle}
        {...formProps}
        validationSchema={validationSchema}
        initialValues={
          defaultInitialValues && isParty
            ? defaultInitialValues
            : formProps.initialValues
        }
      >
        <Grid container spacing={1}>
          {formElements}
        </Grid>
      </FormikDialog>
      <ControlledAutocomplete
        {...fieldProps}
        onEditOption={editEnabled && fieldProps.onEditOption}
        xs={xs}
        disableEdit={disableEdit}
        {...rest}
      />
    </>
  );
};

CrudSelect.propTypes = {
  endpoint: PropTypes.string.isRequired,
  formSchema: PropTypes.array.isRequired,
  formTitle: PropTypes.string,
  getOptionLabel: PropTypes.func,
  disableEdit: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  overrideDefaultValues: PropTypes.object,
  saveEndpoint: PropTypes.string,
  xs: PropTypes.number,
  modifySchema: PropTypes.func,
  handleAddNewOverride: PropTypes.func,
  modifySubmittedValues: PropTypes.func,
  editEnabled: PropTypes.bool,
  addEnabled: PropTypes.bool,
  isParty: PropTypes.bool,
  getOptionValue: PropTypes.func,
};

export default CrudSelect;
