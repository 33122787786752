export const columns = [
  {
    title: "Organization Name",
    field: "law_enforcement_pd_business_entity.business_name",
  },
  {
    field: "disposition",
    format: "startCase",
  },
  { field: "nature" },
  { field: "sentence" },
];

export const defaultInitialValues = {
  type: "military_charge",
  charge_date: null,
  law_enforcement_pd_business_entity_id: null,
  disposition: "",
  nature: "",
  sentence: "",
};

export const endpoint = "military_militaryLegalHistory";
