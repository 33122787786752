import { formats } from "components/Common/Tables/Common/helpers";
import {
  fieldTypes,
  formGroupTypes,
} from "componentGenerators/formGenerator/config";

const qualifiersColumns = [
  {
    field: "party_name",
    title: "Name",
  },
  {
    field: "related_bd_party_role",
    title: "Qualifier Role",
    format: formats.startCase,
  },
  {
    field: "from_date",
    title: "From Date",
    format: "date",
  },
  {
    field: "to_date",
    title: "To Date",
    format: "date",
  },
];

const qualifiersFormSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Qualifier",
  },
  {
    type: fieldTypes.bdPartySelect,
    name: "related_bd_party_id",
    label: "Entity",
  },
  {
    type: fieldTypes.select,
    name: "related_bd_party_role",
    dynamicMenuItems: ["bdQualifiersVirginia"],
    label: "Qualifier Role",
    defaultValue: null,
  },
  {
    formGroup: formGroupTypes.fromToCurrent,
  },
];

const qualifiersSchema = {
  type: "table",
  saveEndpoint: "bdPosition",
  endpoint: "views/bd_qualifiers_virginia_a_grouped",
  formSchema: qualifiersFormSchema,
  title: "Virginia's Qualifiers",
  columns: qualifiersColumns,
  infoAlerts: [
    {
      body: `Provide the names of the owners, officers, directors, CEOs, etc. 
      who will be directly/significantly involved in providing goods and/or services. 
      Also, provide the names of those individuals who manage, administer or control the Applicant’s 
      activities such as project managers, field supervisors, account managers, site superintendents, 
      distribution managers, sales supervisors, account representatives etc.`,
    },
  ],

  modifySubmissionValues: (formValues) => ({
    ...formValues,
  }),
};

export default [qualifiersSchema];
