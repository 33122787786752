import PropTypes from "prop-types";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { FormikDialog } from "components/Formik/formContainers/FormikDialog";
import Center from "components/flex/Center";
import React from "react";
import DeleteConfirmation from "components/Common/Dialogs/DeleteConfirmation";
import { LicenseItem } from "components/Pages/LicensingControl/components/DetailsDialog/LicenseItem";
import LicenseForm from "components/Pages/LicensingControl/components/sharedForms/LicenseForm.jsx";

import { licenseTypeCategory } from "components/Pages/LicensingControl/Businesses/config";

export const BusinessLicenses = ({ licensesData }) => {
  return (
    <>
      <DeleteConfirmation />
      <FormikDialog title="License / Certification">
        <Grid container spacing={2}>
          <LicenseForm
            category={licenseTypeCategory}
            description="Personal License Attachment"
          />
        </Grid>
      </FormikDialog>
      <Box pb={2}>
        <Typography variant="h3" gutterBottom>
          Licenses & Certifications
        </Typography>
      </Box>
      {licensesData.length === 0 && <Box p={2} />}
      {licensesData.map(
        ({
          license_type,
          license_agency_bd_business_entity,
          id,
          expiration_date,
          disposition_date,
        }) => {
          const licenseName = license_type || "N/A";
          const agencyName =
            license_agency_bd_business_entity?.business_name || "N/A";

          return (
            <LicenseItem
              key={id}
              licenseType={`${licenseName}`}
              dispositionDate={disposition_date}
              expirationDate={expiration_date}
              agencyName={agencyName}
            />
          );
        }
      )}
      <Center pt={1} pb={5}>
        <Button
          variant="contained"
          startIcon={<AddCircleOutlineIcon />}
          color="primary"
          disabled
        >
          Add New License
        </Button>
      </Center>
    </>
  );
};

BusinessLicenses.propTypes = {
  id: PropTypes.number,
  licensesFormInitiallyOpen: PropTypes.bool,
  licensesData: PropTypes.array,
};
