import React from "react";
import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { defaultInitialValues } from "./config";
import useForm from "hooks/useForm.js";
import { Grid, Box } from "@material-ui/core";
import DisplayTable from "components/Common/Tables/DisplayTable/index.js";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import {
  SectionSubHeader,
  SectionLabel,
} from "components/Common/LayoutComponents";
import useFetch from "queryHooks/useFetch.js";

import YesNo from "components/Formik/formGroups/YesNo";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";
import BusinessAssociation from "components/Common/BusinessAssociation/BusinessAssociation";

const LouisianaDisclosureQuestions = ({ name }) => {
  const formData = useForm({
    defaultInitialValues,
    noValidate: true,
    queryKey: "pdSupplement",
    endpoint: "louisiana_disclosure_questions_pdSupplement",
  });
  const { data = [], status } = useFetch(
    "louisiana_disclosure_questions_criminal_lawsuits"
  );
  return (
    <FormSectionWrapper title={name} formData={formData}>
      <EnhancedFormikPage>
        <Grid container spacing={2}>
          <BusinessAssociation
            title="Associated Gaming Entity"
            header="This application is submitted with the permit or permit application of:"
            label="Company Name (Your Position) (Ownership Percent)"
            name="louisiana.pd_business_entity_id"
            options={[
              "business_name",
              "association",
              "ownership_percent_pretty",
            ]}
          />

          {/* JUN-10 WIll come from CORE UI */}
          {/* <SectionSubHeader label="Spouse Information" divider />
          <SectionLabel label="Current spouse's driver's license number:" /> */}
          {/* <FormikTextField
            label="Driver License Number"
            name="louisiana.spouse_license_number"
            xs={8}
          /> */}
          <SectionSubHeader label="Investigatory Information" divider />
          <YesNo
            label="Is the applicant currently in default on the payment of any student loan?"
            name="louisiana.has_student_loan_default"
            xs={12}
          />
          <SectionLabel label="If Yes, please provide details in an attachment below:" />
          <FormikFileDropzone
            description="Student Loans Default"
            name="la_student_loan_attachment_group_id"
            xs={12}
          />
          <SectionSubHeader label="Legal Information" divider />
          <YesNo
            label="Has the applicant ever had a conviction set aside, a prosecution dismissed under La. C.Cr.P. Article 893 or 894 or
            similar law in any jurisdiction, been discharged or had a proceeding dismissed under La. C.Cr.P. art. 978(B)(3) or
            similar law in any jurisdiction, or had a conviction expunged or received a pardon or executive clemency?"
            name="louisiana.q_e3_bool"
            xs={12}
          />
          <SectionLabel label="If Yes, please provide details including name, title and agency of employment for each agent, member or employee in an attachment below:" />
          <FormikFileDropzone
            description="Legal Information"
            name="la_q_e3_attachment_group_id"
            xs={12}
          />
          <SectionSubHeader label="Relations with Government" divider />
          <YesNo
            label="Do you have any personal or business relationship with any member, agent or
            employee of the Louisiana Gaming Control Board, the Louisiana Department of
            Justice, Office of the Attorney General, or the Louisiana Department of
            Public Safety, Office of the State Police?"
            name="louisiana.q_f3_bool"
            xs={12}
          />
          <SectionLabel label="If Yes, please provide details including name, title and agency of employment for each agent, member or employee in an attachment below:" />
          <FormikFileDropzone
            description="Relationships with Louisiana's government"
            name="la_q_f3_attachment_group_id"
            xs={12}
          />
        </Grid>
      </EnhancedFormikPage>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={status}>
          <DisplayTable
            records={data}
            linkTo={["lawsuits_and_arbitrations"]}
            columns={[
              {
                title: "Person Name",
                field: "relative_or_employee_name",
              },
              {
                title: "Case Number",
                field: "case_number",
              },
              { title: "Other party", field: "other_parties" },
              { title: "Location", field: "action_location" },
            ]}
            dependsOnQueryCount="In the past twenty (20) years, has the applicant or applicant’s spouse, as an individual, trustee, executor, sole proprietor, 
          partner, member, manager or shareholder, director, or officer of a corporation, been party to a lawsuit or an arbitration including matrimonial matters, 
          negligence matters, collection matters, debt matters, etc? "
          />
        </LoadingComponent>
      </Box>
    </FormSectionWrapper>
  );
};

export default LouisianaDisclosureQuestions;
