import { getAddressJson } from "helpers/addressHelpers";
import { get } from "lodash";

import { useFormikContext } from "formik";

export default function useGoogleLookup() {
  const { values, setFieldValue } = useFormikContext();

  function getPlaces(fields, place) {
    const website = place.website || "";
    const phone = place.international_phone_number || "";
    const name = place.name || "";
    const address = getAddressJson(place);
    const state = address["state"];
    const city = address["city"];
    const county = address["county"];

    const lookupValues = {
      website,
      phone,
      name,
      address,
      state,
      city,
      county,
    };

    Object.keys(fields).forEach((key) => {
      if (get(values, fields[key]) === undefined) {
        throw new Error(`make sure the values modified exist`);
      } else {
        setFieldValue(get(fields, key), lookupValues[key]);
      }
    });
  }

  return { getPlaces };
}
