import { useLocation } from "react-router-dom";

const getParam = (pathname) => pathname.split("/")[2];

// ! We are not currently utilizing react-router parameters, if we were this could be done with useParam hook instead of manually parsing the path string
// TODO: Refactor the router so that this logic and hook can be removed

const useFormPath = () => {
  const { pathname } = useLocation();

  return getParam(pathname);
};

export default useFormPath;
