import { fiduciaryDenials } from "../../uiStrings";
import { formats } from "components/Common/Tables/Common/helpers";
export const columns = [
  {
    title: "Business Entity",
    field: "office_position.pd_business_entity.business_name",
  },
  {
    title: "Date",
    field: "discipline_date",
    format: formats.date,
  },
  {
    title: "Capacity",
    field: "office_position.position",
  },
  {
    title: "Action",
    field: "discipline_type",
    format: "startCase",
  },
  {
    title: "Reason",
    field: "discipline_reason",
  },
];

export const tableQuestions = fiduciaryDenials.tableQuestion;

export const defaultInitialValues = {
  office_position_id: null,
  discipline_date: null,
  discipline_reason: "",
  discipline_type: "",
};

export const endpoint = "fiduciary_denials_trustOfficerDenial";
