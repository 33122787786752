import Box from "@material-ui/core/Box";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CancelButton from "components/Common/CustomButtons/CancelButton";
import Dialog from "components/core/Dialog";
import PropTypes from "prop-types";
import React from "react";
import DialogHeader from "./DialogHeader";
import { LoadingButton } from "../CustomButtons/LoadingButton";
import { Divider } from "@material-ui/core";

export default function ConfirmationDialog({
  title,
  content,
  open,
  onClose,
  onCancel,
  onConfirm,
  confirmationText = "Ok",
  cancellationText = "Cancel",
  cancellationButtonProps,
  confirmationButtonProps,
  confirmButton,
  showDialogActions = true,
  loading,
  dialogProps,
  children,
  divider = false,
  TransitionProps,
  ...props
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose || onCancel}
      maxWidth={props.maxWidth || "sm"}
      fullWidth
      TransitionProps={TransitionProps}
      {...dialogProps}
      {...props}
    >
      <DialogHeader onClose={onCancel}>{title}</DialogHeader>
      {divider ? <Divider /> : null}

      {(content || children) && (
        <DialogContent>
          {children}
          {content && <DialogContentText>{content}</DialogContentText>}
        </DialogContent>
      )}
      <Box pb={1}>
        {showDialogActions && (
          <>
            <Box pb={1}>
              <Divider />
            </Box>
            <CancelButton
              variant="text"
              {...cancellationButtonProps}
              onClick={onCancel}
            >
              {cancellationText}
            </CancelButton>
            {onConfirm && (
              <>
                {confirmButton ? (
                  confirmButton
                ) : (
                  <LoadingButton
                    onClick={onConfirm}
                    loading={loading}
                    label={confirmationText}
                    {...confirmationButtonProps}
                  />
                )}
              </>
            )}
          </>
        )}
      </Box>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  TransitionProps: PropTypes.object,
  cancellationButtonProps: PropTypes.any,
  cancellationText: PropTypes.string,
  children: PropTypes.any,
  confirmButton: PropTypes.any,
  confirmationButtonProps: PropTypes.any,
  confirmationText: PropTypes.string,
  content: PropTypes.node,
  dialogProps: PropTypes.any,
  divider: PropTypes.bool,
  loading: PropTypes.any,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  showDialogActions: PropTypes.bool,
  title: PropTypes.node,
};
