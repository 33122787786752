import { Box } from "@material-ui/core";
import React from "react";

const SvgContainer = ({ image, height = "200px" }) => (
  <Box display="flex" justifyContent="center" p={2} height={height}>
    {React.cloneElement(image, { height: "100%" })}
  </Box>
);

export default SvgContainer;
