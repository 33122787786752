import React from "react";
import { formats } from "components/Common/Tables/Common/helpers";

export const columns = [
  {
    field: "from_date",
    format: formats.date,
  },
  {
    field: "to_date",
    format: formats.date,
  },
  {
    title: "Business Entity",
    field: "pd_business_entity.business_name",
  },
  {
    title: "Nature of Entity",
    field: "pd_business_entity.business_description",
  },
  {
    field: "position",
  },
  {
    field: "compensation",
  },
  {
    field: "position_type",
    format: formats.startCase,
  },
];

export const tableQuestions = (
  <ol>
    <li>
      List all offices, trusteeships, directorships or fiduciary positions
      (including nonprofit charitable entities and family trusts) held by you
      with any firm, corporation, association, partnership or other business
      entity during the last ten (10) year period.
    </li>
    <li>
      If you or your spouse has served as a trustee or other fiduciary officer
      in any capacity list all your positions and your spouse’s position for the
      last twelve (12) months.
    </li>
  </ol>
);

export const defaultInitialValues = {
  type: "trust",
  pd_business_entity_id: null,
  relative_pd_person_id: null,
  compensation: "",
  from_date: null,
  to_date: null,
  is_current: false,
  position: "",
  position_type: "",
};
