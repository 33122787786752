import MuiDialog from "@material-ui/core/Dialog";
import React from "react";
import styled, { css } from "styled-components";

const StyledDialog = styled(MuiDialog)`
  ${({ $width }) => {
    return css`
      & .MuiDialog-paper {
        max-width: ${$width}px;
      }
      )
      `;
  }}
`;

const getWidth = (width) => {
  switch (width) {
    case "xs":
      return 444;
    case "sm":
      return 600;
    case "md":
      return 780;
    case "lg":
      return 960;
    case "xl":
      return 1280;
    case "xxl":
      return 1920;
    default:
      return 600;
  }
};

const Dialog = ({ children, maxWidth, ...rest }) => {
  return (
    <StyledDialog $width={getWidth(maxWidth)} fullWidth={!!maxWidth} {...rest}>
      {children}
    </StyledDialog>
  );
};

export default Dialog;
