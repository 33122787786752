import axios from "axios";
import permissionReducer from "components/Permission/permissionReducer";
import React from "react";

const usePermission = ({ id }) => {
  const [permissionState, permissionDispatch] = React.useReducer(
    permissionReducer,
    {}
  );
  const getData = async (endpoint) => {
    const { data } = await axios.get(endpoint);
    return data;
  };

  const postData = async (endpoint, payload) => {
    const { data } = await axios.post(endpoint, payload);
    return data;
  };

  React.useMemo(() => {
    if (id) {
      getData(`account/collaborators/permissions/${id}`)
        .then((data) => {
          permissionDispatch({
            type: "LOAD",
            initialState: data,
          });
        })
        .catch((err) => console.log(err));
    } else {
      getData(`account/collaborators/permissions`)
        .then((data) => {
          permissionDispatch({
            type: "LOAD",
            initialState: data,
          });
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { permissionState, permissionDispatch, postData };
};

export default usePermission;
