import React from "react";
import { familyArrests } from "../../uiStrings";

export const columns = [
  { field: "charge_date", format: "date" },
  { field: "relative_pd_person.full_name", title: "Relative Name" },
  {
    title: "Relative Type",
    field: "relative_pd_person.relative_type",
    format: "startCase",
  },
  { field: "nature" },
  {
    title: "Agency Name",
    field: "law_enforcement_pd_business_entity.business_name",
  },
  { field: "disposition" },
];

export const tableQuestions = <>{familyArrests.tableQuestion}</>;

export const nonRequired = ["attachment_group_id"];

export const endpoint = "family_arrests_familyArrestLegalHistory";

export const modalTitle = "Add Arrest or Charge";

export const defaultInitialValues = {
  type: "family_arrest",
  relative_pd_person_id: null,
  law_enforcement_pd_business_entity_id: null,
  charge_date: null,
  age_at_time: "",
  nature: "",
  case_number: "",
  incident_city: "",
  incident_county: "",
  incident_state: "",
  disposition: "",
  sentence: "",
  incarceration_length: "",
  incarceration_location: "",
  attachment_group_id: null,
};

export const dataHookProps = {
  defaultInitialValues,
  nonRequired,
  endpoint,
};
