import { Grid } from "@material-ui/core";
import { Divider, SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import YesNo from "components/Formik/formGroups/YesNo";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import PDFinancialSelect from "../../../../Formik/formGroups/CrudSelect/selects/PDFinancialSelect";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";

const Form = () => {
  const { safetyDepositTypes } = useMenuOptions();

  return (
    <Grid container spacing={2}>
      <YesNo
        label="Is this safety deposit boxes, vault or other security storage in your name?"
        name="held_by_applicant"
        xs={12}
      />

      <Divider />
      <PDFinancialSelect />

      <SectionSubHeader label="Account Details" divider />

      <FormikSelect
        label="Account Type"
        name="account_type"
        options={safetyDepositTypes}
        xs={4}
      />
      <FormikTextField
        label="Name(s) on Account or Deposit Box"
        name="name_held"
        xs={8}
      />
      <FormikTextField
        label="Account or Deposit Box #"
        name="account_number"
        xs={4}
      />
      <FormikTextField label="Contents" name="content" xs={8} />
    </Grid>
  );
};

const SafetyDepositBox = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "safe_deposit_boxes_safeDepositBox",
    nonRequired: ["content"],
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default SafetyDepositBox;
