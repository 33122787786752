import { personalLicensing } from "../../uiStrings";
import { formats } from "components/Common/Tables/Common/helpers";

export const columns = [
  {
    title: "From",
    field: "from_date",
    format: formats.date,
  },
  {
    title: "To",
    field: "to_date",
    format: formats.date,
  },
  {
    title: "Licensing Agency",
    field: "license_agency_pd_business_entity.business_name",
  },
  {
    title: "Industry",
    field: "type",
    format: "startCase",
  },
  {
    title: "Number",
    field: "license_number",
  },
  {
    field: "disposition",
    format: "startCase",
  },
  { title: "Applicant", field: "held_by_name" },
  {
    title: "Business",
    field: "pd_business_entity.business_name",
  },
];

export const tableQuestions = personalLicensing.tableQuestion;

export const defaultInitialValues = {
  type: null,
  pd_business_entity_id: null,
  relative_pd_person_id: null,
  license_agency_pd_business_entity_id: null,
  application_date: null,
  from_date: null,
  to_date: null,
  license_type: "",
  license_number: "",
  disposition: null,
  disposition_reason: "",
  has_hearing: false,
  hearing_date: null,
  hearing_nature: "",
  was_testimony_given: false,
  hearing_participant: "",
  owner_type: "personal",
  has_condition: null,
  condition_type: null,
  condition_date: null,
  condition_reason: "",
};
