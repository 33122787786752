import React from "react";
import { fieldTypes } from "componentGenerators/formGenerator/config";

export const columns = [
  {
    title: "Name of Court/Agency",
    field: "law_enforcement_pd_business_entity.business_name",
  },
  { field: "nature", title: "Nature of Proceeding or Investigation" },
  { field: "testimony_date" },
];

export const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Nature & Details",
  },
  {
    type: fieldTypes.text,
    label: "Nature of Proceeding or Investigation",
    name: "nature",
    xs: 12,
  },
  {
    type: fieldTypes.yesNo,
    label: "Was Testimony Given?",
    name: "was_testimony_given",
  },
  {
    type: fieldTypes.yesNo,
    label: "Were you subpoenaed?",
    name: "was_subpoenaed",
  },
  {
    type: fieldTypes.date,
    label: "Testimony Date",
    name: "testimony_date",
  },
  {
    type: fieldTypes.text,
    label: "Time Period of Investigation",
    name: "time_period",
  },
  {
    type: fieldTypes.subHeader,
    label: "Law Enforcement or Court",
  },
  {
    type: fieldTypes.pdLawEnforcementSelect,
    name: "law_enforcement_pd_business_entity_id",
  },
];

export const tableQuestions = (
  <>
    Provide details if either is true:
    <ul>
      <li>
        You have been called to testify before, or otherwise been questioned,
        interviewed, deposed or requested to take a polygraph exam by a
        government agency/organization, court, commission, grand jury or
        investigative body (local, state, county, provincial, federal, national,
        etc) in any jurisdiction other than in response to a traffic summons;
      </li>
      <li>
        You have ever been subpoenaed to appear or testify before a federal,
        national, state, county grand jury, or other criminal investigatory
        agency or body, or any board or commission, or any civil, criminal or
        administrative proceeding or hearing;
      </li>
    </ul>
  </>
);

export const nonRequired = [];

export const endpoint = "testimonies_testimonyLegalHistory";

export const dataHookProps = {
  nonRequired,
  endpoint,
};
