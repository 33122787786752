import { get } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Row } from "./Row";

export const PermissionedRow = ({
  handleEdit,
  handleDelete,
  handleView,
  record,
  viewOnly,
  missingRequiredItems,
  ...props
}) => {
  const shouldView = get(record, "crud_permission")
    ? true
    : get(record, "crud_permission") === "read" || viewOnly;

  const permissionedHandlers = React.useMemo(() => {
    if (shouldView) {
      return {
        handleDelete: null,
        handleEdit: null,
        handleView: handleView,
        hasMissingRequired: false,
      };
    } else {
      return {
        handleDelete: handleDelete,
        handleEdit: handleEdit,
        hasMissingRequired: missingRequiredItems?.length > 0,
        handleView: null,
      };
    }
  }, [shouldView, handleView, handleDelete, handleEdit, missingRequiredItems]);

  return (
    <Row
      {...permissionedHandlers}
      missingRequiredItems={missingRequiredItems}
      record={record}
      {...props}
    />
  );
};

PermissionedRow.propTypes = {
  viewOnly: PropTypes.bool,
  missingRequiredItems: PropTypes.array,
  handleDelete: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  handleEdit: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  handleView: PropTypes.func,
  record: PropTypes.object,
};
