import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Committed Acts",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Has the entity ever committed acts, even those they were not charged for, that would constitute a crime, offense or violation of criminal or civil law?",
    name: "new_york_cvavls.has_miscellaneous_q10",
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label: "If you have answered Yes, please provide details below:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "new_york_cvavls.miscellaneous_q10",
    xs: 12,
    rows: 4,
  },
  {
    type: fieldTypes.subHeader,
    label: "Cooperation Refusal",
    divider: true,
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Has the entity ever refused to cooperate with any legislative body or other official investigatory body involved in the investigation of crimes?",
    name: "new_york_cvavls.has_miscellaneous_q11",
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label: "If you have answered Yes, please provide details below:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "new_york_cvavls.miscellaneous_q11",
    xs: 12,
    rows: 4,
  },
  {
    type: fieldTypes.subHeader,
    label: "Cartels",
    divider: true,
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Is the entity a member of, or associated with any member of, a career offender cartel, as those terms are defined by New York law?",
    name: "new_york_cvavls.has_miscellaneous_q13",
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label: "If you have answered Yes, please provide details below:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "new_york_cvavls.miscellaneous_q13",
    xs: 12,
    rows: 4,
  },
  {
    type: fieldTypes.subHeader,
    label: "Judgements Due",
    divider: true,
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Is the entity delinquent in the payment of any judgments due to any governmental agency anywhere?",
    name: "new_york_cvavls.has_miscellaneous_q16",
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label: "If you have answered Yes, please provide details below:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "new_york_cvavls.miscellaneous_q16",
    xs: 12,
    rows: 4,
  },
  {
    type: fieldTypes.subHeader,
    label: "Associations",
    divider: true,
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Does the entity have affiliates or close associates whoserelationship with you may pose any injurious threat to the interest ofthe State of New York?",
    name: "new_york_cvavls.has_miscellaneous_q17",
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label: "If you have answered Yes, please provide details below:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "new_york_cvavls.miscellaneous_q17",
    xs: 12,
    rows: 4,
  },
];

export default [
  {
    type: "form",
    formSchema,
    endpoint: "bdSupplement",
  },
];
