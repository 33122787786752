import React from "react";
import { commonStrings } from "../../uiStrings";

export const columns = [
  {
    field: "exchange_date",
  },
  {
    field: "amount",
    format: "currency",
  },
  {
    field: "exchange_location",
  },
  {
    field: "reason",
  },
];

export const tableQuestions = (
  <>
    Have you ever exchanged currency in an amount of more than $10,000USD within
    the past ten (10) years? {commonStrings.usdCurrencyReporting}
    {commonStrings.provideDetails}
  </>
);

export const defaultInitialValues = {
  exchange_date: null,
  exchange_location: "",
  amount: null,
  reason: "",
  was_report_filed: "",
};
