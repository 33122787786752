import PropTypes from "prop-types";
import React from "react";
import MuiTextField from "@material-ui/core/TextField";

function TextField({
  fullWidth = true,
  size = "small",
  margin = "dense",
  variant = "outlined",
  autoComplete = "off",
  multiline,
  rows,
  ...props
}) {
  return (
    <MuiTextField
      {...props}
      fullWidth={fullWidth}
      size={size}
      margin={margin}
      variant={variant}
      autoComplete={autoComplete}
      multiline={multiline || !!rows}
      rows={rows}
    />
  );
}

TextField.propTypes = {
  autoComplete: PropTypes.string,
  fullWidth: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  margin: PropTypes.oneOf(["dense", "none", "normal"]),
  size: PropTypes.oneOf(["medium", "small"]),
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
};

export default TextField;
