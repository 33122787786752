import {
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import Center from "../../flex/Center";
import useLoadDashboardData from "./useLoadDashboardData";
import { GeneratedApplications } from "./widgets/ApplicationManager/GeneratedApplications";
// import MultiFactorAuthWarning from "./widgets/ApplicationManager/MultiFactorAuthWarning";
import FormStatuses from "./widgets/FormStatuses/FormStatuses";

export default function BusinessDashboard() {
  const { formStatus, status } = useLoadDashboardData();

  return (
    <>
      {status === "loading" && (
        <Center isFullPage>
          <CircularProgress size={80} />
        </Center>
      )}
      {status === "resolved" && (
        <>
          <Container maxWidth="xl">
            <Grid container spacing={2}>
              <Grid item sm={12} md={7}>
                <Typography variant="h2">Corporate Dashboard</Typography>
              </Grid>
              {/* <Grid item xs={12}>
                <MultiFactorAuthWarning />
              </Grid> */}
              <Grid item sm={12} md={7}>
                <FormStatuses formStatuses={formStatus} />
              </Grid>
              <Grid item sm={12} md={5}>
                <GeneratedApplications />
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </>
  );
}
