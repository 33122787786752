import React from "react";
import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider";
import useTabularData from "hooks/tableHooks/useTabularData";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";
import FormikNumberField from "components/Formik/fields/FormikNumberField.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField.jsx";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Compliance Officer's Report" />
      <FormikNumberField
        label="Effective Year"
        name="effective_year"
        type="year"
        xs={4}
      />
      <FormikTextField label="Description" name="description" xs={12} />
      <FormikFileDropzone
        label="Compliance Officer Report"
        description="Compliance Officer Report"
        name="corporate_report_attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};

const IowaCorporateReport = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    noValidate: true,
    endpoint: "bdCorporateReport?report_type=compliance_officer_report",
  });

  return (
    <FormSectionProvider
      title={name}
      formData={formData}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
    </FormSectionProvider>
  );
};

export default IowaCorporateReport;
