import PropTypes from "prop-types";
import React from "react";

import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

import useGoogleLookup from "hooks/useGoogleLookup";
import { Box, Grid } from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import GenericError from "components/Common/GenericError";
import poweredByGoogle from "assets/img/powered_by_google_on_white_hdpi.png";

const google = window.google;

const service = new google.maps.places.PlacesService(
  document.createElement("div")
);

const InputField = ({
  params,
  children,
  xs,
  helperText,
  placeholder,
  onChange,
  type,
  disabled,
  name,
  value,
  errors,
  touched,
  label,
  onBlur,
  variant,
  InputProps,
  id,
}) => {
  return (
    <Grid item xs={xs}>
      <Box>
        <TextField
          fullWidth={true}
          size="small"
          variant={variant || "outlined"}
          helperText={helperText}
          disabled={disabled}
          placeholder={placeholder}
          id={id}
          name={name}
          value={value || ""}
          onChange={onChange}
          onBlur={onBlur}
          label={label}
          type={type}
          InputProps={InputProps}
          autoComplete="off"
          {...params}
        />
        {children}
      </Box>
      <GenericError name={name} errors={errors} touched={touched} />
    </Grid>
  );
};

export default function FormikGoogleLookup({ fields, xs = 12 }) {
  const [prediction, setPrediction] = React.useState("");
  const [placeId, setPlaceId] = React.useState(null);
  const { getPlaces } = useGoogleLookup();

  const handleSelect = async (lookupResponse) => {
    const geoCodeResponse = await geocodeByAddress(lookupResponse);
    const geo_place_id = geoCodeResponse[0]?.place_id;
    setPlaceId(geo_place_id);
  };

  React.useEffect(() => {
    if (placeId) {
      service.getDetails(
        {
          placeId: placeId,
        },
        function (place, status) {
          const STATUS = google.maps.places.PlacesServiceStatus.OK;
          // Google API returns address as place.name if type is an address
          // We do not want to put address in to business name
          if (place.types.some((type) => type === "street_address")) {
            place.name = "";
          }
          if (status === STATUS) {
            getPlaces(fields, place);
            setPrediction("");
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeId]);

  return (
    <PlacesAutocomplete
      value={prediction}
      onChange={(changedValue) => {
        setPrediction(changedValue);
      }}
      clearItemsOnError={true}
      onError={() => {
        return null;
      }}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Grid item xs={xs}>
          <Box w={1} style={{ position: "relative" }}>
            <InputField
              style={{ marginBottom: "0" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="filled"
              label="Start typing an address or business name to search..."
              name="prediction"
              value={prediction}
              onChange={setPrediction}
              {...getInputProps({
                placeholder: "Google Lookup...",
                className: "location-search-input",
              })}
            />
            <Box
              zIndex="modal"
              style={{ zIndex: "1000", position: "absolute" }}
              // className="autocomplete-dropdown-container"
            >
              {loading && <div>Loading...</div>}

              {suggestions.map((suggestion) => {
                const { formattedSuggestion } = suggestion;
                const { mainText, secondaryText } = formattedSuggestion;
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";

                const style = suggestion.active
                  ? {
                      boxShadow:
                        "0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08)",
                      display: "block",
                      width: "100%",
                      padding: "8px",
                      fontSize: "13px",
                      backgroundColor: "#fafafa",
                      cursor: "pointer",
                      borderRight: "0.3mm ridge black",
                      borderLeft: "0.3mm ridge black",
                      borderTop: "0.3mm ridge black",
                    }
                  : {
                      display: "block",
                      borderRight: "0.3mm ridge black",
                      borderLeft: "0.3mm ridge black",
                      borderTop: "0.3mm ridge black",
                      width: "100%",
                      padding: "8px",
                      fontSize: "13px",
                      backgroundColor: "#ffffff",
                      cursor: "pointer",
                    };

                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={suggestion.index}
                  >
                    <strong>{mainText}</strong> <small>{secondaryText}</small>
                  </div>
                );
              })}
              {suggestions.length ? (
                <Box>
                  <div
                    style={{
                      display: "block",
                      borderRight: "0.3mm ridge black",
                      borderLeft: "0.3mm ridge black",
                      borderBottom: "0.3mm ridge black",
                      backgroundColor: "white",
                      width: "100%",
                      padding: "8px",
                      cursor: "pointer",
                      backgroundPosition: "right",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundImage: `url(${poweredByGoogle})`,
                    }}
                  />
                </Box>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </Grid>
      )}
    </PlacesAutocomplete>
  );
}

FormikGoogleLookup.propTypes = {
  fields: PropTypes.object,
  xs: PropTypes.number,
};
