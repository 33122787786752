import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import { Card, CardContent } from "@material-ui/core";
import {
  SectionSubHeader,
  Paragraph,
} from "components/Common/LayoutComponents";
import CustomLink from "components/Common/Link";

const ColoradoVLMARequiredAttachments = ({ name }) => {
  return (
    <FormSectionWrapper title={name}>
      <Card variant="outlined">
        <CardContent>
          <SectionSubHeader label="Articles" />
          <Paragraph>
            <>
              Attach certified copies of all articles of incorporation, bylaws,
              articles of organization, or a true copy of any partnership or
              trust agreement, including any and all amendments to such. Upload
              your document in
              <CustomLink to="bd_corporate_documents" />
            </>
          </Paragraph>
          <SectionSubHeader label="Reports and SEC filings" />
          <Paragraph>
            <>
              If a corporation, attach copies of all annual and bi-annual
              reports and SEC filings, if any, for past 3 years, and all minutes
              from all corporate meetings in the past 12 months. Upload your
              documents in
              <CustomLink to="bd_financial_statements" />
            </>
          </Paragraph>
          <SectionSubHeader label="Uniform Commercial Code Report" />
          <Paragraph>
            <>
              Attach current copy of any Uniform Commercial Code Report for all
              states where known to be filed. Upload your documents in
              <CustomLink to="bd_financial_statements" />
            </>
          </Paragraph>
          <SectionSubHeader label="certificate of authority" />
          <Paragraph>
            <>
              Provide certificate of authority from the colorado secretary of
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              state's office. Upload your documents in
              <CustomLink
                to="bd_application_details"
                label="Business Details"
              />
            </>
            under the Authorized States entry for Colorado.
          </Paragraph>
        </CardContent>
      </Card>
    </FormSectionWrapper>
  );
};

export default ColoradoVLMARequiredAttachments;
