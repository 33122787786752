export const tableQuestions = null;

export const defaultInitialValues = {
  bd_pennsylvania_eea: {
    bd_pa_eea_application_period_checkbox: {
      initial: false,
      renewal: false,
    },
    bd_pa_eea_slot_machine_checkbox: {
      manufacturer: false,
      supplier: false,
      manufacturer_designee: false,
    },
    bd_pa_eea_table_game_checkbox: {
      manufacturer: false,
      supplier: false,
      manufacturer_designee: false,
    },
    bd_pa_eea_interactive_gaming_checkbox: {
      manufacturer: false,
      supplier: false,
      operator: false,
    },
    bd_pa_eea_video_gaming_checkbox: {
      manufacturer: false,
      supplier: false,
      operator: false,
    },
    bd_pa_eea_fantasy_contest_checkbox: {
      operator: false,
    },
    bd_pa_eea_sports_wagering_checkbox: {
      manufacturer: false,
      supplier: false,
      operator: false,
    },
  },
  small_diverse_business_attachment_group_id: null,
};

export const endpoint = "bdSupplement";

export const tabularDataParams = {
  defaultInitialValues,
  endpoint,
};
