import React from "react";
import { address } from "helpers/initialValues";

export const columns = [
  {
    field: "full_name",
  },
  {
    field: "date_of_birth",
    format: "date",
  },
  {
    field: "email",
  },
  {
    field: "home_address",
    format: "address",
  },
];

export const tableQuestions = (
  <>The following are all people listed in the business disclosure.</>
);

export const defaultInitialValues = {
  first_name: "",
  middle_name: "",
  suffix_name: "",
  last_name: "",
  occupation: "",
  home_address: address,
  date_of_birth: null,
  country_of_birth: "",
  home_phone: "",
  mobile_phone: "",
  business_phone: "",
  fax: "",
  ssn: "",
  email: "",
  title: "",
};
