import React, { useState, useMemo } from "react";
import { Box } from "@material-ui/core";
import ColapsibleLog from "./ColapsibleLog";
import useInvalidOnlyFilter from "../hooks/useInvalidOnlyFilter";
import useBreakingOnlyFilter from "../hooks/useBreakingOnlyFilter";
import DropDown from "components/Common/Inputs/DropDown";
import PaginationControl from "components/DevTools/PdfTools/Components/PaginationControl";

export default function ValidateAllTags({ pdfValidity }) {
  const [focusPdfValidity, setFocusPdfValidity] = useState([]);
  const [filterType, setFilterType] = useState("");

  const invalidOnlyFilter = useInvalidOnlyFilter(pdfValidity);
  const breakingOnlyFilter = useBreakingOnlyFilter(pdfValidity);

  const data = useMemo(() => {
    switch (filterType) {
      case "all":
        return pdfValidity;
      case "invalid":
        return invalidOnlyFilter;
      case "breaking":
        return breakingOnlyFilter;
      default:
        return pdfValidity;
    }
  }, [filterType, breakingOnlyFilter, invalidOnlyFilter, pdfValidity]);

  return (
    <>
      {!!pdfValidity.length && (
        <Box style={{ width: "200px" }} mt={2}>
          <DropDown
            variant="filled"
            label="Select Filter"
            menuItems={[
              { value: "invalid", label: "Invalid Only" },
              { value: "breaking", label: "Breaking PDF" },
              { value: "all", label: "ALL" },
            ]}
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
            fullWidth
          />
        </Box>
      )}
      <Box display="flex" flexDirection="column" mt={2}>
        {focusPdfValidity.map((val, i) => (
          <ColapsibleLog
            key={"key-" + i}
            status={val.pdf_status}
            messages={[{ invalid_fields: val.invalid_fields }]}
          />
        ))}
      </Box>
      <PaginationControl
        data={data}
        setFocus={setFocusPdfValidity}
        paginationSize={10}
      />
    </>
  );
}
