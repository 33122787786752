export default [
  {
    type: "readOnlyTable",
    endpoint: "views/bd_loans?instrument_type=tax_liability",
    linkTo: ["bd_loans"],
    columns: [
      {
        title: "Business Entity",
        field: "business_name",
      },
      {
        title: "Loan Date",
        field: "from_date",
        format: "date",
      },
      {
        title: "Status",
        field: "loan_status",
        format: "startCase",
      },
      {
        title: "Purpose of Loan",
        field: "purpose_of_loan",
      },
      {
        title: "Original Amount",
        field: "amount_original",
        format: "currency",
      },
      {
        title: "Current Amount",
        field: "amount_current",
        format: "currency",
      },
    ],
    dependsOnQueryCount:
      " Are there any federal, state, and/or local outstanding tax liabilities owed by the applicant and/or any related business entities in which it has a financial or ownership interest?",
  },
];
