import React from "react";

export const columns = [
  { title: "Firm", field: "related_pd_business_entity.business_name" },
  { title: "CPA/Accountant", field: "representative_name" },
  { title: "From", field: "from_date", format: "date" },
  { title: "To", field: "to_date", format: "date" },
];

export const defaultInitialValues = {
  related_pd_business_entity_id: null,
  from_date: null,
  to_date: null,
  representative_name: "",
  service_role: "accountant",
  is_current: false,
};
export const modalTitle = "CPA/Accountants";

export const tableQuestions = (
  <>
    Identify current and past C.P.A.s, Accountants or individuals who assisted
    you in preparation of financial matters in the last ten (10) years.
  </>
);
export const nonRequired = [];

export const endpoint = "iowa_accountants_pdServiceProviderAccountant";

export const dataHookProps = {
  defaultInitialValues,
  nonRequired,
  endpoint,
};
