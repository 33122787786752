import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import {
  SectionSubHeader,
  SectionLabel,
} from "components/Common/LayoutComponents";

import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm.js";
import React from "react";
import YesNo from "components/Formik/formGroups/YesNo";

const MontanaDisclosureQuestionsForm = ({ innerRef }) => {
  const formData = useForm({
    defaultInitialValues: {
      montana: {
        has_arrest: null,
        charged_misdemeanor: null,
        convicted_misdemeanor: null,
        has_probabation: null,
        has_arrest_traffic_offense: null,
        is_on_probation: null,
      },
    },
    noValidate: true,
    endpoint: "montana_disclosure_questions_pdSupplement",
  });

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage
        title={"Citizenship & Residence"}
        innerRef={innerRef}
        showSaveButton={false}
      >
        <Grid container spacing={2}>
          <SectionSubHeader label="Personal/ Criminal History Statement Information" />

          <SectionLabel label="Answer Yes to the questions even if your charges were dismissed, deferred, or otherwise changed." />

          <SectionLabel label=" In the past 10 years have you been:" />
          <YesNo label="Arrested?" name="montana.has_arrest" />
          <YesNo
            label="Charged with a misdemeanor or felony crime?"
            name="montana.charged_misdemeanor"
          />
          <YesNo
            label="Convicted of a misdemeanor or felony crime?"
            name="montana.convicted_misdemeanor"
          />
          <YesNo label="Placed on probation?" name="montana.has_probabation" />
          <YesNo
            label="Arrested or ticketed for alcohol-related traffic offenses (such as DUI, Per Se, etc.)"
            name="montana.has_arrest_traffic_offense"
          />
          <YesNo
            label="Are you currently on probation or parole?"
            name="montana.is_on_probation"
          />
        </Grid>
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

export default MontanaDisclosureQuestionsForm;
