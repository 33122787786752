import TextField from "components/Common/Inputs/TextField";
import React from "react";
import H3 from "components/DevTools/PdfTools/Components/H3.jsx";

export default function Column({ key, json, setJSON, setNewPageTag }) {
  const updateColumnJSON = (e) => {
    const columnCopy = json.columns[e.target.id];
    columnCopy[e.target.name] =
      e.target.name === "width"
        ? parseInt(e.target.value) || ""
        : e.target.value;
    json.columns[e.target.id] = columnCopy;

    setJSON(json);
    setNewPageTag(JSON.stringify(json));
  };

  return (
    <div key={key}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          gap: "10px",
        }}
      >
        <div style={{ width: "37.5vw" }}>
          <TextField
            value={json.columns[key]?.data || ""}
            id={key}
            label="column (params if required)"
            name="data"
            onChange={updateColumnJSON}
          />
        </div>
        <div style={{ width: "37.5vw" }}>
          <TextField
            value={json.columns[key]?.header || ""}
            id={key}
            label="Header"
            name="header"
            onChange={updateColumnJSON}
          />
        </div>
      </div>
      <H3>Add Style (optional):</H3>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          gap: "10px",
        }}
      >
        <div style={{ width: "24vw" }}>
          <TextField
            value={json.columns[key]?.width || ""}
            id={key}
            label="Width (must be number)"
            name="width"
            onChange={updateColumnJSON}
          />
        </div>
      </div>
    </div>
  );
}
