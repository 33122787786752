import React, { useReducer, createContext, useContext, useEffect } from "react";
import { getModel } from "helpers/apiHelpers";
import { useAuth } from "context/auth";

const LoadFormConstraintContext = createContext();
const _ = require("lodash");

/*
 * [
 *   {
 *     "form_nav_tag": "business_ownership",
 *     "info_message": "Message 1",
 *     "jurisdiction_name": "Missouri"
 *   },
 *   {
 *     "form_nav_tag": "business_ownership",
 *     "info_message": "Message 2",
 *     "jurisdiction_name": "New Jersey"
 *   },
 * ]
 *             |
 *             |
 *             V
 * {
 *   "business_ownership": [
 *     "Missouri: Message 1",
 *     "New Jersey: Message 2",
 *   ]
 * },
 *  {
 *   "business_ownership": [
 *     "MESSAGE 1: [Missouri, New Jersey]", Missouri,New Jersey: requires ALL information from the age of 18
 * }
 */

function formatResponse(data) {
  const formattedData = {};
  _.forEach(data, (d) => {
    if (
      formattedData[d.form_nav_tag] &&
      Array.isArray(formattedData[d.form_nav_tag][d.info_message])
    ) {
      formattedData[d.form_nav_tag][d.info_message] = {
        ...(formattedData[d.form_nav_tag][d.info_message] = [
          ...formattedData[d.form_nav_tag][d.info_message],
          d.jurisdiction_name,
        ]),
      };
    } else if (formattedData[d.form_nav_tag]) {
      formattedData[d.form_nav_tag] = {
        ...formattedData[d.form_nav_tag],
        [d.info_message]: [d.jurisdiction_name],
      };
    } else {
      formattedData[d.form_nav_tag] = {
        [d.info_message]: [d.jurisdiction_name],
      };
    }
  });
  return formattedData;
}

function formConstraintReducer(state, action) {
  if (action.type === "LOAD") {
    return {
      ...state,
      loading: false,
      formConstraint: action.data,
    };
  }
  return state;
}

export default function FormConstraintProvider({ children }) {
  const { account_type } = useAuth();
  const [state, dispatch] = useReducer(formConstraintReducer, {
    loading: true,
    formConstraint: {},
  });

  useEffect(() => {
    const endpointPath = `common/getFormConstraint`;
    if (account_type === "super_admin") {
      dispatch({
        type: "LOAD",
        data: {},
      });
      return null;
    }
    getModel(endpointPath)
      .then((data) => {
        dispatch({
          type: "LOAD",
          data: formatResponse(data),
        });
      })
      .catch(() => {
        dispatch({
          type: "LOAD",
          data: {},
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoadFormConstraintContext.Provider value={state}>
      {children}
    </LoadFormConstraintContext.Provider>
  );
}

export function useFormConstraint() {
  const context = useContext(LoadFormConstraintContext);
  if (context === undefined) {
    throw new Error(
      "useFormConstraint must be used within FormConstraintProvider"
    );
  }
  return context;
}
