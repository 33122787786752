import { createTheme } from "@material-ui/core/styles";

export const colors = {
  logo: {
    light: "#00AEEF",
    dark: "#005687",
  },
  button: {
    main: "#5585FB",
    second: "#005586",
  },
  icons: "#FFAF30",
  heading: "#2556CF",
  navIcon: "#555",
};

const theme = createTheme({
  typography: {
    h1: {
      fontSize: "2.25em",
      fontWeight: 300,
    },
    h2: {
      fontSize: "1.75em",
      fontWeight: 300,
    },
    h3: {
      fontSize: "1.5em",
      fontWeight: 300,
    },
    h4: {
      fontSize: "1.25em",
      fontWeight: 300,
    },
    h5: {
      fontSize: "1em",
      fontWeight: 300,
    },
    body2: {
      fontWeight: 300,
    },
  },

  palette: {
    navIcon: {
      main: colors.navIcon,
    },
    button: {
      main: "#5585FB",
      second: "#005586",
    },

    primary: {
      main: "#065586",
    },
    error: {
      lightest: "#ffebee",
      main: "#c42e2b",
    },
    gold: { main: "#FFAF30", contrastText: "rgba(0, 0, 0, 0.87)" },
    text: {
      secondary: "#5F6368",
    },
  },
  sizing: {
    drawerWidth: 350,
  },
  overrides: {
    MuiListItemIcon: {
      root: {
        minWidth: "36px",
      },
    },
    MuiFormControlLabel: {
      label: {
        fontWeight: 300,
        fontSize: "1.3em",
      },
    },

    MuiTableCell: {
      root: {
        padding: "8px",
      },
    },

    MuiListItem: {
      root: {
        paddingTop: "0px",
        paddingBottom: "0px",
      },
    },
  },
});

export default theme;
