import { CircularProgress, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

export const DefaultInput = ({ params, loading, label, ...rest }) => (
  <TextField
    {...params}
    label={label}
    variant="outlined"
    InputProps={{
      ...params.InputProps,
      endAdornment: (
        <React.Fragment>
          {loading ? <CircularProgress color="inherit" size={20} /> : null}
          {params.InputProps.endAdornment}
        </React.Fragment>
      ),
    }}
    {...rest}
  />
);

DefaultInput.propTypes = {
  loading: PropTypes.bool,
  label: PropTypes.string,
  params: PropTypes.shape({
    InputProps: PropTypes.shape({
      endAdornment: PropTypes.node,
    }),
  }),
};
