export const defaultInitialValues = {
  louisiana_r06_07: {
    qualifier_pd_business_entity_id: null,
    residence_country_date: null,
    languages_spoken: "",
  },

  louisiana: {
    has_student_loan_default: null,
    q_e3_bool: null,
    q_f3_bool: null,
  },

  la_student_loan_attachment_group_id: null,
  la_q_e3_attachment_group_id: null,
  la_q_f3_attachment_group_id: null,
};

export const endpoint = "pdSupplement";
