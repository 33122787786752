import { Box, Grid, TableSortLabel, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const ColumnHeaderText = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.light};
`;

const ListHeaderContainer = styled(Grid)`
  margin: ${({ theme }) => theme.spacing(2, 3, 1, 3)};
`;

export const headCells = [
  { key: "path", name: "Section" },
  { key: "status", name: "Status" },
  { key: "notes", name: "Notes" },
];

const ColumnHeader = ({
  columnKey,
  name,
  orderBy,
  handleSortRequest,
  order,
}) => (
  <TableSortLabel
    active={orderBy === columnKey}
    direction={orderBy === columnKey ? order : "asc"}
    onClick={() => {
      console.log(columnKey);
      handleSortRequest(columnKey);
    }}
  >
    <ColumnHeaderText variant="h3">
      <Box fontWeight={500}>{name}</Box>
    </ColumnHeaderText>
  </TableSortLabel>
);

export const Listheader = (props) => {
  return (
    <ListHeaderContainer>
      <Box display="flex" width={1} alignItems="center">
        <Box display="flex" flexGrow={1} width={0.5} mr={3}>
          <ColumnHeader
            {...props}
            columnKey={headCells[0].key}
            name={headCells[0].name}
          />
        </Box>
        <Box display="flex" width={0.5} ml={3}>
          <ColumnHeader
            {...props}
            columnKey={headCells[1].key}
            name={headCells[1].name}
          />
        </Box>
        <Box display="flex" width={0.5} ml={3}>
          <ColumnHeader
            {...props}
            columnKey={headCells[2].key}
            name={headCells[2].name}
          />
        </Box>
      </Box>
    </ListHeaderContainer>
  );
};
