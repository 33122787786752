import { fieldTypes } from "componentGenerators/formGenerator/config";
import React from "react";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Birth Certificate",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please provide a copy of your Birth Certificate in the
        <CustomLink to="citizenship" /> attachment section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        If a birth certificate is not available, a copy of a letter from you to
        the appropriate government agency requesting a birth certificate will be
        acceptable for processing a temporary license. The letter must show both
        the name and address of the agency from which the birth certificate is
        requested. A permanent occupational license will not be issued until the
        applicant provides a copy of the birth certificate or evidence
        indicating that the records have been destroyed or are no longer
        available.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Driver’s License",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please provide a copy of your Driver’s License in the
        <CustomLink to="vehicle_licenses" />
        attachment section and attach behind exhibit labeled Exhibit 4.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Education",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please provide transcripts and High School Diploma in the
        <CustomLink to="education" /> section. Provide all official
        post-secondary school transcripts. If you do not have any post-secondary
        education, provide a copy of your high school diploma. Attach behind
        Exhibit 6.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Bankruptcy",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please provide a copy of the bankruptcy petition in the
        <CustomLink to="bankruptcies" /> section and attach behind Exhibit 14.
        (if applicable)
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Military",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Provide a copy of your military record (DD-214, DD-220, or NGB-22) in
        the <CustomLink to="military" /> section and attach behind Exhibit 16c
        (if applicable).
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Criminal History",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        For those applicants, whose legal residence is outside the United
        States, or reside the majority of their time outside of the United
        States, please provide recent national law enforcement/Police clearances
        for those jurisdictions and attach behind Exhibit 27.
      </>
    ),
  },
  {
    type: fieldTypes.dropZone,
    name: "criminal_check_attachment_group_id",
    description: "Criminal Check",
    label: "Upload Attachment",
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Child Support/Alimony",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please provide a copy of any court orders related to child support or
        alimony in the
        <CustomLink to="children" /> section and attach behind Exhibit 36.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Financial Statements",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please provide copies of the last thirty-six (36) monthly bank
        statements for all your personal checking and savings accounts in the
        <CustomLink to="bank_accounts" /> section and attach behind Exhibit 37.
        This includes, but is not limited to, individual and joint accounts and
        any account closed within the thirty-six (36) month period.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Retirement Statements",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please provide the most recent annual copy of all retirement plan
        statements for the last three (3) years in the
        <CustomLink to="pension_funds" />
        section and attach behind Exhibit 38. This includes 401(k) and IRA
        statements.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Brokerage Account",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please provide copies of the last thirty-six (36) monthly statements for
        all of your personal brokerage accounts in the
        <CustomLink to="margin_accounts" /> section and attach behind Exhibit 39
        . This includes, but is not limited to, individual and joint accounts.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Mortgage Statement",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please provide copies of you and your spouse&lsquo;s most recent
        year-end Mortgage Statements for all properties owned in the
        <CustomLink to="real_estate_loans" label="Real Estates Loan" /> section
        and attach behind Exhibit 43.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Tax Returns",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please provide true and accurate copies of your personal state and
        federal tax returns for the last three (3) years. Include W-2s, K-1s,
        schedules and attachments in the
        <CustomLink to="us_taxes" /> section and attach behind Exhibit 46. If
        you owe any payment on the tax returns, submit as Exhibit 46A, proof of
        payment or proof of an established payment plan.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Tax Audits, Adjustments",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        In the last ten (10) years, if you have ever had any municipal, state,
        or federal tax returns audited or adjusted, please provide a description
        of the facts, circumstances, and facts, circumstances, and results of
        each audit or adjustment in the
        <CustomLink to="us_taxes" /> section and attach behind Exhibit 47.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Related Tax Returns",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please provide copies of any tax returns that are associated with the
        filing of your individual tax return, including but not limited to
        Partnership Tax Returns (Form 1065) and/or Sub S Corporation Tax Returns
        (Form 1120S) for the last three (3) years in the
        <CustomLink to="us_taxes" /> section and attach behind Exhibit 47. This
        does not apply to Passive Investors.
      </>
    ),
  },
];

export default [
  {
    type: "form",
    formSchema,
    endpoint: "indiana_attachments_personalDisclosure",
  },
];
