import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DefaultTextField } from "components/Common/InputElements";
import { useAuth } from "context/auth";
import { getModel } from "helpers/apiHelpers";
import React from "react";
import GridItem from "./Containers/Grid/GridItem";
import _ from "lodash";

/*
 * This component is getting pretty old and inflexible. If you have a need an AsyncSearchDropDown consider using FormikAsyncSelect. We could also use a new async dropdown that is not bound to formik
 */
export const AsyncSearchDropDown = ({
  onChange: onHandleChange,
  size,
  noEmployee,
  optionLabel,
  endpoint,
  params = null,
  relatedFieldName,
  textFieldProps,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState(props.options || []);
  const loading = open && options.length === 0;
  const { employee_id } = useAuth();

  React.useEffect(() => {
    let active = true;

    function format(item, args) {
      var regExp = /\{([^}]+)\}/g;
      var matches = item.match(regExp);
      for (var i = 0; i < matches.length; i++) {
        var str = matches[i];
        var k = str.substring(1, str.length - 1);
        item = item.replace(new RegExp("\\{" + k + "\\}", "g"), _.get(args, k));
      }

      return item;
    }

    // converting the data to [{id: 1, value: "some value"}]
    const convertToIdValue = (data) => {
      const labelValue = _.map(data, (d) => {
        return {
          id: d.id,
          value: format(optionLabel, d),
          all_data: d,
        };
      });
      return labelValue;
    };
    if (!loading) {
      return undefined;
    }

    (async () => {
      let finalEndpoint = `/employee/${employee_id}/${endpoint}`;
      if (noEmployee) {
        finalEndpoint = endpoint;
      }
      await getModel(finalEndpoint, params)
        .then(function (result) {
          if (active) {
            setOptions(convertToIdValue(result));
          }
        })
        .catch(function () {
          setOptions([]);
        });
    })();

    return () => {
      active = false;
    };
  }, [employee_id, loading, optionLabel, endpoint, noEmployee, params]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <GridItem md={size || 4}>
      <Autocomplete
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(option, value) => {
          if (relatedFieldName) {
            // this is for formik
            onHandleChange({
              target: { value: value.id, name: relatedFieldName },
            });
          } else {
            onHandleChange(value);
          }
        }}
        getOptionLabel={(option) => `${option.value}`}
        options={options}
        loading={loading}
        disabled={props.disabled || false}
        {...props}
        renderInput={(params) => (
          <DefaultTextField
            params={params}
            label={props.label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            {...textFieldProps}
          />
        )}
      />
    </GridItem>
  );
};

AsyncSearchDropDown.defaultProps = {
  nosubmit: true,
  noEmployee: true,
};
