export const defaultInitialValues = {
  michigan_vra: {
    //ApplicantInformationSection
    gaming_or_sports_betting: null,
    initial_or_five_year: null,
    is_registered_with_lara: null,
    registered_with_lara_explanation: "",
    type_of_tax: "",
    is_phys_bus_address_same: null,
    authorized_contact_person_id: null,

    //BusinessInformationSection
    name_entities_intends_conducts: "",
    description_goods_services: "",
    is_affiliated_marketer: null,
    is_gross_or_adjusted_receipts: null,
    is_publicly_traded: null,

    //EntityDisclosureSection
    total_ownership_percentage: null,
  },
};
