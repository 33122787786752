import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Operation License Applicant",
  },

  {
    type: fieldTypes.bdBusinessSelect,
    label: "Operation License Applicant",
    name: "maryland_principal_entity.operation_license_applicant_id",
    helperText: "Select the Operation License Applicant",
  },
  {
    type: fieldTypes.subHeader,
    label: "Point of Contact",
  },

  {
    type: fieldTypes.bdPersonSelect,
    label: "Contact Person",
    name: "maryland_principal_entity.contact_person_id",
    helperText: "Select your Point of Contact",
  },
  {
    type: fieldTypes.subHeader,
    label: "Materials",
  },
  {
    type: fieldTypes.label,
    label:
      "Please provide the nature and results of any other material reorganization, readjustment or success of the Principal Entity, holding, intermediary, subsidiary or affiliate. Describe the acquisition or disposition of any material amount of assets otherwise then in the normal course of business and any material change in the way business was conducted by the Principal Entity, holding company, intermediary, subsidiary or affiliate because of such an event:",
  },
  {
    type: fieldTypes.text,
    label: "Description",
    name: "maryland_principal_entity.appendix_20_details",
    rows: 4,
    xs: 12,
  },
  {
    type: fieldTypes.subHeader,
    label: "Relationship of Pirncipal Entity and the Applicant",
  },
  {
    type: fieldTypes.label,
    label:
      "Provide a description of the relationship between the Principal Entity and the Applicant for the Video Lottery Terminal Operation License, and include any terms and conditions that affect ownership and control:",
  },
  {
    type: fieldTypes.text,
    label: "Relationship Description",
    name: "maryland_principal_entity.principal_entity_and_applicant_relationhip",
    rows: 4,
    xs: 12,
  },
];

export default [
  {
    type: "form",
    formSchema,
    endpoint: "bdSupplement",
  },
];
