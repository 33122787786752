import React from "react";
import useUIStore from "../../store/useUIStore";
import { GettingStartedDialog } from "./GettingStarted/GettingStarted";

const GlobalComponents = () => {
  const state = useUIStore();

  return (
    <>
      <GettingStartedDialog
        onClose={state.closeGettingStartedDialog}
        open={state.gettingStartedDialogIsOpen}
      />
    </>
  );
};

export default GlobalComponents;
