export const defaultInitialValues = {
  tennessee_license_type_checkbox: null,
  tennessee_gaming_entity_relationship_checkbox: null,
  tennessee: {
    spouse_certificate_number: "",
    driver_license_condition_length: "",
    gaming_entity_relationship_explanation: "",
    is_spouse_us_citizen: null,
    spouse_registration_number: "",
    has_settlement: null,
    settlement_nature: "",
    settlement_other_party: "",
    settlement_disposition_date: null,
    settlement_disposition: "",
    has_tax_obligations: null,
    tax_obligations: "",
    tax_obligations_amount: "",
    tax_obligations_date: null,
    tax_obligations_reason: "",
    has_driver_license_condition: null,
    driver_license_condition_reason: "",
    driver_license_condition_status: "",
    driver_license_condition_jurisdiction: "",
  },
};

export const endpoint = "tennessee_disclosure_questions_pdSupplement";
