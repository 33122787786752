import CustomCardHeader from "components/Common/Containers/Card/CustomCardHeader";
import React from "react";
import { styled } from "@material-ui/core/styles";
import { Card, Box } from "@material-ui/core";

export const StyledCard = styled(Card)`
  height: 0px;
`;

const ListWidget = ({ title, children, headerAction }) => {
  return (
    <StyledCard variant="outlined">
      <CustomCardHeader title={title} action={headerAction} />
      <Box style={{ maxHeight: "300px", overflowY: "auto" }}>{children}</Box>
    </StyledCard>
  );
};

export default ListWidget;
