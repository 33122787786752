import React from "react";
import { citations } from "../../uiStrings";

export const columns = [
  { field: "charge_date", format: "date" },
  {
    title: "Agency Name",
    field: "law_enforcement_pd_business_entity.business_name",
  },
  { field: "nature", title: "Nature of Charge" },
  { field: "disposition" },
];

export const tableQuestions = <>{citations.tableQuestion}</>;

export const nonRequired = ["law_enforcement_pd_business_entity_id"];

export const endpoint = "citations_citationLegalHistory";

export const defaultInitialValues = {
  type: "citation",
  law_enforcement_pd_business_entity_id: null,
  nature: "",
  charge_date: null,
  disposition: "",
  participant_name: "",
};

export const modalTitle = "Citation";

export const dataHookProps = {
  defaultInitialValues,
  nonRequired,
  endpoint,
};
