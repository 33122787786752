// Nevada
import NevadaDisclosureQuestions from "components/Pages/Supplemental/PD/Nevada/NevadaDisclosureQuestions/NevadaDisclosureQuestions";
import NevadaMedicalRecreationalMarijuana from "components/Pages/Supplemental/PD/Nevada/NevadaMedicalRecreationalMarijuana/NevadaMedicalRecreationalMarijuana";
import NevadaFamilyArrests from "components/Pages/Supplemental/PD/Nevada/NevadaFamilyArrests/NevadaFamilyArrests";
import NevadaArbitrationAssociation from "components/Pages/Supplemental/PD/Nevada/NevadaArbitrationAssociation/NevadaArbitrationAssociation";

export default {
  nevada_disclosure_questions: NevadaDisclosureQuestions,
  nevada_medical_recreational_marijuana: NevadaMedicalRecreationalMarijuana,
  nevada_family_arrests: NevadaFamilyArrests,
  nevada_arbitration_association: NevadaArbitrationAssociation,
};
