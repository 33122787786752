export const columns = [

  {
    title: "From",
    field: "from_date",
    format: "date",

  },
  {
    title: "To",
    field: "to_date",
    format: "date",

  },
  { title: "Name", field: "person_name" },
  {
    title: "Address & Phone Number",
    field: "person_address_and_phone",
  },
  { title: "Relationship", field: "person_relative_type" },
  { title: "Title", field: "position" },
  { title: "Duties", field: "duties" },

]