import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm";
import React from "react";
import { endpoint, defaultInitialValues } from "./config.js";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import { useMenuOptions } from "hooks/useMenuOptions.js";
import FormikTextField from "components/Formik/fields/FormikTextField.jsx";
import BDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/BDPersonSelect";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import YesNo from "components/Formik/formGroups/YesNo.jsx";

const Form = () => {
  const { tnBEDLicenseType } = useMenuOptions();

  return (
    <Grid container spacing={1}>
      <SectionSubHeader>Application Type</SectionSubHeader>
      <SectionLabel label="Select the appropriate application type you are applying for." />
      <FormikSelect
        label="Application Type"
        name="tennessee_bed.license_type"
        options={tnBEDLicenseType}
        xs={6}
      />
      <SectionLabel label="Type of Supplier Business." />
      <FormikTextField
        label="Supplier Type"
        name="tennessee_bed.supplier_business_type"
        xs={6}
      />
      <SectionLabel label="Name of Sports Gaming Operator(s) Doing Business With (attach additional page as necessary" />
      <FormikTextField
        label="Operator Name"
        name="tennessee_bed.sports_gaming_operator_name"
        xs={6}
      />
      <SectionSubHeader>Application Contact Person</SectionSubHeader>
      <BDPersonSelect
        name="tennessee_bed.tn_bed_application_contact_bd_person_id"
        label="Contact"
        xs={4}
      />
      <SectionLabel label="Proposed date to commence operations" />
      <FormikDatePicker
        label="Proposed date"
        name="tennessee_bed.proposed_operation_date"
      />
      <SectionSubHeader label="mbe wbe vbe ceritfied" divider />
      <YesNo
        label="Has you business been certified as MBE, WBE or VBE."
        name="tennessee_bed.is_certified_mbe_wbe_vbe"
        xs={12}
      />
    </Grid>
  );
};

export default function TennesseeBEDApplicationInformation({ name }) {
  const formData = useForm({
    defaultInitialValues,
    endpoint,
  });

  return (
    <FormSectionProvider formData={formData} title={name}>
      <EnhancedFormikPage>
        <Form />
      </EnhancedFormikPage>
    </FormSectionProvider>
  );
}
