import React from "react";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Agreement or statement of intent",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload below an agreement with or a statement of intent from a Casino
        Licensee or a casino license applicant stating that the Applicant will
        be supplying the Casino with goods and services upon the Applicant
        receiving a Supplier License. Please attach at the back of Exhibit 3
        cover page.
      </>
    ),
  },
  {
    type: fieldTypes.dropZone,
    name: "indiana_supplier_license.agreement_attachment_group_id",
    description: "Agreement",
    label: "Upload Attachment",
  },
  {
    type: fieldTypes.subHeader,
    label: "certificate of good standing",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload below a certificate of good standing from the state of
        incorporation of Applicant and a certificate of good standing issued by
        the Indiana Secretary of State indicating Applicant is qualified to do
        business in the State of Indiana. Please attach at the back of Exhibit
        4A cover page.
      </>
    ),
  },
  {
    type: fieldTypes.dropZone,
    name: "indiana_supplier_license.good_standing_attachment_group_id",
    description: "Certificate",
    label: "Upload Attachment",
  },
  {
    type: fieldTypes.subHeader,
    label: "articles of incorporation or corporate charters",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload certified copies of the articles of incorporation or corporate
        charters, and amendments thereto, of Applicant and its affiliated
        companies in the
        <CustomLink to="bd_corporate_documents" />
        section. Please attach at the back of Exhibit 4D1.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "articles of association",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload articles of association in the
        <CustomLink to="bd_corporate_documents" />
        section. Please attach at the back of Exhibit 4D2 cover page.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "by-laws",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload by-laws in the
        <CustomLink to="bd_corporate_documents" />
        section. Please attach at the back of Exhibit 4D3 cover page.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "shareholders’ agreements",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload shareholders’ agreements in the
        <CustomLink to="bd_corporate_documents" />
        section. Please attach at the back of Exhibit 6D cover page.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "ownership chart",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload an ownership chart, including percentage interest of the Key
        Persons and Substantial Owners of Applicant illustrating the ultimate
        owners/real parties in interest in the
        <CustomLink to="bd_corporate_documents" />
        section. Please attach at the back of Exhibit 31.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "organizational charts",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload the organizational charts for the Applicant illustrating its
        operations and the identity of the individuals/business entities
        responsible for performing the various operations. Include
        organizational charts for Applicant’s Substantial Owners and its
        ultimate parent company in the
        <CustomLink to="bd_corporate_documents" />
        section. Please attach at the back of Exhibit 32 cover page.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Gaming contracts",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload contracts, leasing or rental agreements, or other agreements
        relating to gaming in the
        <CustomLink to="bd_contracts" /> section. Please attach at the back of
        Exhibit 6A cover page.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "purchase and sale agreements",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload purchase/sale agreements in the
        <CustomLink to="bd_contracts" /> section. Please attach at the back of
        Exhibit 6B cover page.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "management contracts",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload management contracts in the
        <CustomLink to="bd_contracts" /> section. Please attach at the back of
        Exhibit 6C cover page.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "share certificates",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload share certificates (both sides) of any business entity held by or
        on behalf of applicant in the
        <CustomLink to="bd_assets" /> section. Please attach at the back of
        Exhibit 6E cover page.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Financial Statements & Tax Returns",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Provide copies of the following documents for Applicant or its
        Substantial Owners, for the last three (3) fiscal years and for the
        period ending one (1) month prior to the date of Application, or where
        the Applicant or Substantial Owner has not existed as a Business Entity
        in any jurisdiction for the last three fiscal years, for the Applicant
        or Substantial Owner or any Predecessor Company to the extent that they
        exist for the last three (3) fiscal years:
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        • Financial Statements and state and federal tax returns, certified or
        authenticated by independent certified public accountants if the
        certified or authenticated documents have been prepared in the
        <CustomLink to="bd_financial_statements" />
        and <CustomLink to="bd_tax_returns" /> sections.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        • Management representations and lawyer’s contingency letters provided
        to certified public accountants pertaining to certified financial audits
        and all reports and correspondence that pertain to the issuance of
        Financial Statements, managerial advisory services or internal control
        recommendations in the
        <CustomLink to="bd_financial_statements" />
        section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please attach all above documents at the back of the Exhibit 27 cover
        page.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "IRS Tax Form",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Complete the IRS Tax Form 4506-T attached to your application. Return
        the original, signed by a corporate officer who is authorized to execute
        the form on behalf of the company. Please attach at the back of Exhibit
        30 cover page.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Federal tax stamp numbers",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach your federal tax stamp numbers for transporting Gaming Equipment
        held by the Applicant or any of its Substantial Owners. If the business
        entity is a corporation attach it at the back of exhibit 4A otherwise
        attach it at the back of exhibit 5C.
      </>
    ),
  },
  {
    type: fieldTypes.dropZone,
    name: "indiana_supplier_license.tax_stamp_number_attachment_group_id",
    description: "tax_stamps",
    label: "Upload Attachment",
  },
  {
    type: fieldTypes.subHeader,
    label: "Stocks",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach a copy of the contract or other instrument relating to the
        conditions under which the owner of record or subscriber holds and votes
        or has subscribed for the stock in the
        <CustomLink to="bd_owners" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Litigations",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach for pending litigation, unsatisfied judgments, decrees,
        restraining orders, or injunctive orders, the pertinent facts, including
        the type and amount of relief sought in the
        <CustomLink to="bd_litigations" /> section. Attach at the back of
        exhibit Exhibit 15(b) cover page.
      </>
    ),
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "bdSupplement",
};
