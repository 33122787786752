export const defaultInitialValues = {
  tennessee: {
    is_education_lottery_employee: null,
    is_education_lottery_board_member: null,
    is_sports_wagering_advisory_council_board_member: null,
    is_olympic_sports_team_owner_or_employee: null,
    is_sports_team_governing_member: null,
    can_affect_sporting_event_outcome: null,
  },
};

export const endpoint = "tennessee_employment_pdSupplement";
