import React from "react";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import { dayjsUTC } from "helpers/apiHelpers";
import CustomLink from "components/Common/Link";

const formatDate = (date) => dayjsUTC(date, "MM/D/YYYY");

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Renewed License",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please select below the license you are renewing (Please note, you will
        need to have previously entered the license you are renewing in the
        <CustomLink to="personal_licensing" /> section to see it appear in the
        drop down.):
      </>
    ),
  },
  {
    type: fieldTypes.asyncSelect,
    endpoint:
      "pd_oklahoma_rkela_supplement_disclosure_questions_gaming_personal_license?issuing_jurisdiction=oklahoma",
    label: "Application date • License Number • Business Name",
    getOptionLabel: (option) =>
      `${formatDate(option.application_date)} • ${option.license_number} • ${
        option.business_name
      } `,
    getOptionValue: (option) => option.id,
    xs: 8,
    required: true,
    name: "pd_oklahoma_rkela.associated_license_id",
  },
  {
    type: fieldTypes.subHeader,
    label: "Employment",
  },
  {
    type: fieldTypes.label,
    label:
      "Please select below the employment which represent the executive position you will fill:",
  },
  {
    type: fieldTypes.asyncSelect,
    endpoint:
      "pd_oklahoma_rkela_supplement_disclosure_questions_employments_applicant",
    label: "Executive Position",
    getOptionLabel: (option) => `${option.business_name} - ${option.position}`,
    getOptionValue: (option) => option.id,
    xs: 8,
    required: true,
    name: "pd_oklahoma_rkela.employment_id",
  },
  {
    type: fieldTypes.subHeader,
    label: "Additional Information",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Has anything changed since the submission of your original Key Executive application that has not been asked on the Renewal Key Executive License Application? (i.e. financial, payment of taxes, etc)",
    name: "pd_oklahoma_rkela.has_other_changes",
  },
  {
    type: fieldTypes.label,
    label: "If you have answered YES, please provide details below:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "pd_oklahoma_rkela.other_changes_details",
    rows: 4,
    xs: 12,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "pd_oklahoma_rkela_supplement_disclosure_questions_pdSupplement",
};
