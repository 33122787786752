import { CircularProgress, makeStyles } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import useInvite from "queryHooks/useInvite";
import { useSaveInvitePassword } from "queryHooks/useSaveInvitePassword";
import React from "react";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import LoginCard from "../../../components/Common/LoginCard";
import { PasswordStep } from "./PasswordStep";
import { LoginButton, StyledCardContent } from "./styles";
import { SuccesStep } from "./SuccesStep";
import { WelcomeStep } from "./WelcomeStep";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(styles);

const getStep = (props) => ({
  1: <WelcomeStep {...props} />,
  2: <PasswordStep {...props} />,
  3: <SuccesStep />,
});

export function AcceptInvite() {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  let { inviteHash } = useParams();
  const classes = useStyles();
  const [step, setStep] = React.useState(1);

  const { isLoading, isSuccess, data, error } = useInvite(inviteHash);

  const { mutate, status } = useSaveInvitePassword(inviteHash);

  React.useEffect(() => {
    if (error) {
      enqueueSnackbar("Invalid Invitation Link", {
        variant: "error",
      });
      history.push("/auth/login_page");
    }
  }, [error, enqueueSnackbar, history]);

  const handleSuccesfullSubmission = () => {
    setStep(3);
    setTimeout(function () {
      history.push("/auth/login_page");
    }, 3000);
  };

  const onSubmit = ({ password }) => {
    mutate(
      { password },
      {
        onSuccess: handleSuccesfullSubmission,
      }
    );
  };

  const { first_name } = data || {};

  const incrementStep = () => setStep((prev) => prev + 1);
  const viewProps = {
    incrementStep,
    isLoading,
    name: first_name,
    onSubmit,
    status,
  };

  return (
    <>
      <LoginButton
        className={classes.container}
        component={RouterLink}
        variant="outlined"
        to="/auth"
      >
        Login
      </LoginButton>
      <LoginCard>
        {isLoading && (
          <StyledCardContent style={{ alignItems: "center" }}>
            <CircularProgress />
          </StyledCardContent>
        )}
        {isSuccess && getStep(viewProps)[step]}
      </LoginCard>
    </>
  );
}

export default AcceptInvite;
