import {
  Box,
  CircularProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip,
  Divider,
  IconButton,
} from "@material-ui/core";
import AttachmentIcon from "@material-ui/icons/Attachment";
import Center from "components/flex/Center";
import React from "react";
import { useDropzone } from "react-dropzone";
import { animated } from "react-spring";
import useBoop from "hooks/useBoop";
import {
  DropText,
  DropzoneBox,
  FileUploadButton,
  StyledIcon,
  UploadedFilesList,
} from "../../Pages/LicensingControl/EmployeeFileUpload/styles";
import useFileDropZone from "../../Pages/LicensingControl/EmployeeFileUpload/useFileDropZone";
import ErrorIcon from "@material-ui/icons/Error";
import { dayjsUTC } from "helpers/apiHelpers";
import DeleteIcon from "@material-ui/icons/Delete";

export function DropArea({ onDrop, compact, accept }) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  });

  const { onClick, ...rootProps } = getRootProps();

  const [style, trigger] = useBoop({ rotation: 30, scale: 0.9 });

  React.useEffect(() => {
    if (isDragActive) {
      trigger();
    }
  }, [isDragActive, trigger]);

  return (
    <div {...rootProps}>
      <input {...getInputProps()} />
      <DropzoneBox $isDragActive={isDragActive} $isCompact={compact}>
        <animated.span style={style}>
          <StyledIcon />
        </animated.span>
        <Box pt={compact ? 1 : 2.5}>
          <DropText component="span">
            <Box textAlign="center">Drag & Drop your files here</Box>
          </DropText>
          <DropText component="span">
            <Box
              textAlign="center"
              fontWeight="fontWeightBold"
              pt={compact ? 1 : 2}
              pb={compact ? 1 : 2}
            >
              OR
            </Box>
          </DropText>
          <Center>
            <FileUploadButton variant="contained" onClick={onClick}>
              Browse Files
            </FileUploadButton>
          </Center>
        </Box>
      </DropzoneBox>
    </div>
  );
}

const AttachmentLink = ({ name, updated_at, dense = false, status }) => {
  const formattedDate = dayjsUTC(updated_at, "MMM D YYYY");

  return (
    <ListItem dense={dense}>
      <ListItemIcon>
        <AttachmentIcon />
      </ListItemIcon>
      {dense ? (
        <ListItemText primary={name} />
      ) : (
        <ListItemText
          primary={name}
          secondary={`Last Updated: ${formattedDate}`}
        />
      )}
      {status === "error" && (
        <ListItemIcon>
          <Tooltip title="File not uploaded correctly">
            <ErrorIcon color="error" />
          </Tooltip>
        </ListItemIcon>
      )}
    </ListItem>
  );
};

const DropZone = ({ endpoint, compact = true, accept }) => {
  const { onDrop, files, status, onDelete } = useFileDropZone({
    endpoint,
  });

  const filesSortedByLastUpdate = React.useMemo(
    () =>
      files
        ? files.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
        : [],
    [files]
  );

  return (
    <>
      <Box pl={1} pr={1}>
        <DropArea onDrop={onDrop} compact={compact} accept={accept} />
      </Box>
      {status === "loading" && (
        <Center>
          <CircularProgress />
        </Center>
      )}
      {status === "success" && (
        <Box pt={2}>
          {files.length > 0 && (
            <>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                gutterBottom
              >
                Uploaded Files:
              </Typography>
              <UploadedFilesList pt={2}>
                {filesSortedByLastUpdate.map(
                  ({ id, updated_at, label, status, error }) => (
                    <Box pb={2} key={id} display="flex" alignItems="center">
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          onDelete({ id });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>

                      <AttachmentLink
                        key={id}
                        name={label}
                        updated_at={updated_at}
                        status={status}
                      />
                      {typeof error === "string" ? (
                        <Box pl={2} pr={2}>
                          <Typography>{error}</Typography>
                        </Box>
                      ) : null}
                    </Box>
                  )
                )}
              </UploadedFilesList>
              <Divider />
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default DropZone;
