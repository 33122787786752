import React from "react";
import { formats } from "components/Common/Tables/Common/helpers";

export const columns = [
  { title: "Creditor", field: "creditor_pd_party_name" },
  { title: "Phone", field: "creditor_pd_party_phone" },
  { title: "Relative Name", field: "relative_pd_person.full_name" },
  { field: "due_date" },
  { field: "original_amount", format: formats.currency },
  { field: "outstanding_amount", format: formats.currency },
  {
    title: "Current",
    field: "is_current",
    format: "boolean",
    sortType: "boolean",
  },
];

export const tableQuestions = (
  <>
    List below the information requested with regard to any other indebtedness
    for which you, your spouse or your dependent children are obligated.
  </>
);

export const nonRequired = ["relative_pd_person_id"];

export const endpoint = "other_indebtedness_otherPayable";

export const defaultInitialValues = {
  type: "other",
  relative_pd_person_id: null,
  creditor_pd_party_id: null,
  nature_of_payable: "",
  nature_of_security: "",
  interest_rate: null,
  due_date: null,
  periodic_payment_details: "",
  original_amount: null,
  outstanding_amount: null,
  is_current: null,
};

export const modalTitle = "Debt";

export const dataHookProps = {
  defaultInitialValues,
  nonRequired,
  endpoint,
};
