import create from "zustand";

const useUIStore = create((set) => ({
  gettingStartedDialogIsOpen: false,
  openGettingStartedDialog: () => set({ gettingStartedDialogIsOpen: true }),
  closeGettingStartedDialog: () => set({ gettingStartedDialogIsOpen: false }),
  toggleGettingStartedDialog: () =>
    set((state) => ({
      gettingStartedDialogIsOpen: !state.gettingStartedDialogIsOpen.bears,
    })),
}));

export default useUIStore;
