import { getFormComponents } from "componentGenerators/helpers";
import bd_louisiana_level_two_supplement_application_information from "components/Pages/Supplemental/BD/LouisianaLevelTwo/LouisianaLevelTwoApplicationInformation/LouisianaLevelTwoApplicationInformation";
import bd_louisiana_level_two_supplement_general_information from "components/Pages/Supplemental/BD/LouisianaLevelTwo/LouisianaLevelTwoGeneralInformation/LouisianaLevelTwoGeneralInformation";
import bd_louisiana_level_two_supplement_attachments from "components/Pages/Supplemental/BD/LouisianaLevelTwo/LouisianaLevelTwoAttachments/LouisianaLevelTwoAttachments";

export default getFormComponents({
  bd_louisiana_level_two_supplement_application_information,
  bd_louisiana_level_two_supplement_general_information,
  bd_louisiana_level_two_supplement_attachments,
});
