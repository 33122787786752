import FlexRow from "components/flex/FlexRow";
import { useFormikContext } from "formik";
import React from "react";
import styled from "styled-components";
import useKeyPress from "../../../hooks/useKeyPress";

const Wrapper = styled.div`
  margin: 60px 5px;
  background: #f6f8fa;
  border: solid 1px;
  padding: 0.5rem;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 20;
  max-width: 400px;
  max-height: 90vh;
  overflow: auto;
`;

const FormikDebugger = () => {
  const { touched, errors, values, isSubmitting } = useFormikContext();
  const [showFormikDebugger, setShowFormikDebugger] = React.useState(false);

  const f2Pressed = useKeyPress("F2", process.env.NODE_ENV === "development");

  React.useEffect(() => {
    if (f2Pressed) setShowFormikDebugger((prev) => !prev);
  }, [f2Pressed]);

  if (process.env.NODE_ENV !== "development" || !showFormikDebugger)
    return null;

  return (
    <Wrapper>
      <FlexRow>
        <strong>Formik props</strong>
        <button onClick={() => setShowFormikDebugger(false)}>close</button>
      </FlexRow>
      <div>
        <code>touched:</code> {JSON.stringify(touched, null, 2)}
      </div>
      <div>
        <code>errors:</code> {JSON.stringify(errors, null, 2)}
      </div>
      <div>
        <code>values:</code> <pre>{JSON.stringify(values, null, 2)}</pre>
      </div>
      <div>
        <code>isSubmitting:</code> {JSON.stringify(isSubmitting, null, 2)}
      </div>
    </Wrapper>
  );
};

export default FormikDebugger;
