import React from "react";

import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents/";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormikCheckBoxGroup from "components/Formik/formGroups/FormikCheckBoxGroup";
import { useMenuOptions } from "hooks/useMenuOptions";
import useForm from "hooks/useForm.js";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import { Grid } from "@material-ui/core";
import { defaultInitialValues, endpoint } from "./config.js";
import BusinessAssociation from "components/Common/BusinessAssociation/BusinessAssociation.jsx";

const Form = () => {
  const {
    njQualificationRolesCheckbox,
    njAncillaryQualificationConnectionCheckbox,
  } = useMenuOptions();

  return (
    <Grid container spacing={2}>
      <BusinessAssociation
        header="Select the ancillary enterprise applicant or licensee of which you
            are a qualifier. If the enterprise is not listed add entries in one
            or more of the following sections:"
        label="Business Entity (Your Association)"
        name={"new_jersey_ancillary.qualifier_pd_business_entity_id"}
      />

      <SectionSubHeader divider>Qualifications</SectionSubHeader>
      <SectionLabel label="I am applying for qualification in connection with:" />
      <FormikCheckBoxGroup
        name="nj_ancillary_qualification_in_connection_checkbox"
        options={njAncillaryQualificationConnectionCheckbox}
      />
      <SectionLabel label="I am a qualifier because I am a(n):" />
      <FormikCheckBoxGroup
        name="nj_qualification_roles_checkbox"
        options={njQualificationRolesCheckbox}
      />
      <FormikTextField
        label="If Other role"
        name={`new_jersey_ancillary.qualification_roles_other`}
        xs={4}
      />
    </Grid>
  );
};

const NewJerseyAncillaryDisclosureQuestions = ({ title }) => {
  const formData = useForm({
    defaultInitialValues,
    endpoint,
  });

  return (
    <FormSectionWrapper title={title}>
      <FormContextProvider formData={formData}>
        <EnhancedFormikPage>
          <Form />
        </EnhancedFormikPage>
      </FormContextProvider>
    </FormSectionWrapper>
  );
};

export default NewJerseyAncillaryDisclosureQuestions;
