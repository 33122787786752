import PropTypes from "prop-types";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import React from "react";
// import { queryKey } from "components/Pages/LicensingControl/Business/config.js";
import { TabContentContainer } from "components/Pages/LicensingControl/components/DetailsDialog/Modal.styles.js";
// import useLCAttachment from "components/Pages/LicensingControl/hooks/useLCAttachment";
// import { useDropzoneEndpoints } from "components/Common/FileDropZone/useDropzoneEndpoints";
// import { FileDropzone } from "components/Common/FileDropZone/FileDropzone";

export const RecordsTab = () =>
  // {
  // entityId,
  // attachmentGroupUseState: [attachmentID, setAttachmentID],
  // initialValues,
  // }
  {
    // const shapeEntityAttachments = (lc_attachment_group_id) => {
    //   return {
    //     ...initialValues,
    //     // id: entityId,
    //     lc_attachment_group_id,
    //   };
    // };

    // const { attachments, onAttachmentsUploaded } = useLCAttachment({
    //   shapeEntityAttachments,
    //   queryKey: queryKey,
    //   endpoint: "licensing/lcBusinessEntity",
    //   attachmentID,
    //   setAttachmentID,
    // });

    // const { getAttachmentEndpoints, attachmentGroupEndpoint } =
    //   useDropzoneEndpoints();

    return (
      <TabContentContainer>
        <Box display="flex" flexDirection="column" alignItems="center" pt={2}>
          <Card variant="outlined" style={{ width: "100%" }}>
            <CardContent>
              <Typography variant="h3" gutterBottom>
                Upload Business Records / Attachments
              </Typography>

              <Box pt={2}>
                {/* <FileDropzone
                attachmentList={attachments}
                setAttachmentGroupID={(id) =>
                  onAttachmentsUploaded("_name", id)
                }
                attachmentGroupID={attachmentID}
                description="Business Attachment"
                getAttachmentEndpoints={getAttachmentEndpoints}
                attachmentGroupEndpoint={attachmentGroupEndpoint}
              /> */}
              </Box>
            </CardContent>
          </Card>
        </Box>
      </TabContentContainer>
    );
  };

RecordsTab.propTypes = {
  currentRecord: PropTypes.shape({
    id: PropTypes.number,
    lc_person: PropTypes.shape({
      lc_attachment_group_id: PropTypes.number,
    }),
  }),
  initialValues: PropTypes.shape({
    lc_person: PropTypes.object,
  }),
};
