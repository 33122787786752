import Icon from "@material-ui/core/Icon";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import cx from "classnames";
import React from "react";
import { NavLink } from "react-router-dom";
import { getActiveRoute } from "./helpers";
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";
import { withStyles } from "@material-ui/core";

const NavItemLink = ({
  classes,
  route,
  level,
  color = "orange",
  miniActive,
}) => {
  const innerNavLinkClasses =
    level === 1
      ? classes.collapseOutterItemLink +
        " " +
        cx({
          [" " + classes[color]]: getActiveRoute(route.path),
        })
      : classes.collapseInnerItemLink +
        " " +
        cx({
          [" " + classes[color]]: getActiveRoute(route.path),
        });

  const navLinkClasses =
    classes.itemLink +
    " " +
    cx({
      [" " + classes[color]]: getActiveRoute(route.path),
    });
  const itemText =
    classes.itemText +
    " " +
    cx({
      [classes.itemTextMini]: miniActive,
    });
  const collapseItemText =
    classes.collapseItemText +
    " " +
    cx({
      [classes.collapseItemTextMini]: miniActive,
    });

  return (
    <ListItem
      className={cx(
        { [classes.item]: route.icon !== undefined },
        { [classes.collapseItem]: route.icon === undefined }
      )}
    >
      <NavLink
        to={route.layout + route.path}
        className={cx(
          { [navLinkClasses]: route.icon !== undefined },
          { [innerNavLinkClasses]: route.icon === undefined }
        )}
      >
        {route.icon !== undefined && level === 1 ? (
          typeof route.icon === "string" ? (
            <Icon className={classes.itemIcon}>{route.icon}</Icon>
          ) : (
            <route.icon className={classes.itemIcon} />
          )
        ) : null}

        <ListItemText
          primary={route.name}
          disableTypography={true}
          className={cx(
            { [itemText]: route.icon !== undefined },
            { [collapseItemText]: route.icon === undefined }
          )}
        />
      </NavLink>
    </ListItem>
  );
};

export default withStyles(sidebarStyle)(NavItemLink);
