import {
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
// import ChatBubbleOutlined from "@material-ui/icons/ChatBubbleOutlined";

import FlagIcon from "@material-ui/icons/Flag";
import FlagOutlinedIcon from "@material-ui/icons/FlagOutlined";
import useAnchorEl from "hooks/useAnchorEl";
import React from "react";
import styled from "styled-components";
import { NotePopper } from "./NotePopper";

const StyledButton = styled(Button)`
  text-transform: none;
`;

// const HasNoteIcon = styled(ChatBubbleOutlined)`
//   color: ${green[600]};
// `;

const FlagCheckbox = withStyles({
  root: {
    "&$checked": {
      color: red[600],
    },
  },
  checked: {},
})((props) => (
  <Checkbox
    icon={<FlagOutlinedIcon />}
    checkedIcon={<FlagIcon />}
    color="default"
    {...props}
  />
));

const GreenCheckbox = withStyles({
  root: {
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const AddNoteButton = ({ hasNote, ...rest }) => (
  <StyledButton
    variant="text"
    // startIcon={hasNote ? <HasNoteIcon /> : <ChatBubbleOutlineOutlinedIcon />}
    startIcon={<ChatBubbleOutlineOutlinedIcon />}
    {...rest}
  >
    <Typography variant="body2">
      {hasNote ? "View Note" : "Add Note"}
    </Typography>
    {hasNote && (
      <Badge color="primary" variant="dot">
        <Box>&nbsp;</Box>
      </Badge>
    )}
  </StyledButton>
);

const StatusSection = ({ status, handleStatusChange }) => {
  return (
    <>
      <FormControlLabel
        control={<FlagCheckbox name="flagged" onChange={handleStatusChange} />}
        label="Flag"
        checked={status?.status === "flagged"}
      />

      <FormControlLabel
        control={
          <GreenCheckbox name="completed" onChange={handleStatusChange} />
        }
        label="Complete"
        checked={status?.status === "completed"}
      />
    </>
  );
};

const FormSectionActions = ({ handleStatusChange, status, updateNote }) => {
  const { anchorEl, toggle, open, setOff } = useAnchorEl();

  const note = status?.notes || "";

  const domParser = new DOMParser();
  const noteTextContent = domParser
    .parseFromString(note, "text/html")
    .documentElement.textContent.trim();

  const hasNotes = status?.["meta"]?.["notes"];
  const hasStatus = status?.["meta"]?.["status"];

  return (
    <>
      <NotePopper
        open={open}
        anchorEl={anchorEl}
        handleClose={setOff}
        note={note}
        updateNote={updateNote}
      />
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          {hasStatus && (
            <StatusSection
              status={status}
              handleStatusChange={handleStatusChange}
            />
          )}
          {hasNotes && (
            <AddNoteButton
              onClick={toggle}
              hasNote={Boolean(noteTextContent.length)}
            />
          )}
        </Box>
        <Divider />
      </Box>
    </>
  );
};

export default FormSectionActions;
