import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import styled from "styled-components";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";

export const StyledSelectCell = styled(TableCell)`
  width: 60;
`;

export const StyledCell = styled(TableCell)`
  width: 130;
  height: 40;
`;

export const StyledTable = styled(Table)`
  min-width: 650;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledTableRow = styled(TableRow)`
  background-color: ${(props) =>
    props.$isEditMode ? props.theme.palette.grey[100] : ""};
`;

export const StyledTableHead = styled(TableHead)`
  background-color: ${({ theme }) => theme.palette.grey[200]};
`;
