import { withStyles } from "@material-ui/core";
import { StyledChip } from "components/Pages/LicensingControl/components/DetailsDialog/Modal.styles.js";
import React from "react";

const TruncatedChip = withStyles(() => ({
  root: {
    maxWidth: 150,
  },
  label: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}))(StyledChip);

const LicenseCell = ({ licenses }) => {
  const licenseCount = licenses.length;

  return (
    <>
      <TruncatedChip size="small" label={licenses[0].license_type || "N/A"} />
      {licenseCount > 1 && (
        <TruncatedChip size="small" label={`+${licenseCount - 1}`} />
      )}
    </>
  );
};

export default LicenseCell;
