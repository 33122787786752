import React from "react";

export const columns = [
  {
    title: "Report Type",
    field: "report_type",
    format: "startCase",
  },
  {
    title: "Effective Year",
    field: "effective_year",
  },
  {
    title: "Country Filed",
    field: "country_filed",
  },
  {
    title: "Has Attachments",
    field: "corporate_report_attachment_group_id",
    render: ({ corporate_report_attachment_group_id }) => {
      if (corporate_report_attachment_group_id) {
        return "Yes";
      }
      return "No";
    },
  },
];

export const tableQuestions = (
  <>
    Provide a copy of all federal and foreign Corporate Income Tax forms for the
    Application for the the last 5 years.
  </>
);

export const defaultInitialValues = {
  //bd_business_entity_id: null,
  report_type: "tax_return",
  effective_year: null,
  country_filed: null,
  corporate_report_attachment_group_id: null,
};

export const nonRequired = ["corporate_report_attachment_group_id"];
