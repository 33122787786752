import { fieldTypes } from "componentGenerators/formGenerator/config";
import React from "react";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Arrests and Charges",
  },
  {
    type: fieldTypes.label,
    label:
      "Has the applicant ever had a conviction set aside, a prosecution dismissed under La. C.Cr.P. Article 893 or 894, or similar law in any jurisdiction, been discharged or had a proceeding dismissed under La. C.Cr.P. art. 978(B)(3), or similar law in any jurisdiction, or had a conviction expunged or received a pardon or executive clemency?",
  },
  {
    type: fieldTypes.yesNo,
    name: "louisiana_phfr.louisiana_personal_financial_f3",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        If YES, attach certified copies of documents relating to each matter to
        this application and reference as Section F-3 in the
        <CustomLink to="arrests" /> section.
      </>
    ),
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.subHeader,
    label: "Students loans",
  },
  {
    type: fieldTypes.label,
    label:
      "Is the applicant currently in default on the payment of any student loan? If YES, please attach a separate sheet(s) of paper providing details and reference as Section F-5.",
  },
  {
    type: fieldTypes.yesNo,
    name: "louisiana_phfr.section_f_q_5_boolean",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "louisiana_phfr.section_f_q_5_details",
    xs: 12,
    rows: 4,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "louisiana_phfr_supplement_criminal_history_pdSupplement",
};
