import React from "react";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Certificate of Good Standing",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Provide proof of registration with the Maryland Department of
        Assessments and Taxation (MD SDAT) to do business within the State. A
        “Certificate of Good Standing” must be obtained from MD SDAT (not from
        the Maryland Comptroller’s Office). Upload documents in
        <CustomLink to="bd_application_details" />
        section under the Authorized States tab.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "casino’s request for proposal",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Principal Entities replying to a casino’s request for proposal (RFP)
        must submit the original application along with the original bid that is
        submitted in response to the RFP. Please upload below if applicable.
      </>
    ),
  },
  {
    type: fieldTypes.dropZone,
    name: "maryland_principal_entity.rfp_attachment_group_id",
    description: "Gaming Building Info",
    label: "Upload Attachment",
    xs: 12,
  },
  {
    type: fieldTypes.subHeader,
    label: "Litigations",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload all documentation related to any existing litigation in
        <CustomLink to="bd_litigations" />
        section. Please make sure to attach it at the back of appendix 1.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Audited Financial Statements",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Attach all audited Financial statements for the last five years,
        including the Principal Entity’s last fiscal year. If audited financial
        statement do not exist, then provide unaudited financial statements in
        <CustomLink to="bd_financial_statements" />
        section. Please make sure to attach it at the back of appendix 1.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Audited Financial Statements",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload your Annual reports for the last five years in
        <CustomLink to="bd_financial_statements" />
        section. Please make sure to attach it the back of appendix 3 cover
        page.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Litigations",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Description of Existing Litigation. Description of any settled or closed
        litigation against the Principal Entity for the past three (3) years.
        Also describe any existing or settled or closed litigation for the past
        three (3) years for any holding, intermediary, subsidiary or affiliate.
        A description of any judgments against Principal Entity, holding
        company, intermediary, subsidiary or affiliate for the past five (5)
        years. Describe if any judgments were covered by insurance and if so the
        insurance company. Provide this in addition to Exhibit 6 and 9. Please
        upload documents in
        <CustomLink to="bd_litigations" />
        section. Please make sure to attach it the back of appendix 5 cover
        page.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Audited Financial statements",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Audited Financial statements for the last five years, including the
        Principal Entity’s last fiscal year. If audited financial statement do
        not exist, then provide unaudited financial statements in
        <CustomLink to="bd_financial_statements" />
        section. Please make sure to attach it the back of appendix 6 cover
        page.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Audited Financial statements",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload copy of the last quarterly unaudited financial statement in
        <CustomLink to="bd_financial_statements" />
        section. Please make sure to attach it the back of appendix 9 cover
        page.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Interim Reports",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload copy(ies) of any interim reports in
        <CustomLink to="bd_financial_statements" />
        section. Please make sure to attach it the back of appendix 10 cover
        page.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Ddefinitive Proxy or Information Statement (SEC)",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload copy of the last definitive Proxy or information statement (SEC)
        in
        <CustomLink to="bd_financial_statements" />
        section. Please make sure to attach it the back of appendix 11 cover
        page.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Registration Statements",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload copy of all registration statements for the last five years filed
        in accordance with the Securities Act of 1933 in
        <CustomLink to="bd_financial_statements" />
        section. Please make sure to attach it the back of appendix 12 cover
        page.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Articles of Incorporation",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload certified copies of the Articles of Incorporation, Charter and
        By-laws, and all amendments and proposed amendments in
        <CustomLink to="bd_corporate_documents" />
        section. Please make sure to attach it the back of appendix 14 cover
        page.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Ownership Table",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload current Ownership table of organization in
        <CustomLink to="bd_corporate_documents" />
        section. Please make sure to attach it the back of appendix 15 cover
        page.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "IRS Forms",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload copies of 1120 forms and 941 forms filed with the IRS in the last
        five years in
        <CustomLink to="bd_tax_returns" />
        section. Please make sure to attach it the back of appendix 16 cover
        page.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload copies of IRS 5500 form filed in the last 5 years in
        <CustomLink to="bd_tax_returns" />
        section. Please make sure to attach it the back of appendix 17 cover
        page.
      </>
    ),
  },
  {
    type: fieldTypes.subHeader,
    label: "Reference Letter",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        If Principal Entity has held a gaming license in another jurisdiction,
        attach below a letter of reference from the gaming or casino enforcement
        or regulatory agency that specifies the experiences of the agency with
        the Principal Entity and its associates and gaming operation. Attach to
        your application at the back of appendix 18.
      </>
    ),
  },
  {
    type: fieldTypes.dropZone,
    name: "maryland_principal_entity.reference_letter_attachment_group_id",
    description: "Reference Letter",
    label: "Upload Attachment",
    xs: 12,
  },
  {
    type: fieldTypes.subHeader,
    label: "Existing Vendors Chart",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload a chart of existing vendors including the name, address, phone
        and tax identification number of the vendor, type of good and/or
        services provided by the vendor, total amount of business with vendor in
        the past twenty four (24) months. Attach at the back of Appendix 19.
      </>
    ),
  },
  {
    type: fieldTypes.dropZone,
    name: "maryland_principal_entity.vendor_chart_attachment_group_id",
    description: "Existing Vendors Chart",
    label: "Upload Attachment",
    xs: 12,
  },
];

export default [
  {
    type: "form",
    formSchema,
    endpoint: "bdSupplement",
  },
];
