import BusinessIcon from "@material-ui/icons/Business";
import PersonIcon from "@material-ui/icons/Person";
import formSchemas from "componentGenerators/formGenerator/formSchemas";
import { address, businessTypeCheckBox } from "helpers/initialValues";
import PropTypes from "prop-types";
import React from "react";
import usePartySelect from "../hooks/usePartySelect";
import SelectBusinessOrPersonDialog from "../SelectBusinessOrPersonDialog";
import PermissionedCrudSelect from "./PermissionedCrudSelect";

const entityTypeDefaults = {
  business_entity: {
    formSchema: formSchemas.pdBusinessSchema,
    saveEndpoint: "pdBusinessEntity",
    formTitle: "Add / Edit Business",
    initialValues: {
      ...businessTypeCheckBox,
      business_name: "",
      business_phone: "",
      business_website: "",
      business_address: address,
    },
  },
  person: {
    formSchema: formSchemas.pdPersonSchema,
    saveEndpoint: "pdPerson",
    formTitle: "Add / Edit Person",
    initialValues: {
      is_deceased: false,
      first_name: "",
      middle_name: "",
      last_name: "",
      suffix_name: "",
      date_of_birth: null,
      place_of_birth: "",
      relative_type: null,
      occupation: "",
      email: "",
      phone_number: "",
      home_address: address,
    },
  },
};

const getOptionIcon = ({ type }) => {
  if (type === "business_entity")
    return <BusinessIcon color="primary" fontSize="small" />;
  if (type === "person") return <PersonIcon color="primary" fontSize="small" />;
  return null;
};

const PDPartySelect = ({
  name,
  label = "Party",
  endpoint = "pd_parties",
  usesPermission = true,
  ...rest
}) => {
  const {
    open,
    onClose,
    entityType,
    handleEntitySelected,
    handleAddNewOverride,
  } = usePartySelect();

  const defaults = entityTypeDefaults[entityType];

  return (
    <>
      <SelectBusinessOrPersonDialog
        handleAddPerson={() => handleEntitySelected("person")}
        handleAddBusiness={() => handleEntitySelected("business_entity")}
        open={open}
        onClose={onClose}
      />
      <PermissionedCrudSelect
        name={name}
        label={label}
        endpoint={endpoint}
        saveEndpoint={defaults?.saveEndpoint}
        usesPermission={usesPermission}
        formSchema={defaults?.formSchema || []}
        formTitle={defaults?.formTitle}
        getOptionIcon={getOptionIcon}
        handleAddNewOverride={handleAddNewOverride}
        editEnabled={false} //disabled for party select because the "views/bd_parties" attributes do not match with the saveEndpoint attributes
        isParty={true}
        {...rest}
      />
    </>
  );
};

PDPartySelect.propTypes = {
  endpoint: PropTypes.string,
  formSchema: PropTypes.array,
  formTitle: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  usesPermission: PropTypes.bool,
};

export default PDPartySelect;
