import axios from "axios";
import { useAuth } from "context/auth";
import { logout401 } from "helpers/apiHelpers";
import React from "react";

export const useMultiFileDropzone = ({
  groupId,
  setFieldValue,
  name,
  setLoading,
  setGroupList,
  groupList,
  loading,
}) => {
  const { employee_id } = useAuth();

  const getAttachmentGroups = async () => {
    if (!groupId) return [];
    const allAttGroups = await axios.get(
      `/employee/${employee_id}/attachmentGroup/${groupId}/`
    );
    return allAttGroups.data;
  };

  const syncGroupList = () => {
    getAttachmentGroups()
      .then((data) => {
        setGroupList(data);
        setLoading(false);
      })
      .catch((err) => {
        logout401(err);
        console.log(err);
      });
  };

  React.useEffect(() => {
    if (loading && groupId) {
      syncGroupList();
    } else {
      setLoading(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, groupId]);

  const addAttachmentGroup = (year, successCallback) => {
    const attGrpPayload = {
      description: year.toString(),
      parent_id: groupId,
    };

    getAttachmentGroups()
      .then((allAttGroups) => {
        axios
          .post(`/employee/${employee_id}/attachmentGroup/`, attGrpPayload)
          .then(({ data }) => {
            successCallback(data);
            if (!allAttGroups.length) {
              setFieldValue(name, data.id);
            }
            setGroupList([data, ...allAttGroups]);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        logout401(err);
        console.log(err);
      });
  };

  const removeAttachmentItem = (attachmentGroupId, itemID) => {
    const newGroupList = groupList.map((group) => ({
      ...group,
      attachments: group.attachments.filter(
        (attachment) =>
          !(attachment.id === itemID && group.id === attachmentGroupId)
      ),
    }));
    axios
      .delete(
        `/employee/${employee_id}/attachmentGroup/${attachmentGroupId}/attachment/${itemID}`
      )
      .then(() => {
        setGroupList(newGroupList);
      })
      .catch((err) => {
        logout401(err);
      });
  };

  return {
    addAttachmentGroup,
    removeAttachmentItem,
    syncGroupList,
  };
};
