import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikSelect from "components/Formik/fields/FormikSelect";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";

import { columns, tabularDataParams, tableQuestions } from "./config.js";
import FromToCurrent from "components/Formik/formGroups/FromToCurrent.jsx";
import BDBusinessFamilySelect from "components/Formik/formGroups/CrudSelect/selects/BDBusinessFamilySelect";
import BDFinancialSelect from "components/Formik/formGroups/CrudSelect/selects/BDFinancialSelect";

const OffBookAccountsForm = () => {
  const { bdBankAccountTypes } = useMenuOptions();
  return (
    <Grid container spacing={2}>
      <BDBusinessFamilySelect name="bd_business_entity_id" />

      <BDFinancialSelect />

      <SectionSubHeader label="Account Details" divider />

      <FormikSelect
        label="Account Type"
        name="account_type"
        options={bdBankAccountTypes}
        xs={4}
      />
      <FormikTextField label="Account Number" name="account_number" xs={4} />

      <FormikTextField label="Nature of Account" name="nature" xs={4} />

      <SectionSubHeader label="Account Dates" divider />

      <FromToCurrent />
    </Grid>
  );
};

const OffBookAccounts = ({ name }) => {
  const formData = useTabularData(tabularDataParams);

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <OffBookAccountsForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
    </FormSectionProvider>
  );
};

export default OffBookAccounts;
