import React from "react";
import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider";
import useTabularData from "hooks/tableHooks/useTabularData";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";
import FormikNumberField from "components/Formik/fields/FormikNumberField.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField.jsx";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import BDBusinessSelect from "components/Formik/formGroups/CrudSelect/selects/BDBusinessSelect";
import BDPartySelect from "components/Formik/formGroups/CrudSelect/selects/BDPartySelect";
import { InfoAlert } from "components/Layout/Alert/InfoAlert.jsx";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <BDPartySelect
        name="bd_party_id"
        label="Applicant or Related Business"
        xs={6}
      />
      <BDBusinessSelect
        name="tax_agency_entity_id"
        label="Taxing Agency"
        xs={6}
      />
      <FormikTextField label="Type of Tax" name="type_of_tax" xs={4} />
      <FormikDatePicker
        type="yearMonth"
        label="Taxing Period"
        name="taxing_period"
        xs={4}
      />
      <FormikNumberField label="Amount" name="amount" xs={4} />
    </Grid>
  );
};

const MichiganTaxLiabilities = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "bdTaxLiability",
  });

  return (
    <FormSectionProvider
      title={name}
      formData={formData}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default MichiganTaxLiabilities;
