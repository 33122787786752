import PropTypes from "prop-types";
import {
  AccordionActions,
  Box,
  Button,
  Divider,
  IconButton,
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import CreateIcon from "@material-ui/icons/Create";
import React from "react";
import styled from "styled-components";
import DeleteIcon from "@material-ui/icons/Delete";
import FlexRow from "components/flex/FlexRow";

const StyledAccordian = styled(Accordion)`
  background-color: ${({ theme }) => theme.palette.grey[50]};
`;

const AccordianItem = ({
  summary,
  open,
  toggle,
  children,
  handleSubmit,
  handleDelete,
  isSubmitting,
}) => {
  return (
    <StyledAccordian
      variant="outlined"
      TransitionProps={{ unmountOnExit: true }}
      expanded={open}
      onChange={toggle}
    >
      <FlexRow>
        <Box width={1}>
          <AccordionSummary>{summary}</AccordionSummary>
        </Box>
        <Box pr={1} display="flex" alignItems="center">
          {!open && (
            <IconButton onClick={toggle}>
              <CreateIcon />
            </IconButton>
          )}
          {handleDelete && (
            <IconButton onClick={handleDelete}>
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      </FlexRow>
      <Divider />
      <AccordionDetails style={{ backgroundColor: "white" }}>
        <Box pt={2} pb={1}>
          {children}
        </Box>
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        <Button size="small" onClick={toggle}>
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          Save
        </Button>
      </AccordionActions>
    </StyledAccordian>
  );
};

AccordianItem.propTypes = {
  children: PropTypes.node,
  handleDelete: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  open: PropTypes.bool,
  summary: PropTypes.node,
  toggle: PropTypes.func,
};

export default AccordianItem;
