import WestVirginiaSWILApplicationInformation from "components/Pages/Supplemental/BD/WestVirginiaSWIL/WestVirginiaSWILApplicationInformation/WestVirginiaSWILApplicationInformation";
import WestVirginiaSWILAttachments from "components/Pages/Supplemental/BD/WestVirginiaSWIL/WestVirginiaSWILAttachments/WestVirginiaSWILAttachments";
import { getFormComponents } from "componentGenerators/helpers";

const pageSchemas = {
  bd_west_virginia_swil_supplement_application_information: WestVirginiaSWILApplicationInformation,
  bd_west_virginia_swil_supplement_attachments: WestVirginiaSWILAttachments,
};

export default {
  ...getFormComponents(pageSchemas),
};
