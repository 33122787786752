const defaultInitialValues = {
  illinois: {
    pd_q32_boolean: null,
    pd_q32_details: ""
  }
};

const columns = [
  { field: "type", format: "startCase" },
  { field: "license_type", format: "startCase" },
  { field: "agency_name" },
  { title: "Business Name", field: "pd_business_entity.business_name" },
]


const IllinoisLicensesAndPermitsItems = {
  questions: {
    pd_q32_boolean:
      "Have you or any of your Relatives ever held more than a 10% direct, Indirect or Attributed ownership interest in any Business Entity which holds a license under the Act or which has applied or intends to apply for a license under the Act?",
  },
  tableQuestion:
    "Illinois requires you to list any business, technical or professional licenses, permits, certifications that you have held, applied for, or otherwise been affiliated with, in any state or jurisdiction, including Illinois. Review all your licenses below and be sure they are classified correctly.",
};

export { IllinoisLicensesAndPermitsItems, defaultInitialValues, columns };
