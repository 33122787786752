import React from "react";
import * as Sentry from "@sentry/react";
import Center from "components/flex/Center";

const Fallback = () => {
  return <Center>An error has occurred</Center>;
};

// eslint-disable-next-line react/display-name
const withErrorBoundary = (Component) => (props) => {
  return (
    <Sentry.ErrorBoundary fallback={Fallback}>
      <Component {...props} />
    </Sentry.ErrorBoundary>
  );
};

export default withErrorBoundary;
