import { Grid } from "@material-ui/core";
import {
  SectionSubHeader,
  SectionLabel,
} from "components/Common/LayoutComponents/index.js";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikInlineTable from "components/Common/Tables/FormikInlineTable/FormikInlineTable";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone.jsx";
import FormikGoogleLookup from "components/Formik/fields/FormikGoogleLookup.jsx";
import FormikNumberField from "components/Formik/fields/FormikNumberField.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import PDRelativeSelect from "components/Formik/formGroups/CrudSelect/selects/PDRelativeSelect.jsx";
import { FormikAddressFields } from "components/Formik/formGroups/FormikAddressFields.jsx";

import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import ColumnSum from "components/Layout/SectionsLayout/ColumnSum.jsx";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import {
  columns,
  dataHookProps,
  modalTitle,
  tableQuestions,
} from "./config.js";
import usePartyEntityColumns from "./usePartyEntityColumns";

/*
 *  Removing owner_pd_party_name due to error on patch, this should be moved to the API - possibly the controller
 */
const modifySubmissionValues = (values) => {
  return {
    ...values,
    realestate_ownership: values.realestate_ownership.map(
      ({ owner_pd_party_name: _owner_pd_party_name, ...ownership }) => ownership
    ),
  };
};

const OwnershipForm = () => {
  return (
    <Grid container spacing={2}>
      <PDRelativeSelect label="Owner" name="owner_pd_party_id" />

      <FormikNumberField
        label="Ownership Percent"
        name="ownership_percent"
        type="percent"
        xs={6}
      />
    </Grid>
  );
};

const Form = () => {
  const { getPartyEntityColumns } = usePartyEntityColumns();

  return (
    <Grid container spacing={2}>
      <PDRelativeSelect
        label="Spouse or Child (If Applicable)"
        header="Select if held by Spouse or Dependent Child"
      />
      <SectionSubHeader>Property Address</SectionSubHeader>

      <FormikGoogleLookup
        fields={{
          address: "property_address",
        }}
      />

      <FormikAddressFields addressPrefix="property_address." />
      <SectionSubHeader label="Property Details" divider />
      <FormikDatePicker label="Date Acquired" name="acquisition_date" />
      <FormikTextField label="Owner of Record" name="owner_of_record" xs={4} />
      <FormikTextField
        label="Purpose of property"
        name="property_purpose"
        xs={4}
      />
      <FormikTextField
        label="Property Size"
        name="property_size"
        helperText="Lot Size / Square Footage of Building"
        xs={4}
      />
      <FormikTextField label="Type of Property" name="property_type" xs={4} />
      <FormikNumberField label="Annual Income" name="annual_income" xs={4} />
      <FormikNumberField label="Down Payment" name="down_payment" xs={4} />
      <FormikNumberField
        label="Monthly Rental Income if Any"
        name="monthly_income"
        xs={4}
      />
      <SectionSubHeader
        label="Ownership by You, Spouse or Dependent Child"
        divider
      />
      <FormikNumberField
        label="Percent Owned"
        name="ownership_percent"
        type="percent"
        helperText="By You, Spouse or Dependent"
        xs={4}
      />
      <FormikNumberField
        label="Purchase Price of % Owned"
        name="purchase_price"
        xs={4}
      />
      <FormikNumberField
        label="Market Value of % Owned"
        name="market_value"
        xs={4}
      />
      <SectionSubHeader label="Additional Owners" divider />
      <SectionLabel label="Add additional Individuals or Entities Sharing Interest" />
      <Grid item xs={12}>
        <FormikInlineTable
          name="realestate_ownership"
          columns={getPartyEntityColumns()}
          addButtonText={"Add Owner"}
          defaultInitialValues={{
            owner_pd_party_id: null,
            ownership_percent: null,
          }}
          form={<OwnershipForm />}
          dialogTitle="Other Owners"
        />
      </Grid>

      <SectionSubHeader label="Real Estate Interests" divider />
      <FormikFileDropzone
        description="Real Estate Interests"
        label="Upload Attachment"
        name="real_estate_interests_attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};

const RealEstateInterests = ({ name }) => {
  const formData = useTabularData({
    ...dataHookProps,
    patchValues: modifySubmissionValues,
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={modalTitle}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        footer={
          <ColumnSum
            name="market_value"
            data={formData.data}
            label="Total Market Value"
          />
        }
        columns={columns}
        addButtonText="Add Record"
      />
    </FormSectionProvider>
  );
};

export default RealEstateInterests;
