import PropTypes from "prop-types";
import React from "react";
import { startCase } from "lodash";
import { Box } from "@material-ui/core";
import BulkActions from "../BulkActions";
import { permReduceTypes } from "../permissionReducer";
import { renameTopLevel } from "../helpers";
import {
  ActionsBox,
  CustomTypography,
  StyledDivider,
  StyledTreeItemTopLevel,
} from "./StyledComponents";

const SupplementTab = ({ topLevel, state, dispatch, topLevelNode, index }) => (
  <Box key={topLevel}>
    <StyledTreeItemTopLevel
      key={topLevel}
      nodeId={topLevel}
      label={renameTopLevel(topLevel)}
    >
      <ActionsBox justifyContent="space-around">
        <CustomTypography variant="body1">
          {startCase(topLevel)}
        </CustomTypography>
        <BulkActions
          state={state[topLevel]}
          name={`${topLevel}`}
          dispatch={dispatch}
          dispatchOptions={{
            edit_all: {
              type: permReduceTypes.supplement_edit_all,
              name: "pd_supplement",
            },
            view_all: {
              type: permReduceTypes.supplement_view_all,
              name: "pd_supplement",
            },
          }}
        />
      </ActionsBox>
    </StyledTreeItemTopLevel>
    {topLevelNode.length - 1 > index ? <StyledDivider /> : null}
  </Box>
);

SupplementTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  topLevel: PropTypes.string.isRequired,
  topLevelNode: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
};

export default SupplementTab;
