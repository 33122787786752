import { useUpdateFormStatus } from "context/formStatus";
import { useSnackbar } from "notistack";

const useUpdateNote = (status) => {
  const updateStatus = useUpdateFormStatus();
  const { enqueueSnackbar } = useSnackbar();
  const updateNote = ({ note }) => {
    updateStatus({ status: status.currentStatus, notes: note }, status.id)
      .then(() => {
        enqueueSnackbar("Note Saved", {
          variant: "info",
          autoHideDuration: 2000,
        });
      })
      .catch(() => {
        enqueueSnackbar("Unable to Save Note", {
          variant: "error",
        });
      });
  };

  return { updateNote };
};

export default useUpdateNote;
