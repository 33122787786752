import {
  Button,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
} from "@material-ui/core";
import { Form, Formik, useFormikContext } from "formik";
import React, { useState } from "react";
import { initialValues } from "./config";
import { StyledCardActions, StyledCardContent } from "./styles";
import styled from "styled-components";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import FormikToggleText from "components/Formik/fields/FormikToggleText";

const RuleListItemText = styled(ListItem)`
  color: ${({ validate }) => (validate ? "green" : "red")};
`;

const CheckMarks = ({ checked }) => {
  return (
    <ListItemIcon>
      {checked ? (
        <CheckBoxIcon style={{ color: "green" }} />
      ) : (
        <CheckBoxOutlineBlankIcon />
      )}
    </ListItemIcon>
  );
};

export const PasswordValidationDisplay = ({ setHasError }) => {
  const [isMinLength, setIsMinLength] = useState(false);
  const [hasUpper, setHasUpper] = useState(false);
  const [hasLower, setHasLower] = useState(false);
  const [hasSymbol, setHasSymbol] = useState(false);
  const [hasDigit, setHasDigit] = useState(false);
  const [isMatch, setIsMatch] = useState(false);
  const {
    values: { password, passwordConfirm },
    values,
  } = useFormikContext();

  React.useEffect(() => {
    setIsMinLength(password.length >= 8);
    setHasUpper(password.length && password.match(/[A-Z]/g));
    setHasLower(password.length && password.match(/[a-z]/g));
    setHasSymbol(
      password.length && password.match(/[-!$%^&*()_+|~=@`{}[\]:";'<>?,./]/g)
    );
    setHasDigit(password.length && password.match(/[0-9]/g));
    setIsMatch(password !== "" && passwordConfirm === password);
  }, [values, password, passwordConfirm]);

  React.useEffect(() => {
    if (
      isMinLength &&
      hasUpper &&
      hasLower &&
      hasSymbol &&
      hasDigit &&
      isMatch
    ) {
      setHasError(false);
    } else {
      setHasError(true);
    }
  }, [
    isMinLength,
    hasUpper,
    hasLower,
    hasSymbol,
    hasDigit,
    isMatch,
    setHasError,
  ]);

  return (
    <List>
      <RuleListItemText validate={isMinLength}>
        <CheckMarks checked={isMinLength} />
        <ListItemText primary="At least 8 characters" />
      </RuleListItemText>

      <RuleListItemText validate={hasUpper}>
        <CheckMarks checked={hasUpper} />
        <ListItemText primary="At least one uppercase character" />
      </RuleListItemText>

      <RuleListItemText validate={hasLower}>
        <CheckMarks checked={hasLower} />
        <ListItemText primary="At least one lowercase character" />
      </RuleListItemText>

      <RuleListItemText validate={hasSymbol}>
        <CheckMarks checked={hasSymbol} />
        <ListItemText primary="At least one symbol" />
      </RuleListItemText>

      <RuleListItemText validate={hasDigit}>
        <CheckMarks checked={hasDigit} />
        <ListItemText primary="At least one digit" />
      </RuleListItemText>

      <RuleListItemText validate={isMatch}>
        <CheckMarks checked={isMatch} />
        <ListItemText primary="Passwords must match" />
      </RuleListItemText>
    </List>
  );
};

export const PasswordStep = ({ onSubmit, requiresCurrent = false }) => {
  const [hasError, setHasError] = useState(true);

  const newInitialValues = requiresCurrent
    ? { ...initialValues, oldPassword: "" }
    : initialValues;

  return (
    <Formik
      initialValues={newInitialValues}
      onSubmit={(values, actions) => {
        if (!hasError) {
          onSubmit(values);
          actions.resetForm();
        } else {
          actions.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <StyledCardContent>
              <Grid container spacing={1}>
                {requiresCurrent ? (
                  <FormikToggleText
                    label="Current Password"
                    name="oldPassword"
                    autocomplete="current-password"
                    xs={12}
                  />
                ) : null}
                <Grid item xs={12} />

                <FormikToggleText
                  autocomplete="new-password"
                  label="New Password"
                  name="password"
                  xs={12}
                />
                <Grid item xs={12} />

                <FormikToggleText
                  label="Confirm New Password"
                  name="passwordConfirm"
                  autocomplete="new-password"
                  xs={12}
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordValidationDisplay setHasError={setHasError} />
              </Grid>
            </StyledCardContent>

            <StyledCardActions>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Set Password
              </Button>
            </StyledCardActions>
          </Form>
        );
      }}
    </Formik>
  );
};
