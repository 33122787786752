import React from "react";
import { Box } from "@material-ui/core";
import ShortDate from "../Options/ShortDate";
import BreakAddress from "../Options/BreakAddress";
import TableRows from "../TableRows";
import CombinedColumns from "../Options/CombinedColumns";
import OrderBy from "components/DevTools/PdfTools/Tools/DataIndex/Options/OrderBy.js";

export default function Options() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginBottom: "20px",
      }}
    >
      <Box
        component="div"
        style={{
          padding: "0px 0px 20px 25px",
          fontWeight: "bold",
          marginRight: "10px",
        }}
        fontSize={16}
      >
        Options:
      </Box>
      <ShortDate />
      <BreakAddress />
      <CombinedColumns />
      <OrderBy />
      <div>
        <TableRows />
      </div>
    </div>
  );
}
