import { Typography } from "@material-ui/core";
import React from "react";
import { StyledCardContent } from "./styles";

export const SuccesStep = () => (
  <StyledCardContent>
    <Typography variant="h6" align="center" style={{ paddingBottom: "10px" }}>
      Success!
    </Typography>
    <Typography align="center">
      You will be redirected to the login page shortly...
    </Typography>
  </StyledCardContent>
);
