import { useMenuOptions } from "hooks/useMenuOptions";
import useForm from "hooks/useForm";
import React from "react";
import { generateFullAddress } from "helpers/addressHelpers";
import useToggle from "hooks/useToggle.js";

import { FormikDialog } from "components/Formik/formContainers/FormikDialog.jsx";
import { useFormikContext } from "formik";
import { useAuth } from "context/auth.js";
import { AddressForm, addressIntitialValues } from "./InlineAddressTable";
import FormikAsyncSelect from "components/Formik/fields/FormikAsyncSelect";

const FormikAsyncBDAddressSelect = ({ name, label, endpoint }) => {
  const { on, setOn, setOff } = useToggle();
  const { bd_organization_id, id } = useAuth();
  const { setFieldValue, resetForm, setSubmitting } = useFormikContext();

  const handleAddAddressOnSuccess = (data) => {
    setFieldValue(name, data.id);
  };

  const { formikProps } = useForm({
    defaultInitialValues: addressIntitialValues,
    queryKey: endpoint,
    saveEndpoint: `bdOrganization/${bd_organization_id}/bdAddress`,
    fetchEnabled: false,
    successCallback: handleAddAddressOnSuccess,
  });

  const { bdAddressPurposeCheckboxOptions } = useMenuOptions();

  function handleSubmit(item) {
    formikProps.onSubmit(
      { bd_business_entity_id: parseInt(id), ...item },
      { setSubmitting }
    );
    resetForm();
    setOff();
  }

  return (
    <>
      <FormikDialog
        onCancel={setOff}
        open={on}
        {...formikProps}
        title="Add Address"
        onSubmit={handleSubmit}
      >
        <AddressForm
          fields={{
            address: "address",
            website: "website",
            phone: "phone",
          }}
          addressPrefix={"address."}
          items={bdAddressPurposeCheckboxOptions}
        />
      </FormikDialog>

      <FormikAsyncSelect
        name={name}
        label={label}
        endpoint={endpoint}
        handleAddOption={setOn}
        getOptionLabel={({ address }) => generateFullAddress(address)}
        getOptionValue={({ id }) => id}
        xs={6}
      />
    </>
  );
};

export default FormikAsyncBDAddressSelect;
