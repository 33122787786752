import { Box, Link } from "@material-ui/core";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

const InlineLink = ({ children, color = "primary", to, ...props }) => (
  <>
    &nbsp;
    <Link
      color={color}
      underline="always"
      component={RouterLink}
      to={to}
      {...props}
    >
      <Box fontWeight="fontWeightMedium" display="inline">
        {children}
      </Box>
    </Link>
    &nbsp;
  </>
);

export default InlineLink;
