import {
  employmentTableColumns,
  employmentTableQuestions,
  employmentTableEndpoint,
  employmentTableSaveEndpoint,
  employmentTableFormSchema,
  ownershipTableColumns,
  ownershipTableQuestions,
  ownershipTableEndpoint,
  ownershipTableSaveEndpoint,
  ownershipTableFormSchema,
} from "./config.js";

const schema = [
  {
    type: "table",
    title: "Family Employment",
    endpoint: employmentTableEndpoint,
    saveEndpoint: employmentTableSaveEndpoint,
    formSchema: employmentTableFormSchema,
    columns: employmentTableColumns,
    infoAlerts: [
      {
        severity: "info",
        body: employmentTableQuestions,
      },
    ],
  },
  {
    type: "table",
    title: "Family Business Involvement",
    endpoint: ownershipTableEndpoint,
    saveEndpoint: ownershipTableSaveEndpoint,
    formSchema: ownershipTableFormSchema,
    columns: ownershipTableColumns,
    infoAlerts: [
      {
        severity: "info",
        body: ownershipTableQuestions,
      },
    ],
  },
];

export default schema;
