import { withStyles } from "@material-ui/core";
import { StyledChip } from "components/Pages/LicensingControl/components/DetailsDialog/Modal.styles.js";
import React from "react";

const TruncatedChip = withStyles(() => ({
  root: {
    maxWidth: 150,
  },
  label: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}))(StyledChip);

const AgencyCell = ({ agencies }) => {
  const agencyCount = agencies.length;
  return (
    <>
      <TruncatedChip size="small" label={agencies[0] || "N/A"} />
      {agencyCount > 1 && (
        <TruncatedChip size="small" label={`+${agencyCount - 1}`} />
      )}
    </>
  );
};

export default AgencyCell;
