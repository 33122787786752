import { Button, Typography } from "@material-ui/core";
import React from "react";
import { StyledCardActions, StyledCardContent } from "./styles";

export const WelcomeStep = ({ incrementStep, name }) => (
  <>
    <StyledCardContent>
      <Typography variant="h3" align="center" style={{ paddingBottom: "30px" }}>
        Welcome, to OneComply <strong>{name}</strong>.
      </Typography>
      <Typography variant="h5" align="center">
        To activate your account, you will be required to create a password in
        the next step.
      </Typography>
    </StyledCardContent>
    <StyledCardActions style={{ paddingTop: "40px" }}>
      <Button
        onClick={incrementStep}
        style={{
          width: "50%",
        }}
        variant="contained"
        color="primary"
      >
        Next
      </Button>
    </StyledCardActions>
  </>
);
