import PropTypes from "prop-types";
import CrudSelect, {
  formSchemas,
} from "components/Formik/formGroups/CrudSelect/CrudSelect";
import React from "react";
import BusinessIcon from "@material-ui/icons/Business";
import useBdIdAddedToAddress from "../hooks/useBdIdAddedToAddress";

const BDGovernmentAgencySelect = ({
  name = "tax_agency_bd_business_entity_id",
  label = "Tax Agency",
  header = "Tax Agency",
  endpoint = "views/bd_business_entities?is_government=true",
  formTitle = "Add / Edit Business",
  formSchema = formSchemas.bdBusinessSchema,
  ...rest
}) => {
  const modifySubmittedValues = useBdIdAddedToAddress();

  return (
    <CrudSelect
      name={name}
      label={label}
      endpoint={endpoint}
      saveEndpoint="bdBusinessEntity"
      formSchema={formSchema}
      formTitle={formTitle}
      overrideDefaultValues={{
        business_type_checkbox: { is_government: true },
      }}
      optionIcon={<BusinessIcon color="primary" fontSize="small" />}
      modifySubmittedValues={modifySubmittedValues}
      header={header}
      {...rest}
    />
  );
};

BDGovernmentAgencySelect.propTypes = {
  addEnabled: PropTypes.bool,
  endpoint: PropTypes.string,
  formSchema: PropTypes.array,
  formTitle: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  header: PropTypes.string,
};

export default BDGovernmentAgencySelect;
