import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ConfirmationDialog from "components/Common/Dialogs/ConfirmationDialog";
import React from "react";

import styled from "styled-components";

const SuccessIcon = styled(CheckCircleIcon)`
  color: ${green[500]};
  font-size: 60px;
`;

const GenerateReportForm = () => {
  const [time, setTime] = React.useState(30);

  const handleChange = (event) => {
    setTime(event.target.value);
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography>Expiration Date Range</Typography>
      </Grid>
      <Grid item xs={10}>
        <FormControl variant="outlined" size="small" fullWidth>
          <InputLabel>Date Range</InputLabel>
          <Select
            value={time}
            onChange={handleChange}
            label="Time Frame"
            size="small"
            fullWidth
          >
            <MenuItem value={30}>30 Days</MenuItem>
            <MenuItem value={90}>90 Days</MenuItem>
            <MenuItem value={6}>6 months</MenuItem>
            <MenuItem value={1}>1 Year</MenuItem>
            <MenuItem value={0}>All</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Typography style={{ paddingTop: "15px" }}>License Type(s)</Typography>
      </Grid>
      <Grid item xs={10}>
        {/* <MultiSelect
          options={licenseOptions}
          label={"License / Certification"}
          placeholder="Select License / Certification"
        /> */}
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ paddingTop: "15px" }}>Recipient(s)</Typography>
      </Grid>
      <Grid item xs={10}>
        {/* <MultiSelect
          options={recipientsOptions}
          label={"Recipient(s)"}
          placeholder="Select Recipient"
        /> */}
      </Grid>
    </Grid>
  );
};

const ReportSuccessStep = () => {
  const [isGenerating, setIsGenerating] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => setIsGenerating(false), 3000);
  }, []);

  const text = isGenerating ? "Generating Report" : "Report Sent";

  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={5}>
      <Typography variant="h3">
        <Box pb={3} fontWeight="fontWeightMedium">
          {text}
        </Box>
      </Typography>
      {isGenerating ? <CircularProgress /> : <SuccessIcon />}
    </Box>
  );
};

export const GenerateReportDialog = ({ closeDialog, ...props }) => {
  const [step, setStep] = React.useState(1);

  const isStep1 = step === 1;
  const isStep2 = step === 2;

  const handleNext = () => {
    isStep1 && setStep(step + 1);
    isStep2 && closeDialog();
  };

  return (
    <ConfirmationDialog
      title={"Expired Licenses Report"}
      confirmationButtonProps={{ variant: "contained", color: "primary" }}
      confirmationText="Send Report"
      onConfirm={handleNext}
      showDialogActions={isStep1}
      {...props}
    >
      <Box pb={2}>
        {step === 1 && <GenerateReportForm />}
        {isStep2 && <ReportSuccessStep />}
      </Box>
    </ConfirmationDialog>
  );
};
