import React from "react";
import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import FormikTextField from "components/Formik/fields/FormikTextField";
import useForm from "hooks/useForm.js";
import { defaultInitialValues, endpoint } from "./config";

import { SectionLabel } from "components/Common/LayoutComponents";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <SectionLabel label="Please list all languages spoken or written below:" />
      <FormikTextField
        label="Spoken or Written Languages"
        name="elk_valley.languages_spoken_or_written"
      />
      <SectionLabel label="List any additional information you may wish to have considered as part of this application below:" />
      <FormikTextField
        label="Additional information"
        name="elk_valley.additional_information"
      />
    </Grid>
  );
};

const ElkValleyDisclosureQuestions = ({ name }) => {
  const formData = useForm({
    defaultInitialValues,
    noValidate: true,
    endpoint,
  });
  return (
    <FormSectionWrapper title={name} formData={formData}>
      <EnhancedFormikPage title={"Personal Information"}>
        <Form />
      </EnhancedFormikPage>
    </FormSectionWrapper>
  );
};

export default ElkValleyDisclosureQuestions;
