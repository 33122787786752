import { useMutation, useQueryClient } from "react-query";
import { updateModel } from "helpers/apiHelpers";
import produce from "immer";

const usePatch = (
  queryKey,
  endpoint,
  { onSuccess, onError, shouldUpdateCache = true } = {}
) => {
  const queryCache = useQueryClient();

  function handleUpdate({ id, ...payload }) {
    const request_id = id?.id ?? id;
    return updateModel(endpoint, request_id, payload);
  }

  const updateCache = (newValue) => {
    const previousValue = queryCache.getQueryData(queryKey);
    const indexToUpdate = previousValue.findIndex(
      (item) => item.id === newValue.id
    );
    const nextState = produce(previousValue, (draft) => {
      draft[indexToUpdate] = { ...previousValue[indexToUpdate], ...newValue };
    });

    queryCache.setQueryData(queryKey, nextState);
  };

  return useMutation(handleUpdate, {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
      shouldUpdateCache && updateCache(data);
    },
    onError: (error) => {
      onError && onError(error);
    },
  });
};

export default usePatch;
