import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import cx from "classnames";
import React from "react";
import { NavLink } from "react-router-dom";
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";

import { withStyles } from "@material-ui/core";

const CollapseNavSubheader = ({
  route,
  classes,
  displaySelectedItem,
  navState,
  createLinks,
  miniActive,
}) => {
  var st = {};
  st[route["state"]] = !navState[route.state];
  const navLinkClasses = classes.itemLinkCustom;

  const itemText =
    classes.itemText +
    " " +
    cx({
      [classes.itemTextMini]: miniActive,
    });
  const collapseItemText =
    classes.collapseItemText +
    " " +
    cx({
      [classes.collapseItemTextMini]: miniActive,
    });
  return (
    <ListItem
      className={cx(
        { [classes.item]: route.icon !== undefined },
        { [classes.collapseItem]: route.icon === undefined },
        { [classes.itemStatus]: route.icon !== undefined }
      )}
    >
      <NavLink
        to={"#"}
        className={navLinkClasses}
        onClick={(e) => {
          e.preventDefault();
          displaySelectedItem(st);
        }}
      >
        {route.icon !== undefined ? (
          typeof route.icon === "string" ? (
            <Icon className={classes.itemIcon}>{route.icon}</Icon>
          ) : (
            <route.icon className={classes.itemIcon} />
          )
        ) : (
          <span className={classes.collapseItemMini}></span>
        )}
        <ListItemText
          primary={route.name}
          secondary={
            <b
              className={
                classes.caret +
                " " +
                (navState[route.state] ? classes.caretActive : "")
              }
            />
          }
          disableTypography={true}
          className={cx(
            { [itemText]: route.icon !== undefined },
            { [collapseItemText]: route.icon === undefined }
          )}
        />
      </NavLink>
      <Collapse in={navState[route.state]} unmountOnExit>
        <List
          cy-data="formsContainer"
          className={classes.list + " " + classes.collapseList}
        >
          {createLinks(route.views, 2)}
        </List>
      </Collapse>
    </ListItem>
  );
};

export default withStyles(sidebarStyle)(CollapseNavSubheader);
