import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Fade,
  IconButton,
  Popper,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Notes } from "components/Common/Notes/Notes";
import React from "react";
import styled from "styled-components";

const StyledCard = styled(Card)`
  & .MuiCardHeader-root {
    padding-bottom: 0px;
  }
  & .MuiCardContent-root {
    padding-top: ${({ theme }) => theme.spacing(1)}px;
    padding-bottom: ${({ theme }) => theme.spacing(1)}px;
  }
`;

export const NotePopper = ({
  open,
  anchorEl,
  handleClose,
  note,
  updateNote,
}) => {
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement="bottom-end"
      transition
      style={{ zIndex: "10" }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <StyledCard elevation={5}>
            <CardHeader
              subheader="Note"
              action={
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              }
            />
            <CardContent>
              <Box width={350}>
                <Notes
                  handleSave={updateNote}
                  note={note}
                  submissionCallback={handleClose}
                />
              </Box>
            </CardContent>
          </StyledCard>
        </Fade>
      )}
    </Popper>
  );
};
