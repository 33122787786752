import React from "react";
import { endpoint, defaultInitialValues } from "./config";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm.js";
import { Grid } from "@material-ui/core";
import YesNo from "components/Formik/formGroups/YesNo";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import FormikTextField from "components/Formik/fields/FormikTextField";
import CustomLink from "components/Common/Link";

const IowaClassDFinancialDataForm = () => {
  return (
    <Grid container spacing={1}>
      <SectionSubHeader>State Tax Information</SectionSubHeader>
      <YesNo
        label={
          <>
            Has the business entity filed all State income tax returns for the
            previous three (3) years? Review your
            <CustomLink to="bd_tax_returns" /> section
          </>
        }
        name="iowa_class_d.is_state_tax_filed"
        xs={12}
      />
      <YesNo
        label={
          <>
            If No, has your business entity filed an extension? If Yes, attach a
            copy of the extension application form to associated
            <CustomLink to="bd_tax_returns" /> year.
          </>
        }
        name="iowa_class_d.is_state_tax_extension_filed"
        xs={12}
      />
      <SectionLabel>
        If your business entity did not file an extension, please provide
        explanation below:
      </SectionLabel>
      <FormikTextField
        label="Explanation"
        name="iowa_class_d.io_state_tax_extension_explain"
        xs={12}
      />
      <SectionLabel>State revenue department(s) address:</SectionLabel>
      <FormikTextField
        label="Address(es)"
        name="iowa_class_d.io_state_tax_department_address"
        multiline
        rows={3}
        xs={12}
      />
      <SectionSubHeader divider>Federal Tax Information</SectionSubHeader>

      <YesNo
        label={
          <>
            Has the business entity filed all Federal income tax returns for the
            previous three (3) years? Review your
            <CustomLink to="bd_tax_returns" /> section
          </>
        }
        name="iowa_class_d.is_federal_tax_filed"
        xs={12}
      />
      <YesNo
        label={
          <>
            If No, has your business entity filed an extension? If Yes, attach a
            copy of the extension application form to associated
            <CustomLink to="bd_tax_returns" /> year.
          </>
        }
        name="iowa_class_d.is_federal_tax_extension_filed"
        xs={12}
      />
      {/* bd_tax_returns */}
      <SectionLabel>
        If your business entity did not file an extension, please provide
        explanation below:
      </SectionLabel>
      <FormikTextField
        label="Explanation"
        name="iowa_class_d.io_federal_tax_extension_explain"
        xs={12}
      />
      <SectionLabel>IRS office location:</SectionLabel>
      <FormikTextField
        label="Location"
        name="iowa_class_d.io_federal_irs_office_location"
        xs={12}
      />
      <SectionSubHeader divider>Past Entities and Projects</SectionSubHeader>
      <SectionLabel>
        Identify all dormant or shell company names used or owned by your
        business entity for the past twenty (20) years:
      </SectionLabel>
      <FormikTextField
        label="Company names"
        name="iowa_class_d.io_all_company_names_owned"
        row={3}
        xs={12}
      />
      <SectionLabel>
        Identify any failed, abandoned or dissolved business projects where the
        business entity was an investor or planner.
      </SectionLabel>
      <FormikTextField
        label="Company names"
        name="iowa_class_d.io_all_company_names_abondoned"
        row={3}
        xs={12}
      />
    </Grid>
  );
};

const IowaClassDFinancialData = () => {
  const formData = useForm({
    defaultInitialValues,
    endpoint,
  });

  return (
    <FormSectionProvider title="Tax Data" formData={formData}>
      <EnhancedFormikPage>
        <IowaClassDFinancialDataForm />
      </EnhancedFormikPage>
    </FormSectionProvider>
  );
};
export default IowaClassDFinancialData;
