import bd_indiana_supplier_license_supplement_application_information from "components/Pages/Supplemental/BD/IndianaSupplierLicense/IndianaSupplierLicenseApplicationInformation/IndianaSupplierLicenseApplicationInformation";
import bd_indiana_supplier_license_supplement_attachments from "components/Pages/Supplemental/BD/IndianaSupplierLicense/IndianaSupplierLicenseAttachments/IndianaSupplierLicenseAttachments";
import bd_indiana_supplier_license_supplement_applicant_details from "components/Pages/Supplemental/BD/IndianaSupplierLicense/IndianaSupplierLicenseApplicantDetails/IndianaSupplierLicenseApplicantDetails";
import bd_indiana_supplier_license_supplement_litigations from "components/Pages/Supplemental/BD/IndianaSupplierLicense/IndianaSupplierLicenseLitigations/IndianaSupplierLicenseLitigations";
import bd_indiana_supplier_license_supplement_political_contributions from "components/Pages/Supplemental/BD/IndianaSupplierLicense/IndianaSupplierLicensePoliticalContributions/IndianaSupplierLicensePoliticalContributions";
import { getFormComponents } from "componentGenerators/helpers";

const pageSchemas = {
  bd_indiana_supplier_license_supplement_application_information,
  bd_indiana_supplier_license_supplement_attachments,
  bd_indiana_supplier_license_supplement_applicant_details,
  bd_indiana_supplier_license_supplement_litigations,
};

export default {
  ...getFormComponents(pageSchemas),
  bd_indiana_supplier_license_supplement_political_contributions,
};
