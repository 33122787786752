import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Application Type",
  },
  {
    type: fieldTypes.label,
    label: "Please select below the type of application your are applying for:",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "michigan_isbigo.application_type_checkbox",
    dynamicMenuItems: ["michiganISBIGOLicensesTypeCheckbox"],
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "michigan_isbigo_supplement_applications_details_pdSupplement",
};
