import PropTypes from "prop-types";
import { Box, Grid, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { AddOutlinedButton } from "components/Common/CustomButtons/AddOutlinedButton";
import DeleteConfirmation from "components/Common/Dialogs/DeleteConfirmation";
import React from "react";
import TextField from "../../../Common/Inputs/TextField";
import useMultiInlineFields from "./useMultiInlineFields";
import Center from "components/flex/Center";

const DeletableTextField = ({
  initialValue,
  onBlur,
  onDelete,
  label,
  autoFocus,
}) => {
  return (
    <Box display="flex" pb={0.5}>
      <TextField
        label={label}
        defaultValue={initialValue}
        onBlur={onBlur}
        autoFocus={autoFocus}
      />
      <IconButton edge="end" aria-label="delete" onClick={onDelete}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

DeletableTextField.propTypes = {
  autoFocus: PropTypes.bool,
  initialValue: PropTypes.string,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onDelete: PropTypes.func,
};

const MultiInlineFields = ({
  name,
  label,
  addFirstRecordButtonText,
  addAdditonalRecordButtonText,
  attributeName = "name",
}) => {
  const {
    records,
    handleInputBlur,
    handleAddRecord,
    handleDeleteRecord,
    deleteDialogProps,
  } = useMultiInlineFields({
    name,
    attributeName,
  });

  const recordTextFields = records.map(
    ({ tempMeta: { id, isNew }, ...recordData }, idx) => (
      <DeletableTextField
        label={`${label} #${idx + 1}`}
        key={id}
        initialValue={recordData[attributeName]}
        handleInputBlur={handleInputBlur}
        onBlur={(e) => handleInputBlur(e.target.value, id)}
        onDelete={() => handleDeleteRecord(id)}
        autoFocus={isNew}
      />
    )
  );

  return (
    <>
      <DeleteConfirmation
        open={deleteDialogProps.open}
        onConfirm={deleteDialogProps.onConfirm}
        onCancel={deleteDialogProps.onCancel}
      />
      <Grid item xs={12}>
        {records.length ? (
          <Box display="flex" flexDirection="column" width={0.5}>
            {recordTextFields}
            <AddOutlinedButton variant="text" onClick={handleAddRecord}>
              {addAdditonalRecordButtonText}
            </AddOutlinedButton>
          </Box>
        ) : (
          <Center>
            <AddOutlinedButton onClick={handleAddRecord}>
              {addFirstRecordButtonText}
            </AddOutlinedButton>
          </Center>
        )}
      </Grid>
    </>
  );
};

MultiInlineFields.propTypes = {
  addAdditonalRecordButtonText: PropTypes.string,
  addFirstRecordButtonText: PropTypes.string,
  attributeName: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
};

export default MultiInlineFields;
