import { getFormComponents } from "componentGenerators/helpers";
import michigan_limited_pd_supplement_disclosure_questions from "components/Pages/Supplemental/PD/MichiganLimitedPD/MichiganLimitedPDDisclosureQuestions/MichiganLimitedPDDisclosureQuestions";
import michigan_limited_pd_supplement_ownership from "components/Pages/Supplemental/PD/MichiganLimitedPD/MichiganLimitedPDOwnership/MichiganLimitedPDOwnership";
import michigan_limited_pd_supplement_compliance from "components/Pages/Supplemental/PD/MichiganLimitedPD/MichiganLimitedPDCompliance/MichiganLimitedPDCompliance";
import michigan_limited_pd_supplement_criminal_history from "components/Pages/Supplemental/PD/MichiganLimitedPD/MichiganLimitedPDCriminalHistory/MichiganLimitedPDCriminalHistory";
import michigan_limited_pd_supplement_attachments from "components/Pages/Supplemental/PD/MichiganLimitedPD/MichiganLimitedPDAttachments/MichiganLimitedPDAttachments";

export default getFormComponents({
  michigan_limited_pd_supplement_disclosure_questions,
  michigan_limited_pd_supplement_ownership,
  michigan_limited_pd_supplement_compliance,
  michigan_limited_pd_supplement_criminal_history,
  michigan_limited_pd_supplement_attachments,
});
