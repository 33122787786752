import axios from "axios";
import { useAuth } from "context/auth";
import useEndPoint from "hooks/useEndpoint";
import useFetch from "queryHooks/useFetch";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { sortDocumentSections } from "helpers/utils";

async function updateGeneratedPdf(
  endpoint,
  pdf_form_id,
  generated_pdf_id,
  status
) {
  const { data } = await axios.patch(
    `${endpoint}/${pdf_form_id}/generatedPdf/${generated_pdf_id}`,
    status
  );
  return data;
}

const useAttachmentData = ({
  extra_pages_attachment_group_id,
  pdfFormId,
  generatedPdfId,
  hasPdfUrl,
}) => {
  const { employee_id, account_type } = useAuth();
  const [extraAttachments, setExtraAttachments] = React.useState([]);

  const controlledPath =
    account_type === "employee" ? "personal_details_pdfForm" : "pdfForm";
  const { fullEndpoint } = useEndPoint(controlledPath);

  const queryCache = useQueryClient();

  const { mutate: mutateGeneratedPdf } = useMutation(
    (payload) =>
      updateGeneratedPdf(fullEndpoint, pdfFormId, generatedPdfId, payload),
    {
      onSuccess: () => {
        queryCache.invalidateQueries("applications");
      },
    }
  );

  const {
    data: extraAttachmentsObject,
    isLoading: isLoadingExtraAttachments,
    refetch,
  } = useFetch(
    ["extraAttachment", extra_pages_attachment_group_id],
    `attachmentGroup/${extra_pages_attachment_group_id}`,
    {
      errorMessage: "Error Retrieving Extra Pages Information",
      enabled: false,
    }
  );
  const hasAttachmentsLoaded = queryCache.getQueryData([
    "applicationAttachment",
    employee_id,
  ]);

  const { data: attachmentData, isLoading: attachmentsLoading } = useFetch(
    ["applicationAttachment", employee_id],
    "personal_details_documentRepository",
    {
      errorMessage: "Error Retrieving Attachments",
      enabled: !hasAttachmentsLoaded,
      cacheTime: 0,
      select: sortDocumentSections,
    }
  );

  const hasAttachments = !!attachmentData?.[0];
  const hasExtraAttachments = !!extraAttachmentsObject?.[0]?.attachments
    ?.length;

  React.useEffect(() => {
    if (extra_pages_attachment_group_id && hasPdfUrl) {
      refetch();
    }
  }, [extra_pages_attachment_group_id, hasPdfUrl, refetch]);

  React.useEffect(() => {
    if (hasExtraAttachments) {
      setExtraAttachments(extraAttachmentsObject[0].attachments);
    }
  }, [hasExtraAttachments, extraAttachmentsObject]);

  return {
    attachmentData,
    attachmentsLoading,
    isLoadingExtraAttachments,
    extraAttachmentsObject,
    hasAttachments,
    hasExtraAttachments,
    extraAttachments,
    mutateGeneratedPdf,
  };
};

export default useAttachmentData;
