import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.label,
    label:
      "Check any of the following privileged or professional licenses you have held individually or as part of an ownership group in this state or any other domestic or foreign jurisdiction:",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "colorado_kapa.licenses_type_checkbox",
    dynamicMenuItems: ["coloradoLicensesTypeCheckbox"],
    defaultValue: null,
  },
  {
    type: fieldTypes.label,
    label: " If selected other, please specify below:",
  },
  {
    type: fieldTypes.text,
    label: "Other",
    name: "colorado_kapa.other_type_license",
    xs: 12,
    rows: 4,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "colorado_kapa_supplement_licenses_pdSupplement",
};
