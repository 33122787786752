import React, { useReducer, createContext, useContext } from "react";
export const DispatchContext = createContext(null);
export const StateContext = createContext(null);

export const ACTIONS = {
  SET_STATE: "set_state",
  FETCH_TAG_DATA: "fetch_tag_data",
  SORT_BY_DATE: "sort_by_date",
  SORT_BY_AMOUNT: "sort_by_amount",
  RESET_FILTERS: "reset_filters",
  RESET: "reset",
};

const initialState = {
  dataIndex: "",
  selected: "",
  focusTable: [],
  column: "",
  combo: "",
  token: "",
  amountFilter: "",
  tablesWithAmount: "",
  amount: "$0",
  amountField: "",
  tableRows: 0,
  timeFilter: "",
  tablesWithDates: "",
  timePeriod: "",
  timeRange: "",
  fetchedData: "",
  fetchedDataRows: [],
  showRowsData: false,
  checkTag: "",
  checkTagType: "text",
  query: "",
  sql: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_STATE:
      return {
        ...state,
        [action.name]: action.value,
      };
    case ACTIONS.FETCH_TAG_DATA:
      return action.value.invalid.length > 0
        ? {
            ...state,
            query: action.value.query,
            fetchedData: `tag invalid: ${action.value.invalid[0].field}`,
          }
        : {
            ...state,
            query: action.value.query,
            fetchedData: `Tag Response Data: ${Object.values(
              action.value.input
            )}`,
            fetchedDataRows: action.value.tableData,
          };
    case ACTIONS.SORT_BY_DATE:
      return {
        ...state,
        tablesWithDates: state.dataIndex
          ? state.dataIndex.reduce((acc, { table, columns }) => {
              const dates = columns.filter((col) => col.includes("date"));
              if (dates.length > 0) {
                acc[table] = dates;
              }
              return acc;
            }, {})
          : [],
      };
    case ACTIONS.SORT_BY_AMOUNT:
      return {
        ...state,
        tablesWithAmount: state.dataIndex
          ? state.dataIndex.reduce((acc, { table, columns }) => {
              const tender = columns.filter(
                (col) =>
                  (col.includes("amount") && !col.includes("date")) ||
                  (col.includes("value") && !col.includes("date")) ||
                  (col.includes("balance") && !col.includes("date"))
              );
              if (tender.length > 0) {
                acc[table] = tender;
              }
              return acc;
            }, {})
          : [],
      };
    case ACTIONS.RESET_FILTERS:
      return {
        ...state,
        timeFilter: "",
        timePeriod: "",
        timeRange: "",
        amountField: "",
        amountFilter: "",
        amount: "$0",
      };
    case ACTIONS.RESET:
      return {
        ...initialState,
        dataIndex: state.dataIndex,
      };
    default:
      return state;
  }
};

export const PdfToolsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
};

export function usePdfDispatch() {
  const context = useContext(DispatchContext);
  if (context === undefined) {
    throw new Error("usePdfTools must be used within PdfToolsProvider");
  }
  return context;
}
export function usePdfState() {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("usePdfState must be used within PdfToolsProvider");
  }
  return context;
}
