/**
 * Creates the routes (including base + endpoint)
 * e.g. for employee = employee/1/passport
 *      for bdOrganization = bdOrganization/1/bd/2/bdPosition
 */
export function generateEndpoint(endpoint, auth) {
  let { employee_id, bd_organization_id, bd_business_entity_id } = auth;

  //LICENSE CONTROL ENDPOINTS
  if (
    endpoint.includes("licensing/lc") ||
    endpoint.includes("licensing/views")
  ) {
    return endpoint;
  }

  //BUSINESS ENDPOINTS
  if (bd_business_entity_id) {
    if (
      endpoint.includes("bdPerson") ||
      endpoint.includes("bdBusinessEntity") ||
      endpoint.includes("generatedPDF")
    ) {
      return `/bdOrganization/${bd_organization_id}/${endpoint}`;
    }
    return `/bdOrganization/${bd_organization_id}/bd/${bd_business_entity_id}/${endpoint}`;
  }

  //PERSONAL ENDPOINTS
  return `/employee/${employee_id}/${endpoint}`;
}

/**
 * Removes query params from the routes and get the base url
 * e.g. employee/1/passport?name=xyz  --> employee/1/passport
 */
export function removeAttributes(endpoint) {
  return endpoint.includes("?")
    ? endpoint.slice(0, endpoint.indexOf("?"))
    : endpoint;
}
