import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Business Ownership",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Does the applicant have any financial, ownership interest or other relationship with a Casino, Supplier or Vendor Licensee/Applicant?",
    name: "michigan_business_disclosure.has_interest_in_other_licensee",
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label:
      "If yes, please explain the nature of interest or relationship below:",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "michigan_business_disclosure.interest_in_other_licensee_details",
    xs: 12,
    rows: 4,
  },
];

export default [
  {
    formSchema,
    endpoint: "bdSupplement",
    type: "form",
  },
  {
    type: "readOnlyTable",
    endpoint: "views/bd_assets_non_us",
    linkTo: ["bd_assets"],
    columns: [
      {
        title: "Issuing Entity",
        field: "issuing_business_name",
      },
      {
        field: "asset_type",
        format: "startCase",
      },
      {
        field: "type_of_stock",
        format: "startCase",
      },
      {
        title: "Held From",
        field: "purchase_date",
        format: "date",
      },
      {
        field: "market_value",
        format: "currency",
      },
    ],
    dependsOnQueryCount:
      "Does the applicant have any direct, indirect or attributed legal or beneficial interest in any business entity outside the United States?",
  },
];
