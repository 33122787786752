/* eslint-disable */
import React from "react";
import TelegramIcon from "@material-ui/icons/Telegram";
import Button from "components/Common/CustomButtons/Button";

export default function SendButton(props) {
  return (
    <Button
      color="primary"
      type="submit"
      startIcon={<TelegramIcon />}
      {...props}
    >
      Send
    </Button>
  );
}
