import { Grid } from "@material-ui/core";
import {
  SectionSubHeader,
  SectionLabel,
} from "components/Common/LayoutComponents";
import Tooltip from "components/Common/Tooltip";
import FormikSelect from "components/Formik/fields/FormikSelect";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FromToCurrent from "components/Formik/formGroups/FromToCurrent";
import { useMenuOptions } from "hooks/useMenuOptions";
import React from "react";
import FormikCheckBox from "components/Formik/fields/FormikCheckBox";
import YesNo from "components/Formik/formGroups/YesNo";
import BDPartySelect from "components/Formik/formGroups/CrudSelect/selects/BDPartySelect";

const BusinessInformationForm = () => {
  const {
    formOfBusiness,
    listOfCountries,
    businessRelationshipType,
  } = useMenuOptions();
  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Incorporation Name" />
      <FormikTextField label="Incorporation Name" name="business_name" xs={4} />
      <FormikSelect
        label="Relationship"
        name="business_relationship_type"
        options={businessRelationshipType}
        xs={3}
      >
        <Tooltip title="Relationship within the organization.  If the organization is composed of a single business entity select Parent." />
      </FormikSelect>

      <SectionSubHeader label="Incorporation Details" divider />
      <YesNo
        xs={12}
        label="Is this business entity publicly listed?"
        name="is_public_company"
      />
      <YesNo
        label="A Tribe or an arm of a Tribe?"
        name="business_type_checkbox.is_tribal"
        xs={12}
      />
      <FormikSelect
        label="Form of Business"
        name="form_of_business"
        options={formOfBusiness}
        xs={4}
      />
      <FormikTextField
        label="State of Incorporation"
        name="incorporation_state"
        xs={4}
      />
      <FormikSelect
        label="Country of Incorporation"
        name="incorporation_country"
        options={listOfCountries}
        xs={4}
      />

      <SectionSubHeader label="Dates" divider />
      <FromToCurrent
        fromProps={{ label: "Date of Formation", name: "incorporation_date" }}
        toProps={{ label: "End Date of Business" }}
      />

      <SectionSubHeader label="Identification Numbers" divider />
      <FormikTextField
        label="Employer ID Number"
        helperText="For US Entities: EIN, FEIN or Federal Tax ID"
        name="employer_id_number"
        xs={6}
      >
        <Tooltip title="The Employer Identification Number (EIN), also known as the Federal Employer Identification Number (FEIN) or the Federal Tax Identification Number, is a unique nine-digit number assigned by the Internal Revenue Service (IRS) to business entities operating in the United States for the purposes of identification." />
      </FormikTextField>
      <FormikCheckBox
        label="Check if applied for"
        name="is_ein_applied"
        xs={6}
      />
      <FormikTextField
        label="Unique Entity Number (or SSN)"
        helperText="For Non-US Entities or Sole Proprietorships."
        name="unique_entity_number"
        xs={6}
      >
        <Tooltip
          title={
            <>
              Unique Entity Number field if:
              <ul>
                <li>
                  The business entity is registered outside the United States.
                </li>
                <li>The business is a sole proprietorship. Use your SSN.</li>
              </ul>
            </>
          }
        />
      </FormikTextField>
      <FormikTextField
        label="Dun and Bradstreet Identification Number"
        name="dun_bradstreet_id_number"
        xs={4}
      />

      <BDPartySelect
        label="Registered Agent"
        helperText="(if applicable)"
        name="registered_agent_bd_party_id"
        header="Registered Agent for the Business Entity"
      >
        <Tooltip
          title={
            <>
              Any Individual or Business Entity against whom service of process
              may be made on behalf of any Business Entity or that is designated
              as such by any articles of incorporation or other corporate
              filings in any state.
            </>
          }
        />
      </BDPartySelect>

      <SectionSubHeader label="Business Description" divider />
      <SectionLabel label="Provide a description of the business, including principal products, services, markets and methods of distribution." />
      <FormikTextField
        label="Business Description"
        name="business_description"
        xs={12}
        multiline
        rows={3}
      />
    </Grid>
  );
};

export default BusinessInformationForm;
