import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";

import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm";
import React from "react";
import { SectionSubHeader } from "components/Common/LayoutComponents/index";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { defaultInitialValues } from "./config";
import YesNo from "components/Formik/formGroups/YesNo";

const MichiganGamblingProblemsForm = () => {
  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Gaming Problems Questions" />
      <YesNo
        label="Do you have, or have you ever had any gambling-related problems or debts?"
        xs={12}
        name="michigan.has_gaming_related_problems"
      />
      <FormikTextField
        multiline
        rows={4}
        label="Explanation"
        name="michigan.gaming_related_problems_details"
        xs={12}
      />
      <YesNo
        label="Have you ever been treated for any gambling-related problems?"
        xs={12}
        name="michigan.has_been_treated_for_gaming_related_problems"
      />
      <FormikTextField
        multiline
        rows={4}
        label="Explanation"
        name="michigan.treated_for_gaming_related_problems_details"
        xs={12}
      />
    </Grid>
  );
};

const MichiganGamblingProblems = ({ name }) => {
  const formData = useForm({
    defaultInitialValues,
    endpoint: "michigan_gambling_problems_pdSupplement",
  });

  return (
    <FormSectionProvider formData={formData} title={`Michigan ${name}`}>
      <EnhancedFormikPage>
        <MichiganGamblingProblemsForm />
      </EnhancedFormikPage>
    </FormSectionProvider>
  );
};

export default MichiganGamblingProblems;
