import React from "react";

export const columns = [
  {
    title: "Case Name",
    field: "case_name",
  },
  {
    field: "docket_number",
  },
  { title: "Business Entity", field: "party_name" },
  {
    title: "Agency or Court",
    field: "law_enforcement_bd_busines_entity.business_name",
  },
  {
    field: "nature_of_charge",
  },
  {
    field: "litigation_status",
    format: "startCase",
  },
];

export const tableQuestions = (
  <>
    Provide all existing civil litigation at equity and law to which the
    enterprise, or any subsidiary, is presently a part, whether in this state or
    in another jurisdiction. Do not include any case for monetary damages in
    litigation in which the damages may not reasonably be expected to exceed
    $100,000, or litigation in which damages may be expected to exceed $100,000,
    but which involve claims against the enterprise which are fully and
    completely covered under an insurance carrier.
  </>
);

export const defaultInitialValues = {
  bd_party_id: null,
  law_enforcement_bd_business_entity_id: null,
  disposition_date: null,
  case_name: "",
  nature_of_charge: "",
  docket_number: "",
  bd_legal_history_attachment_group_id: null,
  type: "litigation",
  bd_related_party_id: null,
  litigation_status: null,
  filing_date: null,
  role_in_dispute: "",
};

export const endpoint = "bdLegalHistory?type=litigation";
export const nonRequired = ["bd_legal_history_attachment_group_id"];

export const tabularDataParams = {
  defaultInitialValues,
  endpoint,
  nonRequired,
};
