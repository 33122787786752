import React from "react";

import { SectionLabel } from "components/Common/LayoutComponents/";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import useForm from "hooks/useForm.js";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import { Grid } from "@material-ui/core";
import AlertEdit from "components/Common/Alerts/AlertEdit";
import { defaultInitialValues, endpoint } from "./config";

const Form = () => {
  return (
    <EnhancedFormikPage>
      <Grid container spacing={2}>
        <AlertEdit
          xs={12}
          linkTo="tax_audit"
          title="Review your information"
          dependsOnQueryCount="Has your Federal Income Tax Return ever been audited or adjusted?"
          endpoint="new_york_tax_information_taxReturnActivityFederalAuditedAdjusted"
          displayValue={"id"}
        />

        <SectionLabel label="If yes, please describe the nature and resolution of the audit and tax year(s) below:" />
        <FormikTextField
          multiline
          rows={4}
          label="Nature and Resolution"
          name="new_york.new_york_q6_b_details"
          xs={12}
        />

        <AlertEdit
          xs={12}
          linkTo="tax_audit"
          title="Review your information"
          dependsOnQueryCount="Have you ever failed to file Federal or State Income Tax returns?"
          endpoint="new_york_tax_information_taxReturnActivityStateFederalNotFiled"
          displayValue={"id"}
        />

        <SectionLabel label="If yes, please write a narrative indicating what year and reasons for the failure below:" />
        <FormikTextField
          multiline
          rows={4}
          label="Year & Reasons"
          name="new_york.new_york_q6_c_details"
          xs={12}
        />
      </Grid>
    </EnhancedFormikPage>
  );
};

const NewYorkTaxInformation = ({ name }) => {
  const formData = useForm({
    defaultInitialValues: defaultInitialValues,
    endpoint: endpoint,
  });

  return (
    <FormSectionWrapper title={name}>
      <FormContextProvider formData={formData}>
        <Form />
      </FormContextProvider>
    </FormSectionWrapper>
  );
};

export default NewYorkTaxInformation;
