import { Button, Box, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";

export const MissingPagesList = ({ missingExtraPageMessage }) => (
  <Box>
    <Typography>
      These extra pages need to be created and uploaded into OneComply&lsquo;s
      system to accommodate your data overflow:
    </Typography>
    <br />
    {missingExtraPageMessage.split(", ").map((item) => (
      <Box key={item}>• {item}</Box>
    ))}
    <br />
    <Typography>
      We have been notified and we&lsquo;ll contact you shortly with details.
    </Typography>
  </Box>
);

export const useExtraPageWarning = (missing_extra_page_message) => {
  const [extraPageAlert, setExtraPageAlert] = React.useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    const action = (key) => (
      <Button
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        Dismiss
      </Button>
    );
    extraPageAlert &&
      missing_extra_page_message &&
      enqueueSnackbar(
        <MissingPagesList
          missingExtraPageMessage={missing_extra_page_message}
        />,
        {
          variant: "warning",
          persist: true,
          action,
        }
      );
  }, [
    missing_extra_page_message,
    extraPageAlert,
    closeSnackbar,
    enqueueSnackbar,
  ]);
  return { setExtraPageAlert };
};
