import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import FormikInlineTable from "components/Common/Tables/FormikInlineTable/FormikInlineTable";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import {
  columns,
  dataHookProps,
  modalTitle,
  tableQuestions,
  profitSharePlanPersonClassColums,
  profitSharePlanPersonClassInitialValues,
} from "./config.js";
import FormikNumberField from "components/Formik/fields/FormikNumberField.jsx";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import Tooltip from "components/Common/Tooltip";
import BDPartySelect from "components/Formik/formGroups/CrudSelect/selects/BDPartySelect.jsx";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <FormikTextField
        label="Title or Name of the Plan"
        name="plan_name"
        xs={7}
      />
      <BDPartySelect name="trustee_bd_party_id" label="Plan Trustee" />
      <SectionSubHeader label="Plan Specifications" divider />
      <FormikTextField
        multiline
        rows={4}
        label="Material Feature of the Plan"
        name="material_features"
        xs={12}
      />
      <FormikTextField
        multiline
        rows={4}
        label="Methods of Financing Plan"
        name="financing_methods"
        xs={12}
      />
      <FormikInlineTable
        name="bd_profit_share_plan_person_class"
        columns={profitSharePlanPersonClassColums}
        addButtonText={"Add Each Plan Class"}
        defaultInitialValues={profitSharePlanPersonClassInitialValues}
        dialogTitle="Profit Share Plan Person Class"
        form={<ProfitSharePlanPersonClassForm />}
      />

      <SectionSubHeader
        label="Attach a descriptions of plans  and 5500 form field with the IRS for the past five (5) years."
        divider
      />

      <FormikFileDropzone
        description="BD Profits Share Docs"
        label="Upload copies of the loan and finance agreements."
        name="attachment_group_id"
        size={12}
      />
    </Grid>
  );
};

const ProfitSharePlanPersonClassForm = () => {
  return (
    <Grid container spacing={2}>
      <FormikTextField
        label="Class of Persons in Plan"
        name="person_class"
        xs={12}
      />
      <FormikNumberField
        label="Number of individuals in Each Class"
        name="person_number"
        xs={8}
        type="none"
      />
      <FormikNumberField
        label="Distributed Amount last year"
        name="distributed_amount_last_year"
        xs={8}
        type="dollar"
        tooltip={
          <Tooltip title="Amount distributed to each class of persons during the last fiscal year the plan was in effect" />
        }
      />
    </Grid>
  );
};

const BDProfitSharePlan = ({ name }) => {
  const formData = useTabularData(dataHookProps);

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={modalTitle}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default BDProfitSharePlan;
