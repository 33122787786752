import { Grid } from "@material-ui/core";
import { Divider, SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import PDBusinessSelect from "components/Formik/formGroups/CrudSelect/selects/PDBusinessSelect.jsx";
import PDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/PDPersonSelect.jsx";
import FromToCurrent from "components/Formik/formGroups/FromToCurrent";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues } from "./config.js";
import useEndPoint from "hooks/useEndpoint.js";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Employment Details" />
      <PDBusinessSelect label="Employer" />

      <Grid item xs={6} />
      <Divider />

      <PDPersonSelect
        label="Spouse"
        overrideDefaultValues={{ relative_type: "spouse" }}
        endpoint="currentSpouse"
      />

      <SectionSubHeader label="Employment Details" divider />
      <FormikTextField label="Title/Position Held" name="position" xs={6} />

      <SectionSubHeader label="Dates" divider />
      <FromToCurrent />
    </Grid>
  );
};

const SpousalEmployment = ({ name }) => {
  const { fullEndpoint } = useEndPoint("spouse_employment_spouseEmployment");

  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "spouse_employment_employments_spouse",
    saveEndpoint: fullEndpoint,
  });

  return (
    <FormSectionProvider formData={formData} title={name}>
      <EnhancedFormikDialog title="Employer Details">
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        subheader={
          <InfoAlert>
            Provide any and all compensated employment, of whatever nature, held
            by your spouse or domestic partner during the past twelve (12) month
            period.
          </InfoAlert>
        }
        columns={columns}
        addButtonText="Add Record"
      />
    </FormSectionProvider>
  );
};

export default SpousalEmployment;
