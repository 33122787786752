import React from "react";
import { Box } from "@material-ui/core";
import {
  usePdfDispatch,
  usePdfState,
  ACTIONS,
} from "components/DevTools/PdfTools/Context/PdfToolsProvider.jsx";

export default function DataDisplay() {
  const { fetchedData, fetchedDataRows, showRowsData } = usePdfState();
  const dispatch = usePdfDispatch();

  const showData = () => {
    dispatch({
      type: ACTIONS.SET_STATE,
      value: !showRowsData,
      name: "showRowsData",
    });
  };

  return (
    <>
      <Box
        component="div"
        style={{
          padding: "10px 0px 20px 25px",
          fontWeight: "bold",
          marginRight: "10px",
          marginLeft: "30px",
          color: "green",
        }}
        fontSize={16}
      >
        {fetchedData}
      </Box>
      <Box
        component="div"
        style={{
          padding: "0px 0px 20px 25px",
          fontWeight: "bold",
          marginRight: "10px",
          marginLeft: "30px",
          color: "purple",
        }}
        fontSize={16}
        onClick={showData}
        className="pointer"
      >
        {fetchedDataRows.length
          ? `Total rows of data: ${fetchedDataRows.length}`
          : ""}
      </Box>
      {showRowsData &&
        fetchedDataRows.map((row, i) => (
          <Box
            key={i}
            component="div"
            style={{
              padding: "0px 0px 10px 25px",
              fontWeight: "bold",
              marginRight: "10px",
              marginLeft: "30px",
              color: "green",
            }}
            fontSize={16}
          >
            {`row index ${i}: ${Object.values(row)[0]}`}
          </Box>
        ))}
    </>
  );
}
