import PropTypes from "prop-types";
import { Divider } from "@material-ui/core";
import React from "react";
import DialogHeader from "./DialogHeader";
import Dialog from "components/core/Dialog";

export default function SimpleDialog({
  open,
  onClose,
  title,
  children,
  divider,
  preventCloseOnBlur = true,
  TransitionProps,
  ...props
}) {
  return (
    <Dialog
      onClose={preventCloseOnBlur ? null : onClose}
      open={open}
      TransitionProps={TransitionProps}
      {...props}
    >
      <DialogHeader onClose={onClose}>{title}</DialogHeader>
      {divider && <Divider />}
      {children}
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  TransitionProps: PropTypes.any,
  children: PropTypes.node,
  divider: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  preventCloseOnBlur: PropTypes.bool,
  title: PropTypes.string,
};
