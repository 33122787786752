import { fieldTypes } from "componentGenerators/formGenerator/config";
import { dayjsUTC } from "helpers/apiHelpers.js";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Contact Person",
  },
  {
    type: fieldTypes.label,
    label: "Please select your contact person below:",
  },
  {
    type: fieldTypes.bdPersonSelect,
    label: "Contact Person",
    name: "ohio_rvla.contact_person_id",
    defaultValue: null,
  },
  {
    type: fieldTypes.subHeader,
    label: "Application Purpose",
    divider: true,
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "ohio_rvla.application_purpose_checkbox",
    label: "Please select the purpose of your application below:",
    dynamicMenuItems: ["ohioApplicationPurpose"],
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "ohio_rvla.business_description_checkbox",
    label: "Please select all that apply:",
    dynamicMenuItems: ["ohioBusinessDescription"],
  },
  {
    type: fieldTypes.label,
    label:
      "If you selected 'Provide other services', please provide a brief description.",
  },
  {
    type: fieldTypes.text,
    label: "Description",
    name: "ohio_rvla.other_services_description",
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label:
      "If you selected 'Holding Company', please provide the name of the applicant.",
  },
  {
    type: fieldTypes.text,
    label: "Applicant Name",
    name: "ohio_rvla.holding_company_applicant_name",
    xs: 12,
  },
  {
    type: fieldTypes.subHeader,
    label: "Management Company License",
    divider: true,
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Have you been licensed as a Management Company to conduct casino gaming at an Ohio casino facility within the last twelve (12) months?",
    name: "ohio_rvla.is_management_company_ohio_casino",
  },
  {
    type: fieldTypes.label,
    label:
      "If you answered yes to the question above, please select the date and license number of the last submission filed",
  },
  {
    type: fieldTypes.asyncSelect,
    name: "ohio_rvla.management_company_license_id",
    label: "License and Last Submission Date",
    endpoint: "views/bd_licenses",
    getOptionLabel: ({ license_number, application_date }) =>
      `${license_number} • ${dayjsUTC(application_date, "MM/DD/YYYY")}`,
    getOptionValue: (option) => option.id,
    fetchOptions: { useFullEndpoint: true },
    xs: 6,
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Do you currently have a pending Management Company License Application to conduct casino gaming at an Ohio casino facility?",
    name: "ohio_rvla.is_pending_management_company_license",
  },
  {
    type: fieldTypes.label,
    label:
      "If you answered yes to the question above, please select the date of the last submission filed",
  },
  {
    type: fieldTypes.date,
    label: "Submission Date",
    name: "ohio_rvla.pending_management_company_license_sub_date",
    xs: 6,
  },
  {
    type: fieldTypes.yesNo,
    label: `Has the Applicant or Holding Company ever been granted any gaming license or certificate issued by a 
      licensing authority in Ohio or any other jurisdiction, foreign or domestic, that has been fined, restricted, 
      ubjected to settlement, or otherwise penalized?`,
    name: "ohio_rvla.has_any_fined_licenses",
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "bdSupplement",
};
