import React from "react";
import useToggle from "hooks/useToggle";

const useDeleteDialog = ({ handleConfirm }) => {
  const { on: open, setOn, setOff } = useToggle();
  const [selectedItem, setSelectedItem] = React.useState(null);

  const onConfirm = () => {
    handleConfirm(selectedItem);
  };

  const onCancel = () => {
    setOff();
    setSelectedItem(null);
  };

  const openDeleteDialog = (item) => {
    setSelectedItem(item);
    setOn();
  };

  const reset = () => {
    setOff();
    setSelectedItem(null);
  };

  return { open, setOn, setOff, onConfirm, onCancel, openDeleteDialog, reset };
};

export default useDeleteDialog;
