import { Grid } from "@material-ui/core";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import { useBdOrganizationId } from "queryHooks/useBdOrganizationId";
import React from "react";
import { collaboratorsColumns, collaboratorsValidationSchema } from "./config";
import FormikAsyncSelect from "components/Formik/fields/FormikAsyncSelect";
import useDeleteDialog from "hooks/useDeleteDialog";
import useDelete from "queryHooks/useDelete";
import { deleteWithBody } from "helpers/apiHelpers";
import { CollaboratorsDisclosureDisclaimer } from "./components/Disclaimers";

const Form = ({ filterUserOptions }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CollaboratorsDisclosureDisclaimer />
      </Grid>
      <FormikAsyncSelect
        name="account_id"
        endpoint="/bdOrganization/manage/getAccount"
        label="Collaborator"
        getOptionLabel={({ first_name, last_name, email }) =>
          `${first_name} ${last_name} (${email})`
        }
        getOptionValue={({ id }) => id}
        fetchOptions={{ useFullEndpoint: false }}
        xs={6}
        filterOptions={filterUserOptions}
      />
    </Grid>
  );
};

export const CollaboratorsTable = () => {
  const { id } = useBdOrganizationId();
  const path = `/bdOrganization/manage/${id}/collaborator`;

  const formData = useTabularData({
    defaultInitialValues: { account_id: null },
    getEndpoint: path,
    saveEndpoint: path,
    fetchOptions: { enabled: !!id },
    noRequired: true,
  });

  const handleConfirm = (id) => {
    // eslint-disable-next-line no-use-before-define
    mutate({ id });
  };

  const { reset, openDeleteDialog, ...deleteDialogProps } = useDeleteDialog({
    handleConfirm,
  });

  const { mutate } = useDelete(path, path, {
    onMutate: reset,
    queryFn: ({ id }) => deleteWithBody(path, { account_id: id }),
  });

  // Users that are already collaborators are not shown as an option
  const filterUserOptions = (data) =>
    data.filter(({ id }) => !formData.data.some((option) => option.id === id));

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikDialog
        validationSchema={collaboratorsValidationSchema}
        title={"Add Collaborator"}
      >
        <Form filterUserOptions={filterUserOptions} />
      </EnhancedFormikDialog>

      <EnhancedActionsTable
        addButtonText="Add Collaborator"
        columns={collaboratorsColumns}
        handleEditRecord={null}
        handleDeleteRecord={openDeleteDialog}
        isDeleting={deleteDialogProps.open}
        confirmDelete={deleteDialogProps.onConfirm}
        cancelDelete={deleteDialogProps.onCancel}
      />
    </FormContextProvider>
  );
};
