import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Court Record",
  },
  {
    type: fieldTypes.yesNo,
    label: "Are you currently on parole, probation or supervised release?",
    name: "pd_oklahoma_rkela.is_on_parole",
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "pd_oklahoma_rkela_supplement_court_record_pdSupplement",
};
