import React from "react";
import { address } from "helpers/initialValues";

export const columns = [
  {
    field: "full_name",
  },
  {
    field: "phone_number",
  },
  { field: "home_address", format: "address" },
  { title: "Is Deceased", field: "is_deceased", format: "boolean" },
  { title: "Is Retired", field: "is_retired", format: "boolean" },
  {
    field: "occupation",
  },
];

export const tableQuestions = (
  <>
    List names, residence addresses, dates of birth, and most recent occupations
    of parents, parents-in-law, or legal guardian, living or deceased. If
    retired or deceased list last address and occupation.
  </>
);

export const nonRequired = ["middle_name", "suffix_name"];

export const defaultInitialValues = {
  is_deceased: null,
  is_retired: null,
  first_name: "",
  middle_name: "",
  last_name: "",
  suffix_name: "",
  relative_type: "",
  occupation: "",
  date_of_birth: null,
  place_of_birth: "",
  phone_number: "",
  home_address: address,
};

export const endpoint = "parents_parent";
