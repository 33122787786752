import {
  Box,
  Card,
  Container,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";
import withErrorBoundary from "hocs/withErrorBoundary";
import useFetch from "queryHooks/useFetch";
import React from "react";
import FilterTable from "../components/FilterTable/FilterTable";
import { LicenseFilterForm } from "./components/LicenseFilterForm";
import { Row } from "./components/Row";
import { headCells, csvHeaders } from "./config";
import { filterReducer, handleFilter } from "./helpers";
import useFilterTable from "./useFilterTable";
import { CSVLink } from "react-csv";
import GetAppIcon from "@material-ui/icons/GetApp";
import { getUniqueValues } from "components/Pages/LicensingControl/helpers.js";

const HeaderArea = () => (
  <Box display="flex" alignItems="center">
    <Typography variant="h1" gutterBottom>
      License Reporting
    </Typography>
  </Box>
);

const Licenses = () => {
  const { data = [], status } = useFetch(
    "lcLicenses",
    "licensing/views/lc_licenses"
  );

  const licenseTypeFilterOptions = getUniqueValues({
    array: data,
    property: "license_type_name",
  });

  const agencyNameFilterOptions = getUniqueValues({
    array: data,
    property: "agency_name",
  });

  const [filterState, filterDispatch] = React.useReducer(filterReducer, {
    license_type_name: [],
    agency_name: [],
    license_type_category: [],
    expiryUrgency: [],
    filterCount: 0,
  });

  const { tableHeadProps, sortedRecords, applyFilter } = useFilterTable({
    records: data,
    handleFilter: handleFilter(filterState, [
      // first value is the table attribute name, second value is the dropdown attribute name. Only needed if different than the table attribute
      ["license_type_name", "name"],
      ["agency_name", "name"],
      ["license_type_category"],
    ]),
  });

  return (
    <>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <HeaderArea />
          </Grid>
          <Grid item xs={12}>
            <LoadingComponent status={status}>
              <Card variant="outlined">
                <FilterTable
                  filterStyle={{ width: "500px" }}
                  handleSetFilterFn={applyFilter}
                  filterCount={filterState.filterCount}
                  tableHeadProps={tableHeadProps}
                  sortedRecords={sortedRecords}
                  title="Licenses"
                  headCells={headCells}
                  row={(item) => <Row item={item} key={item.id} />}
                  filterForm={
                    <LicenseFilterForm
                      filterState={filterState}
                      filterDispatch={filterDispatch}
                      licenseTypeFilterOptions={licenseTypeFilterOptions}
                      agencyNameFilterOptions={agencyNameFilterOptions}
                    />
                  }
                  headerButton={
                    <CSVLink
                      data={sortedRecords}
                      headers={csvHeaders}
                      filename="OneComply-Exported-Licenses"
                    >
                      <Button startIcon={<GetAppIcon />} color="primary">
                        Export
                      </Button>
                    </CSVLink>
                  }
                />
              </Card>
            </LoadingComponent>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default withErrorBoundary(Licenses);
