import PersonIcon from "@material-ui/icons/Person";
import CrudSelect, {
  formSchemas,
} from "components/Formik/formGroups/CrudSelect/CrudSelect";
import PropTypes from "prop-types";
import React from "react";

const BDPersonSelect = ({
  name = "related_bd_party_id",
  label = "Person",
  //endpoint = "bdPerson", - change this back once we fix sorting at the API level
  endpoint = "views/bd_persons_app",
  formTitle = "Add / Edit Person",
  formSchema = formSchemas.bdPersonSchema,
  ...rest
}) => {
  return (
    <CrudSelect
      name={name}
      label={label}
      endpoint={endpoint}
      saveEndpoint="bdPerson"
      formSchema={formSchema}
      formTitle={formTitle}
      optionIcon={<PersonIcon color="primary" fontSize="small" />}
      {...rest}
    />
  );
};

BDPersonSelect.propTypes = {
  endpoint: PropTypes.string,
  formSchema: PropTypes.array,
  formTitle: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  disableEdit: PropTypes.func,
};

export default BDPersonSelect;
