import PropTypes from "prop-types";
import React from "react";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { useFormInformation } from "context/formStatus";
import { get } from "lodash";
import Typography from "@material-ui/core/Typography";
export function BaseLink({ label, children, to, ...props }) {
  return (
    <Link component={RouterLink} to={to} underline="always" {...props}>
      {label ? (
        <Typography display="inline" fontWeight="bold">
          {label}
        </Typography>
      ) : (
        <Typography display="inline" fontWeight="bold">
          {children}
        </Typography>
      )}
    </Link>
  );
}

BaseLink.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  to: PropTypes.string,
};

export default function TextLink({ label, to, noSpace, ...props }) {
  const { formStatus } = useFormInformation();

  const hyperLinkLabel =
    label || get(formStatus, `${to.toString().trim()}.name`);

  return (
    <BaseLink
      to={to.toString().trim()}
      style={noSpace ? {} : { paddingRight: "5px", paddingLeft: "5px" }}
      {...props}
    >
      {hyperLinkLabel}
    </BaseLink>
  );
}

TextLink.propTypes = {
  label: PropTypes.any,
  to: PropTypes.string,
  noSpace: PropTypes.bool,
};

export function ButtonLink(props) {
  const { label, to, size, startIcon, className } = props;

  return (
    <Link to={to}>
      <Button
        size={size || "small"}
        startIcon={startIcon || null}
        className={className || null}
      >
        {label}
      </Button>
    </Link>
  );
}

ButtonLink.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  startIcon: PropTypes.any,
  to: PropTypes.string,
};
