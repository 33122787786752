import BD_ColoradoVLMARequiredAttachments from "components/Pages/Supplemental/BD/ColoradoVLMA/ColoradoVLMARequiredAttachments/ColoradoVLMARequiredAttachments";
import BD_ColoradoVLMAFinancialHistory from "components/Pages/Supplemental/BD/ColoradoVLMA/ColoradoVLMAFinancialHistory/ColoradoVLMAFinancialHistory";
import BD_ColoradoVLMAApplicantionDetails from "components/Pages/Supplemental/BD/ColoradoVLMA/ColoradoVLMAApplicantionDetails/ColoradoVLMAApplicantionDetails";

const bd_colorado_vlma_supplement_pages = {
  bd_colorado_vlma_supplement_required_attachments:
    BD_ColoradoVLMARequiredAttachments,
  bd_colorado_vlma_supplement_financial_history:
    BD_ColoradoVLMAFinancialHistory,
  bd_colorado_vlma_supplement_application_details:
    BD_ColoradoVLMAApplicantionDetails,
};

export default bd_colorado_vlma_supplement_pages;
