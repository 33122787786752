import { getFormComponents } from "componentGenerators/helpers";
import new_york_cvke_supplement_disclosure_questions from "components/Pages/Supplemental/PD/NewYorkCVKE/NewYorkCVKEDisclosureQuestions/NewYorkCVKEDisclosureQuestions";
import new_york_cvke_supplement_tax_information from "components/Pages/Supplemental/PD/NewYorkCVKE/NewYorkCVKETaxInformation/NewYorkCVKETaxInformation";
import new_york_cvke_supplement_attachments from "components/Pages/Supplemental/PD/NewYorkCVKE/NewYorkCVKEAttachments/NewYorkCVKEAttachments";
import new_york_cvke_supplement_criminal_history from "components/Pages/Supplemental/PD/NewYorkCVKE/NewYorkCVKECriminalHistory/NewYorkCVKECriminalHistory";

export default getFormComponents({
  new_york_cvke_supplement_disclosure_questions,
  new_york_cvke_supplement_tax_information,
  new_york_cvke_supplement_attachments,
  new_york_cvke_supplement_criminal_history,
});
