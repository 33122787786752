import React from "react";
import {
  columns,
  nonRequired,
  endpoint,
  formSchema,
  infoAlerts,
  modifySubmissionValues,
} from "./config.js";

const schema = {
  type: "table",
  endpoint,
  formSchema,
  nonRequired,
  columns,
  infoAlerts,
  modifySubmissionValues,
};

const bdLicensesSchema = {
  type: "readOnlyTable",
  endpoint: "business_licensing_pd_bd_licenses_kp",
  infoAlert: (
    <>
      The following details are collected from the business entitiy profiles of
      your organization within OneComply where you have been identified either
      as:
      <ul>
        <li>Owner</li>
        <li>Director</li>
        <li>Officer</li>
        <li>Trustee</li>
        <li>Founder</li>
        <li>Partner</li>
        <li>Shareholder</li>
      </ul>
    </>
  ),
  columns: [
    {
      title: "Business Name",
      field: "business_name",
    },
    {
      title: "Industry",
      field: "industry_type",
      format: "startCase",
    },
    {
      title: "Application Date",
      field: "application_date",
      format: "date",
    },
    {
      title: "License Agency",
      field: "license_agency_name",
    },
    {
      title: "License Type",
      field: "license_type",
    },
  ],
};

export default [schema, bdLicensesSchema];
