import React from "react";
import SimpleDialog from "components/Common/Dialogs/SimpleDialog";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";
import LaunchIcon from "@material-ui/icons/Launch";
import { startCase, toLower } from "lodash";

import {
  DialogContentText,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

function removePrefix(item) {
  return item.slice(0, 3) === "bd_" ? item.slice(3) : item;
};

function ListItemLink(props) {
  return (
    <ListItem
      button
      component={RouterLink}
      to={props.to}
      {...props}
      target="_blank"
    />
  );
}

const LinkList = ({ linkToList }) => (
  <List dense={true}>
    {linkToList.map((item) => (
      <ListItemLink to={item} key={item}>
        <ListItemIcon>
          <LaunchIcon />
        </ListItemIcon>
        <ListItemText primary={startCase(toLower(removePrefix(item)))} />
      </ListItemLink>
    ))}
  </List>
);

const DialogSubheader = styled(DialogContentText)`
  padding: ${({ theme }) => theme.spacing(0, 2)};
  margin: 0px;
`;

const DialogContent = styled.div`
  max-width: 700px;
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const EditDialog = ({ open, onClose, linkToList }) => (
  <SimpleDialog onClose={onClose} open={open} title="Add / Edit">
    <DialogContent>
      <DialogSubheader>
        To modify or add information, visit the relevant section:
      </DialogSubheader>
      <LinkList linkToList={linkToList} />
    </DialogContent>
  </SimpleDialog>
);

export default EditDialog;
