import React from "react";

export default function SQLViewer({ query }) {
  return query ? (
    <div
      style={{
        marginLeft: "25px",
        marginRight: "25px",
        marginBottom: "20px",
        borderStyle: "inset",
        padding: "5px",
        fontFamily: "monospace",
        fontSize: "16px",
      }}
    >
      {query.split("\n").map((line, i) => (
        <>
          <em key={i}>{line}</em>
          <br />
        </>
      ))}
    </div>
  ) : null;
}
