import FormikTextField from "components/Formik/fields/FormikTextField";
import React from "react";
import { Grid } from "@material-ui/core";

export const FirstMiddleLast = () => {
  return (
    <>
      <FormikTextField label="First Name" name="first_name" xs={12} md={4} />
      <FormikTextField label="Last Name" name="last_name" xs={12} md={4} />
      <Grid item xs={4} />
      <FormikTextField label="Middle Name" name="middle_name" xs={12} md={4} />
      <FormikTextField label="Suffix" name="suffix_name" xs={6} md={2} />
      <Grid item xs={4} />
    </>
  );
};
