import React from "react";
import { formats } from "components/Common/Tables/Common/helpers";

export const columns = [
  {
    field: "from_date",
  },
  {
    field: "to_date",
  },
  {
    field: "destination",
  },
  {
    field: "purpose",
    format: formats.startCase,
  },
];

export const tableQuestions = (
  <>
    Provide details of all travel outside your country of residence during the
    past five (5) years
  </>
);

export const endpoint = "foreign_travel_foreignTravel";

export const nonRequired = ["purpose_description"];

export const defaultInitialValues = {
  from_date: null,
  to_date: null,
  destination: "",
  purpose: "",
  purpose_description: "",
};
