import React, { useState } from "react";
import { Select, InputLabel, FormControl, MenuItem } from "@material-ui/core";
import {
  usePdfDispatch,
  usePdfState,
  ACTIONS,
} from "components/DevTools/PdfTools/Context/PdfToolsProvider.jsx";

export default function BreakAddress() {
  const dispatch = usePdfDispatch();
  const { token, column } = usePdfState();
  const [breakAddress, setBreakAddress] = useState("");

  const handleChange = (event) => {
    setBreakAddress(event.target.value);
    if (event.target.value === "reset") {
      dispatch({ type: ACTIONS.SET_STATE, value: "", name: "token" });
    } else if (token.includes("=") && !token.includes(event.target.value)) {
      dispatch({
        type: ACTIONS.SET_STATE,
        value: token + ":" + event.target.value,
        name: "token",
      });
    } else {
      dispatch({
        type: ACTIONS.SET_STATE,
        value: "=" + event.target.value,
        name: "token",
      });
    }
  };

  if (column && column.includes("address")) {
    return (
      <FormControl
        variant="filled"
        style={{ width: "150px", marginRight: "10px" }}
      >
        <InputLabel>Break Address</InputLabel>
        <Select value={breakAddress || ""} onChange={handleChange}>
          {[
            { format: "street", token: "street" },
            { format: "city", token: "city" },
            { format: "county", token: "county" },
            { format: "state", token: "state" },
            { format: "country", token: "country" },
            { format: "postal code", token: "postal_code" },
            { format: "- reset", token: "reset" },
          ].map((val) => (
            <MenuItem key={val.token} value={val.token}>
              {val.format}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else {
    return <></>;
  }
}
