import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import { Divider } from "components/Common/LayoutComponents";
import BDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/BDPersonSelect";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm";
import React from "react";
import { tabularDataParams } from "./config.js";

const PennEEAContactInformationForm = () => {
  return (
    <Grid container spacing={1}>
      <BDPersonSelect
        name="bd_pennsylvania_eea.bd_pa_eea_contact_bd_person_id"
        label="Main Contact Person"
        header="Main Contact Person"
        xs={6}
      />
      <Divider />
      <BDPersonSelect
        name="bd_pennsylvania_eea.bd_pa_eea_billing_contact_bd_person_id"
        label="Billing Contact Person"
        header="Billing Contact Person"
        xs={6}
      />
    </Grid>
  );
};

const PennEEAContactInformation = ({ name }) => {
  const formData = useForm(tabularDataParams);

  return (
    <FormSectionProvider formData={formData} title={`Pennsylvania EEA ${name}`}>
      <EnhancedFormikPage>
        <PennEEAContactInformationForm />
      </EnhancedFormikPage>
    </FormSectionProvider>
  );
};

export default PennEEAContactInformation;
