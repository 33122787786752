import React from "react";

import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents/";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormikCheckBoxGroup from "components/Formik/formGroups/FormikCheckBoxGroup";
import { useMenuOptions } from "hooks/useMenuOptions";
import useForm from "hooks/useForm.js";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import { Grid } from "@material-ui/core";
import YesNo from "components/Formik/formGroups/YesNo";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikSelect from "components/Formik/fields/FormikSelect";
import FormikAsyncSelect from "components/Formik/fields/FormikAsyncSelect";
import { commonStrings } from "../../../../PD/uiStrings";
import { defaultInitialValues, endpoint } from "./config";
import Tooltip from "components/Common/Tooltip";

const Form = () => {
  const {
    criminalDispositionType,
    tennesseeLicenseTypeCheckbox,
    tennesseeGamingBusinessEntityRelationshipCheckbox,
  } = useMenuOptions();

  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="License Information" />
      <SectionLabel label="Type of license or registration with which you are associated:" />

      <FormikCheckBoxGroup
        name="tennessee_license_type_checkbox"
        options={tennesseeLicenseTypeCheckbox}
      />

      <SectionLabel label="Position or association with applicant, licensee, or registrant:" />

      <FormikCheckBoxGroup
        name="tennessee_gaming_entity_relationship_checkbox"
        options={tennesseeGamingBusinessEntityRelationshipCheckbox}
      />

      <FormikTextField
        label="Other"
        name="tennessee.gaming_entity_relationship_explanation"
        xs={8}
      />

      <SectionSubHeader label="Personal Information" divider />

      <YesNo
        label="Is your spouse or partner a citizen of the United States?"
        name="tennessee.is_spouse_us_citizen"
      />

      <SectionLabel label="If naturalized, certificate number:" divider />

      <FormikTextField
        label="Certificate Number"
        name="tennessee.spouse_certificate_number"
        xs={4}
      />

      <SectionLabel label="If not a citizen, authorization or registration number:" />

      <FormikTextField
        label="Registration Number"
        name="tennessee.spouse_registration_number"
        xs={4}
      />

      <SectionSubHeader label="Criminal Investigation" divider />

      <YesNo
        label="Have you or any person or entity on your behalf ever entered into a settlement before litigation or criminal charges were filed against you or an entity with which you were affiliated as an owner, officer, director, manager, partner, or trustee?"
        name="tennessee.has_settlement"
        xs={12}
      />

      <FormikTextField
        label="Nature of Alleged Claim(s) or charge(s)"
        name="tennessee.settlement_nature"
        xs={4}
      />

      <FormikTextField
        label="Adverse Party or Government Agency"
        name="tennessee.settlement_other_party"
        xs={4}
      />

      <FormikDatePicker
        label="Disposition Date"
        name="tennessee.settlement_disposition_date"
        xs={4}
      />

      <FormikSelect
        label="Disposition"
        name="tennessee.settlement_disposition"
        options={criminalDispositionType}
        xs={4}
      />

      <YesNo
        label="Do you currently have any tax obligations, fines, court orders, legal obligations, or judgments outstanding, owed, or past due?"
        name="tennessee.has_tax_obligations"
        xs={12}
      />

      <SectionLabel label="If yes, provide the following:" divider />

      <FormikTextField
        label="Obligation"
        name="tennessee.tax_obligations"
        xs={4}
      />

      <FormikTextField
        label="Amount or Action Owed or Due"
        name="tennessee.tax_obligations_amount"
        xs={4}
      />

      <FormikDatePicker
        label="Date of Disposition"
        name="tennessee.tax_obligations_date"
        xs={4}
      />

      <FormikTextField
        label="Reason for status"
        name="tennessee.tax_obligations_reason"
        xs={4}
      />

      <YesNo
        label="Have you ever had your driver’s license suspended or revoked in any jurisdiction?"
        name="tennessee.has_driver_license_condition"
        xs={12}
      />

      <SectionLabel label="If yes, provide the following:" divider />

      <FormikTextField
        label="Reason for Suspension or Revocation"
        name="tennessee.driver_license_condition_reason"
        xs={8}
      />

      <FormikTextField
        label="Current Status"
        name="tennessee.driver_license_condition_status"
        xs={8}
      />

      <FormikTextField
        label="Length of Suspension or Revocation"
        name="tennessee.driver_license_condition_length"
        xs={8}
      />

      <FormikAsyncSelect
        name="tennessee.driver_license_condition_jurisdiction"
        endpoint="jurisdiction?all=true"
        label="Issuing Jurisdiction"
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.tag}
        fetchOptions={{ useFullEndpoint: false }}
      >
        <Tooltip title={commonStrings.additionalJurisdictions} />
      </FormikAsyncSelect>
    </Grid>
  );
};

const TennesseeDisclosureQuestions = ({ title }) => {
  const formData = useForm({
    defaultInitialValues: defaultInitialValues,
    endpoint: endpoint,
  });

  return (
    <FormSectionWrapper title={title}>
      <FormContextProvider formData={formData}>
        <EnhancedFormikPage>
          <Form />
        </EnhancedFormikPage>
      </FormContextProvider>
    </FormSectionWrapper>
  );
};

export default TennesseeDisclosureQuestions;
