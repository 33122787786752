import PropTypes from "prop-types";
import { FormControlLabel, Radio } from "@material-ui/core";
import { Field } from "formik";
import React from "react";
import withFieldTools from "hocs/withFieldTools";

function FormikRadioBase({ label, color = "primary", ...props }) {
  return (
    <FormControlLabel
      control={<Field {...props} type="radio" as={Radio} color={color} />}
      label={label}
    />
  );
}

FormikRadioBase.propTypes = {
  color: PropTypes.oneOf(["default", "primary", "secondary"]),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default withFieldTools(FormikRadioBase);
