import React from "react";
import { address } from "helpers/initialValues";

export const columns = [
  {
    field: "full_name",
  },
  {
    field: "phone_number",
  },
  { field: "home_address", format: "address" },
  { title: "Relationship", field: "relative_type", format: "startCase" },
];

export const tableQuestions = (
  <>
    OPTIONAL: Other sections refer to relatives outside the immediate family you
    have already entered. If you need to add any additional relatives you can
    add them here.
  </>
);

export const nonRequired = ["suffix_name", "middle_name"];

export const defaultInitialValues = {
  is_deceased: null,
  is_retired: null,
  first_name: "",
  middle_name: "",
  last_name: "",
  suffix_name: "",
  relative_type: "",
  occupation: "",
  date_of_birth: null,
  place_of_birth: "",
  phone_number: "",
  home_address: address,
};

export const endpoint = "other_relatives_otherRelative";
