import { fieldTypes } from "componentGenerators/formGenerator/config";
import { NumberFormatTypes } from "components/Common/Inputs/NumberField";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Business Background",
  },
  {
    type: fieldTypes.label,
    label:
      "Please provide a detailed description of the business entity you are applying for.",
  },
  {
    type: fieldTypes.text,
    label: "Description of Business",
    name: "bd_maryland_swcla.sw_business_description",
    rows: 2,
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label:
      "Please provide a detailed description of the type of goods or services to be provided to a Sports Wagering Licensee.",
  },
  {
    type: fieldTypes.text,
    label: "Description of Goods or Services",
    name: "bd_maryland_swcla.sw_types_of_service",
    rows: 2,
    xs: 12,
  },
  {
    type: fieldTypes.label,
    label:
      "Please provide the name of the Sports Wagering Licensee to which such goods or services are to be provided.",
  },
  {
    type: fieldTypes.text,
    label: "Licensee Name",
    name: "bd_maryland_swcla.sw_providing_goods_and_services",
    rows: 2,
    xs: 12,
  },
  {
    type: fieldTypes.subHeader,
    label:
      "Provide the details of the agreement that the Applicant has entered into with a Sports Wagering Licensee(s) or Sports Wagering License Applicant(s) in the boxes below.",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label: "The Date That the Applicant formally agreed to conduct Business",
  },
  {
    type: fieldTypes.date,
    label: "Agreement Date",
    name: "bd_maryland_swcla.sw_business_start_date",
  },
  {
    type: fieldTypes.label,
    label: "The Anticipated Contract Start and End Dates",
  },
  {
    type: fieldTypes.date,
    label: "Contract Start Date",
    name: "bd_maryland_swcla.sw_contract_start_date",
  },
  {
    type: fieldTypes.date,
    label: "Contract End Date",
    name: "bd_maryland_swcla.sw_contract_end_date",
  },
  {
    type: fieldTypes.text,
    label: "Terms of Compensation",
    name: "bd_maryland_swcla.terms_of_compensation",
    rows: 2,
    xs: 12,
  },
  {
    type: fieldTypes.number,
    label: "Amount of Compensation",
    name: "bd_maryland_swcla.amount_of_compensation",
    format: NumberFormatTypes.dollar,
    xs: 12,
  },
  {
    type: fieldTypes.text,
    label:
      "Nature of Contract or Agreement and Goods and/or Services to be Provided.",
    name: "bd_maryland_swcla.nature_of_contract",
    rows: 2,
    xs: 12,
  },
];

export default [
  {
    type: "form",
    formSchema,
    endpoint: "bdSupplement",
  },
];
