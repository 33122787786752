import { fieldTypes } from "componentGenerators/formGenerator/config";
import { formats } from "components/Common/Tables/Common/helpers";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Position applied for",
  },
  {
    type: fieldTypes.text,
    label: "Position Title",
    name: "rhode_island_phd.position_applied",
  },
  {
    type: fieldTypes.subHeader,
    label: "Check location(s)",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "rhode_island_phd.location_checkbox",
    dynamicMenuItems: ["rhodeIslandLocationCheckbox"],
  },
  {
    type: fieldTypes.subHeader,
    label: "holding company",
  },
  {
    type: fieldTypes.label,
    label:
      "If applicable, the name of the holding company (ies) of the facility or licensee with which you have any positions.",
  },
  {
    type: fieldTypes.text,
    label: "Company Names",
    name: "rhode_island_phd.associated_holding_company",
    xs: 12,
    rows: 4,
  },
  {
    type: fieldTypes.subHeader,
    label: "Associated Positions",
  },
  {
    type: fieldTypes.label,
    label:
      "Please identify if any of the below positions apply to you in connection with the above two questions.",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "rhode_island_phd.key_position_checkbox",
    dynamicMenuItems: ["rhodeIslandAssociatedPositionCheckbox"],
    defaultValue: null,
  },
  {
    type: fieldTypes.text,
    label: "Other Position Description",
    name: "rhode_island_phd.key_position_other_description",
  },
];

const licenses = {
  type: "readOnlyTable",
  endpoint:
    "rhode_island_phd_supplement_disclosure_questions_licenses_applicant_personal_rhode_island",
  linkTo: ["personal_licensing"],
  columns: [
    {
      title: "From",
      field: "from_date",
      format: formats.date,
    },
    {
      title: "To",
      field: "to_date",
      format: formats.date,
    },
    {
      title: "Licensing Agency",
      field: "agency_name",
    },
    {
      title: "Industry",
      field: "type",
      format: "startCase",
    },
    {
      title: "Number",
      field: "license_number",
    },
    {
      field: "disposition",
      format: "startCase",
    },
  ],
  dependsOnQueryCount:
    "Have you ever applied to the Division of Gaming and Athletics Licensing for any license in the past? If yes, complete the following.",
};

export default [
  licenses,
  {
    type: "form",
    formSchema,
    endpoint: "rhode_island_phd_supplement_disclosure_questions_pdSupplement",
  },
];
