import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Grid, Divider, Typography, Box, Hidden } from "@material-ui/core";
import styled from "styled-components";
import Tooltip from "components/Common/Tooltip";

const StyledAlert = styled(Alert)`
  & .MuiAlert-message {
    width: 100%;
  }
`;

function checkState(item) {
  if (Array.isArray(item)) {
    return item.length > 0;
  } else if (typeof item === "object") {
    return item.display_value;
  } else {
    return false;
  }
}

const AlertBody = ({
  title,
  subheader,
  question,
  answerLabel,
  dependsOnQueryCount,
}) => (
    <>
      <AlertTitle>{title}</AlertTitle>
      <Grid container spacing={1}>
        {subheader && (
          <Grid item xs={12}>
            <li>{subheader()}</li>
          </Grid>
        )}

        {question && (
          <Grid item xs={12}>
            {dependsOnQueryCount ? (
              <DependsOnQuestionSection
                answerLabel={answerLabel}
                hasfilteredState={checkState(question)}
                dependsOnQueryCount={dependsOnQueryCount}
              />
            ) : null}
          </Grid>
        )}
      </Grid>
    </>
  );

const AlertHeader = ({ showIcon = true, ...rest }) => (
  <>
    {showIcon ? (
      <StyledAlert severity="warning">
        <AlertBody {...rest} />
      </StyledAlert>
    ) : (
        <StyledAlert severity="warning" icon={false}>
          <AlertBody {...rest} />
        </StyledAlert>
      )}
  </>
);

const DependsOnQuestionSection = ({
  dependsOnQueryCount,
  hasfilteredState,
  answerLabel,
}) => (
    <Grid container alignItems="center" spacing={2}>
      <Grid item md={6} sm={8} xs={12}>
        {typeof dependsOnQueryCount === "object" ? (
          <Typography variant="body2">
            {dependsOnQueryCount.question}
            {dependsOnQueryCount.tooltip && (

              <Tooltip title={dependsOnQueryCount.tooltip} />
            )}
          </Typography>
        ) : (
            <Typography variant="body2">{dependsOnQueryCount}</Typography>
          )}
        <Typography variant="body2"></Typography>
      </Grid>
      <Hidden xsDown>
        <Grid item>
          <Divider orientation="vertical" flexItem style={{ height: "40px" }} />
        </Grid>
      </Hidden>
      <Grid item>
        <Box display="flex">
          {answerLabel ? (
            answerLabel
          ) : (
              <Typography variant="body2">Your Answer</Typography>
            )}
          <div style={{ padding: "0 0.5em" }}>:</div>
          <strong>{hasfilteredState ? "YES" : "NO"}</strong>
        </Box>
      </Grid>
    </Grid>
  );

export default AlertHeader;
