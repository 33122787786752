import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "License Information",
  },
  {
    type: fieldTypes.label,
    label: "Please select below the type of license you are applying for:",
  },
  {
    type: fieldTypes.checkBoxGroup,
    name: "cherokee_vendor_license.license_type_checkbox",
    dynamicMenuItems: ["cherokeeLicenseTypeCheckbox"],
    defaultValue: null,
  },

  {
    type: fieldTypes.bdPersonSelect,
    label: "Contact Person",
    header: "Contact Person",
    helperText: "Selct your contact person",
    name: "cherokee_vendor_license.contact_person_id",
  },
  {
    type: fieldTypes.label,
    label:
      "Please provide below the contact person's driver License number and the issuing state:",
  },
  {
    type: fieldTypes.text,
    label: "License Number",
    name: "cherokee_vendor_license.contact_person_driver_license",
  },
  {
    type: fieldTypes.text,
    label: "Issuing State",
    name: "cherokee_vendor_license.contact_person_driver_license_state",
  },
];

export default [
  {
    type: "form",
    formSchema,
    endpoint: "bdSupplement",
  },
];
