import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";
import { mainNavBarIcon } from "./config";
import { Box } from "@material-ui/core";

const MainNavItemWrapper = styled.div`
  text-align: center;
  cursor: pointer;
  padding: 10px 0px;
  ${({ $selected }) =>
    $selected &&
    css`
      background: #3b4f65;
      border-left: 3px solid #e99916;
    `}
`;

const MainSideBarWrapper = styled.div`
  float: left;
  width: 25%;
  font-size: 12px;
  background: #243b53;
  height: calc(100% - 45px);
  padding: 5px 0px;
`;

const MainNavItem = ({ selected, name, onClick, icon }) => (
  <MainNavItemWrapper
    $selected={selected}
    cy-data={_.camelCase(name)}
    onClick={onClick}
  >
    {icon}
    <Box pr={0.1} pl={0.1} mr={0.4}>
      {name}
    </Box>
  </MainNavItemWrapper>
);

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
export default function SidebarWrapper({
  className,
  links,
  navState,
  navWidth,
  onChangeMainNav,
}) {
  if (navState.sidebarLoading) {
    return (
      <div style={{ textAlign: "center" }}>
        <h1>Loading</h1>
        <CircularProgress />
      </div>
    );
  }

  //SIDEBAR
  return (
    <div className={className} style={{ width: navWidth, height: "100%" }}>
      <MainSideBarWrapper>
        {_.map(navState.mainNavBar, (item) => (
          <MainNavItem
            key={item.name}
            selected={navState.selectedMainMenu === item.name}
            name={item.name}
            onClick={() => onChangeMainNav(item)}
            icon={mainNavBarIcon[item.tag]}
          />
        ))}
      </MainSideBarWrapper>
      <div
        cy-data="sidebar"
        style={{
          display: "contents",
        }}
      >
        {links}
      </div>
    </div>
  );
}

SidebarWrapper.propTypes = {
  className: PropTypes.string,
  headerLinks: PropTypes.object,
  links: PropTypes.object,
  state: PropTypes.object,
};
