import React, { useReducer, createContext, useContext, useEffect } from "react";
import { updateModel, getModel } from "helpers/apiHelpers";
import { useAuth } from "context/auth";
import useLeftNav from "hooks/useLeftNav";
import { get } from "lodash";

const LoadFormStatusContext = createContext();
const UpdateFormStatus = createContext();

function extractInformation(data, itemsMeta) {
  return data.reduce(
    (prev, form) => ({
      ...prev,
      [form.tag]: {
        status: form.form_status.length ? form.form_status[0].status : "",
        id: form.id,
        path: form.tag,
        name: form.name,
        notes: form.form_status?.[0]?.notes || null,
        meta: {
          notes: itemsMeta?.[form.tag]?.["form_notes_flag"],
          status: itemsMeta?.[form.tag]?.["form_status_flag"],
        },
      },
    }),
    {}
  );
}

function statusReducer(state, action) {
  if (action.type === "LOAD") {
    return {
      ...state,
      loading: false,
      formStatus: action.data,
    };
  }

  if (action.type === "UPDATE_FORMSTATUS") {
    state.formStatus[get(action, "data.tag")] = {
      ...state.formStatus[get(action, "data.tag")],
      status: get(action, "data.form_status[0].status"),
      notes: get(action, "data.form_status[0].notes"),
      id: get(action, "data.id"),
    };
    return {
      ...state,
    };
  }
  return state;
}

export default function FormStatusProvider({ children }) {
  const { employee_id, bd_organization_id, bd_business_entity_id } = useAuth();
  const {
    navState: { navBarTagMap },
  } = useLeftNav();

  const [state, dispatch] = useReducer(statusReducer, {
    loading: true,
    formStatus: [],
    hasEmployeeId: employee_id !== "null",
  });

  useEffect(() => {
    if (
      Object.keys(navBarTagMap).length !== 0 &&
      navBarTagMap.constructor === Object
    ) {
      if (employee_id !== "null" && employee_id !== null) {
        const endpointPath = `employee/${employee_id}/personal_details_formStatus`;
        getModel(endpointPath)
          .then((data) => {
            dispatch({
              type: "LOAD",
              data: extractInformation(data, navBarTagMap),
            });
          })
          .catch(() => {
            dispatch({
              type: "LOAD",
              data: {},
            });
          });
      } else if (bd_organization_id !== "null" && bd_organization_id !== null) {
        const endpointPath = `bdOrganization/${bd_organization_id}/bd/${bd_business_entity_id}/formStatus?account_type=business`;
        getModel(endpointPath)
          .then((data) => {
            dispatch({
              type: "LOAD",
              data: extractInformation(data, navBarTagMap),
            });
          })
          .catch(() => {
            dispatch({
              type: "LOAD",
              data: {},
            });
          });
      } else {
        dispatch({
          type: "LOAD",
          data: {},
        });
      }
    }
  }, [bd_business_entity_id, bd_organization_id, employee_id, navBarTagMap]);

  return (
    <LoadFormStatusContext.Provider value={state}>
      <UpdateFormStatus.Provider
        value={({ status, notes }, form_nav_id) => {
          let endpointPath = `employee/${employee_id}/personal_details_formStatus`;
          if (bd_organization_id) {
            endpointPath = `bdOrganization/${bd_organization_id}/bd/${bd_business_entity_id}/formStatus`;
          }
          return updateModel(endpointPath, form_nav_id, {
            status,
            notes,
          }).then(({ data }) => {
            dispatch({
              type: "UPDATE_FORMSTATUS",
              data,
            });
          });
        }}
      >
        {children}
      </UpdateFormStatus.Provider>
    </LoadFormStatusContext.Provider>
  );
}

export function useFormInformation() {
  const context = useContext(LoadFormStatusContext);
  if (context === undefined) {
    throw new Error(
      "useFormInformation must be used within FormStatusProvider"
    );
  }
  return context;
}

export function useUpdateFormStatus() {
  const context = useContext(UpdateFormStatus);
  if (context === undefined) {
    throw new Error(
      "useUpdateFormStatus must be used within FormStatusProvider"
    );
  }
  return context;
}
