import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Authorized Contact",
  },
  {
    type: fieldTypes.bdPersonSelect,
    label: "Contact Person",
    name: "michigan_business_disclosure.authorized_contact_bd_person_id",
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "bdSupplement",
};
