import { Grid } from "@material-ui/core";
import EnhancedFileDropzone from "components/Common/FileDropZone/EnhancedFileDropzone.jsx";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import Tooltip from "components/Common/Tooltip";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import PDBusinessSelect from "components/Formik/formGroups/CrudSelect/selects/PDBusinessSelect.jsx";
import PDLawEnforcementSelect from "components/Formik/formGroups/CrudSelect/selects/PDLawEnforcementSelect.jsx";
import PDRelativeSelect from "components/Formik/formGroups/CrudSelect/selects/PDRelativeSelect.jsx";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, dataHookProps, tableQuestions } from "./config.js";

const Form = () => {
  const {
    lawsuitAndArbitrationDispositionType,
    disputeResolutionMethod,
    roleInDispute,
  } = useMenuOptions();

  return (
    <Grid container spacing={2}>
      <PDRelativeSelect
        label="Relative (If Applicable)"
        header="Relative (If Applicable)"
      />
      <FormikSelect
        label="Select Type"
        name="type"
        header={"Resolution Method & Role"}
        divider
        options={disputeResolutionMethod}
        xs={6}
      />
      <FormikSelect
        label="Your role in the dispute"
        name="role_in_dispute"
        options={roleInDispute}
        xs={6}
      />

      <SectionSubHeader
        label="Associated Business Entity (If Applicable)"
        divider
      />
      <PDBusinessSelect label="Business Entity (If Applicable)" />
      <SectionSubHeader label="Law Enforcement or Court" divider />
      <PDLawEnforcementSelect />

      <FormikTextField
        header="Lawsuit Details"
        divider
        label="Docket Number"
        name="case_number"
        xs={4}
      />
      <FormikTextField label="Nature of Suit" name="nature" xs={8} />
      <FormikDatePicker label="Date Filed" name="filing_date" xs={4} />
      <FormikTextField
        label="Other Parties to Suit"
        name="other_parties"
        xs={8}
      />
      <FormikTextField
        label="Approximate Date(s)"
        helperText="Date(s) of Lawsuit/Arbitration/Bankruptcy"
        name="time_period"
        xs={6}
      />
      <FormikTextField
        label="Location Action Filed"
        helperText="City, State, County"
        name="action_location"
        xs={6}
      />
      <FormikDatePicker
        header="Disposition"
        divider
        label="Disposition Date"
        name="disposition_date"
        xs={4}
      />
      <FormikSelect
        label="Disposition"
        name="disposition"
        options={lawsuitAndArbitrationDispositionType}
        xs={6}
      />
      <SectionLabel
        header="Relief Sought & Impact"
        divider
        label="For all pending litigation, unsatisfied judgments, decrees, restraining orders, protective orders, or injunctive orders, provide the following."
      />
      <FormikTextField
        label="Type and Amount of Relief Sought"
        name="relief_sought"
        xs={12}
        multiline
        rows={2}
      />
      <FormikTextField
        label="Gaming Operational Impact Assessment"
        name="gaming_operation_impact_assessment"
        helperText="Assessment of the Impact, if any, the action may have on your Gaming Operation"
        xs={12}
        multiline
        rows={2}
      />
      <SectionSubHeader label="Documentation" divider />
      <SectionLabel label="Upload a copy of all documentation in the matter (not required by all jurisdictions)">
        <Tooltip title="Required by Missouri" type="warning" />
      </SectionLabel>
      <EnhancedFileDropzone
        description="Personal Lawsuits"
        name="attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};

const Lawsuits = ({ name }) => {
  const formData = useTabularData(dataHookProps);

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default Lawsuits;
