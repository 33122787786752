//APPLICATIONS
import ManageApplications from "components/Pages/Applications/ManageApplications";
import RequestApplications from "components/Pages/Applications/RequestApplications/RequestApplications";

const bd_additional_pages = {
  bd_active_applications: ManageApplications,
  bd_request_applications: RequestApplications,
};

export default bd_additional_pages;
