import React from "react";

export const columns = [
  {
    title: "Lobbyist Name",
    field: "related_party_name",
  },
  {
    title: "From Date",
    field: "from_date",
    format: "date",
  },
  {
    title: "To Date",
    field: "to_date",
    format: "date",
  },
  /*{
    title: "Business Entity",
    field: "bd_business_entity.business_name",
  },*/
];

export const tableQuestions = (
  <>
    Does the Applicant have any other company or person including lobbyists
    acting on its behalf in the State or jurisdiction of application or
    elsewhere?
  </>
);

export const defaultInitialValues = {
  related_bd_party_role: "lobbyist",
  //bd_business_entity_id: null,
  related_bd_party_id: null,
  from_date: null,
  to_date: null,
  is_current: false,
  service_rendered: null,
  lobbyist_liaison_bd_person_id: null,
  fee_arrangement: "",
  is_lobbyist_cash_fund: null,
  lobbyist_cash_fund_inventory_list: "",
  is_lobbyist_pledged_monetary: null,
  lobbyist_pledged_monetary_explain: "",
  is_lobbyist_transfered_cash: null,
  lobbyist_transfered_cash_explain: "",
};
