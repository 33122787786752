import { Box, Divider, Typography } from "@material-ui/core";
import React from "react";
import { StyledDropDown, TotalIncomeWrapper } from "./AnnualIncome.styles";
import { yearSelectMenuOptions } from "./helpers";
import InfoIcon from "@material-ui/icons/Info";

const SelectYearHelper = () => (
  <div
    style={{
      marginLeft: "30px",
      display: "flex",
      alignItems: "center",
    }}
  >
    <InfoIcon color="primary" />
    <Typography>
      <Box
        fontWeight="fontWeightMedium"
        m={1}
        fontSize={20}
        fontStyle="oblique"
      >
        Select a Year
      </Box>
    </Typography>
  </div>
);

export const SubHeader = ({ handleYearSelect, year, total }) => (
  <>
    <Box display="flex" justifyContent="space-between">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h4">
          <Box fontWeight="fontWeightMedium">Selected Year:</Box>
        </Typography>
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <StyledDropDown
            style={{ width: "200px" }}
            label="Year"
            value={year}
            onChange={handleYearSelect}
            menuItems={yearSelectMenuOptions}
          />
          {!year && <SelectYearHelper />}
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h4">
          <Box fontWeight="fontWeightMedium">Total Annual Income:</Box>
        </Typography>
        <TotalIncomeWrapper>
          <Typography variant="h2">
            <Box fontWeight="fontWeightBold">{total}</Box>
          </Typography>
        </TotalIncomeWrapper>
      </div>
    </Box>
    <Divider />
  </>
);
