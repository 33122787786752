import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import YesNo from "components/Formik/formGroups/YesNo.jsx";
import { useMenuOptions } from "hooks/useMenuOptions.js";
import FormikNumberField from "components/Formik/fields/FormikNumberField.jsx";

const Form = () => {
  const { renumerationType } = useMenuOptions();
  return (
    <Grid container spacing={2}>
      <FormikTextField
        label="Name and Address of all Parties Involved"
        name="party_names_and_addresses"
        xs={12}
        multiline
        rows={2}
      />
      <FormikTextField
        label="Nature of Goods or Service Provided"
        name="nature"
        xs={8}
      />
      <FormikSelect
        options={renumerationType}
        name="renumeration_type"
        label="Renumeration Type"
      />
      <FormikNumberField label="Amount Received" name="amount" xs={4} />
      <FormikDatePicker label="Date Received" name="date_received" xs={4} />
      <YesNo label="Is this gaming related?" name="is_gaming_related" xs={12} />
    </Grid>
  );
};

const ReferralFees = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "referral_fees_referralFee",
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default ReferralFees;
