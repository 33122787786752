import { Grid } from "@material-ui/core";
import { SectionLabel } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikNumberField, {
  NumberFormatTypes,
} from "components/Formik/fields/FormikNumberField.jsx";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import PDRelativeSelect from "components/Formik/formGroups/CrudSelect/selects/PDRelativeSelect.jsx";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import ColumnSum from "components/Layout/SectionsLayout/ColumnSum.jsx";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import {
  columns,
  dataHookProps,
  modalTitle,
  tableQuestions,
} from "./config.js";

const Form = () => {
  const { listOfCountries, vehicleOwnership } = useMenuOptions();

  return (
    <Grid container spacing={2}>
      <PDRelativeSelect
        label="Spouse or Child (If Applicable)"
        header="Select if held by Spouse or Dependent Child"
      />

      <FormikTextField
        header="Vehicle Details"
        divider
        label="Type of Vehicle"
        name="vehicle_type"
        xs={4}
      />
      <FormikTextField label="Make / Model" name="vehicle_model" xs={4} />
      <FormikNumberField
        label="Year"
        name="vehicle_year"
        type={NumberFormatTypes.year}
        xs={4}
      />

      <FormikSelect
        header="Financial Details"
        divider
        label="Location of Vehicle"
        name="location_country"
        options={listOfCountries}
        xs={4}
      />
      <FormikSelect
        label="Owned or Leased"
        name="leased_or_owned"
        options={vehicleOwnership}
        xs={4}
      />
      <FormikDatePicker
        label="Purchase/Lease Date"
        name="acquisition_date"
        xs={4}
      />
      <FormikTextField
        label="Lease Details, if Applicable"
        name="lease_details"
        xs={12}
        helperText="Length of Lease, Total Lease Costs, Down Payments, Monthly Payments, Number Payments of Lease Life"
      />
      <FormikNumberField label="Cost *" name="cost" xs={6} />
      <FormikNumberField
        label="Current Market Value"
        name="current_value"
        helperText="Only if Owned"
        xs={6}
      />
      <SectionLabel
        label="* If leased, enter the sum of the down payment + monthly payments to date"
        xs={12}
      />
    </Grid>
  );
};

const Vehicles = ({ name }) => {
  const formData = useTabularData(dataHookProps);

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={modalTitle}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        footer={
          <ColumnSum
            name="current_value"
            data={formData.data}
            label="Current Cash Value (if owned)"
          />
        }
        columns={columns}
        addButtonText="Add Record"
      />
    </FormSectionProvider>
  );
};

export default Vehicles;
