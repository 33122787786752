import PropTypes from "prop-types";
import { Box, Grid } from "@material-ui/core";
import FormikInlineTable from "components/Common/Tables/FormikInlineTable/FormikInlineTable";
import { FormikAddressFields } from "components/Formik/formGroups/FormikAddressFields";
import FormikGoogleLookup from "components/Formik/fields/FormikGoogleLookup";
import React from "react";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormikEmailTextField from "components/Formik/fields/FormikEmailTextField";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import FromToCurrent from "./FromToCurrent";
import { address } from "helpers/initialValues";
import { generateFullAddress } from "helpers/addressHelpers";
import FormikCheckBoxGroup from "./FormikCheckBoxGroup";
import { useMenuOptions } from "hooks/useMenuOptions";

const AddressCell = ({ address }) => {
  return (
    <Box>
      {address ? <>{generateFullAddress(address)}</> : "No Address Provided"}
    </Box>
  );
};

AddressCell.propTypes = {
  address: PropTypes.object,
};

const addressColumns = [
  {
    field: "address",
    title: "Address",
    render: AddressCell,
    disableSorting: true,
  },
];

export const addressIntitialValues = {
  address: address,
  from_date: null,
  to_date: null,
  is_current: false,
  website: "",
  phone: "",
  fax: "",
  email: "",
  purpose_checkbox: {
    is_registered_business_address: false,
    is_principal_activity_conducted: false,
    is_branch_office: false,
  },
};

export const AddressForm = ({ fields, addressPrefix, items }) => (
  <Grid container spacing={1}>
    <SectionSubHeader label="Purpose of Address (select all that apply)" />

    <Box width={1} pl={1}>
      <FormikCheckBoxGroup menuOptions={items} name="purpose_checkbox" />
    </Box>
    <FormikGoogleLookup fields={fields} />

    <SectionSubHeader divider label="Address" />

    <FormikAddressFields addressPrefix={addressPrefix} />
    <SectionSubHeader label="Dates" divider />

    <FromToCurrent />

    <SectionSubHeader divider label="Additional Information" />

    <FormikTextField label="Website" name="website" xs={6} />
    <FormikEmailTextField label="Email" name="email" xs={6} />
    <FormikTextField label="Phone" name="phone" xs={6} />
    <FormikTextField label="Fax" name="fax" xs={6} />
  </Grid>
);

AddressForm.propTypes = {
  addressPrefix: PropTypes.string,
  fields: PropTypes.shape({
    address: PropTypes.string,
    website: PropTypes.string,
    phone: PropTypes.string,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.any,
    })
  ),
};

const InlineAddressTable = ({
  lookupFields = {
    address: "address",
    website: "website",
    phone: "phone",
  },
  addressPrefix = "address.",
  defaultInitialValues = addressIntitialValues,
  columns = addressColumns,
  dialogTitle = "Address",
  addButtonText = "Add Address",
  name = "bd_address",
  form,
  ...props
}) => {
  const { bdAddressPurposeCheckboxOptions } = useMenuOptions();
  const defaultForm = (
    <AddressForm
      fields={lookupFields}
      addressPrefix={addressPrefix}
      items={bdAddressPurposeCheckboxOptions}
    />
  );
  return (
    <Grid item xs={12}>
      <FormikInlineTable
        name={name}
        addButtonText={addButtonText}
        dialogTitle={dialogTitle}
        columns={columns}
        defaultInitialValues={defaultInitialValues}
        form={form || defaultForm}
        {...props}
      />
    </Grid>
  );
};

InlineAddressTable.propTypes = {
  addButtonText: PropTypes.string,
  addressPrefix: PropTypes.string,
  columns: PropTypes.array,
  defaultInitialValues: PropTypes.object,
  dialogTitle: PropTypes.string,
  form: PropTypes.node,
  lookupFields: PropTypes.shape({
    address: PropTypes.string,
    website: PropTypes.string,
    phone: PropTypes.string,
  }),
  name: PropTypes.string,
};

export default InlineAddressTable;
