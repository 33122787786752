import { InfoAlert } from "components/Layout/Alert/InfoAlert.jsx";
import React from "react";

export const BusinessDisclosureDisclaimer = () => (
  <InfoAlert severity="warning">
    Creating a business disclosure may result in additional billing.
  </InfoAlert>
);

export const CollaboratorsDisclosureDisclaimer = () => (
  <InfoAlert severity="warning">
    Collaborators will have access to view and edit all business disclosures.
  </InfoAlert>
);
