export const columns = [
  {
    title: "Business",
    field: "business_name",
  },
  {
    title: "Shared Owned",
    field: "number_shares_owned",
  },
  { title: "Nature of Interest", field: "nature_of_interest" },
  { title: "Ownership %", field: "ownership_percent" },
];

export const dependsOnQueryCount = "Do you have any ownership interest, financial interest or financial investment in any business entity applying to, or presently licensed by, the New Mexico Gaming Control Board?";