import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.label,
    label:
      "If applicable, please provide a narrative detailing the reasons for such audit or adjustment.",
  },
  {
    type: fieldTypes.text,
    label: "Details",
    name: "new_york_cvke.tax_return_details",
    xs: 12,
    rows: 4,
  },
];

const financialTable = {
  type: "readOnlyTable",
  endpoint: "new_york_cvke_supplement_tax_information_taxReturnActivity",
  linkTo: ["tax_audit"],
  columns: [
    {
      title: "Tax Year",
      field: "tax_year",
    },
    {
      title: "Tax Office",
      field: "tax_agency_pd_business_entity.business_name",
    },
    {
      title: "Activity Type",
      field: "activity_type",
    },
  ],
  dependsOnQueryCount: `Have your Federal Income Tax Returns ever been audited or adjusted? 
    Please review the following table to answer the question below.`,
};

export default [
  financialTable,
  {
    type: "form",
    formSchema,
    endpoint: "new_york_cvke_supplement_tax_information_pdSupplement",
  },
];
