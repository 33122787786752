import { Box, IconButton, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const EditButton = styled(IconButton)`
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`;

export const DefaultOption = ({
  option,
  onEditOption,
  getOptionLabel,
  getSecondaryLabel,
  icon,
}) => {
  if (option.addNew)
    return (
      <Box display="flex" justifyContent="center" width={1} pt={1}>
        <Box pr={1}>
          <AddCircleOutlineIcon color="primary" />
        </Box>
        <Typography color="primary">Add New</Typography>
      </Box>
    );

  const label = getOptionLabel(option) || "N/A";
  const secondaryLabel = getSecondaryLabel ? getSecondaryLabel(option) : null;
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width={1}
    >
      <Box display="flex" alignItems="center">
        {icon && (
          <Box pr={1.5} height={19}>
            {icon}
          </Box>
        )}
        {label}
        {secondaryLabel ? (
          <Box pl={1}>
            <Typography variant="body2">{secondaryLabel}</Typography>
          </Box>
        ) : null}
      </Box>
      {onEditOption && (
        <EditButton size="small" onClick={() => onEditOption(option)}>
          <EditIcon color="action" />
        </EditButton>
      )}
    </Box>
  );
};

DefaultOption.propTypes = {
  onEditOption: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getSecondaryLabel: PropTypes.func,
  icon: PropTypes.node,
  option: PropTypes.shape({
    addNew: PropTypes.bool,
    name: PropTypes.string,
  }),
};
