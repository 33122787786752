import { Grid } from "@material-ui/core";
import React from "react";
import { useMenuOptions } from "hooks/useMenuOptions";
import FormikSelect from "components/Formik/fields/FormikSelect";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import { FormikDatePicker } from "components/Formik/fields";

export const EditAuthorizedStateForm = () => {
  const { listOfUSStates } = useMenuOptions();
  return (
    <Grid container spacing={2}>
      <FormikSelect
        options={listOfUSStates}
        row
        label="Authorized State"
        name="state_code"
        xs={6}
      />
      <FormikDatePicker
        label="Date Authorized"
        helperText="Qualification to Conduct Business"
        name="date_authorized"
        xs={6}
      />

      <FormikTextField
        label="State Employer Identification Number"
        name="state_employer_id_number"
        xs={12}
      />
      <FormikFileDropzone
        description="Certificate Of Authority"
        label="Upload Attachment"
        name="certificate_of_authority_attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};
