import PropTypes from "prop-types";
import React from "react";
import { useField } from "formik";
import withFieldTools from "hocs/withFieldTools";
import { useDropzoneEndpoints } from "../../Common/FileDropZone/useDropzoneEndpoints";
import { FileDropzone } from "components/Common/FileDropZone/FileDropzone";

function FormikFileDropzoneBase({ id: _id, ...props }) {
  const [field, _meta, helpers] = useField(props.name);

  const { getAttachmentEndpoints, attachmentGroupEndpoint } =
    useDropzoneEndpoints();
  return (
    <FileDropzone
      setAttachmentGroupID={(id) => helpers.setValue(id)}
      attachmentGroupID={field.value}
      getAttachmentEndpoints={getAttachmentEndpoints}
      attachmentGroupEndpoint={attachmentGroupEndpoint}
      {...props}
    />
  );
}

FormikFileDropzoneBase.propTypes = {
  name: PropTypes.string.isRequired,
};

const EnhancedFormikFileDropzone = withFieldTools(FormikFileDropzoneBase);

const FormikFileDropzone = ({ tooltip: _tooltip, ...rest }) => (
  <EnhancedFormikFileDropzone xs={12} {...rest} />
);

FormikFileDropzone.propTypes = {
  tooltip: PropTypes.node,
};

export default FormikFileDropzone;
