import React, { Fragment } from "react";

export default function GenericError(props) {
  const { errors, name } = props;

  return (
    <Fragment>
      {errors && errors[props.name] ? (
        <div style={{ color: "red" }}>{errors[name]}</div>
      ) : null}
    </Fragment>
  );
}
