import { Divider } from "@material-ui/core";
import { MultiSelect } from "components/Pages/LicensingControl/components/FilterTable/MultiSelect.jsx";
import React from "react";

export const EmployeeFilterForm = ({
  industryProps,
  dispositionProps,
  industryOptions,
  dispositionOptions,
}) => {
  return (
    <>
      <MultiSelect
        options={industryOptions}
        label={"Industry"}
        {...industryProps}
        placeholder="Select Industry"
      />

      <Divider style={{ margin: "20px 0px" }} />

      <MultiSelect
        options={dispositionOptions}
        label={"Disposition"}
        placeholder="Select Disposition"
        {...dispositionProps}
      />

      <Divider style={{ margin: "20px 0px" }} />
    </>
  );
};
