import { Box, Divider, Typography } from "@material-ui/core";
import React from "react";

const CustomCardHeader = ({ title, action }) => (
  <>
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      p={2}
    >
      <Typography variant="h3">
        <Box fontWeight="fontWeightMedium">{title}</Box>
      </Typography>
      {action}
    </Box>
    <Divider />
  </>
);

export default CustomCardHeader;
