import { Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import PDBusinessSelect from "components/Formik/formGroups/CrudSelect/selects/PDBusinessSelect.jsx";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Business Entity" />
      <PDBusinessSelect />

      <SectionSubHeader label="Trustee" divider />
      <PDBusinessSelect name="trustee_pd_business_entity_id" label="Trustee" />

      <SectionSubHeader label="Details" divider />
      <FormikDatePicker name="liquidation_date" />
      <FormikTextField name="case_number" xs={4} />
      <FormikTextField label="Current Status" name="status" xs={4} />
      <FormikTextField
        label="Your Relationship to the Business"
        name="relationship_to_business"
        xs={6}
      />
      <FormikTextField
        label="Reason Placed under Receivership"
        name="reason"
        xs={6}
      />
    </Grid>
  );
};

const BusinessReceivership = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "business_receiverships_liquidation",
    overwriteMissingRequiredDisplay: {
      trustee_pd_business_entity_id: "Trustee",
    },
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default BusinessReceivership;
