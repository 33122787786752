import React from "react";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import CustomLink from "components/Common/Link";

const formSchema = [
  {
    type: fieldTypes.label,
    label:
      "Upload a list of services to be provided including any links to websites, powerpoint presentations, screen shots or information that will assist in our review process.",
  },
  {
    type: fieldTypes.dropZone,
    name: "west_virginia_swil.services_attachment_group_id",
    description: "Services Provided",
    label: "Upload Attachment",
  },
  {
    type: fieldTypes.label,
    label: "If not applicable or available, please explain below:",
  },
  {
    type: fieldTypes.text,
    label: "Explanation",
    name: "west_virginia_swil.no_services_explanation",
    rows: 2,
    xs: 12,
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.label,
    label:
      "Upload an intent letter to work with a specific West Virginia casino (operator).",
  },
  {
    type: fieldTypes.dropZone,
    name: "west_virginia_swil.intent_letter_attachment_group_id",
    description: "Intent Letter",
    label: "Upload Attachment",
  },
  {
    type: fieldTypes.label,
    label: "If not applicable or available, please explain below:",
  },
  {
    type: fieldTypes.text,
    label: "Explanation",
    name: "west_virginia_swil.no_intent_letter_explanation",
    rows: 2,
    xs: 12,
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.label,
    label: (
      <>
        Add all other Suppliers or Management Services Providers you are in
        contract with for West Virginia Operations in the
        <CustomLink to="bd_contracts" /> section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: "If not applicable or available, please explain below:",
  },
  {
    type: fieldTypes.text,
    label: "Explanation",
    name: "west_virginia_swil.no_supplier_explanation",
    rows: 2,
    xs: 12,
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.label,
    label:
      "Upload a list of jurisdictions where you are licensed, certified, or approved to conduct sports betting management services or supply sports betting equipment, systems or services including license letters and/or certificates.",
  },
  {
    type: fieldTypes.dropZone,
    name: "west_virginia_swil.licenses_attachment_group_id",
    description: "List of Jurisdictions Where Licensed & Certified",
    label: "Upload Attachment",
  },
  {
    type: fieldTypes.label,
    label: "If not applicable or available, please explain below:",
  },
  {
    type: fieldTypes.text,
    label: "Explanation",
    name: "west_virginia_swil.no_licenses_explanation",
    rows: 2,
    xs: 12,
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.label,
    label: (
      <>
        Upload certificates that your business is registered in West Virginia
        with the WV Secretary of State and the WV State Tax Department or
        receipts showing that you are in the process of registering your
        business in the
        <CustomLink to="bd_application_details" label="Authorized States" />
        section.
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: "If not applicable or available, please explain below:",
  },
  {
    type: fieldTypes.text,
    label: "Explanation",
    name: "west_virginia_swil.no_certificates_explanation",
    rows: 2,
    xs: 12,
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.label,
    label:
      "Upload a list of all websites that your business is affiliated with for West Virginia Operations.",
  },
  {
    type: fieldTypes.dropZone,
    name: "west_virginia_swil.websites_attachment_group_id",
    description: "List of Jurisdictions Where Licensed & Certified",
    label: "Upload Attachment",
  },
  {
    type: fieldTypes.label,
    label: "If not applicable or available, please explain below:",
  },
  {
    type: fieldTypes.text,
    label: "Explanation",
    name: "west_virginia_swil.no_websites_explanation",
    rows: 2,
    xs: 12,
  },
  { type: fieldTypes.divider },
  {
    type: fieldTypes.label,
    label:
      "Upload a list of independent testing laboratories where your product(s) have been tested and approved, if applicable.",
  },
  {
    type: fieldTypes.dropZone,
    name: "west_virginia_swil.labs_attachment_group_id",
    description: "Lab Testing",
    label: "Upload Attachment",
  },
  {
    type: fieldTypes.label,
    label: "If not applicable or available, please explain below:",
  },
  {
    type: fieldTypes.text,
    label: "Explanation",
    name: "west_virginia_swil.no_labs_explanation",
    rows: 2,
    xs: 12,
  },
];

export default [
  {
    formSchema,
    endpoint: "bdSupplement",
    type: "form",
  },
];
