import PropTypes from "prop-types";
import { Box, Grid, Paper } from "@material-ui/core";
import React from "react";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";

import { KeyboardDatePickerBase } from "components/Formik/fields/FormikDatePicker";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import useTabularData from "hooks/tableHooks/useTabularData";
import { AsyncSearchDropDown } from "components/Common/AsyncSearchDropdown";
import AsyncFormikAutocomplete from "components/Formik/fields/FormikAsyncAutocomplete.jsx";
import dayjs from "dayjs";
import { useFormikContext } from "formik";

import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);

const columns = [
  {
    title: "Activation Date",
    field: "created_at",
    format: "date",
  },
  { title: "Customer", field: "customer.name" },
  { title: "Jurisdiction", field: "pdf_form.jurisdiction.name" },
  { title: "Application", field: "pdf_form.name" },
];

const FilterSection = ({ handleSearch, onChange, state }) => {
  return (
    <Paper>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <KeyboardDatePickerBase
              label="Filter From Date"
              name="from_date"
              onChange={(value) => onChange(value, "from_date")}
              value={state.from_date}
            />
          </Grid>
          <Grid item xs={6}>
            <KeyboardDatePickerBase
              label="Filter To Date"
              name="to_date"
              onChange={(value) => onChange(value, "to_date")}
              value={state.to_date}
            />
          </Grid>

          <AsyncSearchDropDown
            label="Filter by Customer"
            optionLabel="{name}"
            endpoint="/admin/customer"
            noEmployee={true}
            size={6}
            onChange={(item) => {
              const id = item?.id;
              handleSearch("customer_id", id);
            }}
          />

          <AsyncSearchDropDown
            label="Filter by Jurisdiction"
            optionLabel="{name}"
            endpoint="/admin/jurisdiction"
            noEmployee={true}
            size={6}
            onChange={(item) => {
              const id = item?.id;
              handleSearch("jurisdiction_id", id);
            }}
          />
        </Grid>
      </Box>
    </Paper>
  );
};

FilterSection.propTypes = {
  handleSearch: PropTypes.func,
  handleSelectFromDate: PropTypes.func,
  handleSelectToDate: PropTypes.func,
  state: PropTypes.object,
};

const Form = () => {
  const { values } = useFormikContext();
  return (
    <Grid container spacing={2}>
      <AsyncFormikAutocomplete
        name="customer"
        label="Filter by Customer"
        endpoint="/admin/customer"
        helperText="Required"
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        fetchOptions={{ useFullEndpoint: false }}
        xs={6}
      />

      <AsyncFormikAutocomplete
        name="jurisdiction"
        label="Filter By Jurisdiction"
        endpoint="/admin/jurisdiction"
        helperText="Not Required"
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        fetchOptions={{ useFullEndpoint: false }}
        xs={6}
      />

      <AsyncFormikAutocomplete
        name="pdf_form"
        label="Select Application"
        helperText="Required"
        endpoint={`/admin/customer/${
          values.customer?.id || null
        }/jurisdiction/${values.jurisdiction?.id || null}/applicationForm`}
        disabled={!values.customer}
        getOptionLabel={(option) =>
          `${option?.jurisdiction?.name || ""} - ${option?.name || ""}`
        }
        getOptionValue={(option) => option.id}
        fetchOptions={{ useFullEndpoint: false, enabled: !!values.customer }}
        xs={12}
      />
    </Grid>
  );
};

const CustomerApplicationForm = () => {
  const [state, setState] = React.useState({
    customer_id: null,
    jurisdiction_id: "",
    from_date: null,
    to_date: null,
  });

  const formData = useTabularData({
    defaultInitialValues: {
      pdf_form: null,
      customer: null,
      jurisdiction: null,
    },
    postValues: ({ pdf_form, customer, jurisdiction: _jurisdiction }) => ({
      pdf_form_id: pdf_form?.id,
      customer_id: customer?.id,
    }),
    endpoint: "/admin/customerPdfForm",
    saveEndpoint: "/admin/customerPdfForm",
    fetchOptions: { useFullEndpoint: false },
    nonRequired: ["jurisdiction", "customer", "from_date", "to_date"],
  });

  const { data } = formData;
  const filteredData = React.useMemo(
    () =>
      data &&
      data.filter((e) => {
        let shouldFilter = true;
        if (state.customer_id) {
          shouldFilter = state.customer_id === e.customer_id;
        }
        if (state.jurisdiction_id && shouldFilter) {
          shouldFilter = state.jurisdiction_id === e.pdf_form.jurisdiction_id;
        }
        if (state.from_date && state.to_date && shouldFilter) {
          shouldFilter = dayjs(e.created_at).isBetween(
            state.from_date,
            state.to_date,
            null,
            "[)"
          );
        }
        return shouldFilter;
      }),
    [state, data]
  );

  const handleDateChange = ({ name, value }) => {
    setState({ ...state, [name]: value });
  };

  const handleSearch = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  return (
    <FormSectionProvider formData={formData}>
      <Box pb={1}>
        <FilterSection
          handleSearch={handleSearch}
          onChange={handleDateChange}
          state={state}
        />
      </Box>
      <EnhancedFormikDialog>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        handleEditRecord={null}
        columns={columns}
        records={filteredData}
      />
    </FormSectionProvider>
  );
};

export default CustomerApplicationForm;
