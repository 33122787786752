import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents/index.js";
import BDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/BDPersonSelect";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useForm from "hooks/useForm";
import React, { useCallback } from "react";
import { defaultInitialValues } from "./config.js";
import CustomLink from "components/Common/Link.js";
import FormikSelect from "components/Formik/fields/FormikSelect.jsx";
import useEndPoint from "hooks/useEndpoint.js";
import useGet from "hooks/useGet.js";

const Form = () => {
  const { coloradoFormOfBusiness } = useMenuOptions();
  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Gaming Licence Numbers" />
      <FormikTextField
        label="Sport Betting Licensing Number"
        name="colorado_vlma.sports_betting_licensing_number"
        xs={4}
      />
      <FormikTextField
        label="Gaming Retailer License Number"
        name="colorado_vlma.gaming_retailer_license_number"
        xs={4}
      />
      <SectionSubHeader label="Contact Persons" divider />
      <SectionLabel>Primary contact person:</SectionLabel>
      <BDPersonSelect
        name="colorado_vlma.co_primary_contact_person_id"
        label="Person"
        xs={6}
      />
      <SectionLabel>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Person who maintains applicant's business records:
      </SectionLabel>
      <BDPersonSelect
        name="colorado_vlma.co_person_business_records_id"
        label="Person"
        xs={6}
      />
      <SectionLabel>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Person who prepares applicant's tax returns, government forms & reports:
      </SectionLabel>
      <BDPersonSelect
        name="colorado_vlma.co_person_government_forms_id"
        label="Person"
        xs={6}
      />
      <SectionSubHeader label="Colorado Sales Tax License Number" divider />
      <FormikTextField
        label="Sales Tax Number"
        name="colorado_vlma.co_sales_tax"
        xs={4}
      />
      <SectionSubHeader label="Liquor License Number" divider />
      <FormikTextField
        label="Liquor License Number"
        name="colorado_vlma.co_liquor_license"
        xs={4}
      />
      <FormikTextField
        label="Name of Holder"
        name="colorado_vlma.name_of_liquor_holder"
        xs={4}
      />
      <SectionSubHeader label="Type of Business Structure" divider />
      <SectionLabel>
        Colorado offers 2 additional types of businesses in the type of business
        structure section. Please review carefully and select the most
        appropriate answer.
      </SectionLabel>
      <FormikSelect
        label="Form of Business"
        name="colorado_vlma.co_form_of_business"
        options={coloradoFormOfBusiness}
        xs={4}
      />
      <FormikTextField
        label="Other Description"
        name="colorado_vlma.co_form_of_business_other_description"
        helperText="(if other selected)"
        xs={4}
      />
      <SectionSubHeader label="Qualification Date" divider />
      <SectionLabel
        label={
          <>
            Date of qualification to conduct business in Colorado. Upload
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            certificate of authority from the Colorado secretary of state's
            office to
            <CustomLink to="bd_application_details" label="Authorized States" />
            under Colorado entry.
          </>
        }
      />
      <FormikDatePicker
        label="Qualification Date"
        name="colorado_vlma.co_qualification_date"
        xs={4}
      />
      <SectionSubHeader label="Business Associations Gaming" divider />
      <SectionLabel label="Please list your business associations in the gaming/sports betting industry in Colorado." />
      <FormikTextField
        multiline
        rows={4}
        label="Gaming Business Associations"
        name="colorado_vlma.business_associations_gaming"
        xs={12}
      />
      <SectionSubHeader label="Records Location" divider />
      <SectionLabel label="Location of financial books and records for applicant's business:" />
      <FormikTextField
        multiline
        rows={1}
        label="Location"
        name="colorado_vlma.co_records_location"
        xs={12}
      />
    </Grid>
  );
};

const ColoradoVLMAApplicantionDetails = ({ title }) => {
  const { bdBusinessEntityEndpoint } = useEndPoint("");
  const { data, status } = useGet(bdBusinessEntityEndpoint);

  const getInitialValues = useCallback(() => {
    return status !== "success"
      ? defaultInitialValues
      : {
          ...defaultInitialValues,
          colorado_vlma: {
            ...defaultInitialValues.colorado_vlma,
            co_form_of_business:
              defaultInitialValues.colorado_vlma.co_form_of_business ||
              data[0].form_of_business,
          },
        };
  }, [data, status]);

  const formData = useForm({
    defaultInitialValues: getInitialValues(),
    endpoint: "bdSupplement",
  });

  return (
    <FormSectionProvider formData={formData} title={title}>
      <EnhancedFormikPage>
        <Form />
      </EnhancedFormikPage>
    </FormSectionProvider>
  );
};

export default ColoradoVLMAApplicantionDetails;
