import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FromToCurrent from "components/Formik/formGroups/FromToCurrent";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";
import BDBusinessSelect from "components/Formik/formGroups/CrudSelect/selects/BDBusinessSelect.jsx";

const LegalAdvisorsForm = () => {
  return (
    <Grid container spacing={2}>
      <BDBusinessSelect
        name="related_bd_party_id"
        label="Name of Law Firm"
        header="Name of Law Firm"
      />
      <FormikTextField
        name="representative_name"
        label="Name of Legal Representative"
        header="Legal Representative"
        helperText="If applicable"
        xs={6}
      />

      <SectionSubHeader label="Engagement Details" divider />
      <FromToCurrent />

      <FormikTextField
        xs={12}
        label="Service Rendered"
        name="service_rendered"
      />
    </Grid>
  );
};

const LegalAdvisors = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "bdServiceProvider?related_bd_party_role=lawyer",
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog maxWidth="md" title={name}>
        <LegalAdvisorsForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
    </FormSectionProvider>
  );
};

export default LegalAdvisors;
