import PropTypes from "prop-types";
import React from "react";
import TextField from "../../Common/Inputs/TextField";
import { useField } from "formik";
import withFieldTools from "hocs/withFieldTools";
import { startCase } from "lodash";

const validateLength = (maxLength) => (value) => {
  return typeof value === "string" && value.length >= maxLength
    ? `Max character limit of ${maxLength} reached`
    : null;
};

export function FormikTextFieldBase({
  label,
  maxLength = 255,
  size,
  ...props
}) {
  const [field, meta] = useField({
    validate: validateLength(maxLength),
    ...props,
  });
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <TextField
      {...field}
      label={label || startCase(props.name)}
      error={!!errorText}
      {...(size ? { xs: size } : {})}
      {...props}
      helperText={errorText || props.helperText}
    />
  );
}

FormikTextFieldBase.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

const EnhancedTextField = withFieldTools(FormikTextFieldBase);

const FormikTextField = (props) => <EnhancedTextField xs={4} {...props} />;

FormikTextField.propTypes = {
  tooltip: PropTypes.node,
};

export default FormikTextField;
