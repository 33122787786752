// @material-ui/core components
import CustomerAnalytics from "components/Pages/Admin/CustomerAnalytics/CustomerAnalytics";
import ManageApplications from "components/Pages/Applications/ManageApplications";
import React from "react";
import { Route, Switch } from "react-router-dom";
import createRoutes from "routeHelpers/createRoutes";
import { Redirect } from "react-router-dom";
import { useAuth } from "context/auth";

export default function AppPageRouter({ routes }) {
  const { collab_permissions } = useAuth();

  const appRoutes = React.useMemo(() => {
    if (routes && routes.length) {
      return createRoutes(routes, collab_permissions);
    } else return null;
  }, [routes, collab_permissions]);

  if (!appRoutes) return null;

  return (
    <>
      <Switch>
        <Route exact path="/app/generated_application">
          <ManageApplications />
        </Route>
        {appRoutes}
        <Route exact path="/app/customer_analytics_admin">
          <CustomerAnalytics />
        </Route>
        <Redirect from="/app" to={"/app/dashboard"} />
      </Switch>
    </>
  );
}
