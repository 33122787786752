import { formats } from "components/Common/Tables/Common/helpers";

export const columns = [
  {
    field: "to_date",
    format: formats.date,
  },

  {
    field: "license_type",
  },
  {
    field: "license_number",
  },
  {
    title: "Issuing Jurisdiction",
    field: "issuing_jurisdiction",
    format: "startCase",
  },
];

export const nonRequired = ["attachment_group_id"];

export const tableQuestions =
  "Enter all current motor vehicle operator licenses (automobiles, motorcycles, airplanes, boats, recreational vehicles, etc.) issued to you in any jurisdiction.";

export const defaultInitialValues = {
  is_primary_drivers_license: null,
  from_date: null,
  to_date: null,
  license_number: "",
  license_type: "",
  issuing_jurisdiction: "",
  attachment_group_id: null,
};
