import React from "react";

export const columns = [
  { field: "vehicle_type" },
  { title: "Relative", field: "relative_pd_person.full_name" },
  { title: "Ownership", field: "leased_or_owned" },
  { title: "Year", field: "vehicle_year" },
  { title: "Model", field: "vehicle_model" },
  { field: "cost", format: "currency" },
  { field: "current_value", format: "currency" },
];

export const tableQuestions = (
  <>
    Provide information for all vehicles owned or leased by you, your spouse, or
    your dependent children.
  </>
);

export const nonRequired = ["relative_pd_person_id", "lease_details"];

export const endpoint = "vehicles_vehicle";

export const defaultInitialValues = {
  relative_pd_person_id: null,
  location_country: "",
  vehicle_type: "",
  vehicle_model: "",
  vehicle_year: null,
  leased_or_owned: "",
  acquisition_date: null,
  lease_details: "",
  cost: null,
  current_value: null,
};

export const modalTitle = "Vehicle Details";

export const dataHookProps = {
  defaultInitialValues,
  nonRequired,
  endpoint,
};
