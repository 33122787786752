import { useMutation, useQueryClient } from "react-query";
import { createModel } from "helpers/apiHelpers";

const usePost = (queryKey, endpoint, { onSuccess, onError } = {}) => {
  const queryCache = useQueryClient();

  function handleAdd(payload) {
    return createModel(endpoint, payload).then(({ data }) => {
      return data;
    });
  }

  const updateCache = (newValue) => {
    const previousValue = queryCache.getQueryData(queryKey)
      ? queryCache.getQueryData(queryKey)
      : [];
    queryCache.setQueryData(queryKey, [...previousValue, newValue]);
  };

  return useMutation(handleAdd, {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
      updateCache(data);
    },
    onError: (error) => {
      onError && onError(error);
    },
  });
};

export default usePost;
