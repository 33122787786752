import { Grid } from "@material-ui/core";
import { SectionLabel } from "components/Common/LayoutComponents";
import { NumberFormatTypes } from "components/Common/Inputs/NumberField";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikGoogleLookup from "components/Formik/fields/FormikGoogleLookup";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import { FormikAddressFields } from "components/Formik/formGroups/FormikAddressFields";
import YesNo from "components/Formik/formGroups/YesNo";
import FormSectionWrapper, {
  FormContextProvider,
} from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { address } from "helpers/initialValues";
import useForm from "hooks/useForm.js";
import React from "react";

const NevadaDisclosureQuestions = ({ name }) => {
  const formData = useForm({
    defaultInitialValues: {
      nevada_q23_enterprise_address: address,
      nevada: {
        q22_boolean: null,
        q22_borrower_name: "",
        q22_loan_amount: null,
        q22_loan_date: null,
        q22_finance_use_details: "",
        q23_boolean: null,
        q23_entity_name: "",
        q23_from_date: null,
        q23_to_date: null,
        q23_ownership_percentage: null,
        q23_enterprise_type: "",
      },
    },
    noValidate: true,
    endpoint: "nevada_medical_recreational_marijuana_pdSupplement",
  });
  return (
    <FormSectionWrapper title={name}>
      <FormContextProvider formData={formData}>
        <EnhancedFormikPage>
          <Grid container spacing={2}>
            <YesNo
              label="Have you or your spouse ever made any loan which was used to finance a Marijuana related operation, license, permit or certification, in any jurisdiction?"
              xs={12}
              name="nevada.q22_boolean"
            />

            <SectionLabel label="If yes, complete the following:" />

            <FormikTextField
              label="Name of Borrower "
              name="nevada.q22_borrower_name"
              xs={4}
            />

            <FormikNumberField
              label="Original Amount Of Loan "
              name="nevada.q22_loan_amount"
              xs={4}
            />

            <FormikDatePicker
              label="Date of Loan"
              name="nevada.q22_loan_date"
              xs={4}
            />

            <FormikTextField
              label="Type of Marijuana related enterprise the funds were use to finance"
              name="nevada.q22_finance_use_details"
              xs={12}
            />

            <YesNo
              label="Have you or your spouse ever held an interest in any landlord entity owning real estate which is or has been used for a Marijuana related operation, in any jurisdiction?"
              xs={12}
              name="nevada.q23_boolean"
            />

            <SectionLabel label="If yes, complete the following:" />

            <FormikTextField
              label="Name of Entity"
              name="nevada.q23_entity_name"
              xs={4}
            />

            <FormikDatePicker
              label="From Date"
              name="nevada.q23_from_date"
              xs={4}
            />

            <FormikDatePicker
              label="To Date"
              name="nevada.q23_to_date"
              xs={4}
            />

            <FormikNumberField
              label="Percentage of Ownership"
              name="nevada.q23_ownership_percentage"
              xs={4}
              type={NumberFormatTypes.percent}
            />

            <FormikGoogleLookup
              fields={{
                address: "nevada_q23_enterprise_address",
              }}
            />

            <FormikAddressFields addressPrefix="nevada_q23_enterprise_address." />

            <FormikTextField
              label="Type of Marijuana Related Enterprise"
              name="nevada.q23_enterprise_type"
              xs={4}
            />
          </Grid>
        </EnhancedFormikPage>
      </FormContextProvider>
    </FormSectionWrapper>
  );
};

export default NevadaDisclosureQuestions;
