import { Box, Grid, Paper } from "@material-ui/core";
import SaveButton from "components/Common/CustomButtons/SaveButton";
import FormWrapper from "components/Forms/FormWrapper";
import React from "react";
import * as Yup from "yup";
import BusinessInformationForm from "./BusinessInformationForm";

const validationSchema = Yup.object().shape({
  business_name: Yup.string().required("Required"),
  business_relationship_type: Yup.string().nullable().required("Required"),
});

export const ApplicantInformation = ({
  initialValues,
  fetchFn,
  handleSubmit,
}) => (
  <Paper variant="outlined">
    <Box p={3}>
      <FormWrapper
        initialValues={initialValues}
        fetchFn={fetchFn}
        handleSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <Grid container spacing={2}>
          <Grid item>
            <BusinessInformationForm />
          </Grid>

          <Grid item xs={12}>
            <Box width={1}>
              <SaveButton
                type="submit"
                size="md"
                variant="contained"
                color="primary"
              >
                Save
              </SaveButton>
            </Box>
          </Grid>
        </Grid>
      </FormWrapper>
    </Box>
  </Paper>
);
