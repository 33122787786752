import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";

const CustomerLink = (tableData) => {
  return (
    <div>
      <Link
        component={RouterLink}
        to={{
          pathname: "customer_analytics_admin",
          search: "?id=" + tableData.id,
        }}
      >
        {tableData.customer.name}
      </Link>{" "}
    </div>
  );
};

export const columns = [
  {
    title: "Customer",
    field: "customer",
    render: CustomerLink,
  },
  { title: "Last Activity", field: "last_activity", format: "date" },
  { title: "New Key Accounts", field: "new_key_account" },
  { title: "New Occupational Disclosures", field: "new_occupational" },
  { title: "New Applications", field: "new_applications" },
  { title: "Key Accounts", field: "active_key_account" },
  { title: "Occupational Disclosures", field: "active_occupational" },
  {
    title: "Business Disclosure",
    field: "business_disclosure_count",
  },
];

export const defaultInitialValues = {
  customer: null,
  updated_at: null,
  last_activity: null,
  new_key_account: null,
  new_applications: null,
  business_accounts: null,
  active_key_account: null,
  business_disclosure: null,
};
