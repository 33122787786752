import { Grid } from "@material-ui/core";
import BusinessOwnerList from "components/Common/BusinessOwnerInlineList/BusinessOwnerList";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikNumberField from "components/Formik/fields/FormikNumberField.jsx";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { fieldTypes } from "components/Formik/formGroups/CrudSelect/CrudSelect";
import PDBusinessSelect from "components/Formik/formGroups/CrudSelect/selects/PDBusinessSelect.jsx";
import FormikCheckBoxGroup from "components/Formik/formGroups/FormikCheckBoxGroup";
import FromToCurrent from "components/Formik/formGroups/FromToCurrent.jsx";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";

const additionalFieldsSchema = [
  {
    type: fieldTypes.subHeader,
    children: "Extra Details",
    gutterTop: true,
  },
  {
    name: "business_status",
    type: fieldTypes.text,
    label: "Current Business Status",
    xs: 6,
  },
  {
    name: "business_description",
    type: fieldTypes.text,
    label: "Business Description",
    xs: 12,
  },
  {
    name: "gaming_activity_description",
    type: fieldTypes.text,
    label: "Gaming Activity Description",
    helperText: "How gaming was part of the operations (If Applicable)",
    xs: 12,
    multiline: true,
    rows: 2,
  },
];

const Form = () => {
  const { businessInterestRoleCheckboxOptions } = useMenuOptions();

  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Business" />

      <PDBusinessSelect
        modifySchema={(schema) => [...schema, ...additionalFieldsSchema]}
      />

      <SectionSubHeader label="Involvement Dates" divider />
      <FromToCurrent />

      <SectionSubHeader label="Involvement Details" divider />
      <FormikCheckBoxGroup
        menuOptions={businessInterestRoleCheckboxOptions}
        name="role_checkbox"
        label="Indicate how you are related to the business"
        row
        xs={12}
      />
      <FormikNumberField
        label="Ownership %"
        name="ownership_percent"
        xs={4}
        type="percent"
      />
      <FormikNumberField label="Debt Owed to You" name="debt_owed" xs={4} />
      <FormikNumberField
        label="# of shares owned"
        name="number_shares_owned"
        xs={4}
        type="none"
      />

      <SectionSubHeader label="Cessation Details" divider />
      <FormikTextField
        label="Termination of Involvement (If Applicable)"
        name="termination_of_involvement"
        helperText="The reasons you terminated your association with the Business Entity."
        xs={8}
      />
      <SectionSubHeader label="List Other Owners" divider />
      <BusinessOwnerList />
    </Grid>
  );
};

const BusinessInterests = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "business_interests_businessInterest",
    nonRequired: ["number_shares_owned", "termination_of_involvement"],
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default BusinessInterests;
