export const defaultInitialValues = {
  michigan: {
    has_made_political_contribution: null,
    political_contribution_contributor: "",
    political_contribution_candidate_name: "",
    political_contribution_position_held: "",
    political_contribution_amount: "",
    political_contribution_method_of_payment: "",
    political_contribution_intermediary: "",
    political_contribution_date: null,
  }
}