export const columns = [
  {
    title: "Business Name",
    field: "business_name",
  },
  {
    title: "Relative Name",
    field: "person_name",
  },

  {
    title: "Acquisition Date",
    field: "acquisition_date",
    format: "date",
  },
  {
    title: "Acquisition Cost",
    field: "acquisition_cost",
    format: "currency",
  },
  {
    title: "Ownership",
    field: "ownership_percent",
    format: "percent",
  },
  {
    title: "Current Market Value",
    field: "market_value",
    format: "currency",
  },
];
