export const columns = [
  {
    title: "From",
    field: "from_date",
    format: "date",
  },
  {
    title: "To",
    field: "to_date",
    format: "date",
  },
  {
    title: "Business Name, Address, Phone",
    field: "business_name_address_phone",
    style: {
      minWidth: "300px",
    },
  },
  { title: "Family Member", field: "family_member_info" },
  { title: "Business Description", field: "business_description" },
  { title: "% Ownership", field: "ownership_percent" },
  { title: "Debt Owed", field: "debt_owed" },
  { title: "Role/Position", field: "positions" },
];
