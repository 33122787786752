import { Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import Tooltip from "components/Common/Tooltip";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { fieldTypes } from "components/Formik/formGroups/CrudSelect/CrudSelect";
import PDBusinessSelect from "components/Formik/formGroups/CrudSelect/selects/PDBusinessSelect.jsx";
import PDRelativeSelect from "components/Formik/formGroups/CrudSelect/selects/PDRelativeSelect.jsx";
import FromToCurrent from "components/Formik/formGroups/FromToCurrent";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { jurisdictionDropdownSchema } from "componentGenerators/formGenerator/formSchemas";
import {
  columns,
  defaultInitialValues,
  endpoint,
  familyMemberHelperText,
  nonRequired,
  tableQuestions,
} from "./config.js";

const additionalFieldsSchema = [
  {
    type: fieldTypes.subHeader,
    children: "Jurisdiction",
    gutterTop: true,
  },
  jurisdictionDropdownSchema,
];

const GovernmentPositionsForm = () => {
  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Government Entity" />

      <PDBusinessSelect
        modifySchema={(schema) => [...schema, ...additionalFieldsSchema]}
        overrideDefaultValues={{
          business_type_checkbox: { is_government: true },
        }}
        endpoint="government_business_entities"
        label="Government Entity"
      />

      <SectionSubHeader label="Family Member (if applicable)" />
      <PDRelativeSelect
        label="Family Member (if applicable)"
        tooltip={<Tooltip title={familyMemberHelperText} />}
      />

      <SectionSubHeader label="Position Details" divider />
      <FormikTextField
        label="Title of Office or Position"
        name="position"
        xs={6}
      />

      <FormikTextField
        multiline
        rows={3}
        label="Duties & Responsibilities"
        name="duties"
        xs={8}
      />
      <SectionSubHeader label="Dates" divider />
      <FromToCurrent />
    </Grid>
  );
};

const GovernmentPositions = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint,
    nonRequired,
  });

  return (
    <FormSectionProvider formData={formData} title={name}>
      <EnhancedFormikDialog title={name}>
        <GovernmentPositionsForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        subheader={<InfoAlert>{tableQuestions}</InfoAlert>}
        columns={columns}
        addButtonText="Add Record"
      />
    </FormSectionProvider>
  );
};

export default GovernmentPositions;
