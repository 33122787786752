import React from "react";
import { commonStrings } from "../../uiStrings";

export const columns = [
  {
    field: "original_loan_date",
  },
  { title: "Borrower", field: "debtor_pd_party_name" },
  {
    field: "original_loan_amount",
    format: "currency",
  },
  { field: "current_balance", format: "currency" },
  {
    field: "termination_date",
  },
  {
    title: "Active",
    field: "is_current",
    format: "boolean",
    sortType: "boolean",
  },
];

export const tableQuestions = (
  <>
    During the last ten year period, have {commonStrings.youSpouseChildren} made
    any loan in excess of $10,000USD? {commonStrings.usdCurrencyReporting}
  </>
);

export const defaultInitialValues = {
  relative_pd_person_id: null,
  debtor_pd_party_id: null,
  interest_rate: null,
  original_loan_amount: null,
  current_balance: null,
  loan_co_parties: "",
  security_of_loan: "",
  original_loan_date: null,
  termination_date: null,
  is_current: false,
};
