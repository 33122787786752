import React from "react";
import { formats } from "components/Common/Tables/Common/helpers";

export const columns = [
  { title: "Creditor", field: "creditor_pd_party_name" },
  { title: "Relative Name", field: "relative_pd_person.full_name" },
  { field: "nature_of_payable" },
  { field: "incurred_date" },
  { field: "outstanding_amount", format: formats.currency },
  {
    title: "Current",
    field: "is_current",
    format: "boolean",
    sortType: "boolean",
  },
];

export const tableQuestions = (
  <>
    List below the information requested with regard to all accounts payable
    (include lines of credit, installment loans, revolving charge accounts and
    any other accounts) for which you, your spouse or your dependent children
    are obligated.
  </>
);

export const nonRequired = [
  "relative_pd_person_id",
  "nature_of_security",
  "loans_and_other_payables_attachment_group_id",
];

export const endpoint = "loans_and_other_payables_loanPayable";

export const defaultInitialValues = {
  type: "loan",
  relative_pd_person_id: null,
  creditor_pd_party_id: null,
  account_number: "",
  incurred_date: null,
  due_date: null,
  nature_of_payable: "",
  nature_of_security: "",
  interest_rate: null,
  original_amount: null,
  authorized_amount: null,
  total_payments: "",
  outstanding_amount: null,
  loans_and_other_payables_attachment_group_id: null,
  is_current: true,
};

export const modalTitle = "Note Loans & Other Payables";

export const dataHookProps = {
  defaultInitialValues,
  nonRequired,
  endpoint,
};
