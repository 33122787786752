export const columns = [
  { title: "Person Name", field: "party_name" },
  { title: "Amount of Shares Held", field: "shares_held_count" },
  { title: "Ownership Percentage", field: "ownership_percent", format: "percent" },
];

export const defaultInitialValues = {
  question_6_d_reason: "",
};

export const question =
  "Are you able to provide a complete list of all stockholders/partners showing the number of shares/interest held of record by each is filed herewith?";

export const endpoint =
  "views/bd_positions_a_partners_shareholders_owners_grouped";
