import { useAuth } from "context/auth";
import { createGeneratedPdf } from "helpers/apiHelpers";
import useToggle from "hooks/useToggle";
import React from "react";
import { useMutation, useQueryClient } from "react-query";

const useGenerateApplicationDialog = (setSelectedApplication = () => null) => {
  const { on, setOn, setOff } = useToggle();
  const [jurisdiction, setJurisdiction] = React.useState("");
  const [applicationType, setApplicationType] = React.useState("");

  const { employee_id, bd_organization_id, bd_business_entity_id } = useAuth();
  const modelName = bd_organization_id ? "bdOrganization" : "employee";

  const modelId = bd_organization_id || employee_id;
  const queryCache = useQueryClient();

  const { mutate, isLoading, reset } = useMutation(
    ({ pdfForm_id }) =>
      createGeneratedPdf(modelName, modelId, pdfForm_id, bd_business_entity_id),
    {
      onSuccess: ({ id }) => {
        setOff();
        reset();
        setSelectedApplication(id);
        queryCache.invalidateQueries("applications");
      },
    }
  );

  const onJurisdictionChange = (value) => {
    setJurisdiction(value.id);
  };

  const onApplicationChange = (e) => setApplicationType(e.target.value);

  const onGenerate = (pdfForm_id) => {
    mutate({ pdfForm_id });
  };

  const onSubmit = () => {
    if (applicationType) {
      onGenerate(applicationType);
    }
  };

  React.useEffect(() => {
    if (!on) {
      setJurisdiction("");
      setApplicationType("");
    }
  }, [on]);

  const applicationEndpoint = `jurisdiction/${jurisdiction}/application${
    bd_organization_id ? "?is_business=true" : ""
  }`;

  return {
    on,
    jurisdiction,
    isLoading,
    applicationEndpoint,
    setOn,
    setOff,
    onApplicationChange,
    onJurisdictionChange,
    onSubmit,
  };
};

export default useGenerateApplicationDialog;
