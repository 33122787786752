export const columns = [
  {
    title: "Business Name",
    field: "business_name",
  },
  { title: "Compliance Officer", field: "compliance_officer_bd_person.full_name" },
  {
    title: "Formation",
    field: "incorporation_date",
    format: "date",
  },
  {
    title: "Relationship",
    format: "startCase",
    field: "business_relationship_type",
  },
  {
    title: "Form of Business",
    format: "startCase",
    field: "form_of_business",
  },
  {
    title: "Employer ID Number",
    field: "employer_id_number",
  },
]

export const defaultInitialValues = {
  compliance_officer_bd_person_id: null
};

export const endpoint = "bdBusinessEntity?business_relationship_type!=null";
export const tabularDataParams = {
  defaultInitialValues,
  endpoint,
};
