import PropTypes from "prop-types";
import { formSchemas } from "components/Formik/formGroups/CrudSelect/CrudSelect";
import React from "react";
import BusinessIcon from "@material-ui/icons/Business";
import PermissionedCrudSelect from "./PermissionedCrudSelect";

const PDBusinessSelect = ({
  name = "pd_business_entity_id",
  label = "Business Entity",
  endpoint = "business_entities",
  formTitle = "Add / Edit Business",
  formSchema = formSchemas.pdBusinessSchema,
  usesPermission = true,
  ...rest
}) => {
  return (
    <PermissionedCrudSelect
      name={name}
      label={label}
      endpoint={endpoint}
      usesPermission={usesPermission}
      saveEndpoint="pdBusinessEntity"
      formSchema={formSchema}
      formTitle={formTitle}
      optionIcon={<BusinessIcon color="primary" fontSize="small" />}
      {...rest}
    />
  );
};

PDBusinessSelect.propTypes = {
  endpoint: PropTypes.string,
  formSchema: PropTypes.array,
  formTitle: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  usesPermission: PropTypes.bool,
};

export default PDBusinessSelect;
