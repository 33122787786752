import { Box, Grid, Typography } from "@material-ui/core";
import { lighten, useTheme } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import Center from "components/flex/Center";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikTextField from "components/Formik/fields/FormikTextField";
import FormikToggleButtonGroup from "components/Formik/fields/FormikToggleButtonGroup";
import { useField } from "formik";
import React from "react";
import { LicenseAgencyAndType } from "components/Pages/LicensingControl/components/LicenseAgencyAndType";
import PropTypes from "prop-types";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";

const ToggleButtonText = ({ children }) => (
  <Box pt={1} pb={1} pr={5} pl={5}>
    <Typography>{children}</Typography>
  </Box>
);

export const StatusButtonGroup = () => {
  const theme = useTheme();

  const [{ value }] = useField("current_status");

  const getToggleStyle = (name, color) => {
    const themeColor = theme.palette[color].main;
    if (value === name) {
      return {
        backgroundColor: lighten(themeColor, 0.7),
        border: `solid 2px ${lighten(themeColor, 0.3)}`,
      };
    }
    return {};
  };

  return (
    <Grid item xs={12}>
      <Center>
        <FormikToggleButtonGroup exclusive name="current_status">
          <ToggleButton
            value="pending"
            style={getToggleStyle("pending", "warning")}
          >
            <ToggleButtonText>Pending</ToggleButtonText>
          </ToggleButton>
          <ToggleButton
            value="approved"
            style={getToggleStyle("approved", "success")}
          >
            <ToggleButtonText>Approved</ToggleButtonText>
          </ToggleButton>
        </FormikToggleButtonGroup>
      </Center>
    </Grid>
  );
};

const LicenseForm = ({ category, description }) => {
  return (
    <>
      <SectionSubHeader>License / Certification Details</SectionSubHeader>
      <LicenseAgencyAndType category={category} />

      <FormikTextField
        label="License/Certification Number"
        name="license_number"
        xs={6}
      />
      <SectionSubHeader divider>Dates</SectionSubHeader>
      <FormikDatePicker
        label="Held From"
        name="start_date"
        xs={6}
        helperText="Date the application is valid from"
      />
      <FormikDatePicker label="Expiry Date" name="expiration_date" xs={6} />

      <SectionSubHeader divider>Status</SectionSubHeader>

      <StatusButtonGroup />

      <SectionSubHeader divider>Attachment(s)</SectionSubHeader>

      <FormikFileDropzone
        description={description}
        name="lc_attachment_group_id"
        xs={12}
      />
    </>
  );
};

LicenseForm.propTypes = {
  category: PropTypes.string.isRequired,
};

export default LicenseForm;
