import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import React from "react";

const useStyles = makeStyles(() => ({
  addNewButton: {
    color: "#005687",
    borderColor: "#005687",
    marginTop: "10px",
    marginBottom: "10px",
  },
}));

export const AddOutlinedButton = (props) => {
  const classes = useStyles();
  return (
    <Button
      size="small"
      disableElevation
      variant="outlined"
      startIcon={<AddCircleOutlineIcon />}
      className={classes.addNewButton}
      {...props}
    >
      {props.children}
    </Button>
  );
};
