import PropTypes from "prop-types";
import { FormikDialog } from "components/Formik/formContainers/FormikDialog";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { useSnackbar } from "notistack";
import React from "react";
import { useQueryClient } from "react-query";
import usePatch from "../../../../queryHooks/usePatch";
import { employmentsQueryKey, licensesQueryKey } from "./config";

export const ArchiveEmployee = ({
  employeeToBeArchived,
  setEmployeeToBeArchived,
}) => {
  const queryClient = useQueryClient();

  const { mutate: archivePatchMutation } = usePatch(
    employmentsQueryKey,
    "licensing/lcEmployment",
    { shouldUpdateCache: false }
  );

  const handleClearArchivedEmployee = () => setEmployeeToBeArchived(null);

  const { enqueueSnackbar } = useSnackbar();

  const submitArchivedRecord = (values, { resetForm, setSubmitting }) => {
    archivePatchMutation(
      { id: employeeToBeArchived, ...values },
      {
        onSuccess: () => {
          resetForm();
          handleClearArchivedEmployee();
          enqueueSnackbar("Employee Archived", {
            autoHideDuration: 2000,
            variant: "success",
          });

          queryClient.invalidateQueries(employmentsQueryKey);
          queryClient.invalidateQueries(licensesQueryKey);
        },
        onError: () => {
          setSubmitting(false);
          handleClearArchivedEmployee();
          enqueueSnackbar("Error", {
            variant: "error",
          });
        },
      }
    );
  };

  return (
    <FormikDialog
      title="Archive Employee"
      initialValues={{ is_archived: true, archived_reason: "" }}
      onSubmit={submitArchivedRecord}
      onCancel={handleClearArchivedEmployee}
      open={!!employeeToBeArchived}
      maxWidth="sm"
    >
      <FormikTextField
        label="Reason for archiving"
        name="archived_reason"
        xs={12}
      />
    </FormikDialog>
  );
};

ArchiveEmployee.propTypes = {
  employeeToBeArchived: PropTypes.any,
  setEmployeeToBeArchived: PropTypes.func,
};
