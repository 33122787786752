export const defaultInitialValues = {
  pa_certification: {
    certification_type_checkbox: null,
    pa_cert_contact_bd_person_id: null,
    is_minority_women_business: null,
    certified_applicant_minority_women_business: "",
    minority_women_business_certification_number: "",
    pa_applications_bd_person_id: null,
    pa_billing_contact_bd_party_id: null,
    pa_form_of_business: null,
    is_transportation_company_arrangement: null,
    transportation_company_names: "",
    goods_services_gaming_service_provider: "",
    gaming_service_provider_name: "",
    is_associated_gaming_service_provider: null,
    associated_gaming_service_providers_names: "",
  },
  pa_minority_women_business_cert_attachment_group_id: null,
}

export const endpoint = "bdSupplement";
