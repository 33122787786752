import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "General Information",
  },
  {
    type: fieldTypes.label,
    label:
      "Please provide below the number of individuals currently employed by the applicant:",
  },
  {
    type: fieldTypes.number,
    label: "Number of Individuals",
    name: "bd_louisiana_level_two.employee_amount",
    format: "none",
  },
  {
    type: fieldTypes.label,
    label:
      "Please provide below the applicant’s gross revenue for the past twelve (12) months:",
  },
  {
    type: fieldTypes.number,
    label: "Gross Revenue",
    name: "bd_louisiana_level_two.gross_revenue",
  },
  {
    type: fieldTypes.label,
    label:
      "Please provide below the applicant’s gross revenue from sales to the gaming industry for the past twelve (12) months:",
  },
  {
    type: fieldTypes.number,
    label: "Gaming Gross Revenue",
    name: "bd_louisiana_level_two.gross_revenue_gaming",
  },
  {
    type: fieldTypes.label,
    label:
      "Please provide below the applicant’s gross revenue from sales to the gaming industry in Louisiana for the past twelve (12) months:",
  },
  {
    type: fieldTypes.number,
    label: "Gaming Lousiana Gross Revenue ",
    name: "bd_louisiana_level_two.gross_revenue_gaming_louisiana",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "Does the applicant lease any equipment utilized for conducting business?",
    name: "bd_louisiana_level_two.has_leased_equipment",
  },
  {
    type: fieldTypes.label,
    label: "If yes please attach copy of lease(s) below:",
  },
  {
    type: fieldTypes.dropZone,
    name: "bd_louisiana_level_two.lease_equipment_attachment_group",
    description: "Lease Equipment Leases",
    label: "Upload Attachment",
  },
  {
    type: fieldTypes.label,
    label:
      "Please provide below the total number of commercial delivery vehicles:",
  },
  {
    type: fieldTypes.number,
    label: "Number of Commercial Delivery Vehicles",
    name: "bd_louisiana_level_two.total_number_vehicles",
    format: "none",
  },
  {
    type: fieldTypes.label,
    label:
      "Please provide below the total number of commercial delivery vehicles owned:",
  },
  {
    type: fieldTypes.number,
    label: "Number of Commercial Delivery Vehicles Owned",
    name: "bd_louisiana_level_two.total_number_vehicles_owned",
    format: "none",
  },
  {
    type: fieldTypes.label,
    label:
      "Please provide below the total number of commercial delivery vehicles leased:",
  },
  {
    type: fieldTypes.number,
    label: "Number of Commercial Delivery Vehicles Leased",
    name: "bd_louisiana_level_two.total_number_vehicles_leased",
    format: "none",
  },
  {
    type: fieldTypes.yesNo,
    label: "Does the applicant serve as a broker or intermediary?",
    name: "bd_louisiana_level_two.has_served_as_broker",
  },
  {
    type: fieldTypes.yesNo,
    label:
      "If you have answered YES to the above question, does the actual supplier distribute through brokers or intermediaries as a common practice?",
    name: "bd_louisiana_level_two.has_supplier_distibution",
  },
];

export default [
  {
    type: "form",
    formSchema,
    endpoint: "bdSupplement",
  },
];
