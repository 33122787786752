import React from "react";
import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.label,
    label: (
      <>
        <b>
          Describe the business presently conducted and the business intended to
          be conducted by the Applicant or Holding Company and its parent
          companies, intermediaries, subsidiaries, affiliates, or any other
          business entities conducting business and the general development of
          such business during the past five (5) years. The description shall
          include information on matters such as the following:
        </b>
      </>
    ),
  },
  {
    type: fieldTypes.label,
    label: `A. Competitive conditions in the industry or industries involved and the competitive position of the Applicant 
      or Holding Company, if known.`,
  },
  {
    type: fieldTypes.text,
    label: "Description",
    name: "ohio_rvla.exhibit_2_A",
    xs: 12,
    rows: 3,
  },
  {
    type: fieldTypes.label,
    label: `B.	The principal products produced and services rendered by the Applicant or Holding Company and its parent 
      companies, intermediaries, subsidiaries, affiliates or any other business entities conducting business, the 
      principal markets for said products or services and the methods of distribution.`,
  },
  {
    type: fieldTypes.text,
    label: "Description",
    name: "ohio_rvla.exhibit_2_B",
    xs: 12,
    rows: 3,
  },
  {
    type: fieldTypes.label,
    label: `C.	If a significant part, defined as ten (10) percent or more, of the Applicant’s or Holding Company’s business is dependent upon a single customer, for each such customer state;
      (1)	Its name, business address, and telephone number; and
      (2)	Its relationship with and its importance to the Applicant’s business.`,
  },
  {
    type: fieldTypes.text,
    label: "Description",
    name: "ohio_rvla.exhibit_2_C",
    xs: 12,
    rows: 3,
  },
  {
    type: fieldTypes.label,
    label: `D.	The sources and availability of raw materials essential to the business of the Applicant or Holding Company.`,
  },
  {
    type: fieldTypes.text,
    label: "Description",
    name: "ohio_rvla.exhibit_2_D",
    xs: 12,
    rows: 3,
  },
  {
    type: fieldTypes.label,
    label: `E.	The duration importance, effect and holders of all patents, trademarks, copyrights, licenses, franchises and 
      concessions that are material to the Applicant’s or Holding Company’s business`,
  },
  {
    type: fieldTypes.text,
    label: "Description",
    name: "ohio_rvla.exhibit_2_E",
    xs: 12,
    rows: 3,
  },
  {
    type: fieldTypes.label,
    label: `F.	A summary of warranties in effect on the Applicant’s or Holding Company’s products, 
      including currently pending claims relating to such warranties`,
  },
  {
    type: fieldTypes.text,
    label: "Description",
    name: "ohio_rvla.exhibit_2_F",
    xs: 12,
    rows: 3,
  },
  {
    type: fieldTypes.label,
    label: `G.	A summary of all currently pending litigation relating to the Applicant’s or Holding Company’s products`,
  },
  {
    type: fieldTypes.text,
    label: "Description",
    name: "ohio_rvla.exhibit_2_G",
    xs: 12,
    rows: 3,
  },
  {
    type: fieldTypes.label,
    label: `H.	A list identifying by name, address, and telephone number of all distributors, sales representatives, or 
      other individuals or business entities, doing business in Ohio and that formally or informally distribute, 
      market, or represent any good produced or service rendered by the Applicant or Holding Company`,
  },
  {
    type: fieldTypes.text,
    label: "Description",
    name: "ohio_rvla.exhibit_2_H",
    xs: 12,
    rows: 3,
  },
  {
    type: fieldTypes.label,
    label: `I.	A list identifying by name, address, and telephone number of all suppliers and/or vendors that supply to 
      the Applicant or Holding Company any parts, components, hardware, or software integral to the gaming-related 
      purpose of the good produced or service rendered by the Applicant or Holding Company`,
  },
  {
    type: fieldTypes.text,
    label: "Description",
    name: "ohio_rvla.exhibit_2_I",
    xs: 12,
    rows: 3,
  },
  {
    type: fieldTypes.label,
    label: `J. A detailed account and list of the gaming-related equipment, devices, goods, or services being provided or 
      to be provided under the license for which the Gaming-Related Vendor License Application is being made.`,
  },
  {
    type: fieldTypes.text,
    label: "Description",
    name: "ohio_rvla.exhibit_2_J",
    xs: 12,
    rows: 3,
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "bdSupplement",
};
