import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <FormikTextField label="Nature of Lien/Debt" name="nature" xs={6} />
      <FormikTextField
        label="Location Filed"
        name="filing_location"
        helperText="City, State, Country"
        xs={6}
      />
      <FormikDatePicker name="filing_date" xs={4} />
      <FormikDatePicker name="occurrence_date" xs={4} />
      <FormikTextField label="Current Status" name="status" xs={4} />
    </Grid>
  );
};

const LiensAndDebts = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "liens_lien",
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
    </FormSectionProvider>
  );
};

export default LiensAndDebts;
