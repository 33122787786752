import { Divider, Grid } from "@material-ui/core";
import { MultiSelect } from "components/Pages/LicensingControl/components/FilterTable/MultiSelect.jsx";
import React from "react";

const categoryOptions = [
  {
    license_type_category: "personal",
    name: "Personal",
  },
  {
    name: "Business",
    license_type_category: "business",
  },
];

const expiringOptions = [
  {
    expiryUrgency: "expired",
    name: "Expired",
  },
  {
    expiryUrgency: 7,
    name: "Within 7 Days",
  },
  {
    expiryUrgency: 30,
    name: "Within 30 days",
  },
  {
    expiryUrgency: 183,
    name: "Within 6 months",
  },
];

export const LicenseFilterForm = ({
  filterState,
  filterDispatch,
  licenseTypeFilterOptions,
  agencyNameFilterOptions,
}) => {
  const CustomMultiSelect = ({ attributeName, ...props }) => (
    <>
      <MultiSelect
        value={filterState[attributeName]}
        onChange={(e, payload) => {
          filterDispatch({
            type: "UPDATE",
            name: attributeName,
            payload,
          });
        }}
        {...props}
      />
      <Divider style={{ margin: "20px 0px" }} />
    </>
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <CustomMultiSelect
          options={licenseTypeFilterOptions}
          label={"License Type"}
          attributeName="license_type_name"
        />
        <CustomMultiSelect
          options={categoryOptions}
          label={"Category"}
          attributeName="license_type_category"
        />
      </Grid>
      <Grid item xs={6}>
        <CustomMultiSelect
          options={agencyNameFilterOptions}
          label={"Agency Name"}
          attributeName="agency_name"
        />
        <CustomMultiSelect
          options={expiringOptions}
          label={"Expiry Date"}
          attributeName="expiryUrgency"
        />
      </Grid>
    </Grid>
  );
};
