export const defaultInitialValues = {
  related_bd_party_id: null,
  related_bd_party_role: "manager",
};

export const columns = [
  {
    title: "Office Holder",
    field: "related_party_name",
  },
  {
    title: "Role",
    field: "related_bd_party_role",
    format: "startCase",
  },
];

export const endpoint = "bdPosition?related_bd_party_role=manager";

export const tabularDataParams = {
  defaultInitialValues,
  endpoint,
};
