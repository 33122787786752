import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import FormikSelect from "components/Formik/fields/FormikSelect";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import { useMenuOptions } from "hooks/useMenuOptions";
import useForm from "hooks/useForm.js";
import React from "react";

const AppearanceAndCharacteristicsForm = ({ innerRef }) => {
  const formData = useForm({
    defaultInitialValues: {
      gender: null,
      eye_color: null,
      hair_color: null,
      race: null,
      physical_build: "",
      complexion: "",
      height_ft: "",
      height_inches: "",
      height_cms: "",
      weight_lbs: "",
      weight_kgs: "",
      scars_marks: "",
    },
    noValidate: true,
    endpoint: "personal_details_personalDisclosure",
  });

  const { gender, eye, hair, race } = useMenuOptions();

  return (
    <FormContextProvider formData={formData}>
      <EnhancedFormikPage
        title={"Appearance & Characteristics"}
        innerRef={innerRef}
        showSaveButton={false}
      >
        <Grid container spacing={2}>
          <FormikSelect label="Gender" name="gender" options={gender} xs={4} />
          <FormikSelect
            label="Eye Color"
            name="eye_color"
            options={eye}
            xs={4}
          />
          <FormikSelect
            label="Hair Color"
            name="hair_color"
            options={hair}
            xs={4}
          />
          <FormikSelect label="Race" name="race" options={race} xs={4} />
          <FormikTextField
            label="Physical Build"
            name="physical_build"
            xs={4}
          />
          <FormikTextField label="Complexion" name="complexion" xs={4} />
          <FormikTextField
            label="Scars, Tattoos & Distinguishing Marks"
            placeholder="Do you have any scars, tattoos or other distinguishing marks?"
            name="scars_marks"
            multiline
            rows={2}
            xs={12}
          />

          <SectionSubHeader divider>Height</SectionSubHeader>
          <FormikTextField
            label="Ft"
            name="height_ft"
            xs={4}
            helperText="Height"
          />
          <FormikTextField label="Inches" name="height_inches" xs={4} />
          <FormikTextField label="CMs" name="height_cms" xs={4} />

          <SectionSubHeader divider>Weight</SectionSubHeader>
          <FormikTextField
            label="LBs"
            name="weight_lbs"
            xs={4}
            helperText="Weight"
          />

          <FormikTextField label="KGs" name="weight_kgs" xs={4} />
        </Grid>
      </EnhancedFormikPage>
    </FormContextProvider>
  );
};

export default AppearanceAndCharacteristicsForm;
