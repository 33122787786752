import { Box, DialogContent, Divider } from "@material-ui/core";
import SimpleDialog from "components/Common/Dialogs/SimpleDialog";
import FormikDebugger from "components/Formik/other/FormikDebugger";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import withFormContext from "../../../hocs/withFormContext";
import FormikErrorScroll from "../other/FormikErrorScroll";
import CancelButton from "../../Common/CustomButtons/CancelButton";
import SaveButton from "../../Common/CustomButtons/SaveButton";
import { PromptIfDirty } from "components/Formik/other/PromptIfDirty";
import { permissionTypes } from "components/Permission/helpers";
import useGetPermission from "components/Permission/useGetPermission";
import SentryErrorBoundary from "error/SentryErrorBoundary";

const StyledDialog = styled(SimpleDialog)`
  & .MuiDialogContent-root {
    padding: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }
`;

const ActionsFooter = ({ onCancel, onSave, isSubmitting }) => (
  <Box pb={2} pt={2}>
    <CancelButton variant="text" onClick={onCancel}>
      Cancel
    </CancelButton>
    <SaveButton disabled={isSubmitting} onClick={onSave}>
      Save
    </SaveButton>
  </Box>
);

ActionsFooter.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

const confirmText = "Are you sure you want to leave? You have unsaved changes.";

export const FormikDialog = ({
  open,
  onCancel,
  initialValues,
  onSubmit,
  maxWidth = "md",
  children,
  title = "Edit",
  validationSchema,
  isView,
  ...rest
}) => {
  const permission = useGetPermission();
  const shouldView = isView || permission === permissionTypes.read;

  const confirmCancel = ({ resetForm, dirty }) => {
    if (!dirty || window.confirm(confirmText)) {
      resetForm();
      onCancel();
    }
  };

  return (
    <>
      <SentryErrorBoundary>
        {initialValues && (
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            {...rest}
          >
            {({ submitForm, isSubmitting, ...formikBag }) => (
              <>
                <PromptIfDirty />
                {!open && <FormikDebugger />}
                <FormikErrorScroll />
                <StyledDialog
                  title={title}
                  divider
                  open={open}
                  onClose={() => confirmCancel(formikBag)}
                  maxWidth={maxWidth}
                >
                  <Form>
                    <FormikDebugger />
                    <DialogContent>
                      <Box p={2}>{children}</Box>
                      <Divider />
                      {shouldView ? null : (
                        <ActionsFooter
                          isSubmitting={isSubmitting}
                          onCancel={() => confirmCancel(formikBag)}
                          onSave={submitForm}
                        />
                      )}
                    </DialogContent>
                  </Form>
                </StyledDialog>
              </>
            )}
          </Formik>
        )}
      </SentryErrorBoundary>
    </>
  );
};

export const EnhancedFormikDialog = withFormContext(FormikDialog);

FormikDialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  isView: PropTypes.bool,
  onCancel: PropTypes.func,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  maxWidth: PropTypes.oneOf(["false", "xs", "sm", "md", "lg", "xl"]),
  children: PropTypes.node,
  validationSchema: PropTypes.object,
};
