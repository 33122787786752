import { Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FormikInlineTable from "components/Common/Tables/FormikInlineTable/FormikInlineTable";
import Tooltip from "components/Common/Tooltip";
import FormikFileDropzone from "components/Formik/fields/FormikFileDropzone";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { fieldTypes } from "components/Formik/formGroups/CrudSelect/CrudSelect";
import PDBusinessSelect from "components/Formik/formGroups/CrudSelect/selects/PDBusinessSelect.jsx";
import FromToCurrent from "components/Formik/formGroups/FromToCurrent";
import YesNo from "components/Formik/formGroups/YesNo";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { SectionLabel } from "../../../../Common/LayoutComponents/index";
import FormikNumberField from "../../../../Formik/fields/FormikNumberField";
import {
  commonStrings,
  employmentDiscipline,
  employmentHistory,
} from "../../uiStrings";
import {
  columns,
  defaultInitialValues,
  disciplineColumns,
  disciplineDefaultInitialValues,
  validationSchema,
} from "./config.js";
import DisciplinaryActionsForm from "./DisciplinaryActionsForm";

const additionalFieldsSchema = [
  {
    type: fieldTypes.subHeader,
    children: "Extra Details",
    gutterTop: true,
  },
  {
    name: "business_description",
    type: fieldTypes.text,
    label: "Business Description",
    xs: 12,
    multiline: true,
    rows: 2,
  },
  {
    type: fieldTypes.label,
    label:
      "If Gaming was part of the operations of the Business Entity, describe how.",
  },
  {
    name: "gaming_activity_description",
    type: fieldTypes.text,
    label: "Gaming Activity Description",
    xs: 12,
    multiline: true,
    rows: 2,
  },
];

const EmployeeForm = () => {
  return (
    <Grid container spacing={2}>
      <SectionSubHeader label="Employer" />

      <PDBusinessSelect
        label="Employer"
        modifySchema={(schema) => [...schema, ...additionalFieldsSchema]}
      />

      <SectionSubHeader label="Employment Type" divider />
      <YesNo
        label="Were/are you a key person?"
        name="is_key_person"
        xs={12}
        tooltip={<Tooltip title={commonStrings.keyPerson} />}
      />
      <SectionSubHeader label="Employment Details" divider />
      <FormikTextField label="Supervisor" name="supervisor" xs={6} />
      <FormikTextField
        label="Country & State of Employment"
        name="employment_location"
        helperText="Applicable for Gaming Employment Only"
        xs={6}
      />
      <FormikNumberField
        label="Annual Salary"
        name="compensation_annual"
        xs={6}
      />
      <FormikNumberField
        label="Monthly Salary"
        name="compensation_monthly"
        xs={6}
      />
      <FormikTextField label="Position" name="position" xs={6} />

      <FormikTextField
        multiline
        rows={4}
        label="Duties"
        name="duties"
        xs={12}
      />
      <SectionSubHeader label="Dates" divider />
      <FromToCurrent />
      <SectionSubHeader label="Departure" divider />
      <FormikTextField label="Reason for leaving" name="reason_left" xs={6} />
      <FormikTextField
        label="Compensation at Departure"
        name="compensation_at_departure"
        helperText="Exit/Severance Package"
        xs={6}
      />
      <SectionSubHeader label="Disciplinary Actions" divider />
      <Grid item>
        <InfoAlert>{employmentDiscipline.tableQuestion}</InfoAlert>
      </Grid>
      <Grid item xs={12}>
        <FormikInlineTable
          name="employment_discipline"
          columns={disciplineColumns}
          addButtonText={"Add Disciplinary Actions"}
          defaultInitialValues={disciplineDefaultInitialValues}
          validationSchema={validationSchema}
          form={<DisciplinaryActionsForm />}
          dialogTitle="Disciplinary Actions"
        />
      </Grid>
      <SectionLabel>
        Provide a copy of your current employment contract or agreement,
        including bonus structure or stock options for your present position for
        which you are submitting this gaming application.
      </SectionLabel>
      <FormikFileDropzone
        description="Employment Agreement or Contract"
        name="attachment_group_id"
        xs={12}
      />
    </Grid>
  );
};

const EmploymentHistory = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "employment_history_employmentHistory",
    nonRequired: [
      "employment_location",
      "reason_left",
      "compensation_at_departure",
    ],
  });

  return (
    <FormSectionProvider formData={formData} title={name}>
      <EnhancedFormikDialog title="Employment Details">
        <EmployeeForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        subheader={<InfoAlert>{employmentHistory.tableQuestion}</InfoAlert>}
        columns={columns}
      />
    </FormSectionProvider>
  );
};

export default EmploymentHistory;
