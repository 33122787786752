export const convertToNumber = (str) => {
  return parseInt(str) || null;
};

export const convertToBoolean = (str) => str === "true";

export default function authReducer(state, action) {
  //TABLE IDS
  const employee_id = convertToNumber(action?.employee_id);
  const bd_organization_id = convertToNumber(action?.bd_organization_id);
  const bd_business_entity_id = convertToNumber(action?.bd_business_entity_id);
  const account_id = convertToNumber(action?.account_id);
  const account_type = action?.account_type;

  //PERMISSION
  const collab_permissions = action.collab_permissions;
  const flattened_permissions = action.flattened_permissions;
  const is_account_owner = action.is_account_owner;

  // DISPLAY
  const name = action.name;
  const email = action.email;

  //AUTHENTICATION
  const authenticated = !!action.token;
  const token = action.token;

  if (action.type === "LOGIN") {
    localStorage.setItem("accessToken", token);
    localStorage.setItem("employee_id", employee_id);
    localStorage.setItem("bd_organization_id", bd_organization_id);
    localStorage.setItem("bd_business_entity_id", bd_business_entity_id);
    localStorage.setItem("name", name);
    localStorage.setItem("account_id", account_id);
    localStorage.setItem("email", email);
    localStorage.setItem("account_type", account_type);
    localStorage.setItem("is_account_owner", is_account_owner);

    return {
      ...state,
      loading: false,
      account_id,
      employee_id,
      bd_business_entity_id,
      bd_organization_id,
      authenticated,
      token,
      collab_permissions,
      flattened_permissions,
      email,
      account_type,
      name,
      is_account_owner,
    };
  }

  if (action.type === "REFRESH") {
    return {
      ...state,
      loading: false,
      employee_id,
      bd_organization_id,
      bd_business_entity_id,
      account_id,
      authenticated,
      token,
      email,
      collab_permissions,
      flattened_permissions,
      account_type,
      name,
      is_account_owner,
    };
  }

  if (action.type === "CHANGE_ACCOUNT") {
    localStorage.setItem("account_type", account_type);
    localStorage.setItem("is_account_owner", is_account_owner);
    localStorage.setItem("bd_organization_id", bd_organization_id);
    localStorage.setItem("name", name);
    localStorage.setItem("employee_id", employee_id);
    localStorage.setItem("bd_business_entity_id", bd_business_entity_id);

    return {
      ...state,
      employee_id,
      bd_organization_id,
      bd_business_entity_id,
      collab_permissions,
      flattened_permissions,
      account_type,
      name,
      is_account_owner,
    };
  }

  if (action.type === "LOGOUT") {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("bd_business_entity_id");
    localStorage.removeItem("account_id");
    localStorage.removeItem("bd_organization_id");
    localStorage.removeItem("employee_id");
    localStorage.removeItem("email");
    localStorage.removeItem("account_type");
    localStorage.removeItem("name");
    localStorage.removeItem("is_account_owner");

    //THESE ARE NOT USED ANYMORE WE CAN REMOVE IN A BIT
    localStorage.removeItem("display_name");
    localStorage.removeItem("id");
  }
  return {
    ...state,
    loading: false,
    employee_id: null,
    bd_organization_id: null,
    bd_business_entity_id: null,
    account_id: null,
    authenticated: false,
    token: null,
    email: null,
    name: null,
    account_type: null,
    is_account_owner: null,
  };
}
