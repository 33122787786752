import React from "react";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import { dayjsUTC } from "helpers/apiHelpers.js";
import CustomLink from "components/Common/Link";

const formatDate = (date) => dayjsUTC(date, "MM/D/YYYY");

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Date of last submission",
  },
  {
    type: fieldTypes.label,
    label: (
      <>
        Please select below the license you are renewing (Please note, you will
        need to have previously entered the license you are renewing in the
        <CustomLink to="personal_licensing" /> section to see it appear in the
        drop down.):
      </>
    ),
  },
  {
    type: fieldTypes.asyncSelect,
    endpoint:
      "tennessee_phdrf_supplement_disclosure_questions_gaming_personal_license?issuing_jurisdiction=tennessee",
    label: "Application date • License Number • Business Name",
    getOptionLabel: (option) =>
      `${formatDate(option.application_date)} • ${option.license_number} • ${
        option.business_name
      } `,
    getOptionValue: (option) => option.id,
    xs: 8,
    required: true,
    name: "tennessee_phdrf.associated_license_id",
  },
  {
    type: fieldTypes.checkBoxGroup,
    label: "Please check off the purpose of this application",
    name: "tennessee_phdrf.tennessee_application_purpose_checkbox",
    dynamicMenuItems: ["tennesseeApplicationPurpose"],
  },
  {
    type: fieldTypes.checkBoxGroup,
    label:
      "Please check off your position or association with applicant, licensee, or registrant",
    name: "tennessee_phdrf.tennessee_applicant_position_checkbox",
    dynamicMenuItems: ["tennesseeApplicantPosition"],
  },
  {
    type: fieldTypes.label,
    label: "If you chose 'Other' please provide your position title",
  },
  {
    type: fieldTypes.text,
    label: "Title",
    name: "tennessee_phdrf.applicant_position_other_details",
  },
  {
    type: fieldTypes.yesNo,
    label: "Has your primary residence changed since your last application?",
    name: "tennessee_phdrf.has_primary_residence_changed",
  },
  {
    type: fieldTypes.yesNo,
    label: "Has your citizenship changed since your last application?",
    name: "tennessee_phdrf.has_citizenship_changed",
  },
  {
    type: fieldTypes.subHeader,
    label: "Spouse Information",
    divider: true,
  },
  {
    type: fieldTypes.label,
    label:
      "If your spouse has changed since your last applicantion, please provide his/her SSN number.",
  },
  {
    type: fieldTypes.text,
    label: "SSN number",
    name: "tennessee_phdrf.new_spouse_ssn",
  },
  {
    type: fieldTypes.yesNo,
    label: "Has your spouse's citizenship changed since your last application?",
    name: "tennessee_phdrf.has_spouse_citizenship_changed",
  },
  {
    type: fieldTypes.label,
    label:
      "If your spouse has changed citizenship, please enter their naturalization certificate number (if Naturalized US citizen) or their authorization/registration number (if no longer a US citizen)",
  },
  {
    type: fieldTypes.text,
    label: "Certificate Number",
    name: "tennessee_phdrf.spouse_citizenship_certificate_num",
  },
  {
    type: fieldTypes.text,
    label: "Authorization/Registration Number",
    name: "tennessee_phdrf.spouse_citizenship_registration_num",
  },
  {
    type: fieldTypes.subHeader,
    label: "Vehicle License",
    divider: true,
  },
  {
    type: fieldTypes.yesNo,
    label: `Has your driver’s license been suspended?`,
    name: "tennessee_phdrf.has_your_drivers_license_been_suspended",
  },
  {
    type: fieldTypes.text,
    label: "Reason for Suspension",
    name: "tennessee_phdrf.suspended_drivers_license_details",
  },
  {
    type: fieldTypes.subHeader,
    label: "Disclosure Questions",
    divider: true,
  },
  {
    type: fieldTypes.yesNo,
    label: `Are you an employee of the Tennessee Lottery Corporation?`,
    name: "tennessee_phdrf.is_an_employee_of_tennessee_lottery",
  },
  {
    type: fieldTypes.yesNo,
    label: `Are you a member of the Board of Directors of the Tennessee Education Lottery Corporation?`,
    name: "tennessee_phdrf.is_a_member_of_lottery_board",
  },
  {
    type: fieldTypes.yesNo,
    label: `Are you a member of the Sports Wagering Advisory Council of the Tennessee Education Lottery Corporation?`,
    name: "tennessee_phdrf.is_a_member_of_sports_waging_council",
  },
  {
    type: fieldTypes.yesNo,
    label: `Are you an owner or employee of any professional, collegiate, or Olympic sports team?`,
    name: "tennessee_phdrf.is_an_owner_of_olympic_sports_team",
  },
  {
    type: fieldTypes.yesNo,
    label: `Are you a coach of, or player for, a collegiate, professional, or Olympic sports team or sport?`,
    name: "tennessee_phdrf.is_a_coach_of_olympic_sports_team",
  },
  {
    type: fieldTypes.yesNo,
    label: `Are you a member or employee of any governing body of a sports team, league, or association?`,
    name: "tennessee_phdrf.is_a_member_of_governing_body_of_a_sports_team",
  },
  {
    type: fieldTypes.yesNo,
    label: `Are you an official, referee, or any person having the ability to affect directly or holding a position through which decisions made by you would affect the outcome of a sporting event?`,
    name: "tennessee_phdrf.is_an_official_that_can_affect_sporting_events",
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "tennessee_phdrf_supplement_disclosure_questions_pdSupplement",
};
