import { Grid } from "@material-ui/core";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import Tooltip from "components/Common/Tooltip";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import PDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/PDPersonSelect.jsx";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";
import { Divider } from "components/Common/LayoutComponents";
import PDFinancialSelect from "components/Formik/formGroups/CrudSelect/selects/PDFinancialSelect.jsx";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <PDPersonSelect
        label="Claimant Name"
        helperText="Select, if the claimant was anyone other then you"
      />

      <FormikDatePicker label="Date of Claim" name="claim_date" xs={6} />
      <FormikNumberField label="Claim Amount" name="claim_amount" xs={6} />
      <FormikTextField label="Nature of Claim" name="nature" xs={6} />
      <FormikTextField
        label="Disposition"
        name="disposition"
        xs={6}
        tooltip={
          <Tooltip
            title="
            The current status or final outcome of the claim, including the
            approved amount (note: claim amount and approved amount may be
            different). Example: Claim Approved, $50,000 or Claim Denied, $0"
          />
        }
      />

      <Divider />
      <PDFinancialSelect
        label="Carrier Name"
        name="carrier_pd_business_entity_id"
      />
    </Grid>
  );
};

const InsuranceClaims = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "insurance_claims_insuranceClaim",
    nonRequired: ["relative_pd_person_id"],
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog title={name}>
        <Form />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} />
    </FormSectionProvider>
  );
};

export default InsuranceClaims;
