import { CircularProgress, Container, Grid } from "@material-ui/core";
import React from "react";
import Center from "../../../flex/Center";
import FormStatuses from "../widgets/FormStatuses/FormStatuses";
import { ExpiringDocuments } from "../widgets/AttentionNeeded/ExpiringDocuments";
import { GeneratedApplications } from "../widgets/ApplicationManager/GeneratedApplications";
import { HeaderCard } from "../widgets/HeaderCard/HeaderCard";
import useLoadDashboardData from "../useLoadDashboardData";
// import MultiFactorAuthWarning from "../widgets/ApplicationManager/MultiFactorAuthWarning";
import { usePDApplicationAssignments } from "queryHooks/useApplicationAssignments";
import { ApplicationAssignmentsWidget } from "../../LicensingControl/components/ApplicationAssignmentsWidget";

export default function PersonalDashboard() {
  const { formStatus, status } = useLoadDashboardData();

  const { data: assignmentsData, status: assignmentsStatus } =
    usePDApplicationAssignments();

  return (
    <>
      {status === "loading" && (
        <Center isFullPage>
          <CircularProgress size={80} />
        </Center>
      )}
      {status === "resolved" && (
        <>
          <Container maxWidth="lg">
            {/* <MultiFactorAuthWarning /> */}
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <HeaderCard />
              </Grid>
              <Grid item xs={6}>
                <ExpiringDocuments />
              </Grid>
              <Grid item xs={7}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <ApplicationAssignmentsWidget
                      status={assignmentsStatus}
                      data={assignmentsData}
                      label="Application Assignments"
                      nameLabel="Assignor: "
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormStatuses formStatuses={formStatus} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <GeneratedApplications />
              </Grid>

              <Grid item sm={12} md={7}></Grid>
            </Grid>
          </Container>
        </>
      )}
    </>
  );
}
