import { DialogTitle as MuiDialogTitle, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import styled from "styled-components";

const CloseButton = styled(IconButton)`
  position: "absolute",
    ${({ theme }) => `
    right: ${theme.spacing(1)}px;,
    top: ${theme.spacing(1)}px;,
    color: ${theme.palette.grey[500]}
    `};
`;

const DialogWrapper = styled(MuiDialogTitle)`
  margin: 0;
  padding: ${({ theme }) => theme.spacing(2, 2, 1, 2)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function DialogHeader(props) {
  const { children, onClose, ...other } = props;
  return (
    <>
      <DialogWrapper disableTypography {...other}>
        {typeof children === "string" ? (
          <Typography variant="h6">{children}</Typography>
        ) : (
          children
        )}
        {onClose ? (
          <CloseButton aria-label="close" onClick={onClose}>
            <CloseIcon cy-data="close_modal" />
          </CloseButton>
        ) : null}
      </DialogWrapper>
    </>
  );
}
