import { Box } from "@material-ui/core";

import React from "react";
import useFetch from "queryHooks/useFetch.js";
import DisplayTable from "components/Common/Tables/DisplayTable/index.js";
import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider";
import { columns } from "./config";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent";

const IndianaBusinessInvolvement = ({ title }) => {
  const { data, status } = useFetch(
    "indiana_business_involvement_associated_business_entities_is_kp_or_5_percent_owner"
  );
  return (
    <FormSectionWrapper title={"Indiana " + title}>
      <Box mb={1} bgcolor="white">
        <LoadingComponent status={status}>
          <DisplayTable
            records={data}
            linkTo={[
              "employment_history",
              "business_interests",
              "business_entities",
            ]}
            columns={columns}
            dependsOnQueryCount={{
              tooltip:
                "Key Person: Any officer, director, executive, employee, trustee, Substantial Owner, independent contractor, or agent of a Business Entity, having the power to exercise, either alone or in conjunction with others, management or operating authority over a Business Entity or Affiliate(s) thereof.",
              question:
                "Have you been associated with a Business Entity as a Key Person, Substantial Owner, or Substantial Creditor for the last ten (10) years?",
            }}
          />
        </LoadingComponent>
      </Box>
    </FormSectionWrapper>
  );
};

export default IndianaBusinessInvolvement;
