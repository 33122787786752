import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import { Box, Paper } from "@material-ui/core";
import DisplayTable from "components/Common/Tables/DisplayTable";
import useFetch from "queryHooks/useFetch";
import {
  assetsColumns,
  gamingEndpoint,
  gamingTableQuestion,
  trustColumns,
  trustEndpoint,
  trustTableQuestion,
  nonUSEndpoint,
  nonUSTableQuestion,
  legalColumns,
  legalEndpoint,
  legalTableQuestion,
  litigationColumns,
  litigationEndpoint,
  litigationTableQuestion,
  taxColumns,
  taxEndpoint,
  taxTableQuestion,
} from "./config.js";
import LoadingComponent from "components/Common/LoadingComponent/LoadingComponent.jsx";

const ColoradoVLMAFinancialHistory = ({ name }) => {
  const { data: gamingData, status: gamingStatus } = useFetch(gamingEndpoint);
  const { data: nonUSData, status: nonUSStatus } = useFetch(nonUSEndpoint);
  const { data: trustData, status: trustStatus } = useFetch(trustEndpoint);
  const { data: legalData, status: legalStatus } = useFetch(legalEndpoint);
  const { data: litigationData, status: litigationStatus } = useFetch(
    litigationEndpoint
  );
  const { data: taxData, status: taxStatus } = useFetch(taxEndpoint);

  return (
    <FormSectionWrapper title={name}>
      <Box mb={1}>
        <Paper>
          <LoadingComponent status={gamingStatus}>
            <DisplayTable
              records={gamingData}
              linkTo={["bd_assets"]}
              columns={assetsColumns}
              dependsOnQueryCount={{
                question: gamingTableQuestion,
              }}
            />
          </LoadingComponent>
        </Paper>
      </Box>
      <Box mb={1}>
        <Paper>
          <LoadingComponent status={nonUSStatus}>
            <DisplayTable
              records={nonUSData}
              linkTo={["bd_assets"]}
              columns={assetsColumns}
              dependsOnQueryCount={{
                question: nonUSTableQuestion,
              }}
            />
          </LoadingComponent>
        </Paper>
      </Box>
      <Box mb={1}>
        <Paper>
          <LoadingComponent status={trustStatus}>
            <DisplayTable
              records={trustData}
              linkTo={["bd_trusts"]}
              columns={trustColumns}
              dependsOnQueryCount={{
                question: trustTableQuestion,
              }}
            />
          </LoadingComponent>
        </Paper>
      </Box>
      <Box mb={1}>
        <Paper>
          <LoadingComponent status={legalStatus}>
            <DisplayTable
              records={legalData}
              linkTo={["bd_antitrust_and_trade"]}
              columns={legalColumns}
              dependsOnQueryCount={{
                question: legalTableQuestion,
              }}
            />
          </LoadingComponent>
        </Paper>
      </Box>
      <Box mb={1}>
        <Paper>
          <LoadingComponent status={litigationStatus}>
            <DisplayTable
              records={litigationData}
              linkTo={["bd_litigations"]}
              columns={litigationColumns}
              dependsOnQueryCount={{
                question: litigationTableQuestion,
              }}
            />
          </LoadingComponent>
        </Paper>
      </Box>
      <Box mb={1}>
        <Paper>
          <LoadingComponent status={taxStatus}>
            <DisplayTable
              records={taxData}
              linkTo={["bd_tax_returns"]}
              columns={taxColumns}
              dependsOnQueryCount={{
                question: taxTableQuestion,
              }}
            />
          </LoadingComponent>
        </Paper>
      </Box>
    </FormSectionWrapper>
  );
};

export default ColoradoVLMAFinancialHistory;
