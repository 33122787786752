import React from "react";
import { Grid } from "@material-ui/core";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import {
  SectionLabel,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import FromToCurrent from "components/Formik/formGroups/FromToCurrent";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";

import { columns, defaultInitialValues, tableQuestions } from "./config.js";
import Tooltip from "components/Common/Tooltip";
import { YesNo } from "components/Formik/fields";
import BDPartySelect from "components/Formik/formGroups/CrudSelect/selects/BDPartySelect.jsx";
import BDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/BDPersonSelect.jsx";

const LobbyistsForm = () => {
  return (
    <Grid container spacing={2}>
      <BDPartySelect
        name="related_bd_party_id"
        label="Lobbyist Details"
        header="Lobbyist Details"
      />
      <SectionSubHeader label="Additional Information" divider />
      <FromToCurrent />
      <FormikTextField label="Details" name="service_rendered" xs={12} />
      <BDPersonSelect
        name="lobbyist_liaison_bd_person_id"
        label="Lobbyist Liaison"
        header="Lobbyist Liaison"
      >
        <Tooltip title="Individual in the Applicant’s business who is the liaison with the lobbyist or consultant"></Tooltip>
      </BDPersonSelect>
      <SectionSubHeader label="fee arrangements" />
      <SectionLabel
        label="Provide the fee arrangements made with lobbyist or consultant."
        divider
      />
      <FormikTextField
        label="Arrangements"
        name="fee_arrangement"
        multiline
        rows={3}
        xs={12}
      />
      <SectionSubHeader divider />
      <YesNo
        label="Has the business entity supplied a cash fund to any lobbyist or consultant? If yes, supply an inventory list of those expenditures, or authorize your lobbyist or consultant to detail information."
        name="is_lobbyist_cash_fund"
      />
      <FormikTextField
        label="Details"
        name="lobbyist_cash_fund_inventory_list"
        multiline
        rows={3}
        xs={12}
      />
      <YesNo
        label="Has the business entity pledged anything of monetary value to a lobbyist, consultant or nominee as a reward for obtaining commission approval of a contract? If yes, explain."
        name="is_lobbyist_pledged_monetary"
      />
      <FormikTextField
        label="Explanation"
        name="lobbyist_pledged_monetary_explain"
        multiline
        rows={3}
        xs={12}
      />
      <YesNo
        label="Has the business entity transferred cash in any manner to an attorney's trust account for dispersal to a lobbyist, consultant or nominee? If yes, explain"
        name="is_lobbyist_transfered_cash"
      />
      <FormikTextField
        label="Explanation"
        name="lobbyist_transfered_cash_explain"
        multiline
        rows={3}
        xs={12}
      />
    </Grid>
  );
};

const Lobbyists = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "bdServiceProvider?related_bd_party_role=lobbyist",
    nonRequired: [
      "lobbyist_liaison_bd_person_id",
      "fee_arrangement",
      "lobbyist_cash_fund_inventory_list",
      "lobbyist_pledged_monetary_explain",
      "lobbyist_transfered_cash_explain",
    ],
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <EnhancedFormikDialog maxWidth="md" title={name}>
        <LobbyistsForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
    </FormSectionProvider>
  );
};

export default Lobbyists;
