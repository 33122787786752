import { Box, Card, Divider, List, Paper, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import useInput from "hooks/useInput";
import useTable from "hooks/useTable";
import { SearchInput } from "../../../../Common/Inputs/SearchInput";
import { LicenseRow } from "./components/LicenseRow";
import { Listheader } from "./components/ListHeader";
import { ProgressIndicator } from "./components/ProgressIndicator";
import { countCompleted, searchFilter } from "./helpers";

const SearchContainer = styled(Paper)`
  background-color: ${({ theme }) => theme.palette.primary.light};
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

const ListContainer = styled(Box)`
  max-height: 400px;
  overflow: auto;
  padding: ${({ theme }) => theme.spacing(1, 2, 1, 2)};
`;

const HeaderContainer = styled(Box)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

const Search = (props) => (
  <SearchContainer elevation={0}>
    <Box p={1}>
      <SearchInput colorVariant="primary" label="Search" {...props} fullWidth />
    </Box>
  </SearchContainer>
);

const Header = ({ handleSearch, searchValue, completedCount, records }) => (
  <HeaderContainer p={3} display="flex">
    <Box
      width={0.5}
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
    >
      <Typography variant="h2" color="inherit">
        <Box fontWeight="fontWeightMedium">Application Progress</Box>
      </Typography>
      <Search onChange={handleSearch} value={searchValue} />
    </Box>
    <Box width={0.5} display="flex" justifyContent="flex-end">
      <ProgressIndicator
        completedCount={completedCount}
        totalCount={records.length}
      />
    </Box>
  </HeaderContainer>
);

const FormStatuses = ({ formStatuses }) => {
  const [searchValue, handleSearch] = useInput();
  const [filterFn, setFilterFn] = React.useState(null);
  const records = Object.values(formStatuses).filter(
    (item) => item?.["meta"]?.["status"]
  );
  const { tableHeadProps, getSortedRecords } = useTable({
    initialRowsPerPage: records.length,
    records,
    filterFn,
  });

  const sortedRecords = getSortedRecords();

  const completedCount = countCompleted(records);

  React.useEffect(() => {
    setFilterFn({
      fn: (records) => {
        return records.filter((record) => searchFilter(record, searchValue));
      },
    });
  }, [searchValue]);

  return (
    <Card variant="outlined">
      <Header
        handleSearch={handleSearch}
        searchValue={searchValue}
        completedCount={completedCount}
        records={records}
      />
      <Listheader {...tableHeadProps} />
      <Divider />
      <ListContainer>
        <List>
          {sortedRecords.map((employee) => (
            <LicenseRow key={employee.id} {...employee} />
          ))}
        </List>
      </ListContainer>
    </Card>
  );
};

export default FormStatuses;
