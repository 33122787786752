import React from "react";
import { commonStrings } from "../../uiStrings";
import { formats } from "components/Common/Tables/Common/helpers";

export const columns = [
  { title: "Dealer/Brokerage", field: "dealer_pd_business_entity.business_name" },
  {
    field: "account_type",
  },
  {
    field: "account_number",
  },
  {
    field: "account_balance",
    format: formats.currency,
  },
  {
    field: "margin_amount",
    format: formats.currency,
  },
];

export const tableQuestions = (
  <>
    Do you maintain a brokerage or margin account with any securities or
    commodities dealer?
    {commonStrings.provideDetails}
  </>
);

export const defaultInitialValues = {
  dealer_pd_business_entity_id: null,
  broker_person_name: "",
  broker_phone: "",
  relative_pd_person_id: null,
  account_number: "",
  account_type: "",
  margin_amount: null,
  account_balance: null,
  balance_date: null,
  attachment_group_id: null,
};
