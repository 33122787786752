import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import ColumnSum from "components/Layout/SectionsLayout/ColumnSum.jsx";
import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { BankAccountsForm } from "../BankAccounts/BankAccounts";
import { defaultInitialValues } from "../BankAccounts/config";
import { columns } from "./config.js";
import useEndPoint from "hooks/useEndpoint";

const BankAccountsAndCash = ({ name }) => {
  const { fullEndpoint } = useEndPoint(
    "non_resident_bank_accounts_bankAccount"
  );

  const formData = useTabularData({
    defaultInitialValues,
    endpoint: "non_resident_bank_accounts_bankAccountsNonResident",
    saveEndpoint: fullEndpoint,
    validationSchemaKey: "bank_accounts",
    noRequired: true,
  });

  return (
    <FormSectionWrapper title={name} formData={formData}>
      <EnhancedFormikDialog title="Bank Account Details">
        <BankAccountsForm />
      </EnhancedFormikDialog>
      <EnhancedActionsTable
        columns={columns}
        footer={
          <ColumnSum
            name="current_balance"
            data={formData.data}
            label="Total Current Balance"
          />
        }
      />
    </FormSectionWrapper>
  );
};

export default BankAccountsAndCash;
