const CSVData = [
  [
    "business_name",
    "business_identifier",
    "first_name",
    "last_name",
    "employee_identifier",
    "license_number",
    "expiration_date",
    "agency_name",
  ],
  [
    "Sample Company",
    23423,
    "John",
    "Doe",
    234542,
    1234,
    "07/22/2021",
    "Sample Licensing Agency Name",
  ],
];

export default CSVData;
