import produce from "immer";

export const uploadActions = {
  UPDATE_UPLOAD_PROGRESS: "UPDATE_UPLOAD_PROGRESS",
  ADD_UPLOAD: "ADD_UPLOAD",
  REMOVE_UPLOAD: "REMOVE_UPLOAD",
};

export const uploadsReducer = (state, action) => {
  switch (action.type) {
    case uploadActions.UPDATE_UPLOAD_PROGRESS:
      return produce(state, (draftState) => {
        const index = state.findIndex(
          (upload) => upload.fileTrackingID === action.fileTrackingID
        );
        draftState[index].progress = action.progress;
      });
    case uploadActions.ADD_UPLOAD:
      return [
        ...state,
        {
          fileTrackingID: action.fileTrackingID,
          file: action.payload,
          progress: 0,
        },
      ];
    case uploadActions.REMOVE_UPLOAD:
      return state.filter(
        (upload) => upload.fileTrackingID !== action.fileTrackingID
      );

    default:
      console.error("Incorrect Action Type");
  }
};
