import { TableCell, TableRow, TableSortLabel } from "@material-ui/core";
import React from "react";
import { StyledTableHeader } from "../styles";
import { startCase } from "lodash";

const TableHeadCell = ({
  disableSorting,
  label,
  sortDirection,
  cellProps,
  ...rest
}) => (
    <TableCell sortDirection={sortDirection} {...cellProps}>
      {disableSorting ? (
        label
      ) : (
          <TableSortLabel {...rest}>{label}</TableSortLabel>
        )}
    </TableCell>
  );

export const CustomTableHead = ({
  handleSortRequest,
  orderBy,
  order,
  columns,
  isDense,
  hasActions = true,
}) => (
    <StyledTableHeader $isDense={isDense}>
      <TableRow>
        {hasActions ? <TableCell width="10%" /> : <TableCell width="5%" />}

        {columns.map(({ field, disableSorting, title, sortType, cellProps }) => (
          <TableHeadCell
            key={field}
            disableSorting={disableSorting}
            label={title ? title : startCase(field)}
            sortDirection={orderBy === field ? order : false}
            active={orderBy === field}
            direction={orderBy === field ? order : "asc"}
            cellProps={cellProps}
            onClick={() => {
              handleSortRequest(field, sortType);
            }}
          />
        ))}
      </TableRow>
    </StyledTableHeader>
  );
