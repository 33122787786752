import { useAuth } from "context/auth";
import useFormPath from "hooks/useFormPath";
import React from "react";
import { getCurrentPermissions } from "./helpers";

const useGetPermission = () => {
  const formPath = useFormPath();
  const { flattened_permissions } = useAuth();

  let permission = React.useMemo(() => {
    return getCurrentPermissions(flattened_permissions, formPath);
  }, [flattened_permissions, formPath]);
  return permission;
};

export default useGetPermission;
