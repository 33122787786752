import { Box, Grid, Paper } from "@material-ui/core";
import {
  Paragraph,
  SectionSubHeader,
} from "components/Common/LayoutComponents";
import CustomLink from "components/Common/Link";
import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";

const TennesseeVRARequiredAttachments = ({ name }) => {
  return (
    <FormSectionWrapper title={name}>
      <Paper>
        <Box padding={2}>
          <Grid>
            <SectionSubHeader label="ownership and executive structure" />
            <Paragraph>
              <>
                Prepare a flowchart which illustrates the full ownership and
                executive structure (e.g., officers, directors, key personnel,
                managers, general partners, trustees, etc.) of the applicant.
                List all parent or holding, intermediary, and subsidiary
                (whether or not wholly-owned) entities and identify the
                ownership and executive structure for each. For non-publicly
                traded business entities identified in the flowchart, all voting
                and non-voting ownership, regardless of percentage of ownership,
                must be disclosed. For publicly-traded business entities in the
                flowchart, all voting and non-voting beneficial owners of five
                percent or more must be listed. Upload your documents in
                <CustomLink to="bd_corporate_documents" />
              </>
            </Paragraph>
            <SectionSubHeader label="Articles of incorporation" />
            <Paragraph>
              <>
                Articles of incorporation, organization, or other legal
                formation documents with the jurisdiction where company was
                formed, and any amendments thereto. Upload your documents in
                <CustomLink to="bd_corporate_documents" />
              </>
            </Paragraph>
            <SectionSubHeader label="governing documentation" />
            <Paragraph>
              <>
                Copy of bylaws, shareholder agreement, operating agreement,
                partnership agreement, trust agreement, or other governing
                documentation of the business entity. Upload your documents in
                <CustomLink to="bd_corporate_documents" />
              </>
            </Paragraph>
            <SectionSubHeader label="Ownership List" />
            <Paragraph>
              <>
                List of owners, including names, home and business addresses,
                telephone numbers, and dates of birth, together with percentage
                of ownership interests held; for publicly-traded organizations,
                a list of the names and addresses of beneficial owners holding
                5% or more. Upload your documents in
                <CustomLink to="bd_corporate_documents" />
              </>
            </Paragraph>
            <SectionSubHeader label="organizational structure" />
            <Paragraph>
              <>
                Complete organizational structure of applicant identifying
                owners, officers/directors, managers, general partners,
                trustees, and key personnel, including for any parent, holding,
                intermediary, affiliated, associated, or subsidiary business
                entities. Upload your documents in
                <CustomLink to="bd_corporate_documents" />
              </>
            </Paragraph>
          </Grid>
        </Box>
      </Paper>
    </FormSectionWrapper>
  );
};

export default TennesseeVRARequiredAttachments;
