import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";

import { Grid } from "@material-ui/core";
import { EnhancedFormikPage } from "components/Formik/formContainers/FormikPage.js";
import {
  SectionSubHeader,
  SectionLabel,
} from "components/Common/LayoutComponents";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { FormContextProvider } from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useForm from "hooks/useForm.js";

import YesNo from "components/Formik/formGroups/YesNo";
import FormikDatePicker from "components/Formik/fields/FormikDatePicker";
import { address } from "helpers/initialValues";
import FormikNumberField from "components/Formik/fields/FormikNumberField";
import FormikGoogleLookup from "components/Formik/fields/FormikGoogleLookup";
import { NumberFormatTypes } from "components/Common/Inputs/NumberField";
import { FormikAddressFields } from "components/Formik/formGroups/FormikAddressFields";
import BusinessAssociation from "components/Common/BusinessAssociation/BusinessAssociation";

const NevadaDisclosureQuestions = ({ name }) => {
  const formData = useForm({
    defaultInitialValues: {
      nevada_spouse_certificate_address: address,
      nevada: {
        associated_business_entity_id: null,
        business_entity_investment_amount: null,
        business_entity_investment_source: "",
        has_business_entity_interest_condition: null,
        has_registered_for_draft: null,
        draft_county: "",
        draft_state: "",
        draft_date: null,
        is_spouse_us_citizen: null,
        spouse_registration_number: "",
        spouse_certificate_number: "",
        spouse_certificate_date: null,
        q14_details: "",
        q15_details: "",
        computers_number: null,
        computers_locations: "",
        tablets_number: null,
        tablets_locations: "",
        data_storage_number: null,
        data_storage_locations: "",
        thumb_drives_number: null,
        thumb_drives_locations: "",
        smart_phones_number: null,
        smart_phones_locations: "",
        other_number: null,
        other_locations: "",
        q17_boolean: null,
        q17_details: "",
      },
    },
    noValidate: true,
    endpoint: "nevada_disclosure_questions_pdSupplement",
  });
  return (
    <FormSectionWrapper title={name}>
      <FormContextProvider formData={formData}>
        <EnhancedFormikPage>
          <Grid container spacing={2}>
            <BusinessAssociation
              title="Associated Gaming Entity"
              header="Name of location for which you are to be licensed, registered, or found suitable:"
              label="Company Name (Your Position) (Ownership Percent)"
              name="nevada.associated_business_entity_id"
              options={[
                "business_name",
                "association",
                "ownership_percent_pretty",
              ]}
            />

            <SectionLabel label="Amount of Personal Investment in business:" />

            <FormikNumberField
              label="Amount"
              name="nevada.business_entity_investment_amount"
              xs={4}
              type={NumberFormatTypes.dollar}
            />

            <FormikTextField
              label="Source of Investment"
              name="nevada.business_entity_investment_source"
              xs={4}
            />

            <YesNo
              label="Has your interest in this gambling establishment been assigned, pledged or hypothecated to any person, firm, 
          or corporation, or has any agreement been entered into whereby your interest is to be assigned, pledged or sold either in part or in whole?"
              name="nevada.has_business_entity_interest_condition"
              xs={12}
            />

            <SectionSubHeader label="Personal Information" />

            <YesNo
              label="Have you registered for the draft?"
              name="nevada.has_registered_for_draft"
              xs={12}
            />

            <SectionLabel label="If yes, please enter the details below:" />

            <FormikTextField label="County" name="nevada.draft_county" xs={4} />
            <FormikTextField label="State" name="nevada.draft_state" xs={4} />

            <FormikDatePicker label="Date" name="nevada.draft_date" xs={4} />

            <SectionSubHeader label="Spouse Information" />

            <YesNo
              label="Is your spouse a citizen of the United States?"
              name="nevada.is_spouse_us_citizen"
              xs={12}
            />
            <SectionLabel label="If yes, please enter the details below:" />
            <FormikTextField
              label="If a non-citizen, Registration No:"
              name="nevada.spouse_registration_number"
              xs={12}
            />

            <FormikTextField
              label="If Naturalized, Certificate No:"
              name="nevada.spouse_certificate_number"
              xs={12}
            />

            <FormikDatePicker
              label="Naturalization Date"
              name="nevada.spouse_certificate_date"
              xs
            />

            <SectionLabel label="Place of Naturalization:" />

            <FormikGoogleLookup
              fields={{
                address: "nevada_spouse_certificate_address",
              }}
            />

            <FormikAddressFields
              label="Place of Naturalization"
              addressPrefix={"nevada_spouse_certificate_address."}
            />

            <SectionLabel label="Provide a list of the business and personal e-mail accounts you have used or have had available to you in the past five years:" />

            <FormikTextField label="Emails" name="nevada.q14_details" xs={12} />

            <SectionLabel label="Provide a list of the business and personal cell phone numbers you have used in the past five years:" />

            <FormikTextField
              label="Phone Numbers"
              name="nevada.q15_details"
              xs={12}
            />

            <SectionLabel label="List below the number and location of electronic devices you have had access to in the past five years:" />

            <FormikNumberField
              label="Number of Computers"
              name="nevada.computers_number"
              xs={3}
              type="none"
            />
            <FormikTextField
              label="Location of Computers"
              name="nevada.computers_locations"
              xs={9}
            />
            <FormikNumberField
              label="Number of Tablets"
              name="nevada.tablets_number"
              xs={3}
              type="none"
            />
            <FormikTextField
              label="Location of Tablets"
              name="nevada.tablets_locations"
              xs={9}
            />
            <FormikNumberField
              label="Number of Data Storage"
              name="nevada.data_storage_number"
              xs={3}
              type="none"
            />
            <FormikTextField
              label="Location of Data Storage"
              name="nevada.data_storage_locations"
              xs={9}
            />
            <FormikNumberField
              label="Number of Thumb Drives"
              name="nevada.thumb_drives_number"
              xs={3}
              type="none"
            />
            <FormikTextField
              label="Location of Thumb Drives"
              name="nevada.thumb_drives_locations"
              xs={9}
            />
            <FormikNumberField
              label="Number of Smart Phones"
              name="nevada.smart_phones_number"
              xs={3}
              type="none"
            />
            <FormikTextField
              label="Location of Smart Phones"
              name="nevada.smart_phones_locations"
              xs={9}
            />
            <FormikNumberField
              label="Number of Other Electronic Devices"
              name="nevada.other_number"
              xs={3}
              type="none"
            />
            <FormikTextField
              label="Location of Other Electronic Devices"
              name="nevada.other_locations"
              xs={9}
            />

            <YesNo
              label="Do you or you spouse store data in the Cloud or a similar service (ICloud/Drop Box)?"
              xs={12}
              name="nevada.q17_boolean"
            />

            <SectionLabel label="If yes, list below and provide a description of the contents:" />

            <FormikTextField
              label="Cloud Data Sources & Contents"
              name="nevada.q17_details"
              xs={12}
            />
          </Grid>
        </EnhancedFormikPage>
      </FormContextProvider>
    </FormSectionWrapper>
  );
};

export default NevadaDisclosureQuestions;
