import React from "react";
import { formats } from "components/Common/Tables/Common/helpers";

export const columns = [
  {
    field: "business_name",
    title: "Business Name",
  },
  {
    field: "person_name",
    title: "Relative Name",
  },
  {
    field: "person_relative_type_pretty",
    title: "Relationship",
    format: formats.startCase,
  },
  {
    field: "acquisition_date",
    title: "Acquisition Date",
    format: formats.date,

    sortType: "date",
  },
  {
    field: "acquisition_cost",
    title: "Acquisition Cost",
    format: formats.currency,
  },
  {
    field: "ownership_percent",
    format: formats.percent,
  },
  {
    field: "market_value",
    format: formats.currency,
  },
];

export const tableQuestions = (
  <>
    Provide information for any business investments in which any direct,
    indirect, vested or contingent is held by you, your spouse, domestic partner
    or your dependent children. Business interests should include, but not be
    limited to, joint ventures, partnerships, sole proprietorships, corporations
    and LLCs.
  </>
);

export const defaultInitialValues = {
  pd_business_entity_id: null,
  is_current: true,
  pd_person_id: null,
  role_checkbox: null,
  annual_income: null,
  ownership_percent: null,
  acquisition_date: null,
  acquisition_cost: null,
  years_associated: "",
  valuation_date: null,
  market_value: null,
  valuation_method: "",
  attachment_group_id: null,
};
