import React from "react";
import { commonStrings } from "../../uiStrings";

export const columns = [
  {
    field: "obligation_date",
  },
  {
    title: "Obligation Amount",
    field: "amount_original",
    format: "currency",
  },
  {
    field: "nature",
  },
  {
    title: "Borrower",
    field: "borrower_names",
  },
];

export const tableQuestions = (
  <>
    Provide details if {commonStrings.youSpouse}, in the past ten (10) years
    given a guarantee, co­signed or otherwise insured payment of a loan, debt or
    other financial obligation in any jurisdiction?
  </>
);

export const defaultInitialValues = {
  relative_pd_person_id: null,
  nature: "",
  borrower_names: "",
  obligation_date: null,
  obligation_status: "",
  amount_original: null,
};

export const nonRequired = ["relative_pd_person_id"];
