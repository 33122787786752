import useToggle from "hooks/useToggle";
import React from "react";
import { formActions } from "../helpers";

const usePartySelect = () => {
  const [entityType, setEntityType] = React.useState([]);
  const { on, setOn, setOff } = useToggle();

  const crudDispatch = React.useRef(null);

  const handleEntitySelected = (entityParam) => {
    setEntityType(entityParam);
    setOff();
    crudDispatch.current({ type: formActions.ADD_RECORD });
  };

  const handleAddNewOverride = (dispatch) => {
    crudDispatch.current = dispatch;
    setOn();
  };

  return {
    open: on,
    onClose: setOff,
    entityType,
    handleEntitySelected,
    handleAddNewOverride,
  };
};

export default usePartySelect;
