import BusinessIcon from "@material-ui/icons/Business";
import PersonIcon from "@material-ui/icons/Person";
import formSchemas from "componentGenerators/formGenerator/formSchemas";
import CrudSelect from "components/Formik/formGroups/CrudSelect/CrudSelect";
import { address, businessTypeCheckBox } from "helpers/initialValues";
import PropTypes from "prop-types";
import React from "react";
import usePartySelect from "../hooks/usePartySelect";
import SelectBusinessOrPersonDialog from "../SelectBusinessOrPersonDialog";
import useBdIdAddedToAddress from "../hooks/useBdIdAddedToAddress";

const entityTypeDefaults = {
  business_entity: {
    formSchema: formSchemas.bdBusinessSchema,
    saveEndpoint: "bdBusinessEntity",
    formTitle: "Add / Edit Business",
    initialValues: {
      ...businessTypeCheckBox,
      business_name: "",
      bd_address: {
        fax: "",
        phone: "",
        website: "",
        email: "",
        address: address,
      },
    },
  },

  person: {
    formSchema: formSchemas.bdPersonSchema,
    saveEndpoint: "bdPerson",
    formTitle: "Add / Edit Person",
    initialValues: {
      first_name: "",
      middle_name: "",
      last_name: "",
      suffix_name: "",
      date_of_birth: null,
      country_of_birth: null,
      home_phone: "",
      mobile_phone: "",
      business_phone: "",
      fax: "",
      home_address: address,
      occupation: "",
      title: "",
      email: "",
    },
  },
};

const getOptionIcon = ({ type }) => {
  if (type === "business_entity")
    return <BusinessIcon color="primary" fontSize="small" />;
  if (type === "person") return <PersonIcon color="primary" fontSize="small" />;
  return null;
};

const BDPartySelect = ({
  name = "bd_party_id",
  label = "Party Involved",
  header = "Party Involved",
  endpoint = "views/bd_parties",
  ...rest
}) => {
  const {
    open,
    onClose,
    entityType,
    handleEntitySelected,
    handleAddNewOverride,
  } = usePartySelect();

  const defaults = entityTypeDefaults[entityType];

  const modifySubmittedValues = useBdIdAddedToAddress();

  return (
    <>
      <SelectBusinessOrPersonDialog
        handleAddPerson={() => handleEntitySelected("person")}
        handleAddBusiness={() => handleEntitySelected("business_entity")}
        open={open}
        onClose={onClose}
      />
      <CrudSelect
        name={name}
        label={label}
        header={header}
        endpoint={endpoint}
        saveEndpoint={defaults?.saveEndpoint}
        formSchema={defaults?.formSchema || []}
        formTitle={defaults?.formTitle}
        getOptionIcon={getOptionIcon}
        handleAddNewOverride={handleAddNewOverride}
        isParty={true}
        editEnabled={false} //disabled for party select because the "views/bd_parties" attributes do not match with the saveEndpoint attributes
        {...(entityType === "business_entity" ? { modifySubmittedValues } : {})}
        {...rest}
      />
    </>
  );
};

BDPartySelect.propTypes = {
  endpoint: PropTypes.string,
  formSchema: PropTypes.array,
  formTitle: PropTypes.string,
  label: PropTypes.string,
  header: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default BDPartySelect;
