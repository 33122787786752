import React from "react";
import { get } from "lodash";
import { isObject } from "helpers/utils";

const useInitialValues = (currentRecord, defaultInitialValues) => {
  const initialValues = React.useMemo(() => {
    if (currentRecord) {
      const initialValueKeys = Object.keys(defaultInitialValues);
      return initialValueKeys.reduce((acc, field) => {
        const defaultFieldValue = get(defaultInitialValues, field);
        const currentFieldValue = get(currentRecord, field);

        acc[field] = isObject(defaultInitialValues[field])
          ? {
              ...defaultFieldValue,
              ...currentFieldValue,
            }
          : currentFieldValue;
        return acc;
      }, {});
    } else return defaultInitialValues;
  }, [currentRecord, defaultInitialValues]);

  return initialValues;
};

export default useInitialValues;
