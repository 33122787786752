import * as yup from "yup";

export const validation = yup.object().shape({
  location_number: yup
    .string()
    .required("Required Field")
    .typeError("Required Field"),
});

export const columns = [
  {
    field: "location_number",
    title: "Location",
  },
];

export const defaultInitialValues = {
  location_number: "",
};

export const sortingOptions = {
  defaultSortBy: "location_number",
  defaultSortOrder: "asc",
};

export const endpoint = "licensing/lcWorkLocation";
