import React from "react";
import { formats } from "components/Common/Tables/Common/helpers";
import { fieldTypes } from "componentGenerators/formGenerator/config";
import { NumberFormatTypes } from "components/Formik/fields/FormikNumberField";

const nonFiledTaxesColumns = [
  { title: "Creditor", field: "creditor_pd_party_name" },
  { field: "incurred_date" },
  { field: "outstanding_amount", format: formats.currency },
];

const nonFiledTaxesFormSchema = [
  {
    type: fieldTypes.subHeader,
    label: "Tax Agency",
  },
  {
    type: fieldTypes.pdBusinessSelect,
    label: "Creditor",
    getOptionLabel: (option) => option.business_name,
    getOptionValue: (option) => option.id,
    xs: 8,
    required: true,
    name: "creditor_pd_party_id",
  },
  {
    type: fieldTypes.subHeader,
    label: "Details",
  },
  {
    name: "incurred_date",
    type: fieldTypes.date,
    label: "Incurred Date",
    required: true,
  },
  {
    type: fieldTypes.number,
    label: "Total Amount Due",
    name: "outstanding_amount",
    format: NumberFormatTypes.dollar,
    required: true,
  },
];

const nonFiledTaxesSchema = {
  type: "table",
  endpoint: "michigan_isbigo_supplement_tax_payables_taxesPayablePayables",
  formSchema: nonFiledTaxesFormSchema,
  columns: nonFiledTaxesColumns,
  infoAlerts: [
    {
      body: (
        <>
          List below the information requested with regard to all taxes payable
          for which you are obligated. Only real estate and income taxes need to
          be included.
        </>
      ),
    },
  ],
  modifySubmissionValues: (formValues) => ({
    ...formValues,
    type: "tax",
  }),
};

export default [nonFiledTaxesSchema];
