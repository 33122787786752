import useFetch from "queryHooks/useFetch";
import usePost from "queryHooks/usePost";
import { useCallback } from "react";
import useErrorSnackBar from "hooks/useErrorSnackbar";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import useDelete from "queryHooks/useDelete";

const readFile = (file, handleSuccess) => {
  const reader = new FileReader();
  reader.readAsText(file);
  reader.onabort = () => console.warn("file reading was aborted");
  reader.onerror = () => console.warn("file reading has failed");
  reader.onload = () => {
    handleSuccess(reader.result);
  };
};

const useFileDropZone = ({ endpoint }) => {
  const { data, status } = useFetch(endpoint, endpoint, {
    useFullEndpoint: false,
  });

  const queryCache = useQueryClient();

  const { handleErrorMessage } = useErrorSnackBar();

  const { enqueueSnackbar } = useSnackbar();

  const { mutate: postMutation } = usePost(endpoint, endpoint, {
    onError: ({ _item, ...rest }) => {
      queryCache.invalidateQueries(endpoint);
      handleErrorMessage(rest);
    },
    onSuccess: () =>
      enqueueSnackbar("File Uploaded Successfully", {
        autoHideDuration: 2000,
        variant: "success",
      }),
  });

  const { mutate: deleteMutation } = useDelete(endpoint, endpoint, {
    // onMutate: reset,
    onSuccess: () => {
      enqueueSnackbar("Record Deleted", {
        variant: "success",
      });
    },
    onError: (error) => {
      handleErrorMessage(error);
    },
  });

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((fileData) => {
        readFile(fileData, (parsedFile) =>
          postMutation({ file_data_text: parsedFile, label: fileData.name })
        );
      });
    },
    [postMutation]
  );
  return {
    onDrop,
    files: data,
    status,
    onDelete: deleteMutation,
  };
};

export default useFileDropZone;
