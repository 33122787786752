import { Grid } from "@material-ui/core";
import { SectionSubHeader } from "components/Common/LayoutComponents";
import { EnhancedActionsTable } from "components/Common/Tables/Common";
import { EnhancedFormikDialog } from "components/Formik/formContainers/FormikDialog";
import BDPersonSelect from "components/Formik/formGroups/CrudSelect/selects/BDPersonSelect.jsx";
import { InfoAlert } from "components/Layout/Alert/InfoAlert";
import FormSectionProvider from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import useTabularData from "hooks/tableHooks/useTabularData";
import React from "react";
import { columns, defaultInitialValues, tableQuestions } from "./config.js";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <SectionSubHeader>Person</SectionSubHeader>
      <BDPersonSelect />
    </Grid>
  );
};

const KnowledgeOfContributions = ({ name }) => {
  const formData = useTabularData({
    defaultInitialValues,
    endpoint:
      "bdServiceProvider?related_bd_party_role=knowledge_of_contribution",
  });

  return (
    <FormSectionProvider
      formData={formData}
      title={name}
      headerComponent={<InfoAlert>{tableQuestions}</InfoAlert>}
    >
      <>
        <EnhancedFormikDialog title="Employer Details">
          <Form />
        </EnhancedFormikDialog>
        <EnhancedActionsTable columns={columns} addButtonText="Add Record" />
      </>
    </FormSectionProvider>
  );
};

export default KnowledgeOfContributions;
