//ELK VALLEY
import ElkValleyDisclosureQuestions from "components/Pages/Supplemental/PD/ElkValley/ElkValleyDisclosureQuestions/ElkValleyDisclosureQuestions";
import ElkValleyMaritalStatus from "components/Pages/Supplemental/PD/ElkValley/ElkValleyMaritalStatus/ElkValleyMaritalStatus";
import ElkValleyFinancialHistory from "components/Pages/Supplemental/PD/ElkValley/ElkValleyFinancialHistory/ElkValleyFinancialHistory";
import ElkValleyBusinessInterests from "components/Pages/Supplemental/PD/ElkValley/ElkValleyBusinessInterests/ElkValleyBusinessInterests";

const elk_valley_pages = {
  elk_valley_disclosure_questions: ElkValleyDisclosureQuestions,
  elk_valley_marital_status: ElkValleyMaritalStatus,
  elk_valley_financial_history: ElkValleyFinancialHistory,
  elk_valley_business_interests: ElkValleyBusinessInterests,
};

export default elk_valley_pages;
