import { fieldTypes } from "componentGenerators/formGenerator/config";

const formSchema = [
  {
    type: fieldTypes.subHeader,
    label: "associated business entity",
  },
  {
    type: fieldTypes.label,
    label:
      "This application is submitted in connection with the permit or permit application of:",
  },
  {
    type: fieldTypes.pdBusinessSelect,
    label: "Business Entity",
    name: "louisiana_phfr.associated_business_id",
  },
  {
    type: fieldTypes.label,
    label:
      "List the amount to be invested by the applicant in the company chosen above:",
  },
  {
    type: fieldTypes.number,
    label: "Amount",
    name: "louisiana_phfr.section_l_q_12_amount_invested",
    xs: 4,
  },
  {
    type: fieldTypes.subHeader,
    label: "Residential History",
  },
  {
    type: fieldTypes.label,
    label: "Approximate date you started living in your country of residence:",
  },
  {
    type: fieldTypes.date,
    label: "Approximate Date:",
    name: "louisiana_phfr.country_residence_date",
  },
];

export default {
  type: "form",
  formSchema,
  endpoint: "louisiana_phfr_supplement_disclosure_questions_pdSupplement",
};
