import React from "react";
import { Button, Grid } from "@material-ui/core";
import EditDialog from "components/Layout/Dialog/EditDialog";
import useToggle from "hooks/useToggle";
import AlertHeader from "components/Layout/Alert/AlertHeader";
import useFetch from "queryHooks/useFetch";
import LoadingComponent from "../LoadingComponent/LoadingComponent";

const EditHeader = ({ linkToList }) => {
  const {
    on: isEditDialogOpen,
    toggle: toggleEditDialog,
    setOff: closeEditDialog,
  } = useToggle();
  return (
    <>
      <EditDialog
        open={isEditDialogOpen}
        onClose={closeEditDialog}
        linkToList={linkToList}
      />
      <Grid item>
        <Button
          color="primary"
          variant="contained"
          onClick={toggleEditDialog}
          size="small"
        >
          Edit
        </Button>
      </Grid>
    </>
  );
};

const AlertEdit = ({
  xs = 12,
  linkTo,
  title,
  dependsOnQueryCount,
  displayValue,
  endpoint = "personalDisclosure",
  editComponent,
  showIcon,
}) => {
  const linkToList = linkTo
    ? Array.isArray(linkTo)
      ? linkTo
      : [linkTo]
    : null;
  const showEdit = !!linkToList?.length;

  if (showIcon !== false) {
    showIcon = showEdit;
  }

  const { data, status } = useFetch(endpoint, endpoint);

  if (status !== "success") return null;
  return (
    <LoadingComponent status={status}>
      <Grid item xs={xs}>
        <AlertHeader
          title={title}
          showIcon={showIcon}
          dependsOnQueryCount={dependsOnQueryCount}
          question={{
            display_value: data[0] ? data[0][displayValue] : false,
          }}
          default="layout"
        />
      </Grid>
      {editComponent
        ? editComponent(linkToList)
        : showEdit && <EditHeader linkToList={linkToList} />}
    </LoadingComponent>
  );
};

export default AlertEdit;
