import React from "react";

const useInput = () => {
  const [searchValue, setSearchValue] = React.useState("");

  const handleInput = (e) => {
    setSearchValue(e.target.value);
  };

  const reset = () => setSearchValue("");

  return [searchValue, handleInput, reset];
};

export default useInput;
