
import TennesseeBEDApplicationInformation from "components/Pages/Supplemental/BD/TennesseeBED/TennesseeBEDApplicationInformation/TennesseeBEDApplicationInformation.jsx";
import TennesseeBEDQualifiers from "components/Pages/Supplemental/BD/TennesseeBED/TennesseeBEDQualifiers/TennesseeBEDQualifiers.jsx";
import TennesseeBEDAttachments from "components/Pages/Supplemental/BD/TennesseeBED/TennesseeBEDAttachments/TennesseeBEDAttachments.jsx";

export default {
  bd_tennessee_bed_supplement_application_details: TennesseeBEDApplicationInformation,
  bd_tennessee_bed_supplement_qualifiers: TennesseeBEDQualifiers,
  bd_tennessee_bed_supplement_attachments: TennesseeBEDAttachments,
};
