import FormSectionWrapper from "components/Layout/SectionsLayout/FormSectionProvider.jsx";
import React from "react";
import { BusinessAddressForm, MailingAddressForm } from "./AddressForms";
import PersonalDetailsForm from "./PersonalDetailsForm";
import AppearanceAndCharacteristicsForm from "./AppearanceAndCharacteristicsForm";
import NickNamesTable from "./NickNamesTable";
import MultiFormWrapper from "components/Layout/SectionsLayout/MultiFormWrapper";

const PersonalDetails = ({ name }) => {
  return (
    <FormSectionWrapper title={name}>
      <MultiFormWrapper>
        <PersonalDetailsForm />
        <MailingAddressForm />
        <BusinessAddressForm />
        <AppearanceAndCharacteristicsForm />
      </MultiFormWrapper>

      <NickNamesTable />
    </FormSectionWrapper>
  );
};

export default PersonalDetails;
