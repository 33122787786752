import React from "react";
import FormikTextField from "components/Formik/fields/FormikTextField";
import { Grid } from "@material-ui/core";
import FormikAutocomplete from "components/Formik/fields/FormikAutocomplete";

const BusinessForm = () => {
  return (
    <Grid container spacing={2}>
      <FormikTextField label="Business Name" name="business_name" xs={6} />
      <FormikTextField label="External Id" name="external_id" xs={6} />
      <FormikAutocomplete
        label="Recipient Contact Email"
        name="notification_emails"
        xs={12}
        multiple
        freeSolo
        options={[].map(() => "")}
      />
    </Grid>
  );
};

export default BusinessForm;
