import { Box, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import styled from "styled-components";

const PercentText = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

const useCircularProgressStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  bottom: {
    color: theme.palette.primary.contrastText,
  },
  top: {
    color: theme.palette.gold.main,
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
}));

function CircularProgressWithLabel({ formattedCompletion, value }) {
  const classes = useCircularProgressStyles();

  const circularProgressProps = {
    variant: "determinate",
    size: 80,
    thickness: 4,
  };
  return (
    <Box position="relative" display="inline-flex">
      <div className={classes.root}>
        <CircularProgress
          className={classes.bottom}
          value={100}
          {...circularProgressProps}
        />
        <CircularProgress
          value={value}
          className={classes.top}
          classes={{
            circle: classes.circle,
          }}
          {...circularProgressProps}
        />
      </div>
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <PercentText variant="subtitle2" component="div">
          {`${formattedCompletion}%`}
        </PercentText>
      </Box>
    </Box>
  );
}

export const ProgressIndicator = ({ completedCount, totalCount }) => {
  const completionPercentage = (completedCount / totalCount) * 100;
  const formattedCompletion = completionPercentage.toFixed(1);
  return (
    <Box minWidth={100} mr={3}>
      <Box width={1} display="flex" justifyContent="center">
        <CircularProgressWithLabel
          value={completionPercentage}
          formattedCompletion={formattedCompletion}
        />
      </Box>
      <Box>
        <Typography variant="subtitle1">
          {`${completedCount} / ${totalCount}`} Complete
        </Typography>
      </Box>
    </Box>
  );
};
